import React from 'react';
import { useData } from '../../providers/DataProvider';

import { UPSERT_LANE } from './gql';
import { toast } from 'react-toastify';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

const log = false;

////////// COMPONENT //////////
export default function LaneAddEditModal(props) {
  const ctx = useData();

  const { open, onClose, laneInput } = props;

  const handleSubmit = async () => {
    try {
      let res = await ctx.apolloClient.mutate({
        mutation: UPSERT_LANE,
        variables: { laneObjects: [laneInput] },
      });
      if (res && res.data.insert_lanes.returning.length > 0) {
        const resLane = res.data.insert_lanes.returning[0];
        log && console.log(`>> UPSERTED Lane:`, resLane);
        toast.success(`Successfully upserted lane`);
        handleClose();
      }
    } catch (err) {
      console.error(`Error upserting lane:`, err);
      toast.error(`Successfully upserted lane`);
    }
  };
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Save Lane</ModalHeader>

        <ModalContent subtitle={`Are you sure you want to save this lane?`} />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}
