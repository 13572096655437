//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, FormControl, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  movePlanning: `config.add_moves_disabled`,
  bypassMovePlanning: `config.add_moves_always`,
  allowedMoveTypes: `config.allowed_move_types`,
  defaultMoveType: `config.default_move_type`,
  distanceEstimates: `config.distance_estimates`,
  priceEstimates: `config.price_estimates`,
  triangleMoves: `config.allow_triangle_moves`,
};

// DEFAULTS //
const getDefaultMovePlanning = (overrideRef, inheritedRef) => {
  // Reverse bool logic for move planning flag
  if (getPropValue(overrideRef, option?.movePlanning) === true) return false;
  if (getPropValue(overrideRef, option?.movePlanning) === false) return true;
  return !getPropValue(inheritedRef, option?.movePlanning);
};
const getDefaultBypassMovePlanning = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.bypassMovePlanning) === false) return false;
  if (getPropValue(overrideRef, option?.bypassMovePlanning) === true) return true;
  return getPropValue(inheritedRef, option?.bypassMovePlanning);
};
const getDefaultAllowedMoveTypes = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.allowedMoveTypes) || getPropValue(inheritedRef, option?.allowedMoveTypes);
};
const getDefaultMoveType = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.defaultMoveType) || getPropValue(inheritedRef, option?.defaultMoveType);
};
const getDefaultDistanceEstimates = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.distanceEstimates) === false) return false;
  if (getPropValue(overrideRef, option?.distanceEstimates) === true) return true;
  return getPropValue(inheritedRef, option?.distanceEstimates);
};
const getDefaultPriceEstimates = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.priceEstimates) === false) return false;
  if (getPropValue(overrideRef, option?.priceEstimates) === true) return true;
  return getPropValue(inheritedRef, option?.priceEstimates);
};
const getDefaultTriangleMoves = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.triangleMoves) === false) return false;
  if (getPropValue(overrideRef, option?.triangleMoves) === true) return true;
  return getPropValue(inheritedRef, option?.triangleMoves);
};

//////////////////////// COMPONENT ////////////////////////

export default function MovePlanning({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [movePlanning, setMovePlanning] = React.useState(getDefaultMovePlanning(overrideRef, inheritedRef));
  const [bypassMovePlanning, setBypassMovePlanning] = React.useState(
    getDefaultBypassMovePlanning(overrideRef, inheritedRef)
  );
  const [allowedMoveTypes, setAllowedMoveTypes] = React.useState(getDefaultAllowedMoveTypes(overrideRef, inheritedRef));
  const [defaultMoveType, setDefaultMoveType] = React.useState(getDefaultMoveType(overrideRef, inheritedRef));
  const [distanceEstimates, setDistanceEstimates] = React.useState(
    getDefaultDistanceEstimates(overrideRef, inheritedRef)
  );
  const [priceEstimates, setPriceEstimates] = React.useState(getDefaultPriceEstimates(overrideRef, inheritedRef));
  const [triangleMoves, setTriangleMoves] = React.useState(getDefaultTriangleMoves(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setMovePlanning(getDefaultMovePlanning(null, inheritedRef));
      setBypassMovePlanning(getDefaultBypassMovePlanning(null, inheritedRef));
      setAllowedMoveTypes(getDefaultAllowedMoveTypes(null, inheritedRef));
      setDefaultMoveType(getDefaultMoveType(null, inheritedRef));
      setDistanceEstimates(getDefaultDistanceEstimates(null, inheritedRef));
      setPriceEstimates(getDefaultPriceEstimates(null, inheritedRef));
      setTriangleMoves(getDefaultTriangleMoves(null, inheritedRef));
    }
  }, [isReset]);

  // Handle move planning change
  const handleMovePlanningChange = () => {
    // Get the value
    const value = !movePlanning;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { add_moves_disabled: !value } };
    setMovePlanning(value);
    updateMutableRef(changes);
  };

  // Handle bypass move planning change
  const handleBypassMovePlanningChange = () => {
    // Get the value
    const value = !bypassMovePlanning;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { add_moves_always: value } };
    setBypassMovePlanning(value);
    updateMutableRef(changes);
  };

  // Handle allowed move types change
  const handleAllowedMoveTypesChange = type => {
    // Get the value
    const value = [...allowedMoveTypes];
    if (value?.includes(type)) value.splice(value.indexOf(type), 1);
    else value.push(type);

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { allowed_move_types: value } };
    setAllowedMoveTypes(value);
    updateMutableRef(changes);
  };

  // Handle default move type change
  const handleDefaultMoveTypeChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { default_move_type: value } };
    setDefaultMoveType(value);
    updateMutableRef(changes);
  };

  // Handle distance estimates change
  const handleDistanceEstimatesChange = () => {
    // Get the value
    const value = !distanceEstimates;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { distance_estimates: value } };
    setDistanceEstimates(value);
    updateMutableRef(changes);
  };

  // Handle price estimates change
  const handlePriceEstimatesChange = () => {
    // Get the value
    const value = !priceEstimates;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { price_estimates: value } };
    setPriceEstimates(value);
    updateMutableRef(changes);
  };

  // Handle triangle moves change
  const handleTriangleMovesChange = () => {
    // Get the value
    const value = !triangleMoves;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { allow_triangle_moves: value } };
    setTriangleMoves(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Move Planning`}
        tip={`The move planning module allows us to fine-tune the client's experience when planning moves on the portal.`}
      />

      <SettingsOption
        contained
        title={`Activation`}
        description={`Activate/Deactivate the move planning feature for this client. This will allow the client to create moves in our portal or API. NOTE: This switch will deactivate automatically when a client goes over their move limit. Please use the "Bypass Auto-Deactivation" option to override this behavior.`}
        value={movePlanning}
        checkReset={() => checkMutableRefField(option?.movePlanning)}
        onReset={() =>
          deleteMutableRefField(option?.movePlanning, () =>
            setMovePlanning(getPropValue(inheritedRef, option?.movePlanning))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={movePlanning} onChange={() => handleMovePlanningChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Bypass Auto-Deactivation`}
        description={`Bypass the automatic deactivation of move planning. As mentioned in the "Activation" option, move planning will deactivate automatically when a client goes over their move limit. This switch will override that behavior and allow the client to continue creating moves.`}
        value={bypassMovePlanning}
        checkReset={() => checkMutableRefField(option?.bypassMovePlanning)}
        onReset={() =>
          deleteMutableRefField(option?.bypassMovePlanning, () =>
            setBypassMovePlanning(getPropValue(inheritedRef, option?.bypassMovePlanning))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={bypassMovePlanning} onChange={() => handleBypassMovePlanningChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Allowed Move Types`}
        description={`Choose which move types are allowed for this client in the move planner. More often than not, there is no reason to restrict a client unless they request/require it.`}
        value={allowedMoveTypes}
        checkReset={() => checkMutableRefField(option?.allowedMoveTypes)}
        onReset={() =>
          deleteMutableRefField(option?.allowedMoveTypes, () =>
            setAllowedMoveTypes(getPropValue(inheritedRef, option?.allowedMoveTypes))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <FormControl>
          <FormControlLabel
            label='Concierge'
            value='concierge'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`concierge`)}
                onClick={() => handleAllowedMoveTypesChange(`concierge`)}
              />
            }
          />
          <FormControlLabel
            label='Concierge + Loaner'
            value='concierge-loaner'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`concierge-loaner`)}
                onClick={() => handleAllowedMoveTypesChange(`concierge-loaner`)}
              />
            }
          />
          <FormControlLabel
            label='One-Way'
            value='one-way'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`one-way`)}
                onClick={() => handleAllowedMoveTypesChange(`one-way`)}
              />
            }
          />
          <FormControlLabel
            label='Round-Trip'
            value='round-trip'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`round-trip`)}
                onClick={() => handleAllowedMoveTypesChange(`round-trip`)}
              />
            }
          />
        </FormControl>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Default Move Type`}
        description={`Choose the default move type for this client. This is a simple quality of life feature that automatically selects the prefered move type for the client in the move planner.`}
        value={defaultMoveType}
        checkReset={() => checkMutableRefField(option?.defaultMoveType)}
        onReset={() =>
          deleteMutableRefField(option?.defaultMoveType, () =>
            setDefaultMoveType(getPropValue(inheritedRef, option?.defaultMoveType))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <FormControl>
          <RadioGroup value={defaultMoveType} onChange={handleDefaultMoveTypeChange}>
            <FormControlLabel
              label='Concierge'
              value='concierge'
              control={<Radio className={cls.radio} color='primary' />}
            />
            <FormControlLabel
              label='Concierge + Loaner'
              value='concierge-loaner'
              control={<Radio className={cls.radio} color='primary' />}
            />
            <FormControlLabel
              label='One-Way'
              value='one-way'
              control={<Radio className={cls.radio} color='primary' />}
            />
            <FormControlLabel
              label='Round-Trip'
              value='round-trip'
              control={<Radio className={cls.radio} color='primary' />}
            />
          </RadioGroup>
        </FormControl>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Activate Distance Estimates`}
        description={`Activate/Deactivate the distance estimates feature for this client. This will allow the client to see distance estimates for their moves before they create them.`}
        value={distanceEstimates}
        checkReset={() => checkMutableRefField(option?.distanceEstimates)}
        onReset={() =>
          deleteMutableRefField(option?.distanceEstimates, () =>
            setDistanceEstimates(getPropValue(inheritedRef, option?.distanceEstimates))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={distanceEstimates} onChange={() => handleDistanceEstimatesChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Activate Price Estimates`}
        description={`Activate/Deactivate the price estimates feature for this client. This will allow the client to see price estimates for their moves before they create them.`}
        value={priceEstimates}
        checkReset={() => checkMutableRefField(option?.priceEstimates)}
        onReset={() =>
          deleteMutableRefField(option?.priceEstimates, () =>
            setPriceEstimates(getPropValue(inheritedRef, option?.priceEstimates))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={priceEstimates} onChange={() => handlePriceEstimatesChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Activate Triangle Moves`}
        description={`Activate/Deactivate the triangle moves feature for this client. Triangle moves allow for price adjustments to a ride-drive-ride bundle of moves. If you do not understand what this feature does, please leave it disabled.`}
        value={triangleMoves}
        checkReset={() => checkMutableRefField(option?.triangleMoves)}
        onReset={() =>
          deleteMutableRefField(option?.triangleMoves, () =>
            setTriangleMoves(getPropValue(inheritedRef, option?.triangleMoves))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={triangleMoves} onChange={() => handleTriangleMovesChange()} />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  radio: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
