import { gql } from 'graphql-tag';

const SET_MOVE_TAGS = gql`
  mutation set_move_tags($moveId: bigint!, $tags: String!) {
    update_moves(where: { id: { _eq: $moveId } }, _set: { tags: $tags }) {
      affected_rows
      returning {
        id
        tags
      }
    }
  }
`;

export { SET_MOVE_TAGS };
