//////////////////////// DEPENDENCIES ////////////////////////

//////////////////////// COMPONENT ////////////////////////

export default function useOrganizationDetails() {
  /** Tabs for organization details */
  const tabs = [
    {
      id: 0,
      label: `Overview`,
      route: `overview`,
      icon: `view_quilt`,
    },
    {
      id: 1,
      label: `Users`,
      route: `users`,
      icon: `people_alt`,
    },
    {
      id: 2,
      label: `Settings`,
      route: `settings`,
      icon: `settings_applications`,
    },
  ];

  /** Get the organization object with only the override values */
  const getDefaultOrganizationRef = organization => {
    // Build the new organization object with defaults
    let newOrganization = {
      config: {},
    };

    // If we pass in a organization object, override some values
    if (organization) {
      // Get the mutable organization copy
      let mutableOrganization = JSON.parse(JSON.stringify(organization || {}));

      // Set values where applicable
      if (mutableOrganization?.config) newOrganization.config = mutableOrganization?.config;
    }

    // Return the new organization object
    return newOrganization;
  };

  // Return additional logic
  return {
    tabs,
    getDefaultOrganizationRef,
  };
}
