import React from 'react';
import { Button, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import dayjs from 'dayjs';

const formattedDateTime = date => {
  if (!date) return 'N/A';
  return dayjs(date).format('MM/DD/YYYY h:mm a');
};
const formattedSlaDateTime = (created_at, duration_hrs) => {
  if (!created_at || !duration_hrs) return 'N/A';
  return dayjs(created_at).add(duration_hrs, 'hours').format('MM/DD/YYYY h:mm a');
};
const formattedVehicleDescription = (color, year, make, model) => {
  if (!color && !year && !make && !model) return 'N/A';
  return `${color ? color : ''} ${year ? year : ''} ${make ? make : ''} ${model ? model : ''}`;
};

const dynamicTimestampRow = move => {
  if (move.status === null) {
    return null;
  }
  if (move.status === 'dispatched') {
    return <SummaryMoveItemRow label='Pickup Time' value={formattedDateTime(move.pickup_time)} />;
  }
  if (move.status === 'pickup arrived') {
    return <SummaryMoveItemRow label='Pickup Arrived' value={formattedDateTime(move.pickup_arrived)} />;
  }
  if (move.status === 'pickup started') {
    return <SummaryMoveItemRow label='Pickup Started' value={formattedDateTime(move.pickup_started)} />;
  }
  if (move.status === 'pickup successful') {
    return <SummaryMoveItemRow label='Pickup Successful' value={formattedDateTime(move.pickup_successful)} />;
  }
  if (move.status === 'delivery arrived') {
    return <SummaryMoveItemRow label='Delivery Arrived' value={formattedDateTime(move.delivery_arrived)} />;
  }
  if (move.status === 'delivery started') {
    return <SummaryMoveItemRow label='Delivery Started' value={formattedDateTime(move.delivery_started)} />;
  }
  if (move.status === 'delivery successful') {
    return <SummaryMoveItemRow label='Delivery Successful' value={formattedDateTime(move.delivery_successful)} />;
  }
};
const SummaryMoveItemRow = ({ label, value }) => {
  return (
    <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
      <Grid item xs={4} wrap='nowrap'>
        <Typography variant='body2'>{label}:</Typography>
      </Grid>
      <Grid item xs={8} wrap='nowrap'>
        <Typography variant='body2' style={{ textAlign: 'right' }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SummaryMoveCard = ({ move, onClose }) => {
  const cls = useStyles();
  const { goToMoveDetails } = useTools();

  const goToMove = () => {
    goToMoveDetails(move.id);
    onClose();
  };

  return (
    <CardContent className={cls.moveItem}>
      <Grid container>
        <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
          <Grid item xs={3} wrap='nowrap'>
            <Button variant='contained' color='primary' onClick={goToMove}>
              #{move?.id}
            </Button>
          </Grid>
          {move?.active === 0 ? (
            <Grid item xs={2} wrap='nowrap'>
              <Typography variant='h6' style={{ color: 'red' }}>
                INACTIVE
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={7} wrap='nowrap' xw>
            <Typography variant='h6' style={{ textAlign: 'right' }}>
              VIN: {move?.vehicle_vin}
            </Typography>
          </Grid>
          {move?.pickup_workflow_data ? (
            <Grid item xs={12}>
              <SummaryMoveItemRow
                label={<b>{'Driver Entered VIN'}</b>}
                value={<b>{move.pickup_workflow_data['driver-vin']}</b>}
              />
            </Grid>
          ) : null}
          <hr className={cls.ttDivider} />
          <SummaryMoveItemRow label='Customer' value={move?.customer?.name} />
          <SummaryMoveItemRow label='Driver' value={move?.driver_name || 'none'} />
          <SummaryMoveItemRow
            label='Vehicle'
            value={formattedVehicleDescription(
              move?.vehicle_color,
              move?.vehicle_year,
              move?.vehicle_make,
              move?.vehicle_model
            )}
          />
          <hr className={cls.ttDivider} />
          <SummaryMoveItemRow label='Status' value={move?.status || 'unassigned'} />
          {/*The Timestamp row here will be dynamically generated based on the move's status*/}
          {dynamicTimestampRow(move)}
          <SummaryMoveItemRow label='Cancel Status' value={move?.cancel_status || 'uncanceled'} />
          {move?.cancel_status ? (
            <SummaryMoveItemRow label='Cancel Reason' value={move?.cancel_reason || 'uncanceled'} />
          ) : null}
          <hr className={cls.ttDivider} />
          <SummaryMoveItemRow label='Pickup Location' value={move?.lane?.pickup?.name} />
          <SummaryMoveItemRow label='Delivery Location' value={move?.lane?.delivery?.name} />
          <hr className={cls.ttDivider} />
          <SummaryMoveItemRow label='Created at' value={formattedDateTime(move?.createdat)} />
          <SummaryMoveItemRow label='Created by' value={move?.createdBy || 'N/A'} />
          {/* SLA row here */}
          {move?.sla ? (
            <>
              <hr className={cls.ttDivider} />
              <SummaryMoveItemRow label='SLA' value={formattedSlaDateTime(move?.createdat, move?.sla.duration_hrs)} />
            </>
          ) : null}
          {/* Railyard Status row here */}
          {move?.hangtags && move?.hangtags.length > 0 ? (
            <>
              <hr className={cls.ttDivider} />
              <SummaryMoveItemRow label='Hangtag Status' value={move?.hangtags[0].status} />
              <SummaryMoveItemRow label='Hangtag Number' value={move?.hangtags[0].rear_code} />
            </>
          ) : null}
        </Grid>
      </Grid>
    </CardContent>
  );
};

const useStyles = makeStyles(theme => ({
  moveItem: {
    width: '500px',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
  },

  ttDivider: {
    margin: '0',
    width: '100%',
    opacity: 0.5,
  },
}));

export default SummaryMoveCard;
