//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import { useInvoices } from './useInvoices';

import { InvoiceTableRow } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceTableBody(props) {
  const { rows, order, orderBy, page, rowsPerPage, handleARReviewModalOpen, handleARLogsModalOpen, handleRefetch } = props;
  const { getDefaultFoldId } = useInvoices();

  const [foldId, setFoldId] = React.useState(getDefaultFoldId());

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`invoice-row-${i}`}>
              <InvoiceTableRow
                row={row}
                index={i}
                foldId={foldId}
                setFoldId={setFoldId}
                handleARReviewModalOpen={handleARReviewModalOpen}
                handleARLogsModalOpen={handleARLogsModalOpen}
                handleRefetch={handleRefetch}
              />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}
