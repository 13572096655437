import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Icon, Typography, Tooltip } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import TopUpModal from '../../components/ManageTopUp/TopUpModal';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayFilter1({ type, start, end, onTypeChange, screenTotal, balanceAmount }) {
  const cls = useStyles();

  const { formatUSD } = useTools();

  const [topUpModal, setTopUpModal] = React.useState({ open: false, input: null, output: null });
  const handleTopUpModalOpen = (input = null) => {
    setTopUpModal({ open: true, input: input, output: null });
  };
  const handleTopUpModalClose = async (output = null) => {
    setTopUpModal({ open: false, input: null, output: output });
  };

  const handleType = newType => {
    if (onTypeChange) {
      if (newType === `due`) onTypeChange(`due`);
      else onTypeChange(`accruing`);
    }
  };

  return (
    <>
      <TopUpModal
        open={topUpModal.open}
        input={topUpModal.input}
        onClose={handleTopUpModalClose}
        balance={balanceAmount}
      />

      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <Tooltip title='Switch between driver pay DUE (up to the past 6 weeks) and driver pay ACCRUING (this week)'>
            <div>
              <Button
                className={cls.dueBtn}
                variant={type === `due` ? `contained` : `outlined`}
                size='large'
                onClick={() => handleType(`due`)}
              >
                <Icon className={cls.btnIcon}>history</Icon>
                Due
              </Button>

              <Button
                className={cls.accruingBtn}
                variant={type === `accruing` ? `contained` : `outlined`}
                size='large'
                onClick={() => handleType(`accruing`)}
              >
                <Icon className={cls.btnIcon}>event</Icon>
                Accruing
              </Button>
            </div>
          </Tooltip>
        </Grid>

        <Grid item />

        <Grid item>
          <Typography className={cls.dateTxt}>{dayjs(start).format(`MM/DD/YYYY`)}</Typography>
        </Grid>

        <Grid item>
          <Typography className={cls.toTxt}>TO</Typography>
        </Grid>

        <Grid item>
          <Typography className={cls.dateTxt}>{dayjs(end).format(`MM/DD/YYYY`)}</Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <div className={cls.totalBox}>
            <div className={cls.totalKeyBox}>
              <Typography className={cls.totalKeyTxt}>TOP UP BALANCE</Typography>
            </div>
            <Typography className={cls.totalValTxt}>{formatUSD(balanceAmount)}</Typography>
          </div>
        </Grid>

        <Grid item>
          <Grid item>
            <Button color='tertiary' size='large' onClick={() => handleTopUpModalOpen()}>
              <Icon className={cls.btnIcon}>currency_exchange</Icon>
              Top Up
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  dueBtn: {
    borderRadius: `4px 0 0 4px`,
  },
  accruingBtn: {
    borderRadius: `0 4px 4px 0`,
  },

  dateTxt: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  toTxt: {
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.disabled,
  },

  totalBox: {
    position: 'relative',
    minWidth: 116,
    padding: `11px 10px`,
    border: theme.border[0],
    borderRadius: 4,
  },
  totalKeyBox: {
    position: 'absolute',
    top: -5,
    left: 6,
    padding: `0 4px`,
    background: theme.palette.background.paper,
  },
  totalKeyTxt: {
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  totalValTxt: {
    zIndex: 11,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },

  line: {
    width: 1,
    height: 40,
    background: theme.palette.divider,
  },
}));
