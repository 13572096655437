//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  useTheme,
  makeStyles,
  Grid,
  Typography,
  Tooltip,
  InputBase,
  TextField,
  Icon,
  MenuItem,
  Button,
  Chip,
} from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import CustomSwitch from '../../components/CustomSwitch';
import { MoveVehicleModal } from './index';
import { MoveVehicleWeightModal } from './index';
import DriverAppVersionChip from '../../components/DriverAppVersionChip';
import vinValidate from '../../utils/vinValidation';

const rateClassOptions = [
  {
    name: 'stranded',
    value: 'stranded',
  },
  {
    name: 'base',
    value: 'base',
  },
];

//////////////////////// COMPONENT ////////////////////////

export default function MoveInfo(props) {
  const cls = useStyles();
  const theme = useTheme();
  const {
    goToMoveDetails,
    goToDriverDetails,
    capEach,
    getFormattedCombinedStatusFromMove,
    getFormattedVehicleFromMove,
    getInitialsFromName,
    getCleansedPhoneNumber,
    getWorkflowData,
    getCancelReasonFromMove,
    goToCustomerDetails,
  } = useTools();

  const { move, mainImageUrl, printMode, editMode, editVals, editSetVals } = props;
  const {
    active,
    autoAssign,
    chargeable,
    color,
    consumerName,
    consumerPhone,
    consumerPickup,
    dealerContact,
    lyftFlag,
    make,
    manualFlag,
    model,
    moveTemplate,
    notes,
    odometer,
    payable,
    rateClass,
    rateClassOverride,
    refNum,
    rideType,
    stock,
    vin,
    year,
    config,
    weight,
    vehicleWeightMetadata,
  } = editVals;
  const {
    setActive,
    setAutoAssign,
    setChargeable,
    setColor,
    setConsumerName,
    setConsumerPhone,
    setConsumerPickup,
    setDealerContact,
    setLyftFlag,
    setMake,
    setManualFlag,
    setModel,
    setMoveTemplate,
    setMoveType,
    setNotes,
    setOdometer,
    setPayable,
    setPriority,
    setRateClass,
    setRateClassOverride,
    setRefNum,
    setRideType,
    setStock,
    setVin,
    setYear,
    setConfig,
    setWeight,
    setVehicleWeightMetadata,
  } = editSetVals;

  // Pickup workflow data
  const pickupWorkflowData = getWorkflowData(`pickup`, move.pickup_workflow_data, `move_details`, true);

  const pickupWorkflowEven = [];
  const pickupWorkflowOdd = [];

  pickupWorkflowData &&
    pickupWorkflowData.length &&
    pickupWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) pickupWorkflowEven.push(wd);
        else pickupWorkflowOdd.push(wd);
      });

  // Delivery workflow data
  const deliveryWorkflowData = getWorkflowData(`delivery`, move.delivery_workflow_data, `move_details`, true);

  const deliveryWorkflowEven = [];
  const deliveryWorkflowOdd = [];

  deliveryWorkflowData &&
    deliveryWorkflowData.length &&
    deliveryWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) deliveryWorkflowEven.push(wd);
        else deliveryWorkflowOdd.push(wd);
      });

  const [vehicleModal, setVehicleModal] = React.useState({ open: false });
  const handleVehicleModalOpen = (input = null) => {
    setVehicleModal({ open: true, input: input });
  };
  const handleVehicleModalClose = (output = null) => {
    setVehicleModal({ ...vehicleModal, open: false });
    if (output) {
      toast.info(
        `Vehicle - ${output.year || `<year>`} ${output.make || `<make>`} ${output.model || `<model>`} (${
          output.color || `<color>`
        })`
      );
      setYear(output.year);
      setMake(output.make);
      setModel(output.model);
      setColor(output.color);
    }
  };

  const [vehicleWeightModal, setVehicleWeightModal] = React.useState({ open: false });
  const [vehicleWeight, setVehicleWeight] = React.useState(null);

  React.useEffect(() => {
    if (move?.config?.dispatcherProvidedWeight) {
      setVehicleWeight(move.config.dispatcherProvidedWeight);
    } else if (move && move.pickupWorkflowData && move.pickupWorkflowData['vehicle-weight']) {
      setVehicleWeight(move.pickupWorkflowData['vehicle-weight']);
    } else if (move && move.deliveryWorkflowData && move.deliveryWorkflowData['vehicle-weight']) {
      setVehicleWeight(move.deliveryWorkflowData['vehicle-weight']);
    } else if (move && move.vehicle_gross_weight_lbs) {
      setVehicleWeight(move.vehicle_gross_weight_lbs);
    }
  }, [move]);

  const handleVehicleWeightModalOpen = (input = null) => {
    setVehicleWeightModal({ open: true, input: input });
  };
  const handleVehicleWeightModalClose = (output = null) => {
    setVehicleWeightModal({ ...vehicleWeightModal, open: false });
    if (output) {
      console.log('output', output);
      toast.info(`New Vehicle Weight Set: ${output.weight}`);
      setConfig(output.config);
      setVehicleWeightMetadata(output.eventlogMetadata);
    }
  };

  React.useEffect(() => {
    setActive(move.active === 1 ? true : false);
    setAutoAssign(move.auto_assign === 1 ? true : false);
    setChargeable(move.chargeable);
    setColor(move.vehicle_color || ``);
    setConsumerName(move.consumer_name || ``);
    setConsumerPhone(move.consumer_phone || ``);
    setConsumerPickup(move.consumer_pickup);
    setDealerContact(move.dealer_contact || ``);
    setLyftFlag(move.lyft_flag === 1 ? true : false);
    setMake(move.vehicle_make || ``);
    setManualFlag(move.manual_flag);
    setModel(move.vehicle_model || ``);
    setMoveTemplate(move.pickup_template_override || ``);
    setMoveType(move.move_type || ``);
    setNotes(move.move_details || ``);
    setOdometer(move.vehicle_odometer || ``);
    setPayable(move.payable);
    setPriority(move.priority || 10);
    setRateClass(move.class || ``);
    setRateClassOverride(move.rate_class_override === 1 ? true : false);
    setRefNum(move.reference_num || ``);
    setRideType(move.ride_type || ``);
    setStock(move.vehicle_stock || ``);
    setVin(move.vehicle_vin || ``);
    setYear(move.vehicle_year || ``);
    setWeight(move.vehicle_gross_weight_lbs || ``);
    setConfig(move.config || null);
    // eslint-disable-next-line

    if (move?.config?.dispatcherProvidedWeight) {
      setVehicleWeight(move.config.dispatcherProvidedWeight);
    } else if (move && move.pickupWorkflowData && move.pickupWorkflowData['vehicle-weight']) {
      setVehicleWeight(move.pickupWorkflowData['vehicle-weight']);
    } else if (move && move.deliveryWorkflowData && move.deliveryWorkflowData['vehicle-weight']) {
      setVehicleWeight(move.deliveryWorkflowData['vehicle-weight']);
    } else if (move && move.vehicle_gross_weight_lbs) {
      setVehicleWeight(move.vehicle_gross_weight_lbs);
    }
  }, [editMode, move]);

  const handleSwitch = (prev, setHandler) => {
    if (setHandler) {
      if (prev) setHandler(false);
      else setHandler(true);
    }
  };
  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };
  //UNUSED
  // const handlePriorityChange = event => {
  //   let input = 9;

  //   if (event.key) input = Number(event.key);
  //   else if (event.target && event.target.value) input = Number(event.target.value);

  //   if (input >= 1 && input <= 9) setPriority(input);
  // };

  const handleVehicleEdit = () => {
    handleVehicleModalOpen({
      make: { name: make, vehiclemodels: [] },
      model: { name: model },
      year: year,
      color: color,
    });
  };

  const handleVehicleWeightEdit = () => {
    handleVehicleWeightModalOpen({
      weight: move.vehicle_gross_weight_lbs ? move.vehicle_gross_weight_lbs : null,
      config: config || null,
      pickupWorkflow: move.pickup_workflow_data || null,
      deliveryWorkflow: move.delivery_workflow_data || null,
    });
  };

  const isVinValid = vin => vinValidate(vin);

  //Renders multiple links if the childMove relationship is present- otherwise will fall back to using te moveByReturnRideId relationship
  const RelatedMoveButtons = move => {
    const { childMoves, moveByReturnRideId, parent_move_id, parentMove } = move;

    let relatedMoves = [];

    if (parentMove && parentMove.active && parentMove.cancel_status !== 'canceled') relatedMoves.push(parentMove);

    if (childMoves && childMoves.length > 0) {
      childMoves.forEach(cm => {
        if (cm.move_type === 'ride' && cm.cancel_status === null) relatedMoves.push(cm);
        if (cm.move_type === 'drive' && cm.cancel_status !== 'canceled') relatedMoves.push(cm);
      });
    } else if (moveByReturnRideId && moveByReturnRideId.active && moveByReturnRideId.cancel_status !== 'canceled')
      relatedMoves.push(moveByReturnRideId);

    if (relatedMoves && relatedMoves.length > 0) {
      return (
        <div className={cls.valTxt}>
          {relatedMoves.map(relatedMove => (
            <Button
              key={`related-move-btn-${relatedMove.id}`}
              variant='outlined'
              size='small'
              color='primary'
              style={{ maxHeight: '20px', marginLeft: '5px' }}
              onClick={() => {
                goToMoveDetails(relatedMove.id);
              }}
            >
              {relatedMove.id}
            </Button>
          ))}
        </div>
      );
    } else {
      return <div className={cls.valTxt}>-</div>;
    }
  };

  const GetMoveStyle = () => {
    const isConciergeOrLoaner = move.consumer_type ? true : false;
    const isConcierge = isConciergeOrLoaner && move.consumer_type === 'customer';
    const isLoaner = isConciergeOrLoaner && move.consumer_type === 'loaner';
    return (
      <>
        {move &&
          isConcierge &&
          move.moveByReturnRideId &&
          move.moveByReturnRideId.consumer_type &&
          move.moveByReturnRideId.consumer_type === 'loaner' && (
            <div>
              Concierge +{' '}
              <Button
                variant='outlined'
                size='small'
                color='primary'
                style={{ maxHeight: '20px' }}
                onClick={() => {
                  goToMoveDetails(move.return_ride_id);
                }}
              >
                Loaner
              </Button>
            </div>
          )}
        {move && isConcierge && <div>Concierge</div>}
        {move && isLoaner && <div>Loaner</div>}
        {/* 10/19/22 - Commented this out because I don't understand why it was specifying "Stranded" */}
        {/* {move &&
          !move.consumer_pickup &&
          !move.moveByReturnRideId &&
          move.chargeable &&
          move.tags &&
          !move.tags.includes('loaner') && <div>Stranded Move (not concierge)</div>} */}
      </>
    );
  };

  return (
    <>
      <MoveVehicleWeightModal
        open={vehicleWeightModal.open}
        onClose={handleVehicleWeightModalClose}
        input={vehicleWeightModal.input}
      />
      <MoveVehicleModal open={vehicleModal.open} onClose={handleVehicleModalClose} input={vehicleModal.input} />

      <div className={cls.main}>
        {!printMode ? (
          <div className={cls.info}>
            <Typography className={cls.infoTxt}>VEHICLE</Typography>
            <div className={cls.smallBreak} />
            <Tooltip title={getFormattedVehicleFromMove(move)}>
              {mainImageUrl ? (
                <img className={cls.infoImage} src={mainImageUrl} alt='vehicle' />
              ) : (
                <div className={cls.infoImage}>
                  <Icon className={cls.infoNoVehicleIcon}>drive_eta</Icon>
                </div>
              )}
            </Tooltip>
          </div>
        ) : null}

        <div className={printMode ? cls.paperPrint : cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip placement='top-start' title='Move ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                  {move.id ? `Move #${move.id}` : `No Move ID`}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top' title='Move Date'>
                <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                  {dayjs(move.pickup_time).format(`M/D/YYYY`)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top-end' title='Move Status'>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                  {getFormattedCombinedStatusFromMove(move)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <div className={cls.break} />

          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <Typography
                    className={cls.valTxt}
                    style={{ background: '#00000010', color: theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => handleVehicleEdit()}
                  >
                    Click to edit vehicle
                  </Typography>
                ) : (
                  <Typography className={cls.valTxt}>{getFormattedVehicleFromMove(move)}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>
                  {editMode ? `Manual\xa0Flag` : `Transmission`}:&nbsp;&nbsp;
                </Typography>
                {editMode ? (
                  <CustomSwitch
                    editMode={editMode}
                    checked={manualFlag}
                    onChange={() => handleSwitch(manualFlag, setManualFlag)}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.manual_flag ? `Manual` : `Automatic`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Odometer:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    type='number'
                    min='0'
                    value={odometer}
                    onChange={handleInputChange(setOdometer)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>
                    {move.vehicle_odometer ? move.vehicle_odometer + ' miles' : '-'}
                  </Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={stock}
                    onChange={handleInputChange(setStock)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.vehicle_stock || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={vin}
                    onChange={handleInputChange(setVin)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={[cls.valTxt, cls.vinContainer]}>
                    <Icon className={cls.vinValidate}>
                      {isVinValid(move.vehicle_vin) ? (
                        <Tooltip title='VIN is valid.'>
                          <span className={cls.validVin}>check_circle</span>
                        </Tooltip>
                      ) : (
                        <Tooltip title='VIN may be invalid! Please double check with the customer.'>
                          <span className={cls.invalidVin}>help</span>
                        </Tooltip>
                      )}
                    </Icon>

                    {move.vehicle_vin || `-`}
                  </Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={refNum}
                    onChange={handleInputChange(setRefNum)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.reference_num || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Gross&nbsp;Vehicle&nbsp;Weight&nbsp;(lbs)</Typography>
                {editMode ? (
                  <Typography
                    className={cls.valTxt}
                    style={{ background: '#00000010', color: theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => handleVehicleWeightEdit()}
                  >
                    Click to edit vehicle weight
                  </Typography>
                ) : (
                  <Typography className={cls.valTxt}>{vehicleWeight || '-'}</Typography>
                )}
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
                <Button className={cls.link} onClick={() => goToDriverDetails(move?.driver_id)}>
                  <Typography className={cls.link}>{move.driver_name || `HopDriver`}</Typography>
                </Button>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
                <Button className={cls.link} onClick={() => goToCustomerDetails(move?.customer?.id)}>
                  <Typography className={cls.link}>{move?.customer?.name || `Unknown Dealer`}</Typography>
                </Button>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Payer:&nbsp;&nbsp;</Typography>
                <Button className={cls.link} onClick={() => goToCustomerDetails(move?.payer?.id)}>
                  <Typography className={cls.link}>{move?.payer?.name || `-`}</Typography>
                </Button>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.lane && move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer&nbsp;Contact:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <InputBase
                    value={dealerContact}
                    onChange={handleInputChange(setDealerContact)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.dealer_contact || `-`}</Typography>
                )}
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <TextField
                    variant='filled'
                    multiline
                    InputProps={{ style: { padding: 5 } }}
                    value={notes}
                    onChange={handleInputChange(setNotes)}
                    className={cls.nakedValTxt}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.move_details || `-`}</Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {!printMode ? (
          <div className={cls.info}>
            <Typography className={cls.infoTxt}>DRIVER</Typography>
            <div className={cls.smallBreak} />
            <Tooltip
              title={
                getPropValue(move, `driver.user.display_name`)
                  ? move.driver.user.display_name
                  : move.driver_name
                  ? move.driver_name
                  : `HopDriver`
              }
            >
              <div
                className={cls.infoDriver}
                onClick={() =>
                  move.driver_id ? goToDriverDetails(move.driver_id) : toast.warning(`No driver assigned!`)
                }
              >
                {getPropValue(move, `driver.user.avatar_url`) ? (
                  <img className={cls.infoImage} src={move.driver.user.avatar_url} alt='driver' />
                ) : (
                  <div className={cls.infoImage}>
                    <Typography className={cls.infoNoDriverTxt}>{getInitialsFromName(move)}</Typography>
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
        ) : null}
      </div>

      {!printMode && move?.sla ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Selected&nbsp;SLA:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>
                      {move?.sla?.label} ({move?.sla?.duration_hrs}
                      {move?.sla?.duration_hrs === 1 ? `hr` : `hrs`})
                    </Typography>
                  </div>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Deadline:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>
                      {move?.deliver_by ? dayjs(move.deliver_by).format(`M/D/YYYY h:mm A z`) : `-`}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}

      {!printMode ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Move&nbsp;Type:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{move.move_type ? capEach(move.move_type) : `-`}</Typography>
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Ride&nbsp;Type:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={rideType}
                        onChange={handleInputChange(setRideType)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>{move.ride_type ? capEach(move.ride_type) : `-`}</Typography>
                    )}
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Template&nbsp;Override:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={moveTemplate}
                        onChange={handleInputChange(setMoveTemplate)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>
                        {move.pickup_template_override ? capEach(move.pickup_template_override) : `-`}
                      </Typography>
                    )}
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Rate&nbsp;Class:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <TextField
                        select
                        placeholder='Select rate class...'
                        value={rateClass}
                        onChange={handleInputChange(setRateClass)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      >
                        {rateClassOptions.map((r, i) => (
                          <MenuItem key={`rate-class-${i}`} value={r.value}>
                            <Typography className={cls.valTxt}>{r.name}</Typography>
                            <Typography className={cls.valTxt}>{r.desc}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <Typography className={cls.valTxt}>{move.class ? capEach(move.class) : `-`}</Typography>
                    )}
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Related&nbsp;Moves:&nbsp;&nbsp;</Typography>
                    {RelatedMoveButtons(move)}
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Move&nbsp;Style:&nbsp;&nbsp;</Typography>
                    <div className={cls.valTxt}>
                      <GetMoveStyle />
                    </div>
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Driver&nbsp;App&nbsp;Version:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>
                      <DriverAppVersionChip version={move.driver_app_version} />
                    </Typography>
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Driver/Plan&nbsp;Status:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>
                      <Chip
                        label={move.driver_status ? capEach(move.driver_status) : `-`}
                        size={'small'}
                        color={move.driver_status === 'draft' ? 'primary' : `secondary`}
                      />
                    </Typography>
                  </div>
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Active:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? active : move.active === 1 ? true : false}
                      onChange={() => handleSwitch(active, setActive)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Chargeable:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? chargeable : move.chargeable}
                      onChange={() => handleSwitch(chargeable, setChargeable)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Payable:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? payable : move.payable}
                      onChange={() => handleSwitch(payable, setPayable)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Auto&nbsp;Assign:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? autoAssign : move.auto_assign === 1 ? true : false}
                      onChange={() => handleSwitch(autoAssign, setAutoAssign)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Lyft&nbsp;Flag:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? lyftFlag : move.lyft_flag === 1 ? true : false}
                      onChange={() => handleSwitch(lyftFlag, setLyftFlag)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Rate&nbsp;Class&nbsp;Override:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? rateClassOverride : move.rate_class_override === 1 ? true : false}
                      onChange={() => handleSwitch(rateClassOverride, setRateClassOverride)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Pickup:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      editMode={editMode}
                      checked={editMode ? consumerPickup : move.consumer_pickup}
                      onChange={() => handleSwitch(consumerPickup, setConsumerPickup)}
                    />
                  </div>
                  <div className={cls.lineBreak} />
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Has&nbsp;Loaner:&nbsp;&nbsp;</Typography>
                    <CustomSwitch
                      disabled={true}
                      checked={
                        move.moveByReturnRideId &&
                        move.moveByReturnRideId.tags &&
                        move.moveByReturnRideId.consumer_type === 'loaner'
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}

      {(!editMode && move.consumer_pickup) || (editMode && consumerPickup) ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Name:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={consumerName}
                        onChange={handleInputChange(setConsumerName)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>{move.consumer_name || `-`}</Typography>
                    )}
                  </div>
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Phone:&nbsp;&nbsp;</Typography>
                    {editMode ? (
                      <InputBase
                        value={consumerPhone}
                        onChange={handleInputChange(setConsumerPhone)}
                        className={cls.nakedValTxt}
                        inputProps={{ className: cls.nakedValInput }}
                      />
                    ) : (
                      <Typography className={cls.valTxt}>
                        {getCleansedPhoneNumber(move.consumer_phone) || `-`}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      {move.cancel_status ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Cancel&nbsp;Reason:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{getCancelReasonFromMove(move)}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      {pickupWorkflowData && pickupWorkflowData.length ? (
        <>
          <div className={cls.break} />
          <div className={cls.break} />

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <Divide tip='View extra workflow data found on the pickup'>Pickup Workflow Data</Divide>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {pickupWorkflowOdd.map((wd, i) => (
                    <React.Fragment key={`move-details-pickup-workflow-odd-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < pickupWorkflowOdd.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  {pickupWorkflowEven.map((wd, i) => (
                    <React.Fragment key={`move-details-pickup-workflow-even-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < pickupWorkflowEven.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </div>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      {deliveryWorkflowData && deliveryWorkflowData.length ? (
        <>
          <div className={cls.break} />
          <div className={cls.break} />

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <Divide tip='View extra workflow data found on the delivery'>Delivery Workflow Data</Divide>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>

          <div className={cls.main}>
            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}

            <div className={printMode ? cls.paperPrint : cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {deliveryWorkflowOdd.map((wd, i) => (
                    <React.Fragment key={`move-details-delivery-workflow-odd-${i}`}>
                      <div>
                        {Array.isArray(wd.val) ? (
                          wd.val.map((val, index) => (
                            <div key={index} className={cls.listItem}>
                              <Typography className={cls.keyTxt}>
                                {wd.key}&nbsp;&nbsp;{index + 1}:
                              </Typography>
                              <Typography className={cls.valTxt}>{`${val}`}</Typography>
                            </div>
                          ))
                        ) : (
                          <div className={cls.listItem}>
                            <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                            <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                          </div>
                        )}
                      </div>
                      {i < deliveryWorkflowOdd.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  {deliveryWorkflowEven.map((wd, i) => (
                    <React.Fragment key={`move-details-delivery-workflow-even-${i}`}>
                      <div>
                        {Array.isArray(wd.val) ? (
                          wd.val.map((val, index) => (
                            <div key={index} className={cls.listItem}>
                              <Typography className={[cls.keyTxt, cls.fuelTxt]}>
                                {wd.key}&nbsp;&nbsp;{index + 1}:
                              </Typography>
                              <Typography className={cls.valTxt}>{`${val}`}</Typography>
                            </div>
                          ))
                        ) : (
                          <div className={cls.listItem}>
                            <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                            <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                          </div>
                        )}
                      </div>
                      {i < deliveryWorkflowEven.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </div>

            {!printMode ? (
              <div className={cls.info}>
                <div className={cls.infoSpacer} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    display: 'block',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoDriver: {
    cursor: 'pointer',
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  infoImage: {
    position: 'relative',
    display: 'block',
    verticalAlign: 'top',
    objectFit: 'cover',
    width: '128px',
    height: '128px',
    borderRadius: '50%',
    background: theme.palette.fade[3],
    boxShadow: theme.shadow.soft,
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
  },
  infoNoVehicleIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: '80px',
    cursor: 'default',
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 700,
    cursor: 'default',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  paperPrint: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[1],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  list: {},
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  fuelTxt: {
    display: 'inline',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  input: {
    margin: 0,
  },
  btn: {
    verticalAlign: 'top',
    minHeight: '40px',
    maxHeight: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: theme.palette.text.disabled,
    },
  },
  tags: {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tag: {
    marginRight: theme.spacing(1),
    color: `#fff`,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    background: theme.palette.primary.main,
  },
  tagLigt: {
    marginRight: theme.spacing(1),
    color: `#fff`,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    background: theme.palette.secondary.light,
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  tinyBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },

  vinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  vinValidate: {
    fontSize: 18,
    marginTop: -1,
    marginRight: 6,
  },

  validVin: {
    color: theme.palette.success.main,
  },
  invalidVin: {
    color: theme.palette.warning.main,
  },
  link: {
    padding: 0,
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));
