//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Tooltip, Chip, Icon, Button } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import sdk from '@hopdrive/sdk';
import { useData } from '../../providers/DataProvider';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';

//////////////////////// COMPONENT ////////////////////////

function MoveAccessorials({ accessorials = [], move = {}, ...props }) {
  const ctx = useData();

  const cls = useStyles();
  const { capFirst, checkNeg } = useTools();

  const [fuelMultiplier, setFuelMultiplier] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchConfig = async () => {
      const customerConfig = await sdk.configs.getCustomerConfig(move.customer.id);
      setFuelMultiplier(customerConfig.fuel_multiplier);
    };
    fetchConfig();
  }, [move]);

  const handleApproveAccessorial = id => {
    setLoading(true);
    ctx.apolloClient
      .mutate({
        mutation: gql`
          mutation approveAccessorial($id: bigint!) {
            update_accessorials(where: { id: { _eq: $id } }, _set: { status: "approved" }) {
              affected_rows
            }
          }
        `,
        variables: { id },
      })
      .then(res => {
        if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
          toast.error('Failed to approve accessorial.');
        } else {
          toast.success(`Approved accessorial!`);
          setLoading(false);
        }
      })
      .catch(err => {
        toast.error(`Failed to approve accessorial!`);
        console.error(`Failed to approve accessorial:`, err);
        setLoading(false);
      });
  };

  return (
    <>
      {accessorials.map(accessorial => {
        let authorization = (accessorial && accessorial.authorization) || {};
        return (
          <div key={`accessorial-${accessorial.id}`}>
            <div className={cls.paper}>
              <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                <Grid item xs>
                  <Tooltip placement='top-start' title='Accessorial ID'>
                    <Typography className={cls.headTxtL}>
                      #{accessorial.id}&nbsp;-&nbsp;{capFirst(accessorial.code)}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  {(accessorial.status === 'pending' && accessorial.code === 'reimbursement') ||
                  (accessorial.status === 'pending' && accessorial.code === 'fuel' && !accessorial.authorization) ? (
                    <Button
                      onClick={() => handleApproveAccessorial(accessorial.id)}
                      color='secondary'
                      variant='contained'
                      disabled={loading || move.settled}
                      className={cls.btnApprove}
                    >
                      Approve
                    </Button>
                  ) : null}

                  <Tooltip placement='top-end' title='Accessorial Status'>
                    <Chip
                      variant={accessorial.status === 'approved' ? 'default' : 'outlined'}
                      icon={
                        <Icon fontSize='small'>{accessorial.status === 'approved' ? 'done' : 'monetization_on'}</Icon>
                      }
                      size='small'
                      color={accessorial.status === 'pending' ? 'primary' : 'secondary'}
                      label={capFirst(accessorial.status)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <div className={cls.break} />
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <Typography className={cls.subheadTxt}>Accessorial Info</Typography>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Cost:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{'$' + (accessorial.cost || 0).toFixed(2)}</Typography>
                  </div>
                  {authorization.id ? (
                    <>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Max&nbsp;Charge:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {'$' + (authorization.max_charge || 0).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Authorization&nbsp;ID:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.id}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Swipe&nbsp;Attempts:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{authorization.auth_attempts}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Valid&nbsp;Window:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {authorization.valid_from
                            ? `${dayjs(authorization.valid_from).format('h:mm A')} – ${dayjs(
                                authorization.valid_to
                              ).format('h:mm A')}`
                            : ''}
                        </Typography>
                      </div>
                    </>
                  ) : null}
                </Grid>
                <div className={cls.hiddenLineBreak} />
                <Grid item sm={4} xs={12}>
                  {authorization.id ? (
                    <>
                      <Typography className={cls.subheadTxt}>Automated Pay Calculations</Typography>
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Customer&nbsp;Price</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Accessorial&nbsp;Cost:</Typography>
                            <Typography className={cls.detailNotesTxt}>Customer&nbsp;Fuel&nbsp;Multiplier:</Typography>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(accessorial.cost || 0).toFixed(2)}
                            </Typography>
                            <Typography className={cls.detailAmountTxt}>x{fuelMultiplier}</Typography>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(accessorial.ar_amount || 0).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Driver&nbsp;Pay</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>
                              Driver&nbsp;Pay&nbsp;Per&nbsp;Minute:
                            </Typography>
                            <Typography className={cls.detailNotesTxt}>Minutes&nbsp;at&nbsp;Fuel&nbsp;Pump:</Typography>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(move.lane.driver_pay_per_minute || 0).toFixed(2)}
                            </Typography>
                            <Typography className={cls.detailAmountTxt}>x5</Typography>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(accessorial.ap_amount || 0).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography className={cls.subheadTxt}>Pay Info</Typography>
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Customer&nbsp;Price</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(accessorial.ar_amount || 0).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.block}>
                        <Typography className={cls.detailNameTxt}>Driver&nbsp;Pay</Typography>
                        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                          <Grid item xs>
                            <Typography className={cls.detailNotesTxt}>Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={cls.detailAmountTxt}>
                              ${checkNeg(accessorial.ap_amount || 0).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={4} xs={12}>
                  <Typography className={cls.subheadTxt}>Notes</Typography>
                  <div className={cls.block}>
                    <Typography className={cls.keyTxt}>Accessorial&nbsp;Notes:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{accessorial.notes}</Typography>
                  </div>
                  {authorization.id ? (
                    <>
                      <div className={cls.lineBreak} />
                      <div className={cls.block}>
                        <Typography className={cls.keyTxt}>Last&nbsp;Decline&nbsp;Reason:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {authorization.last_declined_reason
                            ? `${dayjs(authorization.last_declined_at).format('MMM D, YYYY')}: ${
                                authorization.last_declined_reason
                              }`
                            : ''}
                        </Typography>
                      </div>
                    </>
                  ) : null}
                </Grid>
                <div className={cls.hiddenBreak} />
              </Grid>
            </div>
            <div className={cls.break} />
          </div>
        );
      })}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  block: {
    display: 'block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  btnApprove: {
    marginRight: theme.spacing(1),
    padding: '2px 8px',
    fontSize: 12,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveAccessorials;
