//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';

import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import CustomerAddToolbar from './CustomerAddToolbar';
import CustomerAddForm from './CustomerAddForm';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerAdd(props) {
  const cls = useStyles();

  const { _sdk } = useData();
  const { goToPreviousPage, goToCustomerDetails, goToCustomerAdd, condensedCase, getAddressComponents } = useTools();

  const orgId = Number(props?.match?.params?.id);
  const singleOrg = orgId ? true : false;

  const [formCategory, setFormCategory] = React.useState(``);
  const [formLocation, setFormLocation] = React.useState(null);
  const [formName, setFormName] = React.useState(``);
  const [formOrganizationId, setFormOrganizationId] = React.useState(orgId || 0);

  const [isSaving, setIsSaving] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState({});

  const orgQuery = orgId ? GET_ORGANIZATION : GET_ORGANIZATIONS;
  const { loading, error, data } = useQuery(orgQuery, {
    variables: {
      organizationId: orgId,
    },
  });
  const [insertCustomerAndLocation] = useMutation(INSERT_CUSTOMER_AND_LOCATION);
  const [updateLocationCustomerId] = useMutation(UPDATE_LOCATION_CUSTOMER_ID);

  const checkIsFormValid = organization => {
    let isValid = true;
    let newValidationErrors = {};

    if (!formCategory) {
      isValid = false;
      newValidationErrors.formCategory = `Category must be selected!`;
    }
    if (!formLocation) {
      isValid = false;
      newValidationErrors.formLocation = `Location must be found on Google!`;
    }
    if (!formName || (organization?.name && condensedCase(formName) === condensedCase(organization?.name))) {
      isValid = false;
      newValidationErrors.formName = `Name is required and must be unique from the organization name!`;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  // Insert the customer and location together
  const createCustomerAndLocation = async () => {
    try {
      // Build the customer object
      let customerVars = {
        address: formLocation?.address || null,
        billing_frequency: `monthly`,
        category: formCategory || null,
        config: {},
        name: formName || null,
        organization_id: formOrganizationId || null,
      };

      // Build the location object
      let locationVars = {
        address: formLocation?.address || null,
        favorite: true,
        latitude: formLocation?.latitude || null,
        longitude: formLocation?.longitude || null,
        name: formLocation?.name || null,
        place_id: formLocation?.place_id || null,
        type: `customer`,
      };

      // Find the region ID and apply it to the location
      const foundRegionRes = await _sdk.regions.getByCoords([locationVars.longitude, locationVars.latitude]);
      const foundRegionId = foundRegionRes?.data && foundRegionRes?.data?.length ? foundRegionRes?.data?.[0]?.id : null;
      locationVars.region_id = foundRegionId;

      // Build the individual address pieces and apply them to the location
      const locationPieces = await getAddressComponents(locationVars?.address);
      locationVars.address_line_one = locationPieces?.address_line_one || null;
      locationVars.address_line_two = locationPieces?.address_line_two || null;
      locationVars.city = locationPieces?.city || null;
      locationVars.state = locationPieces?.state || null;
      locationVars.timezone = locationPieces?.timezone || null;
      locationVars.zip_code = locationPieces?.zip_code || null;

      // Build the insertable customer object with the location
      const insertableCustomer = {
        ...customerVars,
        location: {
          data: locationVars,
          on_conflict: {
            constraint: `idx_25692_primary`,
            update_columns: [
              `address`,
              `address_line_one`,
              `address_line_two`,
              `city`,
              `customer_id`,
              `favorite`,
              `latitude`,
              `longitude`,
              `name`,
              `nickname`,
              `place_id`,
              `region_id`,
              `state`,
              `timezone`,
              `type`,
              `zip_code`,
            ],
          },
        },
      };

      // Insert the customer and location together
      let newCustomer;
      const insertRes = await insertCustomerAndLocation({ variables: { insertableCustomer: insertableCustomer } });
      if (insertRes?.data?.insert_customers_one) {
        newCustomer = insertRes?.data?.insert_customers_one;
      }

      // Update the customer_id on the location
      const locationId = newCustomer?.location?.id;
      const customerId = newCustomer?.id;
      if (locationId && customerId) {
        const updateRes = await updateLocationCustomerId({ variables: { locationId, customerId } });
        if (updateRes?.data?.update_locations?.[0]) {
          newCustomer.location.customer_id = updateRes?.data?.update_locations?.[0]?.customer_id;
        }
      }

      // Return the customer & nested location
      toast.success(`Customer created!`);
      return newCustomer;
    } catch (err) {
      console.error(`Error creating customer & location:`, err);
      toast.error(`Error creating customer & location!`);
      return null;
    }
  };

  const handleCancel = () => {
    goToPreviousPage();
  };

  const handleSubmit = async organization => {
    setIsSaving(true);

    const isFormValid = checkIsFormValid(organization);
    if (isFormValid) {
      const customer = await createCustomerAndLocation();
      if (customer?.id) goToCustomerDetails(customer?.id);
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  const handleSubmitAndAddACustomer = async organization => {
    setIsSaving(true);

    const isFormValid = checkIsFormValid(organization);
    if (isFormValid) {
      const customer = await createCustomerAndLocation();
      if (customer?.id) {
        if (formOrganizationId === orgId) window.location.reload();
        else if (formOrganizationId) goToCustomerAdd(formOrganizationId);
        else goToCustomerAdd();
      }
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title={orgId ? [`#${orgId}`, `Add Customer`] : `Add Customer`} back />

        <DefaultLoadingFallback message='FETCHING ORGANIZATIONS' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching organizations:`, error);
    Sentry.captureException(error);
  }

  // DATA STATE //
  const organizations = data?.organizations || [];
  const organization = singleOrg && data?.organizations?.length === 1 ? data?.organizations?.[0] : null;

  return (
    <div className={cls.root}>
      <Toolbar
        fullscreen
        title={
          singleOrg && organization?.name
            ? [organization?.name, `Add Customer`]
            : orgId
            ? [`#${orgId}`, `Add Customer`]
            : `Add Customer`
        }
        back
      >
        <CustomerAddToolbar
          organizations={organizations}
          organization={organization}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onSubmitAndAddACustomer={handleSubmitAndAddACustomer}
          isSaving={isSaving}
        />
      </Toolbar>

      {!singleOrg || (singleOrg && organization?.id) ? (
        <>
          <Spacer size='xl' />
          <CustomerAddForm
            organizations={organizations}
            formCategory={formCategory}
            onFormCategoryChange={setFormCategory}
            formLocation={formLocation}
            onFormLocationChange={setFormLocation}
            formName={formName}
            onFormNameChange={setFormName}
            formOrganizationId={formOrganizationId}
            onFormOrganizationIdChange={setFormOrganizationId}
            isSaving={isSaving}
            validationErrors={validationErrors}
          />
          <Spacer size='xl' />
        </>
      ) : (
        <DefaultEmptyFallback message='ORGANIZATION DOES NOT EXIST' />
      )}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.background.paper,
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_ORGANIZATION = gql`
  query admin_getOrganization($organizationId: bigint!) {
    organizations(where: { id: { _eq: $organizationId } }) {
      id
      category
      name
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query admin_getOrganizations {
    organizations(order_by: { name: asc }) {
      id
      category
      name
    }
  }
`;

const INSERT_CUSTOMER_AND_LOCATION = gql`
  mutation admin_insertCustomerAndLocation($insertableCustomer: customers_insert_input!) {
    insert_customers_one(
      object: $insertableCustomer
      on_conflict: {
        constraint: idx_21595_primary
        update_columns: [billing_frequency, category, config, location_id, name, organization_id]
      }
    ) {
      id
      billing_frequency
      category
      location_id
      name
      organization_id
      location {
        id
        address
        address_line_one
        address_line_two
        city
        customer_id
        favorite
        latitude
        longitude
        name
        nickname
        place_id
        region_id
        state
        timezone
        type
        zip_code
      }
    }
  }
`;

const UPDATE_LOCATION_CUSTOMER_ID = gql`
  mutation admin_updateLocation($locationId: bigint!, $customerId: bigint!) {
    update_locations(where: { id: { _eq: $locationId } }, _set: { customer_id: $customerId }) {
      affected_rows
      returning {
        id
        customer_id
      }
    }
  }
`;
