import React from 'react';
import { makeStyles, Grid, Typography, Icon, Divider } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import LaunchPadSection from './LaunchPadSection';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function LaunchPadGroup({ index, group, refetch }) {
  const cls = useStyles();

  const [isApproving, setIsApproving] = React.useState(false);

  return (
    <div className={cls.paper}>
      <div className={cls.headBar}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <Typography className={cls.headTxt}>{group.label || `LaunchPad Group ${index + 1}`}</Typography>
          </Grid>

          <Grid item>
            <Button
              loading={isApproving}
              disabled={!group.approve || !group.canApprove() || isApproving}
              color='primary'
              onClick={() => {
                if (group.approve && group.canApprove())
                  group.approve(() => {
                    setIsApproving(false);
                    refetch();
                  });
              }}
            >
              {group.isApproved() ? <Icon className={cls.btnIcon}>check_circle</Icon> : null}
              {group.isApproved() ? `Approved` : `Approve`}
            </Button>
          </Grid>
        </Grid>
      </div>

      {group.sections && group.sections.length
        ? group.sections.map((s, i) => (
            <React.Fragment key={`launchpad-group-${index}-section-${i}`}>
              {i > 0 && !s.hide ? <Divider /> : null}
              <LaunchPadSection index={i} group={group} section={s} />
            </React.Fragment>
          ))
        : null}
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headBar: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));
