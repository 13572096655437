//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';

import { MoveTileDrive, MoveTileRide } from '../index';
import { useScheduler } from '../providers/SchedulerProvider';

//////////////////////// COMPONENT ////////////////////////

export default function TimelineAreaRow({ enrichedPlan, enrichedMoves, index }) {
  const cls = useStyles();

  const { isSchedulerVisible } = useScheduler();

  return (
    <div className={index % 2 ? cls.rowEven : cls.rowOdd}>
      <Droppable
        droppableId={`droppable-timeline-row-${enrichedPlan.id}`}
        direction='vertical'
        isDropDisabled={isSchedulerVisible}
      >
        {(provided, snapshot) => (
          <div
            id={`droppable-timeline-row-${enrichedPlan.id}`}
            className={cls.droppable}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height: '100%',
              padding: snapshot.isDraggingOver ? '10px' : '0px',
              backgroundColor: snapshot.isDraggingOver ? 'rgba(160, 160, 160, 0.1)' : 'transparent',
            }}
          >
            {enrichedMoves && enrichedMoves.length > 0
              ? enrichedMoves.map((enrichedMove, i) => {
                  if (enrichedMove.move.move_type === `ride`)
                    return <MoveTileRide key={`ride-tile-${enrichedMove.move.id}-${i}`} enrichedRide={enrichedMove} />;
                  return <MoveTileDrive key={`drive-tile-${enrichedMove.move.id}-${i}`} enrichedDrive={enrichedMove} />;
                })
              : null}
          </div>
        )}
      </Droppable>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: 80,
    maxHeight: 80,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
    overflow: 'hidden',
  },
  rowEven: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: 80,
    maxHeight: 80,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: theme.border[0],
    background: theme.palette.background.light,
    overflow: 'hidden',
  },
  droppable: {
    display: 'flex',
    width: '100%',
  },
}));
