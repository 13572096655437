//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, IconButton, Icon, Tooltip, Badge } from '@material-ui/core';

import Spacer from '../../../components/Spacer';
import { useSettings } from '../providers/SettingsProvider';
import { useUnassignedMoves } from '../providers/UnassignedMovesProvider';

//////////////////////// COMPONENT ////////////////////////

export default function PlansToolbarOptions(props) {
  const cls = useStyles();

  const {
    moveDrawerOpen,
    setMoveDrawerOpen,
    driverDrawerOpen,
    setDriverDrawerOpen,
    cancelDrawerOpen,
    setCancelDrawerOpen,
    openDebugModal,
  } = useSettings();
  const { unassignedOperationsCount, unassignedConciergeCount } = useUnassignedMoves();

  return (
    <Grid container wrap='nowrap'>
      <Grid item>
        <Tooltip title='Open Unassigned Moves List'>
          <IconButton
            className={cls.iconBtn}
            onClick={() => {
              setMoveDrawerOpen(!moveDrawerOpen);
            }}
          >
            <Badge
              badgeContent={unassignedOperationsCount}
              max={999}
              className={cls.iconMoves}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Icon className={cls.iconMoves}>drive_eta</Icon>
            </Badge>
            <Badge className={cls.badgeConcierge} badgeContent={unassignedConciergeCount} max={999}>
              <Icon className={cls.iconConcierge}>drive_eta</Icon>
            </Badge>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Drivers List'>
          <IconButton
            className={cls.iconBtn}
            data-testid='drivers-btn'
            onClick={() => {
              setDriverDrawerOpen(!driverDrawerOpen);
            }}
          >
            <Icon className={cls.iconDrivers}>group</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Canceled Moves List'>
          <IconButton
            className={cls.iconBtn}
            onClick={() => {
              setCancelDrawerOpen(!cancelDrawerOpen);
            }}
          >
            <Icon className={cls.iconCancel}>block</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Debug Menu'>
          <IconButton className={cls.iconBtn} onClick={() => openDebugModal()}>
            <Icon>bug_report</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      {/* <Grid item>
        <Tooltip title='Open Settings Menu'>
          <IconButton className={cls.iconBtn}>
            <Icon>settings</Icon>
          </IconButton>
        </Tooltip>
      </Grid> */}

      <Spacer column size='xs' />
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  iconMoves: {
    color: theme.palette.primary.main,
  },
  iconDrivers: {
    color: theme.palette.secondary.main,
  },
  iconCancel: {
    // color: theme.palette.error.main,
  },
  iconConcierge: {
    color: theme.palette.concierge.main,
    marginLeft: '-8px',
  },
  badgeConcierge: {
    color: theme.palette.concierge.main,
  }
}));
