import { gql } from '@apollo/client';

const GET_LANE = gql`
  query lookup_lane($id: bigint!) {
    lanes(where: { id: { _eq: $id } }, order_by: { id: asc }) {
      id
      customer_id
      pickup {
        id
        address
        name
        region_id
      }
      delivery {
        id
        address
        name
        region_id
      }
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      description
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
    }
  }
`;

const GET_INVERSE = gql`
  query MyQuery($pickup: bigint, $delivery: bigint) {
    lanes(where: { pickup: { id: { _eq: $delivery } }, _and: { delivery: { id: { _eq: $pickup } } } }) {
      id
    }
  }
`;

// either creates new lane or updates only the details of an existing lane
const UPSERT_LANE = gql`
  mutation upsert_lanes($laneObjects: [lanes_insert_input!]!) {
    insert_lanes(
      objects: $laneObjects
      on_conflict: {
        constraint: lanes_pkey
        update_columns: [
          average_drive_speed_min_per_mile
          average_drive_speed_mph
          createdat
          dealer_base_discount
          dealer_base_price
          dealer_base_rate
          dealer_base_rate_type
          dealer_stranded_discount
          dealer_stranded_price
          dealer_stranded_rate
          dealer_stranded_rate_type
          delivery_inspection_sec
          destination_location_id
          distance_miles
          driver_base_pay
          driver_base_pay_discount
          driver_drive_pay
          driver_pay_per_kilometer
          driver_pay_per_mile
          driver_pay_per_minute
          driver_rake
          driver_return_pay
          driver_return_pay_discount
          driver_time_pay
          duration_sec
          estimated_rideshare_return_cost
          insurance_cost
          insurance_cost_per_mile
          tolls
          updatedat
        ]
      }
    ) {
      returning {
        id
        customer_id
        pickup {
          id
          address
          name
          region_id
        }
        delivery {
          id
          address
          name
          region_id
        }
        average_drive_speed_min_per_mile
        average_drive_speed_mph
        dealer_base_discount
        dealer_base_price
        dealer_base_rate
        dealer_base_rate_type
        dealer_stranded_discount
        dealer_stranded_price
        dealer_stranded_rate
        dealer_stranded_rate_type
        delivery_inspection_sec
        destination_location_id
        distance_miles
        driver_base_pay
        driver_base_pay_discount
        driver_drive_pay
        driver_pay_per_kilometer
        driver_pay_per_mile
        driver_pay_per_minute
        driver_rake
        driver_return_pay
        driver_return_pay_discount
        driver_time_pay
        duration_sec
        estimated_rideshare_return_cost
        insurance_cost
        insurance_cost_per_mile
        origin_location_id
        pickup_inspection_sec
        return_ride_wait_sec
        tolls
      }
    }
  }
`;

export { GET_LANE, GET_INVERSE, UPSERT_LANE };