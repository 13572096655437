//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Chip, Grid, Typography, TextField, MenuItem, Tooltip, Icon } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import dayjs from 'dayjs';

const log = false;

//////////////////////// COMPONENT ////////////////////////

function MoveAR(props) {
  const cls = useStyles();
  const { capFirst, clampNegNum } = useTools();

  const { move } = props;

  const armove = move?.accountsReceivable?.active ? move.accountsReceivable : null;
  const revisions = armove ? move.accountsReceivable.revisions : [];

  const currentRevisionNum = revisions && revisions.length > 0 ? revisions[0].revision + 1 : 1;
  const [revisionNum, setRevisionNum] = React.useState(currentRevisionNum);
  const getSelectedRevision = () => {
    if (currentRevisionNum === revisionNum) return armove;
    else {
      for (let i = 0; i < revisions.length; i++) {
        if (revisions[i].revision === revisionNum) return revisions[i];
      }
    }
  };
  const selectedRevision = getSelectedRevision();

  React.useEffect(() => {
    if (move) setRevisionNum(currentRevisionNum);
  }, [move, currentRevisionNum]);

  const total = selectedRevision ? selectedRevision.due_amount - selectedRevision.discount_amount : 0;
  const paid = selectedRevision ? selectedRevision.paid_amount : 0;
  const due = total - paid;

  let lineItems =
    selectedRevision && selectedRevision.details && selectedRevision.details.length > 0
      ? selectedRevision.details.map(ar => {
          let name = capFirst(ar.name);
          let notes = capFirst(ar.notes);
          let amount = ar.amount;
          let documents = ar.documents ? ar.documents : [];

          if (ar.name.includes(`INVALID`)) name = ` INVALID Transport Cost`;
          else if (ar.name.includes(`Transport`)) name = ` Transport Cost`;
          else if (!ar.name.includes(`Promo`) && !ar.name.includes(`promo`)) name = `Accessorial: ${capFirst(ar.name)}`;
          else name = capFirst(ar.name);

          if (ar.amount < 0) amount = `($${Math.abs(amount).toFixed(2)})`;
          else amount = `$${amount.toFixed(2)}`;

          return { name: name, notes: notes, amount: amount, documents: documents };
        })
      : [];

  lineItems = lineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const rateInfoSection = () => {
    //We use the rates stored on the transport cost line item if it exists (this relationship was created in 08/2023),
    //  otherwise we use the rates stored on the move
    const revisionTransportCost = selectedRevision?.details?.find(item => item.name.includes('Transport'));
    const selectedRateRule = revisionTransportCost?.raterule ? revisionTransportCost.raterule : move?.raterule;
    const selectedRateRuleGroup = selectedRateRule?.raterulegroup ? selectedRateRule.raterulegroup : null;
    log && console.log('selectedRateRule', selectedRateRule, 'selectedRateRuleGroup', selectedRateRuleGroup);
    //If there is a price override, we display this section differently
    const rateSourceIsPriceOverride = revisionTransportCost?.rate_source === 'price-override';
    //If the move has no related rate, skip the rate info section
    if (!rateSourceIsPriceOverride && (!selectedRateRule || !selectedRateRuleGroup)) return null;
    if (rateSourceIsPriceOverride) {
      return (
        <div className={cls.block}>
          <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
            <Grid item>
              <Typography className={cls.detailAmountTxt}>
                <Chip style={{}} size='small' color={'primary'} label={'Price Override Applied'} />
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
    //We get the customer of the raterule group instead of using the one on the move in case of a third party payer or an error in assigning rates
    const rateRuleGroupLabel = selectedRateRuleGroup
      ? `${selectedRateRuleGroup?.customer?.name} ${selectedRateRuleGroup.name}`
      : `Unknown Contract`;
    const formattedBeginDate = selectedRateRuleGroup?.begin_date
      ? dayjs(selectedRateRuleGroup.begin_date).format('MM/DD/YYYY')
      : null;
    const formattedEndDate = selectedRateRuleGroup?.end_date
      ? dayjs(selectedRateRuleGroup.end_date).format('MM/DD/YYYY')
      : null;
    const rateRuleGroupDateRange =
      formattedBeginDate && formattedEndDate
        ? `Contract Date Range: ${formattedBeginDate} - ${formattedEndDate}`
        : `Contract Date Range Unknown`;
    const getRateChipLabel = chipType => {
      let defaultLabel = `Unknown Rate`;
      if (!selectedRateRule) return defaultLabel;
      if (chipType === 'rateSource') {
        const rateSource =
          revisionTransportCost && revisionTransportCost.rate_source
            ? revisionTransportCost.rate_source.replace(/-/g, ' ')
            : 'default';
        const rateLabel = `${selectedRateRule.class} ${rateSource}`;
        return rateLabel;
      }
      if (chipType === 'mileageBands') {
        return `Mileage band ${selectedRateRule.distance_start}-${selectedRateRule.distance_end} mi`;
      }
      if (chipType === 'rateAmount') {
        const getRateFromRateSource = rateSource => {
          if (rateSource === 'class-3-rate') return selectedRateRule.class_3_rate;
          if (rateSource === 'class-4-rate') return selectedRateRule.class_4_rate;
          if (rateSource === 'class-5-rate') return selectedRateRule.class_5_rate;
          if (rateSource === 'class-6-rate') return selectedRateRule.class_6_rate;
          return selectedRateRule?.rate || 0; //default to "rate" to cover armoves before 08/2023 with no rate_source
        };
        const getRateLabel = (rateType, rateAmount) => {
          if (!rateAmount || !rateType) return 'Missing Rate';
          if (rateType === 'flat') return `Flat Rate: $${rateAmount}`;
          return `$${rateAmount} per mile`;
        };
        const rateAmount = getRateFromRateSource(revisionTransportCost?.rate_source);
        const rateLabel = getRateLabel(selectedRateRule?.type, rateAmount);
        return rateLabel;
      }
      return defaultLabel;
    };
    return (
      <div className={cls.block}>
        <Grid container spacing={2} alignItems='space-between' wrap='nowrap'>
          <Grid item xs>
            <Typography className={cls.detailNameTxt}>{rateRuleGroupLabel}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='space-between' wrap='nowrap'>
          <Grid item xs>
            <Typography className={cls.detailNotesTxt}>{rateRuleGroupDateRange}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
          <Grid item xs>
            <Typography className={cls.detailNotesTxt}>
              <Chip
                style={{}}
                variant={'outlined'}
                size='small'
                color={'secondary'}
                label={getRateChipLabel('rateSource')}
              />
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography className={cls.detailNotesTxt}>
              <Chip style={{}} size='small' color={'secondary'} label={getRateChipLabel('mileageBands')} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={cls.detailAmountTxt}>
              <Chip style={{}} size='small' color={'primary'} label={getRateChipLabel('rateAmount')} />
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  const priceOverrideInfo = () => {
    const priceOverride = move?.price_override?.active ? move.price_override : null;
    const amount = move?.price_override?.amount;
    const accessorialArr = move?.price_override?.config?.allowed_charges;
    const formatAccessorialArr = arr => {
      if (!arr) return 'None';
      return arr.map(item => ` ${capFirst(item)}`).toString();
    };
    if (!priceOverride) return null;
    return (
      <>
        <div className={cls.lineBreak} />
        <Grid container spacing={0} alignItems='flex-start' justifyContent='space-between' wrap='wrap'>
          <Grid item xs={12}>
            <Typography className={cls.detailNameTxt}>Price Override</Typography>
            <Typography className={cls.detailNotesTxt}>
              Price override that will be applied next time the move's price is calculated.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cls.smallBreak} />
            <Typography className={cls.leftKey}>Override&nbsp;Amount:&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cls.smallBreak} />
            <Typography className={cls.val}>${clampNegNum(amount).toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cls.smallBreak} />
            <Typography className={cls.leftKey}>Allowed&nbsp;Accessorials:&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cls.smallBreak} />
            <Typography className={cls.val}>{formatAccessorialArr(accessorialArr)}</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {armove ? (
        <div className={cls.paper}>
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              <Tooltip
                placement='top-start'
                title={move?.accountsReceivable?.id ? `AR Move ID #${move?.accountsReceivable?.id}` : `No AR Move ID`}
              >
                <Typography className={cls.headTxtL}>Customer Price</Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip placement='top-end' title='Invoice'>
                <Typography className={cls.headTxtR}>
                  {armove.invoice_id ? `Invoice #${armove.invoice_id}` : `No Invoice ID`}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <div className={cls.break} />
          {revisions && revisions.length > 0 && (
            <>
              <TextField
                select
                fullWidth
                label='Revision'
                margin='dense'
                variant='outlined'
                value={revisionNum}
                onChange={event => setRevisionNum(event.target.value)}
                style={{ margin: 0 }}
              >
                <MenuItem value={currentRevisionNum}>Current Revision #{currentRevisionNum}</MenuItem>
                {revisions.map(item => (
                  <MenuItem key={`revision-num-${item.revision}`} value={item.revision}>
                    Revision #{item.revision}
                  </MenuItem>
                ))}
              </TextField>
              <div className={cls.break} />
            </>
          )}
          {rateInfoSection()}
          <div className={cls.lineBreak} />
          {lineItems.length > 0 &&
            lineItems.map((item, i) => (
              <React.Fragment key={`selected-revision-detail-${i}`}>
                <div className={cls.block}>
                  <Typography className={cls.detailNameTxt}>{capFirst(item.name)}</Typography>
                  <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Typography className={cls.detailNotesTxt}>{capFirst(item.notes)}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={cls.detailAmountTxt}>{item.amount}</Typography>
                    </Grid>
                  </Grid>
                </div>

                {item?.documents?.length ? (
                  <div className={cls.docs}>
                    {item?.documents.map(doc => (
                      <Chip
                        classes={{ root: cls.docChip, icon: cls.docChipIcon, label: cls.docChipLabel }}
                        size='small'
                        icon={<Icon>attach_file</Icon>}
                        label={doc?.name}
                        onClick={() => window.open(doc?.url, '_blank')}
                      />
                    ))}
                  </div>
                ) : null}
                <div className={cls.lineBreak} />
              </React.Fragment>
            ))}
          {selectedRevision.discount_amount > 0 && (
            <>
              <div className={cls.block}>
                <Typography className={cls.detailNameTxt}>Discount</Typography>
                <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                  <Grid item xs>
                    <Typography className={cls.detailNotesTxt}>{capFirst(selectedRevision.discount_reason)}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={cls.detailAmountTxt}>
                      (${selectedRevision.discount_amount.toFixed(2)})
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={cls.lineBreak} />
            </>
          )}
          {lineItems.length > 0 || selectedRevision.discount_amount > 0 ? <div className={cls.break} /> : null}
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              {selectedRevision.notes ? (
                <>
                  <div className={cls.notesBox}>
                    <Typography className={cls.boxKey}>Notes:</Typography>
                    <Typography className={cls.boxVal}>{capFirst(selectedRevision.notes)}</Typography>
                  </div>
                </>
              ) : null}
              {selectedRevision.notes && selectedRevision.disputed ? <div className={cls.smallBreak} /> : null}
              {selectedRevision.disputed ? (
                <>
                  <div className={cls.disputeBox}>
                    <Typography className={cls.boxKey}>Disputed&nbsp;Reason:</Typography>
                    <Typography className={cls.boxVal}>{capFirst(selectedRevision.dispute_reason)}</Typography>
                  </div>
                </>
              ) : null}
            </Grid>
            <Grid item>
              <Typography className={cls.key}>Total:&nbsp;&nbsp;&nbsp;</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.key}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.key}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
            </Grid>
            <Grid item>
              <Typography className={cls.val}>${clampNegNum(total).toFixed(2)}</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.val}>${clampNegNum(paid).toFixed(2)}</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.val}>${clampNegNum(due).toFixed(2)}</Typography>
            </Grid>
          </Grid>
          {priceOverrideInfo()}
        </div>
      ) : (
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO AR RECORD FOUND</Typography>
          {priceOverrideInfo()}
        </div>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  notesBox: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.action.hover,
  },
  disputeBox: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.error.light,
  },
  boxKey: {
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  boxVal: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  key: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  leftKey: {
    lineHeight: 1,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  val: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  block: {
    display: 'block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: '#e0e0e0',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

  docs: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginTop: 4,
  },
  docChip: {
    height: 20,
    fontWeight: 600,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  docChipIcon: {
    fontSize: 14,
    color: theme.palette.info.contrastText,
  },
  docChipLabel: {
    fontSize: 12,
    color: theme.palette.info.contrastText,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveAR;
