import React, { useState, useRef } from 'react';
import { gql } from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { Box, Icon, InputAdornment, makeStyles, Popover, TextField, Typography } from '@material-ui/core';
import SummaryMoveItem from './SummaryMoveItem';
import NoVinItem from './NoVinItem';
const VinSearchbar = () => {
  const cls = useStyles();
  const searchRef = useRef(null);

  const [vin, setVin] = useState('');
  const [matchingMoves, setMatchingMoves] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [noVINMessage, setNoVINMessage] = useState('Cannot find moves with this VIN');
  const handleVinChange = event => {
    setVin(event.target.value);
  };

  // Lazy queries for both cases
  const [fetchVinPartial, { data, loading, error }] = useLazyQuery(GET_MOVES_BY_VIN);

  const handleSearchSubmit = event => {
    event.preventDefault();
    handleVinSearch();
  };

  const handleVinSearch = () => {
    if (vin.length < 6) {
      setNoVINMessage('Must enter at least 6 characters to search VINs');
      setMatchingMoves([]);
      setOpenPopover(true);
      return;
    } else if (vin.length > 17) {
      setNoVINMessage('VIN cannot be longer than 17 characters');
      setMatchingMoves([]);
      setOpenPopover(true);
      return;
    } else {
      fetchMovesWithMatchingVin();
    }
  };

  const fetchMovesWithMatchingVin = async () => {
    try {
      const response = await fetchVinPartial({ variables: { partialVin: `%${vin}` } });
      if (response.data.moves.length > 0) {
        setMatchingMoves(response.data.moves);
      } else {
        setNoVINMessage('No moves found with this VIN');
        setMatchingMoves([]);
      }
      setOpenPopover(true);
    } catch (error) {
      console.error('Error fetching moves with matching VIN:', error);
    }
  };

  const handleClose = () => {
    setMatchingMoves([]);
    setVin('');
    setOpenPopover(false);
  };

  return (
    <Box display='flex' alignItems='center' gap={2}>
      <form className={cls.search} onSubmit={handleSearchSubmit}>
        <TextField
          fullWidth
          placeholder='Search Moves by VIN'
          value={vin}
          onChange={handleVinChange}
          variant='outlined'
          size='small'
          ref={searchRef}
          InputProps={{
            className: cls.searchColor,
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon style={{ color: '#fff', cursor: 'default' }}>search</Icon>
              </InputAdornment>
            ),
          }}
          SelectProps={{
            inputProps: {
              classes: {
                icon: cls.searchColor,
              },
            },
          }}
          className={cls.searchTextField}
        />
        <Popover
          open={openPopover}
          onClose={handleClose}
          anchorEl={searchRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            className: cls.popoverPaper,
          }}
        >
          {matchingMoves.length > 0 ? (
            matchingMoves.map(move => (
              <SummaryMoveItem key={'move-card-' + move.id} move={move} onClose={handleClose} />
            ))
          ) : (
            <NoVinItem message={noVINMessage} />
          )}
        </Popover>
      </form>
    </Box>
  );
};

const GET_MOVES_BY_VIN = gql`
  query get_moves_by_partial_vin($partialVin: String!) {
    moves(where: { vehicle_vin: { _ilike: $partialVin } }, limit: 10, order_by: { id: desc }) {
      id
      active
      createdat
      createdBy
      cancel_status
      delivery_arrived
      delivery_started
      delivery_successful
      driver_name
      pickup_arrived
      pickup_started
      pickup_successful
      pickup_time
      pickup_started
      pickup_workflow_data
      status
      vehicle_color
      vehicle_year
      vehicle_make
      vehicle_model
      vehicle_vin
      customer {
        id
        name
      }
      hangtags(limit: 1, order_by: { updated_at: desc }) {
        id
        type
        rear_code
        status
        updated_at
      }
      lane {
        id
        description
        pickup {
          id
          name
          address
        }
        delivery {
          id
          name
          address
        }
      }
      pickup_workflow_data
      sla {
        id
        created_at
        label
        duration_hrs
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  popoverPaper: {
    maxHeight: 'calc(100vh - 64px - 16px)',
    maxWidth: '80vw',
    overflowY: 'auto',
  },
  search: {
    minWidth: 250,
    maxWidth: 250,
    marginRight: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchTextField: {
    borderRadius: theme.shape.borderRadius,
    background: '#ffffff24',
    color: theme.palette.text.contrast,
    '&:hover': {
      background: '#ffffff36',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.contrast,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.contrast,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
    transition: '0.2s',
  },
  searchColor: {
    color: theme.palette.text.contrast,
  },
  swapIcon: {
    top: 0,
    right: 0,
    color: 'white',
    padding: '8px',
    '&:hover': {
      background: '#ffffff36',
    },
  },
}));

export default VinSearchbar;
