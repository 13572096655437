//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/_app/PrivateRoute';

import AccessorialReportPage from './pages/AccessorialReport/AccessorialReport';
import ActiveTime from './pages/ActiveTime/ActiveTime';
import ActiveTimeAudit from './pages/ActiveTime/ActiveTimeAudit';
import AppDumpDownloadPage from './pages/AppDumpDownload/AppDumpDownload';
import APReportPage from './pages/APReport/APReport';
import ARReportPage from './pages/ARReports/ARReports';
import AssignedChaseVehicles from './pages/ChaseVehicles/AssignedChaseVehicles';
import ChaseVehicles from './pages/ChaseVehicles/ChaseVehicles';
import CustomerAddPage from './pages/CustomerAdd/CustomerAdd';
import CustomerDetailsPage from './pages/CustomerDetails/CustomerDetails';
import CustomersPage from './pages/Customers/Customers';
import CustomerUsersDetailsPage from './pages/CustomerDetails/CustomerUsers/CustomerUsersDetails';
import CustomerUsersPage from './pages/CustomerUsers/CustomerUsers';
import DealerUserAddPage from './pages/DealerUserAdd/DealerUserAdd';
import DealerUserDetailsPage from './pages/DealerUserDetails/DealerUserDetails';
import DisputedMovesPage from './pages/DisputedMoves/DisputedMoves';
import DriverDetailsPage from './pages/DriverDetails/DriverDetails';
import DriverEmailsPage from './pages/DriverEmails/DriverEmails';
import DriverPayoutDetailsPage from './pages/DriverPayoutDetails/PayoutDetails';
import DriverPayoutsPage from './pages/DriverPayouts/DriverPayouts';
import DriverPay1Page from './pages/DriverPay/DriverPay';
import DriverPay2Page from './pages/DriverPay-2/DriverPay';
import DriverPay3Page from './pages/DriverPay-3/DriverPay';
import DriverSchedulePage from './pages/DriverSchedule/DriverSchedule';
import DriversPage from './pages/Drivers/Drivers';
import FaqManagerPage from './pages/FaqManager/FaqManager';
import FirebaseLoginPage from './pages/Login/FirebaseLogin';
import GPSViewerPage from './pages/GPSViewer/GPSViewer';
import GPFormPage from './pages/GroupPlanning/GPForm';
import GPTimelinePage from './pages/GroupPlanning/GPTimeline';
import InsuranceRateDetailsPage from './pages/InsuranceRateDetails/InsuranceRateDetails';
import InsuranceRatesPage from './pages/InsuranceRates/InsuranceRates';
import InvoicesPage from './pages/Invoices/Invoices';
import LaneDetailsPage from './pages/LaneDetails/LaneDetails';
import LanesEntryPage from './pages/LanesEntry/LanesEntry';
import LanesPage from './pages/Lanes/Lanes';
import LocationDetailsPage from './pages/LocationDetails/LocationDetails';
import LocationsPage from './pages/Locations/Locations';
import MapPage from './pages/Map/Map';
import MissingRegionLocations from './pages/MissingRegionLocations/MissingRegionLocations';
import MoveCalculatorPage from './pages/MoveCalculator/MoveCalculator';
import MoveDetailsPage from './pages/MoveDetails/MoveDetails';
import MovePlannerPage from './pages/MovePlanner/MovePlanner';
import MovesPage from './pages/Moves/Moves';
import NotificationAddPage from './pages/NotificationAdd/NotificationAdd';
import NotificationDetailsPage from './pages/NotificationDetails/NotificationDetails';
import NotificationsPage from './pages/Notifications/Notifications';
import OnboardingPage from './pages/Onboarding/Onboarding';
import OrganizationAddPage from './pages/OrganizationAdd/OrganizationAdd';
import OrganizationDetailsPage from './pages/OrganizationDetails/OrganizationDetails';
import OrganizationsPage from './pages/Organizations/Organizations';
import PayCycleDetailsPage from './pages/DriverPay-3/PayCycleDetails';
import PlansGroupPage from './pages/PlansGroup/PlansGroup';
import PlansPage from './pages/Plans-New/Plans';
import ProductsFormPage from './pages/Products/ProductsForm';
import ProductsPage from './pages/Products/Products';
import ProfilePage from './pages/Profile/Profile';
import PromoDetailsPage from './pages/PromoDetails/PromoDetails';
import PromosPage from './pages/Promos/Promos';
import QuicklinksPage from './pages/Quicklinks/Quicklinks';
import RegionsPage from './pages/Regions/Regions';
import RuleDetailsPage from './pages/RuleDetails/RuleDetails';
import RulesPage from './pages/Rules/Rules';
import TestingPage from './pages/Testing/Testing';
import UserDetailsPage from './pages/UserDetails/UserDetails';
import UsersEntryPage from './pages/UserAdd/UsersEntry';
import UsersPage from './pages/Users/Users';
import ZeroDollarMovesPage from './pages/ZeroDollarMoves/ZeroDollarMoves';
import BatchJob from './pages/BatchJobs/BatchJobs';
import BatchJobDetail from './pages/BatchJobs/BatchJobDetail';

//////////////////////// COMPONENT ////////////////////////

export default function Routes(props) {
  return (
    <Switch>
      <Route exact path='/' component={PlansPage} />
      <Route exact path='/login' component={FirebaseLoginPage} />
      <PrivateRoute exact path='/accessorial-report' component={AccessorialReportPage} />
      <PrivateRoute exact path='/active-time' component={ActiveTime} />
      <PrivateRoute exact path='/active-time/:moveId' component={ActiveTimeAudit} />
      <PrivateRoute exact path='/batch-jobs' component={BatchJob} />
      <PrivateRoute exact path='/batch-jobs/:batchJobId' component={BatchJobDetail} />
      <PrivateRoute exact path='/ap-report' component={APReportPage} />
      <PrivateRoute exact path='/app-dump-download-page' component={AppDumpDownloadPage} />
      <PrivateRoute exact path='/ar-report/:id' component={ARReportPage} />
      <PrivateRoute exact path='/chase-vehicles/:id' component={AssignedChaseVehicles} />
      <PrivateRoute exact path='/chase-vehicles' component={ChaseVehicles} />
      <PrivateRoute exact path='/customers/add' component={CustomerAddPage} />
      <PrivateRoute exact path='/customers/add/:id' component={CustomerAddPage} />
      <PrivateRoute exact path='/customers' component={CustomersPage} />
      <PrivateRoute exact path='/customers/:id' component={CustomerDetailsPage} />
      <PrivateRoute exact path='/customers/users/add' component={DealerUserAddPage} />
      <PrivateRoute exact path='/customers/users/:id' component={DealerUserDetailsPage} />
      <PrivateRoute exact path='/customers/:id/users' component={CustomerUsersDetailsPage} />
      <PrivateRoute exact path='/customer-users' component={CustomerUsersPage} />
      <PrivateRoute exact path='/disputed-moves' component={DisputedMovesPage} />
      <PrivateRoute exact path='/drivers' component={DriversPage} />
      <PrivateRoute exact path='/drivers/:id' component={DriverDetailsPage} />
      <PrivateRoute exact path='/driver-emails' component={DriverEmailsPage} />
      <PrivateRoute exact path='/driver-pay' component={DriverPay3Page} />
      <PrivateRoute exact path='/driver-pay/:id' component={PayCycleDetailsPage} />
      <PrivateRoute exact path='/driver-pay-1' component={DriverPay1Page} />
      <PrivateRoute exact path='/driver-pay-2' component={DriverPay2Page} />
      <PrivateRoute exact path='/driver-schedule' component={DriverSchedulePage} />
      <PrivateRoute exact path='/driver-payouts' component={DriverPayoutsPage} />
      <PrivateRoute exact path='/driver-payouts/:id' component={DriverPayoutDetailsPage} />
      <PrivateRoute exact path='/faq-manager' component={FaqManagerPage} />
      <PrivateRoute exact path='/gps-viewer' component={GPSViewerPage} />
      <PrivateRoute exact path='/group-planning/form' component={GPFormPage} />
      <PrivateRoute exact path='/group-planning/timeline' component={GPTimelinePage} />
      <PrivateRoute exact path='/invoices' component={InvoicesPage} />
      <PrivateRoute exact path='/invoices/:id' component={InvoicesPage} />
      <PrivateRoute exact path='/insurance' component={InsuranceRatesPage} />
      <PrivateRoute exact path='/insurance/:id' component={InsuranceRateDetailsPage} />
      <PrivateRoute exact path='/lanes/add' component={LanesEntryPage} />
      <PrivateRoute exact path='/lanes' component={LanesPage} />
      <PrivateRoute exact path='/lanes/:id' component={LaneDetailsPage} />
      <PrivateRoute exact path='/locations' component={LocationsPage} />
      <PrivateRoute exact path='/missing-region-locations' component={MissingRegionLocations} />
      <PrivateRoute exact path='/locations/:id' component={LocationDetailsPage} />
      <PrivateRoute exact path='/map' component={MapPage} />
      <PrivateRoute exact path='/map/lanes/:id' component={MapPage} />
      <PrivateRoute exact path='/map/locations/:id' component={MapPage} />
      <PrivateRoute exact path='/move-calculator' component={MoveCalculatorPage} />
      <PrivateRoute exact path='/moves/add' component={MovePlannerPage} />
      <PrivateRoute exact path='/moves' component={MovesPage} />
      <PrivateRoute exact path='/moves/:id' component={MoveDetailsPage} />
      <PrivateRoute exact path='/notifications' component={NotificationsPage} />
      <PrivateRoute exact path='/notifications/:id' component={NotificationDetailsPage} />
      <PrivateRoute exact path='/addnotification' component={NotificationAddPage} />
      <PrivateRoute exact path='/onboarding/:id' component={OnboardingPage} />
      <PrivateRoute exact path='/organizations/add' component={OrganizationAddPage} />
      <PrivateRoute exact path='/organizations' component={OrganizationsPage} />
      <PrivateRoute path='/organizations/:id' component={OrganizationDetailsPage} />
      <PrivateRoute exact path='/plans' component={PlansPage} />
      <PrivateRoute exact path='/plans-group' component={PlansGroupPage} />
      <PrivateRoute exact path='/profile' component={ProfilePage} />
      <PrivateRoute exact path='/products' component={ProductsPage} />
      <PrivateRoute exact path='/products/add' component={ProductsFormPage} />
      <PrivateRoute exact path='/promos' component={PromosPage} />
      <PrivateRoute exact path='/promos/:id' component={PromoDetailsPage} />
      <PrivateRoute exact path='/quicklinks' component={QuicklinksPage} />
      <PrivateRoute exact path='/regions' component={RegionsPage} />
      <PrivateRoute exact path='/rules' component={RulesPage} />
      <PrivateRoute exact path='/rules/:id' component={RuleDetailsPage} />
      <PrivateRoute exact path='/testing' component={TestingPage} />
      <PrivateRoute exact path='/users/add' component={UsersEntryPage} />
      <PrivateRoute exact path='/users' component={UsersPage} />
      <PrivateRoute exact path='/users/:id' component={UserDetailsPage} />
      <PrivateRoute exact path='/zero-dollar-moves' component={ZeroDollarMovesPage} />
    </Switch>
  );
}
