//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TextField, Typography, InputAdornment, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function SettingsList({ settings }) {
  const cls = useStyles();

  const { condensedCase } = useTools();

  const [search, setSearch] = React.useState(``);
  const [filteredSettings, setFilteredSettings] = React.useState(settings);

  React.useEffect(() => {
    if (search) {
      const input = condensedCase(search);
      const newSettings = settings.filter(setting => {
        const sublabelStr = setting?.sublabels?.join(` `) || ``;
        if (condensedCase(setting?.label).includes(input)) return true;
        if (condensedCase(sublabelStr).includes(input)) return true;
        return false;
      });
      setFilteredSettings(newSettings);
    } else {
      setFilteredSettings(settings);
    }
  }, [search]);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  const handleAnchorClick = elementId => event => {
    const element = document.getElementById(elementId);
    const parentElement = document.getElementById(`settings-overflow`);
    if (parentElement) parentElement.scrollTo({ top: element.offsetTop, behavior: `smooth` });
  };

  return (
    <div id='settings-list' className={cls.list}>
      <div className={cls.listHeader}>
        <div className={cls.listHeaderSearch}>
          <TextField
            fullWidth
            label='Quick Search'
            placeholder='Search for a setting...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={cls.fade} />
      </div>

      <div className={cls.listBody}>
        {filteredSettings?.length ? (
          filteredSettings?.map((setting, i) => (
            <React.Fragment key={`config-setting-${setting?.id}`}>
              <div className={cls.listItem} onClick={handleAnchorClick(setting?.id)}>
                <Icon className={cls.listItemIcon}>arrow_forward</Icon>
                <Typography className={cls.listItemLabel}>{setting?.label}</Typography>
              </div>

              {setting?.sublabels?.map((sublabel, j) => (
                <Typography key={`config-setting-${setting?.id}-sublabel-${j}`} className={cls.listItemSublabel}>
                  {sublabel}
                </Typography>
              ))}

              {i < filteredSettings?.length - 1 && <Spacer />}
            </React.Fragment>
          ))
        ) : (
          <Typography className={cls.notFound}>No settings found...</Typography>
        )}
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  list: {
    position: 'relative',
    overflow: 'auto',
    width: 320,
    height: '100%',
    borderRight: theme.border[0],
    backgroundColor: theme.palette.background.paper,
  },
  listHeader: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  listHeaderSearch: {
    padding: theme.spacing(2, 2, 0, 2),
    backgroundColor: theme.palette.background.paper,
  },
  listBody: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  listItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& $listItemIcon': {
        color: theme.palette.primary.main,
      },
      '& $listItemLabel': {
        color: theme.palette.primary.main,
      },
      '& $listItemSublabel': {
        color: theme.palette.primary.light,
      },
    },
  },
  listItemIcon: {
    display: 'block',
    marginTop: -1,
    marginRight: 6,
    fontSize: 18,
    transition: '0.15s all ease-in-out',
  },
  listItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: 1.5,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    transition: '0.15s all ease-in-out',
  },
  listItemSublabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    textIndent: 32,
    color: theme.palette.text.secondary,
    transition: '0.15s all ease-in-out',
  },

  fade: {
    width: '100%',
    height: theme.spacing(2),
    background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
  },

  notFound: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
