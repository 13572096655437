// Check if a move is a Tookan move
export const isTookanMove = move => {
  if (move && (!move.driver_app_version || move.driver_app_version.startsWith(`1`))) return true;
  else return false;
};

// Check if the move is out of sync
export const checkMoveNeedsSync = move => {
  if (move && move.move_type === `drive`) {
    // Check moves that arent for Tookan in the draft state
    if (!isTookanMove(move) && move.driver_status === `draft`) return true;

    // Check if a Tookan move is out of sync with the Tookan timestamp, but only in certain statuses
    if (
      isTookanMove(move) &&
      (!move.synced_with_tookan || move.updatedat > move.synced_with_tookan) &&
      (move.active !== 1 ||
        !move.status ||
        move.status === `dispatched` ||
        move.status === `canceled` ||
        move.status === `failed` ||
        move.cancel_status)
    )
      return true;
  }
  return false;
};

// Check if the plan is out of sync
export const checkPlanNeedsSync = plan => {
  if (plan) {
    const { moves } = plan;
    if (moves && moves.length) {
      const unsyncedMoves = moves.filter(move => checkMoveNeedsSync(move));
      if (unsyncedMoves.length) return true;
    }
  }
  return false;
};

// Check to see if the move is eligible for a conversion to Tookan
export const checkMoveEligibleForConversion = move => {
  if (move) {
    // Check to make sure the move isnt already a Tookan move and check its running status
    if (
      !isTookanMove(move) &&
      move.active &&
      !move.cancel_status &&
      move.status &&
      (move.status === `dispatched` || move.status.includes(`pickup`) || move.status.includes(`delivery`))
    ) {
      return true;
    }
  }
  return false;
};

// Define object with helpers
const helpers = {
  isTookanMove,
  checkMoveNeedsSync,
  checkPlanNeedsSync,
  checkMoveEligibleForConversion,
};

// Export
export default helpers;
