//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { makeStyles, Typography } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';

import { GET_INVOICES } from './gql';
import { useInvoices } from './useInvoices';

import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import { InvoicesListFilter, InvoicesListItem } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function InvoicesList({
  invoiceId,
  setInvoiceId,
  invoiceIdInURL,
  selectedCustomerIds,
  setSelectedCustomerIds,
}) {
  const cls = useStyles();

  const { getDefaultStart, getDefaultEnd } = useInvoices();

  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());

  const handleChangeCustomers = (customerIds = []) => {
    if (customerIds) {
      console.log("local", customerIds)
      localStorage.setItem(`invoice-id`, null);
      localStorage.setItem(`invoice-customer-id-array`, customerIds);
      setSelectedCustomerIds(customerIds);
      setInvoiceId(0);
    }
  };

  const handleDateChange = (value, name) => {
    const newDate = dayjs(value).format();
    if (name === `start`) {
      localStorage.setItem(`invoice-list-start`, newDate);
      setStart(newDate);
    } else {
      localStorage.setItem(`invoice-list-end`, newDate);
      setEnd(newDate);
    }
  };

  //
  const { loading, error, data } = useQuery(GET_INVOICES, {
    variables: {
      selectedCustomerIds: selectedCustomerIds,
      start: start,
      end: end,
    },
  });

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.list}>
        <InvoicesListFilter
          start={start}
          end={end}
          handleChangeCustomers={handleChangeCustomers}
          onDateChange={handleDateChange}
        />
        <Loading absolute />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching invoices:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.list}>
        <InvoicesListFilter
          start={start}
          end={end}
          handleChangeCustomers={handleChangeCustomers}
          onDateChange={handleDateChange}
        />
        <DefaultErrorFallback message='ERROR FETCHING INVOICES' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.arinvoices || !data.arinvoices.length > 0) {
    return (
      <div className={cls.list}>
        <InvoicesListFilter
          start={start}
          end={end}
          handleChangeCustomers={handleChangeCustomers}
          onDateChange={handleDateChange}
        />
        <DefaultEmptyFallback message='NO INVOICES FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const invoices = data.arinvoices;
  return (
    <div className={cls.list}>
      <InvoicesListFilter
        start={start}
        end={end}
        invoiceIdInURL={invoiceIdInURL}
        handleChangeCustomers={handleChangeCustomers}
        onDateChange={handleDateChange}
      />
      {invoices.map((invoice, i) => (
        <React.Fragment key={`invoice-list-item-${i}`}>
          <InvoicesListItem invoice={invoice} selectedInvoiceId={invoiceId} setSelectedInvoiceId={setInvoiceId} />
        </React.Fragment>
      ))}
      <Typography className={cls.footnote}>*All datetimes are presented in UTC</Typography>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  list: {
    position: 'relative',
    width: 320,
    height: '100%',
    borderLeft: theme.border[0],
    background: theme.palette.background.main,
    overflow: 'auto',
  },
  footnote: {
    padding: theme.spacing(1),
    fontSize: 12,
  },
}));
