// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { buildDriverPayCycleArray } from '@hopdrive/sdk/lib/modules/wallet';

import { makeStyles, LinearProgress } from '@material-ui/core';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import PayCycleDetailsHeader from './PayCycleDetailsHeader';
import PayCycleDetailsSubheader from './PayCycleDetailsSubheader';
import PayCycleDetailsTable from './PayCycleDetailsTable';

// COMPONENT ---------------------------------------------------------------- //

export default function PayCycleDetailsContent({
  loading,
  error,
  refetch,
  paycycle,
  defaultDriverPayArray,
  filters,
  isPaying,
  setIsPaying,
}) {
  const cls = useStyles();

  const [driverPayArray, setDriverPayArray] = React.useState(defaultDriverPayArray || []);
  const [payMode, setPayMode] = React.useState(false);
  const [checkedDriverPayArray, setCheckedDriverPayArray] = React.useState([]);

  React.useEffect(() => {
    if (paycycle) {
      let driverPayArray = [];
      if (payMode) {
        let editedPaycycle = { ...paycycle };
        editedPaycycle.appayments = editedPaycycle?.appayments?.filter(
          app =>
            app?.status === `unpaid` &&
            (!app?.driverpayout ||
              app?.driverpayout?.status === `canceled` ||
              app?.driverpayout?.status === `failed`) &&
            app?.amount !== 0
        );
        editedPaycycle.apcharges = editedPaycycle?.apcharges?.filter(
          apc =>
            apc?.status === `unpaid` &&
            (!apc?.driverpayout ||
              apc?.driverpayout?.status === `canceled` ||
              apc?.driverpayout?.status === `failed`) &&
            apc?.amount !== 0
        );
        driverPayArray = buildDriverPayCycleArray(editedPaycycle);
      } else {
        driverPayArray = buildDriverPayCycleArray(paycycle);
      }
      if (filters?.select?.value && filters?.select?.value !== `all`) {
        driverPayArray = driverPayArray?.filter(dp => dp?.region_ids?.includes(filters?.select?.value));
      }
      setDriverPayArray(driverPayArray);
      setCheckedDriverPayArray([]);
    } else setDriverPayArray([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paycycle, payMode, filters]);

  if (loading) return <LinearProgress />;
  if (error) {
    console.error(`Failed to fetch pay cycle:`, error);
    return <DefaultErrorFallback message={`Failed to fetch pay cycle`} />;
  }
  if (!paycycle) return <DefaultEmptyFallback message={`No Pay Cycle Found`} />;
  if (!driverPayArray?.length) return <DefaultEmptyFallback message={`No Driver Pay Found With Current Filters`} />;
  return (
    <>
      <PayCycleDetailsHeader paycycle={paycycle} payMode={payMode} setPayMode={setPayMode} />
      <PayCycleDetailsSubheader
        paycycle={paycycle}
        driverPayArray={driverPayArray}
        payMode={payMode}
        setPayMode={setPayMode}
        isPaying={isPaying}
        setIsPaying={setIsPaying}
        checkedDriverPayArray={checkedDriverPayArray}
        refetch={refetch}
      />
      <PayCycleDetailsTable
        paycycle={paycycle}
        driverPayArray={driverPayArray}
        payMode={payMode}
        checkedDriverPayArray={checkedDriverPayArray}
        setCheckedDriverPayArray={setCheckedDriverPayArray}
        refetch={refetch}
      />
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
