// DEPENDENCIES ---------------------------------------------------------------- //
import { useApolloClient } from '@apollo/client';
import { WATCH_BATCH_JOB } from './gql';
// HOOK ------------------------------------------------------------------------ //

export default function useGroupPlanning() {
  /** Get the chase vehicle name */
  const client = useApolloClient();

  const getVehicleName = vehicle => {
    let vehicleStr = ``;

    const make = vehicle?.chasevehicle?.make || vehicle?.make;
    const model = vehicle?.chasevehicle?.model || vehicle?.model;

    if (make && model) vehicleStr = `${make} ${model}`;
    else if (make) vehicleStr = make;
    else if (model) vehicleStr = model;
    else vehicleStr = `Unknown`;

    return vehicleStr;
  };

  /** Get the chase vehicle vin */
  const getVehicleVin = vehicle => {
    const vin = vehicle?.chasevehicle?.vin || vehicle?.vin;
    return vin || `Unknown`;
  };

  /** Get the chase vehicle capacity */
  const getVehicleCapacity = vehicle => {
    const capacity = vehicle?.chasevehicle?.capacity || vehicle?.capacity;
    return capacity || 0;
  };

  const waitForBatchJobCompletion = async (batchId, timeoutMs = 180000) => {
    try {
      const result = await new Promise((resolve, reject) => {
        const subscription = client
          .subscribe({
            query: WATCH_BATCH_JOB,
            variables: {
              batchId,
            },
          })
          .subscribe({
            next: ({ data }) => {
              const job = data?.batch_jobs?.[0];
              if (job?.status === 'done' || job?.status === 'error') {
                subscription.unsubscribe();
                resolve(job);
              }
            },
            error: err => {
              subscription.unsubscribe();
              reject(err);
            },
          });

        setTimeout(() => {
          subscription.unsubscribe();
          reject(new Error('Batch job timed out'));
        }, timeoutMs);
      });

      return result?.output;
    } catch (err) {
      console.error(`Failed waiting for batch job:`, err);
      return {
        success: false,
        details: err?.message,
      };
    }
  };

  return { getVehicleName, getVehicleVin, getVehicleCapacity, waitForBatchJobCompletion };
}
