//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useTheme, makeStyles, Tooltip, Typography, Chip, Icon } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import { Table } from '../../components/TableComponents';

const driverStatuses = [`online`, `offline`, `training`, `onboarding`, `interviewed`, `new`, `rejected`, `terminated`];

//////////////////////// COMPONENT ////////////////////////

export default function DriversTable({ drivers, status, taxClass, search }) {
  const theme = useTheme();
  const cls = useStyles();
  const {
    goToDriverDetails,
    goToOnboarding,
    getFormattedStatusFromDriver,
    getCleansedPhoneNumber,
    getCleansedPhoneType,
    getInitialsFromName,
  } = useTools();

  // Set filtered drivers based on search results
  const [filteredDrivers, setFilteredDrivers] = React.useState(drivers);
  React.useEffect(() => {
    let newFilteredDrivers = drivers;

    if (status && status !== `all`) {
      newFilteredDrivers = newFilteredDrivers.filter(d => d.status && d.status.toLowerCase().includes(status));
    }

    if (taxClass && taxClass !== `both`) {
      newFilteredDrivers = newFilteredDrivers.filter(d => d.tax_class && d.tax_class.includes(taxClass));
    }

    if (search && search.length > 0) {
      const searchInput = search.toLowerCase();
      newFilteredDrivers = newFilteredDrivers.filter(
        d =>
          `${d.id}`.includes(searchInput) ||
          (d.display_name && d.display_name.toLowerCase().includes(searchInput)) ||
          (d.region && d.region.name && d.region.name.toLocaleLowerCase().includes(searchInput))
      );
    }

    setFilteredDrivers(newFilteredDrivers);
  }, [drivers, status, taxClass, search]);

  // Table config
  const config = [
    {
      name: `id`,
      value: driver => driver.id,
      label: `ID`,
      tooltip: `ID of the driver`,
    },
    {
      name: `avatar_img`,
      value: driver => driver.display_name,
      renderer: driver =>
        driver.avatar_url ? (
          <div className={cls.avatar}>
            <img className={cls.avatarImg} src={driver.avatar_url} alt='avatar' />
          </div>
        ) : (
          <div className={cls.avatar}>
            <div className={cls.avatarImg}>
              <Typography className={cls.initialsTxt}>
                {getInitialsFromName({ driver_name: driver.display_name || null })}
              </Typography>
            </div>
          </div>
        ),
      label: `Avatar`,
      tooltip: `Image of the driver`,
    },
    {
      name: `display_name`,
      value: driver => driver.display_name,
      label: `Name`,
      tooltip: `Name of the driver`,
    },
    {
      name: `region_name`,
      value: driver => getPropValue(driver, `region.name`),
      label: `Region`,
      tooltip: `The driver's home region`,
    },
    {
      name: `tax_class`,
      value: driver => driver.tax_class,
      label: `Tax Class`,
      tooltip: `The driver's tax class`,
      style: { minWidth: 120 },
    },
    {
      name: `hasDD`,
      value: driver => driver.bank_account_token,
      renderer: driver =>
        driver.bank_account_token ? (
          <Tooltip title='Direct deposit ready'>
            <Icon style={{ display: `block`, color: theme.palette.success.main }}>check_circle</Icon>
          </Tooltip>
        ) : (
          <Tooltip title='No direct deposit available'>
            <Icon style={{ display: `block`, color: theme.palette.text.disabled }}>remove_circle_outline</Icon>
          </Tooltip>
        ),
      label: `DD`,
      tooltip: `Does the driver have Direct Deposit setup?`,
    },
    {
      name: `email`,
      value: driver => driver.email,
      label: `Email`,
      tooltip: `Email of the driver`,
    },
    {
      name: `phone`,
      value: driver => getCleansedPhoneNumber(driver.phone),
      label: `Phone`,
      tooltip: `Phone number of the driver`,
    },
    {
      name: `phone_type`,
      value: driver => driver.phone_type,
      renderer: driver => (
        <Tooltip title={getCleansedPhoneType(driver.phone_type) || `Unknown`}>
          {getCleansedPhoneType(driver.phone_type) === `Android` ? (
            <Icon className={cls.phoneAndroidIcon}>android</Icon>
          ) : getCleansedPhoneType(driver.phone_type) === `iOS` ? (
            <Icon className={cls.phoneAppleIcon}>apple</Icon>
          ) : (
            <Icon className={cls.phoneDefaultIcon}>phone_android</Icon>
          )}
        </Tooltip>
      ),
      label: `OS`,
      tooltip: `Operating system of the driver's phone`,
      style: { minWidth: 100 },
    },
    {
      name: `status`,
      value: driver => driverStatuses.indexOf(driver.status),
      renderer: driver => (
        <div className={cls.statusBox}>
          {driver.status === `online` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
              circle
            </Icon>
          )}
          {driver.status === `offline` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.default.lighter }}>
              bedtime
            </Icon>
          )}
          {driver.status === `training` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.warning.main }}>
              circle
            </Icon>
          )}
          {driver.status === `onboarding` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.warning.main }}>
              contrast
            </Icon>
          )}
          {driver.status === `interviewed` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
              flag_circle
            </Icon>
          )}
          {driver.status === `new` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
              new_releases
            </Icon>
          )}
          {driver.status === `rejected` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
              remove_circle
            </Icon>
          )}
          {driver.status === `terminated` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
              cancel
            </Icon>
          )}
          <Typography className={cls.statusTxt}>{getFormattedStatusFromDriver(driver)}</Typography>
        </div>
      ),
      label: `Status`,
      tooltip: `Status of the driver`,
    },
    {
      name: `active`,
      value: driver => driver.active,
      renderer: driver => (
        <Chip
          size='small'
          color={driver.active ? `secondary` : `default`}
          label={driver.active ? `Active` : `Inactive`}
        />
      ),
      label: `Active`,
      tooltip: `Is the driver active?`,
      align: `right`,
    },
  ];

  // Table row actions
  const rowActions = [
    {
      label: `Go To Driver Details`,
      handler: driver => goToDriverDetails(driver.id ? driver.id : null),
      disabled: false,
      hide: false,
    },
    {
      label: `Go To Onboarding`,
      handler: driver => goToOnboarding(driver.id ? driver.id : null),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={filteredDrivers}
        columnConfig={config}
        onRowClick={driver => goToDriverDetails(driver.id)}
        rowActions={rowActions}
        defaultOrderBy={'display_name'}
        defaultOrder={'asc'}
        tableAriaLabel={`drivers`}
      />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },

  phoneAndroidIcon: {
    display: 'block',
    color: theme.palette.default.light,
  },
  phoneAppleIcon: {
    display: 'block',
    color: theme.palette.default.lighter,
  },
  phoneDefaultIcon: {
    display: 'block',
    color: theme.palette.default.main,
  },

  statusBox: {
    position: 'relative',
    display: 'flex',
  },
  statusIcon: {
    fontSize: 20,
    marginRight: 8,
  },
  statusTxt: {
    fontSize: 14,
  },
}));
