//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { UserDetailsProvider } from '../../providers/UserDetailsProvider';

import Toolbar from '../../components/Toolbar';

import DealerUserAddToolbar from './DealerUserAddToolbar';
import DealerUserAddForm from './DealerUserAddForm';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerUserAdd(props) {
  const cls = useStyles();

  const initialState = props?.location?.state;

  // const [superAdminOverride, setSuperAdminOverride] = React.useState(false);

  //Check if the customer has any users. If not, set the default role to dealer-super-admin so that at least one user at the rooftop has the permissions to add others
  // React.useEffect(() => {
  //   if (customerId) checkCustomerUsers();
  // }, [customerId]);

  // const checkCustomerUsers = async () => {
  //   const res = await apolloClient.query({ query: GET_CUSTOMER_USERS, variables: { customerId: customerId } });
  //   if (res && res.data) {
  //     const users = getPropValue(res, `data.users`);
  //     if (!users || !users.length > 0) {
  //       setRole('dealer-super-admin');
  //       setSuperAdminOverride(true);
  //     }
  //   }
  // };

  const user = {};

  return (
    <div className={cls.root}>
      <UserDetailsProvider user={user}>
        <Toolbar fullscreen title={`Add User`} back>
          <DealerUserAddToolbar />
        </Toolbar>

        <Spacer size='xl' />

        <DealerUserAddForm
          // superAdminOverride={superAdminOverride}
          initialState={initialState}
        />

        <Spacer size='xl' />
      </UserDetailsProvider>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.background.paper,
  },
}));
