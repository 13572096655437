import { gql } from '@apollo/client';

const GET_ZERO_DOLLAR_MOVES = gql`
  query get_zero_dollar_moves($dateLimit: timestamptz) {
    moves(
      where: {
        active: {_eq: 1}, 
        move_type: {_ilike: "drive"}, 
        chargeable: {_eq: true}, 
        customer_id: {_neq: 9},
        status: {_nin: ["failed", "canceled", "cancelled", "dispatched"]}
        _and: [
          {_or:[
            {cancel_status: {_nin: ["canceled", "cancelled"]}},
            {cancel_status: {_is_null: true}}
          ]}
          {_or: [
            {accountsReceivable: {due_amount: {_lt: 20}}},
            {_not: {accountsReceivable: {details: {}}}},
            {accountsReceivable: {details: {name: {_ilike: "%INVALID%"}}}}
          ]}
        ]
        pickup_time: { _gt: $dateLimit }
      }
      order_by: { pickup_time: desc_nulls_last }
    ) {
      id
        active
        class
        settled
        status
        cancel_status
        reference_num
        pickup_time
        vehicle_vin
        vehicle_year
        vehicle_make
        vehicle_model
        vehicle_color
        vehicle_odometer
        pickup_started
        pickup_arrived
        pickup_successful
        delivery_started
        delivery_arrived
        delivery_successful
        tags
        customer {
          id
          name
        }
        lane {
          id
          description
          distance_miles
          delivery {
            id
            name
            address
          }
          pickup {
            id
            name
            address
          }
        }
        accountsReceivable {
          id
          status
          notes
          author
          billable_datetime
          due_amount
          paid_amount
          discount_amount
          discount_reason
          disputed
          dispute_reason
          details {
            id
            name
            notes
            amount
          }
          revisions(order_by: { revision: desc }) {
            id
            revision
            due_amount
            discount_amount
            discount_reason
            disputed
            dispute_reason
            details {
              id
              name
              notes
              amount
            }
          }
        }
    }
  }
`;

const GET_MOVE_LOGS = gql`
  query get_move_logs($moveId: bigint!) {
    arevents(where: { move_id: { _eq: $moveId } }, order_by: { id: desc }) {
      id
      arevent_id
      move_id
      invoice_id
      message
      level
      createdat
    }
  }
`;

export { GET_ZERO_DOLLAR_MOVES, GET_MOVE_LOGS};