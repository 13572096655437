//////////////////////// DEPENDENCIES ////////////////////////

import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Link,
  CssBaseline,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import PasswordReset from './PasswordReset';
import { useData } from '../../providers/DataProvider';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import { getUserId } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

function FirebaseLogin(props) {
  const firebaseAuth = getAuth();
  const ctx = useData();
  const cls = useStyles();
  const userCtx = useUserDetails();

  const log = true;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);

  onAuthStateChanged(firebaseAuth, user => {
    if (user) {
      if (ctx.lastLocation) {
        props.history.push(ctx.lastLocation);
      } else props.history.push('/');
    }
  });

  const handleEnter = event => {
    if (event && event.keyCode && event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = async e => {
    if (!email || !password) {
      setErrorMessage('Please enter an email and password.');
      return;
    }

    try {
      let loginRes = await signInWithEmailAndPassword(firebaseAuth, email, password);
      const userId = await getUserId();
      if (loginRes.user) {
        try {
          userCtx.writeUserEventlog(email, userId, 'user.login', false)
        } catch (err) {
          console.log('Could not publish user eventlog', err);
        }
        props.history.push('/');
      }
    } catch (err) {
      console.log('err', err.code, err.message);
      if (err.code.includes('password') || err.code.includes('user-not-found')) {
        setErrorMessage('Wrong email or password.');
      } else if (err.code.includes('invalid-email')) {
        setErrorMessage('Please enter a valid email and try again.');
      } else setErrorMessage('An error occurred while attempting to sign in.');
    }
  };

  return (
    <>
      <CssBaseline />

      <Container className={cls.container} component='main'>
        {showResetPassword ? (
          <PasswordReset open={showResetPassword} setOpen={setShowResetPassword} email={email} setEmail={setEmail} />
        ) : (
          <div>
            <Spacer size='xl' />

            <Typography className={cls.titleTxt}>Sign in to continue</Typography>

            <Spacer size='lg' />

            <TextField
              required
              fullWidth
              autoFocus
              id='email'
              name='email'
              autoComplete='email'
              label='Email'
              placeholder='Enter email...'
              variant='outlined'
              size='small'
              value={email}
              onChange={e => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      email
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Spacer />

            <TextField
              required
              fullWidth
              id='password'
              name='password'
              type='password'
              label='Password'
              placeholder='Enter password...'
              variant='outlined'
              size='small'
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={handleEnter}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      lock
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Spacer size='lg' />

            <Button fullWidth type='submit' color='primary' size='large' onClick={() => handleSubmit()}>
              Sign in
            </Button>

            {errorMessage ? (
              <>
                <Spacer size='xs' />
                <Typography className={cls.errorTxt}>{errorMessage}</Typography>
                <Spacer size='xs' />
              </>
            ) : null}

            <Spacer size='xs' />

            <div className={cls.reset}>
              <Link
                className={cls.resetTxt}
                color='secondary'
                target='_blank noreferrer'
                onClick={() => {
                  setErrorMessage(null);
                  setShowResetPassword(true);
                }}
              >
                Request or reset password
              </Link>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 480,
  },
  titleTxt: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  errorTxt: {
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.error.main,
  },
  reset: {
    width: '100%',
    textAlign: 'center',
  },
  resetTxt: {
    fontSize: 14,
    cursor: 'pointer',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default FirebaseLogin;
