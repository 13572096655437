import React from 'react';
import { Table, Column } from '../../components/TableComponents';
import { Link } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import { makeStyles, Chip } from '@material-ui/core';
import dayjs from 'dayjs';


export default function AssignedChaseVehicleTable({ chaseVehicles }) {
  const { goToCustomerDetails, goToOrganizationDetails, goToMoveDetails } = useTools();
  const [search, setSearch] = React.useState(``);
  const [filteredChaseVehicles, setFilteredChaseVehicles] = React.useState([]);

  const cls = useStyles();

  const capitalizeFirst = value => {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  };

  const getStatusChipClass = status => {
    switch (status) {
      case `received`:
        return cls.statusPending;
      case `returned`:
        return cls.statusDone;
      case `error`:
        return cls.statusError;
      default:
        return undefined;
    }
  };

  const handleLocalFilters = () => {
    let filteredData = [...chaseVehicles];

    if (chaseVehicles?.length) {
      if (search?.length) {
        const searchLower = search.toLowerCase();
        filteredData = filteredData?.filter(
          fd =>
            `${fd?.vin}`?.toLowerCase().includes(searchLower) ||
            `${fd?.make}`?.toLowerCase().includes(searchLower) ||
            `${fd?.model}`?.toLowerCase().includes(searchLower) ||
            `${fd?.capacity}`?.toLowerCase().includes(searchLower) ||
            `${fd?.customer_id}`?.toLowerCase().includes(searchLower) ||
            `${fd?.first_move_id}`?.includes(searchLower) ||
            `${fd?.last_move_id}`?.includes(searchLower)
        );
      }
    }

    setFilteredChaseVehicles(filteredData);
  };

  React.useEffect(() => {
    handleLocalFilters();
  }, [chaseVehicles, search]);

  return (
    <div className={cls.wrapper}>
      <Table
        data={filteredChaseVehicles}
        tableAriaLabel={`assigned-chase-vehicles`}
        stickyHeader={true}
        search={search}
        setSearch={setSearch}
      >
        <Column name='status'
         label='Status' 
         value={chaseVehicle => capitalizeFirst(chaseVehicle?.status)}
         renderer={chaseVehicle => (
          <Chip className={getStatusChipClass(chaseVehicle?.status)} label={capitalizeFirst(chaseVehicle?.status)} size='small' />
        )}
          />
          <Column 
            name='receivedAt' 
            label='Received At' 
            value={chaseVehicle => dayjs(chaseVehicle?.created_at).format('MM-DD-YYYY')} 
          />
        <Column name='vin' label='Vin' />
        <Column name='make' label='Make' />
        <Column name='model' label='Model' />
        <Column name='capacity' label='Capacity' />
        <Column name='first_move_id' 
          label='First Move ID' 
          renderer={chaseVehicle => <Link
             component="button" onClick={(e) => {
              e.stopPropagation();
              goToMoveDetails(chaseVehicle?.first_move_id);
          }}>
            {chaseVehicle?.first_move_id}</Link>  }
        />
        <Column name='last_move_id' 
          label='Last Move ID' 
          renderer={chaseVehicle => <Link
             component="button" onClick={(e) => {
              e.stopPropagation();
              goToMoveDetails(chaseVehicle?.last_move_id);
          }}>
            {chaseVehicle?.last_move_id}</Link>  }
        />
        <Column 
          name='region' 
          label='Region'
          value={chaseVehicle => chaseVehicle?.region?.name}
        />
        <Column 
          name='organization' 
          label='Organization'
          renderer={chaseVehicle => (
            <Link 
              component="button"
              onClick={(e) => {
                e.stopPropagation();
                goToOrganizationDetails(chaseVehicle?.organization?.id);
              }}
            >
              {chaseVehicle?.organization?.name}
            </Link>
          )}
        />
        <Column 
          name='customer' 
          label='Customer' 
          renderer={chaseVehicle => (
            <Link 
              component="button"
              onClick={(e) => {
                e.stopPropagation();
                goToCustomerDetails(chaseVehicle?.customer?.id);
              }}
            >
              {chaseVehicle?.customer?.name}
            </Link>
          )}
        />
        
      </Table>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({

  wrapper: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },

  statusPending: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.warning.contrastText,
  },

  statusDone: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },

}));
