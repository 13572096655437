//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useTools } from '../../hooks/useTools';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import CustomersFilter from './CustomersFilter';
import CustomersTable from './CustomersTable';

//////////////////////// COMPONENT ////////////////////////

export default function CustomersByOrg({ organization }) {
  const cls = useStyles();

  const { goToRoute } = useTools();

  // Component state
  const [status, setStatus] = React.useState(`all`);
  const [search, setSearch] = React.useState(``);

  // Handle add customer
  const handleAddCustomer = () => {
    goToRoute(`/customers/add/${organization?.id}`);
  };

  // Control status filter
  const handleStatusChange = (status = null) => {
    if (status) {
      localStorage.setItem(`customers-by-org-status`, status);
      setStatus(status);
    }
  };

  // Query
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMERS_BY_ORG, {
    variables: {
      organizationId: organization?.id,
      start: dayjs().startOf(`day`).subtract(30, `day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
    fetchPolicy: `cache-and-network`,
  });

  // Handle clear filters
  const handleClearFilters = () => {
    setStatus(`all`);
    setSearch(``);
  };

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Customers'>
          <CustomersFilter />
        </Toolbar>

        <DefaultLoadingFallback message='FETCHING CUSTOMERS' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching customers:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
          <CustomersFilter
            handleAddCustomer={handleAddCustomer}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING CUSTOMERS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data?.customers?.length) {
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
          <CustomersFilter
            handleAddCustomer={handleAddCustomer}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultEmptyFallback message='NO CUSTOMERS FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const customers = data?.customers;

  return (
    <div className={cls.root}>
      <Toolbar zIndex={9} fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
        <CustomersFilter
          handleAddCustomer={handleAddCustomer}
          status={status}
          onStatusChange={handleStatusChange}
          search={search}
          onSearchChange={setSearch}
        />
      </Toolbar>

      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMERS TABLE' />}>
        <CustomersTable customers={customers} status={status} search={search} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  toolbar: {
    padding: theme.spacing(2),
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_CUSTOMERS_BY_ORG = gql`
  query admin_getCustomersByOrg($organizationId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    customers(where: { organization_id: { _eq: $organizationId } }, order_by: { name: asc }) {
      id
      active
      address
      email
      name
      phone
      status
      moves_aggregate(
        where: {
          active: { _eq: 1 }
          chargeable: { _eq: true }
          move_type: { _eq: "drive" }
          pickup_time: { _gte: $start, _lte: $end }
          status: { _eq: "delivery successful" }
          _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
        }
      ) {
        aggregate {
          count
        }
      }
      organization {
        id
        name
      }
    }
  }
`;
