// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import { makeStyles, Grid, Tooltip, Typography, TextField, IconButton, Icon, InputAdornment } from '@material-ui/core';

import { condensedCase } from '../../utils/GeneralUtils';
import { getFormattedVehicleFromMove } from '../../utils/MoveUtils';

import { usePlansGroup } from './PlansGroupProvider';

import PlansGroupMoveItem from './PlansGroupMoveItem';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupMoveList({ moves = [] }) {
  const cls = useStyles();

  const { selectedMoveIds, handleClearMoveIds, getSelectableMoves, maxGroupSize } = usePlansGroup();

  const [filteredMoves, setFilteredMoves] = React.useState([]);
  const [search, setSearch] = React.useState(``);

  React.useEffect(() => {
    let selectedArray = [...moves?.filter(m => selectedMoveIds?.includes(m?.id))];
    let filteredArray = getSelectableMoves(moves);

    if (filteredArray?.length) {
      // Search filter
      if (search?.length) {
        const searchEdit = condensedCase(search);
        filteredArray = filteredArray?.filter(
          m =>
            condensedCase(m?.id)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.customer?.name)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.lane?.pickup?.address)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.lane?.pickup?.name)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.lane?.delivery?.address)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.lane?.delivery?.name)?.toString()?.includes(searchEdit) ||
            condensedCase(getFormattedVehicleFromMove(m))?.toString()?.includes(searchEdit) ||
            condensedCase(m?.consumer_name)?.toString()?.includes(searchEdit) ||
            condensedCase(m?.consumer_phone)?.toString()?.includes(searchEdit)
        );
      }
    }

    setFilteredMoves([...selectedArray, ...filteredArray]);
    // eslint-disable-next-line
  }, [selectedMoveIds, search]);

  return (
    <div className={cls.moveList}>
      <div className={cls.moveListHeader}>
        <div className={cls.moveListHeaderContent}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs>
              <TextField
                fullWidth
                name='search'
                placeholder='Search for a move...'
                size='small'
                variant='outlined'
                value={search}
                onChange={e => setSearch(e?.target?.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon color='disabled' fontSize='small'>
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='clear input'
                        style={{ display: search?.length ? `block` : `none`, padding: 4, marginRight: -8 }}
                        onClick={() => {
                          setSearch(``);
                        }}
                      >
                        <Icon fontSize='small'>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {selectedMoveIds?.length ? (
              <Grid item>
                <Tooltip title='Clear Selected Moves'>
                  <IconButton className={cls.clear} onClick={() => handleClearMoveIds()}>
                    <Icon>layers_clear</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        </div>

        <div className={cls.moveListHeaderFade} />
      </div>

      <div className={cls.moveListDescription}>
        <Typography className={cls.moveListDescriptionTxt}>
          Select up to {maxGroupSize} drivers & moves to group together. Selected moves will be matched with selected
          drivers and synced by time.
        </Typography>

        <Typography className={cls.moveListDescriptionAltTxt}>
          NOTE: When selecting a move, the criteria for the list of drivers & moves changes based on the move you
          selected.
          {/* Selecting more than {maxGroupSize} moves will prompt us to request an XL ride. */}
        </Typography>
      </div>

      <div className={cls.moveListBody}>
        {filteredMoves?.length ? (
          filteredMoves?.map((move, i) => (
            <React.Fragment key={`plans-group-move-item-${move?.id}`}>
              <PlansGroupMoveItem move={move} />
              {i < filteredMoves?.length - 1 ? <div className={cls.moveListBodySpacer} /> : null}
            </React.Fragment>
          ))
        ) : (
          <Typography className={cls.notFoundTxt}>No Moves Found</Typography>
        )}
      </div>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  moveList: {
    zIndex: 100,
    position: 'relative',
    overflow: 'auto',
    width: 384,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  moveListHeader: {
    zIndex: 101,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  moveListHeaderContent: {
    maxHeight: 56,
    padding: 16,
    paddingBottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  moveListHeaderFade: {
    width: '100%',
    height: 8,
    background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
  },

  moveListDescription: {
    padding: '8px 16px',
  },
  moveListDescriptionTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
  },
  moveListDescriptionAltTxt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  moveListBody: {
    position: 'relative',
    padding: 16,
    paddingTop: 8,
  },
  moveListBodySpacer: {
    height: 8,
    width: '100%',
  },

  clear: {
    padding: 4,
  },

  notFoundTxt: {
    paddingTop: 32,
    paddingBottom: 32,
    lineHeight: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
