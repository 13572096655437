//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerAddToolbar({
  organizations,
  organization,
  onCancel = () => {},
  onSubmit = () => {},
  onSubmitAndAddACustomer = () => {},
  isSaving,
}) {
  const cls = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs />
      <Grid item>
        <Button onClick={() => onCancel()}>
          <Icon className={cls.btnIcon}>highlight_off</Icon>
          Cancel
        </Button>
      </Grid>

      <Grid item>
        <Button loading={isSaving} disabled={isSaving} color='primary' onClick={() => onSubmit(organization)}>
          <Icon className={cls.btnIcon}>task_alt</Icon>
          Submit
        </Button>
      </Grid>

      <Grid item>
        <Button
          loading={isSaving}
          disabled={isSaving}
          variant='outlined'
          color='primary'
          onClick={() => onSubmitAndAddACustomer(organization)}
        >
          <Icon className={cls.btnIcon}>add_task</Icon>
          Submit & Add Another
        </Button>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));
