//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Button } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function ModalAction({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
}
