import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormGroup,
  Dialog,
  DialogActions,
  Button,
  Chip,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

let log = false;

export default function RateRuleFormModal({
  open = false,
  rateRule = null,
  onSave = unsavedRateRule => {},
  onCancel = () => {},
}) {
  const cls = useStyles();

  const [unsavedRateRule, setUnsavedRateRule] = useState(rateRule);

  useEffect(() => {
    log && console.log('Inbound rate rule prop set to: ', rateRule);
    if (!rateRule) return;
    setUnsavedRateRule(rateRule);
  }, [rateRule]);

  useEffect(() => {
    if (!unsavedRateRule) return;
    log && console.log('Unsaved rate rule changed to: ', unsavedRateRule);
  }, [unsavedRateRule]);

  const handleInputChange = name => event => {
    setUnsavedRateRule({ ...unsavedRateRule, [name]: event.target.value });
  };

  const handleButtonGroupChange = name => (event, value) => {
    setUnsavedRateRule({ ...unsavedRateRule, [name]: value });
  };

  return (
    <Dialog open={open} aria-labelledby='rate-rule-form-modal'>
      <DialogTitle id='rate-rule-form-modal'>
        Rate Rule Editor
        <Chip
          label={rateRule?.class?.toUpperCase()}
          variant='outlined'
          color='primary'
          size='small'
          style={{ marginLeft: '10px' }}
        />
      </DialogTitle>

      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING RATE RULE FORM' />}>
        <DialogContent dividers>
          {unsavedRateRule && (
            <>
              <DialogContentText>Update the mileage band for this rate rule:</DialogContentText>
              <FormControl>
                <FormGroup>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='distance-start'>Start Mileage</InputLabel>
                        <OutlinedInput
                          id='distance-start'
                          value={unsavedRateRule.distance_start || 0}
                          onChange={handleInputChange('distance_start')}
                          endAdornment={<InputAdornment position='end'>mi</InputAdornment>}
                          labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='distance-end'>End Mileage</InputLabel>
                        <OutlinedInput
                          id='distance-end'
                          value={unsavedRateRule.distance_end || 0}
                          onChange={handleInputChange('distance_end')}
                          endAdornment={<InputAdornment position='end'>mi</InputAdornment>}
                          labelWidth={90}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <DialogContentText style={{ marginTop: '20px' }}>
                    Update rates by weight class for this rate rule:
                  </DialogContentText>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='class-1-&-2'>Class 1 & 2</InputLabel>
                        <OutlinedInput
                          id='rate'
                          value={unsavedRateRule.rate || 0}
                          onChange={handleInputChange('rate')}
                          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                          labelWidth={85}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='class-3'>Class 3</InputLabel>
                        <OutlinedInput
                          id='class-3-rate'
                          value={unsavedRateRule.class_3_rate || 0}
                          onChange={handleInputChange('class_3_rate')}
                          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                          labelWidth={55}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='class-4'>Class 4</InputLabel>
                        <OutlinedInput
                          id='class-3-rate'
                          value={unsavedRateRule.class_4_rate || 0}
                          onChange={handleInputChange('class_4_rate')}
                          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                          labelWidth={55}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='class-5'>Class 5</InputLabel>
                        <OutlinedInput
                          id='class-3-rate'
                          value={unsavedRateRule.class_5_rate || 0}
                          onChange={handleInputChange('class_5_rate')}
                          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                          labelWidth={55}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className={cls.margin} variant='outlined'>
                        <InputLabel htmlFor='class-5'>Class 6</InputLabel>
                        <OutlinedInput
                          id='class-3-rate'
                          value={unsavedRateRule.class_6_rate || 0}
                          onChange={handleInputChange('class_6_rate')}
                          startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                          labelWidth={55}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
                <FormGroup>
                  <Grid container spacing={4} style={{ marginTop: '10px' }}>
                    <Grid item md={12}>
                      <ToggleButtonGroup
                        value={unsavedRateRule.type || 'per'}
                        onChange={handleButtonGroupChange('type')}
                        aria-label='type'
                        exclusive
                      >
                        <ToggleButton value='per' aria-label='per mile'>
                          Per Mile
                        </ToggleButton>
                        <ToggleButton value='flat' aria-label='flat rate'>
                          Flat Rate
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </>
          )}
          {!unsavedRateRule && (
            <>
              <DialogContentText>No rate rule provided to edit</DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave(unsavedRateRule);
            }}
            color='primary'
            // autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Sentry.ErrorBoundary>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    height: '100%',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 600,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
}));
