//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import {
  useTheme,
  makeStyles,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Icon,
  Tooltip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { gql, useMutation } from '@apollo/client';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetailsOverviewInfo({ organization }) {
  const theme = useTheme();
  const cls = useStyles();

  const { capFirst } = useTools();

  const [formActive, setFormActive] = React.useState(organization?.active || false);
  const [formCategory, setFormCategory] = React.useState(organization?.category || ``);
  const [formDescription, setFormDescription] = React.useState(organization?.description || ``);
  const [formName, setFormName] = React.useState(organization?.name || ``);
  const [formStatus, setFormStatus] = React.useState(organization?.status || ``);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState(null);

  // Detect edit bool change
  React.useEffect(() => {
    if (isEditing) {
      setFormActive(organization?.active || false);
      setFormCategory(organization?.category || ``);
      setFormDescription(organization?.description || ``);
      setFormName(organization?.name || ``);
      setFormStatus(organization?.status || ``);
    }
  }, [isEditing]);

  // Mutation to save organization info
  const [saveOrganization] = useMutation(SAVE_ORGANIZATION);

  // Check if the form is valid
  const checkIsFormValid = () => {
    let isValid = true;
    let newValidationErrors = {};

    if (formActive !== true && formActive !== false) {
      isValid = false;
      newValidationErrors.formActive = `Active flag must be selected!`;
    }
    if (!formCategory) {
      isValid = false;
      newValidationErrors.formCategory = `Category must be selected!`;
    }
    if (!formDescription) {
      isValid = false;
      newValidationErrors.formDescription = `Description is required!`;
    }
    if (!formName || formName?.length < 3) {
      isValid = false;
      newValidationErrors.formName = `Name is required and must be at least 3 characters long!`;
    }
    if (!formStatus) {
      isValid = false;
      newValidationErrors.formStatus = `Status must be selected!`;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  // Handle any input change
  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  // Edit the form
  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  // Save changes out of edit mode
  const handleSaveChanges = async () => {
    setIsSaving(true);

    // Check if the form is valid before saving
    const isFormValid = checkIsFormValid();
    if (isFormValid) {
      try {
        const variables = {
          id: organization?.id,
          active: formActive ? true : false,
          category: formCategory || null,
          description: formDescription || null,
          name: formName || null,
          status: formStatus || null,
        };

        const res = await saveOrganization({ variables });
        if (res?.data?.update_organizations?.affected_rows) {
          toast.success(`Successfully updated organization!`);
          setIsEditing(false);
        }
      } catch (err) {
        console.error(`Failed to update organization:`, err);
        toast.error(`Failed to update organization!`);
      }
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  return (
    <div className={cls.info}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography className={cls.headTxt}>
            {organization?.name} (#{organization?.id})
          </Typography>
        </Grid>

        {isEditing ? (
          <Grid item>
            <Tooltip placement='top' title={`Click to lock & save your changes`}>
              <Button disabled={isSaving} loading={isSaving} color='primary' onClick={() => handleSaveChanges()}>
                <Icon className={cls.btnIcon}>save</Icon>
                Save Changes
              </Button>
            </Tooltip>
          </Grid>
        ) : null}

        <Grid item>
          <Tooltip
            placement='top'
            title={isEditing ? `Click to lock & discard your changes` : `Click to unlock & edit`}
          >
            <IconButton
              style={{
                color: isEditing ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              className={cls.iconBtn}
              onClick={() => handleEdit()}
            >
              <Icon>{isEditing ? `lock_open` : `lock`}</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Name'
              placeholder='Enter the organization name...'
              value={formName}
              onChange={handleInputChange(setFormName)}
              error={validationErrors?.formName}
              helperText={validationErrors?.formName || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      label
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Name</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  label
                </Icon>
                <Typography className={cls.valTxt}>{organization?.name || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        <Grid item md={8} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Description'
              placeholder='Enter the organization description...'
              value={formDescription}
              onChange={handleInputChange(setFormDescription)}
              error={validationErrors?.formDescription}
              helperText={validationErrors?.formDescription || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      description
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Description</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  description
                </Icon>
                <Typography className={cls.valTxt}>{organization?.description || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Category'
              placeholder='Select the organization category...'
              value={formCategory}
              onChange={handleInputChange(setFormCategory)}
              error={validationErrors?.formCategory}
              helperText={validationErrors?.formCategory || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={`operational`}>Operational</MenuItem>
              <MenuItem value={`concierge`}>Concierge</MenuItem>
              <MenuItem value={`house`}>House</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Category</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  category
                </Icon>
                <Typography className={cls.valTxt}>{capFirst(organization?.category) || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Status'
              placeholder='Select the organization status...'
              value={formStatus}
              onChange={handleInputChange(setFormStatus)}
              error={validationErrors?.formStatus}
              helperText={validationErrors?.formStatus || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      token
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={`live`}>Live</MenuItem>
              <MenuItem value={`onboarding`}>Onboarding</MenuItem>
              <MenuItem value={`deactivated`}>Deactivated</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Status</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  token
                </Icon>
                <Typography className={cls.valTxt}>{capFirst(organization?.status) || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Active?'
              placeholder='Select the organization active flag...'
              value={formActive}
              onChange={handleInputChange(setFormActive)}
              error={validationErrors?.formActive}
              helperText={validationErrors?.formActive || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      toggle_on
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Active?</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  toggle_on
                </Icon>
                <Typography className={cls.valTxt}>{organization?.active ? `Yes` : `No`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  info: {
    padding: theme.spacing(2),
  },

  headTxt: {
    fontSize: 24,
    fontWeight: 600,
  },

  keyTxt: {
    lineHeight: '14px',
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  valBox: {
    display: `flex`,
    alignItems: `center`,
  },
  valIcon: {
    marginRight: 8,
  },
  valTxt: {
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    lineHeight: '20px',
    fontSize: 16,
    fontWeight: 400,
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },

  iconBtn: {
    width: 34,
    height: 34,
  },

  line: {
    width: `100%`,
    height: 0,
    borderBottom: theme.border[1],
    borderBottomStyle: 'dashed',
    marginTop: 5,
  },
}));

//////////////////////// STYLES ////////////////////////

const SAVE_ORGANIZATION = gql`
  mutation admin_saveOrganization(
    $id: bigint!
    $active: Boolean!
    $category: String!
    $description: String!
    $name: String!
    $status: String!
  ) {
    update_organizations(
      where: { id: { _eq: $id } }
      _set: { active: $active, category: $category, description: $description, name: $name, status: $status }
    ) {
      affected_rows
      returning {
        id
        active
        category
        description
        name
        status
      }
    }
  }
`;
