// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import CustomerSelect from '../../components/CustomerSelect';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormTime({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [customerId, setCustomerId] = React.useState(0);

  /** Handle changing the customer ID */
  const handleCustomerChange = e => {
    const value = e?.target?.value;

    setCustomerId(value);
    setFormData({ ...formData, customerId: value || 0 });
  };

  // Set the initial customer ID
  React.useEffect(() => {
    if (initialLoad) {
      if (initialFormData?.customerId) setCustomerId(initialFormData?.customerId);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData]);

  // Reset the customer ID when formData changes
  React.useEffect(() => {
    if (formData?.customerId) setCustomerId(formData?.customerId);
  }, [formData]);

  return (
    <div className={cls.root}>
      <label className={cls.textFieldLabel} htmlFor='startTime'>
        Which customer is this group for?<span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <CustomerSelect value={customerId} onChange={handleCustomerChange} />
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },
}));
