//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Tooltip, Icon, Divider } from '@material-ui/core';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useTools } from '../../../hooks/useTools';
import { useModal } from '../providers/ModalProvider';
import { useSettings } from '../providers/SettingsProvider';

import { MoveTileTooltip, MoveTileStatusBar } from '../index';

import { ReactComponent as IconLyft } from '../../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../../static/uber.svg';
import { ReactComponent as IconHD } from '../../../static/bunny.svg';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileRide({ enrichedRide = {} }) {
  const {
    goToMoveDetails,
    goToLaneDetails,
    getFormattedRidesharePartner,
    getFormattedCombinedStatusFromMove,
    searchLocationOnGoogleMaps,
    searchLaneOnGoogleMaps,
  } = useTools();

  const { handleManageRideModalOpen } = useModal();
  const { timelineMoveOffset, enableMoveIds, atRiskView } = useSettings();

  const {
    move = {},
    isCompleted,
    isCanceled,
    isFailed,
    waitDelay,
    driveDelay,
    extraWaitDuration,
    extraDriveDuration,
    minWidth,
    waitWidthExtra,
    driveWidthExtra,
    gapPx,
    zIndex,
    bgColor,
  } = enrichedRide;

  const lane = move.lane || {};
  const pickup = lane.pickup || {};
  const delivery = lane.delivery || {};

  const useStyles = makeStyles(theme => ({
    shell: {
      position: 'relative',
      marginLeft: gapPx,
      marginRight: timelineMoveOffset,
    },
    tile: {
      position: 'relative',
      display: 'flex',
      height: 64,
      border: `2px solid ${bgColor}`,
      borderRadius: theme.shape.paperRadius,
      background: '#fff',
      cursor: 'pointer',
      overflow: 'hidden',
    },
    ride: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: minWidth - 4,
      maxWidth: minWidth - 4,
      height: 64,
      padding: theme.spacing(1),
      color: bgColor,
      overflow: 'hidden',
      '& svg': {
        '& path': {
          fill: bgColor,
        },
        '& rect': {
          fill: bgColor,
        },
        '& polygon': {
          fill: bgColor,
        },
      },
    },
    waitExtra: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: waitWidthExtra,
      maxWidth: waitWidthExtra,
      height: 64,
      background: bgColor,
      backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
      opacity: 0.7,
      overflow: 'hidden',
    },
    driveExtra: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: driveWidthExtra,
      maxWidth: driveWidthExtra,
      height: 64,
      background: bgColor,
      backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
      opacity: 0.7,
      overflow: 'hidden',
    },
    extraBox: {
      position: 'absolute',
      top: 64,
      left: 4,
      width: 64,
      transformOrigin: '0 0',
      transform: 'rotate(-90deg)',
    },
    extraTxt: {
      lineHeight: 1,
      fontSize: 10,
      fontWeight: 900,
      textAlign: 'center',
      color: theme.palette.text.contrast,
      opacity: 0.75,
      textShadow: '-1px 1px 1px #00000040',
    },
    overlayPast: {
      zIndex: 272,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#fff',
      opacity: 0.5,
      pointerEvents: 'none',
    },
    laneBox: {
      zIndex: 262,
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
    laneTxt: {
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    laneBreak: {
      width: '100%',
      height: theme.spacing(0.25),
    },
    laneFade: {
      zIndex: 263,
      position: 'absolute',
      top: 0,
      right: 0,
      width: theme.spacing(2),
      height: '100%',
      background: `linear-gradient(90deg, #ffffff00, #ffffff)`,
      pointerEvents: 'none',
    },
    rideBox: {
      zIndex: 261,
      position: 'absolute',
      display: 'flex',
      alignContent: 'flex-end',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      maxHeight: 18,
    },
    iconAuto: {
      fontSize: 21,
      marginTop: -1,
    },
    iconLyft: {
      height: 18,
      marginBottom: -2,
    },
    iconUber: {
      height: 14,
    },
    iconShuttle: {
      height: 20,
    },
    iconHD: {
      height: 18,
    },
    anchorBox: {
      zIndex: 261,
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      maxHeight: 18,
    },
    anchorTxt: {
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 400,
      color: `${theme.palette.text.contrast}a0`,
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    tooltip: {
      minWidth: 540,
      maxWidth: 720,
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
    },
  }));
  const cls = useStyles();

  const rideActions = [
    {
      label: `Go to Ride Details`,
      handler: move => goToMoveDetails(move.id, false, true),
    },
    {
      label: `Go to Lane Details`,
      handler: move => goToLaneDetails(move.lane.id),
    },
    {},
    {
      label: `Manage Ride`,
      handler: move => handleManageRideModalOpen({ move: move }),
    },
    {},
    {
      label: `Search Pickup Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
    },
    {
      label: `Search Delivery Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
    },
    {
      label: `Search Directions`,
      handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
    },
  ];

  const getDelayString = type => {
    let seconds = 0;

    if (type === `wait`) {
      if (waitDelay) seconds = waitDelay;
      if (extraWaitDuration && extraWaitDuration > waitDelay) seconds = extraWaitDuration;
    }
    if (type === `drive`) {
      if (driveDelay) seconds = driveDelay;
      if (extraDriveDuration && extraDriveDuration > driveDelay) seconds = extraDriveDuration;
    }

    let minutes = Number(seconds / 60).toFixed(0);

    if (type === `wait`) return `Driver waited for an extra ${minutes} minutes`;
    if (type === `drive`) {
      if (isCompleted) return `Drive ran ${minutes} minutes behind`;
      return `Drive is running ${minutes} minutes behind`;
    }
  };

  // Get the rideshare partner's icon
  const RidePartnerIcon = () => {
    if (move.ride_type === `lyft`) return <IconLyft className={cls.iconLyft} />;
    if (move.ride_type === `uber`) return <IconUber className={cls.iconUber} />;
    if (move.ride_type === `shuttle`) return <IconHD className={cls.iconHD} />;
    return <Icon className={cls.iconAuto}>hdr_auto</Icon>;
  };

  // Get info for the bottom of the move tile
  const getSubInfo = () => {
    return (
      <Tooltip
        title={
          <>
            <div>Ride Type - {getFormattedRidesharePartner(move.ride_type)}</div>
            <div>Ride Status - {getFormattedCombinedStatusFromMove(move)}</div>
          </>
        }
      >
        <div className={cls.rideBox}>{RidePartnerIcon()}</div>
      </Tooltip>
    );
  };

  return (
    <>
      <ContextMenuTrigger id={`ride-tile-${move.id}-cm`} source={`ride-tile-${move.id}-cm`}>
        <div className={cls.shell} style={{ opacity: atRiskView ? 0.1 : 1 }}>
          {/* STATUS BADGE */}
          <MoveTileStatusBar enrichedMove={enrichedRide} />

          {/* PAST OVERLAY */}
          {isCompleted || isCanceled || isFailed ? <div className={cls.overlayPast} /> : null}

          {/* TILE */}
          <div className={cls.tile}>
            {/* EXTRA WAIT */}
            {waitDelay > 0 ? (
              <Tooltip title={getDelayString(`wait`)} placement='top'>
                <div className={cls.waitExtra}>
                  <div className={cls.extraBox}>
                    <Typography className={cls.extraTxt}>WAIT</Typography>
                  </div>
                </div>
              </Tooltip>
            ) : null}

            {/* RIDE MOVE */}
            <div className={cls.ride}>
              <Tooltip title={<MoveTileTooltip enrichedMove={enrichedRide} />} classes={{ tooltip: cls.tooltip }}>
                <div className={cls.laneBox}>
                  <Typography className={cls.laneTxt}>
                    {enableMoveIds ? <strong>#{move.id} - </strong> : null}
                    {pickup.name || `Unknown Pickup`}
                  </Typography>
                  <div className={cls.laneBreak} />
                  <Typography className={cls.laneTxt}>to {delivery.name || `Unknown Delivery`}</Typography>
                </div>
              </Tooltip>
              <div className={cls.laneFade} />

              {getSubInfo()}
            </div>

            {/* EXTRA DRIVE */}
            {driveDelay > 0 ? (
              <Tooltip title={getDelayString(`drive`)} placement='top'>
                <div className={cls.driveExtra}>
                  <div className={cls.extraBox}>
                    <Typography className={cls.extraTxt}>DRIVE</Typography>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`ride-tile-${move.id}-cm`}>
        {rideActions.map((action, i) => {
          if (!action || !action.handler) return <Divider key={`ride-tile-action-${i}`} className={cls.divider} />;
          return (
            <ContextMenuItem
              key={`ride-tile-action-${i}`}
              disabled={action.disabled}
              onClick={() => action.handler(move)}
            >
              {action.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>
    </>
  );
}
