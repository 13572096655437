import React, { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DebugModal } from '../components/DebugModal';
import Loading from '../../../components/Loading';
import Layout from '../components/Layout';

import { SimpleLogger } from '../../../utils/SimpleLogger';

const getDefaultState = stateName => {
  const localState = localStorage.getItem(stateName);
  if (localState === `true`) return true;
  else return false;
};

const SettingsContext = createContext({});

function SettingsProvider({ children }) {
  // Date the timeline is displaying
  const [timelineDate, setTimelineDate] = useState();
  // A check for if the timelineDate is today
  const [isTimelineDateToday, setIsTimelineDateToday] = useState(
    dayjs(timelineDate).format(`YYYY-MM-DD`) === dayjs().format(`YYYY-MM-DD`)
  );

  // Zoom level of the timeline
  const [timelineScale, setTimelineScale] = React.useState(localStorage.getItem(`timeline-scale`) || 1);
  // Multiplier constant applied to the minutes of a move to get width
  const timelineMult = 4;
  // Base width of a minute on the timeline (based on the constants)
  const [timelineScaleWidth, setTimelineScaleWidth] = React.useState((timelineScale * timelineMult) / 60);
  // Constant offset to be subtracted from the timeline move tiles
  const timelineMoveOffset = 4;

  // Right sidebar Drawer open logic
  const [moveDrawerOpen, setMoveDrawerOpen] = useState(false);
  const [driverDrawerOpen, setDriverDrawerOpen] = useState(false);
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);

  // Show/Hide at risk view
  const [atRiskView, setAtRiskView] = useState(false);

  // Controls whether or not the settings dialog is being shown to the user
  const [debugModalOpen, setDebugModalOpen] = useState(false);

  // Debug state
  const [enableMoveIds, setEnableMoveIds] = React.useState(getDefaultState(`plans-move-ids`));
  const [enableDebugTooltips, setEnableDebugTooltips] = React.useState(getDefaultState(`plans-debug-tooltips`));
  const [disableNotificationSound, setDisableNotificationSound] = React.useState(
    getDefaultState(`plans-disable-notification-sound`)
  );

  const [enableGeneralLogs, setEnableGeneralLogs] = React.useState(getDefaultState(`plans-general-logs`));
  const [enableSettingsLogs, setEnableSettingsLogs] = React.useState(getDefaultState(`plans-settings-logs`));
  const [enablePlanLogs, setEnablePlanLogs] = React.useState(getDefaultState(`plans-plan-logs`));
  const [enableTimelineLogs, setEnableTimelineLogs] = React.useState(getDefaultState(`plans-timeline-logs`));
  const [enableSchedulerLogs, setEnableSchedulerLogs] = React.useState(getDefaultState(`plans-scheduler-logs`));
  const [enableDriverLogs, setEnableDriverLogs] = React.useState(getDefaultState(`plans-driver-logs`));
  const [enableRegionLogs, setEnableRegionLogs] = React.useState(getDefaultState(`plans-region-logs`));
  const [enableUnassignedMovesLogs, setEnableUnassignedMovesLogs] = React.useState(
    getDefaultState(`plans-unassigned-moves-logs`)
  );
  const [enableCanceledMovesLogs, setEnableCanceledMovesLogs] = React.useState(
    getDefaultState(`plans-canceled-moves-logs`)
  );

  const [enableEnrichmentLogs, setEnableEnrichmentLogs] = React.useState(getDefaultState(`plans-enrichment-logs`));
  const [enableRideLogs, setEnableRideLogs] = React.useState(getDefaultState(`plans-ride-logs`));
  const [enablePersistLogs, setEnablePersistLogs] = React.useState(getDefaultState(`plans-persist-logs`));
  const [enableDragDropLogs, setEnableDragDropLogs] = React.useState(getDefaultState(`plans-drag-and-drop-logs`));

  const { log } = new SimpleLogger({ prefix: 'SettingsProvider', enabled: enableSettingsLogs });

  useEffect(() => {
    log('Initializing through useEffect()...');
    loadTimelineDate();
  }, []);

  // Whenever the selected date changes, run the following code
  useEffect(() => {
    if (!timelineDate) return; // Skip if it's the initial load
    const formattedTimelineDate = timelineDate.format(`YYYY-MM-DD`);
    log(`Timeline date changed:`, formattedTimelineDate);
    setIsTimelineDateToday(formattedTimelineDate === dayjs().format(`YYYY-MM-DD`));
    sessionStorage.setItem('timeline-date', formattedTimelineDate);
    sessionStorage.setItem('timeline-date-save-date', dayjs().format(`YYYY-MM-DD`));
  }, [timelineDate]);

  // If the timeline scale changes, update timelineScaleWidth
  React.useEffect(() => {
    log(`Timeline scale has changed. Updating dependent variables...`);
    setTimelineScaleWidth((timelineScale * timelineMult) / 60);
  }, [timelineScale]);

  // Initial timelineDate load
  const loadTimelineDate = () => {
    let tempDate = null;
    const timelineDateSaveDate = dayjs(sessionStorage.getItem('timeline-date-save-date') || dayjs());

    try {
      if (sessionStorage.getItem('timeline-date') !== null) {
        tempDate = dayjs(sessionStorage.getItem('timeline-date'));
        if (!tempDate.isValid()) {
          tempDate = dayjs();
        }
        log('Loading timeline date from local storage', tempDate.format('LLL'));
      } else {
        tempDate = dayjs();
        log('Loading timeline date using current date', tempDate.format('LLL'));
      }
    } catch (error) {
      tempDate = dayjs();
      log('Error loading timeline date using current date', tempDate.format('LLL'));
    }
    setTimelineDate(tempDate);
  };

  // If we aren't able to load the settings, then everything else in the child providers will
  // not load correctly. Therefor we should take over the tree and prevent children from being
  // rendered and instead render a loading icon until the date is selected.
  if (!timelineDate) {
    log(`Timeline date isn't set yet. Holding on settings provider creation.`);
    return (
      <Layout>
        <DebugModal open={debugModalOpen} />
        <Loading fixed />
      </Layout>
    );
  }

  const context = {
    timelineDate,
    setTimelineDate,
    isTimelineDateToday,
    timelineScale,
    setTimelineScale,
    timelineMult,
    timelineScaleWidth,
    timelineMoveOffset,
    moveDrawerOpen,
    setMoveDrawerOpen,
    driverDrawerOpen,
    setDriverDrawerOpen,
    cancelDrawerOpen,
    setCancelDrawerOpen,
    atRiskView,
    setAtRiskView,
    debugModalOpen,
    openDebugModal: () => setDebugModalOpen(true),
    closeDebugModal: () => setDebugModalOpen(false),
    enableMoveIds,
    enableDebugTooltips,
    disableNotificationSound,
    enableGeneralLogs,
    enableSettingsLogs,
    enablePlanLogs,
    enableTimelineLogs,
    enableSchedulerLogs,
    enableDriverLogs,
    enableRegionLogs,
    enableUnassignedMovesLogs,
    enableCanceledMovesLogs,
    enableEnrichmentLogs,
    enableRideLogs,
    enablePersistLogs,
    enableDragDropLogs,
    setEnableMoveIds,
    setEnableDebugTooltips,
    setDisableNotificationSound,
    setEnableGeneralLogs,
    setEnableSettingsLogs,
    setEnablePlanLogs,
    setEnableTimelineLogs,
    setEnableSchedulerLogs,
    setEnableDriverLogs,
    setEnableRegionLogs,
    setEnableUnassignedMovesLogs,
    setEnableCanceledMovesLogs,
    setEnableEnrichmentLogs,
    setEnableRideLogs,
    setEnablePersistLogs,
    setEnableDragDropLogs,
  };

  return (
    <SettingsContext.Provider value={context}>
      <DebugModal open={debugModalOpen} />
      {children}
    </SettingsContext.Provider>
  );
}

const useSettings = () => useContext(SettingsContext);

export { useSettings, SettingsProvider };
