// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';
import { LoadScript, GoogleMap, DrawingManager, Polygon } from '@react-google-maps/api';

import { useTheme, makeStyles, Typography } from '@material-ui/core';
import { styles } from '../../utils/googleMapOptions';

import { useTools } from '../../hooks/useTools';
import { useRegions } from './RegionsProvider';

const log = false;

// Libraries used for the Google Maps API
const libraries = [`core`, `drawing`];

// COMPONENT -------------------------------------------------- //

export default function RegionsMap({ regions, refetch }) {
  const theme = useTheme();
  const cls = useStyles();
  const { conformRegionGeofenceForMap } = useTools();
  const {
    defaultCenter,
    forceRenderRegions,
    setMap,
    setDrawingManager,
    setDrawingPolygon,
    editMode,
    drawMode,
    selectedRegionId,
    selectRegion,
    isGeofenceValid,
    completeGeofence,
  } = useRegions();

  const polygonRefs = React.useRef([]);

  // FUNCTIONAL HELPERS //

  /** Get polygon based on ref index */
  const getPolygonByRef = refIndex => {
    let polygon = polygonRefs.current[refIndex];
    polygon = polygon.state.polygon;
    return polygon;
  };

  /** Get geofence from a polygon */
  const getPolygonGeofence = polygon => {
    // Get array of points
    const geofenceArray = polygon.getPath().getArray();

    // Loop over the points and build the geofence
    let geofence = [];
    geofenceArray.forEach(p => {
      geofence.push({ lat: p.lat(), lng: p.lng() });
    });

    // If the first and last points are identical, remove the last point
    geofence = conformRegionGeofenceForMap(geofence);

    // Return the geofence
    return geofence;
  };

  /** Remove a vertex from a polygon */
  const deletePolygonVertex = (polygon, point) => {
    // Check for the point and remove it if possible
    if (point && point.vertex) polygon.getPath().removeAt(point.vertex);
  };

  // MAP HELPERS //

  // When the map is loaded, fire this callback
  const onMapLoad = React.useCallback(loadedMap => {
    // log && console.log(`loadedMap`, loadedMap);
    setMap(loadedMap);
  }, []);

  // When the map is unloaded, fire this callback
  const onMapUnload = React.useCallback(unloadedMap => {
    // log && console.log(`unloadedMap`, unloadedMap);
    setMap(null);
  }, []);

  // DRAWING HELPERS //

  // Options for the drawing manager
  const drawOptions = {
    drawingControlOptions: {
      drawingModes: [`polygon`],
    },
    polygonOptions: {
      fillColor: theme.palette.success.main,
      fillOpacity: 0.333,
      strokeColor: theme.palette.success.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      // editable: true,
    },
  };

  // When the map is loaded, fire this callback
  const onDrawLoad = React.useCallback(drawingManager => {
    // log && console.log(`Drawing Manager:`, drawingManager);
    setDrawingManager(drawingManager);
  }, []);

  // When a drawn polygon is completed, fire this callback
  const onDrawPolygonComplete = React.useCallback(polygon => {
    // Get the polygon's current geofence (and check for overlap)
    const geofence = getPolygonGeofence(polygon);
    log && console.log(`Geofence completed:`, geofence);

    // Check if the polygon is valid and set it in state
    if (isGeofenceValid(geofence, true)) {
      completeGeofence(geofence);
      setDrawingPolygon(polygon);
    } else {
      polygon.setMap(null);
    }
  }, []);

  // POLYGON HELPERS //

  // Options for an unselected polygon
  const unselectedPolygonOptions = {
    fillColor: theme.palette.concierge.main,
    fillOpacity: 0.333,
    strokeColor: theme.palette.concierge.main,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
  };

  // Options for a selected polygon
  const selectedPolygonOptions = {
    fillColor: theme.palette.ops.main,
    fillOpacity: 0.333,
    strokeColor: theme.palette.ops.main,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
  };

  // Handle region polygon loaded
  const onPolygonLoad = region => polygon => {
    // log && console.log(`Polygon for region #${region.id} created:`, polygon);
  };

  // Handle clicking on the polygon
  const onPolygonClick = region => event => {
    // Make sure we arent in draw mode first
    if (!drawMode) {
      // Check if region is not already active and select that region
      if (selectedRegionId !== region.id) {
        selectRegion(region);
        log && console.log(`Selected region #${region.id}.`);
      }
    }
  };

  // Handle right-clicking on the polygon
  const onPolygonRightClick = region => event => {
    // Make sure we arent in draw mode first
    if (!drawMode) {
      // Get the polygon by ref and its current geofence
      const polygon = getPolygonByRef(region.id);
      const oldGeofence = getPolygonGeofence(polygon);

      // Check to see if the polygon would be valid before the vertex delete
      // Otherwise give an error
      // Set the new geofence in state
      if (oldGeofence.length >= 4 && event && event.vertex) {
        deletePolygonVertex(polygon, event);
        const newGeofence = getPolygonGeofence(polygon);
        completeGeofence(newGeofence);
        log && console.log(`Removed point from region #${region.id} geofence:`, newGeofence);
      } else if (event && event.vertex) {
        console.error(`Failed to delete point! Geofence needs at least 3 points.`);
        toast.error(`Failed to delete point! Geofence needs at least 3 points.`);
      }
    }
  };

  // Handle mouse up on the polygon
  const onPolygonMouseUp = region => event => {
    // Make sure we arent in draw mode first
    if (!drawMode) {
      // Get the polygon by ref and its current geofence
      const polygon = getPolygonByRef(region.id);
      const geofence = getPolygonGeofence(polygon);

      // Set the current geofence in state
      completeGeofence(geofence);
    }
  };

  // RENDER //

  // Render map component
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
      <GoogleMap
        mapContainerClassName={cls.mapContainer}
        options={{ styles: styles }}
        center={defaultCenter}
        zoom={5}
        clickableIcons={false}
        onLoad={onMapLoad}
        onUnmount={onMapUnload}
      >
        {editMode && selectedRegionId ? (
          <>
            <div className={cls.info}>
              <div className={cls.infoBox}>
                <Typography className={cls.infoTxtL}>Drag points to edit a region's geofence.</Typography>
                <Typography className={cls.infoTxtS}>Right-click to remove a point.</Typography>
              </div>
            </div>
          </>
        ) : null}

        {drawMode ? (
          <>
            <div className={cls.info}>
              <div className={cls.infoBox}>
                <Typography className={cls.infoTxtL}>Click to start drawing the region geofence.</Typography>
                <Typography className={cls.infoTxtS}>You may edit the geofence after the region is saved.</Typography>
              </div>
            </div>

            <DrawingManager
              drawingMode='polygon'
              options={drawOptions}
              onLoad={onDrawLoad}
              onPolygonComplete={onDrawPolygonComplete}
            />
          </>
        ) : null}

        {forceRenderRegions && regions && regions.length
          ? regions.map(r => (
              <Polygon
                key={`region-polygon-${r.id}`}
                ref={e => (polygonRefs.current[r.id] = e)}
                options={selectedRegionId === r.id ? selectedPolygonOptions : unselectedPolygonOptions}
                editable={selectedRegionId === r.id ? true : false}
                paths={r.points}
                onLoad={onPolygonLoad(r)}
                onClick={onPolygonClick(r)}
                onRightClick={onPolygonRightClick(r)}
                onMouseUp={onPolygonMouseUp(r)}
              />
            ))
          : null}
      </GoogleMap>
    </LoadScript>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  mapContainer: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    cursor: 'pointer',
    overflow: 'hidden',
  },

  info: {
    zIndex: 2,
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
  infoBox: {
    width: 'fit-content',
    padding: theme.spacing(1, 2),
    borderRadius: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.default.light,
  },
  infoTxtL: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.contrast,
  },
  infoTxtS: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.contrastFade,
  },
}));
