function descendingComparator(a, b, orderBy) {
  let aProp = a[orderBy];
  let bProp = b[orderBy];
  if (typeof aProp === 'string') {
    aProp = aProp.trim().toLowerCase();
  }
  if (typeof bProp === 'string') {
    bProp = bProp.trim().toLowerCase();
  }
  if (!aProp) {
    return 1;
  }
  if (!bProp) {
    return -1;
  }
  if (bProp < aProp) {
    return -1;
  }
  if (bProp > aProp) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export { getComparator, stableSort };
