// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { FilterProvider } from './FilterProvider';
import FilterButton from './FilterButton';
import FilterPopover from './FilterPopover';

// COMPONENT ---------------------------------------------------------------- //

export default function Filter({
  baseFilters,
  localFilters,
  onApply,
  onClear,
  onToggle,
  config,
  tooltip,
  tooltipPlacement,
}) {
  const cls = useStyles();
  return (
    <div className={cls.root}>
      <FilterProvider
        baseFilters={baseFilters}
        localFilters={localFilters}
        onApply={onApply}
        onClear={onClear}
        onToggle={onToggle}
        config={config}
      >
        <FilterButton tooltip={tooltip} tooltipPlacement={tooltipPlacement} />
        <FilterPopover />
      </FilterProvider>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    width: 'fit-content',
  },
}));
