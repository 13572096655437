//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../components/ModalComponents';
import { useData } from '../providers/DataProvider';

//////////////////////// COMPONENT ////////////////////////
const AuthorizationWindowModal = ({ open, close, save, pendingAcc = {
  authorization: {
    id: 0,
    valid_from: 0,
    valid_to: 0
  }
}, pickupTime, durationSec }) => {

  const { authorization: { id: authId, valid_from, valid_to } } = pendingAcc;

  const ctx = useData();

  const [startTime, setStartTime] = React.useState(valid_from);
  const [endTime, setEndTime] = React.useState(valid_to);

  React.useEffect(() => {
    setStartTime(valid_from);
    setEndTime(valid_to);
  }, [open, valid_from, valid_to])

  const checkValidWindow = (startTime, endTime) => {
    const start_time = ctx._sdk.utilities.getLocalISOString(new Date(startTime));
    const end_time = ctx._sdk.utilities.getLocalISOString(new Date(endTime));
    if ((start_time && end_time) && (start_time < end_time)) {
      return false;
    } else {
      return true;
    }
  };

  const handleStartTimeChange = event => {
    setStartTime(dayjs(event).format());
  };

  const handleEndTimeChange = event => {
    setEndTime(dayjs(event).format());
  };

  const handleSave = () => {
    const start_time = ctx._sdk.utilities.getLocalISOString(new Date(startTime));
    const end_time = ctx._sdk.utilities.getLocalISOString(new Date(endTime));
    let authorization = {
      updated_at: 'now()',
      valid_from: start_time,
      valid_to: end_time
    };
    ctx._sdk.authorizations.update(authId, authorization);
    save();
    close();
  };

  const handleReset = () => {
    const start_time = ctx._sdk.utilities.getLocalISOString(ctx._sdk.utilities.addMinutes(-15, new Date(pickupTime)));
    const durationMin = Number(durationSec / 60)
    const end_time = ctx._sdk.utilities.getLocalISOString(ctx._sdk.utilities.addMinutes(Number(durationMin.toFixed(0)) + 15, new Date(pickupTime)));
    setStartTime(start_time);
    setEndTime(end_time);
  };

  return (
    <Modal open={open}>
      <ModalHeader handleClose={close}>Edit Authorization Window</ModalHeader>

      <ModalContent
        subtitle={`Enter start and end times to change the valid time window on this move's pending accessorial. Card swipes can only be accepted within this window of time. Click the 'Reset' button to automatically select times using the move's pickup time and duration. Your changes will be made upon clicking 'Save'.`}
      >

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TimePicker
                autoOk
                fullWidth
                minutesStep={5}
                variant='inline'
                orientation='portrait'
                label='Start Time'
                margin='none'
                inputVariant='outlined'
                value={startTime}
                onChange={handleStartTimeChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                autoOk
                fullWidth
                minutesStep={5}
                variant='inline'
                orientation='portrait'
                label='End Time'
                margin='none'
                inputVariant='outlined'
                value={endTime}
                onChange={handleEndTimeChange}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </ModalContent>

      <ModalFooter>
        <ModalAction disabled={checkValidWindow(startTime, endTime)} onClick={handleSave} color='primary'>
          Save
        </ModalAction>
        <ModalAction onClick={handleReset} color='secondary'>
          Reset
        </ModalAction>
        <ModalAction onClick={close}>Cancel</ModalAction>
      </ModalFooter>
    </Modal>
  );
};

//////////////////////// EXPORT ////////////////////////

export default AuthorizationWindowModal;
