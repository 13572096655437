import 'date-fns';
import React from 'react';
import { useSubscription } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { NotificationsTable } from './index';
import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import gql from 'graphql-tag';

function Notifications(props) {
  const cls = useStyles();

  const { loading, error, data, refetch } = useSubscription(GET_NOTIFICATIONS);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <DefaultErrorFallback message={'ERROR FETCHING NOTIFICATIONS'} />;
  }
  if (data && data.notifications && data.notifications.length < 1) {
    return <DefaultEmptyFallback message={'NO NOTIFICATIONS FOUND'} />;
  }
  if (data && data.notifications && data.notifications.length > 0) {
    return (
      <div className={cls.root}>
        <NotificationsTable notifications={data.notifications} refetch={refetch} />
      </div>
    );
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
}));

const GET_NOTIFICATIONS = gql`
  subscription get_notifications {
    notifications {
      id
      type
      title
      body
      assignedto
      assignedat
      assignedby
      resolvedat
      resolvedby
      createdat
      createdby
      notes
      duedate
    }
  }
`;

export default Notifications;
