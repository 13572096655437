// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import uuid from 'uuid';
import { toast } from 'react-toastify';

import { gql, useMutation } from '@apollo/client';

import { getRegionIdFromMove, getLaneDurationFromMove, getRideDurationFromMove } from '../../utils/MoveUtils';
import {
  getDisplayNameFromDriver,
  getRegionIdFromDriver,
  getRegionNameFromDriver,
  getArrayOfAttributesFromDriver,
} from '../../utils/DriverUtils';

import { useTools } from '../../hooks/useTools';

const log = false;

const maxGroupSize = 4;

const getEarliestPossiblePlanDate = date => {
  let earliestPossibleDate = dayjs();

  if (date) {
    const overrideDate = dayjs(date);
    if (earliestPossibleDate.isBefore(overrideDate)) {
      earliestPossibleDate = overrideDate;
    }
  }

  return earliestPossibleDate.format(`YYYY-MM-DD`);
};

// -------------------------------- CONTEXT -------------------------------- //

const PlansGroupContext = React.createContext({});

// -------------------------------- PROVIDER -------------------------------- //

function PlansGroupProvider({ children, date, preselectedDriverIds, preselectedMoveIds, preselectedRegionIds }) {
  const [selectedPlanDate, setSelectedPlanDate] = React.useState(getEarliestPossiblePlanDate(date));
  const [selectedPlanTime, setSelectedPlanTime] = React.useState(dayjs().format());

  const [selectedDriverIds, setSelectedDriverIds] = React.useState(preselectedDriverIds || []);
  const [selectedMoveIds, setSelectedMoveIds] = React.useState(preselectedMoveIds || []);
  const [selectedRegionIds, setSelectedRegionIds] = React.useState(preselectedRegionIds || []);

  const [selectedBundles, setSelectedBundles] = React.useState([]);

  const [rideActive, setRideActive] = React.useState(true);
  const [rideFirst, setRideFirst] = React.useState(false);
  const [rideType, setRideType] = React.useState(`auto`);

  const { goToPreviousPage } = useTools();

  // MUTATIONS //

  const [insertPlans] = useMutation(INSERT_PLANS);
  const [upsertMoves] = useMutation(UPSERT_MOVES);
  const [upsertRidegroups] = useMutation(UPSERT_RIDEGROUPS);

  // HANDLERS //

  const handleSelectDriverId = driverId => {
    setSelectedDriverIds(prev => {
      const index = prev.indexOf(driverId);
      if (index === -1) return [...prev, driverId];
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const handleSelectMoveId = moveId => {
    setSelectedMoveIds(prev => {
      const index = prev.indexOf(moveId);
      if (index === -1) return [...prev, moveId];
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const handleSelectRegionId = regionId => {
    setSelectedRegionIds(prev => {
      const index = prev.indexOf(regionId);
      if (index === -1) return [...prev, regionId];
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  const handleClearDriverIds = () => {
    setSelectedDriverIds([]);
  };

  const handleClearMoveIds = () => {
    setSelectedMoveIds([]);
  };

  const handleClearRegionIds = () => {
    setSelectedRegionIds([]);
  };

  // GETTERS //

  /** Get drivers that can be selected from the list (based on driver(s) criteria) */
  const getSelectableDrivers = (drivers, moves, plans) => {
    // If the max amount of drivers are selected, no more drivers can be selected
    if (selectedDriverIds?.length >= maxGroupSize) return [];

    // If there are any selected drivers or moves, start matching criteria
    if (selectedDriverIds?.length || selectedMoveIds?.length) {
      // Filter out the drivers that are already selected
      let filteredDrivers = drivers?.filter(m => !selectedDriverIds?.includes(m?.id));

      // Sort drivers based on selected moves
      const selectedMove = moves?.find(m => m?.id === selectedMoveIds?.[0]);
      const regionId = getRegionIdFromMove(selectedMove);
      if (regionId) {
        filteredDrivers = filteredDrivers?.sort((a, b) => {
          // SPECIAL SORTING //

          // Sort by drivers within the same region as a selected move
          const aMatchesRegionId = getRegionIdFromDriver(a) === regionId;
          const bMatchesRegionId = getRegionIdFromDriver(b) === regionId;
          if (aMatchesRegionId && !bMatchesRegionId) return -1;
          if (!aMatchesRegionId && bMatchesRegionId) return 1;

          // Sort by drivers with the railyard tag
          const aRailyardTrained = getArrayOfAttributesFromDriver(a)?.includes(`railyard`);
          const bRailyardTrained = getArrayOfAttributesFromDriver(b)?.includes(`railyard`);
          if (aRailyardTrained && !bRailyardTrained) return -1;
          if (!aRailyardTrained && bRailyardTrained) return 1;

          // Sort by drivers with an active plan
          const aHasActivePlan = plans?.find(p => p?.driver_id === a?.id);
          const bHasActivePlan = plans?.find(p => p?.driver_id === b?.id);
          if (aHasActivePlan && !bHasActivePlan) return -1;
          if (!aHasActivePlan && bHasActivePlan) return 1;

          // DEFAULT SORTING //

          // Sort by region name
          const aRegionName = getRegionNameFromDriver(a);
          const bRegionName = getRegionNameFromDriver(b);
          if (aRegionName < bRegionName) return -1;
          if (aRegionName > bRegionName) return 1;

          // Sort by display name
          const aDisplayName = getDisplayNameFromDriver(a);
          const bDisplayName = getDisplayNameFromDriver(b);
          if (aDisplayName < bDisplayName) return -1;
          if (aDisplayName > bDisplayName) return 1;

          // Otherwise, don't sort
          return 0;
        });
      }

      // Return the filtered drivers
      return filteredDrivers;
    }

    // If no drivers are selected, return the full list of drivers
    return drivers;
  };

  /** Get moves that can be selected from the list (based on move(s) criteria) */
  const getSelectableMoves = moves => {
    // If the max amount of moves are selected, no more moves can be selected
    if (selectedMoveIds?.length >= maxGroupSize) return [];

    // If there are any selected moves, start matching criteria
    if (selectedMoveIds?.length) {
      // Filter out the moves that are already selected
      let filteredMoves = moves?.filter(m => !selectedMoveIds?.includes(m?.id));

      // Find the lane id and only show moves from the same lane
      const laneId = moves?.find(m => m?.id === selectedMoveIds[0])?.lane?.id;
      filteredMoves = filteredMoves?.filter(m => m?.lane?.id === laneId);

      // Return the filtered moves
      return filteredMoves;
    }

    // If no moves are selected, return the full list of groupable moves
    return moves;
  };

  /** Get moves that can be grouped */
  const getGroupableMoves = moves => {
    if (moves?.length) {
      // Initialize an array to hold groupable moves
      let groupableMoves = [];

      // Loop through all moves
      for (let i = 0; i < moves.length; i++) {
        const move = moves[i];

        // If we find a different move with the same lane and date, add it to the groupable array
        if (
          moves.find(
            comparisonMove =>
              comparisonMove.id !== move.id &&
              comparisonMove?.lane?.id === move?.lane?.id &&
              (dayjs(comparisonMove?.ready_by).format(`YYYY-MM-DD`) === dayjs(move?.ready_by).format(`YYYY-MM-DD`) ||
                dayjs(comparisonMove?.pickup_time).format(`YYYY-MM-DD`) ===
                  dayjs(move?.pickup_time).format(`YYYY-MM-DD`))
          )
        ) {
          groupableMoves.push(move);
        }
      }

      // Return the groupable moves
      return groupableMoves;
    } else {
      return [];
    }
  };

  /** Get the default plan time */
  const getDefaultPlanTime = plans => {
    // Set the earliest possible time to the current time
    let earliestPossibleTime = dayjs();

    // If the selected date is in the future, set the earliest possible time to the start of the selected date
    if (earliestPossibleTime.isBefore(dayjs(selectedPlanDate))) earliestPossibleTime = dayjs(selectedPlanDate);

    // Map through each bundle and find the latest move ready by time
    selectedBundles?.forEach(bundle => {
      const moveReadyTime = dayjs(bundle?.move?.ready_by);
      if (earliestPossibleTime.isBefore(moveReadyTime)) earliestPossibleTime = moveReadyTime;
    });

    // Map through each plan's moves to find the latest move end time
    plans?.forEach(plan => {
      plan?.moves?.forEach(move => {
        let moveEndTime = null;

        if (move?.delivery_successful || move?.delivery_time)
          moveEndTime = dayjs(move?.delivery_successful || move?.delivery_time);
        else if (move?.move_type === `drive`)
          moveEndTime = dayjs(move?.pickup_time || move?.ready_by).add(getLaneDurationFromMove(move), `minute`);
        else if (move?.move_type === `ride`)
          moveEndTime = dayjs(move?.pickup_time || move?.ready_by).add(getRideDurationFromMove(move), `minute`);

        if (earliestPossibleTime.isBefore(moveEndTime)) earliestPossibleTime = moveEndTime;
      });
    });

    // Return the earliest possible time in UTC ISO format
    return earliestPossibleTime;
  };

  // PERSISTERS //

  /** Check for warnings before trying to save the plan */
  const checkSaveWarnings = () => {
    // Check for 2 or more selected bundles
    if (selectedBundles?.length < 2) {
      const msg = `You must have at least 2 drivers and 2 moves selected to form a group!`;
      console.warn(msg);
      toast.warning(msg);
      return true;
    }

    // Check for an equal amount of drivers and moves
    if (selectedDriverIds?.length !== selectedMoveIds?.length) {
      const msg = `You must have an equal amount of drivers and moves selected to form a group!`;
      console.warn(msg);
      toast.warning(msg);
      return true;
    }

    // Otherwise, return false
    return false;
  };

  /** Build the insertable plans array */
  const buildInsertablePlans = () => {
    let plansToInsert = [];

    // Only insert plans for bundles that don't already have a plan
    selectedBundles?.forEach(bundle => {
      if (!bundle?.plan) {
        const driverId = bundle?.driver?.id;
        const driverName = bundle?.driver?.user?.display_name;
        const regionId = bundle?.driver?.region_id;

        plansToInsert.push({
          driver_id: driverId,
          driver_name: driverName,
          plan_date: selectedPlanDate,
          region_id: regionId,
        });
      }
    });

    // Return the plans to insert
    return plansToInsert;
  };

  /** Build the upsertable moves array */
  const buildUpsertableMoves = (pickupTime, insertedPlans) => {
    let movesToUpsert = [];

    // Loop over the selected bundles and build the moves to upsert
    selectedBundles?.forEach(bundle => {
      // Find the bundle plan or related plan that was just inserted
      const foundPlan = bundle?.plan || insertedPlans?.find(p => p?.driver_id === bundle?.driver?.id);

      // Find the durations for the move(s)
      const laneDuration = getLaneDurationFromMove(bundle?.move);
      const rideDuration = getRideDurationFromMove(bundle?.move);

      // Find the pickup times for the move(s)
      const drivePickupTime = rideActive && rideFirst ? pickupTime.add(rideDuration, `minute`) : pickupTime;
      const ridePickupTime = rideActive && rideFirst ? pickupTime : pickupTime.add(laneDuration, `minute`);

      // Find the delivery times for the move(s)
      const driveDeliveryTime = drivePickupTime.add(laneDuration, `minute`);
      const rideDeliveryTime = ridePickupTime.add(rideDuration, `minute`);

      // Find the sequences for the move(s)
      const foundPlanSequence =
        foundPlan?.moves?.reduce((acc, move) => (move?.sequence > acc ? move?.sequence : acc), 0) || 0;
      const driveSequence = rideActive && rideFirst ? foundPlanSequence + 2 : foundPlanSequence + 1;
      const rideSequence = rideActive && rideFirst ? driveSequence - 1 : driveSequence + 1;

      // Upsert the drive move
      movesToUpsert.push({
        id: bundle?.move?.id,
        class: `stranded`,
        delivery_time: driveDeliveryTime.utc().format(),
        driver_app_version: bundle?.driver?.driver_app_version,
        driver_id: bundle?.driver?.id,
        driver_name: bundle?.driver?.user?.display_name,
        driver_status: `draft`,
        pickup_time: drivePickupTime.utc().format(),
        plan_id: foundPlan?.id,
        sequence: driveSequence,
        status: `dispatched`,
        updatedat: `now()`,
      });

      // Upsert the ride move
      if (rideActive) {
        movesToUpsert.push({
          class: `stranded`,
          customer_id: bundle?.move?.customer?.id,
          delivery_time: rideDeliveryTime.utc().format(),
          driver_app_version: bundle?.driver?.driver_app_version,
          driver_id: bundle?.driver?.id,
          driver_name: bundle?.driver?.user?.display_name,
          driver_status: `draft`,
          lane_id: bundle?.move?.lane?.inverse?.id,
          lyft_trigger_id: bundle?.move?.id,
          move_type: `ride`,
          parent_move_id: bundle?.move?.id,
          pickup_time: ridePickupTime.utc().format(),
          plan_id: foundPlan?.id,
          ride_type: rideType,
          sequence: rideSequence,
        });
      }
    });

    // Return the moves to upsert
    return movesToUpsert;
  };

  /** Build the insertable ridegroups array */
  const buildInsertableRidegroups = () => {
    let ridegroupsToInsert = [];

    // Generate the uuid for the group
    const groupId = uuid();

    // Insert a ridegroup for each bundled move
    selectedBundles?.forEach(bundle => {
      ridegroupsToInsert.push({
        drive_move_id: bundle?.move?.id,
        group_id: groupId,
      });
    });

    // Return the ridegroups to insert
    return ridegroupsToInsert;
  };

  /** Save the bundles of plans */
  const savePlansGroup = async () => {
    const plans = selectedBundles?.map(bundle => bundle?.plan);
    const defaultPlanTime = getDefaultPlanTime(plans);
    let planTime = dayjs(selectedPlanTime);

    // Check if the plan time is before the default plan time
    if (planTime.isBefore(defaultPlanTime)) {
      planTime = defaultPlanTime;
      setSelectedPlanTime(planTime.format());
    }

    // Make sure we have everything we need before persisting the changes
    if (checkSaveWarnings()) return;

    // Build the insertable plans
    let insertablePlans = buildInsertablePlans();
    let plansRes = null;

    // Check for insertable plans and try to insert them
    if (insertablePlans?.length) {
      try {
        plansRes = await insertPlans({
          variables: {
            insertablePlans,
          },
        });
        if (!plansRes?.data?.insert_plans?.affected_rows) throw new Error(`Error inserting plans!`);
        log && console.log(`Inserted Plans:`, plansRes?.data?.insert_plans);
      } catch (err) {
        console.error(`Failed to create plans:`, err);
        toast.error(`Failed to create plans!`);
        return;
      }
    }

    // Build the upsertable moves & ridegroups
    let upsertableMoves = buildUpsertableMoves(planTime, plansRes?.data?.insert_plans?.returning);
    let upsertableRidegroups = buildInsertableRidegroups();
    let movesRes = null;
    let ridegroupsRes = null;

    // Check for upsertable moves & ridegroups and try to upsert them
    if (upsertableMoves?.length && upsertableRidegroups?.length) {
      // Upsert the moves
      try {
        movesRes = await upsertMoves({
          variables: {
            upsertableMoves,
          },
        });
        if (!movesRes?.data?.insert_moves?.affected_rows) throw new Error(`Error upserting moves!`);
        log && console.log(`Upserted Moves:`, movesRes?.data?.insert_moves);
      } catch (err) {
        console.error(`Failed to create/update moves:`, err);
        toast.error(`Failed to create/update moves!`);
        return;
      }

      // Upsert the ridegroups
      try {
        ridegroupsRes = await upsertRidegroups({
          variables: {
            upsertableRidegroups,
          },
        });
        if (!ridegroupsRes?.data?.insert_ridegroups?.affected_rows) throw new Error(`Error upserting ridegroups!`);
        log && console.log(`Upserted Ridegroups:`, ridegroupsRes?.data?.insert_ridegroups);
      } catch (err) {
        console.error(`Failed to create/update ridegroups:`, err);
        toast.error(`Failed to create/update ridegroups!`);
        return;
      }
    }

    // If there are no moves or ridegroups to save, show a warning
    if (!upsertableMoves?.length || !upsertableRidegroups?.length) {
      const msg = `No moves/ridegroups to save!`;
      console.warn(msg);
      toast.warning(msg);
      return;
    }

    // Show a success message
    log && console.log(`Save group ran successfully!`);
  };

  /** Close the planning tool and return to the previous screen */
  const closePlansGroup = () => {
    goToPreviousPage();
  };

  /** Context for the provider and hook */
  const context = {
    // State

    selectedPlanDate,
    setSelectedPlanDate,
    selectedPlanTime,
    setSelectedPlanTime,
    selectedDriverIds,
    setSelectedDriverIds,
    selectedMoveIds,
    setSelectedMoveIds,
    selectedRegionIds,
    setSelectedRegionIds,
    selectedBundles,
    setSelectedBundles,
    rideActive,
    setRideActive,
    rideFirst,
    setRideFirst,
    rideType,
    setRideType,

    // Handlers

    handleSelectDriverId,
    handleSelectMoveId,
    handleSelectRegionId,
    handleClearDriverIds,
    handleClearMoveIds,
    handleClearRegionIds,

    // Getters

    getSelectableDrivers,
    getSelectableMoves,
    getGroupableMoves,
    getDefaultPlanTime,

    // Persisters

    savePlansGroup,
    closePlansGroup,

    // Constants

    maxGroupSize,
  };

  return <PlansGroupContext.Provider value={context}>{children}</PlansGroupContext.Provider>;
}

// -------------------------------- HOOK -------------------------------- //

const usePlansGroup = () => React.useContext(PlansGroupContext);

// -------------------------------- EXPORT -------------------------------- //

export { usePlansGroup, PlansGroupProvider };

// -------------------------------- GRAPHQL -------------------------------- //

const INSERT_PLANS = gql`
  mutation admin_plansGroup_insertPlans($insertablePlans: [plans_insert_input!]!) {
    insert_plans(objects: $insertablePlans) {
      affected_rows
      returning {
        id
        driver_id
        region_id
        plan_date
      }
    }
  }
`;

const UPSERT_MOVES = gql`
  mutation admin_plansGroup_upsertMoves($upsertableMoves: [moves_insert_input!]!) {
    insert_moves(
      objects: $upsertableMoves
      on_conflict: {
        constraint: moves_pkey
        update_columns: [
          class
          delivery_time
          driver_app_version
          driver_id
          driver_name
          driver_status
          pickup_time
          plan_id
          sequence
          status
          updatedat
        ]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const UPSERT_RIDEGROUPS = gql`
  mutation admin_plansGroup_upsertRidegroups($upsertableRidegroups: [ridegroups_insert_input!]!) {
    insert_ridegroups(
      objects: $upsertableRidegroups
      on_conflict: { constraint: ridegroups_drive_move_id_key, update_columns: [group_id] }
    ) {
      affected_rows
      returning {
        id
        drive_move_id
        group_id
      }
    }
  }
`;
