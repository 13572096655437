//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, useTheme, Grid, Typography, Icon, IconButton, Collapse, Tooltip, Chip } from '@material-ui/core/';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab/';
import gql from 'graphql-tag';
import sdk from '@hopdrive/sdk';
import { useQuery } from '@apollo/client';
import MoveOutcomeAdjustment from './EventRenderers/MoveOutcomeAdjustment';
import Spacer from '../../components/Spacer';
import { Button } from '@hopdrive/storybook';
import { REACT_APP_A0_CB_SD } from '../../utils/env';

//////////////////////// COMPONENT ////////////////////////

function MoveAuditTimelineItem({ timelineItem = {}, setRefetchingSmsInterval, ...props }) {
  const cls = useStyles();
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const [chipClickable, setChipClickable] = React.useState(false);
  const [pollInterval, setPollInterval] = React.useState(0);
  const [smsUpdatedat, setSmsUpdatedat] = React.useState();
  const [smsFailed, setSmsFailed] = React.useState(false);

  const GET_SMS_STATUS = gql`
    query get_sms_status($id: bigint!) {
      smsmessages(where: { id: { _eq: $id } }) {
        id
        status
        updatedat
      }
    }
  `;

  const UPDATE_SMS_STATUS = gql`
    mutation updateSMSStatus($id: bigint!) {
      update_smsmessages(where: { id: { _eq: $id } }, _set: { updatedat: "now()", status: "initiating" }) {
        returning {
          id
          status
          updatedat
        }
      }
    }
  `;

  const { loading, data } = useQuery(GET_SMS_STATUS, {
    variables: { id: timelineItem ? timelineItem.id : null },
    pollInterval: pollInterval,
  });

  React.useEffect(() => {
    if (data && data.smsmessages && data.smsmessages.length && data.smsmessages.length > 0) {
      if (data.smsmessages[0].status === 'sent' || data.smsmessages[0].status === 'failed') {
        setPollInterval(0);
        setRefetchingSmsInterval(0);
      }

      //If an SMS gets stuck in "initiating" status, it won't have have an error message.
      //So, check updatedat time to see if it's stuck, and if so, mark the SMS as failed
      let updatedTime;

      if (data.smsmessages[0].status && data.smsmessages[0].status === 'initiating' && data.smsmessages[0].updatedat) {
        updatedTime = new Date(data.smsmessages[0].updatedat).getTime();
      }

      if (updatedTime && Date.now() - updatedTime > 10000) {
        setPollInterval(0);
        setRefetchingSmsInterval(0);
        setSmsFailed(true);
      }
    }
  }, [data]);

  //Time out the SMS retry attempt if it fails to go through
  React.useEffect(() => {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkRetry = async (smsUpdatedat, sms) => {
      await delay(10000);
      if (sms.status && sms.status === 'initiating' && smsUpdatedat) {
        if (Date.now() - smsUpdatedat > 10000) {
          setSmsFailed(true);
          setPollInterval(0);
          setRefetchingSmsInterval(0);
        }
      }
    };

    if (
      smsUpdatedat &&
      data &&
      data.smsmessages.length > 0 &&
      data.smsmessages[0].status &&
      data.smsmessages[0].status === 'initiating'
    ) {
      checkRetry(smsUpdatedat, data.smsmessages[0]);
    }
  }, [smsUpdatedat]);

  const retrySms = async smsId => {
    setPollInterval(1000);
    setRefetchingSmsInterval(1000);
    setSmsFailed(false);
    setSmsUpdatedat(Date.now());

    try {
      let statusRes = await sdk.gql.mutation(UPDATE_SMS_STATUS, { id: smsId });
    } catch (err) {
      console.error(`Failed to reinitiate SMS:`, err);
    }
  };

  // Get the event log code to use
  const getLogCode = () => {
    //If the timeline item object includes actiontype, this is an event log
    if (timelineItem && timelineItem.actiontype && timelineItem.actiontype.name) return timelineItem.actiontype.name;
    //If not, it's an sms message
    if (
      timelineItem &&
      timelineItem.template_id &&
      timelineItem.template_id !== null &&
      timelineItem.status &&
      timelineItem.status === 'sent'
    )
      return 'sms.sent';
    if (
      timelineItem &&
      timelineItem.template_id &&
      timelineItem.template_id !== null &&
      timelineItem.status &&
      timelineItem.status === 'failed'
    )
      return 'sms.failed';
    if (
      timelineItem &&
      timelineItem.template_id &&
      timelineItem.template_id !== null &&
      timelineItem.status &&
      timelineItem.status === 'initiating' &&
      smsFailed
    )
      return 'sms.failed';
    if (
      timelineItem &&
      timelineItem.template_id &&
      timelineItem.template_id !== null &&
      timelineItem.status &&
      timelineItem.status === 'initiating' &&
      !smsFailed
    )
      return 'sms.retry';
    if (
      timelineItem &&
      timelineItem.template_id &&
      timelineItem.template_id !== null &&
      timelineItem.status &&
      timelineItem.status === 'sending'
    )
      return 'sms.retry';
    if (
      timelineItem &&
      (!timelineItem.template_id || timelineItem.template_id === null) &&
      timelineItem.status &&
      timelineItem.status === 'sent'
    )
      return 'sms.consumer.reply';
    else return null;
  };
  const logCode = getLogCode();

  // Get the event log name to render
  const getLogName = () => {
    if (logCode) {
      // let strArray = logCode.split(`.`);
      // strArray = strArray.map(item => item.charAt(0).toUpperCase() + item.slice(1));
      // return strArray.join(` `);
      if (logCode === 'hangtag.assigned') return 'Hangtag Assigned';
      if (logCode === 'hangtag.unassigned') return 'Hangtag Unassigned';
      if (logCode === 'move.cancel.failed') return 'Cancel Failed';
      if (logCode === 'move.cancel.partial') return 'Cancel Requested after Move Started';
      if (logCode === 'move.cancel.pending') return 'Pending Cancel';
      if (logCode === 'move.cancel.seen') return 'Cancel Request Seen';
      if (logCode === 'move.cancel.succeeded') return 'Canceled';
      if (logCode === 'move.rescheduled') return 'Move Rescheduled';
      if (logCode === 'move.created') return 'Created';
      if (logCode === 'move.details.updated') return 'Details Manually Updated';
      if (logCode === 'move.dispatched') return 'Dispatched to Driver';
      if (logCode === 'move.fuel.authorized') return 'Fuel Charge Authorized';
      if (logCode === 'move.lane.updated') return 'Lane Updated';
      if (logCode === 'sms.sent') return 'Concierge SMS Sent';
      if (logCode === 'move.outcome.adjustment') return 'Move Outcome Adjusted';
      if (logCode === 'move.planned') return 'Planned as Draft';
      if (logCode === 'move.return.created') return 'Hangtag Return Move Created';
      if (logCode === 'move.return.ready') return 'Hangtag Return Ready';
      if (logCode === 'move.status.overridden') return 'Status Manually Updated';
      if (logCode === 'move.times.updated') return 'Times Manually Updated';
      if (logCode === 'move.uncanceled') return 'Uncanceled';
      if (logCode === 'move.undispatched') return 'Unassigned from Driver';
      if (logCode === 'move.unplanned') return 'Unplanned as Draft';
      if (logCode === 'priceoverride.insert') return 'Price Override Created';
      if (logCode === 'priceoverride.update') return 'Price Override Updated';
      if (logCode === 'sms.consumer.reply') return 'Consumer Replied to Concierge SMS';
      if (logCode === 'sms.failed') return 'Concierge SMS Failed to Send';
      if (logCode === 'sms.retry') return 'Sending SMS...';
      if (logCode === 'sms.sent') return 'Concierge SMS Sent';
    } else return `Unknown Log`;
  };
  const logName = getLogName();

  // Get the event log time to render
  const getLogTime = () => {
    if (timelineItem && timelineItem.updatedat) return dayjs(timelineItem.updatedat).format('LLLL');
    else return `Unknown Time`;
  };
  const logTime = getLogTime();

  // Get the event log role to render
  const getLogRole = () => {
    if (timelineItem && timelineItem.roletype && timelineItem.roletype.name) return timelineItem.roletype.name;
    else return `system`;
  };
  const logRole = getLogRole();

  const getLogUser = () => {
    if (timelineItem && timelineItem.user) {
      return timelineItem.user;
    } else return `unknown`;
  };
  const logUser = getLogUser();

  // Get the event log icon to render
  const getLogIcon = () => {
    let icon = `info_outlined`;

    if (logCode && logCode.includes(`.adjustment`)) icon = `update`;
    if (logCode && logCode.includes(`.called`)) icon = `call`;
    if (logCode && logCode.includes(`.cancel`)) icon = `do_not_disturb_alt`;
    if (logCode && logCode.includes(`.canceled`)) icon = `do_not_disturb_alt`;
    if (logCode && logCode.includes(`.created`)) icon = `add`;
    if (logCode && logCode.includes(`.deleted`)) icon = `close`;
    if (logCode && logCode.includes(`.dispatched`)) icon = `subtitles`;
    if (logCode && logCode.includes(`.failed`)) icon = `error_outline`;
    if (logCode && logCode.includes(`.fuel`)) icon = `local_gas_station`;
    if (logCode && logCode.includes(`.insert`)) icon = `add`;
    if (logCode && logCode.includes(`.overridden`)) icon = `update`;
    if (logCode && logCode.includes(`.planned`)) icon = `subtitles`;
    if (logCode && logCode.includes(`.reply`)) icon = `phone_callback`;
    if (logCode && logCode.includes(`.retry`)) icon = `error_outline`;
    if (logCode && logCode.includes(`.sent`)) icon = `call`;
    if (logCode && logCode.includes(`.uncanceled`)) icon = `settings_backup_restore`;
    if (logCode && logCode.includes(`.undispatched`)) icon = `event_busy`;
    if (logCode && logCode.includes(`.unplanned`)) icon = `event_busy`;
    if (logCode && logCode.includes(`.update`)) icon = `update`;
    if (logCode && logCode.includes(`.updated`)) icon = `update`;

    return icon;
  };
  const logIcon = getLogIcon();

  // Get the event log color to use
  const getLogColor = () => {
    let color = theme.palette.text.primary;

    if (logCode && logCode.includes(`.adjustment`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.called`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.cancel`)) color = theme.palette.error.main;
    if (logCode && logCode.includes(`.canceled`)) color = theme.palette.error.main;
    if (logCode && logCode.includes(`.created`)) color = theme.palette.info.main;
    if (logCode && logCode.includes(`.deleted`)) color = theme.palette.error.main;
    if (logCode && logCode.includes(`.dispatched`)) color = theme.palette.success.main;
    if (logCode && logCode.includes(`.failed`)) color = theme.palette.error.light;
    if (logCode && logCode.includes(`.overridden`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.planned`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.reply`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.retry`)) color = theme.palette.error.light;
    if (logCode && logCode.includes(`.sent`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.uncanceled`)) color = theme.palette.success.main;
    if (logCode && logCode.includes(`.unplanned`)) color = theme.palette.error.light;
    if (logCode && logCode.includes(`.updated`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`move.created`)) color = theme.palette.success.main;

    return color;
  };
  const logColor = getLogColor();

  let smsMediaUrls = [];
  if (timelineItem && timelineItem.smsmedia && timelineItem.smsmedia.length > 0) {
    timelineItem.smsmedia.forEach(media => {
      smsMediaUrls.push(media.url);
    });
  }

  const getMetadataRenderer = () => {
    if (logCode === `move.outcome.adjustment`) {
      return (
        <Collapse in={expanded}>
          <div className={cls.collapse}>
            <div className={cls.collapseBox}>
              <MoveOutcomeAdjustment eventlog={timelineItem} />
            </div>
          </div>
        </Collapse>
      );
    } else if (timelineItem?.message_body) {
      return (
        <Collapse in={expanded}>
          <div className={cls.collapse}>
            <div className={cls.collapseBox}>
              <div className={cls.sms}>
                {timelineItem.error ? (
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item style={{ margin: '5px', color: 'red', fontWeight: 'bold' }}>
                      {timelineItem.error}
                    </Grid>
                  </Grid>
                ) : null}

                {smsFailed ? (
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item style={{ margin: '5px', color: 'red', fontWeight: 'bold' }}>
                      Error: Could not initialize SMS event handler
                    </Grid>
                  </Grid>
                ) : null}

                <Chip
                  style={{ margin: '5px' }}
                  variant={'outlined'}
                  size='small'
                  color={'black'}
                  label={timelineItem.recipient_phone ? `To: ${timelineItem.recipient_phone}` : null}
                />

                <Chip
                  style={{ margin: '5px' }}
                  variant={'outlined'}
                  size='small'
                  color={'black'}
                  label={timelineItem.sender_phone ? `From: ${timelineItem.sender_phone}` : null}
                />

                <Grid container spacing={2} alignItems='center'>
                  <Grid item style={{ margin: '5px' }}>
                    {timelineItem.message_body}
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems='center'>
                  {smsMediaUrls.length > 0
                    ? smsMediaUrls.map((media, index) => {
                        return (
                          <Grid style={{ margin: '5px' }} item key={index}>
                            <img className={cls.infoImage} src={media} alt='vehicle' />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </div>
            </div>
          </div>
        </Collapse>
      );
    } else if (timelineItem.metadata && timelineItem.actiontype.name === 'move.rescheduled') {
      return (
        <Collapse in={expanded} style={{ width: '95%' }}>
          <div className={cls.collapse}>
            <Typography>This move was rescheduled.</Typography>
            <Spacer size='xs' />
            <Button
              color='primary'
              onClick={async () => {
                window.open(
                  `https://${REACT_APP_A0_CB_SD}.hopdrive.io/moves/${timelineItem.metadata.newMoveId}`,
                  '_blank'
                );
              }}
            >
              View New Move
            </Button>
          </div>
        </Collapse>
      );
    } else if (timelineItem?.metadata) {
      return (
        <Collapse in={expanded}>
          <div className={cls.collapse}>
            <div className={cls.collapseBox}>
              <pre className={cls.json}>{JSON.stringify(timelineItem.metadata, null, 2)}</pre>
            </div>
          </div>
        </Collapse>
      );
    } else return null;
  };

  return (
    <>
      {timelineItem ? (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className={cls.timelineDot} style={{ background: logColor }}>
              <Icon>{logIcon}</Icon>
            </TimelineDot>

            {logCode !== `move.created` ? <TimelineConnector /> : null}
          </TimelineSeparator>

          <TimelineContent style={{ width: '100%' }}>
            <div className={cls.paper} onClick={() => setExpanded(!expanded)}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs>
                  <Typography className={cls.timeText}>
                    {logTime} -{' '}
                    <strong>
                      by {logUser} ({logRole})
                    </strong>
                  </Typography>

                  <Typography className={cls.title}>
                    {logName}
                    {timelineItem.metadata || timelineItem.message_body ? (
                      <Tooltip placement='top' title='View details'>
                        <IconButton className={cls.iconButton} onClick={() => setExpanded(!expanded)}>
                          <Icon>{expanded ? `expand_less` : `expand_more`}</Icon>
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {timelineItem.status && timelineItem.status === 'failed' ? (
                      <Chip
                        clickable={chipClickable}
                        variant={'outlined'}
                        size='small'
                        color={'primary'}
                        label={'Retry'}
                        onClick={() => retrySms(timelineItem.id)}
                      />
                    ) : null}

                    {smsFailed ? (
                      <Chip
                        clickable={chipClickable}
                        variant={'outlined'}
                        size='small'
                        color={'primary'}
                        label={'Retry'}
                        onClick={() => retrySms(timelineItem.id)}
                      />
                    ) : null}
                  </Typography>
                </Grid>
              </Grid>

              {getMetadataRenderer()}
            </div>
          </TimelineContent>
        </TimelineItem>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    cursor: 'pointer',
    transition: '0.15s',
  },
  timelineDot: {
    boxShadow: `none`,
  },
  title: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
  },
  timeText: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  iconButton: {
    padding: `8px !important`,
    marginLeft: theme.spacing(0.5),
  },
  collapse: {
    overflow: 'hidden',
    marginTop: theme.spacing(0.5),
    width: `100%`,
  },
  collapseBox: {
    padding: 12,
    borderRadius: 8,
    backgroundColor: theme.palette.background.main,
  },
  sms: {
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: theme.palette.text.primary,
  },
  json: {
    padding: '10px',
    margin: '10px 0',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: '#333',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  infoImage: {
    position: 'relative',
    display: 'block',
    verticalAlign: 'top',
    objectFit: 'cover',
    width: '128px',
    height: '128px',
    background: theme.palette.fade[3],
    boxShadow: theme.shadow.soft,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveAuditTimelineItem;
