import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  OutlinedInput,
  Button,
  Icon,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RateRulesTable from './RateRulesTable';
import ProductFeeTable from './ProductFeeTable';
import AllowedSlas from './AllowedSlas';
import CustomerPricingInsurance from './CustomerPricingInsurance';
import CustomerPricingRideshareRates from './CustomerPricingRideshareRates';
import DatetimeSelect from './DatetimeSelect';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { useData } from '../../providers/DataProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment-timezone';
import { getUserEmail } from '../../utils/authHelper';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

const log = false;

let beginDateBefore,
  endDateBefore,
  nameBefore,
  descriptionBefore = null;

export default function RateRuleGroupEditor({ customer, slas, rateRuleGroup, onHasChanges, refetch }) {
  const cls = useStyles();
  const theme = useTheme();
  const { apolloClient } = useData();

  const [hasChanges, setHasChanges] = useState(false);
  const [beginUtc, setBeginUtc] = useState(null);
  const [endUtc, setEndUtc] = useState(null);
  const [saveableBeginDate, setSaveableBeginDate] = useState(null);
  const [saveableEndDate, setSaveableEndDate] = useState(null);
  const [beginDisplayDate, setBeginDisplayDate] = useState(null);
  const [endDisplayDate, setEndDisplayDate] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [nameEditingEnabled, setNameEditingEnabled] = useState(false);
  const [descriptionEditingEnabled, setDescriptionEditingEnabled] = useState(false);
  const [rateRules, setRateRules] = useState(rateRuleGroup && rateRuleGroup.rateRules);
  const [actionsOpen, setActionsOpen] = useState(null);
  const [productFees, setProductFees] = useState([]);
  const [slasState, setSlasState] = useState([]);
  const [newInsuranceRateId, setNewInsuranceRateId] = useState(null);
  const [newRideshareRateId, setNewRideshareRateId] = useState(null);
  const [customerTimezone, setCustomerTimezone] = useState(null);
  const [customerTimezoneText, setCustomerTimezoneText] = useState(null);
  const [newDateBegin, setNewDateBegin] = useState(null);
  const [newDateEnd, setNewDateEnd] = useState(null);

  //Get customer's timezone and display rate rule group effective dates accordingly
  useEffect(() => {
    if (customer && customer.location && customer.location.timezone) {
      setCustomerTimezone(customer.location.timezone);

      if (customer.location.timezone === 'America/New_York') {
        setCustomerTimezoneText('Eastern');
      } else if (customer.location.timezone === 'America/Chicago') {
        setCustomerTimezoneText('Central');
      } else if (customer.location.timezone === 'America/Phoenix') {
        setCustomerTimezoneText('Arizona');
      } else if (customer.location.timezone === 'America/Denver') {
        setCustomerTimezoneText('Mountain');
      } else if (customer.location.timezone === 'America/Los_Angeles') {
        setCustomerTimezoneText('Pacific');
      }
    }
  }, [customer]);

  useEffect(() => {
    if (beginDisplayDate) {
      let newDate = new Date(beginDisplayDate);
      setNewDateBegin(newDate);
    }

    if (endDisplayDate) {
      let newDate = new Date(endDisplayDate);
      setNewDateEnd(newDate);
    }
  }, [beginDisplayDate, endDisplayDate]);

  useEffect(() => {
    if (!saveableBeginDate && !saveableEndDate) {
      return;
    } else if (
      newDateBegin &&
      saveableBeginDate &&
      saveableBeginDate.getTime() === newDateBegin.getTime() &&
      newDateEnd &&
      saveableEndDate &&
      saveableEndDate.getTime() === newDateEnd.getTime()
    ) {
      return;
    } else if ((saveableBeginDate && !saveableEndDate) || (!saveableBeginDate && saveableEndDate)) {
      log && console.log('A date has changed : saveable begin or end');
      setHasChanges(true);
    } else if (!saveableBeginDate && !saveableEndDate) {
      return;
    } else {
      log && console.log('A date has changed : idk');
      setHasChanges(true);
    }
  }, [saveableBeginDate, saveableEndDate, newDateBegin, newDateEnd]);

  useEffect(() => {
    if (beginUtc) {
      const utcDate = new Date(beginUtc);
      setSaveableBeginDate(utcDate);
    }
    if (endUtc) {
      const utcDate = new Date(endUtc);
      setSaveableEndDate(utcDate);
    }
  }, [beginUtc, endUtc]);

  useEffect(() => {
    if (!rateRuleGroup) return;
    log &&
      console.log(
        'rateRuleGroup prop changed',
        rateRuleGroup,
        rateRuleGroup.productfees,
        rateRuleGroup.insurancerate,
        rateRuleGroup.ridesharerate
      );

    //Default all working values
    setHasChanges(false);
    const customerBeginTime = moment.tz(rateRuleGroup && rateRuleGroup.begin_date, customerTimezone); //Convert UTC to the customer's timezone
    const customerEndTime = moment.tz(rateRuleGroup && rateRuleGroup.end_date, customerTimezone); //Convert UTC to the customer's timezone
    beginDateBefore = customerBeginTime.format();
    endDateBefore = customerEndTime.format();
    nameBefore = rateRuleGroup && rateRuleGroup.name;
    descriptionBefore = rateRuleGroup && rateRuleGroup.description;
    setBeginDisplayDate(beginDateBefore);
    setEndDisplayDate(endDateBefore);
    setName(nameBefore);
    setRateRules(rateRuleGroup.raterules);
    setDescription(descriptionBefore);
    setProductFees(rateRuleGroup.productfees);
  }, [rateRuleGroup]);

  useEffect(() => {
    if (!name || !nameBefore) return;
    if (nameBefore === name) return;
    log && console.log('The name has changed');
    setHasChanges(true);
  }, [name]);

  useEffect(() => {
    if (!description || !descriptionBefore) return;
    if (descriptionBefore === description) return;
    log && console.log('The description has changed');
    setHasChanges(true);
  }, [description]);

  useEffect(() => {
    log && console.log('HasChanges changed!', hasChanges);
    if (hasChanges) {
      onHasChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges]);

  useEffect(() => {
    const existingSlas = rateRuleGroup?.slas_to_raterulegroup?.map(sla => sla?.sla);
    setSlasState(existingSlas || []);
  }, [rateRuleGroup]);

  const handleSlasStateChange = slasToSet => {
    setSlasState(slasToSet);
  };

  const handleInsuranceFeeChange = insuranceRate => {
    log && console.log('About to set hasChanges to true');
    setHasChanges(true);
    setNewInsuranceRateId(insuranceRate.id);
    log && console.log(`Insurance Rate changed`, insuranceRate);
  };

  const handleRideshareFeeChange = rideshareRate => {
    log && console.log('About to set hasChanges to true');
    setHasChanges(true);
    setNewRideshareRateId(rideshareRate.id);
    log && console.log(`Rideshare Rate changed`, rideshareRate);
  };

  const handleProductFeeChange = productFees => {
    log && console.log('About to set hasChanges to true');
    setHasChanges(true);
    setProductFees(productFees);
    log && console.log(`Product fees changed`, productFees);
  };

  const handleRateRulesChange = rateRules => {
    log && console.log('About to set hasChanges to true');
    setHasChanges(true);
    setRateRules(rateRules);
    log && console.log(`Rate rules changed`, rateRules);
  };

  //Ian Schu 07/26/2023 - this new duplicate function is very similar to the handleSaveChanges function
  //  which used to be used to create a duplicate. However, I found using the same function for both to cause a lot of small issues,
  //  to the point where it was a better solution to make a new function, even though it contains very similar code.
  //  I think it also improves the readability of the code to seperate the two actions into different functions
  const duplicate = async () => {
    log && console.log('Duplicating the rate rule group');

    let newRateRuleGroup = Object.assign({}, rateRuleGroup);

    //Delete values that prevent insert, as well as the nested values (to be replaced)
    delete newRateRuleGroup.id;
    delete newRateRuleGroup.__typename;
    delete newRateRuleGroup.createdat;
    delete newRateRuleGroup.updatedat;
    delete newRateRuleGroup.raterules;
    delete newRateRuleGroup.productfees;
    delete newRateRuleGroup.insurancerate;
    delete newRateRuleGroup.ridesharerate;
    delete newRateRuleGroup.slas_to_raterulegroup;

    //Change the name so the copy can be easily distinguished from the orignal
    newRateRuleGroup.name = `${rateRuleGroup.name} (copy)`;

    //We need to force the child rate rule rows to go into the mutation by saying they have changes
    // and removing the id from each as well
    if (rateRuleGroup && Array.isArray(rateRuleGroup.raterules) && rateRuleGroup.raterules.length > 0) {
      log && console.log('duplicating child rate rules', rateRuleGroup.raterules);
      let rateRulesForMutation = [];
      rateRuleGroup.raterules.forEach(rateRule => {
        let rateRuleForMutation = {
          ...rateRule,
          customer_id: customer.id,
        };
        //rate_rule_group_id will be determined through nested insert relationship
        delete rateRuleForMutation.rate_rule_group_id;
        delete rateRuleForMutation.hasChanges;
        delete rateRuleForMutation.__typename;
        delete rateRuleForMutation.id;
        rateRulesForMutation.push(rateRuleForMutation);
      });
      //Nest rate rules into raterulegroup obj so we can update all tables with one mutation
      const updateRateRulesCols = Object.keys(rateRulesForMutation[0]).filter(key => key !== 'id');
      log && console.log(updateRateRulesCols, 'updateRateRulesCols');
      const nestedRatesData = {
        data: rateRulesForMutation,
        on_conflict: {
          constraint: 'idx_21717_primary',
          update_columns: updateRateRulesCols,
        },
      };
      newRateRuleGroup.raterules = nestedRatesData;
    }
    //Then we do the same for the productfees
    if (rateRuleGroup && Array.isArray(rateRuleGroup.productfees) && rateRuleGroup.productfees.length > 0) {
      log && console.log('duplicating child product fees', rateRuleGroup.productfees);
      //get user email from profile to save into updatedAt
      let userEmail = getUserEmail() || 'unknown';
      let productFeesForMutation = [];
      rateRuleGroup.productfees.forEach(productFee => {
        let productFeeForMutation = {
          ...productFee,
        };
        productFeeForMutation.createdBy = userEmail;
        productFeeForMutation.updatedBy = userEmail;
        delete productFeeForMutation.id;
        delete productFeeForMutation.rate_rule_group_id;
        delete productFeeForMutation.hasChanges;
        delete productFeeForMutation.__typename;
        delete productFeeForMutation.product;
        productFeesForMutation.push(productFeeForMutation);
      });
      //Nest product fees into raterulegroup obj so we can update all tables with one mutation
      const updateProductFeesCols = Object.keys(productFeesForMutation[0]).filter(
        key => key !== 'id' && key !== 'billable_date'
      );
      log && console.log(updateProductFeesCols, 'updateProductFeesCols', productFeesForMutation);
      const nestedProductFees = {
        data: productFeesForMutation,
        on_conflict: {
          constraint: 'product_fees_pkey',
          update_columns: updateProductFeesCols,
        },
      };
      newRateRuleGroup.productfees = nestedProductFees;
    }
    //Then finally, duplicate the insurance rate
    log && console.log('duplicating insurancerate', rateRuleGroup.insurancerate);
    newRateRuleGroup.insurance_rate_id = rateRuleGroup.insurancerate ? rateRuleGroup.insurancerate.id : null;
    //...and the rideshare rate
    log && console.log('duplicating ridesharerate', rateRuleGroup.ridesharerate);
    newRateRuleGroup.rideshare_rate_id = rateRuleGroup.ridesharerate ? rateRuleGroup.ridesharerate.id : null;
    //set new dates for duplicated raterulegroup
    let beginDate = dayjs().startOf('month').format('YYYY/MM/DD');
    let endDate = dayjs().endOf('month').add(5, 'year').format('YYYY/MM/DD');
    newRateRuleGroup.begin_date = beginDate;
    newRateRuleGroup.end_date = endDate;
    log && console.log('NEW: ', newRateRuleGroup);
    try {
      const dupeRes = await pushRateRuleGroupToServer(newRateRuleGroup);
      if (dupeRes && dupeRes.data && dupeRes.data.insert_raterulegroups) {
        refetch();
      } else {
        console.error('Failed to duplicate the rate rule group');
        toast.error('Failed to duplicate the rate rule group');
      }
    } catch (err) {
      console.error('Failed to duplicate the rate rule group', err);
      toast.error('Failed to duplicate the rate rule group');
    }
  };

  const confirmationMessage = `Are you sure you want to duplicate this rate rule group?

Doing so will create a new set with all of the mileage bands below duplicated as well.

If you do, please make sure to prevent overlapping effective and expiration dates of multiple groups.`;

  const globalActions = [
    {
      label: `Duplicate`,
      handler: () => {
        if (window.confirm(confirmationMessage)) duplicate();
      },
    },
  ];

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };

  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const handleSaveChanges = async () => {
    log && console.log('User clicked the save changes button');
    log && console.log('Rate Rule Group prior to mutation prep: ', rateRuleGroup);

    //Build up a new object for mutation
    let rateRuleGroupForMutation = {
      ...rateRuleGroup,
      begin_date: saveableBeginDate ? saveableBeginDate.toISOString() : beginDateBefore,
      end_date: saveableEndDate ? saveableEndDate.toISOString() : endDateBefore,
      customer_id: customer.id,
      name: name,
      description: description,
    };

    //Remove properties we do not intend to update in the mutation
    delete rateRuleGroupForMutation.updatedat;
    delete rateRuleGroupForMutation.createdat;
    delete rateRuleGroupForMutation.raterules;
    delete rateRuleGroupForMutation.productfees;
    delete rateRuleGroupForMutation.insurancerate;
    delete rateRuleGroupForMutation.ridesharerate;
    delete rateRuleGroupForMutation.slas_to_raterulegroup;
    if (rateRuleGroupForMutation.id < 1 || !rateRuleGroupForMutation.id) delete rateRuleGroupForMutation.id;
    delete rateRuleGroupForMutation.__typename;

    log && console.log('Rate Rules prior to mutation prep: ', rateRules);

    //Remove any temporary hasChanges properties from rate rule rows
    if (rateRules && rateRules.length > 0) {
      let rateRulesForMutation = [];
      rateRules
        .filter(o => o.hasChanges)
        .forEach(rateRule => {
          let rateRuleForMutation = {
            ...rateRule,
            customer_id: customer.id,
          };
          //rate_rule_group_id will be determined through nested insert relationship
          delete rateRuleForMutation.rate_rule_group_id;
          delete rateRuleForMutation.hasChanges;
          delete rateRuleForMutation.__typename;
          if (rateRuleForMutation.id < 1 || !rateRuleForMutation.id) delete rateRuleForMutation.id;
          rateRulesForMutation.push(rateRuleForMutation);
        });
      if (rateRulesForMutation.length > 0) {
        //Nest rate rules into raterulegroup obj so we can update all tables with one mutation
        const updateRateRulesCols = Object.keys(rateRulesForMutation[0]).filter(key => key !== 'id');
        log && console.log(updateRateRulesCols, 'updateRateRulesCols');
        const nestedRatesData = {
          data: rateRulesForMutation,
          on_conflict: {
            constraint: 'idx_21717_primary',
            update_columns: updateRateRulesCols,
          },
        };
        rateRuleGroupForMutation.raterules = nestedRatesData;
        log && console.log('Rate Rules prepared for mutation: ', JSON.stringify(rateRulesForMutation, null, 2));
      }
    }

    //If there is a new insurance rate, add the id to the rate rule group to save the relationship
    if (newInsuranceRateId) {
      rateRuleGroupForMutation.insurance_rate_id = newInsuranceRateId;
    }

    //If there is a new rideshare rate, add the id to the rate rule group to save the relationship
    if (newRideshareRateId) {
      rateRuleGroupForMutation.rideshare_rate_id = newRideshareRateId;
    }

    //Remove any temporary hasChanges properties from product fees rows
    if (productFees && productFees.length > 0) {
      //get user email to save into updatedAt
      let userEmail = getUserEmail() || 'unknown';
      let productFeesForMutation = [];
      productFees
        .filter(o => o.hasChanges)
        .forEach(productFee => {
          let productFeeForMutation = {
            ...productFee,
            // rate_rule_group_id: rateRuleGroupForMutation.id,
            // customer_id: customer.id,
          };
          if (!productFeeForMutation.createdBy) productFeeForMutation.createdBy = userEmail;
          if (productFeeForMutation.id < 0) delete productFeeForMutation.id;
          productFeeForMutation.updatedBy = userEmail;
          delete productFeeForMutation.hasChanges;
          delete productFeeForMutation.__typename;
          delete productFeeForMutation.product;
          if (productFeeForMutation.id < 1 || !productFeeForMutation.id) delete productFeeForMutation.id;
          productFeesForMutation.push(productFeeForMutation);
        });
      //Nest product fees into raterulegroup obj so we can update all tables with one mutation
      if (productFeesForMutation.length > 0) {
        const updateProductFeesCols = Object.keys(productFeesForMutation[0]).filter(
          key => key !== 'id' && key !== 'billable_date'
        );
        log && console.log(updateProductFeesCols, 'updateProductFeesCols', productFeesForMutation);
        const nestedProductFees = {
          data: productFeesForMutation,
          on_conflict: {
            constraint: 'product_fees_pkey',
            update_columns: updateProductFeesCols,
          },
        };
        rateRuleGroupForMutation.productfees = nestedProductFees;
        log && console.log('Product Fees prepared for mutation: ', JSON.stringify(productFeesForMutation, null, 2));
      }
    }

    setNameEditingEnabled(false);
    setDescriptionEditingEnabled(false);

    log && console.log('Rate Rule Group prepared for mutation: ', JSON.stringify(rateRuleGroupForMutation, null, 2));

    try {
      let newRateGroupCreated = false;
      const res = await pushRateRuleGroupToServer(rateRuleGroupForMutation);
      if (res) {
        const returned = res.data.insert_raterulegroups.returning[0];
        log && console.log(`pushRateRuleGroupToServer returned:`, returned);
        newRateGroupCreated = rateRuleGroupForMutation.id !== returned.id;
        rateRuleGroupForMutation.id = returned.id;
        log && console.log(`newRateGroupCreated:`, newRateGroupCreated);
        toast.success(`The rate rule group was saved successfully`);
        setHasChanges(false);
        setSaveableBeginDate(null);
        setSaveableEndDate(null);
        refetch();
      }
    } catch (error) {
      toast.error(`Failed to save the rate rule group`);
      console.error('Something went wrong running the raterulegroup mutation', error);
    }
  };

  const pushRateRuleGroupToServer = async rateRuleGroupForMutation => {
    const updateCols = Object.keys(rateRuleGroupForMutation).filter(
      key => key !== 'id' && key !== 'productfees' && key !== 'raterules'
    );
    log && console.log('RATERULEGROUP MUTATION: ', rateRuleGroupForMutation, updateCols);
    return apolloClient.mutate({
      mutation: gql`
        mutation upsertRateRuleGroup(
          $rateRuleGroup: [raterulegroups_insert_input!]!
          $cols: [raterulegroups_update_column!]!
        ) {
          insert_raterulegroups(
            objects: $rateRuleGroup
            on_conflict: { constraint: idx_25769_primary, update_columns: $cols }
          ) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
      variables: {
        rateRuleGroup: [rateRuleGroupForMutation],
        cols: updateCols,
      },
    });
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING RATE GROUP DETAILS' />}>
      <div className={cls.root}>
        {rateRuleGroup && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
              <Grid item md={8}>
                {nameEditingEnabled && (
                  <FormControl fullWidth className={cls.margin} variant='outlined'>
                    <OutlinedInput
                      id='name'
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
                {!nameEditingEnabled && (
                  <Typography
                    className={cls.headTxt}
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      setNameEditingEnabled(true);
                    }}
                  >
                    {rateRuleGroup.name}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3}>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }} component='div'>
                  <Button
                    variant='outlined'
                    color='primary'
                    size='medium'
                    startIcon={<Icon>check_circle</Icon>}
                    onClick={handleSaveChanges}
                    disabled={!hasChanges}
                  >
                    Save Changes
                  </Button>
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Tooltip placement='top' title={`Actions`}>
                  <IconButton
                    style={{ marginTop: '-4px', color: theme.palette.text.secondary }}
                    className={cls.iconBtn}
                    onClick={handleActionsOpen}
                  >
                    <Icon>settings</Icon>
                  </IconButton>
                </Tooltip>
                <Menu
                  keepMounted
                  id={`customer-actions-menu`}
                  anchorEl={actionsOpen}
                  open={Boolean(actionsOpen)}
                  onClose={handleActionsClose}
                >
                  {globalActions.map((action, i) =>
                    !action.hide ? (
                      <MenuItem key={`customer-action-${i}`} onClick={() => handleAction(action)}>
                        {action.label || `Action ${i + 1}`}
                      </MenuItem>
                    ) : null
                  )}
                </Menu>
              </Grid>
              <Grid item md={12}>
                {descriptionEditingEnabled && (
                  <FormControl fullWidth className={cls.margin} variant='outlined'>
                    <OutlinedInput
                      id='description'
                      value={description}
                      onChange={e => {
                        setDescription(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
                {!descriptionEditingEnabled && (
                  <Typography
                    style={{ fontSize: 'small' }}
                    onClick={() => {
                      setDescriptionEditingEnabled(true);
                    }}
                  >
                    {rateRuleGroup.description}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12}>
                <Typography style={{ marginBottom: '1rem', fontSize: 'small' }}>
                  Effective dates are saved in GMT and displayed below in{' '}
                  {customer?.location?.timezone
                    ? `the customer's timezone (${customer.location.timezone}).`
                    : `EST by default.`}
                  Select a timezone from the dropdown if you would like to view the effective dates from a different
                  timezone.
                </Typography>
                <DatetimeSelect
                  type='begin'
                  timeData={beginDisplayDate}
                  onChange={setBeginUtc}
                  useUtc
                  style={beginDateBefore !== beginDisplayDate ? { backgroundColor: '#ffe9ec' } : {}}
                  timezone={customerTimezone}
                />
                <DatetimeSelect
                  type='end'
                  timeData={endDisplayDate}
                  onChange={setEndUtc}
                  useUtc
                  style={endDateBefore !== endDisplayDate ? { backgroundColor: '#ffe9ec' } : {}}
                  timezone={customerTimezone}
                />
              </Grid>

              <Grid item md={12}>
                <AllowedSlas
                  raterulegroupId={rateRuleGroup?.id}
                  allSlas={slas}
                  slasState={slasState}
                  onChange={handleSlasStateChange}
                />
                <br />
                <CustomerPricingInsurance
                  insuranceRate={rateRuleGroup?.insurancerate}
                  onChange={handleInsuranceFeeChange}
                  setHasChanged={setHasChanges}
                />
                <br></br>
                <CustomerPricingRideshareRates
                  rideshareRate={rateRuleGroup.ridesharerate}
                  onChange={handleRideshareFeeChange}
                  setHasChanged={setHasChanges}
                />
                <br></br>
                <ProductFeeTable
                  productFees={productFees}
                  onChange={handleProductFeeChange}
                  setHasChanged={setHasChanges}
                />
                <br />
                <RateRulesTable rateRules={rateRuleGroup?.raterules} onChange={handleRateRulesChange} />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    height: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  newVersionBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  headTxt: {
    //lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
}));
