import React from 'react';
import { ModalContent, ModalHeader, ModalFooter, ModalAction } from '../../components/ModalComponents';
import { TextField } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import { ADD_CHASE_VEHICLE, GET_CHASE_VEHICLES } from './gql';
import { useMutation } from '@apollo/client';

export default function AddChaseVehicleContent({ onClose }) {
  const [vin, setVin] = React.useState(``);
  const [make, setMake] = React.useState(``);
  const [model, setModel] = React.useState(``);
  const [capacity, setCapacity] = React.useState(``);

  const [addChaseVehicle] = useMutation(ADD_CHASE_VEHICLE, {
    refetchQueries: [{ query: GET_CHASE_VEHICLES }],
    onCompleted: () => {
      onClose();
    },
  });

  const handleAdd = React.useCallback(() => {
    addChaseVehicle({
      variables: { input: { vin, make, model, capacity } },
    });
  }, [vin, make, model, capacity, addChaseVehicle]);

  const handleInputChange = React.useCallback(
    setValue => event => {
      setValue(event.target.value);
    },
    []
  );

  return (
    <>
      <ModalHeader handleClose={onClose}>Add Chase Vehicle</ModalHeader>
      <ModalContent>
        <TextField
          required
          id='vin-input'
          label='Vehicle Vin'
          value={vin}
          onChange={handleInputChange(setVin)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='make-input'
          label='Vehicle Make'
          value={make}
          onChange={handleInputChange(setMake)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='model-input'
          label='Vehicle Model'
          value={model}
          onChange={handleInputChange(setModel)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='capacity-input'
          label='Vehicle Capacity'
          value={capacity}
          onChange={handleInputChange(setCapacity)}
          autoComplete='off'
          fullWidth
        />
      </ModalContent>
      <ModalFooter>
        <ModalAction color='primary' disabled={!vin || !make || !model || !capacity} onClick={handleAdd}>
          Add
        </ModalAction>
        <ModalAction onClick={onClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}
