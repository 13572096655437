// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, Grid, Icon, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

// COMPONENT ---------------------------------------------------------------- //

export default function PayCycleDetailsHeader({ paycycle, payMode, setPayMode }) {
  const cls = useStyles();

  const { formatUSD } = useTools();
  const {
    getDriverCountFromPaycycle,
    getMoveCountFromPaycycle,
    getAccessorialCountFromPaycycle,
    getMileageCountFromPaycycle,
    getAmountPaidFromPaycycle,
    getAmountDueFromPaycycle,
  } = useDriverPay();

  const amountPaid = getAmountPaidFromPaycycle(paycycle);
  const amountDue = getAmountDueFromPaycycle(paycycle);
  const amountRemaining = amountDue - amountPaid;

  const showPayButton = () => {
    if (payMode || amountRemaining === 0) return false;
    return true;
  };

  return (
    <div className={cls.header}>
      <div className={cls.headerCover} />

      <div className={cls.flexHeader}>
        <div className={cls.flexHeaderItem1}>
          <Typography className={cls.headerTxt}>Total Driver Pay</Typography>

          <div className={cls.flex}>
            <Typography className={cls.amountDueTxt}>
              {formatUSD(amountDue, { displayAccountingNegative: true })}
            </Typography>

            {showPayButton() ? (
              <Button className={cls.btn} size='large' color='primary' onClick={() => setPayMode(!payMode)}>
                <Icon className={cls.btnIcon}>payment</Icon>
                PAY
              </Button>
            ) : null}
          </div>

          <Typography className={cls.amountRemainingTxt}>
            <b>{formatUSD(amountRemaining, { displayAccountingNegative: true })}</b> remaining to be paid
          </Typography>
        </div>

        <div className={cls.flexHeaderItem2}>
          <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
            <Grid item lg={3} md={6} xs={12}>
              <div className={cls.metricBox}>
                <Typography className={cls.metricValTxt}>{getDriverCountFromPaycycle(paycycle)}</Typography>
                <Typography className={cls.metricKeyTxt}>Drivers</Typography>
              </div>
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <div className={cls.metricBox}>
                <Typography className={cls.metricValTxt}>{getMoveCountFromPaycycle(paycycle)}</Typography>
                <Typography className={cls.metricKeyTxt}>Moves</Typography>
              </div>
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <div className={cls.metricBox}>
                <Typography className={cls.metricValTxt}>{getAccessorialCountFromPaycycle(paycycle)}</Typography>
                <Typography className={cls.metricKeyTxt}>Accessorials</Typography>
              </div>
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <div className={cls.metricBox}>
                <Typography className={cls.metricValTxt}>{getMileageCountFromPaycycle(paycycle)}</Typography>
                <Typography className={cls.metricKeyTxt}>Miles</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: 8,
    position: 'relative',
    width: '100%',
    padding: 48,
    backgroundColor: theme.palette.secondary.darker,
    backgroundImage: 'url(/moving-car-flip.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: theme.shadow.nav,
    animation: `$fadeIn 0.667s`,
  },
  headerCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(90deg, ${theme.palette.secondary.darker}c8, ${theme.palette.secondary.main}c8)`,
  },

  headerTxt: {
    marginBottom: 12,
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.contrastPrimary,
  },
  amountDueTxt: {
    lineHeight: 1,
    fontSize: 56,
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },
  amountRemainingTxt: {
    marginTop: 16,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.contrastPrimary,
  },

  metricBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: theme.spacing(3, 6),
    borderRadius: 8,
    backgroundColor: '#ffffff30',
    backdropFilter: 'blur(6px)',
  },
  metricKeyTxt: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.contrastPrimary,
  },
  metricValTxt: {
    lineHeight: 1,
    fontSize: 36,
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  flexHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 48,
  },
  flexHeaderItem1: {
    position: 'relative',
    width: 'fit-content',
  },
  flexHeaderItem2: {
    position: 'relative',
  },
  flexMetric: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
  },

  btn: {
    backdropFilter: 'blur(6px)',
  },
  btnIcon: {
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
