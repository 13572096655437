//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

function LocationInfo({ location = {} }) {
  const cls = useStyles();

  const { getFormattedTypeFromLocation, getCleansedPhoneNumber, goToCustomerDetails } = useTools();

  return (
    <div className={cls.main}>
      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
              Location #{location.id}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
              {location.name || `-`}
            </Typography>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.name || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Address:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.address || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Google&nbsp;Place&nbsp;ID:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.place_id || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Nickname:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.nickname || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Email:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.link}><a href={`mailto:${location?.email}`}>{location?.email}</a></Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Phone:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.link}><a href={`mailto:${location?.phone}`}>{getCleansedPhoneNumber(location.phone) || `-`}</a></Typography>
            </div>
          </Grid>

          <div className={cls.hiddenBreak} />

          <Grid item md={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;&nbsp;</Typography>
              <Button
                className={cls.link}
                disableRipple
                onClick={() => goToCustomerDetails(location?.customer?.id)}
              >
              <Typography className={cls.link}>
                {location.customer && location.customer.name ? location.customer.name : `-`}
              </Typography>
              </Button>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getFormattedTypeFromLocation(location)}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Region:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>
                {location.region ? `${location.region.id} - ${location.region.name}` : `-`}
              </Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Latitude:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.latitude || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Longitude:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.longitude || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Timezone:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{location.timezone || `-`}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  link: {
    padding: 0,
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    textTransform: 'none',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default LocationInfo;
