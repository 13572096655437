// Apollo Setup Docs - https://www.apollographql.com/docs/react/get-started/

import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import _sdk from '@hopdrive/sdk';
import { REACT_APP_GQL_SD } from '../utils/env';
import { SimpleLogger } from '../utils/SimpleLogger';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import fetch from 'cross-fetch';
import {
  getUserToken,
  getUserName,
  getUserEmail,
  getAllowedRoles,
  getAllowedRegions,
  getUserRole,
} from '../utils/authHelper';

const version = require('../version');
const jwt = require('jsonwebtoken');

const DataContext = createContext({});

function DataProvider({ children }) {
  const { log } = new SimpleLogger({ prefix: 'DataProvider', enabled: true });

  const firebaseAuth = getAuth();
  const history = useHistory()

  const [firebaseUser, setFirebaseUser] = React.useState(null);
  const [apolloClient, setApolloClient] = React.useState(null);
  const [loading, setLoading] = React.useState(true)
  const [lastLocation, setLastLocation] = React.useState(null);


  //Listen for changes to the logged in Firebase user and set JWT accordingly

  const getFirebaseIdToken = async () => {
    let firebaseToken = await firebaseAuth.currentUser?.getIdToken();
    if (firebaseToken) {
      setFirebaseUser(firebaseToken)
      setLoading(false)
    } 
  };

  React.useEffect(() => {
    onAuthStateChanged(firebaseAuth, user => {
      getFirebaseIdToken()
      if (!user) {
        handleApolloClient()
      }
    })
  }, [])


  //If we have a JWT, use it to build out the Sentry user and the user object to be used as context throughout the site
  //Then, initialize Apollo with the JWT
  useEffect(() => {
    const buildSentryUser = async () => {
      return {
        email: await getUserEmail(),
        name: await getUserEmail(),
        nickname: await getUserName(),
        role: await getUserRole(),
        allowed_roles: await getAllowedRoles(),
        allowed_regions: await getAllowedRegions(),
      };
    };

    const SentryUser = buildSentryUser();
    Sentry.setContext('user', SentryUser);

  if (firebaseUser !== null) {
      handleApolloClient();
    }
  }, [firebaseUser]);

  const handleApolloClient = async () => {
    log(`Attempting to initialize Apollo...`);

    //Set up error handler to refresh JWT if it's expired
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions && extensions.code && extensions.code === 'invalid-headers') {
            console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            firebaseAuth.signOut(firebaseAuth);
          }
          if (message && message.includes('JWTExpired')) {
            if (firebaseAuth.currentUser) {
              getFirebaseIdToken()
            } else {
              console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
              firebaseAuth.signOut(firebaseAuth)
              history.push('/login')
            }
          }
        });
      }

      if (networkError) {
        console.error(`[Network Error]: ${networkError.message}`);
      }
    });

    const wsurl = `wss://${REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`;
    const httpUrl = `https://${REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`;

    const httpLink = createHttpLink({
      uri: httpUrl,
      fetch,
    });

    const getHeaders = async () => {
      const clientName = `admin-portal-${version.tag || 'vX.X.X'}`;
      try {
        const token = firebaseUser
        return {
          Authorization: `Bearer ${token}`,
          'hasura-client-name': clientName,
          'x-hasura-role': 'admin'
        };
      } catch (err) {
        console.log('Error retrieving token', err);
      }
    };

    //Setup authlink with id token jwt
    const authLink = setContext(async () => {
      return { headers: await getHeaders() };
    });

    //Web Socket setup (for subscriptions)
    const wsLink = new WebSocketLink({
      uri: wsurl,
      options: {
        lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: async () => {
          return { headers: await getHeaders() };
        },
      },
    });

    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink)
    );

    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, link]),
      cache: new InMemoryCache(),
    });

    log(`Apollo Initialized!`, client);

    //Configure sdk to use apolloClient with user's token
    _sdk.configure({
      apollo_client: client,
    });

    setApolloClient(client);
  };

  //Set Context
  const context = {
    apolloClient,
    _sdk: _sdk,
    firebaseUser,
    loading, 
    setLoading,
    lastLocation,
    setLastLocation,
  };

  return (
    <DataContext.Provider value={context}>
      {apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : null}
    </DataContext.Provider>
  );
}

const useData = () => useContext(DataContext);

export { useData, DataProvider };
