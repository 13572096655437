// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Icon, InputAdornment, MenuItem, Radio, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useFilters } from '../FilterProvider';

const ranges = [
  {
    label: `Custom`,
    value: `custom`,
  },
  {
    label: `Future Year`,
    value: `future-1-year`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(1, `year`).endOf(`day`).format(),
  },
  {
    label: `Future 6 Months`,
    value: `future-6-month`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(6, `month`).endOf(`day`).format(),
  },
  {
    label: `Future 3 Months`,
    value: `future-3-month`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(3, `month`).endOf(`day`).format(),
  },
  {
    label: `Future 2 Months`,
    value: `future-2-month`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(2, `month`).endOf(`day`).format(),
  },
  {
    label: `Future Month`,
    value: `future-1-month`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(1, `month`).endOf(`day`).format(),
  },
  {
    label: `Future 2 Weeks`,
    value: `future-2-week`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(2, `week`).endOf(`day`).format(),
  },
  {
    label: `Future Week`,
    value: `future-1-week`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(1, `week`).endOf(`day`).format(),
  },
  {
    label: `Future 3 Days`,
    value: `future-3-day`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().add(3, `day`).endOf(`day`).format(),
  },
  {
    label: `Today`,
    value: `today`,
    start: dayjs().startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Past 3 Days`,
    value: `past-3-day`,
    start: dayjs().subtract(3, `day`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Current Pay Cycle (1099)`,
    value: `current-pay-cycle (1099)`,
    start: dayjs().startOf('week').format(),
    end: dayjs().endOf('week').format(),
  },
  {
    label: `Current Pay Cycle (W2)`,
    value: `current-pay-cycle (W2)`,
    start: dayjs().startOf('week').format(),
    end: dayjs().add(1, 'week').endOf('week').format(),
  },
  {
    label: `Previous Pay Cycle (1099)`,
    value: `previous-pay-cycle (1099)`,
    start: dayjs().subtract(1, 'week').startOf('week').format(),
    end: dayjs().subtract(1, 'week').endOf('week').format(),
  },
  {
    label: `Previous Pay Cycle (W2)`,
    value: `previous-pay-cycle (W2)`,
    start: dayjs().subtract(2, 'week').startOf('week').format(),
    end: dayjs().subtract(1, 'week').endOf('week').format(),
  },
  {
    label: `Two Cycles Ago (1099)`,
    value: `two-cycles-ago (1099)`,
    start: dayjs().subtract(2, 'week').startOf('week').format(),
    end: dayjs().subtract(2, 'week').endOf('week').format(),
  },
  {
    label: `Two Cycles Ago (W2)`,
    value: `two-cycles-ago (W2)`,
    start: dayjs().subtract(4, 'week').startOf('week').format(),
    end: dayjs().subtract(3, 'week').endOf('week').format(),
  },
  {
    label: `Past Week`,
    value: `past-1-week`,
    start: dayjs().startOf(`week`).subtract(1, `week`).format(),
    end: dayjs().startOf(`week`).format(),
  },
  {
    label: `Past 2 Weeks`,
    value: `past-2-week`,
    start: dayjs().startOf(`week`).subtract(2, `week`).format(),
    end: dayjs().startOf(`week`).format(),
  },
  {
    label: `Past Month`,
    value: `past-1-month`,
    start: dayjs().subtract(1, `month`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Past 2 Months`,
    value: `past-2-month`,
    start: dayjs().subtract(2, `month`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Past 3 Months`,
    value: `past-3-month`,
    start: dayjs().subtract(3, `month`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Past 6 Months`,
    value: `past-6-month`,
    start: dayjs().subtract(6, `month`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
  {
    label: `Past Year`,
    value: `past-1-year`,
    start: dayjs().subtract(1, `year`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
  },
];

// COMPONENT ---------------------------------------------------------------- //

export default function DateRange({ module, options, defaults }) {
  const cls = useStyles();

  const { filters, updateModule } = useFilters();

  const handleRangeChange = range => {
    const newRangeObj = ranges?.find(r => r?.value === range);
    updateModule(module, `range`, range);
    if (newRangeObj?.start && newRangeObj?.end) {
      handleStartDateChange(newRangeObj?.start);
      handleEndDateChange(newRangeObj?.end);
    }
  };

  const handleStartDateChange = (date, utc) => {
    let editedDate = dayjs(date).startOf(`day`).format();
    if (utc) editedDate = dayjs.utc(editedDate).startOf(`day`).format();
    updateModule(module, `start`, editedDate);
  };

  const handleEndDateChange = (date, utc) => {
    let editedDate = dayjs(date).endOf(`day`).format();
    if (utc) editedDate = dayjs.utc(editedDate).endOf(`day`).format();
    updateModule(module, `end`, editedDate);
  };

  const handleUTCChange = utc => {
    updateModule(module, `utc`, utc);
    handleStartDateChange(filters?.[module]?.start, utc);
    handleEndDateChange(filters?.[module]?.end, utc);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={cls.flexList}>
        <TextField
          select
          fullWidth
          variant='outlined'
          size='small'
          label='Date Range'
          value={filters?.[module]?.range || defaults?.range}
          onChange={e => handleRangeChange(e?.target?.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  calendar_month
                </Icon>
              </InputAdornment>
            ),
          }}
        >
          {ranges
            ?.filter(range => options?.ranges?.includes(range?.value))
            ?.map(range => (
              <MenuItem key={range?.value} value={range?.value}>
                {range?.label}
              </MenuItem>
            ))}
        </TextField>

        <div className={cls.dateContainer}>
          <DatePicker
            required
            disabled={filters?.[module]?.range !== `custom`}
            autoOk
            className={cls.date}
            inputProps={{
              className: cls.input,
            }}
            orientation='portrait'
            inputVariant='outlined'
            variant='inline'
            size='small'
            format='MM/dd/yyyy'
            label='Start Date'
            minDate={options?.minDate}
            maxDate={filters?.[module]?.end}
            value={filters?.[module]?.start || defaults?.start}
            onChange={handleStartDateChange}
          />

          <Typography className={cls.to}>to</Typography>

          <DatePicker
            required
            disabled={filters?.[module]?.range !== `custom`}
            autoOk
            className={cls.date}
            inputProps={{
              className: cls.input,
            }}
            orientation='portrait'
            inputVariant='outlined'
            variant='inline'
            size='small'
            format='MM/dd/yyyy'
            label='End Date'
            minDate={filters?.[module]?.start}
            maxDate={options?.maxDate}
            value={filters?.[module]?.end || defaults?.end}
            onChange={handleEndDateChange}
          />
        </div>

        {options?.showUtc !== false ? (
          <div className={cls.radioGroup}>
            <div className={cls.radioItem}>
              <Radio
                className={cls.radioBtn}
                size='small'
                checked={!filters?.[module]?.utc || !defaults?.utc}
                onChange={_ => handleUTCChange(false)}
              />
              <Typography className={cls.radioLabel}>{dayjs().format(`z`)} (Local)</Typography>
            </div>

            <div className={cls.radioItem}>
              <Radio
                className={cls.radioBtn}
                size='small'
                checked={filters?.[module]?.utc || defaults?.utc}
                onChange={_ => handleUTCChange(true)}
              />
              <Typography className={cls.radioLabel}>UTC (Universal)</Typography>
            </div>
          </div>
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  flexList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 12,
  },

  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  date: {
    minWidth: 96,
    maxWidth: 128,
  },
  input: {
    height: `1em`,
    padding: theme.spacing(1, 1),
  },
  to: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
    width: `100%`,
  },
  radioItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  radioBtn: {
    padding: 4,
  },
  radioLabel: {
    fontSize: 12,
  },
}));
