//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import { Modal } from '../ModalComponents';
import ARReviewContent from './ARReviewContent';

//////////////////////// COMPONENT ////////////////////////

function ARReviewModal(props) {
  const { open, onClose, input } = props;

  return (
    <Modal open={open} width='sm'>
      {input ? <ARReviewContent open={open} onClose={onClose} input={input} /> : null}
    </Modal>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ARReviewModal;
