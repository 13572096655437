// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { getTimeFromPosition } from '../../utils/TimelineUtils';

import { useTimeline } from './TimelineProvider';

import TimelineLabelLoop from './TimelineLabelLoop';
import TimelineAreaMarker from './TimelineAreaMarker';
import TimelineAreaMarkerCurrent from './TimelineAreaMarkerCurrent';
import TimelineAreaMarkerHover from './TimelineAreaMarkerHover';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineAreaHeader() {
  const cls = useStyles();

  const {
    headerSize,
    isCurrentDay,
    isHovering,
    markerHoverPosition,
    markerHoverTime,
    markers,
    multiplier,
    setIsHovering,
    setMarkerHoverPosition,
    setMarkerHoverTime,
    showMarkers,
    sidebarSize,
    timelineRef,
    timezoneOverride,
    zoom,
  } = useTimeline();

  const headerRef = React.useRef(null);

  const handleMouseOver = e => {
    const position = timelineRef?.current?.scrollLeft - sidebarSize + e?.clientX || 0;
    const time = getTimeFromPosition(position, zoom, multiplier, timezoneOverride);

    setIsHovering(true);
    setMarkerHoverPosition(position);
    setMarkerHoverTime(time);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  if (!headerSize) return null;
  return (
    <div
      ref={headerRef}
      className={cls.timelineAreaHeader}
      style={{ flex: `0 0 ${headerSize}px` }}
      onMouseMove={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <TimelineLabelLoop />

      {showMarkers ? (
        <>
          {isCurrentDay ? <TimelineAreaMarkerCurrent showLabel={true} /> : null}

          {markers?.map((marker, i) => (
            <TimelineAreaMarker key={`timeline-area-marker-${i}`} showLabel={true} marker={marker} />
          ))}

          {isHovering && markerHoverPosition && markerHoverTime ? <TimelineAreaMarkerHover showLabel={true} /> : null}
        </>
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineAreaHeader: {
    zIndex: 102,
    position: 'sticky',
    top: 0,
    width: 'max-content',
    backgroundColor: `${theme.palette.background.paper}f0`,
    boxShadow: theme.shadow.medium,
    cursor: 'col-resize',
  },
}));
