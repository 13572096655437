import React, { useState } from 'react';
import { makeStyles, Container, Checkbox, Grid, Button, FormControlLabel, FormControl,
FormLabel, FormGroup, FormHelperText, Divider  } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import axios from 'axios';

import { getUserToken } from '../../utils/authHelper'

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import Loading from '../../components/Loading';

import { GET_REGIONS } from './gql';

const log = false;

export default function DriverEmails(props) {
    const cls = useStyles();
    
    // HOOK ////////////////////////////////////////
    const { loading, error, data } = useQuery(GET_REGIONS);

    // STATES ////////////////////////////////////////
    const [ selection, setSelection ] = useState(initSelection())
    const [ emails, setEmails ] = useState(null)


    // LOGIC ////////////////////////////////////////
    function initSelection() {
        let selections = {};
        if (data && data.regions ) data.regions.map(region => { return Object.assign(selections, { [region.team_id]: true }) })
        return selections;
      }

    function handleSelect(event, action) { return setSelection({ ...selection, [event.target.value]: action }) };

    async function handleFetchEmails() {
        try {
            const token = await getUserToken()
            await axios({
                method: 'POST',
                url: '/.netlify/functions/handleFetchDriverEmails',
                data: { team_ids: getTeamIdsFromSelection() },
                headers: { 'authorization': `Bearer ${token}` }
            })
            .then(res => {
                setEmails(res.data)
                navigator.clipboard.writeText(res.data)
                res.data.length >= 1 ? toast.success("Successfully copied emails clipboard") : toast.warning("No emails found")
            })
        } catch (err) {
            console.error("Failed to retrieve emails:", err);
            toast.error(`Failed to retrieve emails`)
        }
    }
    
    function getTeamIdsFromSelection() {
        let team_ids = [];
        for (let [key, value] of Object.entries(selection)) { if (value) { team_ids.push(data.regions.find(o => o.team_id === parseInt(key)).team_id) } }
        return team_ids;
    }

    // RENDER ////////////////////////////////////////
    if (loading) return <Loading fixed/>
    if (error) {
        console.error('Error fetching regions:', error);
        Sentry.captureException(error);
        return <DefaultErrorFallback message={`ERROR FETCHING REGIONS`}/>
    }

    if (data && data.regions) {
        const regions = data.regions;
        log && console.log('Regions Fetched:', regions);

        return(<>
            <Container className={cls.root} maxWidth='lg'>
                <Grid className={cls.grid} container justifyContent='center' spacing={2}>
                    <Grid item xs={6}>
                        <FormLabel component="legend">Get driver emails for the following regions:</FormLabel>
                        <FormControl key="form-control" component="fieldset">
                            <FormGroup className={ cls.list }>
                            { regions.map(region => {
                                return(<div className={ cls.listitem } key={`regions-key-${region.team_id}`}>
                                    <FormControlLabel
                                        key={`region-select-${region.team_id}`}
                                        control={<Checkbox 
                                            color="primary" 
                                            key={`region-checkbox-${region.team_id}`}
                                            checked={selection[region.team_id] ? true : false} 
                                            onChange={ handleSelect } 
                                            value={ region.team_id } />}
                                        label={region.name}
                                    />
                                    <Divider/>
                                </div>)
                            }) }
                            <FormHelperText>A comma-separated list of emails will be returned and copied to the clipboard</FormHelperText>
                            </FormGroup>
                            <Button disabled={!Object.values(selection).includes(true)} color="secondary" className={cls.btn} onClick={() => handleFetchEmails()}>
                            <Email className={cls.icon} color={!Object.values(selection).includes(true) ? "disabled" : "action"} />
                                Get Driver Emails
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                { emails ? 
                    <Grid container justifyContent='center' spacing={2}> 
                        <Grid item xs={8} className={cls.emailGrid}>
                            <pre className={ cls.emails }> 
                                { emails } 
                            </pre>
                        </Grid>
                    </Grid> 
                : null }
            </Container>
        </>)
    }

};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        textAlign: 'center',
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    list: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        backgroundColor: theme.palette.background.main,
    },
    listitem: {
        textAlign:'left'
    },
    grid: {
      padding: theme.spacing(2),
    },
    btn: {
      marginTop: theme.spacing(2),
      textTransform: 'none',
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    emails: {
        backgroundColor: theme.palette.background.main,
        padding: '1rem',
        margin: 0,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        color: theme.palette.text.primary,
    },
  }));