//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid, TextField, InputAdornment, Icon } from '@material-ui/core';

import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';

const moveStatuses = [
  { id: 1, label: 'Not Assigned', value: null },
  { id: 2, label: 'Dispatched', value: 'dispatched' },
  { id: 3, label: 'Pickup Started', value: 'pickup started' },
  { id: 4, label: 'Pickup Arrived', value: 'pickup arrived' },
  { id: 5, label: 'Pickup Successful', value: 'pickup successful' },
  { id: 6, label: 'Delivery Started', value: 'delivery started' },
  { id: 7, label: 'Delivery Arrived', value: 'delivery arrived' },
  { id: 8, label: 'Delivery Successful', value: 'delivery successful' },
  { id: 9, label: 'Failed', value: 'failed' },
  { id: 10, label: 'Pending Cancel', value: 'pending' },
  { id: 11, label: 'Canceled After Started', value: 'started' },
  { id: 12, label: 'Canceled Before Started', value: 'canceled' },
];

//////////////////////// COMPONENT ////////////////////////

export default function MovesByCustomerFilter({ statusArray, onStatusArrayChange, search, onSearchChange }) {
  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      {onStatusArrayChange ? (
        <Grid item xs>
          <ReusableMultiselect
            label='Move Statuse'
            selectedArray={statusArray?.map(status => status.id)}
            dataArray={moveStatuses}
            optionName='label'
            handleOutput={onStatusArrayChange}
            noIdInLabel={true}
          />
        </Grid>
      ) : null}

      {onSearchChange ? (
        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
