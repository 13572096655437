import React, { useState, useEffect } from 'react';
import Script from 'react-load-script';
import { gql, useQuery } from '@apollo/client';

import { useTheme, makeStyles, Typography, TextField, Icon, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import sdk from '@hopdrive/sdk';
import * as Sentry from '@sentry/react';
import Loading from './Loading';

import useGoogle from '../hooks/UseGoogle';

// import LocationAddEditModal from './LocationAddEditModal';
// import AddressBuilderModal from './AddressBuilderModal';

import { toast } from 'react-toastify';

const locIcon = require('../static/location-icon.png');
const log = false;

////////// COMPONENT //////////
export default function LocationSelect(props) {
  const cls = useStyles();
  const theme = useTheme();
  const googleService = useGoogle();

  const { locationData, onChange, label, customerId } = props;

  const [location, setLocation] = useState(locationData ? locationData : null);
  const [suggestions, setSuggestions] = useState([]);

  const { loading, error, data } = useQuery(GET_LOCATIONS, {
    variables: {
      customerId: customerId,
    },
  });

  useEffect(() => {
    if (onChange) onChange(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setLocation(null);
  }, [customerId]);

  const handleLocationChange = newLoc => {
    log && console.log(`Selected Location:`, newLoc);
    if (newLoc) {
      if (newLoc.id) setLocation(newLoc);
      else handleNewLocation(newLoc);
    } else setLocation(null);
  };

  const getRegionId = async (lat, lon) => {
    var regionId;
    // Used to get region_id from Latitude & Longitude
    let regionRes = await sdk.regions.getByCoords([lon, lat]);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      regionId = regionRes.data[0].id;
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      regionId = null;
    }
    return regionId;
  };

  const handleNewLocation = async newLoc => {
    try {
      log && console.log('Google Location.....');
      const locObj = {
        regionId: await getRegionId(newLoc.latitude, newLoc.longitude),
        type: newLoc.type || 'customer',
        name: newLoc.name,
        address: newLoc.address,
        nickname: newLoc.nickname || '',
        email: newLoc.email || '',
        phone: newLoc.phone || '',
        longitude: newLoc.longitude,
        latitude: newLoc.latitude,
      };
      setLocation(locObj);
      log && console.log(locObj);
    } catch (err) {
      console.error('Failed to create location:', err);
      toast.error('Failed to create Google location');
    }
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === `input` && value && value !== ``) {
      log && console.log(`Input:`, value);
      handleLocationChange(null);
      handleGoogleSearch(value);
    } else {
      setSuggestions([]);
    }
  };


  const handleGoogleSearch = input => {
    googleService?.textSearch({ query: input }, res => {
      if (res) {
        log && console.log(`Response from Google:`, res);
        setSuggestions(
          res.map(s => ({
            id: null,
            customer_id: null,
            region_id: null,
            place_id: s.place_id,
            name: s.name,
            address: s.formatted_address,
            input_address: input,
            latitude: s.geometry.location.lat(),
            longitude: s.geometry.location.lng(),
            nickname: null,
            email: null,
            phone: null,
            notes: null,
            favorite: false,
          }))
        );
      }
    });
  };

  if (loading) return <Loading fixed />;

  if (error) {
    console.log(error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <div className={cls.notifyBox}>
          <Typography className={cls.notifyText} style={{ color: theme.palette.error.main }}>
            AN ERROR OCCURRED
          </Typography>
        </div>
      </div>
    );
  }

  let locs = data.locations.length > 0 ? data.locations : [];
  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GPLCS}&libraries=places`}
      />

      <Autocomplete
        includeInputInList
        options={locs.concat(suggestions)}
        filterOptions={createFilterOptions({
          stringify: option => `${option.name} ${option.nickname} ${option.address} ${option.input_address}`,
        })}
        getOptionLabel={option => (option && option.nickname ? option.nickname : option.name || '')}
        getOptionSelected={(option, value) => option.value === value.value}
        noOptionsText='Unknown Address'
        value={location}
        onChange={(event, value) => handleLocationChange(value)}
        onInputChange={handleInputChange}
        // onKeyUp={handleKeyUp}
        style={{ width: '100%' }}
        renderInput={params => (
          <TextField
            {...params}
            error={props.valid ? !props.valid.toString() : 'false'}
            fullWidth
            label={label ? label : `Location`}
            placeholder='Search for a location...'
            helperText={location && location.address ? location.address : null}
            variant='outlined'
            margin='none'
          />
        )}
        renderOption={option => (
          <>
            {option.id ? (
              option.favorite ? (
                <Tooltip placement='top' title='Favored Location'>
                  <div className={cls.optionIcon}>
                    <Icon className={cls.favoriteIcon}>favorite</Icon>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip placement='top' title='Stored Location'>
                  <div className={cls.optionIcon}>
                    <img className={cls.locIcon} src={locIcon.default} alt='location pin' />
                  </div>
                </Tooltip>
              )
            ) : (
              <Tooltip placement='top' title='Google-Suggested Location'>
                <div className={cls.optionIcon}>
                  <FontAwesomeIcon className={cls.googleIcon} icon={faGoogle} title='Google-Suggested Location' />
                </div>
              </Tooltip>
            )}
            <div className={cls.option}>
              <Typography className={cls.optionName}>{!option.nickname ? option.name : option.nickname}</Typography>
              <Typography className={cls.optionAddress}>{option.address}</Typography>
            </div>
          </>
        )}
      />
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  heart: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favoriteIcon: {
    color: theme.palette.error.main,
  },
  locIcon: {
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.6),
    maxWidth: '30px',
  },
  googleIcon: {
    color: theme.palette.text.secondary,
  },
  option: {
    display: 'block',
  },
  optionName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  optionAddress: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
}));

const GET_LOCATIONS = gql`
  query get_locations($customerId: bigint) {
    locations(
      where: { customer_id: { _eq: $customerId }, active: { _eq: 1 } }
      order_by: [{ favorite: desc }, { name: asc }]
    ) {
      id
      customer_id
      region_id
      place_id
      name
      address
      latitude
      longitude
      nickname
      email
      phone
      notes
      favorite
      type
    }
  }
`;
