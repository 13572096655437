import React from 'react';

import { makeStyles, ButtonGroup, Button } from '@material-ui/core';

////////// COMPONENT //////////
export default function ConsumerLocButtonGroup(props) {
  const cls = useStyles();

  const { consumerLocation, setConsumerLocation, switchLabelA, switchLabelB } = props;

  return (
    <>
      <ButtonGroup fullWidth className={cls.btnGroup} color='primary'>
        <Button
          fullWidth
          size='large'
          className={consumerLocation === 'pickup' ? cls.btnActive : cls.btn}
          onClick={() => setConsumerLocation('pickup')}
        >
          {switchLabelA || `Label A`}
        </Button>
        <Button
          fullWidth
          size='large'
          className={consumerLocation === 'delivery' ? cls.btnActive : cls.btn}
          onClick={() => setConsumerLocation('delivery')}
        >
          {switchLabelB || `Label B`}
        </Button>
      </ButtonGroup>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  btn: {
    minHeight: '56px',
  },
  btnActive: {
    minHeight: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));
