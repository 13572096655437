import React, { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import LanesRow from './LanesRow';
import Loading from '../../components/Loading';
import { DefaultErrorFallback } from '../../components/Fallbacks';

export default function LanesTable({ customerId, offset, limit }) {
  const cls = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');

  const handleSortByProperty = property => event => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleOrderBy = (column, direction) => {
    try {
      if (column === 'customer') {
        return { id: direction };
      } else {
        let colString = column.toString();
        let orderByQueryObj = { [colString]: direction };
        return orderByQueryObj;
      }
    } catch {
      return { id: 'desc' };
    }
  };

  const headers = [
    { id: `id`, alignLeft: true, numeric: true, label: `ID` },
    { id: `customer`, alignLeft: true, numeric: false, label: `Customer` },
    { id: `description`, alignLeft: true, numeric: false, label: `Description` },
    { id: `distance_miles`, alignLeft: false, numeric: true, label: `Distance` },
    { id: `duration`, alignLeft: false, numeric: true, label: `Duration` },
    { id: `speed`, alignLeft: false, numeric: true, label: `Avg.\xa0Speed` },
  ];

  //Lanes are fetched based on customer select, current page, and pagination settings
  const { loading, error, data } = useQuery(GET_LANES, {
    variables: {
      customerId: customerId,
      limit: limit,
      offset: offset,
      order_by: handleOrderBy(orderBy, order) || { id: 'desc' },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <DefaultErrorFallback message={'ERROR FETCHING LANES'} />;
  }
  if (data) {
    const rows = data.lanes.map(lane => {
      return {
        id: lane.id || '-',
        customer: lane.customer.name || '-',
        lane: lane.description || '-',
        distance: lane.distance_miles || '-',
        duration: Number(Number(lane.duration_sec) / 60).toFixed(1) || '-',
        speed: Number(lane.average_drive_speed_mph).toFixed(1) || '-',
      };
    });

    return (
      <>
        <div className={cls.paper}>
          <Table className={cls.table} size='small'>
            <TableHead className={cls.tableHead}>
              <TableRow>
                {headers.map(
                  header =>
                    !header.hide && (
                      <TableCell
                        key={`${header.id}-cell`}
                        align={header.alignLeft ? 'left' : 'right'}
                        padding='normal'
                        sortDirection={orderBy === header.id ? order : false}
                      >
                        {header.value || (
                          <TableSortLabel
                            className={cls.headTxt}
                            active={orderBy === header.id}
                            direction={order}
                            onClick={handleSortByProperty(header.id)}
                          >
                            {header.label}
                            {orderBy === header.id ? (
                              <span className={cls.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <LanesRow
                  key={`lane-${row.id}-row`}
                  rowId={row.id}
                  columns={[
                    { align: 'left', value: row.id },
                    { align: 'left', value: row.customer },
                    { align: 'left', value: row.lane },
                    { align: 'right', value: `${row.distance}\xa0mi` },
                    { align: 'right', value: `${row.duration}\xa0min` },
                    { align: 'right', value: `${row.speed}\xa0mph` },
                  ]}
                  index={i}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
}

/////GQL//////

const GET_LANES = gql`
  query get_lanes($customerId: bigint!, $limit: Int, $offset: Int, $order_by: lanes_order_by!) {
    lanes(
      where: { customer_id: { _eq: $customerId }, active: { _eq: "1" } }
      order_by: [$order_by]
      limit: $limit
      offset: $offset
    ) {
      customer {
        id
        name
      }
      distance_miles
      id
      description
      duration_sec
      average_drive_speed_mph
    }
  }
`;

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    background: theme.palette.background.paper,
  },
  table: {
    position: 'relative',
    minWidth: '400px',
  },
  tableHead: {
    background: theme.palette.background.paper,
  },
  headTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
