import React from 'react';
import { makeStyles, TableRow, TableCell } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../hooks/useTools';

function LanesRow({ rowId, expandedRowId, columns, index }) {
  const cls = useStyles();
  const collect = props => {
    return props;
  };
  const { goToLaneDetails, goToRoute } = useTools();

  //TODO: add route to move calculator for specific lane (NYI)
  const actions = [
    {
      label: `Go To Lane Details`,
      handler: () => goToLaneDetails(rowId ? rowId : null),
      disabled: false,
      hide: false,
    },
    {
      label: `Go To Lane Map`,
      handler: () => goToRoute(`map/lanes/${rowId}`),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${rowId}-cm`}
        source={`atr-row-${rowId}-cm`}
        holdToDisplay={1000}
        collect={collect}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{ className: `MuiTableRow-root ${index % 2 ? cls.rowEven : cls.rowOdd}` }}
      >
        {columns.map(
          (column, i) =>
            !column.hide && (
              <TableCell onClick={() => goToLaneDetails(rowId)} align={column.align} key={`atr-row-${rowId}-col-${i}`}>
                {column.value}
              </TableCell>
            )
        )}
      </ContextMenuTrigger>
      <TableRow>
        <TableCell
          style={{ borderBottom: expandedRowId === rowId ? '1px solid #50525824' : 0 }}
          align='left'
          padding='none'
          colSpan={columns.filter(c => !c.hide).length}
        />
      </TableRow>
      {actions && actions.length > 0 && (
        <ContextMenu
          key={`atr-row-${rowId}-cm`}
          id={`atr-row-${rowId}-cm`}
          style={{ boxShadow: '2px 4px 12px #00000050' }}
        >
          {actions.map(
            action =>
              !action.hide && (
                <MenuItem
                  disabled={action.disabled}
                  data={{ action: action, ...action.data }}
                  onClick={action.handler}
                  key={`atr-row-action-${action.label}-${rowId}`}
                >
                  {action.label}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}
    </>
  );
}
export default LanesRow;

const useStyles = makeStyles(theme => ({
  txt: {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));
