import 'date-fns';
import React, { useState } from 'react';
import { makeStyles, Container, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';

import CustomerSelect from '../../components/CustomerSelect';
import LanesEntryOutput from './LanesEntryOutput';

export default function LanesEntryForm(props) {
  const cls = useStyles();

  const [customer, setCustomer] = useState(0);

  const handleCustomerChange = event => {
    const { value } = event.target;
    setCustomer(value);
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Card>
          <CardHeader title={'Create Lanes'} />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h6' component='h6'>
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomerSelect value={customer} onChange={handleCustomerChange} />
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='baseline' spacing={2}>
                { customer ? <LanesEntryOutput customer={customer} /> : null }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
