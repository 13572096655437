const round = (option, dayjsClass) => {
  dayjsClass.prototype.round = function (amount, unit) {
    if (this.get(unit) % amount >= amount / 2) {
      return this.ceil(amount, unit);
    } else {
      return this.floor(amount, unit);
    }
  };
};
export default round;
