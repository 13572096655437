import 'date-fns';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import { UPSERT_LANE } from './gql';
import sdk from '@hopdrive/sdk';

let log = true;

const styles = theme => ({
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dlPairs: {
    margin: '1em 0',
    borderBottom: '1px solid rgb(231, 231, 231)',
  },
});

function DetailInputItem(props) {
  return (
    <Grid item>
      <FormControl fullWidth={false}>
        <InputLabel htmlFor='bootstrap-input'>{props.label}</InputLabel>
        <Input
          disabled={props.disabled === true ? true : false}
          type={props.name && (props.name.includes('rate_type') || props.label === 'Base Pay') ? 'text' : 'number'}
          disableUnderline={true}
          name={props.name}
          // endAdornment={props.units !== '$' ? props.units : null}
          startAdornment={props.units === '$' ? '$' : null}
          value={props.value}
          onChange={props.onChange}
        />
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

function DetailsSection(props) {
  const [lane, setLane] = useState({});

  useEffect(() => {
    if (props.lane) setLane(props.lane);
    else log && console.log('LaneEdit: error setting lane');
  }, []);

  useEffect(() => {
    console.log("PROPS", props)
    console.log("LANE", lane)
  }, [lane, props])

  const recalculateLaneDetails = async () => {
    // TODO: switch these to params so that form values can overwrite detail form on click
    let currentLane = lane;
    let newDetails = lane;
    let detailedLane = await sdk.lanes.calculateDetails(
      currentLane,
      newDetails.driver_pay_per_minute,
      newDetails.driver_pay_per_mile,
      newDetails.dealer_base_discount,
      newDetails.dealer_stranded_discount,
      newDetails.driver_base_pay_discount,
      newDetails.driver_return_pay_discount,
      newDetails.driver_rake,
      newDetails.tolls
    );
    setLane(detailedLane);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    // convert minutes back to seconds
    if (name === 'duration_sec') {
      setLane({ [name]: value * 60 });
    } else {
      setLane({ [name]: value });
    }
  };

  const upsertLane = async () => {
    toast.info('Sending Lane Data...');
    delete lane.pickup;
    delete lane.delivery;
    let updatedAt = { updatedat: 'now()' };
    let updatedLane = Object.assign({}, lane, updatedAt);
    log && console.log('upsertLane - Lane to upsert:', updatedLane);
    sdk.gql.mutation(UPSERT_LANE, { laneObjects: updatedLane })
      .then(res => {
        if (res.success) {
          // validate that mutation was successful
          if (lane.id) {
            toast.success("Lane Edited")
          } else {
            toast.success("Lane Created")
            if (props.primary) props.handleSetSubmit('submittedPrimaryLane');
            else props.handleSetSubmit('submittedInverseLane');
          }

          console.log("RES", res)

          log && console.log('createlane - UPSERT_LANE response (res.data):', res.data.insert_lanes.returning);
          let resLane = res.data.insert_lanes.returning[0];
          delete resLane.customer;
          delete resLane.pickup;
          delete lane.pickup;
          delete resLane.delivery;
          delete lane.delivery;
          delete resLane.__typename;
          setLane(resLane);
        } else if (res.errors && res.errors[0]) {
          console.log("UPSERT_LANE ERROR", res.errors[0])
          toast.error("Error Upserting Lane", res.errors[0].message)
        } else {
          console.log("Unable to upsert lane", res)
          toast.error("Unable to upsert lane.")
        }
      })
      .catch(err => {
        log && console.log('upsertLanes .catch -- err:', err);
        toast.error('Upsert Failed');
        throw err;
      });
  };


  return (
    <Grid container item xs={6} spacing={1}>
      <Grid container item xs={12}>
        <Typography variant='subtitle1' component='h6' color='textPrimary'>
          {props.primary ? 'Primary Lane: ' + lane.description : 'Inverse Lane: ' + lane.description}
        </Typography>
      </Grid>
      {/* calculator */}
      <Grid container item xs={12} spacing={1}>
        <Grid container item xs={12} spacing={1}>
          Lane Detail Calculator
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <TextField
              name={'driver_pay_per_minute'}
              label='Driver per Minute Rate'
              value={lane.driver_pay_per_minute}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            <TextField
              name={'driver_pay_per_mile'}
              label='Driver per Mile Rate'
              value={lane.driver_pay_per_mile}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <TextField
              name={'driver_base_pay_discount'}
              label='Driver Base Pay Discount'
              value={lane.driver_base_pay_discount}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            <TextField
              name={'driver_return_pay_discount'}
              label='Driver Return Pay Discount'
              value={lane.driver_return_pay_discount}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <TextField
              name={'dealer_base_discount'}
              label='Dealer Base Discount'
              value={lane.dealer_base_discount}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            <TextField
              name={'dealer_stranded_discount'}
              label='Dealer Stranded Discount'
              value={lane.dealer_stranded_discount}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <TextField
              name={'driver_rake'}
              label='Base Pay Rake'
              value={lane.driver_rake}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            <TextField
              name={'tolls'}
              label='Tolls'
              value={lane.tolls}
              onChange={handleInputChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Button onClick={recalculateLaneDetails} color='primary'>
            Recalculate
          </Button>
        </Grid>
      </Grid>
      {/* details */}
      <Grid container item xs={11}>
        <Paper m={1} p={2}>
          <Grid container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              <strong>Driving Details</strong>
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              <span className='float-right text-primary'>
                {Math.round(lane.distance_miles)} mi/
                <small className='text-secondary'>{Math.round(lane.duration_sec / 60)} min</small>
              </span>
            </Typography>
            <Grid m={1} container item xs={12}>
              <Typography color='primary' gutterBottom>
                Info
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <DetailInputItem
                label='Total Time'
                name={'duration_sec'}
                value={Math.round(lane.duration_sec / 60)}
                units={'mins'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Drive Time'
                value={Math.round(
                  sdk.utilities.getDriveTimeAsSeconds(lane.distance_miles, lane.average_drive_speed_mph) / 60
                )}
                units={'mins'}
                disabled={true}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Distance'
                name={'distance_miles'}
                value={lane.distance_miles}
                units={'miles'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Return Ride'
                name={'estimated_rideshare_return_cost'}
                units={'$'}
                value={lane.estimated_rideshare_return_cost}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Insurance Per Mile'
                name={'insurance_cost_per_mile'}
                value={lane.insurance_cost_per_mile}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Insurance Cost'
                name={'insurance_cost'}
                value={lane.insurance_cost}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid m={1} container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              <strong>Driver Pay</strong>
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              <span className='float-right text-primary'>
                ${lane.driver_base_pay}/<small className='text-secondary'>${lane.driver_return_pay}</small>
              </span>
            </Typography>
            <Grid container item xs={12}>
              <Typography color='primary' gutterBottom>
                Factors
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <DetailInputItem
                label='Per Minute'
                name={'driver_pay_per_minute'}
                value={lane.driver_pay_per_minute}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Per Mile'
                name={'driver_pay_per_mile'}
                value={lane.driver_pay_per_mile}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* Round */}
              <DetailInputItem
                label='Average Speed'
                name={'average_drive_speed_mph'}
                value={lane.average_drive_speed_mph}
                untis={'mph'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Rake'
                name={'driver_rake'}
                value={lane.driver_rake}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Base Pay'
                value='1.00'
                units={'$'}
                helperText=' '
                disabled={true}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid container item xs={12}>
              <Typography color='primary' gutterBottom>
                Base
              </Typography>
            </Grid>
            <Grid container justify='space-around' item xs={12}>
              <DetailInputItem
                label='Time Pay'
                name={'driver_time_pay'}
                value={lane.driver_time_pay}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Drive Pay'
                name={'driver_drive_pay'}
                value={lane.driver_drive_pay}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Base Discount'
                name={'driver_base_pay_discount'}
                units={'$'}
                value={lane.driver_base_pay_discount}
                helperText=' '
                onChange={handleInputChange}
              />
            </Grid>
            <Grid container item xs={12}>
              <Typography color='primary' gutterBottom>
                Return
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <DetailInputItem
                label='Return Pay'
                name={'driver_return_pay'}
                value={lane.driver_return_pay}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Return Discount'
                name={'driver_return_pay_discount'}
                value={lane.driver_return_pay_discount}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid m={1} container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              <strong>Pricing</strong>
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              <span className='float-right text-primary'>
                ${lane.dealer_base_price}/<small className='text-secondary'>${lane.dealer_stranded_price}</small>
              </span>
            </Typography>
            <Grid container item xs={12}>
              <Typography color='primary' gutterBottom>
                Round Trip
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <DetailInputItem
                label='Rate'
                name={'dealer_base_rate'}
                value={lane.dealer_base_rate}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* change to drop down- options are flat or per */}
              <DetailInputItem
                label='Rate Type'
                name={'dealer_base_rate_type'}
                value={lane.dealer_base_rate_type}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* Round */}
              <DetailInputItem
                label='Discount'
                name={'dealer_base_discount'}
                value={lane.dealer_base_discount}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Tolls'
                name={'tolls'}
                value={lane.tolls}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* Ask Rob how this should work re: calculations */}
              <DetailInputItem
                label='Price'
                name={'dealer_base_price'}
                value={lane.dealer_base_price}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
            </Grid>
            <Grid container item xs={12}>
              <Typography color='primary' gutterBottom>
                Stranded
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <DetailInputItem
                label='Rate'
                name={'dealer_stranded_rate'}
                value={lane.dealer_stranded_rate}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* change to drop down- options are flat or per */}
              <DetailInputItem
                label='Rate Type'
                name={'dealer_stranded_rate_type'}
                value={lane.dealer_stranded_rate_type}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* Round */}
              <DetailInputItem
                label='Discount'
                name={'dealer_stranded_discount'}
                value={lane.dealer_stranded_discount}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              <DetailInputItem
                label='Tolls'
                name={'tolls'}
                value={lane.tolls}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
              {/* Ask Rob how this should work re: calculations */}
              <DetailInputItem
                label='Price'
                name={'dealer_stranded_price'}
                value={lane.dealer_stranded_price}
                units={'$'}
                helperText=' '
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid m={1} container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              <strong>Profit</strong>
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              <span className='float-right text-primary'>
                ${(lane.dealer_base_price - lane.insurance_cost - lane.tolls - lane.driver_drive_pay).toFixed(2)}/
                <small className='text-secondary'>
                  $
                  {(
                    lane.dealer_stranded_price -
                    lane.insurance_cost -
                    lane.tolls -
                    lane.driver_drive_pay -
                    lane.driver_return_pay
                  ).toFixed(2)}
                </small>
              </span>
            </Typography>
          </Grid>
          <Grid container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              Total Round Trip Cost
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              $
              {(
                parseFloat(lane.insurance_cost) +
                parseFloat(lane.tolls) +
                parseFloat(lane.driver_drive_pay)
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid container justify='space-around' item xs={12}>
            <Typography color='textPrimary' gutterBottom>
              Total Stranded Cost
            </Typography>
            <Typography color='textPrimary' gutterBottom>
              $
              {(
                parseFloat(lane.insurance_cost) +
                parseFloat(lane.tolls) +
                parseFloat(lane.driver_drive_pay) +
                parseFloat(lane.driver_return_pay)
              ).toFixed(2)}
            </Typography>
          </Grid>
          <hr />
          {/* Submit Button */}
          <Grid container item xs={12}>
            <Button color='primary' variant='outlined' onClick={upsertLane}>
              {lane.id ? 'Save Lane' : 'Submit Lane'}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

DetailsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsSection);
