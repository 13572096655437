import { useSettings } from '../providers/SettingsProvider';
import { useScheduler } from '../providers/SchedulerProvider';
import { useUnassignedMoves } from '../providers/UnassignedMovesProvider';
import { useEnrichedPlans } from './useEnrichedPlans';
import { SimpleLogger } from '../../../utils/SimpleLogger';

//////////////////////// COMPONENT ////////////////////////

export function useDragAndDrop() {
  const { enableDragDropLogs } = useSettings();
  const { unassignedMoves } = useUnassignedMoves();
  const { buildEnrichedMove, detectRide, detectRideGap, detectLink, calcBuffer } = useEnrichedPlans();
  const {
    selectPlanById,
    workingPlan,
    runScheduler,
    scheduleUnplannedMoveWithSiblings,
    showBuffers,
    hideBuffers,
    showAddMoveButton,
    hideAddMoveButton,
    getLocalEnrichedMoveById,
    getDriveMoveIds,
    getNextDriveMoveById,
    reorderEnrichedMove,
    ifConfirmed,
    checkMoveAssign,
    showScheduler,
    queueMoveForPlanning,
    tryToPlanMove,
  } = useScheduler();

  const { log } = new SimpleLogger({ prefix: 'useDragAndDrop', enabled: enableDragDropLogs });

  // A place to store a built runScheduler reason string that is used
  // as a parameter to the runScheduler() call to provide context on
  // the console log.
  let rerunSchedulerReason = '';

  const onBeforeDragStart = start => {
    log('onBeforeDragStart: ', start);
    //runScheduler(`A drag is about to start`);
  };

  const onDragStart = (start, provided) => {
    log('onDragStart: ', start, provided);
    hideBuffers();
    hideAddMoveButton();
    //runScheduler(`A drag started`);
  };

  const onDragUpdate = (update, provided) => {
    //log('onDragStart: ', update, provided);
    //runScheduler(`A drag has updated`);
    const { source, destination, draggableId } = update;
    const { index: destinationIndex = 0 } = destination || {};

    log(`Hovering over position ${destinationIndex}`);
  };

  /**
   * The index of where a move gets dropped does not directly correlate to the
   * position in the workingPlan.enrichedMoves array that it should be injected
   * at because of the ride moves that are in the array too but we don't render
   * in the on-screen list.
   *
   * This function will translate between the dropped index of the on-screen
   * move bundles array to the correct position in the workingPlan.enrichedMoves
   * array.
   *
   * @param {Number} dropIndex Index of the destination droppable array
   * @returns {Number} Correlated position in workingPlan.enrichedMoves array
   */
  const translateDropIndex = dropIndex => {
    let { enrichedMoves = [] } = workingPlan;
    enrichedMoves = enrichedMoves || [];

    // Example
    //   On-screen scheduler shows vertical array of drive moves (bundles)
    //     Drive Move IDs: [53, 56, 59]

    //   The source enriched moves array holds 7 moves (drives marked with *)
    //     Enriched Move IDs: [ 52, 53*, 54, | 55, 56*, 57, | 58, 59*, 60]
    //
    //   Formula:
    //     (dropIndex == 0 ? 0 : dropIndex >= Drive Move IDs length ? Drive Move IDs length : Enriched Move IDs Index - (beforeIsRide ? 1 : 0))
    //
    //   If the dropIndex is 0 then the following is true:
    //     Displaced Move ID: 53
    //     Enriched Move IDs Index: 1
    //     Displaced Move's Before Move is a Ride: true
    //     Displaced Move's After Move is a Ride: true
    //     Array position is (First Position) = 0
    //
    //   If the dropIndex is 1 then the following is true:
    //     Displaced Move ID: 56
    //     Enriched Move IDs Index: 4
    //     Displaced Move's Before Move is a Ride: true
    //     Displaced Move's After Move is a Ride: true
    //     Array position is 4 - 1 = 3
    //
    //   If the dropIndex is 2 then the following is true:
    //     Displaced Move ID: 59
    //     Enriched Move IDs Index: 7
    //     Displaced Move's Before Move is a Ride: true
    //     Displaced Move's After Move is a Ride: true
    //     Array position is 7 - 1 = 6
    //
    //   If the dropIndex is 3 then the following is true:
    //     Displaced Move ID: null
    //     Enriched Move IDs Index: null
    //     Displaced Move's Before Move is a Ride: false
    //     Displaced Move's After Move is a Ride: false
    //     Array position is (Enriched Moves Length) = 9

    const driveMoveIds = getDriveMoveIds();
    const enrichedMoveIds = enrichedMoves.map(o => o.move.id);
    const dropMoveId = driveMoveIds[dropIndex];
    const enrichedMoveIdsIndex = enrichedMoveIds.indexOf(dropMoveId);
    const enrichedDriveMove = getLocalEnrichedMoveById(dropMoveId);

    let xlatedIndex = 0;

    if (dropIndex == 0) {
      xlatedIndex = 0;
    } else {
      if (dropIndex >= driveMoveIds.length) {
        xlatedIndex = enrichedMoves.length;
      } else {
        xlatedIndex = enrichedMoveIdsIndex - (enrichedDriveMove.hasRideBefore ? 1 : 0);
      }
    }

    return xlatedIndex;
  };

  /**
   * Get the next drive move found in the plan chronologically after
   * the drop destination index provided. This is expacting the index
   * of the array of drive move bundles being dropped on in the
   * scheduler. This is NOT the position (index) of the drop in
   * the workingPlan.enrichedMoves array. To get that index, we must
   * first use the translateDropIndex function.
   *
   * @param {Number} dropIndex The index to start at in searching
   * @returns {EnrichedMove} Found enrichedMove or null if there is no next move
   */
  const getNextDriveMoveAfterDrop = (dropIndex = -1) => {
    const driveMoveIds = getDriveMoveIds();
    const driveMoveId = driveMoveIds[dropIndex];
    return getNextDriveMoveById(driveMoveId);
  };

  const dropUnassignedOnScheduler = (draggableId, index) => {
    try {
      const injectionIndex = translateDropIndex(index);
      const move = unassignedMoves.find(o => o.id == draggableId);
      tryToPlanMove(move, injectionIndex);

      // TODO: We need to check if the move being dragged is part of a 
      // set and if so check the compatibility of both moves in the set

      // Show warning if driver does not fit the criteria
      // if (!checkMoveAssign(workingPlan.driver_id, move)) {
      //   ifConfirmed({
      //     // TODO: Provide the incompatible thing to the UI in the modal
      //     body: 'Driver does not meet the criteria of this move!',
      //     onAgree: () => {
      //       const plannedMoves = scheduleUnplannedMoveWithSiblings(move, injectionIndex);
      //       rerunSchedulerReason = `User dropped move(s) ${JSON.stringify(
      //         plannedMoves.map(o => o.id)
      //       )} at position ${index}`;
      //       log(rerunSchedulerReason);
      //     },
      //   });
      //}
    } catch (error) {
      log(`dropUnassignedOnScheduler failed because ${error.message}`);
    }
  };

  const dropUnassignedOnPlan = (draggableId, droppableId) => {
    try {
      const move = unassignedMoves.find(o => o.id == draggableId);
      const planId = droppableId.replace('droppable-timeline-row-', '');
      const foundPlan = selectPlanById(planId);
      log(`dropUnassignedOnPlan - Dragging ${draggableId} then dropped onto plan ${planId}`, foundPlan);

      // Show warning if driver does not fit the criteria
      if (!checkMoveAssign(foundPlan.driver_id, move)) {
        ifConfirmed({
          body: 'Driver does not meet the criteria of this move!',
          onAgree: () => {
            queueMoveForPlanning(move);
            rerunSchedulerReason = `User dropped move ${move.id} onto plan ${planId} and it was queued for planning.`;
            log(rerunSchedulerReason);
            showScheduler();
          },
        });
      } else {
        queueMoveForPlanning(move);
        rerunSchedulerReason = `User dropped move ${move.id} onto plan ${planId} and it was queued for planning.`;
        log(rerunSchedulerReason);
        showScheduler();
      }
    } catch (error) {
      log(`dropUnassignedOnPlan failed because ${error.message}`);
    }
  };

  const dragAroundOnScheduler = (draggableId, index) => {
    try {
      let { enrichedMoves = [] } = workingPlan;
      enrichedMoves = enrichedMoves || [];
      let enrichedMove = enrichedMoves.find(o => o.move.id == draggableId) || {};
      reorderEnrichedMove(enrichedMove, index);
      const { move = {} } = enrichedMove;
      rerunSchedulerReason = `User rearranged move(s) ${move.id} in the scheduler to index ${index}`;
      log(rerunSchedulerReason, enrichedMove);
    } catch (error) {
      log(`dragAroundOnSchedule failed because ${error.message}`);
    }
  };

  const onDragEnd = async result => {
    try {
      const { source, destination, draggableId } = result;

      log(`[InvalidDropIndexes] Dragging done. Dropped at ${destination.index}`);

      if (!destination || result.reason === 'CANCEL') return;

      if (source.droppableId === destination.droppableId && source.index === destination.index) return;

      log('onDragEnd: ', result);

      if (source.droppableId === 'droppable-unassigned') {
        if (destination.droppableId === 'droppable-schedule') {
          dropUnassignedOnScheduler(draggableId, destination.index);
          runScheduler(rerunSchedulerReason);
        } else if (destination.droppableId.includes('droppable-timeline-row')) {
          dropUnassignedOnPlan(draggableId, destination.droppableId);
          // Don't call runScheduler() here because it overrides the queued move planning
        } else {
          log(`Dropped unassigned move onto unknown droppable zone ${destination.droppableId}`);
        }
      } else if (source.droppableId === 'droppable-schedule') {
        if (destination.droppableId === 'droppable-schedule') {
          dragAroundOnScheduler(draggableId, destination.index);
          runScheduler(rerunSchedulerReason);
        }
      }
    } catch (error) {
      log(`onDragEnd crashed because ${error.message}`);
    } finally {
      showBuffers();
      showAddMoveButton();
    }
  };

  return {
    onBeforeDragStart,
    onDragStart,
    onDragUpdate,
    onDragEnd,
  };
}
