import React, { useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';
import { toast } from 'react-toastify';
import MoveLocation from '../MoveLocation';
import LocationSelect from '../LocationSelect';
import axios from 'axios';
import sdk from '@hopdrive/sdk';
import { getUserEmail } from '../../utils/authHelper';
import { useEngine } from '../../hooks/useEngine';

////////// COMPONENT //////////
export default function EditLaneModalContent(props) {
  const cls = useStyles();
  const { onClose, move } = props;
  const { lane } = move;
  const { rerunAP } = useEngine();

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [pickup, setPickup] = useState(lane.pickup);
  const [delivery, setDelivery] = useState(lane.delivery);
  const [hasChanges, setHasChanges] = useState(false);
  const [pairedMove, setPairedMove] = useState(null);
  const [updateType, setUpdateType] = useState(null);
  const [userEmail, setUserEmail] = React.useState();

  React.useEffect(() => {
    if (move?.childMoves?.length && move?.childMoves?.length > 0) {
      move.childMoves.forEach(childMove => {
        if (childMove.move_type && childMove.move_type === 'drive' && childMove.active) {
          setPairedMove(childMove);
        }
      });
    } else if (move?.parentMove && move?.parentMove?.move_type && move?.parentMove?.move_type === 'drive') {
      setPairedMove(move?.parentMove);
    }

    let email = getUserEmail();
    setUserEmail(email);
  }, [move]);

  //in case they change between pickup and delivery after they've already selected a location
  React.useEffect(() => {
    if (hasChanges) {
      if (selectedLocation === 'delivery' && pickup?.address !== lane.pickup?.address) {
        setDelivery(pickup);
        setPickup(lane.pickup);
      } else if (selectedLocation === 'pickup' && delivery?.address !== lane.delivery?.address) {
        setPickup(delivery);
        setDelivery(lane.delivery);
      }
    }
  }, [hasChanges, selectedLocation]);

  React.useEffect(() => {
    if (updateType) {
      handleSaveLane();
    }
  }, [updateType]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleLocationSelect = location => {
    if (!location) return;
    setHasChanges(true);
    if (selectedLocation === 'pickup') {
      setPickup(location);
    } else if (selectedLocation === 'delivery') {
      setDelivery(location);
    }
  };

  const handleSaveLane = async () => {
    setUpdateType(null);
    if (
      !pickup?.region?.id &&
      !pickup?.regionId &&
      !pickup.region_id &&
      !delivery?.region?.id &&
      !delivery?.regionId &&
      !delivery?.region_id
    ) {
      toast.error('At least one location must be in a Hopdrive service region');
      return;
    }
    if (pickup?.address === delivery?.address) {
      toast.error('Pickup and delivery locations cannot be the same');
      return;
    }
    try {
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/handleMoveLaneUpdate',
        data: {
          type: updateType,
          move_id: move?.id,
          paired_move_id: pairedMove?.id,
          customer_id: move?.customer?.id,
          pickup: { name: pickup.name, address: pickup.address },
          delivery: { name: delivery.name, address: delivery.address },
        },
      });
      if (response?.status === 200) {
        toast.success('Lane updated successfully');
        await writeEventLog(response?.data, move.id, pairedMove?.id);
        if (pairedMove && updateType === 'pair') {
          await writeEventLog(response?.data, move.id, pairedMove?.id);
        }
        if (onClose) onClose();
      } else if (response?.status === 206) {
        if (response?.data?.laneId) {
          toast.warn('This lane updated successfully, but paired move failed to update');
          await writeEventLog(response?.data, move.id, null);
        } else if (response?.data?.inverseId) {
          toast.warn('Paired move updated successfully, but this lane failed to update');
          await writeEventLog(response?.data, null, pairedMove?.id);
        }
        if (onClose) onClose();
      } else {
        toast.error('Failed to update lane');
        if (onClose) onClose();
      }
    } catch (err) {
      toast.error('Failed to update lane');
      console.error('Failed to save lane and/or location', err);
      if (onClose) onClose();
    }
  };

  const writeEventLog = async (data, moveId, inverseId) => {
    try {
      if (moveId) {
        let metadata = {
          old: {
            pickupAddress: lane?.pickup?.address,
            pickupId: lane?.pickup?.id,
            deliveryAddress: lane?.delivery?.address,
            deliveryId: lane?.delivery?.id,
          },
          new: {
            pickupAddress: pickup?.address,
            pickupId: data?.lanePickupId || pickup?.id,
            deliveryAddress: delivery?.address,
            deliveryId: data?.laneDeliveryId || delivery?.id,
          },
        };
  
        // Insert move update eventlog
        let eventConfig = {
          action: `move.lane.updated`,
          user: userEmail,
          role: 'admin',
          move_id: move.id,
          lane_id: data?.laneId,
          customer_id: move.customer?.id,
          metadata: metadata,
        };
        await sdk.events.buildAndCreate(eventConfig);
      }
      if (inverseId) {
        let pairedMoveMetadata = {
          old: {
            pickupAddress: lane?.delivery?.address,
            pickupId: lane?.delivery?.id,
            deliveryAddress: lane?.pickup?.address,
            deliveryId: lane?.pickup?.id,
          },
          new: {
            pickupAddress: delivery?.address,
            pickupId: data?.inversePickupId || delivery?.id,
            deliveryAddress: pickup?.address,
            deliveryId: data?.inverseDeliveryId || pickup?.id,
          },
        };
        let pairedEventConfig = {
          action: `move.lane.updated`,
          user: userEmail,
          role: 'admin',
          move_id: pairedMove.id,
          customer_id: pairedMove.customer?.id,
          lane_id: data?.inverseId,
          metadata: pairedMoveMetadata,
        };
        await sdk.events.buildAndCreate(pairedEventConfig);
      }
    } catch (err) {
      console.error(`Failed to insert eventlog:`, err);
    }
  };

  return (
    <>
      <ModalHeader handleClose={handleClose}>Edit Lane</ModalHeader>

      <ModalContent subtitle={`Click on a pickup or delivery location to update the lane.`}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MoveLocation
              type='pickup'
              move={move}
              editMode={true}
              selected={selectedLocation}
              setSelected={setSelectedLocation}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MoveLocation
              type='delivery'
              move={move}
              editMode={true}
              selected={selectedLocation}
              setSelected={setSelectedLocation}
            />
          </Grid>
        </Grid>

        {selectedLocation ? (
          <>
            <div className={cls.bigBreak} />
            <LocationSelect
              customerId={move?.customer?.id}
              locationData={selectedLocation === 'pickup' ? lane.pickup : lane.delivery}
              onChange={handleLocationSelect}
              label={selectedLocation === 'pickup' ? 'Pickup Location' : 'Delivery Location'}
            />
          </>
        ) : null}

        <div className={cls.bigBreak} />
      </ModalContent>

      <ModalFooter>
        {pairedMove ? (
          <>
            <ModalAction onClick={() => setUpdateType('pair')} disabled={!hasChanges}>
              {' '}
              Update Both Paired Moves{' '}
            </ModalAction>
            <ModalAction onClick={() => setUpdateType('one')} disabled={!hasChanges}>
              {' '}
              Update Only This Move{' '}
            </ModalAction>
          </>
        ) : (
          <ModalAction onClick={() => setUpdateType('one')} disabled={!hasChanges}>
            Submit
          </ModalAction>
        )}
        <ModalAction onClick={() => handleClose()} color='secondary'>
          Cancel
        </ModalAction>
      </ModalFooter>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '21px',
    fontWeight: 500,
  },
  input: {
    margin: 0,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(4),
  },
}));
