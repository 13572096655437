import React from 'react';
import { makeStyles, withStyles, Switch } from '@material-ui/core';

export default function CustomSwitch({ children, editMode = false, ...rest }) {
  const cls = useStyles();

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.text.secondary,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: editMode ? theme.palette.primary.main : `#00000000`,
          backgroundColor: editMode ? theme.palette.primary.main : theme.palette.text.secondary,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);

  return (
    <div className={cls.switch}>
      <AntSwitch disabled={!editMode} {...rest} />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  switch: {
    width: '100%',
  },
}));
