//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import sdk from '@hopdrive/sdk';
import { useTools } from '../../hooks/useTools';

import { AttemptContent, AttemptInfoFull, AttemptInfoBrief } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideAttemptDetails({
  ride = {},
  attempt = {},
  driveLane = null,
  rideLane = null,
  rideshareRate = 0,
  rideCostEstimate = 0,
  title = null,
  subtitle = null,
  loading,
  error,
}) {
  const cls = useStyles();

  const { calculateRideCostDelta } = useTools();

  const [calcedSurge, setCalcedSurge] = React.useState(0);

  React.useEffect(() => {
    if (attempt && attempt.surge_cost_percentage && attempt.ride_cost && driveLane && rideLane) {
      let surgeCostDelta = calculateRideCostDelta(
        attempt.surge_cost_percentage,
        attempt.ride_cost,
        driveLane,
        rideLane,
        rideshareRate,
        sdk
      );
      setCalcedSurge(surgeCostDelta);
    }
  }, [attempt, driveLane, rideLane, rideshareRate]);

  const Header = () => {
    if (title || subtitle) {
      return (
        <>
          {title ? <Typography className={cls.titleTxt}>{title}</Typography> : null}
          {subtitle ? <Typography className={cls.subtitleTxt}>{subtitle}</Typography> : null}
          <Spacer size='xs' />
        </>
      );
    }
    return null;
  };

  const Details = () => {
    if (loading || error || !attempt) {
      return (
        <div className={cls.loadErrorMissing}>
          <div className={cls.loadErrorMissingBox}>
            {loading ? (
              <Typography className={cls.loadErrorMissingTxt}>LOADING RIDE DATA...</Typography>
            ) : error ? (
              <Typography className={cls.loadErrorMissingTxt}>ERROR FETCHING RIDE DATA!</Typography>
            ) : !attempt ? (
              <Typography className={cls.loadErrorMissingTxt}>RIDE HAS NOT BEEN CALLED</Typography>
            ) : null}
          </div>
        </div>
      );
    }

    if (attempt && !loading && !error) {
      // If estimate attempts are active, render comparison or chosen view
      if (ride && ride.active_estimate_attempts) {
        // Render comparison if estimate has started and decision has not completed
        if (
          attempt.status === sdk.ridehail.attemptStatuses.ESTIMATING.code ||
          attempt.status === sdk.ridehail.attemptStatuses.ESTIMATED.code
        ) {
          let lyftEstimate =
            ride.attempts.find(
              attempt =>
                attempt.estimate_id === ride.active_estimate_attempts &&
                attempt.ride_type === sdk.ridehail.rideTypes.LYFT.code
            ) || {};
          let uberEstimate =
            ride.attempts.find(
              attempt =>
                attempt.estimate_id === ride.active_estimate_attempts &&
                attempt.ride_type === sdk.ridehail.rideTypes.UBER.code
            ) || {};

          return (
            <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs={6}>
                <AttemptContent ride={ride} attempt={lyftEstimate}>
                  <AttemptInfoBrief
                    attempt={lyftEstimate}
                    rideCostEstimate={rideCostEstimate}
                    calcedSurge={calcedSurge}
                    condensed
                  />
                </AttemptContent>
              </Grid>

              <Grid item xs={6}>
                <AttemptContent ride={ride} attempt={uberEstimate}>
                  <AttemptInfoBrief
                    attempt={uberEstimate}
                    rideCostEstimate={rideCostEstimate}
                    calcedSurge={calcedSurge}
                    condensed
                  />
                </AttemptContent>
              </Grid>
            </Grid>
          );
        }

        // Render chosen view if decision has completed
        else {
          let chosenEstimate =
            ride.attempts.find(
              attempt =>
                attempt.estimate_id === ride.active_estimate_attempts &&
                attempt.status !== sdk.ridehail.attemptStatuses.NOTCHOSEN.code
            ) || {};
          let notChosenEstimate =
            ride.attempts.find(
              attempt =>
                attempt.estimate_id === ride.active_estimate_attempts &&
                attempt.status === sdk.ridehail.attemptStatuses.NOTCHOSEN.code
            ) || {};

          return (
            <>
              <AttemptContent ride={ride} attempt={chosenEstimate}>
                <AttemptInfoFull
                  ride={ride}
                  attempt={chosenEstimate}
                  rideCostEstimate={rideCostEstimate}
                  calcedSurge={calcedSurge}
                />
              </AttemptContent>

              <Spacer />

              <AttemptContent ride={ride} attempt={notChosenEstimate} backdrop>
                <AttemptInfoBrief
                  attempt={notChosenEstimate}
                  rideCostEstimate={rideCostEstimate}
                  calcedSurge={calcedSurge}
                />
              </AttemptContent>
            </>
          );
        }
      }

      // If no estimate attempts are active, render only active attempt view
      else {
        return (
          <AttemptContent ride={ride} attempt={attempt}>
            <AttemptInfoFull
              ride={ride}
              attempt={attempt}
              rideCostEstimate={rideCostEstimate}
              calcedSurge={calcedSurge}
            />
          </AttemptContent>
        );
      }
    }
    return <div className={cls.loadErrorMissing} />;
  };

  return (
    <>
      <Header />
      <Details />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitleTxt: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  details: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  headTxtL: {
    lineHeight: 1.25,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  headTxtC: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  statusIndicator: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  statusIcon: {
    // fontSize: 40,
  },

  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },

  loadErrorMissing: {
    position: 'relative',
    width: '100%',
    height: 282.5,
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  loadErrorMissingBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  },
  loadErrorMissingTxt: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
}));
