import React from 'react';
import { gql } from 'graphql-tag';

import { useData } from '../../../providers/DataProvider';
import { useTookan } from '../../../hooks/useTookan';

import { useSettings } from '../providers/SettingsProvider';
import { usePlans } from './PlansProvider';
import { useScheduler } from './SchedulerProvider';
import { useEnrichedPlans } from '../hooks/useEnrichedPlans';
import { isTookanMove } from '../utils/helpers';

import { SimpleLogger } from '../../../utils/SimpleLogger';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

const TimelineContext = React.createContext({});

let _state = 'timeline.ready';

function TimelineProvider({ refreshSeconds = 5000, children }) {
  const ctx = useData();
  const { Tookan } = useTookan();

  const { timelineDate, isTimelineDateToday, timelineScaleWidth, enableTimelineLogs } = useSettings();
  const { plans, state: plansState } = usePlans();
  const { isSchedulerVisible } = useScheduler();
  const { buildEnrichedPlan, getCurrentTime, getCurrentPosition } = useEnrichedPlans();

  const [enrichedPlans, setEnrichedPlans] = React.useState([]);
  const [currentPosition, setCurrentPosition] = React.useState(getCurrentPosition());
  const [currentTime, setCurrentTime] = React.useState(getCurrentTime());

  const { log } = new SimpleLogger({ prefix: 'TimelineProvider', enabled: enableTimelineLogs });

  React.useEffect(() => {
    log(`Timeline date changed. Setting timeline state.`);
    _state = 'timeline.loading';
  }, [timelineDate]);

  // Interval update to rebuild the enrichedPlans
  const updateEnrichedPlans = () => {
    // log(`updateEnrichedPlans called plans: `, plans);
    if (plans && plans.length > 0) {
      const mutablePlans = JSON.parse(JSON.stringify(plans));
      const newEnrichedPlans = mutablePlans.map(mp => buildEnrichedPlan(mp));
      //log(`Rebuilding enrichedPlans on polling update...`, newEnrichedPlans);
      setEnrichedPlans(newEnrichedPlans);
    } else setEnrichedPlans([]);
  };

  // Interval update to set the current time, current position and enrichedPlans
  const updateInterval = () => {
    // log(`UpdateInterval called. Plans: `, plans);
    setCurrentPosition(getCurrentPosition());
    setCurrentTime(getCurrentTime());
    updateEnrichedPlans();
  };

  // Timer interval to update the marker position
  // Reset the interval when the base width changes (had issues with the update function using only the initial timeline scale)
  const timer = React.useRef();
  React.useEffect(() => {
    if (plansState === `query.successful`) {
      _state = 'timeline.ready';
      log(`Setting timeline interval with plans:`, plans);
      updateInterval();
      clearInterval(timer.current);
      if (isTimelineDateToday && !isSchedulerVisible)
        timer.current = setInterval(() => updateInterval(), refreshSeconds * 1000);
    }
    return () => clearInterval(timer.current);
  }, [plans, plansState, isTimelineDateToday, isSchedulerVisible, timelineScaleWidth]);

  // Assign moves to a driver by syncing them
  const assignMoves = async moves => {
    if (moves && moves.length) {
      const moveObjects = moves.map(m => {
        const driverStatus =
          !m.driver_status || m.driver_status === `unassigned` || m.driver_status === `draft`
            ? `assigned`
            : m.driver_status;

        return {
          id: m.id,
          driver_status: driverStatus,
          updatedat: `now()`,
        };
      });

      try {
        const res = await ctx.apolloClient.mutate({
          mutation: ASSIGN_MOVES,
          variables: {
            moveObjects,
          },
        });

        if (getPropValue(res, `data.insert_moves.affected_rows`)) {
          toast.info(`Synced move(s) to driver!`, { autoClose: 2000 });
        }
      } catch (err) {
        console.error(`Failed to sync move(s) to driver:`, err);
        toast.error(`Failed to sync move(s) to driver!`);
      }
    }
  };

  // Sync a single move with Tookan
  const handleSyncOneMove = async move => {
    log(`handleSyncOneMove => move:`, move);

    // Handle a move that needs to go to Tookan
    if (move && isTookanMove(move)) {
      Tookan.handleTookanMoves(ctx.apolloClient, [move]);
      toast.info(`Synced move(s) to Tookan!`, { autoClose: 2000 });
    }

    // Handle a move that needs to go to driver app
    if (move && !isTookanMove(move)) {
      await assignMoves([move]);
    }
  };

  // Sync a single plan with Tookan
  const handleSyncOnePlan = async plan => {
    log(`handleSyncOnePlan => plan:`, plan);

    // Separate Tookan moves and driver app moves
    let tookanMoves = [];
    let driverAppMoves = [];

    // Assign each move to its expected array
    if (plan && plan.moves && plan.moves.length) {
      plan.moves.forEach(m => {
        if (m && isTookanMove(m)) tookanMoves.push(m);
        if (m && !isTookanMove(m)) driverAppMoves.push(m);
      });
    }

    // Handle the Tookan moves
    if (tookanMoves && tookanMoves.length) {
      Tookan.handleTookanMoves(ctx.apolloClient, plan.moves);
      toast.info(`Synced move(s) to Tookan!`, { autoClose: 2000 });
    }

    // Handle the driver app moves
    if (driverAppMoves && driverAppMoves.length) {
      await assignMoves(driverAppMoves);
    }
  };

  // Get the seconds and pixels from the last saved scoll position
  const getLastScrollPosition = () => {
    // Default to 7am (25,200 seconds since midnight) if no position exists yet
    const scrollPosSecs = localStorage.getItem(`timeline-scroll-seconds`) || 25200;
    return {
      seconds: scrollPosSecs,
      pixels: scrollPosSecs * timelineScaleWidth,
    };
  };

  // Set the scroll position in seconds
  const updateLastScrollSeconds = pxPos => {
    const scrollPosSeconds = pxPos / timelineScaleWidth;
    localStorage.setItem(`timeline-scroll-seconds`, scrollPosSeconds);
  };

  // Context
  const context = {
    state: _state,
    enrichedPlans,
    currentPosition,
    currentTime,
    handleSyncOneMove,
    handleSyncOnePlan,
    getLastScrollPosition,
    updateLastScrollSeconds,
  };

  return <TimelineContext.Provider value={context}>{children}</TimelineContext.Provider>;
}

const useTimeline = () => React.useContext(TimelineContext);

export { useTimeline, TimelineProvider };

//////////////////////// GRAPHQL ////////////////////////

const ASSIGN_MOVES = gql`
  mutation admin_plans_assignMoves($moveObjects: [moves_insert_input!]!) {
    insert_moves(
      objects: $moveObjects
      on_conflict: { constraint: moves_pkey, update_columns: [driver_status, updatedat] }
    ) {
      affected_rows
    }
  }
`;
