// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import GoogleMap from 'google-map-react';

import { useTheme, makeStyles, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { styles } from '../../utils/googleMapOptions';

import { useGPSViewer } from './GPSViewerProvider';

const log = true;

// COMPONENT -------------------------------------------------- //

export default function GPSViewerMap(props) {
  const theme = useTheme();
  const cls = useStyles();

  const {
    // Base State
    mapPoints,
    move,
    locations,
    selectedLocation,
    selectedPing,
    showPings,

    // Handlers
    setSelectedLocation,
    setSelectedPing,
  } = useGPSViewer();

  const [etaTime, setEtaTime] = React.useState(null);
  const [etaMinutes, setEtaMinutes] = React.useState(null);

  const [renderer, setRenderer] = React.useState(null);
  const [service, setService] = React.useState(null);
  const [directionsRenderer, setDirectionsRenderer] = React.useState(null);
  const [directionsService, setDirectionsService] = React.useState(null);

  const [driverPath, setDriverPath] = React.useState(null);

  // Once the Google API is loaded, we can redraw the polyline based on map points
  React.useEffect(() => {
    if (mapPoints && mapPoints.length && renderer && service && directionsRenderer && directionsService) {
      log && console.log(`REDRAWING`);
      if (driverPath) removeDriverPath();
      setTimeout(() => {
        addDriverPath();
      }, 250);
    }

    // eslint-disable-next-line
  }, [mapPoints]);

  // Remove the driver's path from the map
  const removeDriverPath = () => {
    try {
      // Remove the polyline
      driverPath.setVisible(false);

      // Set the path in state
      setDriverPath(driverPath);
    } catch (err) {
      console.error(`Failed to remove the driver's path from the map:`, err);
    }
  };

  // Draw the driver's path on the map
  const addDriverPath = async () => {
    try {
      // Create the polyline from the driver path
      const polyline = new service.Polyline({
        path: mapPoints,
        geodesic: true,
        strokeColor: theme.palette.primary.main,
        strokeOpacity: !move || move.status === `delivery successful` ? 1 : 0.333,
        strokeWeight: 4,
      });

      // Draw the path onto the map component
      polyline.setMap(renderer);

      // Set the path in state
      setDriverPath(polyline);
    } catch (err) {
      console.error(`Failed to draw the driver's path on the map:`, err);
    }
  };

  // Function for getting center lat long coordinates based on locations
  let getCenterCoords = () => {
    if (locations.length) {
      let latSum = 0;
      let lonSum = 0;

      locations.forEach(loc => {
        latSum += loc.latitude || 0;
        lonSum += loc.longitude || 0;
      });

      const latAvg = latSum / locations.length;
      const lonAvg = lonSum / locations.length;
      return [latAvg, lonAvg];
    } else {
      return [37.5407, -77.436];
    }
  };

  // Handle clicking on a map marker
  const handleMarkerClick = location => {
    if (selectedLocation && selectedLocation.id === location.id) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(location);
    }
  };

  // Handle loading of Google Maps API
  const handleGoogleApiLoaded = (apiRenderer, apiService) => {
    setRenderer(apiRenderer);
    setService(apiService);

    // Set Google Directions API renderer
    const newDirectionsRenderer = new apiService.DirectionsRenderer({
      polylineOptions: {
        geodesic: true,
        strokeColor: theme.palette.info.main,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      },
      suppressMarkers: true,
      provideRouteAlternatives: false,
    });
    newDirectionsRenderer.setMap(apiRenderer);
    setDirectionsRenderer(newDirectionsRenderer);

    // Set Google Directions API service
    const newDirectionsService = new apiService.DirectionsService();
    setDirectionsService(newDirectionsService);
  };

  // Handle building the driver's route
  const handleRoute = async (origin, destination) => {
    try {
      directionsRenderer.setMap(renderer);

      // Throw an error if there's a malformed origin or destination
      if (
        !origin ||
        !origin.latitude ||
        !origin.longitude ||
        !destination ||
        !destination.latitude ||
        !destination.longitude
      ) {
        throw new Error(
          `Input for Google call was malformed. Skipping Google call. Driver's route was not rendered...`
        );
      }

      // Build the origin and destination strings to feed into Google
      const originStr = `${origin.latitude},${origin.longitude}`;
      const destinationStr = `${destination.latitude},${destination.longitude}`;

      // Build the request to Google
      const req = {
        origin: originStr,
        destination: destinationStr,
        travelMode: 'DRIVING',
      };

      // Call Google's Directions API to get the driver route
      directionsService.route(req, (res, status) => {
        if (status === 'OK') {
          // Render the route from the response
          // log && console.log(`Google Route Response:`, res);
          directionsRenderer.setDirections(res);

          // Set the ETA
          const durationSec = res.routes[0].legs[0].duration.value || 0;
          const durationMin = Math.round(durationSec / 60);
          const curTimePlusDurationSec = dayjs()
            .add(durationSec || 0, `seconds`)
            .format(`h:mm A`);

          setEtaTime(curTimePlusDurationSec);
          setEtaMinutes(durationMin);
        }
      });
    } catch (err) {
      console.error(`Driver's route could not be determined:`, err);
    }
  };

  // Location marker component
  const LocationMarker = ({ loc, useEta, useActive }) => {
    return (
      <div
        onClick={() => handleMarkerClick(loc)}
        className={clsx(cls.marker, {
          [cls.markerEta]: useEta && etaMinutes !== null,
          [cls.markerActive]: useActive,
          [cls.markerPickup]: loc.type === `pickup_loc`,
          [cls.markerDelivery]: loc.type === `delivery_loc`,
          [cls.markerDriver]: loc.type === `driver_loc`,
        })}
      >
        {/* ICON */}

        {loc.type === `pickup_loc` ? (
          <>
            {move.status === `pickup started` && etaMinutes !== null ? (
              <>
                <Typography className={cls.markerEtaTxt}>{etaMinutes}</Typography>
                <Typography className={cls.markerEtaUnitTxt}>MIN</Typography>
              </>
            ) : (
              <Typography className={cls.markerTxt}>P</Typography>
            )}
          </>
        ) : null}

        {loc.type === `delivery_loc` ? (
          <>
            {move.status === `delivery started` && etaMinutes !== null ? (
              <>
                <Typography className={cls.markerEtaTxt}>{etaMinutes}</Typography>
                <Typography className={cls.markerEtaUnitTxt}>MIN</Typography>
              </>
            ) : (
              <Typography className={cls.markerTxt}>D</Typography>
            )}
          </>
        ) : null}

        {loc.type === `driver_loc` ? <Icon className={cls.markerIcon}>account_circle</Icon> : null}

        {/* HOVER BOX */}

        <div className={cls.bubbleBox}>
          {/* TITLE */}

          {loc.type === `pickup_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.info.main }}>
                Pickup Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {loc.type === `delivery_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.success.main }}>
                Delivery Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {loc.type === `driver_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.primary.main }}>
                {move.driver_name || `HopDriver`}
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {/* NAME */}

          {loc.name ? <Typography className={cls.bubbleNameTxt}>{loc.name}</Typography> : null}

          {/* ADDRESS */}

          {loc.address ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleAddressTxt}>{loc.address}</Typography>
            </>
          ) : null}

          {/* LAT/LON */}

          {loc.latitude && loc.longitude ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleLatLonTxt}>
                {loc.latitude}, {loc.longitude}
              </Typography>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  // Ping marker component
  const PingMarker = ({ loc }) => {
    return <div className={cls.ping} />;
  };

  // Selected ping marker component
  const SelectedPingMarker = ({ loc }) => {
    return <div className={cls.selectedPing} onClick={() => setSelectedPing(null)} />;
  };

  return (
    <div className={cls.mapContainer}>
      {/* ROUTE INFO */}
      {etaTime !== null && etaMinutes !== null ? (
        <div className={cls.mapInfo}>
          <Typography className={cls.mapInfoTxt}>
            ETA {etaTime} ({etaMinutes} min)
          </Typography>
        </div>
      ) : null}

      {/* MAP */}
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
        center={getCenterCoords()}
        defaultZoom={12}
        options={{
          styles: styles,
        }}
        setOptions={{ clickableIcons: false }}
      >
        {/* LOCATION MARKERS */}
        {locations.map(location => {
          if (Number(location.latitude) && Number(location.longitude)) {
            let useEta = false;
            if (
              (location.type === `pickup_loc` && move.status === `pickup started`) ||
              (location.type === `delivery_loc` && move.status === `delivery started`)
            ) {
              useEta = true;
            }
            const useActive = selectedLocation && selectedLocation.id === location.id;

            return (
              <LocationMarker
                key={`location-marker-${location.id}`}
                lat={location.latitude}
                lng={location.longitude}
                loc={location}
                useEta={useEta}
                useActive={useActive}
              />
            );
          } else return null;
        })}

        {/* PING MARKERS */}
        {showPings
          ? mapPoints.map((ping, i) => {
              if (Number(ping.lat) && Number(ping.lng)) {
                return <PingMarker key={`ping-marker-${i}`} lat={ping.lat} lng={ping.lng} loc={ping} />;
              } else return null;
            })
          : null}

        {selectedPing ? <SelectedPingMarker lat={selectedPing.lat} lng={selectedPing.lng} loc={selectedPing} /> : null}
      </GoogleMap>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  mapContainer: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  mapInfo: {
    zIndex: 1,
    position: 'absolute',
    display: 'block',
    top: 10,
    left: 10,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  mapInfoTxt: {
    fontSize: 16,
    fontWeight: 500,
  },

  marker: {
    zIndex: 2,
    position: 'absolute',
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.text.contrast}`,
    borderRadius: '50%',
    transformOrigin: '50% 50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: theme.palette.text.contrast,
    transition: '0.1s',
    cursor: 'pointer',
    '& $bubbleBox': {
      display: 'none',
    },
    '&:hover': {
      zIndex: 4,
      width: 32,
      height: 32,
      border: `2px solid ${theme.palette.text.contrast}`,
      '& $bubbleBox': {
        display: 'block',
      },
    },
  },
  markerEta: {
    zIndex: 3,
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.text.contrast}`,
  },
  markerActive: {
    zIndex: 5,
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.text.contrast}`,
    '& $bubbleBox': {
      display: 'block',
    },
  },

  markerTxt: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 500,
  },
  markerEtaTxt: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 12,
    fontWeight: 700,
  },
  markerEtaUnitTxt: {
    position: 'absolute',
    top: '72%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 8,
    fontWeight: 300,
  },
  markerIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
  },
  markerPickup: {
    color: theme.palette.info.contrast,
    background: theme.palette.info.main,
  },
  markerDelivery: {
    color: theme.palette.success.contrast,
    background: theme.palette.success.main,
  },
  markerDriver: {
    color: theme.palette.primary.contrast,
    background: theme.palette.primary.main,
  },

  icon: {
    filter: 'drop-shadow(1px 1px 1px #00000064)',
    transition: '0.1s',
  },

  bubbleBox: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: theme.spacing(7),
    minWidth: 192,
    maxWidth: 192,
    padding: theme.spacing(1),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 1px 2px #00000064',
    backgroundColor: theme.palette.background.paper,
  },
  bubbleLine: {
    width: '100%',
    height: 1,
    marginTop: 4,
    marginBottom: 6,
    background: theme.palette.divider,
  },
  bubbleNameTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  bubbleAddressTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  bubbleLatLonTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  ping: {
    width: 8,
    height: 8,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    transform: 'translate(-50%, -50%)',
    background: theme.palette.background.paper,
  },
  selectedPing: {
    width: 16,
    height: 16,
    border: `4px solid ${theme.palette.info.main}`,
    borderRadius: 16,
    transform: 'translate(-50%, -50%)',
    background: theme.palette.background.paper,
    cursor: 'pointer',
  },
}));
