//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function SettingsTitle({ children, settingId, title, tip }) {
  const cls = useStyles();

  const handleAnchorClick = event => {
    const element = document.getElementById(settingId);
    const parentElement = document.getElementById(`settings-overflow`);
    if (parentElement) parentElement.scrollTo({ top: element.offsetTop, behavior: `smooth` });
  };

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item>
          <Icon className={cls.titleIcon}>arrow_forward</Icon>
        </Grid>

        <Grid item>
          <Typography className={cls.titleTxt} onClick={handleAnchorClick}>
            {title}&nbsp;
          </Typography>
        </Grid>

        <Grid item>
          {tip && (
            <Tooltip title={tip}>
              <span>
                <Icon className={cls.infoIcon}>info_outline</Icon>
              </span>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs />

        <Grid item>{children}</Grid>
      </Grid>

      <Spacer size={4} />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleIcon: {
    display: 'block',
    marginTop: -2,
    marginRight: 6,
    lineHeight: 1,
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    transition: '0.15s all ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  infoIcon: {
    display: 'block',
    marginTop: -2,
    lineHeight: 1,
    fontSize: 18,
    color: theme.palette.text.disabled,
    cursor: 'pointer',
  },
}));
