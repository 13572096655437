import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles, Icon, Typography, Tooltip } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';

import { useTools } from '../../hooks/useTools';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import Loading from '../../components/Loading';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

import { AccordionTable, AccordionRow } from '../../components/AccordionTable';
import ARReportsFooter from './ARReportsFooter';

import { GET_ARMOVES, GET_ARPAYMENTS } from './gql';


const log = false;

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

export default function ARReportsTableWrapper({ report, status, customerIdArray, start, end }) {
  const cls = useStyles();
  const { goToMoveDetails, goToInvoice } = useTools();

  const [search, setSearch] = useState();
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const variables = {
    status: status,
    customerIdArray: customerIdArray,
    start: dayjs(start).subtract(1, 'day').format(),
    end: end,
  };

  // HOOKS ///////////////////////////////////////////////////////////////////////////////////////////
  const [getMoves, { loading: movesLoading, error: movesError, data: movesData }] = useLazyQuery(
    GET_ARMOVES(status, customerIdArray),
    {
      variables,
    }
  );
  const [getPayments, { loading: paymentsLoading, error: paymentsError, data: paymentsData }] = useLazyQuery(
    GET_ARPAYMENTS(status, customerIdArray),
    { variables }
  );

  useEffect(() => {
    if (report === 'moves') getMoves();
    else if (report === 'payments') getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  // LOADING / ERROR RENDERS ///////////////////////////////////////////////////////////////////////
  if (movesLoading || paymentsLoading) return <Loading fixed />;
  if (movesError || paymentsError) {
    const error = movesError || paymentsError;

    console.log(`Error fetching ar reports (${report}):`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message={`ERROR FETCHING AR REPORTS (${report})`} />;
  }

  // DATA TOOLS ////////////////////////////////////////////////////////////////////////////////////
  const TableSort = {
    desc: (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    },

    stableSort: (array, cmp) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    },

    getSorting: (order, orderBy) => {
      return order === 'desc' ? (a, b) => TableSort.desc(a, b, orderBy) : (a, b) => -TableSort.desc(a, b, orderBy);
    },
  };

  const round = (num, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  };

  const checkNeg = num => {
    if (num > 0) return num;
    else return 0;
  };

  const cap = str => {
    if (str) {
      if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
      else {
        let arr = str.split(` `);
        arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
        return arr.join(` `);
      }
    }
  };

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move.customer.name && o.move.customer.name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.id && (o.move.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.lane.description && o.move.lane.description.toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = arreports => {
    if (arreports.length > 0) {
      const createCsvRow = arreport => {
        if (report === 'moves') {
          let regionName
          if (getPropValue(arreport, `move.lane.pickup.region.name`)){
            regionName = getPropValue(arreport, `move.lane.pickup.region.name`)
          } else if (getPropValue(arreport, `move.lane.delivery.region.name`)){
            regionName = getPropValue(arreport, `move.lane.delivery.region.name`)
          } else {
            regionName = null
            console.log('move id null region', getPropValue(arreport, `move.id`) )
          }

          return {
            ID: arreport.id,
            CUSTOMER: arreport.move.customer ? arreport.move.customer.name : `-`,
            MOVE_ID: arreport.move.id,
            DATE: dayjs(arreport.billable_datetime).utc().format(`MM/DD/YYYY`),
            LANE:
              arreport.move.lane && arreport.move.lane.description ? arreport.move.lane.description : `Unknown Lane`,
            STATUS: arreport.status ? cap(arreport.status) : `-`,
            TOTAL: arreport.due_amount
              ? `$${checkNeg(arreport.due_amount - arreport.discount_amount).toFixed(2)}`
              : arreport.due_amount === 0
              ? `$0.00`
              : `-`,
            REGION: regionName,
            WEEK_YEAR: dayjs(arreport.move.pickup_time).format(`YYYY-W`),
          };
        } else if (report === 'payments') {
          return {
            ID: arreport.id,
            CUSTOMER: arreport.arinvoice.customer ? arreport.arinvoice.customer.name : `-`,
            INVOICE_ID: arreport.arinvoice.id,
            ACCOUNTING_ID: arreport.accounting_id,
            TRANSACTION_ID: arreport.gateway_transaction_id,
            DATE: dayjs(arreport.createdat).format(`MM/DD/YYYY`),
            STATUS: arreport.status ? cap(arreport.status) : `-`,
            TOTAL: arreport.amount ? `$${checkNeg(arreport.amount).toFixed(2)}` : arreport.amount === 0 ? `$0.00` : `-`,
          };
        }
      };

      const csvRows = arreports.map(arreport => createCsvRow(arreport));
      let csvOptions = {
        showTitle: true,
        useKeysAsHeaders: true,
      };

      //NOTE: fix how the customerId name fixes
      if (report === `moves`) {
        csvOptions.filename = `${
          customerIdArray && customerIdArray.length ? arreports[0].move.customer.name.replace(/ /g, '_') : `All`
        }_AR_Records_from_${dayjs(start).format(`MM/DD/YYYY`)}_to_${dayjs(end).format(`MM/DD/YYYY`)}`;
      } else if (report === `payments`) {
        csvOptions.filename = `${
          customerIdArray && customerIdArray.length ? arreports[0].arinvoice.customer.name.replace(/ /g, '_') : `All`
        }_AR_Records_from_${dayjs(start).format(`MM/DD/YYYY`)}_to_${dayjs(end).format(`MM/DD/YYYY`)}`;
      }

      // Create and generate the CSV
      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(csvRows);
    }
  };

  const getRowTotal = row => {
    if (report === 'moves') {
      return (
        <>
          {row.armove.disputed || row.armove.discount_amount > 0 ? (
            <Tooltip
              disableFocusListener
              title={
                row.armove.disputed
                  ? `This AR record is disputed - ${row.armove.dispute_reason}`
                  : `This AR record is discounted for $${row.armove.discount_amount} - ${row.armove.discount_reason}`
              }
            >
              <Icon className={cls.rowIcon} fontSize='small'>
                {row.armove.disputed ? `announcement` : `local_offer`}
              </Icon>
            </Tooltip>
          ) : null}
          <Typography className={cls.rowTxt} style={{ display: 'inline' }}>
            {row.TOTAL}
          </Typography>
        </>
      );
    }
  };

  // Move Actions
  const getTableMoveActions = armoves => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(armoves) }];
  };
  const getRowMoveActions = armove => {
    return [
      { label: `Go\xa0To\xa0Invoice`, handler: () => goToInvoice(armove.invoice_id, 'customerId', armove.id) },
      { label: `Go\xa0To\xa0Move\xa0Details`, handler: () => goToMoveDetails(armove.move.id) },
    ];
  };

  // Payment Actions
  const getTablePaymentActions = arpayments => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(arpayments) }];
  };
  const getRowPaymentActions = arpayment => {
    return [{ label: `Go\xa0To\xa0Invoice`, handler: () => goToInvoice(arpayment.invoice.id, 'customerId') }];
  };

  // DATA RENDER ////////////////////////////////////////////////////////////////////////////////////
  if (movesData || paymentsData) {
    const data = movesData ? movesData.armoves : paymentsData.arpayments;
    const filteredAR = applyFilters(data);

    log && console.log(`AR Reports (${report})`, data);

    // Set a consistent amount object that holds the totals
    var amount = {};
    var headers;
    var rows;

    // Move Variables
    var subtotalMoves;
    var discountedMoves;

    // Payment Variables
    var disputedMoves;
    var paidMoves;
    var subtotalPayments;
    var successfulPayments;
    var failedPayments;

    // MOVES LOGIC ///////////////////////////////////////////////////////////////
    if (report === 'moves') {
      // Valid moves to calculate base totals
      subtotalMoves = filteredAR.filter(item => item.due_amount > 0);
      discountedMoves = subtotalMoves.filter(
        item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0
      );
      disputedMoves = subtotalMoves.filter(item => item.disputed === true);
      paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);

      // Base totals from valid moves
      amount.subtotal = round(
        subtotalMoves.length > 0
          ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.discounted = round(
        discountedMoves.length > 0
          ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.disputed = round(
        disputedMoves.length > 0
          ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.paid = round(
        paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0,
        2
      );

      // Set calculations from base totals
      amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
      amount.unpaid = checkNeg(amount.total - amount.paid);

      headers = [
        { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
        { id: `CUSTOMER`, alignLeft: true, numeric: false, label: `Customer` },
        { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
        { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
        { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
        { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
        { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
      ];

      rows = filteredAR.map(armove => {
        return {
          ID: armove.id,
          CUSTOMER: armove.move.customer ? armove.move.customer.name : `-`,
          MOVE_ID: armove.move.id,
          DATE: dayjs(armove.billable_datetime).utc().format(`MM/DD/YYYY`),
          LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
          STATUS: armove.status ? cap(armove.status) : `-`,
          TOTAL: armove.due_amount
            ? `$${checkNeg(armove.due_amount - armove.discount_amount).toFixed(2)}`
            : armove.due_amount === 0
            ? `$0.00`
            : `-`,
          armove: armove,
        };
      });

      // PAYMENTS LOGIC ////////////////////////////////////////////////////////////
    } else if (report === 'payments') {
      // Valid payments to calculate base totals
      subtotalPayments = filteredAR.filter(item => item.amount > 0);
      successfulPayments = subtotalPayments.filter(item => item.status === `successful`);
      failedPayments = subtotalPayments.filter(item => item.status === `failed`);

      // Base totals from valid payments
      amount.subtotal = round(
        subtotalPayments.length > 0
          ? subtotalPayments.map(item => item.amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.successful = round(
        successfulPayments.length > 0
          ? successfulPayments.map(item => item.amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.failed = round(
        failedPayments.length > 0
          ? failedPayments.map(item => item.amount).reduce((total, current) => total + current)
          : 0,
        2
      );

      // Set calculations from base totals
      amount.total = checkNeg(amount.subtotal);

      headers = [
        { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
        { id: `CUSTOMER`, alignLeft: true, numeric: false, label: `Customer` },
        { id: `INVOICE_ID`, alignLeft: true, numeric: true, label: `Invoice\xa0ID` },
        { id: `ACCOUNTING_ID`, alignLeft: true, numeric: true, label: `Accounting\xa0ID` },
        { id: `TRANSACTION_ID`, alignLeft: true, numeric: true, label: `Transaction\xa0ID` },
        { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
        { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
        { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
      ];

      rows = filteredAR.map(arpayment => {
        return {
          ID: arpayment.id,
          CUSTOMER: arpayment.arinvoice.customer ? arpayment.arinvoice.customer.name : `-`,
          INVOICE_ID: arpayment.arinvoice.id,
          ACCOUNTING_ID: arpayment.accounting_id,
          TRANSACTION_ID: arpayment.gateway_transaction_id,
          DATE: dayjs(arpayment.createdat).format(`MM/DD/YYYY`),
          STATUS: arpayment.status ? cap(arpayment.status) : `-`,
          TOTAL: arpayment.amount
            ? `$${checkNeg(arpayment.amount).toFixed(2)}`
            : arpayment.amount === 0
            ? `$0.00`
            : `-`,
          arpayment: arpayment,
        };
      });
    }

    // RENDER /////////////////////////////////////////////////////////////////////
    return (
      <>
        <AccordionTable
          title={`${rows.length} AR ${report === 'moves' ? 'Moves' : 'Payments'}`}
          headers={headers}
          rows={rows}
          actions={report === 'moves' ? getTableMoveActions(data) : getTablePaymentActions(data)}
          search={search}
          defaultOrder={defaultOrder}
          defaultOrderBy={defaultOrderBy}
          order={order}
          orderBy={orderBy}
          tablePage={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          setSearch={setSearch}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setTablePage={setTablePage}
          setRowsPerPage={setRowsPerPage}
          setExpandedRowId={setExpandedRowId}
          className={cls.table}
          refreshPersistAs={`ar_report_${report}`}
        >
          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
            .map(row => (
              <AccordionRow
                key={report === 'moves' ? `arreport-move-${row.armove.id}` : `arreport-payments-${row.arpayment.id}`}
                rowId={report === 'moves' ? row.armove.id : row.arpayment.id}
                expandedRowId={expandedRowId}
                setExpandedRowId={setExpandedRowId}
                columns={
                  report === 'moves'
                    ? [
                        { align: 'left', value: row.ID },
                        { align: 'left', value: row.CUSTOMER },
                        { align: 'left', value: row.MOVE_ID },
                        { align: 'right', value: row.DATE },
                        { align: 'left', value: row.LANE },
                        { align: 'left', value: row.STATUS },
                        { align: 'right', value: getRowTotal(row) },
                      ]
                    : [
                        { align: 'left', value: row.ID },
                        { align: 'left', value: row.CUSTOMER },
                        { align: 'left', value: row.INVOICE_ID },
                        { align: 'left', value: row.ACCOUNTING_ID },
                        { align: 'left', value: row.TRANSACTION_ID },
                        { align: 'right', value: row.DATE },
                        { align: 'left', value: row.STATUS },
                        { align: 'right', value: row.TOTAL },
                      ]
                }
                actions={report === 'moves' ? getRowMoveActions(row.armove) : getRowPaymentActions(row.arpayment)}
                onClick={() =>
                  report === 'moves'
                    ? goToInvoice(row.armove.invoice_id, null, row.armove.id)
                    : goToInvoice(row.INVOICE_ID, null)
                }
                className={
                  report === 'moves'
                    ? !row.armove.disputed
                      ? cls.row
                      : cls.rowDisputed
                    : !row.arpayment.disputed
                    ? cls.row
                    : cls.rowDisputed
                }
              >
                <div />
              </AccordionRow>
            ))}
        </AccordionTable>

        <div className={cls.break} />
        <ARReportsFooter report={report} amount={amount} />
      </>
    );
  } else return <></>;
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
