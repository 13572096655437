//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, TextField, InputAdornment, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  cancellationFee: `config.cancellation_fee`,
  delayPrice: `config.delay_price_per_minute`,
  fuelMultiplier: `config.fuel_multiplier`,
};

// DEFAULTS //
const getDefaultCancellationFee = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.cancellationFee) || getPropValue(inheritedRef, option?.cancellationFee);
};
const getDefaultDelayPrice = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.delayPrice) || getPropValue(inheritedRef, option?.delayPrice);
};
const getDefaultFuelMultiplier = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.fuelMultiplier) || getPropValue(inheritedRef, option?.fuelMultiplier);
};

//////////////////////// COMPONENT ////////////////////////

export default function Rates({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [cancellationFee, setCancellationFee] = React.useState(getDefaultCancellationFee(overrideRef, inheritedRef));
  const [delayPrice, setDelayPrice] = React.useState(getDefaultDelayPrice(overrideRef, inheritedRef));
  const [fuelMultiplier, setFuelMultiplier] = React.useState(getDefaultFuelMultiplier(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setCancellationFee(getDefaultCancellationFee(null, inheritedRef));
      setDelayPrice(getDefaultDelayPrice(null, inheritedRef));
      setFuelMultiplier(getDefaultFuelMultiplier(null, inheritedRef));
    }
  }, [isReset]);

  // Handle cancellation fee change
  const handleCancellationFeeChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { cancellation_fee: Number(value) } };
    setCancellationFee(value);
    updateMutableRef(changes);
  };

  // Handle delay price change
  const handleDelayPriceChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { delay_price_per_minute: Number(value) } };
    setDelayPrice(value);
    updateMutableRef(changes);
  };

  // Handle fuel multiplier change
  const handleFuelMultiplierChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { fuel_multiplier: Number(value) } };
    setFuelMultiplier(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Rates`}
        tip={`The rates module allows us to adjust the price rates to a client based on their contract. PLEASE be careful and triple check your inputs before submitting changes to any of these options as they directly affect client pricing.`}
      />

      <SettingsOption
        contained
        title={`Cancellation Fee`}
        description={`Amount to charge the client for cancelling a move that has already been started.`}
        value={cancellationFee}
        checkReset={() => checkMutableRefField(option?.cancellationFee)}
        onReset={() =>
          deleteMutableRefField(option?.cancellationFee, () =>
            setCancellationFee(getPropValue(inheritedRef, option?.cancellationFee))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Cancellation Fee'
          placeholder='Enter a cancellation fee...'
          size='small'
          variant='outlined'
          value={cancellationFee}
          onChange={handleCancellationFeeChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  block
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '1',
            min: '0',
            max: '1000',
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Delay Price Per Minute`}
        description={`Amount to charge the client per each minute our driver is delayed. This only applies when we choose to specifically charge the client for the delay. Min: 0.00, Max: 1.00.`}
        value={delayPrice}
        checkReset={() => checkMutableRefField(option?.delayPrice)}
        onReset={() =>
          deleteMutableRefField(option?.delayPrice, () => setDelayPrice(getPropValue(inheritedRef, option?.delayPrice)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Delay Price'
          placeholder='Enter a delay price...'
          size='small'
          variant='outlined'
          value={delayPrice}
          onChange={handleDelayPriceChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  more_time
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '0.01',
            min: '0',
            max: '1',
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Fuel Multiplier`}
        description={`The multiplier used to calculate the fuel surcharge. This is a multiplier of the base fare. Min: 0.0, Max: 3.0. NOTE: This does not affect clients with flat rates.`}
        value={fuelMultiplier}
        checkReset={() => checkMutableRefField(option?.fuelMultiplier)}
        onReset={() =>
          deleteMutableRefField(option?.fuelMultiplier, () =>
            setFuelMultiplier(getPropValue(inheritedRef, option?.fuelMultiplier))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Fuel Multiplier'
          placeholder='Enter a fuel multiplier...'
          size='small'
          variant='outlined'
          value={fuelMultiplier}
          onChange={handleFuelMultiplierChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  local_gas_station
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '0.1',
            min: '0',
            max: '3',
          }}
        />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
}));
