//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import Script from 'react-load-script';

import { makeStyles, Typography, TextField, Tooltip, InputAdornment, Icon } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

//////////////////////// COMPONENT ////////////////////////

export default function GoogleLocationBuilder({
  customerId,
  required,
  fullWidth,
  label,
  placeholder,
  variant,
  size,
  defaultLocation,
  onChange,
  validationError,
}) {
  const cls = useStyles();

  const [google, setGoogle] = React.useState(null);
  const [googleLocation, setGoogleLocation] = React.useState(defaultLocation);
  const [suggestions, setSuggestions] = React.useState([]);
  const [hideErrors, setHideErrors] = React.useState(false);

  React.useEffect(() => {
    if (defaultLocation) {
      setGoogleLocation(defaultLocation);
      if (onChange) onChange(defaultLocation);
    }
  }, [defaultLocation]);

  const handleGoogleScript = () => {
    setGoogle(new window.google.maps.places.PlacesService(document.createElement('div')));
  };

  const handleGoogleSearch = input => {
    google?.textSearch({ query: input }, res => {
      if (res) {
        setSuggestions(
          res.map(s => ({
            address: s?.formatted_address,
            customer_id: customerId || null,
            latitude: s?.geometry?.location?.lat(),
            longitude: s?.geometry?.location?.lng(),
            name: s?.name,
            place_id: s?.place_id,
          }))
        );
      }
    });
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === `input` && value) {
      handleGoogleLocationChange(null);
      handleGoogleSearch(value);
    } else {
      handleGoogleLocationChange(null);
      setSuggestions([]);
    }
  };

  const handleGoogleLocationChange = newLoc => {
    if (newLoc) {
      setGoogleLocation(newLoc);
      if (onChange) onChange(newLoc);
    } else {
      setGoogleLocation(null);
      if (onChange) onChange(null);
    }
  };

  const handleFocus = () => {
    setHideErrors(true);
  };
  const handleBlur = () => {
    setHideErrors(false);
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GPLCS}&libraries=places`}
        onLoad={handleGoogleScript}
      />

      <Autocomplete
        className={cls.autocomplete}
        includeInputInList
        options={suggestions}
        filterOptions={createFilterOptions({
          stringify: option => `${option?.name} ${option?.address} ${option?.input_address}`,
        })}
        getOptionLabel={option => option?.name || ``}
        getOptionSelected={(option, value) => option?.value === value?.value}
        noOptionsText={`No locations found`}
        value={googleLocation}
        onChange={(event, value) => handleGoogleLocationChange(value)}
        onInputChange={handleInputChange}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        renderInput={params => (
          <TextField
            {...params}
            required={required || false}
            fullWidth={fullWidth || true}
            multiline
            label={label || `Location`}
            placeholder={placeholder || `Search for a location...`}
            variant={variant || `outlined`}
            size={size || `small`}
            error={validationError && !hideErrors}
            helperText={validationError && !hideErrors ? validationError : googleLocation?.address}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    location_on
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(option, i) => (
          <React.Fragment key={`google-option-${i}`}>
            <Tooltip placement='top' title='Google-Suggested Location'>
              <div className={cls.optionIcon}>
                <FontAwesomeIcon className={cls.googleIcon} icon={faGoogle} title='Google-Suggested Location' />
              </div>
            </Tooltip>

            <div className={cls.option}>
              <Typography className={cls.optionName}>{option?.name}</Typography>
              <Typography className={cls.optionAddress}>{option?.address}</Typography>
            </div>
          </React.Fragment>
        )}
      />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  autocomplete: {
    width: '100%',
  },
  googleIcon: {
    color: theme.palette.text.secondary,
  },
  option: {
    display: 'block',
  },
  optionName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  optionAddress: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
}));
