//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';

const toPlusCase = str => {
  if (str) return str.split(` `).join(`+`);
  return str;
};

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideLocation({ location = {}, title = `Location` }) {
  const cls = useStyles();

  return (
    <a
      href={`https://www.google.com/maps/place/${toPlusCase(location.address)}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Tooltip title={`Click to view location in Google Maps.`}>
        <div className={cls.paper}>
          <Typography className={cls.headTxt}>{title}</Typography>
          <Typography className={cls.nameTxt}>{location.name}</Typography>
          <Typography className={cls.addressTxt}>{location.address} Location</Typography>
          {location.latitude && location.longitude ? (
            <Typography className={cls.latLonTxt}>
              {location.latitude}, {location.longitude}
            </Typography>
          ) : null}
        </div>
      </Tooltip>
    </a>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    padding: theme.spacing(1, 2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.main,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  headTxt: {
    lineHeight: 1.5,
    fontSize: 16,
    fontWeight: 600,
  },
  nameTxt: {
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 500,
  },
  addressTxt: {
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  latLonTxt: {
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.disabled,
  },
}));
