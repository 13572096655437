//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import MovesByCustomerFilter from './MovesByCustomerFilter';
import MovesByCustomerTable from './MovesByCustomerTable';

//////////////////////// COMPONENT ////////////////////////

export default function MovesByCustomer({ customer }) {
  const cls = useStyles();

  // Component state
  const [statusArray, setStatusArray] = React.useState([]);
  const [search, setSearch] = React.useState(``);

  // Control status filter
  const handleStatusArrayChange = (newStatusArray = []) => {
    if (newStatusArray) setStatusArray(newStatusArray);
  };

  // Query
  const { loading, error, data, refetch } = useQuery(GET_MOVES_BY_CUSTOMER, {
    variables: {
      customerId: customer?.id,
      start: dayjs().startOf(`day`).subtract(30, `day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
    fetchPolicy: `cache-and-network`,
  });

  // Handle clear filters
  const handleClearFilters = () => {
    setStatusArray([]);
    setSearch(``);
  };

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Moves'>
          <MovesByCustomerFilter />
        </Toolbar>

        <DefaultLoadingFallback message='FETCHING MOVES' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching moves:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Moves' onClear={handleClearFilters} refetch={handleRefetch}>
          <MovesByCustomerFilter
            statusArray={statusArray}
            onStatusArrayChange={handleStatusArrayChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING MOVES' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data?.moves?.length) {
    return (
      <div className={cls.root}>
        <Toolbar zIndex={9} fullscreen title='Moves' onClear={handleClearFilters} refetch={handleRefetch}>
          <MovesByCustomerFilter
            statusArray={statusArray}
            onStatusArrayChange={handleStatusArrayChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultEmptyFallback message='NO MOVES FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const moves = data?.moves;

  return (
    <div className={cls.root}>
      <Toolbar zIndex={9} fullscreen title='Moves' onClear={handleClearFilters} refetch={handleRefetch}>
        <MovesByCustomerFilter
          statusArray={statusArray}
          onStatusArrayChange={handleStatusArrayChange}
          search={search}
          onSearchChange={setSearch}
        />
      </Toolbar>

      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING MOVES' />}>
        <MovesByCustomerTable moves={moves} statusArray={statusArray} search={search} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  toolbar: {
    padding: theme.spacing(2),
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_MOVES_BY_CUSTOMER = gql`
  query admin_getMovesByCustomer($customerId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    moves(
      where: {
        customer_id: { _eq: $customerId }
        move_type: { _eq: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
      }
      order_by: { pickup_time: desc }
    ) {
      id
      cancel_status
      pickup_time
      status
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      accountsReceivable {
        id
        due_amount
      }
      lane {
        id
        pickup {
          id
          name
        }
        delivery {
          id
          name
        }
      }
    }
  }
`;
