export function useVehiclePhotos() {
  /** Sort images by a specified order */
  const sortImages = images => {
    if (images && images.length) {
      const imageOrder = [
        `driver-front`,
        `driver-front-wheel`,
        `driver-rear`,
        `driver-rear-wheel`,
        `passenger-front`,
        `passenger-front-wheel`,
        `passenger-rear`,
        `passenger-rear-wheel`,
        `instrument-cluster`,
        `windshield`,
        `interior-front`,
        `interior-rear`,
        `roof`,
        `interior-trunk`,
        `interior-vin`,
        `vehicle-vin`, // This is the same as interior-vin, but for older moves
        `vehicle-weight-badge`,
        `fuel-pump`,
      ];

      images = images.map((image, i) => {
        let num = 100;
        if (image.step_id === `damages`) num = 500;
        if (image.step_id === `signature`) num = 1000;
        if (imageOrder.includes(image.step_id)) num = imageOrder.indexOf(image.step_id);
        return { ...image, order: num };
      });

      return images.sort((a, b) => {
        const aNum = a.order;
        const bNum = b.order;
        if (aNum > bNum) return 1;
        if (aNum < bNum) return -1;
        return 0;
      });
    }
    return [];
  };

  /** Convert a step id string to something thats human readable */
  const getHumanReadableLabel = str => {
    str = str.replace(/[.\s_-]/g, ` `).split(' ');

    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(' ');
  };

  /** Get the description of a photo */
  const getPhotoDescription = ({ move = {}, type = `pickup`, signer = null, photo = null }) => {
    try {
      if (photo) {
        if (photo.step_id === `signature`) return `Signer Name: ${signer}`;

        if (photo.step_id === `damages`) {
          const damageNoteProp = photo.name.replace(`damages-`, `damages-note-`);
          let damageNote = ``;
          if (type === `pickup` && move && move.pickup_workflow_data) {
            damageNote = move.pickup_workflow_data[damageNoteProp];
          } else if (type === 'delivery' && move && move.delivery_workflow_data) {
            damageNote = move.delivery_workflow_data[damageNoteProp];
          }
          return damageNote ? `Damage: ${damageNote}` : `Damage`;
        }
      }
    } catch (err) {
      console.log('Error getting photo description', err.message);
    }
    return null;
  };

  /** Get the carousel images array */
  const getCarouselImagesArray = ({ move = {}, type = `pickup`, signer = null }) => {
    let vehiclePhotosArray = [];
    if (type === `pickup`) vehiclePhotosArray = move?.pickup_photos;
    if (type === `delivery`) vehiclePhotosArray = move?.delivery_photos;
    if (type === `fuel`) vehiclePhotosArray = move?.fuel_photos;
    const sortedPhotosArray = sortImages(vehiclePhotosArray);

    let carouselImagesArray = [];

    for (const index in sortedPhotosArray) {
      if (Object.hasOwnProperty.call(sortedPhotosArray, index)) {
        const photo = sortedPhotosArray[index];
        if (!photo) continue;
        if (!photo.step_id) continue;

        let label = getHumanReadableLabel(photo.step_id === `damages` ? photo.name : photo.step_id);
        let description = getPhotoDescription({ move, type, signer, photo });

        let carouselPhotoObj = {
          id: photo.step_id,
          label,
          original: photo.url,
          originalTitle: label,
          thumbnail: photo.url,
          thumbnailTitle: label,
          thumbnailLabel: label,
          description: description || label,
          isSignature: photo.step_id === 'signature',
          index,
        };

        carouselImagesArray.push(carouselPhotoObj);
      }
    }

    return carouselImagesArray;
  };

  return { getCarouselImagesArray };
}
