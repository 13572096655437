//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTimeline } from '../providers/TimelineProvider';

import { TimelineAreaRow, TimelineAreaMarker } from '../index';

// import { SimpleLogger } from '../../../utils/SimpleLogger';
// const { log } = new SimpleLogger({ prefix: 'TimelineAreaBody', enabled: false });

//////////////////////// COMPONENT ////////////////////////

export default function TimelineAreaBody(props) {
  const cls = useStyles();

  const { enrichedPlans } = useTimeline();

  return (
    <div className={cls.areaBody}>
      {enrichedPlans.map((enrichedPlan, index) => {
        const { enrichedMoves = [] } = enrichedPlan;
        return (
          <TimelineAreaRow
            key={`timeline-row-${index}`}
            enrichedPlan={enrichedPlan}
            enrichedMoves={enrichedMoves}
            index={index}
          />
        );
      })}
      <TimelineAreaMarker hasText={false} />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  areaBody: {
    zIndex: 101,
    position: 'relative',
    display: 'block',
    flex: 1,
    width: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
