//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, useTheme, Grid, Tooltip, Typography, Chip, Icon, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';
import NatureTwoToneIcon from '@material-ui/icons/NatureTwoTone';
import DriverAvatar from './DriverAvatar';
import { useModal } from '../providers/ModalProvider';
import { useScheduler } from '../providers/SchedulerProvider';
import { useTimeline } from '../providers/TimelineProvider';
import { useTools } from '../../../hooks/useTools';
import { checkPlanNeedsSync } from '../utils/helpers';

// import { SimpleLogger } from '../../../utils/SimpleLogger';
// const { log } = new SimpleLogger({ prefix: 'TimelineIndexRow', enabled: true });

//////////////////////// COMPONENT ////////////////////////

export default function TimelineIndexRow({ index, enrichedPlan, driver, driverStatus, moveStatus }) {
  const theme = useTheme();
  const cls = useStyles();

  const { plan, enrichedMoves = [] } = enrichedPlan;

  const { capFirst, getLocationByIdFromList, getDriverTagsFromAttributes } = useTools();
  const { handleParkedCarModalOpen } = useModal();
  const { selectPlan, showScheduler, findEnrichedPlanLocations } = useScheduler();
  const { handleSyncOnePlan } = useTimeline();

  const planLocations = findEnrichedPlanLocations(enrichedPlan);
  const parkedLocation = getLocationByIdFromList(plan.parked_location, planLocations) || {};

  const [syncButtonDisabled, setSyncButtonDisabled] = React.useState(false);

  const movesCount = enrichedMoves.filter(
    m =>
      m.move.move_type === 'drive' &&
      (!m.move.cancel_status ||
        m.move.cancel_status === `started` ||
        m.move.cancel_status === `delivered` ||
        m.move.cancel_status === `rescheduled`)
  ).length;
  const completedMovesCount = enrichedMoves.filter(
    m =>
      m.move.move_type === 'drive' &&
      (m.move.status === `delivery successful` ||
        m.move.cancel_status === `started` ||
        m.move.cancel_status === `delivered`)
  ).length;

  let attributes = getDriverTagsFromAttributes(driver?.config?.attributes);

  const handleManagePlan = () => {
    selectPlan(plan);
    showScheduler();
  };
  const handleParkedLocation = () => {
    handleParkedCarModalOpen({
      planId: plan.id,
      driverName: driver?.display_name,
      locationId: plan.parked_location,
      validLocations: planLocations,
    });
  };
  const handleSyncPlan = async () => {
    disableSyncButtonTimeout();
    await handleSyncOnePlan(enrichedPlan.plan);
  };
  const disableSyncButtonTimeout = () => {
    setSyncButtonDisabled(true);
    setTimeout(() => {
      setSyncButtonDisabled(false);
    }, 15000);
  };

  const planRowActions = [
    {
      label: `Manage Plan`,
      handler: () => handleManagePlan(),
    },
    {
      label: `Sync Plan`,
      handler: async () => await handleSyncPlan(),
      disabled: syncButtonDisabled || !checkPlanNeedsSync(plan),
    },
    {
      label: `Set Parked Location`,
      handler: () => handleParkedLocation(),
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`plan-context-menu-${index}`}
        enrichedPlan={enrichedPlan}
        holdToDisplay={1000}
        collect={p => p}
        disableIfShiftIsPressed={true}
      >
        <Tooltip
          title={
            <div className={cls.ttBox}>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Plan ID:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {plan.id || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Plan Date:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {dayjs(plan.plan_date).format(`MM/DD/YYYY`) || `-`}
                </Grid>
              </Grid>

              <hr className={cls.ttDivider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver ID:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.id || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Name:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.display_name || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Phone:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.phone || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Email:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.email || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Region:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.region_name || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Status:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {capFirst(driverStatus) || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver App Version:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.driver_app_version || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Driver Attributes:
                </Grid>
                {attributes.length > 0 ? (
                  <Grid item xs={12}>
                    {attributes.map((attribute, i) => (
                      <span key={`driver-attribute-${i}`} className={cls.toolChipDiv}>
                        <Chip
                          classes={{ root: cls.toolChip, label: cls.toolChipLabel }}
                          label={attribute}
                          size='small'
                          variant='outlined'
                        />
                        {i < attributes.length - 1 ? <Spacer size='xxxs' /> : null}
                      </span>
                    ))}
                  </Grid>
                ) : null}
              </Grid>

              <hr className={cls.ttDivider} />

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Move Status:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {moveStatus || `-`}
                </Grid>
              </Grid>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Moves Completed:
                </Grid>
                <Grid item className={cls.ttVal}>{`${completedMovesCount || 0}/${movesCount || 0}`}</Grid>
              </Grid>

              <hr className={cls.ttDivider} />

              <Grid container justifyContent='space-between'>
                <Grid item className={cls.ttKey}>
                  Parked Location:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {parkedLocation.name || `Unknown`}
                </Grid>
              </Grid>
            </div>
          }
          placement='right'
          width='fit-content'
          data-testid='driver-plan'
        >
          <div className={cls.indexRow}>
            <Grid container spacing={2} alignItems='center' onClick={handleManagePlan} style={{ cursor: `pointer` }}>
              <Grid item>
                <DriverAvatar driver={driver} driverStatus={driverStatus} clickable disableTooltip />
              </Grid>

              <Grid item xs>
                <Typography className={cls.nameTxt}>{driver?.display_name || `HopDriver`}</Typography>

                <Typography className={cls.regionTxt}>{driver?.region_name || `No Region`}</Typography>

                <Chip
                  classes={{ root: cls.defaultChip, label: cls.chipLabel }}
                  label={`${completedMovesCount || 0} / ${movesCount || 0}`}
                  size='small'
                />

                {attributes.includes(`concierge`) || attributes.includes(`concierge trained`) ? (
                  <Tooltip title='Concierge Trained'>
                    <Icon className={cls.tagIcon}>assignment_ind</Icon>
                  </Tooltip>
                ) : null}

                {attributes.includes(`manual`) ? (
                  <Tooltip title='Manual Trained'>
                    <NatureTwoToneIcon className={cls.tagIcon} />
                  </Tooltip>
                ) : null}

                {attributes.includes(`railyard`) ? (
                  <Tooltip title='Railyard Trained'>
                    <Icon className={cls.tagIcon}>calendar_view_week</Icon>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>

            <div className={cls.actions}>
              <div
                className={cls.actionsFade}
                style={{
                  background:
                    index % 2
                      ? `linear-gradient(90deg, ${theme.palette.background.light}00, ${theme.palette.background.light})`
                      : `linear-gradient(90deg, ${theme.palette.background.paper}00, ${theme.palette.background.paper})`,
                }}
              />
              <div
                className={cls.actionsBox}
                style={{ background: index % 2 ? theme.palette.background.light : theme.palette.background.paper }}
              >
                {checkPlanNeedsSync(plan) ? (
                  <Chip
                    classes={{ root: cls.chip, label: cls.chipLabel }}
                    clickable={false}
                    variant='outlined'
                    size='small'
                    label='Sync'
                    data-testid={`sync-to-app-${driver?.id}`}
                    onClick={handleSyncPlan}
                    disabled={syncButtonDisabled}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Tooltip>
      </ContextMenuTrigger>

      <ContextMenu id={`plan-context-menu-${index}`}>
        {planRowActions.map((action, i) => (
          <MenuItem key={`plan-row-actions-${i}`} disabled={action.disabled} onClick={() => action.handler()}>
            {action.label}
          </MenuItem>
        ))}
      </ContextMenu>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  indexRow: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  nameTxt: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
  },
  regionTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  defaultChip: {
    height: 18,
    marginRight: theme.spacing(0.5),
  },
  chip: {
    height: 18,
    borderColor: theme.palette.error.main,
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
    background: 'transparent',
    '&:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      background: theme.palette.error.main,
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  chipLabel: {
    fontSize: 12,
    fontWeight: 500,
  },
  toolChipDiv: {
    width: 'fit-content',
  },
  toolChip: {
    height: 16,
    borderColor: theme.palette.text.contrast,
    color: theme.palette.text.contrast,
  },
  toolChipLabel: {
    textTransform: 'capitalize',
    fontSize: 10,
    fontWeight: 500,
  },
  tagIcon: {
    verticalAlign: 'top',
    fontSize: 18,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ttBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 192,
  },
  ttKey: {
    color: theme.palette.text.contrastFade,
  },
  ttVal: {
    color: theme.palette.text.contrast,
  },
  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
  actions: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
  actionsBox: {
    position: 'relative',
    paddingLeft: 2,
    pointerEvents: 'auto',
  },
  actionsFade: {
    position: 'relative',
    width: theme.spacing(4),
    pointerEvents: 'none',
  },
  iconBtn: {
    padding: theme.spacing(0.25),
  },
  iconPark: {
    fontSize: 20,
  },
  iconSync: {
    fontSize: 20,
    color: theme.palette.error.main,
  },
}));
