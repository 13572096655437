import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

export default function BatchJobDetailToolbar({ batchJob, onReset, resetDisabled }) {
  const cls = useStyles();

  return (
    <>
      {batchJob ? (
        <Grid container spacing={2}>
          <Grid item>
            <Button size='small' variant='outlined' color='secondary' onClick={onReset} disabled={resetDisabled}>
              Rerun Job
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  // We can keep minimal styling if needed
}));
