//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, IconButton, Icon, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//////////////////////// COMPONENT ////////////////////////

export default function PlansToolbarDate({ date, onDateChange }) {
  const cls = useStyles();

  const handleDateChange = newDate => {
    if (onDateChange) onDateChange(newDate);
  };

  const handleThisDate = name => {
    let newDate = dayjs(date).format();

    if (name === `prev`) newDate = dayjs(date).subtract(1, `day`).format();
    else newDate = dayjs(date).add(1, `day`).format();

    handleDateChange(newDate);
  };

  const DatePickerWithRef = React.forwardRef((props, ref) => (
    <DatePicker
      ref={ref}
      className={cls.date}
      inputProps={{
        className: cls.input,
      }}
      autoOk
      format='MM/dd/yyyy'
      orientation='portrait'
      size='small'
      variant='inline'
      inputVariant='outlined'
      value={date}
      onChange={handleDateChange}
    />
  ));

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={1} alignItems='center' wrap='nowrap'>
        <Grid item>
          <Tooltip title='Previous Date'>
            <IconButton className={cls.iconBtn} onClick={() => handleThisDate(`prev`)}>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title='Date Selection'>
            <DatePickerWithRef/>
          </Tooltip>
        </Grid>

        <Grid item>
          <Tooltip title='Next Date'>
            <IconButton className={cls.iconBtn} onClick={() => handleThisDate(`next`)}>
              <Icon>arrow_forward</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(0.5),
  },
  date: {
    width: 128,
  },
  input: {
    height: `1em`,
    padding: theme.spacing(1, 1),
  },
}));
