import React from 'react';
import { useTheme, makeStyles, Grid, IconButton, Icon, Tooltip } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function UserToolbar({ addUserMode = false, user = {} }) {
  const theme = useTheme();
  const cls = useStyles();
  const { goToAdminUserDetails } = useTools();

  const { editMode, setEditMode, isSaving, setIsSaving, handleSave, handleCreateUser } = useUserDetails();

  const handleSubmit = async () => {
    const res = await handleCreateUser('admin');
    if (res?.success && res?.userId) {
      goToAdminUserDetails(res.userId, true )
    } else {
      setIsSaving(false);
    }
};

  return (
    <Grid container alignItems='center'>
      <Grid item xs />

      {editMode ? (
        <Grid item>
          <Tooltip placement='top' title={`Click to lock & save your changes`}>
            <Button
              loading={isSaving}
              disabled={isSaving}
              className={cls.bigBtn}
              color='primary'
              onClick={() => handleSave('admin')}
            >
              Save Changes
            </Button>
          </Tooltip>
        </Grid>
      ) : null}

      {addUserMode ? (
        <Tooltip placement='top' title={`Click to Add User`}>
          <Button
            loading={isSaving}
            disabled={isSaving}
            className={cls.bigBtn}
            color='primary'
            onClick={() => handleSubmit()}
          >
            Add Hopdrive User
          </Button>
        </Tooltip>
      ) : (
        <Grid item>
          <Tooltip
            placement='top'
            title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the driver`}
          >
            <IconButton
              style={{
                color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              className={cls.iconBtn}
              onClick={() => setEditMode(!editMode)}
            >
              <Icon>{editMode ? `lock_open` : `lock`}</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  bigBtn: {
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
}));
