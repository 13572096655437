//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TablePagination,
  TextField,
  InputLabel,
  MenuItem,
  InputAdornment,
  Icon,
  IconButton,
  Tooltip,
  useTheme
} from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import { NotificationsTableHead, NotificationsTableBody } from './index';
import { useTools } from '../../hooks/useTools';
import Toolbar from '../../components/Toolbar';
import { Button } from '@hopdrive/storybook';
import { getUserEmail } from '../../utils/authHelper'
const defaultOrder = `desc`;
const defaultOrderBy = `createdat`;

//////////////////////// COMPONENT ////////////////////////

function NotificationsTable(props) {
  const { notifications } = props;
  
  const cls = useStyles();
  const theme = useTheme();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState('');
  const [searchCriteria, setSearchCriteria] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [filteredNotifications, setFilteredNotifications] = React.useState([]);
  const [filterByUser, setFilterByUser] = React.useState(false)
  const [userFilterField, setUserFilterField] = React.useState('')
  const [userEmail, setUserEmail] = React.useState(null)

  const { goToRoute } = useTools();

  React.useEffect(() => {
    let email = getUserEmail()
    setUserEmail(email)
}, [])

  React.useEffect(() => {
    let workingNotificationsArray = [];
    if (filterByUser === true) {
      workingNotificationsArray = applyUserFilter()
    } else if (filter && !searchCriteria && !search) {
      workingNotificationsArray = applyFilters()
    } else if (search && searchCriteria && !filter)  {
      workingNotificationsArray = applySearch()
    } else if (filter && search && searchCriteria) {
      workingNotificationsArray = applySearchAndFilters()
    } else workingNotificationsArray = notifications

    workingNotificationsArray = workingNotificationsArray.map(notification => {
      return {
        id: notification.id,
        type: notification.type || ` – `,
        title: notification.title || ` – `,
        body: notification.body || ` – `,
        assignedto: notification.assignedto || ` – `,
        assignedat: notification.assignedat || ` – `,
        assignedby: notification.assignedby || ` – `,
        resolvedat: notification.resolvedat || ` – `,
        resolvedby: notification.resolvedby || ` – `,
        createdat: notification.createdat || ` – `,
        createdby: notification.createdby || ` – `,
        notes: notification.notes || ` – `,
        duedate: notification.duedate || ` – `,
        notification: notification,
      };
    });


    setFilteredNotifications(workingNotificationsArray);

  }, [filter, search, searchCriteria, filterByUser, userFilterField])


   //---------------Filtering by current user---------------//

   const applyUserFilter = () => {
    switch (userFilterField) {
      case 'inform':
        return notifications.filter(note => getType(note) === 'inform');
      case 'unassigned':
        return notifications.filter(note => getAssigned(note) === null && getType(note) === 'action');
      case 'assigned':
        return notifications.filter(note => getAssignedToSearch(note) === userEmail && getType(note) === 'action');
      case 'unresolved':
        return notifications.filter(note => getResolvedBySearch(note) === 'Unknown' && getAssignedToSearch(note) === userEmail);
      case 'resolved':
        return notifications.filter(note => getResolvedBySearch(note) === userEmail && getAssignedToSearch(note) === userEmail && getType(note) === 'action');
      case 'created':
        return notifications.filter(note => getCreatedBySearch(note) === userEmail);
      default:
        return notifications.filter(note =>
          [getCreatedBySearch(note), getResolvedBySearch(note), getAssignedToSearch(note), getAssignedBySearch(note), getAssigned(note)].includes(userEmail)
        );
    }
  };

  //---------------Filtering by category---------------//

  const applyFilters = () => {
    switch (filter) {
      case 'inform':
        return notifications.filter(note => getType(note) === 'inform');
      case 'action':
        return notifications.filter(note => getType(note) === 'action');
      case 'unassigned':
        return notifications.filter(note => getAssigned(note) === null && getType(note) === 'action');
      case 'assigned':
        return notifications.filter(note => getAssigned(note) !== null && getType(note) === 'action');
      case 'unresolved':
        return notifications.filter(note => getResolved(note) === null && getType(note) === 'action');
      case 'resolved':
        return notifications.filter(note => getResolved(note) !== null && getType(note) === 'action');
      default:
        return notifications;
    }
  };

  const getResolved = note => {
    if (note.type === 'action' && note.resolvedby === null) {
      return note.resolvedby;
    } else return 'Unknown resolution status';
  };

  const getAssigned = note => {
    if (note.type === 'action' && note.assignedto === null) {
      return note.assignedto;
    } else return 'Unknown assignment status';
  };

  const getType = note => {
    if (note.type) {
      return note.type;
    } else return 'Unknown Type';
  };

  //---------------Searching by text---------------//

  const applySearch = () => {
    switch (searchCriteria) {
      case 'title':
        return notifications.filter(note => getTitleSearch(note).includes(search.toLowerCase()));
      case 'created by':
        return notifications.filter(note => getCreatedBySearch(note).includes(search.toLowerCase()));
      case 'body':
        return notifications.filter(note => getBodySearch(note).includes(search.toLowerCase()));
      case 'assigned to':
        return notifications.filter(note => getAssignedToSearch(note).includes(search.toLowerCase()));
      case 'assigned by':
        return notifications.filter(note => getAssignedBySearch(note).includes(search.toLowerCase()));
      case 'resolved by':
        return notifications.filter(note => getResolvedBySearch(note).includes(search.toLowerCase()));
      default:
        if (!filter && (!searchCriteria || search.length < 1)) {
          return notifications;
        } else if (searchCriteria === 'title' && filter === 'inform') {
          return filteredNotifications.filter(note => getTitleSearch(note).includes(search.toLowerCase()) && getType(note) === filter);
        } else if (searchCriteria === 'title' && filter === 'action') {
          return notifications.filter(note => getTitleSearch(note).includes(search.toLowerCase()) && getType(note) === filter);
        } else {
          return [];
        }
    }
  };

  const getTitleSearch = note => {
    if (note.title) {
      return note.title.toLowerCase();
    } else return 'Unknown title';
  };

  const getBodySearch = note => {
    if (note.body) {
      return note.body.toLowerCase();
    } else return 'Unknown body';
  };

  const getAssignedToSearch = note => {
    if (note.assignedto) {
      return note.assignedto.toLowerCase();
    } else return 'Unknown assignee';
  };

  const getAssignedBySearch = note => {
    if (note.assignedby) {
      return note.assignedby.toLowerCase();
    } else return 'Unknown assignee';
  };

  const getResolvedBySearch = note => {
    if (note.resolvedby) {
      return note.resolvedby.toLowerCase();
    } else return 'Unknown';
  };

  const getCreatedBySearch = note => {
    if (note.createdby) {
      return note.createdby.toLowerCase();
    } else return 'Unknown creator';
  };

  //---------------Filtering and Searching at Once---------------//

  const applySearchAndFilters = () => {
    return notifications.filter(note => {
      const title = getTitleSearch(note).toLowerCase();
      const body = getBodySearch(note).toLowerCase();
      const createdBy = getCreatedBySearch(note).toLowerCase();
      const assignedTo = getAssignedToSearch(note).toLowerCase();
      const assignedBy = getAssignedBySearch(note).toLowerCase();
      const resolvedBy = getResolvedBySearch(note).toLowerCase();
      const resolved = getResolved(note);
      const type = getType(note);
  
      const searchLower = search.toLowerCase();
  
      // Check if any of the search criteria match
      const matchesSearchCriteria =
        (searchCriteria === 'title' && title.includes(searchLower)) ||
        (searchCriteria === 'body' && body.includes(searchLower)) ||
        (searchCriteria === 'created by' && createdBy.includes(searchLower)) ||
        (searchCriteria === 'assigned to' && assignedTo.includes(searchLower)) ||
        (searchCriteria === 'assigned by' && assignedBy.includes(searchLower)) ||
        (searchCriteria === 'resolved by' && resolvedBy.includes(searchLower));
  
      // Check if any of the filter criteria match
      const matchesFilterCriteria =
        (!filter) ||
        (filter === 'inform' && type === 'inform') ||
        (filter === 'action' && type === 'action') ||
        (filter === 'unassigned' && assignedTo === null && type === 'action') ||
        (filter === 'assigned' && assignedTo !== null && type === 'action') ||
        (filter === 'resolved' && resolved !== null && type === 'action') ||
        (filter === 'unresolved' && resolved === null && type === 'action');
  
      // Return true only if both search and filter criteria match
      return matchesSearchCriteria && matchesFilterCriteria;
    });
  };
        

  //---------------The Table Itself---------------//

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  const headers = [
    { id: `read`, align: `left`, label: `Read` },
    { id: `type`, align: `left`, label: `Type` },
    { id: `duedate`, align: `left`, label: `Due`},
    { id: `title`, align: `left`, label: `Title` },
    { id: `body`, align: `left`, label: `Body` },
    { id: `assignedat`, align: `left`, label: `Assigned` },
    { id: `resolvedat`, align: `left`, label: `Resolved` },
    { id: `createdat`, align: `left`, label: `Created` },
    { id: `id`, align: `left`, label: `ID` },
  ];

  return (
    <div className={cls.root}>
    
      {!filterByUser ? (

      <Toolbar fullscreen title='Notifications' style={{ marginBottom: '8px' }}>
        <TextField
          select
          style={{ minWidth: 200, maxWidth: 250, maxHeight: 40 }}
          label='Filter By Category'
          placeholder='Select a filter...'
          variant='outlined'
          value={filter}
          onChange={handleInputChange(setFilter)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top', maxHeight: '40px'}} position='start'>
                <Icon color='disabled' fontSize='small'>
                  filter_alt
                </Icon>
              </InputAdornment>
            ),
          }}
        >
          <InputLabel>Select Filter</InputLabel>
          <MenuItem value={null}>
            <em>Clear Filters</em>
          </MenuItem>
          <MenuItem value={'inform'}>Informational Notifications</MenuItem>
          <MenuItem value={'action'}>Action Items</MenuItem>
          <MenuItem value={'unassigned'}>Unassigned Action Items</MenuItem>
          <MenuItem value={'assigned'}>Assigned Action Items</MenuItem>
          <MenuItem value={'unresolved'}>Unresolved Action Items</MenuItem>
          <MenuItem value={'resolved'}>Resolved Action Items</MenuItem>
        </TextField>

        <TextField
          select
          style={{ minWidth: 250, maxWidth: 250, maxHeight: 30, paddingLeft: 8 }}
          label='Search By'
          placeholder='Select a field to search by'
          variant='outlined'
          value={searchCriteria}
          onChange={handleInputChange(setSearchCriteria)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top', maxHeight: '40px' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  list
                </Icon>
              </InputAdornment>
            ),
          }}
        >
          <InputLabel>Select Filter</InputLabel>
          <MenuItem value={null}>
            <em>Clear Search Criteria</em>
          </MenuItem>
          <MenuItem value={'title'}>Title</MenuItem>
          <MenuItem value={'body'}>Body</MenuItem>
          <MenuItem value={'created by'}>Created By</MenuItem>
          <MenuItem value={'assigned to'}>Assigned To</MenuItem>
          <MenuItem value={'assigned by'}>Assigned By</MenuItem>
          <MenuItem value={'resolved by'}>Resolved By</MenuItem>
        </TextField>

        {searchCriteria ? (
          <TextField
            style={{ minWidth: 200, minHeight: 50, paddingLeft: 8 }}
            placeholder='Type to search...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleInputChange(setSearch)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top', minHeight: '56px' }} position='start'>
                  <Icon color='disabled' style={{ cursor: 'default' }}>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        ) : null}

          <Button style={{ margin: '8px' }} color='primary' size='large' onClick={() => setFilterByUser(true)}>
            Your Notifications
          </Button>

        <Tooltip placement='top' title={`Click to add new notification`}>
                <IconButton
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                  className={cls.iconBtn}
                  onClick={() => goToRoute('/addnotification')}
                >
                  <AddAlertIcon />
                </IconButton>
            </Tooltip>
      </Toolbar>
      ) : (
        <Toolbar fullscreen title='Notifications' style={{ marginBottom: '8px' }}>
        <TextField
        select
        style={{ minWidth: 300, maxWidth: 250, maxHeight: 40 }}
        label='Filter Your Notifications By Category'
        placeholder='Select a filter...'
        variant='outlined'
        value={userFilterField}
        onChange={handleInputChange(setUserFilterField)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top', maxHeight: '40px'}} position='start'>
              <Icon color='disabled' fontSize='small'>
                filter_alt
              </Icon>
            </InputAdornment>
          ),
        }}
      >
        <InputLabel>Select Filter</InputLabel>
        <MenuItem value={null}>
          <em>Clear Filters</em>
        </MenuItem>
        <MenuItem value={'unassigned'}>Awaiting Assignment</MenuItem>
        <MenuItem value={'assigned'}>Your Assigned Items</MenuItem>
        <MenuItem value={'unresolved'}>Your Unresolved Items</MenuItem>
        <MenuItem value={'resolved'}>Your Resolved Items</MenuItem>
        <MenuItem value={'created'}>Created By You</MenuItem>
        <MenuItem value={'inform'}>Informational Notifications</MenuItem>
      </TextField>

            <Button style={{ margin: '8px' }} color='primary' size='large' onClick={() => setFilterByUser(false)}>
            All Notifications
          </Button>

          <Tooltip placement='top' title={`Click to add new notification`}>
                <IconButton
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                  className={cls.iconBtn}
                  onClick={() => goToRoute('/addnotification')}
                >
                  <AddAlertIcon />
                </IconButton>
            </Tooltip>

          </Toolbar>

      )}

      <div className={cls.paper}>
        <TableContainer>
          <Table size='small' aria-label='notifications-index-table'>
            <NotificationsTableHead
              headers={headers}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <NotificationsTableBody
              rows={filteredNotifications}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
        </TableContainer>

        <div className={cls.pagination}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={filteredNotifications.length}
            // count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  search: {
    minWidth: 128,
    maxWidth: 256,
    marginRight: theme.spacing(1),
  },
  searchTextField: {
    borderRadius: theme.shape.borderRadius,
    background: '#ffffff24',
    color: theme.palette.text.contrast,
    '&:hover': {
      background: '#ffffff36',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.contrast,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.contrast,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
    transition: '0.2s',
  },
  searchColor: {
    color: theme.palette.text.contrast,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NotificationsTable;
