import React from 'react';
import { makeStyles, Typography, TextField, MenuItem, InputAdornment, Icon, Grid } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

////////// COMPONENT //////////
export default function TemplateForm(props) {
  const cls = useStyles();

  const { template, onTemplateChange, config, customer } = props;


  const handleTemplateChange = event => {
    if (onTemplateChange) onTemplateChange(event.target.value);
  };

  if (config && customer) {
    // Set default templates
    const defaultPickupTemplate = customer.pickup_template_name || null;
    const defaultDeliveryTemplate = customer.delivery_template_name || null;

    // Return template selection if allowed_templates isnt empty
    if (config && config.allowed_templates && config.allowed_templates.length > 0) {
      // Show all allowed templates, including defaults
      let allowedTemplates = config.allowed_templates || [];
      if (defaultPickupTemplate) allowedTemplates.push(defaultPickupTemplate);
      if (defaultDeliveryTemplate) allowedTemplates.push(defaultDeliveryTemplate);

      // Remove duplicates
      allowedTemplates = [...new Set(allowedTemplates)];

      return (
        <Grid item xs>
          <Spacer size='xs' />

          <Typography className={cls.templateTxt}>
            {allowedTemplates.length} Templates Available - (Optional) Select a template to&nbsp;use:
          </Typography>

          <Spacer />

          <TextField
            fullWidth
            select
            label='Select Template'
            placeholder='Default'
            variant='outlined'
            size='small'
            value={template}
            onChange={handleTemplateChange}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      file_copy
                    </Icon>
                  </InputAdornment>
                </>
              ),
            }}
          >
            <MenuItem value={``}>
              <em>Default</em>
            </MenuItem>
            {allowedTemplates.map((at, i) => (
              <MenuItem key={`allowed-template-${i}`} value={at}>
                {at}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    }
  }

  return null;
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  templateTxt: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

