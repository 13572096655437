import React from 'react';
import { makeStyles, Grid, Typography, LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useTools } from '../../hooks/useTools';
import { useDriverPay } from './useDriverPay';

import DriverPayModalTable from './DriverPayModalTable';

const log = false;

//////////////////////// COMPONENT ////////////////////////

// input.driverPay - Driver pay array with objects that have id and amount to pay
// input.start - Start date for queried appayments
// input.end - End date for queried appayments
// input.refetch - Refetch the data after the payouts were initiated
// input.balance - Available balance to pay drivers with
// input.handleTopUp - Open top up modal to add funds

export default function DriverPayModal({ open, input, onClose, balance }) {
  const cls = useStyles();

  const { goToPayoutsIndex, formatUSD } = useTools();
  const { getTotalFromDriverPay, initiatePayouts } = useDriverPay();

  const [driverPay, setDriverPay] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const [isPaying, setIsPaying] = React.useState(false);
  const [wasInitiated, setWasInitiated] = React.useState(false);

  // Update driverPay array if the input changes
  React.useEffect(() => {
    if (input) {
      const localDriverPay = input.driverPay || [];
      const localTotalAmount = getTotalFromDriverPay(localDriverPay);

      log && console.log(`Driver Pay Input:`, localDriverPay);
      log && console.log(`Total Amount:`, localTotalAmount);

      if (localDriverPay) setDriverPay(localDriverPay);
      if (localTotalAmount) setTotalAmount(localTotalAmount);

      setIsPaying(false);
      setWasInitiated(false);
    }
  }, [input]);

  // Handle closing of the form
  const handlePayDrivers = async () => {
    const res = await initiatePayouts(driverPay, input.start, input.end, input.refetch);

    if (res) {
      // Set the status and driverpayout_id of each row
      const newDriverPay = driverPay.map(dp => {
        const response = res.find(r => r.driverId === dp.driver_id);
        return {
          ...dp,
          status: response.success ? `Successful` : `Failed`,
          notes: response.notes || null,
          driverpayout_id: response.driverpayoutId,
          transfer_token: response.transferToken,
        };
      });
      setDriverPay(newDriverPay);
      setWasInitiated(true);
    } else {
      // Set the status to failed for all rows if no response was returned
      const newDriverPay = driverPay.map(dp => ({ ...dp, status: `Failed`, notes: `Please contact an admin.` }));
      setDriverPay(newDriverPay);
    }

    setIsPaying(false);
  };

  // Handle closing of the form
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <Modal open={open}>
      {open && driverPay && driverPay.length ? (
        <>
          <ModalHeader onClose={onClose}>Verify Driver Payments</ModalHeader>

          <ModalContent disablePadding>
            <DriverPayModalTable driverPay={driverPay} />
            {isPaying ? <LinearProgress /> : <Spacer size='xxs' />}
          </ModalContent>

          <ModalFooter>
            <div className={cls.total}>
              <Typography className={cls.amountTxt}>{formatUSD(totalAmount)}</Typography>
              <Typography className={cls.balanceTxt}>&nbsp;/&nbsp;{formatUSD(balance)} Available Balance</Typography>
            </div>

            {!wasInitiated ? (
              <ModalAction
                loading={isPaying}
                disabled={isPaying || totalAmount > input.balance}
                color='primary'
                onClick={() => {
                  setIsPaying(true);
                  handlePayDrivers();
                }}
              >
                Confirm
              </ModalAction>
            ) : (
              <ModalAction color='secondary' onClick={() => goToPayoutsIndex()}>
                Go&nbsp;To&nbsp;Payout&nbsp;History
              </ModalAction>
            )}

            {wasInitiated && input && input.handleTopUp ? (
              <ModalAction
                color='tertiary'
                onClick={() => {
                  input.handleTopUp();
                  handleClose();
                }}
              >
                Top&nbsp;Up
              </ModalAction>
            ) : null}

            <ModalAction onClick={() => handleClose()}>Close</ModalAction>
          </ModalFooter>
        </>
      ) : null}
    </Modal>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  total: {
    marginRight: 'auto',
  },
  amountTxt: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
  },
  balanceTxt: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
}));
