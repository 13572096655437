// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Step from './Step';

// COMPONENT -------------------------------------------------- //

export default function StepGroup({ step = {}, move = {} }) {
  const cls = useStyles();

  const { id, label, description, steps } = step;

  const [isAppointmentMove, setIsAppointmentMove] = React.useState(false);

  React.useEffect(() => {
    if (move?.appointment?.id) {
      setIsAppointmentMove(true);
    }
  }, [move]);

  return (
    <>
      {isAppointmentMove && step?.id === 'cancel-group' ? (
        <div className={cls.label}>
          <div className={cls.title}>
            <Typography className={cls.titleTxt}>Cancel Move</Typography>
          </div>
          <Spacer size='xl' />
          <div className={cls.subtitle}>
            <Typography className={cls.subtitleTxt}>
              {move.status !== null && move.status !== 'dispatched'
                ? 'Since this move is a prepaid, direct-to-consumer concierge appointment, it cannot be canceled after it has begun.'
                : 'This move is a prepaid, direct-to-consumer concierge appointment. To cancel it, advise the customer to cancel the associated appointment in Portal. This will both cancel the move and initiate a refund to the consumer.'}
            </Typography>
          </div>
        </div>
      ) : isAppointmentMove && step?.id === 'reschedule-group' ? (
        <div className={cls.label}>
          <div className={cls.title}>
            <Typography className={cls.titleTxt}>Reschedule Move</Typography>
          </div>
          <Spacer size='xl' />
          <div className={cls.subtitle}>
            <Typography className={cls.subtitleTxt}>
              This move is a prepaid, direct-to-consumer concierge appointment, and it cannot be rescheduled. To move
              this vehicle at a different time, advise the customer to cancel the associated appointment in Portal and
              create a new appointment with the updated time.
            </Typography>
          </div>
        </div>
      ) : (
        <div className={cls.label}>
          <div className={cls.title}>{label ? <Typography className={cls.titleTxt}>{label}</Typography> : null}</div>

          {label && description ? <Spacer size='xs' /> : null}

          <div className={cls.subtitle}>
            {description ? <Typography className={cls.subtitleTxt}>{description}</Typography> : null}
          </div>

          {label || description ? <Spacer size='xl' /> : null}

          {steps && steps?.length
            ? steps?.map((step, i) => {
                return (
                  <React.Fragment key={`step-id-${step?.id}`}>
                    <Step step={step} />
                  </React.Fragment>
                );
              })
            : null}
        </div>
      )}
    </>
  );
}

// COMPONENT -------------------------------------------------- //
const useStyles = makeStyles(theme => ({
  title: {
    display: 'block',
  },
  titleTxt: {
    display: 'inline',
    fontSize: 21,
    fontWeight: 600,
  },

  subtitle: {
    display: 'block',
  },
  subtitleTxt: {
    display: 'inline',
    fontSize: 16,
    fontWeight: 400,
  },
}));
