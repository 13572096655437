//////////////////////// DEPENDENCIES ////////////////////////

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';

import { useTools } from '../../hooks/useTools';

const log = true;

//////////////////////// COMPONENT ////////////////////////

export function useMoves() {
  const { getFormattedVehicleFromMove, getFormattedCombinedStatusFromMove, getWorkflowData } = useTools();

  // Set default values
  const defaultRange = `week`;
  const defaultStart = dayjs.utc(dayjs().startOf(`day`).subtract(1, defaultRange)).format();
  const defaultEnd = dayjs.utc(dayjs().endOf(`day`)).format();
  const defaultCustomerIdArray = [];
  const defaultMoveStatusArray = [];
  const defaultCancelStatusArray = [];
  const defaultVehicleMakeArray = [];
  const defaultVehicleModelArray = [];

  // Get local storage items
  const getDefaultDisable = () => {
    const localRange = localStorage.getItem(`move-index-range`);
    if (localRange && localRange === `custom`) return false;
    else return true;
  };

  const getDefaultRange = () => {
    const localRange = localStorage.getItem(`move-index-range`);
    if (localRange) return localRange;
    else return defaultRange;
  };

  const getDefaultStart = () => {
    const localRange = localStorage.getItem(`move-index-range`);
    const localStart = localStorage.getItem(`move-index-start`);
    if (localRange && localRange === `day`) return dayjs.utc(dayjs().startOf(`day`)).format();
    if (localRange && localRange !== `custom`)
      return dayjs.utc(dayjs().startOf(`day`).subtract(1, localRange)).format();
    else if (localRange === `custom` && localStart) return localStart;
    else return defaultStart;
  };

  const getDefaultEnd = () => {
    const localRange = localStorage.getItem(`move-index-range`);
    const localEnd = localStorage.getItem(`move-index-end`);
    if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().endOf(`day`)).format();
    else if (localRange === `custom` && localEnd) return localEnd;
    else return defaultEnd;
  };

  const getDefaultCustomerIdArray = () => {
    return defaultCustomerIdArray;
  };

  const getDefaultMoveStatusArray = () => {
    return defaultMoveStatusArray;
  };

  const getDefaultCancelStatusArray = () => {
    return defaultCancelStatusArray;
  }

  const getDefaultVehicleMakeArray = () => {
    return defaultVehicleMakeArray;
  };

  const getDefaultVehicleModelArray = () => {
    return defaultVehicleModelArray;
  };

  const formatTime = time => {
    if (time && typeof time === `string`) {
      const newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
      return newTime;
    }
    return null;
  };

  /** Generate a CSV list of the current moves index table data */
  const generateCSV = (checkedMoves = [], allMoves = [], start = null, end = null) => {
    let moves = [];
    if (checkedMoves && checkedMoves.length > 0) {
      moves = checkedMoves;
    } else if (allMoves && allMoves.length > 0) {
      moves = allMoves;
    }
    if (moves && moves.length > 0) {
      try {
        // Gather extra pickup headers to show for the csv rows
        const gatherExtraPickupHeaders = moves => {
          let extraPickupHeaders = [];
          moves.forEach(move => {
            const pickupWorkflowData = getWorkflowData(`pickup`, move.pickup_workflow_data, `csv`);
            if (pickupWorkflowData && Object.keys(pickupWorkflowData).length > 0) {
              const workflowKeys = Object.keys(pickupWorkflowData);
              extraPickupHeaders = [...extraPickupHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraPickupHeaders)];
        };
        const extraPickupHeaders = gatherExtraPickupHeaders(moves);

        // Gather extra delivery headers to show for the csv rows
        const gatherExtraDeliveryHeaders = moves => {
          let extraDeliveryHeaders = [];
          moves.forEach(move => {
            const deliveryWorkflowData = getWorkflowData(`delivery`, move.delivery_workflow_data, `csv`);
            if (deliveryWorkflowData && Object.keys(deliveryWorkflowData).length > 0) {
              const workflowKeys = Object.keys(deliveryWorkflowData);
              extraDeliveryHeaders = [...extraDeliveryHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraDeliveryHeaders)];
        };
        const extraDeliveryHeaders = gatherExtraDeliveryHeaders(moves);

        // Create rows and options for CSV
        const createCsvRow = move => {
          const pickupWorkflowData = getWorkflowData(`pickup`, move.pickup_workflow_data, `csv`) || {};
          const extraPickupData = {};
          extraPickupHeaders.forEach(key => {
            extraPickupData[key] = pickupWorkflowData[key] !== undefined ? pickupWorkflowData[key] : ``;
          });

          const deliveryWorkflowData = getWorkflowData(`delivery`, move.delivery_workflow_data, `csv`) || {};
          const extraDeliveryData = {};
          extraDeliveryHeaders.forEach(key => {
            extraDeliveryData[key] = deliveryWorkflowData[key] !== undefined ? deliveryWorkflowData[key] : ``;
          });

          return {
            move_id: move.id,
            status: getFormattedCombinedStatusFromMove(move) || ``,
            customer_name: move.customer.name || ``,
            pickup_time: formatTime(move.pickup_time) || ``,
            reference_num: move.reference_num || ``,
            vehicle_stock: move.vehicle_stock || ``,
            vehicle_vin: move.vehicle_vin || ``,
            vehicle_make: move.vehicle_make || ``,
            vehicle_model: move.vehicle_model || ``,
            vehicle_year: move.vehicle_year || ``,
            vehicle_color: move.vehicle_color || ``,
            vehicle_odometer: move.vehicle_odometer || ``,
            formatted_vehicle: getFormattedVehicleFromMove(move) || ``,
            lane: move.lane.description || ``,
            ...extraPickupData,
            ...extraDeliveryData,
          };
        };
        const csvRows = moves.map(move => createCsvRow(move));

        // Set CSV options
        const csvOptions = {
          filename: `moves_${dayjs(start).format(`MM-DD-YYYY`)}_to_${dayjs(end).format(`MM-DD-YYYY`)}`,
          useKeysAsHeaders: true,
        };

        // Create and generate the CSV
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.success(`Generated CSV!`, { autoClose: 2000 });
        log && console.log(`Generated CSV:`, csvRows);
      } catch (err) {
        toast.error(`Failed to generate CSV!`);
        console.error(`Failed to generate CSV:`, err);
      }
    } else {
      toast.error(`Failed to generate CSV - No moves found!`);
      console.error(`Failed to generate CSV - No moves found!`);
    }
  };

  // Return additional logic
  return {
    defaultRange,
    defaultStart,
    defaultEnd,
    defaultCustomerIdArray,
    defaultMoveStatusArray,
    defaultCancelStatusArray,
    defaultVehicleMakeArray,
    defaultVehicleModelArray,
    getDefaultDisable,
    getDefaultRange,
    getDefaultStart,
    getDefaultEnd,
    getDefaultCustomerIdArray,
    getDefaultMoveStatusArray,
    getDefaultCancelStatusArray,
    getDefaultVehicleMakeArray,
    getDefaultVehicleModelArray,
    formatTime,
    generateCSV,
  };
}
