//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { Modal, ModalHeader } from '../ModalComponents';

import ManageRideContent from './ManageRideContent';

//////////////////////// COMPONENT ////////////////////////

// input.move - The ride move the modal needs to render properly

export default function ManageRideModal({ open, onClose, input }) {
  // Handle modal closing and optionally pass in an output
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <Modal open={open}>
      {open ? (
        <>
          <ModalHeader onClose={handleClose}>
            {getPropValue(input, `move.id`) ? `Manage Ride #${input.move.id}` : `Manage Ride`}
          </ModalHeader>

          <ManageRideContent input={input} onClose={handleClose} />
        </>
      ) : null}
    </Modal>
  );
}
