import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TableWrapper from './TableWrapper';
import LanesFilter from './LanesFilter';
import Toolbar from '../../components/Toolbar';
import { DefaultEmptyFallback } from '../../components/Fallbacks';


function LaneTableView(props) {
  const cls = useStyles();

  const [customerId, setCustomerId] = useState(localStorage.getItem('lanes_search') || 1);

  const handleCustomerChange = event => {
    if (event.target.value > 0) {
      setCustomerId(event.target.value);
      localStorage.setItem('lanes_search', event.target.value);
    } else {
      setCustomerId(null);
    }
  };

  return (
    <div className={cls.root}>
      {/* Banner */}
      <Toolbar fullscreen title='Lanes'>
        <LanesFilter customerId={customerId} onCustomerChange={handleCustomerChange} />
      </Toolbar>
      {/* Table */}
      {customerId ? (
        <TableWrapper customerId={customerId} />
      ) : (
        //This should never render
        <DefaultEmptyFallback message='PLEASE SELECT A CUSTOMER' />
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
}));

export default LaneTableView;
