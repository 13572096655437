// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Chip, Icon, Typography } from '@material-ui/core';

import { Table, Column } from '../../components/TableComponents';

import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

// COMPONENT ---------------------------------------------------------------- //

export default function DriverPayTable({ paycycles }) {
  const cls = useStyles();

  const { goToPayCycleDetails, capFirst, formatUSD } = useTools();
  const {
    getDriverCountFromPaycycle,
    getMoveCountFromPaycycle,
    getAmountPaidFromPaycycle,
    getAmountDueFromPaycycle,
    getPaycycleStatusFromPaycycle,
  } = useDriverPay();

  const getStatusChipClass = status => {
    switch (status) {
      case `accruing`:
        return cls.statusAccruing;
      case `preparing`:
        return cls.statusPreparing;
      case `prepared`:
        return cls.statusPrepared;
      case `closed`:
        return cls.statusClosed;
      case `done`:
        return cls.statusDone;
      case `failed`:
        return cls.statusFailed;
      default:
        return undefined;
    }
  };

  const getStatusChipVariant = status => {
    switch (status) {
      case `accruing`:
      case `preparing`:
        return `outlined`;
      case `prepared`:
      case `closed`:
      case `done`:
      case `failed`:
      default:
        return `default`;
    }
  };

  return (
    <div className={cls.wrapper}>
      <Table
        data={paycycles}
        onRowClick={paycycle => goToPayCycleDetails(paycycle?.id)}
        // rowActions={rowActions}
        defaultOrderBy={'date'}
        defaultOrder={'desc'}
        tableAriaLabel={`pay-cycles`}
        stickyHeader={true}
      >
        <Column
          name={`date`}
          label={`Date Range`}
          tooltip={`Date range of the pay cycle`}
          value={paycycle => `${paycycle?.start_date} to ${paycycle?.end_date}`}
          renderer={paycycle => {
            return `${dayjs.utc(dayjs(paycycle?.start_date)).format(`MMM D`)} - ${dayjs
              .utc(dayjs(paycycle?.end_date))
              .format(`MMM D, YYYY`)}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`tax_class`}
          label={`Tax Class`}
          tooltip={`Tax class of the pay cycle`}
          value={paycycle => paycycle?.tax_class}
          renderer={paycycle => <Chip label={paycycle?.tax_class} size='small' />}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`driver_count`}
          label={`Driver Count`}
          tooltip={`Number of drivers in the pay cycle`}
          value={paycycle => getDriverCountFromPaycycle(paycycle)}
          renderer={paycycle =>
            `${getDriverCountFromPaycycle(paycycle)} Driver${getDriverCountFromPaycycle(paycycle) === 1 ? `` : `s`}`
          }
          style={{ minWidth: 100 }}
        />

        <Column
          name={`move_count`}
          label={`Move Count`}
          tooltip={`Number of moves in the pay cycle`}
          value={paycycle => getMoveCountFromPaycycle(paycycle)}
          renderer={paycycle =>
            `${getMoveCountFromPaycycle(paycycle)} Move${getMoveCountFromPaycycle(paycycle) === 1 ? `` : `s`}`
          }
          style={{ minWidth: 100 }}
        />

        <Column
          name={`status`}
          label={`Status`}
          tooltip={`Status of the pay cycle`}
          value={paycycle => paycycle?.status}
          renderer={paycycle => (
            <Chip
              className={getStatusChipClass(getPaycycleStatusFromPaycycle(paycycle))}
              label={capFirst(paycycle?.status)}
              icon={
                getPaycycleStatusFromPaycycle(paycycle) === `done` ? (
                  <Icon className={cls.statusIcon}>check</Icon>
                ) : undefined
              }
              variant={getStatusChipVariant(paycycle?.status)}
              size='small'
            />
          )}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`amount_paid`}
          label={`Amount Paid`}
          tooltip={`Total amount paid in the pay cycle`}
          value={paycycle => getAmountPaidFromPaycycle(paycycle)}
          renderer={paycycle => {
            const amountPaid = getAmountPaidFromPaycycle(paycycle);
            return (
              <Typography className={amountPaid < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                {formatUSD(amountPaid, { displayAccountingNegative: true })}
              </Typography>
            );
          }}
          style={{ minWidth: 100 }}
          align={`right`}
        />

        <Column
          name={`amount_due`}
          label={`Amount Due`}
          tooltip={`Total amount due in the pay cycle`}
          value={paycycle => getAmountDueFromPaycycle(paycycle)}
          renderer={paycycle => {
            const amountDue = getAmountDueFromPaycycle(paycycle);
            return (
              <Typography className={amountDue < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                {formatUSD(amountDue, { displayAccountingNegative: true })}
              </Typography>
            );
          }}
          style={{ minWidth: 100 }}
          align={`right`}
        />
      </Table>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflowY: `auto`,
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.paper,
  },

  statusAccruing: {
    borderColor: theme.palette.default.main,
    color: theme.palette.default.main,
  },
  statusPreparing: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  statusPrepared: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  statusClosed: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusDone: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  statusFailed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  statusIcon: {
    fontSize: 16,
    color: theme.palette.success.contrastText,
  },

  amountTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxtNegative: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
}));
