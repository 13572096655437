import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Button, TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';

import { ModalContent, ModalFooter, ModalHeader } from '../ModalComponents';
import DefaultEmptyFallback from '../Fallbacks/DefaultEmptyFallback';

import { gql, useQuery } from '@apollo/client';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

let log = false;

export default function InsuranceRateFormModal({
  editMode = false,
  rateRuleGroupId,
  currentInsuranceRateId = 0,
  selectedInsuranceRate,
  onEditSave = unsavedInsuranceRate => {},
  onAddSave,
  onCancel = () => {},
}) {
  const [unsavedInsuranceRate, setUnsavedInsuranceRate] = useState(null);
  const [insuranceRates, setInsuranceRates] = useState([]);
  const [selectedInsuranceRateId, setSelectedInsuranceRateId] = useState(
    selectedInsuranceRate?.id ? selectedInsuranceRate.id : currentInsuranceRateId
  );
  const [filteredInsuranceRates, setFilteredInsuranceRates] = useState([]);

  const { loading, error, data } = useQuery(GET_INSURANCE_RATES);
  useEffect(() => {
    unsavedInsuranceRate && console.log('unsavedInsuranceRate', unsavedInsuranceRate);
  }, [unsavedInsuranceRate]);
  useEffect(() => {
    if (data) {
      log && console.log('Insurance Rates: ', data, data.insurancerates);
      setInsuranceRates(data.insurancerates);
      if (data?.insurancerates?.length) {
        let filteredRates = data.insurancerates.filter(rate => dayjs(rate?.expiration_date).isAfter(dayjs()));
        setFilteredInsuranceRates(filteredRates);
      }
    } else {
      setInsuranceRates([]);
      setFilteredInsuranceRates([]);
    }
    if (error) {
      console.error('Error loading insurance rates: ', error);
      // toast.error()
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!unsavedInsuranceRate) return;
    log && console.log('Unsaved insurance rate changed to: ', unsavedInsuranceRate);
  }, [unsavedInsuranceRate]);

  const handleProductChange = event => {
    if (event.target.value) handleInsuranceRateSelect(event.target.value);
    else setSelectedInsuranceRateId(0);
  };

  const handleInsuranceRateSelect = insuranceRateId => {
    if (insuranceRateId && selectedInsuranceRateId && selectedInsuranceRateId !== insuranceRateId) {
      setSelectedInsuranceRateId(insuranceRateId);
      const selectedInsuranceRate = insuranceRates.find(insuranceRate => insuranceRate.id === insuranceRateId);
      setUnsavedInsuranceRate(selectedInsuranceRate);
    } else if (insuranceRateId !== currentInsuranceRateId) {
      setSelectedInsuranceRateId(insuranceRateId);
      //Set the selected Insurance Rate
      const selectedInsuranceRate = insuranceRates.find(insuranceRate => insuranceRate.id === insuranceRateId);
      log && console.log('selected insurance rate', selectedInsuranceRate);
      setUnsavedInsuranceRate(selectedInsuranceRate);
    } else {
      setSelectedInsuranceRateId(insuranceRateId);
      setUnsavedInsuranceRate(null);
    }
  };

  return (
    <>
      <ModalHeader id='insurance-rate-edit-modal' onClose={onCancel}>
        {editMode ? 'Switch Insurance Rate' : 'Add Insurance Rate'}
      </ModalHeader>
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INSURANCE RATE FORM' />}>
        <ModalContent dividers>
          {filteredInsuranceRates.length ? (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable={true}
                  fullWidth
                  options={filteredInsuranceRates}
                  getOptionLabel={option => (option.name ? `${option.name} (${option.state || 'no state'})` : '')}
                  value={filteredInsuranceRates.find(rate => rate.id === selectedInsuranceRateId) || null}
                  onChange={handleProductChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Select Insurance'
                      placeholder='Select an insurance rate...'
                      variant='outlined'
                      size='small'
                    />
                  )}
                  renderOption={option => (
                    <MenuItem key={option.id} value={option.id} style={{ width: '100%' }}>
                      {`${option.name} (${option.state || 'no state'})`}
                    </MenuItem>
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <DefaultEmptyFallback message='NO ACTIVE INSURANCE RATES FOUND' />
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            onClick={() => {
              onCancel();
              setSelectedInsuranceRateId(0);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAddSave(unsavedInsuranceRate);
            }}
            color='primary'
            //disabled if insurance rate not selected from dropdown
            disabled={unsavedInsuranceRate ? false : true}
          >
            Save
          </Button>
        </ModalFooter>
      </Sentry.ErrorBoundary>
    </>
  );
}

const GET_INSURANCE_RATES = gql`
  query getInsuranceRatesForTable {
    insurancerates(order_by: { id: desc }) {
      id
      carrier_name
      createdat
      createdby
      description
      effective_date
      expiration_date
      name
      rate
      class_3_rate
      class_4_rate
      class_5_rate
      class_6_rate
      region_id
      region {
        id
        name
      }
      state
      type
      updatedat
      updatedby
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // padding: theme.spacing(2),
    width: 800,
    height: 'fitContent',
  },
  productSelector: {
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 600,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '4px 0',
    background: theme.palette.divider,
  },
  inputInfo: {
    padding: 20,
  },
  inputField: {
    width: 200,
    margin: 20,
  },
  date: {
    width: 200,
    margin: 20,
  },
}));
