//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import { InvoiceContent, InvoicesList } from './index';
import { useInvoices } from './useInvoices';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// COMPONENT ////////////////////////

export default function Invoices(props) {
  const cls = useStyles();
  const { getDefaultInvoiceId, getDefaultSelectedCustomerIds } = useInvoices();

  const [invoiceId, setInvoiceId] = React.useState(getDefaultInvoiceId());
  const [invoiceIdInURL, setInvoiceIdInURL] = React.useState(false);
  const [selectedCustomerIds, setSelectedCustomerIds] = React.useState(getDefaultSelectedCustomerIds() || []);

  const propInvoiceId = getPropValue(props, 'match.params.id');

  React.useEffect(() => {
    if (propInvoiceId) {
      setInvoiceId(propInvoiceId);
      setInvoiceIdInURL(true);
    }
  }, [propInvoiceId]);

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INVOICES' />}>
      <div className={cls.root}>
        <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
          <Grid className={cls.gridItem} item xs>
            <div className={cls.scrollWrap}>
              <InvoiceContent
                invoiceId={invoiceId}
                setSelectedCustomerIds={setSelectedCustomerIds}
                invoiceIdInURL={invoiceIdInURL}
              />
            </div>
          </Grid>
          <Grid className={cls.gridItem} item>
            <div className={cls.scrollWrap}>
              <InvoicesList
                invoiceId={invoiceId}
                setInvoiceId={setInvoiceId}
                invoiceIdInURL={invoiceIdInURL}
                selectedCustomerIds={selectedCustomerIds}
                setSelectedCustomerIds={setSelectedCustomerIds}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  gridItem: {
    position: 'relative',
  },
  scrollWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 64,
  },
}));
