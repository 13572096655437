//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import { Modal } from '../ModalComponents';
import ARPriceOverrideContent from './ARPriceOverrideContent';

//////////////////////// COMPONENT ////////////////////////

function ARPriceOverrideModal(props) {
  const { open, onClose, input } = props;

  return (
    <Modal open={open} width='md'>
      {input ? <ARPriceOverrideContent open={open} onClose={onClose} input={input} /> : null}
    </Modal>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ARPriceOverrideModal;
