//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, useTheme, Icon } from '@material-ui/core';
import { ScheduleOutlineDrive, ScheduleOutlineRide } from '../index';
import { useScheduler } from '../providers/SchedulerProvider';
import { Draggable } from 'react-beautiful-dnd';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import DeadlineChip from '../../../components/MoveComponents/DeadlineChip';
import { useTools } from '../../../hooks/useTools';

const { log } = new SimpleLogger({ prefix: 'ScheduleOutlineBundle', enabled: true });

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleOutlineBundle({ enrichedMove, onClick, index }) {
  const cls = useStyles();
  const theme = useTheme();

  const { getMoveDeadline } = useTools();
  const { openRideModal, isSelected } = useScheduler();

  //const move = withOverrides(enrichedMove) || {};
  const { withOverrides: move = {}, enrichedRideBefore, enrichedRideAfter } = enrichedMove;
  const { lane = {} } = move;

  // Attachment to the left of the drive move
  const LeftAttachment = () => {
    return (
      <ScheduleOutlineRide
        enrichedDrive={enrichedMove}
        enrichedRide={enrichedRideBefore}
        onClick={e => {
          openRideModal(enrichedRideBefore, enrichedMove, false);
          e.stopPropagation();
        }}
        index={index}
      />
    );
  };

  // Attachment to the right of the drive move
  const RightAttachment = () => {
    return (
      <ScheduleOutlineRide
        enrichedDrive={enrichedMove}
        enrichedRide={enrichedRideAfter}
        isAfter
        onClick={e => {
          openRideModal(enrichedRideAfter, enrichedMove, true);
          e.stopPropagation();
        }}
        index={index}
      />
    );
  };

  const DragCountBadge = ({ children, ...props }) => {
    return <div className={cls.dragCountBadge}>{children}</div>;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    //border: (selectedEnrichedMoveId === enrichedMove.id) ? theme.border[0] : '',
    //borderStyle: (selectedEnrichedMoveId === enrichedMove.id) ? '' : '',
    background: isSelected(enrichedMove) ? theme.palette.action.focus : theme.palette.drop,
    cursor: isDragging ? 'grabbing' : enrichedMove.isDraggable ? 'grab' : 'pointer',
    boxShadow: isDragging ? '10px 10px 32px -1px rgba(0,0,0,0.75)' : null,
    animation: isSelected(enrichedMove) ? `selected 1s infinite` : `none`,
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // Bundle component
  return (
    <Draggable
      draggableId={`${move.id}`}
      isDragDisabled={!enrichedMove.isDraggable}
      value={move.id}
      key={move.id}
      index={index}
    >
      {(provided, snapshot) => {
        // Get the deadline for the move. Use the estimated delivery time as the reference time.
        const calcTime = move?.delivery_time;
        const deadlineMs = getMoveDeadline(move?.deliver_by, calcTime);

        return (
          <div
            className={cls.bundle}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            key={move.id}
            data-toggle='collapse'
            onClick={e => {
              log('Clicked on a draggable schedule bundle');
              if (onClick) onClick(e);
            }}
            value={move.id}
            aria-expanded='false'
            aria-controls={`draggable-${move.id}`}
            id={`draggable-${move.id}`}
          >
            <Grid container spacing={1} justifyContent='space-between' alignItems='center' wrap='nowrap'>
              <Grid item>
                <div className={cls.dragIconBox}>
                  {enrichedMove.isDraggable ? <Icon className={cls.dragIcon}>drag_indicator</Icon> : null}
                </div>
              </Grid>

              <Grid item>
                <LeftAttachment />
              </Grid>

              <Grid item xs onClick={onClick}>
                {move ? (
                  <ScheduleOutlineDrive
                    move={move}
                    enrichedMove={enrichedMove}
                    start={move.pickup_time}
                    end={move.delivery_time}
                    duration={enrichedMove.duration}
                    distance={lane.distance_miles}
                  />
                ) : (
                  <div className={cls.emptyDrive} />
                )}
              </Grid>

              <Grid item>
                <RightAttachment />
              </Grid>
            </Grid>

            <div className={cls.deadline}>
              <DeadlineChip
                move={move}
                deadlineMs={deadlineMs}
                calc='planning'
                calcTime={calcTime}
                hide={Number(deadlineMs) && deadlineMs <= 86400000 ? false : true} // Show only if deadline is less than 24 hours
              />
            </div>

            {!enrichedMove.isValid ? <div className={cls.notValid} /> : null}
            {!enrichedMove.isDraggable ? <div className={cls.inProgress} /> : null}
            {snapshot.isDragging && enrichedMove.linkedEnrichedMove ? <DragCountBadge>2</DragCountBadge> : null}
          </div>
        );
      }}
    </Draggable>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  bundle: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.paperRadius,
    transition: '0.15s',
  },
  emptyDrive: {
    position: 'relative',
    width: '100%',
    height: 48,
    border: theme.border[0],
    borderStyle: 'dashed',
    borderRadius: theme.shape.borderRadius,
  },
  dragIconBox: {
    width: 36,
    height: 40,
    marginLeft: theme.spacing(-0.5),
  },
  dragIcon: {
    fontSize: 40,
    color: theme.palette.text.secondary,
  },
  notValid: {
    zIndex: 310,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.paperRadius,
    background: `${theme.palette.error.main}80`,
    cursor: 'default',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  inProgress: {
    zIndex: 310,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.paperRadius,
    background: '#ffffff80',
    cursor: 'default',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dragCountBadge: {
    right: '-8px',
    top: '-8px',
    color: 'white',
    background: theme.palette.error.main,
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    lineHeight: '30px',
    position: 'absolute',
    textAlign: 'center',
    fontSize: '0.8rem',
  },

  deadline: {
    zIndex: 250,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));
