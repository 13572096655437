import React, { useCallback } from 'react';
import { Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

const log = false;
/**
 *
 * @param {Array} dataArray Array of data objects to show in the dropdown
 * @param {Array} selectedArray Optional array of data ids that are pre selected
 * @param {String} optionName String representing dot notation of object values to use as labels in the dropdown options
 * @param {String} label What to label the input and placeholder text (ex. 'move' if you want the input to be labeled 'Moves')
 * @param {function} handleOutput Function that will run when the selected array changes
 * @param {Boolean} noIdInLabel If true, the label will ont include the id in parens (defaults to false)
 * @returns
 */
export default function ReusableMultiselect({
  dataArray = [],
  selectedArray = [],
  optionName = 'name',
  label = 'Option',
  handleOutput,
  noIdInLabel = false,
  listSelected = false,
}) {
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [toggleSelectAll, setToggleSelectAll] = React.useState(false);

  React.useEffect(() => {
    //Clone array since data from apollo is read-only
    const optionsArray = dataArray.map(option => option);
    setOptions(optionsArray);

    //Handle pre-selected options
    if (selectedArray.length) {
      var preselectedOptions = [];
      optionsArray.forEach(option => {
        if (selectedArray.includes(option.id)) {
          preselectedOptions.push(option);
        }
      });
      setSelectedOptions(preselectedOptions);
    } else setSelectedOptions([])
    log && console.log('In Multi- ', label, dataArray, preselectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataArray, selectedArray]);

  const getOptionName = option => {
    let name = null;
    if (option && optionName) {
      name = getPropValue(option, optionName);
    }
    return name || 'No name found';
  };

  const sortOptions = useCallback(
    options => {
      //This function will sort selected above unselected, and both selected and unselected sections will be alphabetized
      function orderCustomerOptions(a, b) {
        const aName = getOptionName(a);
        const bName = getOptionName(b);
        const aIsSelected = selectedOptions.includes(a);
        const bIsSelected = selectedOptions.includes(b);
        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (!aIsSelected && bIsSelected) {
          return 1;
        }
        if (aIsSelected && bIsSelected) {
          if (aName.toLowerCase() < bName.toLowerCase()) {
            return -1;
          }
          if (aName.toLowerCase() > bName.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        if (aName.toLowerCase() < bName.toLowerCase()) {
          return -1;
        }
        if (aName.toLowerCase() > bName.toLowerCase()) {
          return 1;
        }
        return 0;
      }
      options.sort(orderCustomerOptions);
      return options;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions]
  );

  const handleOnChange = (event, value) => {
    log && console.log('Multiselect current values: ', selectedOptions);
    log && console.log('On Change Event', event, value);
    let newSelections = []
    value.forEach(v => {
      newSelections.push(v)
    })

    setSelectedOptions(newSelections);
    // setSelectedArray(selectedOptionIds)
    //If a handle function has been passed in, run it now
    if (handleOutput) {
      handleOutput(value);
    }
  };

  const handleToggleSelectAll = options => {
    // If there is an input value, select only the filtered items
    if (!toggleSelectAll) {
      const filterValue = inputValue.trim().toLowerCase();
  
      const filteredOptions = filterValue.length
        ? options.filter(option => {
            const optionNameValue = getOptionName(option);
            return optionNameValue.toLowerCase().includes(filterValue);
          })
        : options;
  
      // Update state once
      setToggleSelectAll(true);
      setSelectedOptions(filteredOptions);
  
      // Call handleOutput if defined
      if (handleOutput) {
        handleOutput(filteredOptions);
      }
    } else {
      // Deselect all options
      setToggleSelectAll(false);
      setSelectedOptions([]);
  
      // Call handleOutput with an empty array
      if (handleOutput) {
        handleOutput([]);
      }
    }
  };

  const getOptionLabel = option => {
    const optionLabelName = getOptionName(option);
    if (option && noIdInLabel && optionLabelName) return `${optionLabelName}`;
    else if (option && option.id && optionLabelName) return `${optionLabelName} (${option.id})`;
    return `Unknown ${label}`;
  };

  const getPlaceholderText = () => {
    const selectedValuesCount = Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.length : 0;
    if (listSelected && selectedValuesCount > 0) {
      const namesToDisplay = selectedOptions.slice(0, 10); // Get the first 10 names
      const namesString = namesToDisplay.map(obj => obj.name).join(', ');
      if (selectedValuesCount > 10) {
        return `${namesString}, [+${selectedValuesCount - 10}]`; // Add ellipsis if more than 10 names
      }
      return namesString; // Return the names if 10 or fewer
    } else {
      if (selectedValuesCount === 1) {
        return `1 ${label} Selected`;
      }
      if (selectedValuesCount > 1) {
        return `${selectedOptions.length} ${label}s Selected`;
      }
      return listSelected ? `Filter by region...` : `Select ${label}s...`;
    }
  };

  const renderOptions = (option, selected) => {
    return (
      <div>
        <Checkbox
          data-testid={`${option.id}-checkbox-option-multiselect`}
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 4 }}
          checked={selected}
          color={'primary'}
        />
        {getOptionLabel(option)}
      </div>
    );
  };

  const icon = <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = <CheckBox fontSize='small' />;

  return (
    <Autocomplete
      multiple
      id='option-multi-select'
      options={sortOptions(options)}
      value={selectedOptions}
      disableCloseOnSelect
      disableClearable={true}
      popupIcon={''}
      getOptionLabel={option => getOptionName(option)}
      onChange={(event, value) => {
        handleOnChange(event, value);
      }}
      renderTags={() => {
        return null;
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event && event.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setInputValue(value);
        }
      }}
      renderOption={(option, { selected }) => renderOptions(option, selected)}
      renderInput={params => (
        <TextField
          {...params}
          data-testid='option-multiselect-textfield'
          multiline
          //  required={false}
          name='option'
          label={label + 's' || 'Options'}
          placeholder={getPlaceholderText()}
          size='small'
          variant='outlined'
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <FormControlLabel
                data-testid='option-multiselect-select-all-checkbox'
                style={{ marginLeft: 4, marginRight: 0 }}
                onClick={e => {
                  e.preventDefault(); // prevent blur
                  handleToggleSelectAll(options);
                }}
                control={
                  <Checkbox
                    id='select-all-checkbox'
                    color={'primary'}
                    style={{
                      padding: 0,
                      marginRight: 8,
                    }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={toggleSelectAll}
                  />
                }
              />
            ),
          }}
        />
      )}
    />
  );
}
