import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  useTheme,
  makeStyles,
  Container,
  Tooltip,
  Button,
  Grid,
  Icon,
  IconButton,
  Typography,
  InputBase,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';

import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';

import { getUserToken } from '../../utils/authHelper';
import { useData } from '../../providers/DataProvider';
import { GET_LANE, GET_INVERSE, UPSERT_LANE } from './gql';

const log = false;
const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function LanesDetails(props) {
  const ctx = useData();
  const cls = useStyles();
  const theme = useTheme();

  log && console.log('Lane ID:', props.match.params.id);

  // STATES /////////////////////////////////////////////////////////////////////////////////////////
  const [laneID, setLaneID] = useState(props.match.params.id || 0);

  const [editMode, setEditMode] = useState(false);

  const [oldLane, setOldLane] = useState({});
  const [newLane, setNewLane] = useState({});

  // HOOK /////////////////////////////////////////////////////////////////////////////////////////
  const { loading, error, data, refetch } = useQuery(GET_LANE, {
    variables: { id: laneID },
  });

  useEffect(() => {
    if (data && data.lanes) setOldLane(data.lanes[0]);
  }, [data]);

  // LOGIC /////////////////////////////////////////////////////////////////////////////////////////
  function handleSetEdit() {
    useStyles = makeStyles(theme => ({
      root: {
        display: 'block',
        position: 'relative',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
      paper: {
        position: 'relative',
        width: '100%',
        padding: theme.spacing(2),
        borderRadius: theme.shape.paperRadius,
        background: theme.palette.background.paper,
        boxShadow: theme.shadow.medium,
      },
      head: {
        verticalAlign: 'top',
        display: 'inline-block',
        lineHeight: 1,
        fontSize: '24px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
          fontSize: '21px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '18px',
        },
      },
      headTxtL: {
        lineHeight: 1.25,
        fontSize: '21px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '16px',
        },
        cursor: 'default',
      },
      headTxtR: {
        lineHeight: 1.25,
        fontSize: '16px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
          fontSize: '15px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
        },
        cursor: 'default',
      },
      subheadTxt: {
        marginBottom: theme.spacing(1.5),
        lineHeight: 1.25,
        fontSize: '12px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
          fontSize: '11px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '10px',
        },
      },
      listItem: {
        display: 'flex',
        flexWrap: 'nowrap',
      },
      keyTxt: {
        color: theme.palette.text.secondary,
        textAlign: 'left',
        lineHeight: 1.25,
        fontSize: '14px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
          fontSize: '13px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        },
      },
      valTxt: {
        width: '100%',
        textAlign: 'right',
        lineHeight: 1.25,
        fontSize: '14px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
          fontSize: '13px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        },
      },
      inputTxt: {
        width: '100%',
        textAlign: 'right',
        fontSize: '14px',
        minHeight: '17px',
        maxHeight: '17px',
        padding: 0,
        background: '#00000008',
        [theme.breakpoints.down('sm')]: {
          fontSize: '13px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        },
      },
      tollsEdt: {
        marginTop: '-13px',
      },
      lineBreak: {
        width: '100%',
        height: '1px',
        marginTop: '6px',
        marginBottom: '8px',
        background: '#eee',
      },
      hiddenBreak: {
        display: 'none',
        width: '100%',
        height: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          display: 'block',
        },
      },
      break: {
        width: '100%',
        height: theme.spacing(2),
      },
      smallBreak: {
        width: '100%',
        height: theme.spacing(1),
      },
      midBreak: {
        width: '100%',
        height: theme.spacing(1.5),
      },
      rootTable: {
        verticalAlign: 'top',
        position: 'relative',
        width: '100%',
      },
      notFound: {
        width: '100%',
        padding: theme.spacing(4),
        borderRadius: theme.shape.paperRadius,
        marginLeft: 'auto',
        marginRight: 'auto',
        background: theme.palette.background.paper,
        boxShadow: theme.shadow.medium,
      },
      notFoundTxt: {
        color: theme.palette.text.secondary,
        lineHeight: 1.25,
        textAlign: 'center',
        fontSize: 21,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
        },
      },
      iconBtn: {
        verticalAlign: 'top',
        display: 'inline-block',
        marginTop: '-12px',
        [theme.breakpoints.down('sm')]: {
          marginTop: '-14px',
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: '-16px',
        },
      },
      saveBtn: {
        color: theme.palette.primary.main,
      },
      recalcBtn: {
        color: theme.palette.secondary.main,
      },
      inverseBtn: {
        verticalAlign: 'top',
        float: 'right',
        marginTop: '-5px',
        marginLeft: theme.spacing(1),
        boxShadow: `none`,
        '&:hover, &:active': {
          boxShadow: `none`,
        },
      },
      nameTxt: {
        lineHeight: 1.25,
        fontSize: '18px',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
        },
      },
      addressTxt: {
        color: theme.palette.text.secondary,
        lineHeight: 1.25,
        fontSize: '14px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
          fontSize: '11px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '10px',
        },
      },
      location: {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#00000000',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        cursor: 'pointer',
        transition: '0.2s',
      },
    }));
    setNewLane({ ...oldLane });
    setEditMode(!editMode);
  }

  const handleInputChange = event => {
    if (event.target.name === 'duration_sec') {
      setNewLane({ ...newLane, [event.target.name]: Number(event.target.value * 60) });
    } else {
      setNewLane({ ...newLane, [event.target.name]: Number(event.target.value) });
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  const goToLocationDetails = id => {
    props.history.push(`/locations/${id}`);
  };

  async function handleInverse() {
    try {
      let inverseID = null;
      await ctx.apolloClient
        .query({
          query: GET_INVERSE,
          variables: { pickup: oldLane.pickup.id, delivery: oldLane.delivery.id },
          fetchPolicy: 'network-only',
        })
        .then(res => {
          if (res.data.lanes[0]) {
            log && console.log('INVERSE LANE ID', res.data.lanes[0].id);
            inverseID = res.data.lanes[0].id;
          }
        });
      if (inverseID) {
        setLaneID(inverseID);
        window.history.pushState('', '', `/lanes/${inverseID}`);
      } else {
        toast.error('Failed to find an inverse lane');
      }
    } catch (err) {
      toast.error('Error finding inverse lane');
      console.error(err);
    }
  }

  async function recalculate() {
    console.log('Recalculating Lane......');
    try {
      const token = await getUserToken();

      //We need to send this to a netlify function because Lane Details cannot be calculated on the front-end
      const laneCalcRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/recalculateLane`,
        data: {
          lane: newLane
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (laneCalcRes && laneCalcRes.status === 200) {
        let detailedLane = laneCalcRes.data;
        setNewLane({ ...detailedLane });
      }else{
        throw new Error("Error recalculating Lane: ", laneCalcRes.body)
      }
    } catch (error) {
      toast.error('Error Calculating Lane Details')
      console.error(error);
    }

    // Set whichever lane obj is being rendered in the edit section to the new detailedLane
    // When the user saves the changes, persist the detailedLane to the db
    // If the user discards changes, then revert back to the original oldLane
  }

  function handleTotalBaseProfit(laneObj) {
    if (laneObj.dealer_base_rate_type === `flat`)
      return (
        Number(laneObj.dealer_base_price) -
        Number(
          Number(Number(laneObj.driver_base_pay) - Number(laneObj.driver_base_pay_discount)) +
            Number(laneObj.insurance_cost) +
            Number(1.55 + 0.024 * Number(laneObj.dealer_base_price))
        ) -
        Number(laneObj.dealer_base_discount) -
        Number(laneObj.tolls)
      );
    else if (laneObj.dealer_base_rate_type === `per`)
      return (
        Number(laneObj.dealer_base_rate) * Number(laneObj.distance_miles) -
        Number(
          Number(Number(laneObj.driver_base_pay) - Number(laneObj.driver_base_pay_discount)) +
            Number(laneObj.insurance_cost) +
            Number(1.55 + 0.024 * Number(laneObj.dealer_base_price))
        ) -
        Number(laneObj.dealer_base_discount) -
        Number(laneObj.tolls)
      );
    else return 0;
  }

  function handleTotalStrandedProfit(laneObj) {
    if (laneObj.dealer_stranded_rate_type === `flat`)
      return (
        Number(laneObj.dealer_stranded_price) -
        Number(
          Number(
            Number(laneObj.driver_base_pay) -
              Number(laneObj.driver_base_pay_discount) +
              Number(laneObj.driver_return_pay) -
              Number(laneObj.driver_return_pay_discount)
          ) +
            Number(laneObj.insurance_cost) +
            Number(1.55 + 0.024 * Number(laneObj.dealer_base_price)) +
            Number(laneObj.estimated_rideshare_return_cost)
        ) -
        Number(laneObj.dealer_stranded_discount) -
        Number(laneObj.tolls)
      );
    else if (laneObj.dealer_stranded_rate_type === `per`)
      return (
        Number(laneObj.dealer_stranded_rate) * Number(laneObj.distance_miles) -
        Number(
          Number(
            Number(laneObj.driver_base_pay) -
              Number(laneObj.driver_base_pay_discount) +
              Number(laneObj.driver_return_pay) -
              Number(laneObj.driver_return_pay_discount)
          ) +
            Number(laneObj.insurance_cost) +
            Number(1.55 + 0.024 * Number(laneObj.dealer_base_price)) +
            Number(laneObj.estimated_rideshare_return_cost)
        ) -
        Number(laneObj.dealer_stranded_discount) -
        Number(laneObj.tolls)
      );
    else return 0;
  }

  // RENDER /////////////////////////////////////////////////////////////////////////////////////////
  if (loading) return <Loading fixed />;
  if (error) {
    console.log('Error Fetching Lane:', error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FETCHING LANE' />;
  } else if (data && data.lanes && data.lanes[0] && oldLane) {
    const lane = data.lanes[0];
    log && console.log('LANE:', lane);

    async function upsertLane() {
      await recalculate();
      log && console.log('Lane Recalculated:', newLane);

      let lane = newLane;

      log && console.log('newLane --> lane:', lane);
      delete lane.pickup;
      delete lane.delivery;
      delete lane.customer;
      delete lane.__typename;

      let updatedAt = { updatedat: 'now()' };
      let updatedLane = Object.assign({}, lane, updatedAt);

      log && console.log('upsertLane - Lane to upsert:', updatedLane);

      ctx.apolloClient
        .mutate({
          mutation: UPSERT_LANE,
          variables: { laneObjects: updatedLane },
        })
        .then(res => {
          toast.success('Lane Edited');
          refetch();
          setEditMode(false);
        })
        .catch(err => {
          if (log) console.log('upsertLanes .catch -- err:', err);
          toast.error('Upsert Failed');
          console.error(err);
        });
    }

    return (
      <>
        <div className={cls.root}>
          <Container maxWidth='lg'>
            <IconButton style={{ marginRight: theme.spacing(1) }} className={cls.iconBtn} onClick={goBack}>
              <Icon>arrow_back</Icon>
            </IconButton>
            <Typography className={cls.head}>&nbsp;Lane&nbsp;Details&nbsp;</Typography>
            <Button className={cls.inverseBtn} color='primary' variant='contained' onClick={handleInverse}>
              &nbsp;Inverse&nbsp;Lane&nbsp;
            </Button>
            {/* TOOLS  -------------------------------------------------------- */}
            <div style={{ float: 'right' }}>
              {editMode ? (
                <>
                  <Tooltip placement='top' title={`Click to recalculate lane rates`}>
                    <Button className={cls.recalcBtn} onClick={() => recalculate()}>
                      {!loading ? 'Recalculate' : <Loading />}
                    </Button>
                  </Tooltip>
                  <Tooltip placement='top' title={`Click to lock & save your changes`}>
                    <Button className={cls.saveBtn} onClick={() => upsertLane()}>
                      Save Changes
                    </Button>
                  </Tooltip>
                </>
              ) : null}
              <Tooltip
                placement='top'
                title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the lane`}
              >
                <IconButton
                  style={{
                    marginLeft: theme.spacing(1),
                    color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
                  }}
                  className={cls.iconBtn}
                  onClick={() => handleSetEdit()}
                >
                  <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip placement='top' title={`Actions`}>
                <IconButton style={{ color: theme.palette.text.secondary }} className={cls.iconBtn}>
                  <Icon>settings</Icon>
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ width: '100%', height: theme.spacing(4) }} />

            {/* PICKUP LOCATION --------------------------------------------------------  */}
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title={`Pickup Location ID`}>
                        <Typography className={cls.headTxtL}>Pickup&nbsp;#{lane.pickup.id}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.smallBreak} />
                  <Tooltip placement='top' title={`Click to see details for this location`}>
                    <div
                      className={cls.location}
                      onClick={() => {
                        goToLocationDetails(lane.pickup.id);
                      }}
                    >
                      <Typography className={cls.nameTxt}>{lane.pickup.name}</Typography>
                      <Typography className={cls.addressTxt}>{lane.pickup.address}</Typography>
                    </div>
                  </Tooltip>
                </div>
              </Grid>

              {/* DELIVERY LOCATION -------------------------------------------------------- */}
              <Grid item sm={6} xs={12}>
                <>
                  <div className={cls.paper}>
                    <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                      <Grid item xs>
                        <Tooltip placement='top-start' title={`Delivery Location ID`}>
                          <Typography className={cls.headTxtL}>Delivery&nbsp;#{lane.delivery.id}</Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <div className={cls.smallBreak} />
                    <Tooltip placement='top' title={`Click to see details for this location`}>
                      <div
                        className={cls.location}
                        onClick={() => {
                          goToLocationDetails(lane.delivery.id);
                        }}
                      >
                        <Typography className={cls.nameTxt}>{lane.delivery.name}</Typography>
                        <Typography className={cls.addressTxt}>{lane.delivery.address}</Typography>
                      </div>
                    </Tooltip>
                  </div>
                </>
              </Grid>
            </Grid>
            <div className={cls.break} />

            {!editMode ? (
              <>
                {/* ROUTE INFORMATION -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item sm={12}>
                      <Tooltip placement='top-start' title='Lane ID'>
                        <Typography className={cls.headTxtL}>Lane&nbsp;#{oldLane.id}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <Typography className={cls.subheadTxt}>Route Info</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Distance&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {Number(oldLane.distance_miles).toFixed(1)}&nbsp;mi
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Time&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {Number(Number(oldLane.duration_sec) / 60).toFixed(1)}&nbsp;min
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Average&nbsp;Speed&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {Number(oldLane.average_drive_speed_mph).toFixed(1)}&nbsp;mph
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />

                {/* DRIVER PAY -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Driver Pay'>
                        <Typography className={cls.headTxtL}>Driver&nbsp;Pay</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_drive_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_base_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Time&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_time_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Distance&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_drive_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_return_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Standard&nbsp;Pay/Min&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${oldLane.driver_pay_per_minute}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Standard&nbsp;Pay/Mile&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${oldLane.driver_pay_per_mile}</Typography>
                      </div>
                      <div className={cls.break} />
                    </Grid>

                    <div className={cls.hiddenBreak} />

                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.driver_base_pay_discount).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.driver_return_pay_discount).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Rake&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.driver_rake).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.break} />
                      <div className={cls.break} />
                      <div className={cls.break} />
                      <Typography className={cls.subheadTxt}>Totals</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Totals&nbsp;Base&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(Number(oldLane.driver_base_pay) - Number(oldLane.driver_base_pay_discount)).toFixed(
                            2
                          )}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(oldLane.driver_base_pay) -
                              Number(oldLane.driver_base_pay_discount) +
                              Number(oldLane.driver_return_pay) -
                              Number(oldLane.driver_return_pay_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.break} />
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />

                {/* CUSTOMER PRICE -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Customer Pricing'>
                        <Typography className={cls.headTxtL}>Customer&nbsp;Price</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Base Rates</Typography>
                      {/* <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>
                        Milage&nbsp;Band&nbsp;&nbsp;
                        </Typography>
                        <Typography className={cls.valTxt}>
                        { milageBand }
                        </Typography>
                    </div>
                    <div className={cls.lineBreak} /> */}
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{capFirst(oldLane.dealer_base_rate_type)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Rate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(Number(oldLane.dealer_base_rate) - Number(oldLane.dealer_base_discount)).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.dealer_base_discount).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Tolls&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.tolls).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Price&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(Number(oldLane.dealer_base_price) - Number(oldLane.dealer_base_discount)).toFixed(2)}
                        </Typography>
                      </div>
                    </Grid>

                    <div className={cls.hiddenBreak} />

                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Stranded Rates</Typography>
                      {/* <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>
                        Milage&nbsp;Band&nbsp;&nbsp;
                        </Typography>
                        <Typography className={cls.valTxt}>
                        { milageBand }
                        </Typography>
                    </div>
                    <div className={cls.lineBreak} /> */}
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Stranded&nbsp;Rate&nbsp;Type&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{capFirst(oldLane.dealer_stranded_rate_type)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Rate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(oldLane.dealer_stranded_rate) - Number(oldLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Stranded&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.dealer_stranded_discount).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Tolls&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.tolls).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Price&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(oldLane.dealer_stranded_price) - Number(oldLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />

                {/* PROFIT & COST INFORMATION -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Profit & Cost'>
                        <Typography className={cls.headTxtL}>Profit&nbsp;&&nbsp;Cost</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Factors</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Insurance&nbsp;Cost/Mile&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.insurance_cost_per_mile).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Insurance&nbsp;Cost:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(oldLane.insurance_cost).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Commission&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(1.55 + 0.024 * Number(oldLane.dealer_base_price)).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Ride&nbsp;Estimate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(oldLane.estimated_rideshare_return_cost).toFixed(2)}
                        </Typography>
                      </div>
                    </Grid>

                    <div className={cls.hiddenBreak} />

                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Totals</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Cost&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(
                              Number(Number(oldLane.driver_base_pay) - Number(oldLane.driver_base_pay_discount)) +
                                Number(oldLane.insurance_cost) +
                                Number(1.55 + 0.024 * Number(oldLane.dealer_base_price))
                            ) + Number(oldLane.dealer_base_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Cost&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(
                              Number(
                                Number(oldLane.driver_base_pay) -
                                  Number(oldLane.driver_base_pay_discount) +
                                  Number(oldLane.driver_return_pay) -
                                  Number(oldLane.driver_return_pay_discount)
                              ) +
                                Number(oldLane.insurance_cost) +
                                Number(1.55 + 0.024 * Number(oldLane.dealer_base_price)) +
                                Number(oldLane.estimated_rideshare_return_cost)
                            ) + Number(oldLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Profit&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${handleTotalBaseProfit(oldLane).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Profit&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${handleTotalStrandedProfit(oldLane).toFixed(2)}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <>
                {/* EDITABLE INFORMATION ----------------------------------------------------------------------------------------------------------------   */}

                {/* EDITABLE ROUTE INFORMATION -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item sm={12}>
                      <Tooltip placement='top-start' title='Lane ID'>
                        <Typography className={cls.headTxtL}>Lane&nbsp;#{newLane.id}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <Typography className={cls.subheadTxt}>Route Info</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Distance&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='distance_miles'
                          defaultValue={Number(newLane.distance_miles).toFixed(1)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Time&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {Number(Number(newLane.duration_sec) / 60).toFixed(1)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Average&nbsp;Speed&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          {Number(newLane.average_drive_speed_mph).toFixed(2)}&nbsp;mph
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />
                {/* EDITABLE DRIVER PAY -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Driver Pay'>
                        <Typography className={cls.headTxtL}>Driver&nbsp;Pay</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.driver_drive_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.driver_base_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Time&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.driver_time_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Distance&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.driver_drive_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.driver_return_pay).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Standard&nbsp;Pay/Min&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='driver_pay_per_minute'
                          defaultValue={newLane.driver_pay_per_minute}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Standard&nbsp;Pay/Mile&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='driver_pay_per_mile'
                          defaultValue={newLane.driver_pay_per_mile}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.break} />
                    </Grid>

                    <div className={cls.hiddenBreak} />
                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Drive&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='driver_base_pay_discount'
                          defaultValue={Number(newLane.driver_base_pay_discount).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='driver_return_pay_discount'
                          defaultValue={Number(newLane.driver_return_pay_discount).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.break} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Rake&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='driver_rake'
                          defaultValue={Number(newLane.driver_rake).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.break} />
                      <div className={cls.break} />
                      <div className={cls.break} />
                      <Typography className={cls.subheadTxt}>Totals</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Totals&nbsp;Base&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(Number(newLane.driver_base_pay) - Number(newLane.driver_base_pay_discount)).toFixed(
                            2
                          )}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Pay&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(newLane.driver_base_pay) -
                              Number(newLane.driver_base_pay_discount) +
                              Number(newLane.driver_return_pay) -
                              Number(newLane.driver_return_pay_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.break} />
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />

                {/* EDITABLE CUSTOMER PRICE -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Customer Pricing'>
                        <Typography className={cls.headTxtL}>Customer&nbsp;Price</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Base Rates</Typography>
                      {/* <div className={cls.listItem}>
                            <Typography className={cls.keyTxt}>
                            Milage&nbsp;Band&nbsp;&nbsp;
                            </Typography>
                            <Typography className={cls.valTxt}>
                            { milageBand }
                            </Typography>
                        </div>
                        <div className={cls.lineBreak} /> */}
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{capFirst(newLane.dealer_base_rate_type)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Rate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(Number(newLane.dealer_base_rate) - Number(newLane.dealer_base_discount)).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Base&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='dealer_base_discount'
                          defaultValue={Number(newLane.dealer_base_discount).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.lineBreak} />

                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Price&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(Number(newLane.dealer_base_price) - Number(newLane.dealer_base_discount)).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                    </Grid>

                    <div className={cls.hiddenBreak} />

                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Stranded Rates</Typography>
                      {/* <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>
                        Milage&nbsp;Band&nbsp;&nbsp;
                        </Typography>
                        <Typography className={cls.valTxt}>
                        { milageBand }
                        </Typography>
                    </div>
                    <div className={cls.lineBreak} /> */}
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Stranded&nbsp;Rate&nbsp;Type&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{capFirst(newLane.dealer_stranded_rate_type)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Rate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(newLane.dealer_stranded_rate) - Number(newLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Stranded&nbsp;Discount&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='dealer_stranded_discount'
                          defaultValue={Number(newLane.dealer_stranded_discount).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Price&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(newLane.dealer_stranded_price) - Number(newLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={cls.tollsEdt}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Tolls&nbsp;&nbsp;</Typography>
                        <InputBase
                          name='tolls'
                          defaultValue={Number(newLane.tolls).toFixed(2)}
                          onChange={handleInputChange}
                          className={cls.inputTxt}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.break} />

                {/* EDITABLE PROFIT & COST INFORMATION -------------------------------------------------------- */}
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Profit & Cost'>
                        <Typography className={cls.headTxtL}>Profit&nbsp;&&nbsp;Cost</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={cls.break} />
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Factors</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Insurance&nbsp;Cost/Mile&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(newLane.insurance_cost_per_mile).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Insurance&nbsp;Cost:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${Number(newLane.insurance_cost).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Commission&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(1.55 + 0.024 * Number(newLane.dealer_base_price)).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Return&nbsp;Ride&nbsp;Estimate&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          ${Number(newLane.estimated_rideshare_return_cost).toFixed(2)}
                        </Typography>
                      </div>
                    </Grid>

                    <div className={cls.hiddenBreak} />

                    <Grid item sm={6} xs={12}>
                      <Typography className={cls.subheadTxt}>Totals</Typography>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Cost&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(
                              Number(Number(newLane.driver_base_pay) - Number(newLane.driver_base_pay_discount)) +
                                Number(newLane.insurance_cost) +
                                Number(1.55 + 0.024 * Number(newLane.dealer_base_price))
                            ) + Number(newLane.dealer_base_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Cost&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>
                          $
                          {Number(
                            Number(
                              Number(
                                Number(newLane.driver_base_pay) -
                                  Number(newLane.driver_base_pay_discount) +
                                  Number(newLane.driver_return_pay) -
                                  Number(newLane.driver_return_pay_discount)
                              ) +
                                Number(newLane.insurance_cost) +
                                Number(1.55 + 0.024 * Number(newLane.dealer_base_price)) +
                                Number(newLane.estimated_rideshare_return_cost)
                            ) + Number(newLane.dealer_stranded_discount)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Base&nbsp;Profit&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${handleTotalBaseProfit(newLane).toFixed(2)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Total&nbsp;Stranded&nbsp;Profit&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>${handleTotalStrandedProfit(newLane).toFixed(2)}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Container>
        </div>
      </>
    );
  } else return <DefaultEmptyFallback message='NO LANE FOUND' />;
}

// STYLE /////////////////////////////////////////////////////////////////////////////////////////
let useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  inputTxt: {
    width: '100%',
    textAlign: 'right',
    fontSize: '14px',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  tollsEdt: {
    marginTop: '-13px',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  saveBtn: {
    color: theme.palette.primary.main,
  },
  recalcBtn: {
    color: theme.palette.secondary.main,
  },
  inverseBtn: {
    verticalAlign: 'top',
    float: 'right',
    marginTop: '-5px',
    marginLeft: theme.spacing(1),
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  addressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#00000000',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
}));
