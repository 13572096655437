// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { getGapBetweenTimes } from '../../utils/TimelineUtils';

import { useTimeline } from './TimelineProvider';

import StaticPlanAreaRow from './DataTypes/StaticPlan/StaticPlanAreaRow';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineAreaRow({ dataItem, index }) {
  const cls = useStyles();

  const { dataType, date, earliestTime, itemOffset, multiplier, rowSize, timezoneOverride, zoom } = useTimeline();

  const startGap = getGapBetweenTimes(
    dayjs(`${date}T12:00:00`).tz(timezoneOverride).startOf('day').format(),
    dayjs(earliestTime).tz(timezoneOverride).format(),
    zoom,
    multiplier
  );

  return (
    <div
      className={clsx(
        cls.timelineAreaRow,
        cls[index % 2 ? 'even' : 'odd'],
        dataItem?.isSpecial ? cls[index % 2 ? 'specialEven' : 'specialOdd'] : null
      )}
      style={{ height: `${rowSize}px` }}
    >
      {dataType === `static-plan` ? (
        <StaticPlanAreaRow
          dataItem={dataItem}
          index={index}
          startGap={startGap}
          itemOffset={itemOffset}
          multiplier={multiplier}
          timezoneOverride={timezoneOverride}
          zoom={zoom}
        />
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineAreaRow: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  odd: {
    backgroundColor: theme.palette.background.paper,
  },
  even: {
    backgroundColor: theme.palette.background.light,
  },
  specialOdd: {
    backgroundColor: `${theme.palette.info.main}30`,
  },
  specialEven: {
    backgroundColor: `${theme.palette.info.main}40`,
  },
}));
