//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal } from '../../components/ModalComponents';
import { MoveVehicleWeightModalContent } from './index'

//////////////////////// COMPONENT ////////////////////////

function MoveVehicleWeightModal(props) {

  const { open, onClose, input } = props;

  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open} width='sm'>
        <MoveVehicleWeightModalContent input={input} handleClose={handleClose} />
      </Modal>
    </>
  );
}

//////////////////////// EXPORT ////////////////////////

export default MoveVehicleWeightModal;