import React from 'react';
import dayjs from 'dayjs';
import gql from 'graphql-tag';

import { useQuery, useLazyQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core';

import Toolbar from '../../components/Toolbar';
import Filter from '../../components/Filter/Filter';

import ActiveTimeTableWrapper from './ActiveTimeTableWrapper';

const baseFilters = Object.freeze({
  dateRange: Object.freeze({
    range: `past-1-week`,
    start: dayjs().startOf(`day`).subtract(1, `week`).format(),
    end: dayjs().endOf(`day`).format(),
    utc: false,
  }),
  select: Object.freeze({
    value: `all`,
  }),
});

const getLocalFilters = () => {
  const localFilters = JSON.parse(localStorage.getItem(`ActiveTime-Filters`)) || {};
  return localFilters;
};

export default function ActiveTime() {
  const cls = useStyles();

  const [allRegions, setAllRegions] = React.useState([]);
  const [filters, setFilters] = React.useState(getLocalFilters());
  const [filterConfig, setFilterConfig] = React.useState({
    modules: [`dateRange`, `select`, `genericRadio`, `switchButton`],
    moduleOptions: {
      dateRange: {
        ranges: [
          `custom`,
          `current-pay-cycle (1099)`,
          `current-pay-cycle (W2)`,
          `previous-pay-cycle (1099)`,
          `previous-pay-cycle (W2)`,
          `two-cycles-ago (1099)`,
          `two-cycles-ago (W2)`,
          `past-1-week`,
          `past-1-month`,
          `past-1-year`,
        ],
        minDate: dayjs().subtract(1, `year`).format(),
        maxDate: dayjs().add(1, `year`).format(),
        showUtc: false,
      },
      select: {
        allowAll: true,
        icon: `public`,
        label: `Region Select`,
        options: [],
      },
      genericRadio: {
        label: `Status`,
        options: [`both`, `approved`, `audit`],
      },
      switchButton: {
        label: `Tax Class`,
        options: [`1099`, `W-2`],
      },
    },
  });

  const [getRegions] = useLazyQuery(GET_REGIONS);

  React.useEffect(() => {
    const fetchRegions = async () => {
      try {
        const res = await getRegions();
        const newRegions =
          res?.data?.regions
            ?.map(r => ({ value: r?.id, label: `${r?.name} (${r?.id})` }))
            ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

        setFilterConfig({
          ...filterConfig,
          moduleOptions: {
            ...filterConfig.moduleOptions,
            select: { ...filterConfig.moduleOptions.select, options: newRegions },
          },
        });
        setAllRegions(newRegions);
      } catch (err) {
        console.log('error fetching regions', err);
      }
    };
    fetchRegions();
  }, []);

  const handleApplyFilters = output => {
    setFilters(output);
    localStorage.setItem(`ActiveTime-Filters`, JSON.stringify(output));
  };
  const handleClearFilters = output => {
    setFilters(output);
    localStorage.removeItem(`ActiveTime-Filters`);
  };
  const handleToggleFilters = output => {
    setFilters(output);
  };
  const handleStatusOutput = options => {
    const checkedOption = options?.find(opt => opt.checked);
    if (!checkedOption?.value) return [`approved`, `audit`];
    if (checkedOption?.value === `both`) return [`approved`, `audit`];
    return [checkedOption?.value];
  };
  const handleRegionOutput = value => {
    const allRegionIds = allRegions?.map(r => r.value);
    if (value === `all`) return allRegionIds;
    if (!value) return allRegionIds;
    return [value];
  };
  const handleTaxClassOutput = options => {
    const allTaxClasses = [`1099`, `W-2`];
    const checkedOptions = options?.find(opt => opt.checked);
    if (!checkedOptions) return allTaxClasses;
    return [checkedOptions.value];
  };

  const { loading, error, data } = useQuery(GET_ACTIVE_TIMES, {
    variables: {
      start: filters?.dateRange?.start || baseFilters?.dateRange?.start,
      end: filters?.dateRange?.end || baseFilters?.dateRange?.end,
      selectedStatuses: handleStatusOutput(filters?.genericRadio?.options),
      selectedRegions: handleRegionOutput(filters?.select?.value),
      selectedTaxClass: handleTaxClassOutput(filters?.switchButton?.options),
    },
    fetchPolicy: `network-only`,
  });

  const activeTimes = data?.activetimes || [];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Active Time'>
        <div className={cls.flex}>
          <div className={cls.flexStretch} />

          <Filter
            baseFilters={baseFilters}
            localFilters={getLocalFilters()}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
            onToggle={handleToggleFilters}
            config={filterConfig}
          />

          {/* <ActionsMenu actions={actions} /> */}
        </div>
      </Toolbar>

      <ActiveTimeTableWrapper loading={loading} error={error} activeTimes={activeTimes} />
    </div>
  );
}

const GET_ACTIVE_TIMES = gql`
  query GetActiveTimes(
    $start: timestamptz
    $end: timestamptz
    $selectedStatuses: [String!]
    $selectedRegions: [bigint!]
    $selectedTaxClass: [String!]
  ) {
    activetimes(
      where: {
        status: { _in: $selectedStatuses }
        move: {
          _and: [{ pickup_time: { _gte: $start } }, { pickup_time: { _lte: $end } }]
          lane: { pickup: { region_id: { _in: $selectedRegions } } }
          driver: { tax_class: { _in: $selectedTaxClass } }
        }
      }
    ) {
      id
      duration_minutes
      factors
      move_id
      notes
      override_minutes
      status
      move {
        id
        driver_name
        pickup_time
        driver {
          id
          tax_class
        }
        lane {
          id
          pickup {
            id
            region {
              id
              name
            }
          }
          delivery {
            id
            region {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_REGIONS = gql`
  query getRegions {
    regions {
      id
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexStretch: {
    flex: 1,
  },

  btn1: {
    height: 36.5,
    borderRadius: `4px 0 0 4px`,
  },
  btn2: {
    height: 36.5,
    borderRadius: `0 4px 4px 0`,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
}));
