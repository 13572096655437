//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, TextField, InputAdornment, Tooltip, Icon, IconButton } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

function Searchbar(props) {
  const cls = useStyles();
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const [isMoveSearch, setIsMoveSearch] = React.useState(true);

  const handleSearchSubmit = event => {
    event.preventDefault();
    if (isMoveSearch && search) history.push(`/moves/${search}`);
    if (!isMoveSearch && search) history.push(`/invoices/${search}`);
    setSearch('');
  };

  const handleSearchChange = event => {
    const val = event.target.value || ``;
    if (val === `` || !isNaN(parseInt(val))) setSearch(val);
  };

  const swapClick = () => {
    setIsMoveSearch(!isMoveSearch);
  };

  return (
    <form className={cls.search} onSubmit={handleSearchSubmit}>
      <Tooltip title={isMoveSearch ? 'Click to search invoices by ID' : 'Click to search moves by ID'}>
        <IconButton style={{ position: 'absolute', zIndex: 1010 }} className={cls.swapIcon} onClick={swapClick}>
          <Icon style={{ padding: 0 }}>swap_vert</Icon>
        </IconButton>
      </Tooltip>

      <TextField
        fullWidth
        placeholder={isMoveSearch ? 'Search Move By ID' : 'Search Invoice By ID'}
        variant='outlined'
        size='small'
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          className: cls.searchColor,
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon style={{ color: '#fff', cursor: 'default' }}>search</Icon>
            </InputAdornment>
          ),
        }}
        SelectProps={{
          inputProps: {
            classes: {
              icon: cls.searchColor,
            },
          },
        }}
        className={cls.searchTextField}
      />
    </form>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  search: {
    minWidth: 250,
    maxWidth: 250,
    marginRight: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchTextField: {
    borderRadius: theme.shape.borderRadius,
    background: '#ffffff24',
    color: theme.palette.text.contrast,
    '&:hover': {
      background: '#ffffff36',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.contrast,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.contrast,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
    transition: '0.2s',
  },
  searchColor: {
    color: theme.palette.text.contrast,
  },
  swapIcon: {
    top: 0,
    right: 0,
    color: 'white',
    padding: '8px',
    '&:hover': {
      background: '#ffffff36',
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default Searchbar;
