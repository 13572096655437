import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const NoVinItem = ({ message }) => {
  const cls = useStyles();
  return (
    <Typography align='center' variant='h6' className={cls.moveItem}>
      {message}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  moveItem: {
    width: '500px',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
  }
}));

export default NoVinItem;