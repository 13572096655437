// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

import TimelineAreaRow from './TimelineAreaRow';
import TimelineAreaMarker from './TimelineAreaMarker';
import TimelineAreaMarkerCurrent from './TimelineAreaMarkerCurrent';
import TimelineAreaMarkerHover from './TimelineAreaMarkerHover';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineAreaBody() {
  const cls = useStyles();

  const { data, isCurrentDay, isHovering, markerHoverPosition, markerHoverTime, markers, showMarkers } = useTimeline();

  if (!data?.length) return null;
  return (
    <div className={cls.timelineAreaBody}>
      {data?.map((dataItem, i) => (
        <React.Fragment key={`timeline-area-row-${i}`}>
          <TimelineAreaRow dataItem={dataItem} index={i} />
        </React.Fragment>
      ))}

      {showMarkers ? (
        <>
          {isCurrentDay ? <TimelineAreaMarkerCurrent showLabel={false} /> : null}

          {markers?.map((marker, i) => (
            <TimelineAreaMarker key={`timeline-area-marker-${i}`} showLabel={false} marker={marker} />
          ))}

          {isHovering && markerHoverPosition && markerHoverTime ? <TimelineAreaMarkerHover showLabel={false} /> : null}
        </>
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineAreaBody: {
    zIndex: 101,
    position: 'relative',
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
