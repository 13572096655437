import React, { useState } from 'react';
import { makeStyles, Container, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

import CustomerSelect from '../../components/CustomerSelect';
import LocationSelect from '../../components/LocationSelect';
import OutputWrapper from './OutputWrapper';

////////// COMPONENT //////////
export default function MoveCalculatorPage(props) {
  const cls = useStyles();

  // STATES -------------------------------------------------------------------------------------
  const [customer, setCustomer] = useState(1);
  const [pickup, setPickup] = useState();
  const [delivery, setDelivery] = useState();

  const handlePickup = pickup => {
    if (pickup === null) {
      setPickup(null);
    } else if (delivery && delivery.address === pickup.address) {
      toast.error('Pickup Address must be different than Delivery Address');
    } else {
      setPickup(pickup);
    }
  };
  const handleDelivery = delivery => {
    if (delivery === null) {
      setDelivery(null);
    } else if (pickup && pickup.address === delivery.address) {
      toast.error('Delivery Address must be different than Pickup Address');
    } else {
      setDelivery(delivery);
    }
  };
  const handleCustomerChange = event => {
    setCustomer(event.target.value);
  };

  // RENDER -------------------------------------------------------------------------------------
  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Typography className={cls.head}>Move Calculator</Typography>
        <Typography className={cls.sub}>
          Welcome to the move calculator. This form allows you to calculate the route and cost information of a move
          based on today's date.
        </Typography>

        <div className={cls.paper}>
          <div className={cls.lane}>
            <CustomerSelect required value={customer || 'Select a customer...'} onChange={handleCustomerChange} />
          </div>
          <div className={cls.lane}>
            <div className={cls.laneL}>
              <LocationSelect
                valid
                customerId={customer}
                locationData={pickup}
                onChange={handlePickup}
                label='Pickup Location'
              />
            </div>
            <div className={cls.laneR}>
              <LocationSelect
                valid
                customerId={customer}
                locationData={delivery}
                onChange={handleDelivery}
                label='Delivery Location'
              />
            </div>
          </div>
          {/* ------------------------ CALCULATOR OUTPUT ------------------------ */}
          {customer && pickup && delivery ? (
            <OutputWrapper customerId={customer} pickup={pickup} delivery={delivery} regionId={pickup.region_id} />
          ) : null}
        </div>
      </Container>
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  lane: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  laneL: {
    width: '100%',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      marginTop: theme.spacing(2),
    },
  },
  laneR: {
    width: '100%',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      marginTop: theme.spacing(2),
    },
  },
}));
