import { useQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import DriverSelect from '../../components/DriverSelect';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

function PayableDrivers(props) {
  const { regionId, payPeriod, status, driverId, handleDriverChange } = props;
  const { loading, error, data } = useQuery(GET_PAYABLE_DRIVERS(status, regionId, driverId, payPeriod), {
    variables: { regionId: regionId, payPeriod: payPeriod, status: status },
  });
  if (loading)
    return (
      <DriverSelect
        selectAllItem
        label='Driver Filter'
        value={driverId || 0}
        onChange={handleDriverChange}
        drivers={[{ driver_id: null, driver_name: 'Loading...' }]}
      />
    );
  if (error) {
    toast.error('Failed to retrieve payable drivers');
    console.error(`Failed to retrieve payable drivers:`, error);
  }
  if (data && data.pay_periods_by_driver && data.pay_periods_by_driver.length > 0) {
    return (
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAYABLE DRIVERS' />}>
        <DriverSelect
          selectAllItem
          label='Driver Filter'
          value={driverId || 0}
          onChange={handleDriverChange}
          drivers={data.pay_periods_by_driver}
        />
      </Sentry.ErrorBoundary>
    );
  } else
    return (
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAYABLE DRIVERS' />}>
        <DriverSelect selectAllItem label='Driver Filter' value={driverId || 0} onChange={handleDriverChange} />
      </Sentry.ErrorBoundary>
    );
}

const GET_PAYABLE_DRIVERS = (status, regionId, driverId, payPeriod) => gql`
  query payable_drivers($payPeriod: String, $status: String, $regionId: bigint, $start: timestamp, $end: timestamp) {
    pay_periods_by_driver(where: {
      ${payPeriod ? 'pay_period: {_eq: $payPeriod},' : ''}
      ${status ? 'status: {_eq: $status},' : ''}
      ${regionId ? 'region_id: {_eq: $regionId},' : ''}
    },
    distinct_on: [driver_id, driver_name]
    order_by: {driver_id: desc, driver_name: desc}) {
      driver_id
      driver_name
    }
  }
`;

export default PayableDrivers;
