import React from 'react';
import { GET_PAYOUT_DETAILS, buildDriverPayCycleObject } from '@hopdrive/sdk/lib/modules/wallet';
import { makeStyles, Container } from '@material-ui/core';
import { Divide, Loading, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';

import { useTools } from '../../hooks/useTools';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import Toolbar from '../../components/Toolbar';

import PayoutToolbar from './PayoutToolbar';
import PayoutInfo from './PayoutInfo';
import APDetailTable from '../DriverPay-3/APDetailTable';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function PayoutDetails(props) {
  const cls = useStyles();

  const { goToDriverDetails } = useTools();

  const driverpayoutId = props.match.params.id;

  const { loading, error, data, refetch } = useQuery(GET_PAYOUT_DETAILS, {
    variables: { driverpayoutId },
    fetchPolicy: `network-only`,
  });

  // Handle refetch callback to pass as props
  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Payout Details' />
          <Loading position='fixed' />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting driverpayout details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Payout Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultErrorFallback message='ERROR GETTING PAYOUT DETAILS' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.driverpayouts || !data.driverpayouts.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Payout Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback message='NO PAYOUT FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //
  const payout = data.driverpayouts[0];
  log && console.log(`Driver Payout:`, payout);

  try {
    var driverPay = buildDriverPayCycleObject(payout?.driver, payout?.appayments, payout?.apcharges);
    log && console.log(`Driver Pay Object:`, driverPay);
  } catch (err) {
    console.error(`Error calling buildDriverPayObject`, err);
  }

  const actions = [
    {
      label: `Go To Driver Details`,
      handler: () => goToDriverDetails(payout?.driver?.id),
    },
  ];

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Toolbar back title='Payout Details' refetch={handleRefetch} actions={actions}>
          <PayoutToolbar payout={payout} refetch={handleRefetch} />
        </Toolbar>

        <Spacer />

        {/* PAYOUT BASE INFO */}
        <PayoutInfo payout={payout} driverPay={driverPay} />

        {/* AP TABLE */}
        {driverPay ? (
          <>
            <Divide spacer tip='View the payments & charges associated with this payout.'>
              Payments & Charges Table
            </Divide>
            <APDetailTable paper driverPay={driverPay} />
          </>
        ) : null}
      </Container>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
