//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, CircularProgress, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function Loading(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      position: props.fixed ? 'fixed' : props.relative ? 'relative' : 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'max-content',
      margin: 'auto',
    },
    loading: {
      display: 'block',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      color: props.color ? props.color : theme.palette.primary.main,
    },
    iconBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: 2,
    },
    icon: {
      color: props.color ? props.color : theme.palette.primary.main,
    },
  }));
  const cls = useStyles();

  return (
    <div className={cls.root}>
      <CircularProgress className={cls.loading} />

      {props.icon ? (
        <div className={cls.iconBox}>
          <Icon className={cls.icon}>{props.icon}</Icon>
        </div>
      ) : null}
    </div>
  );
}
