import React from 'react';
import { useLazyQuery, InMemoryCache } from '@apollo/client';
import { useSettings } from './SettingsProvider';
import { useRegions } from './RegionsProvider'
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { queries } from '../utils/queries';
import { useData } from '../../../providers/DataProvider';

const PlansContext = React.createContext({});

let _queryPlans = [];
let _filteredQueryPlans = [];
let _state;

function PlansProvider({ children }) {
  const ctx = useData();

  const { timelineDate, enablePlanLogs } = useSettings();
  const { selectedRegionId, selectedRegionIds } = useRegions();

  const [plans, setPlans] = React.useState([]);

  const { log } = new SimpleLogger({ prefix: 'PlansProvider', enabled: enablePlanLogs });

  React.useEffect(() => {
    log(`Timeline date changed. Running query directly...`);
    refetchQuery();
    subscribeToPlanUpdates();
  }, [timelineDate]);

  React.useEffect(() => {
    if (selectedRegionId) {
      _filteredQueryPlans = _queryPlans.filter(qp => qp.region_id === selectedRegionId);
      setPlans(_filteredQueryPlans);
    } else {
      _filteredQueryPlans = _queryPlans.filter(qp => selectedRegionIds.includes(qp.region_id));
      setPlans(_filteredQueryPlans);
    }
  }, [selectedRegionId, selectedRegionIds]);

  const timelineDateLocal = timelineDate.format('YYYY-MM-DD');

  const [runQuery, { subscribeToMore }] = useLazyQuery(
    queries.GET_DETAILED_PLANS_BY_DATE,
    {
      fetchPolicy: 'network-only',
      variables: {
        plan_date: timelineDateLocal,
      },
      onCompleted: (data) => {        
        if (data && data.plans.length) {
          _queryPlans = Object.assign([], data.plans);

          if (selectedRegionId) {
            _filteredQueryPlans = _queryPlans.filter(qp => qp.region_id === selectedRegionId);
            setPlans(_filteredQueryPlans);
          } else {
            _filteredQueryPlans = _queryPlans.filter(qp => selectedRegionIds.includes(qp.region_id));
            setPlans(_filteredQueryPlans);
          }
        } else {
          setPlans([]);
        }

        _state = 'query.successful'
      },
      onError: () => {
        _state = 'query.error'
      },
    },
  );

  const subscribeToPlanUpdates = () => {
    subscribeToMore({
      document: queries.SUBSCRIBE_TO_PLAN_CHANGES,
      variables: { plan_date: timelineDateLocal },
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              moves: {
                merge(existing, incoming, { mergeObjects }) {
                  return mergeObjects(existing, incoming);
                },
              },
            },
          },
        },
      }),
    });
  };
 

  const refetchQuery = () => {
    log(`Manually refetching query from server...`);
    _state = 'query.loading';
    runQuery();
  };

  // Export state variables by adding them to context
  const context = {
    plans,
    state: _state,
    refetch: refetchQuery,
  };

  return <PlansContext.Provider value={context}>{children}</PlansContext.Provider>;
}

const usePlans = () => React.useContext(PlansContext);

export { usePlans, PlansProvider };
