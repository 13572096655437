import React, { Fragment } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';

const EventLogItemGeneric = ({ event }) => {
  const cls = useStyles();

  return (
    <Fragment key={'test'}>
      <div className={cls.lineBreak} />
      <div className={cls.listItem}>
        <Typography className={cls.keyTxt}>
          <b>{event?.actiontype?.name}</b>
          <br />
          {event?.actiontype?.description}
          <br />
          {dayjs(event?.event_time_utc).format('MM/DD/YYYY hh:mm A')}
        </Typography>
        <Typography className={cls.valTxt}>
          {event?.metadata && <div className={cls.json}>{JSON.stringify(event?.metadata, null, 2)}</div>}
        </Typography>
      </div>
    </Fragment>
  );
};

export default EventLogItemGeneric;

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    minWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 11,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  json: {
    backgroundColor: '#f9f9f9',
    borderRadius: 15,
    padding: 10,
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
  },
}));
