// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Icon, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';

import { getUserEmail } from '../../utils/authHelper';

import MoveOutcomeReviewAccessorialRow from './MoveOutcomeReviewAccessorialRow';

// COMPONENT ---------------------------------------------------------------- //

export default function MoveOutcomeReasonModalContent({ input, onClose }) {
  const cls = useStyles();

  const [reason, setReason] = React.useState(``);

  /** Apply the changes and insert an eventlog with the reason */
  const handleApplyChanges = async () => {
    const userEmail = getUserEmail();

    // Build the eventlog config object to be attach to the accessorial
    const eventlogConfig = {
      action: `move.outcome.adjustment`,
      user: userEmail,
      role: `admin`,
      move_id: input?.move?.id,
      customer_id: input?.move?.customer?.id,
      lane_id: input?.move?.lane?.id,
      driver_id: input?.move?.driver_id,
      metadata: {},
    };

    // Edit the metadata of the eventlogConfig conditionally
    if (input?.old?.code)
      eventlogConfig.metadata.old = {
        ap_amount: input?.old?.ap_amount,
        ar_amount: input?.old?.ar_amount,
        code: input?.old?.code,
        cost: input?.old?.cost,
        notes: input?.old?.notes,
      };

    if (input?.new?.code)
      eventlogConfig.metadata.new = {
        ap_amount: input?.new?.ap_amount,
        ar_amount: input?.new?.ar_amount,
        code: input?.new?.code,
        cost: input?.new?.cost,
        notes: input?.new?.notes,
      };

    if (reason) eventlogConfig.metadata.reason = reason;

    // Close the modal with the output
    if (onClose)
      onClose({
        old: input?.old,
        new: input?.new,
        eventlogConfig: eventlogConfig,
        type: input?.new ? `edit` : `delete`,
      });
  };

  /** Cancel the changes made to the accessorial */
  const handleCancelChanges = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Accessorial Adjustment</ModalHeader>

      <ModalContent>
        {/* OLD ACCESSORIAL */}

        <MoveOutcomeReviewAccessorialRow accessorial={input?.old} />

        {/* ARROW */}

        <div className={cls.arrows}>
          <Icon className={cls.arrow}>south</Icon>
        </div>

        {/* NEW ACCESSORIAL */}

        <MoveOutcomeReviewAccessorialRow
          accessorial={input?.new}
          isModified={input?.new ? true : false}
          isDeleted={!input?.new ? true : false}
        />

        <Spacer size='lg' />

        {/* REASON */}

        <div className={cls.reason}>
          <Icon className={cls.reasonIcon}>info</Icon>

          <div>
            <Typography className={cls.reasonTitleTxt}>Help Us Improve the System!</Typography>
            <Typography className={cls.reasonSubtitleTxt}>
              Please provide a sufficient reason for modifying, adding, or deleting this accessorial. This reason will
              be logged onto the move audit and taken into account to help us improve the move outcome system in the
              future.
            </Typography>
          </div>
        </div>

        <Spacer size='lg' />

        <TextField
          required
          multiline
          minRows={3}
          fullWidth
          variant='outlined'
          size='small'
          label='Reason'
          placeholder='Enter your adjustment reason...'
          value={reason}
          onChange={e => setReason(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' style={{ alignSelf: `flex-start`, marginTop: 10 }}>
                <Icon color='disabled' fontSize='small'>
                  comment
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </ModalContent>

      <ModalFooter>
        <ModalAction
          disabled={reason?.length < 5}
          color='primary'
          variant='contained'
          onClick={() => handleApplyChanges()}
        >
          Apply
        </ModalAction>

        <ModalAction color='default' variant='contained' onClick={() => handleCancelChanges()}>
          Cancel
        </ModalAction>
      </ModalFooter>
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  arrows: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 64,
    padding: 6,
  },
  arrow: {
    fontSize: 24,
  },

  reason: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 12,
    padding: 12,
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.action.hover,
  },
  reasonIcon: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  reasonTitleTxt: {
    marginBottom: 6,
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  reasonSubtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}));
