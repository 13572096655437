//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid } from '@material-ui/core';
// import { Button } from '@hopdrive/storybook';

// import { useTools } from '../../hooks/useTools';

import CustomerSelect from '../../components/CustomerSelect';

//////////////////////// COMPONENT ////////////////////////

function LanesToolbar({ customerId, onCustomerChange }) {
  // const { goToRoute } = useTools();

  return (
    <Grid container spacing={2} alignItems='center' wrap='nowrap'>
      <Grid item xs />

      <Grid item>
        <CustomerSelect selectAllItem value={customerId} onChange={onCustomerChange} />
      </Grid>

      {/* 12/16/2021 - Commented out the add lanes button because the add lanes page has too many bugs to be usable in current form */}
      {/* <Grid item>
        <Button color='primary' size='large' onClick={() => goToRoute(`/lanes/add`)}>
          Add Lane
        </Button>
      </Grid> */}
    </Grid>
  );
}

//////////////////////// EXPORT ////////////////////////

export default LanesToolbar;
