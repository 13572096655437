import React from 'react';
import { Modal } from '../../components/ModalComponents';

import TerminationModalContent from './TerminationModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function TerminationModal({ open, input, onClose }) {
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return <Modal open={open}>{open ? <TerminationModalContent input={input} onClose={handleClose} /> : null}</Modal>;
}
