// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';
import { useTheme, makeStyles, Typography, Icon, TextField, InputAdornment } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_DRIVERS } from './gql';

import DriverAvatar from '../../components/DriverComponents/DriverAvatar';

/** Sort & filter drivers list */
const cleanseDrivers = (drivers, selectedIds, selectedRegionId, filteredId) => {
  return drivers
    ?.filter(d => d?.id !== filteredId)
    ?.sort((a, b) => {
      if (selectedIds?.includes(a?.id) && !selectedIds?.includes(b?.id)) return -1;
      if (!selectedIds?.includes(a?.id) && selectedIds?.includes(b?.id)) return 1;
      if (a?.region?.id === selectedRegionId && b?.region?.id !== selectedRegionId) return -1;
      if (a?.region?.id !== selectedRegionId && b?.region?.id === selectedRegionId) return 1;
      if (a?.region?.name < b?.region?.name) return -1;
      if (a?.region?.name > b?.region?.name) return 1;
      if (a?.user?.display_name?.toLowerCase() < b?.user?.display_name?.toLowerCase()) return -1;
      if (a?.user?.display_name?.toLowerCase() > b?.user?.display_name?.toLowerCase()) return 1;
      return 0;
    });
};

/** Search input to filter drivers */
const searchDrivers = (drivers, searchInput) => {
  if (!searchInput) return drivers;
  const search = searchInput?.toLowerCase();
  return drivers?.filter(d => {
    return (
      d?.id?.toString()?.includes(search) ||
      d?.user?.display_name?.toLowerCase()?.includes(search) ||
      d?.region?.name?.toLowerCase()?.includes(search)
    );
  });
};

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormDrivers({ initialFormData, formData, setFormData }) {
  const theme = useTheme();
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [driverIds, setDriverIds] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);
  const [filteredDrivers, setFilteredDrivers] = React.useState([]);
  const [search, setSearch] = React.useState(``);

  // Lazy fetch
  const [fetchDrivers, { loading, error }] = useLazyQuery(GET_DRIVERS);

  /** Handle adding a driver ID */
  const handleDriverIdAdd = id => {
    let newDriverIds = [...driverIds, id];
    if (newDriverIds?.length > formData?.capacity - 1) {
      toast.warning(`You cannot assign more drivers than the vehicle's capacity!`, {
        toastId: `gp-form-driver-capacity`,
      });
      return;
    }
    handleSetState(newDriverIds);
  };

  /** Handle removing a driver ID */
  const handleDriverIdRemove = id => {
    const newDriverIds = driverIds.filter(driverId => driverId !== id);
    handleSetState(newDriverIds);
  };

  /** Handle setting drivers state */
  const handleSetState = newDriverIds => {
    setDriverIds(newDriverIds);
    setDrivers(() => cleanseDrivers(drivers, newDriverIds, formData?.regionId, formData?.vehicleDriverId));
    setFilteredDrivers(() => cleanseDrivers(drivers, newDriverIds, formData?.regionId, formData?.vehicleDriverId));
    setFormData({ ...formData, driverIds: newDriverIds });
  };

  /** Handle clicking a driver */
  const handleDriverClick = id => {
    if (driverIds?.includes(id)) handleDriverIdRemove(id);
    else handleDriverIdAdd(id);
    setSearch(``);
  };

  /** Handle fetching drivers */
  const handleFetchDrivers = async () => {
    if (!formData?.date || !formData?.vehicleId || !formData?.vehicleDriverId || formData?.capacity - 1 <= 0) return;

    try {
      const res = await fetchDrivers();
      if (res?.data?.drivers) {
        setDrivers(() =>
          cleanseDrivers([...res?.data?.drivers], formData?.driverIds, formData?.regionId, formData?.vehicleDriverId)
        );
        setFilteredDrivers(() =>
          cleanseDrivers([...res?.data?.drivers], formData?.driverIds, formData?.regionId, formData?.vehicleDriverId)
        );
      }
    } catch (err) {
      console.error(`Failed to fetch drivers:`, err);
    }
  };

  // Clear, then fetch/refetch drivers on form change
  React.useEffect(() => {
    setDriverIds([]);
    setDrivers([]);
    setFilteredDrivers([]);
    setSearch(``);
    handleFetchDrivers();
  }, [formData?.date, formData?.vehicleId, formData?.vehicleDriverId]);

  // Set the initial driver IDs
  React.useEffect(() => {
    if (initialLoad && initialFormData?.driverIds?.length && drivers?.length) {
      setDriverIds(initialFormData?.driverIds);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData, drivers]);

  // Use search input to filter drivers
  React.useEffect(() => {
    setFilteredDrivers(() => searchDrivers(drivers, search));
  }, [search]);

  return (
    <div className={cls.root}>
      <label className={cls.label}>
        Which drivers would you like to assign to this group?<span className={cls.required}> *</span>
      </label>

      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Search for drivers...'
        value={search}
        onChange={e => setSearch(e?.target?.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon color='disabled' fontSize='small'>
                search
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <Spacer size='sm' />

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {!filteredDrivers?.length ? (
              <Typography className={cls.emptyTxt}>No drivers found from search criteria</Typography>
            ) : null}
            {filteredDrivers?.map(driver => (
              <div key={driver?.id} className={cls.item} onClick={() => handleDriverClick(driver?.id)}>
                <DriverAvatar driver={driver} />

                <div>
                  <Typography className={cls.itemTxt1}>
                    {driver?.user?.display_name} ({driver?.id})
                  </Typography>
                  <Typography className={cls.itemTxt2}>{driver?.region?.name}</Typography>
                </div>

                {driverIds?.includes(driver?.id) ? (
                  <div className={cls.selected}>
                    <Icon className={cls.selectedIcon}>check</Icon>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: `relative`,
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  required: {
    color: theme.palette.error.main,
  },

  container: {
    position: `relative`,
    overflowY: `scroll`,
    width: `100%`,
    minHeight: `64px`,
    maxHeight: `384px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `0 1 287px`,
    height: `fit-content`,
    padding: theme.spacing(0.5, 2, 0.5, 0.5),
    borderRadius: 48,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },

  emptyTxt: {
    fontSize: 18,
    color: theme.palette.text.disabled,
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `${theme.palette.secondary.main}80`,
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },
}));
