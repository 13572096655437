//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, TextField, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';

//////////////////////// COMPONENT ////////////////////////

export default function OverrideStatusModal({ open, onClose, attempt }) {
  const cls = useStyles();

  const [status, setStatus] = React.useState(``);

  const statusItems = [
    {
      label: `Accepted`,
      value: `accepted`,
    },
    {
      label: `Arrived`,
      value: `arrived`,
    },
    {
      label: `Picked Up`,
      value: `pickedUp`,
    },
    {
      label: `Dropped Off`,
      value: `droppedOff`,
    },
    {
      label: `Canceled`,
      value: `canceled`,
    },
    {
      label: `Failed`,
      value: `failed`,
    },
  ];

  // Handle modal saving the status to the move and attempt
  const handleSubmit = () => {
    handleClose({ status: status });
  };

  // Handle modal closing and optionally pass in an output
  const handleClose = (output = null) => {
    if (onClose) onClose(output, attempt);
    setStatus(``);
  };

  return (
    <Modal open={open} width='sm'>
      {open ? (
        <>
          <ModalHeader onClose={handleClose}>Override Status</ModalHeader>

          <ModalContent>
            <Typography className={cls.subtitleTxt}>
              Manually override the status for the current attempt as well as the related ride-move.
            </Typography>
            <Typography className={cls.subtitleTxt}>
              <b>
                NOTE - This action should only be used as a last resort! You may use this to force a ride to be
                considered 'successful'.
              </b>
            </Typography>

            <Spacer />

            <TextField
              fullWidth
              select
              label={`Selected Status`}
              placeholder='Select a status...'
              size='small'
              variant='outlined'
              value={status}
              onChange={e => setStatus(e.target.value)}
            >
              {statusItems.map(si => (
                <MenuItem key={`override-status-item-${si.value}`} value={si.value}>
                  {si.label}
                </MenuItem>
              ))}
            </TextField>
          </ModalContent>

          <ModalFooter>
            <ModalAction onClick={() => handleSubmit()} color='primary' disabled={!status}>
              Submit
            </ModalAction>
            <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
          </ModalFooter>
        </>
      ) : null}
    </Modal>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitleTxt: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}));
