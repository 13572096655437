import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Container } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import Toolbar from '../../components/Toolbar';
import ARReportsFilter from './ARReportsFilter';
import ARReportsWrapper from './ARReportsWrapper';

const log = false

const getDefaultRange = report => {
  const localRange = localStorage.getItem(`arreport-${report}-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = report => {
  const localRange = localStorage.getItem(`arreport-${report}-range`);
  const localStart = localStorage.getItem(`arreport-${report}-start`);

  if (localRange && localRange !== `custom`) {
    return dayjs.utc(dayjs().startOf('day').subtract(1, localRange)).format();
  } else if (localRange === `custom` && localStart) return localStart;
  else return dayjs.utc(dayjs().startOf('day').subtract(1, `week`)).format();
};

const getDefaultEnd = report => {
  const localRange = localStorage.getItem(`arreport-${report}-range`);
  const localEnd = localStorage.getItem(`arreport-${report}-end`);

  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().endOf('day')).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return dayjs.utc(dayjs().endOf('day')).format();
};

const getDefaultDisable = report => {
  const localRange = localStorage.getItem(`arreport-${report}-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};

export default function ARReports(props) {
  const cls = useStyles();
  const { capFirst } = useTools();
  
  const getDefaultSelectedCustomerIds = () => {
    const localCustomerIdArrayString = localStorage.getItem(`selectedCustomersArray`);
    const localCustomerIdArray = localCustomerIdArrayString ? localCustomerIdArrayString.split(',') : null;
    const intParsedLocalCustomerIdArray = Array.isArray(localCustomerIdArray)
      ? localCustomerIdArray.map(customerId => parseInt(customerId))
      : [];
    if (intParsedLocalCustomerIdArray && intParsedLocalCustomerIdArray.length > 0) return intParsedLocalCustomerIdArray;
    return [];
  };

  const report = props.match.params.id;

  // STATES /////////////////////////////////////////////////////////////////
  const [status, setStatus] = React.useState(null);
  const [customerIdArray, setCustomerIdArray] = React.useState(getDefaultSelectedCustomerIds());
  const [range, setRange] = React.useState(getDefaultRange(report));
  const [start, setStart] = React.useState(getDefaultStart(report));
  const [end, setEnd] = React.useState(getDefaultEnd(report));
  const [disablePickers, setDisablePickers] = React.useState(getDefaultDisable(report));

  React.useEffect(() => {
    log && console.log("selectedCustomerIdArray", customerIdArray)
    localStorage.setItem(`selectedCustomersArray`, customerIdArray);
  }, [customerIdArray]);

  const handleRangeChange = value => {
    localStorage.setItem(`arreport-${report}-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf('day').subtract(1, value)).format());
      setEnd(dayjs.utc(dayjs().endOf('day')).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart(report));
      setEnd(getDefaultEnd(report));
    }
  };

  const handleDateChange = (value, name) => {
    console.log('NAME', name);
    if (name === `start`) {
      const newDate = dayjs.utc(value).startOf(`day`).format();
      localStorage.setItem(`arreport-${report}-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(value).endOf(`day`).format();
      console.log(value, newDate);

      localStorage.setItem(`arreport-${report}-end`, newDate);
      setEnd(newDate);
    }
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        {(report === 'moves' || report === 'payments') && (
          <>
            <Toolbar back title={`AR Report (${capFirst(report)})`} />

            <div className={cls.break} />

            <ARReportsFilter
              report={report}
              status={status}
              selectedCustomerIdArray={customerIdArray}
              range={range}
              start={start}
              end={end}
              onStatusChange={setStatus}
              handleChangeCustomers={setCustomerIdArray}
              onRangeChange={handleRangeChange}
              onDateChange={handleDateChange}
              disablePickers={disablePickers}
            />

            <div className={cls.break} />

            <ARReportsWrapper report={report} status={status} customerIdArray={customerIdArray} start={start} end={end} />
          </>
        )}
      </Container>
    </div>
  );
}

//////////////// STYLE ////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
