import React, { useContext } from 'react';
// import { GlobalContext } from '../../global-context';

import { useTheme, makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheck, faMoneyCheckAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcDiscover, faCcAmex } from '@fortawesome/free-brands-svg-icons';

////////// COMPONENT //////////
export default function MethodCard(props) {
  //   const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const theme = useTheme();

  const { method } = props;

  // Function to dynamically build a new payment method card
  const createCard = item => {
    let type, cardType, num, exp, route, icon;

    if (item.type === `card`) {
      type = `Credit/Bank Card`;
      num = `${item.account_number}`.padStart(4, `0`);
      exp =
        `${item.expiration}`.padStart(4, `0`).substring(0, 2) +
        `/` +
        `${item.expiration}`.padStart(4, `0`).substring(2, 4);
      route = null;
      if (item.card_type === `visa`) {
        cardType = `Visa`;
        icon = <FontAwesomeIcon className={cls.icon} color='#0050a0' icon={faCcVisa} />;
      } else if (item.card_type === `master`) {
        cardType = `Mastercard`;
        icon = <FontAwesomeIcon className={cls.icon} color='#ff0000' icon={faCcMastercard} />;
      } else if (item.card_type === `discover`) {
        cardType = `Discover`;
        icon = <FontAwesomeIcon className={cls.icon} color='#ff7000' icon={faCcDiscover} />;
      } else if (item.card_type === `amex`) {
        cardType = `American Express`;
        icon = <FontAwesomeIcon className={cls.icon} color='#0080ff' icon={faCcAmex} />;
      } else {
        cardType = `Unknown Card`;
        icon = <FontAwesomeIcon className={cls.icon} color='#888' icon={faQuestionCircle} />;
      }
    } else if (item.type === `ach`) {
      type = `ACH Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FontAwesomeIcon className={cls.icon} color={theme.palette.text.primary} icon={faMoneyCheck} />;
    } else if (item.type === `echeck`) {
      type = `Electronic Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FontAwesomeIcon className={cls.icon} color={theme.palette.text.primary} icon={faMoneyCheckAlt} />;
    } else {
      type = `Unknown Type`;
      cardType = `N/A`;
      num = `N/A`;
      exp = `N/A`;
      route = `N/A`;
      icon = <FontAwesomeIcon className={cls.icon} color={theme.palette.text.secondary} icon={faQuestionCircle} />;
    }

    return (
      <>
        <div className={cls.payCard}>
          <Grid container spacing={0}>
          <Grid item xs={12}>
              {item.primary === true ? (
                <Typography
                  color='textSecondary'
                  style={{ width: '50%',textAlign: 'left', fontSize: '18px', fontWeight: 500 }}
                >
                  Primary
                </Typography>
              ) : (
                <div style={{ height: '27px' }}></div>
              )}
            </Grid>
            {item.type === `card` ? (
              <>
                <div style={{ display: 'inline-block' }}>{icon}</div>
                <div style={{ display: 'inline-block' }}>
                  <Typography className={cls.txtOver} variant='h6'>
                    {item.name !== `` || item.name !== null ? item.name : `Payment`}
                  </Typography>
                  <Typography className={cls.txtUnder} color='textSecondary'>
                    {cardType} ****{num}
                  </Typography>
                  <Typography className={cls.txtUnder} color='textSecondary'>
                    Expires: {exp}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'inline-block' }}>{icon}</div>
                <div style={{ display: 'inline-block' }}>
                  <Typography className={cls.txtOver} variant='h6'>
                    {item.name}
                  </Typography>
                  <Typography className={cls.txtUnder} color='textSecondary'>
                    {type} ****{num}
                  </Typography>
                  <Typography className={cls.txtUnder} color='textSecondary'>
                    Routing: {route}
                  </Typography>
                </div>
              </>
            )}
            <div style={{ width: '100%', marginBottom: '13px'}} />
          </Grid>
        </div>
      </>
    );
  };

  return <>{createCard(method)}</>;
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  payCard: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  icon: {
    fontSize: '64px',
    marginRight: theme.spacing(3),
  },
  button: {
    float: 'right',
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  txtOver: {
    fontSize: '18px',
  },
  txtUnder: {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));
