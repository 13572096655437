import { gql } from '@apollo/client';

const GET_DISPUTED_MOVES = gql`
  query get_disputed_moves {
    armoves(where: { disputed: { _eq: true }, move: { disputed: { _eq: true } } }, order_by: { id: desc }) {
      id
      status
      notes
      author
      billable_datetime
      due_amount
      paid_amount
      discount_amount
      discount_reason
      disputed
      dispute_reason
      move {
        id
        active
        class
        settled
        status
        reference_num
        vehicle_vin
        vehicle_year
        vehicle_make
        vehicle_model
        vehicle_color
        vehicle_odometer
        pickup_started
        pickup_arrived
        pickup_successful
        delivery_started
        delivery_arrived
        delivery_successful
        tags
        customer {
          id
          name
        }
        lane {
          id
          description
          distance_miles
          delivery {
            id
            name
            address
          }
          pickup {
            id
            name
            address
          }
        }
      }
      details {
        id
        name
        notes
        amount
      }
      revisions(order_by: { revision: desc }) {
        id
        revision
        due_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        details {
          id
          name
          notes
          amount
        }
      }
    }
  }
`;

const GET_MOVE_LOGS = gql`
  query get_move_logs($moveId: bigint!) {
    arevents(where: { move_id: { _eq: $moveId } }, order_by: { id: desc }) {
      id
      arevent_id
      move_id
      invoice_id
      message
      level
      createdat
    }
  }
`;

export { GET_DISPUTED_MOVES, GET_MOVE_LOGS };
