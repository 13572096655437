// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { useRegions } from './RegionsProvider';

const timezones = ['America/New_York', 'America/Chicago', 'America/Phoenix', 'America/Denver', 'America/Los_Angeles'];

// COMPONENT -------------------------------------------------- //

export default function RegionsAddEdit({ regions, refetch }) {
  const cls = useStyles();

  const { selectedRegionId, selectedRegionGeofence, unselectRegion, saveRegionAndGeofence } = useRegions();

  const [initialRegion, setInitialRegion] = React.useState({});

  const [name, setName] = React.useState(``);
  const [timezone, setTimezone] = React.useState(`none`);
  const [description, setDescription] = React.useState(``);
  const [email, setEmail] = React.useState(``);
  const [accountingClassNum, setAccountingClassNum] = React.useState(``);
  const [status, setStatus] = React.useState('upcoming');

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSavingDisabled, setIsSavingDisabled] = React.useState(true);

  // Set initial state when selected region changes
  React.useEffect(() => {
    if (selectedRegionId && regions && regions.length) {
      const foundRegion = regions.find(r => r.id === selectedRegionId);

      if (foundRegion) {
        setInitialRegion(foundRegion);

        setName(foundRegion.name);
        setTimezone(foundRegion.timezone);
        setDescription(foundRegion.description);
        setEmail(foundRegion.email);
        setAccountingClassNum(foundRegion.accounting_class_num);
        setStatus(foundRegion.status);
      }
    } else {
      setInitialRegion({});

      setName(``);
      setTimezone(`none`);
      setDescription(``);
      setEmail(``);
      setAccountingClassNum(``);
      setStatus('upcoming');
    }
  }, [selectedRegionId, regions]);

  // Set the save button to be disabled
  React.useEffect(() => {
    if (name.length >= 3 && timezone !== `none`) setIsSavingDisabled(false);
    else setIsSavingDisabled(true);
  }, [name, timezone, selectedRegionGeofence]);

  // Handle a text input change
  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  // Handle cancel of saving region
  const handleCancel = () => {
    unselectRegion();
  };

  // Handle saving the region
  const handleSaveRegion = async () => {
    const res = await saveRegionAndGeofence({
      ...initialRegion,
      name,
      timezone,
      description,
      email,
      accounting_class_num: accountingClassNum,
      points: selectedRegionGeofence,
      status: status,
    });
    if (res.success) {
      toast.success(`Successfully saved region & geofence!`);
      refetch();
    } else if (res.error) toast.error(res.error);

    setIsSaving(false);
  };

  return (
    <div className={cls.region}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography className={cls.regionHeadTxt}>
            {selectedRegionId ? `Editing` : `Add New`} Region
            {initialRegion && initialRegion.name ? ` - ${initialRegion.name} (${initialRegion.id})` : ``}
          </Typography>

          <Spacer size='xs' />

          <Typography className={cls.regionSubTxt}>
            {selectedRegionId
              ? `Edit the details of a region and/or redraw the region's geofence on the map.`
              : `To create a new region, fill in the form below and draw the region's geofence on the map.`}
          </Typography>
        </Grid>

        <Grid item>
          {!isSaving ? (
            <IconButton className={cls.iconBtn} disabled={isSaving} onClick={() => handleCancel()}>
              <Icon>close</Icon>
            </IconButton>
          ) : null}
        </Grid>
      </Grid>

      <Spacer size='lg' />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label='Name'
            placeholder='Enter the region name...'
            variant='outlined'
            size='small'
            value={name || ``}
            onChange={handleInputChange(setName)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            select
            fullWidth
            label='Timezone'
            placeholder='Select a timezone...'
            variant='outlined'
            size='small'
            value={timezone || ``}
            onChange={handleInputChange(setTimezone)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    access_time_filled
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`none`}>
              <em>Select a timezone</em>
            </MenuItem>
            {timezones.map((tz, i) => (
              <MenuItem key={`timezone-${i}`} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            label='Description'
            placeholder='(Optional) Enter the region description...'
            variant='outlined'
            size='small'
            value={description || ``}
            onChange={handleInputChange(setDescription)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    description
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Email'
            placeholder='(Optional) Enter the market manager email...'
            type='email'
            variant='outlined'
            size='small'
            value={email || ``}
            onChange={handleInputChange(setEmail)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Accounting Class Number'
            placeholder='(Optional) Enter the accounting class number...'
            variant='outlined'
            size='small'
            value={accountingClassNum || ``}
            onChange={handleInputChange(setAccountingClassNum)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    account_balance
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ color: 'red' }}>
            <TextField
              select
              value={status}
              fullWidth
              label='Status'
              placeholder='Select active status...'
              variant='outlined'
              size='small'
              onChange={handleInputChange(setStatus)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      car_crash
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem key='upcoming' value='upcoming'>
                Upcoming
              </MenuItem>
              <MenuItem key='live' value='live'>
                Live
              </MenuItem>
              <MenuItem key='deactivated' value='deactivated'>
                Deactivated
              </MenuItem>
            </TextField>

            <Spacer size='xs' />

          <Typography className={cls.regionStatusSubTxt}>
            <b>Upcoming:</b> Region is in the process of being established. <br />
            <b>Live:</b> Region is active and equipped to execute moves. <br />
            <b>Deactivated:</b> Region is no longer in service.
          </Typography>
        </Grid>
      </Grid>

      <Spacer size='lg' />

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Button fullWidth disabled={isSaving} onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Grid>

        <Grid item xs={7}>
          <Button
            fullWidth
            disabled={isSaving || isSavingDisabled}
            loading={isSaving}
            color='primary'
            onClick={() => {
              setIsSaving(true);
              handleSaveRegion();
            }}
          >
            Save Region & Geofence
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  region: {
    padding: theme.spacing(2),
  },
  regionHeadTxt: {
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 500,
  },
  regionSubTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  regionStatusSubTxt: {
    lineHeight: 1.333,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  iconBtn: {
    padding: theme.spacing(1),
    marginTop: -8,
  },
  line: {
    width: '100%',
    height: 1,
    background: theme.palette.dividerStrong,
  },
}));
