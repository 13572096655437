//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal } from '../ModalComponents';
import ProductFeeFormModal from './ProductFeeFormModal';

//////////////////////// COMPONENT ////////////////////////

// The Modal form inputs do not work correctly without this wrapper component

export default function ProductFeeFormModalWrapper({  
    open = false,
    editMode = false,
    rateRuleGroupId,
    productFee = null,
    onEditSave = unsavedProductFee => {},
    onAddSave,
    handleRemoveProduct = unsavedProductFee => {},
    onCancel = () => {},}) {

  return (
    <Modal open={open}>
        {open ? (<ProductFeeFormModal
            editMode = {editMode}
            rateRuleGroupId = {rateRuleGroupId}
            productFee = {productFee}
            onEditSave = {onEditSave}
            onAddSave = {onAddSave}
            onCancel = {onCancel}
            handleRemoveProduct = {handleRemoveProduct}
        />):null}
    </Modal>
  );
}
