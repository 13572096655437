import React from 'react';

import { makeStyles, TextField, Typography, InputAdornment, Icon, Checkbox } from '@material-ui/core';

import CustomerSelect from '../../components/CustomerSelect'
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';

////////// COMPONENT //////////
export default function MapIndex(props) {
  const cls = useStyles();

  const {
    locations,
    getLocationsError,
    setSidebarState,
    search,
    setSearch,
    customerId,
    setCustomerId,
    tags,
    setTags,
    setSelectedLocation,
  } = props;

  const handleLocationSearch = loc => {
    setSelectedLocation(loc);
    setSidebarState(`location`);
  };

  const handleTags = (name, str) => event => {
    if (str && str !== null) {
      let newTags = [...tags[name]];
      if (newTags.length > 0 && newTags.includes(str)) {
        let strIndex = newTags.indexOf(str);
        if (strIndex !== -1) {
          newTags.splice(strIndex, 1);
          setTags[name]([...newTags]);
        }
      } else {
        newTags.push(str);
        setTags[name]([...newTags]);
      }
    }
  };

  const LocationsList = () => {
    if (getLocationsError) {
      return <DefaultErrorFallback message='Error getting locations' />;
    } else if (!locations || locations.length < 1) {
      return <DefaultEmptyFallback message='No Locations Found' />;
    } else {
      return (
        <>
          <div className={cls.smallBreak} />
          <div className={cls.locations}>
            {locations.map((loc, i) => {
              return (
                <React.Fragment key={`map-index-row-${loc.id}`}>
                  <div
                    className={cls.location}
                    style={{ backgroundColor: i % 2 === 0 ? '#fff' : '#f4f4f4' }}
                    onClick={() => handleLocationSearch(loc)}
                  >
                    <Typography className={cls.locationNameTxt}>
                      {loc.nickname && loc.nickname !== `` ? loc.nickname : loc.name}
                    </Typography>
                    <Typography className={cls.locationAddressTxt}>
                      {loc.address ? loc.address : `No address provided`}
                    </Typography>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className={cls.smallBreak} />
        </>
      );
    }
  };

  return (
    <div className={cls.index}>
      <div className={cls.filter}>
        <div className={cls.smallBreak} />

        <TextField
          fullWidth
          label='Search'
          placeholder='Search for a location...'
          variant='outlined'
          margin='dense'
          value={search}
          onChange={event => (event.target.value ? setSearch(event.target.value) : setSearch(``))}
          className={cls.search}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />

        <div className={cls.bigBreak} />

        <CustomerSelect
          selectAllItem
          value={customerId || 0}
          onChange={event => (event.target.value ? setCustomerId(event.target.value) : setCustomerId(null))}
        />

        <div className={cls.break} />

        <div className={cls.types}>
          <Typography className={cls.filterHeadTxt}>Type Filters</Typography>
          <div className={cls.filterItem}>
            <Checkbox
              className={cls.filterItemCheck}
              color='secondary'
              checked={tags.type.includes(`customer`)}
              onChange={handleTags(`type`, `customer`)}
            />
            <Typography className={cls.filterItemTxt}>Customer</Typography>
          </div>
          <div className={cls.filterItem}>
            <Checkbox
              className={cls.filterItemCheck}
              color='secondary'
              checked={tags.type.includes(`consumer business`)}
              onChange={handleTags(`type`, `consumer business`)}
            />
            <Typography className={cls.filterItemTxt}>Consumer Business</Typography>
          </div>
          <div className={cls.filterItem}>
            <Checkbox
              className={cls.filterItemCheck}
              color='secondary'
              checked={tags.type.includes(`consumer residential`)}
              onChange={handleTags(`type`, `consumer residential`)}
            />
            <Typography className={cls.filterItemTxt}>Consumer Residential</Typography>
          </div>
        </div>
      </div>
      <LocationsList />
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  index: {
    position: 'relative',
    overflow: 'scroll',
  },
  filter: {
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  search: {
    margin: 0,
  },
  types: {
    display: 'block',
  },
  filterHeadTxt: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  filterItem: {
    display: 'block',
  },
  filterItemCheck: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: theme.spacing(0.5),
  },
  filterItemTxt: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingTop: 14,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  locations: {
    overflow: 'auto',
    height: 'auto',
  },
  location: {
    display: 'block',
    padding: theme.spacing(2),
    '&:hover': {
      filter: 'brightness(0.9)',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  locationNameTxt: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1.2,
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  locationAddressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));
