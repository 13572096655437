//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useDrivers } from './useDrivers';

import { GET_DRIVERS } from './gql';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';

import { DriversFilter, DriversTable } from './index';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function Drivers(props) {
  const cls = useStyles();

  // Call the useMoves hook to get additional logic
  const { getDefaultRegionId, getDefaultStatus, getDefaultTaxClass, generateCSV } = useDrivers();

  // Component state
  const [regionId, setRegionId] = React.useState(getDefaultRegionId());
  const [status, setStatus] = React.useState(getDefaultStatus());
  const [taxClass, setTaxClass] = React.useState(getDefaultTaxClass());
  const [search, setSearch] = React.useState(``);

  // Control region filter
  const handleRegionChange = (regionId = null) => {
    if (regionId || regionId === 0) {
      localStorage.setItem(`driver-index-region-id`, regionId);
      setRegionId(regionId);
    }
  };

  // Control status filter
  const handleStatusChange = (status = null) => {
    if (status) {
      localStorage.setItem(`driver-index-status`, status);
      setStatus(status);
    }
  };

  // Control tax class filter
  const handleTaxClassChange = (taxClass = null) => {
    if (taxClass) {
      localStorage.setItem(`driver-index-tax-class`, taxClass);
      setTaxClass(taxClass);
    }
  };

  // Clear all filters
  const handleClearFilters = () => {
    localStorage.setItem(`driver-index-region-id`, 0);
    localStorage.setItem(`driver-index-status`, `all`);
    localStorage.setItem(`driver-index-tax-class`, `both`);

    setRegionId(0);
    setStatus(`all`);
    setTaxClass(`both`);
    setSearch(``);
  };

  /** Combine the driver object and user object to make the data easier to use */
  const spreadDriverUser = (driver = null, index) => {
    if (driver) {
      let spreadDriver = {
        index: index,
        ...driver.user,
        ...driver,
      };
      delete spreadDriver.user;
      return spreadDriver;
    }
    return null;
  };

  /** Combine the driver object and user object to make the data easier to use */
  const spreadDriverUsers = (drivers = []) => {
    let driverUsers = drivers.map((d, i) => {
      return spreadDriverUser(d, i);
    });
    return driverUsers;
  };

  // Call the query (with options passed in) and get back the data + states
  const { loading, error, data, refetch } = useQuery(GET_DRIVERS(regionId), {
    variables: {
      regionId: regionId || null,
    },
  });

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Drivers' onClear={handleClearFilters}>
          <DriversFilter
            regionId={regionId}
            status={status}
            taxClass={taxClass}
            search={search}
            onRegionChange={handleRegionChange}
            onStatusChange={handleStatusChange}
            onTaxClassChange={handleTaxClassChange}
            onSearchChange={setSearch}
          />
        </Toolbar>
        <Loading fixed />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching drivers:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Drivers' onClear={handleClearFilters} refetch={handleRefetch}>
          <DriversFilter
            regionId={regionId}
            status={status}
            taxClass={taxClass}
            search={search}
            onRegionChange={handleRegionChange}
            onStatusChange={handleStatusChange}
            onTaxClassChange={handleTaxClassChange}
            onSearchChange={setSearch}
          />
        </Toolbar>
        <DefaultErrorFallback message='ERROR FETCHING DRIVERS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.drivers || !data.drivers.length > 0) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Drivers' onClear={handleClearFilters} refetch={handleRefetch}>
          <DriversFilter
            regionId={regionId}
            status={status}
            taxClass={taxClass}
            search={search}
            onRegionChange={handleRegionChange}
            onStatusChange={handleStatusChange}
            onTaxClassChange={handleTaxClassChange}
            onSearchChange={setSearch}
          />
        </Toolbar>
        <DefaultEmptyFallback message='NO DRIVERS FOUND WITH SELECTED FILTERS' />
      </div>
    );
  }

  // DATA STATE //
  const driversDB = data.drivers;
  const drivers = spreadDriverUsers(driversDB);
  log && console.log(`Drivers:`, drivers);

  const tableActions = [
    {
      label: `Generate CSV`,
      handler: () => generateCSV(drivers),
      disable: drivers.length <= 0,
    },
  ];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Drivers' onClear={handleClearFilters} refetch={handleRefetch} actions={tableActions}>
        <DriversFilter
          regionId={regionId}
          status={status}
          taxClass={taxClass}
          search={search}
          onRegionChange={handleRegionChange}
          onStatusChange={handleStatusChange}
          onTaxClassChange={handleTaxClassChange}
          onSearchChange={setSearch}
          drivers={drivers}
          refetch={handleRefetch}
        />
      </Toolbar>
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING DRIVERS TABLE' />}>
        <DriversTable drivers={drivers} status={status} taxClass={taxClass} search={search} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));
