import React from 'react';
import { Modal } from '../../components/ModalComponents';

import EditLaneModalContent from './EditLaneModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function EditLaneModal({ open, close, move }) {
  const handleClose = (output = null) => {
    if (close) close();
  };

  return <Modal open={open}>{open ? <EditLaneModalContent onClose={handleClose} move={move}/> : null}</Modal>;
}