import React from 'react';
import dayjs from 'dayjs';
import { GET_PAYOUTS } from '@hopdrive/sdk/lib/modules/wallet';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';

import useDriverPayouts from './useDriverPayouts';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import DriverPayoutsFilter1 from './DriverPayoutsFilter1';
import DriverPayoutsFilter2 from './DriverPayoutsFilter2';
import DriverPayoutsTable from './DriverPayoutsTable';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayouts(props) {
  const cls = useStyles();

  const {
    getDefaultRange,
    getDefaultStart,
    getDefaultEnd,
    getDefaultRegionId,
    getDefaultDriverId,
    getDefaultStatus,
    generateCSV,
  } = useDriverPayouts();

  const [range, setRange] = React.useState(getDefaultRange());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());
  const [regionId, setRegionId] = React.useState(getDefaultRegionId());
  const [driverId, setDriverId] = React.useState(getDefaultDriverId());
  const [status, setStatus] = React.useState(getDefaultStatus());
  const [search, setSearch] = React.useState(``);

  // Control range picker
  const handleRangeChange = value => {
    if (value) {
      localStorage.setItem(`driver-payouts-range`, value);
      setRange(value);
    }
  };

  // Control start date
  const handleStartChange = value => {
    if (value) {
      localStorage.setItem(`driver-payouts-start`, value);
      setStart(value);
    }
  };

  // Control end date
  const handleEndChange = value => {
    if (value) {
      localStorage.setItem(`driver-payouts-end`, value);
      setEnd(value);
    }
  };

  // Control region filter
  const handleRegionIdChange = (value = null) => {
    if (value || value === 0) {
      localStorage.setItem(`region-payouts-region-id`, value);
      setRegionId(value);
    }
  };

  // Control driver filter
  const handleDriverIdChange = (value = null) => {
    if (value || value === 0) {
      localStorage.setItem(`driver-payouts-driver-id`, value);
      setDriverId(value);
    }
  };

  // Control status filter
  const handleStatusChange = (value = null) => {
    if (value || value === 0) {
      localStorage.setItem(`driver-payouts-status`, value);
      setStatus(value);
    }
  };

  // Control search input
  const handleSearchChange = value => {
    setSearch(value);
  };

  // Handle clearing the filters stored in state
  const handleClearFilters = () => {
    localStorage.removeItem(`driver-payouts-region-id`);
    localStorage.removeItem(`driver-payouts-driver-id`);
    localStorage.removeItem(`driver-payouts-status`);
    localStorage.removeItem(`driver-payouts-range`);
    localStorage.removeItem(`driver-payouts-start`);
    localStorage.removeItem(`driver-payouts-end`);

    setRegionId(0);
    setDriverId(0);
    setStatus(`all`);
    setSearch(``);
    setRange(`week`);
    setStart(dayjs().startOf(`week`).format());
    setEnd(dayjs().endOf(`week`).format());
  };

  // Call the query (with options passed in) and get back the data + states
  const { loading, error, data, refetch } = useQuery(GET_PAYOUTS, {
    variables: {
      start: start,
      end: end,
    },
    fetchPolicy: `network-only`,
  });

  // Handle refetch callback to pass as props
  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Payout History' onClear={handleClearFilters}>
          <DriverPayoutsFilter1
            range={range}
            start={start}
            end={end}
            onRangeChange={handleRangeChange}
            onStartChange={handleStartChange}
            onEndChange={handleEndChange}
          />
        </Toolbar>
        <Loading fixed />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching driver payouts report:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Payout History' refetch={handleRefetch} onClear={handleClearFilters}>
          <DriverPayoutsFilter1
            range={range}
            start={start}
            end={end}
            onRangeChange={handleRangeChange}
            onStartChange={handleStartChange}
            onEndChange={handleEndChange}
          />
        </Toolbar>
        <DefaultErrorFallback message='ERROR FETCHING DRIVER PAYOUTS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.driverpayouts || !data.driverpayouts.length > 0) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Payout History' refetch={handleRefetch} onClear={handleClearFilters}>
          <DriverPayoutsFilter1
            range={range}
            start={start}
            end={end}
            onRangeChange={handleRangeChange}
            onStartChange={handleStartChange}
            onEndChange={handleEndChange}
          />
        </Toolbar>
        <DefaultEmptyFallback message='NO DRIVER PAYOUTS FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const driverpayouts = data.driverpayouts;
  log && console.log(`Driver Payouts:`, driverpayouts);

  const tableActions = [
    {
      label: `Generate CSV`,
      handler: () => generateCSV(driverpayouts, start, end),
      disable: driverpayouts.length <= 0,
    },
  ];

  return (
    <div className={cls.root}>
      <Toolbar
        fullscreen
        title='Payout History'
        refetch={handleRefetch}
        onClear={handleClearFilters}
        actions={tableActions}
      >
        <DriverPayoutsFilter1
          range={range}
          start={start}
          end={end}
          onRangeChange={handleRangeChange}
          onStartChange={handleStartChange}
          onEndChange={handleEndChange}
        />
      </Toolbar>
      <DriverPayoutsFilter2
        regionId={regionId}
        driverId={driverId}
        status={status}
        search={search}
        onRegionIdChange={handleRegionIdChange}
        onDriverIdChange={handleDriverIdChange}
        onStatusChange={handleStatusChange}
        onSearchChange={handleSearchChange}
      />
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING DRIVER PAYOUTS REPORT' />}>
        <DriverPayoutsTable
          driverpayouts={driverpayouts}
          regionId={regionId}
          driverId={driverId}
          status={status}
          search={search}
          refetch={handleRefetch}
        />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));
