// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';

import { getCurrentPosition, getCurrentTime } from '../../utils/TimelineUtils';

// -------------------------------- CONTEXT -------------------------------- //

const TimelineContext = React.createContext({});

// -------------------------------- PROVIDER -------------------------------- //

function TimelineProvider({
  children,
  data,
  dataType,
  date,
  earliestTime,
  header,
  headerSize,
  intervalCallback,
  itemOffset,
  latestTime,
  markers,
  multiplier,
  refreshMs,
  rowSize,
  scrollKey,
  showMarkers,
  sidebarSize,
  timezoneOverride,
  zoom,
}) {
  // REFS //
  const timelineRef = React.useRef(null);

  // STATE //
  const [currentPosition, setCurrentPosition] = React.useState(
    getCurrentPosition(date, zoom, multiplier, timezoneOverride)
  );
  const [currentTime, setCurrentTime] = React.useState(getCurrentTime(timezoneOverride));
  const [isCurrentDay, setIsCurrentDay] = React.useState(true);
  const [isHovering, setIsHovering] = React.useState(false);
  const [markerHoverTime, setMarkerHoverTime] = React.useState(null);
  const [markerHoverPosition, setMarkerHoverPosition] = React.useState(0);

  const context = {
    // PROPS //

    data,
    dataType,
    date,
    earliestTime,
    header,
    headerSize,
    intervalCallback,
    itemOffset,
    latestTime,
    markers,
    multiplier,
    refreshMs,
    rowSize,
    scrollKey,
    showMarkers,
    sidebarSize,
    timezoneOverride,
    zoom,

    // REFS //

    timelineRef,

    // STATE //

    currentPosition,
    currentTime,
    isCurrentDay,
    isHovering,
    markerHoverTime,
    markerHoverPosition,

    // STATE HANDLERS //

    setCurrentPosition,
    setCurrentTime,
    setIsCurrentDay,
    setIsHovering,
    setMarkerHoverTime,
    setMarkerHoverPosition,
  };

  return <TimelineContext.Provider value={context}>{children}</TimelineContext.Provider>;
}

// -------------------------------- HOOK -------------------------------- //

const useTimeline = () => React.useContext(TimelineContext);

// -------------------------------- EXPORT -------------------------------- //

export { useTimeline, TimelineProvider };
