//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useMutation } from '@apollo/client';
import { makeStyles, Grid, Typography, Icon, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import sdk from '@hopdrive/sdk';
import { UPDATE_LOCATION_DETAILS } from './gql';
import RegionSelect from '../../components/RegionSelect';
import { toast } from 'react-toastify';
import { getUserName } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

function LocationEdit({ location = {} }) {
  const cls = useStyles();

  const [updateLocationDetails] = useMutation(UPDATE_LOCATION_DETAILS);

  const [type, setType] = React.useState(location.type || ``);
  const [regionId, setRegionId] = React.useState(location.region && location.region.id ? location.region.id : ``);
  const [name, setName] = React.useState(location.name || ``);
  const [address, setAddress] = React.useState(location.address || ``);
  const [latitude, setLatitude] = React.useState(location.latitude || ``);
  const [longitude, setLongitude] = React.useState(location.longitude || ``);
  const [timezone, setTimezone] = React.useState(location.timezone || ``);
  const [nickname, setNickname] = React.useState(location.nickname || ``);
  const [email, setEmail] = React.useState(location.email || ``);
  const [phone, setPhone] = React.useState(location.phone || ``);
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    const getDisabledButton = () => {
      const currentRegionId = location.region && location.region.id ? location.region.id : null;
      if (type && location.type !== type) setDisabled(false);
      else if ((regionId && currentRegionId !== regionId) || (regionId && location.region === null)) setDisabled(false);
      else if (name && location.name !== name) setDisabled(false);
      else if (address && location.address !== address) setDisabled(false);
      else if (latitude && location.latitude !== parseFloat(latitude)) setDisabled(false);
      else if (longitude && location.longitude !== parseFloat(longitude)) setDisabled(false);
      else if (timezone && location.timezone !== timezone) setDisabled(false);
      else if (location.nickname !== null && nickname === ``) setDisabled(false);
      else if (location.email !== null && email === ``) setDisabled(false);
      else if (location.phone !== null && phone === ``) setDisabled(false);
      else if (nickname && nickname !== location.nickname) setDisabled(false);
      else if (email && email !== location.email && email !== ``) setDisabled(false);
      else if (phone && phone !== location.phone && phone !== ``) setDisabled(false);
      else setDisabled(true);
    };
    getDisabledButton();
  }, [location, type, regionId, name, address, latitude, longitude, timezone, nickname, email, phone]);

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  const updateLocation = async () => {
    if (!disabled) {
      try {
        const updatedBy = await getUserName();
        const variables = {
          id: location.id,
          type: type || null,
          regionId: regionId || null,
          name: name || null,
          address: address || null,
          latitude: parseFloat(latitude) || null,
          longitude: parseFloat(longitude) || null,
          timezone: timezone || null,
          nickname: nickname || null,
          email: email || null,
          phone: phone || null,
          updatedBy: updatedBy || null,
        };
        const res = await updateLocationDetails({ variables });
        if (res && res.data && res.data.update_locations && res.data.update_locations.affected_rows > 0) {
          toast.success(`Successfully updated location!`);
          setDisabled(true);
        }
      } catch (err) {
        toast.error(`Failed to update location!`);
        console.error(`Failed to update location:`, err);
      }
    }
  };

  const getRegionId = async () => {
    var newRegionId;
    // Used to get region_id from Latitude & Longitude
    let regionRes = await sdk.regions.getByCoords([location.longitude, location.latitude]);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      newRegionId = regionRes.data[0].id;
      handleInputChange(setRegionId(newRegionId));
      if (newRegionId !== regionId) {
        toast.info(`New Region Id: ${newRegionId}. Click "Save Location" for update to take effect`);
      } else {
        toast.info(`Region Already Up-To-Date`);
      }
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      toast.error(`This location is not within a valid region. Please contact a market manager.`);
      newRegionId = null;
    }
    return newRegionId;
  };

  return (
    <div className={cls.paper}>
      <Typography className={cls.title}>Required Fields</Typography>

      <div className={cls.break_md} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            required
            fullWidth
            label='Type'
            placeholder='Specify a location type...'
            value={type}
            onChange={handleInputChange(setType)}
            size='small'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label_important
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`customer`}>Standard</MenuItem>
            <MenuItem value={`consumer business`}>Consumer Business</MenuItem>
            <MenuItem value={`consumer residential`}>Consumer Residential</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <RegionSelect
            required
            allowAll
            label='Region'
            value={regionId || 0}
            onChange={handleInputChange(setRegionId)}
          />
        </Grid>
      </Grid>

      <div className={cls.break_md} />

      <TextField
        required
        fullWidth
        label='Name'
        placeholder='Enter a name...'
        value={name}
        onChange={handleInputChange(setName)}
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                local_offer
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_md} />

      <TextField
        disabled
        required
        fullWidth
        label='Address'
        placeholder='Enter an address...'
        value={address}
        onChange={handleInputChange(setAddress)}
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                room
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_md} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label='Latitude'
            placeholder='Enter a latitude...'
            value={latitude}
            onChange={handleInputChange(setLatitude)}
            size='small'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    language
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label='Longitude'
            placeholder='Enter a longitude...'
            value={longitude}
            onChange={handleInputChange(setLongitude)}
            size='small'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    language
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <div className={cls.break_md} />

      <TextField
        required
        fullWidth
        label='Timezone'
        placeholder='Enter a timezone code for the location...'
        value={timezone}
        onChange={handleInputChange(setTimezone)}
        size='small'
        variant='outlined'
        className={cls.input}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                access_time_filled
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_lg} />

      <Typography className={cls.title}>Optional Fields</Typography>

      <div className={cls.break_md} />

      <TextField
        fullWidth
        label='Nickname'
        placeholder='(Optional) Enter a nickname...'
        value={nickname}
        onChange={handleInputChange(setNickname)}
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                loyalty
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_md} />

      <TextField
        fullWidth
        type='email'
        label='Email'
        placeholder='(Optional) Enter a related email...'
        value={email}
        onChange={handleInputChange(setEmail)}
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                email
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_md} />

      <TextField
        fullWidth
        type='phone'
        label='Phone'
        placeholder='(Optional) Enter a related phone number...'
        value={phone}
        onChange={handleInputChange(setPhone)}
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                phone_iphone
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <div className={cls.break_md} />

      <div className={cls.actions}>
        <Button style={{ marginRight: '10px' }} variant='outlined' color='error' onClick={() => getRegionId()}>
          Refresh Region
        </Button>
        <Button disabled={disabled} color='primary' onClick={() => updateLocation()}>
          Save Location
        </Button>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  title: {
    fontSize: 21,
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  break_md: {
    width: '100%',
    height: theme.spacing(2),
  },
  break_lg: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default LocationEdit;
