import { gql } from '@apollo/client';

const GET_LOCATION_DETAILS = gql`
  subscription get_location_details($locationId: bigint!) {
    locations(where: { id: { _eq: $locationId }, active: { _eq: 1 } }) {
      id
      active
      address
      email
      favorite
      latitude
      longitude
      name
      nickname
      notes
      notes_updated_at
      notes_updated_by
      phone
      place_id
      timezone
      type
      updated_by
      updatedat
      customer {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

const UPDATE_LOCATION_DETAILS = gql`
  mutation update_location_details(
    $id: bigint!
    $type: String!
    $regionId: bigint
    $name: String!
    $address: String!
    $latitude: numeric!
    $longitude: numeric!
    $timezone: String!
    $nickname: String
    $email: String
    $phone: String
    $updatedBy: String
  ) {
    update_locations(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        region_id: $regionId
        name: $name
        address: $address
        latitude: $latitude
        longitude: $longitude
        timezone: $timezone
        nickname: $nickname
        email: $email
        phone: $phone
        updatedat: "now()"
        updated_by: $updatedBy
      }
    ) {
      affected_rows
      returning {
        id
        address
        email
        latitude
        longitude
        name
        nickname
        phone
        place_id
        region_id
        timezone
        type
      }
    }
  }
`;

const UPDATE_LOCATION_DETAILS_NOTES = gql`
  mutation update_location_details_notes($locationId: bigint!, $notes: String, $updatedBy: String) {
    update_locations(
      where: { id: { _eq: $locationId } }
      _set: {
        notes: $notes
        updatedat: "now()"
        updated_by: $updatedBy
        notes_updated_at: "now()"
        notes_updated_by: $updatedBy
      }
    ) {
      affected_rows
      returning {
        id
        notes
      }
    }
  }
`;

const DELETE_LOCATION_DETAILS = gql`
  mutation delete_location_details($locationId: bigint!) {
    update_locations(where: { id: { _eq: $locationId } }, _set: { active: 0 }) {
      affected_rows
      returning {
        id
        active
      }
    }
  }
`;

export { GET_LOCATION_DETAILS, UPDATE_LOCATION_DETAILS, UPDATE_LOCATION_DETAILS_NOTES, DELETE_LOCATION_DETAILS };
