import axios from 'axios';
import { gql } from 'graphql-tag';
import { useData } from '../../../providers/DataProvider';
import { useSettings } from '../providers/SettingsProvider';
import { useEnrichedPlans } from './useEnrichedPlans';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { toast } from 'react-toastify';

//////////////////////// COMPONENT ////////////////////////

export function useRides() {
  const ctx = useData();
  const { enableRideLogs } = useSettings();
  const { buildEnrichedMove, calcAccurateDuration, findStartOfBlock } = useEnrichedPlans();

  const { log } = new SimpleLogger({ prefix: 'useRides', enabled: enableRideLogs });

  //Creates a placeholder enrichedMove object that holds the place that a ride move will occupy
  //Similar structure to enriched move, with additional flags for ride build status
  //getLaneGoogleData is a callback that will set off the first step of building the ride's lane
  const buildEnrichedRidePlaceholder = (parentEnrichedMove, rideType, laneType, chargeable) => {
    // Build the base move to start with then we will layer on the ride specific props
    let enrichedMove = buildEnrichedMove();
    let parentCustomerId = getPropValue(parentEnrichedMove, 'move.customer_id');
    Object.assign(enrichedMove.overrides, {
      customer_id: parentCustomerId,
      chargeable: chargeable, 
      move_type: 'ride',
      ride_type: rideType,
      lyft_trigger_id: parentEnrichedMove.withOverrides ? parentEnrichedMove.withOverrides.id : null,
      status: null,
      driver_app_version: parentEnrichedMove.withOverrides ? parentEnrichedMove.withOverrides.driver_app_version : null,
      driver_id: parentEnrichedMove.withOverrides ? parentEnrichedMove.withOverrides.driver_id : null,
      driver_name: parentEnrichedMove.withOverrides ? parentEnrichedMove.withOverrides.driver_name : null,
    });

    let placeHolder = {
      //Set the parent move id field (tracks which drive move will be assigned this moves return_ride_id)
      parentMoveId: parentEnrichedMove.id || null,

      //The next 6 flags track the 3 steps of the ride's lane creation
      //Once all 3 steps are completed, the placeholder will become a ride suggestion

      // possible values: 'new' || 'running' || 'successful' || 'failure'
      googleEnrichmentStatus: 'new',

      // possible values: 'new' || 'running' || 'successful' || 'failure'
      apEnrichmentStatus: 'new',

      // possible values: 'new' || 'running' || 'successful' || 'failure'
      arEnrichmentStatus: 'new',

      // Becuse we are automatically creating this ride suggestion, we need to mark
      // it as such for the time being. When the user goes into the ride modal and
      // actually adds this as a ride in the driver's schedule, then at that point
      // this isPlaceholder flag will set to false. The save all process will only
      // persist enrichedMoves where the isPlaceholder flag is false.
      isPlaceholder: true,

      // TODO: we could optimize this by having it select parked car or return ride instead in
      // certain conditions
      // possible values: 'next stop' || 'prev stop' || 'return ride' || 'parked car' || 'custom'
      rideLaneType: laneType,
    };

    // Merge our placeholder properties with the generated enrichedMove
    try {
      Object.assign(enrichedMove, placeHolder);
    } catch (error) {
      log(`  🌱 ${error.message} occurred when assigning placeholder props to the enrichedMove shell`);
    }

    log(`  🌱 Built Enriched Ride Placeholder for drive move: ${parentEnrichedMove.id}: `, enrichedMove);
    return enrichedMove;
  };

  //Create Ride Placeholder and Splice after parent move
  const injectPlaceholderAfter = (parentEnrichedMove, parentMoveIndex, enrichedMoveArray, laneType) => {
    // We assume here that there will always be another move in the array (to the right). This
    // is only a safe assumption because we never call this function when that's not the case.
    // Beware if you change that assumption.
    const nextIndex = parentMoveIndex + 1;

    //TODO: Get default ride type from customer config
    let rideType = undefined;
    const enrichedPlaceholderMove = buildEnrichedRidePlaceholder(parentEnrichedMove, rideType, laneType); //Create 'enriched' version of ride

    // Sets parent enriched move's hasRideGap and hasRideAfter flags
    // Prevents re-looping on every run of refreshPlans
    parentEnrichedMove.enrichedRideAfter = enrichedPlaceholderMove;
    parentEnrichedMove.hasRideGapAfter = false;
    parentEnrichedMove.hasRideAfter = true;

    // Splice the new move into the enrichedMovesArray at the next position
    enrichedMoveArray.splice(nextIndex, 0, enrichedPlaceholderMove);
    return enrichedPlaceholderMove;
  };

  const handleEnrichedPlanRides = (enrichedPlan, onFoundLane, onNewLane, onInvalidRide, isInitialLoad = false) => {
    log('Handling Enriched Ride Lanes');
    if (!enrichedPlan || !enrichedPlan.enrichedMoves) return;
    const { enrichedMoves } = enrichedPlan;

    //Check if any rides marked as 'starting ride' are still at index 0. If it is not, set isStartingRide to false
    const startingRide = enrichedMoves.find(em => em.isStartingRide);
    if (startingRide) {
      enrichedMoves.forEach((em, i) => {
        if (em.isStartingRide && i !== 0) {
          em.isStartingRide = false;
        }
      });
    }

    //Filter enriched rides
    const enrichedRidesArray = enrichedMoves.filter(
      enrichedMove =>
        ((enrichedMove.move && enrichedMove.move.move_type === 'ride') ||
          (enrichedMove.overrides && enrichedMove.overrides.move_type === 'ride')) &&
        enrichedMove.isPlaceholder === false
    );

    //On the initial load this function will loop through the rides returned from the database and assign their rideLaneType
    //On each subsequent refresh it will check if the lane type matches the ride's current position and adjust the lane as needed
    if (isInitialLoad) {
      enrichedRidesArray.forEach(enrichedRide => {
        if (!enrichedRide.inProgress && !enrichedRide.isCompleted) {
          detectRidelaneType(enrichedRide, enrichedPlan, onFoundLane, onNewLane, onInvalidRide);
        }
      });
    } else {
      enrichedRidesArray.forEach(enrichedRide => {
        if (!enrichedRide.inProgress && !enrichedRide.isCompleted) {
          handleEnrichedRideLane(enrichedRide, enrichedPlan, onFoundLane, onNewLane, onInvalidRide);
        }
      });
    }
  };

  //Set new lanes for rides based on their current prior and next moves
  const handleEnrichedRideLane = (enrichedRide, enrichedPlan, onFoundLane, onNewLane, onInvalidRide) => {
    //Deconstruct enrichedRide
    const { rideLaneType, next, prior } = enrichedRide;
    const { enrichedMoves } = enrichedPlan;

    //Deconstruct ride locations
    const enrichedRideWithOverrides = enrichedRide.withOverrides;
    const ridePickupLoc = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.origin_location_id')
      : null;
    const rideDeliveryLoc = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.destination_location_id')
      : null;
    const laneDescription = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.description')
      : null;

    //deconstruct parked car
    const parkedCarLoc = enrichedPlan.plan && enrichedPlan.plan.parked_location;

    //deconstruct next move pickup
    const nextMoveWithOverrides = next.withOverrides;
    const nextMovePickup = nextMoveWithOverrides
      ? getPropValue(nextMoveWithOverrides, 'lane.origin_location_id')
      : null;

    //We need to detect if the 'next move' is actually the first move in the plan, and change the lane type if so
    const nextMoveIndex = enrichedMoves ? enrichedMoves.findIndex(em => em.id === nextMoveWithOverrides.id) : null;

    //deconstruct prior move delivery
    const priorMoveWithOverrides = prior.withOverrides;
    const priorMoveDelivery = priorMoveWithOverrides
      ? getPropValue(priorMoveWithOverrides, 'lane.destination_location_id')
      : null;

    //Get first location in the ride's block of moves
    const firstMoveInBlock = findStartOfBlock(enrichedRide, enrichedPlan.enrichedMoves);
    const firstMoveInBlockWithOverrides = firstMoveInBlock.withOverrides;
    const firstMovePickupLoc = firstMoveInBlockWithOverrides
      ? getPropValue(firstMoveInBlockWithOverrides, 'lane.origin_location_id')
      : null;

    //Get parent move id. If there is no parentMoveId then this is an unassigned before ride and we will use the next move's id
    const parentMoveId = enrichedRide.parentMoveId || nextMoveWithOverrides.id;

    //The conditions for needing a new ride are different for each type of ride
    //But in each case an existing ride location is compared against the location it should have according to its lane type
    let pickupLoc = ridePickupLoc;
    let deliveryLoc = rideDeliveryLoc;
    let isAfter = true;

    switch (rideLaneType) {
      case 'return ride':
        //NOTE: Commented this out because it was running immediately after rides created, making a return ride not before a buffer impossible
        //Check if ride should be changed to a 'next stop' lane
        //  Should be changed if lane would be the same for a next ride as for a return ride
        if (next && nextMoveIndex !== 0 && firstMovePickupLoc === nextMovePickup) {
          enrichedRide.rideLaneType = 'next stop';
        }
        //Check if lane should be recalced
        if (rideDeliveryLoc !== firstMovePickupLoc) {
          deliveryLoc = firstMovePickupLoc;
        }
        break;
      case 'next stop':
        //Check if lane should be changed to a 'return ride' lane
        //  needs to be changed if ride is last move in the plan
        //  if it is it's 'next' move will be the first move in the plan
        if (!next || nextMoveIndex === 0) {
          enrichedRide.rideLaneType = 'return ride';
          deliveryLoc = firstMovePickupLoc;
        }
        //check if ride should be recalced
        if (rideDeliveryLoc !== nextMovePickup) {
          deliveryLoc = nextMovePickup;
        }
        break;
      case 'prev stop':
        //check if ride should be recalced
        if (ridePickupLoc !== priorMoveDelivery) {
          pickupLoc = priorMoveDelivery;
          isAfter = false;
        }
        break;
      //TODO: need a new rideLaneType for a parked car before ride, as it will be calculated differently
      case 'parked car':
        if (rideDeliveryLoc !== parkedCarLoc) {
          deliveryLoc = parkedCarLoc;
        }
        break;
      default:
        log('Custom Lane- will not recalc');
    }
    //Check if pickup and delivery locations match- if they do, no lane is needed
    if (pickupLoc === deliveryLoc) {
      onInvalidRide && onInvalidRide(enrichedRide, parentMoveId, isAfter, false);
      log('Removing invalid ride');
    }
    //Check if pickup or delivery locations have changed and a new lane must be assigned
    else if (ridePickupLoc !== pickupLoc || rideDeliveryLoc !== deliveryLoc) {
      enrichRideLaneAsync(enrichedRide, pickupLoc, deliveryLoc, null, null, onFoundLane, onNewLane);
      log(
        `Changing ride lane for ${
          enrichedRideWithOverrides.id ? 'ride #' + enrichedRideWithOverrides.id : 'a new ride'
        } from ${laneDescription} to ${pickupLoc} to ${deliveryLoc}`
      );
    }
  };

  const detectRidelaneType = (enrichedRide, enrichedPlan) => {
    //Deconstruct enrichedRide
    const { next, prior, id } = enrichedRide;
    const { enrichedMoves } = enrichedPlan;

    //Deconstruct ride locations
    const enrichedRideWithOverrides = enrichedRide.withOverrides;
    const ridePickupLoc = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.origin_location_id')
      : null;
    const rideDeliveryLoc = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.destination_location_id')
      : null;
    const laneDescription = enrichedRideWithOverrides
      ? getPropValue(enrichedRideWithOverrides, 'lane.description')
      : null;

    //deconstruct parked car
    const parkedCarLoc = enrichedPlan.plan && enrichedPlan.plan.parked_location;

    //deconstruct next move pickup
    const nextMoveWithOverrides = next.withOverrides;
    const nextMovePickup = nextMoveWithOverrides
      ? getPropValue(nextMoveWithOverrides, 'lane.origin_location_id')
      : null;

    //We need to detect if the 'next move' is actually the first move in the plan, and change the lane type if so
    const nextMoveIndex = enrichedMoves ? enrichedMoves.findIndex(em => em.id === nextMoveWithOverrides.id) : null;

    //deconstruct prior move delivery
    const priorMoveWithOverrides = prior.withOverrides;
    const priorMoveDelivery = priorMoveWithOverrides
      ? getPropValue(priorMoveWithOverrides, 'lane.destination_location_id')
      : null;

    //Get first location in the ride's block of moves
    const firstMoveInBlock = findStartOfBlock(enrichedRide, enrichedPlan.enrichedMoves);
    const firstMoveInBlockWithOverrides = firstMoveInBlock.withOverrides;
    const firstMovePickupLoc = firstMoveInBlockWithOverrides
      ? getPropValue(firstMoveInBlockWithOverrides, 'lane.origin_location_id')
      : null;

    let isAfter = true;
    //Detect if ride is after or before parent (note: some before rides have no parent move)
    const parentMoveId = enrichedRide.move && enrichedRide.move.parentMove && enrichedRide.move.parent_move_id;
    isAfter = prior && prior.id === parentMoveId ? true : false;

    if (isAfter) {
      if (nextMoveIndex === 0 && firstMovePickupLoc === rideDeliveryLoc) {
        //The last move is a special case because it's 'next move' is actually the first move in the plan
        //  If it has a lane going from the end of the prior move to the beginning of the plan, this is a 'return ride'
        enrichedRide.rideLaneType = 'return ride';
        log(`set ride ${id} lane (${laneDescription}) to type: return ride`);
        return;
      } else if (nextMovePickup === rideDeliveryLoc) {
        enrichedRide.rideLaneType = 'next stop';
        log(`set ride ${id} lane (${laneDescription}) to type: next stop`);
        return;
      } else if (firstMovePickupLoc === rideDeliveryLoc) {
        enrichedRide.rideLaneType = 'return ride';
        log(`set ride ${id} lane (${laneDescription}) to type: return ride`);
        return;
      } else if (parkedCarLoc === rideDeliveryLoc) {
        enrichedRide.rideLaneType = 'parked car';
        log(`set ride ${id} lane (${laneDescription}) to type: parked car`);
        return;
      } else {
        enrichedRide.rideLaneType = 'custom';
        log(`set ride ${id} lane (${laneDescription}) to type: custom`);
        return;
      }
    } else {
      if (priorMoveDelivery === ridePickupLoc && !enrichedRide.isStartingRide) {
        enrichedRide.rideLaneType = 'prev stop';
        log(`set ride ${id} lane (${laneDescription}) to type: prev stop`);
        return;
      } else {
        enrichedRide.rideLaneType = 'custom';
        log(`set ride ${id} lane (${laneDescription}) to type: custom`);
      }
    }
  };

  const deleteInvalidPlaceholderRides = (enrichedMoveArray, callback) => {
    log('🕳️ Removing placeholder rides');
    if (!enrichedMoveArray || !enrichedMoveArray.length > 0) {
      log('Error: cannot delete placeholders; No enriched move array passed in');
      return;
    }

    //loop backward so slice does not affect index of moves in array
    for (var i = enrichedMoveArray.length; i >= 0; i--) {
      if (
        enrichedMoveArray[i] &&
        enrichedMoveArray[i].isPlaceholder &&
        enrichedMoveArray[i].googleEnrichmentStatus === 'successful' &&
        mismatchedRideLane(enrichedMoveArray[i], enrichedMoveArray[i + 1], enrichedMoveArray)
      ) {
        //splice placeholder ride out of array
        enrichedMoveArray.splice(i, 1);
        //applies a change to the enrichedRideArray as a result of this deletion
        callback && callback(enrichedMoveArray);
      }
    }
  };

  const mismatchedRideLane = (enrichedRide, nextEnrichedMove, enrichedMoves) => {
    //default to false
    let rideLaneIsMismatched = false;

    //Deconstruct enrichedRide
    const { rideLaneType } = enrichedRide;

    //Deconstruct ride locations
    const enrichedRideWithOverrides = enrichedRide.withOverrides;
    const {
      lane: { destination_location_id: rideDeliveryLoc },
    } = enrichedRideWithOverrides;

    if (!nextEnrichedMove) {
      //If there is no longer a next move, the placeholder needs to be deleted
      rideLaneIsMismatched = true;
    } else if (rideLaneType === 'next stop' && nextEnrichedMove.buffer) {
      //If there is a buffer, the placeholder should have a 'return ride' type lane
      rideLaneIsMismatched = true;
    } else if (rideLaneType === 'next stop') {
      //deconstruct next move pickup
      const nextMoveWithOverrides = nextEnrichedMove.withOverrides
        ? nextEnrichedMove.withOverrides
        : { lane: { origin_location_id: null } };

      const {
        lane: { origin_location_id: nextMovePickup },
      } = nextMoveWithOverrides;

      //A next stop ride lane is mismatched if it's delivery location does not match the pickup location of the next move
      rideLaneIsMismatched = rideDeliveryLoc !== nextMovePickup ? true : false;
    } else if (rideLaneType === 'return ride' && !nextEnrichedMove.buffer) {
      //If there is no buffer, the placeholder should have a 'next stop' type lane
      rideLaneIsMismatched = true;
    } else if (rideLaneType === 'return ride') {
      //Get first location in the ride's block of moves
      const firstMoveInBlock = findStartOfBlock(enrichedRide, enrichedMoves);
      const firstMoveInBlockWithOverrides = firstMoveInBlock.withOverrides;
      const {
        lane: { origin_location_id: firstMovePickupLoc },
      } = firstMoveInBlockWithOverrides;

      //A return ride lane is mismatched if the ride's delivery does not match the pickup location of the first drive in the block
      rideLaneIsMismatched = rideDeliveryLoc !== firstMovePickupLoc ? true : false;
    }
    return rideLaneIsMismatched;
  };

  //Injects the enriched ride placeholders into the enriched plan after every drive with 'hasRideGap = true'
  //Sets 'hasRideGap' to false after placeholder created
  //After this step we have a rough outline of the entire plan
  const injectPlaceholderRideMoves = (enrichedMoveArray, onFoundLane, onNewLane) => {
    log('💉 Injecting Placeholder Rides...');
    if (!enrichedMoveArray || !enrichedMoveArray.length > 0) {
      log('Error: cannot inject placeholders; No enriched move array passed in');
      return;
    }

    //loop through array and run injectPlaceholder function for every rideGap that returns true
    //The loop goes backwards so that adding the suggestions will not affect the index
    //of the drive we are placing the ride after
    for (var i = enrichedMoveArray.length - 1; i >= 0; i--) {
      if (
        enrichedMoveArray[i].hasRideGapAfter &&
        !enrichedMoveArray[i].hasRideAfter &&
        enrichedMoveArray[i].move.move_type === 'drive' &&
        !enrichedMoveArray[i].inProgress &&
        !enrichedMoveArray[i].isCompleted
      ) {
        //Note: we can use index here to get the next move because the last move will never have a ride gap and so will never enter this code block
        const parentEnrichedMove = enrichedMoveArray[i];
        const nextEnrichedMove = enrichedMoveArray[i + 1];
        let laneType = 'next stop';

        //If the next move has a buffer (time gap before it) then the ride created will need to be a 'next move' type
        if (nextEnrichedMove.buffer) {
          laneType = 'return ride';
        }
        const firstEnrichedMoveInBlock = findStartOfBlock(parentEnrichedMove, enrichedMoveArray);

        const nextLocation = getPropValue(nextEnrichedMove, 'move.lane.pickup');
        const firstLocationInBlock = getPropValue(firstEnrichedMoveInBlock, 'move.lane.pickup');

        const pickupLocation = getPropValue(parentEnrichedMove, 'move.lane.delivery');
        //If the move is a return ride type, we set it to a different destination
        const deliveryLocation = laneType === 'return ride' ? firstLocationInBlock : nextLocation;

        //No need for ride- the driver's already there
        if (pickupLocation.id === nextLocation.id) {
          parentEnrichedMove.hasRideGapAfter = false;
          nextEnrichedMove.hasRideGapBefore = false;
          continue;
        }

        //TODO: handle before ride placeholders (concierge moves with customerPickup?)
        const injectedPlaceholderMove = injectPlaceholderAfter(enrichedMoveArray[i], i, enrichedMoveArray, laneType);

        enrichRideLaneAsync(injectedPlaceholderMove, pickupLocation.id, deliveryLocation.id, pickupLocation.name, deliveryLocation.name, onFoundLane, onNewLane);
      }
    }
  };

  const enrichRideLaneAsync = async (
    injectedPlaceholderMove,
    pickupLocationId,
    deliveryLocationId,
    pickupLocationName,
    deliveryLocationName,
    onFoundLane,
    onNewLane
  ) => {
    let existingLane = await getExistingLane(pickupLocationId, deliveryLocationId);

    if (existingLane) {
      // If we found a lane, the enrichment flags are all set to successful because no enrichment is needed
      injectedPlaceholderMove.googleEnrichmentStatus = 'successful';
      injectedPlaceholderMove.apEnrichmentStatus = 'successful';
      injectedPlaceholderMove.arEnrichmentStatus = 'successful';

      //Set the lane into the overrides
      injectedPlaceholderMove.overrides = Object.assign(injectedPlaceholderMove.overrides, { lane: existingLane });

      //set the duration of the enrichedMove (can now be calculated since lane has been found)
      injectedPlaceholderMove.duration = calcAccurateDuration(injectedPlaceholderMove.overrides);

      onFoundLane && onFoundLane();
    } else {
      //When a new lane is built in plans it is set in session storage. If later that same lane is needed it can be found in session storage, eliminating the need to rebuild lanes already built once
      const laneIsInStorage = getLaneFromSessionStorage(injectedPlaceholderMove, pickupLocationId, deliveryLocationId);
      if (laneIsInStorage) {
        onFoundLane && onFoundLane();
      } else {
        // Go get the lane object created on the server then only continue once
        // it hase returned and been added onto the placeholder move
        const abbreviatedLane = await addNewLaneToPlaceholderAsync(
          injectedPlaceholderMove,
          pickupLocationId,
          deliveryLocationId,
          pickupLocationName,
          deliveryLocationName
        );
        if (onNewLane) onNewLane();

        //AP and AR can only be calculated if the distance data was returned from google
        if (abbreviatedLane) {
          // Now that we know we have a lane object with all the values that come
          // with it, we can calculate AR and AP
          //Run these async but together, in a Promise.all()
          await (async () => {
            addAPDataToRidePlaceholderAsync(injectedPlaceholderMove);
            addARDataToRidePlaceholderAsync(injectedPlaceholderMove);
          })();

          //Save new lane to local storage so we will not need to build it again if we need it later
          const newRideLane = getPropValue(injectedPlaceholderMove, 'overrides.lane');
          const currentBuiltLaneArr = sessionStorage.getItem('built_lanes_array');
          const newBuiltLaneArr = currentBuiltLaneArr ? JSON.parse(currentBuiltLaneArr) : [];
          newBuiltLaneArr.push(newRideLane);
          sessionStorage.setItem('built_lanes_array', JSON.stringify(newBuiltLaneArr));
          onNewLane()
        }
        enableRideLogs && console.log('[useRides] ⌛ Finished building ride lane', abbreviatedLane, injectedPlaceholderMove);
      }
    }
  };

  const getExistingLane = async (pickupId, deliveryId) => {
    log(`   🕵️‍♂️ Querying Ride Lane with location ids: ${pickupId}, ${deliveryId}...`);
    if (typeof pickupId !== 'number' || typeof deliveryId !== 'number') {
      log('   Error: cannot query lane, missing a location id');
      return null;
    } else {
      //TODO: use sdk.lanes.getLaneByLocationId
      const getLaneRes = await ctx.apolloClient.query({
        query: GET_LANE_BY_LOCATION_IDS,
        variables: { origin_location_id: pickupId, destination_location_id: deliveryId },
        // fetchPolicy: `network-only`,
      });
      //Make Safer
      const foundLane = getLaneRes.data && getLaneRes.data.lanes ? getLaneRes.data.lanes[0] : null;
      return foundLane;
    }
  };

  const getLaneFromSessionStorage = (injectedPlaceholderMove, pickupLocationId, deliveryLocationId) => {
    //Check if lane has previously been built and saved in local storage
    let builtLaneArr = sessionStorage.getItem('built_lanes_array');
    if (builtLaneArr) {
      log(' Setting ride lane to previously built lane...');
      builtLaneArr = JSON.parse(builtLaneArr);
      const foundBuiltLane = builtLaneArr.find(
        lane => lane.origin_location_id === pickupLocationId && lane.destination_location_id === deliveryLocationId
      );
      if (foundBuiltLane) {
        // If we found a lane, the enrichment flags are all set to successful because no enrichment is needed
        injectedPlaceholderMove.googleEnrichmentStatus = 'successful';
        injectedPlaceholderMove.apEnrichmentStatus = 'successful';
        injectedPlaceholderMove.arEnrichmentStatus = 'successful';

        //Set the lane into the overrides
        injectedPlaceholderMove.overrides = Object.assign(injectedPlaceholderMove.overrides, {
          lane: foundBuiltLane,
        });

        //set the duration of the enrichedMove (can now be calculated since lane has been found)
        injectedPlaceholderMove.duration = calcAccurateDuration(injectedPlaceholderMove.overrides);
        return true;
      } else {
        return false;
      }
    }
  };

  //Enrich Ride placeholder with lane duration, making it a Ride Suggestion
  const addNewLaneToPlaceholderAsync = async (injectedPlaceholderMove, pickupLocationId, deliveryLocationId, pickupLocationName, deliveryLocationName) => {
    injectedPlaceholderMove.googleEnrichmentStatus = 'running';
    const rideWithOverrides = injectedPlaceholderMove.withOverrides;

    // We assume here that abbreviated lane will come back with the appropriate
    // lane object structure the same as the database would have returned it for
    // a move already in the database. The netlify function will fake that out
    // for us before it returns.
    const abbreviatedLane = await getLaneDistanceData(
      rideWithOverrides.customer_id,
      pickupLocationId,
      deliveryLocationId
    );

    // If we were unsuccessful building a lane object, then we need to set the
    // status as such so downstream processes do not run for no reason.
    if (!abbreviatedLane) {
      // TODO: Should this default structure be here? It's a hardcoded copy of
      // our database schema basically which should be avoided. How else should
      // we handle a failed call to build the lane? How do we prevent null refs
      // on all downstream references?
      // 10/27/21 - added a duration value of 0 here to prevent errors when durations are calculated while placeholder lane is still building
      injectedPlaceholderMove.overrides = Object.assign(injectedPlaceholderMove.overrides, {
        lane: {
          duration_sec: 0,
          distance_miles: 0,
          pickup: {},
          delivery: {},
          origin_location_id: pickupLocationId,
          destination_location_id: deliveryLocationId,
        },
      });

      //If this part of the lane build fails, AR and AP cannot be calculated
      injectedPlaceholderMove.googleEnrichmentStatus = 'failure';
      injectedPlaceholderMove.apEnrichmentStatus = 'failure';
      injectedPlaceholderMove.arEnrichmentStatus = 'failure';
      toast.error('Ride Lane Failed to build');
      return null;
    } else {
      abbreviatedLane.pickup = {id: pickupLocationId, name: pickupLocationName}
      abbreviatedLane.delivery = {id: deliveryLocationId, name: deliveryLocationName}
      injectedPlaceholderMove.overrides = Object.assign(injectedPlaceholderMove.overrides, { lane: abbreviatedLane });
      //set the duration of the enrichedMove (can now be calculated since lane duration has been fetched)
      injectedPlaceholderMove.duration = calcAccurateDuration(injectedPlaceholderMove.overrides);
      injectedPlaceholderMove.googleEnrichmentStatus = 'successful';
      return abbreviatedLane;
    }
  };

  //Fetch Google Data
  const getLaneDistanceData = async (customerId, pickupLocationId, deliveryLocationId) => {
    try {
      // Go out to the netlify function here to build a lane object based on
      // the inputed pickupLocationId and deliveryLocationId
      log('⏳ Begin building ride lane');
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/buildAbbreviatedLane',
        data: {
          customer_id: customerId,
          pickup_id: pickupLocationId,
          delivery_id: deliveryLocationId,
        },
      });
      let newLane = response.data;
      log(' New abbreviated Lane: ', newLane);
      return newLane;
    } catch (error) {
      console.error('Lane Build Google Data Error: ', error);
      return null;
    }
  };

  //Enrich Ride Suggestion's lane with AP or AR data
  const addAPDataToRidePlaceholderAsync = async enrichedRidePlaceholder => {
    enrichedRidePlaceholder.apEnrichmentStatus = 'running';

    const enrichedRideLane = getPropValue(enrichedRidePlaceholder, 'overrides.lane');

    //If distance or duration are falsey, lane AR data cannot be calculated
    if (!enrichedRideLane.distance_miles || !enrichedRideLane.duration_sec) return enrichedRidePlaceholder;

    const apLaneData = await getLaneAPData(enrichedRideLane);

    //TODO: What indicates failure to calculate AP in the response from the netlify func?
    if (!apLaneData) {
      enrichedRidePlaceholder.overrides = Object.assign(enrichedRidePlaceholder.overrides, {
        lane: { pickup: {}, delivery: {} },
      });
      enrichedRidePlaceholder.apEnrichmentStatus = 'failure';
      toast.error('Ride Lane Failed to build');
      return;
    }

    const abbreviatedLane = { ...enrichedRideLane, ...apLaneData };

    enrichedRidePlaceholder.overrides = Object.assign(enrichedRidePlaceholder.overrides, { lane: abbreviatedLane });
    enrichedRidePlaceholder.apEnrichmentStatus = 'successful';
  };

  //Create Lane Stub with AP data
  const getLaneAPData = async laneWithDistanceData => {
    //Netlify Function to get lane AP data
    try {
      log('⏳ Adding AP data to Lane...');
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/enrichLaneAP',
        data: laneWithDistanceData,
      });
      let newLane = response.data;
      enableRideLogs && console.log('[useRides] Lane AP data: ', newLane);
      return newLane;
    } catch (error) {
      // A lane without AR data is still usable, becasuse our AP system has redundancies and doesn't rely on the lane alone
      return;
    }
  };

  //Enrich Ride Suggestion's lane with AP or AR data
  const addARDataToRidePlaceholderAsync = async enrichedRidePlaceholder => {
    enrichedRidePlaceholder.arEnrichmentStatus = 'running';

    const enrichedRideLane = getPropValue(enrichedRidePlaceholder, 'overrides.lane');

    //If distance or duration are falsey, lane AR data cannot be calculated
    if (!enrichedRideLane.distance_miles || !enrichedRideLane.duration_sec) return enrichedRidePlaceholder;
    const ARLaneData = await getLaneARData(enrichedRideLane);
    const abbreviatedLane = { ...enrichedRideLane, ...ARLaneData };

    // If we were unsuccessful building a lane object, then we need to set the
    // status as such so downstream processes do not run for no reason.
    if (!ARLaneData) {
      // If AR fails to update, the lane will still be usable to the scheduler.
      // How should we indicate this type of failure? What should be done before inserting lane to prevent bad data
      enrichedRidePlaceholder.arEnrichmentStatus = 'failure';
      toast.error('Ride Lane Failed to build');
      return;
    }

    enrichedRidePlaceholder.overrides = Object.assign(enrichedRidePlaceholder.overrides, { lane: abbreviatedLane });
    enrichedRidePlaceholder.arEnrichmentStatus = 'successful';
  };

  //Enrich Lane stub, finishing lane building process
  const getLaneARData = async laneWithDistanceData => {
    //Netlify Function to get lane AP data
    try {
      log('⏳ Adding AR data to Lane...');
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/enrichLaneAR',
        data: laneWithDistanceData,
      });
      let newLane = response.data;
      log(' New abbreviated Lane: ', newLane);
      return newLane;
    } catch (error) {
      // A lane without AR data is still usable, becasuse our AR system has redundancies and doesn't rely on the lane alone
      return;
    }
  };

  //Sometimes we need to build a ride where no placeholder has been built (e.g before first drive or after last drive)
  //In these cases we follow the same steps as suggesting placeholders, but without injecting them into the array
  const buildNewRide = (parentPlannableMove, pickup, delivery, rideType, laneType, callback) => {
    try {
      log('Building new ride');
      const { id: pickupId, name: pickupName } = pickup;
      const { id: deliveryId, name: deliveryName } = delivery;
      const newPlaceholderRide = buildEnrichedRidePlaceholder(parentPlannableMove, rideType);

      //onFoundlane and onNewLane are callbacks that rerun scheduler
      //TODO: feel like this is a bad way to use a callback- assumes its parameters
      enrichRideLaneAsync(
        newPlaceholderRide,
        pickupId,
        deliveryId,
        pickupName,
        deliveryName,
        () => callback(newPlaceholderRide, false),
        () => callback(newPlaceholderRide, false)
      );
      //Set the type of the lane ('next stop', 'return ride', etc.)
      newPlaceholderRide.rideLaneType = laneType;
      return newPlaceholderRide;
    } catch (err) {
      log('Error: Failed to build new ride');
    }
  };

  return {
    injectPlaceholderRideMoves,
    buildNewRide,
    enrichRideLaneAsync,
    handleEnrichedPlanRides,
    deleteInvalidPlaceholderRides,
    buildEnrichedRidePlaceholder,
  };
}
const GET_LANE_BY_LOCATION_IDS = gql`
  query get_lanes_by_location_ids($origin_location_id: bigint!, $destination_location_id: bigint!) {
    lanes(
      order_by: { updatedat: desc }
      where: {
        origin_location_id: { _eq: $origin_location_id }
        destination_location_id: { _eq: $destination_location_id }
      }
    ) {
      id
      active
      description
      destination_location_id
      origin_location_id
      createdat
      customer_id
      pickup {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      delivery {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      updatedat
    }
  }
`;
