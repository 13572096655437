// -------------------------------- IMPORTS -------------------------------- //

import { capEach, getCleansedPhoneNumber, getReadableText } from './GeneralUtils';
import { checkMoveInProgress } from './MoveUtils';

// -------------------------------- EXPORTS -------------------------------- //

// NAVIGATION //

/** Open driver details in a new tab */
export const openDriverDetailsFromDriver = driver => {
  if (driver?.id) window.open(`/drivers/${driver?.id}`, `_blank`);
};

/** Open region details in a new tab */
export const openRegionDetailsFromDriver = driver => {
  if (driver?.region_id || driver?.region?.id)
    window.open(`/regions/${driver?.region_id || driver?.region?.id}`, `_blank`);
};

// GETTERS //

/** Get the driver ID from a driver object */
export const getDriverIdFromDriver = driver => {
  return driver?.id || undefined;
};

/** Get the user ID from a driver object */
export const getUserIdFromDriver = driver => {
  return driver?.user_id || driver?.user?.id || undefined;
};

/** Get the display name from a driver object */
export const getDisplayNameFromDriver = driver => {
  return driver?.user?.display_name || undefined;
};

/** Get the email from a driver object */
export const getEmailFromDriver = driver => {
  return driver?.user?.email || undefined;
};

/** Get the phone number from a driver object */
export const getPhoneFromDriver = driver => {
  return driver?.user?.phone || undefined;
};

/** Get the phone OS from a driver object */
export const getPhoneOSFromDriver = driver => {
  return driver?.phone_type || undefined;
};

/** Get the driver app version from a driver object */
export const getDriverAppVersionFromDriver = driver => {
  return driver?.driver_app_version || undefined;
};

/** Get the avatar URL from a driver object */
export const getAvatarURLFromDriver = driver => {
  return driver?.user?.avatar_url || undefined;
};

/** Get the region ID from a driver object */
export const getRegionIdFromDriver = driver => {
  return driver?.region_id || driver?.region?.id || undefined;
};

/** Get the region name from a driver object */
export const getRegionNameFromDriver = driver => {
  return driver?.region?.name || undefined;
};

/** Get the status from a driver object */
export const getStatusFromDriver = driver => {
  return driver?.status || undefined;
};

/** Get the plan status from a driver & plan object */
export const getPlanStatusFromDriverAndPlan = (driver, plan) => {
  // Get the driver status
  const driverStatus = getStatusFromDriver(driver);

  // If there is a move being worked, the driver is busy
  const inProgressMove = plan?.moves?.find(m => checkMoveInProgress(m));
  if (inProgressMove) return `busy`;

  // If there is no move being worked, read off of the driver status
  if (driverStatus === `online` || driverStatus === `training`) return `free`;
  if (driverStatus === `offline`) return `inactive`;

  // Otherwise, we can assume they dont have a status
  return undefined;
};

/** Get the plan status hex code color from a driver & plan object */
export const getPlanStatusColorFromDriverAndPlan = (driver, plan, overridePlanStatus, theme) => {
  const planStatus = overridePlanStatus || getPlanStatusFromDriverAndPlan(driver, plan);

  if (planStatus === `busy`) return theme?.palette?.warning?.main;
  if (planStatus === `free`) return theme?.palette?.success?.main;
  if (planStatus === `inactive`) return theme?.palette?.default?.lighter;
  return theme?.palette?.default?.main;
};

/** Get the active flag from a driver object */
export const getActiveFlagFromDriver = driver => {
  return driver?.user?.active || undefined;
};

/** Get an array of driver attributes from a driver object */
export const getArrayOfAttributesFromDriver = driver => {
  // Get attributes object
  const attributes = driver?.config?.attributes || {};

  // Assign attributes and remove nulls
  let attributesArr =
    Object.keys(attributes).length > 0
      ? Object.keys(attributes).map((key, i) => {
          const value = Object.values(attributes)[i];
          if (value === true) return `${getReadableText(key)}`.toLowerCase();
          return null;
        })
      : [];
  attributesArr = attributesArr.filter(a => a);
  attributesArr = attributesArr.sort();
  return attributesArr;
};

// FORMATTERS //

/** Format the driver ID from a driver object */
export const formatDriverIdFromDriver = driver => {
  const driverId = getDriverIdFromDriver(driver);
  return driverId ? `#${driverId}` : `-`;
};

/** Format the user ID from a driver object */
export const formatUserIdFromDriver = driver => {
  const userId = getUserIdFromDriver(driver);
  return userId ? `#${userId}` : `-`;
};

/** Format the display name from a driver object */
export const formatDisplayNameFromDriver = driver => {
  return getDisplayNameFromDriver(driver) || `-`;
};

/** Format the email from a driver object */
export const formatEmailFromDriver = driver => {
  return getEmailFromDriver(driver) || `-`;
};

/** Format the phone number from a driver object */
export const formatPhoneFromDriver = driver => {
  return getCleansedPhoneNumber(getPhoneFromDriver(driver)) || `-`;
};

/** Format the phone OS from a driver object */
export const formatPhoneOSFromDriver = driver => {
  const phoneOS = getPhoneOSFromDriver(driver);

  if (phoneOS) {
    if (phoneOS === `android`) return `Android`;
    if (phoneOS === `ios`) return `iOS`;
    return capEach(phoneOS);
  }
  return `Unknown`;
};

/** Format the driver app version from a driver object */
export const formatDriverAppVersionFromDriver = driver => {
  const version = getDriverAppVersionFromDriver(driver);
  return version ? `v${version}` : `-`;
};

/** Format the avatar URL from a driver object */
export const formatAvatarURLFromDriver = driver => {
  return getAvatarURLFromDriver(driver) || `-`;
};

/** Format the region ID from a driver object */
export const formatRegionIdFromDriver = driver => {
  const regionId = getRegionIdFromDriver(driver);
  return regionId ? `#${regionId}` : `-`;
};

/** Format the region name from a driver object */
export const formatRegionNameFromDriver = driver => {
  return getRegionNameFromDriver(driver) || `No Region`;
};

/** Format the region name and ID from a driver object */
export const formatRegionFromDriver = driver => {
  if (driver?.region?.name && (driver?.region_id || driver?.region?.id))
    return `${driver?.region?.name} (${driver?.region_id || driver?.region?.id})`;
  return `No Region (-)`;
};

/** Format the status from a driver object */
export const formatStatusFromDriver = driver => {
  const status = getStatusFromDriver(driver);

  if (status) {
    if (status === `new`) return `New`;
    if (status === `interviewed`) return `Interviewed`;
    if (status === `onboarding`) return `Onboarding`;
    if (status === `training`) return `Training`;
    if (status === `offline`) return `Offline`;
    if (status === `online`) return `Online`;
    if (status === `rejected`) return `Rejected`;
    if (status === `terminated`) return `Terminated`;
    return capEach(status);
  }
  return `Unknown`;
};

/** Format the plan status from a driver & plan object */
export const formatPlanStatusFromDriverAndPlan = (driver, plan) => {
  const planStatus = getPlanStatusFromDriverAndPlan(driver, plan);

  if (planStatus) {
    if (planStatus === `busy`) return `Busy`;
    if (planStatus === `free`) return `Free`;
    if (planStatus === `inactive`) return `Inactive`;
    return capEach(planStatus);
  }
  return `Unknown`;
};

/** Format the active flag from a driver object */
export const formatActiveFlagFromDriver = driver => {
  const activeFlag = getActiveFlagFromDriver(driver);
  if (activeFlag === 1 || activeFlag === 0) return activeFlag ? `Yes` : `No`;
  return `Unknown`;
};

// TAG CHECKS //

/** Check for concierge tag */
export const checkForConciergeTag = driver => {
  return driver?.config?.attributes?.concierge || driver?.config?.attributes?.concierge_trained ? true : false;
};

/** Check for manual tag */
export const checkForManualTag = driver => {
  return driver?.config?.attributes?.manual || driver?.config?.attributes?.manual_trained ? true : false;
};

/** Check for railyard tag (Hangtag) */
export const checkForRailyardTag = driver => {
  return driver?.config?.attributes?.railyard || driver?.config?.attributes?.railyard_trained ? true : false;
};

/** Check for any standard tag */
export const checkForAnyTag = driver => {
  const hasConciergeTag = checkForConciergeTag(driver);
  const hasManualTag = checkForManualTag(driver);
  const hasRailyardTag = checkForRailyardTag(driver);

  return hasConciergeTag || hasManualTag || hasRailyardTag || false;
};
