import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useDriverDetails } from './DriverDetailsProvider';
import CustomSwitch from '../../components/CustomSwitch';

//////////////////////// COMPONENT ////////////////////////

export default function DriverInfoAttributes({ driver = {} }) {
  const cls = useStyles();
  const ctx = useDriverDetails();

  const attributes = getPropValue(driver, `config.attributes`) || {};

  const handleSwitch = (preValue, setHandler) => {
    if (setHandler) {
      if (preValue) setHandler(false);
      else setHandler(true);
    }
  };

  return (
    <div className={cls.paper}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <Typography className={cls.headTxt}>Driver Attributes</Typography>
        </Grid>

        <Grid item>
          <Tooltip
            placement='top'
            title='Qualifications of the driver. These checks determine what moves the driver is allowed to do.'
          >
            <Icon className={cls.headIcon}>help</Icon>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Manual Trained:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.manual : attributes.manual}
              onChange={() => handleSwitch(ctx.manual, ctx.setManual)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Concierge Trained:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.concierge : attributes.concierge}
              onChange={() => handleSwitch(ctx.concierge, ctx.setConcierge)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Railyard Trained:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.railyard : attributes.railyard}
              onChange={() => handleSwitch(ctx.railyard, ctx.setRailyard)}
            />
          </div>
        </Grid>

        <div className={cls.hiddenBreak} />

        <Grid item md={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>DOT Certified:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.dotCertified : attributes.dot_certified}
              onChange={() => handleSwitch(ctx.dotCertified, ctx.setDotCertified)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>CarOffer Certified:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.carofferCertified : attributes.caroffer_certified}
              onChange={() => handleSwitch(ctx.carofferCertified, ctx.setCarofferCertified)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>CarMax Certified:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.carmaxCertified : attributes.carmax_certified}
              onChange={() => handleSwitch(ctx.carmaxCertified, ctx.setCarmaxCertified)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Carvana Certified:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.carvanaCertified : attributes.carvana_certified}
              onChange={() => handleSwitch(ctx.carvanaCertified, ctx.setCarvanaCertified)}
            />
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Genesis Certified:</Typography>
            <CustomSwitch
              editMode={ctx.editMode}
              checked={ctx.editMode ? ctx.genesisCertified : attributes.genesis_certified}
              onChange={() => handleSwitch(ctx.genesisCertified, ctx.setGenesisCertified)}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  main: {
    position: 'relative',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headIcon: {
    fontSize: 21,
    color: theme.palette.text.secondary,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    marginRight: 8,
    lineHeight: 1.25,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
