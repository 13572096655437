import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import { getUserEmail, getUserToken } from '../../utils/authHelper';

import { useTools } from '../../hooks/useTools';

const log = false;

//////////////////////// HOOK ////////////////////////

export function useDriverPay() {
  const { round, formatUSD } = useTools();

  let username;
  let email = getUserEmail();

  if (email) {
    username = email;
  } else username = 'admin';

  const defaultType = `due`;
  const defaultRegionId = 0;
  const defaultTaxClass = `1099`;

  const getDefaultRegionId = () => {
    const localRegionId = localStorage.getItem(`driver-pay-region-id`);
    if (localRegionId) return Number(localRegionId);
    return 0;
  };
  const getDefaultTaxClass = () => {
    const localTaxClass = localStorage.getItem(`driver-pay-tax-class`);
    if (localTaxClass) return localTaxClass;
    return `1099`;
  };

  const getStart = type => {
    if (type === `due`) return dayjs().startOf(`week`).subtract(6, `week`).format();
    if (type === `accruing`) return dayjs().startOf(`week`).format();
  };
  const getEnd = type => {
    if (type === `due`) return dayjs().endOf(`week`).subtract(1, `week`).format();
    if (type === `accruing`) return dayjs().endOf(`week`).format();
  };

  const getTypeTip = type => {
    if (type === `due`) return `Past 6 Weeks`;
    if (type === `accruing`) return `This Week`;
  };

  const getTotalFromDriverPay = driverPay => {
    if (driverPay && driverPay.length) {
      const amounts = driverPay.map(dp => dp.total);
      const total = amounts.reduce((total, current) => total + current, 0);
      return round(total, 2);
    }
  };

  /** Generate and download a CSV from driver pay */
  const generateDriverPayCSV = (driverPayArr, start, end) => {
    const createCsvRow = driverPay => {
      return {
        driver_id: driverPay.driver_id || ``,
        driver_name: driverPay.driver_name || ``,
        earliest_move_time: driverPay.start_time || ``,
        latest_move_time: driverPay.end_time || ``,

        total_duration_sec: driverPay.total_duration_sec || 0,
        total_miles: driverPay.total_miles || 0,
        pay_per_move: formatUSD(driverPay.pay_per_move) || `$0.00`,
        pay_per_hour: formatUSD(driverPay.pay_per_hour) || `$0.00`,
        pay_per_mile: formatUSD(driverPay.pay_per_mile) || `$0.00`,
        total_earnings: formatUSD(driverPay.total) || `$0.00`,

        payment_count: driverPay.payment_count || 0,
        move_count: driverPay.move_count || 0,
        drive_move_count: driverPay.drive_move_count || 0,
        ride_move_count: driverPay.ride_move_count || 0,
        accessorial_count: driverPay.accessorial_count || 0,
      };
    };
    const csvRows = driverPayArr.map(driverPay => createCsvRow(driverPay));
    const csvOptions = {
      filename: `driver_pay_${start}_to_${end}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  /** Handle calling netlify to process the payment (for a single driver) */
  const handleDriverPayoutProcessing = async (start, end, driverId, driverBankToken, username, token) => {
    try {
      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDriverPayoutProcessing`,
        data: {
          start,
          end,
          driverId,
          driverBankToken,
          username,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (res && res.status === 200 && res.data) {
        return res.data;
      } else {
        console.error(`Failed to initiate payout!`);
        return { driverId, success: false, notes: `No response was found!` };
      }
    } catch (err) {
      console.error(`Failed to initiate payout - Global catch:`, err);
      return { driverId, success: false, notes: err.message };
    }
  };

  /** Send out to the handleDriverPayoutProcessing netlify function to pay drivers */
  const initiatePayouts = async (driverPayArr = [], start, end, refetchCallBack) => {
    // Check to make sure driverPayArr is populated
    if (driverPayArr && driverPayArr.length) {
      const token = await getUserToken();

      // Create an array of promises, then wait till they are settled
      const promiseArr = driverPayArr.map(dp => {
        return handleDriverPayoutProcessing(start, end, dp.driver_id, dp.driver_bank_account_token, username, token);
      });
      const results = await Promise.allSettled(promiseArr);

      // Check for results
      if (results && results.length) {
        const fixedResults = results.map(r => r.value);
        if (refetchCallBack) refetchCallBack();
        log && console.log(`Initiate payout results:`, fixedResults);
        return fixedResults;
      } else {
        console.error(`Failed to find results for payouts!`);
        toast.error(`Failed to find results for payouts!`);
      }
    } else {
      console.error(`No drivers selected to pay!`);
      toast.error(`No drivers selected to pay!`);
    }

    // Fallback return null
    return null;
  };

  // Return logic hook
  return {
    defaultType,
    defaultRegionId,
    defaultTaxClass,
    getDefaultRegionId,
    getDefaultTaxClass,
    getStart,
    getEnd,
    getTypeTip,
    getTotalFromDriverPay,
    generateDriverPayCSV,
    initiatePayouts,
  };
}
