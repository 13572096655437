//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function Spacer({ size = 'md', column = false }) {
  const getSize = () => {
    let sizeNum = 2;
    if (Number(size)) {
      sizeNum = size;
    } else if (isNaN(size) && typeof size === `string`) {
      if (size === `xxxs`) sizeNum = 0.25;
      if (size === `xxs`) sizeNum = 0.5;
      if (size === `xs`) sizeNum = 1;
      if (size === `sm`) sizeNum = 1.5;
      if (size === `md`) sizeNum = 2;
      if (size === `lg`) sizeNum = 3;
      if (size === `xl`) sizeNum = 4;
      if (size === `xxl`) sizeNum = 6;
      if (size === `xxxl`) sizeNum = 8;
    }
    return sizeNum;
  };

  const useStyles = makeStyles(theme => ({
    spacerH: {
      display: 'block',
      width: '100%',
      height: theme.spacing(getSize()),
    },
    spacerV: {
      display: 'inline-block',
      width: theme.spacing(getSize()),
    },
  }));
  const cls = useStyles();

  return <div className={column ? cls.spacerV : cls.spacerH} />;
}
