//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Tooltip, IconButton, Icon, Menu, MenuItem, Divider } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function IconAction({
  className = null,
  actions = [],
  onOpenCallback = null,
  onCloseCallback = null,
  tooltip = null,
  tooltipPlacement = null,
  ...rest
}) {
  const cls = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionsOpen = e => {
    if (e) e?.stopPropagation();
    setAnchorEl(e?.currentTarget);
    if (onOpenCallback) onOpenCallback();
  };
  const handleActionsClose = e => {
    if (e) e?.stopPropagation();
    setAnchorEl(null);
    if (onCloseCallback) onCloseCallback();
  };

  const handleItemHandler = handler => {
    if (handler) handler();
  };

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <>
      <Tooltip placement={tooltipPlacement || `top`} title={tooltip || `Actions`}>
        <IconButton className={getClassNames()} onClick={handleActionsOpen} {...rest}>
          <Icon>more_vert</Icon>
        </IconButton>
      </Tooltip>

      {actions && actions?.length > 0 ? (
        <Menu keepMounted id={`actions-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleActionsClose}>
          {actions?.map((action, i) => {
            if (!action || action?.hide) return null;
            if (!action?.handler) return <Divider key={`action-${i}`} className={cls.divider} />;
            return action?.component ? (
              action?.component(handleActionsClose)
            ) : (
              <MenuItem
                key={`action-${i}`}
                disabled={action?.disabled}
                onClick={e => {
                  handleItemHandler(action?.handler || null);
                  handleActionsClose(e);
                }}
              >
                {action?.label || `Action ${i + 1}`}
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
