//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';
import CustomerUsersTable from './CustomerUsersTable';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerUsersDetails({ customer }) {
  const cls = useStyles();

  return (
    <Container maxWidth='lg'>
      <Divide spacer tip='View and add customers within this organization.'>
        Users By Customer
      </Divide>

      <div className={cls.paper}>
        <CustomerUsersTable customer={customer}/>
      </div>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },
}));
