import React, { useState } from 'react';
import { makeStyles, Grid, Typography, Tooltip, InputAdornment, Button, TextField, Icon } from '@material-ui/core';
import { useRates } from '../../hooks/useRates';
import sdk from '@hopdrive/sdk';

import LaneAddEditModal from './LaneAddEditModal';

export default function MapLaneCalculator(props) {
  const cls = useStyles();
  const { lane, setLane, rateRules, payRateGroups } = props;

  //Lane object (editable with form)
  const [laneObj, setLaneObj] = useState(lane);
  const [estimatedReturnRideCost, setEstimatedReturnRideCost] = useState(lane.estimated_rideshare_return_cost);

  const {
    getBand,
    // getPayRates,
    totalDurationInMins,
    driveDurationInMins,
    getCommission,
    getTotalBasePay,
    getTotalStrandedPay,
    getTotalBaseCost,
    getTotalStrandedCost,
    getTotalBaseRate,
    getTotalStrandedRate,
    getTotalBasePrice,
    getTotalStrandedPrice,
    getTotalBaseProfit,
    getTotalStrandedProfit,
  } = useRates(laneObj, rateRules, payRateGroups, estimatedReturnRideCost);

  //Form Inputs
  const [driveDiscount, setDriveDiscount] = useState(lane.driver_base_pay_discount);
  const [returnDiscount, setReturnDiscount] = useState(lane.driver_return_pay_discount);
  const [baseDiscount, setBaseDiscount] = useState(lane.dealer_base_discount);
  const [strandedDiscount, setStrandedDiscount] = useState(lane.dealer_stranded_discount);
  const [tolls, setTolls] = useState(lane.tolls);

  React.useEffect(() => {
    if (lane && rateRules?.length > 0) {

      try {
        const rideshareRule = rateRules.find(
          rule => rule.distance_start < lane.distance_miles && rule.distance_end >= lane.distance_miles && rule.raterulegroup?.ridesharerate && Object.keys(rule.raterulegroup?.ridesharerate).length > 0
        );
        if (rideshareRule) {
          const estRideCost = sdk.rating.thirdParty.getEstimatedReturnRideCost(lane.distance_miles, lane.average_drive_speed_mph, lane.tolls, rideshareRule)
          setEstimatedReturnRideCost(estRideCost);
        } else setEstimatedReturnRideCost(lane.estimated_rideshare_return_cost)
      } catch (err) {
        console.error('Error getting rideshare rate, using lane default')
        setEstimatedReturnRideCost(lane.estimated_rideshare_return_cost)
      }
    }
  }, [rateRules, lane])

  // useEffect(() => {
  //   let payRates = getPayRates(lane.customer_id, regionId, payRateGroups);
  //   setBaseRate(payRates[0]);
  //   setStrandedPayRate(payRates[1]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [payRateGroups, lane]);

  const capFirst = str => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleInputChange = (setHandler, property) => event => {
    if (!event.target.value) {
      setHandler('');
      const clonedLane = { ...laneObj };
      clonedLane[property] = lane[property];
      setLaneObj(clonedLane);
    } else if (setHandler) {
      setHandler(event.target.value);
      const clonedLane = { ...laneObj };
      clonedLane[property] = event.target.value;
      setLaneObj(clonedLane);
    }
  };

  const [modal, setModal] = useState({ open: false });
  const handleLaneAddEditModalOpen = (input = null) => {
    console.log('INPUT', input);
    setModal({ open: true, input: input });
  };
  const handleLaneAddEditModalClose = (output = null) => {
    setModal({ ...modal, open: false });
    if (output) setLane(output);
  };

  //If user leaves input empty, set value to original value
  const handleInputBlur = (setHandler, property) => event => {
    if (!event.target.value) {
      setHandler(lane[property]);
    }
  };

  const formatLaneObject = lane => {
    const formLane = {
      distance_miles: lane.distance,
      average_drive_speed_mph: lane.average_drive_speed_mph,
      driver_time_pay: lane.driver_time_pay,
      driver_drive_pay: lane.driver_drive_pay,
      driver_return_pay: lane.driver_return_pay,
      dealer_base_rate: lane.baseRate,
      dealer_base_price: lane.basePrice,
      dealer_stranded_rate: lane.strandedRate,
      dealer_stranded_price: lane.strandedPrice,
      insurance_cost: lane.totalInsuranceCost,
      estimated_rideshare_return_cost: lane.returnRideEstimate,

      driver_pay_per_minute: lane.driver_pay_per_minute,
      driver_pay_per_mile: lane.driver_pay_per_mile,
      driver_base_pay_discount: lane.driver_base_pay_discount,
      driver_return_pay_discount: lane.driver_return_pay_discount,
      dealer_base_discount: lane.dealer_base_discount,
      dealer_stranded_discount: lane.dealer_stranded_discount,
      tolls: tolls,
    };

    const upsertableLane = Object.assign({}, lane, formLane);
    delete upsertableLane.customer;
    delete upsertableLane.delivery;
    delete upsertableLane.pickup;
    delete upsertableLane.__typename;

    return upsertableLane;
  };

  //Grid Item component
  const GridItem = props => {
    const { label, amount = 0, unit, isMoney } = props;
    if (isMoney) {
      return (
        <Grid item md={6} xs={12}>
          <div className={cls.flex}>
            <Typography className={cls.flexTxtL}>{label}</Typography>
            <div className={cls.flexLine} />
            <Typography className={cls.flexTxtR}>{'$\u00A0' + Number(amount).toFixed(2)}</Typography>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item md={6} xs={12}>
          <div className={cls.flex}>
            <Typography className={cls.flexTxtL}>{label}</Typography>
            <div className={cls.flexLine} />
            <Typography className={cls.flexTxtR}>
              {amount}
              {unit}
            </Typography>
          </div>
        </Grid>
      );
    }
  };

  return (
    <>
      <LaneAddEditModal open={modal.open} onClose={handleLaneAddEditModalClose} laneInput={modal.input} />
      {/* Basic Info Section */}
      <div className={cls.section}>
        <Typography className={cls.descriptionTxt1}>Lane {lane.id}</Typography>
        <Typography className={cls.descriptionTxt2}>{lane.description}</Typography>
        {/* <IconButton className={cls.historyBtn} onClick={() => { }}><Icon>history</Icon></IconButton> */}
      </div>

      <div className={cls.lineBreak} />

      {/* Route Info Section */}
      <div className={cls.section}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headerTxtL}>Route Information</Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip placement='top' title='Time / Distance'>
              <Typography className={cls.headerTxtR}>
                {`${driveDurationInMins().toFixed(1)}\xa0min / ${Number(lane.distance_miles).toFixed(1) || 0}\xa0mi`}
              </Typography>
            </Tooltip>
          </Grid>

          <div className={cls.smallBreak} />

          <GridItem label='Total&nbsp;Time' amount={totalDurationInMins().toFixed(1)} unit='&nbsp;min' />
          <GridItem label='Drive&nbsp;Time' amount={driveDurationInMins().toFixed(1)} unit='&nbsp;min' />
          <GridItem label='Distance' amount={Number(lane.distance_miles).toFixed(1)} unit='&nbsp;mi' />
          <GridItem
            label='Average&nbsp;Speed'
            amount={Number(lane.average_drive_speed_mph).toFixed(1) || 0}
            unit='&nbsp;mph'
          />
        </Grid>
      </div>

      <div className={cls.lineBreak} />

      {/* Driver Pay Section */}
      <div className={cls.section}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headerTxtL}>Driver Pay</Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip placement='top' title='Total Base Pay / Total Stranded Pay'>
              <Typography className={cls.headerTxtR}>
                {`$${getTotalBasePay().toFixed(2)} / $${getTotalStrandedPay().toFixed(2)}`}
              </Typography>
            </Tooltip>
          </Grid>

          <div className={cls.smallBreak} />
          {/* THIS SECTION CANNOT WORK UNTIL SDK'S RATING LIBRARY IS REFACTORED */}
          {/* <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label='Pay/Min Override'
              placeholder='Enter pay per minute...'
              variant='outlined'
              margin='dense'
              value={payMinute}
              onBlur={handleInputBlur(setPayMinute, 'driver_pay_per_minute')}
              onChange={handleInputChange(setPayMinute, 'driver_pay_per_minute')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label='Pay/Mile Override'
              placeholder='Enter pay per mile...'
              variant='outlined'
              margin='dense'
              value={payMile}
              onBlur={handleInputBlur(setPayMile, 'driver_pay_per_mile')}
              onChange={handleInputChange(setPayMile, 'driver_pay_per_mile')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label='Drive Discount'
              placeholder='Enter drive discount...'
              variant='outlined'
              margin='dense'
              value={driveDiscount}
              onBlur={handleInputBlur(setDriveDiscount, 'driver_base_pay_discount')}
              onChange={handleInputChange(setDriveDiscount, 'driver_base_pay_discount')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label='Return Discount'
              placeholder='Enter return discount...'
              variant='outlined'
              margin='dense'
              value={returnDiscount}
              onBlur={handleInputBlur(setReturnDiscount, 'driver_return_pay_discount')}
              onChange={handleInputChange(setReturnDiscount, 'driver_return_pay_discount')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Factors</Typography>
          </Grid>

          <GridItem label='Drive&nbsp;Discount' amount={laneObj.driver_base_pay_discount} isMoney={true} />
          <GridItem label='Return&nbsp;Discount' amount={laneObj.driver_return_pay_discount} isMoney={true} />
          <GridItem label='Standard&nbsp;Pay/Min' amount={laneObj.driver_pay_per_minute} isMoney={true} />
          <GridItem label='Time&nbsp;Pay' amount={laneObj.driver_time_pay} isMoney={true} />
          <GridItem label='Standard&nbsp;Pay/Mile' amount={laneObj.driver_pay_per_mile} isMoney={true} />
          <GridItem label='Distance&nbsp;Pay' amount={laneObj.driver_drive_pay} isMoney={true} />
          <GridItem label='Return&nbsp;Pay' amount={lane.driver_return_pay} isMoney={true} />

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Totals</Typography>
          </Grid>
          <GridItem label='Total&nbsp;Base&nbsp;Pay' amount={getTotalBasePay()} isMoney={true} />
          <GridItem label='Total&nbsp;Stranded&nbsp;Pay' amount={getTotalStrandedPay()} isMoney={true} />
        </Grid>
      </div>

      <div className={cls.lineBreak} />

      {/* Customer Price Section */}
      <div className={cls.section}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headerTxtL}>Customer Price</Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip placement='top' title='Total Base Price / Total Stranded Price'>
              <Typography className={cls.headerTxtR}>
                {`$${getTotalBasePrice().toFixed(2)} / $${getTotalStrandedPrice().toFixed(2)}`}
              </Typography>
            </Tooltip>
          </Grid>

          <div className={cls.smallBreak} />

          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              label='Base Discount'
              placeholder='Enter base discount...'
              value={baseDiscount}
              onBlur={handleInputBlur(setBaseDiscount, 'dealer_base_discount')}
              onChange={handleInputChange(setBaseDiscount, 'dealer_base_discount')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              label='Stranded Discount'
              placeholder='Enter stranded discount...'
              value={strandedDiscount}
              onBlur={handleInputBlur(setStrandedDiscount, 'dealer_stranded_discount')}
              onChange={handleInputChange(setStrandedDiscount, 'dealer_stranded_discount')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              label='Tolls'
              placeholder='Enter tolls...'
              value={tolls}
              onBlur={handleInputBlur(setTolls, 'tolls')}
              onChange={handleInputChange(setTolls, 'tolls')}
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Base</Typography>
          </Grid>

          {/* Cap first lane.dealer_base_rate_type */}
          <GridItem label='Rate&nbsp;Type' amount={capFirst(lane.dealer_base_rate_type) || 'N/A'} />
          <GridItem label='Mileage&nbsp;Band' amount={getBand(lane.distance_miles, 'base', rateRules)} />
          <GridItem label='Total&nbsp;Base&nbsp;Rate' amount={getTotalBaseRate()} isMoney={true} />
          <GridItem label='Base&nbsp;Discount' amount={laneObj.dealer_base_discount} isMoney={true} />
          <GridItem label='Total&nbsp;Base&nbsp;Price' amount={getTotalBasePrice()} isMoney={true} />

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Stranded</Typography>
          </Grid>

          {/* Cap first lane.dealer_stranded_rate_type */}
          <GridItem label='Rate&nbsp;Type' amount={capFirst(lane.dealer_stranded_rate_type) || 'N/A'} />
          <GridItem label='Mileage&nbsp;Band' amount={getBand(lane.distance_miles, 'stranded', rateRules)} />
          <GridItem label='Total&nbsp;Stranded&nbsp;Rate' amount={getTotalStrandedRate()} isMoney={true} />
          <GridItem label='Stranded&nbsp;Discount' amount={laneObj.dealer_stranded_discount} isMoney={true} />
          <GridItem label='Total&nbsp;Stranded&nbsp;Price' amount={getTotalStrandedPrice()} isMoney={true} />
        </Grid>
      </div>

      <div className={cls.lineBreak} />

      {/* Profit/Cost Section */}
      <div className={cls.section}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headerTxtL}>Profit & Cost</Typography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip placement='top' title='Total Base Profit / Total Stranded Profit'>
              <Typography className={cls.headerTxtR}>
                {`$${getTotalBaseProfit().toFixed(2)} / $${getTotalStrandedProfit().toFixed(2)}`}{' '}
              </Typography>
            </Tooltip>
          </Grid>

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Factors</Typography>
          </Grid>

          <GridItem label='Insurance&nbsp;Cost/Mile' amount={lane.insurance_cost_per_mile} isMoney={true} />
          <GridItem label='Total&nbsp;Insurance&nbsp;Cost' amount={lane.insurance_cost} isMoney={true} />
          <GridItem label='Commission' amount={getCommission()} isMoney={true} />
          <GridItem
            label='Return&nbsp;Ride&nbsp;Estimate'
            amount={estimatedReturnRideCost}
            isMoney={true}
          />
          <GridItem label='Total&nbsp;Base&nbsp;Cost' amount={getTotalBaseCost()} isMoney={true} />
          <GridItem label='Total&nbsp;Stranded&nbsp;Cost' amount={getTotalStrandedCost()} isMoney={true} />

          <div className={cls.smallBreak} />

          <Grid item xs={12}>
            <Typography className={cls.subheaderTxt}>Totals</Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <div className={cls.flex}>
              <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Profit</Typography>
              <div className={cls.flexLine} />
              <Typography className={cls.flexTxtR}>${getTotalBaseProfit().toFixed(2)}</Typography>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={cls.flex}>
              <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Profit</Typography>
              <div className={cls.flexLine} />
              <Typography className={cls.flexTxtR}>${getTotalStrandedProfit().toFixed(2)}</Typography>
            </div>
          </Grid>
        </Grid>

        <div className={cls.bigBreak} />

        {/* <Button
          onClick={async () => {
            handleRecalc();
          }}
          fullWidth
          size='large'
          color='primary'
          variant='outlined'
        >
          Recalculate
        </Button> */}
        <div className={cls.break} />
        <Button
          onClick={() => handleLaneAddEditModalOpen(formatLaneObject(laneObj))}
          fullWidth
          size='large'
          color='primary'
          variant='outlined'
          className={cls.saveBtn}
        >
          {lane && lane.id ? `Save Changes` : `Save Lane`}
        </Button>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  lane: {
    width: '100%',
  },
  sectionOne: {
    background: theme.palette.text.primary,
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  section: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  descriptionTxt1: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  descriptionTxt2: {
    lineHeight: 1.2,
    fontSize: '18px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  historyBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  headerTxtL: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  headerTxtR: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  subheaderTxt: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  input: {
    verticalAlign: 'top',
    margin: 0,
  },
  laneBtn: {
    verticalAlign: 'top',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    verticalAlign: 'top',
    width: '100%',
  },
  flexLine: {
    width: '100%',
    height: '1px',
    marginTop: '5.5px',
    marginBottom: '5.5px',
    background: '#eee',
  },
  flexTxtL: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  flexTxtR: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    background: '#ddd',
  },
}));
