import React from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';
import { Button, TextField, Typography } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import { useMutation } from '@apollo/client';
import { UPDATE_CHASE_VEHICLE, GET_CHASE_VEHICLES } from './gql';

export default function EditChaseVehicleContent({ input, onClose }) {
  const [vin, setVin] = React.useState('');
  const [make, setMake] = React.useState('');
  const [model, setModel] = React.useState('');
  const [capacity, setCapacity] = React.useState('');

  const [updateChaseVehicle] = useMutation(UPDATE_CHASE_VEHICLE, {
    refetchQueries: [
      {
        query: GET_CHASE_VEHICLES,
      },
    ],
    onCompleted: () => {
      onClose();
    },
  });

  React.useEffect(() => {
    if (input) {
      setVin(input.vin || '');
      setMake(input.make || '');
      setModel(input.model || '');
      setCapacity(input.capacity || '');
    }
  }, [input]);

  const handleSave = React.useCallback(() => {
    updateChaseVehicle({
      variables: {
        id: input?.id,
        input: {
          vin,
          make,
          model,
          capacity,
        },
      },
    });
  }, [input?.id, vin, make, model, capacity, updateChaseVehicle]);

  const handleInputChange = React.useCallback(
    setValue => event => {
      setValue(event.target.value);
    },
    []
  );

  return (
    <>
      <ModalHeader handleClose={onClose}>Edit Chase Vehicle</ModalHeader>
      <ModalContent>
        <TextField
          required
          id='vin-input'
          label='Vehicle Vin'
          value={vin}
          onChange={handleInputChange(setVin)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='make-input'
          label='Vehicle Make'
          value={make}
          onChange={handleInputChange(setMake)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='model-input'
          label='Vehicle Model'
          value={model}
          onChange={handleInputChange(setModel)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
        <TextField
          required
          id='capacity-input'
          label='Vehicle Capacity'
          value={capacity}
          onChange={handleInputChange(setCapacity)}
          autoComplete='off'
          fullWidth
        />
        <Spacer />
      </ModalContent>
      <ModalFooter>
        <ModalAction disabled={!vin || !make || !model || !capacity} color='primary' onClick={handleSave}>
          Save
        </ModalAction>
        <ModalAction onClick={onClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}
