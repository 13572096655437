//////////////////////// DEPENDENCIES ////////////////////////

import React  from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { GET_INSURANCE_RATES } from './gql';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';

import { InsuranceRatesTable } from './index';

//////////////////////// COMPONENT ////////////////////////

function InsuranceRates() {
  const cls = useStyles();

  // Query all insurance rates
  const insuranceRatesQuery = GET_INSURANCE_RATES;
  const { loading, error, data, refetch } = useQuery(insuranceRatesQuery);

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Insurance Rates'>
          <></>
        </Toolbar>
        <Loading fixed />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching insurance rates:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Insurance Rates' refetch={handleRefetch}>
          <></>
        </Toolbar>
        <DefaultErrorFallback message='ERROR FETCHING INSURANCE RATES' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.insurancerates || !data.insurancerates.length > 0) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Insurance Rates' refetch={handleRefetch}>
          <></>
        </Toolbar>
        <DefaultEmptyFallback message='NO INSURANCE RATES FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const insuranceRates = data.insurancerates;

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Insurance Rates' refetch={handleRefetch}>
        <></>
      </Toolbar>
      {/* If table body has an error, show error message instead of table */}
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INSURANCE RATES TABLE' />}>
        <InsuranceRatesTable insuranceRates={insuranceRates} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InsuranceRates;