//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import { ManageRideAttemptDetails } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideAttemptHistory({
  attempts = [],
  currentAttempt = {},
  selectedAttempt = {},
  setSelectedAttemptId,
}) {
  const cls = useStyles();

  const { getFormattedRidesharePartner, getFormattedStatusFromRide, formatUSD } = useTools();

  // Initial useEffect when the input changes
  React.useEffect(() => {
    if (currentAttempt && !selectedAttempt.id) setSelectedAttemptId(currentAttempt.id);
  }, [currentAttempt]);

  if (attempts.length > 0) {
    return (
      <>
        <Typography className={cls.titleTxt}>View Attempt History</Typography>
        <Typography className={cls.subtitleTxt}>
          Click on an attempt row to see more details about it. * = Current attempt.
        </Typography>

        <Spacer size='xs' />

        <div className={cls.table}>
          <Table size='small'>
            <TableHead className={cls.tableHeadRow}>
              <TableRow>
                <TableCell className={cls.tableCell} align='left'>
                  ID
                </TableCell>
                <TableCell className={cls.tableCell} align='left'>
                  Ride Partner
                </TableCell>
                <TableCell className={cls.tableCell} align='left'>
                  Status
                </TableCell>
                <TableCell className={cls.tableCell} align='left'>
                  Cost
                </TableCell>
                <TableCell className={cls.tableCell} align='right'>
                  Requested At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attempts.map((attempt, i) => (
                <TableRow
                  key={`attempts-row-${i}`}
                  className={cls.tableRow}
                  onClick={() =>
                    selectedAttempt.id === attempt.id ? setSelectedAttemptId(0) : setSelectedAttemptId(attempt.id)
                  }
                >
                  <TableCell
                    className={selectedAttempt.id === attempt.id ? cls.tableCellActive : cls.tableCell}
                    align='left'
                    padding='normal'
                  >
                    {attempt.id}
                    {currentAttempt.id === attempt.id ? `*` : ``}
                  </TableCell>
                  <TableCell
                    className={selectedAttempt.id === attempt.id ? cls.tableCellActive : cls.tableCell}
                    align='left'
                    padding='normal'
                  >
                    {getFormattedRidesharePartner(attempt.ride_type)}
                  </TableCell>
                  <TableCell
                    className={selectedAttempt.id === attempt.id ? cls.tableCellActive : cls.tableCell}
                    align='left'
                    padding='normal'
                  >
                    {getFormattedStatusFromRide(attempt)}
                  </TableCell>
                  <TableCell
                    className={selectedAttempt.id === attempt.id ? cls.tableCellActive : cls.tableCell}
                    align='left'
                    padding='normal'
                  >
                    {formatUSD(attempt.ride_cost || attempt.estimated_ride_cost) || `-`}
                  </TableCell>
                  <TableCell
                    className={selectedAttempt.id === attempt.id ? cls.tableCellActive : cls.tableCell}
                    align='right'
                    padding='normal'
                  >
                    {dayjs(attempt.requested_at || attempt.createdat).format(`MM/DD hh:mm A z`)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Spacer />

        <ManageRideAttemptDetails attempt={selectedAttempt} />
      </>
    );
  }
  return (
    <>
      <Typography className={cls.titleTxt}>View Attempt History</Typography>
      <Typography className={cls.subtitleTxt}>
        Click on an attempt row to see more details about it. * = Current attempt.
      </Typography>

      <Spacer size='xs' />

      <div className={cls.loadErrorMissing}>
        <div className={cls.loadErrorMissingBox}>
          <Typography className={cls.loadErrorMissingTxt}>NO ATTEMPT HISTORY FOUND</Typography>
        </div>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitleTxt: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  table: {
    position: 'relative',
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    overflow: 'hidden',
  },
  tableHeadRow: {
    background: theme.palette.background.main,
  },
  tableRow: {
    borderTop: theme.border[0],
    '&:hover': {
      '& $tableCell': {
        background: theme.palette.action.hover,
      },
    },
  },
  tableCell: {
    border: 0,
    cursor: 'pointer',
    transition: '0.15s',
  },
  tableCellActive: {
    border: 0,
    color: theme.palette.text.contrast,
    background: theme.palette.primary.main,
    cursor: 'pointer',
    transition: '0.15s',
  },

  loadErrorMissing: {
    position: 'relative',
    width: '100%',
    height: 282.5,
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  loadErrorMissingBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  },
  loadErrorMissingTxt: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
}));
