import React, { useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import dayjs from 'dayjs';

import Toolbar from '../../components/Toolbar';
import APReportFilter from './APReportFilter';
import APReportWrapper from './APReportWrapper';

const log = false;

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  const localStart = localStorage.getItem(`apreport-start`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().startOf('day').subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return dayjs.utc(dayjs().startOf(`day`).subtract(1, `week`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  const localEnd = localStorage.getItem(`apreport-end`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().startOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return dayjs.utc(dayjs().startOf(`day`)).format();
};
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`apreport-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};

export default function APReport() {
  const cls = useStyles();

  // STATES /////////////////////////////////////////////////////////////////
  const [status, setStatus] = useState(null);
  const localDriverId = parseInt(localStorage.getItem(`driverId`));
  const [driverId, setDriverId] = useState(localDriverId || null);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  // LOGIC ////////////////////////////////////////////////////////////////////////////////////
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`apreport-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf(`day`).subtract(1, value)).format());
      setEnd(dayjs.utc(dayjs().startOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`apreport-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`apreport-end`, newDate);
      setEnd(newDate);
    }
  };

  // RENDER ////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Toolbar back title='AP Report' />

        <div className={cls.break} />

        <APReportFilter
          status={status}
          driverId={driverId}
          range={range}
          start={start}
          end={end}
          onStatusChange={setStatus}
          onDriverChange={setDriverId}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          disablePickers={disablePickers}
        />

        <div className={cls.break} />

        <APReportWrapper status={status} driverId={driverId} start={start} end={end} />
      </Container>
    </div>
  );
}

// STYLES /////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
