//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container, Typography, Button, Icon } from '@material-ui/core';
import { Timeline } from '@material-ui/lab/';

import { GET_SMS_MESSAGES } from './gql';

import { MoveAuditTimelineItem } from './index';

//////////////////////// COMPONENT ////////////////////////

function MoveAuditTimeline({ move, ...props }) {
  const cls = useStyles();
  const [limit, setLimit] = React.useState(10);
  const [timelineAggregate, setTimelineAggregate] = React.useState([]);
  const [timelineItems, setTimelineItems] = React.useState([]);
  const [eventlogs, setEventLogs] = React.useState(
    move && move.eventlogs && move.eventlogs.length > 0 ? move.eventlogs : null
  );
  const [refetchingSmsInterval, setRefetchingSmsInterval] = React.useState(0);

  const { loading, error, data } = useQuery(GET_SMS_MESSAGES, {
    variables: { moveId: move.id, limit: limit },
    pollInterval: refetchingSmsInterval,
  });

  //Build array of combined move and sms events, and sort all by time in descending order
  React.useEffect(() => {
    let timelineArray = [];
    let eventObjArray = [];
    let smsObjArray = data && data.smsmessages && data.smsmessages.length > 0 ? data.smsmessages : null;
    if (smsObjArray && smsObjArray.length > 0) timelineArray.push(...smsObjArray);

    if (eventlogs && eventlogs.length && eventlogs.length > 0) {
      eventlogs.forEach(eventItem => {
        let eventTime = eventItem.event_time_utc;
        eventItem.updatedat = eventTime;
        eventObjArray.push(eventItem);
      });
    }

    timelineArray.push(...eventObjArray);
    timelineArray.sort((a, b) => new Date(b.updatedat) - new Date(a.updatedat));
    timelineArray = Object.keys(timelineArray).map(key => [timelineArray[key]]);

    setTimelineItems(timelineArray);
  }, [eventlogs, data]);

  //Update number of timeline items shown when user selects to expand or collapse list
  React.useEffect(() => {
    let limitedTimelineItems = [];
    for (let i = 0; i < limit; i++) {
      if (timelineItems[i]) {
        limitedTimelineItems.push(timelineItems[i]);
      }
    }
    setTimelineAggregate(limitedTimelineItems);
  }, [limit, timelineItems]);

  const showMore = () => {
    setLimit(limit + 10);
  };

  const showLess = () => {
    setLimit(10);
  };

  // LOADING STATE //
  if (loading) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>LOADING AUDIT TIMELINE...</Typography>
        </div>
      </Container>
    );
  }

  // ERROR STATE //
  if (error) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>ERROR FETCHING AUDIT TIMELINE</Typography>
        </div>
      </Container>
    );
  }

  // EMPTY STATE //
  if (!data || !move.eventlogs || !move.eventlogs.length > 0) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO AUDIT LOGS AVAILABLE</Typography>
        </div>
      </Container>
    );
  }

  // DATA STATE //
  const aggregate = timelineItems.length;

  return (
    <Container maxWidth='md'>
      <div className={cls.paper}>
        <Timeline className={cls.timeline} align='center'>
          {timelineAggregate.map((event, i) => {
            return (
              <MoveAuditTimelineItem
                key={`audit-log-${i}`}
                timelineItem={event ? event[0] : null}
                setRefetchingSmsInterval={setRefetchingSmsInterval}
              />
            );
          })}
        </Timeline>
        {limit <= aggregate ? (
          <Button className={cls.load} onClick={showMore}>
            show more <Icon>expand_more</Icon>
          </Button>
        ) : (
          <Button className={cls.load} onClick={showLess}>
            show less <Icon>expand_less</Icon>
          </Button>
        )}
      </div>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timeline: {
    position: 'relative',
    padding: 0,
    margin: 0,
  },
  load: {
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  paper: {
    position: 'relative',
    maxWidth: '75%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    margin: '0 auto',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveAuditTimeline;
