//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  Icon,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Badge,
} from '@material-ui/core';
import DotMenu from '../DotMenu';
import { gql } from '@apollo/client';
import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';
import { toast } from 'react-toastify';
import { REACT_APP_ACC_CODES } from '../../utils/env';

//////////////////////// COMPONENT ////////////////////////

const ManageAccessorialsTable = props => {
  const ctx = useData();

  const classes = useStyles();
  const { capFirst } = useTools();

  const [newCharge, setCharge] = React.useState({
    code: '',
    cost: 0,
    time: 30,
    customer: 0,
    driver: 0,
    notes: '',
    status: '',
  });

  const [updatedCharge, setUpdatedCharge] = React.useState({
    id: '',
    code: '',
    cost: 0,
    time: 30,
    customer: 0,
    driver: 0,
    notes: '',
    status: '',
  });

  const [loading, setLoading] = React.useState(false);

  const handleAddAccessorial = () => {
    setLoading(true);
    try {
      ctx.apolloClient
        .mutate({
          mutation: gql`
            mutation newAccessorial(
              $move_id: bigint!
              $code: String!
              $notes: String!
              $cost: numeric!
              $ap_amount: numeric!
              $ar_amount: numeric!
            ) {
              insert_accessorials(
                objects: {
                  move_id: $move_id
                  code: $code
                  notes: $notes
                  cost: $cost
                  ap_amount: $ap_amount
                  ar_amount: $ar_amount
                  status: "approved"
                }
              ) {
                returning {
                  id
                }
              }
            }
          `,
          variables: {
            move_id: props.moveId,
            code: newCharge.code,
            notes: newCharge.notes,
            cost: Number(newCharge.cost),
            ap_amount: Number(newCharge.driver),
            ar_amount: Number(newCharge.customer),
          },
        })
        .then(res => {
          if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
            handleError('Failed to save new accessorial.');
          } else {
            setCharge({
              code: '',
              cost: 0,
              time: 30,
              customer: 0,
              driver: 0,
              notes: '',
              status: '',
            });
            toast.success(`Added new accessorial!`);
            if (props.refetch) {
              props.refetch();
            }
            setLoading(false);
          }
        });
    } catch (err) {
      toast.error(`Failed to add new accessorial!`);
      console.error(`Failed to add new accessorial:`, err);
      setLoading(false);
    }
  };

  const handleError = msg => {
    setLoading(false);
    toast.error(`Error: `, msg);
    console.error(`Error: `, msg);
  };

  const handleDeleteAccessorial = charge => {
    setLoading(true);
    ctx.apolloClient
      .mutate({
        mutation: gql`
          mutation deleteAccessorialAndRelatedPayment($accessorialId: bigint!) {
            delete_appayments(where: { accessorial_id: { _eq: $accessorialId }, status: { _neq: "paid" } }) {
              affected_rows
              returning {
                id
                accessorial_id
              }
            }
            delete_accessorials(where: { id: { _eq: $accessorialId } }) {
              affected_rows
              returning {
                id
              }
            }
          }
        `,
        variables: { accessorialId: charge.id },
      })
      .then(res => {
        if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
          handleError('Failed to delete accessorial.');
        } else {
          toast.success(`Deleted accessorial!`);
          props.refetch();
          setLoading(false);
        }
      })
      .catch(err => {
        if (err.message.includes('appayments_accessorial_id_fkey')) {
          toast.error(`Failed to delete accessorial. Driver has already been paid.`);
        } else {
          toast.error(`Failed to delete accessorial!`);
        }
        console.error(`Failed to delete accessorial:`, err);
        setLoading(false);
      });
  };

  const handleEditAccessorial = () => {
    setLoading(true);
    try {
      ctx.apolloClient
        .mutate({
          mutation: gql`
            mutation updateAccessorial(
              $id: bigint!
              $code: String!
              $notes: String!
              $cost: numeric!
              $ap_amount: numeric!
              $ar_amount: numeric!
              $status: String!
            ) {
              update_accessorials(
                where: { id: { _eq: $id } }
                _set: {
                  code: $code
                  notes: $notes
                  cost: $cost
                  ap_amount: $ap_amount
                  ar_amount: $ar_amount
                  status: $status
                  updatedat: "now()"
                }
              ) {
                affected_rows
              }
            }
          `,
          variables: {
            id: parseInt(updatedCharge.id),
            code: updatedCharge.code,
            notes: updatedCharge.notes,
            cost: Number(updatedCharge.cost),
            ap_amount: Number(updatedCharge.driver),
            ar_amount: Number(updatedCharge.customer),
            status: updatedCharge.status,
          },
        })
        .then(res => {
          if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
            // handleEventlog('updated.failed', false, "Failed to update accessorial.")
            handleError('Failed to update accessorial.');
          } else {
            toast.success(`Updated accessorial!`);
            props.refetch();
            setUpdatedCharge({
              id: '',
              code: '',
              time: 30,
              cost: 0,
              customer: 0,
              driver: 0,
              notes: '',
              status: '',
            });
            setLoading(false);
          }
        });
    } catch (err) {
      toast.error(`Failed to update accessorial!`);
      console.error(`Failed to update accessorial:`, err);
      setLoading(false);
    }
  };

  const handleChange = name => event => setCharge({ ...newCharge, [name]: event.target.value });

  const handleUpdateChange = name => event => setUpdatedCharge({ ...updatedCharge, [name]: event.target.value });

  const handleApproveAccessorial = charge => {
    setLoading(true);
    ctx.apolloClient
      .mutate({
        mutation: gql`
          mutation approveAccessorial($id: bigint!) {
            update_accessorials(where: { id: { _eq: $id } }, _set: { status: "approved" }) {
              affected_rows
            }
          }
        `,
        variables: { id: charge.id },
      })
      .then(res => {
        if (Object.getOwnPropertyDescriptor(res.data, 'errors')) {
          handleError('Failed to approve accessorial.');
        } else {
          toast.success(`Approved accessorial!`);
          props.refetch();
          setLoading(false);
        }
      })
      .catch(err => {
        toast.error(`Failed to approve accessorial!`);
        console.error(`Failed to approve accessorial:`, err);
        setLoading(false);
      });
  }

  const chargeIsValid = () => {
    if (
      !newCharge.code ||
      String(newCharge.code).trim().length < 1 ||
      String(newCharge.cost).trim().length < 1 ||
      String(newCharge.customer).trim().length < 1 ||
      String(newCharge.driver).trim().length < 1 ||
      !newCharge.notes ||
      String(newCharge.notes).trim().length < 1
    )
      return false;
    else return true;
  };

  const updatedChargeIsValid = () => {
    if (
      !updatedCharge.code ||
      String(updatedCharge.code).trim().length < 1 ||
      String(updatedCharge.cost).trim().length < 1 ||
      String(updatedCharge.customer).trim().length < 1 ||
      String(updatedCharge.driver).trim().length < 1 ||
      !updatedCharge.notes ||
      String(updatedCharge.notes).trim().length < 1
    )
      return false;
    else return true;
  };

  const handleformSubmit = action => event => {
    event.preventDefault();
    if (action === 'save') {
      if (!chargeIsValid()) {
        toast.warning(`Please fill out all accessorial fields!`);
        return;
      } else handleAddAccessorial();
    } else if (action === 'update') {
      if (!updatedChargeIsValid()) {
        toast.warning(`Please fill out all accessorial fields!`);
        return;
      } else handleEditAccessorial();
    }
  };

  const isApAmountPaid = accessorial => {
    if (accessorial.appayment) {
      if (accessorial.appayment.status === 'paid') return true;
      else return false;
    } else return false;
  };

  const isArAmountPaid = accessorial => {
    if (accessorial.move.accountsReceivable) {
      if (accessorial.move.accountsReceivable.status === 'paid') return true;
      else return false;
    } else return false;
  };

  const handleTimeChange = type => event => {
    let delayCost = event.target.value > 30 ? (props.perMinRate * (event.target.value - 30)).toFixed(2) : 0;
    if (type === 'update') {
      setUpdatedCharge({
        ...updatedCharge,
        time: event.target.value,
        cost: delayCost,
        customer: delayCost,
        driver: delayCost,
      });
    } else {
      setCharge({
        ...newCharge,
        time: event.target.value,
        cost: delayCost,
        customer: delayCost,
        driver: delayCost,
      });
    }
  };

  const accessorialForm = (type, Charge) => {
    let onChange = type === 'update' ? handleUpdateChange : handleChange;
    return (
      <>
        {/* Code */}
        <TableCell padding='none' component='th' scope='row'>
          <Select
            disabled={loading}
            value={Charge.code}
            onChange={onChange('code')}
            displayEmpty
            name='code'
            className={classes.formField}
            style={{ minWidth: '70px' }}
          >
            {(REACT_APP_ACC_CODES || '').split(',').map(code => (
              <MenuItem key={`${code}key1`} value={code}>
                {capFirst(code)}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        {/* Set Cost */}
        {Charge.code !== 'delay' ? (
          <TableCell padding='none' align='left'>
            <form onSubmit={handleformSubmit('save')}>
              <TextField
                disabled={loading}
                id='cost'
                className={classes.formField}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                type='number'
                value={Charge.cost}
                onChange={onChange('cost')}
              />
            </form>
          </TableCell>
        ) : (
          <TableCell padding='none' align='left'>
            <form onSubmit={handleformSubmit('update')}>
              <TextField
                disabled={loading}
                id='time'
                className={classes.formField}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>mins</InputAdornment>,
                }}
                type='number'
                value={Charge.time}
                onChange={handleTimeChange(type)}
              />
            </form>
          </TableCell>
        )}

        {/* Customer Cost */}
        <TableCell padding='none' align='left'>
          <form onSubmit={handleformSubmit('save')}>
            <TextField
              disabled={loading || Charge.code === 'delay' || Charge.code === 'waived'}
              id='customer'
              className={classes.formField}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              type='number'
              value={Charge.customer}
              onChange={onChange('customer')}
            />
          </form>
        </TableCell>
        {/* Driver Cost */}
        <TableCell padding='none' align='left'>
          <form onSubmit={handleformSubmit('save')}>
            <TextField
              disabled={loading || Charge.code === 'delay'}
              id='driver'
              className={classes.formField}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              type='number'
              value={Charge.driver}
              onChange={onChange('driver')}
            />
          </form>
        </TableCell>
        {/* Notes */}
        <TableCell padding='none' align='left'>
          <form onSubmit={handleformSubmit('save')}>
            <TextField
              disabled={loading}
              id='notes'
              className={classes.formField}
              style={{ minWidth: '150px' }}
              value={Charge.notes}
              onChange={onChange('notes')}
            />
          </form>
        </TableCell>
      </>
    );
  };

  return (
    <>
      {props.type ? null : <DialogTitle>Manage Accessorials</DialogTitle>}
      <DialogContent>
        {props.type ? null : <DialogContentText>Add or remove accessorial charges to the move.</DialogContentText>}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding='none' align='left'>
                Code
              </TableCell>
              <TableCell padding='none' align='left'>
                Cost
              </TableCell>
              <TableCell padding='none' align='left'>
                Customer
              </TableCell>
              <TableCell padding='none' align='left'>
                Driver
              </TableCell>
              <TableCell padding='none' align='left'>
                Notes
              </TableCell>
              <TableCell padding='none' align='right'>
                Status
              </TableCell>
              <TableCell padding='none' align='right'></TableCell> {/* Used to render an action menu */}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(props.accessorials || []).map((charge, i) => {
              const actions = [
                {
                  label: 'edit',
                  function: () => {
                    setUpdatedCharge({
                      id: charge.id,
                      code: charge.code,
                      time: 30,
                      cost: charge.cost,
                      customer: charge.ar_amount,
                      driver: charge.ap_amount,
                      notes: charge.notes,
                      status: charge.status,
                    });
                  },
                },
                { label: 'delete', function: () => handleDeleteAccessorial(charge) },
              ];
              if (charge.status === 'pending') actions.push({ label: 'approve', function: () => handleApproveAccessorial(charge)});

              return charge.id !== updatedCharge.id ? (
                // List of saved accessorials
                <TableRow key={`accessorial-${i}`}>
                  {/* code */}
                  <TableCell padding='none' component='th' scope='row'>
                    {capFirst(charge.code)}
                  </TableCell>
                  {/* Cost */}
                  {
                    <TableCell padding='none' align='left'>
                      ${charge.cost.toFixed(2)}
                    </TableCell>
                  }
                  {/* Driver Cost */}
                  <TableCell padding='none' align='left'>
                    {isArAmountPaid(charge) ? (
                      <Badge
                        badgeContent={
                          <Icon className={classes.badgeIcon} style={{ color: 'blue' }}>
                            monetization_on
                          </Icon>
                        }
                        className={classes.badge}
                      >
                        ${charge.ar_amount.toFixed(2)}
                      </Badge>
                    ) : (
                      '$' + charge.ar_amount.toFixed(2)
                    )}
                  </TableCell>
                  {/* Customer Cost */}
                  <TableCell padding='none' align='left'>
                    {isApAmountPaid(charge) ? (
                      <Badge
                        badgeContent={
                          <Icon className={classes.badgeIcon} style={{ color: 'green' }}>
                            monetization_on
                          </Icon>
                        }
                        className={classes.badge}
                      >
                        ${charge.ap_amount.toFixed(2)}
                      </Badge>
                    ) : (
                      '$' + charge.ap_amount.toFixed(2)
                    )}
                  </TableCell>
                  {/* Notes */}
                  <TableCell
                    padding='none'
                    align='left'
                    style={{ whiteSpace: 'nowrap', overflowX: 'hidden', maxWidth: '200px' }}
                  >
                    {charge.notes}
                  </TableCell>
                  <TableCell padding='none' align='right'>
                    {charge.status === 'pending' ? (
                      <Icon className={classes.status}>
                        <Tooltip title='Approval is still pending. To approve, open the menu to the right.'>
                          <span className={classes.pending}>pending</span>
                        </Tooltip>
                      </Icon>
                    ) : charge.status === 'approved' ? (
                      <Icon className={classes.status}>
                        <Tooltip title='This accessorial has been approved.'>
                          <span className={classes.approved}>check_circle</span>
                        </Tooltip>
                      </Icon>
                    ) : charge.status === 'expired' ? (
                      <Icon className={classes.status}>
                        <Tooltip title='This accessorial has expired.'>
                          <span className={classes.expired}>error</span>
                        </Tooltip>
                      </Icon>
                    ) : (null)}
                  </TableCell>
                  <TableCell padding='none' align='left'>
                    <DotMenu actions={actions} />
                  </TableCell>
                </TableRow>
              ) : (
                //Update Accessorial form
                <TableRow key={`new-accessorial`}>
                  {accessorialForm('update', updatedCharge)}
                  <TableCell padding='none' align='right'>
                    <Button
                      onClick={() => {
                        setUpdatedCharge({
                          id: '',
                          code: '',
                          time: 30,
                          cost: 0,
                          customer: 0,
                          driver: 0,
                          notes: '',
                          status: '',
                        });
                      }}
                      className={classes.button}
                      size='small'
                      variant='contained'
                      color='default'
                      style={{ transform: 'scale(.75)' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading || !updatedChargeIsValid()}
                      onClick={handleEditAccessorial}
                      className={classes.button}
                      size='small'
                      variant='contained'
                      color='primary'
                      style={{ transform: 'scale(.75)' }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            <br />
            <br />
            {/* New Accessorial Form */}
            <TableRow>
              {accessorialForm('create', newCharge)}
              {/* Save Button */}
              <TableCell padding='none' align='right'>
                <Button
                  disabled={loading || !chargeIsValid()}
                  onClick={handleAddAccessorial}
                  className={classes.button}
                  size='small'
                  variant='contained'
                  color='primary'
                  style={{ transform: 'scale(.75)' }}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      {props.type ? null : (
        <DialogActions>
          <Button
            className={classes.button}
            size='small'
            color='default'
            // variant="contained"
            onClick={props.close}
          >
            Close
          </Button>
          {/* <Button
          className={classes.button}
          size="small"
          color="primary"
          onClick={props.close}
        // variant="contained"
        >
          Save
        </Button> */}
        </DialogActions>
      )}
    </>
  );
};

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
    textTransform: 'none !important',
  },
  badge: {
    backgroundColor: 'white !important',
    textTransform: 'none !important',
    position: 'relative',
  },
  badgeIcon: {
    marginTop: '-5px',
    marginRight: '-3px',
    transform: 'scale(0.75)',
  },
  table: {
    minWidth: 700,
  },
  formField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  status: {
    fontSize: 18,
    marginTop: -1,
    marginRight: 6,
  },
  approved: {
    color: theme.palette.success.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
  expired: {
    color: theme.palette.error.main,
  }
}));

//////////////////////// EXPORT ////////////////////////

export default ManageAccessorialsTable;
