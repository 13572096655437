import { gql } from '@apollo/client';

const GET_PROMO = gql`
    query get_promos($id: bigint!) {
        promos(where: {id: {_eq: $id}}) {
            id
            customer_id
            region_id
            customer{
            name
            }
            rule{
                id
                name
                description
                jsonb
            }
            expiration_date
            activation_date
            config
        }
    }
`;

const UPDATE_PROMO = gql`
    mutation update_promo(
        $id: bigint!, 
        $activation_date: timestamptz, 
        $config: jsonb, 
        $customer_id: bigint, 
        $expiration_date: timestamptz, 
        $region_id: bigint, 
        $rule_id: bigint!
    ) {
        update_promos(where: {id: {_eq: $id}}, 
            _set: {
                activation_date: $activation_date, 
                config: $config, 
                customer_id: $customer_id, 
                expiration_date: $expiration_date, 
                region_id: $region_id, 
                rule_id: $rule_id}) {
            affected_rows
            returning {
                activation_date
                active
                createdat
                config
                customer_id
                expiration_date
                id
                region_id
                rule_id
                updatedat
            }
        }
    }
`;

const ADD_PROMO = gql`
mutation add_promo(
    $activation_date: timestamptz,
    $config: jsonb,
    $customer_id: bigint,
    $expiration_date: timestamptz,
    $region_id: bigint,
    $rule_id: bigint!
    ) {
    insert_promos(objects: {
        activation_date: $activation_date,
        active: true,
        config: $config,
        createdat: "now()",
        customer_id: $customer_id,
        expiration_date: $expiration_date,
        region_id: $region_id,
        rule_id: $rule_id
    }) {
        affected_rows
        returning {
            activation_date
            active
            createdat
            config
            customer_id
            expiration_date
            id
            region_id
            rule_id
            updatedat
        }
    }
}
`;

const GET_PROMO_TYPES = gql`
query get_rules {
    businessrules(where: { active: { _eq: true }, type: { _eq: 2 } }) {
        id
        name
        description
        jsonb
        ruletype{
            id
            name
        }
    }
}
`;

export { GET_PROMO, UPDATE_PROMO, ADD_PROMO, GET_PROMO_TYPES }