//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import dayjs from 'dayjs';

//////////////////////// COMPONENT ////////////////////////

function InsuranceRateInfo({ insuranceRate = {} }) {
  const cls = useStyles();

  const { capEach } = useTools();

  return (
    <div className={cls.main}>
      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
              {capEach(insuranceRate.name) || `Unknown Insurance Rate`}
            </Typography>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Class&nbsp;1&2&nbsp;Rate:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.rate || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Class&nbsp;3&nbsp;Rate:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.class_3_rate || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Class&nbsp;4&nbsp;Rate:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.class_4_rate || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Class&nbsp;5&nbsp;Rate:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.class_5_rate || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Class&nbsp;6&nbsp;Rate:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.class_6_rate || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.type}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Description:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.description || `-`}</Typography>
            </div>
          </Grid>

          <div className={cls.hiddenBreak} />

          <Grid item md={6} xs={12}>
          <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>State:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.state || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Region:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>
                {insuranceRate.region && insuranceRate.region.name ? insuranceRate.region.name : `-`}
              </Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Created:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{dayjs(insuranceRate.createdat).format('MMM D YYYY h:mm a') || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Last&nbsp;Updated:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{dayjs(insuranceRate.updatedat).format('MMM D YYYY h:mm a') || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Created&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.createdby || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Last&nbsp;Updated&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.updatedby || `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Effective&nbsp;Date:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>
                {dayjs(insuranceRate.effective_date).format('MMM D YYYY') || `-`}
              </Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Expiration&nbsp;Date:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>
                {dayjs(insuranceRate.expiration_date).format('MMM D YYYY') || `-`}
              </Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Carrier&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{insuranceRate.place_id || `-`}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InsuranceRateInfo;
