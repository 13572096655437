//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';
import Spacer from '../../components/Spacer';
import sdk from '@hopdrive/sdk';
import { getUserEmail } from '../../utils/authHelper'

//////////////////////// COMPONENT ////////////////////////

function MoveTimeModal(props) {
  const cls = useStyles();

  const { open, onClose, input } = props;

  const [myReadyDate, setMyReadyDate] = React.useState(``);
  const [myReadyTime, setMyReadyTime] = React.useState(``);

  const [myPickupDate, setMyPickupDate] = React.useState(``);
  const [myPickupTime, setMyPickupTime] = React.useState(``);
  const [userEmail, setUserEmail] = React.useState(null)

  React.useEffect(() => {
    if (input) {
      setMyReadyDate(input.readyTime);
      setMyReadyTime(input.readyTime);
      setMyPickupDate(input.pickupTime);
      setMyPickupTime(input.pickupTime);
    }
  }, [input]);

  React.useEffect(() => {
    let email = getUserEmail()
    setUserEmail(email)
  }, [])

  // React.useEffect(() => {
  //   if (myReadyDate > myPickupDate) {
  //     setMyPickupDate(myReadyDate);
  //   }
  //   if (myReadyTime > myPickupTime) {
  //     setMyPickupTime(myReadyTime);
  //   }
  // }, [myReadyDate, myReadyTime]);

  const handleDateChange = str => event => {
    if (str === `ready`) setMyReadyDate(dayjs(event).startOf(`day`).format());
    if (str === `pickup`) setMyPickupDate(dayjs(event).startOf(`day`).format());
  };

  const handleTimeChange = str => event => {
    if (str === `ready`) setMyReadyTime(dayjs(event).format());
    if (str === `pickup`) setMyPickupTime(dayjs(event).format());
  };

  const calcDeliveryTime = (pickupTime, laneDuration) => {
    if (!pickupTime) return null;
    if (!laneDuration) return pickupTime;
    return dayjs(pickupTime).add(laneDuration, `second`);
  };

  const handleSubmit = async () => {
    let newReadyTime = `${dayjs.utc(myReadyDate).format(`YYYY-MM-DD`)}T${dayjs
      .utc(myReadyTime)
      .format(`HH:mm`)}:00+00:00`;
    let newPickupTime = `${dayjs.utc(myPickupDate).format(`YYYY-MM-DD`)}T${dayjs
      .utc(myPickupTime)
      .format(`HH:mm`)}:00+00:00`;

      // Insert move update eventlog
      if (input.pickupTime !== newPickupTime || input.readyTime !== newReadyTime){
        let metadataText
        if (input.pickupTime !== newPickupTime && input.readyTime === newReadyTime) {
          metadataText = {oldPickupTime: `${input.pickupTime}`, newPickupTime: `${newPickupTime}`}
        } else if (input.pickupTime === newPickupTime && input.readyTime !== newReadyTime){
          metadataText = {oldReadyTime: `${input.readyTime}`, newReadyTime: `${newReadyTime}`}
        } else if (input.pickupTime !== newPickupTime && input.readyTime !== newReadyTime){
          metadataText = {oldPickupTime: `${input.pickupTime}`, newPickupTime: `${newPickupTime}`, oldReadyTime: `${input.readyTime}`, newReadyTime: `${newReadyTime}`}
        }
        try {
          console.log('--EVENT LOG--');
          let eventConfig = {
            action: `move.times.updated`,
            user: userEmail,
            role: 'admin',
            move_id: input.move.id,
            customer_id: input.move.customer.id,
            lane_id: input.move.lane.id,
            driver_id: input.move.driver_id,
            metadata: metadataText || null
          };
          let logRes = await sdk.events.buildAndCreate(eventConfig);
          console.log(`Successfully inserted eventlog ${logRes.id}`);
        }  catch (err) {
          //Can't return bc other functions need to run
          console.error(err);
        }
      }

    // Update the delivery_time based on the selected pickup_time
    const newDeliveryTime = calcDeliveryTime(newPickupTime, input.laneDuration);

    handleClose({
      move: input.move,
      readyTime: newReadyTime,
      pickupTime: newPickupTime,
      deliveryTime: newDeliveryTime,
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Times</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's ready by/pickup times stored in our database. Your changes will be made upon clicking 'Submit', then 'Save\xa0Changes'.`}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography className={cls.titleTxt}>Ready By Time</Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <DatePicker
                  autoOk
                  fullWidth
                  variant='inline'
                  orientation='portrait'
                  format='MM/dd/yyyy'
                  label='Ready By Date (Local date converted to UTC)'
                  size='small'
                  inputVariant='outlined'
                  value={myReadyDate}
                  onChange={handleDateChange(`ready`)}
                />
              </Grid>
              <Grid item xs>
                <TimePicker
                  autoOk
                  fullWidth
                  minutesStep={5}
                  variant='inline'
                  orientation='portrait'
                  label='Ready By Time (Local time converted to UTC)'
                  size='small'
                  inputVariant='outlined'
                  value={myReadyTime}
                  onChange={handleTimeChange(`ready`)}
                />
              </Grid>
            </Grid>

            <Spacer />

            <Typography className={cls.titleTxt}>Pickup Time</Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <DatePicker
                  autoOk
                  fullWidth
                  variant='inline'
                  orientation='portrait'
                  format='MM/dd/yyyy'
                  label='Pickup Date (Local date converted to UTC)'
                  size='small'
                  inputVariant='outlined'
                  value={myPickupDate}
                  onChange={handleDateChange(`pickup`)}
                />
              </Grid>
              <Grid item xs>
                <TimePicker
                  autoOk
                  fullWidth
                  minutesStep={5}
                  variant='inline'
                  orientation='portrait'
                  label='Pickup Time (Local time converted to UTC)'
                  size='small'
                  inputVariant='outlined'
                  value={myPickupTime}
                  onChange={handleTimeChange(`pickup`)}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()} color='primary'>
            Save Times
          </ModalAction>
          <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveTimeModal;
