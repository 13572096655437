//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TimelineIndexHead, TimelineIndexBody } from '../index';

//////////////////////// COMPONENT ////////////////////////

export default function TimelineIndex(props) {
  const cls = useStyles();

  return (
    <div className={cls.timelineIndex}>
      <TimelineIndexHead />
      <TimelineIndexBody />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timelineIndex: {
    zIndex: 110,
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    left: 0,
    minWidth: 256,
    maxWidth: '100%',
    height: '100%',
  },
}));
