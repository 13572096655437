import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useTheme, makeStyles, Typography, Chip, Icon } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';
import useDriverPayouts from './useDriverPayouts';

import CancelPayoutModal from '../../components/CancelPayoutModal';
import { Table } from '../../components/TableComponents';

const driverpayoutStatuses = [`successful`, `processing`, `new`, `canceled`, `failed`];

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayoutsTable({ driverpayouts, regionId, driverId, status, search, refetch }) {
  const theme = useTheme();
  const cls = useStyles();
  const { goToPayoutDetails, capEach, getReadableText, formatUSD, getUserName, getInitialsFromName } = useTools();
  const { formatTime } = useDriverPayouts();

  const [filteredDriverpayouts, setFilteredDriverpayouts] = React.useState([]);

  const [cancelModal, setCancelModal] = React.useState({ open: false, input: null, output: null });
  const handleCancelPayoutModalOpen = (input = null) => {
    setCancelModal({ open: true, input: input, output: null });
  };
  const handleCancelPayoutModalClose = async (output = null) => {
    setCancelModal({ open: false, input: null, output: output });
  };

  React.useEffect(() => {
    let newDriverpayouts = [...driverpayouts];

    if (regionId) newDriverpayouts = newDriverpayouts.filter(n => n.driver.region_id === regionId);

    if (driverId) newDriverpayouts = newDriverpayouts.filter(n => n.driver_id === driverId);

    if (status && status !== `all`) newDriverpayouts = newDriverpayouts.filter(n => n.status === status);

    if (search)
      newDriverpayouts = newDriverpayouts.filter(
        n =>
          (n.driver_id && `${n.driver_id}`.includes(search)) ||
          getUserName(n.driver).toLocaleLowerCase().includes(search) ||
          (n.status && n.status.toLocaleLowerCase().includes(search))
      );

    setFilteredDriverpayouts(newDriverpayouts);
    // eslint-disable-next-line
  }, [driverpayouts, regionId, driverId, status, search]);

  // Table config
  const config = [
    {
      name: `id`,
      value: dp => dp.id,
      label: `ID`,
      tooltip: `ID of the payout`,
    },
    {
      name: `type`,
      value: dp => getReadableText(dp.type),
      label: `Type`,
      tooltip: `Type of payout`,
    },
    {
      name: `avatar_img`,
      value: dp => getPropValue(dp, `driver.user.display_name`),
      renderer: dp =>
        getPropValue(dp, `driver.user.avatar_url`) ? (
          <div className={cls.avatar}>
            <img className={cls.avatarImg} src={dp.driver.user.avatar_url} alt='avatar' />
          </div>
        ) : getPropValue(dp, `driver.user.display_name`) ? (
          <div className={cls.avatar}>
            <div className={cls.avatarImg}>
              <Typography className={cls.initialsTxt}>
                {getInitialsFromName({ driver_name: dp.driver.user.display_name || null })}
              </Typography>
            </div>
          </div>
        ) : null,
      label: `Avatar`,
      tooltip: `Image of the driver`,
    },
    {
      name: `display_name`,
      value: dp => getPropValue(dp, `driver.user.display_name`),
      renderer: dp =>
        getPropValue(dp, `driver.user.display_name`) && getPropValue(dp, `driver.id`)
          ? `${getUserName(dp)} (${dp.driver.id})`
          : null,
      label: `Name`,
      tooltip: `Name of the driver`,
    },
    {
      name: `date_paid`,
      value: dp => dp.created_at,
      renderer: dp => formatTime(dp.created_at),
      label: `Date Paid`,
      tooltip: `The date & time this payout was made`,
      style: { minWidth: 120 },
    },
    {
      name: `status`,
      value: dp => driverpayoutStatuses.indexOf(dp.status),
      renderer: dp => (
        <div className={cls.statusBox}>
          {dp.status.includes(`success`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
              check_circle
            </Icon>
          )}
          {dp.status.includes(`process`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.warning.main }}>
              pending
            </Icon>
          )}
          {dp.status.includes(`new`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
              new_releases
            </Icon>
          )}
          {dp.status.includes(`fail`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
              cancel
            </Icon>
          )}
          {dp.status.includes(`cancel`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.default.main }}>
              block
            </Icon>
          )}
          <Typography className={cls.statusTxt}>{capEach(dp.status)}</Typography>
        </div>
      ),
      label: `Status`,
      tooltip: `Status of the payout`,
    },
    {
      name: `amount`,
      value: dp => dp.active,
      renderer: dp => (
        <Chip
          label={formatUSD(dp.amount, { removeSign: true })}
          icon={<Icon>attach_money</Icon>}
          classes={{
            label: cls.chipLabel,
          }}
        />
      ),
      label: `Amount`,
      tooltip: `Amount of the payout`,
      align: `right`,
    },
  ];

  // Table row actions
  const rowActions = [
    {
      label: `Cancel Transfer`,
      handler: data => handleCancelPayoutModalOpen({ payout: data, refetch }),
      disabled: data =>
        !data.transfer_token || data.status === `successful` || data.status === `failed` || data.status === `canceled`,
    },
  ];

  return (
    <>
      <CancelPayoutModal open={cancelModal.open} input={cancelModal.input} onClose={handleCancelPayoutModalClose} />

      <div className={cls.paper}>
        <Table
          data={filteredDriverpayouts}
          columnConfig={config}
          onRowClick={dp => goToPayoutDetails(dp.id)}
          rowActions={rowActions}
          defaultOrderBy={'id'}
          defaultOrder={'desc'}
          tableAriaLabel={`driverpayouts`}
        />
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  table: {
    overflowY: `hidden`,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },

  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },

  statusBox: {
    position: 'relative',
    display: 'flex',
  },
  statusIcon: {
    fontSize: 20,
    marginRight: 8,
  },
  statusTxt: {
    fontSize: 14,
  },

  chipLabel: {
    marginTop: 2,
    marginLeft: -4,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
}));
