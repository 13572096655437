import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTools } from '../../hooks/useTools';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { Table } from '../../components/TableComponents';

export default function LocationsTable(props) {
  const cls = useStyles();

  const { locations } = props;
  const { goToLocationDetails, getCleansedPhoneNumber } = useTools();

  const columnConfig = [
    {
      name: `id`,
      value: location => location.id, // location.id is the value for this column
      align: `left`,
      label: `Location\xa0ID`,
    },
    {
      name: `name`,
      value: location => location.name,
      align: `left`,
      label: `Name`,
      tooltip: `Name of the location`,
      sortable: true,
    },
    {
      name: `address`,
      value: location => location.address,
      align: `left`,
      label: `Address`,
      tooltip: `Address`,
      sortable: true,
    },
    {
      name: `customer.name`,
      value: location => getPropValue(location, 'customer.name'),
      align: `left`,
      label: `Customer`,
      tooltip: `Name of customer that owns this location`,
      sortable: true,
    },
    {
      name: `email`,
      value: location => location.email,
      align: `left`,
      label: `Email`,
      sortable: true,
    },
    {
      name: `phone`,
      value: location => getCleansedPhoneNumber(location.phone),
      align: `right`,
      label: `Phone`,
      sortable: true,
    },
  ];

  const rowActions = [
    {
      label: `Go To Location Details`,
      handler: location => goToLocationDetails(location.id),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={locations}
        columnConfig={columnConfig}
        onRowClick={location => goToLocationDetails(location.id)}
        rowActions={rowActions}
        defaultOrderBy={'id'}
        defaultOrder={'asc'}
        tableAriaLabel={`locations`}
        stickyHeader={true}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
}));
