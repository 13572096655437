import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client'
import { makeStyles, Container, Button, FormControl, InputLabel, Select, 
MenuItem, TextField, Grid, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Ajv from 'ajv';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import { rulesSchema, promoSchema } from './schema';

import { useData } from '../../providers/DataProvider';
import { GET_RULE_DETAILS, GET_TEMPLATES, GET_RULETYPES, UPDATE_RULE , SAVE_RULE } from './gql';

var ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}
var validate = ajv.compile(rulesSchema);
var validatePromo = ajv.compile(promoSchema);

const log = false;

export default function RulesDetails(props) {
    const ctx = useData();
    const cls = useStyles();

    var rule = props.match.params.id;

    // HOOKS ////////////////////////////////////////////////////////////////////////////////////////////////}
    var [ getDetails, { loading, error, data: rulesData }] = useLazyQuery(GET_RULE_DETAILS, { variables: { id: rule } });
    var { loading: tempLoading, error: tempError, data: tempData } = useQuery(GET_TEMPLATES);
    var { loading: typeLoading, error: typeError, data: typeData } = useQuery(GET_RULETYPES)

    // STATES ////////////////////////////////////////////////////////////////////////////////////////////////
    const [ type, setType ] = useState();
    const [ rules, setRules ] = useState({
        id: null,
        name: null,
        type: null,
        typename: null,
        description: null,
        jsonb: null
    })

    useEffect(() => { if (rule !== 'add') getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [rule])

    // CREATE RULES OBJECT ///////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rulesData && rulesData.businessrules && rulesData.businessrules[0]) handleRules(rulesData.businessrules[0])
        else if (tempData && tempData.businessrules && tempData.businessrules[0]) { handleRules(tempData.businessrules[0])}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rulesData, tempData]);

    // LOGIC ///////////////////////////////////////////////////////////////////////////////////////////////////

    const handleRules = data => {
        log && console.log("Setting Rules Object......")
        setRules({
            id: data.id ?? null,
            name: data.name ?? null,
            type: data.type ?? null,
            typename: data.name ?? null,
            description: data.description ?? null,
            jsonb: { ...data.jsonb } ?? null
        })
    }

    const handleChange = obj => {
        log && console.log("handleChange", obj, obj.jsObject)
        setRules({ ...rules, jsonb: { ...obj.jsObject } })
    }
    const handleTemplateSelect = event => {
        let templates = tempData.businessrules;
        let selectedTemplate = templates.find(temp => temp.id === event.target.value );
        let templateJSON = (selectedTemplate && selectedTemplate.jsonb) ? selectedTemplate.jsonb : {};

        setRules({ ...rules, jsonb: templateJSON})
    };

    const handleTypeChange = event => {
        setType(event.target.value) 
        setRules({...rules, type: event.target.value})
    }

    const updateRule = async () => {
        let validJson = await validateJson(rules.jsonb)
        console.log('valid json', validJson)
        if (validJson) {
            ctx.apolloClient.mutate({
                mutation: UPDATE_RULE,
                variables: { id: rules.id, name: rules.name, description: rules.description, jsonb: rules.jsonb, type: rules.type },
            }).then(res => {
                if (res.data.update_businessrules) {
                    if (log) console.log("updateRule - UPDATE_RULE response (res.data):", res.data);
                    toast.success('Rule Successfully Updated')
                } else { 
                    log && console.log("updateRule - UPDATE_RULE response (res.data):", res.data);
                    toast.error('Failed to Update Rule') 
                }
            }).catch(err => {
                console.log("hasura Error" + err.toString())
            })
        }
    }

    const saveRule = async () => {
        let validJson = await validateJson(rules.jsonb)
        log && console.log('valid json', validJson)
        if (validJson) {
            ctx.apolloClient.mutate({
                mutation: SAVE_RULE,
                variables: { name: rules.name, description: rules.description, jsonb: rules.jsonb, type: rules.type },
            }).then(res => {
                if (res.data.insert_businessrules) {
                    log && console.log("saveRule - SAVE_RULE response (res.data):", res.data);
                    toast.success(`Successfully Saved Rule: ${ rules.name }`)
                } else {
                    log && console.log("saveRule - error response:", res)
                    toast.error('Failed to Save Rule')
                }
            }).catch(err => {
                console.log("hasura Error" + err.toString())
            })
        }
    }

    const validateJson = async (jsonObj) => {
        var valid = validate(jsonObj);
        if (valid) { return true } 
        else {
            if (type === 2 && validatePromo(jsonObj)) { return true }
            console.log("VALIDATE ERRORS", validate.errors[0].message)
            toast.error(`Invalid JSON: ${validate.errors[0].message}` )
            return false
        }
    }
    
    // RENDER ///////////////////////////////////////////////////////////////////////////////////////////////////
    if ( loading || tempLoading || typeLoading ) return <Loading fixed />
    if ( error || tempError || typeError ) {
        console.error('Error Fetching Rule Details:', error);
        Sentry.captureException(error);
        if ( error || tempError ) return <DefaultErrorFallback message={`ERROR FETCHING RULES DETAILS`}/>
    } else if ( rules && rules.id) {
        var types = typeData && typeData.businessruletypes ? Object.values(typeData.businessruletypes) : null;
        log && console.log("Rule:", rules)

        var templates = tempData && tempData.businessrules ? tempData.businessrules : null;

        return (<div className={cls.root}>
            <Container maxWidth="xl">
                <Typography
                    className={cls.header}
                    variant={'h6'}
                > { rule === 'add' ? `Create Rule` : `Edit Rule` }
                </Typography>
                <Grid container direction="row">
                    <Grid container item direction="column" md={6} sm={12}>
                    { types && types.length > 0 &&       
                        <FormControl variant="outlined" className={cls.formControl}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                id="demo-simple-select-outlined"
                                defaultValue="" 
                                value={rules.type.id}
                                onChange={(event) => { handleTypeChange(event) } }
                                label="Type"
                            > 
                            { types.map(type =>
                                <MenuItem key={type.id + 'typeMenuItem'} value={type.id}>{type.name}</MenuItem>
                            )} 
                            </Select>
                        </FormControl> 
                    }
                        <TextField
                            name="nameField"
                            label="Name"
                            style={{ marginLeft: "45px", width: "500px" }}
                            value={ rules.name || '' }
                            onChange={(event) => setRules({...rules, name: event.target.value})}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            name="descriptionField"
                            label="Description"
                            style={{ marginLeft: "45px", width: "500px" }}
                            multiline
                            value={ rules.description || ''}
                            onChange={(event) => setRules({...rules, description: event.target.value})}
                            margin="normal"
                            variant="outlined"
                        />

                        <Button
                            id={ rule === 'add' ? 'saveRuleButton' : 'updateRuleButton' }
                            className={ cls.button }
                            disabled={ rule === 'add' ? !(rules.name && rules.description && rules.jsonb && rules.type) : !(rules.id && rules.name && rules.description && rules.jsonb && rules.type)}
                            onClick={() => { rule === 'add' ? saveRule() : updateRule() }}
                            color='primary'
                            size='medium'
                            variant='contained'
                        > { rule === 'add' ? `Create Rule` : `Save Changes` }
                        </Button>
                    </Grid>
                    <Grid container item direction="column" md={6} sm={12}>
                        { templates && (
                            <FormControl variant="outlined" style={{ width: '500px', paddingBottom: '6px' }}>
                                <InputLabel>Template</InputLabel>
                                <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    defaultValue="" 
                                    onChange={handleTemplateSelect}
                                    label="Template"
                                >
                                    <MenuItem value={''}><em>None</em></MenuItem>
                                    { templates.map(br =>
                                        <MenuItem key={br.id + 'menuItem'} value={br.id}>{br.name}</MenuItem>
                                    ) }
                                </Select>
                            </FormControl> 
                        ) }
                        <JSONInput
                            key={ rules.id + 'JSONInput' }
                            id={ rules.id }
                            placeholder={ rules.jsonb }
                            onChange={handleChange}
                            locale={locale}
                            height='550px'
                            width='500px'
                        />
                    </Grid>
                </Grid>
            </Container>
        </div> );
    } else return <DefaultEmptyFallback message='NO RULE FOUND'/>
}

const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: "45px",
        width: "500px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    header: {
        marginLeft: "50px",
        marginBottom: "20px",
        fontWeight: '700',
        fontSize: '22px',
    },
    button: {
        margin: "5px 0px 5px 50px",
        textAlign: "center",
        width: "35%",
        boxShadow: `none`,
        '&:hover, &:active': {
          boxShadow: `none`,
        },
    }
}));


