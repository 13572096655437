import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Toolbar from '../../components/Toolbar';

import { UserDetailsProvider } from '../../providers/UserDetailsProvider';
import UserInfoEntry from './UserInfoEntry';
import UserToolbar from '../../components/UserManagement/UserToolbar';


const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function UserDetails(props) {
  const cls = useStyles();

  // DATA STATE //
  const user = {};

  return (
    <UserDetailsProvider user={user}>

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Input'>
            <UserToolbar addUserMode={true} />
          </Toolbar>

          <Spacer />

          {/* USER BASE INFO */}
          <UserInfoEntry />

          <Spacer />
        </Container>
      </div>
    </UserDetailsProvider>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
