// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getDistance } from 'geolib';

import { gql } from '@apollo/client';

import { useData } from '../../providers/DataProvider';

// HELPERS -------------------------------------------------- //

const log = true;

// Initialize fallbacks
const fallbackPoints = [];
const fallbackMove = null;
const fallbackLocations = [];
const fallbackSelectedLocation = null;
const fallbackShowRaw = false;
const fallbackShowPings = false;
const fallbackDriverId = ``;
const fallbackMoveId = ``;
const fallbackStart = dayjs().startOf(`day`).format();
const fallbackEnd = dayjs().add(1, `day`).startOf(`day`).format();

// Initialize local storage getters
const getDefaultDriverId = () => {
  const localDriverId = localStorage.getItem(`gps-viewer-driver-id`) || fallbackDriverId;
  return localDriverId;
};
const getDefaultMoveId = () => {
  const localMoveId = localStorage.getItem(`gps-viewer-move-id`) || fallbackMoveId;
  return localMoveId;
};
const getDefaultStart = () => {
  const localStart = localStorage.getItem(`gps-viewer-start`) || fallbackStart;
  return localStart;
};
const getDefaultEnd = () => {
  const localEnd = localStorage.getItem(`gps-viewer-end`) || fallbackEnd;
  return localEnd;
};
const getDefaultShowRaw = () => {
  const localShowRaw = localStorage.getItem(`gps-viewer-show-raw`) === `true` ? true : fallbackShowRaw;
  return localShowRaw;
};
const getDefaultShowPings = () => {
  const localShowPings = localStorage.getItem(`gps-viewer-show-pings`) === `true` ? true : fallbackShowPings;
  return localShowPings;
};

// CONTEXT -------------------------------------------------- //

const GPSViewerContext = React.createContext({});

// PROVIDER -------------------------------------------------- //

function GPSViewerProvider({ children }) {

  const { apolloClient } = useData();

  // BASE STATE //
  const [rawPoints, setRawPoints] = React.useState(fallbackPoints);
  const [filteredPoints, setFilteredPoints] = React.useState(fallbackPoints);
  const [smoothPoints, setSmoothPoints] = React.useState(fallbackPoints);
  const [mapPoints, setMapPoints] = React.useState(fallbackPoints);
  const [move, setMove] = React.useState(fallbackMove);
  const [locations, setLocations] = React.useState(fallbackLocations);
  const [selectedLocation, setSelectedLocation] = React.useState(fallbackSelectedLocation);
  const [selectedPing, setSelectedPing] = React.useState(fallbackSelectedLocation);

  // QUERY STATE //
  const [driverId, setDriverId] = React.useState(getDefaultDriverId());
  const [moveId, setMoveId] = React.useState(getDefaultMoveId());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());
  const [showRaw, setShowRaw] = React.useState(getDefaultShowRaw());
  const [showPings, setShowPings] = React.useState(getDefaultShowPings());

  // HELPER FUNCTIONS //

  /** Find the angle of 3 points (B is the center point)
   *
   * We use this as a way of detecting angles since the "heading" field is unreliable
   */
  const getAngleOfThreePoints = (A, B, C) => {
    var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
    var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
    return (Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) / Math.PI;
  };

  /** Reduce the amount of points by an algorithm that checks distance and heading angle */
  const getFilteredPoints = async points => {
    // Initialize filtered points
    let filteredPoints = [...points];

    // Using a for loop instead of a filter so we can modify the array while looping over it
    for (let fpIndex = 0; fpIndex < filteredPoints.length; fpIndex++) {
      // Initialize useful points and their lat/lng
      const prevOfPrevPoint = filteredPoints[fpIndex - 2];
      const prevOfPrevPointLat = prevOfPrevPoint ? prevOfPrevPoint.location.coordinates[1] : null;
      const prevOfPrevPointLng = prevOfPrevPoint ? prevOfPrevPoint.location.coordinates[0] : null;

      const prevPoint = filteredPoints[fpIndex - 1];
      const prevPointLat = prevPoint ? prevPoint.location.coordinates[1] : null;
      const prevPointLng = prevPoint ? prevPoint.location.coordinates[0] : null;

      const curPoint = filteredPoints[fpIndex];
      const curPointLat = curPoint ? curPoint.location.coordinates[1] : null;
      const curPointLng = curPoint ? curPoint.location.coordinates[0] : null;

      const nextPoint = filteredPoints[fpIndex + 1];
      const nextPointLat = nextPoint ? nextPoint.location.coordinates[1] : null;
      const nextPointLng = nextPoint ? nextPoint.location.coordinates[0] : null;

      // Check if there is a previous and next point (so we always include first and last points)
      if (prevOfPrevPoint && prevPoint && curPoint && nextPoint) {
        // Get the distance between the current point and previous point
        const distanceToPrevPoint = getDistance(
          { latitude: prevPointLat, longitude: prevPointLng },
          { latitude: curPointLat, longitude: curPointLng },
          1
        );

        // Set the previous accurate heading angle based on the previous and previous before that points
        const prevAngle = getAngleOfThreePoints(
          { x: prevOfPrevPointLat, y: prevOfPrevPointLng },
          { x: prevPointLat, y: prevPointLng },
          { x: curPointLat, y: curPointLng }
        );

        // Set the current accurate heading angle based on the previous and next points
        const curAngle = getAngleOfThreePoints(
          { x: prevPointLat, y: prevPointLng },
          { x: curPointLat, y: curPointLng },
          { x: nextPointLat, y: nextPointLng }
        );

        // If the distance is not far enough away from the previous point, remove it and roll-back
        // If the previous point's angle is within a specific degree, remove it and roll-back
        const maxDistance = 15.24; // In Meters (1m = 3ft 3.37in)
        const maxAngle = 2; // In Degrees
        if (distanceToPrevPoint < maxDistance || Math.abs(prevAngle - curAngle) < maxAngle) {
          filteredPoints.splice(fpIndex, 1);
          fpIndex--;
        }
      }
    }

    // Remove falsey values from array
    filteredPoints = filteredPoints.filter(Boolean);

    // Return filtered points
    log && console.log(`Filtered Points:`, filteredPoints);
    return filteredPoints;
  };

  /** Call the Google Roads API to snap & smooth the points
   *
   * Google can only take in 100 points at a time which may lead to multiple API calls
   * We currently have a cap of 20 calls
   */
  const getSmoothPoints = async (filteredPoints, apiKey = process.env.REACT_APP_GOOGLE_API_KEY) => {
    // Initialize smooth points
    let smoothPoints = [];

    // Try and use Google's Roads API to snap the points
    try {
      // Detect the number of API calls we have to do
      const apiCallCount = Math.ceil(filteredPoints.length / 100);

      // Throw an error if theres too many points being requested
      if (apiCallCount > 20) {
        throw new Error(
          `More than 20 API calls to Google were expected. Skipping Google call. Driver's path will not be snapped & smoothed...`
        );
      }

      // Build multiple arrays of points in segments of 100 so Google can work with the data
      const slicedPoints = [];
      for (let pIndex = 0; pIndex / 100 < apiCallCount; pIndex += 100) {
        const singleSlicedPoints = [...filteredPoints].slice(pIndex, pIndex + 100);
        slicedPoints.push(singleSlicedPoints);
      }

      // Do multiple API calls if necessary
      let res = await Promise.allSettled(
        slicedPoints.map(async (singleSlicedPoints, i) => {
          // Format points into a string for Google's API to use
          let combinedPoints = ``;
          singleSlicedPoints.forEach((p, i) => {
            combinedPoints += `${p.location.coordinates[1]},${p.location.coordinates[0]}`;
            if (i < singleSlicedPoints.length - 1) combinedPoints += `|`;
          });

          // Call Google's Roads API to snap the points to a road and smooth it out.
          const snapRes = await axios({
            url: `https://roads.googleapis.com/v1/snapToRoads?path=${combinedPoints}&interpolate=true&key=${apiKey}`,
            method: `GET`,
            headers: {
              'content-type': `application/json`,
            },
          });

          // Check for response
          // log && console.log(`Google Response #${i + 1}:`, snapRes);
          if (getPropValue(snapRes, `data.snappedPoints.length`)) {
            let resPoints = snapRes.data.snappedPoints;
            resPoints = resPoints.map(sp => {
              if (getPropValue(sp, `location.latitude`) && getPropValue(sp, `location.longitude`)) {
                return {
                  latitude: sp.location.latitude,
                  longitude: sp.location.longitude,
                };
              }
              return null;
            });
            return resPoints;
          }
        })
      );

      smoothPoints = res.map(e => e.value).flat();
    } catch (err) {
      console.error(`Driver's path was not snapped and smoothed:`, err);
    }

    // Remove falsey values from array
    smoothPoints = smoothPoints.filter(Boolean);

    // Return smooth points
    log && console.log(`Smooth Points:`, smoothPoints);
    return smoothPoints;
  };

  /** Convert points to be usable with the map component */
  const convertToMapPoints = points => {
    const newMapPoints = points.map(dl => {
      return {
        lat: dl.latitude || dl.location.coordinates[1],
        lng: dl.longitude || dl.location.coordinates[0],
      };
    });

    // Return map points
    log && console.log(`Map Points:`, newMapPoints);
    setMapPoints(newMapPoints);
    return newMapPoints;
  };

  // HANDLER FUNCTIONS //

  // Handle driver ID state
  const onDriverIdChange = value => {
    const newValue = value || fallbackDriverId;
    log && console.log(`onDriverIdChange:`, newValue);
    localStorage.setItem(`gps-viewer-driver-id`, newValue);
    setDriverId(newValue);
  };

  // Handle move ID state
  const onMoveIdChange = value => {
    const newValue = value || fallbackMoveId;
    log && console.log(`onMoveIdChange:`, newValue);
    localStorage.setItem(`gps-viewer-move-id`, newValue);
    setMoveId(newValue);
  };

  // Handle start and end state
  const onDateChange = (value, type) => {
    // Check the new value
    const newValue = dayjs(value).format();
    log && console.log(`onDateChange:`, newValue);
    if (newValue === `Invalid Date`) {
      console.error(`Invalid datetime provided!`);
      toast.error(`Invalid datetime provided!`);
      return;
    }

    // Set start time
    // If the start date is more than 1 day before the end date,
    // or if the start date is after the end date, move the end date
    if (type === `start`) {
      if (dayjs(newValue).isSameOrBefore(dayjs(end).subtract(1, `day`)) || dayjs(newValue).isSameOrAfter(dayjs(end))) {
        const newEnd = dayjs(newValue).add(1, `day`).startOf(`day`).format();
        localStorage.setItem(`gps-viewer-end`, newEnd);
        setEnd(newEnd);
      }

      localStorage.setItem(`gps-viewer-start`, newValue);
      setStart(newValue);
    }

    // Set end time
    // If the end date is before the start date,
    // or if the end date is more than 1 day after the start date, move the start date
    if (type === `end`) {
      if (dayjs(newValue).isSameOrBefore(dayjs(start)) || dayjs(newValue).isSameOrAfter(dayjs(start).add(1, `day`))) {
        const newStart = dayjs(newValue).subtract(1, `day`).startOf(`day`).format();
        localStorage.setItem(`gps-viewer-start`, newStart);
        setStart(newStart);
      }

      localStorage.setItem(`gps-viewer-end`, newValue);
      setEnd(newValue);
    }
  };

  // Handle show raw state
  const onShowRawChange = value => {
    const newValue = value || fallbackShowRaw;
    log && console.log(`onShowRawChange:`, newValue);
    localStorage.setItem(`gps-viewer-show-raw`, newValue);
    setShowRaw(newValue);
  };

  // Handle show pings state
  const onShowPingsChange = value => {
    const newValue = value || fallbackShowPings;
    log && console.log(`onShowPingsChange:`, newValue);
    localStorage.setItem(`gps-viewer-show-pings`, newValue);
    setShowPings(newValue);
  };

  // Handler for clearing all filters (setting to default page state)
  const onClearFilters = () => {
    setRawPoints(fallbackPoints);
    setFilteredPoints(fallbackPoints);
    setSmoothPoints(fallbackPoints);
    setMapPoints(fallbackPoints);
    setLocations(fallbackLocations);
    setSelectedLocation(fallbackSelectedLocation);
    setSelectedPing(fallbackSelectedLocation);
    setMove(fallbackMove);
    onDriverIdChange(fallbackDriverId);
    onMoveIdChange(fallbackMoveId);
    onDateChange(fallbackStart, `start`);
    onDateChange(fallbackEnd, `end`);
    onShowRawChange(fallbackShowRaw);
    onShowPingsChange(fallbackShowPings);
  };

  // QUERY FUNCTIONS //

  // Fetch driverlocations by driver
  const fetchGPSPingsByDriver = async () => {
    try {
      const res = await apolloClient.query({
        query: GET_GPS_PINGS_BY_DRIVER,
        variables: { driverId: Number(driverId), start, end },
        fetchPolicy: `network-only`,
      });

      const newRawPoints = getPropValue(res, `data.driverlocations`) || [];
      log && console.log(`Raw Points:`, newRawPoints);
      setRawPoints(newRawPoints);

      if (!newRawPoints.length) {
        log && console.warn(`GPS points for driver #${driverId} not found within date range!`);
        toast.warning(`GPS points for driver #${driverId} not found within date range!`);
        return [];
      }

      if (!showRaw) {
        const newFilteredPoints = await getFilteredPoints(newRawPoints);
        const newSmoothPoints = await getSmoothPoints(newFilteredPoints);
        setFilteredPoints(newFilteredPoints);
        setSmoothPoints(newSmoothPoints);
        return newSmoothPoints;
      } else {
        return newRawPoints;
      }
    } catch (err) {
      console.error(`Failed to fetch driverlocations by driver:`, err);
      toast.error(`Failed to fetch driverlocations by driver!`);
      return null;
    }
  };

  // Fetch driverlocations by move
  const fetchGPSPingsByMove = async () => {
    try {
      const res = await apolloClient.query({
        query: GET_GPS_PINGS_BY_MOVE,
        variables: { moveId: Number(moveId) },
        fetchPolicy: `network-only`,
      });

      const newRawPoints = getPropValue(res, `data.driverlocations`) || [];
      log && console.log(`Raw Points:`, newRawPoints);
      setRawPoints(newRawPoints);

      if (!newRawPoints.length) {
        log && console.warn(`GPS points for move #${moveId} not found within date range!`);
        toast.warning(`GPS points for move #${moveId} not found within date range!`);
        return [];
      }

      if (!showRaw) {
        const newFilteredPoints = await getFilteredPoints(newRawPoints);
        const newSmoothPoints = await getSmoothPoints(newFilteredPoints);
        setFilteredPoints(newFilteredPoints);
        setSmoothPoints(newSmoothPoints);
        return newSmoothPoints;
      } else {
        return newRawPoints;
      }
    } catch (err) {
      console.error(`Failed to fetch driverlocations by move:`, err);
      toast.error(`Failed to fetch driverlocations by move!`);
      return null;
    }
  };

  // Fetch move related to driverlocations
  const fetchMove = async () => {
    try {
      const res = await apolloClient.query({ query: GET_GPS_MOVE, variables: { moveId }, fetchPolicy: `network-only` });
      const newMove = getPropValue(res, `data.moves.length`) ? res.data.moves[0] : null;
      if (newMove) {
        log && console.log(`Move #${moveId} found:`, newMove);
        let newLocations = [];
        let pickup = { ...newMove.lane.pickup };
        pickup.type = `pickup_loc`;
        newLocations.push(pickup);
        let delivery = { ...newMove.lane.delivery };
        delivery.type = `delivery_loc`;
        newLocations.push(delivery);
        setLocations(newLocations);
      } else {
        log && console.log(`Move #${moveId} not found!`);
        toast.warning(`Move #${moveId} not found!`);
      }
      setMove(newMove);
      return newMove;
    } catch (err) {
      console.error(`Failed to fetch move:`, err);
      toast.error(`Failed to fetch move!`);
      return null;
    }
  };

  // Set Context
  const context = {
    // Main State
    rawPoints,
    filteredPoints,
    smoothPoints,
    mapPoints,
    move,
    locations,
    selectedLocation,
    selectedPing,
    showRaw,
    showPings,

    // Filter State
    driverId,
    moveId,
    start,
    end,

    // Handlers
    setSelectedLocation,
    setSelectedPing,
    convertToMapPoints,
    onDriverIdChange,
    onMoveIdChange,
    onDateChange,
    onShowRawChange,
    onShowPingsChange,
    onClearFilters,

    // Fetchers
    fetchGPSPingsByDriver,
    fetchGPSPingsByMove,
    fetchMove,
  };

  return <GPSViewerContext.Provider value={context}>{children}</GPSViewerContext.Provider>;
}

// HOOK -------------------------------------------------- //

const useGPSViewer = () => React.useContext(GPSViewerContext);

// GRAPHQL -------------------------------------------------- //

const GET_GPS_PINGS_BY_DRIVER = gql`
  query admin_getGpsPingsByDriver($driverId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    driverlocations(
      where: { driver_id: { _eq: $driverId }, time: { _gte: $start, _lte: $end } }
      order_by: { time: asc }
      limit: 5000
    ) {
      id
      accuracy
      activity_confidence
      activity_type
      altitude
      altitude_accuracy
      battery_is_charging
      battery_level
      driver_id
      heading
      location
      move_id
      speed
      time
    }
  }
`;

const GET_GPS_PINGS_BY_MOVE = gql`
  query admin_getGpsPingsByMove($moveId: bigint!) {
    driverlocations(where: { move_id: { _eq: $moveId } }, order_by: { time: asc }, limit: 5000) {
      id
      accuracy
      activity_confidence
      activity_type
      altitude
      altitude_accuracy
      battery_is_charging
      battery_level
      driver_id
      heading
      location
      move_id
      speed
      time
    }
  }
`;

const GET_GPS_MOVE = gql`
  query admin_getGpsMove($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId } }) {
      id
      status
      cancel_status
      lane {
        pickup {
          id
          name
          address
          latitude
          longitude
        }
        delivery {
          id
          name
          address
          latitude
          longitude
        }
      }
    }
  }
`;

// EXPORT -------------------------------------------------- //

export { useGPSViewer, GPSViewerProvider };
