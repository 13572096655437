import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';

import { useEngine } from '../../hooks/useEngine';
import { GET_APPAYMENTS } from './gql';

import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import { AccordionTable, AccordionRow, TableSort } from '../../components/AccordionTable';

const log = false;

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

export default function APReportWrapper({status, driverId, start, end}) {
  const cls = useStyles();
  const history = useHistory();
  const { rerunAP } = useEngine();

  const getARMovesVariables = () => {
    //{ status: status, driverId: driverId, start: start, end: end }
    let variables = {};
    if (driverId) variables.driverId = driverId;
    if (status) variables.status = status;
    variables.start = start;
    variables.end = end;
    return variables;
  }

  // HOOK ////////////////////////////////////////////////////////////////////////////////////
  const { loading, error, data, refetch } = useQuery(GET_APPAYMENTS(status, driverId), {
    variables: getARMovesVariables(),
  });

  // STATES ////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  // LOGIC ////////////////////////////////////////////////////////////////////////////////////
  const cap = str => {
    if (str) {
      if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
      else {
        let arr = str.split(` `);
        arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
        return arr.join(` `);
      }
    }
  };

  const checkNeg = num => {
    if (num > 0) return num;
    else return 0;
  };

  const round = (num, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  };

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.move.driver_name && o.move.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.id && (o.move.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.move.move_type && o.move.move_type.toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = appayments => {
    const createCsvRow = appayment => {
      return {
        ID: appayment.id,
        DRIVER: appayment.move.driver_name || `HopDriver`,
        MOVE_ID: appayment.move.id,
        DATE: dayjs(appayment.move ? appayment.move.pickup_time : appayment.createdat).format(`MM/DD/YYYY`),
        TYPE:
          appayment.type && appayment.move.move_type
            ? cap(
                `${appayment.move.move_type} - ${appayment.type}${
                  appayment.type === `accessorial` ? `: ${appayment.accessorial.code}` : ``
                }`
              )
            : `-`,
        STATUS: appayment.status ? cap(appayment.status) : `-`,
        TOTAL: appayment.amount ? `$${appayment.amount.toFixed(2)}` : appayment.amount === 0 ? `$0.00` : `-`,
      };
    };
    const csvRows = appayments.map(appayment => createCsvRow(appayment));
    const csvOptions = {
      filename: `${driverId ? appayments[0].move.driver_name.replace(/ /g, '_') : `All`}_AP_Records_from_${
        start
      }_to_${end}`,
      showTitle: true,
      title: `${driverId ? appayments[0].move.driver_name : `All`} AP Records from ${start} to ${
        end
      }`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const goToMoveDetails = moveId => {
    history.push(`/moves/${moveId}`);
  };

  const getTableActions = appayments => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(appayments) }];
  };

  const getRowActions = (appayment, refetch) => {
    return [
      {
        label: `Go\xa0To\xa0Move\xa0Details`,
        handler: () => goToMoveDetails(appayment.move.id),
      },
      {
        label: `Rerun Accounts Payable`,
        handler: () => rerunAP(appayment.move, refetch),
      },
    ];
  };

  // RENDER ////////////////////////////////////////////////////////////////////////////////////
  if (loading) return <Loading fixed />;
  if (error) {
    console.log('Error Fetching AP Payments:', error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FETCHING AP REPORTS' />;
  }
  if (data && data.appayments && data.appayments.length > 0) {
    log && console.log('Data:', data);
    const filteredAP = applyFilters(data.appayments);

    // Set a consistent amount object that holds the totals
    var amount = {};

    // Valid moves to calculate base totals
    const subtotalPayments = filteredAP.filter(item => item.amount > 0);
    const paidPayments = subtotalPayments.filter(item => item.status === `paid`);

    // Base totals from valid moves
    amount.subtotal = round(
      subtotalPayments.length > 0
        ? subtotalPayments.map(item => item.amount).reduce((total, current) => total + current)
        : 0
    );
    amount.paid = round(
      paidPayments.length > 0 ? paidPayments.map(item => item.amount).reduce((total, current) => total + current) : 0,
      2
    );

    // Set calculations from base totals
    amount.total = checkNeg(amount.subtotal);
    amount.unpaid = checkNeg(amount.total - amount.paid);

    log && console.log(`AP Amount:`, amount);

    const headers = [
      { id: `ID`, alignLeft: true, numeric: true, label: `ID` },
      { id: `DRIVER`, alignLeft: true, numeric: false, label: `Driver` },
      { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
      { id: `DATE`, alignLeft: false, numeric: true, label: `Date` },
      { id: `TYPE`, alignLeft: true, numeric: false, label: `Type` },
      { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
      { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
    ];
    const rows = filteredAP.map(appayment => {
      return {
        ID: appayment.id,
        DRIVER: appayment.move.driver_name || `HopDriver`,
        MOVE_ID: appayment.move.id,
        DATE: dayjs(appayment.move ? appayment.move.pickup_time : appayment.createdat).format(`MM/DD/YYYY`),
        TYPE:
          appayment.type && appayment.move.move_type
            ? cap(
                `${appayment.move.move_type} - ${appayment.type}${
                  appayment.type === `accessorial` ? `: ${appayment.accessorial.code}` : ``
                }`
              )
            : `-`,
        STATUS: appayment.status ? cap(appayment.status) : `-`,
        TOTAL: appayment.amount ? `$${appayment.amount.toFixed(2)}` : appayment.amount === 0 ? `$0.00` : `-`,
        appayment: appayment,
      };
    });

    return (
      <>
        <AccordionTable
          title={`${rows.length} AP Records`}
          headers={headers}
          rows={rows}
          actions={getTableActions(data.appayments)}
          search={search}
          defaultOrder={defaultOrder}
          defaultOrderBy={defaultOrderBy}
          order={order}
          orderBy={orderBy}
          tablePage={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          setSearch={setSearch}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setTablePage={setTablePage}
          setRowsPerPage={setRowsPerPage}
          setExpandedRowId={setExpandedRowId}
          className={cls.table}
          refetch={refetch}
          refreshPersistAs='ar_report'
        >
          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
            .map(row => (
              <AccordionRow
                key={`ap-record-${row.appayment.id}`}
                rowId={row.appayment.id}
                expandedRowId={expandedRowId}
                setExpandedRowId={setExpandedRowId}
                columns={[
                  { align: 'left', value: row.ID },
                  { align: 'left', value: row.DRIVER },
                  { align: 'left', value: row.MOVE_ID },
                  { align: 'right', value: row.DATE },
                  { align: 'left', value: row.TYPE },
                  { align: 'left', value: row.STATUS },
                  { align: 'right', value: row.TOTAL },
                ]}
                actions={getRowActions(row.appayment, refetch)}
                onClick={() => goToMoveDetails(row.MOVE_ID)}
                className={cls.row}
              >
                <div />
              </AccordionRow>
            ))}
        </AccordionTable>

        <div className={cls.break} />
      </>
    );
  } else return <DefaultEmptyFallback message='NO AP REPORTS FOUND' />;
}

// STYLE ////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
