//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getUserEmail } from '../../utils/authHelper'
import { useSubscription, useMutation } from '@apollo/client';
import { useTools } from '../../hooks/useTools';
import sdk from '@hopdrive/sdk';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

import {
  makeStyles,
  useTheme,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import { Button } from '@hopdrive/storybook';
import Spacer from '../../components/Spacer';

import {
  GET_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION_ASSIGNMENT,
  UPDATE_NOTIFICATION_RESOLVED,
} from './gql';

import * as Sentry from '@sentry/react';

import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import { useNotificationsGlobals } from '../../providers/NotificationsProvider';
import { NotificationInfo } from './index';

//////////////////////// COMPONENT ////////////////////////

function NotificationDetails(props) {
  const cls = useStyles();
  const theme = useTheme();
  const { goToPreviousPage } = useTools();

  const { type, setType, title, setTitle, body, setBody, assignedTo, setAssignedTo, assignedAt, setAssignedAt, assignedBy, setAssignedBy, resolvedBy, setResolvedBy, resolvedAt, setResolvedAt, notes, setNotes, assignmentBell, setAssignmentBell, dueDate, setDueDate } = useNotificationsGlobals();

  const notificationId = props.match.params.id;

  const { loading, error, data } = useSubscription(GET_NOTIFICATION_DETAILS, {
    variables: { notificationId: notificationId },
  });
  const [updateNotificationDetails] = useMutation(UPDATE_NOTIFICATION_DETAILS);
  const [updateNotificationAssignment] = useMutation(UPDATE_NOTIFICATION_ASSIGNMENT);
  const [updateNotificationResolvedBy] = useMutation(UPDATE_NOTIFICATION_RESOLVED);

  const [editMode, setEditMode] = React.useState(
    props.location && props.location.state && props.location.state.editMode
  );
  const [actionsOpen, setActionsOpen] = React.useState(null);
  const [userEmail, setUserName] = React.useState(null)

  React.useEffect(() => {
    let email = getUserEmail()
    setUserName(email)
  }, [])

  // LOADING STATE //
  if (loading) {
    return <Loading fixed />;
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting notification details:`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR GETTING NOTIFICATION DETAILS' />;
  }

  // EMPTY STATE //
  if (!data || !data.notifications || !data.notifications.length > 0) {
    return <DefaultEmptyFallback message='NO NOTIFICATION FOUND' />;
  }

  // DATA STATE //
  const notification = data.notifications[0];

  const handleEditMode = () => {
    if (editMode) {
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const updateNotification = async () => {
    if (assignedTo !== null && assignedTo !== userEmail){
      handleSendEmail()
    }
    
    try {
      const variables = {
        id: notification.id,
        type: type || null,
        title: title || null,
        body: body || null,
        assignedto: assignedTo || null,
        assignedby: assignedBy || null,
        assignedat: assignedAt || null,
        resolvedby: resolvedBy || null,
        resolvedat: resolvedAt || null,
        notes: notes || null,
        duedate: dueDate || null,
      };
      const res = await updateNotificationDetails({ variables });
      if (res && res.data && res.data.update_notifications && res.data.update_notifications.affected_rows > 0) {
        toast.success(`Successfully updated notification!`);
        setEditMode(false);
        setAssignmentBell(true)
      }
    } catch (err) {
      toast.error(`Failed to update notification!`);
      console.error(`Failed to update notification:`, err);
    }

  };

  const updateNotificationAssigned = async notification => {
    
    try {
      setAssignmentBell(true)
      const variables = {
        id: notification.id,
        assignedto: userEmail || null,
        assignedby: userEmail || null,
        assignedat: dayjs().format() || null,
      };
      const res = await updateNotificationAssignment({ variables });
      if (res && res.data && res.data.update_notifications && res.data.update_notifications.affected_rows > 0) {
        toast.success(`Successfully updated notification!`);
        setActionsOpen(false);
      }
    } catch (err) {
      toast.error(`Failed to update notification!`);
      console.error(`Failed to update notification:`, err);
    }
  };

  const updateNotificationResolved = async notification => {
    try {
      const variables = {
        id: notification.id,
        resolvedby: userEmail || null,
        resolvedat: dayjs().format() || null,
      };
      const res = await updateNotificationResolvedBy({ variables });
      if (res && res.data && res.data.update_notifications && res.data.update_notifications.affected_rows > 0) {
        toast.success(`Successfully updated notification!`);
        setActionsOpen(false);
      }
    } catch (err) {
      toast.error(`Failed to update notification!`);
      console.error(`Failed to update notification:`, err);
    }
  };

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleSendEmail = async () => {
    try {
      await axios({
        method: 'POST',
        url: `/.netlify/functions/handleNotificationEmail`,
        data: {
          email: assignedTo,
          name: assignedTo,
          title: title,
          body: body,
          id: notification.id,
          dueDate: dueDate
        },
      })
        .then(res => {
          if (res && res.status === 200) {
            console.log('email sent')
          } else {
            console.error('error');
          }
        })
        .catch(err => {
          console.error('Failed to send email:', err);
          toast.error(`Failed to send email alert`);
        });
    } catch (err) {
      console.error('Unexpected error while sending email alert:', err);
      toast.error(`Failed to send email alert`);
    }
  }

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <IconButton className={cls.iconBtn} onClick={() => goToPreviousPage()}>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Grid>
          <Grid item>
            {notification.type === 'inform' ? (
              <Tooltip title='Information' placement='top'>
                <Icon style={{ color: '#3371FF', fontSize: '1.85rem' }}>inform-icon</Icon>
              </Tooltip>
            ) : (
              <Tooltip title='Action Item' placement='top'>
                <Icon style={{ color: 'red', fontSize: '1.85rem' }}>pending_actions</Icon>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs>
            <Typography className={cls.head}>{notification.title}</Typography>
          </Grid>

          <Grid item>
            <Tooltip placement='top' title={`Click to add new notification`}>
              <Link to='/addnotification'>
                <IconButton
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                  className={cls.iconBtn}
                >
                  <AddAlertIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>

          {editMode ? (
            <Grid item>
              <Tooltip placement='top' title={`Click to lock & save your changes`}>
                <Button className={cls.saveBtn} color='primary' onClick={() => updateNotification(notification)}>
                  Save Changes
                </Button>
              </Tooltip>
            </Grid>
          ) : null}
          <Grid item>
            <Tooltip
              placement='top'
              title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
            >
              <IconButton
                style={{
                  color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
                }}
                className={cls.iconBtn}
                onClick={() => handleEditMode()}
              >
                <Icon>{editMode ? `lock_open` : `lock`}</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip placement='top' title={`Actions`}>
              <IconButton className={cls.iconBtn} onClick={handleActionsOpen}>
                <Icon>settings</Icon>
              </IconButton>
            </Tooltip>
            <Menu
              keepMounted
              id={`notification-actions-menu`}
              anchorEl={actionsOpen}
              open={Boolean(actionsOpen)}
              onClose={handleActionsClose}
            >
              {!notification.assignedto && notification.type === 'action' ? (
                <MenuItem onClick={() => updateNotificationAssigned(notification)}>Accept Assignment</MenuItem>
              ) : null}

              {!notification.resolvedby && notification.assignedto === userEmail ? (
                <MenuItem onClick={() => updateNotificationResolved(notification)}>Mark As Resolved</MenuItem>
              ) : null}

              {!notification.type === 'inform' ? <MenuItem>No action required</MenuItem> : null}

              {!notification.assignedto && notification.resolvedby ? <MenuItem>No action required</MenuItem> : null}
            </Menu>
          </Grid>
        </Grid>

        <div className={cls.break_md} />

        <NotificationInfo
          notification={notification}
          editMode={editMode}
          editVals={{
            type,
            title,
            body,
            assignedAt,
            assignedBy,
            assignedTo,
            resolvedBy,
            resolvedAt,
            notes,
            dueDate
          }}
          editSetVals={{
            setType,
            setTitle,
            setBody,
            setAssignedAt,
            setAssignedBy,
            setAssignedTo,
            setResolvedBy,
            setResolvedAt,
            setNotes,
            setDueDate
          }}
        />
      </Container>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  break_md: {
    width: '100%',
    height: theme.spacing(2),
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NotificationDetails;
