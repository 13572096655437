//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Chip, Icon } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetailsToolbar({ organization }) {
  const cls = useStyles();

  const { capFirst } = useTools();

  const getStatusChipClass = status => {
    switch (status) {
      case `live`:
        return cls.statusLiveChip;
      case `onboarding`:
        return cls.statusOnboardingChip;
      case `deactivated`:
        return cls.statusDeactivatedChip;
      default:
        return cls.statusUnknownChip;
    }
  };

  return (
    <>
      {organization ? (
        <Grid container spacing={2}>
          <Grid item>
            <Chip
              className={getStatusChipClass(organization?.status)}
              label={capFirst(organization?.status || `unknown`)}
              color='primary'
              size='small'
              icon={
                <>
                  {organization?.status === `live` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      check_circle
                    </Icon>
                  )}
                  {organization?.status === `onboarding` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      contrast
                    </Icon>
                  )}
                  {organization?.status === `deactivated` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      block
                    </Icon>
                  )}
                  {!organization?.status && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      help
                    </Icon>
                  )}
                </>
              }
            />
          </Grid>

          <Grid item>
            <Chip
              className={organization?.active ? cls.activeTrueChip : cls.activeFalseChip}
              label={organization?.active ? `Active` : `Inactive`}
              color='primary'
              size='small'
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  statusLiveChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
  },
  statusOnboardingChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
  },
  statusDeactivatedChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
  },
  statusUnknownChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },

  statusIcon: {
    marginLeft: 2,
    marginRight: -2,
  },

  activeTrueChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },
  activeFalseChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));
