import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container, Button } from '@material-ui/core';

import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import { GET_FAQS } from './gql';

import FaqManagerTable from './FaqManagerTable';
import FaqManagerModal from './FaqManagerModal';

const log = false;

export default function FaqManager() {
  const cls = useStyles();

  // HOOKS ////////////////////////////////////////////////////////////////////
  const { loading, error, data } = useQuery(GET_FAQS);

  // STATES ////////////////////////////////////////////////////////////////////
  const [modalRender, setModalRender] = useState(null);
  const [modalData, setModalData] = useState(null);

  // LOGIC ////////////////////////////////////////////////////////////////////
  const handleFAQ = () => {
    setModalData(`hi`);
    setModalRender(`create faq`);
  };

  if (loading) return <Loading fixed />;
  if (error) {
    console.log('Error Fetching FAWS:', error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FETCHING FAQS' />;
  }
  if (data && data.faqs) {
    const faqs = data.faqs;
    log && console.log('FAQs Fetched:', faqs);

    let all = faqs.filter(f => f.user === null);
    let dealer = faqs.filter(f => f.user === `dealer`);
    let driver = faqs.filter(f => f.user === `driver`);

    return (
      <>
        <FaqManagerModal
          open={modalRender !== null}
          data={modalData}
          setData={setModalData}
          render={modalRender}
          setRender={setModalRender}
        />

        <div className={cls.root}>
          <Container maxWidth='lg'>
            <Button className={cls.button} variant='contained' color='primary' onClick={() => handleFAQ()}>
              Create a new FAQ
            </Button>

            <div style={{ width: '100%', height: '16px' }} />

            <FaqManagerTable
              allFaqs={all}
              dealerFaqs={dealer}
              driverFaqs={driver}
              setModalData={setModalData}
              setModalRender={setModalRender}
            />
          </Container>
        </div>
      </>
    );
  } else return <DefaultEmptyFallback message='NO FAQ DATA FOUND' />;
}

// STYLES ///////////////////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  button: {
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
