//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableContainer, Table, TablePagination } from '@material-ui/core';

import AllowedRegionsTableHead from './AllowedRegionsTableHead'
import AllowedRegionsTableBody from './AllowedRegionsTableBody'

const defaultOrder = `desc`;
const defaultOrderBy = `end_date`;

//////////////////////// COMPONENT ////////////////////////

function AllowedRegionsTable(props) {
  const { allowedRegions = [] } = props;

  const cls = useStyles();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headers = [
    { id: 'ID', align: 'left', label: 'ID' },
    { id: 'REGION', align: 'left', label: 'Region' },
  ];

  const rows = allowedRegions.map((allowedRegion) => {

    let regionId = null
    let regionName = null
    if (allowedRegion.region_id) {
      regionId = allowedRegion.region_id
    } else if (allowedRegion.id) {
      regionId = allowedRegion.id
    }

    if (allowedRegion.region && allowedRegion.region.name) {
      regionName = allowedRegion.region.name
    } else if (allowedRegion.name) {
      regionName = allowedRegion.name
    }

    return {
      ID: regionId, 
      REGION: regionName,
    };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='rate-rule-group-index-table'>
          <AllowedRegionsTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <AllowedRegionsTableBody rows={rows} order={order} orderBy={orderBy} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          classes={{
            toolbar: cls.toolbar,
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    // paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0
  }
}));

//////////////////////// EXPORT ////////////////////////

export default AllowedRegionsTable;