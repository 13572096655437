import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID } from './gql';
import { LinearProgress } from '@material-ui/core';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import AssignedChaseVehicleTable from './AssignedChaseVehicleTable';

export default function AssignedChaseVehiclesContent({ chaseId }) {
  const { data, loading, error } = useQuery(GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID, {
    variables: { chase_id: chaseId },
    fetchPolicy: `network-only`,
  });

  console.log(data?.chasevehiclesassigned?.length);

  return (
    <>
      {loading ? <LinearProgress /> : null}
      {error ? <DefaultErrorFallback message={`Failed to fetch chase vehicles`} /> : null}
      {!loading && !error && data?.chasevehiclesassigned?.length ? (
        <AssignedChaseVehicleTable chaseVehicles={data.chasevehiclesassigned} />
      ) : (
        <DefaultEmptyFallback message={`No assigned chase vehicles`} />
      )}
    </>
  );
}
