//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useData } from '../../../providers/DataProvider';
import { Tooltip, Badge, Icon } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { getAuthStatus } from '../../../utils/authHelper'

//////////////////////// COMPONENT ////////////////////////

function NotificationIcon({ loading, error, data, badgeNumber, ...props }) {
  const { apolloClient } = useData();
  const loggedIn = getAuthStatus()

  const BadgeWrapper = props => {
    return (
      <Tooltip title='Notifications'>
        {props.content ? (
          <Badge badgeContent={props.content} color={props.color || 'error'}>
            <Icon>notifications</Icon>
          </Badge>
        ) : (
          <Icon>notifications</Icon>
        )}
      </Tooltip>
    );
  };

  if (loggedIn && apolloClient) {
    if (loading) return <BadgeWrapper content='...' />;
    if (error) {
      console.error(`Error fetching notifications:`, error);
      Sentry.captureException(error);
      return <BadgeWrapper content='!' />;
    }
    if (!data || !data.notifications || !data.notifications.length > 0) return <BadgeWrapper />;
    return <BadgeWrapper content={badgeNumber} />;
  } else return <BadgeWrapper />;
}

//////////////////////// EXPORT ////////////////////////

export default NotificationIcon;
