// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import MoveAR from '../../pages/MoveDetails/MoveAR';
import MoveAP from '../../pages/MoveDetails/MoveAP';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomeReviewAdditionalInfo({ move }) {
  const cls = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <MoveAR move={move} />
        </Grid>

        <Grid item md={6} xs={12}>
          <MoveAP move={move} />
        </Grid>
      </Grid>
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
