//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

import { useTools } from '../../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileHangtagTooltip({ children, hangtag }) {
  const cls = useStyles();

  const { capFirst, getFormattedStatusFromHangtag, getReadableText } = useTools();

  return (
    <Tooltip
      title={
        <div className={cls.ttRoot}>
          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Hangtag Status:</div>
            <div className={cls.ttValBold}>{getFormattedStatusFromHangtag(hangtag) || `-`}</div>
          </div>

          <hr className={cls.ttDivider} />

          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Internal ID:</div>
            <div className={cls.ttVal}>#{hangtag?.id || `-`}</div>
          </div>

          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Rear Code:</div>
            <div className={cls.ttVal}>{hangtag?.rear_code || `-`}</div>
          </div>

          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Hash Code:</div>
            <div className={cls.ttVal}>{hangtag?.hash_id || `-`}</div>
          </div>

          <hr className={cls.ttDivider} />

          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Customer:</div>
            <div className={cls.ttVal}>{hangtag?.customer?.name || `-`}</div>
          </div>

          <div className={cls.ttFlex}>
            <div className={cls.ttKeyFade}>Type:</div>
            <div className={cls.ttValFade}>{capFirst(hangtag?.type) || `-`}</div>
          </div>

          {hangtag?.workflow_data && Object.keys(hangtag?.workflow_data).length ? (
            <>
              <hr className={cls.ttDivider} />

              {Object.keys(hangtag?.workflow_data).map(key => (
                <div key={`hangtag-workflow-${key}`} className={cls.ttFlex}>
                  <div className={cls.ttKey}>{getReadableText(key)}:</div>
                  <div className={cls.ttVal}>{hangtag?.workflow_data?.[key] || `-`}</div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      }
      placement='bottom'
      width='fit-content'
    >
      {children}
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  ttRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  ttFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: 144,
    maxWidth: 384,
    color: theme.palette.text.contrast,
  },
  ttKey: {
    marginRight: 4,
    fontWeight: 400,
  },
  ttKeyFade: {
    marginRight: 4,
    fontWeight: 400,
    color: theme.palette.text.contrastFade,
  },
  ttVal: {
    fontWeight: 600,
  },
  ttValFade: {
    fontWeight: 600,
    color: theme.palette.text.contrastFade,
  },
  ttValBold: {
    fontWeight: 900,
  },
  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
