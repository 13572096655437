// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, Icon, IconButton, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

const log = true;

// COMPONENT ---------------------------------------------------------------- //

export default function PayCycleDetailsSubheader({
  paycycle,
  driverPayArray,
  payMode,
  setPayMode,
  isPaying,
  setIsPaying,
  checkedDriverPayArray,
  refetch,
}) {
  const cls = useStyles();

  const { formatUSD } = useTools();
  const { getAmountRemainingFromDriverPayArray, initiateMassPayout } = useDriverPay();

  const [payableDriverIds, setPayableDriverIds] = React.useState([]);
  const [amountRemaining, setAmountRemaining] = React.useState(0);

  const [failedPayouts, setFailedPayouts] = React.useState([]);

  React.useEffect(() => {
    const filteredDriverPayArray = driverPayArray?.filter(dp =>
      checkedDriverPayArray?.map(cdp => cdp?.driver_id)?.includes(dp?.driver_id)
    );
    setPayableDriverIds(filteredDriverPayArray?.map(fdp => fdp?.driver_id));
    setAmountRemaining(getAmountRemainingFromDriverPayArray(filteredDriverPayArray));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverPayArray, checkedDriverPayArray]);

  React.useEffect(() => {
    if (payMode) setFailedPayouts([]);
  }, [payMode]);

  const handleConfirm = async () => {
    setIsPaying(true);

    log && console.log(`Confirming payout for drivers:`, { paycycleId: paycycle?.id, driverIds: payableDriverIds });
    const res = await initiateMassPayout(paycycle?.id, payableDriverIds, refetch);

    if (res?.failedDriverPayArray?.length) {
      const sortedFailedPayouts = res?.failedDriverPayArray?.sort((a, b) => {
        if (a?.name < b?.name) return -1;
        if (a?.name > b?.name) return 1;
        if (a?.driverName < b?.driverName) return -1;
        if (a?.driverName > b?.driverName) return 1;
        return 0;
      });
      setFailedPayouts(sortedFailedPayouts);
    }

    setPayMode(false);
    setIsPaying(false);
  };

  return (
    <>
      {payMode ? (
        <div className={cls.subheader}>
          <div className={cls.flex}>
            <div className={cls.flexItem}>
              <Typography className={cls.titleTxt}>Pay Selected Drivers</Typography>
            </div>

            <div className={cls.flexItemLine} />

            <div className={cls.flexItem}>
              <Typography className={cls.amountTxt}>
                {formatUSD(amountRemaining, { displayAccountingNegative: true })}
              </Typography>
            </div>

            <div className={cls.flexItemSpacer} />

            <div className={cls.flexItem}>
              <div className={cls.flexItemBackdrop} />

              <Button
                disabled={isPaying}
                className={cls.btnCancel}
                size='large'
                color='white'
                onClick={() => setPayMode(false)}
              >
                <Icon className={cls.btnIcon}>cancel</Icon>
                Cancel
              </Button>
            </div>

            <div className={cls.flexItem}>
              <div className={cls.flexItemBackdrop} />

              <Button
                disabled={isPaying}
                loading={isPaying}
                size='large'
                color='primary'
                onClick={() => handleConfirm()}
              >
                <Icon className={cls.btnIcon}>check_circle</Icon>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      ) : null}

      {failedPayouts?.length ? (
        <div className={cls.failedPayouts}>
          <Typography className={cls.failedPayoutsTitleTxt}>Failed to Pay the Following Drivers</Typography>

          {failedPayouts?.map((fp, i) => (
            <React.Fragment key={fp?.id || fp?.driverId}>
              <div className={cls.failedPayoutsFlex}>
                <div className={cls.failedPayoutsFlexItem}>
                  <Typography className={cls.failedPayoutsFlexItemTxt}>
                    {fp?.name || fp?.driverName} ({fp?.id || fp?.driverId})
                  </Typography>
                </div>

                <div className={cls.failedPayoutsFlexItemStretch}>
                  <Typography className={cls.failedPayoutsFlexItemTxt}>{fp?.error}</Typography>
                </div>
              </div>

              {i === failedPayouts?.length - 1 ? null : <div className={cls.failedPayoutsLine} />}
            </React.Fragment>
          ))}

          <IconButton className={cls.btnClose} onClick={() => setFailedPayouts([])}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      ) : null}
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  subheader: {
    zIndex: 9,
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderTop: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.text.primary,
    boxShadow: theme.shadow.nav,
  },

  titleTxt: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },
  amountTxt: {
    lineHeight: 1,
    fontSize: 40,
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  flexItem: {
    position: 'relative',
  },
  flexItemStretch: {
    position: 'relative',
    flex: 1,
  },
  flexItemLine: {
    position: 'relative',
    flex: 1,
    borderTop: `1px dashed ${theme.palette.text.contrastDisabled}`,
  },
  flexItemSpacer: {
    position: 'relative',
    width: theme.spacing(6),
  },
  flexItemBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 4,
    backgroundColor: 'white',
  },

  btnCancel: {
    color: `${theme.palette.text.primary} !important`,
  },
  btnIcon: {
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
  btnClose: {
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 4,
    color: theme.palette.text.contrastPrimary,
  },

  failedPayouts: {
    zIndex: 9,
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxShadow: theme.shadow.nav,
  },
  failedPayoutsTitleTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 900,
    color: theme.palette.error.contrastText,
  },
  failedPayoutsFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  failedPayoutsFlexItem: {
    position: 'relative',
    flex: 1,
  },
  failedPayoutsFlexItemStretch: {
    position: 'relative',
    flex: 3,
  },
  failedPayoutsFlexItemTxt: {
    fontSize: 14,
    color: theme.palette.error.contrastText,
  },
  failedPayoutsLine: {
    width: '100%',
    borderBottom: `1px solid #ffffff60`,
    margin: theme.spacing(1, 0),
  },
}));
