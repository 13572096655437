import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Link,
  Button,
  TextField,
  InputAdornment,
  Icon,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

import Spacer from '../../components/Spacer';
import Toolbar from '../../components/Toolbar';
const axios = require('axios');
const { RET_RIDE_SD, CON_SD } = require('../../utils/env');

const log = true;

export default function Quicklinks(props) {
  const cls = useStyles();

  const [moveId, setMoveId] = React.useState('');
  const [type, setType] = React.useState('');
  const [conciergeUrl, setConciergeUrl] = React.useState('');
  const [rideshareUrl, setRideshareUrl] = React.useState('');

  const encodeIds = async () => {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/encodeHashIds',
        data: {
          moveId: moveId,
          type: type,
        },
      }).then(res => {
        log && console.log('Response:', res);
        if (res.status === 200) {
          log && console.log('Success!');
        } else {
          console.error('error');
        }

        if (type === 'concierge') {
          setConciergeUrl(`https://${CON_SD}.hopdrive.io/${res.data}`);
        } else if (type === 'rideshare') {
          setRideshareUrl(`https://${RET_RIDE_SD}.hopdrive.io/${res.data}`);
        }
      });
    } catch (err) {
      console.error(`Error hashing ID:`, err);
    }
  };

  const handleMoveIdChange = event => {
    setMoveId(event.target.value);
  };

  const handleSubmit = () => {
    encodeIds();
    setRideshareUrl(null);
    setConciergeUrl(null);
  };

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Quicklinks'></Toolbar>

      <Spacer />
      <Spacer />

      <div className={cls.paper}>
        <Grid container style={{ textAlign: 'center' }} spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: '10px' }}>
              Enter move ID and select concierge or rideshare to generate tracking URLs:
            </Typography>
          </Grid>
          <Spacer />
          <Spacer />
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <TextField
              select
              label='Select Move Type'
              style={{ minWidth: 250, maxWidth: 250, maxHeight: 30, marginRight: 10 }}
              variant='outlined'
              value={type}
              onChange={handleInputChange(setType)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ verticalAlign: 'top', maxHeight: '40px', textAlign: 'left' }}
                    position='start'
                  >
                    <Icon color='disabled' fontSize='small'>
                      list
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <InputLabel>Select Filter</InputLabel>
              <MenuItem value={null}>
                <em>Clear Criteria</em>
              </MenuItem>
              <MenuItem value={'rideshare'}>Rideshare</MenuItem>
              <MenuItem value={'concierge'}>Concierge</MenuItem>
            </TextField>
            <TextField
              style={{ minWidth: 200, minHeight: 50, marginRight: 10 }}
              placeholder='Type to search...'
              label='Search Move ID'
              variant='outlined'
              size='small'
              value={moveId}
              onChange={handleMoveIdChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top', minHeight: '55px' }} position='start'>
                    <Icon color='disabled' style={{ cursor: 'default' }}>
                      search
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
            <Button style={{ marginTop: '8px' }} variant='contained' onClick={handleSubmit} color='primary'>
              Generate URL
            </Button>
          </Grid>
        </Grid>

        {conciergeUrl ? (
          <div className={cls.root}>
            <Grid container style={{ textAlign: 'center' }} spacing={2}>
              <Grid item xs={8} alignItems='center' justify='center' classes={{ item: cls.url }}>
                <Link style={{ fontSize: '12pt', padding: '1px' }} target='_blank' href={conciergeUrl}>
                  {conciergeUrl}
                </Link>
              </Grid>
            </Grid>
          </div>
        ) : rideshareUrl ? (
          <div className={cls.root}>
            <Grid container style={{ textAlign: 'center' }} spacing={2}>
              <Grid item xs={8} alignItems='center' justify='center' classes={{ item: cls.url }}>
                <Link style={{ fontSize: '12pt', padding: '1px' }} target='_blank' href={rideshareUrl}>
                  {rideshareUrl}
                </Link>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
  },

  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(3),
  },

  headers: {
    marginTop: theme.spacing(4),
  },

  searchBar: {
    display: 'inline-flex',
    backgroundColor: '#323232',
    '&:hover': {
      backgroundColor: '#646464',
    },
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
  },

  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 3),
  },
  url: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
}));
