import { gql } from 'graphql-tag';

const GET_DRIVER_DETAILS = gql`
  query get_driver_details($driverId: bigint!) {
    drivers(where: { id: { _eq: $driverId } }) {
      id
      bank_account_token
      config
      driver_app_version
      phone_type
      plate_id
      region_id
      status
      tax_class
      user_id
      vehicle_color
      vehicle_license_plate
      vehicle_license_plate_state
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      verification
      wallet_token
      driverdetail {
        date_of_birth
        gender
        insurance_provider
      }
      driverpayouts(order_by: { id: desc }, limit: 50) {
        id
        amount
        created_at
        status
        type
      }
      equipment {
        id
        equipment_id
        status
        equipment {
          id
          active
          cost
          description
          name
          photo_url
          price
        }
      }
      plate {
        id
        notes
        plate_number
        plate_state
        status
        toll_management_id
      }
      region {
        id
        name
      }
      user {
        id
        active
        avatar_url
        display_name
        email
        firebase_id
        phone
        default_role
      }
    }
  }
`;

const GET_REGIONS = gql`
  query admin_driverDetails_getRegions {
    regions(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const SAVE_DRIVER_DETAILS = gql`
  mutation save_driver_details(
    $driverId: bigint!
    $driverObj: drivers_set_input!
    $driverdetailObj: driverdetails_set_input!
    $userObj: users_set_input!
  ) {
    update_drivers(where: { id: { _eq: $driverId } }, _set: $driverObj) {
      affected_rows
      returning {
        id
      }
    }
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailObj) {
      affected_rows
      returning {
        driver_id
      }
    }
    update_users(where: { driver: { id: { _eq: $driverId } } }, _set: $userObj) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export { GET_DRIVER_DETAILS, GET_REGIONS, SAVE_DRIVER_DETAILS };
