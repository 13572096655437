//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { makeStyles, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../../components/ModalComponents';

//////////////////////// COMPONENT ////////////////////////

export default function ARReviewModal(props) {
  const cls = useStyles();

  const { open, onClose, input } = props;

  const [updateParkedLocation] = useMutation(UPDATE_PARKED_LOCATION);

  const [locationId, setLocationId] = React.useState(0);

  React.useEffect(() => {
    if (open && input) {
      setLocationId(input.locationId || 0);
    }
  }, [open, input]);

  const handleLocationChange = event => {
    setLocationId(event.target.value);
  };

  const handleSubmit = async (clear = false) => {
    try {
      const res = await updateParkedLocation({
        variables: {
          planId: input.planId,
          locationId: clear ? null : locationId || null,
        },
      });
      if (res && res.data && res.data.update_plans && res.data.update_plans.affected_rows > 0) {
        toast.success(`Successfully updated parked location for ${input.driverName}!`);
        handleClose({ locationId: locationId });
      }
    } catch (err) {
      toast.error(`Failed to update parked location!`);
      console.error(`Failed to update parked location:`, err);
    }
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
    setLocationId(0);
  };

  return (
    <>
      <Modal open={open} width='sm'>
        {input ? (
          <>
            <ModalHeader handleClose={handleClose}>Parked Car Location</ModalHeader>

            <ModalContent subtitle={`Set the location of ${input.driverName}'s parked car.`}>
              <TextField
                select
                fullWidth
                size='small'
                variant='outlined'
                label='Parked Location'
                value={locationId}
                onChange={handleLocationChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        local_parking
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={0}>
                  <em>Select a location...</em>
                </MenuItem>
                {input.validLocations && input.validLocations.length > 0
                  ? input.validLocations.map(pl => (
                      <MenuItem value={pl.id}>
                        {pl.name} - {pl.address}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </ModalContent>

            <ModalFooter>
              <ModalAction disabled={!locationId} color='primary' onClick={() => handleSubmit()}>
                Save
              </ModalAction>
              <ModalAction color='secondary' onClick={() => handleSubmit(true)}>
                Clear
              </ModalAction>
              <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({}));

//////////////////////// GRAPHQL ////////////////////////

const UPDATE_PARKED_LOCATION = gql`
  mutation update_parked_location($planId: bigint!, $locationId: bigint) {
    update_plans(where: { id: { _eq: $planId } }, _set: { parked_location: $locationId, updatedat: "now()" }) {
      affected_rows
      returning {
        id
        parked_location
        updatedat
      }
    }
  }
`;
