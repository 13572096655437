//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, TableCell } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';
import { useEngine } from '../../hooks/useEngine';
import { getUserRole } from '../../utils/authHelper';

import { GET_MOVE_LOGS } from '../Invoices/gql';

import ArmoveFold from '../../components/Folds/ArmoveFold';

//////////////////////// COMPONENT ////////////////////////

function DisputedTableRow(props) {
  const { row, foldId, setFoldId, handleARReviewModalOpen, handleARLogsModalOpen } = props;
  const armove = row.armove || {};
  const move = armove.move || {};

  const cls = useStyles();
  const ctx = useData();
  const { goToMoveDetails, copyToClipboard } = useTools();
  const { rerunAR, tagDeliveryReported } = useEngine();
  const [userRole, setUserRole] = React.useState();

  React.useEffect(() => {
    const runAsync = async () => {
      let userRole = await getUserRole();
      setUserRole(userRole);
    };

    runAsync();
  }, []);

  // Handle fold row state
  const handleFoldRow = (id = 0) => {
    let newFoldId = id === foldId ? 0 : id;
    localStorage.setItem(`invoice-fold-id`, newFoldId);
    setFoldId(newFoldId);
  };

  // Handle getting ar move logs
  const handleARLogs = async moveId => {
    try {
      const res = await ctx.apolloClient.query({
        query: GET_MOVE_LOGS,
        variables: { moveId: moveId },
      });
      if (res && res.data && res.data.arevents && res.data.arevents.length > 0) {
        handleARLogsModalOpen(`move`, res.data.arevents);
      }
    } catch (err) {
      toast.error(`Failed to fetch move logs!`);
      console.error(`Failed to fetch move logs:`, err);
    }
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.MOVE_ID || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.REF_NUM || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.CUSTOMER || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.MOVE_DATE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.DISPUTE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.DISCOUNT || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.TOTAL || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.BUTTON || `-`,
      align: `right`,
    },
  ];

  // Set a list of actions after right-clicking on a row
  const rowActions = [
    {
      label: `Go To Move Details`,
      handler: () => goToMoveDetails(move.id || null),
    },
    {
      label: `Recalculate Customer Price`,
      handler: async () => rerunAR(move.id || null),
      disabled: move.settled,
    },
    {
      label: `Review/Discount`,
      handler: () => handleARReviewModalOpen(armove || null),
      disabled: move.settled && userRole !== 'admin',
    },
    {
      label: `View AR Move Logs`,
      handler: () => handleARLogs(move.id || null),
    },
    {
      label: `Tag Delivery Reported`,
      handler: () => tagDeliveryReported(move || null),
      hide: (move.tags && move.tags.includes(`delivery reported`)) || move.customer.id !== 2,
    },
    {
      label: `Untag Delivery Reported`,
      handler: () => tagDeliveryReported(move || null),
      hide: !move.tags || !move.tags.includes(`delivery reported`),
    },
    {
      label: `Copy Reference #`,
      handler: () => copyToClipboard(move.reference_num),
      hide: !move.reference_num || move.customer.id !== 2,
    },
    {
      label: `Copy Delivery Timestamp`,
      handler: () => copyToClipboard(move.delivery_successful),
      hide: !move.delivery_successful,
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${armove.id}-cm`}
        source={`atr-row-${armove.id}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{ className: `MuiTableRow-root ${foldId === armove.id ? cls.rowActive : cls.row}` }}
      >
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${armove.id}-col-${i}`}
                    className={foldId === armove.id ? cls.cellActive : cls.cell}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </ContextMenuTrigger>

      {rowActions && rowActions.length > 0 && (
        <ContextMenu id={`atr-row-${armove.id}-cm`}>
          {rowActions.map(
            (action, i) =>
              !action.hide && (
                <MenuItem
                  key={`atr-row-action-${i}`}
                  disabled={action.disabled || false}
                  onClick={action.handler || null}
                >
                  {action.label || `Action ${i + 1}`}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}

      <ArmoveFold armove={row.armove} actions={rowActions} rowCellCount={rowCells.length} foldId={foldId} />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  row: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  rowActive: {
    background: theme.palette.error.main,
    '&:hover, &:active': {
      background: `${theme.palette.error.main} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  cell: {
    color: theme.palette.text.primary,
    transition: '0.15s',
  },
  cellActive: {
    color: theme.palette.text.contrast,
    transition: '0.15s',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default DisputedTableRow;
