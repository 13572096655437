//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns';
import sdk from '@hopdrive/sdk';
import {
  makeStyles,
  Grid,
  Typography,
  Checkbox,
  Tooltip,
  Icon,
  InputAdornment,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Button } from '@hopdrive/storybook';
import { useTools } from '../../../hooks/useTools';
import { useScheduler } from '../providers/SchedulerProvider';
import { useEnrichedPlans } from '../hooks/useEnrichedPlans';
import useMoveActions from '../hooks/useMoveActions';
import Spacer from '../../../components/Spacer';
import IconAction from '../../../components/IconAction';

import { MoveTileTooltip } from '../index';

import { SimpleLogger } from '../../../utils/SimpleLogger';
const { log } = new SimpleLogger({ prefix: 'ScheduleDetailsPanel', enabled: true });

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleDetailsPanelDrive({ enrichedDrive = null }) {
  const cls = useStyles();

  const { capFirst, getDriveTypeFromMove } = useTools();
  const { applyMoveChangesThenRunScheduler, isPlanEditable } = useScheduler();
  const { revertMoveChanges } = useEnrichedPlans();
  const { handleUnplanDrive, actions } = useMoveActions(enrichedDrive);

  //State management for triangle moves
  const [areTriangleMovesAllowed, setAreTriangleMovesAllowed] = React.useState(false);
  const [isTriangleMove, setIsTriangleMove] = React.useState(false);

  React.useEffect(() => {
    // Check if there is an actual enrichedDrive (render null instead)
    if (!enrichedDrive) return null;
    // Extract the move, moveWithOverrides, lane and customer from the enrichedDrive
    const moveWithOverrides = enrichedDrive.withOverrides || null;
    //Customer config- determines if triangle move can be enabled
    const customerConfig = sdk.configs.enrichCustomerConfig(
      moveWithOverrides?.customer?.config,
      moveWithOverrides?.customer?.organization?.config
    );
    const customerHasTriangleMoves = customerConfig?.allow_triangle_moves || false;
    setAreTriangleMovesAllowed(customerHasTriangleMoves);
    //Config variables for handling triangle moves
    const moveConfig = moveWithOverrides && moveWithOverrides.config ? moveWithOverrides.config : {};
    const moveIsTriangle = moveConfig.triangle_move === true;
    setIsTriangleMove(moveIsTriangle);
  }, [enrichedDrive]);

  // Check if there is an actual enrichedDrive (render null instead)
  if (!enrichedDrive) return null;

  // Extract the move, moveWithOverrides, lane and customer from the enrichedDrive
  const moveWithOverrides = enrichedDrive.withOverrides || null;

  // Extract linked move
  const linkedEnrichedMove = enrichedDrive.linkedEnrichedMove || {};
  const linkedMoveWithOverrides = linkedEnrichedMove.withOverrides || null;

  //Set move as "triangle" in its config if option has been selected
  const handleSetAsTriangle = async () => {
    setIsTriangleMove(!isTriangleMove);
    const moveConfig = moveWithOverrides.config;
    //NOTE: empty obj move config was causing issues so I added this check here- it creates a new config to replace the empty obj entirely
    if (Object.keys(moveConfig === null || moveConfig).length === 0) {
      applyMoveChangesThenRunScheduler(enrichedDrive, {
        config: { triangle_move: true },
        class: 'base',
        rate_class_override: 1,
      });
    } else {
      const newMoveConfig = { ...moveConfig, triangle_move: !isTriangleMove };
      //If we are setting this as a triangle move, we also need to set the move to base and set rate class override
      if (!isTriangleMove === true) {
        await applyMoveChangesThenRunScheduler(enrichedDrive, {
          config: newMoveConfig,
          class: 'base',
          rate_class_override: 1,
        });
      } else {
        await applyMoveChangesThenRunScheduler(enrichedDrive, { config: newMoveConfig, rate_class_override: 0 });
      }
    }
  };

  const handleTimeOverrideCheck = async () => {
    if (moveWithOverrides.pinnable) {
      revertMoveChanges(enrichedDrive, [`pickup_time`, `delivery_time`]);
    }
    await applyMoveChangesThenRunScheduler(enrichedDrive, { pinnable: !moveWithOverrides.pinnable });
  };

  const handleTimeChange = async e => {
    const newPickupTime = dayjs(e).utc().format();
    // dayjs.tz.guess();
    // const dateTimeString = `${timelineDate.format('YYYY-MM-DD')}T${e.target.value}`;
    // const newPickupTime = dayjs(dateTimeString).utc().toISOString();
    log(`Setting the pickup time on move ${enrichedDrive.id} to '${newPickupTime}'`);
    await applyMoveChangesThenRunScheduler(enrichedDrive, { pickup_time: newPickupTime, pinnable: true });
  };

  const handleRateClassOverrideCheck = async () => {
    if (moveWithOverrides.rate_class_override) {
      revertMoveChanges(enrichedDrive, [`class`]);
    }
    await applyMoveChangesThenRunScheduler(enrichedDrive, {
      rate_class_override: moveWithOverrides.rate_class_override ? 0 : 1,
    });
  };

  const handleRateClassChange = async event => {
    const newRateClass = event.target.value || ``;
    log(`Setting the rate class on move ${enrichedDrive.id} to '${newRateClass}'`);
    await applyMoveChangesThenRunScheduler(enrichedDrive, { class: newRateClass, rate_class_override: 1 });
  };

  const DriveIcon = ({ driveType = null }) => {
    if (driveType === `concierge`)
      return (
        <Tooltip title={capFirst(driveType)}>
          <Icon className={cls.iconConcierge}>person_pin</Icon>
        </Tooltip>
      );
    if (driveType === `loaner`)
      return (
        <Tooltip title={capFirst(driveType)}>
          <Icon className={cls.iconLoaner}>emoji_transportation</Icon>
        </Tooltip>
      );
    return (
      <Tooltip title={capFirst(driveType)}>
        <Icon className={cls.iconOps}>drive_eta</Icon>
      </Tooltip>
    );
  };

  const pinnableDisabled = () => {
    if (!isPlanEditable) return true;
    if (enrichedDrive.inProgress || enrichedDrive.isCompleted) return true;
    if ((linkedMoveWithOverrides || {}).pickup_time < (moveWithOverrides || {}).pickup_time) return true;
    return false;
  };

  const rateClassDisabled = () => {
    if (!isPlanEditable) return true;
    //We cannot allow the dispatcher to override the class if the move is triangle- all triangle moves need to be base
    if (isTriangleMove) return true;
    if (enrichedDrive.inProgress || enrichedDrive.isCompleted) return true;
    return false;
  };

  return (
    <div className={cls.panel} style={{ fontSize: 12 }}>
      <Grid container spacing={1} alignItems='center' wrap='nowrap'>
        <Grid item>
          <DriveIcon driveType={getDriveTypeFromMove(moveWithOverrides)} />
        </Grid>

        <Grid item xs>
          <Typography className={cls.title}>Selected Move #{enrichedDrive.id}</Typography>
        </Grid>

        <Grid item>
          <IconAction actions={actions} tooltip='Drive Actions' />
        </Grid>
      </Grid>

      <Spacer />

      <MoveTileTooltip enrichedMove={enrichedDrive} useOverrides />

      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={12} className={cls.darkBackground}>
          <div className={cls.triangeSection}>
            <Checkbox
              disabled={
                !areTriangleMovesAllowed ||
                !isPlanEditable ||
                (moveWithOverrides.status !== null &&
                  moveWithOverrides.status !== `dispatched` &&
                  moveWithOverrides.status !== 'pickup started')
              }
              className={cls.check}
              color='primary'
              checked={isTriangleMove}
              onChange={handleSetAsTriangle}
            />
            <Typography className={cls.titleTxt}>Set as Triangle Move</Typography>
          </div>
          {areTriangleMovesAllowed ? (
            <Grid item xs={12}>
              Both rides will be charged in full to the customer for triangle moves.
            </Grid>
          ) : (
            <Grid item xs={12}>
              Triangle Moves are disabled for this customer.
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={cls.flex}>
            <Checkbox
              disabled={pinnableDisabled()}
              className={cls.check}
              color='primary'
              checked={moveWithOverrides.pinnable}
              onChange={handleTimeOverrideCheck}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                disabled={
                  !moveWithOverrides.pinnable ||
                  (moveWithOverrides.status !== null && moveWithOverrides.status !== `dispatched`)
                }
                autoOk
                minutesStep={5}
                label='Start Time Override'
                orientation='portrait'
                size='small'
                variant='inline'
                inputVariant='outlined'
                value={dayjs(moveWithOverrides.pickup_time).format()}
                onChange={handleTimeChange}
                className={cls.input}
              />
            </MuiPickersUtilsProvider>

            {/* <TextField
                  fullWidth
                  id='time'
                  label='Start Time Override'
                  type='time'
                  variant='outlined'
                  size='small'
                  defaultValue={dayjs(moveWithOverrides.pickup_time).format()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    step: 300, // 5 min
                  }}
                  disabled={
                    !moveWithOverrides.pinnable ||
                    (moveWithOverrides.status !== null && moveWithOverrides.status !== `dispatched`)
                  }
                  onChange={handleTimeChange}
                /> */}
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={cls.flex}>
            <Checkbox
              disabled={rateClassDisabled()}
              className={cls.check}
              color='primary'
              checked={moveWithOverrides.rate_class_override}
              onChange={handleRateClassOverrideCheck}
            />
            <TextField
              select
              fullWidth
              disabled={!moveWithOverrides.rate_class_override || isTriangleMove}
              size='small'
              variant='outlined'
              label='Rate Class Override'
              value={moveWithOverrides.class}
              onChange={handleRateClassChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      rate_review
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={``}>
                <em>Select Rate Class...</em>
              </MenuItem>
              <MenuItem value={`base`}>Base</MenuItem>
              <MenuItem value={`stranded`}>Stranded</MenuItem>
            </TextField>
          </div>
        </Grid>

        <Grid item xs>
          <Tooltip title={`Remove this move from the driver's schedule and return it to the unassigned moves list.`}>
            <div>
              <Button
                disabled={
                  !isPlanEditable &&
                  (moveWithOverrides.status !== null &&
                    moveWithOverrides.status !== `dispatched` &&
                    moveWithOverrides.status !== 'pickup started')
                }
                fullWidth
                size='large'
                onClick={() => handleUnplanDrive()}
              >
                Unplan Move
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  triangeSection: {
    display: 'flex',
    flexWrap: 'nowrap',
    // justifyContent: 'space-between',
    alignItems: 'left',
  },
  check: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  iconConcierge: {
    marginBottom: -3,
    fontSize: 32,
    color: theme.palette.concierge.main,
    cursor: 'pointer',
  },
  iconLoaner: {
    marginBottom: -3,
    fontSize: 32,
    color: theme.palette.concierge.main,
    cursor: 'pointer',
  },
  iconOps: {
    marginBottom: -3,
    fontSize: 32,
    color: theme.palette.ops.main,
    cursor: 'pointer',
  },
  darkBackground: {
    background: theme.palette.background.dark,
  },
}));
