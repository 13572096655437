//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Tooltip, Typography } from '@material-ui/core';
import MoveStatusTracker from './MoveStatusTracker';
import { useTools } from '../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

function MoveTile({ move = {}, ...props }) {
  const cls = useStyles();
  const { goToMoveDetails, capFirst, getFormattedVehicleFromMove } = useTools();

  return (
    <div className={cls.move} onClick={() => goToMoveDetails(move.id)}>
      {move.move_type ? (
        <Typography className={cls.txtMoveType}>Move Type - {capFirst(move.move_type)}</Typography>
      ) : null}

      <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' className={cls.top}>
        <Grid item className={cls.preventOverflow}>
          <Typography className={cls.txtVehicle + ' ' + cls.ellipse}>{getFormattedVehicleFromMove(move)}</Typography>
          <Typography className={cls.txtLane}>
            {move.lane && move.lane.description ? move.lane.description : 'N/A'}
          </Typography>
        </Grid>

        <Grid item xs>
          <Tooltip placement='top-end' title='Move ID'>
            <Typography className={cls.txtId}>{move.id}</Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <MoveStatusTracker move={move} size='small' />

      <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' className={cls.bottom}>
        <Grid item xs={3}>
          <Tooltip placement='bottom-start' title='Stock'>
            <Typography className={cls.txtVal} style={{ textAlign: 'left' }}>
              {move.vehicle_stock ? move.vehicle_stock : `N/A`}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={6}>
          <Tooltip placement='bottom' title='VIN'>
            <Typography className={cls.txtVal} style={{ textAlign: 'center' }}>
              {move.vehicle_vin ? move.vehicle_vin : `N/A`}
            </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Tooltip placement='bottom-end' title='Reference ID'>
            <Typography className={cls.txtVal} style={{ textAlign: 'right' }}>
              {move.reference_num ? move.reference_num : `N/A`}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      {move.cancel_status && move.cancel_status !== 'rescheduled' ? (
        <div className={cls.canceled}>
          <Typography className={cls.txtCanceled}>CANCELED</Typography>
        </div>
      ) : null}

      {move.cancel_status && move.cancel_status === 'rescheduled' ? (
        <div className={cls.canceled}>
          <Typography className={cls.txtCanceled}>RESCHEDULED</Typography>
        </div>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  move: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    '&:hover': {
      background: theme.palette.background.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  txtMoveType: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  top: {
    minWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  bottom: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
  },
  txtVehicle: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  txtLane: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  txtId: {
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  txtKey: {
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  txtVal: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  canceled: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: '#000000a0',
  },
  txtCanceled: {
    position: 'absolute',
    top: '40%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 21,
    },
  },
  ellipse: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  preventOverflow: {
    maxWidth: '85%',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveTile;
