import { gql } from 'graphql-tag';

const GET_REGIONS = gql`
    query {
        regions(order_by: {region_id: asc}) {
            team_id
            name
        }
    }
`

export { GET_REGIONS };