import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

////////// COMPONENT //////////
export default function ARReportsFooter({ amount, report }) {
  const cls = useStyles();

  return (
    <>
      {amount && (
        <Grid container spacing={2}>
          <Grid item md={8} sm={6} xs={false}></Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className={cls.paper}>
              <Typography className={cls.headTxt}>Calculated Totals</Typography>

              <div className={cls.smallBreak} />

              {amount.discounted || amount.disputed ? (
                <>
                  <Typography display='inline' className={cls.key}>
                    Subtotal&nbsp;
                  </Typography>
                  <Typography display='inline' className={cls.val}>
                    ${amount.subtotal.toFixed(2)}
                  </Typography>
                  <br />
                  {amount.discounted ? (
                    <>
                      <Typography display='inline' className={cls.key}>
                        Discounted&nbsp;
                      </Typography>
                      <Typography display='inline' className={cls.val}>
                        (${amount.discounted.toFixed(2)})
                      </Typography>
                      <br />
                    </>
                  ) : null}
                  {amount.disputed ? (
                    <>
                      <Typography display='inline' className={cls.key}>
                        Disputed&nbsp;
                      </Typography>
                      <Typography display='inline' className={cls.val}>
                        (${amount.disputed.toFixed(2)})
                      </Typography>
                      <br />
                    </>
                  ) : null}
                  <div className={cls.lineBreak} />
                </>
              ) : null}

              <Typography display='inline' className={cls.keyDemi}>
                Total&nbsp;
              </Typography>
              <Typography display='inline' className={cls.valDemi}>
                ${amount.total ? amount.total.toFixed(2) : 0}
              </Typography>

              <div className={cls.lineBreak} />

              <Typography display='inline' className={cls.keyBold}>
                {report === 'moves' ? `Total Paid` : 'Total Successful'}
              </Typography>
              <Typography display='inline' className={cls.valBold}>
                ${amount.paid ? amount.paid.toFixed(2) : amount.successful ? amount.successful : 0}
              </Typography>
              <br />
              <Typography display='inline' className={cls.keyBold}>
                {report === 'moves' ? `Total Unpaid` : 'Total Failed'}
              </Typography>
              <Typography display='inline' className={cls.valBold}>
                ${amount.unpaid ? amount.unpaid.toFixed(2) : amount.failed ? amount.failed : 0}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  key: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  val: {
    float: 'right',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  keyDemi: {
    color: theme.palette.text.tertiary,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valDemi: {
    float: 'right',
    color: theme.palette.text.tertiary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  keyBold: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valBold: {
    float: 'right',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    background: '#ddd',
  },
}));
