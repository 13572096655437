import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';

const NotificationsContext = createContext({});

function NotificationsProvider({ children }) {
  const [readList, setReadList] = useState(JSON.parse(localStorage.getItem(`read-notifications`)) || []);
  const [title, setTitle] = useState(null)
  const [body, setBody] = useState(null)
  const [type, setType] = useState(null)
  const [assignedTo, setAssignedTo] = useState(null)
  const [assignedAt, setAssignedAt] = useState(null)
  const [assignedBy, setAssignedBy] = useState(null)
  const [resolvedBy, setResolvedBy] = useState(null)
  const [resolvedAt, setResolvedAt] = useState(null)
  const [notes, setNotes] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [assignmentBell, setAssignmentBell] = useState(false)
  const [isDueDatePast, setIsDueDatePast] = useState(null)

  const checkDueDate =  (notification) => {
    if (notification.duedate && !dayjs().isBefore(notification.duedate)){
      notification.overdue = true
    } else {
      notification.overdue = false
    }
  }

  const addToReadList = id => {
    let newReadList;
    if (readList !== [] && !readList.includes(id)) {
      newReadList = [...readList, id];
      setReadList(newReadList);
    } else {
      newReadList = [id];
      setReadList(newReadList);
    }
    localStorage.setItem('read-notifications', JSON.stringify(newReadList));
  };

  const context = {
    readList: readList,
    setReadList: setReadList,
    addToReadList: addToReadList,
    type: type,
    setType: setType,
    title: title,
    setTitle: setTitle,
    body: body,
    setBody: setBody,
    assignedTo: assignedTo,
    setAssignedTo: setAssignedTo,
    assignedAt: assignedAt, 
    setAssignedAt: setAssignedAt,
    assignedBy: assignedBy,
    setAssignedBy: setAssignedBy,
    resolvedBy: resolvedBy,
    setResolvedBy: setResolvedBy,
    resolvedAt: resolvedAt,
    setResolvedAt: setResolvedAt,
    notes: notes,
    setNotes: setNotes,
    assignmentBell: assignmentBell,
    setAssignmentBell: setAssignmentBell,
    dueDate: dueDate,
    setDueDate: setDueDate,
    isDueDatePast: isDueDatePast,
    setIsDueDatePast: setIsDueDatePast,
    checkDueDate: checkDueDate

  };

  return <NotificationsContext.Provider value={context}>{children}</NotificationsContext.Provider>;
}

const useNotificationsGlobals = () => useContext(NotificationsContext);

export { useNotificationsGlobals, NotificationsProvider };
