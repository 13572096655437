import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  Chip,
  Icon,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
} from '@material-ui/core';
import ProductFeeFormModalWrapper from './ProductFeeFormModalWrapper';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';
import { Divide } from '@hopdrive/storybook'

const log = false;

export default function ProductFeeTable({ productFees, rateRuleGroupId, onChange = productFees => {}, setHasChanged }) {
  const cls = useStyles();

  const [unsavedProductFees, setUnsavedProductFees] = useState(productFees);
  const [selectedProductFee, setSelectedProductFee] = useState({});
  const [formModalOpen, setFormModalOpen] = useState(false);

  useEffect(() => {
    log && console.log(productFees, 'productFees');
    if (!productFees) return;
    setUnsavedProductFees(productFees);
    log && console.log('ProductFeeTable loaded with: ', productFees);
  }, [productFees]);

  useEffect(() => {
    if (!unsavedProductFees) return;
    if (unsavedProductFees.length < 1) return;
    log && console.log('Unsaved Products Fees changed to: ', unsavedProductFees);
    const hasChanges = unsavedProductFees.some(o => o.hasChanges);
    if (hasChanges) onChange(unsavedProductFees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedProductFees]);

  const formatProductName = string => {
    if (string) {
      if (!string.includes(`_`)) return string.charAt(0).toUpperCase() + string.slice(1);
      else {
        let arr = string.split(`_`);
        arr = arr.map(substring => substring.charAt(0).toUpperCase() + substring.slice(1));
        return arr.join(` `);
      }
    }
  };

  const handleRowClick = productFee => event => {
    log && console.log('handleRowClick()');
    log && console.log('handleRowClick() -- productFee:', productFee);
    setSelectedProductFee(productFee);
    setFormModalOpen(true);
  };

  const handleAddProductFee = newProductFee => {
    const productFeeArray = unsavedProductFees;
    //this is used to find or mark the product fee as selected before insertion
    const fakeId = (productFeeArray.length + 1) * -1;
    newProductFee.id = fakeId;
    productFeeArray.push(newProductFee);
    setSelectedProductFee(newProductFee);
    setUnsavedProductFees(productFeeArray);
    setHasChanged(true);
    setFormModalOpen(false);
  };

  const productTermText = (product, billable_date) => {
    let term = product.term;
    if (!product || !term) {
      return <Typography>no product term found</Typography>;
    } else if (term === 'once' && billable_date === null) {
      //one-time product not yet charged to customer
      return (
        <Typography>
          once <Chip component='span' label={'uncharged'} variant={'default'} size='small' color='secondary' />
        </Typography>
      );
    } else if (term === 'once' && billable_date !== null) {
      //one-time product charged to the customer
      return (
        <Typography>
          once <Chip component='span' label={'charged'} variant={'default'} size='small' color='primary' />
        </Typography>
      );
    } else {
      return <Typography>{term}</Typography>;
    }
  };

  const productFeeSave = unsavedProductFee => {
    setFormModalOpen(false);

    unsavedProductFee.charge = Number(unsavedProductFee.charge);
    // unsavedProductFee.billable_date = Number(unsavedProductFee.billable_date);

    //Replace this unsaved product fee in the product fees state array
    setUnsavedProductFees(
      unsavedProductFees.map(productFee => {
        if (productFee.id === unsavedProductFee.id) return { ...unsavedProductFee, hasChanges: true };
        return productFee;
      })
    );

    setSelectedProductFee({});
  };

  const handleRemoveProduct = unsavedProductFee => {
    console.log('handleRemoveProduct');
    setFormModalOpen(false);

    //If productfee is in database, set it to inactive
    //if product fee is not in database, it will be removed from the array of unsaved product fees
    let unsavedProductFeeArray = unsavedProductFees.map(productFee => {
      if (productFee.id === unsavedProductFee.id && productFee.id && productFee.id > 0) {
        unsavedProductFee.active = false;
        return { ...unsavedProductFee, hasChanges: true };
      } else if (productFee.id === unsavedProductFee.id && productFee.id && productFee.id < 0) {
        return null;
      } else {
        return productFee;
      }
    });
    let filteredUnsavedProductFeeArray = unsavedProductFeeArray.filter(pf => pf !== null);
    setUnsavedProductFees(filteredUnsavedProductFeeArray);

    setSelectedProductFee({});
  };

  const activeUnsavedProductFees = unsavedProductFees.filter(pf => pf.active === true);

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMER PRODUCTS' />}>
      {!productFees && <></>}
      {productFees && (
        <>
          <ProductFeeFormModalWrapper
            open={formModalOpen}
            editMode={selectedProductFee ? true : false}
            productFee={selectedProductFee}
            onEditSave={productFeeSave}
            onAddSave={handleAddProductFee}
            onCancel={() => {
              setFormModalOpen(false);
              setSelectedProductFee({});
            }}
            handleRemoveProduct={handleRemoveProduct}
          />
          <div className={cls.divide}>
            <Divide>Products</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='rate rules table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Term</TableCell>
                <TableCell align='center'>Price</TableCell>
                <TableCell align='right'>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeUnsavedProductFees.map((row, index) => (
                <TableRow
                  hover
                  key={row.id || 'pfRow' + index}
                  className={`${
                    (selectedProductFee && row ? selectedProductFee.id === row.id : null) ? cls.rowIsSelected : null
                  } ${row.hasChanges === true ? cls.rowHasChanges : null}`}
                >
                  <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                    {row.product ? formatProductName(row.product.name) : 'no product name found'}
                  </TableCell>
                  <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                    {productTermText(row.product, row.billable_date)}
                  </TableCell>
                  <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='center'>
                    ${row.charge}
                  </TableCell>
                  <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right' width='20%'>
                    {row.notes}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow hover key={-1}>
                <TableCell
                  colSpan={5}
                  align='center'
                  onClick={() => {
                    setFormModalOpen(true);
                    setSelectedProductFee(null);
                  }}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='primary' size='small' startIcon={<Icon>add_box</Icon>}>
                    Add Product
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
  },
  rowIsSelected: {
    transition: 'all .2s ease-in-out',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c3c3c3',
      color: '#fff',
    },
  },
  rowHasChanges: {
    backgroundColor: '#ffe9ec',
    fontStyle: 'italic',
    color: '#fff',
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divide: {
    marginTop: '25px', 
    marginBottom: '10px',
  }
}));
