// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useRegions } from './RegionsProvider';

import IconRefetch from '../../components/IconRefetch';
import IconAction from '../../components/IconAction';
import RegionsAddEdit from './RegionsAddEdit';
import RegionsTable from './RegionsTable';

// COMPONENT -------------------------------------------------- //

export default function RegionsSidebar({ regions, refetch }) {
  const cls = useStyles();

  const { editMode, unselectRegion, addNewRegion } = useRegions();

  const handleRefetch = () => {
    unselectRegion();
    refetch();
  };

  return (
    <div className={cls.sidebar}>
      <div className={cls.sidebarTop}>
        <div className={cls.sectionPad}>
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography className={cls.sidebarTopTitle}>Regions</Typography>
            </Grid>

            <Grid item>
              <Button className={cls.btn} color='primary' onClick={() => addNewRegion()}>
                Add Region
              </Button>
            </Grid>

            <Grid item>
              <IconRefetch refetch={handleRefetch} />
            </Grid>

            <Grid item>
              <IconAction />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={cls.sidebarBottom}>
        {editMode ? (
          <RegionsAddEdit regions={regions} refetch={refetch} />
        ) : (
          <RegionsTable regions={regions} refetch={refetch} />
        )}
      </div>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  sidebar: {
    zIndex: 10,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    height: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },

  sidebarTop: {
    zIndex: 11,
    boxShadow: theme.shadow.medium,
  },
  sidebarTopTitle: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  sidebarBottom: {
    height: '100%',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },

  btn: {
    marginRight: 8,
  },

  sectionPad: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },

  tableAmount: {
    padding: theme.spacing(1, 2, 1, 2),
    background: theme.palette.default.main,
  },
  tableAmountTxt: {
    lineHeight: 1.25,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.contrast,
  },

  line: {
    width: '100%',
    height: 1,
    background: theme.palette.dividerStrong,
  },
}));
