//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { GET_DISPUTED_MOVES } from './gql';

import Loading from '../../components/Loading';
import Spacer from '../../components/Spacer';
import Toolbar from '../../components/Toolbar';

import ARReviewModal from '../../components/ARReview/ARReviewModal';
import ARLogsModal from '../../components/ARLogsModal';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import { DisputedTable } from './index';

//////////////////////// COMPONENT ////////////////////////

function DisputedMoves(props) {
  const cls = useStyles();

  const { loading, error, data, refetch } = useQuery(GET_DISPUTED_MOVES);

  // Review Modal
  const [arReviewModal, setARReviewModal] = React.useState({ open: false });
  const handleARReviewModalOpen = (armove = null) => {
    setARReviewModal({
      ...arReviewModal,
      open: true,
      input: armove,
    });
  };
  const handleARReviewModalClose = (output = null) => {
    if (output) setARReviewModal({ ...arReviewModal, open: false, output: output });
    else setARReviewModal({ ...arReviewModal, open: false });
  };

  // Logs Modal
  const [arLogsModal, setARLogsModal] = React.useState({ open: false });
  const handleARLogsModalOpen = (type = null, events = null) => {
    setARLogsModal({
      ...arLogsModal,
      open: true,
      input: {
        type: type,
        events: events,
      },
    });
  };
  const handleARLogsModalClose = (output = null) => {
    if (output) setARLogsModal({ ...arLogsModal, open: false, output: output });
    else setARLogsModal({ ...arLogsModal, open: false });
  };

  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Disputed Moves' />
          <Spacer />
          <Loading fixed />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching disputed moves:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Disputed Moves' />
          <Spacer />
          <DefaultErrorFallback paper message='ERROR FETHING DISPUTED MOVES' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.armoves || !data.armoves.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Disputed Moves' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback paper message='NO DISPUTED MOVES FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //
  const disputedMoves = data.armoves;

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING DISPUTED MOVES' />}>
      <ARReviewModal open={arReviewModal.open} onClose={handleARReviewModalClose} input={arReviewModal.input} />
      <ARLogsModal open={arLogsModal.open} onClose={handleARLogsModalClose} input={arLogsModal.input} />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title={`${disputedMoves.length} Disputed Moves`} refetch={handleRefetch} />
          <Spacer />
          <DisputedTable
            disputedMoves={disputedMoves}
            handleARReviewModalOpen={handleARReviewModalOpen}
            handleARLogsModalOpen={handleARLogsModalOpen}
          />
        </Container>
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default DisputedMoves;
