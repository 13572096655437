//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';
import { useEngine } from '../../hooks/useEngine';

import { Table, Column } from '../../components/TableComponents';

const statusSequence = [
  null,
  'dispatched',
  'pickup started',
  'pickup arrived',
  'pickup successful',
  'delivery started',
  'delivery arrived',
  'delivery successful',
];

//////////////////////// COMPONENT ////////////////////////

export default function MovesByCustomerTable({ moves, statusArray, search }) {
  const cls = useStyles();
  const {
    condensedCase,
    formatTime,
    getFormattedCombinedStatusFromMove,
    getFormattedVehicleFromMove,
    goToMoveDetails,
  } = useTools();
  const { rerunAR } = useEngine();

  // Set filtered moves based on search results
  const [filteredMoves, setFilteredMoves] = React.useState(moves);
  React.useEffect(() => {
    let newFilteredMoves = moves;

    if (statusArray?.length) {
      newFilteredMoves = newFilteredMoves?.filter(m => {
        const statusValues = statusArray?.map(s => s?.value);
        return statusValues?.includes(m?.status || m?.cancel_status);
      });
    }

    if (search && search.length) {
      const searchInput = condensedCase(search);
      newFilteredMoves = newFilteredMoves.filter(m => {
        return (
          `${m?.id}`.includes(searchInput) ||
          condensedCase(m?.lane?.pickup?.name || ``).includes(searchInput) ||
          condensedCase(m?.lane?.delivery?.name || ``).includes(searchInput) ||
          condensedCase(m?.vehicle_color || ``).includes(searchInput) ||
          condensedCase(m?.vehicle_make || ``).includes(searchInput) ||
          condensedCase(m?.vehicle_model || ``).includes(searchInput) ||
          condensedCase(m?.vehicle_vin || ``).includes(searchInput) ||
          condensedCase(m?.vehicle_year || ``).includes(searchInput)
        );
      });
    }

    setFilteredMoves(newFilteredMoves);
    // eslint-disable-next-line
  }, [moves, statusArray, search]);

  const rowActions = [
    {
      label: `Go To Move Details`,
      handler: move => goToMoveDetails(move?.id),
    },
    {
      label: `Rerun Accounts Receivable`,
      handler: move => rerunAR(move?.id),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={filteredMoves}
        onRowClick={move => goToMoveDetails(move?.id)}
        rowActions={rowActions}
        defaultOrderBy={'pickup_time'}
        defaultOrder={'desc'}
        tableAriaLabel={`moves-by-customer`}
        stickyHeader={true}
      >
        <Column
          name={`id`}
          label={`ID`}
          tooltip={`ID of the move`}
          value={move => move?.id || `-`}
          style={{ minWidth: 80 }}
        />

        <Column
          name={`pickup_time`}
          label={`Pickup`}
          tooltip={`Pickup time of the move`}
          value={move => move?.pickup_time}
          renderer={move => formatTime(move?.pickup_time)}
          style={{ minWidth: 220 }}
        />

        <Column name={`vehicle_vin`} value={move => move?.vehicle_vin} label={`VIN`} tooltip={`VIN of the vehicle`} />

        <Column
          name={`vehicle`}
          label={`Vehicle`}
          tooltip={`Year, make, model and color of the vehicle`}
          disableSort={true}
          value={move => getFormattedVehicleFromMove(move, true, false)}
          style={{ minWidth: 160 }}
        />

        <Column
          name={`lane`}
          disableSort={true}
          value={move => `${move?.lane?.pickup?.name} to ${move?.lane?.delivery?.name}`}
          label={`Lane`}
          tooltip={`Pickup and delivery of the move`}
        />
        <Column
          name={`status`}
          label={`Status`}
          tooltip={`Status of the move`}
          value={move => statusSequence.indexOf(move.status)}
          renderer={move => <Chip label={getFormattedCombinedStatusFromMove(move)} size='small' variant='outlined' />}
          align={`right`}
        />
      </Table>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  rowTxt: {
    lineHeight: 1.43,
    fontSize: 14,
    fontWeight: 400,
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  statusLive: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
  },
  statusOnboarding: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
  },
  statusDeactivated: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
  },
  statusUnknown: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },

  statusIcon: {
    marginLeft: 2,
    marginRight: -2,
  },

  activeTrueChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },
  activeFalseChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));
