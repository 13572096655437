import dayjs from 'dayjs';
import sdk from '@hopdrive/sdk';
import { useTheme } from '@material-ui/core';
import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';

import { SimpleLogger } from '../../../utils/SimpleLogger';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// COMPONENT ////////////////////////

export function useEnrichedPlans() {
  const theme = useTheme();
  const { getLatestTimestampFromMove, getDriveTypeFromMove } = useTools();
  const { timelineDate, isTimelineDateToday, timelineMoveOffset, timelineScaleWidth, enableEnrichmentLogs } =
    useSettings();
  const { timezoneOverride } = useRegions();

  const { log } = new SimpleLogger({ prefix: 'useEnrichedPlans', enabled: enableEnrichmentLogs });

  //////////////////////// BUILD FUNCTIONS ////////////////////////

  /** Build an enrichedPlan with enrichedMoves
   * @param {Array} plan - GQL plan object
   * @returns {Object} enrichedPlan object
   */
  const buildEnrichedPlan = (plan = {}) => {
    plan.moves = plan.moves.sort((a, b) => {
      let aPickupTime = a.pickup_time;
      let bPickupTime = b.pickup_time;

      if (aPickupTime > bPickupTime) {
        return 1;
      } else if (aPickupTime < bPickupTime) {
        return -1;
      } else return 0;
    });
    const enrichedPlan = {
      // Put in a safe getter of the plan id if it exists. This is
      // really just a convenience thing so when we log out this object
      // we don't have to unfold the move object to find the id.
      get id() {
        return (this.plan && this.plan.id) || null;
      },

      plan: plan,

      enrichedMoves: buildEnrichedMovesArray(plan.moves),
    };
    return enrichedPlan;
  };

  /** Build an array of enrichedMoves from a plan
   * @param {Array} planMoves - GQL move objects
   * @returns {Array} Array of enrichedMove objects with nested moves
   */
  const buildEnrichedMovesArray = planMoves => {
    // Initialize the building array
    let enrichedMovesArray = [];

    // Check if planMoves exists
    if (planMoves && planMoves.length > 0) {
      // Set a count to account for the canceled moves being taken out (helps get the previous enrichedMove)
      let arrayCount = -1;

      // Loop over the moves and create an enrichedMove for each
      for (let i = 0; i < planMoves.length; i++) {
        if (planMoves[i].cancel_status === `canceled`) continue;
        // DANIEL TODO: do we want to remove rescheduled moves from the plan?
        if (planMoves[i].cancel_status === `rescheduled`) continue;
        arrayCount += 1; // Increment the count if the move is valid (to help find the previous move)
        const prevEnrichedMove = enrichedMovesArray.length > 0 ? enrichedMovesArray[arrayCount - 1] : null;
        if (planMoves[i].move_type === 'drive')
          enrichedMovesArray.push(buildEnrichedMove(planMoves[i], prevEnrichedMove));
        if (planMoves[i].move_type === 'ride')
          enrichedMovesArray.push(buildEnrichedRide(planMoves[i], prevEnrichedMove));
      }
    }

    // Return the array of enrichedMoves
    return enrichedMovesArray;
  };

  /** Builds an object that will contain both a copy of the original move object and also a series of flags and mutable values describing changes made to the move during planning
   * This function will be run on each of the moves before planning. As moves are immutable, these are the items that will be changed when the moves are planned
   * @param {Object} move - GQL move object
   * @param {Object} prevEnrichedMove - The previous enriched move in the array
   * @returns {Object} enrichedMove object
   */
  const buildEnrichedMove = (move = {}, prevEnrichedMove = null) => {
    // Initialize the enrichedMove object with defaults and getters
    let enrichedMove = {
      // Put in a safe getter of the move id if it exists. This is
      // really just a convenience thing so when we log out this object
      // we don't have to unfold the move object to find the id.
      get id() {
        return (this.move && this.move.id) || null;
      },

      // Put in the original move record from the DB for reference
      move: move,
      overrides: {},

      get withOverrides() {
        return {
          ...(this.move || {}),
          ...(this.overrides || {}),
        };
      },

      // Check if there are any pending changes not yet committed to
      // the database.
      get hasChanges() {
        return this.overrides && Object.keys(this.overrides).length > 0;
      },

      // Number of seconds for the duration of the move. For unstarted moves
      // this comes from the lane associated with the move in the database
      // copied here for easier access (is first set in refreshEnrichedMoves)
      duration: 0,

      // Ride moves that are generated as placeholders or suggestions
      // will make use of this same enrichedMove object, but will only
      // have data in the child overrides object. For those types, this
      // flag will be set to true. When they are converted to real ride
      // moves, then this flag will be removed.
      isPlaceholder: false,

      //Flag that determines if a move should be considered "in progress"
      //A move is in progress if it has any status besides null or 'dispatched' OR a 'isCompleted' status
      get inProgress() {
        if (!this.move || !this.move.status) return false;
        const moveStatus = this.move.status;
        if (
          moveStatus === 'pickup arrived' ||
          moveStatus === 'pickup started' ||
          moveStatus === 'pickup successful' ||
          moveStatus === 'delivery arrived' ||
          moveStatus === 'delivery started' ||
          moveStatus === 'delivery successful' ||
          moveStatus === 'accepted' ||
          moveStatus === 'arrived' ||
          moveStatus === 'pickedUp' ||
          moveStatus === 'droppedOff'
        ) {
          return true;
        } else {
          return false;
        }
      },

      get isCompleted() {
        if (!this.move || !this.move.status) return false;
        const moveStatus = this.move.status;
        if (moveStatus === 'delivery successful' || moveStatus === 'droppedOff') {
          return true;
        } else {
          return false;
        }
      },

      get isDeclined() {
        if (!this.move) return false;
        const moveStatus = this.move.status;
        const driverStatus = this.move.driver_status;
        if (moveStatus === `dispatched` && driverStatus === `declined`) {
          return true;
        } else {
          return false;
        }
      },

      get isCanceled() {
        if (!this.move) return false;
        const moveStatus = this.move.status;
        const cancelStatus = this.move.cancel_status;
        if (moveStatus === `canceled` || cancelStatus === `started`) {
          return true;
        } else {
          return false;
        }
      },

      get isFailed() {
        if (!this.move || !this.move.status) return false;
        const moveStatus = this.move.status;
        if (moveStatus === `failed`) {
          return true;
        } else {
          return false;
        }
      },

      get isRescheduled() {
        if (!this.move) return false;
        const cancelStatus = this.move.cancel_status;
        if (cancelStatus === `rescheduled`) {
          return true;
        } else {
          return false;
        }
      },

      // If this move has a ride immediately before or after it in the
      // chronological sequence of the plan then we will use these flags
      // to indicate that. It will be later in the render loop that they
      // get updated.
      hasRideBefore: false,
      hasRideAfter: false,

      // To make it easier for downstream processes to reference the adjacent
      // moves, these properties will hold a reference to those objects. It
      // will be later in the render loop that they get updated.
      enrichedRideBefore: null,
      enrichedRideAfter: null,

      // To make it easier for downstream processes to reference the adjacent
      // moves, these properties will hold a reference to those objects. It
      // will be later in the render loop that they get updated.
      prior: null,
      next: null,

      // A flag to indicate whether or not the move should be draggable in
      // the vertical scheduler stack allowing the admin to change the order
      // that the moves are placed in the driver's plan.
      get isDraggable() {
        return !this.isCompleted && !this.inProgress;
      },

      hasRideGapBefore: false,
      hasRideGapAfter: false,

      hasLinkBefore: false,
      hasLinkAfter: false,

      // Only applicable to ride moves, this will define what the destination
      // type is such as a ride back to their parked car location, the next
      // move's pickup location, or nothing at all (null) if it's not a ride
      // type move.
      // Intended use of this is to keep track for the ride modal whether or
      // not the destination of the ride move is to the parked car or not.
      // Valid values are 'next move' | 'parked car' | 'custom' | null
      rideLaneType: null,

      // Number of seconds difference between the end of this move and the start
      // of the next if there is a next move.
      buffer: 0,

      // If anything goes wrong in the populating of the enrichedMove properties
      // this flag will be set to false
      isValid: true,

      // For concierge + loaners, the UI needs to treat them as a set and never
      // allow them to be separated. In an effort to ensure the "set" logic is
      // consistent and not repeated through out the code, this property will
      // hold the related enrichedMove. If this move is the concierge drive,
      // then this property will hold the enriched move loaner (if there is
      // one). If this move is the loaner, then this property will hold the
      // concierge drive enriched move. It will be later in the render loop
      // that they get updated.
      linkedEnrichedMove: null,
    };

    // Check if there is a move, then assign core values
    if (move) {
      // Set the planned start and end times of the move
      enrichedMove.plannedStartTime = getPlannedStartTime(move);
      enrichedMove.plannedEndTime = getPlannedEndTime(move);
      enrichedMove.plannedDuration = durationBetween(enrichedMove.plannedStartTime, enrichedMove.plannedEndTime);

      // Set the actual start and end times of the move
      enrichedMove.actualStartTime = getActualStartTime(move);
      enrichedMove.actualEndTime = getActualEndTime(move);
      enrichedMove.actualDuration = durationBetween(enrichedMove.actualStartTime, enrichedMove.actualEndTime);

      // Set the most accurate start and end times of the move (uses the planned times if actual times arent available)
      enrichedMove.accurateStartTime = getAccurateStartTime(move);
      enrichedMove.accurateEndTime = getAccurateEndTime(move);
      enrichedMove.accurateDuration = durationBetween(enrichedMove.accurateStartTime, enrichedMove.accurateEndTime);

      // Set the amount of pixels the move should be from the start of day (based on the most accurate pickup time)
      enrichedMove.startPx = getStartPx(enrichedMove);

      // Set the visual start time of the move (where it should start on the timeline)
      enrichedMove.visualStartTime = getVisualStartTime(enrichedMove, prevEnrichedMove);

      // Set the amount of pixels between the move and previous move
      enrichedMove.gapPx = getGapPxBetweenMoves(enrichedMove, prevEnrichedMove);

      // Set the planned durations of the lane (in seconds)
      if (move.move_type === `ride`) enrichedMove.plannedWaitDuration = getPlannedWaitDuration(move.lane);
      enrichedMove.plannedPickupDuration = getPlannedPickupDuration(move.lane);
      enrichedMove.plannedDriveDuration = getPlannedDriveDuration(move.lane);
      enrichedMove.plannedDeliveryDuration = getPlannedDeliveryDuration(move.lane);

      // Set the actual durations (in seconds) that show each part of the move in detail
      if (move.move_type === `ride`)
        enrichedMove.actualWaitDuration = durationBetween(move.pickup_started, move.pickup_arrived);
      enrichedMove.actualPickupDuration = durationBetween(move.pickup_arrived, move.pickup_successful);
      enrichedMove.actualDriveDuration = durationBetween(move.pickup_successful, move.delivery_arrived);
      enrichedMove.actualDeliveryDuration = durationBetween(move.delivery_arrived, move.delivery_successful);

      if (move.move_type === `ride`)
        enrichedMove.extraWaitDuration = enrichedMove.actualWaitDuration - enrichedMove.plannedWaitDuration;
      enrichedMove.extraPickupDuration = enrichedMove.actualPickupDuration - enrichedMove.plannedPickupDuration;
      enrichedMove.extraDriveDuration = enrichedMove.actualDriveDuration - enrichedMove.plannedDriveDuration;
      enrichedMove.extraDeliveryDuration = enrichedMove.actualDeliveryDuration - enrichedMove.plannedDeliveryDuration;

      // Set the durations of the move (in seconds). These are ONLY for visual use (they have caps)
      enrichedMove.minDuration = getMinDuration(enrichedMove);
      if (move.move_type === `ride`) enrichedMove.waitDelay = getWaitDelay(enrichedMove);
      enrichedMove.pickupDelay = getPickupDelay(enrichedMove);
      enrichedMove.driveDelay = getDriveDelay(enrichedMove);
      enrichedMove.deliveryDelay = getDeliveryDelay(enrichedMove);
      enrichedMove.maxDuration = getMaxDuration(enrichedMove);

      // Set render widths of the move based on the durations and delays
      enrichedMove.minWidth = getMinWidth(enrichedMove.minDuration);
      if (move.move_type === `ride`) enrichedMove.waitWidthExtra = getDelayWidth(enrichedMove.waitDelay);
      enrichedMove.pickupWidthExtra = getDelayWidth(enrichedMove.pickupDelay);
      enrichedMove.driveWidthExtra = getDelayWidth(enrichedMove.driveDelay);
      enrichedMove.deliveryWidthExtra = getDelayWidth(enrichedMove.deliveryDelay);
      enrichedMove.maxWidth = getMaxWidth(enrichedMove.maxDuration);

      // Set the visual end time of the move (where it should end on the timeline)
      enrichedMove.visualEndTime = getVisualEndTime(enrichedMove);

      // Set the zIndex of the move based on its status and move type
      enrichedMove.zIndex = getZIndex(move);

      // Set the background color property of the move based on its move type and drive type
      enrichedMove.bgColor = getBgColor(move);

      // Set the animation property of the move depending on its status
      enrichedMove.animation = getAnimation(move);

      // Set the icon render flags on the move tile
      enrichedMove.hasOpsTag = getHasOpsTag(move);
      enrichedMove.hasConciergeTag = getHasConciergeTag(move);
      enrichedMove.hasLoanerTag = getHasLoanerTag(move);
      enrichedMove.hasManualTag = getHasManualTag(move);
      enrichedMove.hasRailyardTag = getHasRailyardTag(move);
      enrichedMove.hasSlaTag = getHasSlaTag(enrichedMove);
      enrichedMove.hasNotesTag = getHasNotesTag(move);
      enrichedMove.hasMoreTag = getHasMoreTag(move);

      // Set whether the icon section should even be rendered at all
      enrichedMove.hasTags = getHasTags(enrichedMove);

      // Set the deadline countdown in ms
      enrichedMove.deadlineMs = getDeadlineMs(enrichedMove);
    }

    // Return the enrichedMove
    return enrichedMove;
  };

  //Enrich a ride from the database - rides need extra fields so we can track their progress when they change lanes
  //This is basically a copy of buildPlaceholderRide with some flags flipped and no overrides set up top
  const buildEnrichedRide = (move = {}, prevEnrichedMove = null) => {
    // Build the base move to start with then we will layer on the ride specific props
    let enrichedMove = buildEnrichedMove(move, prevEnrichedMove);

    let rideFlags = {
      //Set the parent move id field (tracks which drive move will be assigned this moves return_ride_id)
      parentMoveId: move ? move.parent_move_id : null,

      //These flags track the 3 steps of the ride's lane creation
      //On a ride from the db they are all set to 'successful' to show the ride has all lane data present
      googleEnrichmentStatus: 'successful',
      apEnrichmentStatus: 'successful',
      arEnrichmentStatus: 'successful',

      // This ride already exists, therefor it is not suggested and changes to it can be persisted
      isPlaceholder: false,

      // possible values: 'next stop' || 'prev stop' || 'return ride' || 'parked car' || 'custom'
      //The type will be detected when the scheduler is run on initial load. The default setting for existing lanes is 'custom'
      rideLaneType: 'custom',

      // For the stupid case where the plan starts with a ride
      isStartingRide: !prevEnrichedMove ? true : false,
    };

    // Merge our placeholder properties with the generated enrichedMove
    try {
      Object.assign(enrichedMove, rideFlags);
    } catch (error) {
      log(`  🌱 ${error.message} occurred when assigning placeholder props to the enrichedMove shell`);
    }

    // log(`  🌱 Built Enriched Ride Move for ride move: ${move.id}: `, enrichedMove);
    return enrichedMove;
  };

  //////////////////////// HELPER FUNCTIONS ////////////////////////

  /** Get the current time */
  const getCurrentTime = () => {
    return dayjs().utc().format();
    // return dayjs().utc().add(30, `minute`).format(); // TEST for when you want to change the current time manually
  };

  /** Get the current position on the timeline */
  const getCurrentPosition = () => {
    const startOfDay = dayjs(timelineDate).tz(timezoneOverride).add(6, `hours`).startOf(`day`).format();
    const currentTime = dayjs().tz(timezoneOverride);
    // const currentTime = dayjs().add(30, `minute`); // TEST for when you want to change the current time manually
    const timeFromStart = currentTime.diff(startOfDay, `second`);
    return timeFromStart * timelineScaleWidth;
  };

  /** Get the current time if the plan is on the current day */
  const getCurrentScheduleTime = () => {
    // If the timeline is on the current day, return the current time
    if (isTimelineDateToday) return dayjs().utc();

    // Otherwise, return the start of day of the plan date
    // This ensures that currentTime will never override other dates (planning in past or future)
    return dayjs(timelineDate).startOf('day').utc();
  };

  /** Get the earliest possible start time of the current move, while respecting its previous move
   * @param {Move} previousMove A regular move object from the DB (not an enrichedMove)
   */
  const getStartTimeLimit = previousMove => {
    // Set all possible start time limits
    const now = getCurrentScheduleTime();
    const previousAccurateEndTime = getAccurateEndTime(previousMove) || now;

    // Find the start time limit based on which time is latest
    const limit = dayjs.max(dayjs(now).tz(timezoneOverride), dayjs(previousAccurateEndTime).tz(timezoneOverride)).utc();
    return limit;
  };

  /** Find the duration between two times (in seconds) */
  const durationBetween = (timeOne = null, timeTwo = null) => {
    if (timeOne && timeTwo) {
      const formattedTimeOne = dayjs(timeOne).tz(timezoneOverride).format();
      const formattedTimeTwo = dayjs(timeTwo).tz(timezoneOverride);
      const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
      return durationSec;
    }
    return 0;
  };

  //TODO: move this to utils
  /** Removes the country from address (this is inconsistent in our database, removing it allows us to compare addresses)
   * @param  {String} address - any address from the locations db
   * @returns {String} - same address without country
   */
  const removeCountryFromAddress = address => {
    const addressArray = address.split(',');
    addressArray.pop();
    return addressArray.join();
  };

  /** Run this function after overrideing a pickuptime to reorder the plannableMovesArray by pickup_time */
  const sortEnrichedPlanByOverridePickupTime = plannableMovesArray => {
    var enrichedMovesWithOverrides = [];
    for (var i = 0; i < plannableMovesArray.length; i++) {
      plannableMovesArray[i].override = withOverrides(plannableMovesArray[i]);
      enrichedMovesWithOverrides.push(plannableMovesArray[i]);
    }
    //sort moves based on their overridden value
    enrichedMovesWithOverrides.sort(function (a, b) {
      return new Date(a.override.pickup_time) - new Date(b.override.pickup_time);
    });
    //remove override field to keep enrichedplan object consistent
    enrichedMovesWithOverrides.forEach(enrichedMove => {
      delete enrichedMove.override;
    });
    return enrichedMovesWithOverrides;
  };

  //////////////////////// TIME FUNCTIONS ////////////////////////

  /** Get the planned pickup time for the move based on pickup_time or fallback to ready_by
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getPlannedStartTime = (move = {}) => {
    return move.pickup_time || move.ready_by || null;
  };

  /** Get the planned delivery time for the move based on lane duration, delivery_time, or fallback to deliver_by
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getPlannedEndTime = move => {
    if (move && move.lane && move.pickup_time) {
      if (move.move_type === `ride`)
        return dayjs(move.pickup_time)
          .utc()
          .add(getPlannedWaitDuration(move.lane), `second`)
          .add(getPlannedDriveDuration(move.lane), `second`)
          .format();
      return dayjs(move.pickup_time).utc().add(Number(move.lane.duration_sec), `second`).format();
    }
    return move.delivery_time || move.deliver_by || null;
  };

  /** Get the actual pickup time for the move based on pickup_arrived
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getActualStartTime = (move = {}) => {
    if (move.move_type === `ride`) return move.pickup_started || move.pickup_arrived || null;
    return move.pickup_arrived || null;
  };

  /** Get the actual delivery time for the move based on delivery_successful
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getActualEndTime = (move = {}) => {
    if (move.move_type === `ride`) return move.delivery_successful || move.delivery_arrived || null;
    return move.delivery_successful || null;
  };

  /** Get the most accurate pickup time for the move based on status and specific times
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getAccurateStartTime = (move = {}) => {
    const actualStartTime = getActualStartTime(move);
    const plannedStartTime = getPlannedStartTime(move);

    // If the move isn't in progress, always return the plannedStartTime
    if (!move.status || move.status === `dispatched` || move.status === `pickup started` || move.status === `accepted`)
      return plannedStartTime || null;

    // Fallthru to the actualStartTime with fallbacks
    return actualStartTime || plannedStartTime || null;
  };

  /** Get the most accurate delivery time for the move based on status and specific times
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   */
  const getAccurateEndTime = move => {
    if (move) {
      const { lane = {} } = move;

      const actualEndTime = getActualEndTime(move);
      const plannedEndTime = getPlannedEndTime(move);
      const plannedPickupDuration = getPlannedPickupDuration(lane);
      const plannedDriveDuration = getPlannedDriveDuration(lane);
      const plannedDeliveryDuration = getPlannedDeliveryDuration(lane);

      // If the move is cancel_status started, return the latest possible timestamp
      if (move.cancel_status === `started`) {
        let cancelTimestamp = getLatestTimestampFromMove(move);
        if (move.move_failed) cancelTimestamp = move.move_failed;
        return cancelTimestamp;
      }

      // If the move isn't in progress, always return the plannedEndTime
      if (
        !move.status ||
        move.status === `dispatched` ||
        move.status === `pickup started` ||
        move.status === `accepted`
      )
        return plannedEndTime || null;

      // If the move's pickup has arrived, estimate the end time with the planned durations
      if (move.move_type === `drive` && move.status === `pickup arrived` && move.pickup_arrived)
        return dayjs(move.pickup_arrived)
          .utc()
          .add(plannedPickupDuration + plannedDriveDuration + plannedDeliveryDuration, `second`)
          .format();
      if (move.move_type === `ride` && move.status === `arrived` && move.pickup_arrived)
        return dayjs(move.pickup_arrived).utc().add(plannedDriveDuration, `second`).format();

      // If the move's pickup was successful (or delivery was started), estimate the end time with the planned durations
      if (
        (move.move_type === `drive` && move.status === `pickup successful` && move.pickup_successful) ||
        (move.move_type === `drive` && move.status === `delivery started` && move.delivery_started)
      ) {
        // We use either timestamp here in case delivery_started is missing (it often is in prod) their values are usually less than a minute apart
        const driveStartTime = move.delivery_started || move.pickup_successful;
        return dayjs(driveStartTime)
          .utc()
          .add(plannedDriveDuration + plannedDeliveryDuration, `second`)
          .format();
      }
      if (
        (move.move_type === `ride` && move.status === `pickedUp` && move.pickup_successful) ||
        (move.move_type === `ride` && move.status === `pickedUp` && move.delivery_started)
      ) {
        // We use either timestamp here in case delivery_started is missing (it often is in prod) their values are usually less than a minute apart
        const driveStartTime = move.delivery_started || move.pickup_successful;
        return dayjs(driveStartTime).utc().add(plannedDriveDuration, `second`).format();
      }

      // If the move's delivery has arrived, estimate the end time with the planned durations
      if (move.status === `delivery arrived` && move.delivery_arrived)
        return dayjs(move.delivery_arrived).utc().add(plannedDeliveryDuration, `second`).format();

      // Fallthru to the actualEndTime with fallbacks
      return actualEndTime || plannedEndTime || null;
    } else {
      return null;
    }
  };

  /** Get the visual starting time on the timeline (based on the previous move's "visual" end-time)
   * This includes when the previous move causes the next move to shift
   */
  const getVisualStartTime = (enrichedMove, prevEnrichedMove) => {
    const { move = {} } = enrichedMove;

    // Check todays date and make sure the move is in-progress
    // Don't do this for rides because sometimes drivers dont update ride statuses
    if (
      isTimelineDateToday &&
      move.move_type !== `ride` &&
      (!move.status || move.status === `dispatched` || move.status === `pickup started`)
    ) {
      const currentTime = getCurrentTime();

      // Check for previous move's visualEndTime
      if (prevEnrichedMove && prevEnrichedMove.visualEndTime) {
        // Use the default accurateStartTime if it's after the current time and the previous visualEndTime
        // Moves that have a sizable gap after the previous move should use this
        if (
          enrichedMove.accurateStartTime >= currentTime &&
          enrichedMove.accurateStartTime >= prevEnrichedMove.visualEndTime
        )
          return enrichedMove.accurateStartTime;

        // If the previous move's visualEndTime is before the current time, use current time
        // This helps with pushing out moves that havent started, but are past their planned start time
        if (prevEnrichedMove.visualEndTime <= currentTime) return currentTime;

        // If the previous move's visualEndTime is past this move's accurateStartTime, use that time
        if (prevEnrichedMove.visualEndTime >= enrichedMove.accurateStartTime) return prevEnrichedMove.visualEndTime;
      }

      // If the two moves dont overlap, check the current position and compare it to the start position
      if (getCurrentPosition() >= enrichedMove.startPx) return currentTime;
    }

    // If there is no previous move, use the default accurateStartTime
    if (!prevEnrichedMove) return enrichedMove.accurateStartTime;

    // If there is no gap, set the visualStartTime to the previous visualEndTime
    if (prevEnrichedMove.visualEndTime >= enrichedMove.accurateStartTime) return prevEnrichedMove.visualEndTime;
    return enrichedMove.accurateStartTime;
  };

  /** Get the visual ending time on the timeline (based on the move's visual start and accurate times)
   * This includes when the previous move causes the next move to shift
   */
  const getVisualEndTime = enrichedMove => {
    const { maxDuration, visualStartTime } = enrichedMove;

    // Calculate the visualDuration and add it to the visualStartTime
    const newVisualEndTime = dayjs(visualStartTime).utc().add(maxDuration, `second`).format();

    // Check for null or invalid
    if (newVisualEndTime && newVisualEndTime !== `Invalid Date`) return newVisualEndTime;
    return null;
  };

  //////////////////////// DURATION FUNCTIONS ////////////////////////

  /** Get a lane's estimated wait duration for rides only (in seconds)
   * @param {Object} lane A regular lane object from a move
   */
  const getPlannedWaitDuration = (lane = {}) => {
    return lane.return_ride_wait_sec || 300;
  };

  /** Get a lane's estimated pickup inspection duration (in seconds)
   * @param {Object} lane A regular lane object from a move
   */
  const getPlannedPickupDuration = (lane = {}) => {
    return lane.pickup_inspection_sec || 600;
  };

  /** Get a lane's estimated delivery inspection duration (in seconds)
   * @param {Object} lane A regular lane object from a move
   */
  const getPlannedDeliveryDuration = (lane = {}) => {
    return lane.delivery_inspection_sec || 480;
  };

  /** Get a lane's estimated drive duration (in seconds)
   * @param {Object} lane A regular lane object from a move
   */
  const getPlannedDriveDuration = (lane = {}) => {
    const plannedPickupDuration = getPlannedPickupDuration(lane);
    const plannedDeliveryDuration = getPlannedDeliveryDuration(lane);
    const fullDuration = lane.duration_sec || 60 * 60; // Default to 60 minutes if no lane duration exists (sec * min)
    return fullDuration - plannedPickupDuration - plannedDeliveryDuration;
  };

  /** Get the delay time (in seconds) during the wait time for a ride
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getWaitDelay = enrichedMove => {
    const { move, extraWaitDuration, visualStartTime, plannedDuration } = enrichedMove;

    // Initialize delay
    let newWaitDelay = extraWaitDuration;

    // Stretch logic for when the move is in the appropriate status
    if (isTimelineDateToday && move.status === `accepted`) {
      // Don't add delay if move is cancel started
      if (move.cancel_status === `started`) return 0;

      // Use the plannedDuration and factor in extra time to get move's current duration
      const moveDuration = plannedDuration;
      // Find the predicted current time of the move using the visualStartTime (add moveDuration)
      const moveCurrentTime = dayjs(visualStartTime).utc().add(moveDuration, `second`).format();
      // If the current time is greater than the current predicted move end time, return that extra delay
      const currentTime = getCurrentTime();
      if (currentTime > moveCurrentTime) newWaitDelay = durationBetween(moveCurrentTime, currentTime);
    }

    // Return the new delay value
    if (newWaitDelay < 0) return 0; // Cap the duration at zero to prevent negative delay
    if (newWaitDelay > 60 * 60 * 1) return 60 * 60 * 1; // Cap the delay duration at 1hr to catch abnormally long inpections (sec * min * hr)
    return newWaitDelay;
  };

  /** Get the delay time (in seconds) during the pickup inspection
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getPickupDelay = enrichedMove => {
    const { move, extraPickupDuration, visualStartTime, plannedDuration } = enrichedMove;

    // Initialize delay
    let newPickupDelay = extraPickupDuration;

    // Stretch logic for when the move is in the appropriate status
    if (isTimelineDateToday && (move.status === `pickup arrived` || move.status === `arrived`)) {
      // Don't add delay if move is cancel started
      if (move.cancel_status === `started`) return 0;

      // Use the plannedDuration and factor in extra time to get move's current duration
      const moveDuration = plannedDuration;
      // Find the predicted current time of the move using the visualStartTime (add moveDuration)
      const moveCurrentTime = dayjs(visualStartTime).utc().add(moveDuration, `second`).format();
      // If the current time is greater than the current predicted move end time, return that extra delay
      const currentTime = getCurrentTime();
      if (currentTime > moveCurrentTime) newPickupDelay = durationBetween(moveCurrentTime, currentTime);
    }

    // Return the new delay value
    if (newPickupDelay < 0) return 0; // Cap the duration at zero to prevent negative delay
    if (newPickupDelay > 60 * 60 * 1) return 60 * 60 * 1; // Cap the delay duration at 1hr to catch abnormally long inpections (sec * min * hr)
    return newPickupDelay;
  };

  /** Get the delay time (in seconds) during the drive time
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getDriveDelay = enrichedMove => {
    const { move, extraDriveDuration, visualStartTime, plannedDuration, extraPickupDuration } = enrichedMove;

    // Initialize delay
    let newDriveDelay = extraDriveDuration;

    // Stretch logic for when the move is in the appropriate status
    if (
      isTimelineDateToday &&
      (move.status === `pickup successful` || move.status === `delivery started` || move.status === `pickedUp`)
    ) {
      // Don't add delay if move is cancel started
      if (move.cancel_status === `started`) return 0;

      // Use the plannedDuration and factor in extra time to get move's current duration
      const moveDuration = plannedDuration + extraPickupDuration;
      // Find the predicted current time of the move using the visualStartTime (add moveDuration)
      const moveCurrentTime = dayjs(visualStartTime).utc().add(moveDuration, `second`).format();
      // If the current time is greater than the current predicted move end time, return that extra delay
      const currentTime = getCurrentTime();
      if (currentTime > moveCurrentTime) newDriveDelay = durationBetween(moveCurrentTime, currentTime);
    }

    // Return the new delay value
    if (newDriveDelay < 0) return 0; // Cap the duration at zero to prevent negative delay
    if (newDriveDelay > 60 * 60 * 2) return 60 * 60 * 2; // Cap the delay duration at 2hrs to catch abnormally long drives (sec * min * hr)
    return newDriveDelay;
  };

  /** Get the delay time (in seconds) during the delivery inspection
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getDeliveryDelay = enrichedMove => {
    const { move, extraDeliveryDuration, visualStartTime, plannedDuration, extraPickupDuration, extraDriveDuration } =
      enrichedMove;

    // Initialize delay
    let newDeliveryDelay = extraDeliveryDuration;

    // Stretch logic for when the move is in the appropriate status
    if (isTimelineDateToday && move.status === `delivery arrived`) {
      // Don't add delay if move is cancel started
      if (move.cancel_status === `started`) return 0;

      // Use the plannedDuration and factor in extra time to get move's current duration
      const moveDuration = plannedDuration + extraPickupDuration + extraDriveDuration;
      // Find the predicted current time of the move using the visualStartTime (add moveDuration)
      const moveCurrentTime = dayjs(visualStartTime).utc().add(moveDuration, `second`).format();
      // If the current time is greater than the current predicted move end time, return that extra delay
      const currentTime = getCurrentTime();
      if (currentTime > moveCurrentTime) newDeliveryDelay = durationBetween(moveCurrentTime, currentTime);
    }

    // Return the new delay value
    if (newDeliveryDelay < 0) return 0; // Cap the duration at zero to prevent negative delay
    if (newDeliveryDelay > 60 * 60 * 1) return 60 * 60 * 1; // Cap the delay duration at 1hr to catch abnormally long inpections (sec * min * hr)
    return newDeliveryDelay;
  };

  /** Get the minimum visual duration of the move (in seconds) - No delays
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getMinDuration = enrichedMove => {
    const {
      move = {},
      plannedWaitDuration,
      plannedPickupDuration,
      plannedDriveDuration,
      plannedDeliveryDuration,
      actualWaitDuration,
      actualPickupDuration,
      actualDriveDuration,
      actualDeliveryDuration,
    } = enrichedMove;

    // Initialize each duration segment
    let newWaitDuration = plannedWaitDuration;
    let newPickupDuration = plannedPickupDuration;
    let newDriveDuration = plannedDriveDuration;
    let newDeliveryDuration = plannedDeliveryDuration;

    // Pave over each duration segment if actual durations exist and are lower than the estimated durations
    // For drive moves
    if (move.move_type === `drive`) {
      if (actualPickupDuration && actualPickupDuration < plannedPickupDuration)
        newPickupDuration = actualPickupDuration;
      if (actualDriveDuration && actualDriveDuration < plannedDriveDuration) newDriveDuration = actualDriveDuration;
      if (actualDeliveryDuration && actualDeliveryDuration < plannedDeliveryDuration)
        newDeliveryDuration = actualDeliveryDuration;
    }

    // For ride moves
    if (move.move_type === `ride`) {
      if (actualWaitDuration && actualWaitDuration < plannedWaitDuration) newWaitDuration = actualWaitDuration;
      if (actualPickupDuration || actualPickupDuration === 0) newPickupDuration = actualPickupDuration;
      if (actualDriveDuration && actualDriveDuration < plannedDriveDuration) newDriveDuration = actualDriveDuration;
      if (actualDeliveryDuration || actualDeliveryDuration === 0) newDeliveryDuration = actualDeliveryDuration;
    }

    // Logic to handle cancel_status started moves
    const status = move.status;
    if (enrichedMove.isCanceled && move.move_type === `drive`) {
      if (status === `pickup started`) {
        newPickupDuration = 0;
        newDriveDuration = 0;
        newDeliveryDuration = 0;
      }
      if (status === `pickup successful` || status === `delivery started`) {
        newDriveDuration = 0;
        newDeliveryDuration = 0;
      }
      if (status === `delivery arrived`) {
        newDeliveryDuration = 0;
      }
    }

    // Add the durations up to get the total
    let totalDuration;
    if (move.move_type === `drive`) totalDuration = newPickupDuration + newDriveDuration + newDeliveryDuration;
    if (move.move_type === `ride`)
      totalDuration = newWaitDuration + newPickupDuration + newDriveDuration + newDeliveryDuration;

    if (totalDuration < 0) return 0; // Cap the duration at zero to prevent missing moves
    if (totalDuration > 60 * 60 * 4) return 60 * 60 * 4; // Cap the duration at 4hrs to catch abnormally long moves (sec * min * hr)
    return totalDuration;
  };

  /** Get the maximum visual duration of the move (in seconds) for the visualEndTime - Minimum duration plus delays
   * ONLY used for visual purposes (it has caps and only reflects the actual value within those caps)
   */
  const getMaxDuration = enrichedMove => {
    const { move = {}, minDuration, waitDelay, pickupDelay, driveDelay, deliveryDelay } = enrichedMove;

    // Add the durations and the delays up to get the total
    let totalDuration;
    if (move.move_type === `drive`) totalDuration = minDuration + pickupDelay + driveDelay + deliveryDelay;
    if (move.move_type === `ride`) totalDuration = minDuration + waitDelay + driveDelay;

    if (totalDuration < 0) return 0; // Cap the duration at zero to prevent missing moves
    if (totalDuration > 60 * 60 * 4) return 60 * 60 * 4; // Cap the duration at 4hrs to catch abnormally long moves (sec * min * hr)
    return totalDuration;
  };

  /** Get the SLA time difference from now */
  const getDeadlineMs = enrichedMove => {
    if (enrichedMove?.move?.deliver_by) {
      const slaTime = dayjs(enrichedMove?.move?.deliver_by);
      const calcTime = dayjs(enrichedMove?.visualEndTime);
      const timeLeft = slaTime.diff(calcTime);
      return timeLeft;
    }
    return null;
  };

  //////////////////////// CALC FUNCTIONS ////////////////////////

  /** Calculate the pickup_time for a move, while respecting the previous move
   * @param {Move} move
   * @param {Move} previousMove
   */
  const calcPickupTime = (move = {}, previousMove) => {
    let actualStartTime = getActualStartTime(move);
    let plannedStartTime = getPlannedStartTime(move);
    plannedStartTime = setMoveDateToTimelineDate(timelineDate, plannedStartTime);
    let readyBy = move.ready_by ? setMoveDateToTimelineDate(timelineDate, move.ready_by) : null;

    // Find the earliest possible start time limit
    const earliestPossibleStartTime = getStartTimeLimit(previousMove);

    // Flag if the plannedStartTime respects the earliest limit
    const scheduledTimeIsAfterLimit = dayjs(plannedStartTime).tz(timezoneOverride).isAfter(earliestPossibleStartTime);

    // Flag if ready_by is before plannedStartTime
    const readyByIsAfterLimit = readyBy
      ? dayjs(readyBy).tz(timezoneOverride).isAfter(earliestPossibleStartTime)
      : false;

    // If actualStartTime exists, use it
    if (actualStartTime) return dayjs(actualStartTime).utc();
    // If valid pinnable, set to scheduled pinnable time
    if (plannedStartTime && scheduledTimeIsAfterLimit && move.pinnable) return dayjs(plannedStartTime).utc();
    // Else if readyBy is after start time limit, use ready by
    if (readyByIsAfterLimit) return dayjs(readyBy).utc();
    // Otherwise, default to the earliest possible start time
    return earliestPossibleStartTime;
  };

  /** Sets the move's pickup_time to the timeline date without changing it's time of day.
   * This is for moves that have been planned for one day, but now need to be moved on a different day.
   * We make the assumption that the move should be picked up at the same time of day, but on the plan date. (time can be changed after via pinning)
   *
   * @param {Object} timelineDate the date of the plan
   * @param {Object} moveTime the move's pickup_time or ready_by
   * @returns {Object} dayjs utc object
   */
  const setMoveDateToTimelineDate = (timelineDate, moveTime) => {
    const planDate = dayjs(timelineDate).format('YYYY-MM-DD');
    const moveDate = dayjs(moveTime).format('YYYY-MM-DD');
    if (planDate !== moveDate) {
      const newPlannedTime = dayjs(moveTime)
        .utc()
        .set('year', timelineDate.year())
        .set('month', timelineDate.month())
        .set('date', timelineDate.date());
      return newPlannedTime;
    }
    return moveTime;
  };

  /** Returns move's most accurate (actual or estimated) duration in seconds.
   * Throws errors if anything goes wrong. It does not default
   * the value so make sure to properly handle errors on call.
   *
   * @param {Object} move A regular move object from the DB (not an enrichedMove)
   * @returns {Number} duration in seconds
   */
  const calcAccurateDuration = move => {
    // Error catch for when the move or lane doesnt exist
    if (!move || typeof move !== 'object') throw new Error('Error Calculating Duration: Invalid Move');
    if (!move.lane || typeof move.lane !== 'object') throw new Error('Error Calculating Duration: Invalid Lane');

    // Get the accurate times and the duration between them
    const accurateStartTime = getAccurateStartTime(move);
    const accurateEndTime = getAccurateEndTime(move);
    const duration = durationBetween(accurateStartTime, accurateEndTime);

    // Error catch for when calculated duration is less than or equal to zero
    // if (duration <= 0) throw new Error(`Error Calculating Duration: Attempted to return zero or a negative number`);
    return duration;
  };

  /** Calculate the number of seconds between two moves
   * @param {Timestamp} moveOneEndTime An ISO timestamp of the first move's end time
   * @param {Timestamp} moveTwoStartTime An ISO timestamp of the second move's start time
   * @returns gap between moves in seconds
   */
  const calcBuffer = (moveOneEndTime = null, moveTwoStartTime = null) => {
    // If the times to calc off of dont exist, return 0 buffer
    if (!moveOneEndTime || !moveTwoStartTime) return 0;

    // Find duration between the first move's end time and the second move's start time
    const buffer = durationBetween(moveOneEndTime, moveTwoStartTime);

    // Hard cap the buffer to zero on anything less than 5 minutes (sec * min)
    if (buffer < 60 * 5) return 0;
    return buffer;
  };

  //////////////////////// DETECTION FUNCTIONS ////////////////////////

  /** Check if the first move's delivery location is the same as the second move's pickup location
   * @param {Object} moveOne move one in sequence
   * @param {Object} moveTwo move two in sequence
   * @param {Boolean} isAfter determines if the ride is before or after the move
   * @returns
   */
  const detectRide = (moveOne, moveTwo, isAfter = false) => {
    if (!moveOne || !moveTwo) return false;

    // If one move is a ride, and move's locations match - ride detected
    const adjacentMoveIsRide = (!isAfter && moveOne.move_type === `ride`) || (isAfter && moveTwo.move_type === `ride`);
    const moveOneOriginId = sdk.utilities.getPropValue(moveOne, 'lane.origin_location_id'); // Needed by canceled moves
    const moveOneDestinationId = sdk.utilities.getPropValue(moveOne, 'lane.destination_location_id');
    const moveTwoOriginId = sdk.utilities.getPropValue(moveTwo, 'lane.origin_location_id');

    // Make sure the location ids are numbers
    if (isNaN(moveTwoOriginId) || isNaN(moveOneDestinationId) || isNaN(moveTwoOriginId)) return false;

    // If the adjacent move is a ride
    if (adjacentMoveIsRide) {
      // If the locations match, ride detected!
      if (moveOneDestinationId === moveTwoOriginId) return true;

      // Special Case: Move was canceled and the driver is still at the pickup
      if (moveOne.cancel_status === `started`) {
        // Both the origin and destination ids must be compared against the ride's pickup
        if (moveOneOriginId === moveTwoOriginId) return true;
        if (moveOneDestinationId === moveTwoOriginId) return true;
      }
    }

    // Otherwise, there is no ride detected
    return false;
  };

  /** Check if a ride gap between 2 moves exists (locations don't match)
   * @param {Object} moveOne move one in sequence
   * @param {Object} moveTwo move two in sequence
   * @returns
   */
  const detectRideGap = (moveOne, moveTwo) => {
    // Only return true if moves exist and are move_type 'drive'
    if (!moveOne || !moveTwo) return false;
    if (moveOne.move_type !== 'drive' || moveTwo.move_type !== 'drive') return false;

    // Canceled rides shouldnt have placeholder rides generated after them
    if (moveOne.move_type === 'drive' && moveOne.cancel_status === `started`) return false;

    // Get each move's respective location data and throw an error if something is missing
    let moveOneDeliveryAddress = sdk.utilities.getPropValue(moveOne, 'lane.delivery.address');
    let moveOneDeliveryId = sdk.utilities.getPropValue(moveOne, 'lane.destination_location_id');
    let moveTwoPickupAddress = sdk.utilities.getPropValue(moveTwo, 'lane.pickup.address');
    let moveTwoPickupId = sdk.utilities.getPropValue(moveTwo, 'lane.origin_location_id');
    if (!moveOneDeliveryAddress || !moveOneDeliveryId || !moveTwoPickupAddress || !moveTwoPickupId) {
      throw new Error('Location missing from move - Could not detect gap');
    }

    // Trim 'USA' or 'United States' from the end of addresses as google returns these inconsistently and it could lead to false negatives
    let moveOneDeliveryAddressFormatted =
      moveOneDeliveryAddress.includes('USA') || moveOneDeliveryAddress.includes('United States')
        ? removeCountryFromAddress(moveOneDeliveryAddress)
        : moveOneDeliveryAddress;
    let moveTwoPickupAddressFormatted =
      moveTwoPickupAddress.includes('USA') || moveTwoPickupAddress.includes('United States')
        ? removeCountryFromAddress(moveTwoPickupAddress)
        : moveTwoPickupAddress;

    // If the locations match, there should be no ride gap
    if (moveOneDeliveryId === moveTwoPickupId || moveOneDeliveryAddressFormatted === moveTwoPickupAddressFormatted)
      return false;

    // Otherwise, there is a ride gap
    return true;
  };

  /** Check if no ride gap or buffer exists between 2 drives (locations match)
   * @param {Object} moveOne move one in sequence
   * @param {Object} moveTwo move two in sequence
   * @returns
   */
  const detectLink = (moveOne, moveTwo) => {
    // Only return true if moves exist and are move_type 'drive'
    if (!moveOne || !moveTwo) return false;
    if (moveOne.move_type !== `drive` || moveTwo.move_type !== `drive`) return false;

    // If the buffer between the two moves is greater than zero, do not show the link
    if (calcBuffer(moveOne.delivery_time, moveTwo.pickup_time) > 0) return false;

    // Otherwise, check the ride gap between the moves
    return !detectRideGap(moveOne, moveTwo);
  };

  //reclass moves
  const reclassMoves = enrichedMoveArray => {
    let driveMoveArray = enrichedMoveArray.filter(em => em.move && em.move.move_type === 'drive');
    for (var i = 0; i < driveMoveArray.length; i++) {
      let moveWithOverrides = driveMoveArray[i].withOverrides;

      //If there is no next move, then the first move in the array is used as the 'next move'
      let nextMove = driveMoveArray[i + 1] ? driveMoveArray[i + 1] : driveMoveArray[0];
      let nextMoveWithOverrides = nextMove
        ? nextMove.withOverrides
        : { lane: { origin_location_id: 0, destination_location_id: 0 } };

      //only reclass move if it does not have rate_class_override set to true
      if (!moveWithOverrides.rate_class_override) {
        if (
          getPropValue(moveWithOverrides, 'lane.destination_location_id') ===
          getPropValue(nextMoveWithOverrides, 'lane.origin_location_id')
        ) {
          applyMoveChanges(driveMoveArray[i], { class: 'base' });
        } else {
          applyMoveChanges(driveMoveArray[i], { class: 'stranded' });
        }
      }
    }
  };

  const resequenceMoves = enrichedMoveArray => {
    let noPlaceholdersArray = enrichedMoveArray.filter(em => !em.isPlaceholder);
    for (var i = 0; i < noPlaceholdersArray.length; i++) {
      applyMoveChanges(noPlaceholdersArray[i], { sequence: i + 1 });
    }
  };

  ///////////////////////////////////////////////////
  //  START ROBS NEW STUFF
  ///////////////////////////////////////////////////

  /**
   * Run all functions to enrich the move and set all flags
   *
   * @param {EnrichedMoves} enrichedMoves
   */
  const refreshEnrichedMoves = enrichedMoves => {
    log(`💪 Refreshing enrichment data for ${enrichedMoves.length} moves`);

    enrichedMoves.forEach((enrichedMove, index) => {
      const { move } = enrichedMove;

      log(`   🚘 Enriching move ${move.id}...`);

      const previousMove = index > 0 ? enrichedMoves[index - 1] : null;
      const nextMove = enrichedMoves[index + 1] ? enrichedMoves[index + 1] : null;

      addDuration(enrichedMove);
      flagAdjacentRides(enrichedMove, previousMove, nextMove);
      addRideReferences(enrichedMove, previousMove, nextMove);
      addLinkReferences(enrichedMoves, enrichedMove);
      addLoopingMoveReferences(enrichedMoves, index);
      retimeMove(enrichedMove, previousMove, nextMove);
      addBufferBetweenMoves(enrichedMove, previousMove);
      addRideGaps(enrichedMove, previousMove, nextMove);
      addLinksBetweenMoves(enrichedMove, previousMove, nextMove);
    });

    // Extra calc time function if the first move in the array is a ride
    retimeFirstRide(enrichedMoves[0], enrichedMoves[1]);

    // Set the sequence of the moves (so that old timeline can display them correctly)
    resequenceMoves(enrichedMoves);
  };

  /**
   * Set the move's duration
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   */
  const addDuration = currentMove => {
    try {
      if (!currentMove) return;

      // Initialize the property to it's default value again before recalc'ing
      currentMove.duration = 0;

      const currentMoveWithOverrides = currentMove.withOverrides;

      // Skip placeholder rides with no lane assigned
      if (!currentMoveWithOverrides.lane) return;

      // If the move has not yet started, use the estimated lane duration
      const moveStatus = currentMoveWithOverrides.status;
      if (
        moveStatus === null ||
        moveStatus === 'dispatched' ||
        moveStatus === 'accepted' ||
        moveStatus === 'pickup started'
      ) {
        if (currentMoveWithOverrides.move_type === `drive`)
          currentMove.duration = currentMoveWithOverrides.lane.duration_sec;
        if (currentMoveWithOverrides.move_type === `ride`)
          currentMove.duration = currentMoveWithOverrides.lane.duration_sec - 600 - 480 + 300;
      } else {
        // Get the most accurate duration of the move
        currentMove.duration = calcAccurateDuration(currentMoveWithOverrides);
      }
    } catch (error) {
      log(`      ${error.message}. Occurred adding duration to move: `, currentMove);
      console.error(error);
      // currentMove.isValid = false
    }
  };
  /**
   * Check and flip the boolean flags for return rides found on either side of the move
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   * @param {EnrichedMove} nextMove The enriched move object that comes after in time
   */
  const flagAdjacentRides = (currentMove, previousMove, nextMove) => {
    if (!currentMove) return;

    // Initialize the property to it's default value again before recalc'ing
    currentMove.hasRideBefore = false;
    currentMove.hasRideAfter = false;

    // Set hasRideBefore
    try {
      if (previousMove) {
        currentMove.hasRideBefore = detectRide(previousMove.withOverrides, currentMove.withOverrides);
        log(`      Ride flagged on left`);
      }
    } catch (error) {
      log(`      ${error.message} occurred flagging the ride found on the left`);
      console.error(error);
    }

    // Set hasRideAfter
    try {
      if (nextMove) {
        currentMove.hasRideAfter = detectRide(currentMove.withOverrides, nextMove.withOverrides, true);
        log(`      Ride flagged on right`);
      }
    } catch (error) {
      log(`      ${error.message} occurred flagging the ride found on the right`);
      console.error(error);
    }
  };

  /**
   * Add quick references to the move to make it easier to get at the related ride moves
   * when we are doing calculations later or when presenting on the screen.
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   * @param {EnrichedMove} nextMove The enriched move object that comes after in time
   */
  const addRideReferences = (currentMove, previousMove, nextMove) => {
    const { move = {} } = currentMove;

    // Initialize the property to it's default value again before recalc'ing
    currentMove.enrichedRideBefore = null;
    currentMove.enrichedRideAfter = null;

    // TODO: We decided to have hasRideBefore and hasRideAfter indicate the same ride move
    // when it is a connector between the right of a prior move and the left of the next.
    // However the enrichedRideBefore and enrichedRideAfter objects should only reflect
    // those ride moves if there is a relationship to the drive move on the move object.

    //Add a reference to the before or after ride objects for ease of use
    if (currentMove && currentMove.hasRideBefore) {
      let hasEnrichedRideBefore = detectEnrichedReturnRideBefore(currentMove, previousMove);
      if (hasEnrichedRideBefore) {
        currentMove.enrichedRideBefore = previousMove;
        log(`      🦄 Added reference to ${previousMove.isPlaceholder ? 'suggested ' : ''}previous ride`);
      }
    }

    if (currentMove && currentMove.hasRideAfter) {
      let hasEnrichedRideAfter = detectEnrichedReturnRideAfter(currentMove, nextMove);
      if (hasEnrichedRideAfter) {
        currentMove.enrichedRideAfter = nextMove;
        log(`      🦄 Added reference to ${nextMove.isPlaceholder ? 'suggested ' : ''}next ride`);
      }
    }
  };

  /**
   * Add quick references to the move to make it easier to get at the linked drive move
   * when we are doing calculations later or when presenting on the screen. Specifically
   * this is used for getting the set of linked moves for concierge + loaner move sets.
   * This only looks in the workingPlan.enrichedMoves array for the matching move in the
   * set. It does not try to find that move in the unassigned moves array. The assumption
   * is that if the move is assigned to the schedule (enrichedMoves array) then it HAD to
   * be assigned as a pair with the other one. If that is violated, then this function
   * would simply return null.
   *
   * @param {Array} enrichedMoves The array of enrichedMoves to search in
   * @param {EnrichedMove} currentEnrichedMove The enriched move object that should be modified
   */
  const addLinkReferences = (enrichedMoves, currentEnrichedMove) => {
    if (!currentEnrichedMove) return;

    let { withOverrides: move = {} } = currentEnrichedMove;
    move = move || {};
    let { parent_move = {}, parent_move_id, childMoves = [{}], return_ride_id = null } = move;
    parent_move = parent_move || {};
    //Use new parent_move_id field but fall back to old return_ride-id parent_move if it is missing
    const parentMoveId = parent_move_id || (parent_move && parent_move.id) || null;
    //Note: there should only ever be one child drive move, so we get the first child move with a drive type
    const childDriveMove = childMoves.find(move => move.move_type === 'drive');
    //TODO: remove return_ride_id reference after transistion to new parentMove system
    const childDriveMoveId = childDriveMove ? childDriveMove.id : return_ride_id ? return_ride_id : null;

    // Initialize the property to it's default value again before recalc'ing
    currentEnrichedMove.linkedEnrichedMove = null;

    if (getDriveTypeFromMove(move) === `concierge`) {
      // If we have a loaner related move, then lets link it
      if (parentMoveId) {
        const enrichedLoanerMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === parentMoveId && moveWithOverrides.move_type === 'drive';
        });
        if (enrichedLoanerMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedLoanerMove;
          log(`      🔗 Is concierge - linked loaner ${parentMoveId} via parent`);
        } else {
          log(`      🔗 Is concierge - couldn't find loaner ${parentMoveId}`);
        }
      } else {
        const enrichedLoanerMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === childDriveMoveId && moveWithOverrides.move_type === 'drive';
        });
        if (enrichedLoanerMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedLoanerMove;
          log(`      🔗 Is concierge - linked loaner ${childDriveMoveId} via return`);
        } else {
          log(`      🔗 Is concierge - must be stranded (no parent move)`, move);
        }
      }
    }

    if (getDriveTypeFromMove(move) === `loaner`) {
      // If we have a concierge related move, then lets link it
      if (parentMoveId) {
        const enrichedConciergeMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === parentMoveId && moveWithOverrides.move_type === 'drive';
        });
        if (enrichedConciergeMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedConciergeMove;
          log(`      🔗 Is loaner - linked concierge ${parentMoveId} via parent`);
        } else {
          log(`      🔗 Is loaner - couldn't find concierge ${parentMoveId} (were they planned together?)`);
        }
      } else {
        const enrichedConciergeMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === childDriveMoveId && moveWithOverrides.move_type === 'drive';
        });
        if (enrichedConciergeMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedConciergeMove;
          log(`      🔗 Is loaner - linked concierge ${childDriveMoveId} via return`);
        } else {
          log(`      🔗 Is loaner - no parent move`);
        }
      }
    }

    // Try to detect a pair of moves entered as a round trip
    if (getDriveTypeFromMove(move) === `ops`) {
      // If we have an operational move that has a parent, then lets link it
      if (parentMoveId) {
        const enrichedOpsMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === parentMoveId && moveWithOverrides.move_type === 'drive';
        });
        if (enrichedOpsMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedOpsMove;
          log(`      🔗 Is ops - linked ops ${parentMoveId} via parent`);
        } else {
          log(`      🔗 Is ops - couldn't find ops ${parentMoveId} (were they planned together?)`);
        }
      } else {
        const enrichedOpsMove = enrichedMoves.find(enrichedMove => {
          const { withOverrides: moveWithOverrides = {} } = enrichedMove;
          return moveWithOverrides.id === childDriveMoveId && moveWithOverrides.move_type === 'drive';
        });
        // Let's check to see if there is a related return ride instead
        if (enrichedOpsMove) {
          currentEnrichedMove.linkedEnrichedMove = enrichedOpsMove;
          log(`      🔗 Is ops - linked ops ${childDriveMoveId} via return`);
        } else {
          log(`      🔗 Is ops - no parent move`);
        }
      }
    }
  };

  /**
   * Add quick references to the move to make it easier to get at the related moves
   * when we are doing calculations later or when presenting on the screen. For the
   * last move in the schedule, the enrichedMove.next property will hold a reference
   * to the first move in the schedule. For the first move, prior will hold a
   * reference to the last.
   *
   * @param {Array} enrichedMovesArray The enriched moves array
   * @param {Number} index The current position in the array
   */
  const addLoopingMoveReferences = (enrichedMovesArray, index) => {
    let currentEnrichedMove = enrichedMovesArray[index];
    if (!currentEnrichedMove) return;

    // Initialize the property to it's default value again before recalc'ing
    currentEnrichedMove.prior = null;
    currentEnrichedMove.next = null;

    const moveCount = enrichedMovesArray.length;
    const priorEnrichedMove = index <= 0 ? enrichedMovesArray[moveCount - 1] : enrichedMovesArray[index - 1];
    const nextEnrichedMove = index + 1 >= moveCount ? enrichedMovesArray[0] : enrichedMovesArray[index + 1];

    const { move: priorMove = {} } = priorEnrichedMove;
    const { move: nextMove = {} } = nextEnrichedMove;

    log(`      🪓 Prior and next move references added (${priorMove.id} | ${nextMove.id})`);

    currentEnrichedMove.prior = priorEnrichedMove;
    currentEnrichedMove.next = nextEnrichedMove;
  };

  /**
   * Recalculate the pickup and delivery times of the move then append those changes to the overrides
   * object on the passed in move object
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   * @param {EnrichedMove} nextMove The enriched move object that comes after in time
   */
  const retimeMove = (currentMove, previousMove, nextMove) => {
    let previousMoveWithOverride = withOverrides(previousMove);
    if (previousMove && previousMove.isStartingRide) previousMoveWithOverride = null;
    const nextMoveWithOverride = withOverrides(nextMove);
    const currentMoveWithOverride = withOverrides(currentMove);

    // Set pickup_time
    let newPickupTime;
    // Only run if the first move isnt a ride
    if (previousMoveWithOverride || (currentMoveWithOverride.move_type === `drive` && !previousMoveWithOverride))
      newPickupTime = calcPickupTime(currentMoveWithOverride, previousMoveWithOverride);

    // Set delivery_time
    let newDeliveryTime;
    if (newPickupTime) newDeliveryTime = newPickupTime.add(currentMove.duration, 'seconds');

    //If the time didn't actually change, then skip updating it - Formatted to utc to match the db format
    if (
      dayjs(newPickupTime).utc().format() === currentMoveWithOverride.pickup_time &&
      dayjs(newDeliveryTime).utc().format() === currentMoveWithOverride.delivery_time
    )
      return;

    if (newPickupTime) {
      applyMoveChanges(currentMove, {
        pickup_time: newPickupTime.format(),
      });
    }

    if (newDeliveryTime) {
      applyMoveChanges(currentMove, {
        delivery_time: newDeliveryTime.format(),
      });
    }

    if (newPickupTime && newDeliveryTime) {
      log(
        `      ⏰ Retimed from ${dayjs(currentMoveWithOverride.pickup_time)
          .tz(timezoneOverride)
          .format('h:mm')}-${dayjs(currentMoveWithOverride.delivery_time)
          .tz(timezoneOverride)
          .format('h:mm a')} to ${newPickupTime.format('h:mm')}-${newDeliveryTime.format(
          'h:mm a'
        )} using a ${Math.round(currentMove.duration / 60, 0)} minute duration`
      );
    }
  };

  /** A time recalculation for the first move in the array (if its a ride)
   * This should only run after the plan is refreshed so that it can use the parent move
   * @param {Object} enrichedRide - The first enriched move in the array
   * @param {Object} enrichedParent - The second enriched move in the array
   */
  const retimeFirstRide = (enrichedRide, enrichedParent) => {
    if (enrichedRide && enrichedParent) {
      const rideWithOverrides = enrichedRide.withOverrides;
      if (rideWithOverrides.move_type === `ride`) {
        // Set isStartingRide to true
        enrichedRide.isStartingRide = true;

        // Get the parent move's pickup time and set it to the end time of the ride
        const parentWithOverrides = enrichedParent.withOverrides;
        const parentMovePickupTime = getPlannedStartTime(parentWithOverrides);

        // Subtract the duration of the move from the parentMovePickupTime to get ride's pickup_time
        const newPickupTime = dayjs(parentMovePickupTime).utc().subtract(enrichedRide.duration, `second`).format();

        // Apply the changes to the enrichedRide
        applyMoveChanges(enrichedRide, { pickup_time: newPickupTime, delivery_time: parentMovePickupTime });
      }
    }
  };

  /**
   * Add the downtime between moves where a driver is not expected to be performing
   * any work for hopdrive. This will be used to display a gap in the schedule as a
   * number of hours and minutes.
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   */
  const addBufferBetweenMoves = (currentMove, previousMove) => {
    if (!currentMove) return;
    if (!previousMove) return;

    // Initialize the property to it's default value again before recalc'ing
    currentMove.buffer = 0;

    const { withOverrides: previousMoveWithOverride = {} } = previousMove;
    const { withOverrides: currentMoveWithOverride = {} } = currentMove;

    // Set time buffer
    try {
      currentMove.buffer = calcBuffer(previousMoveWithOverride.delivery_time, currentMoveWithOverride.pickup_time);
      if (currentMove.buffer > 0) {
        log(`      😴 Downtime of ${Math.round(currentMove.buffer / 60, 0)} minutes detected before this move`);
      }
    } catch (error) {
      log(`      😴 ${error.message} while calculating downtime before this move`);
      console.error(error);
    }
  };

  /**
   * Populate ride gap flags for any that are found on the left or right of the move.
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   * @param {EnrichedMove} nextMove The enriched move object that comes after in time
   */
  const addRideGaps = (currentMove, previousMove, nextMove) => {
    if (!currentMove) return;

    // Initialize the property to it's default value again before recalc'ing
    currentMove.hasRideGapBefore = false;
    currentMove.hasRideGapAfter = false;

    // Set hasRideGapBefore
    try {
      if (previousMove) {
        currentMove.hasRideGapBefore = detectRideGap(previousMove.withOverrides, currentMove.withOverrides);
        if (currentMove.hasRideGapBefore) {
          log(`      🛎️ Detected ride gap on the left`);
        }
      } else {
        currentMove.hasRideGapBefore = false;
      }
    } catch (error) {
      log(`      🛎️ ${error.message} when detecting ride gap on the left`);
      console.error(error);
    }

    // Set hasRideGapAfter
    try {
      if (nextMove) {
        currentMove.hasRideGapAfter = detectRideGap(currentMove.withOverrides, nextMove.withOverrides);
        if (currentMove.hasRideGapAfter) {
          log(`      🛎️ Detected ride gap on the left`);
        }
      } else {
        currentMove.hasRideGapAfter = false;
      }
    } catch (error) {
      log(`      🛎️ ${error.message} when detecting ride gap on the left`);
      console.error(error);
    }
  };

  /**
   * Populate ride gap flags for any that are found on the left or right of the move.
   *
   * @param {EnrichedMove} currentMove The enriched move object that should be modified
   * @param {EnrichedMove} previousMove The enriched move object that comes before in time
   * @param {EnrichedMove} nextMove The enriched move object that comes after in time
   */
  const addLinksBetweenMoves = (currentMove, previousMove, nextMove) => {
    if (!currentMove) return;

    // Initialize the property to it's default value again before recalc'ing
    currentMove.hasLinkBefore = false;
    currentMove.hasLinkAfter = false;

    // Set hasRideGapBefore
    try {
      if (previousMove) {
        currentMove.hasLinkBefore = detectLink(previousMove.withOverrides, currentMove.withOverrides);
        if (currentMove.hasRideGapBefore) {
          log(`      🛎️ Detected move link on the left`);
        }
      }
    } catch (error) {
      log(`      🛎️ ${error.message} when detecting move link on the left`);
      console.error(error);
    }

    // Set hasRideGapAfter
    try {
      if (nextMove) {
        currentMove.hasLinkAfter = detectLink(currentMove.withOverrides, nextMove.withOverrides);
        if (currentMove.hasRideGapAfter) {
          log(`      🛎️ Detected move link on the left`);
        }
      }
    } catch (error) {
      log(`      🛎️ ${error.message} when detecting move link on the left`);
      console.error(error);
    }
  };

  const detectEnrichedReturnRideBefore = (currentMove, previousMove) => {
    if (!previousMove || !currentMove) return false;

    const { move = {} } = currentMove;
    let { withOverrides: previousMoveWithOverrides } = previousMove;
    previousMoveWithOverrides = previousMoveWithOverrides || {};
    var parentMoveId = null;

    //Get parentMoveId from enrichedRide or from nested ride returned from db
    //NOTE: before rides have a special case where a ride will need to be tied to a drive even though
    //  they do not share the parent move/return ride relationship in the db
    if (previousMove.parentMoveId) {
      parentMoveId = previousMove.parentMoveId;
    } else if (previousMove.parent_move_id) {
      //parent_move_id is a new move field that constains the id of the drive move associsated with a ride
      //It is different than parent_move.id, because that relies on the return_ride_id field
      //which will not be filled in for placeholder rides or when their are two rides on one drive move
      parentMoveId = previousMove.parent_move_id;
    } else if (previousMoveWithOverrides.parentMove) {
      parentMoveId = previousMoveWithOverrides.parent_move_id;
    } else if (previousMoveWithOverrides.id && previousMoveWithOverrides.parentMove === null) {
      //This is for edge cases where a drive has a before ride *and* and after ride,
      //  the before ride will not be related in the db
      //  but still needs to be related here for scheduler to display it correctly
      parentMoveId = move.id;
    }
    //Force previous ride to be related if it has no relationship to a drive- this will prevent floating rides with no parent drive
    // else if (
    //   previousMoveWithOverrides &&
    //   previousMoveWithOverrides.move_type === 'ride' &&
    //   !previousMoveWithOverrides.buffer &&
    //   !previousMoveWithOverrides.parentMoveId &&
    //   !previousMoveWithOverrides.parentMove
    // ) {
    //   previousMove.parentMoveId = currentMove.id;
    //   parentMoveId = move.id;
    // }

    //If the parent move matches current move's id, the previous move is its return ride and should be assigned
    if (parentMoveId === move.id) {
      return true;
    } else {
      return false;
    }
  };

  const detectEnrichedReturnRideAfter = (currentMove, nextMove) => {
    if (!nextMove || !currentMove) return false;

    const { move = {} } = currentMove;
    let nextMoveWithOverrides = nextMove.withOverrides;
    var parentMoveId = null;

    //Get parentMoveId from enrichedRide or from nested ride returned from db
    if (nextMove.parentMoveId) {
      parentMoveId = nextMove.parentMoveId;
    } else if (nextMoveWithOverrides.parent_move_id) {
      //parent_move_id is a new move field that constains the id of the drive move associsated with a ride
      //It is different than parent_move.id, because that relies on the return_ride_id field
      //which will not be filled in for placeholder rides or when their are two rides on one drive move
      parentMoveId = nextMoveWithOverrides.parent_move_id;
    } else if (nextMoveWithOverrides.parentMove) {
      parentMoveId = nextMoveWithOverrides.parent_move_id;
    }
    //Force next ride to be related if it has no relationship to a drive- this will prevent floating rides with no parent drive
    // else if (
    //   nextMoveWithOverrides &&
    //   nextMoveWithOverrides.move_type === 'ride' &&
    //   !nextMoveWithOverrides.parentMoveId &&
    //   !nextMoveWithOverrides.parentMove
    // ) {
    //   nextMove.parentMoveId = currentMove.id;
    //   parentMoveId = move.id;
    // }

    //If the parent move matches current move's id, the next move is it's return ride and should be assigned
    if (parentMoveId === move.id) {
      return true;
    } else {
      return false;
    }
  };

  ///////////////////////////////////////////////////
  //  END ROBS NEW STUFF
  ///////////////////////////////////////////////////

  /**
   * Apply changes to the move object in the form of override properties.
   * These do not change the move object itself, since that is immutable,
   * but instead provide alternate values in property called overrides
   * on the parent enrichedMove object.
   *
   * This function will use an object assign to apply these overrides
   * to the overrides object without losing any existing overrides.
   *
   * @param {EnrichedMove} enrichedMove The move to be affected
   * @param {Object} changes The properties to patch into the overrides object
   */
  const applyMoveChanges = (enrichedMove, changes = {}) => {
    if (!enrichedMove) return;
    if (!Object.keys(changes).length > 0) return;
    enrichedMove.overrides = { ...enrichedMove.overrides, ...changes };
  };

  /**
   *
   * @param {EnrichedMove} enrichedMove The move to be affected
   * @param {Array} propNames An array of property names to be deleted
   */
  const revertMoveChanges = (enrichedMove, propNames = []) => {
    if (!enrichedMove) return;
    if (!propNames || !propNames.length > 0) return;
    propNames.forEach(propName => {
      delete enrichedMove.overrides[propName];
    });
  };

  /**
   * Get a virtual move object with any overrides from the enriched move
   * wrapper object applied to each property within the returned move object
   * if they are present.
   *
   * @param {EnrichedMove} enrichedMove
   * @returns {Move}
   */
  const withOverrides = enrichedMove => {
    if (!enrichedMove) return null;
    const { move = {}, overrides = {} } = enrichedMove;
    return { ...move, ...overrides };
  };

  /**
   * Finds the move that is the first move in the current move's 'block' of moves
   * It will either be the first move on the plan, or the move right after a gap in time (buffer)
   *
   * @param {EnrichedMove} currentMove - move we want to find the start of current block in reference to
   * @param {Array} enrichedMovesArray - array of moves to search (usually a plan of enriched moves)
   * @returns {EnrichedMove} - returns first enriched move in block
   */
  const findStartOfBlock = (currentMove, enrichedMovesArray = []) => {
    if (!currentMove || !enrichedMovesArray || enrichedMovesArray.length < 1) return null;
    //If there are no buffers in the plan, we return the first move in the plan
    if (!enrichedMovesArray.some(enrichedMove => enrichedMove.buffer > 0)) {
      return enrichedMovesArray[0];
    } else {
      //If there is a buffer in the plan, we need to work back until we find the buffer closest to the current move
      //When found, return the move with buffer. If no buffer found before current move, return the first move in the array
      if (currentMove && currentMove.move) {
        let currentMoveIndex = enrichedMovesArray.findIndex(em => em.move.id === currentMove.move.id);
        for (var i = currentMoveIndex; i >= 0; i--) {
          if (i === 0) {
            //Loop has gone back to the first move in the plan without finding a buffer; return the first move
            return enrichedMovesArray[0];
          } else if (enrichedMovesArray[i].buffer) {
            //If a buffer is found, that move will be the start of the block
            return enrichedMovesArray[i];
          }
        }
      }
    }
    return null;
  };

  //////////////////////// CSS FUNCTIONS ////////////////////////

  // Get the starting position of the move based on the most accurate pickup time
  const getStartPx = enrichedMove => {
    const { move = {} } = enrichedMove;

    const startOfDay = dayjs(timelineDate).tz(timezoneOverride).add(6, `hours`).startOf(`day`).format();
    const startOfMove = dayjs(enrichedMove.accurateStartTime).tz(timezoneOverride);
    const durationFromStart = startOfMove.diff(startOfDay, `second`);
    const positionFromStart = durationFromStart * timelineScaleWidth;

    // Set the start position to now if its getting pushed out by the marker
    // Don't do this for rides because sometimes drivers dont update ride statuses
    if (
      isTimelineDateToday &&
      move.move_type !== `ride` &&
      (!move.status || move.status === `dispatched` || move.status === `pickup started`)
    ) {
      const currentPosition = getCurrentPosition();
      if (currentPosition >= positionFromStart) return currentPosition;
    }

    // Clamp to zero if duration is negative because moves could hide offscreen
    if (durationFromStart < 0) return 0;
    return positionFromStart;
  };

  // Get the gap (in pixels) between two moves
  const getGapPxBetweenMoves = (enrichedMove, prevEnrichedMove) => {
    const { move = {} } = enrichedMove;

    // If there is no previous move, get duration between start of day and current move
    if (!prevEnrichedMove) return enrichedMove.startPx;

    // Compare the previous move's visualEndTime to the current move's visualStartTime
    const endOfPrevMove = dayjs(prevEnrichedMove.visualEndTime).tz(timezoneOverride).format();
    let startOfCurMove = dayjs(enrichedMove.visualStartTime).tz(timezoneOverride);

    // TODO: This was causing issues with creating a double gap, needs more work
    //   This may have already been fixed by using visualStartTime instead of accurateStartTime
    // Fix a rare case where moves perfectly align, creating a gap of 0, which causes the move not to push out on the current day
    // This happens when the previous move actually ends right when this move is supposed to start (down to the millisecond)
    // if (!move.status || move.status === `dispatched` || move.status === `pickup started`) {
    //   const currentTime = getCurrentTime();
    //   const compareTime = dayjs(prevEnrichedMove.visualEndTime).utc().format();
    //   if (currentTime > compareTime) startOfCurMove = dayjs(currentTime).tz(timezoneOverride);
    // }

    // Find the duration between the comparable times
    let durationBetween = startOfCurMove.diff(endOfPrevMove, `second`);

    // Clamp to zero if duration is negative because moves could overlap
    if (durationBetween < 0) return 0;
    return durationBetween * timelineScaleWidth;
  };

  // Get the width of a delay (in pixels)
  const getDelayWidth = delay => {
    if (delay > 0) return delay * timelineScaleWidth;
    return 0;
  };

  // Get the minimum width of the move (in pixels)
  const getMinWidth = minDuration => {
    if (minDuration < timelineMoveOffset) return 0;
    return minDuration * timelineScaleWidth - timelineMoveOffset;
  };

  // Get the maximum width of the move (in pixels)
  const getMaxWidth = maxDuration => {
    if (maxDuration < timelineMoveOffset) return 0;
    return maxDuration * timelineScaleWidth - timelineMoveOffset;
  };

  // Get zIndex based on move type and status
  const getZIndex = move => {
    if (move.move_type === `drive`) {
      if (move.cancel_status || move.status === `canceled`) return 249;
      if (move.status === `delivery successful`) return 251;
      if (!move.status || move.status === `dispatched`) return 252;
      if (move.status && (move.status.includes(`pickup`) || move.status.includes(`delivery`))) return 253;
    }
    return 250;
  };

  // Get background color based on a number of criteria
  const getBgColor = move => {
    if (move.cancel_status === `pending`) return theme.palette.error.main;
    if (move.status === `canceled` || move.cancel_status === `started`) return theme.palette.error.main;

    if (move.move_type === `drive`) {
      if (move.consumer_type === `customer` || move.consumer_type === `loaner`) {
        return theme.palette.concierge.main;
      }
      return theme.palette.ops.main;
    }

    if (move.move_type === `ride` && move.ride_type === `lyft`) {
      return theme.palette.lyft.main;
    }

    if (move.move_type === `ride` && move.ride_type === `uber`) {
      return theme.palette.uber.main;
    }

    if (move.move_type === `ride` && move.ride_type === `shuttle`) {
      return theme.palette.primary.main;
    }

    return theme.palette.auto.main;
  };

  // Get pulse animation for active moves
  const getAnimation = move => {
    if (move.status === `canceled` || move.cancel_status === `started`) return `none`;

    if (move.move_type === `drive` && move.status) {
      if (move.cancel_status === `pending` || move.driver_status === `declined`) return `$pulse 0.75s infinite`;
      if ((move.status.includes(`pickup`) || move.status.includes(`delivery`)) && move.status !== `delivery successful`)
        return `$pulse 1.25s infinite`;
    }

    if (move.move_type === `ride` && move.status) {
      if (move.status === `accepted` || move.status === `arrived` || move.status === `pickedUp`)
        return `$pulse 1.25s infinite`;
    }

    return `none`;
  };

  // List of check functions to render icons on the move tile
  const getHasOpsTag = move => {
    if (move.move_type === `drive` && !move.consumer_type) return true;
    return false;
  };
  const getHasConciergeTag = move => {
    if (
      move.move_type === `drive` &&
      (move.consumer_type === `customer` || (move.tags && move.tags.includes(`concierge`)))
    )
      return true;
    return false;
  };
  const getHasLoanerTag = move => {
    if (move.move_type === `drive` && (move.consumer_type === `loaner` || (move.tags && move.tags.includes(`loaner`))))
      return true;
    return false;
  };
  const getHasManualTag = move => {
    if (move.manual_flag) return true;
    return false;
  };
  const getHasRailyardTag = move => {
    if (move?.hangtags?.length && move?.hangtags?.[0]?.type === `yard`) return true;
    return false;
  };
  const getHasSlaTag = enrichedMove => {
    const isCurrentDay = dayjs(enrichedMove?.move?.pickup_time).isSame(dayjs(), `day`);
    const deadlineMs = getDeadlineMs(enrichedMove);
    if (
      isCurrentDay &&
      deadlineMs &&
      deadlineMs < 7200000 &&
      !enrichedMove?.isCompleted &&
      !enrichedMove?.isCanceled &&
      !enrichedMove?.isFailed &&
      !enrichedMove?.isRescheduled
    )
      return true; // Check for anything less than 2 hours
    return false;
  };
  const getHasNotesTag = move => {
    if (move.move_details) return true;
    return false;
  };
  const getHasMoreTag = move => {
    if (move.tags) return true;
    return false;
  };

  // Check if the move has tags to render
  const getHasTags = enrichedMove => {
    if (enrichedMove.hasOpsTag) return true;
    if (enrichedMove.hasConciergeTag) return true;
    if (enrichedMove.hasLoanerTag) return true;
    if (enrichedMove.hasManualTag) return true;
    if (enrichedMove.hasRailyardTag) return true;
    if (enrichedMove.hasSlaTag) return true;
    if (enrichedMove.hasNotesTag) return true;
    if (enrichedMove.hasMoreTag) return true;
    return false;
  };

  return {
    buildEnrichedPlan,
    buildEnrichedMove,
    buildEnrichedRide,
    getCurrentTime,
    getCurrentPosition,
    applyMoveChanges,
    revertMoveChanges,
    sortEnrichedPlanByOverridePickupTime,
    withOverrides,
    detectRide,
    detectRideGap,
    detectLink,
    calcBuffer,
    calcAccurateDuration,
    retimeMove,
    addRideReferences,
    refreshEnrichedMoves,
    findStartOfBlock,
    reclassMoves,
    resequenceMoves,
  };
}
