//////////////////////// DEPENDENCIES ////////////////////////

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useSettings } from '../providers/SettingsProvider';
import { usePlans } from '../providers/PlansProvider';
import { useRegions } from '../providers/RegionsProvider';
import { useDrivers } from '../providers/DriversProvider';
import {
  makeStyles,
  Divider,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  Icon,
  IconButton,
} from '@material-ui/core';
import DriverAvatar from './DriverAvatar';
import Spacer from '../../../components/Spacer';
import { useDebounce } from '../../../hooks/useDebounce';
import { toast } from 'react-toastify';
import { useTools } from '../../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function DriversList({
  onClick,
  planInteraction = true,
  highlightSelected = false,
  preselectedDriverId,
}) {
  const cls = useStyles();

  const { getFormattedStatusFromPlan } = useTools();

  const { timelineDate } = useSettings();
  const { plans, refetch: refetchPlansFromServer } = usePlans();
  const { selectedRegion } = useRegions();
  const { drivers, translateDriverStatus } = useDrivers();

  const [currentDriver, setCurrentDriver] = useState(
    preselectedDriverId ? drivers?.find(d => d.id === preselectedDriverId) : null
  );
  const [search, setSearch] = useState(
    preselectedDriverId ? drivers?.find(d => d.id === preselectedDriverId)?.display_name?.toLocaleLowerCase() : ''
  );
  const [filteredDrivers, setFilteredDrivers] = useState(drivers);

  // Couldn't get the scrolIntoView to work, so I used the search instead
  const selectedDriverRef = useRef(null);
  const scrollToSelected = () => selectedDriverRef?.current?.scrollIntoView();

  const [addPlanForDriver] = useMutation(INSERT_PLAN);

  // Handle search criteria
  useEffect(() => {
    let filteredArr = [...drivers];

    if (filteredArr.length > 0) {
      // Check region filter
      if (selectedRegion) filteredArr = filteredArr.filter(d => d.region_id === selectedRegion.id);

      // Check search filter
      if (search && search.length > 0) {
        filteredArr = filteredArr.filter(d => {
          let driverStatus = '';
          if (planInteraction) {
            const plan = plans.find(p => p.driver_id === d.id);
            driverStatus = translateDriverStatus(d, getFormattedStatusFromPlan(plan));
          }
          return (
            (d.id && `${d.id}`.toLocaleLowerCase().includes(search)) ||
            (d.display_name && d.display_name.toLocaleLowerCase().includes(search)) ||
            driverStatus.includes(search)
          );
        });
      }

      // This didn't work well (it scrolled the whole modal div, not just the list)
      //scrollToSelected();
    }

    setFilteredDrivers(filteredArr);
  }, [drivers, selectedRegion, search]);

  // Controls search input
  const handleSearchInput = event => {
    setSearch(event.target.value.toLocaleLowerCase());
  };

  const createPlanForDriver = async () => {
    if (!currentDriver) return;
    const driver = currentDriver;
    const planExists = plans.map(plan => plan.driver_id).includes(driver.id);

    // If the plan doesn't exist, create it
    try {
      if (!planExists) {
        const variables = {
          driverId: driver.id,
          driverName: driver.display_name,
          planDate: timelineDate.format(`YYYY-MM-DD`),
          regionId: driver.region_id,
        };

        const res = await addPlanForDriver({ variables });

        if (res && res.data && res.data.insert_plans && res.data.insert_plans.affected_rows > 0) {
          toast.info(`Created a plan for ${driver.display_name}.`, { autoClose: 2000 });
          refetchPlansFromServer();
        } else {
          toast.error(`Failed to create plan for ${driver.display_name}!`);
          console.error(`Failed to create plan for ${driver.display_name}!`);
        }
      } else {
        toast.warning(`${driver.display_name} already has a plan for today!`, { autoClose: 2000 });
      }
    } catch (err) {
      toast.error(`Failed to create plan for ${driver.display_name}!`);
      console.error(`Failed to create plan for ${driver.display_name}:`, err);
    } finally {
      setCurrentDriver();
    }
  };

  // Handle click for the selected driver
  const handleDriverClick = useDebounce(createPlanForDriver, 500);

  return (
    <>
      <div className={cls.searchbar}>
        <TextField
          fullWidth
          name='search'
          label='Search'
          placeholder='Search Drivers...'
          size='small'
          variant='outlined'
          value={search}
          onChange={handleSearchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='clear input'
                  style={{ display: search.length > 0 ? 'block' : 'none', padding: '4px', marginRight: '-8px' }}
                  onClick={() => {
                    setSearch('');
                    if (onClick) onClick({});
                  }}
                >
                  <Icon fontSize='small'>
                    clear
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      {filteredDrivers &&
        filteredDrivers.length &&
        filteredDrivers.map((driver, index) => {
          let driverStatus = '';
          if (planInteraction) {
            const plan = plans.find(p => p.driver_id === driver.id);
            driverStatus = translateDriverStatus(driver, getFormattedStatusFromPlan(plan));
          }
          return (
            <Fragment key={driver.id}>
              <ListItem
                button
                key={driver.id}
                onClick={() => {
                  setCurrentDriver(driver);
                  if (planInteraction) handleDriverClick();
                  if (onClick) onClick(driver);
                }}
                style={highlightSelected && currentDriver?.id === driver?.id ? { backgroundColor: '#e0e0e0' } : {}}
                ref={currentDriver?.id === driver?.id ? selectedDriverRef : null}
              >
                <DriverAvatar driver={driver} driverStatus={driverStatus} clickable />
                <Spacer column />
                <ListItemText primary={driver.display_name} secondary={driver?.region_name} />
              </ListItem>

              <Divider light />
            </Fragment>
          );
        })}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  searchbar: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const INSERT_PLAN = gql`
  mutation admin_timeline_createPlanForDriver(
    $driverId: bigint!
    $driverName: String!
    $planDate: date!
    $regionId: bigint!
  ) {
    insert_plans(
      objects: { driver_id: $driverId, driver_name: $driverName, plan_date: $planDate, region_id: $regionId }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
