import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Chip } from '@material-ui/core';

import { Table, Column } from '../../components/TableComponents';

import { useTools } from '../../hooks/useTools';

export default function BatchJobTable({ batchJobs }) {
  const cls = useStyles();

  const { capFirst, goToRoute } = useTools();

  const [filteredBatchJobs, setFilteredBatchJobs] = React.useState([]);
  const [search, setSearch] = React.useState('');

  const handleLocalFilters = () => {
    let filteredData = [...batchJobs];

    if (batchJobs?.length) {
      // Check search filter
      if (search?.length) {
        const s = search?.toLowerCase()?.trim();
        filteredData = filteredData?.filter(
          fd =>
            `${fd?.id}`?.toLowerCase()?.includes(s) ||
            `${fd?.batch_id}`?.toLowerCase()?.includes(s) ||
            `${fd?.delay_key}`?.toLowerCase()?.includes(s) ||
            fd?.createdby?.toLowerCase()?.includes(s)
        );
      }
    }

    setFilteredBatchJobs(filteredData);
  };

  React.useEffect(() => {
    handleLocalFilters();
    // eslint-disable-next-line
  }, [batchJobs, search]);

  const getStatusChipClass = status => {
    switch (status) {
      case `pending`:
        return cls.statusPending;
      case `done`:
        return cls.statusDone;
      case `error`:
        return cls.statusError;
      default:
        return undefined;
    }
  };

  return (
    <div className={cls.wrapper}>
      <Table
        data={filteredBatchJobs}
        onRowClick={batchJob => goToRoute(`/batch-jobs/${batchJob?.id}`)}
        defaultOrderBy={'createdat'}
        defaultOrder={'desc'}
        tableAriaLabel={`batch-jobs`}
        stickyHeader={true}
        search={search}
        setSearch={setSearch}
      >
        <Column
          name={`id`}
          label={`Id`}
          tooltip={`Id of the batch job`}
          value={batchJob => batchJob?.id}
          renderer={batchJob => `${batchJob?.id}`}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`batch_id`}
          label={`Batch Id`}
          tooltip={`Batch Id of the batch job`}
          value={batchJob => batchJob?.batch_id}
          renderer={batchJob => `${batchJob?.batch_id}`}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`status`}
          label={`Status`}
          tooltip={`Status of the batch job`}
          value={batchJob => batchJob?.status}
          renderer={batchJob => (
            <Chip className={getStatusChipClass(batchJob?.status)} label={capFirst(batchJob?.status)} size='small' />
          )}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`trigger_type`}
          label={`Trigger Type`}
          tooltip={`Trigger type of the batch job`}
          value={batchJob => batchJob?.trigger_type}
          renderer={batchJob => <Chip className={cls.triggerTypeAll} label={batchJob?.trigger_type} size='small' />}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`delay_key`}
          label={`Delay Key`}
          tooltip={`Delay key of the batch job`}
          value={batchJob => batchJob?.delay_key}
          renderer={batchJob => {
            return `${batchJob?.delay_key}`;
          }}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`createdat`}
          label={`Created At Time`}
          tooltip={`Date and time of when the batch job was created`}
          value={batchJob => batchJob?.createdat}
          renderer={batchJob => {
            return `${dayjs(dayjs(batchJob?.createdat)).format(`MMM D, YYYY - h:mm a`)}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`createdby`}
          label={`Created By`}
          tooltip={`User who created the batch job`}
          value={batchJob => batchJob?.createdby}
          renderer={batchJob => {
            return `${batchJob?.createdby}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`updatedat`}
          label={`Updated At Time`}
          tooltip={`Date and time of when the batch job was last updated`}
          value={batchJob => batchJob?.updatedat}
          renderer={batchJob => {
            return `${dayjs(dayjs(batchJob?.updatedat)).format(`MMM D, YYYY - h:mm a`)}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`updatedby`}
          label={`Updated By`}
          tooltip={`User who last updated the batch job`}
          value={batchJob => batchJob?.updatedby}
          renderer={batchJob => {
            return `${batchJob?.updatedby}`;
          }}
          style={{ minWidth: 200 }}
        />
      </Table>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },

  statusPending: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },

  statusDone: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },

  statusError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  triggerTypeAll: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  amountTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxtNegative: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  durationChanged: {
    marginRight: 8,
    textDecoration: 'line-through',
    fontSize: 14,
    fontWeight: 400,
  },
  durationOverridden: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.error.main,
  },
}));
