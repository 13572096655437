//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, TableContainer, Table, TablePagination } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useEngine } from '../../hooks/useEngine';

import { ZeroDollarTableHead, ZeroDollarTableBody } from './index';

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

//////////////////////// COMPONENT ////////////////////////

function InvoiceTable(props) {
  const cls = useStyles();
  const { clampNegNum } = useTools();
  const { rerunAR } = useEngine();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { filteredZeroDollarMoves, handleARReviewModalOpen, handleARLogsModalOpen } = props;

  // Handle page state
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle pagination rows state
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Set headers array used in the table
  const headers = [
    { id: `MOVE_ID`, align: `left`, label: `Move\xa0ID` },
    { id: `REF_NUM`, align: `left`, label: `Ref\xa0#` },
    { id: `STATUS`, align: `left`, label: `Status` },
    { id: `CANCEL_STATUS`, align: `left`, label: `Cancel\xa0Status` },
    { id: `CUSTOMER`, align: `left`, label: `Customer` },
    { id: `MOVE_DATE`, align: `left`, label: `Move\xa0Date` },
    { id: `DISCOUNT`, align: `right`, label: `Discount` },
    { id: `TOTAL`, align: `right`, label: `Total` },
    { id: `BUTTON`, align: `right`, label: `` },
  ];

  // A cleaner way to build the row object for the table (stronger logic when it comes to error handling)
  const buildRowObject = (move = null) => {
    // Initialize the row object
    let row = {};

    if (move) {
      row.MOVE_ID = move.id || null;
      row.REF_NUM = move.reference_num || null;
      row.STATUS = move.status || null;
      row.CANCEL_STATUS = move.cancel_status || null;
      row.BUTTON = (
        <Button color='info' size='small' onClick={() => rerunAR(move.id)} disabled={move.settled}>
          Recalculate
        </Button>
      );

      if (move.customer) {
        row.CUSTOMER = move.customer.name || null;
      }
    }

    row.move = move;

    if (move.accountsReceivable) {
      row.MOVE_DATE = move.accountsReceivable.billable_datetime
        ? dayjs(move.accountsReceivable.billable_datetime).format(`MM/DD/YYYY`)
        : null;
      row.DISCOUNT =
        move.accountsReceivable.discount_amount > 0
          ? `$${clampNegNum(move.accountsReceivable.discount_amount).toFixed(2)}`
          : move.accountsReceivable.discount_amount === 0
          ? `$0.00`
          : `-`;
      row.TOTAL =
        move.accountsReceivable.due_amount > 0
          ? `$${clampNegNum(move.accountsReceivable.due_amount - move.accountsReceivable.discount_amount).toFixed(2)}`
          : move.accountsReceivable.due_amount === 0
          ? `$0.00`
          : `-`;
    } else {
      row.MOVE_DATE = dayjs(move.pickup_time).format(`MM/DD/YYYY`);
    }

    return row;
  };

  // Set rows array with the armove object attached to each row
  const rows = filteredZeroDollarMoves.map((move, i) => {
    return buildRowObject(move);
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='zero-dollar-table'>
          <ZeroDollarTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <ZeroDollarTableBody
            rows={rows}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleARReviewModalOpen={handleARReviewModalOpen}
            handleARLogsModalOpen={handleARLogsModalOpen}
          />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InvoiceTable;
