// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip } from '@material-ui/core';

import {
  getUserIdFromDriver,
  getEmailFromDriver,
  getPhoneFromDriver,
  getPhoneOSFromDriver,
  getDriverAppVersionFromDriver,
  getStatusFromDriver,
  getPlanStatusFromDriverAndPlan,
  formatDriverIdFromDriver,
  formatUserIdFromDriver,
  formatDisplayNameFromDriver,
  formatEmailFromDriver,
  formatPhoneFromDriver,
  formatPhoneOSFromDriver,
  formatDriverAppVersionFromDriver,
  formatRegionFromDriver,
  formatStatusFromDriver,
  formatPlanStatusFromDriverAndPlan,
  formatActiveFlagFromDriver,
} from '../../utils/DriverUtils';

// -------------------------------- COMPONENT -------------------------------- //

export default function StaticDriverTooltip({ children, driver, plan, debug = false, ...props }) {
  const cls = useStyles();

  return (
    <Tooltip
      title={
        <Grid container spacing={2} wrap='nowrap' className={cls.ttRoot}>
          <Grid item xs className={cls.ttList}>
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Internal Driver ID:</div>
              <div className={cls.ttValBold}>{formatDriverIdFromDriver(driver)}</div>
            </div>

            {getUserIdFromDriver(driver) ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Internal User ID:</div>
                <div className={cls.ttValBold}>{formatUserIdFromDriver(driver)}</div>
              </div>
            ) : null}

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Display Name:</div>
              <div className={cls.ttVal}>{formatDisplayNameFromDriver(driver)}</div>
            </div>

            {getStatusFromDriver(driver) ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Status:</div>
                <div className={cls.ttVal}>{formatStatusFromDriver(driver)}</div>
              </div>
            ) : null}

            {getPlanStatusFromDriverAndPlan(driver, plan) ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Plan Status:</div>
                <div className={cls.ttVal}>{formatPlanStatusFromDriverAndPlan(driver, plan)}</div>
              </div>
            ) : null}

            {getEmailFromDriver(driver) ||
            getPhoneFromDriver(driver) ||
            getPhoneOSFromDriver(driver) ||
            getDriverAppVersionFromDriver(driver) ? (
              <>
                <hr className={cls.ttDivider} />

                {getEmailFromDriver(driver) ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>Email:</div>
                    <div className={cls.ttVal}>{formatEmailFromDriver(driver)}</div>
                  </div>
                ) : null}

                {getPhoneFromDriver(driver) ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>Phone:</div>
                    <div className={cls.ttVal}>{formatPhoneFromDriver(driver)}</div>
                  </div>
                ) : null}

                {getPhoneOSFromDriver(driver) ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>Phone OS:</div>
                    <div className={cls.ttVal}>{formatPhoneOSFromDriver(driver)}</div>
                  </div>
                ) : null}

                {getDriverAppVersionFromDriver(driver) ? (
                  <div className={cls.ttFlex}>
                    <div className={cls.ttKey}>App Version:</div>
                    <div className={cls.ttValBold}>{formatDriverAppVersionFromDriver(driver)}</div>
                  </div>
                ) : null}
              </>
            ) : null}

            <hr className={cls.ttDivider} />

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Region:</div>
              <div className={cls.ttVal}>{formatRegionFromDriver(driver)}</div>
            </div>

            {driver?.tax_class ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Tax Class:</div>
                <div className={cls.ttVal}>{driver?.tax_class || `-`}</div>
              </div>
            ) : null}
          </Grid>

          {debug ? (
            <Grid item xs className={cls.ttList}>
              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Active:</div>
                <div className={cls.ttValFade}>{formatActiveFlagFromDriver(driver)}</div>
              </div>

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Firebase ID:</div>
                <div className={cls.ttValFade}>{driver?.firebase_id || driver?.user?.firebase_id || `-`}</div>
              </div>

              <hr className={cls.ttDivider} />

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Last Updated At:</div>
                <div className={cls.ttValFade}>
                  {driver?.updated_at ? dayjs(driver?.updated_at).format(`M/D/YYYY h:mm A`) : `-`}
                </div>
              </div>

              <div className={cls.ttFlex}>
                <div className={cls.ttKeyFade}>Created At:</div>
                <div className={cls.ttValFade}>
                  {driver?.created_at ? dayjs(driver?.created_at).format(`M/D/YYYY h:mm A`) : `-`}
                </div>
              </div>
            </Grid>
          ) : null}
        </Grid>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  ttRoot: {},

  ttList: {},

  ttFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: 192,
    maxWidth: 384,
    color: theme.palette.text.contrast,
  },

  ttKey: {
    marginRight: 8,
    fontWeight: 300,
  },
  ttKeyFade: {
    marginRight: 4,
    fontWeight: 300,
    color: theme.palette.text.contrastFade,
  },

  ttVal: {
    fontWeight: 500,
  },
  ttValFade: {
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
  },
  ttValBold: {
    fontWeight: 900,
  },

  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
