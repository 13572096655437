import React from 'react';
import { makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import CustomerMultiselect from '../../components/CustomerMultiselect/CustomerMultiselect';

////////// COMPONENT //////////
export default function ARReportsFilter({
  report,
  status,
  selectedCustomerIdArray,
  range,
  start,
  end,
  handleChangeCustomers,
  onStatusChange,
  onRangeChange,
  onDateChange,
  disablePickers,
}) {
  const cls = useStyles();

  const handleStatusChange = event => {
    if (event.target.value !== `all`) onStatusChange(event.target.value);
    else onStatusChange(null);
  };

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = name => event => {
    onDateChange(event, name);
  };

  return (
    <>
      <div className={cls.main}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            {/* ---------- STATUS FILTER ---------- */}
            <Grid item md={3} sm={6} xs={12}>
              {report === 'moves' ? (
                <TextField
                  select
                  fullWidth
                  label='Status Filter'
                  placeholder='Select a status filter...'
                  variant='outlined'
                  margin='dense'
                  value={status || `all`}
                  onChange={handleStatusChange}
                  className={cls.input}
                >
                  <MenuItem value={`all`}>All</MenuItem>
                  <MenuItem value={`paid`}>Paid</MenuItem>
                  <MenuItem value={`unpaid`}>Unpaid</MenuItem>
                </TextField>
              ) : (
                <TextField
                  select
                  fullWidth
                  label='Status Filter'
                  placeholder='Select a status filter...'
                  variant='outlined'
                  margin='dense'
                  value={status || `all`}
                  onChange={handleStatusChange}
                  className={cls.input}
                >
                  <MenuItem value={`all`}>All</MenuItem>
                  <MenuItem value={`successful`}>Successful</MenuItem>
                  <MenuItem value={`processing`}>Processing</MenuItem>
                  <MenuItem value={`failed`}>Failed</MenuItem>
                  <MenuItem value={`paid`}>Paid</MenuItem>
                  <MenuItem value={`unpaid`}>Unpaid</MenuItem>
                </TextField>
              )}
            </Grid>

            {/* ---------- CUSTOMER FILTER ---------- */}
            <Grid item md={3} sm={6} xs={12}>
              <CustomerMultiselect
                label='Customer Filter'
                selectedCustomerIdArray={selectedCustomerIdArray}
                handleChangeCustomers={handleChangeCustomers}
              />
            </Grid>

            {/* ---------- DATA RANGE ---------- */}
            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Date Range'
                placeholder='Select a date range...'
                variant='outlined'
                margin='dense'
                value={range || `day`}
                onChange={handleRangeChange}
                className={cls.input}
              >
                <MenuItem value={`day`}>Today</MenuItem>
                <MenuItem value={`week`}>Past Week</MenuItem>
                <MenuItem value={`month`}>Past Month</MenuItem>
                <MenuItem value={`year`}>Past Year</MenuItem>
                <MenuItem value={`custom`}>Custom Range</MenuItem>
              </TextField>
            </Grid>

            {/* ---------- START DATE ---------- */}
            <Grid item md={2} sm={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                maxDate={end}
                orientation='portrait'
                format='MM/dd/yyyy'
                label='Start Date'
                margin='dense'
                inputVariant='outlined'
                value={start}
                onChange={handleDateChange(`start`)}
                className={cls.date}
              />
            </Grid>

            {/* ---------- END DATE ---------- */}
            <Grid item md={2} sm={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                minDate={start}
                orientation='portrait'
                format='MM/dd/yyyy'
                label='End Date'
                margin='dense'
                inputVariant='outlined'
                value={end}
                onChange={handleDateChange(`end`)}
                className={cls.date}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
}));
