// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomeReviewMoveAdjustments({ outcome }) {
  const cls = useStyles();
  const { getFormattedStatusFromDrive, getFormattedCancelStatusFromDrive } = useTools();

  // FOR DEVELOPERS: Add in any additional checks for move adjustments here when adding them to the list so that they will render
  const hasMoveAdjustments =
    outcome?.cancel_status ||
    outcome?.cancel_reason ||
    outcome?.reschedule_status ||
    outcome?.reschedule_reason ||
    outcome?.status;

  return (
    <>
      <Typography className={cls.outcomeTitleTxt}>Move Adjustments</Typography>
      <Typography className={cls.outcomeSubtitleTxt}>
        These are the move adjustments generated from your input.
      </Typography>

      {hasMoveAdjustments ? (
        <>
          {outcome?.cancel_status && outcome?.cancel_reason ? (
            <>
              <Spacer size='sm' />

              <div className={cls.outcomeRow}>
                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Adjustment Type</Typography>
                  <Typography className={cls.outcomeValTxt}>Cancellation</Typography>
                </div>

                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Cancel Status</Typography>
                  <Typography className={cls.outcomeValTxt}>{getFormattedCancelStatusFromDrive(outcome)}</Typography>
                </div>

                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Cancel Reason</Typography>
                  <Typography className={cls.outcomeValTxt}>{outcome?.cancel_reason}</Typography>
                </div>
              </div>
            </>
          ) : null}
          {outcome?.reschedule_status && outcome?.reschedule_reason ? (
            <>
              <Spacer size='sm' />

              <div className={cls.outcomeRow}>
                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Adjustment Type</Typography>
                  <Typography className={cls.outcomeValTxt}>Reschedule</Typography>
                </div>

                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Reschedule Status</Typography>
                  <Typography className={cls.outcomeValTxt}>Rescheduled</Typography>
                </div>

                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Reschedule Reason</Typography>
                  <Typography className={cls.outcomeValTxt}>{outcome?.reschedule_reason}</Typography>
                </div>
              </div>
            </>
          ) : null}

          {outcome?.status ? (
            <>
              <Spacer size='sm' />

              <div className={cls.outcomeRow}>
                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Adjustment Type</Typography>
                  <Typography className={cls.outcomeValTxt}>Status Update</Typography>
                </div>

                <div className={cls.outcomeCellFlex}>
                  <Typography className={cls.outcomeKeyTxt}>Move Status</Typography>
                  <Typography className={cls.outcomeValTxt}>{getFormattedStatusFromDrive(outcome)}</Typography>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Spacer size='sm' />

          <div className={cls.outcomeRow}>
            <div className={cls.outcomeCellFlex}>
              <Typography className={cls.outcomeNoneTxt}>No move adjustments generated</Typography>
            </div>
          </div>
        </>
      )}
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  outcomeTitleTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  },
  outcomeSubtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  outcomeRow: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 4,
    padding: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  outcomeCell: {
    position: 'relative',
    padding: 4,
  },
  outcomeCellFlex: {
    position: 'relative',
    flex: 1,
    padding: 4,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
  },
  outcomeKeyTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  outcomeValTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

  outcomeNoneTxt: {
    lineHeight: 1.25,
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
}));
