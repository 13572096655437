
import { getAuth } from 'firebase/auth'
import React, { createContext, useContext, useState } from 'react';
import RegionSelectModal from '../components/RegionSelectModal';

const RegionsContext = createContext({});

function RegionsProvider({ children }) {

  const firebase = getAuth()

  const [regionModalOpen, setRegionModalOpen] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState(localStorage.getItem(`selected-region-id`) || 0);
  const [selectedRegionIds, setSelectedRegionIds] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  React.useEffect(() => {
    if (firebase.currentUser) {
      setIsAuthenticated(true)
    }

  }, [firebase.currentUser])

  const handleSelectedRegionId = id => {
    localStorage.setItem(`selected-region-id`, id);
    setSelectedRegionId(id);
  };

  const context = {
    setRegionModalOpen,
    selectedRegionId,
    handleSelectedRegionId,
    selectedRegionIds,
    setSelectedRegionIds
  };

  return (
    <RegionsContext.Provider value={context}>
      {isAuthenticated && <RegionSelectModal open={regionModalOpen} setOpen={setRegionModalOpen} />}
      {children}
    </RegionsContext.Provider>
  );
}

const useRegionsGlobals = () => useContext(RegionsContext);

export { useRegionsGlobals, RegionsProvider };
