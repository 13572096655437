// DEPENDENCIES ---------------------------------------------------------------- //

import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';
import { ExportToCsv } from 'export-to-csv';
import { v4 as uuid } from 'uuid';
import { useApolloClient } from '@apollo/client';

import { getUserEmail, getUserToken } from '../../utils/authHelper';

import { useTools } from '../../hooks/useTools';

import { INSERT_BATCH_JOB, WATCH_BATCH_JOB } from './gql';

const log = true;

// HOOK ---------------------------------------------------------------- //

export default function useDriverPay() {
  const client = useApolloClient();
  const { condensedCase, formatUSD, getRegionNameFromMove } = useTools();

  let username;
  let email = getUserEmail();

  if (email) {
    username = email;
  } else username = 'admin';

  /** Get the count of drivers in a pay cycle */
  const getDriverCountFromPaycycle = paycycle => {
    let driverIds = [];
    paycycle?.appayments?.forEach(app => driverIds.push(app?.driver?.id));
    paycycle?.apcharges?.forEach(apc => driverIds.push(apc?.driver?.id));
    driverIds = [...new Set(driverIds)];
    driverIds = driverIds?.filter(did => did);
    return driverIds?.length;
  };

  /** Get the count of moves in a pay cycle */
  const getMoveCountFromPaycycle = paycycle => {
    let moveIds = [];
    paycycle?.appayments?.forEach(app => moveIds.push(app?.move?.id));
    paycycle?.apcharges?.forEach(apc => moveIds.push(apc?.move?.id));
    moveIds = [...new Set(moveIds)];
    moveIds = moveIds?.filter(mid => mid);
    return moveIds?.length;
  };

  /** Get the count of accessorials in a pay cycle */
  const getAccessorialCountFromPaycycle = paycycle => {
    let accessorialIds = [];
    paycycle?.appayments?.forEach(app => accessorialIds.push(app?.accessorial?.id));
    paycycle?.apcharges?.forEach(apc => accessorialIds.push(apc?.accessorial?.id));
    accessorialIds = [...new Set(accessorialIds)];
    accessorialIds = accessorialIds?.filter(mid => mid);
    return accessorialIds?.length;
  };

  /** Get the total number of miles in a pay cycle */
  const getMileageCountFromPaycycle = paycycle => {
    let moves = [];
    paycycle?.appayments?.forEach(app => moves.push(app?.move));
    paycycle?.apcharges?.forEach(apc => moves.push(apc?.move));
    moves = [...new Set(moves)];
    moves = moves?.filter(m => m);

    let totalMiles = 0;
    moves?.forEach(move => {
      totalMiles += move?.lane?.distance_miles || 0;
    });
    return Math.round(totalMiles);
  };

  /** Get the count of payments in a pay cycle */
  const getPaymentCountFromPaycycle = paycycle => {
    let paymentIds = [];
    paycycle?.appayments?.forEach(app => paymentIds.push(app?.id));
    paymentIds = [...new Set(paymentIds)];
    paymentIds = paymentIds?.filter(pid => pid);
    return paymentIds?.length;
  };

  /** Get the count of charges in a pay cycle */
  const getChargeCountFromPaycycle = paycycle => {
    let chargeIds = [];
    paycycle?.apcharges?.forEach(apc => chargeIds.push(apc?.id));
    chargeIds = [...new Set(chargeIds)];
    chargeIds = chargeIds?.filter(cid => cid);
    return chargeIds?.length;
  };

  /** Get the count of payments & charges in a pay cycle */
  const getPaymentAndChargeCountFromPaycycle = paycycle => {
    let paymentAndChargeIds = [];
    paycycle?.appayments?.forEach(app => paymentAndChargeIds.push(app?.id));
    paycycle?.apcharges?.forEach(apc => paymentAndChargeIds.push(apc?.id));
    paymentAndChargeIds = [...new Set(paymentAndChargeIds)];
    paymentAndChargeIds = paymentAndChargeIds?.filter(pcid => pcid);
    return paymentAndChargeIds?.length;
  };

  /** Get amount paid in a pay cycle */
  const getAmountPaidFromPaycycle = paycycle => {
    let amountPaid = 0;
    paycycle?.appayments?.forEach(app => {
      if (
        app?.status === `paid` ||
        app?.status === `waived` ||
        (app?.driverpayout?.id && app?.driverpayout?.status !== `canceled` && app?.driverpayout?.status !== `failed`)
      )
        amountPaid += app?.amount;
    });
    paycycle?.apcharges?.forEach(apc => {
      if (
        apc?.status === `paid` ||
        apc?.status === `waived` ||
        (apc?.driverpayout?.id && apc?.driverpayout?.status !== `canceled` && apc?.driverpayout?.status !== `failed`)
      )
        amountPaid -= apc?.amount;
    });
    return amountPaid;
  };

  /** Get amount due in a pay cycle */
  const getAmountDueFromPaycycle = paycycle => {
    let amountDue = 0;
    paycycle?.appayments?.forEach(app => {
      amountDue += app?.amount;
    });
    paycycle?.apcharges?.forEach(apc => {
      amountDue -= apc?.amount;
    });
    return amountDue;
  };

  /** Get pay cycle status from a pay cycle */
  const getPaycycleStatusFromPaycycle = paycycle => {
    if (
      (paycycle?.status === `prepared` || paycycle?.status === `closed`) &&
      getAmountPaidFromPaycycle(paycycle) === getAmountDueFromPaycycle(paycycle)
    )
      return `done`;
    return paycycle?.status;
  };

  /** Find the featured pay cycle based on a number of criteria */
  const findFeaturedPaycycle = (paycycles, taxClass) => {
    let filteredPaycycles = [...paycycles];
    filteredPaycycles = filteredPaycycles?.filter(
      paycycle => paycycle?.tax_class === taxClass && (paycycle?.status === `prepared` || paycycle?.status === `closed`)
    );
    filteredPaycycles = filteredPaycycles?.sort((a, b) => {
      if (a?.end_date > b?.end_date) return -1;
      if (a?.end_date < b?.end_date) return 1;
      return 0;
    });
    if (!filteredPaycycles?.length) return null;

    let selectedPaycycle = filteredPaycycles?.[0];

    for (let i; i < filteredPaycycles?.length; i++) {
      if (getAmountPaidFromPaycycle(filteredPaycycles[i]) < getAmountDueFromPaycycle(filteredPaycycles[i])) {
        selectedPaycycle = filteredPaycycles[i];
        break;
      }
    }

    return selectedPaycycle;
  };

  /** Get amount remaining from a driver pay array */
  const getAmountRemainingFromDriverPayArray = driverPayArray => {
    let amountRemaining = 0;
    driverPayArray?.forEach(dp => {
      amountRemaining += dp?.amount_due - dp?.amount_paid;
    });
    return amountRemaining;
  };

  /** Generate a CSV from a list of paycycles */
  const generateCSVFromPaycycles = (paycycles, taxClass) => {
    const createCsvRow = paycycle => ({
      id: paycycle?.id || ``,
      tax_class: paycycle?.tax_class || ``,
      start_date: dayjs.utc(dayjs(paycycle?.start_date)).format(`YYYY-MM-DD`),
      end_date: dayjs.utc(dayjs(paycycle?.end_date)).format(`YYYY-MM-DD`),

      amount_paid: formatUSD(getAmountPaidFromPaycycle(paycycle), { displayAccountingNegative: true }) || `$0.00`,
      amount_due: formatUSD(getAmountDueFromPaycycle(paycycle), { displayAccountingNegative: true }) || `$0.00`,
      status: paycycle?.status || ``,
    });

    const csvRows = paycycles
      ?.filter(pc => pc?.tax_class === taxClass)
      ?.sort((a, b) => {
        if (a?.start_date > b?.start_date) return -1;
        if (a?.start_date < b?.start_date) return 1;
        return 0;
      })
      ?.map(paycycle => createCsvRow(paycycle));

    const csvOptions = {
      filename: `paycycles_${taxClass}_${dayjs().format(`YYYY-MM-DD`)}`,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter?.generateCsv(csvRows);
  };

  /** Generate a CSV from a driver pay array */
  const generateCSVFromDriverPayArray = (paycycle, driverPayArray) => {
    const createCsvRow = driverPay => ({
      driver_id: driverPay?.driver_id || ``,
      driver_name: driverPay?.driver_name || ``,
      tax_class: driverPay?.tax_class || ``,
      start_date: dayjs.utc(dayjs(paycycle?.start_date)).format(`YYYY-MM-DD`),
      end_date: dayjs.utc(dayjs(paycycle?.end_date)).format(`YYYY-MM-DD`),

      amount_paid: formatUSD(driverPay?.amount_paid, { displayAccountingNegative: true }) || `$0.00`,
      amount_due: formatUSD(driverPay?.amount_due, { displayAccountingNegative: true }) || `$0.00`,
      status: driverPay?.status || ``,

      total_duration_sec: driverPay?.total_duration_sec || 0,
      total_miles: driverPay?.total_miles || 0,
      pay_per_move: formatUSD(driverPay?.pay_per_move) || `$0.00`,
      pay_per_hour: formatUSD(driverPay?.pay_per_hour) || `$0.00`,
      pay_per_mile: formatUSD(driverPay?.pay_per_mile) || `$0.00`,

      payment_count: driverPay?.payment_count || 0,
      charge_count: driverPay?.charge_count || 0,
      move_count: driverPay?.move_count || 0,
      drive_move_count: driverPay?.drive_move_count || 0,
      ride_move_count: driverPay?.ride_move_count || 0,
      accessorial_count: driverPay?.accessorial_count || 0,
    });

    const csvRows = driverPayArray
      ?.sort((a, b) => {
        if (a?.driver_name < b?.driver_name) return -1;
        if (a?.driver_name > b?.driver_name) return 1;
        return 0;
      })
      ?.map(driverPay => createCsvRow(driverPay));

    const csvOptions = {
      filename: `paycycle_${condensedCase(paycycle?.id)}_${dayjs
        .utc(dayjs(paycycle?.start_date))
        .format('YYYY-MM-DD')}_to_${dayjs.utc(dayjs(paycycle?.end_date)).format('YYYY-MM-DD')}`,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter?.generateCsv(csvRows);
  };

  /** Generate a CSV from a driver pay object */
  const generateCSVFromDriverPayObject = (paycycle, driverPay) => {
    const createCsvRow = (ap, type) => ({
      driver_id: driverPay?.driver_id || ``,
      driver_name: driverPay?.driver_name || ``,
      type: type || ``,
      assigned_date:
        ap?.earliest_payable_date || ap?.earliest_chargeable_date || ap?.move?.pickup_time
          ? dayjs(ap?.earliest_payable_date || ap?.earliest_chargeable_date || ap?.move?.pickup_time).format(
              'MM/DD/YYYY hh:mm A'
            )
          : ``,
      code: ap?.type || ``,
      notes: ap?.notes || ap?.external_notes || ``,
      status: ap?.status || ``,
      amount:
        formatUSD(type === `payment` ? ap?.amount : ap?.amount * -1, { displayAccountingNegative: true }) || `$0.00`,

      move_id: ap?.move?.id || ``,
      move_type: ap?.move?.move_type || ``,
      move_distance: ap?.move?.lane?.distance_miles || ``,
      move_lane: ap?.move?.lane?.description || ``,
      move_region: ap?.move ? getRegionNameFromMove(ap?.move) : ``,

      accessorial_id: ap?.accessorial?.id || ``,
      accessorial_code: ap?.accessorial?.code || ``,
      accessorial_notes: ap?.accessorial?.notes || ``,
    });

    const chargeRows = driverPay?.apcharges?.map(charge => createCsvRow(charge, `charge`));
    const appaymentRows = driverPay?.appayments?.map(appayment => createCsvRow(appayment, `payment`));
    const csvRows = [...chargeRows, ...appaymentRows];

    const csvOptions = {
      filename: `driverpay_${condensedCase(driverPay?.driver_name)}_${dayjs
        .utc(dayjs(paycycle?.start_date))
        .format('YYYY-MM-DD')}_to_${dayjs.utc(dayjs(paycycle?.end_date)).format('YYYY-MM-DD')}`,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter?.generateCsv(csvRows);
  };

  /** Cleanse the result details */
  const cleanseResultDetails = details => {
    return {
      driverIds: details?.driverIds ? JSON.parse(details?.driverIds) : [],
      transferToken: details?.transferToken || ``,
      failedDriverPayArray: details?.failedDriverPayArray ? JSON.parse(details?.failedDriverPayArray) : [],
      success: details?.success === 'true' ? true : false,
      internalNotes: details?.internalNotes || ``,
      externalNotes: details?.externalNotes || ``,
    };
  };

  /** Handle calling netlify to process the payment (for a single driver) */
  const handleDriverPayoutProcessing = async (start, end, driverId, driverBankToken, username, token) => {
    try {
      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDriverPayoutProcessing`,
        data: {
          start,
          end,
          driverId,
          driverBankToken,
          username,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (res?.status === 200 && res?.data) {
        return res?.data;
      } else {
        console.error(`Failed to initiate payout!`);
        return { driverId, transferToken: undefined, success: false, notes: `No response was found!` };
      }
    } catch (err) {
      console.error(`Failed to initiate payout - Global catch:`, err);
      return { driverId, transferToken: undefined, success: false, notes: err?.message };
    }
  };

  /** Wait for batch job completion */
  const waitForBatchJobCompletion = async (batchId, timeoutMs = 180000) => {
    try {
      const result = await new Promise((resolve, reject) => {
        const subscription = client
          .subscribe({
            query: WATCH_BATCH_JOB,
            variables: {
              batchId,
            },
          })
          .subscribe({
            next: ({ data }) => {
              const job = data?.batch_jobs?.[0];
              if (job?.status === 'done' || job?.status === 'error') {
                subscription.unsubscribe();
                resolve(job);
              }
            },
            error: err => {
              subscription.unsubscribe();
              reject(err);
            },
          });

        setTimeout(() => {
          subscription.unsubscribe();
          reject(new Error('Batch job timed out'));
        }, timeoutMs);
      });

      if (result?.output?.details) return cleanseResultDetails(result?.output?.details);
      return result?.output;
    } catch (err) {
      console.error(`Failed waiting for batch job:`, err);
      return {
        success: false,
        notes: err?.message,
      };
    }
  };

  /** Insert a mass payout batch job */
  const insertMassPayoutBatchJob = async (paycycleId, driverIds, username) => {
    try {
      const batchId = uuid();
      const jobInput = {
        batch_id: batchId,
        input: {
          paycycleId,
          driverIds,
          username,
        },
        sequence: 0,
        trigger_type: 'driverMassPayment',
        createdat: 'now()',
        createdby: username,
        updatedat: 'now()',
        updatedby: username,
        delay_ms: 0,
        delay_key: `${paycycleId}-driver-pay`,
      };

      const { data, errors } = await client.mutate({
        mutation: INSERT_BATCH_JOB,
        variables: {
          job: jobInput,
        },
      });

      if (errors) {
        console.error(`Failed to queue mass payout job:`, errors);
        return {
          driverIds,
          success: false,
          notes: 'Failed to queue mass payout job',
          batchId,
        };
      }

      return {
        driverIds,
        success: true,
        notes: 'Mass payout job queued successfully',
        batchId,
      };
    } catch (err) {
      console.error(`Failed to insert batch job:`, err);
      return {
        driverIds,
        success: false,
        notes: err?.message,
        batchId: null,
      };
    }
  };

  /** Handle building a batch job to process the payment (for multiple drivers) */
  const handleMassPayoutProcessing = async (paycycleId, driverIds, username) => {
    try {
      const batchJobResult = await insertMassPayoutBatchJob(paycycleId, driverIds, username);
      if (!batchJobResult.success) {
        return batchJobResult;
      }

      const result = await waitForBatchJobCompletion(batchJobResult.batchId);
      return result;
    } catch (err) {
      console.error(`Failed to initiate mass payout - Global catch:`, err);
      return {
        driverIds,
        success: false,
        internalNotes: err?.message,
        externalNotes: `Failed to initiate mass payout!`,
      };
    }
  };

  /** Send out to the handleDriverPayoutProcessing netlify function to pay a single driver */
  const initiateOnePayout = async (start, end, driverPay = {}, refetchCallBack) => {
    // Check to make sure driverPay is populated
    if (driverPay) {
      const token = await getUserToken();
      const res = await handleDriverPayoutProcessing(
        start,
        end,
        driverPay?.driver_id,
        driverPay?.driver_bank_account_token,
        username,
        token
      );

      // Check for result
      log && console.log(`Initiate payout result:`, res);
      if (res?.success) {
        toast.info(res?.externalNotes || `Payout initiated. Check the table for status.`);
        if (refetchCallBack) refetchCallBack();
        return res;
      } else {
        console.error(res?.internalNotes || `Failed to process payout!`);
        toast.error(res?.externalNotes || `Failed to process payout!`);
        if (refetchCallBack) refetchCallBack();
        return res;
      }
    } else {
      console.error(`No driver selected to pay!`);
      toast.error(`No driver selected to pay!`);
    }

    // Fallback return null
    return null;
  };

  /** Send out to a batch job to pay multiple drivers */
  const initiateMassPayout = async (paycycleId, driverIds, refetchCallBack) => {
    toast.info(`Please allow up to a minute or two for the payouts to process...`);

    // Check to make sure driverIds are populated
    if (driverIds?.length) {
      const res = await handleMassPayoutProcessing(paycycleId, driverIds, username);

      // Check for result
      log && console.log(`Initiate mass payout result:`, res);
      if (res?.success) {
        toast.info(res?.externalNotes || `Mass payout initiated. Check the table for individual statuses.`);
        if (refetchCallBack) refetchCallBack();
        return res;
      } else {
        console.error(res?.internalNotes || `Failed to process mass payout!`);
        toast.error(res?.externalNotes || `Failed to process mass payout!`);
        if (refetchCallBack) refetchCallBack();
        return res;
      }
    } else {
      console.error(`No drivers selected to pay!`);
      toast.error(`No drivers selected to pay!`);
    }

    // Fallback return null
    return null;
  };

  // Return logic hook
  return {
    getDriverCountFromPaycycle,
    getMoveCountFromPaycycle,
    getAccessorialCountFromPaycycle,
    getMileageCountFromPaycycle,
    getPaymentAndChargeCountFromPaycycle,
    getAmountPaidFromPaycycle,
    getAmountDueFromPaycycle,
    getPaymentCountFromPaycycle,
    getChargeCountFromPaycycle,
    getPaycycleStatusFromPaycycle,
    findFeaturedPaycycle,
    getAmountRemainingFromDriverPayArray,
    generateCSVFromPaycycles,
    generateCSVFromDriverPayArray,
    generateCSVFromDriverPayObject,
    initiateOnePayout,
    initiateMassPayout,
  };
}
