import React from 'react';
import { Paper } from '@material-ui/core';

import useTookanDrivers from './useTookanDrivers';
import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import { DriverScheduleHeader, DriverScheduleBody } from './index';

// const log = false;

export default function DriverScheduleTable({ selectedRegion, schedules, refetch }) {
  // The team_id is Tookan's region_id
  const { team_id } = selectedRegion;
  // Get drivers from tookan by region
  const { loading, error, data } = useTookanDrivers(team_id);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return (
      <Paper>
        <DriverScheduleHeader refetch={refetch} />
        <DefaultErrorFallback message='Could Not Retrieve Driver Data from Tookan' />;
      </Paper>
    );
  } else if (data && data.length > 0) {
    return (
      <Paper>
        <DriverScheduleHeader refetch={refetch} />
        <DriverScheduleBody drivers={data} schedules={schedules} />
      </Paper>
    );
  } else {
    return (
      <Paper>
        <DriverScheduleHeader refetch={refetch} />
        <DefaultEmptyFallback message='No Drivers for This Region' />
      </Paper>
    );
  }
}
