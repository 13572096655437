// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import clsx from 'clsx';
import { useTheme, makeStyles, Chip, Icon } from '@material-ui/core';

import DeadlineTooltip from './DeadlineTooltip';

import { vanillaFunctions } from '../../hooks/useTools';
const { getFormattedCountdown } = vanillaFunctions;

// COMPONENT ---------------------------------------------------------------- //

export default function DeadlineChip({ move, enrichedMove, deadlineMs, calc = `timeline`, hide }) {
  const theme = useTheme();
  const cls = useStyles();

  const getChipColor = () => {
    if (!deadlineMs || deadlineMs > 54000000) return theme.palette.default.lighter; // No deadline or greater than 15 hours

    if (deadlineMs <= 0) return theme.palette.error.dark; // Past Due
    if (deadlineMs <= 3600000) return theme.palette.error.main; // Less than 1 hour
    if (deadlineMs <= 7200000) return theme.palette.warning.main; // Less than 2 hours
    if (deadlineMs <= 18000000) return theme.palette.default.main; // Less than 5 hours
    if (deadlineMs <= 54000000) return theme.palette.default.light; // Less than 15 hours
  };

  const getFlashClassname = () => {
    if (!deadlineMs || deadlineMs > 7200000) return cls.noFlash; // No deadline or greater than 1 hour

    if (deadlineMs <= 0) return cls.flashFast; // Past Due
    if (deadlineMs <= 3600000) return cls.flashMedium; // Less than 1 hour
    if (deadlineMs <= 7200000) return cls.flashSlow; // Less than 2 hours
  };

  if (hide) return null;
  return (
    <DeadlineTooltip move={move} enrichedMove={enrichedMove} deadlineMs={deadlineMs} calc={calc}>
      <Chip
        size='small'
        label={getFormattedCountdown(deadlineMs, { format: 'dhm' }) || `No Deadline`}
        icon={<Icon>{calc === `unassigned` ? `history_toggle_off` : `timelapse`}</Icon>}
        classes={{ root: clsx(cls.chip, getFlashClassname()), icon: cls.icon, label: cls.label }}
        style={{ backgroundColor: getChipColor() }}
      />
    </DeadlineTooltip>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  chip: {
    height: 18,
    padding: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 2,
    marginRight: -4,
    fontSize: 16,
    color: theme.palette.common.white,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.common.white,
  },

  noFlash: {
    animation: 'none',
  },
  flashSlow: {
    animation: '$flash 1.333s infinite',
  },
  flashMedium: {
    animation: '$flash 1s infinite',
  },
  flashFast: {
    animation: '$flash 0.667s infinite',
  },

  '@keyframes flash': {
    '0%': {
      filter: 'brightness(80%)',
    },
    '50%': {
      filter: 'brightness(100%)',
    },
    '100%': {
      filter: 'brightness(80%)',
    },
  },
}));
