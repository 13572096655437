import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';
import { NotificationsTableRow } from './index';

function NotificationsTableBody({ rows, order, orderBy, page, rowsPerPage }) {
  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`notifications-index-row-${i}`}>
              <NotificationsTableRow row={row} index={i} />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}

export default NotificationsTableBody;
