//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

function ProductsToolbar(props) {
  const { goToRoute } = useTools();

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs />

      <Grid item>
        <Button color='primary' size='large' onClick={() => goToRoute(`/products/add`)}>
          Add Product
        </Button>
      </Grid>
    </Grid>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ProductsToolbar;
