// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { useTheme, makeStyles, Typography } from '@material-ui/core';

import { getGapBetweenTimes } from '../../utils/TimelineUtils';

import { useTimeline } from './TimelineProvider';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineAreaMarker({ showLabel, marker }) {
  const theme = useTheme();
  const cls = useStyles();

  const { date, multiplier, timezoneOverride, zoom } = useTimeline();

  return (
    <div
      className={cls.timelineAreaMarker}
      style={{
        left:
          getGapBetweenTimes(
            dayjs(date).tz(timezoneOverride).startOf('day').format(),
            dayjs(marker?.time).tz(timezoneOverride).format(),
            multiplier,
            zoom
          ) || 0,
      }}
    >
      <div className={cls.line} style={{ borderLeftColor: marker?.color || theme.palette.text.primary }} />

      {showLabel ? (
        <div className={cls.label} style={{ backgroundColor: marker?.color || theme.palette.text.primary }}>
          <Typography className={cls.labelTxt}>
            {dayjs(marker?.time).tz(timezoneOverride).format(`h:mm A z`)}
          </Typography>
          <Typography className={cls.labelTxt}>{marker?.label}</Typography>
        </div>
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineAreaMarker: {
    zIndex: 300,
    position: 'absolute',
    display: 'flex',
    top: 0,
    height: '100%',
    userSelect: 'none',
    pointerEvents: 'none',
  },

  line: {
    borderLeft: `1px solid`,
    height: '100%',
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    height: 'fit-content',
    padding: 3,
    borderRadius: '0 0 4px 0',
  },
  labelTxt: {
    whiteSpace: 'nowrap',
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.contrastPrimary,
  },
}));
