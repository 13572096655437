//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import sdk from '@hopdrive/sdk';
import { useTools } from '../../hooks/useTools';
import gql from 'graphql-tag';

//////////////////////// COMPONENT ////////////////////////

function MoveBreakdown(props) {
  const cls = useStyles();
  const { capFirst } = useTools();

  const { move } = props;

  const [insurance, setInsurance] = React.useState(0);
  const [insuranceDefault, setInsuranceDefault] = React.useState(true);
  const [insurancePerMileRate, setInsurancePerMileRate] = React.useState(0);
  const [assumedInsurance, setAssumedInsurance] = React.useState(0);
  const [assumedInsurancePerMileRate, setAssumedInsurancePerMileRate] = React.useState(0);
  const [insuranceComparison, setInsuranceComparison] = React.useState(0)
  const [moveDate, setMoveDate] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [rideMoves, setRideMoves] = React.useState([]);
  const [lyftRideCostItems, setLyftRideCostItems] = React.useState([]);
  const [lyftCostTotal, setLyftCostTotal] = React.useState(0);
  const [accessorials, setAccessorials] = React.useState([]);
  const [rideAccessorials, setRideAccessorials] = React.useState([]);
  const [accessorialCostTotal, setAccessorialCostTotal] = React.useState(0);
  const [arPrice, setArPrice] = React.useState(0);
  const [arItems, setArItems] = React.useState([]);
  const [commission, setCommission] = React.useState(0);
  const [apPay, setApPay] = React.useState(0);
  const [apPayments, setApPayments] = React.useState([]);
  const [profit, setProfit] = React.useState(0);

  //Loaner variables
  const [loanerMove, setLoanerMove] = React.useState(null);
  const [loanerRides, setLoanerRides] = React.useState(null);
  const [loanerApPayments, setLoanerApPayments] = React.useState([]);
  const [loanerApPay, setLoanerApPay] = React.useState(0);
  const [loanerAccessorials, setLoanerAccessorials] = React.useState([]);
  const [loanerState, setLoanerState] = React.useState(null);
  const [loanerInsurance, setLoanerInsurance] = React.useState(0);
  const [loanerInsuranceDefault, setLoanerInsuranceDefault] = React.useState(0);
  const [loanerInsurancePerMileRate, setLoanerInsurancePerMileRate] = React.useState(0);
  const [loanerAssumedInsurance, setLoanerAssumedInsurance] = React.useState(0);
  const [loanerAssumedInsurancePerMileRate, setLoanerAssumedInsurancePerMileRate] = React.useState(0);
  const [loanerInsuranceComparison, setLoanerInsuranceComparison] = React.useState(0)
  const [loanerLyftTotal, setLoanerLyftTotal] = React.useState(0);
  const [loanerAccessorialCostTotal, setLoanerAccessorialCostTotal] = React.useState(0);
  const [loanerCostTotal, setLoanerCostTotal] = React.useState(0);

  const GET_INSURANCE_RATES = gql`
    query get_insurance_rates($state: String!, $date: timestamptz) {
      byState: insurancerates(
        where: { state: { _eq: $state }, expiration_date: { _gte: $date }, effective_date: { _lte: $date } }
      ) {
        carrier_name
        createdat
        createdby
        description
        effective_date
        expiration_date
        id
        name
        rate
        state
      }
      byDefault: insurancerates(where: { state: { _is_null: true } }) {
        carrier_name
        createdat
        createdby
        description
        effective_date
        expiration_date
        id
        name
        rate
        state
      }
    }
  `;

  const GET_ASSUMED_INSURANCE_RATE = gql`
    query insurance_rate_rule_group($customerId: bigint! $date: timestamptz) {
      raterulegroups(where: {customer_id: {_eq: $customerId}, insurance_rate_id: {_is_null: false}, begin_date: {_lte: $date}, end_date: {_gte: $date}}) {
        insurancerate {
          rate
        }
      }
    }
  `;

  const getState = address => {
    let state;
    let addressSplit = address.split(',');
    let stateAndZip = addressSplit[2];
    let stateAndZipSplit = stateAndZip.split(' ');
    state = stateAndZipSplit[1];
    return state;
  };

  //Set total profit when all costs are tallied
  React.useEffect(() => {
    let costTotal = apPay + lyftCostTotal + loanerCostTotal + insurance + commission + accessorialCostTotal;
    setProfit(arPrice - costTotal);
  }, [arPrice, apPay, lyftCostTotal, loanerCostTotal, insurance, commission, accessorialCostTotal]);

  //Get values from present move when component loads
  React.useEffect(() => {
    let payItems = [];
    let rideArray = [];
    let accessorialsArray = [];
    let arDetails = [];

    if (move.pickup_time) setMoveDate(move.pickup_time);

    //Get values for retrieving insurance rates
    if (move.lane && move.lane.pickup && move.lane.pickup.address) {
      let address = move.lane.pickup.address;
      let insuranceState = getState(address);
      setState(insuranceState);
    }

    if (move.accountsReceivable.due_amount) {
      let arprice;
      if (move.accountsReceivable.discount_amount) {
        arprice = Number(move.accountsReceivable.due_amount) - Number(move.accountsReceivable.discount_amount);
      } else arprice = Number(move.accountsReceivable.due_amount);
      setArPrice(arprice);
    }

    //Get move AP
    if (move.appayments && move.appayments.length > 0) {
      payItems.push(...move.appayments);
    }

    //Get move AR
    if (move.accountsReceivable && move.accountsReceivable.details && move.accountsReceivable.details.length > 0) {
      arDetails.push(...move.accountsReceivable.details);
    }
    if (arDetails.length > 0) {
      arDetails = arDetails.filter(detail => detail.amount > 0);
    }
    setArItems(arDetails);

    //Process child moves
    if (move.childMoves && move.childMoves.length > 0) {
      move.childMoves.forEach(cm => {
        //If the child move is a ride, get its active lyft ride attempt to tally actual costs incurred from the ride
        if (cm.move_type === 'ride' && cm.active) {
          rideArray.push(cm);
        }
        //If the child move is the loaner in a concierge+loaner move, its associated costs are covered by the
        //parent concierge move, so we need them here to calculate the profitability of this parent move
        if (move.consumer_type === 'customer' && cm.consumer_type === 'loaner') {
          setLoanerMove(cm);
        }
      });
    }

    if (move.consumer_type === 'customer' && move.parentMove && move.parentMove.consumer_type === 'loaner') {
      setLoanerMove(move.parentMove);
    }

    if (move.accessorials && move.accessorials.length > 0) {
      accessorialsArray = move.accessorials;
    }

    if (rideArray && rideArray.length > 0) {
      rideArray.forEach(r => {
        if (r.accessorials && r.accessorials.length > 0) {
          accessorialsArray.push(r.accessorials);
        }
        if (r.appayments && r.appayments.length > 0) {
          payItems.push(...r.appayments);
        }
      });
    }
    setRideMoves(rideArray);


    //Start tallying
    let filteredPayItems = payItems.filter(item => item.amount > 0);
    let appay = filteredPayItems.map(ap => ap.amount).reduce((total, current) => total + current, 0);
    setApPay(appay);
    setApPayments(filteredPayItems);

    //Exclude surge and ride accessorials from the main accessorials array, because those costs will be handled separately
    //Also exclude AP amounts from total accessorial costs, because that cost is already captured in driver pay
    let filteredAccessorialsArray = accessorialsArray.filter(
      acc => acc.code !== 'surge' && acc.code !== 'ride' && acc.cost > 0 && acc.cost - acc.ap_amount !== 0 && acc.status === 'approved'
    );
    if (filteredAccessorialsArray && filteredAccessorialsArray.length > 0) {
      let costTotal = accessorialsArray.map(acc => acc.cost).reduce((total, current) => total + current, 0);
      setAccessorialCostTotal(costTotal);
    }
    setAccessorials([...filteredAccessorialsArray]);

    //Now grab ride accessorials
    let rideAccessorialsArray = accessorialsArray.filter(acc => acc.code === 'ride');
    setRideAccessorials([...rideAccessorialsArray]);
  }, [move]);

  //Set commission based on AR price
  React.useEffect(() => {
    if (arPrice && arPrice > 0) {
      setCommission(0.021 * arPrice);
    }
  }, [arPrice]);

  //Get insurance rate by state
  React.useEffect(() => {
    const getInsuranceRate = async () => {
      let insuranceRate = 0;
      let assumedInsuranceRate = 0;
      let insuranceTotal = 0
      let assumedInsuranceTotal = 0

      try {
        let insuranceRes = await sdk.gql.queryWithApollo(
          GET_INSURANCE_RATES,
          { state: state, date: moveDate },
          sdk.config.apollo_client
        );
        let assumedInsuranceRes = await sdk.gql.queryWithApollo(
          GET_ASSUMED_INSURANCE_RATE,
          { customerId: move.customer.id, date: moveDate },
          sdk.config.apollo_client
        );

        if (
          assumedInsuranceRes &&
          assumedInsuranceRes.data &&
          assumedInsuranceRes.data.raterulegroups &&
          assumedInsuranceRes.data.raterulegroups.length > 0 &&
          assumedInsuranceRes.data.raterulegroups[0].insurancerate &&
          assumedInsuranceRes.data.raterulegroups[0].insurancerate.rate
        ) {
          assumedInsuranceRate = assumedInsuranceRes.data.raterulegroups[0].insurancerate.rate;
          setAssumedInsurancePerMileRate(assumedInsuranceRate);

          assumedInsuranceTotal =
            move && move.lane && move.lane.distance_miles ? move.lane.distance_miles * assumedInsuranceRate : 0;
          setAssumedInsurance(assumedInsuranceTotal);
        }

        if (insuranceRes && insuranceRes.data) {
          if (insuranceRes.data.byState && insuranceRes.data.byState.length > 0) {
            insuranceRate = insuranceRes.data.byState[0].rate;
            setInsurancePerMileRate(insuranceRate);
            setInsuranceDefault(false);
          } else if (insuranceRes.data.byDefault && insuranceRes.data.byDefault.length > 0) {
            insuranceRate = insuranceRes.data.byDefault[0].rate;
            setInsurancePerMileRate(insuranceRate);
            setInsuranceDefault(true);
          }

          insuranceTotal =
            move && move.lane && move.lane.distance_miles ? move.lane.distance_miles * insuranceRate : 0;
          setInsurance(insuranceTotal);
        }

        if (assumedInsuranceTotal > 0){
          let insuranceDelta = insuranceTotal - assumedInsuranceTotal
          setInsuranceComparison(insuranceDelta)
        }

      } catch (err) {
        console.log('Error getting insurance rates,', err);
      }
    };



    if (state) {
      getInsuranceRate();
    }
  }, [state]);

  //Get Lyft ride costs
  React.useEffect(() => {
    const getLyftRideAttempts = async rideMoveIds => {
      try {
        let lyftRidesRes = await sdk.gql.query(
          `query getActiveLyftRideAttemptByMoveId($rideMoveIds: [bigint!]) {
            lyftrides(where: {ride_move_id: {_in: $rideMoveIds}}) {
              id
              ride_move_id
              activeAttempt{
                id
                ride_cost
                estimated_ride_cost
              }
            }
          }
        `,
          { rideMoveIds: rideMoveIds }
        );
        let returnRideCostItems = []

        if (rideAccessorials?.length > 0) {
          rideAccessorials.forEach(ra => {
            const name = ra.code.charAt(0).toUpperCase() + ra.code.slice(1) + ' cost'
            returnRideCostItems.push({name: name, cost: ra.cost})
          })
        } else if (lyftRidesRes && lyftRidesRes.data && lyftRidesRes.data.length > 0) {
          let lyfts = lyftRidesRes.data;
          let lyftAttempts = [];
          lyfts.forEach(lr => {
            if (lr.activeAttempt) {
              lyftAttempts.push(lr.activeAttempt);
            }
          });


          if (lyftAttempts.length > 0){
            lyftAttempts.forEach(la => {
              if (la.ride_cost && la.ride_cost > 0){
                returnRideCostItems.push({name: 'Actual cost from vendor', cost: la.ride_cost})
              } else if ((!la.ride_cost || la.ride_cost < 0) && la.estimated_ride_cost && la.estimated_ride_cost > 0){
                returnRideCostItems.push({name: 'Estimated cost from vendor', cost: la.estimated_ride_cost})
              }
            })
          }

        }
        setLyftRideCostItems(returnRideCostItems);
        let lyftPrice = returnRideCostItems.map(item => item.cost).reduce((total, current) => total + current, 0);
        setLyftCostTotal(lyftPrice);
      } catch (err) {
        console.log('Error getting lyftrideattempts', err);
      }
    };

    if (rideMoves && rideMoves.length > 0) {
      let rideMoveIds = rideMoves.map(rm => rm.id);
      getLyftRideAttempts(rideMoveIds);
    }
  }, [rideMoves, rideAccessorials]);

  //Process loaner move costs in concierge+loaner pairs
  React.useEffect(() => {
    if (loanerMove) {
      let rides = [];
      let accessorials = [];

      if (loanerMove.appayments && loanerMove.appayments.length > 0) {
        let filteredPayItems = loanerMove.appayments.filter(item => item.amount > 0);
        let appay = filteredPayItems.map(ap => ap.amount).reduce((total, current) => total + current, 0);
        setLoanerApPay(appay);
        setLoanerApPayments(filteredPayItems);
      }

      if (loanerMove.childMoves && loanerMove.childMoves.length > 0) {
        loanerMove.childMoves.forEach(cm => {
          if (cm.move_type === 'ride') {
            rides.push(cm);
          }
        });
        setLoanerRides(rides);
      }

      if (loanerMove.accessorials && loanerMove.accessorials.length > 0) {
        accessorials.push(...loanerMove.accessorials);
        //Exclude surge and ride accessorials, because those costs are already reflected in the lyftRideAttempts total
        accessorials = accessorials.filter(
          acc => acc.code !== 'surge' && acc.code !== 'ride' && acc.cost > 0 && acc.cost - acc.ap_amount !== 0 && acc.status === 'approved'
        );
        if (accessorials && accessorials.length > 0) {
          setLoanerAccessorials([...accessorials]);
          let costTotal = accessorials.map(acc => acc.cost).reduce((total, current) => total + current, 0);
          setLoanerAccessorialCostTotal(costTotal);
        }
      }

      if (loanerMove.lane && loanerMove.lane.pickup && loanerMove.lane.pickup.address) {
        let address = loanerMove.lane.pickup.address;
        let insuranceState = getState(address);
        setLoanerState(insuranceState);
      }
    }
  }, [loanerMove]);

  //Get costs of rides attached to loaner move, if any
  React.useEffect(() => {
    if (loanerRides && loanerRides.length > 0) {
      const getLyftRideAttempts = async rideMoveIds => {
        try {
          let lyftRidesRes = await sdk.gql.query(
            `query getActiveLyftRideAttemptByMoveId($rideMoveIds: [bigint!]) {
              lyftrides(where: {ride_move_id: {_in: $rideMoveIds}}) {
                id
                activeAttempt{
                  id
                  ride_cost
                }
              }
            }
          `,
            { rideMoveIds: rideMoveIds }
          );

          if (lyftRidesRes && lyftRidesRes.data && lyftRidesRes.data.length > 0) {
            let lyfts = lyftRidesRes.data;
            let lyftAttempts = [];
            lyfts.forEach(lr => {
              if (lr.activeAttempt) {
                lyftAttempts.push(lr.activeAttempt);
              }
            });

            let lyftPrice = lyftAttempts.map(lra => lra.ride_cost).reduce((total, current) => total + current, 0);
            setLoanerLyftTotal(lyftPrice);
          }
        } catch (err) {
          console.log('Error getting loaner lyftrideattempts', err);
        }
      };

      if (loanerRides && loanerRides.length > 0) {
        let rideMoveIds = loanerRides.map(rm => rm.id);
        getLyftRideAttempts(rideMoveIds);
      }
    }
  }, [loanerRides]);

  //Get loaner insurance rates by state (if pickup and dropoff cross state lines, different insurance
  //rates will apply for the concierge and the loaner)
  React.useEffect(() => {
    const getInsuranceRate = async () => {
      let insuranceRate = 0;
      let assumedInsuranceRate = 0;
      let insuranceTotal = 0;
      let assumedInsuranceTotal = 0

      try {
        let insuranceRes = await sdk.gql.queryWithApollo(
          GET_INSURANCE_RATES,
          { state: loanerState, date: moveDate },
          sdk.config.apollo_client
        );
        let assumedInsuranceRes = await sdk.gql.queryWithApollo(
          GET_ASSUMED_INSURANCE_RATE,
          { customerId: loanerMove.customer.id, date: moveDate },
          sdk.config.apollo_client
        );

        if (
          assumedInsuranceRes &&
          assumedInsuranceRes.data &&
          assumedInsuranceRes.data.raterulegroups &&
          assumedInsuranceRes.data.raterulegroups.length > 0 &&
          assumedInsuranceRes.data.raterulegroups[0].insurancerate &&
          assumedInsuranceRes.data.raterulegroups[0].insurancerate.rate
        ) {
          assumedInsuranceRate = assumedInsuranceRes.data.raterulegroups[0].insurancerate.rate;
          setLoanerAssumedInsurancePerMileRate(assumedInsuranceRate);

          assumedInsuranceTotal =
            loanerMove && loanerMove.lane && loanerMove.lane.distance_miles
              ? loanerMove.lane.distance_miles * assumedInsuranceRate
              : 0;
          setLoanerAssumedInsurance(assumedInsuranceTotal);
        }

        if (insuranceRes && insuranceRes.data) {
          if (insuranceRes.data.byState && insuranceRes.data.byState.length > 0) {
            insuranceRate = insuranceRes.data.byState[0].rate;
            setLoanerInsurancePerMileRate(insuranceRate);
            setLoanerInsuranceDefault(false);
          } else if (insuranceRes.data.byDefault && insuranceRes.data.byDefault.length > 0) {
            insuranceRate = insuranceRes.data.byDefault[0].rate;
            setLoanerInsurancePerMileRate(insuranceRate);
            setLoanerInsuranceDefault(true);
          }

          insuranceTotal =
            loanerMove && loanerMove.lane && loanerMove.lane.distance_miles
              ? loanerMove.lane.distance_miles * insuranceRate
              : 0;
          setLoanerInsurance(insuranceTotal);
        }

        if (assumedInsuranceTotal > 0){
          let insuranceDelta = insuranceTotal - assumedInsuranceTotal
          setLoanerInsuranceComparison(insuranceDelta)
        }
      } catch (err) {
        console.log('Error getting insurance rates,', err);
      }
    };

    if (loanerState) {
      getInsuranceRate();
    }
  }, [loanerState]);

  //Add up all loaner costs
  React.useEffect(() => {
    setLoanerCostTotal(loanerInsurance + loanerApPay + loanerLyftTotal, loanerAccessorialCostTotal);
  }, [loanerApPay, loanerInsurance, loanerLyftTotal, loanerAccessorialCostTotal]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={cls.paper}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <>
                  <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.detailNameTxt}>Customer&nbsp;Price&nbsp;&nbsp;</Typography>
                        <Typography className={cls.detailValTxt}>${arPrice.toFixed(2)}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                  {arItems.length > 0 &&
                    arItems.map((item, i) => (
                      <Grid key={`ar-item-${item.id}`} container spacing={1}>
                        <Grid item sm={12} xs={12}>
                          <div className={cls.listItem} key={`ar-detail-${i}`}>
                            <Typography
                              className={cls.detailNotesTxt}
                              style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                            >
                              {!item.name.includes('Transport') &&
                              !item.name.includes('promo') &&
                              !item.name.includes('Promo')
                                ? `Accessorial - ${item.name}`
                                : `${capFirst(item.name)}`}
                            </Typography>
                            <Typography className={cls.lightValTxt}>${item.amount.toFixed(2)}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  <div className={cls.lineBreak} />
                </>

                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <div className={cls.listItem}>
                      <Typography className={cls.detailNameTxt}>Driver&nbsp;Pay&nbsp;&nbsp;</Typography>
                      <Typography className={cls.detailValTxt}>-${apPay.toFixed(2)}</Typography>
                    </div>
                  </Grid>
                </Grid>

                {apPayments.length > 0 &&
                  apPayments.map((item, i) => (
                    <Grid key={`ap-item-${item.id}`} container spacing={1}>
                      <Grid item sm={12} xs={12}>
                        <div className={cls.listItem} key={`ap-payment-${i}`}>
                          <Typography
                            className={cls.detailNotesTxt}
                            style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                          >
                            {item.accessorial
                              ? `Accessorial - ${item.accessorial.code}`
                              : `${capFirst(item.move.move_type)} ${item.type}`}
                          </Typography>
                          <Typography className={cls.lightValTxt}>${item.amount.toFixed(2)}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  ))}

                <div className={cls.lineBreak} />

                {lyftCostTotal > 0 ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item sm={12} xs={12}>
                        <div className={cls.listItem}>
                          <Typography className={cls.detailNameTxt}>Return&nbsp;Ride(s)&nbsp;&nbsp;</Typography>
                          <Typography className={cls.detailValTxt}>-${lyftCostTotal.toFixed(2)}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {lyftRideCostItems.length > 0 &&
                      lyftRideCostItems.map((item, i) => (
                        <Grid key={`ride-item-${item.id}`} container spacing={1}>
                          <Grid item sm={12} xs={12}>
                            <div className={cls.listItem} key={`lyft-${i}`}>
                              <Typography
                                className={cls.detailNotesTxt}
                                style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                              >
                                {item.name}
                              </Typography>
                              <Typography className={cls.lightValTxt}>${item.cost.toFixed(2)}</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    <div className={cls.lineBreak} />
                  </>
                ) : null}

                {accessorials && accessorials.length > 0 ? (
                  <>
                    <div className={cls.listItem}>
                      <Typography className={cls.detailNameTxt}>Accessorial&nbsp;Costs&nbsp;&nbsp;</Typography>
                      <Typography className={cls.detailValTxt}>-${accessorialCostTotal.toFixed(2)}</Typography>
                    </div>
                    {accessorials.map((item, i) => (
                      <Grid key={`acc-item-${item.id}`} container spacing={1}>
                        <Grid item sm={12} xs={12}>
                          <div className={cls.listItem} key={`accessorial-${i}`}>
                            <Typography
                              className={cls.detailNotesTxt}
                              style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                            >
                              {capFirst(item.code)}
                            </Typography>
                            <Typography className={cls.lightValTxt}>${item.cost.toFixed(2)}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                    <div className={cls.lineBreak} />
                  </>
                ) : null}

                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <div className={cls.listItem}>
                      <Typography className={cls.detailNameTxt}>Insurance&nbsp;&nbsp;</Typography>
                      <Typography className={cls.detailValTxt}>
                        {insurance > 0 ? `-$${insurance.toFixed(2)}` : 'N/A'}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <div className={cls.listItem}>
                      <Typography className={cls.detailNotesTxt} style={{ marginLeft: '15px' }}>
                        {state && !insuranceDefault
                          ? `Actual insurance rate for ${state}: $${insurancePerMileRate.toFixed(2)}/mi`
                          : `Legacy insurance rate: $${insurancePerMileRate.toFixed(2)}/mi`}
                      </Typography>
                      <Typography className={cls.lightValTxt}>
                      {insuranceComparison > 0 ? `($${Math.abs(insuranceComparison.toFixed(2))} higher)` : insuranceComparison < 0 ? `($${Math.abs(insuranceComparison.toFixed(2))} lower)` : null} ${insurance > 0 ? insurance.toFixed(2) : 'N/A'}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                {assumedInsurancePerMileRate > 0 ? (
                  <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.detailNotesTxt} style={{ marginLeft: '15px' }}>
                          {`Assumed insurance rate: $${assumedInsurancePerMileRate.toFixed(2)}/mi`}
                        </Typography>
                        <Typography className={cls.lightValTxt} style={{ textDecoration: 'line-through' }}>
                          ${assumedInsurance > 0 ? assumedInsurance.toFixed(2) : 'N/A'}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}

                <div className={cls.lineBreak} />

                {loanerCostTotal > 0 ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item sm={12} xs={12}>
                        <div className={cls.listItem}>
                          <Typography className={cls.detailNameTxt}>Loaner&nbsp;Costs&nbsp;&nbsp;</Typography>
                          <Typography className={cls.detailValTxt}>-${loanerCostTotal.toFixed(2)}</Typography>
                        </div>
                      </Grid>
                      {loanerApPayments.length > 0 &&
                        loanerApPayments.map((item, i) => (
                          <Grid key={`lap-item-${item.id}`} container spacing={1}>
                            <Grid item sm={12} xs={12}>
                              <div className={cls.listItem} key={`loaner-ap-${i}`}>
                                <Typography
                                  className={cls.detailNotesTxt}
                                  style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                                >
                                  {capFirst(item.move.move_type)} {item.type}
                                </Typography>
                                <Typography className={cls.lightValTxt}>${item.amount.toFixed(2)}</Typography>
                              </div>
                            </Grid>
                          </Grid>
                        ))}
                      {loanerAccessorials.length > 0 &&
                        loanerAccessorials.map((item, i) => (
                          <Grid key={`lacc-item-${item.id}`} container spacing={1}>
                            <Grid item sm={12} xs={12}>
                              <div className={cls.listItem} key={`loaner-accessorial-${i}`}>
                                <Typography
                                  className={cls.detailNotesTxt}
                                  style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                                >
                                  Accessorial - {item.code}
                                </Typography>
                                <Typography className={cls.lightValTxt}>${item.cost.toFixed(2)}</Typography>
                              </div>
                            </Grid>
                          </Grid>
                        ))}

                      <Grid container spacing={1}>
                        <Grid item sm={12} xs={12}>
                          <div className={cls.listItem}>
                            <Typography
                              className={cls.detailNotesTxt}
                              style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                            >
                              {loanerState && !loanerInsuranceDefault
                                ? `Actual insurance rate for ${loanerState}: $${loanerInsurancePerMileRate.toFixed(2)}/mi`
                                : `Legacy insurance rate: $${loanerInsurancePerMileRate}/mi`}
                            </Typography>
                            <Typography className={cls.lightValTxt}>
                            {loanerInsuranceComparison > 0 ? `($${Math.abs(loanerInsuranceComparison.toFixed(2))} higher)` : loanerInsuranceComparison < 0 ? `($${Math.abs(loanerInsuranceComparison.toFixed(2))} lower)` : null} {loanerInsurance > 0 ? `${loanerInsurance.toFixed(2)}` : 'N/A'}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>

                      {loanerAssumedInsurancePerMileRate > 0 ? (
                        <Grid container spacing={1}>
                          <Grid item sm={12} xs={12}>
                            <div className={cls.listItem}>
                              <Typography
                                className={cls.detailNotesTxt}
                                style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
                              >
                                {`Assumed insurance rate: $${loanerAssumedInsurancePerMileRate.toFixed(2)}/mi`}
                              </Typography>
                              <Typography className={cls.lightValTxt} style={{ textDecoration: 'line-through' }}>
                                ${loanerAssumedInsurance > 0 ? loanerAssumedInsurance.toFixed(2) : 'N/A'}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}

                      <div className={cls.lineBreak} />
                    </Grid>
                  </>
                ) : null}

                {commission > 0 ? (
                  <>
                    <div className={cls.listItem}>
                      <Typography className={cls.detailNameTxt}>Commission&nbsp;&nbsp;</Typography>
                      <Typography className={cls.detailValTxt}>-${commission.toFixed(2)}</Typography>
                    </div>
                  </>
                ) : null}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}></Grid>
              <Grid item sm={6} xs={12}>
                <div className={cls.listItem} style={{ justifyContent: 'flex-end' }}>
                  <Typography className={cls.headTxtL}>
                    {profit >= 0 ? `Total Profit:  $${profit.toFixed(2)}` : `Total Loss:  $${profit.toFixed(2)}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  headTxtM: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  detailValTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  lightValTxt: {
    color: theme.palette.text.secondary,
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveBreakdown;
