import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ACTIVE_CUSTOMERS, GET_ALL_CUSTOMERS } from './gql';
import { Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

const log = false;

export default function CustomerMultiselect({
  selectedCustomerIdArray,
  required,
  label,
  handleChangeCustomers,
  includeInactive = false,
  listSelected = false,
}) {
  const [getCustomers, { data, loading }] = useLazyQuery(includeInactive ? GET_ALL_CUSTOMERS : GET_ACTIVE_CUSTOMERS);

  const [selectedCustomerNames, setSelectedCustomerNames] = React.useState([]);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [toggleSelectAll, setToggleSelectAll] = React.useState(false);

  React.useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    log && console.log('In Multi- ', selectedCustomerIdArray);
    if (data && data.customers && data.customers.length) {
      //
      const customers = data.customers;

      //If selected values are being passed in as props, set them as selected if state is currently empty
      const customerIdsFromPropsLength = Array.isArray(selectedCustomerIdArray) && selectedCustomerIdArray.length;
      const currentSelectedCustomerArrayLength = Array.isArray(selectedCustomerNames) && selectedCustomerNames.length;
      //If all customers are selected when component loads, set toggleAllSelected to true
      if (customerIdsFromPropsLength && customerIdsFromPropsLength === customers.length) {
        const allCustomerNames = data.customers.map(customer => customer.name);
        setToggleSelectAll(true);
        setSelectedCustomerNames(allCustomerNames);
      }
      if (customerIdsFromPropsLength && currentSelectedCustomerArrayLength === 0) {
        const preSelectedCustomerNames = [];
        selectedCustomerIdArray.forEach(id => {
          const foundCustomer = customers.find(customer => customer.id === id);
          if (foundCustomer) {
            preSelectedCustomerNames.push(foundCustomer.name);
          }
        });
        setSelectedCustomerNames(preSelectedCustomerNames);
      }

      //Set the options in the dropdown (customer names)
      const allCustomerNames = data.customers.map(customer => customer.name);
      setCustomerOptions(allCustomerNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //handle adding or removing customers from the customer names array when the customer id array changes
  React.useEffect(() => {
    if (data && data.customers && data.customers.length) {
      const selectedCustomerNames = selectedCustomerIdArray.reduce((names, id) => {
        const foundCustomer = data.customers.find(customer => customer.id === id);
        if (foundCustomer) {
          names.push(foundCustomer.name);
        }
        return names;
      }, []);
      setSelectedCustomerNames(selectedCustomerNames);
    } else {
      setSelectedCustomerNames([]);
    }
  }, [selectedCustomerIdArray]);

  const sortCustomerOptions = useCallback(
    options => {
      //This function will place the "Select All" option at the top, then any selected customers, then alphabetize the unselected option
      function orderCustomerOptions(a, b) {
        const aIsSelected = selectedCustomerNames.includes(a);
        const bIsSelected = selectedCustomerNames.includes(b);
        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (!aIsSelected && bIsSelected) {
          return 1;
        }
        if (aIsSelected && bIsSelected) {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      }
      options.sort(orderCustomerOptions);
      return options;
    },
    [selectedCustomerNames]
  );

  const handleOnChange = (event, value, customers) => {
    log && console.log('Multiselect current values: ', selectedCustomerNames);
    log && console.log('On Change Event', event);
    const customerIds = [];
    customers.forEach(customer => {
      if (value.includes(customer.name)) {
        customerIds.push(customer.id);
      }
      setSelectedCustomerNames(value);
    });
    if (handleChangeCustomers) {
      handleChangeCustomers(customerIds);
    }
  };

  const handleToggleSelectAll = () => {
    if (!toggleSelectAll && inputValue.length) {
      const filterValue = inputValue.trim().toLowerCase();
      const filteredCustomerNames = [];
      const selectedCustomerIds = [];
      data.customers.forEach(customer => {
        if (customer.name && customer.name.toLowerCase().includes(filterValue)) {
          filteredCustomerNames.push(customer.name);
          selectedCustomerIds.push(customer.id);
        }
      });
      setToggleSelectAll(true);
      setSelectedCustomerNames(filteredCustomerNames);
      if (handleChangeCustomers) {
        handleChangeCustomers(selectedCustomerIds);
      }
    } else if (!toggleSelectAll) {
      const allCustomerNames = data.customers.map(customer => customer.name);
      const allCustomerIds = data.customers.map(customer => customer.id);
      setToggleSelectAll(true);
      setSelectedCustomerNames(allCustomerNames);
      if (handleChangeCustomers) {
        handleChangeCustomers(allCustomerIds);
      }
    } else {
      setToggleSelectAll(false);
      setSelectedCustomerNames([]);
      if (handleChangeCustomers) {
        handleChangeCustomers([]);
      }
    }
  };

  const getCustomerLabel = (customers, option) => {
    const customer = customers.find(customer => customer.name === option);
    if (customer) return `${customer.name} (${customer.id})`;
    return 'Unkown Customer';
  };

  const getPlaceholderText = () => {
    if (loading) {
      return `Loading Customers...`;
    }
    const selectedValuesCount = Array.isArray(selectedCustomerNames) && selectedCustomerNames.length ? selectedCustomerNames.length : 0;
  
    if (listSelected && selectedValuesCount > 0) {
      const namesToDisplay = selectedCustomerNames.slice(0, 10); // Get the first 10 names
      const namesString = namesToDisplay.join(', ');
      if (selectedValuesCount > 10) {
        return `${namesString}, [+${selectedValuesCount - 10}]`; // Add ellipsis if more than 10 names
      }
      return namesString; // Return the names if 10 or fewer
    } else {
      if (selectedValuesCount === 1) {
        return `1 Customer Selected`;
      }
      if (selectedValuesCount > 1) {
        return `${selectedCustomerNames.length} Customers Selected`;
      }
      return listSelected ? 'Filter by customer...' : 'Select Customers...';
    }
  };

  const renderOptions = (option, selected) => {
    return (
      <div>
        <Checkbox
          data-testid={`${option}-checkbox-customer-multiselect`}
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 4 }}
          checked={selected}
          color={'primary'}
        />
        {getCustomerLabel(data.customers, option)}
      </div>
    );
  };

  const icon = <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = <CheckBox fontSize='small' />;

  return (
    <Autocomplete
      multiple
      id='customer-multi-select'
      options={sortCustomerOptions(customerOptions)}
      value={selectedCustomerNames}
      disableCloseOnSelect
      disableClearable={true}
      popupIcon={''}
      getOptionLabel={option => option}
      onChange={(event, value) => {
        handleOnChange(event, value, data.customers);
      }}
      renderTags={() => {
        return null;
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event && event.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setInputValue(value);
        }
      }}
      renderOption={(option, { selected }) => renderOptions(option, selected)}
      renderInput={params => (
        <TextField
          {...params}
          data-testid='customer-multiselect-textfield'
          required={required || false}
          multiline
          name='customer'
          label={label || 'Customers'}
          placeholder={getPlaceholderText()}
          size='small'
          variant='outlined'
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <FormControlLabel
                data-testid='customer-multiselect-select-all-checkbox'
                style={{ marginLeft: 4, marginRight: 0 }}
                onClick={e => {
                  e.preventDefault(); // prevent blur
                  handleToggleSelectAll();
                }}
                control={
                  <Checkbox
                    id='select-all-checkbox'
                    color={'primary'}
                    style={{
                      padding: 0,
                      marginRight: 8,
                    }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={toggleSelectAll}
                  />
                }
              />
            ),
          }}
        />
      )}
    />
  );
}
