//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles, Tabs, Tab, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalContent } from '../ModalComponents';
import sdk from '@hopdrive/sdk';
import { useSubscription } from '@apollo/client';
import { GET_RIDE } from './gql';

import useManageRide from './useManageRide';

import {
  ManageRidePartner,
  ManageRideAttemptDetails,
  ManageRideActions,
  ManageRideAttemptHistory,
  ManageRideMap,
} from './index';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideContent({ onClose, input }) {
  const cls = useStyles();

  // State management
  const [partner, setPartner] = React.useState(``);
  const [selectedAttemptId, setSelectedAttemptId] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [driverLoc, setDriverLoc] = React.useState(null);
  const [laneRideEstimate, setLaneRideEstimate] = React.useState(0);
  const [rideshareRate, setRideshareRate] = React.useState(0);
  const [driveLane, setDriveLane] = React.useState(null);
  const [rideLane, setRideLane] = React.useState(null);

  // Initial useEffect when the input changes
  React.useEffect(() => {
    console.log('MOVE', input.move);
    const move = input?.move;
    const rideshareRate =
      move?.customer?.raterulegroups?.length && move?.customer?.raterulegroups[0]?.ridesharerate
        ? move?.customer?.raterulegroups[0]?.ridesharerate
        : null;

    // Set rideshare rate
    setRideshareRate(rideshareRate);

    //Set lane ride estimate & calculated "surge" cost we use in accessorials
    let estimatedRidesharereturnCost = sdk.rating.thirdParty.getEstimatedReturnRideCost(
      move?.lane?.distance_miles,
      move?.lane?.average_drive_speed_mph,
      move?.lane?.tolls,
      rideshareRate
    );
    if (estimatedRidesharereturnCost) {
      setLaneRideEstimate(estimatedRidesharereturnCost);
    } else if (getPropValue(input, `move.lane.estimated_rideshare_return_cost`)) {
      setLaneRideEstimate(getPropValue(input, `move.lane.estimated_rideshare_return_cost`));
    }

    // Calculate surge cost delta
    const driveLane = move?.parentMove?.lane;
    const rideLane = move?.lane;
    setDriveLane(driveLane);
    setRideLane(rideLane);

    if (getPropValue(input, `move.ride_type`)) {
      setPartner(input.move.ride_type);
    } else setPartner(`auto`);

    async function fetchDriverLoc() {
      if (getPropValue(input, `move.driver_id`)) {
        const myDriver = null;
        if (myDriver) {
          const myDriverLoc = {
            id: `driver`,
            type: `driver_loc`,
            name: myDriver.name,
            latitude: Number(myDriver.latitude),
            longitude: Number(myDriver.longitude),
          };
          setDriverLoc(myDriverLoc);
        } else setDriverLoc(null);
      } else setDriverLoc(null);
    }
    fetchDriverLoc();
  }, [input]);

  //////////////////////// SUBSCRIPTION ////////////////////////

  // GET RIDE DATA //
  const {
    loading: subLoading,
    error: subError,
    data: subData,
  } = useSubscription(GET_RIDE, {
    variables: { rideMoveId: getPropValue(input, `move.id`) },
    fetchPolicy: `network-only`,
  });

  const { buildDataObject, updateRidePartner } = useManageRide();

  //////////////////////// HANDLERS ////////////////////////

  // Change the ride partner of the selected ride
  const handlePartnerChange = value => {
    updateRidePartner(value, getPropValue(input, `move.id`));
    setPartner(value);
  };

  // Change the active tab
  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  //////////////////////// RENDER - RETURN ////////////////////////

  const dataObj = buildDataObject(input, subData, selectedAttemptId);
  const {
    pickup,
    delivery,
    lyftride,
    overrideLoc,
    attempts,
    currentAttempt,
    selectedAttempt,
    rideStatus,
    attemptStatus,
    attemptPickup,
    attemptDelivery,
  } = dataObj;
  let noAction = true;

  // Check if Ride/Attempt loading
  if (
    !rideStatus ||
    rideStatus === `new` ||
    rideStatus === `called` ||
    rideStatus === `failed` ||
    !attemptStatus ||
    attemptStatus === `accepted` ||
    attemptStatus === `arrived` ||
    attemptStatus === `pickedUp` ||
    attemptStatus === `droppedOff` ||
    attemptStatus === `canceled` ||
    attemptStatus === `failed`
  ) {
    noAction = false;
  }

  return (
    <>
      {/* TABS */}
      <div className={cls.tabs}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
        >
          <Tab label='CURRENT' icon={<Icon>star</Icon>} />
          <Tab label='HISTORY' icon={<Icon>history</Icon>} />
          <Tab label='MAP' icon={<Icon>map</Icon>} />
        </Tabs>
      </div>

      {/* CONTENT */}
      <ModalContent>
        {/* CURRENT ATTEMPT */}
        {activeTab === 0 ? (
          <>
            {/* ATTEMPT DETAILS */}
            <ManageRideAttemptDetails
              ride={lyftride}
              attempt={currentAttempt}
              rideCostEstimate={laneRideEstimate}
              rideshareRate={rideshareRate}
              driveLane={driveLane}
              rideLane={rideLane}
              title={`View Current Attempt`}
              subtitle={`See details and take action on the current ride hailing attempt.`}
              loading={subLoading}
              error={subError}
            />

            {/* RIDE PARTNER */}
            {!currentAttempt || currentAttempt.status !== `droppedOff` ? (
              <>
                <Spacer />
                <ManageRidePartner partner={partner} onPartnerChange={handlePartnerChange} />
              </>
            ) : null}

            <Spacer />

            {/* ACTIONS */}
            <ManageRideActions
              input={input}
              attempt={currentAttempt}
              partner={partner}
              noAction={noAction}
              loading={subLoading}
              error={subError}
            />
          </>
        ) : null}

        {/* ATTEMPT HISTORY */}
        {activeTab === 1 ? (
          <ManageRideAttemptHistory
            attempts={attempts}
            currentAttempt={currentAttempt}
            selectedAttempt={selectedAttempt}
            setSelectedAttemptId={setSelectedAttemptId}
          />
        ) : null}

        {/* INTERACTIVE MAP */}
        {activeTab === 2 ? (
          <ManageRideMap
            pickup={pickup}
            delivery={delivery}
            attemptPickup={attemptPickup}
            attemptDelivery={attemptDelivery}
            driverLoc={driverLoc}
            overrideLoc={getPropValue(lyftride, `latitude`) && getPropValue(lyftride, `longitude`) ? overrideLoc : null}
          />
        ) : null}
      </ModalContent>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  btnIcon: {
    marginRight: theme.spacing(1),
  },
  btnIconSms: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  tabs: {
    width: '100%',
    borderTop: theme.border[0],
    background: theme.palette.background.light,
  },
}));
