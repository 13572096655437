import { React, useState, useEffect } from 'react';
import { gql } from 'graphql-tag';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { makeStyles, withStyles, Grid, Typography, TextField, MenuItem, Container, Switch } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { useData } from '../../providers/DataProvider';
import { getUserEmail } from '../../utils/authHelper'

export default function ProductsForm() {
  const cls = useStyles();
  const history = useHistory();
  const ctx = useData();

  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [prepaid, setPrepaid] = useState(false);
  const [term, setTerm] = useState('monthly');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let userEmail = getUserEmail()
    setEmail(userEmail)
  }, [])

  useEffect(() => {
    if (name && description && typeof cost === 'number' && term) {
      setSaveDisabled(false);
    }
  }, [name, description, cost, term]);

  const handleInputChange = setHandler => event => {
    if (event.target.value !== '') setHandler(event.target.value);
    else setHandler('');
  };
  const handleCostChange = event => {
    if (event.target.value !== 0 && isNaN(event.target.value) !== true) setCost(Number(event.target.value));
    else setCost(0);
  };

  const handleSetTerm = event => {
    const termCode = event.target.value;
    if (termCode === 'weekly' || termCode === 'once') {
      setTerm(event.target.value);
    } else {
      setTerm('monthly');
    }
  };

  const handleSaveProduct = async () => {
    try {
      setIsSaving(true);
      const productObj = {
        active: true,
        cost: cost,
        description: description,
        name: name,
        prepaid: prepaid,
        term: term,
        createdBy: email,
        updatedBy: email,
        salable: true,
      };
      const productRes = await ctx.apolloClient.mutate({
        mutation: INSERT_PRODUCT,
        variables: { productObj: productObj },
      });
      console.log(productRes);
      if (productRes && productRes.data) {
        console.log('Inserted Product', productRes);
        toast.success('Added New Product!');
        history.push({
          pathname: '/products',
        });
        //navigate back to products
        setIsSaving(false);
      } else {
        // toast message
        console.log('No product inserted', productRes);
        toast.error('Failed to Add Product');
        setIsSaving(false);
      }
    } catch (err) {
      console.error('Failed to Add Product', err);
      toast.error('Failed to Add Product');
      setIsSaving(false);
    }
  };

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.text.secondary,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);

  return (
    <div className={cls.root}>
      <Container maxWidth='md'>
        <div className={cls.paper}>
          <Typography className={cls.headTxt}>{`Add Product`}</Typography>
          <Spacer size='xs' />
          <Typography className={cls.subTxt}>
            Create a new product. After the product has been created it can be added to a customer's contract.
          </Typography>
          <Spacer size='lg' />

          <TextField
            required
            fullWidth
            label='Product Name'
            placeholder='Enter a name for the product'
            variant='outlined'
            value={name}
            onChange={handleInputChange(setName)}
          />
          <Spacer />
          <TextField
            required
            fullWidth
            select
            label='Term'
            placeholder='Select how often the customer should be charged for this product'
            variant='outlined'
            size='small'
            value={term}
            onChange={handleSetTerm}
          >
            <MenuItem value={'once'}>once</MenuItem>
            <MenuItem value={'weekly'}>weekly</MenuItem>
            <MenuItem value={'monthly'}>monthly</MenuItem>
          </TextField>
          <Spacer />
          <TextField
            required
            fullWidth
            label='Cost'
            placeholder='Set cost of product'
            variant='outlined'
            size='small'
            value={cost}
            onChange={handleCostChange}
          />
          <Spacer />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Is a Prepaid Package:</Typography>
            <div className={cls.switch}>
              <AntSwitch name='Prepaid Package' checked={prepaid} onChange={() => setPrepaid(!prepaid)} />
            </div>
          </div>

          <Spacer />

          <TextField
            required
            fullWidth
            label='Description'
            placeholder='Enter a brief description of the product'
            variant='outlined'
            size='small'
            minRows={2}
            value={description}
            onChange={handleInputChange(setDescription)}
          />

          <Spacer />

          <Grid container>
            <Grid item xs />
            <Grid item>
              <Button
                disabled={saveDisabled || isSaving}
                loading={isSaving}
                color='primary'
                size='large'
                onClick={() => handleSaveProduct()}
              >
                Save New Product
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
//STYLES
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 500,
  },
  subTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    verticalAlign: 'baseline',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    whiteSpace: 'nowrap',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));
//GQL
const INSERT_PRODUCT = gql`
  mutation INSERT_PRODUCT($productObj: products_insert_input!) {
    insert_products_one(object: $productObj) {
      accounting_id
      active
      cost
      createdBy
      created_at
      description
      id
      name
      prepaid
      salable
      term
      updatedBy
      updated_at
    }
  }
`;
