//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import NavTopBar from './NavTopBar';
import NavSideBar from './NavSideBar';

//////////////////////// COMPONENT ////////////////////////

function Nav(props) {
  const [open, setOpen] = React.useState(false);

  // Open/Close slidable sidebar drawer
  const handleMenu = value => {
    setOpen(value);
  };

  return (
    <>
      <NavTopBar onMenu={setOpen} />
      <NavSideBar open={open || false} onMenu={handleMenu} />
    </>
  );
}

//////////////////////// EXPORT ////////////////////////

export default Nav;
