//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container, Grid, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import GoogleLocationBuilder from '../../components/GoogleLocationBuilder';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function CustomerAddForm({
  organizations,
  formCategory,
  onFormCategoryChange,
  formLocation,
  onFormLocationChange,
  formName,
  onFormNameChange,
  formOrganizationId,
  onFormOrganizationIdChange,
  isSaving,
  validationErrors,
}) {
  const cls = useStyles();

  React.useEffect(() => {
    if (formOrganizationId || formOrganizationId === 0) {
      const org = organizations?.find(o => o?.id === formOrganizationId);
      if (org) {
        onFormNameChange(org?.name + ` - `);
        onFormCategoryChange(org?.category || ``);
      } else {
        onFormNameChange(``);
        onFormCategoryChange(``);
      }
    }
  }, [formOrganizationId]);

  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  return (
    <Container maxWidth='lg'>
      {/* ORGANIZATION */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Parent Organization</Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              (Optional) The organization the customer belongs to. If the customer belongs to an organization, assign
              the organization here. This allows all users that have access to the org to also have access to this
              customer.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              select
              fullWidth
              label='Organization'
              placeholder='Select the organization...'
              variant='outlined'
              size='small'
              value={formOrganizationId || 0}
              onChange={handleInputChange(onFormOrganizationIdChange)}
              error={validationErrors?.formOrganizationId}
              helperText={validationErrors?.formOrganizationId || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      groups
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={0}>
                <em>No Organization</em>
              </MenuItem>
              {organizations?.length &&
                organizations?.map((o, i) => (
                  <MenuItem key={`organization-${o?.id}`} value={o?.id}>
                    {o?.name} (#{o?.id})
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </Grid>
      </Grid>

      {/* NAME */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Customer Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Name of the customer. The name will be the primary identifier for this customer. If the company name
              changes, this can be updated from the customer screen.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Name'
              placeholder='Enter the customer name...'
              variant='outlined'
              size='small'
              value={formName}
              onChange={handleInputChange(onFormNameChange)}
              error={validationErrors?.formName}
              helperText={validationErrors?.formName || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      label
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* LOCATION */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Customer Location <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Location of the customer rooftop/store. This location is used for back-end systems and is a helpful
              identifier for where the customer is located. It will also build the customer's first location
              automatically when the form is submitted.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <GoogleLocationBuilder
              required
              onChange={onFormLocationChange}
              validationError={validationErrors?.formLocation}
            />
          </div>
        </Grid>
      </Grid>

      {/* CATEGORY */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Customer Category <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Category of the customer. Select a category that best describes this customer. This is simply an internal
              flag to help HopDrive understand what this customer specializes in. This flag has no impact on our
              back-end systems whatsoever.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Operational</b> - This customer specializes in operations-type moves.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Concierge</b> - This customer specializes in concierge-type moves.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>House</b> - This customer is a house account and should not be included in AR/AP processes.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Category'
              placeholder='Select the customer category...'
              variant='outlined'
              size='small'
              value={formCategory}
              onChange={handleInputChange(onFormCategoryChange)}
              error={validationErrors?.formCategory}
              helperText={validationErrors?.formCategory || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='operational'>Operational</MenuItem>
              <MenuItem value='concierge'>Concierge</MenuItem>
              <MenuItem value='house'>House</MenuItem>
            </TextField>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
}));
