//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  Switch,
  Divider,
} from '@material-ui/core';
import BigButtonGroup from './BigButtonGroup';
import BigButton from './BigButton';
import { Button } from '@hopdrive/storybook';
import ValueListItem from './ValueListItem';
import { useData } from '../providers/DataProvider';
import { getUserEmail } from '../utils/authHelper';
import { toast } from 'react-toastify';

//////////////////////// COMPONENT ////////////////////////

/**
 * Add or remove spend authorizations to a move
 * @param {BigInteger} moveId  - Requires a move ID passed in as a prop
 * @param {BigInteger} customerId - Requires a customer ID passed in as a prop
 * @param {Boolean} open - Pass in a boolean value of true to render the dialog
 * @param {Function} close - Function to toggle the 'open' value from true to false
 */
const SpendAuthorization = ({ open, close, moveId, customerId }) => {
  const ctx = useData();
  let loggedInUserEmail = getUserEmail();

  const [type, setType] = React.useState(ctx._sdk.accessorials.types.FUEL.code);
  const [maxSpend, setMaxSpend] = React.useState(0);
  const [isUsingCompanyCard, setIsUsingCompanyCard] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [accessorial, setAccessorial] = React.useState(null);
  const [reimbursement, setReimbursement] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleTypeChange = (id, title) => {
    setType(id);
  };

  React.useEffect(() => {
    const handleCreateAccessorial = async () => {
      //Get merged Customer Config
      const getFuelMultiplier = async customerId => {
        if (!customerId) return 1;
        const mergedCustomerConfig = await ctx._sdk.configs.getCustomerConfig(customerId);
        return mergedCustomerConfig?.fuel_multiplier || 1;
      };

      //Build the returning object whenever components of it are updated
      let accessorial;
      let reimbursement = null;
      let fuelMultiplier = await getFuelMultiplier(customerId);

      switch (type) {
        case ctx._sdk.accessorials.types.FUEL.code:
          accessorial = ctx._sdk.accessorials.buildFuelAccessorial({
            moveId: moveId,
            cost: maxSpend,
            fuelMultiplier: fuelMultiplier,
            calcDriverTimePay: true,
            payPerMinute: 0.17,
            durationSeconds: 300,
            status: isUsingCompanyCard ? 'pending' : 'approved',
            notes: isUsingCompanyCard
              ? `Authorized fuel spend with company credit card via spend auth modal`
              : `Authorized fuel spend with driver card via spend auth modal`,
          });
          if (isUsingCompanyCard) {
            accessorial.authorization = {
              data: ctx._sdk.authorizations.buildAuthorization({
                maxCharge: maxSpend,
                createdByUserId: loggedInUserEmail,
              }),
            };
          } else {
            try {
              //build reimbursement accessorial
              reimbursement = ctx._sdk.accessorials.buildAccessorial({
                moveId: moveId,
                code: ctx._sdk.accessorials.types.REIMBURSEMENT.code,
                cost: 0,
                status: 'approved',
                ar_amount: 0,
                ap_amount: Number(maxSpend),
                notes: `Driver Reimbursement for fuel cost`,
              });
            } catch (err) {
              console.log('Error building reimbursement: ', err);
            }
          }
          break;

        default:
          accessorial = null;
          break;
      }
      setAccessorial(accessorial);
      setReimbursement(reimbursement);
    };
    handleCreateAccessorial();
  }, [moveId, type, maxSpend, isUsingCompanyCard, ctx._sdk.accessorials, ctx._sdk.authorizations, customerId]);

  const handleSave = async () => {
    setLoading(true);
    console.log('Spend auth modal saved with the following: ', accessorial);
    const response = await ctx._sdk.accessorials.create(accessorial);
    if (response && response.success === true) {
      toast.success(`Spend authorization saved!`);
    } else {
      toast.error(`Failed to save spend authorization`);
    }
    if (reimbursement) {
      const response = await ctx._sdk.accessorials.create(reimbursement);
      if (response && response.success === true) {
        toast.success(`Reimbursement saved!`);
      } else {
        toast.error(`Failed to save reimbursement`);
      }
    }
    setLoading(false);
    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth='md' aria-labelledby='spend-authorization-modal'>
      <DialogTitle id='spend-authorization-modal'>Spend Authorization</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Authorize the driver to spend some money on this move then record it as an accessorial.
        </DialogContentText>
        <BigButtonGroup onChange={handleTypeChange} preselect={type}>
          <BigButton
            id={ctx._sdk.accessorials.types.FUEL.code}
            title='Fuel'
            subtitle='Put gas in the vehicle being moved'
          />
          <BigButton
            disabled
            id={ctx._sdk.accessorials.types.TOW.code}
            title='Tow'
            subtitle='Prepay for a tow of the vehicle being moved'
          />
          <BigButton disabled id={'other'} title='Other' subtitle='Other misc spend type' />
        </BigButtonGroup>
        <FormControl>
          <FormGroup>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom>
                  Who is initially covering the cost of the fuel?{' '}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom>
                  If the driver has a credit card provided by us and you intend for them to use it then leave this
                  enabled. If instead the driver will need to use their own personal funds and then be reimbursed on
                  their driver pay, then disable this toggle.
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isUsingCompanyCard}
                      onChange={e => {
                        setIsUsingCompanyCard(e.target.checked);
                      }}
                      name='checkedA'
                    />
                  }
                  label={`Use company fuel card${!isUsingCompanyCard ? ' (Driver will use their personal funds)' : ''}`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom>
                  What's the max dollar amount you want to authorize?{' '}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  For swiping at the pump, this should work like prepaying inside and automatically stop the pump when
                  it reaches this amount.
                </Typography>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='max-spend'>Max Authorized Spend</InputLabel>
                  <OutlinedInput
                    id='max-spend'
                    value={maxSpend}
                    onChange={e => setMaxSpend(e.target.value)}
                    startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    labelWidth={175}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <Tabs
                  value={selectedTab}
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={(e, v) => setSelectedTab(v)}
                  aria-label='Accessorial preview'
                >
                  <Tab label='Preview' />
                  <Tab label='Source' />
                </Tabs>
                {selectedTab === 0 && (
                  <>
                    <ValueListItem label='Code' value={accessorial && accessorial.code} />
                    <ValueListItem label='Status' gray value={accessorial && accessorial.status} divider />
                    <ValueListItem
                      label='Cost'
                      value={accessorial && accessorial.cost && '$' + accessorial.cost.toFixed(2)}
                      divider
                    />
                    <ValueListItem
                      label='Payable (driver)'
                      gray
                      value={accessorial && accessorial.ap_amount && '$' + accessorial.ap_amount.toFixed(2)}
                      divider
                    />
                    <ValueListItem
                      label='Chargeable (customer)'
                      value={accessorial && accessorial.ar_amount && '$' + accessorial.ar_amount.toFixed(2)}
                      divider
                    />
                    <ValueListItem label='Notes' gray value={accessorial && accessorial.notes} divider />
                    <ValueListItem
                      label='Card Authorization'
                      value={accessorial && accessorial.cost && '$' + accessorial.cost.toFixed(2)}
                      divider
                    />
                    <Divider />
                    {reimbursement ? (
                      <>
                        <ValueListItem label='Code' value={reimbursement && reimbursement.code} />
                        <ValueListItem label='Status' gray value={reimbursement && reimbursement.status} divider />
                        <ValueListItem
                          label='Cost'
                          value={reimbursement && reimbursement.cost && '$' + reimbursement.cost.toFixed(2)}
                          divider
                        />
                        <ValueListItem
                          label='Payable (driver)'
                          gray
                          value={reimbursement && reimbursement.ap_amount && '$' + reimbursement.ap_amount.toFixed(2)}
                          divider
                        />
                        <ValueListItem
                          label='Chargeable (customer)'
                          value={reimbursement && reimbursement.ar_amount && '$' + reimbursement.ar_amount.toFixed(2)}
                          divider
                        />
                        <ValueListItem label='Notes' gray value={reimbursement && reimbursement.notes} divider />
                        <ValueListItem
                          label='Card Authorization'
                          value={reimbursement && reimbursement.cost && '$' + reimbursement.cost.toFixed(2)}
                          divider
                        />
                      </>
                    ) : null}
                  </>
                )}
                {selectedTab === 1 && (
                  <>
                    <pre>{JSON.stringify(accessorial, null, 2)}</pre>
                    {reimbursement ? (
                      <>
                        <pre>{JSON.stringify(reimbursement, null, 2)}</pre>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary'>
          Cancel
        </Button>
        <Button
          disabled={maxSpend <= 0 || loading === true}
          onClick={handleSave}
          loading={loading}
          color='primary'
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//////////////////////// EXPORT ////////////////////////

export default SpendAuthorization;
