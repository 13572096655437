import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Typography, LinearProgress } from '@material-ui/core';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useDrivers } from '../Drivers/useDrivers';

//////////////////////// COMPONENT ////////////////////////

export default function ReinstateModalContent({ input, onClose }) {
  const cls = useStyles();
  const { reinstateDriver } = useDrivers();

  const [isSaving, setIsSaving] = React.useState(false);

  // Handle submitting the form
  const handleSubmit = async () => {
    const res = await reinstateDriver(input.driver);
    if (res) {
      toast.success(`Successfully reinstated driver!`, { autoClose: 2500 });
      if (onClose) onClose();
    } else {
      toast.error(`Failed to terminate driver, please contact an admin!`);
    }

    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Reinstate Driver</ModalHeader>

      <ModalContent>
        <Typography className={cls.subtitle}>
          When a driver is reinstated, their onboarding checklist will be reset. Once the driver has verified that their personal information on record is still correct, you can re-approve the forms and proceed with the rest of onboarding.
        </Typography>
      </ModalContent>

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <ModalAction
          loading={isSaving}
          disabled={isSaving}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));