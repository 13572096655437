// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import TimelineAreaHeader from './TimelineAreaHeader';
import TimelineAreaBody from './TimelineAreaBody';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineArea() {
  const cls = useStyles();

  return (
    <div className={cls.timelineArea}>
      <TimelineAreaHeader />
      <TimelineAreaBody />
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineArea: {
    zIndex: 100,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 'max-content',
    minHeight: '100%',
  },
}));
