// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { useFilters } from '../FilterProvider';

import { Radio } from '@material-ui/core';

// COMPONENT ---------------------------------------------------------------- //

export default function GenericRadio({ module, options, defaults }) {
  const { filters, updateModule } = useFilters();

  // The state of the module is managed in the FilterProvider
  const moduleOptions = filters[module]?.options;

  const handleChange = value => {
    const newOptions = options.options.map(opt => ({
      value: opt,
      checked: opt === value,
    }));
    updateModule(module, 'options', newOptions);
  };

  React.useEffect(() => {
    // Only set default if no options are currently checked
    if (!moduleOptions?.some(opt => opt.checked)) {
      const defaultOptions = options.options.map((opt, index) => ({
        value: opt,
        checked: index === 0,
      }));
      updateModule(module, 'options', defaultOptions);
    }
  }, []);

  return (
    <div>
      {options.options.map(option => (
        <div key={option} style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={moduleOptions?.find(opt => opt.value === option)?.checked || false}
            onChange={() => handleChange(option)}
            color='primary'
          />
          <span>{option}</span>
        </div>
      ))}
    </div>
  );
}