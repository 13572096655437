//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import { RateRuleGroupTableRow } from './index';

//////////////////////// COMPONENT ////////////////////////

function RateRuleGroupTableBody({ rows, order, orderBy, page, rowsPerPage }) {
  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`moves-index-row-${i}`}>
              <RateRuleGroupTableRow row={row} index={i} />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}

//////////////////////// EXPORT ////////////////////////

export default RateRuleGroupTableBody;
