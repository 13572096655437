import React from 'react';
import dayjs from 'dayjs';
import {
  useTheme,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Icon,
  Typography,
  Chip,
} from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayouts({ driver = {} }) {
  const theme = useTheme();
  const cls = useStyles();

  const { goToPayoutDetails, capEach, formatUSD, getReadableText } = useTools();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Initialize Payouts
  const payouts = driver.driverpayouts || [];

  // Table Headers
  const headers = [
    { id: `id`, align: `left`, label: `ID` },
    { id: `type`, align: `left`, label: `Type` },
    { id: `created_at`, align: `left`, label: `Created\xa0At` },
    { id: `status`, align: `left`, label: `Status` },
    { id: `amount`, align: `right`, label: `Amount` },
  ];

  // Table Rows
  let rows = payouts.map((payout, i) => {
    return {
      // Row Fields
      id: payout.id || null,
      type: getReadableText(payout.type) || null,
      created_at: dayjs(payout.created_at).format(`MM/DD/YYYY hh:mm A`),
      status: (
        <div className={cls.statusBox}>
          {payout.status.includes(`new`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.info.main }}>
              new_releases
            </Icon>
          )}
          {payout.status.includes(`process`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.warning.main }}>
              pending
            </Icon>
          )}
          {payout.status.includes(`success`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
              check_circle
            </Icon>
          )}
          {payout.status.includes(`fail`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
              cancel
            </Icon>
          )}
          {payout.status.includes(`cancel`) && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.default.main }}>
              block
            </Icon>
          )}
          <Typography className={cls.statusTxt}>{capEach(payout.status)}</Typography>
        </div>
      ),
      amount: (
        <Chip
          size='small'
          label={formatUSD(payout.amount, { removeSign: true })}
          icon={<Icon>attach_money</Icon>}
          classes={{
            label: cls.chipLabel,
          }}
        />
      ),

      // Additional Fields
      driverpayout: payout,
      index: i,
      foldId: payout.id,
    };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='driver-details-payouts-table'>
          <TableHead>
            <TableRow>
              {headers.map(header => {
                return (
                  <TableCell
                    key={header.id}
                    align={header.align || `left`}
                    padding={header.padding || `normal`}
                    sortDirection='asc'
                  >
                    {header.label || `Label`}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              const cells = [
                {
                  value: row.id || `-`,
                  align: `left`,
                  onClick: () => goToPayoutDetails(row.id),
                },
                {
                  value: row.type || `-`,
                  align: `left`,
                  onClick: () => goToPayoutDetails(row.id),
                },
                {
                  value: row.created_at || `-`,
                  align: `left`,
                  onClick: () => goToPayoutDetails(row.id),
                },
                {
                  value: row.status || `-`,
                  align: `left`,
                  onClick: () => goToPayoutDetails(row.id),
                },
                {
                  value: row.amount || `-`,
                  align: `right`,
                  onClick: () => goToPayoutDetails(row.id),
                },
              ];
              return (
                <TableRow key={`driver-details-payouts-index-row-${i}`} className={i % 2 ? cls.rowEven : cls.rowOdd}>
                  {cells.map((cell, ci) => {
                    return (
                      <TableCell
                        key={`driver-details-payouts-index-cell-${ci}`}
                        align={cell.align || `left`}
                        onClick={cell.onClick || null}
                      >
                        {cell.value || null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  table: {
    overflowY: `hidden`,
  },
  pagination: {
    paddingRight: theme.spacing(2),
  },

  statusBox: {
    position: 'relative',
    display: 'flex',
  },
  statusIcon: {
    fontSize: 20,
    marginRight: 8,
  },
  statusTxt: {
    fontSize: 14,
  },

  chipLabel: {
    marginTop: 2,
    marginLeft: -4,
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));
