// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { useTheme, makeStyles, Icon, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../../hooks/useTools';

// COMPONENT ---------------------------------------------------------------- //

export default function MoveOutcomeAdjustment({ eventlog }) {
  const theme = useTheme();
  const cls = useStyles();

  const { capFirst, formatUSD } = useTools();

  const oldAccessorial = eventlog?.metadata?.old;
  const newAccessorial = eventlog?.metadata?.new;
  const reason = eventlog?.metadata?.reason;

  const getType = () => {
    if (oldAccessorial?.code && !newAccessorial?.code) return `deleted`;
    if (!oldAccessorial?.code && newAccessorial?.code) return `added`;
    return `modified`;
  };
  const type = getType();

  const getTitle = () => {
    if (oldAccessorial?.code && !newAccessorial?.code) return `Accessorial Deleted`;
    if (!oldAccessorial?.code && newAccessorial?.code) return `Accessorial Added`;
    return `Accessorial Modified`;
  };
  const title = getTitle();

  const getOldAccessorialColor = () => {
    if (type === `deleted`) return `${theme.palette.error.main}20`;
    return theme.palette.background.paper;
  };

  const getNewAccessorialColor = () => {
    if (type === `added`) return `${theme.palette.success.main}20`;
    if (type === `modified`) return `${theme.palette.info.main}20`;
    return theme.palette.background.paper;
  };

  return (
    <>
      <Typography className={cls.titleTxt}>{title}</Typography>

      <Spacer size='xs' />

      {reason ? (
        <div className={cls.reason}>
          <Icon className={cls.reasonIcon}>comment</Icon>
          <Typography className={cls.reasonTxt}>{reason}</Typography>
        </div>
      ) : null}

      <Spacer />

      {oldAccessorial?.code ? (
        <div className={cls.accessorialRow} style={{ background: getOldAccessorialColor() }}>
          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Code</Typography>
            <Typography className={cls.accessorialValTxt}>{capFirst(oldAccessorial?.code)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Cost</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(oldAccessorial?.cost)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Charge</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(oldAccessorial?.ar_amount)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Pay</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(oldAccessorial?.ap_amount)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Notes</Typography>
            <Typography className={cls.accessorialValTxt}>{oldAccessorial?.notes}</Typography>
          </div>
        </div>
      ) : null}

      {oldAccessorial?.code && newAccessorial?.code ? (
        <div className={cls.arrow}>
          <Icon>arrow_downward</Icon>
        </div>
      ) : null}

      {newAccessorial?.code ? (
        <div className={cls.accessorialRow} style={{ background: getNewAccessorialColor() }}>
          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Code</Typography>
            <Typography className={cls.accessorialValTxt}>{capFirst(newAccessorial?.code)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Cost</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(newAccessorial?.cost)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Charge</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(newAccessorial?.ar_amount)}</Typography>
          </div>

          <div className={cls.accessorialCell}>
            <Typography className={cls.accessorialKeyTxt}>Pay</Typography>
            <Typography className={cls.accessorialValTxt}>{formatUSD(newAccessorial?.ap_amount)}</Typography>
          </div>

          <div className={cls.accessorialCellXL}>
            <Typography className={cls.accessorialKeyTxt}>Notes</Typography>
            <Typography className={cls.accessorialValTxt}>{newAccessorial?.notes}</Typography>
          </div>
        </div>
      ) : null}
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  reason: {
    display: 'flex',
    gap: 8,
  },
  reasonIcon: {
    fontSize: 20,
    color: theme.palette.text.secondary,
  },
  reasonTxt: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  accessorialRow: {
    display: 'flex',
    gap: 16,
    padding: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
  },
  accessorialCell: {
    minWidth: '64px',
    maxWidth: '128px',
  },
  accessorialCellXL: {
    minWidth: '128px',
    maxWidth: '256px',
  },
  accessorialKeyTxt: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  accessorialValTxt: {
    fontSize: 14,
  },

  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 4,
    marginBottom: 4,
    fontSize: 24,
    color: theme.palette.text.primary,
  },
}));
