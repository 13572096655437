//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Container } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import CustomerDetailsOverviewMetrics from './CustomerDetailsOverviewMetrics';
import CustomerDetailsOverviewInfo from './CustomerDetailsOverviewInfo';
import MovesByCustomer from './MovesByCustomer';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsOverview({ customer, organizations }) {
  const cls = useStyles();

  return (
    <Container maxWidth='lg'>
      <Divide spacer tip='View top-level metrics for this customer (with comparisons to the previous 30 days).'>
        Customer Metrics
      </Divide>

      <CustomerDetailsOverviewMetrics customer={customer} />

      <Divide
        spacer
        tip={`View and edit the information of this customer. This customer was created on ${dayjs(
          customer?.createdat
        ).format(`MM/DD/YYYY`)} at ${dayjs(customer?.created_at).format(`h:mm A`)}.`}
      >
        Customer Info
      </Divide>

      <div className={cls.paper}>
        <CustomerDetailsOverviewInfo customer={customer} organizations={organizations} />
      </div>

      <Divide spacer tip='View all moves in the past 30 days for this customer.'>
        Moves By Customer
      </Divide>

      <div className={cls.paper}>
        <MovesByCustomer customer={customer} />
      </div>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },
}));
