//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import {
  useTheme,
  makeStyles,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Icon,
  Tooltip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { gql, useMutation } from '@apollo/client';

import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';
import useValidation from '../../hooks/useValidation';

import GoogleLocationBuilder from '../../components/GoogleLocationBuilder';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsOverviewInfo({ customer, organizations }) {
  const theme = useTheme();
  const cls = useStyles();

  const { _sdk } = useData();
  const { condensedCase, capFirst, getAddressComponents, getCleansedPhoneNumber } = useTools();
  const { validateEmailMultiple, validatePhone } = useValidation();

  const [formActive, setFormActive] = React.useState(customer?.active ? 1 : 0);
  const [formCategory, setFormCategory] = React.useState(customer?.category || ``);
  const [formEmail, setFormEmail] = React.useState(customer?.email || ``);
  const [formLocation, setFormLocation] = React.useState(customer?.location || null);
  const [formName, setFormName] = React.useState(customer?.name || ``);
  const [formOrganizationId, setFormOrganizationId] = React.useState(customer?.organization?.id || null);
  const [formPhone, setFormPhone] = React.useState(customer?.phone || ``);
  const [formStatus, setFormStatus] = React.useState(customer?.status || ``);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState(null);

  // Detect edit bool change
  React.useEffect(() => {
    if (!isEditing) {
      setFormActive(customer?.active ? 1 : 0);
      setFormCategory(customer?.category || ``);
      setFormEmail(customer?.email || ``);
      setFormLocation(customer?.location || null);
      setFormName(customer?.name || ``);
      setFormOrganizationId(customer?.organization?.id || null);
      setFormPhone(customer?.phone || ``);
      setFormStatus(customer?.status || ``);
    }
  }, [isEditing]);

  // Mutation to save customer info
  const [saveCustomer] = useMutation(SAVE_CUSTOMER);

  // Check if the form is valid
  const checkIsFormValid = organization => {
    let isValid = true;
    let newValidationErrors = {};

    if (formActive !== 1 && formActive !== 0) {
      isValid = false;
      newValidationErrors.formActive = `Active flag must be selected!`;
    }
    if (!formCategory) {
      isValid = false;
      newValidationErrors.formCategory = `Category must be selected!`;
    }
    if (formEmail && !validateEmailMultiple(formEmail)) {
      isValid = false;
      newValidationErrors.formEmail = `Email is invalid!`;
    }
    // if (!formLocation) {
    //   isValid = false;
    //   newValidationErrors.formLocation = `Location must be found on Google!`;
    // }
    if (!formName || (organization?.name && condensedCase(formName) === condensedCase(organization?.name))) {
      isValid = false;
      newValidationErrors.formName = `Name is required and must be unique from the organization name!`;
    }
    if (formPhone && !validatePhone(formPhone)) {
      isValid = false;
      newValidationErrors.formPhone = `Phone is invalid!`;
    }
    if (!formStatus) {
      isValid = false;
      newValidationErrors.formStatus = `Status must be selected!`;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  // Handle any input change
  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  // Edit the form
  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  // Save changes out of edit mode
  const handleSaveChanges = async () => {
    setIsSaving(true);

    // Check if the form is valid before saving
    const foundOrganization = organizations?.find(org => org?.id === formOrganizationId);
    const isFormValid = checkIsFormValid(foundOrganization);
    if (isFormValid) {
      try {
        // Build the customer object
        let customerVars = {
          id: customer?.id,
          active: formActive ? 1 : 0,
          address: formLocation?.address || null,
          category: formCategory || null,
          email: formEmail || null,
          name: formName || null,
          organization_id: formOrganizationId || null,
          phone: getCleansedPhoneNumber(formPhone) || null,
          status: formStatus || null,
        };

        // Initialize the location object
        let locationVars = null;

        // Initialize the upsert object
        let upsertableCustomer = null;

        // If the location exists, apply it to the customer variables
        if (formLocation?.id) {
          // Apply the location ID to the customer variables
          customerVars.location_id = formLocation?.id;

          // Build the upsertable customer object
          upsertableCustomer = { ...customerVars };
        }

        // If the location form is empty, do nothing
        else if (!formLocation) {
          // Build the upsertable customer object
          upsertableCustomer = { ...customerVars };
        }

        // If the location doesnt yet exist, build a new location object
        else if (!formLocation?.id) {
          // Build the location object
          locationVars = {
            address: formLocation?.address || null,
            customer_id: formLocation?.customer_id || null,
            // email: formEmail || null,
            favorite: true,
            latitude: formLocation?.latitude || null,
            longitude: formLocation?.longitude || null,
            name: formLocation?.name || null,
            // phone: getCleansedPhoneNumber(formPhone) || null,
            place_id: formLocation?.place_id || null,
            type: `customer`,
          };

          // Find the region ID and apply it to the location
          const foundRegionRes = await _sdk?.regions?.getByCoords([locationVars?.longitude, locationVars?.latitude]);
          const foundRegionId =
            foundRegionRes?.data && foundRegionRes?.data?.length ? foundRegionRes?.data?.[0]?.id : null;
          locationVars.region_id = foundRegionId;

          // Build the individual address pieces and apply them to the location
          const locationPieces = await getAddressComponents(locationVars?.address);
          locationVars = {
            ...locationVars,
            address_line_one: locationPieces?.address_line_one || null,
            address_line_two: locationPieces?.address_line_two || null,
            city: locationPieces?.city || null,
            state: locationPieces?.state || null,
            timezone: locationPieces?.timezone || null,
            zip_code: locationPieces?.zip_code || null,
          };

          // Build the upsertable customer object with the location
          upsertableCustomer = {
            ...customerVars,
            location: {
              data: locationVars,
              on_conflict: {
                constraint: `idx_25692_primary`,
                update_columns: [
                  `address`,
                  `address_line_one`,
                  `address_line_two`,
                  `city`,
                  `customer_id`,
                  `email`,
                  `favorite`,
                  `latitude`,
                  `longitude`,
                  `name`,
                  `nickname`,
                  `phone`,
                  `place_id`,
                  `region_id`,
                  `state`,
                  `timezone`,
                  `type`,
                  `zip_code`,
                ],
              },
            },
          };
        }

        // Upsert the customer and location together
        const insertRes = await saveCustomer({ variables: { upsertableCustomer: upsertableCustomer } });
        if (insertRes?.data?.insert_customers_one) {
          toast.success(`Successfully updated customer!`);
          setIsEditing(false);
        }
      } catch (err) {
        console.error(`Failed to update customer:`, err);
        toast.error(`Failed to update customer!`);
      }
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  return (
    <div className={cls.info}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography className={cls.headTxt}>
            {customer?.name} (#{customer?.id})
          </Typography>
        </Grid>

        {isEditing ? (
          <Grid item>
            <Tooltip placement='top' title={`Click to lock & save your changes`}>
              <Button disabled={isSaving} loading={isSaving} color='primary' onClick={() => handleSaveChanges()}>
                <Icon className={cls.btnIcon}>save</Icon>
                Save Changes
              </Button>
            </Tooltip>
          </Grid>
        ) : null}

        <Grid item>
          <Tooltip
            placement='top'
            title={isEditing ? `Click to lock & discard your changes` : `Click to unlock & edit`}
          >
            <IconButton
              style={{
                color: isEditing ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              className={cls.iconBtn}
              onClick={() => handleEdit()}
            >
              <Icon>{isEditing ? `lock_open` : `lock`}</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        {/* NAME */}
        <Grid item md={6} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Name'
              placeholder='Enter the customer name...'
              value={formName}
              onChange={handleInputChange(setFormName)}
              error={validationErrors?.formName}
              helperText={validationErrors?.formName || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      label
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Name</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  label
                </Icon>
                <Typography className={cls.valTxt}>{customer?.name || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* ORGANIZATION */}
        <Grid item md={6} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              select
              fullWidth
              label='Organization'
              placeholder='Select the organization...'
              value={formOrganizationId || 0}
              onChange={handleInputChange(setFormOrganizationId)}
              error={validationErrors?.formOrganizationId}
              helperText={validationErrors?.formOrganizationId || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      groups
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={0}>
                <em>No Organization</em>
              </MenuItem>
              {organizations?.map(org => (
                <MenuItem key={`organization-${org?.id}`} value={org?.id}>
                  {org?.name} (#{org?.id})
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Organization</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  groups
                </Icon>
                <Typography className={cls.valTxt}>
                  {customer?.organization ? `${customer?.organization?.name} (#${customer?.organization?.id})` : `-`}
                </Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* EMAIL */}
        <Grid item md={6} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              fullWidth
              multiline
              label='Email(s)'
              placeholder='Enter customer email(s), separated by commas...'
              value={formEmail}
              onChange={handleInputChange(setFormEmail)}
              error={validationErrors?.formEmail}
              helperText={validationErrors?.formEmail || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      email
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Email(s)</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  email
                </Icon>
                <Typography className={cls.valTxt}>{customer?.email || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* PHONE */}
        <Grid item md={6} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              fullWidth
              multiline
              label='Phone'
              placeholder='Enter the customer phone number...'
              value={formPhone}
              onChange={handleInputChange(setFormPhone)}
              error={validationErrors?.formPhone}
              helperText={validationErrors?.formPhone || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      phone
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Phone</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  phone
                </Icon>
                <Typography className={cls.valTxt}>{customer?.phone || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* LOCATION */}
        <Grid item xs={12}>
          {isEditing ? (
            <GoogleLocationBuilder
              customerId={customer?.id}
              // required
              variant='standard'
              defaultLocation={formLocation}
              onChange={setFormLocation}
              validationError={validationErrors?.formLocation}
            />
          ) : (
            <>
              <Typography className={cls.keyTxt}>Location</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  location_on
                </Icon>
                <Typography className={cls.valTxt}>{customer?.location?.address || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* CATEGORY */}
        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Category'
              placeholder='Select the customer category...'
              value={formCategory}
              onChange={handleInputChange(setFormCategory)}
              error={validationErrors?.formCategory}
              helperText={validationErrors?.formCategory || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={`operational`}>Operational</MenuItem>
              <MenuItem value={`concierge`}>Concierge</MenuItem>
              <MenuItem value={`house`}>House</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Category</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  category
                </Icon>
                <Typography className={cls.valTxt}>{capFirst(customer?.category) || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* STATUS */}
        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Status'
              placeholder='Select the customer status...'
              value={formStatus}
              onChange={handleInputChange(setFormStatus)}
              error={validationErrors?.formStatus}
              helperText={validationErrors?.formStatus || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      token
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={`live`}>Live</MenuItem>
              <MenuItem value={`onboarding`}>Onboarding</MenuItem>
              <MenuItem value={`deactivated`}>Deactivated</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Status</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  token
                </Icon>
                <Typography className={cls.valTxt}>{capFirst(customer?.status) || `-`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>

        {/* ACTIVE */}
        <Grid item md={4} xs={12}>
          {isEditing ? (
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Active?'
              placeholder='Select the customer active flag...'
              value={formActive || 0}
              onChange={handleInputChange(setFormActive)}
              error={validationErrors?.formActive}
              helperText={validationErrors?.formActive || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      toggle_on
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </TextField>
          ) : (
            <>
              <Typography className={cls.keyTxt}>Active?</Typography>
              <Spacer size='xs' />
              <div className={cls.valBox}>
                <Icon className={cls.valIcon} color='disabled' fontSize='small'>
                  toggle_on
                </Icon>
                <Typography className={cls.valTxt}>{customer?.active ? `Yes` : `No`}</Typography>
              </div>
              <div className={cls.line} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  info: {
    padding: theme.spacing(2),
  },

  headTxt: {
    fontSize: 24,
    fontWeight: 600,
  },

  keyTxt: {
    lineHeight: '14px',
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  valBox: {
    display: `flex`,
    alignItems: `center`,
  },
  valIcon: {
    marginRight: 8,
  },
  valTxt: {
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    lineHeight: '20px',
    fontSize: 16,
    fontWeight: 400,
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },

  iconBtn: {
    width: 34,
    height: 34,
  },

  line: {
    width: `100%`,
    height: 0,
    borderBottom: theme.border[1],
    borderBottomStyle: 'dashed',
    marginTop: 5,
  },
}));

//////////////////////// STYLES ////////////////////////

const SAVE_CUSTOMER = gql`
  mutation admin_saveCustomer($upsertableCustomer: customers_insert_input!) {
    insert_customers_one(
      object: $upsertableCustomer
      on_conflict: {
        constraint: idx_21595_primary
        update_columns: [active, address, category, email, location_id, name, organization_id, phone, status]
      }
    ) {
      id
      active
      address
      category
      email
      location_id
      name
      organization_id
      phone
      status
      location {
        id
        address
        address_line_one
        address_line_two
        city
        customer_id
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        place_id
        region_id
        state
        timezone
        type
        zip_code
      }
    }
  }
`;
