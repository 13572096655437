import React from 'react';
import { makeStyles, Chip, Icon } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';

export default function StatusChip({ status }) {
  const cls = useStyles();
  const { capFirst } = useTools();

  const getStatusChipClass = status => {
    switch (status?.toLowerCase()) {
      case 'live':
        return cls.statusLiveChip;
      case 'onboarding':
        return cls.statusOnboardingChip;
      case 'deactivated':
        return cls.statusDeactivatedChip;
      case 'done':
        return cls.statusDoneChip;
      case 'pending':
        return cls.statusPendingChip;
      case 'processing':
        return cls.statusProcessingChip;
      case 'error':
        return cls.statusErrorChip;
      case 'delaying':
        return cls.statusDelayingChip;
      default:
        return cls.statusUnknownChip;
    }
  };

  return (
    <Chip
      className={getStatusChipClass(status)}
      label={capFirst(status || 'unknown')}
      color='primary'
      size='small'
      icon={
        <>
          {status === 'done' && (
            <Icon className={cls.statusIcon} fontSize='small'>
              check_circle
            </Icon>
          )}
          {status === 'pending' && (
            <Icon className={cls.statusIcon} fontSize='small'>
              contrast
            </Icon>
          )}
          {status === 'error' && (
            <Icon className={cls.statusIcon} fontSize='small'>
              block
            </Icon>
          )}
          {status === 'processing' && (
            <Icon className={cls.statusIcon} fontSize='small'>
              sync
            </Icon>
          )}
          {status === 'delaying' && (
            <Icon className={cls.statusIcon} fontSize='small'>
              schedule
            </Icon>
          )}
        </>
      }
    />
  );
}

const useStyles = makeStyles(theme => ({
  statusIcon: {
    marginLeft: 2,
    marginRight: -2,
  },
  statusLiveChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  statusOnboardingChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  statusDeactivatedChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  statusDoneChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  statusPendingChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  statusProcessingChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  statusDelayingChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  statusErrorChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  statusUnknownChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
}));
