//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Tooltip, IconButton, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function IconUnfold({
  className = null,
  tooltip = null,
  tooltipPlacement = null,
  unfold = false,
  onUnfold = () => {},
  ...rest
}) {
  const cls = useStyles();

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <Tooltip placement={tooltipPlacement || 'top'} title={tooltip || `Unfold`}>
      <IconButton className={getClassNames()} onClick={onUnfold} {...rest}>
        <Icon>{unfold ? `keyboard_arrow_up` : `keyboard_arrow_down`}</Icon>
      </IconButton>
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
}));
