//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import { ModalProvider } from './providers/ModalProvider';
import { SettingsProvider } from './providers/SettingsProvider';
import { RegionsProvider } from './providers/RegionsProvider';
import { DriversProvider } from './providers/DriversProvider';
import { PlansProvider } from './providers/PlansProvider';
import { UnassignedMovesProvider } from './providers/UnassignedMovesProvider';
import { CanceledMovesProvider } from './providers/CanceledMovesProvider';
import { SchedulerProvider } from './providers/SchedulerProvider';
import { TimelineProvider } from './providers/TimelineProvider';
import { Timeline, Drawer } from './index';
import Layout from './components/Layout';

//////////////////////// COMPONENT ////////////////////////

export default function Plans(props) {
  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='Error Loading Plans' />}>
      <ModalProvider>
        <SettingsProvider>
          <RegionsProvider>
            <DriversProvider refreshSeconds={500}>
              <PlansProvider>
                <UnassignedMovesProvider>
                  <CanceledMovesProvider>
                    <SchedulerProvider>
                      <Layout>
                        <TimelineProvider refreshSeconds={5}>
                          <Timeline />
                        </TimelineProvider>
                        <Drawer />
                      </Layout>
                    </SchedulerProvider>
                  </CanceledMovesProvider>
                </UnassignedMovesProvider>
              </PlansProvider>
            </DriversProvider>
          </RegionsProvider>
        </SettingsProvider>
      </ModalProvider>
    </Sentry.ErrorBoundary>
  );
}
