//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useTools } from '../../hooks/useTools';
import { makeStyles, Grid, Typography, IconButton, Icon } from '@material-ui/core';


//////////////////////// COMPONENT ////////////////////////

function InsuranceRateBanner({ InsuranceRateId }) {
  const cls = useStyles();

  const { goToPreviousPage } = useTools();

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <IconButton className={cls.iconBtn} onClick={() => goToPreviousPage()}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </Grid>
      <Grid item xs>
        <Typography className={cls.head}>Insurance Rate Details</Typography>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    width: 40,
    height: 40,
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InsuranceRateBanner;