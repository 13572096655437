// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { gql, useMutation } from '@apollo/client';

import { makeStyles, Grid, TextField, MenuItem, Typography, IconButton, Icon } from '@material-ui/core';
import { Button, Divide, Spacer } from '@hopdrive/storybook';
import { toast } from 'react-toastify';

const log = false;

// COMPONENT ---------------------------------------------------------------- //

export default function AllowedSlas({ raterulegroupId, allSlas, slasState, onChange }) {
  const cls = useStyles();

  const [selectedSlaId, setSelectedSlaId] = React.useState(null);
  const [possibleSlas, setPossibleSlas] = React.useState(allSlas);
  const [isLoading, setIsLoading] = React.useState(false);

  const [insertSlaToRaterulegroup] = useMutation(INSERT_SLA_TO_RATERULEGROUP);
  const [deleteSlaToRaterulegroup] = useMutation(DELETE_SLA_TO_RATERULEGROUP);

  React.useEffect(() => {
    const tempSlas = allSlas.filter(sla => !slasState.find(s => s?.id === sla?.id));
    setPossibleSlas(tempSlas);
  }, [slasState]);

  const handleAddSla = async id => {
    setIsLoading(true);

    const foundSla = allSlas?.find(sla => sla?.id === id);
    if (foundSla) {
      try {
        // Create the sla_to_raterulegroup record
        const res = await insertSlaToRaterulegroup({
          variables: { slaToInsert: { sla_id: id, raterulegroup_id: raterulegroupId } },
        });
        log && console.log(`Inserted SLA to RateRuleGroup:`, res);

        // Set the new SLAs state
        if (onChange) onChange([...slasState, foundSla]);
        setSelectedSlaId(null);
      } catch (err) {
        toast.error(`Failed to add allowed SLA!`);
        console.error(`Failed to add allowed SLA:`, err);
      }
    }

    setIsLoading(false);
  };

  const handleDeleteSla = async id => {
    setIsLoading(true);

    if (window.confirm(`Are you sure you want to remove this allowed SLA from this customer's contract?`)) {
      try {
        // Delete the sla_to_raterulegroup record
        const res = await deleteSlaToRaterulegroup({ variables: { slaId: id, raterulegroupId: raterulegroupId } });
        log && console.log(`Deleted SLA to RateRuleGroup:`, res);

        // Set the new SLAs state
        const tempSlas = slasState.filter(sla => sla?.id !== id);
        if (onChange) onChange(tempSlas);
      } catch (err) {
        toast.error(`Failed to remove allowed SLA!`);
        console.error(`Failed to remove allowed SLA:`, err);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <br />

      <Divide
        tip={`View and manage the customer's allowed SLA durations. SLAs are saved upon hitting the "ADD" button.`}
      >
        Allowed SLAs
      </Divide>

      {slasState?.map(sla => (
        <React.Fragment key={`allowed-sla-${sla?.id || sla?.tempId}`}>
          <div className={cls.slaItem}>
            <div>
              <Typography className={cls.slaLabel}>
                {sla?.label} ({sla?.duration_hrs} {sla?.duration_hrs === 1 ? `hr` : `hrs`})
              </Typography>
              <Typography className={cls.slaDescription}>{sla?.description}</Typography>
            </div>

            <IconButton
              disabled={isLoading}
              className={cls.iconDelete}
              onClick={() => handleDeleteSla(sla?.id || sla?.tempId)}
            >
              <Icon>delete</Icon>
            </IconButton>
          </div>
        </React.Fragment>
      ))}

      <Spacer size='xs' />

      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            select
            fullWidth
            label='Select an SLA'
            variant='outlined'
            size='small'
            value={selectedSlaId}
            onChange={e => setSelectedSlaId(e?.target?.value)}
          >
            {possibleSlas?.map(sla => (
              <MenuItem key={`sla-${sla?.id}`} className={cls.selectItem} value={sla?.id}>
                <Typography className={cls.selectLabel}>
                  {sla?.label} ({sla?.duration_hrs} {sla?.duration_hrs === 1 ? `hr` : `hrs`})
                </Typography>
                <Typography className={cls.selectDescription}>{sla?.description}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {selectedSlaId ? (
          <Grid item>
            <Button
              disabled={isLoading}
              size='large'
              color='primary'
              onClick={() => handleAddSla(selectedSlaId)}
              style={{ height: 56 }}
            >
              Add
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  slaItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: theme.palette.background.main,
  },
  slaLabel: {
    fontSize: 16,
    fontWeight: 500,
  },
  slaDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  iconDelete: {
    padding: 8,
    color: theme.palette.error.main,
  },

  selectItem: {
    display: 'block',
  },
  selectLabel: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  selectDescription: {
    whiteSpace: 'wrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

// GRAPHQL ---------------------------------------------------------------- //

const INSERT_SLA_TO_RATERULEGROUP = gql`
  mutation insertSlaToRaterulegroup($slaToInsert: slas_to_raterulegroups_insert_input!) {
    insert_slas_to_raterulegroups_one(object: $slaToInsert) {
      id
      sla_id
      raterulegroup_id
    }
  }
`;

const DELETE_SLA_TO_RATERULEGROUP = gql`
  mutation deleteSlaToRaterulegroup($slaId: bigint!, $raterulegroupId: bigint!) {
    delete_slas_to_raterulegroups(where: { sla_id: { _eq: $slaId }, raterulegroup_id: { _eq: $raterulegroupId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
