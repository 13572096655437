//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Icon } from '@material-ui/core';
import { useTools } from '../../../hooks/useTools';
import { useRegions } from '../providers/RegionsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleDetailsPanelPendingChanges({
  title = `Pending Changes`,
  oldObject = {},
  newObject = {},
}) {
  const cls = useStyles();
  const { circularJSONStringify } = useTools();
  const { timezoneOverride } = useRegions();

  const getRenderValue = (val, key) => {
    let valRender = '';
    switch (typeof val) {
      case 'object':
        valRender = circularJSONStringify(val, 2, [
          `accessorials`,
          `customer`,
          `lane`,
          `lyftride`,
          `lyft_trigger_move`,
          `movesByLyftTriggerId`,
          `parentMove`,
          `enrichedRideBefore`,
          `enrichedRideAfter`,
          `withOverrides`,
          `prior`,
          `next`,
        ]);
        break;
      case 'boolean':
        valRender = val ? 'true' : 'false';
        break;
      case 'string':
        if (key.includes('time')) {
          valRender = dayjs(val).tz(timezoneOverride).format('h:mm A z');
        } else {
          valRender = val;
        }
        break;
      default:
        valRender = val;
        break;
    }
    return valRender;
  };

  return (
    <div className={cls.panel}>
      <Grid container>
        <Grid item xs>
          <div className={cls.titleBox}>
            <Typography className={cls.title}>{title}</Typography>
          </div>
        </Grid>
      </Grid>

      <Grid className={cls.table} container>
        {Object.keys(newObject || {}).map((key, i) => {
          let oldValueRender = getRenderValue(oldObject[key], key);
          let newValueRender = getRenderValue(newObject[key], key);
          return (
            <Grid key={key} item xs={12}>
              <div className={i % 2 ? cls.pendingBoxEven : cls.pendingBoxOdd}>
                <Grid container alignItems='center'>
                  <Grid item xs={5}>
                    <Typography className={cls.keyTxt}>{key}:&nbsp;</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={cls.oldValTxt}>{oldValueRender}</Typography>
                  </Grid>
                  <Grid item xs={1} style={{ height: 24 }}>
                    <Icon className={cls.changeArrow}>arrow_right</Icon>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={cls.newValTxt}>{newValueRender}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
        {Object.keys(newObject || {}).length === 0 && (
          <Grid item xs={12}>
            <div className={cls.flex}>
              <Typography variant='caption'>There are no changes pending</Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  titleBox: {
    padding: theme.spacing(2),
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  pendingBoxOdd: {
    position: 'relative',
    padding: theme.spacing(0, 2),
    background: theme.palette.background.light,
  },
  pendingBoxEven: {
    position: 'relative',
    padding: theme.spacing(0, 2),
    background: theme.palette.background.dark,
  },
  keyTxt: {
    lineHeight: 1,
    fontSize: 12,
  },
  oldValTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    color: theme.palette.text.secondary,
  },
  newValTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
  },
  changeArrow: {
    color: theme.palette.text.secondary,
  },
}));
