import React from 'react';
import { LinearProgress } from '@material-ui/core';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import ActiveTimeTable from './ActiveTimeTable';

export default function ActiveTimeTableWrapper({ loading, error, activeTimes }) {
  const [filteredActiveTimes, setFilteredActiveTimes] = React.useState(activeTimes || []);

  React.useEffect(() => {
    setFilteredActiveTimes(activeTimes);
  }, [activeTimes]);

  if (error) console.error(`Failed to fetch active times:`, error);
  return (
    <>
      {loading ? <LinearProgress /> : null}
      {error ? <DefaultErrorFallback message={`Failed to fetch active times`} /> : null}
      {!loading && !error && !filteredActiveTimes?.length ? (
        <DefaultEmptyFallback message={`No Active Times Found with Selected Filters`} />
      ) : !loading && !error && filteredActiveTimes?.length ? (
        <ActiveTimeTable activeTimes={filteredActiveTimes} />
      ) : null}
    </>
  );
}
