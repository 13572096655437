//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import GoogleMap from 'google-map-react';

import { makeStyles, useTheme, Grid, Icon, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import { styles } from '../../utils/googleMapOptions';

import { ManageRideLocation } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function ManageRideMap({
  pickup = {},
  delivery = {},
  attemptPickup,
  attemptDelivery,
  driverLoc,
  overrideLoc,
}) {
  const theme = useTheme();
  const cls = useStyles();

  const { capFirst } = useTools();

  const [selectedLocation, setSelectedLocation] = React.useState(null);

  // Add locations
  let newLocs = [pickup, delivery];
  if (attemptPickup) newLocs = [...newLocs, attemptPickup];
  if (attemptDelivery) newLocs = [...newLocs, attemptDelivery];
  if (driverLoc) newLocs = [...newLocs, driverLoc];
  if (overrideLoc) newLocs = [...newLocs, overrideLoc];

  // Function for getting center lat long coordinates based on locations
  let getCenterCoords = overrideLoc => {
    if (!overrideLoc && newLocs.length > 0) {
      let latSum = 0;
      let lonSum = 0;

      newLocs.forEach(loc => {
        latSum += loc.latitude || 0;
        lonSum += loc.longitude || 0;
      });

      const latAvg = latSum / newLocs.length;
      const lonAvg = lonSum / newLocs.length;
      return [latAvg, lonAvg];
    } else if (overrideLoc && overrideLoc.latitude && overrideLoc.longitude) {
      return [overrideLoc.latitude, overrideLoc.longitude];
    } else {
      return [37.5407, -77.436];
    }
  };

  const handleMarkerClick = loc => {
    if (selectedLocation && selectedLocation.id === loc.id) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(loc);
    }
  };

  const handleMarkerIcon = loc => {
    if (loc.point === `pickup`) return `place`;
    if (loc.point === `delivery`) return `add_location`;
    return `person_pin_circle`;
  };

  const handleMarkerStyle = loc => {
    let style = undefined;
    if (selectedLocation && selectedLocation.id === loc.id) style = 'active';
    return style;
  };

  const LocationMarker = ({ loc, icon, classStyle }) => {
    return (
      <div
        onClick={() => handleMarkerClick(loc)}
        className={clsx(cls.marker, {
          [cls.markerDb]: loc.type === 'db_loc',
          [cls.markerRideshare]: loc.type === 'rideshare_loc',
          [cls.markerDriver]: loc.type === 'driver_loc',
          [cls.markerOverride]: loc.type === 'override_loc',
          [cls.activeMarker]: classStyle === 'active',
        })}
      >
        {/* ICON */}
        <Icon className={cls.icon}>{icon}</Icon>

        {/* HOVER BOX */}
        <div className={cls.bubbleBox}>
          {/* TITLE */}
          {loc.type === `db_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.info.main }}>
                Planned {capFirst(loc.point)} Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}
          {loc.type === `rideshare_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.error.main }}>
                Actual {capFirst(loc.point)} Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}
          {loc.type === `driver_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.success.main }}>
                Driver Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}
          {loc.type === `override_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.warning.main }}>
                Driver Location Override
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {/* NAME */}
          {loc.name ? <Typography className={cls.bubbleNameTxt}>{loc.name}</Typography> : null}

          {/* ADDRESS */}
          {loc.address ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleAddressTxt}>{loc.address}</Typography>
            </>
          ) : null}

          {/* LAT/LON */}
          {loc.latitude && loc.longitude ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleLatLonTxt}>
                {loc.latitude}, {loc.longitude}
              </Typography>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <Typography className={cls.titleTxt}>View Locations on Map</Typography>
      <Typography className={cls.fineTxt}>
        Blue = Planned pickup location | Red = Actual pickup location | Green = Driver location | Yellow = Driver
        location override
      </Typography>

      <Spacer size='xs' />

      <div className={cls.map}>
        <GoogleMap
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          center={getCenterCoords(newLocs[0])}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
          options={{
            styles: styles,
          }}
          setOptions={{ clickableIcons: false }}
        >
          {newLocs.map(loc => {
            if (Number(loc.latitude) && Number(loc.longitude)) {
              return (
                <LocationMarker
                  key={`location-marker-${loc.id}`}
                  text={loc.type}
                  lat={loc.latitude}
                  lng={loc.longitude}
                  loc={loc}
                  icon={handleMarkerIcon(loc)}
                  classStyle={handleMarkerStyle(loc)}
                />
              );
            } else return null;
          })}
        </GoogleMap>
      </div>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ManageRideLocation location={attemptPickup || pickup} title='Pickup Location' />
        </Grid>
        <Grid item xs={6}>
          <ManageRideLocation location={attemptDelivery || delivery} title='Delivery Location' />
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  fineTxt: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  map: {
    zIndex: 1,
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 320,
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.dark,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  //Basic Marker styling used for all markers
  marker: {
    zIndex: 2,
    position: 'absolute',
    transformOrigin: '50% 85%',
    transform: 'translate(-50%, -85%)',
    color: '#fff',
    transition: '0.15s',
    cursor: 'pointer',
    '& $icon': {
      fontSize: 24,
    },
    '& $bubbleBox': {
      display: 'none',
    },
    '&:hover': {
      zIndex: 4,
      transform: 'translate(-50%, -89%)',
      '& $icon': {
        fontSize: 48,
      },
      '& $bubbleBox': {
        display: 'block',
      },
    },
  },
  markerDb: {
    color: theme.palette.info.main,
  },
  markerRideshare: {
    color: theme.palette.error.main,
  },
  markerDriver: {
    color: theme.palette.success.main,
  },
  markerOverride: {
    color: theme.palette.warning.main,
  },
  activeMarker: {
    zIndex: 5,
    transform: 'translate(-50%, -89%)',
    '& $icon': {
      fontSize: 48,
    },
    '& $bubbleBox': {
      display: 'block',
    },
  },
  icon: {
    filter: 'drop-shadow(1px 1px 1px #00000064)',
    transition: '0.15s',
  },
  bubbleBox: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: theme.spacing(7),
    minWidth: 192,
    maxWidth: 192,
    padding: theme.spacing(1),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 1px 2px #00000064',
    backgroundColor: theme.palette.background.paper,
    transition: '0.15s',
  },
  bubbleLine: {
    width: '100%',
    height: 1,
    marginTop: 4,
    marginBottom: 6,
    background: theme.palette.divider,
  },
  bubbleNameTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  bubbleAddressTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  bubbleLatLonTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));
