//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core/';

const GET_PROMOS = gql`
    query get_rules {
        businessrules(where: { active: { _eq: true }, type: { _eq: 2 } }) {
            id
            name
            description
            jsonb
            ruletype{
                id
                name
            }
        }
    }
`;

//////////////////////// COMPONENT ////////////////////////
export default function PromoSelect({
  value,
  name = 'Promo',
  onChange = () => {},
  placeholder = 'Select a promo...',
  label = 'Promo',
  required = false,
  allowAll = false,
  fullWidth = true,
  variant = 'outlined',
  margin = 'dense',
  style = {
    width: '100%',
    margin: 0,
  },
  InputProps = {
    startAdornment: (
      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
        <Icon color='disabled' fontSize='small'>
          public
        </Icon>
      </InputAdornment>
    ),
  },
}) {

  const { loading, error, data } = useQuery(GET_PROMOS);

  return (
    <TextField
      select
      defaultValue=''
      fullWidth={fullWidth}
      required={required}
      name={name}
      label={label}
      placeholder={loading ? 'Loading...' : placeholder}
      variant={variant}
      margin={margin}
      value={data && data.businessrules ? value : 0}
      onChange={onChange}
      style={style}
      InputProps={InputProps}
    >
      { allowAll ? (
        <MenuItem key={'All Promos'} value={0}>
          All Promos
        </MenuItem>
      ) : (
        <MenuItem key={'Select a Promo'} value={0}>
          <em>Select a Promo</em>
        </MenuItem>
      )}
      { loading && (
        <MenuItem key={'Loading...'} value={0}>
          Loading...
        </MenuItem>
      )}
      { error && (
        <MenuItem key={'Error fetching promos'} value={0}>
          Error fetching promos
        </MenuItem>
      )}
      { data && 
        data.businessrules && 
        data.businessrules.map(promo => (
          <MenuItem key={promo.name} value={promo.id}>
            { promo.name }
          </MenuItem>
        ))}
    </TextField>
  );
}
