//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Container } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import OrganizationDetailsOverviewMetrics from './OrganizationDetailsOverviewMetrics';
import OrganizationDetailsOverviewInfo from './OrganizationDetailsOverviewInfo';
import CustomersByOrg from '../Customers/CustomersByOrg';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetailsOverview({ organization }) {
  const cls = useStyles();

  return (
    <Container maxWidth='lg'>
      <Divide spacer tip='View top-level metrics for this organization (with comparisons to the previous 30 days).'>
        Organization Metrics
      </Divide>

      <OrganizationDetailsOverviewMetrics organization={organization} />

      <Divide
        spacer
        tip={`View and edit the top-level information of this organization. This organization was created on ${dayjs(
          organization?.created_at
        ).format(`MM/DD/YYYY`)} at ${dayjs(organization?.created_at).format(`h:mm A`)}.`}
      >
        Organization Info
      </Divide>

      <div className={cls.paper}>
        <OrganizationDetailsOverviewInfo organization={organization} />
      </div>

      <Divide spacer tip='View and add customers within this organization.'>
        Customers By Organization
      </Divide>

      <div className={cls.paper}>
        <CustomersByOrg organization={organization} />
      </div>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },
}));
