import { gql } from 'graphql-tag';

const GET_ACTIVE_CUSTOMERS = gql`
  query get_active_customers_for_multiselect {
    customers(where: { active: { _eq: 1 } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_ALL_CUSTOMERS = gql`
  query get_all_customers_for_multiselect {
    customers(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export { GET_ACTIVE_CUSTOMERS, GET_ALL_CUSTOMERS };
