import React, { createContext, useContext, useEffect } from 'react';
import { useSubscription, useLazyQuery } from '@apollo/client';
import { queries } from '../utils/queries';
import { useProviders } from '../../../hooks/useProviders';
import { useSettings } from './SettingsProvider';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { useData } from '../../../providers/DataProvider';

const CanceledMovesContext = createContext({});

let _canceledMoves = [];
let _canceledMoveIds = [];
let _state = `subscription.loading`;
let _queryRunning = false;

function CanceledMovesProvider({ children }) {
  const ctx = useData();

  const { getSubscriberProviderState } = useProviders();
  const { timelineDate, enableCanceledMovesLogs } = useSettings();

  const [canceledMoves, setCanceledMoves] = React.useState([]);
  // const [canceledMoveIds, setCanceledMoveIds] = React.useState([]);

  const { log } = new SimpleLogger({ prefix: 'CanceledMovesProvider', enabled: enableCanceledMovesLogs });

  // useEffect(() => {
  //   _queryRunning = true;
  //   runQuery();
  // }, []);

  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData,
  } = useSubscription(queries.SUBSCRIBE_TO_CANCELED_MOVES_CHANGES_BY_DATE, {
    variables: {
      start: timelineDate.startOf('day').toISOString(),
      end: timelineDate.endOf('day').toISOString(),
    },
  });

  const [runQuery, { loading: queryLoading, error: queryError, data: queryData }] = useLazyQuery(
    queries.GET_CANCELED_MOVE_DETAILS_BY_IDS,
    {
      fetchPolicy: 'network-only',
      variables: {
        moveIds: _canceledMoveIds,
      },
    }
  );

  const refetchQuery = () => {
    log(`Manually refetching query from server...`);
    _queryRunning = true;
    runQuery();
  };

  _state = getSubscriberProviderState({
    subscriptionLoading,
    subscriptionError,
    subscriptionData,
    queryLoading,
    queryError,
    queryData,
    queryRunning: _queryRunning,
  });

  const handleSubscriptionSuccess = async () => {
    const { canceledMoves: heartbeatCanceledMoves } = subscriptionData || {};
    const inboundCanceledMoveIds = heartbeatCanceledMoves.map(o => o.id);

    // Check if we found any change in the canceled move ids list
    // Also check if the subscription has returned a different number of moves than we have stored in state
    if (
      JSON.stringify(_canceledMoveIds.sort()) !== JSON.stringify(inboundCanceledMoveIds.sort()) ||
      canceledMoves.length !== _canceledMoveIds.length
    ) {
      _canceledMoveIds = inboundCanceledMoveIds || [];
      log(`📋 Subscription detected changes. Running query...`, _canceledMoveIds, inboundCanceledMoveIds);
      // _queryRunning = true;
      _state = 'query.loading';
      const moveRes = await ctx.apolloClient.query({
        query: queries.GET_CANCELED_MOVE_DETAILS_BY_IDS,
        variables: {
          start: timelineDate.startOf('day').toISOString(),
          end: timelineDate.endOf('day').toISOString(),
        },
        fetchPolicy: 'network-only',
      });
      if (moveRes.data && moveRes.data.canceledMoves) {
        _canceledMoves = Object.assign([], moveRes.data.canceledMoves);
        setCanceledMoves(_canceledMoves);
        log(`📋 Providing ${moveRes.data.canceledMoves.length} canceled move(s)`);
        moveRes.data.canceledMoves.map(m => log(`   🧽 Move ${m.id}`));
        _state = 'query.successful';
      }
      if (moveRes.errors) {
        console.error('Error Querying Unassigned Moves:', moveRes.errors[0]);
        _state = 'query.error';
      }
    } else {
      log(`📋 Subscription ran successfully but no changes in data detected`, _canceledMoveIds, inboundCanceledMoveIds);
      _state = 'query.successful';
    }
  };

  const handleQuerySuccess = () => {
    if (queryData.canceledMoves) {
      _canceledMoves = Object.assign([], queryData.canceledMoves);
      setCanceledMoves(_canceledMoves);
      log(`📋 Providing ${_canceledMoves.length} canceled move(s)`);
      _canceledMoves.map(m => log(`   🧽 Move ${m.id}`));
    }
    // _queryRunning = false;
    _state = 'query.successful';
  };

  const handleQueryError = () => {
    log('Error querying canceled moves');
    // _queryRunning = false;
    _state = 'query.error';
  };

  switch (_state) {
    case 'subscription.loading':
      log(`Subscription is still loading...`);
      break;
    case 'subscription.error':
      log(`Subscription errored out!`);
      break;
    case 'subscription.successful':
      handleSubscriptionSuccess();
      break;
    case 'query.successful':
      // handleQuerySuccess();
      log('Canceled moves query');
      break;
    case 'query.error':
      handleQueryError();
      break;
    default:
      break;
  }

  // Export state variables by adding them to context
  const context = {
    // canceledMoves: _canceledMoves,
    canceledMoves,
    state: _state,
    setCanceledMoves: m => (_canceledMoves = m),
    canceledMoveIds: _canceledMoveIds,
    canceledMovesCount: _canceledMoveIds.length,
    refetch: refetchQuery,
  };

  return <CanceledMovesContext.Provider value={context}>{children}</CanceledMovesContext.Provider>;
}

const useCanceledMoves = () => useContext(CanceledMovesContext);

export { useCanceledMoves, CanceledMovesProvider };
