//////////////////////// DEPENDENCIES ////////////////////////

import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// FUNCTIONS ////////////////////////

/** Pass in an optional timestamp and build a time object
 * @param {String} timestamp - (Optional) Timestamp string or dayjs object
 * @param {String} timezone - (Optional) Timezone override string
 * @param {Boolean} utc - (Optional) Use UTC when building the time object
 * @returns {String} - Timestamp string
 */
const buildTimeObj = (timestamp = null, timezone = null, utc = false) => {
  try {
    // Create a time object (using utc if applicable)
    let timeObj = {};
    if (timestamp) timeObj = dayjs(timestamp);
    else timeObj = dayjs();

    // Convert to UTC (if applicable)
    if (utc) timeObj = timeObj.utc();

    // Convert to timezone (if applicable)
    if (timezone && typeof timezone === `string`) timeObj = timeObj.tz(timezone);

    // Return the time object
    return timeObj;
  } catch (err) {
    console.error(`Failed to create time object:`, err);
  }

  // Fallback to null
  return null;
};

/** Get the timezone from a move based on its lane's pickup or delivery location */
const getTimezoneFromMove = (move = null) => {
  // Check for move, lane and location, use pickup over delivery
  if (getPropValue(move, `lane.pickup.timezone`)) return move.lane.pickup.timezone;
  if (getPropValue(move, `lane.delivery.timezone`)) return move.lane.delivery.timezone;

  // Otherwise return null
  return null;
};

/** Get the timezone from a region based on its timezone field */
const getTimezoneFromRegion = (region = null) => {
  // Check for region and return timezone
  if (getPropValue(region, `timezone`)) return region.timezone;

  // Otherwise return null
  return null;
};

// Export logic
export { buildTimeObj, getTimezoneFromMove, getTimezoneFromRegion };
