import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import DriverPayTableRow from './DriverPayTableRow';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayTableBody({ rows, order, orderBy, page, rowsPerPage }) {
  const [foldId, setFoldId] = React.useState(0);

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`driver-pay-index-row-${i}`}>
              <DriverPayTableRow row={row} index={i} foldId={foldId} setFoldId={setFoldId} />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}
