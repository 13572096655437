import React, { useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import gql from 'graphql-tag';
import { makeStyles, Container, Typography, Box, CardContent, Card, Grid, Tabs, Tab, Button } from '@material-ui/core';
import ReactJson from 'react-json-view';
import StatusChip from '../../components/StatusChip/StatusChip';

dayjs.extend(relativeTime);

// Helper function to check if value is a valid JSON object
const isValidJsonObject = value => {
  return value !== null && typeof value === 'object';
};

// Add helper function to check JSON size
const shouldCollapseByDefault = obj => {
  try {
    // Consider collapsing if JSON string is longer than 200 characters
    return JSON.stringify(obj).length > 200;
  } catch (e) {
    return true;
  }
};

// Add helper function to safely stringify any value
const safeStringify = value => {
  if (value === undefined) return 'undefined';
  if (value === null) return 'null';
  if (typeof value === 'function') return value.toString();
  if (typeof value === 'bigint') return value.toString();
  if (typeof value === 'symbol') return value.toString();
  if (typeof value === 'object') {
    try {
      return JSON.stringify(value, null, 2);
    } catch (err) {
      return `[Object that cannot be stringified: ${err.message}]`;
    }
  }
  return String(value);
};

export default function BatchJobDetailContent({ batchJob }) {
  const cls = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [expandedMessages, setExpandedMessages] = useState({});
  const [expandedJsons, setExpandedJsons] = useState({});

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatDateTime = dateString => {
    if (!dateString) return 'N/A';
    const date = dayjs(dateString);
    const relativeTimeStr = date.fromNow();
    const exactTimeStr = date.format('YYYY-MM-DD HH:mm:ss');
    //return `${relativeTimeStr} (${exactTimeStr})`;
    return exactTimeStr;
  };

  const InfoRow = ({ label, value }) => (
    <Box display='flex' alignItems='center' className={cls.infoRow}>
      <Typography variant='body2' className={cls.label} component='span'>
        {label}
      </Typography>
      <Box display='flex' alignItems='center' gap={1} flex={1}>
        {label === 'Status' ? (
          <StatusChip status={batchJob.status} />
        ) : (
          <Typography variant='body2' className={cls.value} component='span'>
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );

  console.log(batchJob);

  // Helper to generate unique keys for each JSON instance
  const getJsonKey = (prefix, index, subIndex = null) => {
    return `${prefix}-${index}${subIndex !== null ? `-${subIndex}` : ''}`;
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Box className={cls.metadataSection}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <InfoRow label='Job ID' value={batchJob.id} />
              <InfoRow label='Batch ID' value={batchJob.batch_id} />
              <InfoRow label='Trigger Type' value={batchJob.trigger_type} />
              <InfoRow label='Sequence' value={batchJob.sequence} />
              <InfoRow label='Status' value={batchJob.status} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoRow label='Created At' value={formatDateTime(batchJob.createdat)} />
              <InfoRow label='Created By' value={batchJob.createdby || 'N/A'} />
              <InfoRow label='Updated At' value={formatDateTime(batchJob.updatedat)} />
              <InfoRow label='Delay Key' value={batchJob.delay_key || 'N/A'} />
              <InfoRow label='Delay (ms)' value={batchJob.delay_ms || 'N/A'} />
            </Grid>
          </Grid>
        </Box>

        <Card className={cls.dataCard}>
          <Box className={cls.tabsContainer}>
            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor='primary' textColor='primary'>
              <Tab label='Logs' />
              <Tab label='Input' />
              <Tab label='Output' />
            </Tabs>
          </Box>
          <CardContent>
            {activeTab === 0 && (
              <div className={cls.cliLogsContainer}>
                {(batchJob.output?.logs || []).map((log, index) => (
                  <div key={index} className={cls.cliLogEntry}>
                    <span className={cls.cliTimestamp}>
                      {typeof log?.timestamp === 'object' ? 'Unknown time' : formatDateTime(log?.timestamp)}
                    </span>
                    <span className={cls.cliMessage}>
                      {(() => {
                        try {
                          if (log?.message === undefined) return 'undefined';
                          if (log?.message === null) return 'null';

                          if (typeof log?.message === 'object') {
                            return isValidJsonObject(log.message) ? (
                              <>
                                {shouldCollapseByDefault(log.message) && (
                                  <Button
                                    size='small'
                                    color='primary'
                                    onClick={() => {
                                      const key = getJsonKey('message', index);
                                      setExpandedJsons(prev => ({ ...prev, [key]: !prev[key] }));
                                    }}
                                    style={{ marginBottom: 8 }}
                                  >
                                    {expandedJsons[getJsonKey('message', index)] ? 'Collapse All' : 'Expand All'}
                                  </Button>
                                )}
                                <ReactJson
                                  src={log.message}
                                  theme='rjv-default'
                                  displayObjectSize={false}
                                  displayDataTypes={false}
                                  enableClipboard={true}
                                  collapsed={
                                    shouldCollapseByDefault(log.message)
                                      ? !expandedJsons[getJsonKey('message', index)]
                                      : false
                                  }
                                  style={{ height: '100%' }}
                                />
                              </>
                            ) : null;
                          }

                          const stringValue = safeStringify(log?.message);
                          return stringValue.length > 500 ? (
                            <React.Fragment>
                              <div>{expandedMessages[index] ? stringValue : stringValue.substring(0, 500) + '...'}</div>
                              <div>
                                <Button
                                  size='small'
                                  color='primary'
                                  onClick={() => setExpandedMessages(prev => ({ ...prev, [index]: !prev[index] }))}
                                >
                                  {expandedMessages[index] ? 'Show Less' : 'Read More'}
                                </Button>
                              </div>
                            </React.Fragment>
                          ) : (
                            stringValue
                          );
                        } catch (err) {
                          console.error('Error rendering log message:', err);
                          return `[Error rendering message: ${err.message}]`;
                        }
                      })()}
                    </span>
                    {log?.details && log?.details?.length > 0 && (
                      <div className={cls.cliDetails}>
                        {log?.details?.map((detail, detailIndex) => (
                          <div key={detailIndex} className={cls.cliDetailLine}>
                            {(() => {
                              try {
                                if (detail === undefined) return 'undefined';
                                if (detail === null) return 'null';

                                if (typeof detail === 'object') {
                                  return isValidJsonObject(detail) ? (
                                    <>
                                      {shouldCollapseByDefault(detail) && (
                                        <Button
                                          size='small'
                                          color='primary'
                                          onClick={() => {
                                            const key = getJsonKey('detail', index, detailIndex);
                                            setExpandedJsons(prev => ({ ...prev, [key]: !prev[key] }));
                                          }}
                                          style={{ marginBottom: 8 }}
                                        >
                                          {expandedJsons[getJsonKey('detail', index, detailIndex)]
                                            ? 'Collapse All'
                                            : 'Expand All'}
                                        </Button>
                                      )}
                                      <ReactJson
                                        src={detail}
                                        theme='rjv-default'
                                        displayObjectSize={false}
                                        displayDataTypes={false}
                                        enableClipboard={true}
                                        collapsed={
                                          shouldCollapseByDefault(detail)
                                            ? !expandedJsons[getJsonKey('detail', index, detailIndex)]
                                            : false
                                        }
                                        style={{ height: '100%' }}
                                      />
                                    </>
                                  ) : null;
                                }

                                const stringValue = safeStringify(detail);
                                return stringValue.length > 500 ? (
                                  <React.Fragment>
                                    <div>
                                      {expandedMessages[`${index}-${detailIndex}`]
                                        ? stringValue
                                        : stringValue.substring(0, 500) + '...'}
                                    </div>
                                    <div>
                                      <Button
                                        size='small'
                                        color='primary'
                                        onClick={() =>
                                          setExpandedMessages(prev => ({
                                            ...prev,
                                            [`${index}-${detailIndex}`]: !prev[`${index}-${detailIndex}`],
                                          }))
                                        }
                                      >
                                        {expandedMessages[`${index}-${detailIndex}`] ? 'Show Less' : 'Read More'}
                                      </Button>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  stringValue
                                );
                              } catch (err) {
                                console.error('Error rendering detail:', err);
                                return `[Error rendering detail: ${err.message}]`;
                              }
                            })()}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                {(!batchJob.output?.logs || batchJob.output?.logs?.length === 0) && (
                  <div className={cls.cliLogEntry}>
                    <span className={cls.cliMessage}>No logs available</span>
                  </div>
                )}
              </div>
            )}
            {activeTab === 1 && (
              <div className={cls.jsonViewer}>
                {shouldCollapseByDefault(batchJob.input) && (
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      const key = 'input';
                      setExpandedJsons(prev => ({ ...prev, [key]: !prev[key] }));
                    }}
                    style={{ marginBottom: 8 }}
                  >
                    {expandedJsons['input'] ? 'Collapse All' : 'Expand All'}
                  </Button>
                )}
                <ReactJson
                  src={isValidJsonObject(batchJob.input) ? batchJob.input : {}}
                  theme='rjv-default'
                  displayObjectSize={false}
                  displayDataTypes={false}
                  enableClipboard={true}
                  collapsed={shouldCollapseByDefault(batchJob.input) ? !expandedJsons['input'] : false}
                  style={{ height: '100%' }}
                />
              </div>
            )}
            {activeTab === 2 && (
              <div className={cls.jsonViewer}>
                {shouldCollapseByDefault(batchJob.output) && (
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      const key = 'output';
                      setExpandedJsons(prev => ({ ...prev, [key]: !prev[key] }));
                    }}
                    style={{ marginBottom: 8 }}
                  >
                    {expandedJsons['output'] ? 'Collapse All' : 'Expand All'}
                  </Button>
                )}
                <ReactJson
                  src={
                    batchJob.output && isValidJsonObject(batchJob.output) ? { ...batchJob.output, logs: undefined } : {}
                  }
                  theme='rjv-default'
                  displayObjectSize={false}
                  displayDataTypes={false}
                  enableClipboard={true}
                  collapsed={shouldCollapseByDefault(batchJob.output) ? !expandedJsons['output'] : false}
                  style={{ height: '100%' }}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    //margin: '0 auto',
  },
  cliLogsContainer: {
    //background: theme.palette.background.default,
    //padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    height: '600px',
    overflow: 'auto',
  },
  cliLogEntry: {
    fontFamily: 'monospace',
    fontSize: '0.85rem',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: '2px 8px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  cliTimestamp: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  cliMessage: {
    color: theme.palette.text.primary,
  },
  cliDetails: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  cliDetailLine: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    padding: '2px 0',
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
  },
  metadataSection: {
    marginBottom: theme.spacing(3),
  },
  dataCard: {
    backgroundColor: theme.palette.background.paper,
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  jsonViewer: {
    // background: theme.palette.background.default,
    // padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    height: '600px',
    overflow: 'auto',
  },
  label: {
    color: theme.palette.text.secondary,
    width: '100px',
    flexShrink: 0,
    fontSize: '0.875rem',
  },
  value: {
    color: theme.palette.text.primary,
    wordBreak: 'break-all',
    fontSize: '0.875rem',
  },
  infoRow: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
    },
  },
  logEntry: {
    fontFamily: 'monospace',
    fontSize: '0.85rem',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: '2px 8px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  timestamp: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  logLevel: {
    marginRight: theme.spacing(1),
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.warn': {
      color: theme.palette.warning.main,
    },
    '&.info': {
      color: theme.palette.info.main,
    },
  },
  logsContainer: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    maxHeight: '500px',
    overflow: 'auto',
  },
}));
