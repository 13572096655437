import React from 'react';

import BigButtonGroup from '../../components/BigButtonGroup';
import BigButton from '../../components/BigButton';

////////// COMPONENT //////////
export default function TypeForm(props) {
  const { type, onChange } = props;

  const handleTypeChange = val => {
    if (onChange) {
      if (val === `concierge`) onChange(`concierge`, 1);
      if (val === `loaner`) onChange(`loaner`, 2);
      if (val === `one-way`) onChange(`one-way`, 1);
      if (val === `round-trip`) onChange(`round-trip`, 2);
    }
  };

  return (
    <BigButtonGroup onChange={handleTypeChange} preselect={type}>
      <BigButton
        id='concierge'
        title={`Concierge`}
        subtitle={`Retrieve your customer's vehicle and return it to the service\xa0lot.`}
      />
      <BigButton
        id='loaner'
        title={`Concierge +\xa0Loaner`}
        subtitle={`Assign a loaner vehicle to the customer and retrieve their vehicle for\xa0service.`}
      />
      <BigButton id='one-way' title={`One-Way`} subtitle={`Move a single vehicle to a different\xa0lot.`} />
      <BigButton id='round-trip' title={`Round-Trip`} subtitle={`Swap two vehicles between\xa0lots.`} />
      {/* <BigButton id='milk-run' title={`Milk-Run`} subtitle={`Move several vehicles between a chain\xa0of\xa0lots.`} /> */}
    </BigButtonGroup>
  );
}
