// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { TimelineProvider } from './TimelineProvider';
import TimelineContent from './TimelineContent';

// COMPONENT ---------------------------------------------------------------- //

export default function Timeline({
  data = [],
  dataType = `static-plan`,
  date = dayjs().format(`YYYY-MM-DD`),
  earliestTime = dayjs().startOf(`day`).format(),
  header = null,
  headerSize = 48,
  intervalCallback = null,
  itemOffset = 4,
  latestTime = dayjs().endOf(`day`).format(),
  markers = [],
  multiplier = 4,
  refreshMs = 15000,
  rowSize = 80,
  scrollKey = `timeline-scroll-sec`,
  showMarkers = true,
  sidebarSize = 256,
  timezoneOverride = dayjs.tz.guess(),
  zoom = 1,
}) {
  return (
    <TimelineProvider
      data={data}
      dataType={dataType}
      date={date}
      earliestTime={earliestTime}
      header={header}
      headerSize={headerSize}
      intervalCallback={intervalCallback}
      itemOffset={itemOffset}
      latestTime={latestTime}
      markers={markers}
      multiplier={multiplier}
      refreshMs={refreshMs}
      rowSize={rowSize}
      scrollKey={scrollKey}
      showMarkers={showMarkers}
      sidebarSize={sidebarSize}
      timezoneOverride={timezoneOverride}
      zoom={zoom}
    >
      <TimelineContent />
    </TimelineProvider>
  );
}
