//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';
import OrganizationUsersTable from './OrganizationUsersTable';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetailsUsers({ organization }) {
  const cls = useStyles();

  const [customerIds, setCustomerIds] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);

  React.useEffect(() => {
    if (organization?.customers?.length > 0) {
      const ids = organization.customers.map(c => c.id);
      setCustomerIds(ids);
      setCustomers(organization.customers)
    }
  }, [organization]);

  return (
    <Container maxWidth='lg'>
      <Divide spacer tip='View and add customers within this organization.'>
        Users By Organization
      </Divide>

      <div className={cls.paper}>
        <OrganizationUsersTable customerIds={customerIds} setCustomerIds={setCustomerIds} customers={customers} setCustomers={setCustomers}/>
      </div>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },
}));
