//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Typography } from '@material-ui/core';

import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';
import { useTimeline } from '../providers/TimelineProvider';

//////////////////////// COMPONENT ////////////////////////

export default function TimelineAreaMarker({ hasText = true }) {
  const { getNonBreakingWord } = useTools();
  const { isTimelineDateToday } = useSettings();
  const { timezoneOverride } = useRegions();
  const { currentPosition, currentTime } = useTimeline();

  const time = getNonBreakingWord(dayjs(currentTime).tz(timezoneOverride).format(`h:mm A z`));

  // Dynamic styles
  const useStyles = makeStyles(theme => ({
    marker: {
      zIndex: 300,
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: currentPosition,
      height: '100%',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    markerLine: {
      width: 1,
      height: '100%',
      background: theme.palette.error.main,
      boxShadow: '0px 0px 4px #ffffff',
    },
    markerTxt: {
      marginTop: 6,
      marginLeft: 6,
      lineHeight: 1,
      fontSize: 12,
      color: theme.palette.error.main,
      textShadow: '0px 0px 4px #ffffff',
    },
  }));
  const cls = useStyles();

  // Check if the timelineDate is not today (dont even bother rendering it)
  if (!isTimelineDateToday) return null;
  return (
    <div className={cls.marker}>
      <div className={cls.markerLine} />
      {hasText ? <Typography className={cls.markerTxt}>{time}</Typography> : null}
    </div>
  );
}
