import React from 'react';
import { Modal } from '../../components/ModalComponents';

import AddDriverModalContent from './AddDriverModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function AddDriverModal({ open, input, onClose }) {
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return <Modal open={open}>{open ? <AddDriverModalContent input={input} onClose={handleClose} /> : null}</Modal>;
}
