//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function AttemptInfoBrief({ attempt = {}, condensed = false, rideCostEstimate = 0, calcedSurge = 0 }) {
  const cls = useStyles();
  const { getFormattedRidesharePartner, formatUSD } = useTools();

  const FullView = () => {
    return (
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ride&nbsp;Partner:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{getFormattedRidesharePartner(attempt.ride_type) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Time&nbsp;of&nbsp;Request:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {attempt.requested_at || attempt.createdat
                ? dayjs(attempt.requested_at || attempt.createdat).format(`MM/DD/YYYY hh:mm A z`)
                : `-`}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Surge&nbsp;Cost&nbsp;Percentage:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.surge_cost_percentage || `-`}</Typography>
          </div>
        </Grid>

        <div className={cls.hiddenLineBreak} />

        <Grid item sm={6} xs={12}>
          {attempt.ride_cost ? (
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{formatUSD(attempt.ride_cost) || `-`}</Typography>
            </div>
          ) : (
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Estimated&nbsp;Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{formatUSD(attempt.estimated_ride_cost) || `-`}</Typography>
            </div>
          )}
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Estimated&nbsp;Ride&nbsp;Cost&nbsp;(HopDrive):&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(rideCostEstimate) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Hopdrive&nbsp;Surge&nbsp;Charge:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(calcedSurge) || `-`}</Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  const CondensedView = () => {
    return (
      <>
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Ride&nbsp;Partner:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{getFormattedRidesharePartner(attempt.ride_type) || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        {attempt.ride_cost ? (
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(attempt.ride_cost) || `-`}</Typography>
          </div>
        ) : (
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Estimated&nbsp;Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(attempt.estimated_ride_cost) || `-`}</Typography>
          </div>
        )}
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Surge&nbsp;Cost&nbsp;Percentage:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{attempt.surge_cost_percentage || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Ride&nbsp;Cost&nbsp;Estimate:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{rideCostEstimate || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Time&nbsp;of&nbsp;Request:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>
            {attempt.requested_at || attempt.createdat
              ? dayjs(attempt.requested_at || attempt.createdat).format(`MM/DD/YYYY hh:mm A z`)
              : `-`}
          </Typography>
        </div>
      </>
    );
  };

  return condensed ? <CondensedView /> : <FullView />;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
