import React from 'react';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import {
  makeStyles,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Icon,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

import ActionsMenu from '../../components/ActionsMenu';

export default function APDetailTable({
  paycycle,
  driverPay,
  paper = false,
  actions = [],
  handleDriverChargeModalOpen,
  handleDriverIncentiveModalOpen,
}) {
  const cls = useStyles();

  const { goToMoveDetails, goToPayoutDetails, capEach, getRegionNameFromMove, formatUSD } = useTools();
  const { generateCSVFromDriverPayObject } = useDriverPay();

  const printRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `@page { size: A4 landscape; }`,
  });

  const getBorderStyle = (index, rowCount) => {
    return index === rowCount - 1 ? { borderBottom: `none` } : null;
  };

  const getTotalTime = () => {
    const dur = dayjs.duration(driverPay?.total_duration_sec, `seconds`);
    let durStr = ``;

    if (dur?.asDays() > 1) durStr += `${dur?.$d?.days} days `;
    if (dur?.asHours() > 1) durStr += `${dur?.$d?.hours} hrs `;
    if (dur?.asMinutes() > 1) durStr += `${dur?.$d?.minutes} mins `;
    if (dur?.asSeconds() > 1) durStr += `${dur?.$d?.seconds} secs`;

    return durStr;
  };

  const getChipTypeClass = appayment => {
    if (appayment?.type === `move pay`) {
      if (appayment?.move?.move_type === `drive`) return cls.chipTypeDrive;
      if (appayment?.move?.move_type === `ride`) return cls.chipTypeRide;
    }
    if (appayment?.type === `accessorial`) return cls.chipTypeAccessorial;
    return undefined;
  };

  const getChipIssueClass = status => {
    if (status === `failed` || status === `canceled`) return cls.chipIssue;
    if (status === `new`) return cls.chipNew;
    if (status === `processing`) return cls.chipProcessing;
    if (status === `successful`) return cls.chipSuccess;

    return cls.chipDefault;
  };

  if (!driverPay || (!driverPay?.appayments?.length && !driverPay?.apcharges?.length)) return null;

  const appaymentsByMove =
    driverPay?.appayments
      ?.filter(app => app?.move)
      ?.sort((a, b) => {
        if (a?.move?.pickup_time > b?.move?.pickup_time) return 1;
        if (a?.move?.pickup_time < b?.move?.pickup_time) return -1;
        return 0;
      }) || [];

  const appaymentsByNoMove =
    driverPay?.appayments
      ?.filter(app => !app?.move)
      ?.sort((a, b) => {
        if (a?.earliest_payable_date > b?.earliest_payable_date) return 1;
        if (a?.earliest_payable_date < b?.earliest_payable_date) return -1;
        return 0;
      }) || [];

  const apcharges =
    driverPay?.apcharges?.sort((a, b) => {
      if (a?.earliest_chargeable_date > b?.earliest_chargeable_date) return 1;
      if (a?.earliest_chargeable_date < b?.earliest_chargeable_date) return -1;
      return 0;
    }) || [];

  const appaymentsByMoveTotal = appaymentsByMove?.reduce((total, ap) => total + ap?.amount, 0);
  const appaymentsByNoMoveTotal = appaymentsByNoMove?.reduce((total, ap) => total + ap?.amount, 0);
  const apchargesTotal = apcharges?.reduce((total, ap) => total + ap?.amount, 0);

  const hasPayoutWithAPPaymentsByMove = () => {
    let issues = false;
    appaymentsByMove?.forEach(app => {
      if (app?.driverpayout) issues = true;
    });
    return issues;
  };

  const hasPayoutWithAPPaymentsByNoMove = () => {
    let issues = false;
    appaymentsByNoMove?.forEach(app => {
      if (app?.driverpayout) issues = true;
    });
    return issues;
  };

  const hasPayoutWithAPCharges = () => {
    let issues = false;
    apcharges?.forEach(apc => {
      if (apc?.driverpayout) issues = true;
    });
    return issues;
  };

  const hasAccessorials = () => {
    if (driverPay?.accessorial_count) return true;
    return false;
  };

  const getAPPaymentActions = (driverPay, ap) => {
    return [
      {
        label: `Go To Move Details`,
        handler: () => goToMoveDetails(ap?.move?.id),
        hide: !ap?.move?.id,
      },
      {
        label: `Edit Incentive`,
        handler: () =>
          handleDriverIncentiveModalOpen(
            driverPay?.driver_id,
            driverPay?.driver_name,
            paycycle?.tax_class,
            ap?.type,
            ap,
            true
          ),
        disabled:
          ap?.status === 'paid' ||
          paycycle?.status === 'closed' ||
          ap?.driverpayout?.status === 'canceled' ||
          ap?.driverpayout?.status === 'failed',
        hide: ap?.move?.id,
      },
    ];
  };

  const getAPChargeActions = (driverPay, ap) => {
    return [
      {
        label: `Go To Move Details`,
        handler: () => goToMoveDetails(ap?.move?.id),
        hide: !ap?.move?.id,
      },
      {
        label: `Edit Charge`,
        handler: () =>
          handleDriverChargeModalOpen(driverPay?.driver_id, driverPay?.driver_name, paycycle.tax_class, ap, true),
        disabled:
          ap?.status === 'paid' ||
          paycycle?.status === 'closed' ||
          ap?.driverpayout?.status === 'canceled' ||
          ap?.driverpayout?.status === 'failed',
      },
    ];
  };

  return (
    <div className={paper ? cls.paper : cls.root}>
      <div className={cls.flex}>
        <Button
          variant='outlined'
          size='small'
          color='secondary'
          startIcon={<Icon fontSize='small'>download</Icon>}
          onClick={() => generateCSVFromDriverPayObject(paycycle, driverPay)}
        >
          Generate CSV
        </Button>

        <Button
          size='small'
          color='secondary'
          startIcon={<Icon fontSize='small'>print</Icon>}
          onClick={() => handlePrint()}
        >
          Print
        </Button>

        <div style={{ flexGrow: 1 }} />

        {actions?.length ? <ActionsMenu tooltip={`Open Driver Actions`} actions={actions} /> : null}
      </div>

      <Spacer />

      <div id='print-component' ref={printRef}>
        <div className={cls.flexVert}>
          {appaymentsByMove?.length ? (
            <div className={cls.flexVertItem}>
              <div className={cls.title}>
                <Typography className={cls.titleTxt}>CALCULATED INFORMATION</Typography>
              </div>

              <Spacer size='xl' />

              <div className={cls.body}>
                <Grid container direction='row' justifyContent='space-between' spacing={4}>
                  <Grid item xs={3}>
                    <div className={cls.detailSection}>
                      <Typography className={cls.detailValTxt} style={{ fontWeight: 900 }}>
                        FROM
                      </Typography>

                      <Typography className={cls.detailValTxt}>1717 East Cary St, Suite 2120</Typography>

                      <Typography className={cls.detailValTxt}>Richmond, VA 23223</Typography>

                      <Typography className={cls.detailValTxt}>1-800-913-7674</Typography>

                      <Typography className={cls.detailValTxt}>
                        <a href='https://www.hopdrive.com'>hopdrive.com</a>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs>
                    <div className={cls.detailSection}>
                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Total Drive Count</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{driverPay?.drive_move_count}</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Total Ride Count</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{driverPay?.ride_move_count}</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Total Accessorial Count</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{driverPay?.accessorial_count}</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Total Miles Driven</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{driverPay?.total_miles?.toFixed(1)} mi</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Total Time Spent</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{getTotalTime()}</Typography>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs>
                    <div className={cls.detailSection}>
                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Driver</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>
                          {driverPay?.driver_name} ({driverPay?.driver_id})
                        </Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Earnings Date Range</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>
                          {dayjs(driverPay?.start_time).format('MM/DD/YYYY')} -{' '}
                          {dayjs(driverPay?.end_time).format('MM/DD/YYYY')}
                        </Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Average Per Move Rate</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{formatUSD(driverPay?.pay_per_move)}/move</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Average Per Hour Rate</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{formatUSD(driverPay?.pay_per_hour)}/hr</Typography>
                      </div>

                      <div className={cls.detail}>
                        <Typography className={cls.detailKeyTxt}>Average Per Mile Rate</Typography>

                        <div className={cls.detailLine} />

                        <Typography className={cls.detailValTxt}>{formatUSD(driverPay?.pay_per_mile)}/mi</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : null}

          {apcharges?.length ? (
            <div className={cls.flexVertItem}>
              <div className={cls.title}>
                <Typography className={cls.titleTxt}>CHARGES</Typography>

                <Typography className={cls.titleTxtNegative}>({formatUSD(apchargesTotal)})</Typography>
              </div>

              <Spacer size='xl' />

              <div className={cls.bodyTable}>
                <TableContainer>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <Tooltip title='Is the full amount paid?'>
                          <TableCell align='left'>Charged</TableCell>
                        </Tooltip>
                        <TableCell align='left'>Assigned&nbsp;Time</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        {hasPayoutWithAPCharges() ? <TableCell align='left'>Payout&nbsp;Status</TableCell> : null}
                        <TableCell align='right'>Amount</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {apcharges?.map((ap, i) => {
                        return (
                          <React.Fragment key={`apcharge-${ap?.id}-row`}>
                            <ContextMenuTrigger
                              id={`apcharge-row-${ap?.id}-cm`}
                              holdToDisplay={1000}
                              collect={() => ({ ap })}
                              disableIfShiftIsPressed={true}
                              renderTag='tr'
                              attributes={{ className: cls.row }}
                            >
                              <TableCell align='left' style={getBorderStyle(i, apcharges?.length)}>
                                {ap?.status === `paid` ? (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.paidIcon}>check_circle</Icon>
                                  </Tooltip>
                                ) : ap?.status === `waived` ? (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.waivedIcon}>check_circle</Icon>
                                  </Tooltip>
                                ) : ap?.status === `unpaid` ? (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.unpaidIcon}>remove_circle_outline</Icon>
                                  </Tooltip>
                                ) : null}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, apcharges?.length)}>
                                {dayjs(ap?.earliest_chargeable_date).format('M/D/YYYY h:mm A')}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, apcharges?.length)}>
                                <Chip
                                  className={cls.chipTypeNegative}
                                  size='small'
                                  color='primary'
                                  label={capEach(ap?.type) || `Charge`}
                                  icon={<Icon className={cls.chipIcon}>remove_circle</Icon>}
                                />
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, apcharges?.length)}>
                                {ap?.internal_notes || `-`}
                              </TableCell>

                              {hasPayoutWithAPCharges() && (
                                <TableCell align='left' style={getBorderStyle(i, apcharges?.length)}>
                                  {ap?.driverpayout ? (
                                    <Tooltip
                                      placement='top'
                                      title={
                                        ap?.driverpayout?.notes
                                          ? `Notes: ${ap?.driverpayout?.notes}`
                                          : `No payout notes`
                                      }
                                    >
                                      <Chip
                                        className={getChipIssueClass(ap?.driverpayout?.status)}
                                        size='small'
                                        label={`${capEach(ap?.driverpayout?.status)} (${ap?.driverpayout?.id})`}
                                        onClick={() => goToPayoutDetails(ap?.driverpayout?.id)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    `-`
                                  )}
                                </TableCell>
                              )}

                              <TableCell align='right' style={getBorderStyle(i, apcharges?.length)}>
                                <Typography className={cls.amountTxtNegative}>
                                  {formatUSD(-ap?.amount, { displayAccountingNegative: true })}
                                </Typography>
                              </TableCell>
                            </ContextMenuTrigger>

                            <ContextMenu id={`apcharge-row-${ap?.id}-cm`}>
                              {getAPChargeActions(driverPay, ap)?.map((action, i) => {
                                if (!action || action?.hide) return null;
                                if (!action?.handler) return <Divider key={`action-${i}`} className={cls.divider} />;
                                return (
                                  <MenuItem
                                    key={`action-${i}`}
                                    disabled={action?.disabled}
                                    onClick={() => (action?.handler ? action?.handler() : null)}
                                  >
                                    {action?.label || `Action ${i + 1}`}
                                  </MenuItem>
                                );
                              })}
                            </ContextMenu>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : null}

          {appaymentsByNoMove?.length ? (
            <div className={cls.flexVertItem}>
              <div className={cls.title}>
                <Typography className={cls.titleTxt}>INCENTIVES</Typography>

                <Typography className={cls.titleTxt}>{formatUSD(appaymentsByNoMoveTotal)}</Typography>
              </div>

              <Spacer size='xl' />

              <div className={cls.bodyTable}>
                <TableContainer>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <Tooltip title='Is the full amount paid?'>
                          <TableCell align='left'>Paid</TableCell>
                        </Tooltip>
                        <TableCell align='left'>Assigned&nbsp;Time</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        {hasPayoutWithAPPaymentsByNoMove() ? (
                          <TableCell align='left'>Payout&nbsp;Status</TableCell>
                        ) : null}
                        <TableCell align='right'>Amount</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {appaymentsByNoMove?.map((ap, i) => {
                        return (
                          <React.Fragment key={`appayment-${ap?.id}-row`}>
                            <ContextMenuTrigger
                              id={`appayment-row-${ap?.id}-cm`}
                              holdToDisplay={1000}
                              collect={() => ({ ap })}
                              disableIfShiftIsPressed={true}
                              renderTag='tr'
                              attributes={{ className: cls.row }}
                            >
                              <TableCell align='left' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                {ap?.status === `paid` ? (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.paidIcon}>check_circle</Icon>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.unpaidIcon}>remove_circle_outline</Icon>
                                  </Tooltip>
                                )}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                {dayjs(ap?.earliest_payable_date).format('M/D/YYYY h:mm A')}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                <Chip
                                  className={cls.chipTypePositive}
                                  size='small'
                                  color='primary'
                                  label={capEach(ap?.type) || `Incentive`}
                                  icon={<Icon className={cls.chipIcon}>add_circle</Icon>}
                                />
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                {ap?.notes || `-`}
                              </TableCell>

                              {hasPayoutWithAPPaymentsByNoMove() && (
                                <>
                                  <TableCell align='left' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                    {ap?.driverpayout ? (
                                      <Tooltip
                                        placement='top'
                                        title={
                                          ap?.driverpayout?.notes
                                            ? `Notes: ${ap?.driverpayout?.notes}`
                                            : `No payout notes`
                                        }
                                      >
                                        <Chip
                                          className={getChipIssueClass(ap?.driverpayout?.status)}
                                          size='small'
                                          label={`${capEach(ap?.driverpayout?.status)} (${ap?.driverpayout?.id})`}
                                          onClick={() => goToPayoutDetails(ap?.driverpayout?.id)}
                                        />
                                      </Tooltip>
                                    ) : (
                                      `-`
                                    )}
                                  </TableCell>
                                </>
                              )}

                              <TableCell align='right' style={getBorderStyle(i, appaymentsByNoMove?.length)}>
                                <Typography className={ap?.amount < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                                  {formatUSD(ap?.amount, { displayAccountingNegative: true })}
                                </Typography>
                              </TableCell>
                            </ContextMenuTrigger>

                            <ContextMenu id={`appayment-row-${ap?.id}-cm`}>
                              {getAPPaymentActions(driverPay, ap)?.map((action, i) => {
                                if (!action || action?.hide) return null;
                                if (!action?.handler) return <Divider key={`action-${i}`} className={cls.divider} />;
                                return (
                                  <MenuItem
                                    key={`action-${i}`}
                                    disabled={action?.disabled}
                                    onClick={() => (action?.handler ? action?.handler() : null)}
                                  >
                                    {action?.label || `Action ${i + 1}`}
                                  </MenuItem>
                                );
                              })}
                            </ContextMenu>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : null}

          {appaymentsByMove?.length ? (
            <div className={cls.flexVertItem}>
              <div className={cls.title}>
                <Typography className={cls.titleTxt}>PAYMENTS BY MOVES</Typography>

                <Typography className={cls.titleTxt}>{formatUSD(appaymentsByMoveTotal)}</Typography>
              </div>

              <Spacer size='xl' />

              <div className={cls.bodyTable}>
                <TableContainer>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <Tooltip title='Is the full amount paid?'>
                          <TableCell align='left'>Paid</TableCell>
                        </Tooltip>
                        <TableCell align='left'>Move&nbsp;ID</TableCell>
                        {hasAccessorials() ? (
                          <Tooltip placement='top' title='Accessorial ID'>
                            <TableCell align='left'>Acc&nbsp;ID</TableCell>
                          </Tooltip>
                        ) : null}
                        <TableCell align='left'>Pickup&nbsp;Time</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Region</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        <TableCell align='left'>Distance</TableCell>
                        {hasPayoutWithAPPaymentsByMove() ? (
                          <TableCell align='left'>Payout&nbsp;Status</TableCell>
                        ) : null}
                        <TableCell align='right'>Amount</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {appaymentsByMove?.map((ap, i) => {
                        return (
                          <React.Fragment key={`appayment-bymove-${ap?.id}-row`}>
                            <ContextMenuTrigger
                              id={`appayment-row-${ap?.id}-cm`}
                              holdToDisplay={1000}
                              collect={() => ({ ap })}
                              disableIfShiftIsPressed={true}
                              renderTag='tr'
                              attributes={{ className: cls.row }}
                            >
                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {ap?.status === `paid` ? (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.paidIcon}>check_circle</Icon>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={capEach(ap?.status)}>
                                    <Icon className={cls.unpaidIcon}>remove_circle_outline</Icon>
                                  </Tooltip>
                                )}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {ap?.move?.id || `-`}
                              </TableCell>

                              {hasAccessorials() && (
                                <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                  {ap?.accessorial?.id || `-`}
                                </TableCell>
                              )}

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {dayjs(ap?.move?.pickup_time).format('M/D/YYYY h:mm A')}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                <Chip
                                  className={getChipTypeClass(ap)}
                                  size='small'
                                  color='primary'
                                  label={
                                    ap?.type === 'move pay'
                                      ? capEach(ap?.move?.move_type)
                                      : ap?.type === 'accessorial'
                                      ? `Accessorial`
                                      : ap?.notes || `-`
                                  }
                                  icon={
                                    <Icon className={cls.chipIcon}>
                                      {ap?.type === 'move pay'
                                        ? ap?.move?.move_type === 'drive'
                                          ? 'drive_eta'
                                          : 'local_taxi'
                                        : 'monetization_on'}
                                    </Icon>
                                  }
                                />
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {getRegionNameFromMove(ap?.move)}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {ap?.type === 'move pay'
                                  ? ap?.move?.lane?.description
                                  : ap?.type === 'accessorial'
                                  ? `(${capEach(ap?.accessorial?.code)}) ${ap?.accessorial?.notes}`
                                  : ap?.notes || `-`}
                              </TableCell>

                              <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                {ap?.move?.lane?.distance_miles + ' mi'}
                              </TableCell>

                              {hasPayoutWithAPPaymentsByMove() && (
                                <>
                                  <TableCell align='left' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                    {ap?.driverpayout ? (
                                      <Tooltip
                                        placement='top'
                                        title={
                                          ap?.driverpayout?.notes
                                            ? `Notes: ${ap?.driverpayout?.notes}`
                                            : `No payout notes`
                                        }
                                      >
                                        <Chip
                                          className={getChipIssueClass(ap?.driverpayout?.status)}
                                          size='small'
                                          label={`${capEach(ap?.driverpayout?.status)} (${ap?.driverpayout?.id})`}
                                          onClick={() => goToPayoutDetails(ap?.driverpayout?.id)}
                                        />
                                      </Tooltip>
                                    ) : (
                                      `-`
                                    )}
                                  </TableCell>
                                </>
                              )}

                              <TableCell align='right' style={getBorderStyle(i, appaymentsByMove?.length)}>
                                <Typography className={ap?.amount < 0 ? cls.amountTxtNegative : cls.amountTxt}>
                                  {formatUSD(ap?.amount, { displayAccountingNegative: true })}
                                </Typography>
                              </TableCell>
                            </ContextMenuTrigger>

                            <ContextMenu id={`appayment-row-${ap?.id}-cm`}>
                              {getAPPaymentActions(driverPay, ap)?.map((action, i) => {
                                if (!action || action?.hide) return null;
                                if (!action?.handler) return <Divider key={`action-${i}`} className={cls.divider} />;
                                return (
                                  <MenuItem
                                    key={`action-${i}`}
                                    disabled={action?.disabled}
                                    onClick={() => (action?.handler ? action?.handler() : null)}
                                  >
                                    {action?.label || `Action ${i + 1}`}
                                  </MenuItem>
                                );
                              })}
                            </ContextMenu>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : null}
        </div>

        <Spacer />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2),
    background: theme.palette.background.dark,
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.text.primary}20`,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 900,
  },
  titleTxtNegative: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 900,
    color: theme.palette.error.main,
  },

  body: {
    padding: theme.spacing(0, 2),
  },
  bodyTable: {},

  row: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.1s ease-in-out',
  },

  detailSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
  },
  detailKeyTxt: {
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  detailValTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
  },
  detailLine: {
    flex: 1,
    borderTop: `1px dashed ${theme.palette.divider}`,
  },

  chipIcon: {
    fontSize: 18,
  },

  chipDefault: {
    backgroundColor: theme.palette.default.main,
    color: theme.palette.default.contrastText,
    cursor: 'pointer',
  },
  chipNew: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    cursor: 'pointer',
  },
  chipProcessing: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    cursor: 'pointer',
  },
  chipIssue: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    cursor: 'pointer',
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    cursor: 'pointer',
  },

  chipTypeDrive: {
    background: theme.palette.primary.main,
  },
  chipTypeRide: {
    background: theme.palette.auto.main,
  },
  chipTypeAccessorial: {
    background: theme.palette.utility.main,
  },
  chipTypePositive: {
    background: theme.palette.info.main,
  },
  chipTypeNegative: {
    background: theme.palette.error.main,
  },

  paidIcon: {
    display: 'block',
    color: theme.palette.success.main,
  },
  waivedIcon: {
    display: 'block',
    color: theme.palette.warning.main,
  },
  unpaidIcon: {
    display: 'block',
    color: theme.palette.text.disabled,
  },

  flex: {
    display: 'flex',
    gap: 8,
  },
  flexVert: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },

  amountTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxtNegative: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },

  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
