import { gql } from '@apollo/client';

const GET_PROMOS = gql`
    query get_promos {
        promos(where: {active: {_eq: true}}) {
            id
            customer{
                name
            }
            region{
             name
            }
            rule{
                id
                name
                description
                jsonb
            }
            expiration_date
        }
    }
`

export { GET_PROMOS };