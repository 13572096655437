//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid, TextField, InputAdornment, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

function CustomerUsersFilter({ refetch, search, onSearchChange }) {

  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>


        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// EXPORT ////////////////////////

export default CustomerUsersFilter;