// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import Toolbar from '../../components/Toolbar';

import GPFormCustomer from './GPFormCustomer';
import GPFormDate from './GPFormDate';
import GPFormDrivers from './GPFormDrivers';
import GPFormLanes from './GPFormLanes';
import GPFormTime from './GPFormTime';
import GPFormVehicle from './GPFormVehicle';
import GPFormVehicleDriver from './GPFormVehicleDriver';
// import GPFormVehicleLocation from './GPFormVehicleLocation';

// COMPONENT ---------------------------------------------------------------- //

export default function GPForm({ location }) {
  const cls = useStyles();

  const initialFormData = location?.state?.initialFormData || undefined;

  const { goToGroupPlanningTimeline } = useTools();

  const [formData, setFormData] = React.useState({
    capacity: initialFormData?.capacity || 1,
    customerId: initialFormData?.customerId || undefined,
    date: initialFormData?.date || dayjs().format(`YYYY-MM-DD`),
    driverIds: initialFormData?.driverIds || [],
    editMode: initialFormData?.editMode || false,
    endTime: initialFormData?.endTime || dayjs().add(15, `minute`).add(8, `hour`).ceil(15, `minute`).format(`HH:mm`),
    laneIds: initialFormData?.laneIds || [],
    organizationId: initialFormData?.organizationId || undefined,
    regionId: initialFormData?.regionId || undefined,
    startTime: initialFormData?.startTime || dayjs().add(15, `minute`).ceil(15, `minute`).format(`HH:mm`),
    timezone: initialFormData?.timezone || dayjs.tz.guess(),
    vehicleDeliveryLocationId: initialFormData?.vehicleDeliveryLocationId || undefined,
    vehicleDriverId: initialFormData?.vehicleDriverId || 0,
    vehicleId: initialFormData?.vehicleId || undefined,
    vehiclePickupLocationId: initialFormData?.vehiclePickupLocationId || undefined,
  });

  /** Handle building the plan */
  const handleBuildPlan = () => {
    // Validation - Date
    if (!formData?.date || formData?.date === `Invalid Date`) {
      toast.warning(`Please select a valid date for the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Customer
    if (!formData?.customerId) {
      toast.warning(`Please select a customer for the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Vehicle
    if (!formData?.vehicleId) {
      toast.warning(`Please select a chase vehicle!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Vehicle Driver
    if (!formData?.vehicleDriverId) {
      toast.warning(`Please select a driver for the chase vehicle!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Start Time
    if (!formData?.startTime || formData?.startTime === `Invalid Date`) {
      toast.warning(`Please select a valid start time for the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - End Time
    if (!formData?.endTime || formData?.endTime === `Invalid Date`) {
      toast.warning(`Please select a valid end time for the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Lanes
    if (!formData?.laneIds?.length) {
      toast.warning(`Please assign at least one lane to the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Validation - Drivers
    if (!formData?.driverIds?.length) {
      toast.warning(`Please assign at least one driver to the plan!`, { toastId: `gp-form-validation` });
      return;
    }

    // Go to group planning timeline
    goToGroupPlanningTimeline(formData);
  };

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow title='Group Planning'>
        <Grid container>
          <Grid item xs />
          <Grid item>
            <Button style={{ marginTop: -5, marginBottom: -5 }} color='primary' onClick={handleBuildPlan}>
              Build Plan
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <Container className={cls.container} maxWidth='md'>
        <Typography className={cls.sectionTitle}>Start Building Your Plan</Typography>

        <GPFormDate initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

        <div style={{ display: formData?.date ? `block` : `none` }}>
          <GPFormCustomer initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

          <GPFormVehicle initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

          <GPFormVehicleDriver initialFormData={initialFormData} formData={formData} setFormData={setFormData} />
        </div>

        <div style={{ display: formData?.vehicleId && formData?.vehicleDriverId ? `block` : `none` }}>
          <Typography className={cls.sectionTitle}>Select a Start and End Time for the Plan</Typography>

          <GPFormTime initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

          <Typography className={cls.sectionTitle}>
            Assign Lanes from Unassigned Moves ({formData?.laneIds?.length || 0}/1)
          </Typography>

          <GPFormLanes initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

          <Typography className={cls.sectionTitle}>
            Assign Drivers ({formData?.driverIds?.length || 0}/{formData?.capacity - 1})
          </Typography>

          <GPFormDrivers initialFormData={initialFormData} formData={formData} setFormData={setFormData} />

          {/* <Typography className={cls.sectionTitle}>Additional Options</Typography> */}

          {/* <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <GPFormVehicleLocation
                initialFormData={initialFormData}
                formData={formData}
                setFormData={setFormData}
                type='pickup'
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <GPFormVehicleLocation
                initialFormData={initialFormData}
                formData={formData}
                setFormData={setFormData}
                type='delivery'
              />
            </Grid>
          </Grid> */}
        </div>
      </Container>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  container: {
    width: `100%`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 20,
    fontWeight: 600,
  },
}));
