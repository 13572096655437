// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Tooltip } from '@material-ui/core';

import { capEach, getCleansedPhoneNumber } from '../../utils/GeneralUtils';

import { vanillaFunctions } from '../../hooks/useTools';
const { getFormattedCountdown, getDurationInMinutes } = vanillaFunctions;

// -------------------------------- EXPORTS -------------------------------- //

/** Concierge Tag */
export function MoveTagConciergeTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  return (
    <Tooltip
      title={
        <>
          {move?.consumer_name ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Consumer Name:</div>
              <div className={cls.ttVal}>{move?.consumer_name || `-`}</div>
            </div>
          ) : null}

          {move?.consumer_phone ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Consumer Phone:</div>
              <div className={cls.ttVal}>{getCleansedPhoneNumber(move?.consumer_phone) || `-`}</div>
            </div>
          ) : null}

          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Concierge Type:</div>
            <div className={cls.ttVal}>{move?.consumer_at_pickup ? `Pickup` : `Return`}</div>
          </div>
        </>
      }
    >
      {children}
    </Tooltip>
  );
}

/** Groupable Tag */
export function MoveTagGroupableTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  if (move?.ridegroup) {
    return (
      <Tooltip
        title={
          <>
            {move?.ridegroup?.id ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Internal Ridegroup ID:</div>
                <div className={cls.ttValBold}>#{move?.ridegroup?.id || `-`}</div>
              </div>
            ) : null}

            {move?.ridegroup?.group_id ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Shared Group ID:</div>
                <div className={cls.ttValBold}>
                  {move?.ridegroup?.group_id?.substr(move?.ridegroup?.group_id?.length - 4)?.toUpperCase() || `-`}
                </div>
              </div>
            ) : null}

            {move?.ridegroup?.status ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Status:</div>
                <div className={cls.ttVal}>{capEach(move?.ridegroup?.status) || `-`}</div>
              </div>
            ) : null}

            {move?.ridegroup?.ready_at_time ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Ready At Time:</div>
                <div className={cls.ttVal}>{dayjs(move?.ridegroup?.ready_at_time).format(`h:mm A`) || `-`}</div>
              </div>
            ) : null}
          </>
        }
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip title={<div className={cls.ttVal}>Move is potentially groupable with other moves</div>}>
      {children}
    </Tooltip>
  );
}

/** Manual Transmission Tag */
export function MoveTagManualTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  return <Tooltip title={<div className={cls.ttVal}>Manual Transmission</div>}>{children}</Tooltip>;
}

/** Notes Tag */
export function MoveTagNotesTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  return (
    <Tooltip
      title={
        <>
          {move?.move_details ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Notes/Instructions:</div>
              <div className={cls.ttVal}>{move?.move_details || `-`}</div>
            </div>
          ) : null}

          {move?.dealer_contact ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Dealer Contact:</div>
              <div className={cls.ttVal}>{move?.dealer_contact || `-`}</div>
            </div>
          ) : null}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}

/** Railyard Tag */
export function MoveTagRailyardTooltip({ children, move, debug = false }) {
  const cls = useStyles();

  return <Tooltip title={<div className={cls.ttVal}>Railyard Move</div>}>{children}</Tooltip>;
}

/** SLA Tag */
export function MoveTagSlaTooltip({ children, move, deadlineMs, debug = false }) {
  const cls = useStyles();

  const projectedEndTime = move?.deliver_by
    ? dayjs(move?.deliver_by).subtract(deadlineMs, `ms`).format(`M/D/YYYY h:mm A z`)
    : undefined;

  return (
    <Tooltip
      title={
        <>
          <div className={cls.ttFlex}>
            <div className={cls.ttKey}>Time Left After Completion:</div>
            <div className={cls.ttValBold}>{getFormattedCountdown(deadlineMs, { format: 'dhm' }) || `Unknown`}</div>
          </div>

          <hr className={cls.ttDivider} />

          <div className={cls.ttFlex}>
            <div className={cls.ttVal}>Projected End Time</div>
            <div className={cls.ttValBold}>VS </div>
            <div className={cls.ttVal}>Deadline</div>
          </div>

          <hr className={cls.ttDivider} />

          {projectedEndTime ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Projected End Time:</div>
              <div className={cls.ttVal}>
                {projectedEndTime && projectedEndTime !== `Invalid Date` ? projectedEndTime : `-`}
              </div>
            </div>
          ) : null}

          {move?.deliver_by ? (
            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Deadline:</div>
              <div className={cls.ttVal}>
                {move?.deliver_by && move?.deliver_by !== `Invalid Date`
                  ? dayjs(move?.deliver_by).format(`M/D/YYYY h:mm A z`)
                  : `-`}
              </div>
            </div>
          ) : null}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  ttRoot: {},

  ttList: {},

  ttFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: 192,
    maxWidth: 384,
    color: theme.palette.text.contrast,
  },

  ttKey: {
    marginRight: 8,
    fontWeight: 300,
  },
  ttKeyFade: {
    marginRight: 4,
    fontWeight: 300,
    color: theme.palette.text.contrastFade,
  },

  ttVal: {
    fontWeight: 500,
  },
  ttValFade: {
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
  },
  ttValBold: {
    fontWeight: 900,
  },

  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
