import React, { useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import dayjs from 'dayjs';

import Toolbar from '../../components/Toolbar';
import AccessorialReportFilter from './AccessorialReportFilter';
import AccessorialReportWrapper from './AccessorialReportWrapper';

const log = false;

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  const localStart = localStorage.getItem(`accessorial-report-start`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return dayjs.utc(dayjs().startOf(`day`).subtract(1, `week`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  const localEnd = localStorage.getItem(`accessorial-report-end`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().endOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return dayjs.utc(dayjs().endOf(`day`)).format();
};
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`accessorial-report-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};

export default function AccessorialReport() {
  const cls = useStyles();
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));

  const [code, setCode] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [customerIdArray, setCustomerIdArray] = useState([]);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`accessorial-report-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf(`day`).subtract(1, value)).format());
      setEnd(dayjs.utc(dayjs().endOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`accessorial-report-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`accessorial-report-end`, newDate);
      setEnd(newDate);
    }
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Toolbar back title='Accessorials Report' />

        <div className={cls.break} />

        <AccessorialReportFilter
          code={code}
          customerIdArray={customerIdArray}
          range={range}
          start={start}
          end={end}
          selectedRegion={selectedRegion}
          onCodeChange={setCode}
          setCustomerIdArray={setCustomerIdArray}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          onRegionChange={setSelectedRegion}
          disablePickers={disablePickers}
        />

        <div className={cls.break} />

        <AccessorialReportWrapper
          code={code}
          customerIdArray={customerIdArray || []}
          start={start}
          end={end}
          regionId={selectedRegion || null}
        />
      </Container>
    </div>
  );
}

// STYLE //////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
