// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import uuid from 'uuid';

import { makeStyles, Typography } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import MoveOutcomeReasonModal from './MoveOutcomeReasonModal';
import MoveOutcomeReviewMoveAdjustments from './MoveOutcomeReviewMoveAdjustments';
import MoveOutcomeReviewAccessorials from './MoveOutcomeReviewAccessorials';
import MoveOutcomeReviewAdditionalInfo from './MoveOutcomeReviewAdditionalInfo';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomeReview({
  move,
  outcome,
  accessorials,
  setAccessorials,
  selectedAccessorialId,
  setSelectedAccessorialId,
}) {
  const cls = useStyles();

  const [reasonModal, setReasonModal] = React.useState({ open: false, input: null });

  // When either the move or outcome change, update all accessorials
  React.useEffect(() => {
    // Create mutable copies of the accessorials
    const existingAccessorials = JSON.parse(JSON.stringify(move?.accessorials || []));
    const newAccessorials = JSON.parse(JSON.stringify(outcome?.accessorials || []));

    // Set the accessorials state
    setAccessorials([...existingAccessorials, ...newAccessorials]);

    // eslint-disable-next-line
  }, [outcome]);

  /** Handle opening the reason modal */
  const handleReasonModalOpen = (oldAccessorial, newAccessorial) => {
    // Open the reason modal
    setReasonModal({
      open: true,
      input: {
        move: move,
        old: oldAccessorial,
        new: newAccessorial,
      },
    });
  };

  /** Handle closing the reason modal */
  const handleReasonModalClose = (output = null) => {
    // Close the reason modal
    setReasonModal({
      open: false,
      input: null,
    });

    // Apply the edits
    if (output) handleApplyEditsInState(output?.old, output?.new, output?.eventlogConfig, output?.type);
  };

  /** Handle applying edits to an accessorial (Open the reason modal) */
  const handleApplyEdits = (id, code, cost, arAmount, apAmount, notes) => {
    // Find the accessorial
    const foundAccessorial = accessorials?.find(accessorial => accessorial?.id === id || accessorial?.tempId === id);

    // Initialize the edited accessorial
    const editedAccessorial = {
      ...foundAccessorial,
      ap_amount: apAmount,
      ar_amount: arAmount,
      code: code,
      cost: cost,
      notes: notes,
    };

    // Open the reason modal and pass in the old and new accessorials
    handleReasonModalOpen(foundAccessorial, editedAccessorial);
  };

  /** Handle applying edits to an accessorial (Apply the actual edits to state) */
  const handleApplyEditsInState = (oldAccessorial, newAccessorial, eventlogConfig, type) => {
    // Initialize the edited accessorial
    let editedAccessorial = {};

    // Check for add/edit type and edit the accessorial
    if ((type === `add` || type === `edit`) && newAccessorial) {
      editedAccessorial = {
        ...oldAccessorial,
        ap_amount: newAccessorial?.ap_amount,
        ar_amount: newAccessorial?.ar_amount,
        code: newAccessorial?.code,
        cost: newAccessorial?.cost,
        eventlogConfig: eventlogConfig,
        isModified: true,
        notes: newAccessorial?.notes,
      };
    }

    // Check for delete type and edit the accessorial
    if (type === `delete`) {
      editedAccessorial = {
        ...oldAccessorial,
        eventlogConfig: eventlogConfig,
        isDeleted: true,
      };
    }

    // Set the accessorials state
    setAccessorials(prevAccessorials => {
      const newAccessorials = [...prevAccessorials];
      const index = newAccessorials?.findIndex(accessorial => {
        // NOTE: We are checking if these IDs exist first, otherwise undefined will equal undefined and return true
        if (oldAccessorial?.id) return accessorial?.id === oldAccessorial?.id;
        if (oldAccessorial?.tempId) return accessorial?.tempId === oldAccessorial?.tempId;
        return false;
      });
      newAccessorials[index] = editedAccessorial;
      return newAccessorials;
    });

    // Reset the selected id
    setSelectedAccessorialId(null);
  };

  /** Handle discarding edits to an accessorial */
  const handleDiscardEdits = (code, notes) => {
    // If there is no code or notes, remove the accessorial from the state
    if (!code && !notes) {
      setAccessorials(prevAccessorials => {
        return prevAccessorials?.filter(
          accessorial => accessorial?.id !== selectedAccessorialId && accessorial?.tempId !== selectedAccessorialId
        );
      });
    }

    // Reset the selected id
    setSelectedAccessorialId(null);
  };

  /** Handle editing an accessorial */
  const handleEditAccessorial = id => {
    if (selectedAccessorialId) {
      if (window.confirm(`You are currently editing another accessorial. Would you like to discard your changes?`)) {
        setSelectedAccessorialId(id);
      }
    } else {
      setSelectedAccessorialId(id);
    }
  };

  /** Handle marking an accessorial for deletion */
  const handleDeleteAccessorial = async id => {
    // Find the accessorial
    const foundAccessorial = accessorials?.find(accessorial => accessorial?.id === id || accessorial?.tempId === id);

    // Open the reason modal and pass in the old and new accessorials
    handleReasonModalOpen(foundAccessorial, null);
  };

  /** Handle restoring an accessorial from deletion */
  const handleRestoreAccessorial = async id => {
    // Find the accessorial
    const foundAccessorial = accessorials?.find(accessorial => accessorial?.id === id || accessorial?.tempId === id);

    // Initialize the edited accessorial
    const editedAccessorial = {
      ...foundAccessorial,
      isDeleted: false,
    };

    // Set the accessorials state
    setAccessorials(prevAccessorials => {
      const newAccessorials = [...prevAccessorials];
      const index = newAccessorials?.findIndex(accessorial => accessorial?.id === id || accessorial?.tempId === id);
      newAccessorials[index] = editedAccessorial;
      return newAccessorials;
    });
  };

  /** Handle adding a new accessorial */
  const handleAddAccessorial = () => {
    // Create a new accessorial
    const newAccessorial = {
      ap_amount: 0,
      ar_amount: 0,
      code: ``,
      cost: 0,
      move_id: move?.id,
      notes: ``,
      tempId: uuid(),
    };

    // Set the accessorials state
    setAccessorials(prevAccessorials => {
      return [...prevAccessorials, newAccessorial];
    });

    // Set the selected id
    setSelectedAccessorialId(newAccessorial?.tempId);
  };

  return (
    <>
      <MoveOutcomeReasonModal open={reasonModal.open} input={reasonModal.input} onClose={handleReasonModalClose} />

      <div className={cls.title}>
        <Typography className={cls.titleTxt}>Review</Typography>
      </div>

      <Spacer size='xs' />

      <div className={cls.subtitle}>
        <Typography className={cls.subtitleTxt}>
          Based on the information provided, our system generated this outcome for the move. By clicking submit, this
          outcome will be applied and executed on the move. If the system did not generate the outcome you expected,
          please adjust the outcome and give a reason for each adjustment.
        </Typography>
      </div>

      <Spacer size='xl' />

      {outcome ? (
        <>
          {/* MOVE ADJUSTMENTS */}

          <div className={cls.paper}>
            <MoveOutcomeReviewMoveAdjustments outcome={outcome} />
          </div>

          <Spacer />

          {/* ACCESSORIALS */}

          <div className={cls.paper}>
            <MoveOutcomeReviewAccessorials
              accessorials={accessorials}
              selectedAccessorialId={selectedAccessorialId}
              onApplyEdits={handleApplyEdits}
              onDiscardEdits={handleDiscardEdits}
              onEditAccessorial={handleEditAccessorial}
              onDeleteAccessorial={handleDeleteAccessorial}
              onRestoreAccessorial={handleRestoreAccessorial}
              onAddAccessorial={handleAddAccessorial}
            />
          </div>

          <Spacer size='xl' />
        </>
      ) : (
        <div className={cls.loading}>
          <Loading />
        </div>
      )}

      {/* ADDITIONAL INFO */}

      <div className={cls.title}>
        <Typography className={cls.titleTxt}>Additional Info</Typography>
      </div>

      <Spacer size='xs' />

      <div className={cls.subtitle}>
        <Typography className={cls.subtitleTxt}>
          Here is some additional information about the current state of the move to help you determine how to adjust
          the outcome.
        </Typography>
      </div>

      <Spacer size='xl' />

      <MoveOutcomeReviewAdditionalInfo move={move} />

      <Spacer size='xl' />
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  title: {
    display: 'block',
  },
  titleTxt: {
    display: 'inline',
    fontSize: 21,
    fontWeight: 600,
  },

  subtitle: {
    display: 'block',
  },
  subtitleTxt: {
    display: 'inline',
    fontSize: 16,
    fontWeight: 400,
  },

  paper: {
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  loading: {
    position: 'relative',
    padding: theme.spacing(4),
  },
}));
