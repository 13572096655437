import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, Icon, InputBase } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useDriverDetails } from './DriverDetailsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function DriverInfoVehicle({ driver = {} }) {
  const cls = useStyles();
  const ctx = useDriverDetails();

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  return (
    <div className={cls.paper}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <Typography className={cls.headTxt}>Vehicle Info</Typography>
        </Grid>

        <Grid item>
          <Tooltip placement='top' title='Vehicle that the driver owns. Used to keep track of the vehicle.'>
            <Icon className={cls.headIcon}>help</Icon>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle Year:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleYear}
                onChange={handleInputChange(ctx.setVehicleYear)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_year || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle Make:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleMake}
                onChange={handleInputChange(ctx.setVehicleMake)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_make || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle Model:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleModel}
                onChange={handleInputChange(ctx.setVehicleModel)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_model || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle Color:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleColor}
                onChange={handleInputChange(ctx.setVehicleColor)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_color || `-`}</Typography>
            )}
          </div>
        </Grid>

        <div className={cls.hiddenBreak} />

        <Grid item md={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle VIN:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleVin}
                onChange={handleInputChange(ctx.setVehicleVin)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_vin || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>License Plate:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleLicensePlate}
                onChange={handleInputChange(ctx.setVehicleLicensePlate)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_license_plate || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>License Plate State:</Typography>
            {ctx.editMode ? (
              <InputBase
                value={ctx.vehicleLicensePlateState}
                onChange={handleInputChange(ctx.setVehicleLicensePlateState)}
                className={cls.nakedValTxt}
                inputProps={{ className: cls.nakedValInput }}
              />
            ) : (
              <Typography className={cls.valTxt}>{driver.vehicle_license_plate_state || `-`}</Typography>
            )}
          </div>

          <div className={cls.lineBreak} />

          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Insurance Provider:</Typography>
            <Typography className={cls.valTxt}>{driver.insurance_provider || `-`}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headIcon: {
    fontSize: 21,
    color: theme.palette.text.secondary,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    marginRight: 8,
    lineHeight: 1.25,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: 17,
    maxHeight: 17,
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
