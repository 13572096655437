// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';

import { usePlansGroup } from './PlansGroupProvider';

import StaticDriveTile from '../../components/MoveComponents/StaticDriveTile';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupMoveItem({ move }) {
  const { selectedMoveIds, handleSelectMoveId } = usePlansGroup();

  return (
    <StaticDriveTile
      move={move}
      isSelectable
      isSelected={selectedMoveIds?.includes(move?.id)}
      onSelected={handleSelectMoveId}
    />
  );
}
