// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Icon } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../Fallbacks';
import { PageHeader, PageAction, PageContent } from '../PageComponents';

import { useWorkflow } from '../WorkflowComponents/WorkflowProvider';
import { useMoveOutcome } from './MoveOutcomeProvider';
import MoveOutcomeContent from './MoveOutcomeContent';

const log = false;

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomePage({ handleClose, input }) {
  const cls = useStyles();

  const { actions } = useWorkflow();
  const { fallbackMove, setMove, isLoading, isSaving, setIsSaving, getActionHandlerCallback, GET_MOVE_OUTCOME_FORM } =
    useMoveOutcome();

  // When a change is detected on the input, rebuild the move
  React.useEffect(() => {
    if (input && input.move) {
      setMove(input.move);
    } else {
      setMove(fallbackMove);
    }
    // eslint-disable-next-line
  }, [input]);

  // Set the title
  const titles = [`Move #${input?.move?.id}`, `Move Outcome`];

  // Handle the action clicked
  const handleAction = async action => {
    if (action?.label === `Submit`) {
      setIsSaving(true);
      const res = await action?.handler(getActionHandlerCallback(action));
      console.log(`RES:`, res);
      setIsSaving(false);
      if (res) handleClose();
    } else {
      await action?.handler(getActionHandlerCallback(action));
    }
  };

  // QUERY //
  const { loading, error, data } = useQuery(GET_MOVE_OUTCOME_FORM, { variables: { formType: `move-outcome` } });

  // LOADING STATE //
  if (loading) {
    return (
      <>
        <PageHeader titles={titles} handleClose={handleClose} />

        <PageContent>
          <Loading position='fixed' />
        </PageContent>
      </>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching move outcome form:`, error);
    Sentry.captureException(error);
    return (
      <>
        <PageHeader titles={titles} handleClose={handleClose} />

        <PageContent>
          <DefaultErrorFallback paper message='ERROR FETCHING MOVE OUTCOME FORM' />
        </PageContent>
      </>
    );
  }

  // EMPTY STATE //
  if (!data || !data.workflows || !data.workflows.length > 0) {
    return (
      <>
        <PageHeader titles={titles} handleClose={handleClose} />

        <PageContent>
          <DefaultEmptyFallback paper message='NO MOVE OUTCOME FORM FOUND' />
        </PageContent>
      </>
    );
  }

  // DATA STATE //
  const workflow = data.workflows[0];
  log && console.log(`DB Workflow:`, workflow);

  return (
    <>
      <PageHeader titles={titles} handleClose={handleClose}>
        {actions && actions?.length
          ? actions?.map((action, i) => {
              if (action?.hide) return null;
              return (
                <PageAction
                  key={`page-action-${i}`}
                  className={cls.action}
                  color={action?.color || `default`}
                  variant={action?.variant || `contained`}
                  onClick={action?.handler ? () => handleAction(action) : () => {}}
                  disabled={action?.disabled || isLoading || isSaving}
                  loading={action?.loading || isSaving}
                >
                  {action?.icon ? <Icon className={cls.btnIcon}>{action?.icon}</Icon> : null}
                  {action?.label || `Action`}
                </PageAction>
              );
            })
          : null}
      </PageHeader>

      <PageContent>
        <MoveOutcomeContent workflow={workflow} move={input.move} />
      </PageContent>
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  action: {
    marginRight: theme.spacing(1),
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));
