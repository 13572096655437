import React, { useState, useEffect } from 'react';

import { makeStyles, Grid } from '@material-ui/core';

import { useQuery } from '@apollo/client';
import Loading from '../../components/Loading';
import { useData } from '../../providers/DataProvider';
import { GET_LOCATIONS, GET_LANE, GET_LOCATION } from './gql';

import { MapSidebar, MapBounds, LocationRemoveModal } from './index';
import LocationAddEditModal from '../../components/LocationAddEditModal';
import { toast } from 'react-toastify';

const log = false;

////////// COMPONENT //////////
export default function Map(props) {
  const ctx = useData();
  const cls = useStyles();

  const [sidebarState, setSidebarState] = useState(`index`);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [selectedLane, setSelectedLane] = useState({
    origin_location_id: undefined,
    destination_location_id: undefined,
  });
  const [createMode, setCreateMode] = useState(false);
  const [laneMode, setLaneMode] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [search, setSearch] = useState(``);
  const localCustomerId = parseInt(localStorage.getItem(`customerId`));
  const [customerId, setCustomerId] = useState(localCustomerId || 9);
  const [typeTags, setTypeTags] = useState([]);

  const [addEditModal, setAddEditModal] = useState({ open: false });
  const [removeModal, setRemoveModal] = useState({ open: false });

  const { loading, error, data, refetch } = useQuery(GET_LOCATIONS(customerId), {
    variables: {
      customerId: customerId,
    },
  });

  //Handle URL navigation to location or lane
  useEffect(() => {
    log && console.log('window pathname', window.location.pathname);
    if (window.location.pathname.includes(`/lanes/`)) {
      log && console.log(`Lane ID:`, Number(props.match.params.id));
      ctx.apolloClient
        .query({
          query: GET_LANE,
          variables: { id: Number(props.match.params.id) },
        })
        .then(res => {
          if (res && res.data && res.data.lanes && res.data.lanes.length > 0) {
            const resLane = res.data.lanes[0];
            log && console.log(`Lane Found:`, resLane);
            setOrigin(resLane.pickup);
            setDestination(resLane.delivery);
            setSelectedLane(resLane);
            //We have to clear saved customer from local host because if the selected lane is for another customer, it will not be visible
            localStorage.removeItem(`customerId`);
          } else {
            toast.error('Could not find lane');
          }
        })
        .catch(err => {
          console.error(err);
          toast.error('Could not find lane');
        });
    } else if (window.location.pathname.includes(`/locations/`)) {
      log && console.log(`Location ID:`, Number(props.match.params.id));
      ctx.apolloClient
        .query({
          query: GET_LOCATION,
          variables: { id: Number(props.match.params.id) },
        })
        .then(res => {
          if (res && res.data && res.data.locations && res.data.locations.length > 0) {
            const resLoc = res.data.locations[0];
            log && console.log(`Location Found:`, resLoc);
            setSelectedLocation(resLoc);
            setSidebarState(`location`);
            //We have to clear saved customer from local host because if the selected location is for another customer, it will not be visible
            localStorage.removeItem(`customerId`);
          } else {
            toast.error('Could not find lane');
          }
        })
        .catch(err => {
          console.error(err);
          toast.error('Could not find lane');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set customer id in local storage
  useEffect(() => {
    localStorage.setItem(`customerId`, customerId);
  }, [customerId]);

  //Enter Lane Mode
  useEffect(() => {
    if (origin && destination && origin.id && destination.id && !laneMode) {
      log && console.log('ENTER LANE MODE');
      handleEnterLaneMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination]);

  //Prevent setting location if it is the same as the other location
  useEffect(() => {
    if (origin && destination && origin.id && destination.id && origin.id === destination.id) {
      toast.error('Origin and Destination must be different locations');
      setOrigin(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);
  useEffect(() => {
    if (origin && destination && origin.id && destination.id && origin.id === destination.id) {
      toast.error('Origin and Destination must be different locations');
      setDestination(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  //Function for getting center lat long coordinate of a region
  let getCenterCoord = region => {
    if (region && region.geofence && region.geofence.coordinates) {
      //Note: there is an "extra" level of array the coordinates are stored in- not sure why
      let regionCoordsArr = region.geofence.coordinates[0];
      var latSum = 0;
      var longSum = 0;
      //Note: for some reason the region coordinates are orginized [longitude, latitude]
      regionCoordsArr.forEach(coordinate => {
        latSum += coordinate[1];
        longSum += coordinate[0];
      });
      let latAvg = latSum / regionCoordsArr.length;
      let longAvg = longSum / regionCoordsArr.length;
      return { lat: latAvg, lng: longAvg };
    } else {
      log && console.log('region undefined');
      return;
    }
  };

  const boundsContainsLoc = (bounds, loc) => {
    //Prevents filter from crashing out if missing params
    if (!bounds || !loc) {
      return true;
    }
    // Location is within the bounds if South < LAT < North and West < LONG < East
    if (
      bounds.west < loc.longitude &&
      bounds.east > loc.longitude &&
      bounds.south < loc.latitude &&
      bounds.north > loc.latitude
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFilteredLocations = locs => {
    let filteredResults = locs;
    //NOTE: searching overrides the mapbounds filter so user can search for locations not currently on screen
    //filter by search string
    if (search && search.length > 0) {
      filteredResults = filteredResults.filter(
        loc =>
          (loc.id && (loc.id + ``).toLocaleLowerCase().includes(search)) ||
          (loc.name && loc.name.toLocaleLowerCase().includes(search)) ||
          (loc.nickname && loc.nickname.toLocaleLowerCase().includes(search)) ||
          (loc.address && loc.address.toLocaleLowerCase().includes(search))
      );
    } else {
      //filter by map bounds coordinates
      filteredResults = filteredResults.filter(location => boundsContainsLoc(bounds, location));
    }
    //filter by tag
    if (typeTags && typeTags.length > 0) {
      filteredResults = filteredResults.filter(loc => {
        if (loc.type && typeTags.includes(loc.type)) return true;
        return false;
      });
    }
    return filteredResults;
  };

  const handleLocationAddEditModalOpen = (input = null) => {
    setAddEditModal({ open: true, input: input });
  };
  const handleLocationAddEditModalClose = (output = null) => {
    setAddEditModal({ ...addEditModal, open: false });
    if (output) {
      setSelectedLocation(output);
      setSidebarState(`location`);
      //Refetch Data so if a new location was created it will show up
      refetch();
    }
  };
  const handleLocationRemoveModalOpen = (input = null) => {
    setRemoveModal({ open: true, input: input });
  };
  const handleLocationRemoveModalClose = () => {
    setRemoveModal({ ...removeModal, open: false });
    setSelectedLocation(null);
    setSidebarState(`index`);
    refetch();
  };

  const handleEnterLaneMode = () => {
    setLaneMode(true);
    setSidebarState('lane');
  };

  if (loading) {
    return <Loading fixed />;
  }
  if (error) {
    return (
      <div className={cls.root}>
        <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
          <Grid className={cls.gridItem} item>
            <div className={cls.scrollWrap}>
              <MapSidebar
                allLocations={[]}
                locations={[]}
                getLocationsError={error}
                origin={origin}
                destination={destination}
                search={search}
                customerId={customerId}
                tags={{ type: typeTags }}
                sidebarState={sidebarState}
                selectedLocation={selectedLocation}
                selectedLane={selectedLane}
                laneMode={laneMode}
                setOrigin={setOrigin}
                setDestination={setDestination}
                setSearch={setSearch}
                setCustomerId={setCustomerId}
                setTags={{ type: setTypeTags }}
                handleLocationAddEditModalOpen={() => {}}
                handleLocationRemoveModalOpen={() => {}}
              />
            </div>
          </Grid>
          <Grid className={cls.gridItem} item xs>
            <div className={cls.scrollWrap}>
              <MapBounds
                locations={[]}
                setSelectedLocation={setSelectedLocation}
                setSidebarState={() => {}}
                setCreateMode={() => {}}
                setBounds={setBounds}
                handleLocationAddEditModalOpen={() => {}}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    // log && console.log(`Locations for Location Index:`, data.locations);
    const filteredLocations = getFilteredLocations(data.locations);
    //Testing with first location's region - will be set differently in the future
    const region = filteredLocations.length > 0 ? filteredLocations[0].region : null;
    const centerOfRegion = getCenterCoord(region);
    return (
      <div className={cls.root}>
        <LocationAddEditModal
          open={addEditModal.open}
          onClose={handleLocationAddEditModalClose}
          locationInput={addEditModal.input}
          showCustomerSelect
          customerIdDefault={customerId}
        />
        <LocationRemoveModal
          open={removeModal.open}
          onClose={handleLocationRemoveModalClose}
          locationInput={removeModal.input}
        />
        <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
          <Grid className={cls.gridItem} item>
            <div className={cls.scrollWrap}>
              <MapSidebar
                allLocations={data.locations}
                locations={filteredLocations}
                getLocationsError={error ? error : null}
                origin={origin}
                destination={destination}
                search={search}
                customerId={customerId}
                tags={{ type: typeTags }}
                sidebarState={sidebarState}
                selectedLocation={selectedLocation}
                selectedLane={selectedLane}
                laneMode={laneMode}
                setOrigin={setOrigin}
                setDestination={setDestination}
                setSearch={setSearch}
                setCustomerId={setCustomerId}
                setTags={{ type: setTypeTags }}
                setSidebarState={setSidebarState}
                setSelectedLocation={setSelectedLocation}
                setSelectedLane={setSelectedLane}
                createMode={createMode}
                setCreateMode={setCreateMode}
                setLaneMode={setLaneMode}
                handleLocationAddEditModalOpen={handleLocationAddEditModalOpen}
                handleLocationRemoveModalOpen={handleLocationRemoveModalOpen}
              />
            </div>
          </Grid>
          <Grid className={cls.gridItem} item xs>
            <div className={cls.scrollWrap}>
              <MapBounds
                locations={filteredLocations}
                selectedLane={selectedLane}
                origin={origin}
                destination={destination}
                selectedLocation={selectedLocation}
                createMode={createMode}
                laneMode={laneMode}
                center={centerOfRegion}
                setSelectedLocation={setSelectedLocation}
                setSidebarState={setSidebarState}
                setCreateMode={setCreateMode}
                setBounds={setBounds}
                handleLocationAddEditModalOpen={handleLocationAddEditModalOpen}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  gridItem: {
    position: 'relative',
  },
  scrollWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 64,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
