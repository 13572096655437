//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Typography, InputAdornment, Grid, FormControl, FormGroup, TextField, Icon } from '@material-ui/core';
import { ModalContent, ModalFooter, ModalAction } from '../ModalComponents';
import { getUserEmail } from '../../utils/authHelper';
import { toast } from 'react-toastify';
import Spacer from '../Spacer';
import gql from 'graphql-tag';
import sdk from '@hopdrive/sdk';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//////////////////////// COMPONENT ////////////////////////

/**
 * Add or remove incentives to a driver's upcoming pay cycles
 * @param {BigInteger} driverId - Requires a driver ID passed in as a prop
 * @param {Boolean} open - Pass in a boolean value of true to render the dialog
 * @param {Function} close - Function to toggle the 'open' value from true to false
 */
const DriverIncentiveModalContent = ({ open, close, input, type = 'bonus', editMode = false, refetch }) => {
  let loggedInUserEmail = getUserEmail();

  const [amount, setAmount] = React.useState('');
  const [note, setNote] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [earliestPayableDate, setEarliestPayableDate] = React.useState(new Date());
  const [availablePaycycles, setAvailablePaycycles] = React.useState([]);
  const [earliestEditableDate, setEarliestEditableDate] = React.useState(new Date());

  React.useEffect(() => {
    getEarliestEditableDate();
  }, []);

  React.useEffect(() => {
    if (editMode && input?.incentive) {
      setAmount(input?.incentive?.amount);
      setNote(input?.incentive?.notes);
      setEarliestPayableDate(new Date(input?.incentive?.earliest_payable_date));
    }
  }, [input, editMode]);

  const getEarliestEditableDate = async () => {
    try {
      const paycyclesRes = await sdk.gql.query(GET_PAY_CYCLES);
      const paycycles = paycyclesRes?.data;
      setAvailablePaycycles(paycycles);
      let earliestDateToEdit = paycycles[0]?.start_date;
      // Parse the UTC date and add one day to compensate for timezone conversion
      const tempDate = new Date(earliestDateToEdit);
      earliestDateToEdit = new Date(tempDate.getTime() + (tempDate.getTimezoneOffset() * 60000));
      setEarliestEditableDate(earliestDateToEdit);
    } catch (err) {
      console.error('Error getting paycycles', err);
      if (input?.incentive?.earliest_payable_date) {
        setEarliestEditableDate(new Date(input?.incentive?.earliest_payable_date));
      } else {
        setEarliestEditableDate(new Date());
      }
    }
  };

  const getPayCycleId = async () => {
    let paycycleId = null;
    if (availablePaycycles?.length > 0) {
      // First find which paycycle contains the earliestPayableDate
      const validPayCycle = availablePaycycles.find(cycle => {
        const startDate = new Date(cycle.start_date);
        const endDate = new Date(cycle.end_date);
        return (
          earliestPayableDate >= startDate && 
          earliestPayableDate <= endDate
        );
      });

      // Then verify this date isn't before earliestEditableDate
      if (validPayCycle && earliestPayableDate >= earliestEditableDate) {
        paycycleId = validPayCycle.id;
      }
    }
    return paycycleId;
  };

  const handleCreateIncentive = async (paycycleId) => {

    const incentive = {
      driver_id: input.driverId,
      amount: Number(amount),
      notes: note,
      type: type,
      status: 'unpaid',
      paycycle_id: paycycleId,
      earliest_payable_date: earliestPayableDate?.toISOString(),
      createdat: 'now()',
      author: loggedInUserEmail,
    };
    const response = await sdk.gql.mutation(INSERT_INCENTIVE_PAYMENT, { objects: [incentive] });
    return response;
  };

  const handleUpdateIncentive = async (incentiveId, validPaycycleId) => {
    const incentiveUpdate = {
      amount,
      notes: note,
      type,
      earliest_payable_date: earliestPayableDate?.toISOString(),
      paycycle_id: validPaycycleId,
    };
    const response = await sdk.gql.mutation(UPDATE_INCENTIVE, { 
      incentiveId,
      updates: incentiveUpdate
    });
    return response;
  };

  const handleInputChange = React.useCallback(
    setter => event => {
      setter(event.target.value);
    },
    []
  );

  const handleClose = () => {
    setAmount('');
    setNote('');
    close();
  };

  const validateInput = () => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (amount <= 0 || !regex.test(amount)) {
      toast.error('Please enter a valid monetary value');
      return false;
    }
    return true;
  };

  const validatePayCycle = async () => {
    const payCycleId = await getPayCycleId();
    if (!payCycleId) {
      toast.error('No pay cycle found for this date. Please select a date within the next 6 months.');
      return false;
    }
    return payCycleId;
  };

  const handleSave = async () => {
    setLoading(true);
    if (!validateInput()) {
      setLoading(false);
      return;
    }

    let validPaycycleId = await validatePayCycle();
    if (!validPaycycleId) {
      setLoading(false);
      return;
    }

    const response = await handleCreateIncentive(validPaycycleId);
    if (response?.data?.affected_rows && response?.data?.affected_rows > 0) {
      toast.success('Payment saved!');
    } else {
      toast.error('Failed to save incentive payment!');
    }
    setLoading(false);
    close();
  };


  const handleUpdate = async () => {
    setLoading(true);
    if (!validateInput(amount)) {
      setLoading(false);
      return;
    }
    let validPaycycleId = await validatePayCycle();
    if (!validPaycycleId) {
      setLoading(false);
      return;
    }
    const response = await handleUpdateIncentive(input.incentive.id, validPaycycleId);
    if (response?.data?.id ) {
      toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} updated!`);
      if (refetch) refetch();
    } else {
      toast.error(`Failed to update ${type}`);
    }
    setLoading(false);
    close();
  };

  const handleDeactivate = async () => {
    setLoading(true);
    const response = await sdk.gql.mutation(DEACTIVATE_INCENTIVE, {
      incentiveId: input.incentive.id,
    });
    if (response?.data?.id) {
      toast.success(`${type} removed successfully`);
      if (refetch) refetch();
    } else {
      toast.error(`Failed to remove ${type}`);
    }
    setLoading(false);
    close();
  };

  return (
    <>
      <ModalContent subtitle={`${editMode ? 'Edit' : 'Add'} a ${type} payment to the driver's upcoming pay cycle`}>
        <FormControl>
          <FormGroup>
            <Grid container spacing={4}>
              {/* Amount Section */}
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Amount
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  Enter the total amount of the {type} payment you'd like to issue to the driver.
                </Typography>
                <TextField
                  fullWidth
                  type='number'
                  variant='outlined'
                  label='Amount'
                  size='small'
                  value={amount}
                  onChange={handleInputChange(setAmount)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon color='disabled' fontSize='small'>
                          attach_money
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    step: '0.01',
                  }}
                />
              </Grid>

              {/* Note Section */}
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Notes (Optional)
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  Add an optional note about this payment. This note will be visible to the driver.
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Add note...'
                  size='small'
                  value={note}
                  onChange={handleInputChange(setNote)}
                  multiline
                />
              </Grid>

              {/* Payable Date Section */}
              <Grid item sm={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Earliest Payable Date
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  Select the earliest date the driver should be paid for this incentive.
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Payable Date"
                    inputVariant="outlined"
                    size="small"
                    value={earliestPayableDate}
                    onChange={setEarliestPayableDate}
                    fullWidth
                    minDate={earliestEditableDate}
                    format="MM/dd/yyyy"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon color="disabled" fontSize="small">
                            calendar_today
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Spacer size='lg' />
          </FormGroup>
        </FormControl>
        <Spacer size='lg' />
      </ModalContent>
      <ModalFooter>
        <ModalAction onClick={handleClose}>Cancel</ModalAction>
        {editMode && (
          <ModalAction
            loading={loading}
            color='secondary'
            onClick={handleDeactivate}
          >
            Remove {type.charAt(0).toUpperCase() + type.slice(1)}
          </ModalAction>
        )}
        <ModalAction loading={loading} disabled={amount <= 0 || loading === true} color='primary' onClick={editMode ? handleUpdate : handleSave}>
          {editMode ? 'Update' : 'Save'}
        </ModalAction>
      </ModalFooter>
    </>
  );
};

//////////////////////// GQL ////////////////////////

const GET_PAY_CYCLES = gql`
  query getPayCycles{
    paycycles(
      where: { status: { _neq: "closed" }, tax_class: { _eq: "1099" } }
      order_by: { end_date: asc }
    ) {
      id
      end_date
      start_date
    }
  }
`;

const INSERT_INCENTIVE_PAYMENT = gql`
  mutation insert_appayments($objects: [appayments_insert_input!]!) {
    insert_appayments(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_INCENTIVE = gql`
  mutation updateIncentive($incentiveId: bigint!, $updates: appayments_set_input!) {
    update_appayments_by_pk(
      pk_columns: { id: $incentiveId },
      _set: $updates
    ) {
      id
      amount
      notes
      type
    }
  }
`;

const DEACTIVATE_INCENTIVE = gql`
  mutation deactivateIncentive($incentiveId: bigint!) {
    update_appayments_by_pk(pk_columns: { id: $incentiveId }, _set: { active: 0 }) {
      id
      active
    }
  }
`;

//////////////////////// EXPORT ////////////////////////

export default DriverIncentiveModalContent;
