//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { makeStyles, Chip, Icon, Typography, Tooltip } from '@material-ui/core';
import { Table, Column } from '../../components/TableComponents';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import Toolbar from '../../components/Toolbar';
import OrganizationUsersFilter from './OrganizationUsersFilter';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationUsersTable({ customerIds, setCustomerIds, customers, setCustomers }) {
  const cls = useStyles();
  const { goToDealerUserDetails, getInitialsFromName, goToDealerUserAdd } = useTools();

  // Component state
  const [status, setStatus] = React.useState(`all`);
  const [search, setSearch] = React.useState(``);
  const [users, setUsers] = React.useState([]);
  const [customerIdArray, setCustomerIdArray] = React.useState([]);

  const { loading, error, data, refetch } = useQuery(GET_USERS_BY_ORG, {
    variables: {
      orgCustomers: customerIds,
    },
  });

  React.useEffect(() => {
    let usersArray = [];
    if (data && data.users) {
      usersArray = data.users;
    }

    //Filter out possible duplicates from allowed customers and by customer id queries
    let uniqueArray;
    if (usersArray.length > 0) {
      uniqueArray = usersArray.filter((obj, index, array) => {
        return array.findIndex(item => item.id === obj.id) === index;
      });
      setUsers(uniqueArray);
    }
  }, [data]);

  // Set filtered users based on search results
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  React.useEffect(() => {
    let newFilteredUsers = users;

    if (search && search.length > 0) {
      const searchInput = search.toLowerCase();
      newFilteredUsers = newFilteredUsers.filter(u => {
        return (
          `${u?.id}`.includes(searchInput) ||
          u?.display_name?.toLocaleLowerCase().includes(searchInput) ||
          u?.email?.toLocaleLowerCase().includes(searchInput) ||
          u?.default_role?.toLocaleLowerCase().includes(searchInput)
        );
      });
    }

    if (customerIds && customerIds.length > 0) {
      newFilteredUsers = newFilteredUsers.filter(u => {
        return customerIds.includes(u?.customer_id);
      });
    }

    setFilteredUsers(newFilteredUsers);
    // eslint-disable-next-line
  }, [users, search, customerIds]);

  // Handle add user
  const handleAddCustomerUser = () => {
    goToDealerUserAdd({ orgCustomers: customers });
  };

  // Handle clear filters
  const handleClearFilters = () => {
    setStatus(`all`);
    setSearch(``);
    setCustomerIds([]);
  };

  const getFormattedDate = date => {
    let formattedDate = dayjs(date).format('M/DD/YYYY');
    let formattedTime = dayjs(date).format('h:mm a');
    return `${formattedDate} ${formattedTime}`;
  };

  const rowActions = [
    {
      label: `Go To User Details`,
      handler: user => goToDealerUserDetails(user.id ? user.id : null),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.root}>
      <div className={cls.paper}>
        <Toolbar fullscreen title='Users' onClear={handleClearFilters} refetch={refetch}>
          <OrganizationUsersFilter
            handleAddUser={handleAddCustomerUser}
            customers={customers}
            setCustomers={setCustomers}
            customerIdArray={customerIdArray}
            setCustomerIdArray={setCustomerIdArray}
            search={search}
            onSearchChange={setSearch}
            refetch={refetch}
          />
        </Toolbar>

        {error ? (
          <Sentry.ErrorBoundary
            fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMERS TABLE' />}
          ></Sentry.ErrorBoundary>
        ) : loading ? (
          <DefaultLoadingFallback />
        ) : filteredUsers.length ? (
          <Table
            data={filteredUsers}
            onRowClick={user => goToDealerUserDetails(user.id ? user.id : null)}
            rowActions={rowActions}
            defaultOrderBy={'name'}
            defaultOrder={'asc'}
            tableAriaLabel={`users`}
            stickyHeader={true}
          >
            <Column
              name={`id`}
              value={user => user.id}
              label={`ID`}
              tooltip={`ID of the user`}
              style={{ minWidth: 20 }}
            />
            <Column
              name={`avatar_img`}
              value={user => user.display_name || null}
              renderer={user =>
                user?.user?.avatar_url || user.avatar_url ? (
                  <div className={cls.avatar}>
                    <img className={cls.avatarImg} src={user.avatar_url} alt='avatar' />
                  </div>
                ) : (
                  <div className={cls.avatar}>
                    <div className={cls.avatarImg}>
                      <Typography className={cls.initialsTxt}>
                        {getInitialsFromName({ user_name: user.display_name || null })}
                      </Typography>
                    </div>
                  </div>
                )
              }
              label={`Avatar`}
              tooltip={`Image of the user`}
            />
            <Column
              name={`name`}
              value={user => user.display_name}
              label={`Name`}
              tooltip={`Name of the user`}
              style={{ minWidth: 40 }}
            />
            <Column
              name={`email`}
              value={user => user.email}
              label={`Email`}
              tooltip={`Email of the user`}
              style={{ minWidth: 80 }}
            />
            <Column
              name={`role`}
              value={user => user.default_role}
              label={`Role`}
              tooltip={`Default role of the user`}
              style={{ minWidth: 80 }}
            />
            <Column
              name={`rooftops`}
              value={user => user?.users_usertocustomers?.length || 1}
              label={`Rooftops`}
              tooltip={`Rooftops in the organization`}
              style={{ minWidth: 100 }}
              renderer={user => (
                <Tooltip
                  title={
                    <>
                      {user?.users_usertocustomers?.length ? (
                        user?.users_usertocustomers
                          ?.filter((c, i) => i <= 10)
                          ?.map((c, i) => (
                            <>
                              {i < 10 ? (
                                <div key={`customer-tip-${c.id}`}>
                                  {c?.customer?.name} (#{c?.customer_id})
                                </div>
                              ) : (
                                <div key={`customer-tip-${c.id}`}>
                                  ...{user?.user?.users_usertocustomers?.length - 10} more
                                </div>
                              )}
                            </>
                          ))
                      ) : user?.customer ? (
                        <div key={`customer-tip-${user?.customer?.id}`}>
                          {user?.customer?.name} (#{user?.customer?.id})
                        </div>
                      ) : (
                        <div>No Rooftops</div>
                      )}
                    </>
                  }
                >
                  <Typography className={cls.rowTxt}>{user?.users_usertocustomers?.length || `1`}</Typography>
                </Tooltip>
              )}
            />
            <Column
              name={`createdat`}
              value={user => getFormattedDate(user.created_at)}
              label={`Created`}
              tooltip={`When the user was created`}
              style={{ minWidth: 80 }}
            />
            <Column
              name={`status`}
              value={user => user.active}
              renderer={user => (
                <Chip
                  variant={user.active ? 'default' : 'outlined'}
                  icon={user.active ? null : <Icon fontSize='small'>close</Icon>}
                  size='small'
                  color={user.active ? 'primary' : 'default'}
                  label={user.active ? 'Active' : 'Inactive'}
                />
              )}
              label={`Status`}
              align={`right`}
              tooltip={`Status of the user`}
              style={{ minWidth: 30 }}
            />
          </Table>
        ) : (
          <DefaultEmptyFallback message='NO USERS FOUND' />
        )}
      </div>
    </div>
  );
}

//////////////////////// GRAPHQL ////////////////////////

const GET_USERS_BY_ORG = gql`
  query get_users_by_org($orgCustomers: [bigint!]!) {
    users(
      where: {
        _or: [
          { customer_id: { _in: $orgCustomers } }
          { users_usertocustomers: { customer_id: { _in: $orgCustomers } } }
        ]
      }
    ) {
      active
      avatar_url
      created_at
      customer_id
      default_role
      display_name
      email
      id
      phone
      updated_at
    }
  }
`;

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    background: theme.palette.background.paper,
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
}));
