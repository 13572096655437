//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal, ModalHeader } from '../ModalComponents';

import DriverChargeModalContent from './DriverChargeModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function DriverChargeModal({ open, close, input, refetch, editMode = false }) {
  // Handle modal closing and optionally pass in an output
  const handleClose = (output = null) => { 
    if (close) {
      if (output) {
        close(output); 
      } else {
        close();
      }
    }
  };

  return (
    <Modal open={open}>
      {open ? (
        <>
          <ModalHeader handleClose={close}>
            {editMode ? 'Edit Charge' : 'Add Charge'}
            {input?.driverName && input?.driverId
              ? ` to ${input?.driverName} (${input?.driverId})`
              : input?.driverId
              ? ` to Driver #${input?.driverId}`
              : ``}
          </ModalHeader>

          <DriverChargeModalContent input={input} close={handleClose} refetch={refetch} editMode={editMode} />
        </>
      ) : null}
    </Modal>
  );
}
