//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../hooks/useTools';
import dayjs from 'dayjs';
//////////////////////// COMPONENT ////////////////////////

function RateRuleGroupTableRow(props) {
  const { row, index } = props;
  const move = row.move || {};

  const cls = useStyles();
  const { goToCustomerDetails } = useTools();
  
  const formatTime = time => {
    if (time && typeof time === `string`) {
      const newTime = dayjs(time).format(`MM/DD/YYYY`);
      return newTime;
    }
    return null;
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.NAME || `-`,
      align: `left`,
      onClick: () => goToCustomerDetails(row.CUSTOMER_ID),
    },
    {
      value: row.CUSTOMER_NAME || `-`,
      align: `left`,
      onClick: () => goToCustomerDetails(row.CUSTOMER_ID),
    },
    {
      value: row.STATUS || `-`,
      align: `left`,
      onClick: () => goToCustomerDetails(row.CUSTOMER_ID),
    },
    {
      value: formatTime(row.BEGIN_DATE) || `-`,
      align: `left`,
      onClick: () => goToCustomerDetails(row.CUSTOMER_ID),
    },
    {
      value: formatTime(row.END_DATE) || `-`,
      align: `right`,
      onClick: () => goToCustomerDetails(row.CUSTOMER_ID),
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${move.id}-cm`}
        source={`atr-row-${move.id}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{ className: `MuiTableRow-root ${index % 2 ? cls.rowEven : cls.rowOdd}` }}
      >
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${move.id}-col-${i}`}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </ContextMenuTrigger>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default RateRuleGroupTableRow;
