//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  Typography,
  InputAdornment,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
  Switch,
  Icon,
  MenuItem,
} from '@material-ui/core';
import { ModalContent, ModalFooter, ModalAction } from '../ModalComponents';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getUserEmail } from '../../utils/authHelper';
import { toast } from 'react-toastify';
import Spacer from '../Spacer';
import gql from 'graphql-tag';
import sdk from '@hopdrive/sdk';

//////////////////////// COMPONENT ////////////////////////

/**
 * Add or remove charges to a driver's upcoming pay cycles
 * @param {Object} input - includes driver id and tax class
 * @param {Boolean} open - Pass in a boolean value of true to render the dialog
 * @param {Function} close - Function to toggle the 'open' value from true to false
 */
const DriverChargeModalContent = ({ open, close, input, editMode = false, refetch }) => {
  let loggedInUserEmail = getUserEmail();

  React.useEffect(() => {
    if (editMode && input?.charge) {
      setAmount(input?.charge?.amount);
      setInternalNote(input?.charge?.internal_notes);
      setExternalNote(input?.charge?.external_notes);
      setPayCycleSplit(1);
      setStatus(input?.charge?.status);
    }
  }, [input, editMode]);

  const [status, setStatus] = React.useState('unpaid');
  const [amount, setAmount] = React.useState('');
  const [amountPerCycle, setAmountPerCycle] = React.useState('');
  const [earliestChargeableDate, setEarliestChargeableDate] = React.useState(new Date());
  const [internalNote, setInternalNote] = React.useState('');
  const [externalNote, setExternalNote] = React.useState('');
  const [payCycleSplit, setPayCycleSplit] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState('onboarding');
  const [showSplit, setShowSplit] = React.useState(false);
  const [availablePaycycles, setAvailablePaycycles] = React.useState([]);
  const [earliestEditableDate, setEarliestEditableDate] = React.useState(new Date());

  React.useEffect(() => {
    getEarliestEditableDate();
  }, []);


  const getEarliestEditableDate = async () => {
    try {
      const paycyclesRes = await sdk.gql.query(GET_PAY_CYCLES);
      const paycycles = paycyclesRes?.data;
      setAvailablePaycycles(paycycles);
      let earliestDateToEdit = paycycles[0]?.start_date;
      // Parse the UTC date and add one day to compensate for timezone conversion
      const tempDate = new Date(earliestDateToEdit);
      earliestDateToEdit = new Date(tempDate.getTime() + (tempDate.getTimezoneOffset() * 60000));
      setEarliestEditableDate(earliestDateToEdit);
      console.log('earliestEditableDate', earliestDateToEdit);
    } catch (err) {
      console.error('Error getting paycycles', err);
      if (input?.incentive?.earliest_payable_date) {
        setEarliestEditableDate(new Date(input?.incentive?.earliest_payable_date));
      } else {
        setEarliestEditableDate(new Date());
      }
    }
  };

  React.useEffect(() => {
    if (amount <= 0) {
      setShowSplit(false);
    } else {
      setAmountPerCycle(splitCharge(Number(amount), Number(payCycleSplit))[0].toFixed(2));
    }
  }, [amount, payCycleSplit]);

  React.useEffect(() => {
    if (!showSplit) {
      setPayCycleSplit(1);
    }
  }, [showSplit]);

  const handleStatusChange = checked => {
    setStatus(checked ? 'unpaid' : 'waived');
  };

  const handleSetShowSplit = checked => {
    setShowSplit(checked);
  };

  const handleSplitChange = setter => e => {
    const { value } = e.target;
    setter(value);
  };

  const handleInputChange = React.useCallback(
    setter => event => {
      setter(event.target.value);
    },
    []
  );

  const splitCharge = (charge, split) => {
    const chargeInCents = Math.round(charge * 100);
    const baseAmountInCents = Math.floor(chargeInCents / split);
    const remainderInCents = chargeInCents % split;
    const amounts = new Array(split).fill(baseAmountInCents / 100);

    // Distribute the remainder by adding 1 cent to the first 'remainderInCents' cycles
    for (let i = 0; i < remainderInCents; i++) {
      amounts[i] += 0.01;
    }

    return amounts;
  };

  const validateInput = (amount, amountsSplit, payCycles, chargeDate) => {
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(amount)) {
      toast.error('Please enter a valid monetary value');
      return false;
    }
    if (!editMode && (!amountsSplit || amountsSplit.length === 0)) {
      toast.error('Please enter a valid charge amount');
      return false;
    }

    if (!editMode && (!payCycles || payCycles.length === 0)) {
      toast.error('No upcoming pay cycles found for this tax class');
      return false;
    }

    if (!editMode && amountsSplit.length > payCycles.length) {
      toast.error('Please choose a lower number of pay cycles to split the charges');
      return false;
    }

    if (!editMode && payCycles && payCycles.length > 0) {
      const lastPayCycle = payCycles[payCycles.length - 1];
      if (new Date(chargeDate) > new Date(lastPayCycle.end_date)) {
        toast.error('Chargeable date must be within the next 6 months');
        return false;
      }

      // Validation for pay cycle assignment
      const chargePayCycles = payCycles.slice(0, amountsSplit.length);
      const chargeDetails = chargePayCycles.map((_, index) => {
        let chargeDateTime;
        if (index === 0) {
          // First charge uses the original date
          chargeDateTime = new Date(chargeDate);
        } else {
          // Subsequent charges add 7 days for each index
          chargeDateTime = new Date(chargeDate);
          chargeDateTime.setDate(chargeDateTime.getDate() + (7 * index));
        }
        return getPayCycleId(payCycles, chargeDateTime);
      });

      if (chargeDetails.some(paycycleId => paycycleId === null)) {
        toast.error('One or more charges would fall outside available pay cycles. Please adjust the charge date.');
        return false;
      }
    }

    return true;
  };

  const getPayCycleId = (payCycles, chargeDate) => {    
    const chargeDateObj = chargeDate instanceof Date ? chargeDate : new Date(chargeDate);
    const chargeDateStr = chargeDateObj.toISOString().split('T')[0];
    
    const payCycle = payCycles.find(cycle => {
      const startDateStr = cycle.start_date.split('T')[0];
      const endDateStr = cycle.end_date.split('T')[0];
      
      return chargeDateStr >= startDateStr && chargeDateStr <= endDateStr;
    });
    
    return payCycle ? payCycle.id : null;
  };

  const handleCreateCharges = async (payCycles, amounts) => {
    const chargePayCycles = payCycles.slice(0, amounts.length);
    const charges = chargePayCycles.map((_, index) => {
      let chargeDate;
      if (index === 0) {
        chargeDate = earliestChargeableDate.toISOString();
      } else {
        const date = new Date(earliestChargeableDate);
        date.setDate(date.getDate() + 7 * index);
        chargeDate = date.toISOString();
      }
      
      return {
        driver_id: input.driverId,
        paycycle_id: getPayCycleId(payCycles, chargeDate),
        amount: amounts[index],
        earliest_chargeable_date: chargeDate,
        status: status,
        internal_notes: internalNote,
        external_notes: externalNote,
        created_by: loggedInUserEmail,
        created_at: 'now()',
        type: type ? type : input.type ? input.type : null,
      };
    });

    const response = await sdk.gql.mutation(INSERT_CHARGES, { charges });
    return response;
  };

  const handleUpdateCharge = async chargeId => {
    const chargeUpdate = {
      amount,
      status,
      internal_notes: internalNote,
      external_notes: externalNote,
      type,
      earliest_chargeable_date: earliestChargeableDate.toISOString(),
    };
    const response = await sdk.gql.mutation(UPDATE_CHARGE, {
      chargeId,
      updates: chargeUpdate,
    });
    return response;
  };

  const handleClose = () => {
    setAmount('');
    setInternalNote('');
    setExternalNote('');
    setPayCycleSplit(1);
    setStatus('unpaid');
    setEarliestChargeableDate(new Date());
    close();
  };

  const handleSave = async () => {
    setLoading(true);
    const amounts = splitCharge(Number(amount), Number(payCycleSplit));
    if (!validateInput(amount, amounts, availablePaycycles, earliestChargeableDate)) {
      setLoading(false);
      return;
    }
    const response = await handleCreateCharges(availablePaycycles, amounts);
    if (response?.data?.affected_rows && response?.data?.affected_rows > 0) {
      toast.success('Charge saved!');
      if (refetch) refetch();
    } else {
      toast.error('Failed to save charge');
    }
    setLoading(false);
    close();
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (!validateInput(amount, availablePaycycles, earliestChargeableDate)) {
      setLoading(false);
      return;
    }
    const response = await handleUpdateCharge(input.charge.id);
    if (response?.data?.id) {
      toast.success('Charge updated!');
      if (refetch) refetch();
    } else {
      toast.error('Failed to update charge');
    }
    setLoading(false);
    close();
  };

  const handleDeactivate = async () => {
    setLoading(true);
    const response = await sdk.gql.mutation(DEACTIVATE_CHARGE, {
      chargeId: input.charge.id,
    });
    if (response?.data?.id) {
      toast.success('Charge removed successfully');
      if (refetch) refetch();
    } else {
      toast.error('Failed to remove charge');
    }
    setLoading(false);
    close();
  };

  return (
    <>
      <ModalContent
        subtitle={
          editMode
            ? null
            : `Add a charge to the driver's upcoming pay cycles (e.g., for onboarding fees, equipment, etc.)`
        }
      >
        <FormControl>
          <FormGroup>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  {editMode ? 'Edit charge amount and type' : 'Charge Amount and Distribution'}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  {editMode
                    ? 'Enter the updated amount, type, and chargeable date below'
                    : `Enter the total amount of the charge, its type, and the earliest date it should be charged`}
                  .
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      type='number'
                      variant='outlined'
                      label='Amount'
                      size='small'
                      value={amount}
                      onChange={handleInputChange(setAmount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Icon color='disabled' fontSize='small'>
                              attach_money
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        step: '0.01',
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      variant='outlined'
                      label='Charge Type'
                      size='small'
                      value={type}
                      onChange={handleInputChange(setType)}
                    >
                      <MenuItem value={`onboarding`}>Onboarding</MenuItem>
                      <MenuItem value={`equipment`}>Equipment</MenuItem>
                      <MenuItem value={`other`}>Other</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        label='Chargeable Date'
                        inputVariant='outlined'
                        size='small'
                        value={earliestChargeableDate}
                        onChange={setEarliestChargeableDate}
                        minDate={earliestEditableDate}
                        fullWidth
                        format='MM/dd/yyyy'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Icon color='disabled' fontSize='small'>
                                calendar_today
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Spacer size='lg' />

                {!editMode ? (
                  <>
                    <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                      Divide Charge (Optional){' '}
                    </Typography>
                    <Typography variant='body2' display='block' gutterBottom>
                      If you would like divide this charge across multiple pay cycles, flip this switch on.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showSplit}
                          onChange={e => {
                            handleSetShowSplit(e.target.checked);
                          }}
                          disabled={editMode || amount <= 0}
                        />
                      }
                      label={showSplit ? 'Divide across multiple pay cycles' : 'Post as single charge'}
                    />
                    <Spacer size='sm' />
                    <Grid container spacing={2}>
                      {showSplit && amount > 0 ? (
                        <>
                          <Grid container item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              variant='outlined'
                              label='Pay Cycles to Split'
                              type='number'
                              size='small'
                              value={payCycleSplit}
                              onChange={handleSplitChange(setPayCycleSplit)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <Icon color='disabled' fontSize='small'>
                                      receipt_long
                                    </Icon>
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                min: 2,
                                max: 20,
                                step: 1,
                                onKeyDown: e => {
                                  e.preventDefault();
                                },
                              }}
                            />
                          </Grid>
                          <Grid container item sm={6} xs={12} alignItems='center'>
                            <Typography variant='body2'>${amountPerCycle} per pay cycle</Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                    <Spacer size='lg' />
                  </>
                ) : null}

                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Apply/Waive{' '}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom>
                  If you would like to record the charge but not actually deduct it from the driver's pay, flip this
                  switch off.
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={status !== 'waived'}
                      onChange={e => {
                        handleStatusChange(e.target.checked);
                      }}
                      name='checkedA'
                    />
                  }
                  label={`${status === 'waived' ? 'Waive ' : 'Apply'} charge`}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Internal Note (Optional){' '}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  Enter an optional note about this charge for Hopdrive's records. This note will only be visible to
                  Hopdrive admins.
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Add internal note...'
                  size='large'
                  value={internalNote}
                  onChange={handleInputChange(setInternalNote)}
                  multiline
                />

                <Spacer size='lg' />

                <Typography variant='subtitle2' display='block' gutterBottom style={{ fontSize: '16px' }}>
                  Driver Note (Optional){' '}
                </Typography>
                <Typography variant='body2' display='block' gutterBottom style={{ marginBottom: '20px' }}>
                  Enter an optional note to the driver about this charge. This note will be visible to the driver when
                  they view their pay.
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Add driver note...'
                  size='large'
                  value={externalNote}
                  onChange={handleInputChange(setExternalNote)}
                  multiline
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
        <Spacer size='lg' />
      </ModalContent>
      <ModalFooter>
        <ModalAction onClick={handleClose}>Cancel</ModalAction>
        {editMode && (
          <ModalAction
            loading={loading}
            color='secondary'
            onClick={handleDeactivate}
          >
            Remove Charge
          </ModalAction>
        )}
        <ModalAction
          loading={loading}
          disabled={amount <= 0 || loading === true}
          color='primary'
          onClick={editMode ? handleUpdate : handleSave}
        >
          Save
        </ModalAction>
      </ModalFooter>
    </>
  );
};

//////////////////////// GQL ////////////////////////


const GET_PAY_CYCLES = gql`
  query getPayCycles{
    paycycles(
      where: { status: { _neq: "closed" }, tax_class: { _eq: "1099" } }
      order_by: { end_date: asc }
    ) {
      id
      end_date
      start_date
    }
  }
`;

const INSERT_CHARGES = gql`
  mutation insertCharges($charges: [apcharges_insert_input!]!) {
    insert_apcharges(objects: $charges) {
      affected_rows
    }
  }
`;

const UPDATE_CHARGE = gql`
  mutation updateCharge($chargeId: bigint!, $updates: apcharges_set_input!) {
    update_apcharges_by_pk(pk_columns: { id: $chargeId }, _set: $updates) {
      id
      amount
      status
      internal_notes
      external_notes
      type
    }
  }
`;

const DEACTIVATE_CHARGE = gql`
  mutation deactivateCharge($chargeId: bigint!) {
    update_apcharges_by_pk(pk_columns: { id: $chargeId }, _set: { active: false }) {
      id
      active
    }
  }
`;

//////////////////////// EXPORT ////////////////////////

export default DriverChargeModalContent;
