import React from 'react';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Grid, TextField, MenuItem, Icon } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Button } from '@hopdrive/storybook';

import useDriverPayouts from './useDriverPayouts';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayoutsFilter1({ range, start, end, onRangeChange, onStartChange, onEndChange }) {
  const cls = useStyles();

  const { getDateString } = useDriverPayouts();

  const handleRange = event => {
    const val = event.target.value;
    if (onRangeChange) onRangeChange(val);

    if (val !== `custom`) {
      if (onStartChange) onStartChange(dayjs().startOf(val).format());
      if (onEndChange) onEndChange(dayjs().endOf(val).format());
    }
  };
  const handleStart = event => {
    if (event) {
      if (onStartChange) onStartChange(dayjs(event).format());
    }
  };
  const handleEnd = event => {
    if (event) {
      if (onEndChange) onEndChange(dayjs(event).format());
    }
  };

  const handlePrevButton = () => {
    if (range !== `custom`) {
      if (onStartChange) onStartChange(dayjs(start).subtract(1, range).format());
      if (onEndChange) onEndChange(dayjs(end).subtract(1, range).format());
    }
  };
  const handleNextButton = () => {
    if (range !== `custom`) {
      if (onStartChange) onStartChange(dayjs(start).add(1, range).format());
      if (onEndChange) onEndChange(dayjs(end).add(1, range).format());
    }
  };

  const ranges = [
    {
      value: `week`,
      label: `Weekly`,
    },
    {
      value: `month`,
      label: `Monthly`,
    },
    {
      value: `year`,
      label: `Yearly`,
    },
    {
      value: `custom`,
      label: `Custom Range`,
    },
  ];

  return (
    <>
      <div className={cls.filter}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2} wrap='nowrap'>
            <Grid item>
              <Button
                disabled={range === `custom`}
                color='secondary'
                size='large'
                variant='outlined'
                onClick={() => handlePrevButton()}
              >
                <Icon className={cls.btnIconL}>keyboard_double_arrow_left</Icon>
                Prev
              </Button>
            </Grid>

            <Grid item xs>
              <TextField
                select
                fullWidth
                label='Pay Period Range'
                placeholder='Select a pay period range...'
                variant='outlined'
                size='small'
                value={range}
                onChange={handleRange}
              >
                {ranges.map((r, i) => (
                  <MenuItem key={`driver-pay-range-${i}`} value={r.value}>
                    {r.label} {getDateString(r.value, range, start, end)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item>
              <Button
                disabled={range === `custom`}
                color='primary'
                size='large'
                variant='outlined'
                onClick={() => handleNextButton()}
              >
                Next
                <Icon className={cls.btnIconR}>keyboard_double_arrow_right</Icon>
              </Button>
            </Grid>

            <Grid item>
              <div className={cls.line} />
            </Grid>

            <Grid item xs={2}>
              <DatePicker
                fullWidth
                disabled={range !== `custom`}
                autoOk
                maxDate={end}
                label={range !== `custom` ? `Start Date` : `Custom Start Date`}
                format='MM/dd/yyyy'
                orientation='portrait'
                size='small'
                variant='inline'
                inputVariant='outlined'
                value={start}
                onChange={handleStart}
              />
            </Grid>

            <Grid item xs={2}>
              <DatePicker
                fullWidth
                disabled={range !== `custom`}
                autoOk
                minDate={start}
                label={range !== `custom` ? `End Date` : `Custom End Date`}
                format='MM/dd/yyyy'
                orientation='portrait'
                size='small'
                variant='inline'
                inputVariant='outlined'
                value={end}
                onChange={handleEnd}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  btnIconL: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
  btnIconR: {
    marginTop: -2,
    marginLeft: 8,
    marginRight: -4,
    fontSize: 16,
  },

  line: {
    width: 1,
    height: 40,
    background: theme.palette.divider,
  },
}));
