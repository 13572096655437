import React from 'react';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from 'react-toastify';

import { useQuery, useLazyQuery } from '@apollo/client';

import { makeStyles, Button } from '@material-ui/core';

import Toolbar from '../../components/Toolbar';
import Filter from '../../components/Filter/Filter';

import BatchJobTableWrapper from './BatchJobTableWrapper';

const baseFilters = Object.freeze({
  dateRange: Object.freeze({
    range: `past-1-week`,
    start: dayjs().startOf(`day`).subtract(1, `week`).format(),
    end: dayjs().endOf(`day`).format(),
    utc: false,
  }),
  select: Object.freeze({
    value: `all`,
  }),
});

const getLocalFilters = () => {
  const localFilters = JSON.parse(localStorage.getItem(`BatchJob-Filters`)) || {};
  return localFilters;
};

export default function BatchJob() {
  const cls = useStyles();

  const [allTriggerTypes, setAllTriggerTypes] = React.useState([
    { value: `ar`, label: `Accounts Receivable` },
    { value: `ap`, label: `Driver Pay` },
    { value: `csv`, label: `CSV Import` },
    { value: `true-up`, label: `Driver Pay True-up` },
    { value: `superNewOrder`, label: `New Super Dispatch Order` },
    { value: `email-scrape`, label: `Email Scraper` },
    { value: `batchPlan`, label: `Batch Plan` },
  ]);
  const [allStatuses, setAllStatuses] = React.useState([
    { value: `pending`, label: `Pending` },
    { value: `delaying`, label: `Delaying` },
    { value: `processing`, label: `Processing` },
    { value: `done`, label: `Done` },
    { value: `error`, label: `Error` },
  ]);
  const [filters, setFilters] = React.useState(getLocalFilters());
  const [filterConfig, setFilterConfig] = React.useState({
    modules: [`dateRange`, `select`, `genericRadio`],
    moduleOptions: {
      dateRange: {
        ranges: [
          `custom`,
          `today`,
          `past-3-day`,
          `past-1-week`,
          `past-2-week`,
          `past-2-month`,
          `past-1-month`,
          `past-3-month`,
          `past-6-month`,
          `past-1-year`,
        ],
        minDate: dayjs().subtract(1, `year`).format(),
        maxDate: dayjs().add(1, `year`).format(),
        showUtc: false,
      },
      select: {
        allowAll: true,
        icon: `public`,
        label: `Trigger Type`,
        options: allTriggerTypes,
      },
      genericRadio: {
        label: `Status`,
        options: [`any`, `pending`, `delaying`, `processing`, `done`, `error`],
        /*
        TODO: Add support for options object arrays like select supports
        options: [
          { value: `any`, label: `Any` },
          { value: `pending`, label: `Pending` },
          { value: `delaying`, label: `Delaying` },
          { value: `processing`, label: `Processing` },
          { value: `done`, label: `Done` },
          { value: `error`, label: `Error` },
        ],
        */
      },
    },
  });

  const handleApplyFilters = output => {
    setFilters(output);
    localStorage.setItem(`BatchJob-Filters`, JSON.stringify(output));
  };

  const handleClearFilters = output => {
    setFilters(output);
    localStorage.removeItem(`BatchJob-Filters`);
  };

  const handleToggleFilters = output => {
    setFilters(output);
  };

  const handleStatusOutput = options => {
    const checkedOption = options?.find(opt => opt.checked);
    const allStatusIds = allStatuses?.map(r => r.value);
    if (!checkedOption?.value) return allStatusIds;
    if (checkedOption?.value === `any`) return allStatusIds;
    return [checkedOption?.value];
  };

  const handleTriggerTypeOutput = value => {
    const allTriggerTypeIds = allTriggerTypes?.map(r => r.value);
    if (value === `all`) return allTriggerTypeIds;
    if (!value) return allTriggerTypeIds;
    return [value];
  };

  // const handleTaxClassOutput = options => {
  //   const allTaxClasses = [`1099`, `W-2`];
  //   const checkedOptions = options?.find(opt => opt.checked);
  //   if (!checkedOptions) return allTaxClasses;
  //   return [checkedOptions.value];
  // };

  const variables = {
    start: filters?.dateRange?.start || baseFilters?.dateRange?.start,
    end: filters?.dateRange?.end || baseFilters?.dateRange?.end,
    selectedStatuses: handleStatusOutput(filters?.genericRadio?.options),
    selectedTriggerTypes: handleTriggerTypeOutput(filters?.select?.value),
  };

  console.log(`Query variables`, variables);

  const { loading, error, data, refetch } = useQuery(GET_BATCH_JOBS, {
    variables,
    fetchPolicy: `network-only`,
  });

  const handleRefresh = async e => {
    e?.preventDefault();
    try {
      await refetch();
    } catch (err) {
      console.error('Refetch error:', err);
      toast.error('Failed to refresh data');
    }
  };

  const batchJobs = data?.batch_jobs || [];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Batch Jobs'>
        <div className={cls.flex}>
          <div className={cls.flexStretch} />

          <Button variant='text' color='primary' onClick={handleRefresh} startIcon={<RefreshIcon />} disabled={loading}>
            Refresh
          </Button>

          <Filter
            baseFilters={baseFilters}
            localFilters={getLocalFilters()}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
            onToggle={handleToggleFilters}
            config={filterConfig}
          />

          {/* <ActionsMenu actions={actions} /> */}
        </div>
      </Toolbar>

      <BatchJobTableWrapper loading={loading} error={error} batchJobs={batchJobs} />
    </div>
  );
}

const GET_BATCH_JOBS = gql`
  query GetBatchJobs(
    $start: timestamptz
    $end: timestamptz
    $selectedStatuses: [String!]
    $selectedTriggerTypes: [String!]
  ) {
    batch_jobs(
      where: {
        status: { _in: $selectedStatuses }
        _and: [{ createdat: { _gte: $start } }, { createdat: { _lte: $end } }]
        trigger_type: { _in: $selectedTriggerTypes }
      }
    ) {
      id
      batch_id
      input
      output
      trigger_type
      status
      sequence
      delay_key
      delay_ms
      createdat
      createdby
      updatedat
      updatedby
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexStretch: {
    flex: 1,
  },

  btn1: {
    height: 36.5,
    borderRadius: `4px 0 0 4px`,
  },
  btn2: {
    height: 36.5,
    borderRadius: `0 4px 4px 0`,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
}));
