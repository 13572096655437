//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, Typography, InputAdornment, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { sendPasswordResetEmail } from '../../utils/authHelper' 

//////////////////////// COMPONENT ////////////////////////

function PasswordReset(props) {
  const cls = useStyles();

  const { open, setOpen, email, setEmail } = props;

  const [resetSuccess, setResetSuccess] = React.useState(null);

  const sendPasswordReset = async () => {
    try {
      const requestRes = await sendPasswordResetEmail('reset password', email, null);
      if (requestRes) {
        setResetSuccess(true);
      } else {
        setResetSuccess(false);
      }
    } catch (err) {
      console.error(`Error sending reset password email:`, err);
    }
  };

  return (
    <div>
      <Spacer size='xl' />

      <Typography className={cls.titleTxt}>Request or reset password</Typography>
      <Typography className={cls.subtitleTxt}>Enter your email and receive a link to reset your password.</Typography>

      <Spacer size='lg' />

      {resetSuccess === true ? (
        <>
          <Grid container alignItems='center'>
            <Grid item>
              <Icon className={cls.successIcon}>check_circle</Icon>
            </Grid>

            <Grid item xs>
              <Typography className={cls.successTxt}>
                Your request was sent successfully. Please check your email for the next step.
              </Typography>
            </Grid>
          </Grid>

          <Spacer size='lg' />

          <Button fullWidth size='large' onClick={() => setOpen(false)}>
            Return
          </Button>
        </>
      ) : resetSuccess === false ? (
        <>
          <Grid container alignItems='center'>
            <Grid item>
              <Icon className={cls.errorIcon}>cancel</Icon>
            </Grid>

            <Grid item xs>
              <Typography className={cls.errorTxt}>
                Your request failed to send. Please make sure your email is correct and try again.
              </Typography>
            </Grid>
          </Grid>

          <Spacer size='lg' />

          <Grid container spacing={2}>
            <Grid item xs>
              <Button fullWidth size='large' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs>
              <Button fullWidth color='secondary' size='large' onClick={() => setResetSuccess(null)}>
                Try Again
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <TextField
            required
            fullWidth
            autoFocus
            id='email'
            name='email'
            autoComplete='email'
            label='Email'
            placeholder='Enter email...'
            variant='outlined'
            size='small'
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <Spacer size='lg' />

          <Grid container spacing={2}>
            <Grid item xs>
              <Button fullWidth size='large' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs>
              <Button
                disabled={!email || resetSuccess === false || resetSuccess === true}
                fullWidth
                type='submit'
                color='primary'
                size='large'
                onClick={() => sendPasswordReset()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  subtitleTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  successIcon: {
    marginRight: 8,
    fontSize: 24,
    color: theme.palette.success.main,
  },
  successTxt: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    fontSize: 18,
    color: theme.palette.success.main,
  },
  errorIcon: {
    marginRight: 8,
    fontSize: 24,
    color: theme.palette.error.main,
  },
  errorTxt: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    fontSize: 18,
    color: theme.palette.error.main,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default PasswordReset;
