import React, { useState, useEffect } from 'react';

import { TextField, InputAdornment, Icon } from '@material-ui/core';

////////// COMPONENT //////////
export default function DealerContactInput(props) {
  const { defaultContact, onChange } = props;

  const [contact, setContact] = useState(defaultContact ? defaultContact : '');

  useEffect(() => {
    if (onChange) onChange(contact);
  }, [contact]);

  useEffect(() => {
    setContact(defaultContact);
  }, [defaultContact]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        label='Dealer Contact Info'
        placeholder='Enter dealer/service contact info...'
        variant='outlined'
        size='small'
        value={contact}
        onChange={handleInputChange(setContact)}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  contact_phone
                </Icon>
              </InputAdornment>
            </>
          ),
        }}
      />
    </>
  );
}
