//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal, ModalHeader } from '../ModalComponents';

import DriverIncentiveModalContent from './DriverIncentiveModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function DriverIncentiveModal({ open, close, input, refetch, type = 'bonus', editMode = false }) {
  // Handle modal closing and optionally pass in an output
  const handleClose = (output = null) => { // Debug log
    if (close) {
      if (output) {
        close(output);
      } else {
        close();
      }
    }
  };

  return (
    <Modal open={open}>
      {open ? (
        <>
          <ModalHeader handleClose={close}>
            {editMode ? 'Edit' : 'Add'} {type?.charAt(0)?.toUpperCase() + type?.slice(1)} Pay
            {input?.driverName && input?.driverId
              ? ` to ${input?.driverName} (${input?.driverId})`
              : input?.driverId
              ? ` to Driver #${input?.driverId}`
              : ``}
          </ModalHeader>

          <DriverIncentiveModalContent input={input} type={type} close={handleClose} refetch={refetch} editMode={editMode} />
        </>
      ) : null}
    </Modal>
  );
}
