import React from 'react';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { getUserEmail } from '../../utils/authHelper';
import { useTools } from '../../hooks/useTools';

import { makeStyles, Container, TextField, Typography, Tooltip, Box } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { Table, Column } from '../../components/TableComponents';

const log = true;

export default function ActiveTimeAuditContent({ activeTime, refetch }) {
  const [overrideMins, setOverrideMins] = React.useState(0);
  const [notes, setNotes] = React.useState('');
  const [timestamps, setTimestamps] = React.useState([]);
  const [updateActiveTime] = useMutation(UPDATE_ACTIVE_TIME);
  const [updateActiveTimeStatus] = useMutation(UPDATE_ACTIVE_TIME_STATUS);

  const { capFirst, goToMoveDetails, goToPlans } = useTools();

  const cls = useStyles();

  React.useEffect(() => {
    setOverrideMins(activeTime?.override_minutes || 0);
    setNotes(activeTime?.notes || '');
    handleSetTimestamps(activeTime?.move);
  }, [activeTime]);

  function transformSourceString(string) {
    return string.replace(/(?:^|\.|_)(\w)/g, (match, p1) => ' ' + p1.toUpperCase()).trim();
  }

  const FactorSection = ({ factors }) => {
    return (
      <>
        <Typography variant='h6'>Factors</Typography>
        <Typography className={cls.listItem}>
          <span className={cls.factorLabel}>Start Time:</span>{' '}
          <span className={cls.factorValue}>
            <span className={cls.sourceText}>{transformSourceString(factors?.start_time_source)}</span>{' '}
            {dayjs(factors?.start_time).format('MM/DD/YYYY h:mm a z')}
          </span>
        </Typography>
        <Typography className={cls.listItem}>
          <span className={cls.factorLabel}>End Time:</span>{' '}
          <span className={cls.factorValue}>
            <span className={cls.sourceText}>{transformSourceString(factors?.end_time_source)}</span>{' '}
            {dayjs(factors?.end_time).format('MM/DD/YYYY h:mm a z')}
          </span>
        </Typography>
        <Tooltip title='Estimated Duration of Move Set based on Lane Durations'>
          <Typography className={cls.listItem}>
            <span className={cls.factorLabel}>Estimated Duration of Move Set:</span>{' '}
            <span className={cls.factorValue}>{Math.ceil(factors?.estimated_duration_mins)} minutes</span>
          </Typography>
        </Tooltip>
      </>
    );
  };

  const DecisionProcessList = ({ factors, overrideMins, overrideNotes }) => {
    log && console.log('overrideMins', overrideMins);
    log && console.log('overrideNotes', overrideNotes);
    return (
      <>
        <Typography variant='h6'>Decision Process:</Typography>
        <Typography variant='subtitle1'>Start Time Logs</Typography>
        {factors?.start_time_fallback_log?.map((log, index) => (
          <Typography className={cls.listItem} variant='body2'>
            {index + 1}. {capFirst(log?.message)}
          </Typography>
        ))}
        <Typography variant='subtitle1'>End Time Logs</Typography>
        {factors?.end_time_fallback_log?.map((log, index) => (
          <Typography className={cls.listItem} variant='body2'>
            {index + 1}. {capFirst(log?.message)}
          </Typography>
        ))}
        {factors?.audit_reasons_log.length > 0 && (
          <>
            <Typography variant='subtitle1'>Audit Reasons:</Typography>
            {factors?.audit_reasons_log?.map((reason, index) => (
              <Typography className={cls.listItem} variant='body2'>
                {index + 1}. {capFirst(reason)}
              </Typography>
            ))}
          </>
        )}
        {overrideMins > 0 && (
          <>
            <Typography variant='subtitle1'>Override:</Typography>
            <Typography variant='body2' className={cls.listItem}>
              Admin Override of {overrideMins} minutes
            </Typography>
            <Typography variant='body2' className={cls.listItem}>
              Notes:{overrideNotes}
            </Typography>
          </>
        )}
      </>
    );
  };

  const handleSetStatus = async status => {
    const userEmail = getUserEmail() || 'unknown user';
    await updateActiveTimeStatus({ variables: { id: activeTime.id, status, updated_by: userEmail } });
    refetch();
  };

  const handleOverride = async () => {
    const userEmail = getUserEmail() || 'unknown user';
    const overrideMinsNum = Number.isNaN(Number(overrideMins)) ? 0 : Number(overrideMins);
    try {
      await updateActiveTime({
        variables: { id: activeTime.id, override_minutes: overrideMinsNum, notes: notes, updated_by: userEmail },
      });
    } catch (e) {
      console.error(e);
      toast.error(`Failed to override active time duration. Check inputs and try again.`);
    }
    refetch();
  };

  const handleSetTimestamps = async move => {
    if (!move) {
      console.error('No move found');
      return;
    }
    const findBeforeRide = (rideMoves, driveMove) => {
      //Get earliest ride move
      let earliestRide = null;
      if (rideMoves.length === 0) {
        return null;
      } else if (rideMoves.length === 1) {
        earliestRide = rideMoves[0];
      } else if (rideMoves.length > 1) {
        earliestRide = rideMoves.sort((a, b) => new Date(a.pickup_time) - new Date(b.pickup_time))[0];
      }
      //Check if ride is before drive move
      if (new Date(earliestRide.pickup_time) < new Date(driveMove.pickup_time)) {
        return earliestRide;
      } else {
        return null;
      }
    };

    const findAfterRide = (rideMoves, driveMove) => {
      //Finds the latest ride move
      let latestRide = null;
      if (rideMoves.length === 0) {
        return null;
      } else if (rideMoves.length === 1) {
        latestRide = rideMoves[0];
      } else if (rideMoves.length > 1) {
        latestRide = rideMoves.sort((a, b) => new Date(b.pickup_time) - new Date(a.pickup_time))[0];
      }
      //Check if ride is after drive move
      if (new Date(latestRide.pickup_time) > new Date(driveMove.pickup_time)) {
        return latestRide;
      } else {
        return null;
      }
    };

    const rideMoves = move?.childMoves;
    log && console.log('rideMoves', rideMoves, 'move', move);
    const beforeRide = rideMoves.length > 0 ? findBeforeRide(rideMoves, move) : null;
    const afterRide = rideMoves.length > 0 ? findAfterRide(rideMoves, move) : null;
    const generateTimestamps = (move, moveType) => {
      const timestampConfigs = [
        { source: `${moveType} Pickup Time`, field: 'pickup_time' },
        { source: `${moveType} Pickup Arrived`, field: 'pickup_arrived' },
        { source: `${moveType} Pickup Successful`, field: 'pickup_successful' },
        { source: `${moveType} Delivery Started`, field: 'delivery_started' },
        { source: `${moveType} Delivery Arrived`, field: 'delivery_arrived' },
        { source: `${moveType} Delivery Successful`, field: 'delivery_successful' },
      ];

      if (move?.cancel_status) {
        timestampConfigs.push({ source: `${moveType} Cancel Requested`, field: 'cancel_requested_at' });
        timestampConfigs.push({ source: `${moveType} Canceled`, field: 'canceled_at' });
      }

      return timestampConfigs.map(config => ({
        id: move?.id,
        move_type: moveType,
        source: config.source,
        day: move?.[config.field] ? dayjs(move?.[config.field]).format('MM/DD') : 'Missing',
        time: move?.[config.field] ? dayjs(move?.[config.field]).format('h:mm a z') : 'Missing',
      }));
    };

    const driveTimestamps = generateTimestamps(move, 'Drive');
    const beforeRideTimestamps = beforeRide ? generateTimestamps(beforeRide, 'Before Ride') : [];
    const afterRideTimestamps = afterRide ? generateTimestamps(afterRide, 'Ride After') : [];
    const timestamps = [...beforeRideTimestamps, ...driveTimestamps, ...afterRideTimestamps];
    log && console.log('timestamps', timestamps);
    setTimestamps(timestamps);
  };

  const TimestampsTable = ({ timestamps }) => {
    return (
      <Table
        data={timestamps}
        defaultOrderBy={'move_type'}
        defaultOrder={'asc'}
        tableAriaLabel={`active-times`}
        rowKeyCalculator={row => `${row.id}-${row.source}`}
      >
        <Column name='id' label='Id' value={timestamp => timestamp.id} />
        <Column name='move_type' label='Move Type' value={timestamp => timestamp.move_type} style={{ minWidth: 100 }} />
        <Column name='source' label='Source' value={timestamp => timestamp.source} />
        <Column name='day' label='Day' value={timestamp => timestamp.day} />
        <Column name='time' label='Time' value={timestamp => timestamp.time} />
      </Table>
    );
  };

  return (
    <Container maxWidth='lg' style={{ marginTop: '20px' }}>
      <div style={{ display: 'flex', marginBottom: '20px', gap: '20px' }}>
        <div style={{ flex: '1 1 33%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box display='flex' alignItems='center'>
            <Typography variant='h6' style={{ marginRight: '8px' }}>
              Move:
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => goToMoveDetails(activeTime?.move_id)}
              style={{ marginRight: '8px' }}
            >
              #{activeTime?.move_id}
            </Button>
            {activeTime?.move?.plan?.plan_date && (
              <Button
                variant='contained'
                style={{
                  color: 'white',
                }}
                onClick={() => goToPlans(activeTime?.move?.plan?.plan_date)}
              >
                Plan Date: {dayjs(activeTime?.move?.plan?.plan_date).format('MM/DD/YYYY')}
              </Button>
            )}
          </Box>
          <Typography className={cls.listItem}>
            <span className={cls.factorLabel}>Duration:</span>
            <span className={cls.factorValue}>
              {activeTime?.override_minutes ? (
                <>
                  <span style={{ textDecoration: 'line-through' }}>{activeTime?.duration_minutes}</span>
                  <span style={{ marginLeft: '10px' }}>{activeTime?.override_minutes}</span>
                  <span style={{ marginLeft: '10px', color: 'red' }}>Overridden</span>
                </>
              ) : (
                activeTime?.duration_minutes
              )}
            </span>
          </Typography>
          <Tooltip title='Override the duration of the active time'>
            <TextField
              label='Override Mins'
              variant='outlined'
              size='small'
              value={overrideMins}
              onChange={e => setOverrideMins(e.target.value)}
              style={{ width: '120px' }}
            />
          </Tooltip>
          <Tooltip title='Explain your reasoning for overriding the active time duration'>
            <TextField
              label='Override Notes'
              variant='outlined'
              size='small'
              value={notes}
              onChange={e => setNotes(e.target.value)}
              multiline
              style={{ width: '240px' }}
            />
          </Tooltip>
          <Button color='secondary' onClick={handleOverride} style={{ width: '120px' }}>
            Override
          </Button>
        </div>
        <div style={{ flex: '1 1 33%', display: 'flex', flexDirection: 'column' }}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Typography variant='h6' className={cls.listItem} style={{ margin: 0 }}>
              <span className={cls.factorLabel}>Status:</span>
              <span className={cls.factorValue}>
                {capFirst(activeTime?.status)}
                {activeTime?.status === 'approved' && activeTime?.factors?.auto_approved && ' (auto approved by sytem)'}
              </span>
            </Typography>
            {activeTime?.status === 'audit' && (
              <Button
                variant='contained'
                color='success'
                onClick={() => handleSetStatus('approved')}
                style={{ marginRight: '10px' }}
              >
                APPROVE ACTIVE TIME
              </Button>
            )}
            {activeTime?.status === 'approved' && (
              <Button
                variant='contained'
                color='error'
                onClick={() => handleSetStatus('audit')}
                style={{ marginRight: '10px' }}
              >
                Set to Audit
              </Button>
            )}
          </Box>
          <FactorSection factors={activeTime?.factors} />
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0.4', marginRight: '20px' }}>
          <DecisionProcessList
            factors={activeTime?.factors}
            overrideMins={activeTime?.override_minutes}
            overrideNotes={activeTime?.notes}
          />
        </div>
        <div style={{ flex: '0.6' }}>
          <TimestampsTable timestamps={timestamps} />
        </div>
      </div>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  factorLabel: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  factorValue: {
    color: theme.palette.text.primary,
  },
  sourceText: {
    fontWeight: 700,
  },
}));

const UPDATE_ACTIVE_TIME = gql`
  mutation UpdateActiveTime($id: bigint!, $override_minutes: numeric!, $notes: String, $updated_by: String!) {
    update_activetimes_by_pk(
      pk_columns: { id: $id }
      _set: { override_minutes: $override_minutes, notes: $notes, updated_by: $updated_by }
    ) {
      id
    }
  }
`;
const UPDATE_ACTIVE_TIME_STATUS = gql`
  mutation UpdateActiveTimeStatus($id: bigint!, $status: String!, $updated_by: String!) {
    update_activetimes_by_pk(pk_columns: { id: $id }, _set: { status: $status, updated_by: $updated_by }) {
      id
    }
  }
`;
