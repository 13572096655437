import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { gql } from 'graphql-tag';

import { Grid, TextField, InputAdornment, Icon } from '@material-ui/core';

import { useData } from '../../providers/DataProvider';

////////// COMPONENT //////////
export default function ConsumerInfoForm(props) {
  const { consumerData, onChange } = props;
  const ctx = useData();

  const [name, setName] = useState(consumerData && consumerData.name ? consumerData.name : '');
  const [phone, setPhone] = useState(consumerData && consumerData.phone ? consumerData.phone : '');

  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    if (onChange) onChange({ name: name, phone: phone });
  }, [name, phone]);

  useEffect(() => {
    setName(consumerData && consumerData.name ? consumerData.name : '');
    setPhone(consumerData && consumerData.phone ? consumerData.phone : '');
  }, [consumerData]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  async function verifyNumber() {
    let existingConsumerNumber = null;

    if (!phone || (phone && phone.trim() && phone.trim().length < 1) || (phone && !phone.trim())) {
      setIsValid(null);
      return;
    } else {
      try {
        await ctx.apolloClient
          .query({
            query: gql`
              query get_consumer_number($number: String!, $match: String!) {
                consumer_numbers(where: { _or: [{ number: { _eq: $number } }, { alt_formats: { _ilike: $match } }] }) {
                  id
                  number
                  is_mobile
                }
              }
            `,
            variables: {
              number: phone,
              match: phone.trim().length > 9 ? `%${phone.trim()}%` : ``, // Only partial match if whole number is provided
            },
          })
          .then(res => {
            existingConsumerNumber = res.data.consumer_numbers[0];
          });
      } catch (err) {
        console.error('Unexpected error while looking up consumer number:', err);
      }

      try {
        if (existingConsumerNumber) {
          if (existingConsumerNumber.is_mobile) {
            setIsValid(true);
            return;
          } else if (!existingConsumerNumber.is_mobile) {
            setIsValid(false);
            return;
          }
        }
      } catch (err) {
        console.error('Error parsing existing consumer number:', err);
      }

      try {
        axios
          .post(`/.netlify/functions/verifyMobilePhone`, { number: phone.toString() })
          .then(res => {
            if (res.status === 200) {
              res.data === 'valid' ? setIsValid(true) : setIsValid(false);
            } else setIsValid(null); // In the event of an error, assume that the provided number is valid but don't render a verification status
          })
          .catch(() => {
            // In the event of an error, assume that the provided number is valid but don't render a verification status
            setIsValid(null);
          });
      } catch (err) {
        // In the event of an error, assume that the provided number is valid but don't render a verification status
        setIsValid(null);
      }
    }
  }

  function handleNumberError() {
    if (isValid === false) return true;
    else if (props && props.validation) {
      if (props.validation.cnumber) return false;
      if (!props.validation.cnumber) return true;
    } else return false;
  }

  function handleNumberHelperText() {
    if (isValid === true)
      return (
        <span style={{ color: 'green', marginTop: -2 }}>
          <Icon color='inherit' fontSize='small'>
            check
          </Icon>
          &nbsp;Verified mobile number
        </span>
      );
    else if (isValid === false)
      return (
        <span style={{ color: 'red', marginTop: -2 }}>
          <Icon color='error' fontSize='small'>
            error_outline
          </Icon>
          &nbsp;Number is unable to receive SMS
        </span>
      );
    else return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            required
            error={props.validation && props.validation.cname ? !props.validation.cname.toString() : 'false'}
            fullWidth
            label='Consumer Name'
            placeholder='Enter consumer name...'
            variant='outlined'
            size='small'
            value={name}
            onChange={handleInputChange(setName)}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      person
                    </Icon>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            error={handleNumberError()}
            fullWidth
            helperText={handleNumberHelperText()}
            onBlur={verifyNumber}
            label='Consumer Phone'
            placeholder='Enter consumer phone...'
            variant='outlined'
            size='small'
            value={phone}
            onChange={handleInputChange(setPhone)}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      phone_iphone
                    </Icon>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
