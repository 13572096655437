//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { makeStyles, TableCell, Icon, Chip, Tooltip } from '@material-ui/core';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../hooks/useTools';
import { useNotificationsGlobals } from '../../providers/NotificationsProvider';

//////////////////////// COMPONENT ////////////////////////

function NotificationsTableRow({ row, index, notifications, ...props }) {
  const cls = useStyles();
  const { goToNotificationDetails } = useTools();
  const [rowCells, setRowCells] = React.useState({});
  const [isDueDatePast, setIsDueDatePast] = React.useState(null)
  const { readList, setReadList, addToReadList } = useNotificationsGlobals();


  React.useEffect(() => {
    const checkDueDate = () => {
      if (notification.type === 'action' && notification.duedate !== null && !dayjs().isBefore(notification.duedate)){
        setIsDueDatePast(true)
      } else {
        setIsDueDatePast(false)
      }
    }

    checkDueDate()
    

    //----------Custom cell values----------//
    let resolvedValue;
    let assignedValue;
    let dueDateValue;
    let typeIcon;
 



    const overdueIcon = () => {
      const overdueChip = 
        (<Chip variant={'outlined'} size='small' color={'primary'} label={'Overdue'}></Chip>)
      return (<>{overdueChip.props}</>)
    }


    if (notification.type === 'action') {
      typeIcon = (
        <Tooltip title='Action Item' placement='top'>
          <Icon style={{ color: 'red' }}>pending_actions</Icon>
        </Tooltip>
      );
    } else {
      typeIcon = (
        <Tooltip title='Information' placement='top'>
          <Icon style={{ color: '#3371FF' }}>inform-icon</Icon>
        </Tooltip>
      );
    }

    if (notification.type === 'inform') {
      resolvedValue = 'N/A';
    } else if (notification.type === 'action' && notification.resolvedby === null) {
      resolvedValue = 'Unresolved';
    } else resolvedValue = 'by   ' + row.resolvedby + '   at   ' + resolvedDate + ',   ' + resolvedTime;

    if (notification.type === 'inform') {
      assignedValue = 'N/A';
    } else if (notification.type === 'action' && notification.assignedto === null) {
      assignedValue = 'Not Assigned';
    } else
      assignedValue = 'to ' + row.assignedto + ' by ' + row.assignedby + ' at ' + assignedDate + ',   ' + assignedTime;

    if (notification.type === 'inform' && !isDueDatePast) {
      dueDateValue = 'N/A';
    } else if (notification.type === 'action' && notification.duedate === null && !isDueDatePast) {
      dueDateValue = '–'
    } else if (notification.type === 'action' && notification.duedate !== null
     && !isDueDatePast
     ) {
      dueDateValue = dueDateDate + ',   ' + dueDateTime 
    } else if (notification.type === 'action' && notification.duedate !== null && isDueDatePast) {
      dueDateValue = dueDateDate + ',   ' + dueDateTime + overdueIcon()
    }


    // Set a list of cells to render the row
    const localRowCells = [
      {
        value: typeIcon,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: dueDateValue,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: row.title || `-`,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: row.body || `-`,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: assignedValue,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: resolvedValue,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: 'by   ' + row.createdby + '   at   ' + createdDate + ',   ' + createdTime || `-`,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
      {
        value: row.id || `-`,
        align: `left`,
        onClick: () => setReadGoToDetails(row.id),
      },
    ];
    setRowCells(localRowCells);
  }, [row]);

  const notification = row.notification || {};

  const assignedDate = moment(row.assignedat).format('MM/DD/YYYY');
  const assignedTime = moment(row.assignedat).format('LT');
  const createdDate = moment(row.createdat).format('MM/DD/YYYY');
  const createdTime = moment(row.createdat).format('LT');
  const resolvedDate = moment(row.resolvedat).format('MM/DD/YYYY');
  const resolvedTime = moment(row.resolvedat).format('LT');
  const dueDateDate = moment(row.duedate).format('MM/DD/YYYY');
  const dueDateTime = moment(row.duedate).format('LT')

  const setReadGoToDetails = id => {
    addToReadList(id);
    goToNotificationDetails(id);
  };

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${notification.id}-cm`}
        source={`atr-row-${notification.id}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{ className: `MuiTableRow-root ${index % 2 ? cls.rowEven : cls.rowOdd}` }}
      >
        <TableCell
          align='left'
          onClick={() => {
            setReadGoToDetails(row.id);
          }}
        >
          {readList && readList.includes(row.id) ? (
            <Chip variant={'outlined'} size='small' label='Read' />
          ) : (
            <Chip variant={'outlined'} size='small' color={'primary'} label={'Unread'} />
          )}
        </TableCell>

        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => (
              <TableCell
                key={`atr-row-${notification.id}-col-${i}`}
                align={cell.align || `left`}
                onClick={cell.onClick}
              >
                {cell.value}
              </TableCell>
            ))
          : null}
      </ContextMenuTrigger>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NotificationsTableRow;
