//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import {
  useTheme,
  makeStyles,
  Icon,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import SettingsOption from '../../components/Settings/SettingsOption';
import { getUserToken } from '../../utils/authHelper';

const log = true;

//////////////////////// COMPONENT ////////////////////////

export default function AvatarUpload({
  avatar = null,
  setAvatar = () => {},
  userId = null,
}) {
  const theme = useTheme();
  const cls = useStyles();

  // Manage state of the file upload box
  const [dropzoneDragover, setDropzoneDragover] = React.useState(false);
  const [dropzoneError, setDropzoneError] = React.useState(false);
  const [dropzoneFile, setDropzoneFile] = React.useState(null);

  const handleAvatarUrlChange = e => {
    const value = e;
    setAvatar(value);
  };

  /** Refresh the image from the server (requires a timestamp to be set on the src) */
  const refreshImageFromServer = avatarUrl => {
    if (avatarUrl) {
      const timestamp = new Date().getTime();
      const queryString = '?t=' + timestamp;
      let element = document.getElementById('avatar-output');
      element.src = avatarUrl + queryString;
    }
  };

  /** Click upload handler */
  const handleFileClick = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    const file = e?.target?.files[0];
    log && console.log(`Handle click upload:`, file);

    // Upload the file
    await handleAvatarUpload(e, file);
  };

  /** Drag & drop upload handler */
  const handleFileDragAndDrop = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    let file = e.dataTransfer.files[0];
    log && console.log(`Handle drag & drop upload:`, file);

    // Upload the file
    await handleAvatarUpload(e, file);
  };

  /** Handle dragging over the file upload component */
  const handleFileDragoverEnter = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(true);
    setDropzoneError(false);
  };

  /** Handle dragging off the file upload component */
  const handleFileDragoverExit = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);
  };

  /** Handle uploading the file */
  const handleAvatarUpload = async (e, file) => {
    // Check if the file is valid
    if (!file) {
      console.error(`Failed to upload avatar: No file provided!`);
      toast.error(`Failed to upload avatar: No file provided!`);
      setDropzoneError(true);
      return;
    }
    console.log('file size:', file)
    if (file?.size > 5000000) {
      console.error(`Failed to upload avatar: File size must be less than 5MB/5000KB!`);
      toast.error(`Failed to upload avatar: File size must be less than 5MB/5000KB!`);
      setDropzoneError(true);
      return;
    }
    if (file?.type !== 'image/png' && file?.type !== 'image/svg+xml') {
      console.error(`Failed to upload avatar: File type not supported!`);
      toast.error(`Failed to upload avatar: File type not supported!`);
      setDropzoneError(true);
      return;
    }

    // Set the image element src
    const imgSrc = URL.createObjectURL(file);
    const imgElement = document.getElementById('avatar-output');
    imgElement.src = imgSrc;

    // Set the dropzone state
    setDropzoneFile(file);

    const token = await getUserToken();

    // Upload the file
    let reader = new FileReader();
    reader.addEventListener('loadend', async e => {
      try {
        const res = await axios({
          url: '/.netlify/functions/requestUploadUrl',
          method: 'POST',
          data: {
            fileName: `user/avatar/${userId}`,
            fileType: file?.type,
            bucketName: `driver-photos.hopdrive.com`,
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const { uploadURL } = res?.data;
        const uploadRes = await axios.put(uploadURL, file, { headers: { 'Content-Type': file?.type || `image/*` } });

        if (uploadRes.status >= 200 && uploadRes.status < 400) {
          const avatarUrl = uploadURL.split('?')[0];
          handleAvatarUrlChange(avatarUrl);
          refreshImageFromServer(avatarUrl);
          log && console.log(`Successfully uploaded the avatar!`);
        } else {
          console.error(`Failed to upload the avatar to S3!`);
          toast.error(`Failed to upload the avatar!`);
        }
      } catch (err) {
        console.error(`Failed to upload the avatar to S3:`, err);
        toast.error(`Failed to upload the avatar!`);
      }
    });
    reader.readAsArrayBuffer(file);
  };

  /** Handle clearing out the avatar */
  const handleAvatarClear = async () => {
    setDropzoneDragover(false);
    setDropzoneError(false);
    setDropzoneFile(null);
    setAvatar(null);
  };

  return (
    <SettingsOption
      value={avatar}
      onReset={() => setAvatar(null)}
    >
      {!avatar && !dropzoneFile ? (
        <label
          className={clsx(cls.dropzone, {
            [cls.dropzoneDragover]: dropzoneDragover,
            [cls.dropzoneError]: dropzoneError,
          })}
          onDrop={handleFileDragAndDrop}
          onDragOver={handleFileDragoverEnter}
          onDragLeave={handleFileDragoverExit}
        >
          <Typography className={cls.dropzoneTxt}>Click or drag PNG/SVG file here</Typography>
          <Typography className={cls.dropzoneAltLTxt}>Max 5MB/5000KB</Typography>
          <Typography className={cls.dropzoneAltRTxt}>Min height 40px</Typography>
          <input
            className={cls.hide}
            id='avatar-uploader'
            type='file'
            accept='image/png, image/svg+xml'
            onChange={handleFileClick}
          />
        </label>
      ) : null}

      <div className={cls.avatar}>
        <div className={clsx(cls.avatarBox, { [cls.hide]: !avatar && !dropzoneFile })}>
          <img
            className={clsx(cls.avatarImg, { [cls.hide]: !avatar && !dropzoneFile })}
            id='avatar-output'
            alt='avatar-output'
            src={avatar ? `${avatar}?t=${new Date().getTime()}` : null}
          />
        </div>

        <Spacer column size={1} />

        <Tooltip title='Remove avatar' placement='top'>
          <IconButton
            className={clsx(cls.avatarIconBtn, { [cls.hide]: !avatar && !dropzoneFile })}
            onClick={handleAvatarClear}
          >
            <Icon>cancel_circle</Icon>
          </IconButton>
        </Tooltip>
      </div>
    </SettingsOption>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},

  dropzone: {
    position: 'relative',
    display: 'block',
    width: 256,
    height: 128,
    padding: theme.spacing(2),
    border: theme.border[1],
    borderStyle: 'dashed',
    borderRadius: theme.shape.paperRadius,
    cursor: 'pointer',
    transition: `0.15s all ease-in-out`,
  },
  dropzoneDragover: {
    border: theme.border[1],
    borderColor: theme.palette.text.primary,
    '& $dropzoneTxt': {
      color: theme.palette.text.primary,
    },
  },
  dropzoneError: {
    border: theme.border[1],
    borderColor: theme.palette.error.main,
    '& $dropzoneTxt': {
      color: theme.palette.error.main,
    },
  },

  dropzoneTxt: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: `0.15s all ease-in-out`,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dropzoneAltLTxt: {
    position: 'absolute',
    bottom: 4,
    left: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dropzoneAltRTxt: {
    position: 'absolute',
    bottom: 4,
    right: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'right',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },

  avatar: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
  },
  avatarBox: {
    position: 'relative',
    width: 260,
    padding: theme.spacing(1),
    backgroundColor: '#f4f4f4',
    backgroundImage:
      'linear-gradient(45deg, #d0d0d0 25%, transparent 25%), linear-gradient(-45deg, #d0d0d0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #d0d0d0 75%), linear-gradient(-45deg, transparent 75%, #d0d0d0 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  },
  avatarImg: {
    display: 'block',
    maxWidth: 244,
    maxHeight: 144,
    margin: 'auto',
  },
  avatarIconBtn: {
    padding: theme.spacing(0.5),
  },

  hide: {
    display: 'none',
  },

  previewTitleTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  preview: {
    position: 'relative',
  },
  previewBar: {
    width: '100%',
    height: 64,
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.text.primary,
  },
  previewImageBox: {
    padding: 12,
    maxHeight: 40,
  },
  previewImage: {
    display: 'block',
    maxHeight: 40,
    margin: 'auto',
  },
  previewBox: {
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderTopWidth: 0,
    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.palette.background.paper,
  },
  previewSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  previewDefaultBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.text.contrast,
  },
  previewPrimaryBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.contrast,
  },
  previewSecondaryBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.contrast,
  },
  previewDefaultTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  previewPrimaryTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  previewSecondaryTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
}));
