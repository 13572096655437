// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import PlansGroupDriverList from './PlansGroupDriverList';
import PlansGroupBundleList from './PlansGroupBundleList';
import PlansGroupMoveList from './PlansGroupMoveList';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupContent({ drivers, moves, plans, regions, refetch }) {
  const cls = useStyles();

  return (
    <div className={cls.root}>
      <div className={cls.flex}>
        <div className={cls.pad} />

        <div className={cls.body}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='flex-start'
            wrap='nowrap'
            className={cls.height100}
          >
            <Grid item xs className={cls.height100}>
              <Grid container className={cls.bundle}>
                <Grid item className={cls.height100}>
                  <PlansGroupDriverList drivers={drivers} moves={moves} plans={plans} regions={regions} />
                </Grid>

                <Grid item xs className={cls.height100}>
                  <PlansGroupBundleList drivers={drivers} moves={moves} plans={plans} regions={regions} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={cls.height100}>
              <PlansGroupMoveList moves={moves} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },

  flex: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },

  pad: {
    position: 'relative',
    flexShrink: 0,
    width: '100%',
    height: 64 + 48,
  },

  body: {
    position: 'relative',
    flexGrow: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  bundle: {
    position: 'relative',
    overflow: 'auto',
    height: '100%',
  },
  height100: {
    height: '100%',
  },
}));
