import gql from 'graphql-tag';

function buildWhereClause(customerIdArray, moveStatusArray, cancelStatusArray, searchText, nullStatus) {
  // Construct the where clause dynamically based on provided parameters
  let searchCondition = ``
  if (searchText) {
    searchCondition = `{
      _or: [
        { reference_num: { _ilike: $searchText } },
        { vehicle_vin: { _ilike: $searchText } },
        { vehicle_make: { _ilike: $searchText } },
        { vehicle_model: { _ilike: $searchText } },
        { vehicle_color: { _ilike: $searchText } },
        { lane: { description: { _ilike: $searchText } } },
        { customer: { name: { _ilike: $searchText } } },
      ]},
      {_or: [
        { pickup_time: { _gte: $start, _lte: $end } },
        {
          _and: [
            { ready_by: { _gte: $start, _lte: $end } },
            { pickup_time: { _is_null: true } }
          ]
        }
      ]
    }`
  } else {
    searchCondition = `{_or: [
      { pickup_time: { _gte: $start, _lte: $end } }
      { _and: [{ ready_by: { _gte: $start, _lte: $end } }, { pickup_time: { _is_null: true } }] }
    ]}` 
  }

  let customerCondition = ``
  if (customerIdArray?.length > 0) {
    customerCondition = `{customer_id: { _in: $customerIdArray }}`
  }

  let moveStatusCondition = ``
  if (moveStatusArray?.length > 0) {
    if (nullStatus && cancelStatusArray.length > 0) {
      moveStatusCondition = `
      {
        _and: [
          { cancel_status: { _in: $cancelStatusArray } },
          { _or: [
              { status: { _is_null: true } },
              { status: { _in: $moveStatusArray } }
            ]
          }
        ]
      }`
    } else if (nullStatus && !cancelStatusArray.length) {
      moveStatusCondition = `
      {
        _or: [
          { status: { _is_null: true } },
          { status: { _in: $moveStatusArray } }
        ]
      }`
    } else if (!nullStatus && cancelStatusArray.length > 0) {
      moveStatusCondition = `
      {
        _and: [
          { cancel_status: { _in: $cancelStatusArray } },
          { status: { _in: $moveStatusArray } }
        ]
      }`
    } else if (!nullStatus && !cancelStatusArray.length) {
      moveStatusCondition =`{
        _and: [
          {status: { _in: $moveStatusArray }}, 
          {cancel_status: { _is_null: true }}
        ]
      }`
    }
  } else {
    if (nullStatus && cancelStatusArray.length > 0) {
      moveStatusCondition = `
      {
        _and: [
          { cancel_status: { _in: $cancelStatusArray } },
          { status: { _is_null: true } }
        ]
      }`
    } else if (nullStatus && !cancelStatusArray.length) {
      moveStatusCondition = `{status: { _is_null: true }}`
    } else moveStatusCondition = ``
  } 

  let cancelStatusCondition = ``
  if (cancelStatusArray?.length > 0) {
    cancelStatusCondition = moveStatusArray?.length > 0 ? 
    `{_and:[
      {cancel_status: { _in: $cancelStatusArray }},
      {status: { _in: $moveStatusArray }}
    ]}` 
    : 
    `{cancel_status: { _in: $cancelStatusArray }}`
  }

  let whereClause = `{
    active: { _eq: 1 },
    move_type: { _ilike: "drive" },
    _and: [${searchCondition} ${customerCondition} ${moveStatusCondition} ${cancelStatusCondition}],
  }`;

return whereClause;
}

function getMovesQuery(customerIdArray, moveStatusArray, cancelStatusArray, searchText, nullStatus) {
  const whereClause = buildWhereClause(customerIdArray, moveStatusArray, cancelStatusArray, searchText, nullStatus);

  const query = gql`
  query get_moves(
    $customerIdArray: [bigint!]
    $moveStatusArray: [String!]
    $cancelStatusArray: [String!]
    $start: timestamptz!
    $end: timestamptz!
    $limit: Int
    $offset: Int
    $order_by: moves_order_by!
    $searchText: String
  ) {
    moves(
      order_by: [$order_by]
      limit: $limit
      offset: $offset
      where: ${whereClause}
    ) {
      ...Move
    }
  }
  ${moveFragment}
  `;
  return query;
}
function getMoveCountQuery(customerIdArray, moveStatusArray, cancelStatusArray, searchText, nullStatus) {
  const whereClause = buildWhereClause(customerIdArray, moveStatusArray, cancelStatusArray, searchText, nullStatus);

  const query = gql`
  query get_move_count(
    $start: timestamptz!
    $end: timestamptz! 
    $searchText: String, 
    $customerIdArray: [bigint!]
    $moveStatusArray: [String!]    
    $cancelStatusArray: [String!]
    ) {
    moves_aggregate(
      where: ${whereClause}
    ) {
      aggregate {
        count
      }
    }
  }
`;
  return query;
}

const moveFragment = `
fragment Move on moves {
  id
  cancel_reason
  cancel_status
  customer_id
  dealer_contact
  delivery_arrived
  delivery_started
  delivery_successful
  delivery_workflow_data
  driver_id
  driver_name
  move_details
  move_type
  pickup_arrived
  pickup_started
  pickup_successful
  pickup_time
  pickup_workflow_data
  reference_num
  status
  vehicle_color
  vehicle_gross_weight_lbs
  vehicle_make
  vehicle_model
  vehicle_odometer
  vehicle_stock
  vehicle_vin
  vehicle_year
  customer {
    id
    name
  }
  lane {
    id
    description
    distance_miles
    pickup {
      id
      address
      name
    }
    delivery {
      id
      address
      name
    }
  }
  payer{
    id
    name
  }
}`;

export { getMovesQuery, getMoveCountQuery };
