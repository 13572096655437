import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import Queue from './Queue';
import {
  INSERT_MOVES,
  GET_PLAN_BY_DRIVER_ID,
  CREATE_PLAN_FOR_DRIVER,
  ASSIGN_MOVE_TO_DRIVER,
  INSERT_RIDEGROUPS,
} from '../../pages/GroupPlanning/gql';
import sdk from '@hopdrive/sdk';

/**
 * Builds a draft order for moves.
 *
 * It will group moves by lane and sort them by SLA.
 * Then order the lanes by earliest SLA.
 * Then rebuild the moves array.
 * @param {Array<Object>} moves - Array of moves to be sorted
 * @returns {Array<Object>} Array of moves sorted by lane and SLA
 */
export const buildDraftOrder = moves => {
  // Group moves by lane ID
  const movesByLane = moves.reduce((acc, move) => {
    const laneId = move.lane.id;
    if (!acc[laneId]) {
      acc[laneId] = [];
    }
    acc[laneId].push(move);
    return acc;
  }, {});

  // Sort within each lane by SLA/batch
  Object.values(movesByLane).forEach(laneMoves => {
    laneMoves.sort((a, b) => {
      const timeA = a.deliver_by;
      const timeB = b.deliver_by;
      return timeA - timeB;
    });
  });

  // Sort lanes by earliest SLA in each lane
  const sortedLanes = Object.entries(movesByLane).sort((a, b) => {
    const earliestSlaA = Math.min(...a[1].map(move => move.deliver_by));
    const earliestSlaB = Math.min(...b[1].map(move => move.deliver_by));
    return earliestSlaA - earliestSlaB;
  });

  // Flatten the sorted lanes into final result
  const result = [];
  sortedLanes.forEach(([_, laneMoves]) => {
    result.push(...laneMoves);
  });

  return result;
};

/**
 * Builds a chase plan for drivers and moves
 * @param {Object} params
 * @param {Object} params.chaseDriver - The chase driver object
 * @param {Array<Object>} params.drivers - Array of driver objects
 * @param {Array<Object>} params.draftMoves - Array of moves to be assigned
 * @param {Object} params.chaseVehicle - The chase vehicle object
 * @param {string|Date} params.start - Start time
 * @param {string|Date} params.end - End time
 * @returns {Object} Plan object containing chase driver and driver assignments
 */
export const buildChasePlan = ({ chaseDriver, drivers, draftMoves, chaseVehicle, start, end }) => {
  const { driverPlans, chaseMoves } = assignMovesToDrivers({ drivers, draftMoves, chaseVehicle, start, end });

  const plan = {
    chaseDriver: {
      driver: chaseDriver,
      moves: chaseMoves,
    },
    drivers: driverPlans,
  };

  console.log('plan: ', plan);

  return plan;
};

/**
 * Assigns moves to drivers
 * @param {Object} params
 * @param {Array<Object>} params.drivers - Array of driver objects
 * @param {Array<Object>} params.draftMoves - Array of ordermoves to be assigned
 * @param {Object} params.chaseVehicle - The chase vehicle object
 * @param {string|Date} params.start - Start time
 * @param {string|Date} params.end - End time
 * @returns {Object} Plan object containing chase driver and driver assignments
 */
const assignMovesToDrivers = ({ drivers, draftMoves, chaseVehicle, start, end }) => {
  const queue = new Queue(draftMoves);
  const driverPlans = drivers.map(driver => ({
    driver,
    moves: [],
  }));
  const chaseMoves = [];

  let currentTime = dayjs(start); // Initialize current time

  while (!queue.isEmpty()) {
    const firstMoveInIteration = queue.peek();
    let prevMove = null;
    const driversWithMovesThisIteration = new Set(); // Track which drivers got moves

    // check end time
    const enoughTimeForNextIteration = checkIfEnoughTimeForNextIteration({
      firstMoveInIteration,
      prevMove,
      currentTime,
      end,
    });

    if (!enoughTimeForNextIteration) {
      console.log('not enough time for next iteration, breaking');
      break;
    }
    // construct chase move with return move
    const chase = createChaseDriverMove({
      type: 'hopdriver',
      lane: firstMoveInIteration.lane,
      customer: firstMoveInIteration.customer,
      chaseVehicle,
      pickupTime: currentTime,
    });
    chaseMoves.push(chase);

    // Assign moves to drivers
    for (const driverPlan of driverPlans) {
      if (queue.isEmpty()) break;

      const nextMove = queue.peek();
      const remainingMovesInQueue = queue.size();
      const remainingDriversToAssign = driverPlans.length - driversWithMovesThisIteration.size;

      // Only create ride-along if there aren't enough moves for remaining drivers
      const shouldCreateRideAlong =
        !shouldAssignMove(prevMove, nextMove) && remainingMovesInQueue >= remainingDriversToAssign;

      if (shouldCreateRideAlong) {
        assignExtraRideToDriverPlan({ move: prevMove, driverPlan, chaseId: chase.chase_id, pickupTime: currentTime });
        driversWithMovesThisIteration.add(driverPlan); // Track this driver
      } else {
        const move = queue.dequeue();
        prevMove = move;
        assignMoveToDriverPlan({ move, driverPlan, chaseId: chase.chase_id, pickupTime: currentTime });
        driversWithMovesThisIteration.add(driverPlan); // Track this driver
      }
    }

    // Update current time based on the move duration
    currentTime = currentTime.add(prevMove.lane.duration_sec, 'second');

    // if next move is in different lane we may need a linking shuttle move/ride
    if (!queue.isEmpty() && !shouldAssignMove(prevMove, queue.peek())) {
      // TODO: Handle Create Lane here
      const linkingChase = createChaseDriverLinkingShuttle({
        type: 'hopdriver',
        prevMove,
        nextMove: queue.peek(),
        chaseVehicle,
        chaseId: chase.chase_id,
        pickupTime: currentTime,
      });
      pairChase(chase, linkingChase);
      chaseMoves.push(linkingChase);
      for (const driverPlan of driversWithMovesThisIteration) {
        assignShuttleRideToDriverPlan({
          shuttleMove: linkingChase,
          driverPlan,
          chaseId: chase.chase_id,
          pickupTime: currentTime,
        });
      }
      currentTime = currentTime.add(linkingChase.lane.duration_sec, 'second');
    } else {
      const returnChase = createChaseDriverMove({
        type: 'hopdriver',
        lane: firstMoveInIteration.lane.inverse,
        customer: firstMoveInIteration.customer,
        chaseVehicle,
        pickupTime: currentTime,
      });
      pairChase(chase, returnChase);
      chaseMoves.push(returnChase);
      for (const driverPlan of driversWithMovesThisIteration) {
        const isFirstDriver = Array.from(driversWithMovesThisIteration)[0] === driverPlan;
        assignReturnRideToDriverPlan({
          move: prevMove,
          driverPlan,
          chaseId: returnChase.chase_id,
          pickupTime: currentTime,
          isFirstDriver,
        });
      }
      currentTime = currentTime.add(returnChase.lane.duration_sec, 'second');
    }
  }

  return { driverPlans, chaseMoves };
};

/**
 * Assigns a move to a driver draft plan
 * @param {Object} params
 * @param {Object} params.move - The move to be assigned
 * @param {Object} params.driverPlan - The driver plan to assign the move to
 * @param {string} params.chaseId - The chase ID
 * @param {string|Date} params.pickupTime - The pickup time
 */
const assignMoveToDriverPlan = ({ move, driverPlan, chaseId, pickupTime }) => {
  const moveWithChase = {
    ...createDriverMove(move, formatToUTC(pickupTime)),
    chase_id: chaseId,
    config: {
      ...move.config,
      chase_id: chaseId,
    },
  };
  driverPlan.moves.push(moveWithChase);
};

/**
 * Assigns a return ride to a driver draft plan
 * @param {Object} params
 * @param {Object} params.move - The move to be assigned
 * @param {Object} params.driverPlan - The driver plan to assign the move to
 * @param {string} params.chaseId - The chase ID
 * @param {string|Date} params.pickupTime - The pickup time
 */
const assignReturnRideToDriverPlan = ({ move, driverPlan, chaseId, pickupTime, isFirstDriver }) => {
  // Assuming always ride after
  const lastMove = driverPlan.moves[driverPlan.moves.length - 1];
  const returnRideWithChase = {
    ...createDriverReturnRide(move, formatToUTC(pickupTime), lastMove.id),
    chase_id: chaseId,
    config: {
      ...(move.config || {}),
      ride_group_leader: isFirstDriver,
      chase_id: chaseId,
    },
  };
  driverPlan.moves.push(returnRideWithChase);
};

/**
 * Assigns an extra ride to a driver draft plan.
 * When the driver needs to ride along to get to the next set of moves.
 * @param {Object} params
 * @param {Object} params.move - The move to be assigned
 * @param {Object} params.driverPlan - The driver plan to assign the move to
 * @param {string} params.chaseId - The chase ID
 * @param {string|Date} params.pickupTime - The pickup time
 */
const assignExtraRideToDriverPlan = ({ move, driverPlan, chaseId, pickupTime }) => {
  const extraRideWithChase = {
    ...createDriverExtraRide(move, formatToUTC(pickupTime)),
    chase_id: chaseId,
  };
  driverPlan.moves.push(extraRideWithChase);
};

/**
 * Assigns a shuttle ride to a driver draft plan.
 * When the driver needs to ride along to get to the next set of moves.
 * @param {Object} params
 * @param {Object} params.shuttleMove - The shuttle move to be assigned
 * @param {Object} params.driverPlan - The driver plan to assign the shuttle move to
 * @param {string} params.chaseId - The chase ID
 * @param {string|Date} params.pickupTime - The pickup time
 */
const assignShuttleRideToDriverPlan = ({ shuttleMove, driverPlan, chaseId, pickupTime }) => {
  const shuttleRideWithChase = {
    ...createDriverExtraRide(shuttleMove, formatToUTC(pickupTime)),
    chase_id: chaseId,
  };
  driverPlan.moves.push(shuttleRideWithChase);
};

/**
 * Creates a driver move
 * @param {Object} params
 * @param {Object} params.move - The move to be created
 * @param {string|Date} params.pickupTime - The pickup time
 * @returns {Object} The driver move
 */
const createDriverMove = (move, pickupTime) => ({
  ...move,
  pickup_time: formatToUTC(pickupTime),
  config: {
    ...move.config,
  },
});

/**
 * Creates a driver return ride
 * @param {Object} params
 * @param {Object} params.move - The move to be created
 * @param {string|Date} params.pickupTime - The pickup time
 * @param {string} params.lastMoveId - The last move ID
 * @returns {Object} The driver return ride
 */
const createDriverReturnRide = (move, pickupTime, lastMoveId) => ({
  ...move,
  id: null,
  move_type: 'ride',
  ride_type: 'shuttle',
  deliver_by: null,
  lane: move.lane?.inverse,
  parent_move_id: lastMoveId,
  pickup_time: formatToUTC(pickupTime),
  config: {
    ...move.config,
  },
});

/**
 * Creates a driver extra ride. For the ride along scenario.
 * @param {Object} params
 * @param {Object} params.move - The move to be created
 * @param {string|Date} params.pickupTime - The pickup time
 * @returns {Object} The driver extra ride
 */
const createDriverExtraRide = (move, pickupTime) => ({
  ...move,
  id: null,
  move_type: 'ride',
  ride_type: 'shuttle',
  deliver_by: null,
  lane: move.lane,
  pickup_time: formatToUTC(pickupTime),
  config: {
    ...move.config,
  },
});

/**
 * Creates a chase driver move
 * @param {Object} params
 * @param {string} params.type - The type of move
 * @param {Object} params.lane - The lane to be created
 * @param {Object} params.customer - The customer to be created
 * @param {Object} params.chaseVehicle - The chase vehicle to be created
 * @param {string|Date} params.pickupTime - The pickup time
 * @returns {Object} The chase driver move
 */
const createChaseDriverMove = ({ type, lane, customer, chaseVehicle, pickupTime }) => ({
  type,
  id: null,
  move_type: 'drive',
  deliver_by: null,
  lane: lane,
  customer: customer,
  vehicle_make: chaseVehicle?.make,
  vehicle_model: chaseVehicle?.model,
  vehicle_vin: chaseVehicle?.vin,
  chase_id: uuidv4(), // todo: move to config?
  pickup_time: formatToUTC(pickupTime),
  config: {
    isShuttle: true,
    ...chaseVehicle?.config,
  },
});

/**
 * Creates a chase driver linking shuttle. This is used when all moves at the first lane is done
 * and the chase driver needs to shuttle all the drivers to the next lane.
 * @param {Object} params
 * @param {string} params.type - The type of move
 * @param {Object} params.prevMove - The previous move
 * @param {Object} params.nextMove - The next move
 * @param {Object} params.chaseVehicle - The chase vehicle
 * @param {string} params.chaseId - The chase ID
 * @param {string|Date} params.pickupTime - The pickup time
 * @returns {Object} The chase driver linking shuttle
 */
const createChaseDriverLinkingShuttle = ({ type, prevMove, nextMove, chaseVehicle, chaseId, pickupTime }) => {
  const shuttleMove = {
    type,
    id: null,
    move_type: 'drive',
    deliver_by: null,
    lane: {
      id: null,
      distance_miles: prevMove.lane.distance_miles,
      duration_sec: prevMove.lane.duration_sec,
      pickup: prevMove.lane.delivery,
      delivery: nextMove.lane.pickup,
      inverse: null,
    },
    customer: nextMove.customer,
    vehicle_make: chaseVehicle?.make,
    vehicle_model: chaseVehicle?.model,
    vehicle_vin: chaseVehicle?.vin,
    chase_id: chaseId,
    pickup_time: formatToUTC(pickupTime),
    // I don't think we need the config of chase here
    // as it would be a linking shuttle between 2 lanes
    config: {
      isShuttle: true,
    },
  };

  console.log('Created shuttle move:', shuttleMove);
  return shuttleMove;
};

/**
 * Pairs two chase moves. Allows for cost accounting.
 * @param {Object} params
 * @param {Object} params.initialChase - The initial chase
 * @param {Object} params.secondChase - The second chase
 */
const pairChase = (initialChase, secondChase) => {
  initialChase.config = {
    ...initialChase.config,
    chase_id: initialChase.chase_id,
  };
  secondChase.config = {
    ...secondChase.config,
    parent_chase_id: initialChase.chase_id,
    chase_id: secondChase.chase_id,
  };
};

/**
 * Checks if a move should be assigned to a driver.
 *
 * @param {Object} params
 * @param {Object} params.prevMove - The previous move
 * @param {Object} params.nextMove - The next move
 * @returns {boolean} True if the move should be assigned, false otherwise
 */
const shouldAssignMove = (prevMove, nextMove) => {
  if (!nextMove) {
    return false;
  }
  if (!prevMove) {
    return true;
  }
  // check if lane same as previous lane
  return prevMove.lane.id === nextMove.lane.id;
};

/**
 * Checks if there is enough time for the next iteration.
 * @param {Object} params
 * @param {Object} params.firstMoveInIteration - The first move in the iteration
 * @param {Object} params.prevMove - The previous move
 * @param {string|Date} params.currentTime - The current time
 * @param {string|Date} params.end - The end time
 * @returns {boolean} True if there is enough time, false otherwise
 */
const checkIfEnoughTimeForNextIteration = ({ firstMoveInIteration, prevMove, currentTime, end }) => {
  const nextMoveTime = currentTime.add(firstMoveInIteration.lane.duration_sec, 'second');
  return nextMoveTime.isBefore(end);
};

const formatToUTC = time => {
  return dayjs(time).utc().format('YYYY-MM-DDTHH:mm:ssZ');
};

export const convertPlanToComponentFormat = plan => {
  const plans = [];

  // Only add chase driver plan if it exists
  if (plan.chaseDriver?.driver) {
    plans.push({
      driver: plan.chaseDriver.driver,
      moves: plan.chaseDriver.moves.map(moveWrapper => moveWrapper.move || moveWrapper),
      isSpecial: true,
    });
  }

  // Add regular driver plans
  return [
    ...plans,
    ...plan.drivers.map(({ driver, moves }) => ({
      driver,
      moves: moves.map(moveWrapper => moveWrapper.move || moveWrapper),
      isSpecial: false,
    })),
  ];
};

//
// SAVE DRIVER PLANS
//

export const createPlans = async chaseDraftPlan => {
  const {
    chaseDriver: { driver, moves },
    drivers,
  } = chaseDraftPlan;

  const allDrivers = [driver, ...drivers.map(d => d.driver)];
  const planDate = moves[0].pickup_time;
  const regionId = moves[0].lane.pickup.region.id;
  console.log(`allDrivers: `, allDrivers);

  const plans = [];

  // Check and create plans for each driver
  for (const driver of allDrivers) {
    const existingPlan = await sdk.gql.query(GET_PLAN_BY_DRIVER_ID, {
      driverId: driver.id,
      planDate: dayjs(planDate).format(),
    });

    if (existingPlan && existingPlan.data.length > 0) {
      plans.push(existingPlan.data[0]);
    } else {
      // Create new plan and add to plans array
      const newPlan = await sdk.gql.mutation(CREATE_PLAN_FOR_DRIVER, {
        driverId: driver.id,
        planDate: dayjs(planDate).format(),
        regionId: regionId,
      });
      plans.push(newPlan.data[0]);
    }
  }
  console.log(`plans: `, plans);
  return plans;
};

export const createBatchJob = async (driverDraftPlan, username) => {
  console.log(`driver: `, driverDraftPlan);

  const batchId = uuidv4();
  const jobInput = {
    batch_id: batchId,
    input: driverDraftPlan,
    sequence: 0,
    trigger_type: 'batchPlan',
    createdat: 'now()',
    createdby: username,
    updatedat: 'now()',
    updatedby: username,
    delay_ms: 0,
    delay_key: null,
  };

  const INSERT_BATCH_JOB = `
  mutation insert_driver_mass_payment_batch_job($job: batch_jobs_insert_input!) {
    insert_batch_jobs_one(object: $job) {
      id
      batch_id
    }
  }
`;

  const { data, errors } = await sdk.gql.mutation(INSERT_BATCH_JOB, {
    job: jobInput,
  });

  if (errors) {
    console.error(`Failed to create batch job: `, errors);
    throw new Error(`Failed to create batch job: `, errors);
  }

  return data;
};

export const saveChasePlan = async (chaseDraftPlan, createdPlans) => {
  const {
    chaseDriver: { driver, moves },
  } = chaseDraftPlan;
  console.log(`moves: `, moves);
  console.log(`createdPlans: `, createdPlans);
  console.log(`driver: `, driver);

  const driverPlanId = createdPlans.find(plan => plan.driver_id === driver.id).id;
  const insertedMoves = new Map();

  const insertAndAssignMove = async (move, parentMoveId = null) => {
    const formattedMove = await buildMove({
      ...move,
      parent_move_id: parentMoveId,
    });

    const {
      data: [{ id: moveId }],
    } = await sdk.gql.mutation(INSERT_MOVES, {
      movesObjectArray: [formattedMove],
    });

    if (move.chase_id) {
      insertedMoves.set(move.chase_id, moveId);
    }

    await sdk.gql.mutation(ASSIGN_MOVE_TO_DRIVER, {
      moveId,
      driver_app_version: driver.driver_app_version,
      driverId: driver.id,
      planId: driverPlanId,
      pickupTime: move.pickup_time,
      config: move.config,
      driverStatus: 'draft',
      updatedat: 'now()',
      updatedBy: 'auto-planner',
    });

    return moveId;
  };

  await Promise.all(
    moves.filter(move => move.chase_id && !move.config?.parent_chase_id).map(move => insertAndAssignMove(move))
  );

  await Promise.all(
    moves
      .filter(move => move.config?.parent_chase_id)
      .map(move => insertAndAssignMove(move, insertedMoves.get(move.config.parent_chase_id)))
  );
};

export const saveDriverPlans = async (driverDraftPlan, createdPlans) => {
  const { drivers } = driverDraftPlan;

  await Promise.all(
    drivers.map(async ({ driver, moves }) => {
      const driverPlan = createdPlans.find(plan => plan.driver_id === driver.id);
      if (!driverPlan) {
        throw new Error(`No plan found for driver ${driver.id}`);
      }
      console.log(`driverPlan: `, driverPlan);
      console.log('driverDraftPlan: ', driverDraftPlan);

      const mainMoves = moves.filter(m => !(m.move || m).parent_move_id);

      await Promise.all(
        mainMoves.map(async moveWrapper => {
          const mainMove = moveWrapper.move || moveWrapper;
          const returnRide = moves.find(m => (m.move || m).parent_move_id === mainMove.id);
          console.log(`mainMove: `, mainMove);
          console.log(`returnRide: `, returnRide);
          console.log(`returnRide.move: `, returnRide.move);

          await assignMoveToDriver({
            moveId: mainMove.id,
            driverId: driver.id,
            planId: driverPlan.id,
            move: mainMove,
            status: 'assigned',
          });

          if (returnRide) {
            const returnRideId = await createAndAssignReturnRide({
              returnRide: returnRide.move || returnRide,
              parentMoveId: mainMove.id,
              driverId: driver.id,
              planId: driverPlan.id,
            });
            try {
              await assignMovesToRideGroup({
                moveId: mainMove.id,
                chaseId: returnRide.chase_id,
                isRideGroup: returnRide.config?.ride_group_leader,
              });
            } catch (error) {
              console.error(`Failed to assign moves to ride group: `, error);
            }
          }
        })
      );
    })
  );
};

const assignMovesToRideGroup = async (moveId, chaseId, isRideGroup) => {
  const rideGroup = {
    group_id: chaseId,
    drive_move_id: moveId,
    status: isRideGroup ? 'ready' : 'not ready',
    read_at_time: isRideGroup ? 'now()' : null,
  };

  const updatedRideGroup = await sdk.gql.mutation(INSERT_RIDEGROUPS, {
    ridegroupsObjectArray: [rideGroup],
  });

  return updatedRideGroup;
};

const assignMoveToDriver = async ({ moveId, driverId, planId, move, status = 'draft' }) => {
  return sdk.gql.mutation(ASSIGN_MOVE_TO_DRIVER, {
    moveId,
    driverId,
    planId,
    status,
    pickupTime: move.pickup_time,
    config: move.config,
    driverStatus: 'draft',
    updatedat: 'now()',
    updatedBy: 'auto-planner',
  });
};

const createAndAssignReturnRide = async ({ returnRide, parentMoveId, driverId, planId }) => {
  console.log(`returnRide: `, returnRide);
  const returnMove = {
    // might not need to build move ?
    ...returnRide,
    move_type: 'ride',
    ride_type: 'shuttle',
    parent_move_id: parentMoveId,
  };
  console.log(`returnMove: `, returnMove);

  const result = await sdk.gql.mutation(INSERT_MOVES, {
    movesObjectArray: [await buildMove(returnMove)],
  });

  const returnMoveId = result.data[0].id;

  await assignMoveToDriver({
    moveId: returnMoveId,
    driverId,
    planId,
    move: returnMove,
    status: 'not called',
  });

  return returnMoveId;
};

const buildMove = async move => {
  console.log(`move: `, move);
  return {
    chargeable: true,
    class: 'stranded',
    config: move.config,
    customer_id: move.customer.id,
    pickup_time: move.pickup_time,
    reference_num: move.referenceNum,
    move_details: move.move_details || '',
    lane_id: move.lane.id,
    vehicle_color: move.vehicle_color || null,
    vehicle_make: move.vehicle_make || null,
    vehicle_model: move.vehicle_model || null,
    vehicle_stock: move.vehicle_stock || null,
    vehicle_vin: move.vehicle_vin || null,
    vehicle_year: move.vehicle_year || null,
    parent_move_id: move.parent_move_id || null,
    move_type: move.move_type || null,
    ride_type: move.ride_type || null,
  };
};
