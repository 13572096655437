import React from 'react';
import { makeStyles, Grid, Typography, TextField, MenuItem, Tooltip, IconButton, Icon } from '@material-ui/core';
import { Button, Loading, Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';

import { useQuery, useMutation, gql } from '@apollo/client';

import { useTools } from '../../hooks/useTools';
import { toast } from 'react-toastify';

const log = false;

const defaultWorkflowset = {
  default_workflow_set_id: 1,
  default_pickup_workflow_id: 1,
  default_delivery_workflow_id: 2,
};

//////////////////////// COMPONENT ////////////////////////

export default function WorkflowConfigModal({
  defaultWorkflowSetId,
  defaultPickupWorkflowId,
  defaultDeliveryWorkflowId,
  organizationId,
  customerId,
  config,
  onClose,
}) {
  const cls = useStyles();
  const { copyToClipboard } = useTools();

  const [isSaving, setIsSaving] = React.useState(false);

  const [myDefaultWorkflowSetId, setMyDefaultWorkflowSetId] = React.useState(1);
  const [myDefaultPickupWorkflowId, setMyDefaultPickupWorkflowId] = React.useState(1);
  const [myDefaultDeliveryWorkflowId, setMyDefaultDeliveryWorkflowId] = React.useState(2);

  const [name, setName] = React.useState(``);
  const [restriction, setRestriction] = React.useState(`customer`);
  const [description, setDescription] = React.useState(``);
  const [pickupWorkflowId, setPickupWorkflowId] = React.useState(1);
  const [deliveryWorkflowId, setDeliveryWorkflowId] = React.useState(2);

  const [saveDefaultWorkflowSet] = useMutation(SAVE_DEFAULT_WORKFLOW_SET);
  const [removeWorkflowSet] = useMutation(REMOVE_WORKFLOW_SET);
  const [createWorkflowSet] = useMutation(CREATE_WORKFLOW_SET);

  // Initial set of the modal
  React.useEffect(() => {
    setMyDefaultWorkflowSetId(defaultWorkflowSetId || 1);
    setMyDefaultPickupWorkflowId(defaultPickupWorkflowId || 1);
    setMyDefaultDeliveryWorkflowId(defaultDeliveryWorkflowId || 2);
  }, [defaultWorkflowSetId, defaultPickupWorkflowId, defaultDeliveryWorkflowId]);

  //////////////////// CHANGE HANDLERS ////////////////////

  // Handle updating the default workflow set
  const handleDefaultChange = allowedWorkflowSets => event => {
    const val = event.target.value || 1;
    setMyDefaultWorkflowSetId(val);

    const defaultWorkflowSet = allowedWorkflowSets.find(aws => aws.id === val);
    setMyDefaultPickupWorkflowId(defaultWorkflowSet.pickup_workflow_id);
    setMyDefaultDeliveryWorkflowId(defaultWorkflowSet.delivery_workflow_id);
  };

  // Handle an input change
  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val || ``);
  };

  // Handle a workflow id change
  const handleWorkflowIdChange = type => event => {
    const val = event.target.value;
    if (type === `pickup`) setPickupWorkflowId(val || 0);
    if (type === `delivery`) setDeliveryWorkflowId(val || 0);
  };

  // Handle clearing the add workflow set form
  const handleClearForm = () => {
    setName(``);
    setRestriction(`customer`);
    setDescription(``);
    setPickupWorkflowId(1);
    setDeliveryWorkflowId(2);
  };

  //////////////////// MUTATION HANDLERS ////////////////////

  // Handle saving the default workflow set
  const handleSaveDefaultWorkflowSet = async (refetch, overrides = null) => {
    let newDefaultWorkflowSetId = myDefaultWorkflowSetId || null;
    let newDefaultPickupWorkflowId = myDefaultPickupWorkflowId || null;
    let newDefaultDeliveryWorkflowId = myDefaultDeliveryWorkflowId || null;

    if (overrides) {
      newDefaultWorkflowSetId = overrides.default_workflow_set_id;
      newDefaultPickupWorkflowId = overrides.default_pickup_workflow_id;
      newDefaultDeliveryWorkflowId = overrides.default_delivery_workflow_id;
    }

    const variables = {
      customerId: customerId,
      config: {
        ...config,
        default_workflow_set_id: newDefaultWorkflowSetId,
        default_pickup_workflow_id: newDefaultPickupWorkflowId,
        default_delivery_workflow_id: newDefaultDeliveryWorkflowId,
      },
    };

    try {
      const res = await saveDefaultWorkflowSet({ variables: variables });
      if (res?.data?.update_customers?.returning) {
        log && console.log(`Successfully saved default workflow set:`, res.data.update_customers.returning[0]);
        toast.success(`Successfully saved default workflow set!`);
        refetch();
      }
    } catch (err) {
      console.error(`Failed to save default workflow set:`, err);
      toast.error(`Failed to save default workflow set!`);
    }

    setIsSaving(false);
  };

  // Handle removing a workflow set
  const handleRemoveWorkflowSet = async (workflowSetId, refetch) => {
    const variables = {
      workflowSetId: workflowSetId,
    };

    try {
      const res = await removeWorkflowSet({ variables: variables });
      if (res?.data?.update_workflowsets?.returning) {
        log && console.log(`Successfully removed workflow set:`, res.data.update_workflowsets.returning[0]);
        toast.success(`Successfully removed workflow set!`);
        if (myDefaultWorkflowSetId === workflowSetId) {
          handleSaveDefaultWorkflowSet(refetch, defaultWorkflowset);
          setMyDefaultWorkflowSetId(1);
        } else {
          refetch();
        }
      }
    } catch (err) {
      console.error(`Failed to remove workflow set:`, err);
      toast.error(`Failed to remove workflow set!`);
    }
  };

  // Handle creating a new workflow set
  const handleCreateWorkflowSet = async () => {
    if (!name || !description) {
      console.warn(`Could not create workflow set, name or description is missing!`);
      toast.warning(`Could not create workflow set, name or description is missing!`);
      setIsSaving(false);
      return;
    }

    const variables = {
      workflowSetObj: {
        name: name || null,
        description: description || null,
        public: restriction === `public` ? true : false,
        organization_id: restriction === `organization` ? organizationId : null,
        customer_id: restriction === `customer` ? customerId : null,
        pickup_workflow_id: pickupWorkflowId || null,
        delivery_workflow_id: deliveryWorkflowId || null,
      },
    };

    try {
      const res = await createWorkflowSet({ variables: variables });
      if (res?.data?.insert_workflowsets?.returning) {
        log && console.log(`Successfully created workflow set:`, res.data.insert_workflowsets.returning[0]);
        toast.success(`Successfully created workflow set!`);
        handleClearForm();
        refetch();
      }
    } catch (err) {
      console.error(`Failed to create workflow set:`, err);
      toast.error(`Failed to create workflow set!`);
    }

    setIsSaving(false);
  };

  // Handle closing of the form
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  //////////////////// QUERY ////////////////////

  const { loading, error, data, refetch } = useQuery(GET_WORKFLOWS, {
    variables: {
      organizationId: organizationId || 0,
      customerId: customerId || 0,
    },
  });

  // LOADING STATE //
  if (loading) {
    return (
      <>
        <ModalHeader onClose={onClose}>Workflow Config</ModalHeader>
        <ModalContent>
          <Loading />
        </ModalContent>
        <ModalFooter>
          <ModalAction onClick={() => handleClose()}>Close</ModalAction>
        </ModalFooter>
      </>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Failed to fetch workflow sets:`, error);
    return (
      <>
        <ModalHeader onClose={onClose}>Workflow Config</ModalHeader>
        <ModalContent>
          <Typography className={cls.notitle}>Error fetching workflow sets. Please contact an admin.</Typography>
        </ModalContent>
        <ModalFooter>
          <ModalAction onClick={() => handleClose()}>Close</ModalAction>
        </ModalFooter>
      </>
    );
  }

  // EMPTY STATE //
  if (!data || !data.workflowsets || !data.workflowsets.length) {
    return (
      <>
        <ModalHeader onClose={onClose}>Workflow Config</ModalHeader>
        <ModalContent>
          <Typography className={cls.notitle}>No workflow sets found. Please contact an admin.</Typography>
        </ModalContent>
        <ModalFooter>
          <ModalAction onClick={() => handleClose()}>Close</ModalAction>
        </ModalFooter>
      </>
    );
  }

  // DATA STATE //
  const allowedWorkflowSets = data.workflowsets || [];
  const allWorkflows = data.workflows || [];
  const pickupWorkflows = allWorkflows.filter(wf => wf.type === `pickup`) || [];
  const deliveryWorkflows = allWorkflows.filter(wf => wf.type === `delivery`) || [];

  log && console.log(`Allowed Workflow Sets:`, allowedWorkflowSets);
  log && console.log(`All Workflows:`, allWorkflows);

  //////////////////// QUERY ////////////////////

  return (
    <>
      <ModalHeader onClose={onClose}>Workflow Config</ModalHeader>
      <ModalContent>
        {/* ALLOWED WORKFLOW SETS */}

        <Typography className={cls.title}>Edit Allowed Workflow Sets</Typography>

        <Typography className={cls.subtitle}>
          Edit allowed workflow sets for customers when they create their moves.
        </Typography>

        {allowedWorkflowSets.map((aws, i) => (
          <React.Fragment key={`allowed-workflow-set-${i}`}>
            {i !== 0 ? <Spacer size='xs' /> : null}

            <div className={cls.workflowSet}>
              <Grid container alignItems='center' wrap='nowrap'>
                <Grid item xs>
                  <Typography className={cls.workflowSetTxt}>
                    {aws.id} - {aws.name}
                  </Typography>
                </Grid>

                <Grid item>
                  <Tooltip
                    title={aws.public ? `Cannot remove public workflow set` : `Remove workflow set`}
                    placement='top'
                  >
                    <div>
                      <IconButton
                        className={cls.iconBtn}
                        onClick={() => handleRemoveWorkflowSet(aws.id, () => refetch())}
                        disabled={aws.public}
                      >
                        <Icon className={aws.public ? cls.disabledCancelIcon : cls.cancelIcon}>cancel</Icon>
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        ))}

        <Spacer size='lg' />

        {/* DEFAULT WORKFLOW SET */}

        <Typography className={cls.title}>Edit Default Workflow Set</Typography>

        <Typography className={cls.subtitle}>
          Choose a default from this customer's allowed workflow sets. The default workflow set is the preselected
          workflow set that is chosen for the customer when creating a move.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              select
              fullWidth
              label={`Default Workflow Set`}
              size='small'
              variant='outlined'
              value={myDefaultWorkflowSetId}
              onChange={handleDefaultChange(allowedWorkflowSets)}
            >
              <MenuItem className={cls.placeholder} value={0}>
                <em>Select a workflow set</em>
              </MenuItem>
              {allowedWorkflowSets.map((aws, i) => (
                <MenuItem key={`default-workflow-set-${i}`} className={cls.menuItem} value={aws.id}>
                  <Typography className={cls.nameTxt}>
                    {aws.id} - {aws.name}
                  </Typography>
                  <Typography className={cls.descTxt}>{aws.description}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <Button
              className={cls.btnLg}
              color='primary'
              onClick={() => {
                setIsSaving(true);
                handleSaveDefaultWorkflowSet(() => refetch());
              }}
              loading={isSaving}
              disabled={isSaving}
            >
              <Icon className={cls.btnIcon}>save</Icon>Save
            </Button>
          </Grid>
        </Grid>

        <Spacer size='lg' />

        {/* NEW WORKFLOW SET FORM */}

        <Typography className={cls.title}>Add New Workflow Set</Typography>

        <Typography className={cls.subtitle}>
          Create a new public or private workflow set for this customer/organization to use. Please contact tech to help
          with building the workflow you want.
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label='Name'
              placeholder='Enter a workflow set name...'
              size='small'
              variant='outlined'
              value={name}
              onChange={handleInputChange(setName)}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              select
              fullWidth
              label='Restriction'
              size='small'
              variant='outlined'
              value={restriction}
              onChange={handleInputChange(setRestriction)}
            >
              <MenuItem value={`customer`} disabled={customerId ? false : true}>
                Customer
              </MenuItem>
              <MenuItem value={`organization`} disabled={organizationId ? false : true}>
                Organization
              </MenuItem>
              <MenuItem value={`public`}>Public</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Description'
              placeholder='Enter a workflow set description...'
              size='small'
              variant='outlined'
              value={description}
              onChange={handleInputChange(setDescription)}
            />
          </Grid>

          {pickupWorkflows.length ? (
            <Grid item md={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Pickup Workflow'
                size='small'
                variant='outlined'
                value={pickupWorkflowId}
                onChange={handleWorkflowIdChange(`pickup`)}
              >
                <MenuItem className={cls.placeholder} value={0}>
                  <em>Select Pickup Workflow</em>
                </MenuItem>
                {pickupWorkflows.map((pw, i) => (
                  <MenuItem key={`pickup-workflow-${i}`} className={cls.menuItem} value={pw.id}>
                    <Typography className={cls.nameTxt}>
                      {pw.id} - {pw.name}
                    </Typography>
                    <Typography className={cls.descTxt}>{pw.description}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}

          {deliveryWorkflows.length ? (
            <Grid item md={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Delivery Workflow'
                size='small'
                variant='outlined'
                value={deliveryWorkflowId}
                onChange={handleWorkflowIdChange(`delivery`)}
              >
                <MenuItem className={cls.placeholder} value={0}>
                  <em>Select Delivery Workflow</em>
                </MenuItem>
                {deliveryWorkflows.map((dw, i) => (
                  <MenuItem key={`delivery-workflow-${i}`} className={cls.menuItem} value={dw.id}>
                    <Typography className={cls.nameTxt}>
                      {dw.id} - {dw.name}
                    </Typography>
                    <Typography className={cls.descTxt}>{dw.description}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}

          <Grid item xs />

          <Grid item>
            <Button
              className={cls.btn}
              color='primary'
              onClick={() => {
                setIsSaving(true);
                handleCreateWorkflowSet(() => refetch());
              }}
              loading={isSaving}
              disabled={isSaving}
            >
              <Icon className={cls.btnIcon}>add</Icon>Create
            </Button>
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 600,
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  notitle: {
    lineHeight: 1.25,
    fontSize: 21,
    color: theme.palette.error.main,
  },

  workflowSet: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.fade[0],
  },
  workflowSetTxt: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
  },

  placeholder: {
    color: theme.palette.text.secondary,
  },

  menuItem: {
    display: 'block',
  },
  nameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  descTxt: {
    whiteSpace: 'wrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  iconBtn: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  disabledCancelIcon: {
    color: theme.palette.text.disabled,
  },

  btn: {
    minHeight: 40,
  },
  btnLg: {
    minHeight: 53,
  },
  btnIcon: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_WORKFLOWS = gql`
  query get_workflows($organizationId: bigint, $customerId: bigint) {
    workflowsets(
      where: {
        _or: [
          { public: { _eq: true } }
          { organization_id: { _eq: $organizationId } }
          { customer_id: { _eq: $customerId } }
        ]
      }
      order_by: { id: asc }
    ) {
      id
      customer_id
      delivery_workflow_id
      description
      name
      organization_id
      pickup_workflow_id
      public
    }

    workflows(order_by: { id: asc }) {
      id
      description
      name
      type
      version
    }
  }
`;

const SAVE_DEFAULT_WORKFLOW_SET = gql`
  mutation save_default_workflow_set($customerId: bigint!, $config: jsonb!) {
    update_customers(where: { id: { _eq: $customerId } }, _set: { config: $config }) {
      affected_rows
      returning {
        id
        name
        config
      }
    }
  }
`;

const REMOVE_WORKFLOW_SET = gql`
  mutation remove_workflow_set($workflowSetId: Int!) {
    update_workflowsets(where: { id: { _eq: $workflowSetId } }, _set: { organization_id: null, customer_id: null }) {
      affected_rows
      returning {
        id
        customer_id
        description
        name
        organization_id
      }
    }
  }
`;

const CREATE_WORKFLOW_SET = gql`
  mutation create_workflow_set($workflowSetObj: workflowsets_insert_input!) {
    insert_workflowsets(objects: [$workflowSetObj]) {
      affected_rows
      returning {
        id
        customer_id
        delivery_workflow_id
        description
        name
        organization_id
        pickup_workflow_id
        public
      }
    }
  }
`;
