import { gql } from '@apollo/client';

const GET_ARMOVES = (status, customerIdArray) => gql`
  query get_armoves($status: String, $customerIdArray: [bigint!], $start: timestamptz!, $end: timestamptz!) {
    armoves(
      where: {
        active: { _eq: 1 }
        ${status ? `status: { _eq: $status }` : ''} 
        move: { 
          ${customerIdArray && Array.isArray(customerIdArray) && customerIdArray.length ? `customer_id: { _in: $customerIdArray }` : ''} 
          active: { _eq: 1 } 
        }
        billable_datetime: { _gte: $start, _lt: $end }
      }
      order_by: { id: desc }
    ) {
      id
      active
      type
      move_id
      invoice_id
      billable_datetime
      discount_amount
      discount_reason
      disputed
      dispute_reason
      due_amount
      paid_amount
      status
      invoice {
        id
        customer{
          id
          name
        }
      }
      move {
        active
        id
        customer_id
        pickup_time
        lane {
          id
          description
          pickup {
            id
            region {
              id
              name
            }
          }
        }
        customer {
          id
          name
        }
      }
    }
  }
`;

const GET_ARPAYMENTS = (status, customerIdArray) => gql`
  query get_arpayments($status: String, $customerIdArray: [bigint!], $start: timestamptz!, $end: timestamptz!) {
    arpayments(
      where: {
        ${status ? `status: { _eq: $status },` : ''} 
        ${customerIdArray && Array.isArray(customerIdArray) && customerIdArray.length ? `arinvoice: {customer_id: { _in: $customerIdArray }},` : ''} 
        createdat: { _gte: $start, _lte: $end }
      }
      order_by: { id: desc }
    ) {
      id
      accounting_id
      gateway_transaction_id
      createdat
      status
      amount
      arinvoice {
        id
        customer {
          id
          name
        }
      }
    }
  }
`;

export { GET_ARMOVES, GET_ARPAYMENTS };
