// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';

import { PlansGroupProvider } from './PlansGroupProvider';
import PlansGroupQuery from './PlansGroupQuery';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroup(props) {
  const date = props?.location?.state?.date;
  const preselectedDriverIds = props?.location?.state?.driverIds;
  const preselectedMoveIds = props?.location?.state?.moveIds;
  const preselectedRegionIds = props?.location?.state?.regionIds;

  return (
    <PlansGroupProvider
      date={date}
      preselectedDriverIds={preselectedDriverIds}
      preselectedMoveIds={preselectedMoveIds}
      preselectedRegionIds={preselectedRegionIds}
    >
      <PlansGroupQuery />
    </PlansGroupProvider>
  );
}
