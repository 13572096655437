//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  frequency: `billing_frequency`,
  autoPay: `auto_pay`,
};

// DEFAULTS //
const getDefaultFrequency = overrideRef => {
  return getPropValue(overrideRef, option?.frequency);
};
const getDefaultAutoPay = overrideRef => {
  return getPropValue(overrideRef, option?.autoPay);
};

//////////////////////// COMPONENT ////////////////////////

export default function Billing({ settingId, overrideRef, updateMutableRef = () => {} }) {
  const cls = useStyles();

  // Manage state of options
  const [frequency, setFrequency] = React.useState(getDefaultFrequency(overrideRef));
  const [autoPay, setAutoPay] = React.useState(getDefaultAutoPay(overrideRef));

  // Handle frequency change
  const handleFrequencyChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { billing_frequency: value };
    setFrequency(value);
    updateMutableRef(changes);
  };

  // Handle auto pay change
  const handleAutoPayChange = () => {
    // Get the value
    const value = !autoPay;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { auto_pay: value };
    setAutoPay(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Billing`}
        tip={`The billing module allows us to setup billing preferences for the client. Changing these settings will affect how our accounts receivable (AR) system will treat the client from a billing standpoint.`}
      />

      <SettingsOption
        contained
        title={`Frequency`}
        description={`Billing frequency determines how often we generate invoices for this client, which directly affects how often they are billed. This should be set to monthly, unless the client specifically requests otherwise.`}
      >
        <FormControl>
          <RadioGroup value={frequency} onChange={handleFrequencyChange}>
            <FormControlLabel
              label='Monthly'
              value='monthly'
              control={<Radio className={cls.radio} color='primary' />}
            />
            {/* <FormControlLabel label='Biweekly' value='biweekly' control={<Radio className={cls.radio} color='primary' />} /> */}
            <FormControlLabel label='Weekly' value='weekly' control={<Radio className={cls.radio} color='primary' />} />
            <FormControlLabel
              label='Daily'
              value='daily'
              control={<Radio className={cls.radio} color='primary' />}
              disabled
            />
          </RadioGroup>
        </FormControl>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Auto-Pay`}
        description={`Activate/Deactivate auto-pay for this client. Auto-pay determines whether or not we automatically charge the client's credit card after an invoice closes.`}
      >
        <Switch color='primary' checked={autoPay} onChange={() => handleAutoPayChange()} />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  radio: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
