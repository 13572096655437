import React from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container } from '@material-ui/core';

import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import RulesBanner from './RulesBanner'
import RulesTable from './RulesTable';

import { GET_RULES } from './gql';

const log = false;

export default function Rules() {
    const cls = useStyles();
    const { loading, error, data } = useQuery(GET_RULES);

    if (loading) return <Loading fixed />
    if (error) {
        console.error('Error fetching rules:', error);
        Sentry.captureException(error);
        return <DefaultErrorFallback message={`ERROR FETCHING RULES`}/>
    }

    if (data && data.businessrules.length > 0) {
        const rules = data.businessrules;
        log && console.log('Rules Fetched:', rules)
        return(<>
            <RulesBanner />    
            <div className= {cls.rootTable}>
                <Container maxWidth='lg'>
                    <RulesTable rules={ rules }/>
                </Container> 
            </div>
        </>)
    } else return <DefaultEmptyFallback message='UNABLE TO FETCH RULE'/>
};

const useStyles = makeStyles(theme => ({
    rootTable: {
        verticalAlign: 'top',
        position: 'relative',
        width: '100%',
      },
  }));
