import { gql } from 'graphql-tag';

const GET_LOCATIONS = gql`
  query get_locations_of_customer($customer_id: bigint!) {
    locations(
      order_by: { updatedat: desc_nulls_last }
      where: { active: { _eq: 1 }, customer_id: { _eq: $customer_id } }
    ) {
      id
      active
      address
      createdat
      customer_id
      customer {
        active
        address
        branded
        config
        email
        id
        name
        phone
        createdat
        updatedat
        billing_frequency
        payment_terms
        auto_pay
        accounting_id
        notify_billing
      }
      email
      favorite
      latitude
      longitude
      name
      nickname
      phone
      notes
      place_id
      region_id
      region {
        id
        team_id
        name
        description
        geofence
        createdat
        updatedat
        last_synced
        region_id
        accounting_class_num
      }
      tookan_id
      type
      updatedat
    }
  }
`;

const LOCATIONS_SUBSCRIPTION = gql`
  subscription sub_to_locations_of_customer($customer_id: bigint!) {
    locations(
      order_by: { updatedat: desc_nulls_last }
      where: { active: { _eq: 1 }, customer_id: { _eq: $customer_id } }
    ) {
      id
      active
      address
      createdat
      customer_id
      customer {
        active
        address
        branded
        config
        email
        id
        name
        phone
        createdat
        updatedat
        billing_frequency
        payment_terms
        auto_pay
        accounting_id
        notify_billing
      }
      email
      favorite
      latitude
      longitude
      name
      nickname
      phone
      notes
      place_id
      region_id
      region {
        id
        team_id
        name
        description
        geofence
        createdat
        updatedat
        last_synced
        region_id
        accounting_class_num
      }
      tookan_id
      type
      updatedat
    }
  }
`;

const GET_LANE_BY_ID = gql`
  query get_lanes_by_id($id: bigint!) {
    lanes(order_by: { updatedat: desc }, where: { active: { _eq: 1 }, id: { _eq: $id } }) {
      id
      active
      description
      createdat
      customer_id
      customer {
        active
        address
        branded
        config
        email
        id
        name
        phone
        createdat
        updatedat
        billing_frequency
        payment_terms
        auto_pay
        accounting_id
        notify_billing
      }
      pickup {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      delivery {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      updatedat
    }
  }
`;

const GET_LANE_BY_LOCATION_IDS = gql`
  query get_lanes_by_location_ids($origin_location_id: bigint!, $destination_location_id: bigint!) {
    lanes(
      order_by: { updatedat: desc }
      where: {
        origin_location_id: { _eq: $origin_location_id }
        destination_location_id: { _eq: $destination_location_id }
      }
    ) {
      id
      active
      description
      createdat
      customer_id
      customer {
        active
        address
        branded
        config
        email
        id
        name
        phone
        createdat
        updatedat
        billing_frequency
        payment_terms
        auto_pay
        accounting_id
        notify_billing
      }
      pickup {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      delivery {
        id
        active
        address
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        tookan_id
        type
        updatedat
      }
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      updatedat
    }
  }
`;

const UPSERT_LANE = gql`
  mutation upsert_lanes($laneObjects: [lanes_insert_input!]!) {
    insert_lanes(
      objects: $laneObjects
      on_conflict: {
        constraint: lanes_pkey
        update_columns: [
          active
          average_drive_speed_min_per_mile
          average_drive_speed_mph
          customer_id
          dealer_base_discount
          dealer_base_price
          dealer_base_rate
          dealer_base_rate_type
          dealer_stranded_discount
          dealer_stranded_price
          dealer_stranded_rate
          dealer_stranded_rate_type
          delivery_inspection_sec
          description
          destination_location_id
          distance_miles
          driver_base_pay
          driver_base_pay_discount
          driver_drive_pay
          driver_pay_per_kilometer
          driver_pay_per_mile
          driver_pay_per_minute
          driver_rake
          driver_return_pay
          driver_return_pay_discount
          driver_time_pay
          duration_sec
          estimated_rideshare_return_cost
          insurance_cost
          insurance_cost_per_mile
          origin_location_id
          pickup_inspection_sec
          return_ride_wait_sec
          tolls
        ]
      }
    ) {
      returning {
        id
        active
        description
        createdat
        customer_id
        customer {
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        pickup {
          id
          active
          address
          createdat
          customer_id
          customer {
            active
            address
            branded
            config
            email
            id
            name
            phone
            createdat
            updatedat
            billing_frequency
            payment_terms
            auto_pay
            accounting_id
            notify_billing
          }
          email
          favorite
          latitude
          longitude
          name
          nickname
          phone
          notes
          place_id
          region_id
          region {
            id
            team_id
            name
            description
            geofence
            createdat
            updatedat
            last_synced
            region_id
            accounting_class_num
          }
          tookan_id
          type
          updatedat
        }
        delivery {
          id
          active
          address
          createdat
          customer_id
          customer {
            active
            address
            branded
            config
            email
            id
            name
            phone
            createdat
            updatedat
            billing_frequency
            payment_terms
            auto_pay
            accounting_id
            notify_billing
          }
          email
          favorite
          latitude
          longitude
          name
          nickname
          phone
          notes
          place_id
          region_id
          region {
            id
            team_id
            name
            description
            geofence
            createdat
            updatedat
            last_synced
            region_id
            accounting_class_num
          }
          tookan_id
          type
          updatedat
        }
        average_drive_speed_min_per_mile
        average_drive_speed_mph
        dealer_base_discount
        dealer_base_price
        dealer_base_rate
        dealer_base_rate_type
        dealer_stranded_discount
        dealer_stranded_price
        dealer_stranded_rate
        dealer_stranded_rate_type
        delivery_inspection_sec
        destination_location_id
        distance_miles
        driver_base_pay
        driver_base_pay_discount
        driver_drive_pay
        driver_pay_per_kilometer
        driver_pay_per_mile
        driver_pay_per_minute
        driver_rake
        driver_return_pay
        driver_return_pay_discount
        driver_time_pay
        duration_sec
        estimated_rideshare_return_cost
        insurance_cost
        insurance_cost_per_mile
        origin_location_id
        pickup_inspection_sec
        return_ride_wait_sec
        tolls
        updatedat
      }
    }
  }
`;

export { GET_LOCATIONS, GET_LANE_BY_ID, GET_LANE_BY_LOCATION_IDS, LOCATIONS_SUBSCRIPTION, UPSERT_LANE };
