//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import { useTools } from '../../hooks/useTools';

import { makeStyles, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';
import Spacer from '../../components/Spacer';

import { GET_MAKES_AND_MODELS } from '../../utils/gql';

const getListOfYears = () => {
  const year = dayjs().add(1, 'y').format('YYYY');
  const till = dayjs().subtract(35, 'y').format('YYYY');
  let yearList = [];
  for (var y = year; y >= till; y--) {
    yearList.push(`${y}`);
  }
  return yearList;
};

const listOfColors = [
  `Black`,
  `Blue`,
  `Brown`,
  `Burgundy`,
  `Gold`,
  `Gray`,
  `Green`,
  `Orange`,
  `Pink`,
  `Purple`,
  `Red`,
  `Silver`,
  `Tan`,
  `White`,
  `Yellow`,
];

//////////////////////// COMPONENT ////////////////////////

function MoveVehicleModal(props) {
  const cls = useStyles();

  const { getFormattedVehicleFromMove } = useTools();

  const { data: makesAndModels } = useQuery(GET_MAKES_AND_MODELS);

  const { open, onClose, input } = props;

  const [make, setMake] = React.useState(``);
  const [model, setModel] = React.useState(``);
  const [year, setYear] = React.useState(``);
  const [color, setColor] = React.useState(``);

  const [listOfMakes, setListOfMakes] = React.useState([]);
  const [listOfModels, setListOfModels] = React.useState([]);

  React.useEffect(() => {
    if (input) {
      setMake(input.make || ``);
      setModel(input.model || ``);
      setYear(input.year || ``);
      setColor(input.color || ``);
    }
  }, [input]);

  const handleAutocompleteInput = name => (event, value, reason) => {
    if (reason === `input` || reason === `clear`) {
      if (name === `make`) setMake({ name: value, vehiclemodels: [] });
      else if (name === `model`) setModel({ name: value });
    }
  };
  const handleAutocompleteSelect = (name, value) => {
    if (name === `make`) {
      if (value) setListOfModels(listOfMakes.find(m => m.name === value.name).vehiclemodels);
      else setListOfModels([]);
      setMake(value);
    } else if (name === `model`) {
      setModel(value);
    } else if (name === `year`) {
      setYear(value);
    } else if (name === `color`) {
      setColor(value);
    }
  };

  const handleSubmit = async () => {
    handleClose({
      make: make ? make.name : null,
      model: model ? model.name : null,
      year: year,
      color: color,
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  const handleMakesAndModels = async () => {
    try {
      if (makesAndModels && makesAndModels.vehiclemakes) setListOfMakes(makesAndModels.vehiclemakes);
    } catch (err) {
      toast.error(`Failed to fetch makes and models!`);
      console.error(`Failed to fetch makes and models:`, err);
    }
  };

  function handleFilterResults(options, value, subvalue = null) {
    // Manually handle filtering of options when using both onChange and onInputChange
    try {
      if (!value) return options;
      else if (!subvalue) {
        if (value.trim().length < 1) return options;
        else return options.filter(o => o.toLowerCase().includes(value.toLowerCase()));
      } else {
        if (value[subvalue].trim().length < 1) return options;
        return options.filter(o => o[subvalue].toLowerCase().includes(value[subvalue].toLowerCase()));
      }
    } catch (err) {
      return options;
    }
  }

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Vehicle</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's vehicle record stored in our database. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={2}>
              <Autocomplete
                includeInputInList
                options={getListOfYears()}
                getOptionLabel={option => option}
                noOptionsText='No years found'
                value={year}
                onChange={(event, value) => handleAutocompleteSelect(`year`, value)}
                style={{ width: '100%', margin: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Year'
                    placeholder='Select year...'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                freeSolo
                includeInputInList
                onOpen={() => handleMakesAndModels()}
                options={handleFilterResults(listOfMakes, make, 'name')}
                getOptionLabel={option => option.name}
                noOptionsText='No makes found'
                value={make}
                onChange={(event, value) => handleAutocompleteSelect(`make`, value)}
                onInputChange={handleAutocompleteInput(`make`)}
                style={{ width: '100%', margin: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Make'
                    placeholder='Enter or select make...'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                freeSolo
                includeInputInList
                options={handleFilterResults(listOfModels, model, 'name')}
                getOptionLabel={option => option.name}
                noOptionsText='No models found'
                value={model}
                onChange={(event, value) => handleAutocompleteSelect(`model`, value)}
                onInputChange={handleAutocompleteInput(`model`)}
                style={{ width: '100%', margin: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Model'
                    placeholder='Enter or select model...'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                includeInputInList
                options={listOfColors}
                getOptionLabel={option => option}
                noOptionsText='No colors found'
                value={color}
                onChange={(event, value) => handleAutocompleteSelect(`color`, value)}
                style={{ width: '100%', margin: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Color'
                    placeholder='Select color...'
                    variant='outlined'
                    size='small'
                  />
                )}
              />
            </Grid>
          </Grid>

          <Spacer />

          <Typography className={cls.displayTxt}>
            Displayed As:&nbsp;&nbsp;&nbsp;
            <strong>
              {getFormattedVehicleFromMove({
                vehicle_year: year || null,
                vehicle_make: make ? make.name : null,
                vehicle_model: model ? model.name : null,
                vehicle_color: color || null,
              })}
            </strong>
          </Typography>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()} color='primary'>
            Submit
          </ModalAction>
          <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  displayTxt: {
    fontSize: 16,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveVehicleModal;
