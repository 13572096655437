import React from 'react';

import { makeStyles, Typography, Icon, Tooltip, Collapse } from '@material-ui/core';

////////// COMPONENT //////////
export default function FavoriteSelect(props) {
  const cls = useStyles();

  const { expand, handleExpand, favoriteLanes, handleFavoriteSelect } = props;

  return (
    <>
      <div className={cls.expandBtn + ' ' + (expand ? cls.expandHide : cls.expandShow)} onClick={handleExpand}>
        <Typography className={cls.expandTxt}>
          {expand ? `HIDE\xa0FAVORITE\xa0LANES` : `SHOW\xa0FAVORITE\xa0LANES`}
        </Typography>
      </div>

      <div style={{ width: '100%', height: expand ? '16px' : '0px', transition: '0.3s' }} />

      <Collapse timeout='auto' in={expand}>
        <Tooltip
          placement='top'
          title='View your favorite lanes and choose one to fill out the lane form (Click here to hide the favorite lanes).'
        >
          <Typography className={cls.favTitle} onClick={handleExpand}>
            FAVORITE&nbsp;LANES
          </Typography>
        </Tooltip>

        <div style={{ width: '100%', height: '8px' }} />

        <div className={cls.favs}>
          {favoriteLanes.length > 0 ? (
            favoriteLanes.map((lane, i) => (
              <div
                key={`${lane.id}-${i}`}
                onClick={() => handleFavoriteSelect(lane)}
                className={cls.fav + ' ' + (i % 2 ? cls.favEven : cls.favOdd)}
                style={{ borderTop: i !== 0 ? '1px solid #ddd' : 'none' }}
              >
                <div className={cls.favLocBox}>
                  <Typography className={cls.favLocName}>
                    {!lane.pickup.nickname ? lane.pickup.name : lane.pickup.nickname}
                  </Typography>
                  <Typography className={cls.favLocAddress}>{lane.pickup.address}</Typography>
                </div>
                <div className={cls.icon}>
                  <Icon className={cls.forwardIcon}>forward</Icon>
                </div>
                <div className={cls.favLocBox}>
                  <Typography className={cls.favLocName}>
                    {!lane.delivery.nickname ? lane.delivery.name : lane.delivery.nickname}
                  </Typography>
                  <Typography className={cls.favLocAddress}>{lane.delivery.address}</Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography className={cls.notFound}>NO FAVORITE LANES</Typography>
          )}
        </div>
      </Collapse>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  expandBtn: {
    position: 'absolute',
    bottom: '-14px',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    transition: '0.2s',
    cursor: 'pointer',
    textAlign: 'center',
  },
  expandShow: {
    background: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  expandHide: {
    background: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
  },
  expandTxt: {
    marginTop: '1px',
    fontSize: '14px',
    fontWeight: 500,
  },
  favs: {
    overflowY: 'auto',
    maxHeight: '480px',
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },
  favTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },
  fav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: '0.15s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  favOdd: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  favEven: {
    backgroundColor: theme.palette.background.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
    },
  },
  favLocBox: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  favLocName: {
    marginBottom: theme.spacing(0.75),
    lineHeight: 1.2,
    fontSize: '16px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.25),
    },
  },
  favLocAddress: {
    lineHeight: 1.2,
    fontSize: '12px',
    fontWeight: 400,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
  forwardIcon: {
    transition: 'inherit',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(90deg)',
    },
  },
  notFound: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
  },
}));
