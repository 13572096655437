//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useTools } from '../../hooks/useTools';
import { makeStyles, Grid, Typography, IconButton, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { DELETE_LOCATION_DETAILS } from './gql';

//////////////////////// COMPONENT ////////////////////////

function LocationBanner({ locationId }) {
  const cls = useStyles();

  const { goToPreviousPage, goToLocationsIndex } = useTools();

  const [deleteLocationDetails] = useMutation(DELETE_LOCATION_DETAILS);
    
  const handleDeleteLocation = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete this location? Deleting a location can have very serious implications on the lanes which depend upon it. Please proceed with caution and consult a technical team member before clicking OK.`
      )
    ) {
      try {
        const res = await deleteLocationDetails({
          variables: { locationId: locationId },
        });
        if (res && res.data && res.data.update_locations && res.data.update_locations.affected_rows > 0) {
          toast.success(`Successfully deleted location!`);
          goToLocationsIndex();
        }
      } catch (err) {
        toast.error(`Failed to delete location!`);
        console.error(`Failed to delete location:`, err);
      }
    } else return;
  };

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <IconButton className={cls.iconBtn} onClick={() => goToPreviousPage()}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </Grid>
      <Grid item xs>
        <Typography className={cls.head}>Location Details</Typography>
      </Grid>
      <Grid item>
        <Button variant='outlined' color='error' onClick={() => handleDeleteLocation()}>
          Delete Location
        </Button>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    width: 40,
    height: 40,
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default LocationBanner;
