import React from 'react';

import { useFilters } from '../FilterProvider';

import { Button, makeStyles } from '@material-ui/core';

export default function SwitchButton({ module, options, defaults }) {
  const cls = useStyles();

  const { filters, updateModule } = useFilters();

  const moduleOptions = filters[module]?.options;

  const handleChange = (value) => {
    const newOptions = options.options.map(opt => ({
      value: opt,
      checked: opt === value
    }));
    updateModule(module, 'options', newOptions);
  };

  React.useEffect(() => {
    if (!moduleOptions?.some(opt => opt.checked)) {
      const defaultOptions = options.options.map((opt, index) => ({
        value: opt,
        checked: index === 0,
      }));
      updateModule(module, 'options', defaultOptions);
    }
  }, []);

  return (
    <div className={cls.flex}>
      {options.options.map((option, index) => (
        <Button
          key={option}
        //   className={index === 0 ? cls.btn1 : cls.btn2}
          variant={moduleOptions?.find(opt => opt.value === option)?.checked ? 'contained' : 'outlined'}
          onClick={() => handleChange(option)}
        >
          {option}
        </Button>
      ))}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  btn1: {
    height: 36.5,
    borderRadius: `4px 0 0 4px`,
  },
  btn2: {
    height: 36.5,
    borderRadius: `0 4px 4px 0`,
  },
}));
