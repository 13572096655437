// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import {
  makeStyles,
  CircularProgress,
  Icon,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import { useLazyQuery } from '@apollo/client';
import { GET_CHASE_DRIVERS, GET_CHASE_DRIVERS_W2_ONLY } from './gql';

import DriverAvatar from '../../components/DriverComponents/DriverAvatar';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormVehicleDriver({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [showW2Only, setShowW2Only] = React.useState(false);
  const [driverId, setDriverId] = React.useState(0);
  const [drivers, setDrivers] = React.useState([]);

  // Lazy fetch
  const [fetchDrivers, { loading, error }] = useLazyQuery(showW2Only ? GET_CHASE_DRIVERS_W2_ONLY : GET_CHASE_DRIVERS);

  /** Handle changing the driver ID */
  const handleDriverIdChange = e => {
    const value = e?.target?.value;

    setDriverId(value);
    setFormData({ ...formData, driverIds: [], vehicleDriverId: value || 0 });
  };

  /** Handle fetching drivers */
  const handleFetchDrivers = async () => {
    try {
      const res = await fetchDrivers();
      if (res?.data?.drivers) setDrivers([...res?.data?.drivers]);
    } catch (err) {
      console.error(`Failed to fetch drivers:`, err);
    }
  };

  // Clear, then fetch/refetch drivers on mount
  React.useEffect(() => {
    setDriverId(0);
    setDrivers([]);
    handleFetchDrivers();
  }, []);

  // Clear, then fetch/refetch drivers when showW2Only changes
  React.useEffect(() => {
    setDriverId(0);
    setDrivers([]);
    handleFetchDrivers();
  }, [showW2Only]);

  // Set the initial driver ID
  React.useEffect(() => {
    if (initialLoad && initialFormData?.vehicleDriverId && drivers?.length) {
      setDriverId(initialFormData?.vehicleDriverId);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData, drivers]);

  return (
    <div className={cls.root}>
      <label className={cls.textFieldLabel} htmlFor='driverId'>
        Who will be driving the chase vehicle?
        <span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <div className={cls.flex}>
        <Switch size='small' color='primary' checked={showW2Only} onChange={() => setShowW2Only(!showW2Only)} />
        <Typography className={cls.showW2OnlyLabel}>Show Only W-2 Drivers</Typography>
      </div>

      <TextField
        loading={loading}
        disabled={loading || error}
        required
        select
        fullWidth
        name='driverId'
        variant='outlined'
        size='small'
        defaultValue={driverId}
        value={driverId}
        onChange={handleDriverIdChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon color='disabled' fontSize='small'>
                  face
                </Icon>
              )}
            </InputAdornment>
          ),
        }}
      >
        <MenuItem value={0}>
          <em className={cls.placeholder}>{loading ? `Loading...` : error ? `Error!` : `Select Chase Driver`}</em>
        </MenuItem>

        {drivers?.map(driver => (
          <MenuItem className={cls.menuItem} key={driver?.id} value={driver?.id}>
            <DriverAvatar driver={driver} />

            <div>
              <Typography className={cls.menuItemTxt1}>
                {driver?.user?.display_name} ({driver?.id})
              </Typography>
              <Typography className={cls.menuItemTxt2}>{driver?.region?.name}</Typography>
            </div>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  flex: {
    display: `flex`,
    alignItems: `center`,
    gap: 4,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  showW2OnlyLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },

  menuItem: {
    display: `flex`,
    alignItems: `center`,
    gap: 12,
  },
  menuItemTxt1: {
    lineHeight: 1,
    fontSize: 14,
  },
  menuItemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },
}));
