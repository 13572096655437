import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { makeStyles, Grid, ButtonGroup, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import gql from 'graphql-tag';

import { useData } from '../../providers/DataProvider';

const log = false;

////////// TOOLS //////////

const getListOfYears = () => {
  const year = dayjs().add(1, 'y').format('YYYY');
  const till = 1980;
  let yearList = [];
  for (var y = year; y >= till; y--) {
    yearList.push(`${y}`);
  }
  return yearList;
};

const listOfColors = [
  `Black`,
  `Blue`,
  `Brown`,
  `Burgundy`,
  `Gold`,
  `Gray`,
  `Green`,
  `Orange`,
  `Pink`,
  `Purple`,
  `Red`,
  `Silver`,
  `Tan`,
  `White`,
  `Yellow`,
];

////////// COMPONENT //////////
export default function VehicleForm(props) {
  const ctx = useData();
  const cls = useStyles();

  const { vehicleData, onChange, customerId } = props;

  const [listOfVehicles, setListOfVehicles] = useState([]);
  const [listOfMakes, setListOfMakes] = useState([]);
  const [listOfModels, setListOfModels] = useState([]);

  const [refNum, setRefNum] = useState(vehicleData && vehicleData.refNum ? vehicleData.refNum : '');
  const [manual, setManual] = useState(vehicleData && vehicleData.manual ? vehicleData.manual : false);
  const [stock, setStock] = useState(vehicleData && vehicleData.stock ? vehicleData.stock : null);
  const [vin, setVin] = useState(vehicleData && vehicleData.vin ? vehicleData.vin : null);
  const [make, setMake] = useState(vehicleData && vehicleData.make ? vehicleData.make : null);
  const [model, setModel] = useState(vehicleData && vehicleData.model ? vehicleData.model : null);
  const [year, setYear] = useState(vehicleData && vehicleData.year ? vehicleData.year : null);
  const [color, setColor] = useState(vehicleData && vehicleData.color ? vehicleData.color : null);

  const [modelError, setModelError] = useState(null);
  const [stockError, setStockError] = useState(null);

  useEffect(() => {
    if (onChange)
      onChange({
        refNum: refNum,
        manual: manual,
        stock: stock,
        vin: vin,
        make: make,
        model: model,
        year: year,
        color: color,
      });
  }, [refNum, manual, stock, vin, make, model, year, color]);

  useEffect(() => {
    if (props.vehicleData) {
      setRefNum(vehicleData.refNum);
      setManual(vehicleData.manual);
      setStock(vehicleData.stock);
      setVin(vehicleData.vin);
      setMake(vehicleData.make);
      setModel(vehicleData.model);
      setYear(vehicleData.year);
      setColor(vehicleData.color);
    }
  }, [props.vehicleData]);

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };
  const handleManualChange = value => {
    setManual(value);
  };
  const handleAutocompleteInput = name => (event, value, reason) => {
    if (reason === `input` || reason === `clear`) {
      if (name === `stock`) {
        if (value.length > 24) {
          setStockError('Length too long');
        } else {
          setStockError(null);
        }
        setStock({ vehicle_stock: value });
      } else if (name === `vin`) setVin({ vehicle_vin: value });
      else if (name === `make`) setMake({ name: value, vehiclemodels: [] });
      else if (name === `model`) {
        if (value.length > 24) {
          setModelError('Length too long');
        } else {
          setModelError(null);
        }
        setModel({ name: value });
      }
    }
  };
  const handleAutocompleteSelect = (name, value) => {
    if (name === `stock` || name === `vin`) {
      if (value) {
        setManual(value.manual_flag);
        setStock(value);
        setVin(value);
        setMake({ name: value.vehicle_make, vehiclemodels: [] });
        setModel({ name: value.vehicle_model });
        setYear(value.vehicle_year);
        setColor(value.vehicle_color);
      } else {
        if (name === `stock`) setStock(null);
        else setVin(null);
      }
    } else if (name === `make`) {
      if (value) setListOfModels(listOfMakes.find(item => item.name === value.name).vehiclemodels);
      else setListOfModels([]);
      log && console.log(`List of Models:`, listOfModels);
      setMake(value);
    } else if (name === `model`) {
      setModel(value);
    } else if (name === `year`) {
      setYear(value);
    } else if (name === `color`) {
      setColor(value);
    }
  };

  const getVehicles = async () => {
    try {
      let range = new Date();
      range.setMonth(range.getMonth() - 3);
      range = range.toUTCString();
      return await ctx.apolloClient
        .query({
          query: GET_VEHICLES,
          variables: { customerId: customerId, range: range },
        })
        .then(res => {
          log && console.log(`List of Vehicles:`, res.data.moves);
          setListOfVehicles(res.data.moves);
        });
    } catch (err) {
      console.log(`Failed to retrieve list of vehicles:`, err);
      toast.error(`Failed to retrieve list of vehicles: ${err.toString()}`);
    }
  };
  const getMakesAndModels = async () => {
    try {
      return await ctx.apolloClient
        .query({
          query: GET_MAKES_AND_MODELS,
        })
        .then(res => {
          log && console.log(`List of Makes:`, res.data.vehiclemakes);
          setListOfMakes(res.data.vehiclemakes);
        });
    } catch (err) {
      console.log(`Failed to retrieve list of makes:`, err);
      toast.error(`Failed to retrieve list of makes: ${err.toString()}`);
    }
  };

  function handleFilterResults(options, value, subvalue = null) {
    // Manually handle filtering of options when using both onChange and onInputChange
    try {
      if (!value) return options;
      else if (!subvalue) {
        if (value.trim().length < 1) return options;
        else return options.filter(o => o.toLowerCase().includes(value.toLowerCase()));
      } else {
        if (value[subvalue].trim().length < 1) return options;
        return options.filter(o => o[subvalue].toLowerCase().includes(value[subvalue].toLowerCase()));
      }
    } catch (err) {
      return options;
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            label='Reference #'
            placeholder='Enter reference #...'
            variant='outlined'
            margin='none'
            value={refNum}
            onChange={handleInputChange(setRefNum)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ButtonGroup fullWidth className={cls.btnGroup} color='primary'>
            <Button
              fullWidth
              size='large'
              className={!manual ? cls.btnActive : cls.btn}
              onClick={() => handleManualChange(false)}
            >
              Automatic
            </Button>
            <Button
              fullWidth
              size='large'
              className={manual ? cls.btnActive : cls.btn}
              onClick={() => handleManualChange(true)}
            >
              Manual
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            freeSolo
            includeInputInList
            onOpen={() => getVehicles()}
            options={handleFilterResults(listOfVehicles, stock, 'vehicle_stock')}
            getOptionLabel={option => option.vehicle_stock}
            noOptionsText='No stocks found'
            value={stock}
            onChange={(event, value) => handleAutocompleteSelect(`stock`, value)}
            onInputChange={handleAutocompleteInput(`stock`)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                error={
                  stockError
                    ? 'true'
                    : props.validation && props.validation.stock
                    ? !props.validation.stock.toString()
                    : 'false'
                }
                helperText={stockError}
                fullWidth
                label='Stock'
                placeholder='Enter or search stock...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            freeSolo
            includeInputInList
            onOpen={() => getVehicles()}
            options={handleFilterResults(listOfVehicles, vin, 'vehicle_vin')}
            getOptionLabel={option => option.vehicle_vin}
            noOptionsText='No VINs found'
            value={vin}
            onChange={(event, value) => handleAutocompleteSelect(`vin`, value)}
            onInputChange={handleAutocompleteInput(`vin`)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                error={props.validation && props.validation.vin ? !props.validation.vin.toString() : 'false'}
                fullWidth
                label='VIN'
                placeholder='Enter or search VIN...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            freeSolo
            includeInputInList
            onOpen={() => getMakesAndModels()}
            options={handleFilterResults(listOfMakes, make, 'name')}
            getOptionLabel={option => option.name}
            noOptionsText='No makes found'
            value={make}
            onChange={(event, value) => handleAutocompleteSelect(`make`, value)}
            onInputChange={handleAutocompleteInput(`make`)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={props.validation && props.validation.make ? !props.validation.make.toString() : 'false'}
                fullWidth
                label='Make'
                placeholder='Enter or select make...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            freeSolo
            includeInputInList
            options={handleFilterResults(listOfModels, model, 'name')}
            getOptionLabel={option => option.name}
            noOptionsText='No models found'
            value={model}
            onChange={(event, value) => handleAutocompleteSelect(`model`, value)}
            onInputChange={handleAutocompleteInput(`model`)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={
                  modelError
                    ? 'true'
                    : props.validation && props.validation.model
                    ? !props.validation.model.toString()
                    : 'false'
                }
                helperText={modelError}
                fullWidth
                label='Model'
                placeholder='Enter or select model...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            includeInputInList
            options={getListOfYears()}
            getOptionLabel={option => option}
            noOptionsText='No years found'
            value={year}
            onChange={(event, value) => handleAutocompleteSelect(`year`, value)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                error={props.validation && props.validation.year ? !props.validation.year.toString() : 'false'}
                fullWidth
                label='Year'
                placeholder='Select year...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Autocomplete
            includeInputInList
            options={listOfColors}
            getOptionLabel={option => option}
            noOptionsText='No colors found'
            value={color}
            onChange={(event, value) => handleAutocompleteSelect(`color`, value)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                error={props.validation && props.validation.color ? !props.validation.color.toString() : 'false'}
                fullWidth
                label='Color'
                placeholder='Select color...'
                variant='outlined'
                margin='none'
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  btn: {
    minHeight: '56px',
  },
  btnActive: {
    minHeight: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

////////// GRAPHQL //////////
const GET_VEHICLES = gql`
  query get_vehicles($customerId: bigint!, $range: timestamptz!) {
    moves(
      distinct_on: vehicle_stock
      where: {
        customer_id: { _eq: $customerId }
        ready_by: { _gte: $range }
        _and: [
          { vehicle_stock: { _nilike: "0" } }
          { vehicle_stock: { _nilike: "" } }
          { vehicle_vin: { _nilike: "0" } }
          { vehicle_vin: { _nilike: "" } }
        ]
      }
      order_by: { vehicle_stock: asc }
    ) {
      manual_flag
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
    }
  }
`;

const GET_MAKES_AND_MODELS = gql`
  query get_makes_and_models {
    vehiclemakes(order_by: { name: asc }) {
      id
      name
      vehiclemodels(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;
