//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';
import { useData } from '../../providers/DataProvider';
import Loading from '../Loading'

//////////////////////// COMPONENT ////////////////////////

// Switched from the component prop to the render prop so we can wrap error boundaries around each page
// https://reactrouter.com/web/api/Route/render-func

const PrivateRoute = ({component, ...args }) => {
  const ctx = useData()
  const location = useLocation()

React.useEffect(() => {
  ctx.setLastLocation(location.pathname)
}, [])

  if (ctx.firebaseUser) {
    return (
      <Route
      {...args}
      render={props => {
        const Comp = component;
        return (
          <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR RENDERING PAGE' />}>
            <Comp {...props} />
          </Sentry.ErrorBoundary>
        );
      }}
    />
    )
  } else if (ctx.loading) {
    return (
      <Loading />
    )
  } else {
    return (
      <Redirect to='/login' />
    )
  }
};

//////////////////////// EXPORT ////////////////////////

export default PrivateRoute;
