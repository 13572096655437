import React from 'react';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';

import { makeStyles, Grid, MenuItem } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import MoveInfo from '../MoveDetails/MoveInfo';
import MoveLocation from '../../components/MoveLocation.js';

//////////////////////// COMPONENT ////////////////////////

export default function MovesToPrint({ moves = [], checkedMoves = [], action }) {
  const cls = useStyles();

  const [isPrinting, setIsPrinting] = React.useState(false);
  const [bolMoves, setBolMoves] = React.useState(checkedMoves?.length ? checkedMoves : moves);

  React.useEffect(() => {
    if (checkedMoves?.length) {
      setBolMoves(checkedMoves);
    } else if (moves?.length) {
      setBolMoves(moves);
    }
  }, [moves, checkedMoves]);

  const [active, setActive] = React.useState(false);
  const [autoAssign, setAutoAssign] = React.useState(false);
  const [chargeable, setChargeable] = React.useState(false);
  const [color, setColor] = React.useState(``);
  const [consumerName, setConsumerName] = React.useState(``);
  const [consumerPhone, setConsumerPhone] = React.useState(``);
  const [consumerPickup, setConsumerPickup] = React.useState(false);
  const [dealerContact, setDealerContact] = React.useState(``);
  const [lyftFlag, setLyftFlag] = React.useState(false);
  const [make, setMake] = React.useState(``);
  const [manualFlag, setManualFlag] = React.useState(false);
  const [model, setModel] = React.useState(``);
  const [moveTemplate, setMoveTemplate] = React.useState(``);
  const [moveType, setMoveType] = React.useState(``);
  const [notes, setNotes] = React.useState(``);
  const [odometer, setOdometer] = React.useState(``);
  const [payable, setPayable] = React.useState(false);
  const [priority, setPriority] = React.useState(10);
  const [rateClass, setRateClass] = React.useState(``);
  const [rateClassOverride, setRateClassOverride] = React.useState(false);
  const [refNum, setRefNum] = React.useState(``);
  const [rideType, setRideType] = React.useState(``);
  const [stock, setStock] = React.useState(``);
  const [vin, setVin] = React.useState(``);
  const [year, setYear] = React.useState(``);
  const [weight, setWeight] = React.useState(``);
  const [config, setConfig] = React.useState(null);

  const componentRef = React.useRef();

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className={cls.root}>
      {isPrinting &&
        bolMoves?.length &&
        bolMoves?.map((move, i) => (
          <div key={`bol-move-${move?.id}`} className={cls.preventPageBreak}>
            <div className={cls.page}>
              <MoveInfo
                move={move}
                printMode={true}
                editMode={false}
                editVals={{
                  active,
                  autoAssign,
                  chargeable,
                  color,
                  consumerName,
                  consumerPhone,
                  consumerPickup,
                  dealerContact,
                  lyftFlag,
                  make,
                  manualFlag,
                  model,
                  moveTemplate,
                  notes,
                  odometer,
                  payable,
                  rateClass,
                  rateClassOverride,
                  refNum,
                  rideType,
                  stock,
                  vin,
                  year,
                  config,
                  weight,
                }}
                editSetVals={{
                  setActive,
                  setAutoAssign,
                  setChargeable,
                  setColor,
                  setConsumerName,
                  setConsumerPhone,
                  setConsumerPickup,
                  setDealerContact,
                  setLyftFlag,
                  setMake,
                  setManualFlag,
                  setModel,
                  setMoveTemplate,
                  setMoveType,
                  setNotes,
                  setOdometer,
                  setPayable,
                  setPriority,
                  setRateClass,
                  setRateClassOverride,
                  setRefNum,
                  setRideType,
                  setStock,
                  setVin,
                  setYear,
                  setConfig,
                  setWeight,
                }}
              />
​
              <Divide spacer>Pickup & Delivery</Divide>
​
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MoveLocation type='pickup' move={move} printMode={true} editMode={false}/>
                </Grid>
                <Grid item xs={6}>
                  <MoveLocation type='delivery' move={move} printMode={true} editMode={false}/>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
    </div>
  ));

  const handleBeforePrint = async () => {
    setIsPrinting(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
  };
  const handleAfterPrint = async () => {
    setIsPrinting(false);
  };

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <MenuItem key={`toolbar-action-generate-bol`} disabled={action?.disabled || bolMoves?.length > 100}>
            Generate BOLs (Max 100)
          </MenuItem>
        )}
        content={() => componentRef.current}
        documentTitle={`moves_index_bols_${dayjs().format()}`}
        onBeforeGetContent={() => handleBeforePrint()}
        onAfterPrint={() => handleAfterPrint()}
      />

      <div style={{ display: `none` }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  preventPageBreak: {
    pageBreakBefore: 'always',
    '@media print': {
      clear: 'both',
      pageBreakInside: 'avoid',
    },
  },
}));
