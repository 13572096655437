//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { makeStyles, ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

function NavSideBarItem({ route, label, icon }) {
  const cls = useStyles();

  return (
    <ListItem className={cls.listItem} button component={Link} to={route || `/`}>
      <ListItemIcon>
        <Icon>{icon || `home`}</Icon>
      </ListItemIcon>
      <ListItemText primary={label || `Page`} />
    </ListItem>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingRight: theme.spacing(6),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default withRouter(NavSideBarItem);
