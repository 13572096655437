import React from 'react';
import { Modal } from '../../components/ModalComponents';

import AdminDriverAddModalContent from './AdminDriverAddModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function AdminDriverAddModal({ open, close }) {
  const handleClose = (output = null) => {
    if (close) close();
  };

  return <Modal open={open}>{open ? <AdminDriverAddModalContent onClose={handleClose} /> : null}</Modal>;
}