//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles } from '@material-ui/core';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  active: `config.api_enabled`,
};

// DEFAULTS //
const getDefaultActive = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.active) === false) return false;
  if (getPropValue(overrideRef, option?.active) === true) return true;
  return getPropValue(inheritedRef, option?.active);
};

//////////////////////// COMPONENT ////////////////////////

export default function Api({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [active, setActive] = React.useState(getDefaultActive(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setActive(getDefaultActive(null, inheritedRef));
    }
  }, [isReset]);

  // Handle active change
  const handleActiveChange = () => {
    // Get the value
    const value = !active;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { api_enabled: value } };
    setActive(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`API`}
        tip={`The API module is a simple check to show the client uses the API.`}
      />

      <SettingsOption
        contained
        title={`Activation`}
        description={`Activate/Deactivate the API feature for this client. Currently, this is only a visual indicator of the client to show that they use the API. In the future, this switch may behave differently.`}
        value={active}
        checkReset={() => checkMutableRefField(option?.active)}
        onReset={() => deleteMutableRefField(option?.active, () => setActive(getDefaultActive(null, inheritedRef)))}
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={active} onChange={() => handleActiveChange()} />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
}));
