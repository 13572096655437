// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

const hourTimes = [
  `12 AM`,
  `1 AM`,
  `2 AM`,
  `3 AM`,
  `4 AM`,
  `5 AM`,
  `6 AM`,
  `7 AM`,
  `8 AM`,
  `9 AM`,
  `10 AM`,
  `11 AM`,
  `12 PM`,
  `1 PM`,
  `2 PM`,
  `3 PM`,
  `4 PM`,
  `5 PM`,
  `6 PM`,
  `7 PM`,
  `8 PM`,
  `9 PM`,
  `10 PM`,
  `11 PM`,
  `12 AM`,
];
const quarterAfterTimes = [
  `12:15 AM`,
  `1:15 AM`,
  `2:15 AM`,
  `3:15 AM`,
  `4:15 AM`,
  `5:15 AM`,
  `6:15 AM`,
  `7:15 AM`,
  `8:15 AM`,
  `9:15 AM`,
  `10:15 AM`,
  `11:15 AM`,
  `12:15 PM`,
  `1:15 PM`,
  `2:15 PM`,
  `3:15 PM`,
  `4:15 PM`,
  `5:15 PM`,
  `6:15 PM`,
  `7:15 PM`,
  `8:15 PM`,
  `9:15 PM`,
  `10:15 PM`,
  `11:15 PM`,
];
const halfHourTimes = [
  `12:30 AM`,
  `1:30 AM`,
  `2:30 AM`,
  `3:30 AM`,
  `4:30 AM`,
  `5:30 AM`,
  `6:30 AM`,
  `7:30 AM`,
  `8:30 AM`,
  `9:30 AM`,
  `10:30 AM`,
  `11:30 AM`,
  `12:30 PM`,
  `1:30 PM`,
  `2:30 PM`,
  `3:30 PM`,
  `4:30 PM`,
  `5:30 PM`,
  `6:30 PM`,
  `7:30 PM`,
  `8:30 PM`,
  `9:30 PM`,
  `10:30 PM`,
  `11:30 PM`,
];
const quarterBeforeTimes = [
  `12:45 AM`,
  `1:45 AM`,
  `2:45 AM`,
  `3:45 AM`,
  `4:45 AM`,
  `5:45 AM`,
  `6:45 AM`,
  `7:45 AM`,
  `8:45 AM`,
  `9:45 AM`,
  `10:45 AM`,
  `11:45 AM`,
  `12:45 PM`,
  `1:45 PM`,
  `2:45 PM`,
  `3:45 PM`,
  `4:45 PM`,
  `5:45 PM`,
  `6:45 PM`,
  `7:45 PM`,
  `8:45 PM`,
  `9:45 PM`,
  `10:45 PM`,
  `11:45 PM`,
];

// NOTE: This is how the margin is calculated for the time ticks (takes into account the tick multiplier and scale)
// marginRight: 5 * zoom * multiplier - 1, // minutes * constant mult * scale - 1px for line width

// COMPONENTS ---------------------------------------------------------------- //

const HourFull = ({ index, hourTime, multiplier, zoom }) => {
  const cls = useStyles();

  if (hourTime)
    return (
      <Grid item className={cls.hour}>
        <Grid container alignItems='flex-start' wrap='nowrap'>
          {index !== hourTimes.length - 1 ? (
            <>
              <Grid item>
                <div className={cls.tickFull} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickHalf} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
              <Grid item>
                <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
              </Grid>
            </>
          ) : (
            <Grid item>
              <div className={cls.tickFinal} />
            </Grid>
          )}
        </Grid>

        <div className={index === 0 ? cls.labelStart : index === hourTimes.length - 1 ? cls.labelEnd : cls.label}>
          <Typography className={cls.labelTxtFull}>{hourTime}</Typography>
        </div>
      </Grid>
    );
  return null;
};

const HourHalf = ({ index, hourTime, halfHourTime, multiplier, zoom }) => {
  const cls = useStyles();

  return (
    <>
      {hourTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            {index !== hourTimes.length - 1 ? (
              <>
                <Grid item>
                  <div className={cls.tickFull} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
              </>
            ) : (
              <Grid item>
                <div className={cls.tickFinal} />
              </Grid>
            )}
          </Grid>

          <div className={index === 0 ? cls.labelStart : index === hourTimes.length - 1 ? cls.labelEnd : cls.label}>
            <Typography className={cls.labelTxtFull}>{hourTime}</Typography>
          </div>
        </Grid>
      ) : null}

      {halfHourTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            <Grid item>
              <div className={cls.tickHalf} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
          </Grid>

          <div className={cls.label}>
            <Typography className={cls.labelTxtHalf}>{halfHourTime}</Typography>
          </div>
        </Grid>
      ) : null}
    </>
  );
};

const HourQuarter = ({ index, hourTime, quarterAfterTime, halfHourTime, quarterBeforeTime, multiplier, zoom }) => {
  const cls = useStyles();

  return (
    <>
      {hourTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            {index !== hourTimes.length - 1 ? (
              <>
                <Grid item>
                  <div className={cls.tickFull} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
                <Grid item>
                  <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
                </Grid>
              </>
            ) : (
              <Grid item>
                <div className={cls.tickFinal} />
              </Grid>
            )}
          </Grid>

          <div className={index === 0 ? cls.labelStart : index === hourTimes.length - 1 ? cls.labelEnd : cls.label}>
            <Typography className={cls.labelTxtFull}>{hourTime}</Typography>
          </div>
        </Grid>
      ) : null}

      {quarterAfterTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            <Grid item>
              <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
          </Grid>

          <div className={cls.label}>
            <Typography className={cls.labelTxtQuarter}>{quarterAfterTime}</Typography>
          </div>
        </Grid>
      ) : null}

      {halfHourTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            <Grid item>
              <div className={cls.tickHalf} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
          </Grid>

          <div className={cls.label}>
            <Typography className={cls.labelTxtHalf}>{halfHourTime}</Typography>
          </div>
        </Grid>
      ) : null}

      {quarterBeforeTime ? (
        <Grid item className={cls.hour}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            <Grid item>
              <div className={cls.tickQuarter} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
            <Grid item>
              <div className={cls.tickTwelth} style={{ marginRight: 5 * zoom * multiplier - 1 }} />
            </Grid>
          </Grid>

          <div className={cls.label}>
            <Typography className={cls.labelTxtQuarter}>{quarterBeforeTime}</Typography>
          </div>
        </Grid>
      ) : null}
    </>
  );
};

export default function LabelLoop() {
  const { multiplier, zoom } = useTimeline();

  let labels = [];
  for (let i = 0; i < hourTimes.length; i++) {
    const hourTime = hourTimes[i];
    const quarterAfterTime = quarterAfterTimes[i] || null;
    const halfHourTime = halfHourTimes[i] || null;
    const quarterBeforeTime = quarterBeforeTimes[i] || null;

    if (zoom >= 2) {
      labels.push(
        <HourQuarter
          key={`timeline-head-label-${i}`}
          index={i}
          hourTime={hourTime}
          quarterAfterTime={quarterAfterTime}
          halfHourTime={halfHourTime}
          quarterBeforeTime={quarterBeforeTime}
          multiplier={multiplier}
          zoom={zoom}
        />
      );
    } else if (zoom >= 1) {
      labels.push(
        <HourHalf
          key={`timeline-head-label-${i}`}
          index={i}
          hourTime={hourTime}
          halfHourTime={halfHourTime}
          multiplier={multiplier}
          zoom={zoom}
        />
      );
    } else {
      labels.push(
        <HourFull key={`timeline-head-label-${i}`} index={i} hourTime={hourTime} multiplier={multiplier} zoom={zoom} />
      );
    }
  }

  return (
    <Grid container alignItems='flex-start' wrap='nowrap'>
      {labels.map(label => label)}
    </Grid>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  hour: {
    position: 'relative',
  },

  tickFull: {
    verticalAlign: 'top',
    width: 0,
    height: 24,
    borderLeft: theme.border[0],
    userSelect: 'none',
  },
  tickHalf: {
    verticalAlign: 'top',
    width: 0,
    height: 18,
    borderLeft: theme.border[0],
    userSelect: 'none',
  },
  tickQuarter: {
    verticalAlign: 'top',
    width: 0,
    height: 12,
    borderLeft: theme.border[0],
    userSelect: 'none',
  },
  tickTwelth: {
    verticalAlign: 'top',
    width: 0,
    height: 6,
    borderLeft: theme.border[0],
    userSelect: 'none',
  },
  tickFinal: {
    verticalAlign: 'top',
    width: 0,
    height: 24,
    userSelect: 'none',
  },

  labelStart: {
    position: 'absolute',
    left: 0,
  },
  label: {
    position: 'absolute',
    left: 0,
    transform: 'translateX(-50%)',
  },
  labelEnd: {
    position: 'absolute',
    left: 0,
    transform: 'translateX(-100%)',
  },

  labelTxtFull: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(0.75),
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.disabled,
    userSelect: 'none',
  },
  labelTxtHalf: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.text.primary}48`,
    userSelect: 'none',
  },
  labelTxtQuarter: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(2.25),
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.text.primary}36`,
    userSelect: 'none',
  },
}));
