import React from 'react';
import MovesTable from './MovesTable';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import { getMovesQuery, getMoveCountQuery } from './gql';
import { useLazyQuery } from '@apollo/client';

const log = false;

export default function TableWrapper(props) {
  const {
    start,
    end,
    search,
    searchChange,
    setSearchChange,
    checkedMoves,
    setCheckedMoves,
    customerIdArray,
    customerChange,
    setCustomerChange,
    moveStatusArray,
    statusChange,
    setStatusChange,
    cancelStatusArray,
    cancelStatusChange,
    setCancelStatusChange,
    paginationData,
    setPaginationData,
    moves,
    setMoves,
  } = props;

  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const [selectedCancelStatuses, setSelectedCancelStatuses] = React.useState([]);
  const [nullStatus, setNullStatus] = React.useState(false);
  const [moveCount, setMoveCount] = React.useState(null);

  const handleOrderBy = (column, direction) => {
    try {
      let colString = column.toString();
      let orderByQueryObj = { [colString]: direction };
      return orderByQueryObj;
    } catch {
      return { id: 'desc' };
    }
  };

  React.useEffect(() => {
    var moveStatuses = [];
      //If the value is null, that means the user selected "unassigned",
      //so we set nullStatus to true here for the purposes of building the right query
    if (moveStatusArray && moveStatusArray.length > 0) {
      let statusValues = moveStatusArray.map(status => status.value);
      if (statusValues.includes(null)) {
        setNullStatus(true);
      } else {
        setNullStatus(false);
      }
    } else setNullStatus(false)
    moveStatusArray?.forEach(status => {
      if (status.value !== null) {
        moveStatuses.push(status.value);
      }
    });
    setSelectedStatuses(moveStatuses);
  }, [moveStatusArray]);

  React.useEffect(() => {
    var cancelStatuses = [];
    cancelStatusArray?.forEach(status => {
        cancelStatuses.push(status.value);
    });
    setSelectedCancelStatuses(cancelStatuses);
  }, [cancelStatusArray]);


  const moveQuery = getMovesQuery(customerIdArray, selectedStatuses, selectedCancelStatuses, search, nullStatus);
  const [getMoves, { loading, error, data }] = useLazyQuery(moveQuery, {
    variables: {
      customerIdArray: customerIdArray,
      moveStatusArray: selectedStatuses,
      cancelStatusArray: selectedCancelStatuses,
      start: start,
      end: end,
      offset: paginationData?.offset,
      limit: paginationData?.limit,
      order_by: handleOrderBy(paginationData?.orderBy, paginationData?.order),
      searchText: search,
      isNull: nullStatus,
    },
  });

  const moveCountQuery = getMoveCountQuery(customerIdArray, selectedStatuses, selectedCancelStatuses, search, nullStatus);
  const [getMoveCount, { moveCountData }] = useLazyQuery(moveCountQuery, {
    variables: {
      customerIdArray: customerIdArray,
      moveStatusArray: selectedStatuses,
      cancelStatusArray: selectedCancelStatuses,
      start: start,
      end: end,
      searchText: search,
      isNull: nullStatus,
    },
  });

  const handleMoveCount = async () => {
    let moveTotal = await getMoveCount();
    if (moveTotal?.data?.moves_aggregate?.aggregate?.count) {
      setMoveCount(moveTotal.data.moves_aggregate.aggregate.count);
    } else setMoveCount(0);
  };

  const handleMoveQuery = async (filterChange = false) => {
    console.log('handling move query')
    if (filterChange) {
      console.log('with filter change')
      let moveData = await getMoves();
      setMoves(moveData?.data?.moves);
      setPaginationData({
        orderBy: 'id',
        limit: 50,
        order: 'desc',
        offset: 0,
      });
      setStatusChange(false)
      setCancelStatusChange(false)
      setSearchChange(false)
      setCustomerChange(false)
    } else {
      let moveData = await getMoves();
      const movesSet = new Set(moves?.map(obj => obj.id));
      const movesToAdd = moveData?.data?.moves?.filter(obj => !movesSet?.has(obj.id));
      if (movesToAdd?.length) {
        setMoves([...moves, ...movesToAdd]);
      }
    }

    handleMoveCount();
  };

  React.useEffect(() => {
    if (start || end || statusChange || customerChange || searchChange || cancelStatusChange ) {
    handleMoveQuery(true)
    }
  }, [start, end, statusChange, cancelStatusChange, searchChange, customerChange]);

  React.useEffect(() => {
    handleMoveQuery(false);
  }, [paginationData]);

  React.useEffect(() => {
    if (moveCountData) {
      setMoveCount(moveCountData);
    }
  }, [moveCountData]);

  if (error) {
    return <DefaultErrorFallback message='ERROR FETCHING MOVES' />;
  }

  if (!moves || (!moves.length)) {
    return <DefaultEmptyFallback message='NO MOVES FOUND' />;
  }

  return (
    <>
      <MovesTable
        moves={moves}
        incomingData={data?.moves}
        checkedMoves={checkedMoves}
        setCheckedMoves={setCheckedMoves}
        moveCount={moveCount}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </>
  );
}
