import { gql } from '@apollo/client';

const ADD_NOTIFICATION = gql`
  mutation add_notification( $type: String!, $title: String!, $body: String!, $createdby: String!, $createdat: timestamptz!, $assignedto: String, $assignedby: String, $assignedat: timestamptz, $notes: String, $duedate: timestamptz) {
    insert_notifications(object: {type: $type, title: $title, body: $body, createdby: $createdby, createdat: $createdat, assignedby: $assignedby, assignedat: $assignedat, assignedto: $assignedto,  notes: $notes, duedate: $duedate}) {
      id,
      title,
      type,
      body,
      createdat,
      createdby,
      assignedto,
      assignedat,
      assignedby,
      notes,
      duedate
    }
  }
`;

export { ADD_NOTIFICATION };