import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getUserRole, getUserName, getUserAvatar, getUserEmail } from '../../utils/authHelper'
import { ReactComponent as IconHD } from '../../static/bunny.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  card: {
    maxWidth: 500,
  },
  media: {
    objectFit: 'cover',
  },
  iconHd: {
    objectFit: 'cover',
    width: 250,
    height: 'auto',
    margin: 10,
    color: 'lightgray'
  },
  inlineTxt: {
    display: 'inline-flex',
    marginLeft: '10px',
  },
});

function ProfilePage(props) {
  const { classes } = props;

  const [role, setRole] = React.useState(null)
  const [name, setName] = React.useState(null)
  const [avatar, setAvatar] = React.useState(null)
  const [email, setEmail] = React.useState(null)

  React.useEffect(() => {
    const getRole = async () => {
      let userRole = await getUserRole()
      setRole(userRole)
    }
    
    let userName = getUserName()
    let userAvatar = getUserAvatar()
    let userEmail = getUserEmail()
    
    getRole()
    setName(userName)
    setAvatar(userAvatar)
    setEmail(userEmail)

  }, [])

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center'>
        <Card className={classes.card}>
          {avatar ? (
            <CardMedia
              component='img'
              alt='User profile picture'
              className={classes.media}
              height='200'
              image={avatar}
              title=''
            />
          ) : (
            <IconHD className={classes.iconHd}/>
          )}
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              {name || email || 'HopDrive Dispatch User'}
            </Typography>
            <Typography
              color='textSecondary'
              style={{ position: 'relative', top: '-18px' }}
              variant='h6'
              component='h6'
            >
              {!role ? 'Unregistered' : 'HopDrive'}
            </Typography>
            <Typography color='textSecondary'>
              Role:
              <Typography className={classes.inlineTxt}>
                {role ? 
                   role.charAt(0).toUpperCase() +
                    role.slice(1)
                  : 'Unregistered user'}
              </Typography>
            </Typography>
            <Typography color='textSecondary'>
              Email:
              <Typography className={classes.inlineTxt}>
                <a className='btn btn-link' href={email ? `mailto:${email}` : null}>
                  {email || 'No email on record'}
                </a>
              </Typography>
            </Typography>
          </CardContent>
          {/* <CardActions>
            <Button size='small' color='primary'>
              {role ? 'Edit Profile' : 'Register'}
            </Button>
          </CardActions> */}
        </Card>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ProfilePage);
