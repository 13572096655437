import React, { useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useMutation, useQuery } from '@apollo/client';
import { ModalContent, ModalHeader, ModalFooter, ModalAction } from '../../components/ModalComponents';
import Spacer from '../../components/Spacer';
import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import {
  ADD_CHASE_VEHICLE_ASSIGNMENT,
  GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID,
  GET_CUSTOMERS,
  GET_REGIONS,
} from './gql';

export default function AddAssignedChaseVehicleContent({ chaseId, onClose }) {
  const [customers, setCustomers] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(``);
  const [selectedRegion, setSelectedRegion] = React.useState(``);
  const [regions, setRegions] = React.useState([]);
  const [chaseVehicle, setChaseVehicle] = React.useState(null);

  const { data: customersData, loading: customersLoading, error: customersError } = useQuery(GET_CUSTOMERS);
  const { data: regionsData, loading: regionsLoading, error: regionsError } = useQuery(GET_REGIONS);
  const {
    data: chaseVehiclesData,
    loading: chaseVehiclesLoading,
    error: chaseVehiclesError,
  } = useQuery(GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID, {
    variables: { chase_id: chaseId },
  });

  useEffect(() => {
    if (customersData) {
      setCustomers(customersData.customers);
    }
    if (regionsData) {
      setRegions(regionsData.regions);
    }
    if (chaseVehiclesData) {
      setChaseVehicle(chaseVehiclesData.chasevehiclesassigned[0]);
    }
  }, [customersData, regionsData, chaseVehiclesData]);

  const [addChaseVehicleAssignment] = useMutation(ADD_CHASE_VEHICLE_ASSIGNMENT, {
    refetchQueries: [{ query: GET_ASSIGNED_CHASE_VEHICLES_BY_CHASE_ID, variables: { chase_id: chaseId } }],
    onCompleted: () => {
      onClose();
    },
  });

  const handleAdd = React.useCallback(() => {
    console.log(`Selected Customer:`, selectedCustomer);
    addChaseVehicleAssignment({
      variables: {
        input: {
          status: 'received',
          vin: chaseVehicle.vin,
          make: chaseVehicle.make,
          model: chaseVehicle.model,
          capacity: chaseVehicle.capacity,
          chase_vehicle_id: chaseId,
          customer_id: selectedCustomer.id || null,
          organization_id: selectedCustomer.organization_id || null,
          region_id: selectedRegion.id,
        },
      },
    });
  }, [selectedCustomer, selectedRegion]);

  if (customersLoading || regionsLoading || chaseVehiclesLoading)
    return <DefaultLoadingFallback message={`Fetching customers`} />;

  if (customersError || regionsError || chaseVehiclesError) {
    return <DefaultErrorFallback message={`Error fetching customers`} />;
  }

  return (
    <>
      <ModalHeader handleClose={onClose}>Assign Chase Vehicle</ModalHeader>

      <ModalContent>
        <Typography>Select the customer or region to assign this chase vehicle to.</Typography>

        <Spacer />

        <Autocomplete
          id='customer-select'
          options={customers}
          getOptionLabel={option => option.name || ''}
          value={customers.find(customer => customer.id === selectedCustomer.id) || null}
          onChange={(event, newValue) => {
            setSelectedCustomer(newValue ? newValue : '');
          }}
          renderInput={params => <TextField {...params} label='Customer' variant='outlined' size='small' fullWidth />}
        />

        <Spacer />

        <Autocomplete
          id='region-select'
          options={regions}
          getOptionLabel={option => option.name || ''}
          value={regions.find(region => region.id === selectedRegion.id) || null}
          onChange={(event, newValue) => {
            setSelectedRegion(newValue ? newValue : '');
          }}
          renderInput={params => <TextField {...params} label='Region' variant='outlined' size='small' fullWidth />}
        />
      </ModalContent>

      <ModalFooter>
        <ModalAction color='primary' disabled={!selectedRegion} onClick={handleAdd}>
          Add
        </ModalAction>

        <ModalAction onClick={onClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}
