//////////////////////// DEPENDENCIES ////////////////////////

import axios from 'axios';
import dayjs from 'dayjs';
import sdk from '@hopdrive/sdk';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { REACT_APP_A0_CB_SD } from '../utils/env';
import { getTimezoneFromMove } from '../utils/time';
import { useData } from '../providers/DataProvider';
import { useTools } from './useTools';

import { getUserToken } from '../utils/authHelper';

import { SimpleLogger } from '../utils/SimpleLogger';
const { log } = new SimpleLogger({ prefix: 'useTookan', enabled: false });

const avatarImg1 = `https://s3.us-east-1.amazonaws.com/socialauto/fleet_thumb_profile/user.png`;
const avatarImg2 = `https://s3.us-east-1.amazonaws.com/social-auto-new/test/fleet_thumb_profile/user.png`;
const avatarImg3 = `https://s3.us-east-1.amazonaws.com/social-auto-new/prod/fleet_thumb_profile/user.png`;

//////////////////////// HOOK ////////////////////////

export function useTookan() {
  const { apolloClient } = useData();
  const { condensedCase } = useTools();

  const sortImages = images => {
    const imageOrder = [
      `driverfront`,
      `driverfrontwheel`,
      `passengerfront`,
      `passengerfrontwheel`,
      `passengerrear`,
      `passengerrearwheel`,
      `driverrear`,
      `driverrearwheel`,
      `instrumentcluster`,
      `frontinterior`,
      `interiorrear`,
      `trunk`,
    ];

    if (images && images.length > 0) {
      let newImages = images.map((image, i) => {
        let num = 100;
        if (imageOrder.includes(condensedCase(image.label))) num = imageOrder.indexOf(condensedCase(image.label));
        return { ...image, order: num };
      });

      return newImages.sort((a, b) => {
        const aNum = a.order;
        const bNum = b.order;
        if (aNum > bNum) return 1;
        if (aNum < bNum) return -1;
        return 0;
      });
    } else return [];
  };

  /** Call to Tookan to get task details */
  const GET_TOOKAN_TASK = async (job_id = null) => {
    const token = await getUserToken();
    let variables = {
      type: `get_task_details`,
    };
    if (job_id) variables.job_id = job_id;

    return axios({
      method: `POST`,
      url: `/.netlify/functions/handleTookan`,
      data: variables,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  };

  /** Call to Tookan to get available agents */
  const GET_TOOKAN_DRIVERS = async (team_id = null) => {
    const token = await getUserToken();
    let variables = {
      type: `get_available_agents`,
    };
    if (team_id) variables.team_id = team_id;

    return axios({
      method: `POST`,
      url: `/.netlify/functions/handleTookan`,
      data: variables,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  };

  /** Call to Tookan to view regions */
  const GET_TOOKAN_REGIONS = async () => {
    const token = await getUserToken();
    let variables = {
      type: `view_regions`,
    };

    return axios({
      method: `POST`,
      url: `/.netlify/functions/handleTookan`,
      data: variables,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  };

  /** Get images (pickup/delivery) from Tookan */
  const getTookanImages = async (jobId = null, type) => {
    // Set defaults for returned data
    let images = [];
    let mainImage = null;
    let signerName = null;
    let signerImage = null;

    // Call Tookan and get data needed for render
    if (jobId) {
      try {
        const res = await GET_TOOKAN_TASK(jobId);
        log(`GET_TOOKAN_TASK`, res);
        if (
          res &&
          res.status === 200 &&
          res.data &&
          res.data.status === 200 &&
          res.data.data &&
          res.data.data.length > 0
        ) {
          // Filter a specific useable dataset that comes back from Tookan
          let taskHistory = res.data.data[0].task_history.map(item => item.description);
          taskHistory = taskHistory.filter(item => {
            if (condensedCase(item).includes(`taskimages`)) return true;
            if (condensedCase(item).includes(`acknowledgementimages`)) return true;
            if (condensedCase(item).includes(`signername`)) return true;
            return false;
          });

          // Check if the usable response exists
          if (taskHistory && taskHistory.length > 0) {
            // Filter a list of possible main images
            let mainImages = taskHistory.filter(
              item => condensedCase(item).includes(`driverfront`) && !condensedCase(item).includes(`wheel`)
            );

            // Set the main image
            if (mainImages && mainImages.length > 0) mainImage = JSON.parse(mainImages[0]).fleet_data;

            // Extract the fleet data
            let labels = taskHistory.map(item => {
              if (condensedCase(item).includes(`label`)) return JSON.parse(item).label;
              else return item;
            });

            // Extract the fleet data
            let fleetData = taskHistory.map(item => {
              if (condensedCase(item).includes(`fleetdata`)) return JSON.parse(item).fleet_data;
              else return item;
            });

            // Check if the extracted fleet data exists
            if (fleetData && fleetData.length > 0) {
              // Loop over the fleetData and set images, signerImage, and signerName
              for (let i = 0; i < fleetData.length; i++) {
                if (condensedCase(fleetData[i]).includes(`taskimages`)) {
                  images.push({ label: labels[i], src: fleetData[i] });
                }
                if (condensedCase(fleetData[i]).includes(`acknowledgementimages`)) {
                  signerImage = fleetData[i];
                }
                if (
                  !condensedCase(fleetData[i]).includes(`taskimages`) &&
                  !condensedCase(fleetData[i]).includes(`acknowledgementimages`)
                ) {
                  signerName = fleetData[i];
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(`Failed to fetch Tookan data from jobId:`, err);
      }
    }

    // Sort the images before setting them (and add an index field)
    if (images && images.length > 0) {
      images = sortImages(images);
      images.push({ label: `Signature`, src: signerImage });
      images = images.map((image, i) => {
        // Create a cleaned label to display
        const cleanLabel = image.label ? image.label.replace(/[.\s_-]/g, ` `) : null;

        // Check for signature data
        const isSignature = i === images.length - 1;
        const signatureDescPickup = `The pickup signature represents the confirmation of the specified vehicle being picked up at the correct location by the correct driver`;
        const signatureDescDelivery = `The delivery signature represents the confirmation of the specified vehicle being delivered to the correct location by the correct driver`;
        const signatureDesc =
          type === `pickup` ? signatureDescPickup : type === `delivery` ? signatureDescDelivery : null;

        // Return needed props for render
        return {
          label: cleanLabel,
          original: image.src,
          originalTitle: cleanLabel,
          thumbnail: image.src,
          thumbnailTitle: cleanLabel,
          thumbnailLabel: cleanLabel,
          description: isSignature ? `Signer Name: ${signerName} - ${signatureDesc}` : cleanLabel,
          isSignature: isSignature,
          index: i,
        };
      });
    }

    // Return data parsed from Tookan
    return { images, mainImage, signerImage, signerName };
  };

  /** Get drivers data from Tookan (pass in an id to get just one driver) */
  const getTookanDrivers = async (driverIds = [], teamId = null) => {
    // Set default variables for driver data
    let drivers = [];

    // Call Tookan and get data needed for render
    try {
      const res = await GET_TOOKAN_DRIVERS(teamId);
      log(`GET_TOOKAN_DRIVERS`, res);
      if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.length > 0
      ) {
        let tookanDrivers = res.data.data;
        tookanDrivers = tookanDrivers.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          else return 0;
        });

        if (driverIds && Array.isArray(driverIds) && driverIds.length > 0) {
          drivers = tookanDrivers.filter(driver => driverIds.includes(driver.fleet_id));
        } else {
          drivers = tookanDrivers;
        }
      }
    } catch (err) {
      console.error(`Failed to fetch driver data from Tookan:`, err);
    }

    // Return data parsed from Tookan
    return drivers;
  };

  /** Get pickup, delivery & driver data from Tookan, based on a move */
  const getAllTookanDataFromMove = async (pickupJobId = null, deliveryJobId = null, driverIds = []) => {
    // Set the default pickup and delivery objects
    let pickup = {};
    let delivery = {};
    let drivers = [];

    // Get the pickup, delivery and driver data from Tookan
    try {
      pickup = await getTookanImages(pickupJobId, `pickup`);
      delivery = await getTookanImages(deliveryJobId, `delivery`);
      drivers = await getTookanDrivers(driverIds);
    } catch (err) {
      console.error(`Error fetching data from Tookan:`, err);
    }

    // Return the pickup and delivery objects
    return { pickup, delivery, drivers };
  };

  /** Get region data from Tookan */
  const getTookanRegions = async () => {
    // Set default variables for region data
    let regions = [];

    // Call Tookan and get data needed for render
    try {
      const res = await GET_TOOKAN_REGIONS();
      log(`GET_TOOKAN_REGIONS`, res);
      if (getPropValue(res, `data.data`)) {
        regions = getPropValue(res, `data.data`);
        // Filter out inactive regions
        if (regions.length > 0) {
          regions = regions.filter(reg => reg.is_active === 1);
        }
      }
      return regions;
    } catch (err) {
      console.error(`Could not find regions from Tookan:`, err);
      return [];
    }
  };

  /** Get the avatar image from Tookan driver data */
  const getTookanAvatarUrl = (tookanDriver = null) => {
    if (tookanDriver) {
      if (
        tookanDriver.fleet_image === avatarImg1 ||
        tookanDriver.fleet_image === avatarImg2 ||
        tookanDriver.fleet_image === avatarImg3 ||
        tookanDriver.fleet_thumb_image === avatarImg1 ||
        tookanDriver.fleet_thumb_image === avatarImg2 ||
        tookanDriver.fleet_thumb_image === avatarImg3
      )
        return null;
      if (tookanDriver.fleet_image === `app/img/user.png` && tookanDriver.fleet_thumb_image)
        return tookanDriver.fleet_thumb_image;
      if (tookanDriver.fleet_image) return tookanDriver.fleet_image;
    }
    return null;
  };

  /** Get the phone type from Tookan device_type */
  const getTookanPhoneOs = deviceType => {
    if (deviceType === 1 || deviceType === 5) return `ios`;
    else if (deviceType) return `android`;
    else return null;
  };

  /** Use the Tookan tags to determine if the driver can drive manual */
  const getTookanManualTrained = tags => {
    if (tags && tags.includes(`manual`)) return true;
    return false;
  };

  /** Use the Tookan tags to determine if the driver can drive concierge */
  const getTookanConciergeTrained = tags => {
    if (tags && tags.includes(`concierge`)) return true;
    return false;
  };

  /** Use the Tookan team_id to determine the region ID */
  const getRegionIdByTookanTeamId = async (teamId = null) => {
    if (teamId) {
      try {
        const res = await apolloClient.query({
          query: gql`
            query get_region_by_team_id($teamId: bigint!) {
              regions(where: { team_id: { _eq: $teamId } }) {
                id
                team_id
                name
              }
            }
          `,
          variables: { teamId },
        });
        if (res && res.data && res.data.regions && res.data.regions.length) {
          const region = res.data.regions[0];
          return region.id;
        }
      } catch (err) {
        console.error(`Failed to fetch region id by team_id:`, err);
      }
    }
    return 0;
  };

  //////////////// START PLANS FUNCTIONS ////////////////

  // Replace timezone "-" with "+" because Tookan does things backwards
  // NOTE: This will not work internationally, some timezones may need the "+" replaced with "-"
  const parseTookanTimezone = str => {
    if (str) return str.substring(0, 19) + `+` + str.substring(20);
    return str;
  };

  const Tookan = {
    getDrivers: async function (regions) {
      const token = await getUserToken();
      let drivers = [];
      const driverLoad = await axios({
        method: 'post',
        url: `/.netlify/functions/handleTookan`,
        data: {
          type: `get_available_agents`,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.data.message === 'INVALID_API_KEY') {
            log('Tookan.getDrivers Invalid Tookan API key!', response);
            return;
          }
          log('drivers found', response.data.data);
          if (response.data.data.length > 0) drivers = drivers.concat(response.data.data);
          localStorage.setItem('drivers', JSON.stringify(drivers));
        })
        .catch(error => {
          log(
            `Tookan.getDrivers Failed fatching driver list from Tookan API. Attempting to load from local storage instead...`,
            error
          );
        });

      if (!drivers || drivers.length < 1) {
        log('loading drivers from localStorage', drivers);
        if (localStorage.hasOwnProperty('drivers')) {
          const driversJSON = localStorage.getItem('drivers');
          log(`driversJSON from local storage: `, driversJSON);
          drivers = JSON.parse(driversJSON);
          log(`Loaded ${drivers.length} drivers from local storage`, drivers);
        }
      }
      log('get drivers finished with:', drivers);
      drivers = drivers.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      return drivers;
    },

    saveMoveToTookan: async function (client, moveObj, move_id) {
      const token = await getUserToken();
      await axios({
        method: 'post',
        url: `/.netlify/functions/handleTookan`,
        data: Object.assign({}, moveObj, { type: `create_multiple_tasks` }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log('saveMoveToTookan => res:', res);
          if (res.status === 200 && res.data.status === 200) {
            log('Tookan call successful');
            Tookan.updateMoveWithTookanIDs(client, move_id, res);
          }
        })
        .catch(err => {
          log(err);
        });
    },

    updateMoveInTookan: async function (client, moveObj, move_id) {
      const token = await getUserToken();
      log('updateMoveInTookan with moveObj, move_id:', moveObj, move_id);
      await axios({
        method: 'post',
        url: `/.netlify/functions/handleTookan`,
        data: Object.assign({}, moveObj, { type: `edit_multiple_tasks` }),
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log('updateMoveInTookan => res:', res);
          if (res.status === 200 && res.data.status === 200) {
            log('Tookan call successful');
            Tookan.handleTookanSyncTimestamp(client, move_id);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    cancelMoveInTookan: async function (client, job_id, move_id) {
      const token = await getUserToken();
      log('cancelMoveInTookan with job_id, move_id:', job_id, move_id);
      await axios({
        method: 'post',
        url: `/.netlify/functions/handleTookan`,
        data: { type: `cancel_task`, job_id: job_id, job_status: 9 },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log('cancelMoveInTookan => res:', res);
          if (res.status === 200 && res.data.status === 200) {
            log('Tookan call successful');
            Tookan.handleTookanSyncTimestamp(client, move_id);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    handleAutoAssignment: move => {
      if (move.auto_assign < 1) {
        return false;
      } else if (move.class.toLowerCase().trim() === 'stranded' && move.sequence < 2) {
        return true;
      } else {
        return false;
      }
    },

    updateDriverInTookan: async function (client, job_id, move_id, driver_id) {
      const token = await getUserToken();
      log('updateDriverInTookan with job_id, move_id, driver_id:', job_id, move_id, driver_id);
      await axios({
        method: 'post',
        url: `/.netlify/functions/handleTookan`,
        data: { type: `assign_task`, job_id: job_id, fleet_id: driver_id },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          log('updateDriverInTookan => res:', res);
          if (res.status === 200 && res.data.status === 200) {
            log('Tookan call successful');
            Tookan.handleTookanSyncTimestamp(client, move_id);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    // Function to take in an array of moves and perform the necessary Tookan operation (create/update/delete)
    handleTookanMoves: async function (client, moves) {
      // Loop through each move
      moves.forEach(async move => {
        log('Parsing move #', move.id, 'move obj:', move);
        log(
          '   synced_with_tookan:',
          move.synced_with_tookan,
          ', updatedat:',
          move.updatedat,
          move.synced_with_tookan === move.updatedat
        );
        log('   tookan_relationship_id:', move.tookan_relationship_id, parseInt(move.tookan_relationship_id) > 0);
        log('   pickup_stop_id:', move.pickup_stop_id, parseInt(move.pickup_stop_id) > 0);
        log('   delivery_stop_id:', move.delivery_stop_id, parseInt(move.delivery_stop_id) > 0);

        // Logic for Tookan RIDE moves
        if (move.move_type === 'ride' && (!move.driver_app_version || move.driver_app_version === `1.0.0`)) {
          // Ignore ride moves that take place after the parent move
          if (!move.parentMove) return;
          if (move.pickup_time > move.parentMove.pickup_time) return;

          // Set pickup and delivery time + timezone offset
          let pickupTime = dayjs.utc(move.pickup_time);

          const timezone = getTimezoneFromMove(move);
          if (timezone) pickupTime = pickupTime.tz(timezone, true);

          pickupTime = parseTookanTimezone(pickupTime.format());

          // Otherwise, create a pickup-only task in Tookan to reflect the ride-first move
          let rideUrl = await axios({
            url: `/.netlify/functions/handleRideshareURL`,
            method: 'post',
            data: { id: move.id, urlOnly: true },
          });
          let pickupDetails = {
            address: move.lane.pickup.address,
            time: pickupTime,
            phone: move.lane.pickup.phone,
            template_name: 'LyftPickup',
            template_data: [
              {
                label: 'Call_Lyft',
                data: rideUrl
                  ? rideUrl.data
                  : 'Call Dispatch at 804-913-7674 for your ride info, or text at 804-487-3935.',
              },
            ],
            name: `${move.lane.customer.name} - ${move.lane.pickup.name}`,
            email: move.lane.pickup.email,
            latitude: move.lane.pickup.latitude,
            longitude: move.lane.pickup.longitude,
            order_id: `Plan #${move.plan_id} - Move #${move.id}`,
            job_description: move.move_details,
          };
          // delete the coordinates from the move object if they are null or do not exist
          if (!pickupDetails.latitude) delete pickupDetails.latitude;
          if (!pickupDetails.longitude) delete pickupDetails.longitude;
          let moveRequestData = {
            fleet_id: move.driver_id, // Fleet ID is equivalent to driver ID, can be set to specific driver
            timezone: '+300', // This needs to be dynamic as we expand into new timezones
            has_pickup: 1,
            has_delivery: 0,
            layout_type: 0,
            geofence: 0,
            auto_assignment: Tookan.handleAutoAssignment(move),
            pickups: [pickupDetails],
          };
          if (
            // Pickup task for Lyft already exists
            move.tookan_relationship_id &&
            parseInt(move.tookan_relationship_id) > 0 &&
            move.pickup_stop_id &&
            parseInt(move.pickup_stop_id) > 0
          ) {
            if (move.active !== 1) {
              // set the pickup task in Tookan to 'canceled' if the Lyft has been canceled
              Tookan.cancelMoveInTookan(client, move.pickup_stop_id, move.id);
            } else {
              if (move.synced_with_tookan === move.updatedat) return; // Ignore if in sync with Tookan
              // Else update the pickup task with new data
              moveRequestData = {
                pickups: [Object.assign(pickupDetails, { job_id: move.pickup_stop_id })],
              };
              await Tookan.updateMoveInTookan(client, moveRequestData, move.id);
              // Check for previous driver in local storage
              let previousDriverId = localStorage.getItem(`${move.id}-driver_id`);
              // Update pickup task with new driver if move has changed plans
              if (previousDriverId && previousDriverId !== move.driver_id) {
                await Tookan.updateDriverInTookan(client, move.pickup_stop_id, move.id, move.driver_id);
              }
            }
          }
          // Otherwise, create a new pickup task
          else Tookan.saveMoveToTookan(client, moveRequestData, move.id);
        }

        // Logic for Tookan DRIVE moves
        if (move.move_type === 'drive' && (!move.driver_app_version || move.driver_app_version === `1.0.0`)) {
          // Set pickup and delivery time + timezone offset
          let pickupTime = dayjs.utc(move.pickup_time);
          let deliveryTime = dayjs.utc(move.delivery_time);

          const timezone = getTimezoneFromMove(move);
          if (timezone) {
            pickupTime = pickupTime.tz(timezone, true);
            deliveryTime = deliveryTime.tz(timezone, true);
          }

          pickupTime = parseTookanTimezone(pickupTime.format());
          deliveryTime = parseTookanTimezone(deliveryTime.format());

          //TODO: remove this after full transistion to new parent move system
          const returnRide = move.moveByReturnRideId ? move.moveByReturnRideId : null;
          const firstChildMove =
            move.childMoves && Array.isArray(move.childMoves) && move.childMoves.length
              ? move.childMoves[0]
              : returnRide;

          // Fill out request object
          let moveRequestData,
            pickupDetails = {
              address: move.lane.pickup.address,
              time: pickupTime,
              phone: move.lane.pickup.phone,
              template_name: this.handleTemplateName(move, 'pickup'),
              template_data: [
                {
                  label: 'Stock_Number',
                  data: move.vehicle_stock,
                },
                {
                  label: 'Year',
                  data: move.vehicle_year,
                },
                {
                  label: 'Make',
                  data: move.vehicle_make,
                },
                {
                  label: 'Model',
                  data: move.vehicle_model,
                },
                {
                  label: 'Color',
                  data: move.vehicle_color,
                },
                {
                  label: 'Odometer',
                  data: move.vehicle_odometer,
                },
                {
                  label: 'VIN',
                  data: move.vehicle_vin,
                },
                {
                  label: 'Location_Details',
                  data: `https://${
                    REACT_APP_A0_CB_SD === 'admin' ? `driver` : `driver-test`
                  }.socialautotransport.com/locations/${move.lane.pickup.id}`,
                },
                {
                  label: 'DrivePay',
                  data: this.handleDriverPayAmt(move),
                },
                {
                  label: 'RidePay',
                  data: firstChildMove ? this.handleDriverPayAmt(firstChildMove.id) : '',
                },
                {
                  label: 'Consumer_Name',
                  data: move.consumer_name
                    ? move.consumer_name
                    : move.consumer_type === 'loaner'
                    ? move.parentMove && move.parentMove.consumer_name
                      ? move.parentMove.consumer_name
                      : ''
                    : '',
                },
                {
                  label: 'Consumer_Phone',
                  data: move.consumer_phone
                    ? move.consumer_phone
                    : move.consumer_type === 'loaner'
                    ? move.parentMove && move.parentMove.consumer_phone
                      ? move.parentMove.consumer_phone
                      : ''
                    : '',
                },
                {
                  label: 'Move_Type',
                  data:
                    move.consumer_type && move.consumer_type === 'loaner'
                      ? 'Loaner Pickup'
                      : move.consumer_type && move.consumer_type === 'customer'
                      ? 'Consumer Pickup'
                      : 'Standard Pickup',
                  // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                  // This will set the tag based on the actual vehicle type specified by the dealer.
                },
                {
                  label: 'Dealer_Contact',
                  data: move.dealer_contact ? move.dealer_contact : '',
                },
              ],
              ref_images: [move.vehicle_image],
              name: `${move.lane.customer.name} - ${move.lane.pickup.name}`,
              email: move.lane.pickup.email,
              latitude: move.lane.pickup.latitude,
              longitude: move.lane.pickup.longitude,
              order_id: `Plan #${move.plan_id} - Move #${move.id}`,
              job_description: move.move_details,
            },
            deliveryDetails = {
              address: move.lane.delivery.address,
              time: deliveryTime,
              phone: move.lane.delivery.phone,
              template_name: this.handleTemplateName(move, 'delivery'),
              template_data: [
                {
                  label: 'Location_Details',
                  data: `https://${
                    REACT_APP_A0_CB_SD === 'admin' ? `driver` : `driver-test`
                  }.socialautotransport.com/locations/${move.lane.delivery.id}`,
                },
                {
                  label: 'Consumer_Name',
                  data: move.consumer_name ? move.consumer_name : '',
                },
                {
                  label: 'Consumer_Phone',
                  data: move.consumer_phone ? move.consumer_phone : '',
                },
                {
                  label: 'Move_Type',
                  data:
                    move.consumer_type && move.consumer_type === 'loaner'
                      ? 'Loaner Delivery'
                      : move.consumer_type && move.consumer_type === 'customer'
                      ? 'Consumer Delivery'
                      : 'Standard Delivery',
                  // Previously looking at 'consumer_pickup' field but that is currently always set on the 2nd move in a C+L sequence.
                  // This will set the tag based on the actual vehicle type specified by the dealer.
                },
                {
                  label: 'Dealer_Contact',
                  data: move.dealer_contact ? move.dealer_contact : '',
                },
              ],
              name: `${move.lane.customer.name} - ${move.lane.delivery.name}`,
              email: move.lane.delivery.email,
              order_id: `Plan #${move.plan_id} - Move #${move.id}`,
              latitude: move.lane.delivery.latitude,
              longitude: move.lane.delivery.longitude,
              job_description: move.move_details,
            };

          // --------------------------------------------------------------------------------
          // delete the coordinates from the move object if they are null or do not exist
          if (!pickupDetails.latitude) delete pickupDetails.latitude;
          if (!pickupDetails.longitude) delete pickupDetails.longitude;
          if (!deliveryDetails.latitude) delete deliveryDetails.latitude;
          if (!deliveryDetails.longitude) delete deliveryDetails.longitude;
          // Conditions for an existing move to be updated
          if (
            move.tookan_relationship_id &&
            parseInt(move.tookan_relationship_id) > 0 &&
            move.pickup_stop_id &&
            parseInt(move.pickup_stop_id) > 0 &&
            move.delivery_stop_id &&
            parseInt(move.delivery_stop_id) > 0
          ) {
            if (move.active !== 1) {
              // ignore active moves
              // set both the pickup and delivery tasks in Tookan to 'canceled'
              Tookan.cancelMoveInTookan(client, move.pickup_stop_id, move.id);
              Tookan.cancelMoveInTookan(client, move.delivery_stop_id, move.id);
            } else {
              // Will need to check against a new timestamped field in the moves db to see if it is out of sync wih Tookan
              if (move.synced_with_tookan === move.updatedat) return;
              // update the pickup and delivery tasks of an existing move with the submitted move
              moveRequestData = {
                pickups: [Object.assign(pickupDetails, { job_id: move.pickup_stop_id })],
                deliveries: [Object.assign(deliveryDetails, { job_id: move.delivery_stop_id })],
              };
              await Tookan.updateMoveInTookan(client, moveRequestData, move.id);
              // Check for previous driver in local storage
              let previousDriverId = localStorage.getItem(`${move.id}-driver_id`);
              // Update pickup and delivery tasks with new driver if move has changed plans
              if (previousDriverId && previousDriverId !== move.driver_id) {
                await Tookan.updateDriverInTookan(client, move.pickup_stop_id, move.id, move.driver_id);
                Tookan.updateDriverInTookan(client, move.delivery_stop_id, move.id, move.driver_id);
              }
            }
          } else if (move.active === 1) {
            // create a pickup and delivery task in Tookan from the submitted move
            moveRequestData = {
              fleet_id: move.driver_id, // Fleet ID is equivalent to driver ID, can be set to specific driver
              timezone: '0',
              has_pickup: 1,
              has_delivery: 1,
              layout_type: 0,
              geofence: 0,
              auto_assignment: Tookan.handleAutoAssignment(move),
              pickups: [pickupDetails],
              deliveries: [deliveryDetails],
            };
            Tookan.saveMoveToTookan(client, moveRequestData, move.id);
          }
        }
      }); // closes out moves.forEach()
    },

    // Function to update a move entry in Hasura with the timestamp for when it was synced to Tookan
    handleTookanSyncTimestamp: async function (client, id) {
      // GQL update statement
      const UPDATE_TIMESTAMPS_QUERY = gql`
        mutation updateTimestamps($id: bigint!) {
          update_moves(where: { id: { _eq: $id } }, _set: { synced_with_tookan: "now()", updatedat: "now()" }) {
            returning {
              id
              synced_with_tookan
              updatedat
            }
          }
        }
      `;
      await client
        .mutate({
          mutation: UPDATE_TIMESTAMPS_QUERY,
          variables: { id: id },
        })
        .then(res => {
          log(res);
          if (res.errors) {
            Sentry.captureException(res.errors[0]);
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err);
        });
      return null;
    },

    updateMoveWithTookanIDs: async function (client, move_id, tookan_res) {
      // GQL update statement
      const UPDATE_MOVE_QUERY = gql`
          mutation updateTimestamps(
            $id: bigint!
            $tookan_relationship_id: String!
            $pickup_stop_id: String!
            $delivery_stop_id: String
            $driver_status: String!
            $driver_app_version: String!
          ) {
            update_moves(
              where: { id: { _eq: $id } }
              _set: {
                status: "dispatched"
                synced_with_tookan: "now()"
                updatedat: "now()"
                tookan_relationship_id: $tookan_relationship_id
                pickup_stop_id: $pickup_stop_id
                delivery_stop_id: $delivery_stop_id
                driver_status: $driver_status
                driver_app_version: $driver_app_version
              }
            ) {
              returning {
                id
                synced_with_tookan
                updatedat
                tookan_relationship_id
                pickup_stop_id
                delivery_stop_id
                driver_status
              }
            }
          }
        `,
        // Parameters to update
        UPDATE_MOVE_VARIABLES = {
          id: move_id,
          tookan_relationship_id: tookan_res.data.data.pickups[0].job_token.toString(),
          pickup_stop_id: tookan_res.data.data.pickups[0].job_id.toString(),
          delivery_stop_id: tookan_res.data.data.deliveries
            ? tookan_res.data.data.deliveries[0].job_id.toString()
            : null,
          driver_status: 'accepted',
          driver_app_version: '1.0.0',
        };
      await client
        .mutate({
          mutation: UPDATE_MOVE_QUERY,
          variables: UPDATE_MOVE_VARIABLES,
        })
        .then(res => {
          log(res);
          if (res.errors) {
            Sentry.captureException(res.errors[0]);
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err);
        });
    },

    handleMoveUnplan: async function (client, move) {
      log('handleMoveUnplan with move:', move);
      // GQL update statement
      const UPDATE_MOVE_QUERY = gql`
        mutation updateUnplannedMove($id: bigint!) {
          update_moves(
            where: { id: { _eq: $id } }
            _set: {
              synced_with_tookan: "now()"
              updatedat: "now()"
              tookan_relationship_id: null
              pickup_stop_id: null
              delivery_stop_id: null
              driver_id: null
              driver_name: null
            }
          ) {
            returning {
              id
              synced_with_tookan
              updatedat
              tookan_relationship_id
              pickup_stop_id
              delivery_stop_id
              driver_id
              driver_name
            }
          }
        }
      `;
      // Set the statuses of the pickup and stop tasks in Tookan to 'canceled'
      // Tookan.cancelMoveInTookan(client, move.pickup_stop_id, move.id);
      // if (move.delivery_stop_id) Tookan.cancelMoveInTookan(client, move.delivery_stop_id, move.id);
      // Update the move entry in Hasura and delete the related Tookan ID fields
      await client
        .mutate({
          mutation: UPDATE_MOVE_QUERY,
          variables: { id: move.id },
        })
        .then(res => {
          log(res);
          if (res.errors) {
            Sentry.captureException(res.errors[0]);
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err);
        });
    },

    handleDriverPayAmt: function (move) {
      try {
        return `$${move.appayments.find(o => o.type === 'move pay').amount.toFixed(2)}`;
      } catch (err) {
        return '';
      }
    },

    handleTemplateName: function (move, taskType) {
      // Get customer deafults
      const customerConfig = sdk.configs.enrichCustomerConfig(
        move?.customer?.config,
        move?.customer?.organization?.config
      );

      // Check for 'pickup' task type
      if (taskType && taskType.toLowerCase() === 'pickup') {
        // Check if there is a pickup workflow id (default to 1)
        const pickupId = move?.pickup_workflow_id || customerConfig?.default_pickup_workflow_id || 1;
        if (pickupId && pickupId !== 1) return `Custom-${pickupId}`;

        // Fallback to the default pickup template otherwise
        return `Pickup`;
      }

      // Check for 'delivery' task type
      if (taskType && taskType.toLowerCase() === 'delivery') {
        // Check if there is a delivery workflow id (default to 2)
        const deliveryId = move?.delivery_workflow_id || customerConfig?.default_delivery_workflow_id || 2;
        if (deliveryId && deliveryId !== 2) return `Custom-${deliveryId}`;

        // Fallback to the default delivery template otherwise
        return `Delivery`;
      }

      // Return null if the task type is not recognised
      return null;
    },
  };

  //////////////// END PLANS FUNCTIONS ////////////////

  // Return logic from hook
  return {
    getTookanImages,
    getTookanDrivers,
    getAllTookanDataFromMove,
    getTookanRegions,
    getTookanAvatarUrl,
    getTookanPhoneOs,
    getTookanManualTrained,
    getTookanConciergeTrained,
    getRegionIdByTookanTeamId,
    Tookan,
  };
}
