import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import fragments from '../../utils/fragments';
import gql from 'graphql-tag';
import MarkAsPaidModal from './MarkAsPaidModal';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

export default function MarkAsPaidButton({ payPeriod }) {
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setOpen(true);
  };

  const { loading, error, data } = useQuery(GET_APPAYMENTS, {
    variables: { driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start, end: payPeriod.pickup_time_end },
  });

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAID BUTTON' />}>
      {data && data.appayments && data.appayments.length > 0 && (
        <MarkAsPaidModal open={open} setOpen={setOpen} appayments={data.appayments} />
      )}
      <Button
        disabled={
          payPeriod.status === 'paid' ||
          loading ||
          error ||
          open ||
          (data && data.appayments && data.appayments.length === 0)
        }
        size='small'
        variant='contained'
        color='primary'
        onClick={handleClick}
      >
        Mark as Paid
      </Button>
    </Sentry.ErrorBoundary>
  );
}

const GET_APPAYMENTS = gql`
  query get_unpaid_payments_for_mark_as_paid_button($driverId: bigint, $start: timestamptz, $end: timestamptz) {
    appayments(
      where: {
        move: {
          active: { _eq: 1 }
          pickup_time: { _gte: $start, _lte: $end }
          _or: [{ cancel_status: { _neq: "canceled" } }, { cancel_status: { _is_null: true } }]
        }
        active: { _eq: 1 }
        status: { _eq: "unpaid" }
        driver_id: { _eq: $driverId }
      }
      order_by: { move: { pickup_time: desc } }
    ) {
      ...APPayment
      move {
        ...Move
      }
      accessorial {
        ...Accessorials
      }
    }
  }
  ${fragments.move}
  ${fragments.appayment}
  ${fragments.accessorials}
`;
