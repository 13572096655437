//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function AttemptInfoFull({ ride = {}, attempt = {}, rideCostEstimate = 0, calcedSurge = 0 }) {
  const cls = useStyles();
  const { getFormattedRidesharePartner, getFormattedVehicleFromMove, formatUSD, getCleansedPhoneNumber } = useTools();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ride&nbsp;Partner:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{getFormattedRidesharePartner(attempt.ride_type) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Rideshare&nbsp;ID:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.rideshare_id || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          {attempt.ride_cost ? (
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{formatUSD(attempt.ride_cost) || `-`}</Typography>
            </div>
          ) : (
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>
                Estimated&nbsp;Ride&nbsp;Cost&nbsp;({getFormattedRidesharePartner(attempt.ride_type) || `-`}
                ):&nbsp;&nbsp;
              </Typography>
              <Typography className={cls.valTxt}>{formatUSD(attempt.estimated_ride_cost) || `-`}</Typography>
            </div>
          )}
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>
              Surge&nbsp;Cost&nbsp;Percentage&nbsp;({getFormattedRidesharePartner(attempt.ride_type) || `-`}
              ):&nbsp;&nbsp;
            </Typography>
            <Typography className={cls.valTxt}>{attempt.surge_cost_percentage || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ride&nbsp;Duration:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {attempt.ride_duration ? `${(attempt.ride_duration / 60).toFixed(1)}\xa0min` : `-`}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ride&nbsp;Distance:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {attempt.ride_distance ? `${attempt.ride_distance.toFixed(1)}\xa0mi` : `-`}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Time&nbsp;of&nbsp;Request:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {attempt.requested_at || attempt.createdat
                ? dayjs(attempt.requested_at || attempt.createdat).format(`MM/DD/YYYY hh:mm A z`)
                : `-`}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Estimated&nbsp;Arrival&nbsp;Time:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {attempt.estimated_arrival_time
                ? dayjs(attempt.estimated_arrival_time).format(`MM/DD/YYYY hh:mm A z`)
                : `-`}
            </Typography>
          </div>
        </Grid>

        <div className={cls.hiddenLineBreak} />

        <Grid item sm={6} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.driver_first_name || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Driver&nbsp;Phone:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{getCleansedPhoneNumber(attempt.driver_phone || `-`)}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Estimated&nbsp;Ride&nbsp;Cost&nbsp;(HopDrive):&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(rideCostEstimate) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Hopdrive&nbsp;Surge&nbsp;Charge:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{formatUSD(calcedSurge) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Driver&nbsp;Rating:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.driver_rating || `-`} / 5</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{getFormattedVehicleFromMove(attempt) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>License&nbsp;Plate:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.driver_vehicle_license_plate || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>License&nbsp;State:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{attempt.driver_vehicle_license_plate_state || `-`}</Typography>
          </div>
        </Grid>
      </Grid>

      {ride.decision_reason && (
        <Grid container>
          <Grid item xs={12}>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Decision&nbsp;Reason:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt} style={{ textAlign: 'left' }}>
                {ride.decision_reason || `-`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
