// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormDate({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [date, setDate] = React.useState(dayjs().format(`YYYY-MM-DD`));

  const handleDateChange = date => {
    const newDate = dayjs(date).format(`YYYY-MM-DD`);
    setDate(newDate);
    setFormData({
      ...formData,
      capacity: 1,
      customerId: undefined,
      date: newDate,
      driverIds: [],
      laneIds: [],
      organizationId: undefined,
      regionId: undefined,
      timezone: dayjs.tz.guess(),
      vehicleDeliveryLocationId: undefined,
      vehicleId: undefined,
      vehiclePickupLocationId: undefined,
    });
  };

  // Set the initial date
  React.useEffect(() => {
    if (initialLoad && initialFormData?.date) {
      setDate(initialFormData?.date);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData]);

  return (
    <div className={cls.root}>
      <label className={cls.textFieldLabel} htmlFor='date'>
        What date would you like to plan this group?<span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={cls.date}
          required
          autoOk
          name='date'
          minDate={dayjs().format()}
          format='MM/dd/yyyy'
          orientation='portrait'
          variant='inline'
          inputVariant='outlined'
          size='small'
          value={dayjs(date).format()}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  date: {
    display: `block`,
    maxWidth: `100%`,
  },
}));
