// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { WorkflowProvider } from '../WorkflowComponents/WorkflowProvider';
import { MoveOutcomeProvider } from './MoveOutcomeProvider';

import { Page } from '../PageComponents';
import { Modal } from '../ModalComponents';

import MoveOutcomePage from './MoveOutcomePage';
import MoveOutcomeModal from './MoveOutcomeModal';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcome({ open, onClose, renderType = `page`, input = {} }) {
  // Handle closing and optionally pass in an output
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  // Render type page
  if (renderType === `page`)
    return (
      <Page open={open} handleClose={handleClose}>
        {open ? (
          <WorkflowProvider>
            <MoveOutcomeProvider>
              <MoveOutcomePage handleClose={handleClose} input={input} />
            </MoveOutcomeProvider>
          </WorkflowProvider>
        ) : null}
      </Page>
    );

  // Render type modal
  if (renderType === `modal`)
    return (
      <Modal open={open} handleClose={handleClose}>
        {open ? (
          <WorkflowProvider>
            <MoveOutcomeProvider>
              <MoveOutcomeModal handleClose={handleClose} input={input} />
            </MoveOutcomeProvider>
          </WorkflowProvider>
        ) : null}
      </Modal>
    );
  return null;
}
