//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal } from '../ModalComponents';
import InsuranceRateFormModal from './InsuranceRateFormModal';

//////////////////////// COMPONENT ////////////////////////

// The Modal form inputs do not work correctly without this wrapper component

export default function InsuranceRateFormModalWrapper({  
    open = false,
    editMode = false,
    currentInsuranceRateId,
    selectedInsuranceRate = {},
    onEditSave = setUnsavedInsuranceRate => {},
    onAddSave,
    onCancel = () => {},}) {
  return (
    <Modal open={open}>
        {open ? (<InsuranceRateFormModal
            editMode = {editMode}
            currentInsuranceRateId = {currentInsuranceRateId}
            selectedInsuranceRate = {selectedInsuranceRate}
            onEditSave = {onEditSave}
            onAddSave = {onAddSave}
            onCancel = {onCancel}
        />):null}
    </Modal>
  );
}
