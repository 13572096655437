import React, { useState, useEffect } from 'react';
import {
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core';
import gql from 'graphql-tag';
import Loading from './Loading';
import AutoRefresh from './AutoRefresh';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from './Fallbacks/DefaultErrorFallback';
import { useRegionsGlobals } from '../providers/RegionsProvider'
import { getAllowedRegions } from '../utils/authHelper'

let log = false;

const GET_REGIONS = gql`
  query getAllowedRegions($allowed_regions: [bigint!]) {
    regions(where: { id: { _in: $allowed_regions } }) {
      id
      name
      team_id
    }
  }
`;

const styles = theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
});

const RegionSelectModal = ({ open, setOpen, classes }) => {

  const {selectedRegionIds, setSelectedRegionIds} = useRegionsGlobals();


  const [regions, setRegions] = useState(
    JSON.parse(localStorage.getItem('selected-regions')) ?  JSON.parse(localStorage.getItem('selected-regions')) : null
  );
  const [appLoad, setLoading] = useState(false);
  const [allowedRegions, setAllowedRegions] = useState([]);

  // Update user's allowed regions on function mount if they have changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {

    const getUserRegions = async () => {
      const regionsJwt = await getAllowedRegions()
      if (regionsJwt) {
        let parsedRegions = JSON.parse(regionsJwt.replace('{', '[').replace('}', ']'))
        setAllowedRegions(parsedRegions)
      }
    }

    getUserRegions()
  }, []);

  //If the user hasn't selected their own regions yet, set all regions to selected
  useEffect(() => {
    if (regions === null){
      getInitialLocalStorageValues(allowedRegions)
    }
    refetch()
  }, [regions, allowedRegions])

  const getInitialLocalStorageValues = (ar) => {
    let initialLocalStorageObj = {}
    ar.forEach(element => {
      initialLocalStorageObj[element] = true
    })
    setRegions(initialLocalStorageObj)
    localStorage.setItem('selected-regions', JSON.stringify(initialLocalStorageObj))
  }

  const getSelectedRegions = () => {
    let regionPreferencesObject = JSON.parse(localStorage.getItem('selected-regions'))
    let result = Object.keys(regions).map(function (key) {
      return [Number(key), regionPreferencesObject[key]]
    })
    let preferredRegionIds = []
    result.map(r => {
      if (r[1] === true ){
        preferredRegionIds = preferredRegionIds.concat(r[0])
      }
      return preferredRegionIds
    })
    return preferredRegionIds
  }

  const handleLocalStorageSave = () => {
      localStorage.setItem('selected-regions', JSON.stringify(regions))
      setSelectedRegionIds(getSelectedRegions())
      setOpen(false)
    }
  
  const checkDisabled = () => {
    if (localStorage.getItem('selected-regions') !== JSON.stringify(regions)){
      return false
    } else return true
  }

  const handleChange = name => event => {
    setRegions({ ...regions, [name]: event.target.checked });
  };

  const handleRefetch = async () => {
    const regionsJwt = await getAllowedRegions()
    if (regionsJwt) {
      let parsedRegions = JSON.parse(regionsJwt.replace('{', '[').replace('}', ']'))
      setAllowedRegions(parsedRegions)
    }
  }

  const { loading, error, data, refetch } = useQuery(GET_REGIONS, {
    variables: { allowed_regions: allowedRegions },
  });

  if (loading) return null;
  if (error && !error?.message?.includes('Multiple JWTs found')) {
    console.error('Failed to retrieve regions:', error);
    toast.error('Failed to retrieve regions');
    return `Error! ${error.message}`;
  } else if (error && error?.message?.includes('Multiple JWTs found')){
    return `Error! ${error.message}`
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='region-select-modal'>
      <DialogTitle id='region-select-modal'>Region Selection</DialogTitle>
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING REGION SELECTION' />}>
        <DialogContent>
          <DialogContentText>
            Select one or more regions to manage. You will see all moves that start or finish in the selected regions as
            well as all lanes and locations for the regions.
          </DialogContentText>
          {appLoad ? (
            <CircularProgress
              style={{
                display: 'block',
                margin: 'auto',
                position: 'absolute',
                // top: "0",
                left: '0',
                // bottom: "0",
                right: '0',
              }}
            />
          ) : (
            <>
              <FormControl className={classes.formControl}>
                <FormLabel component='legend'>
                  Available Regions:
                  <AutoRefresh refetch={handleRefetch} interval={60000} persistAs='region_select' />
                </FormLabel>
                <FormGroup>
                  {data.regions.map(region => {
                    return (
                      <FormControlLabel
                        key={`form-control-label-${region.id}`}
                        control={
                          <Checkbox
                            checked={regions ? regions[region.id.toString()] : false}
                            onChange={handleChange(region.id)}
                            value={region.id.toString()}
                          />
                        }
                        label={region.name}
                      ></FormControlLabel>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={checkDisabled()}
            onClick={handleLocalStorageSave}
          >
            Accept
          </Button>
          <Button
            color='default'
            variant='contained'
            className={classes.button}
            disabled={appLoad}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Sentry.ErrorBoundary>
    </Dialog>
  );
};

export default withStyles(styles)(RegionSelectModal);
