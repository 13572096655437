//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  useTheme,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ManualIcon from '@material-ui/icons/NatureTwoTone';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../../hooks/useTools';
import { useCanceledMoves } from '../providers/CanceledMovesProvider';
import { DefaultEmptyFallback } from '../../../components/Fallbacks';
import { useSettings } from '../providers/SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';
import CustomerSelect from '../../../components/CustomerSelect';
import Spacer from '../../../components/Spacer';

// import { SimpleLogger } from '../../../utils/SimpleLogger';
// const { log } = new SimpleLogger({ prefix: 'CanceledMovesList', enabled: false });

//////////////////////// COMPONENT ////////////////////////

export default function CanceledMovesList(props) {
  const theme = useTheme();
  const cls = useStyles();

  const { canceledMoves, canceledMoveIds } = useCanceledMoves();
  const { timelineDate } = useSettings();
  const { selectedRegionId } = useRegions();

  const {
    goToMoveDetails,
    goToLaneDetails,
    capFirst,
    getFormattedCancelStatusFromDrive,
    getFormattedVehicleFromMove,
    getRegionIdFromMove,
    getDriveTypeFromMove,
    getCleansedPhoneNumber,
  } = useTools();

  //const [canceledMoves, setCanceledMoves] = React.useState([]);
  const [filteredMoves, setFilteredMoves] = React.useState([]);
  const [status, setStatus] = React.useState(`any`);
  const [moveType, setMoveType] = React.useState(`any`);
  const [customerId, setCustomerId] = React.useState(0);
  const [transmission, setTransmission] = React.useState(`any`);
  const [search, setSearch] = React.useState('');

  // Handle filtering canceledMoves
  React.useEffect(() => {
    let filteredArr = [...canceledMoves];

    if (filteredArr.length > 0) {
      // Check region filter
      if (selectedRegionId) filteredArr = filteredArr.filter(m => getRegionIdFromMove(m) === selectedRegionId);

      // Check cancel status filter
      if (status && status !== `any`) filteredArr = filteredArr.filter(m => m.cancel_status === status);

      // Check move type filter
      if (moveType === `concierge`)
        filteredArr = filteredArr.filter(m => m.consumer_type === `customer` || m.consumer_type === `loaner`);
      if (moveType === `ops`)
        filteredArr = filteredArr.filter(m => m.consumer_type !== `customer` && m.consumer_type !== `loaner`);

      // Check customer filter
      if (customerId && customerId !== 0) filteredArr = filteredArr.filter(m => m.customer.id === customerId);

      // Check tranmission filter
      if (transmission === `manual`) filteredArr = filteredArr.filter(m => m.manual_flag);
      if (transmission === `automatic`) filteredArr = filteredArr.filter(m => !m.manual_flag);

      // Check search filter
      if (search && search.length > 0) {
        filteredArr = filteredArr.filter(
          m =>
            `${m.id}`.includes(search) ||
            (m.ready_by && m.ready_by.toLocaleLowerCase().includes(search)) ||
            (m.pickup_time && m.pickup_time.toLocaleLowerCase().includes(search)) ||
            (m.lane.description && m.lane.description.toLocaleLowerCase().includes(search)) ||
            getFormattedVehicleFromMove(m).toLocaleLowerCase().includes(search) ||
            (m.consumer_name && m.consumer_name.toLocaleLowerCase().includes(search)) ||
            (m.customer.name && m.customer.name.toLocaleLowerCase().includes(search))
        );
      }
    }

    setFilteredMoves(filteredArr);
  }, [canceledMoves, selectedRegionId, status, moveType, customerId, transmission, search, timelineDate]);

  // Controls filter input
  const handleInput = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  // Controlls transmission toggle
  const handleTransmission = () => {
    if (transmission === `any`) setTransmission(`manual`);
    else if (transmission === `manual`) setTransmission(`automatic`);
    else setTransmission(`any`);
  };

  // Controls search input
  const handleSearchInput = event => {
    setSearch(event.target.value.toLocaleLowerCase());
  };

  const canceledMoveActions = [
    {
      label: `Go To Move Details`,
      handler: move => goToMoveDetails(move.id),
    },
    {
      label: `Go To Lane Details`,
      handler: move => goToLaneDetails(move.lane ? move.lane.id : null),
    },
  ];

  function CanceledMovesListItem({ move = {}, index }) {
    const lane = move.lane || {};
    const customer = move.customer || {};
    const driveType = getDriveTypeFromMove(move);

    const getDriveIcon = () => {
      if (driveType === `loaner`) return `emoji_transportation`;
      if (driveType === `concierge`) return `person_pin`;
      return `drive_eta`;
    };

    return (
      <React.Fragment key={`plans-canceled-move-${index}`}>
        <div className={cls.canceledMoveItem}>
          <Grid container alignItems='center' wrap='nowrap'>
            <Grid item className={cls.gridTitleItem}>
              <Tooltip placement='top' title={capFirst(driveType)}>
                <Icon className={driveType === `ops` ? cls.iconOps : cls.iconConcierge}>{getDriveIcon()}</Icon>
              </Tooltip>
            </Grid>
            <Grid item xs className={cls.gridTitleItem}>
              <Typography className={cls.infoIdTxt}>#{move.id}</Typography>
            </Grid>
            <Grid item className={cls.gridTitleItem}>
              <Typography
                className={cls.infoTimeTxt}
                style={{ color: driveType === `ops` ? theme.palette.ops.main : theme.palette.concierge.main }}
              >
                {getFormattedCancelStatusFromDrive(move)}
              </Typography>
            </Grid>
          </Grid>

          <Spacer size='xs' />

          <Grid container alignItems='center' wrap='nowrap'>
            {/* INFO TEXT */}
            <Grid item xs className={cls.overflowFix}>
              <Typography className={cls.infoCustomerTxt}>{customer.name}</Typography>
              <Typography className={cls.infoLaneTxt}>{lane.description}</Typography>
              {driveType === `concierge` || driveType === `loaner` ? (
                <Typography className={cls.infoVehicleTxt}>
                  {move.consumer_name} - {getCleansedPhoneNumber(move.consumer_phone)}
                </Typography>
              ) : null}
              {driveType === `ops` ? (
                <Typography className={cls.infoVehicleTxt}>{getFormattedVehicleFromMove(move)}</Typography>
              ) : null}
              <div className={cls.fadeFix} />
            </Grid>

            {/* ICON GRID */}
            {move.consumer_name || move.consumer_phone || move.manual_flag || move.move_details || move.tags ? (
              <Grid item className={cls.iconGrid}>
                {move.consumer_name || move.consumer_phone ? (
                  <Tooltip
                    placement='top'
                    title={
                      <>
                        <div>Consumer Name: {move.consumer_name || `-`}</div>
                        <div>Consumer Phone: {getCleansedPhoneNumber(move.consumer_phone) || `-`}</div>
                        <div>Vehicle: {getFormattedVehicleFromMove(move)}</div>
                      </>
                    }
                  >
                    <Icon className={cls.icon}>person</Icon>
                  </Tooltip>
                ) : null}

                {move.manual_flag ? (
                  <Tooltip placement='top' title='Manual Transmission'>
                    <ManualIcon className={cls.icon} />
                  </Tooltip>
                ) : null}

                {move.move_details ? (
                  <Tooltip placement='top' title={`Notes/Instructions: ${move.move_details}`}>
                    <Icon className={cls.icon}>assignment</Icon>
                  </Tooltip>
                ) : null}

                {move.tags ? (
                  <Tooltip placement='top' title={`Tags: ${move.tags}`}>
                    <Icon className={cls.icon}>more</Icon>
                  </Tooltip>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </div>

        <div className={cls.lineBreak} />
      </React.Fragment>
    );
  }

  return (
    <>
      <div className={cls.filter}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              name='status'
              label='Cancel Status'
              placeholder='Select a status filter...'
              size='small'
              variant='outlined'
              value={status}
              onChange={handleInput(setStatus)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      block
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='any'>Any Status</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='canceled'>Canceled - Not Started</MenuItem>
              <MenuItem value='started'>Canceled - Started</MenuItem>
              <MenuItem value='delivered'>Canceled - Delivered</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              name='moveType'
              label='Move Type'
              placeholder='Select a type filter...'
              size='small'
              variant='outlined'
              value={moveType}
              onChange={handleInput(setMoveType)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      drive_eta
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='any'>Any Type</MenuItem>
              <MenuItem value='concierge'>Concierge</MenuItem>
              <MenuItem value='ops'>Operations</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Spacer size='xs' />

        <Grid container spacing={1} alignItems='center' wrap='nowrap'>
          <Grid item xs>
            <CustomerSelect selectAllItem value={customerId} onChange={handleInput(setCustomerId)} />
          </Grid>

          <Grid item>
            <Tooltip placement='top' title={`Transmission: ${capFirst(transmission)}`}>
              <IconButton className={cls.iconBtn} onClick={handleTransmission}>
                {transmission === `any` ? <Icon>all_inclusive</Icon> : null}
                {transmission === `manual` ? <ManualIcon /> : null}
                {transmission === `automatic` ? <Icon>hdr_auto</Icon> : null}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Spacer size='xs' />

        <TextField
          fullWidth
          name='search'
          label='Search'
          placeholder='Search Canceled Moves...'
          size='small'
          variant='outlined'
          value={search}
          onChange={handleSearchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </div>

      {filteredMoves && filteredMoves.length > 0 ? (
        <>
          {filteredMoves.map((move, i) => (
            <React.Fragment key={`canceled-move-item-${move.id}`}>
              <ContextMenuTrigger id={`canceled-move-${move.id}-cm`} source={`canceled-move-${move.id}-cm`}>
                <CanceledMovesListItem move={move} index={i} />
              </ContextMenuTrigger>
              <ContextMenu id={`canceled-move-${move.id}-cm`}>
                {canceledMoveActions.map(action => {
                  return (
                    <ContextMenuItem
                      disabled={action.disabled}
                      data={{ action: action, ...action.data }}
                      onClick={() => action.handler(move)}
                      key={`atr-row-action-${action.label}`}
                    >
                      {action.label}
                    </ContextMenuItem>
                  );
                })}
              </ContextMenu>
            </React.Fragment>
          ))}
        </>
      ) : (
        <DefaultEmptyFallback message='No Canceled Moves Found' />
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  canceledMoveItem: {
    position: 'relative',
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    background: theme.palette.background.paper,
    '& $fadeFix': {
      background: `linear-gradient(90deg, ${theme.palette.background.paper}00, ${theme.palette.background.paper})`,
    },
    '& $iconGrid': {
      background: theme.palette.action.hover,
    },
    '&:hover': {
      background: theme.palette.background.dark,
      '& $fadeFix': {
        background: `linear-gradient(90deg, ${theme.palette.background.dark}00, ${theme.palette.background.dark})`,
      },
    },
    cursor: 'pointer',
    overflow: 'hidden',
  },
  gridTitleItem: {
    maxHeight: 24,
  },
  iconGrid: {
    maxHeight: 32,
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
  },
  iconBtn: {
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  iconOps: {
    color: theme.palette.ops.main,
    marginRight: theme.spacing(1),
  },
  iconConcierge: {
    color: theme.palette.concierge.main,
    marginRight: theme.spacing(1),
  },
  overflowFix: {
    position: 'relative',
    minWidth: 0,
  },
  fadeFix: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 24,
    height: '100%',
    paddingRight: theme.spacing(0.5),
  },
  infoIdTxt: {
    marginRight: theme.spacing(1),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoTimeTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoCustomerTxt: {
    lineHeight: 1.333,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoLaneTxt: {
    lineHeight: 1.333,
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  infoVehicleTxt: {
    lineHeight: 1.333,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  lineBreak: {
    width: '100%',
    height: 1,
    background: theme.palette.divider,
  },
}));
