import { gql } from '@apollo/client';

const GET_ACCESSORIALS = (code, customerIdArray, regionId) => gql`
  query get_accessorials(
    $code: String
    $customerIdArray: [bigint]
    $start: timestamptz!
    $end: timestamptz!
    $regionId: bigint
  ) {
    accessorials(
      where: {
        ${code ? 'code: { _eq: $code }' : ''}
        move: {
          ${customerIdArray && customerIdArray.length ? 'customer_id: { _in: $customerIdArray }' : ''}
          lane: {
            pickup: {
              region: {
                ${regionId ? 'id: { _eq: $regionId }' : ''}
              }
            }
          }
        }
        createdat: { _gte: $start, _lte: $end }
      }
      order_by: { id: desc }
    ) {
      id
      createdat
      status
      code
      cost
      ar_amount
      ap_amount
      move_id
      notes
      move {
        id
        driver_name
        customer_id
        customer {
          id
          name
        }
        lane {
          id
          pickup {
            id
            region_id
            region {
              id
            }
          }
        }
      }
    }
  }
`;

export { GET_ACCESSORIALS };
