import { gql } from '@apollo/client';

const GET_DRIVERS = regionId => gql`
  query get_drivers($regionId: bigint) {
    drivers(order_by: { id: desc }, where: {
      ${regionId ? `region_id: { _eq: $regionId }` : ``}
    }) {
      id
      bank_account_token
      config
      phone_type
      plate_id
      region_id
      status
      tax_class
      user_id
      verification
      wallet_token
      created_at
      updated_at
      region {
        id
        name
      }
      user {
        id
        active
        avatar_url
        display_name
        email
        phone
        default_role
      }
    }
  }
`;

export { GET_DRIVERS };
