//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, useTheme, Typography, Tooltip, Icon, Divider } from '@material-ui/core';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useScheduler } from '../providers/SchedulerProvider';
import { useRegions } from '../providers/RegionsProvider';
import { useTools } from '../../../hooks/useTools';
import useMoveActions from '../hooks/useMoveActions';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleOutlineDrive({ move, enrichedMove, start, end, duration, distance }) {
  const theme = useTheme();
  const cls = useStyles();

  const { capFirst, getNameFromLocation, getDriveTypeFromMove, getFormattedCancelStatusFromDrive } = useTools();
  const { actions } = useMoveActions(enrichedMove);

  const { unsavableMoveIds } = useScheduler();
  const { timezoneOverride } = useRegions();

  const driveType = getDriveTypeFromMove(move);
  const isTriangle = getPropValue(enrichedMove, 'withOverrides.config.triangle_move');

  const getDriveColor = () => {
    if (driveType === `loaner` || driveType === `concierge`) {
      return theme.palette.concierge.main;
    } else {
      return theme.palette.ops.main;
    }
  };

  const getDriveIcon = () => {
    if (driveType === `loaner`) return `emoji_transportation`;
    if (driveType === `concierge`) return `person_pin`;
    return `drive_eta`;
  };

  return (
    <>
      <ContextMenuTrigger id={`bundle-drive-${move.id}-cm`} source={`bundle-drive-${move.id}-cm`}>
        <div className={cls.drive} style={{ background: getDriveColor() }}>
          {isTriangle ? (
            <Tooltip placement='top' title='Triangle Move | Move ID | Distance | Duration'>
              <Typography className={cls.driveTextTL}>
                ▲ | #{move.id} | {Number(distance).toFixed(1)} mi | {Number(duration / 60).toFixed(0)} min
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip placement='top' title='Move ID | Distance | Duration'>
              <Typography className={cls.driveTextTL}>
                #{move.id} | {Number(distance).toFixed(1)} mi | {Number(duration / 60).toFixed(0)} min
              </Typography>
            </Tooltip>
          )}

          <Tooltip placement='top' title='Ready By Time'>
            <Typography className={cls.driveTextTR}>
              {dayjs(move.ready_by).tz(timezoneOverride).format(`M/D/YYYY h:mm A z`)}
            </Typography>
          </Tooltip>

          <Tooltip placement='bottom' title='Pickup Location'>
            <Typography className={cls.driveTextBL}>{getNameFromLocation(move.lane.pickup)}</Typography>
          </Tooltip>

          <Tooltip placement='bottom' title='Delivery Location'>
            <Typography className={cls.driveTextBR}>{getNameFromLocation(move.lane.delivery)}</Typography>
          </Tooltip>

          <div
            className={cls.timeBox}
            style={{
              background: `linear-gradient(90deg, ${getDriveColor()}00, ${getDriveColor()}, ${getDriveColor()}, ${getDriveColor()}, ${getDriveColor()}, ${getDriveColor()}00)`,
            }}
          >
            <Tooltip placement='top' title='Planned Pickup Time'>
              <Typography className={cls.timeText}>{dayjs(start).tz(timezoneOverride).format(`hh:mm A z`)}</Typography>
            </Tooltip>
            <Tooltip placement='top' title={`Drive Type: ${capFirst(driveType)}`}>
              <Icon className={cls.driveIcon}>{getDriveIcon()}</Icon>
            </Tooltip>
            <Tooltip placement='top' title='Planned Delivery Time'>
              <Typography className={cls.timeText}>
                {dayjs(end).tz(timezoneOverride).format(`hh:mm A z`)}
                {enrichedMove.hasChanges ? ' *' : ''}
              </Typography>
            </Tooltip>
          </div>

          {unsavableMoveIds && unsavableMoveIds.length && unsavableMoveIds.includes(move.id) ? (
            <div className={cls.cancelOverlay}>
              <Typography className={cls.cancelOverlayTxt}>UNSAVABLE</Typography>
            </div>
          ) : null}

          {move.driver_status === `declined` ? (
            <div className={cls.cancelOverlay}>
              <Typography className={cls.cancelOverlayTxt}>DECLINED</Typography>
            </div>
          ) : null}

          {move.cancel_status === `pending` ||
          move.cancel_status === `started` ||
          move.cancel_status === 'rescheduled' ? (
            <div className={cls.cancelOverlay}>
              <Typography className={cls.cancelOverlayTxt}>{getFormattedCancelStatusFromDrive(move)}</Typography>
            </div>
          ) : null}
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`bundle-drive-${move.id}-cm`}>
        {actions.map((action, i) => {
          if (!action || action.hide) return null;
          if (!action.handler) return <Divider key={`bundle-drive-action-${i}`} className={cls.divider} />;
          return (
            <ContextMenuItem
              key={`bundle-drive-action-${i}`}
              disabled={action.disabled}
              onClick={() => action.handler(move)}
            >
              {action.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  drive: {
    position: 'relative',
    width: '100%',
    height: 48,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.ops.main,
    color: theme.palette.text.contrast,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  driveIcon: {
    marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  timeBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 'max-content',
    minHeight: 36,
    background: theme.palette.ops.main,
  },
  timeText: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 3,
    fontSize: 12,
    fontWeight: 500,
  },
  driveTextTL: {
    position: 'absolute',
    top: 8,
    left: 8,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    textOverflow: 'clip',
  },
  driveTextTR: {
    position: 'absolute',
    top: 8,
    right: 8,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    textOverflow: 'clip',
  },
  driveTextBL: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
    textOverflow: 'clip',
  },
  driveTextBR: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'right',
    color: theme.palette.text.contrastFade,
    textOverflow: 'clip',
  },
  cancelOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    background: '#00000080',
    color: theme.palette.text.contrast,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  cancelOverlayTxt: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
