//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useTools } from '../../hooks/useTools';
import useCustomerDetails from './useCustomerDetails';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import Toolbar from '../../components/Toolbar';

import CustomerDetailsToolbar from './CustomerDetailsToolbar';
import CustomerDetailsOverview from './CustomerDetailsOverview';
import CustomerUsersDetails from './CustomerUsers/CustomerUsersDetails';
import CustomerDetailsPricing from './CustomerDetailsPricing';
import CustomerDetailsSettings from './CustomerDetailsSettings';

import WorkflowConfigModal from '../../components/WorkflowConfig/WorkflowConfigModal';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetails(props) {
  const cls = useStyles();

  const { replacePath } = useTools();
  const { tabs } = useCustomerDetails();

  const customerId = props?.match?.params?.id;
  const pathExtension = props?.location?.pathname?.split(`/`)?.[3];
  const initialTab = tabs.find(tab => tab?.route === pathExtension);

  const [activeTab, setActiveTab] = React.useState(initialTab?.id || 0);

  // Handle tab routing
  const handleRouteToTab = id => {
    const foundTab = tabs?.find(tab => tab?.id === id);
    setActiveTab(foundTab?.id);
    // replacePath(`/customers/${customerId}/${foundTab?.route}`);
  };

  // Query for fetching customer details
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: {
      customerId,
      start60: dayjs().subtract(60, `day`).startOf(`day`).format(),
      start30: dayjs().subtract(30, `day`).startOf(`day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
  });

  // Workflow Config Modal
  const [workflowModal, setWorkflowModal] = React.useState({ open: false, input: null, output: null });
  const handleWorkflowModalOpen = (input = null) => {
    setWorkflowModal({ open: true, input: input, output: null });
  };
  const handleWorkflowModalClose = async (output = null) => {
    setWorkflowModal({ open: false, input: null, output: output });
  };

  // Handle refetching data
  const handleRefetch = () => {
    refetch();
  };

  // Mutation for activating/deactivating customer
  const [activateDeactivateCustomer] = useMutation(ACTIVATE_DEACTIVATE_CUSTOMER);
  const handleCustomerActivation = async (active, status) => {
    try {
      const res = await activateDeactivateCustomer({ variables: { customerId, active, status } });
      if (res?.data?.update_customers?.affected_rows) {
        toast.success(`Successfully ${active ? `activated` : `deactivated`} customer!`);
        handleRefetch();
      }
    } catch (err) {
      console.error(`Failed to ${active ? `activate` : `deactivate`} customer:`, err);
      toast.error(`Failed to ${active ? `activate` : `deactivate`} customer!`);
    }
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Customers`, `#${customerId}`]}
          back
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <CustomerDetailsToolbar />
        </Toolbar>

        <DefaultLoadingFallback message='FETCHING CUSTOMER' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching customer:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Customers`, `#${customerId}`]}
          back
          refetch={handleRefetch}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <CustomerDetailsToolbar />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING CUSTOMER' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.customers || !data.customers.length) {
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Customers`, `#${customerId}`]}
          back
          refetch={handleRefetch}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <CustomerDetailsToolbar />
        </Toolbar>

        <DefaultEmptyFallback message='NO CUSTOMER FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const customer = data?.customers[0];
  const organizations = data?.organizations;
  log && console.log(`Customer:`, customer);

  // Customer actions
  const actions = [
    {
      label: `Configure Move Workflows`,
      handler: () => handleWorkflowModalOpen({ customer }),
    },
    {
      label: `Activate Customer`,
      handler: async () => await handleCustomerActivation(1, `live`),
      hide: customer?.active,
    },
    {
      label: `Deactivate Customer`,
      handler: async () => await handleCustomerActivation(0, `deactivated`),
      hide: !customer?.active,
    },
  ];

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMER DETAILS' />}>
      <WorkflowConfigModal open={workflowModal.open} input={workflowModal.input} onClose={handleWorkflowModalClose} />

      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={
            customer?.organization ? [customer?.organization?.name, customer?.name] : [`Customers`, customer?.name]
          }
          back
          refetch={handleRefetch}
          actions={actions}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <CustomerDetailsToolbar customer={customer} />
        </Toolbar>

        {/* OVERVIEW */}
        {activeTab === 0 && (
          <CustomerDetailsOverview customer={customer} organizations={organizations} refetch={handleRefetch} />
        )}

        {/* USERS */}
        {activeTab === 1 && <CustomerUsersDetails customer={customer} refetch={handleRefetch} />}

        {/* PRICING */}
        {activeTab === 2 && <CustomerDetailsPricing customerId={customerId} />}

        {/* SETTINGS */}
        {activeTab === 3 && <CustomerDetailsSettings customer={customer} refetch={handleRefetch} />}

      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_CUSTOMER_DETAILS = gql`
  query admin_getCustomerDetails(
    $customerId: bigint!
    $start60: timestamptz!
    $start30: timestamptz!
    $end: timestamptz!
  ) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      active
      auto_pay
      billing_frequency
      branded
      category
      config
      createdat
      email
      location_id
      name
      notify_billing
      organization_id
      phone
      status
      updatedat
      location {
        id
        address
        address_line_one
        address_line_two
        city
        customer_id
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        place_id
        region_id
        state
        timezone
        type
        zip_code
      }
      previous30Moves: moves(
        where: {
          active: { _eq: 1 }
          chargeable: { _eq: true }
          move_type: { _eq: "drive" }
          pickup_time: { _gte: $start60, _lte: $start30 }
          status: { _eq: "delivery successful" }
          _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
        }
      ) {
        id
        accountsReceivable {
          id
          due_amount
        }
      }
      current30Moves: moves(
        where: {
          active: { _eq: 1 }
          chargeable: { _eq: true }
          move_type: { _eq: "drive" }
          pickup_time: { _gte: $start30, _lte: $end }
          status: { _eq: "delivery successful" }
          _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
        }
      ) {
        id
        accountsReceivable {
          id
          due_amount
        }
      }
      organization {
        id
        config
        name
      }
    }

    organizations(order_by: { name: asc }) {
      id
      config
      name
    }
  }
`;

const ACTIVATE_DEACTIVATE_CUSTOMER = gql`
  mutation admin_activateDeactivateCustomer($customerId: bigint!, $active: smallint!, $status: String!) {
    update_customers(
      where: { id: { _eq: $customerId } }
      _set: { active: $active, status: $status, updatedat: "now()" }
    ) {
      affected_rows
    }
  }
`;
