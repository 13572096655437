import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import './styles/index.css';
import './styles/react-contextmenu.css';
import App from './App';

import { createBrowserHistory } from 'history';

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import ceil from './utils/daysjsExtenders/ceil';
import floor from './utils/daysjsExtenders/floor';
import round from './utils/daysjsExtenders/round';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(ceil);
dayjs.extend(floor);
dayjs.extend(round);

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_PROJECT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

const firebase = getAuth(firebaseApp);

let log = false;

onAuthStateChanged(firebase, user => {
  if (user) {
    log && console.log('logged in user found');
  } else {
    log && console.log('no logged in user');
  }
});

export const history = createBrowserHistory();

// Create the root, then render it (New in React 18)
const root = document.getElementById('root');
const app = ReactDOM.createRoot(root);

// Initialize Sentry
process.env.REACT_APP_ENV !== 'development' &&
  Sentry.init({
    dsn: 'https://bd5c2a0658964a6f8d63ddf2c22b1ea5@o491290.ingest.sentry.io/5556633',
    autoSessionTracking: true,
    environment: process.env.REACT_APP_ENV,
    release: 'sat-admin@v3.17.0',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

app.render(
  <Router history={history}>
    <App />
  </Router>
);

// If you wrap the App component in React Strict Mode - It shows deprication warnings (not sure if we wanted to use this or not)
// <React.StrictMode></React.StrictMode>
