// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { makeStyles, Typography, Icon, TextField, InputAdornment } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_MOVE_LANES_BY_CUSTOMER, GET_MOVE_LANES_BY_REGION } from './gql';

const maxLanes = 1;

/** Sort & filter lanes list */
const cleanseLanes = (lanes, selectedIds) => {
  return lanes?.sort((a, b) => {
    if (selectedIds?.includes(a?.id) && !selectedIds?.includes(b?.id)) return -1;
    if (!selectedIds?.includes(a?.id) && selectedIds?.includes(b?.id)) return 1;
    if (a?.pickup?.name < b?.pickup?.name) return -1;
    if (a?.pickup?.name > b?.pickup?.name) return 1;
    if (a?.delivery?.name < b?.delivery?.name) return -1;
    if (a?.delivery?.name > b?.delivery?.name) return 1;
    return 0;
  });
};

/** Search input to filter lanes */
const searchLanes = (lanes, searchInput) => {
  if (!searchInput) return lanes;
  const search = searchInput?.toLowerCase();
  return lanes?.filter(l => {
    return (
      l?.pickup?.name?.toLowerCase()?.includes(search) ||
      l?.pickup?.address?.toLowerCase()?.includes(search) ||
      l?.delivery?.name?.toLowerCase()?.includes(search) ||
      l?.delivery?.address?.toLowerCase()?.includes(search)
    );
  });
};

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormLanes({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [laneIds, setLaneIds] = React.useState([]);
  const [lanes, setLanes] = React.useState([]);
  const [filteredLanes, setFilteredLanes] = React.useState([]);
  const [search, setSearch] = React.useState(``);

  // Lazy fetch
  const [fetchMoveLanes, { loading, error }] = useLazyQuery(
    formData?.customerId ? GET_MOVE_LANES_BY_CUSTOMER : GET_MOVE_LANES_BY_REGION
  );

  /** Handle adding a lane ID */
  const handleLaneIdAdd = id => {
    const newLaneIds = [...laneIds, id];
    if (newLaneIds?.length > maxLanes) {
      toast.warning(`You cannot assign more lanes than ${maxLanes}!`);
      return;
    }
    handleSetState(newLaneIds);
  };

  /** Handle removing a lane ID */
  const handleLaneIdRemove = id => {
    const newLaneIds = laneIds.filter(laneId => laneId !== id);
    handleSetState(newLaneIds);
  };

  /** Handle setting lanes state */
  const handleSetState = newLaneIds => {
    setLaneIds(newLaneIds);
    setLanes(() => cleanseLanes(lanes, newLaneIds));
    setFilteredLanes(() => cleanseLanes(lanes, newLaneIds));
    setFormData({ ...formData, laneIds: newLaneIds });
  };

  /** Handle clicking a lane */
  const handleLaneClick = id => {
    if (laneIds?.includes(id)) handleLaneIdRemove(id);
    else handleLaneIdAdd(id);
    setSearch(``);
  };

  /** Handle fetching move lanes */
  const handleFetchMoveLanes = async () => {
    if (!formData?.date || !formData?.vehicleId || (!formData?.customerId && !formData?.regionId)) return;

    try {
      let variables = formData?.customerId ? { customerId: formData?.customerId } : { regionId: formData?.regionId };
      variables = {
        ...variables,
        start: dayjs().subtract(1, `week`).startOf(`day`).format(),
        end: dayjs().add(1, `week`).endOf(`day`).format(),
      };
      const res = await fetchMoveLanes({ variables });
      if (res?.data?.moves) {
        let foundLaneIds = res?.data?.moves?.map(move => move?.lane?.id);
        foundLaneIds = [...new Set(foundLaneIds)];

        let foundLanes = foundLaneIds?.map(laneId => {
          return {
            ...res?.data?.moves?.find(move => move?.lane?.id === laneId)?.lane,
            moveIds: res?.data?.moves?.filter(move => move?.lane?.id === laneId)?.map(move => move?.id),
          };
        });
        foundLanes = foundLanes?.filter(Boolean);
        foundLanes = cleanseLanes(foundLanes, formData?.laneIds);

        setLanes([...foundLanes]);
        setFilteredLanes([...foundLanes]);
      }
    } catch (err) {
      console.error(`Failed to fetch lanes from moves:`, err);
    }
  };

  // Clear, then fetch/refetch move lanes on form change
  React.useEffect(() => {
    setLaneIds([]);
    setLanes([]);
    setFilteredLanes([]);
    setSearch(``);
    handleFetchMoveLanes();
  }, [formData?.date, formData?.vehicleId]);

  // Set the initial lane IDs
  React.useEffect(() => {
    if (initialLoad && initialFormData?.laneIds?.length && lanes?.length) {
      setLaneIds(initialFormData?.laneIds);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData, lanes]);

  // Use search input to filter lanes
  React.useEffect(() => {
    setFilteredLanes(() => searchLanes(lanes, search));
  }, [search]);

  return (
    <div className={cls.root} style={{ display: formData?.vehicleId && formData?.vehicleDriverId ? `block` : `none` }}>
      <label className={cls.label}>
        Which lane(s) would you like to assign to this group?<span className={cls.required}> *</span>
      </label>

      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Search for lanes...'
        value={search}
        onChange={e => setSearch(e?.target?.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon color='disabled' fontSize='small'>
                search
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <Spacer size='sm' />

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {!filteredLanes?.length ? (
              <Typography className={cls.emptyTxt}>No lanes found from search criteria</Typography>
            ) : null}
            {filteredLanes?.map(lane => (
              <div key={lane?.id} className={cls.item} onClick={() => handleLaneClick(lane?.id)}>
                <div>
                  <Typography className={cls.itemTxt1}>{lane?.pickup?.name}</Typography>
                  <Typography className={cls.itemTxt1}>to {lane?.delivery?.name}</Typography>
                  <Typography className={cls.itemTxt2}>
                    {Math.round((lane?.duration_sec || 0) / 60)} min | {lane?.distance_miles || 0} miles
                  </Typography>
                </div>

                {lane?.moveIds?.length ? (
                  <div className={cls.badge}>
                    <Typography className={cls.badgeTxt}>
                      {lane?.moveIds?.length} Move{lane?.moveIds?.length === 1 ? `` : `s`}
                    </Typography>
                  </div>
                ) : null}

                {laneIds?.includes(lane?.id) ? (
                  <div className={cls.selected}>
                    <Icon className={cls.selectedIcon}>check</Icon>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: `relative`,
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  required: {
    color: theme.palette.error.main,
  },

  container: {
    position: `relative`,
    overflowY: `scroll`,
    width: `100%`,
    minHeight: `64px`,
    maxHeight: `384px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `0 1 435px`,
    height: `fit-content`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1.25,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },

  emptyTxt: {
    fontSize: 18,
    color: theme.palette.text.disabled,
  },

  badge: {
    position: `absolute`,
    top: 6,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.fade[5],
  },
  badgeTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `${theme.palette.secondary.main}80`,
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },
}));
