import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Chip } from '@material-ui/core';

import { Table, Column } from '../../components/TableComponents';

import { useTools } from '../../hooks/useTools';

export default function ActiveTimeTable({ activeTimes }) {
  const cls = useStyles();

  const { capFirst, getRegionNameFromMove, goToRoute } = useTools();

  const [filteredActiveTimes, setFilteredActiveTimes] = React.useState([]);
  const [search, setSearch] = React.useState('');

  const handleLocalFilters = () => {
    let filteredData = [...activeTimes];

    if (activeTimes?.length) {
      // Check search filter
      if (search?.length) {
        const s = search?.toLowerCase()?.trim();
        filteredData = filteredData?.filter(
          fd =>
            `${fd?.move?.id}`?.includes(s) ||
            fd?.move?.driver_name?.toLowerCase()?.includes(s) ||
            getRegionNameFromMove(fd?.move)?.toLowerCase()?.includes(s)
        );
      }
    }

    setFilteredActiveTimes(filteredData);
  };

  React.useEffect(() => {
    handleLocalFilters();
    // eslint-disable-next-line
  }, [activeTimes, search]);

  const getStatusChipClass = (status, isAutoApproved = false) => {
    switch (status) {
      case `audit`:
        return cls.statusAudit;
      case `approved`:
        return isAutoApproved ? cls.statusAutoApproved : cls.statusApproved;
      default:
        return undefined;
    }
  };

  const getStatusLabel = (status, isAutoApproved = false) => {
    return isAutoApproved && status === `approved` ? `Auto Approved` : capFirst(status);
  };

  const getActiveTimeDuration = activeTime => {
    return activeTime?.override_minutes || activeTime?.duration_minutes;
  };

  return (
    <div className={cls.wrapper}>
      <Table
        data={filteredActiveTimes}
        onRowClick={activeTime => goToRoute(`/active-time/${activeTime?.move_id}`)}
        defaultOrderBy={'move_date'}
        defaultOrder={'desc'}
        tableAriaLabel={`active-times`}
        stickyHeader={true}
        search={search}
        setSearch={setSearch}
      >
        <Column
          name={`move_id`}
          label={`Move Id`}
          tooltip={`Id of the drive move`}
          value={activeTime => activeTime?.move_id}
          renderer={activeTime => `${activeTime?.move_id}`}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`move_date`}
          label={`Move Pickup Time`}
          tooltip={`Date and time of the drive move`}
          value={activeTime => activeTime?.move?.pickup_time}
          renderer={activeTime => {
            return `${dayjs(dayjs(activeTime?.move?.pickup_time)).format(`MMM D, YYYY - h:mm a`)}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`status`}
          label={`Status`}
          tooltip={`Status of the active time`}
          value={activeTime => activeTime?.status}
          renderer={activeTime => (
            <Chip
              className={getStatusChipClass(activeTime?.status, activeTime?.factors?.auto_approved)}
              label={getStatusLabel(activeTime?.status, activeTime?.factors?.auto_approved)}
              size='small'
            />
          )}
          style={{ minWidth: 100 }}
        />

        <Column
          name={`duration`}
          label={`Duration in Minutes`}
          tooltip={`Calculated duration of the drive move with attached rides in minutes`}
          value={activeTime => getActiveTimeDuration(activeTime)}
          renderer={activeTime =>
            activeTime?.override_minutes ? (
              <>
                <span className={cls.durationChanged}>{activeTime?.duration_minutes}</span>
                <span className={cls.durationOverridden}>{activeTime?.override_minutes}</span>
              </>
            ) : (
              <span>{activeTime?.duration_minutes}</span>
            )
          }
          style={{ minWidth: 100 }}
        />

        <Column
          name={`driver_name`}
          label={`Driver Name`}
          value={activeTime => activeTime?.move?.driver_name}
          renderer={activeTime => {
            return `${activeTime?.move?.driver_name}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`driver_tax_class`}
          label={`Driver Tax Class`}
          value={activeTime => activeTime?.move?.driver?.tax_class}
          renderer={activeTime => {
            return `${activeTime?.move?.driver?.tax_class}`;
          }}
          style={{ minWidth: 200 }}
        />

        <Column
          name={`region`}
          label={`Region`}
          tooltip={`Region of the move`}
          value={activeTime => getRegionNameFromMove(activeTime?.move)}
          renderer={activeTime => {
            return `${getRegionNameFromMove(activeTime?.move)}`;
          }}
          style={{ minWidth: 100 }}
          align={`right`}
        />
      </Table>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    borderBottom: theme.border[0],
      backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },

  statusApproved: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  statusAutoApproved: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusAudit: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  amountTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxtNegative: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  durationChanged: {
    marginRight: 8,
    textDecoration: 'line-through',
    fontSize: 14,
    fontWeight: 400,
  },
  durationOverridden: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.error.main,
  },
}));
