import React, { useEffect } from 'react';
import { makeStyles, Grid, TextField, InputAdornment, Icon, MenuItem, Divider, Typography } from '@material-ui/core';
import { Modal, ModalHeader, ModalAction, ModalFooter, ModalContent } from '../../components/ModalComponents';
import { useTools } from '../../hooks/useTools';
import { DriversList } from '../Plans-New';

export default function HangtagManagementModal(props) {
  const { open, setHangtagManagementModal, move, onClose } = props;
  const cls = useStyles();

  const hangtag = move?.hangtags?.[0];

  const { copyToClipboard } = useTools();

  const [status, setStatus] = React.useState(hangtag?.status || '');
  const [moveId, setMoveId] = React.useState(hangtag?.move_id || '');
  const [driverId, setDriverId] = React.useState(hangtag?.driver_id || '');
  const [driverName, setDriverName] = React.useState('');

  useEffect(() => {
    setStatus(hangtag?.status || '');
    setMoveId(hangtag?.move_id || '');
    setDriverId(hangtag?.driver_id || '');
    setDriverName('');
  }, [open]);

  useEffect(() => {
    if (status === 'unassigned') setMoveId('');
  }, [status]);

  const handleClose = output => {
    setHangtagManagementModal(false);
    if (onClose) onClose(output);
  };

  const handleSubmit = () => {
    handleClose({
      driver_id: driverId,
      move_id: moveId,
      status
    });
  };

  const handleInputChange = setHandler => event => {
    if (event.target.value !== `-`) setHandler(event.target.value);
    else setHandler(``);
  };

  const handleDriverSelect = driver => {
    console.log('Setting driver: ', driver);
    setDriverId(driver?.id);
    setDriverName(driver?.display_name);
  };

  return (
    <Modal open={open}>
      <ModalHeader handleClose={() => handleClose()}>Hangtag Manager <span variant='h6' style={{ fontWeight: 800, fontFamily: 'monospace', backgroundColor: '#777', padding: '2px', paddingRight: '5px', paddingLeft: '5px', borderRadius: '7px', color: 'white' }}>{hangtag?.hash_id} </span></ModalHeader>
      <ModalContent
        subtitle={`Edit the hangtag associated with this move. You can force the status of the hangtag, or assign it to a different move or driver.`}
      >
        <div className={cls.smallBreak} />

        <Grid container spacing={2} alignItems='top'>
          <Grid item xs={6}>

            <TextField
              required
              fullWidth
              label='Assigned Move'
              placeholder='Enter the ID of the assigned move...'
              value={moveId}
              onChange={e => setMoveId(e.target.value)}
              margin='dense'
              variant='outlined'
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      directions_car
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              required
              select
              fullWidth
              label='Status'
              placeholder='Unassigned'
              value={status}
              onChange={handleInputChange(setStatus)}
              margin='dense'
              variant='outlined'
              className={cls.input}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      schema
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='unassigned'>Unassigned (tag is not assigned to a move)</MenuItem>
              <MenuItem value='assigned'>Assigned (driver has hung the tag after delivery)</MenuItem>
              <MenuItem value='scanned'>Scanned (dealer has indicated return ready)</MenuItem>
              <MenuItem value='completed'>Completed (return move created - status tag)</MenuItem>

              <Divider className={cls.divider} />

              <MenuItem value='lost'>Lost/Destroyed</MenuItem>

            </TextField>
          </Grid>

          <Grid item xs={6}>
            <div className={cls.scrollBox}>
              <DriversList
                onClick={handleDriverSelect}
                planInteraction={false}
                highlightSelected={true}
                preselectedDriverId={driverId}
              />
            </div>
          </Grid>
        </Grid>
      </ModalContent>

      <ModalFooter>
        <ModalAction onClick={handleSubmit} color='primary'>
          Submit
        </ModalAction>
        <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(3),
  },
  title: {
    fontSize: '21px',
    fontWeight: 500,
  },
  input: {
    marginTop: 10,
  },
  placeholder: {
    color: theme.palette.text.disabled,
    fontWeight: 400,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  scrollBox: {
    position: 'relative',
    height: '200px',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
    border: `1px solid ${theme.palette.divider}`,
  },
}));
