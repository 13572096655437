//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container, Typography } from '@material-ui/core';
import { RateRuleGroupTable } from './index';

//////////////////////// COMPONENT ////////////////////////

function InsuranceRateRuleGroupList({ insuranceRate, ...props }) {
  const cls = useStyles();

  console.log('insuranceRate RRGs: ', insuranceRate.raterulegroups);
  const relatedRateRuleGroups =
    Array.isArray(insuranceRate.raterulegroups) && insuranceRate.raterulegroups.length
      ? insuranceRate.raterulegroups
      : null;

  // EMPTY STATE //
  if (!relatedRateRuleGroups) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO ASSOCIATED RATE RULE GROUPS AVAILABLE</Typography>
        </div>
      </Container>
    );
  }

  // DATA STATE //

  return (
    <RateRuleGroupTable rateRuleGroups={relatedRateRuleGroups}/>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timeline: {
    position: 'relative',
    padding: 0,
    margin: 0,
  },
  load: {
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  tile: {
    position: 'relative',
    minWidth: '220px',
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '160px',
      maxWidth: '220px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '120px',
      maxWidth: '160px',
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InsuranceRateRuleGroupList;
