// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineToolbarBasic({ title = `Timeline`, zoom, setZoom }) {
  const cls = useStyles();

  const handleZoomOut = () => {
    if (zoom > 0.5) setZoom(zoom - 0.5);
  };

  const handleZoomIn = () => {
    if (zoom < 2.0) setZoom(zoom + 0.5);
  };

  return (
    <div className={cls.header}>
      {title ? <Typography className={cls.headerTxt}>{title}</Typography> : null}

      <div className={cls.spacer} />

      {zoom ? (
        <div className={cls.zoom}>
          <Typography className={cls.zoomTxt}>{zoom * 100}%</Typography>

          <div className={cls.spacer} />

          {setZoom ? (
            <div className={cls.zoomBtns}>
              <Tooltip title='Zoom Out'>
                <div style={{ marginRight: '-3px' }}>
                  <IconButton className={cls.iconBtn} disabled={zoom <= 0.5} color='inherit' onClick={handleZoomOut}>
                    <Icon className={zoom <= 0.5 ? cls.disabledIcon : cls.icon}>remove_circle_outline</Icon>
                  </IconButton>
                </div>
              </Tooltip>

              <Tooltip title='Zoom In'>
                <div style={{ marginLeft: '-3px' }}>
                  <IconButton className={cls.iconBtn} disabled={zoom >= 2.0} color='inherit' onClick={handleZoomIn}>
                    <Icon className={zoom >= 2.0 ? cls.disabledIcon : cls.icon}>add_circle_outline</Icon>
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 1, 0, 1.5),
  },
  headerTxt: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },

  zoom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '88px',
    height: '24px',
    padding: theme.spacing(0, 0, 0, 1),
    borderRadius: '12px',
    backgroundColor: theme.palette.text.disabled,
  },
  zoomTxt: {
    fontSize: '12px',
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },
  zoomBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px',
    backgroundColor: theme.palette.text.disabled,
  },

  spacer: {
    flex: 1,
  },

  iconBtn: {
    padding: '4px',
  },
  icon: {
    fontSize: '16px',
    color: theme.palette.text.contrastPrimary,
  },
  disabledIcon: {
    fontSize: '16px',
    color: theme.palette.text.contrastDisabled,
  },
}));
