// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Stepper, Step, StepLabel } from '@material-ui/core';

import { useWorkflow } from '../WorkflowComponents/WorkflowProvider';

// COMPONENT -------------------------------------------------- //

export default function WorkflowStepper({ screens, selectedScreen }) {
  const cls = useStyles();

  const { handleGoToScreen } = useWorkflow();

  const selectedScreenIndex = screens.indexOf(selectedScreen);

  return (
    <>
      <Stepper className={cls.stepper} activeStep={selectedScreenIndex} alternativeLabel>
        {screens.map(s => (
          <Step className={cls.step} key={s?.id}>
            <StepLabel
              className={cls.stepLabel}
              onClick={() => {
                if (s?.id !== selectedScreen?.id) handleGoToScreen(s?.id);
              }}
            >
              {s?.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(2, 0),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    backgroundColor: 'transparent',
    // boxShadow: theme.shadow.medium,
  },
  step: {
    padding: 0,
  },
  stepLabel: {
    cursor: 'pointer',
    '& svg': {
      '& path': {
        fill: theme.palette.default.main,
        transition: '0.15s',
      },
    },
    '&:hover': {
      '& svg': {
        '& path': {
          fill: theme.palette.default.light,
        },
      },
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));
