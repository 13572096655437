import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { getUserToken } from '../../utils/authHelper';

import { makeStyles, Grid, Typography, TextField, InputAdornment, Icon, LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useTools } from '../../hooks/useTools';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function TopUpModalContent({ input, handleClose, balance }) {
  const cls = useStyles();

  const { formatUSD } = useTools();

  const [amount, setAmount] = React.useState(``);
  const [isValidAmount, setIsValidAmount] = React.useState(false);

  const [isToppingUp, setIsToppingUp] = React.useState(false);
  const [wasInitiated, setWasInitiated] = React.useState(false);

  // Update state if the input changes
  React.useEffect(() => {
    if (input) {
      setIsToppingUp(false);
      setWasInitiated(false);
    }
  }, [input]);

  // Check if amount is valid before allowing confirmation
  React.useEffect(() => {
    if (/^-?\d*\.?\d*$/.test(amount) && amount > 0) {
      setIsValidAmount(true);
    } else {
      setIsValidAmount(false);
    }
  }, [amount]);

  const handleAmount = event => {
    let val = event.target.value;
    if (val < 0) val = 0;
    if (val > 10000) val = 10000;
    setAmount(val);
  };

  /** Send funds to the balance from our master bank */
  const initiateTopUp = async (amount = null) => {
    log && console.log(`Initiating top-up for $${amount} ...`);
    if (amount) {
      try {
        const token = await getUserToken();
        const res = await axios({
          method: `POST`,
          url: `/.netlify/functions/topUpBalance`,
          data: {
            amount,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        if (res && res.status === 200 && res.data) {
          log && console.log(`Successfully initiated top-up!`);
          return res.data.transferToken;
        } else {
          console.error(`Failed to initiate top-up!`);
          return null;
        }
      } catch (err) {
        console.error(`Failed to initiate top-up - Global catch:`, err);
        return null;
      }
    }
  };

  /** Handle topping up the balance */
  const handleTopUp = async () => {
    const res = await initiateTopUp(Number(amount));

    if (res) {
      toast.success(`Successfully initiated a top-up!`);
    } else {
      toast.error(`Failed to initiate top-up. Please contact an admin!`);
    }

    setIsToppingUp(false);
  };

  return (
    <>
      <ModalHeader onClose={handleClose}>Add Funds to Balance</ModalHeader>

      <ModalContent>
        <Typography className={cls.subtitleTxt}>
          Transfers to balance may take 3-4 business days. Please fill this balance with a reasonable amount after
          running driver pay. Be aware, the limit per top-up is $10,000. If you need to add more, please make multiple
          top-ups.
        </Typography>

        <Spacer />

        <Grid container spacing={4}>
          <Grid item>
            <Typography className={cls.headTxt}>Available Balance</Typography>
            <Spacer size='xs' />
            <Typography className={cls.balanceTxt}>{formatUSD(balance)}</Typography>
          </Grid>

          <Grid item xs>
            <Typography className={cls.headTxt}>Transfer to Balance</Typography>
            <Spacer size='xs' />
            <TextField
              fullWidth
              type='number'
              placeholder='Enter an amount to transfer...'
              variant='outlined'
              size='small'
              value={amount}
              onChange={handleAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      attach_money
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Spacer />

        {isToppingUp ? <LinearProgress /> : <Spacer size='xxs' />}
      </ModalContent>

      <ModalFooter>
        {!wasInitiated ? (
          <ModalAction
            loading={isToppingUp}
            disabled={isToppingUp || !isValidAmount}
            color='primary'
            onClick={() => {
              setIsToppingUp(true);
              handleTopUp();
            }}
          >
            Confirm
          </ModalAction>
        ) : null}

        <ModalAction onClick={() => handleClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitleTxt: {
    lineHeight: 1.333,
    fontSize: 14,
    fontWeight: 400,
  },
  headTxt: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  balanceTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
  },
}));
