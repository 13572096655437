import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, InputAdornment, Grid, Icon, IconButton } from '@material-ui/core/';
import SyncIcon from '@material-ui/icons/Sync';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

import { getUserToken } from '../../utils/authHelper'

const log = false;

export default function RecentDriverACHSelect({
  estimateValue,
  value,
  name = 'RecentDriverACHSelect',
  driverName = '',
  totalPayAmount = 0,
  onChange = () => {},
  setAchTransaction = () => {},
  placeholder = 'Select a recent ACH...',
  label = 'ACH Transaction',
  required = false,
  fullWidth = true,
  variant = 'outlined',
  margin = 'dense',
  style = {
    width: '100%',
    margin: 0,
  },
  InputProps = {
    startAdornment: (
      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
        <Icon color='disabled' fontSize='small'>
          attach_money
        </Icon>
      </InputAdornment>
    ),
  },
}) {

  const [loading, setLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    log && console.log('suggestions:', suggestions);
    setLoading(false);
    if (suggestions && suggestions.length > 0) {
      setAchTransaction(suggestions[0].transaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  useEffect(() => {
    async function fetchSuggestion() {
      await fetchTransactionSuggestions();
      setLoading(false);
    }
    fetchSuggestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTransactionSuggestions = async () => {
    try {
      const token = await getUserToken()
      const res = await axios({
        method: 'post',
        url: '/.netlify/functions/handleSuggestedACHTransactions',
        data: { name: driverName, subtotal: totalPayAmount, date: moment().subtract(5, 'days').format('YYYY-MM-DD') },
        headers: { authorization: `Bearer ${token}` },
      });
      res && res.data && (await setSuggestions(res.data));
    } catch (err) {
      toast.error('Failed to retrieve recent ACH transactions: ' + err.toString());
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING ACH TRANSACTIONS' />}>
      <Grid container>
        <Grid item md={11}>
          <TextField
            select={true}
            fullWidth={fullWidth}
            required={required}
            name={name}
            label={label}
            placeholder={loading ? 'Loading...' : placeholder}
            variant={variant}
            margin={margin}
            value={value}
            onChange={onChange}
            style={style}
            InputProps={InputProps}
          >
            {loading && (
              <MenuItem key={'Loading...'} value={0}>
                Loading...
              </MenuItem>
            )}
            {!suggestions && (
              <MenuItem key={'Error fetching recent driver pay'} value={0}>
                Error fetching recent driver pay
              </MenuItem>
            )}
            {(suggestions && suggestions.length === 0) &&
              (<MenuItem key={`transaction-override`} value={0}>
                {'Transaction Override - NO QB DATA'} - ${estimateValue} on {moment().format('LT')}{' '}
              </MenuItem>)
            }
            {(suggestions || []).map((suggestion, index) => (
              <MenuItem key={`transaction-${suggestion.transaction}`} value={suggestion.transaction}>
                {suggestion.name} - ${suggestion.amount} on {moment(suggestion.updatedat).format('LT')}{' '}
                {index === 0 ? '(suggested)' : null}{' '}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={1}>
          <IconButton aria-label='Refresh' onClick={fetchTransactionSuggestions}>
            <SyncIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Sentry.ErrorBoundary>
  );
}
