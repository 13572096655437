//////////////////////// DEPENDENCIES ////////////////////////

import { useData } from '../../providers/DataProvider';
import { toast } from 'react-toastify';

//////////////////////// COMPONENT ////////////////////////

export function useMoveDetails() {
  const ctx = useData();

  // Reset swipe attempts to zero on authorization
  const resetSwipeAttempts = async acc => {
    const { id: accId, authorization: { id: authId } } = acc;
    let authorization = {
      auth_attempts: 0,
      updated_at: 'now()'
    };
    const response = await ctx._sdk.authorizations.update(authId, authorization);
    if (response.success === true) {
      toast.success(`Authorization Swipe Attempts were reset on Accessorial #${accId}.`);
    } else {
      toast.error(`Failed to update authorization: ${response.message}`);
    }
  };

  // Set accessorial to Expired status
  const expirePendingAccessorial = async acc => {
    const { id: accId, authorization: { id: authId }, notes } = acc;
    let accessorial = {
      status: ctx._sdk.accessorials.statusCodes.EXPIRED.code,
      updatedat: 'now()',
      notes: notes + ', manually expired from move details',
    };
    let authorization = {
      approved_at: null,
      updated_at: 'now()',
      valid_from: null,
      valid_to: null,
    };
    const response = await ctx._sdk.authorizations.updateAccessorialAndAuthorization(accId, authId, accessorial, authorization);
    if (response.success === true) {
      toast.success(`Accessorial #${accId} was expired successfully.`);
    } else {
      toast.error(`Failed to expire accessorial: ${response.message}`);
    }
  };

  // Return additional logic
  return {
    resetSwipeAttempts,
    expirePendingAccessorial,
  };
}
