// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Grid, Typography, IconButton, Icon, Tooltip, Chip } from '@material-ui/core';
import {
  BatteryUnknown,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryFull,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
} from '@material-ui/icons';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useGPSViewer } from './GPSViewerProvider';

// COMPONENT -------------------------------------------------- //

export default function GPSViewerSidebar(props) {
  const cls = useStyles();

  const { getActivityFromPoint, getActivityIconFromPoint, copyToClipboard } = useTools();
  const { rawPoints, selectedPing, setSelectedPing } = useGPSViewer();

  /** Get formatted battery icon string from driverlocation object */
  const getBatteryIconFromPoint = (point = null) => {
    // Default fallback battery icon string when not enough info is provided
    const fallbackBatteryIcon = <BatteryUnknown className={cls.icon} />;

    try {
      // Check for point
      if (point) {
        // Initialize usable variables
        const isCharging = point.battery_is_charging;
        const level = Number(point.battery_level) * 100;

        // Set local battery icon string
        let batteryIcon = null;

        // Check charging and battery level
        if (isCharging) {
          if (level >= 95) batteryIcon = <BatteryChargingFull className={cls.icon} />;
          else if (level >= 90) batteryIcon = <BatteryCharging90 className={cls.icon} />;
          else if (level >= 80) batteryIcon = <BatteryCharging80 className={cls.icon} />;
          else if (level >= 60) batteryIcon = <BatteryCharging60 className={cls.icon} />;
          else if (level >= 50) batteryIcon = <BatteryCharging50 className={cls.icon} />;
          else if (level >= 30) batteryIcon = <BatteryCharging30 className={cls.icon} />;
          else batteryIcon = <BatteryCharging20 className={cls.icon} />;
        } else {
          if (level >= 95) batteryIcon = <BatteryFull className={cls.icon} />;
          else if (level >= 90) batteryIcon = <Battery90 className={cls.icon} />;
          else if (level >= 80) batteryIcon = <Battery80 className={cls.icon} />;
          else if (level >= 60) batteryIcon = <Battery60 className={cls.icon} />;
          else if (level >= 50) batteryIcon = <Battery50 className={cls.icon} />;
          else if (level >= 30) batteryIcon = <Battery30 className={cls.icon} />;
          else batteryIcon = <Battery20 className={cls.icon} />;
        }

        // Return the built battery icon string
        return batteryIcon;
      }
    } catch (err) {
      console.error(`Error building battery icon`, err);
    }

    return fallbackBatteryIcon;
  };

  // Handle setting the active point
  const handleSelectedPoint = (point, index) => {
    if (selectedPing && selectedPing.id === index) setSelectedPing(null);
    else {
      setSelectedPing({
        id: index,
        lat: point.location.coordinates[1],
        lng: point.location.coordinates[0],
      });
    }
  };

  // Check for points
  if (!rawPoints || !rawPoints.length) {
    return (
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>No GPS pings found for this driver/move.</Typography>
      </div>
    );
  }

  // Render items
  return (
    <div className={cls.table}>
      {rawPoints.map((point, i) => {
        const activity = getActivityFromPoint(point);
        const activityIcon = getActivityIconFromPoint(point);
        const batteryIcon = getBatteryIconFromPoint(point);

        return (
          <div key={`gps-item-${point.id}`} className={i % 2 ? cls.pointEven : cls.pointOdd}>
            <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
              <Grid item>
                <Tooltip title={`${point.id}`}>
                  <IconButton className={cls.iconBtnId} onClick={() => copyToClipboard(point.id)}>
                    <Icon className={cls.iconId}>fingerprint</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item />

              <Grid item>
                <Tooltip
                  title={
                    <div>
                      <div>Activity: {activity}</div>
                      <div>Activity Confidence: {point.activity_confidence}%</div>
                    </div>
                  }
                >
                  <Icon className={cls.icon}>{activityIcon}</Icon>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip
                  title={
                    <div>
                      <div>Altitude: {point.altitude !== -1 ? `${point.altitude}m` : `-`}</div>
                      <div>
                        Altitude Accuracy: {point.altitude_accuracy !== -1 ? `${point.altitude_accuracy}%` : `-`}
                      </div>
                    </div>
                  }
                >
                  <Icon className={cls.icon}>straight</Icon>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip
                  title={
                    <div>
                      <div>Battery {Math.round(Number(point.battery_level) * 100)}%</div>
                      {point.battery_is_charging ? <div>Charging</div> : null}
                    </div>
                  }
                >
                  {batteryIcon}
                </Tooltip>
              </Grid>

              <Grid item xs />

              <Grid item>
                <Tooltip title={`Timestamp (Click to copy)`}>
                  <div className={cls.timeBox} onClick={() => copyToClipboard(dayjs(point.time).format())}>
                    <Typography className={cls.timeTxt1}>{dayjs(point.time).format(`MM/DD/YYYY`)}</Typography>
                    <Typography className={cls.timeTxt2}>{dayjs(point.time).format(`h:mm:ss A`)}</Typography>
                  </div>
                </Tooltip>
              </Grid>

              <Grid item xs />

              <Grid item>
                <Tooltip title={`View this point on the map`}>
                  <Chip
                    size='small'
                    label={!selectedPing || selectedPing.id !== i ? `Show Point` : `Hide Point`}
                    classes={{
                      root: cls.chipGoTo,
                    }}
                    onClick={() => handleSelectedPoint(point, i)}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Spacer />

            <div className={cls.infoBox}>
              <Grid container justifyContent='space-evenly'>
                <Grid item>
                  <Tooltip title={`Coordinates (Click to copy)`}>
                    <div
                      className={cls.infoBoxHold}
                      onClick={() =>
                        copyToClipboard(`${point.location.coordinates[1]},${point.location.coordinates[0]}`)
                      }
                    >
                      <Icon className={cls.infoBoxIcon}>public</Icon>
                      <Typography className={cls.infoBoxTxt}>
                        {point.location.coordinates[1] && point.location.coordinates[0]
                          ? `${point.location.coordinates[1]}, ${point.location.coordinates[0]}`
                          : `-`}
                      </Typography>
                    </div>
                  </Tooltip>
                </Grid>

                <div className={cls.line} />

                <Grid item>
                  <Tooltip title={`Driver ID (Click to copy)`}>
                    <div className={cls.infoBoxHold} onClick={() => copyToClipboard(point.driver_id)}>
                      <Icon className={cls.infoBoxIcon}>person</Icon>
                      <Typography className={cls.infoBoxTxt}>
                        {point.driver_id ? `#${point.driver_id}` : `-`}
                      </Typography>
                    </div>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <div className={cls.lineV} />
                </Grid>

                <Grid item>
                  <Tooltip title={`Move ID (Click to copy)`}>
                    <div className={cls.infoBoxHold} onClick={() => copyToClipboard(point.move_id)}>
                      <Icon className={cls.infoBoxIcon}>drive_eta</Icon>
                      <Typography className={cls.infoBoxTxt}>{point.move_id ? `#${point.move_id}` : `-`}</Typography>
                    </div>
                  </Tooltip>
                </Grid>

                <div className={cls.line} />

                <Grid item>
                  <Tooltip title={`Accuracy of the coordinates`}>
                    <div className={cls.infoBoxHold}>
                      <Icon className={cls.infoBoxIcon}>gps_fixed</Icon>
                      <Typography className={cls.infoBoxTxt}>
                        {point.accuracy !== -1 ? `${point.accuracy}%` : `-`}
                      </Typography>
                    </div>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <div className={cls.lineV} />
                </Grid>

                <Grid item>
                  <Tooltip title={`Heading angle`}>
                    <div className={cls.infoBoxHold}>
                      <Icon className={cls.infoBoxIcon}>north_east</Icon>
                      <Typography className={cls.infoBoxTxt}>
                        {point.heading !== -1 ? `${point.heading}°` : `-`}
                      </Typography>
                    </div>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <div className={cls.lineV} />
                </Grid>

                <Grid item>
                  <Tooltip title={`Velocity`}>
                    <div className={cls.infoBoxHold}>
                      <Icon className={cls.infoBoxIcon}>speed</Icon>
                      <Typography className={cls.infoBoxTxt}>
                        {point.speed !== -1 ? `${point.speed} m/s` : `-`}
                      </Typography>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      })}
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  pointOdd: {
    position: 'relative',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
  },
  pointEven: {
    position: 'relative',
    padding: theme.spacing(2),
    background: theme.palette.background.dark,
  },

  timeTxt1: {
    marginBottom: 4,
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.default.main,
    cursor: 'pointer',
  },
  timeTxt2: {
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.default.main,
    cursor: 'pointer',
  },

  iconBtn: {
    padding: theme.spacing(0.5),
  },
  icon: {
    display: 'block',
    fontSize: 24,
    color: theme.palette.default.main,
  },

  iconBtnId: {
    padding: theme.spacing(0.5),
    background: theme.palette.default.main,
    '&:hover': {
      background: theme.palette.default.light,
    },
  },
  iconId: {
    display: 'block',
    fontSize: 24,
    color: theme.palette.text.contrast,
  },

  chipGoTo: {
    background: theme.palette.default.main,
    color: theme.palette.text.contrast,
    '&:hover': {
      background: theme.palette.default.light,
    },
  },

  infoBox: {
    border: `1px solid ${theme.palette.default.lighter}`,
  },
  infoBoxHold: {
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  infoBoxIcon: {
    display: 'inline-block',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.default.main,
  },
  infoBoxTxt: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 3,
    marginLeft: 4,
    lineHeight: 1,
    fontSize: 16,
    color: theme.palette.default.main,
  },

  notFound: {
    padding: theme.spacing(2),
  },
  notFoundTxt: {
    lineHeight: 1,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  line: {
    width: '100%',
    height: 1,
    background: theme.palette.default.lighter,
  },
  lineV: {
    width: 1,
    height: '100%',
    background: theme.palette.default.lighter,
  },
}));
