import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper } from '@material-ui/core';

import { DriverScheduleTable, DriverScheduleBanner } from './index';
// import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';

export default function DriverSchedule() {
  const cls = useStyles();

  const [selectedRegionObj, setSelectedRegionObj] = useState(null);

  //Declaring this wrapper saves repetition in the code
  const DriverScheduleWrapper = props => {
    const { children } = props;
    return (
      <Paper id='root' className={cls.root}>
        <Container maxWidth='lg'>
          <DriverScheduleBanner selectedRegionObj={selectedRegionObj} setSelectedRegionObj={setSelectedRegionObj} />
          <div style={{ width: '100%', height: '24px' }} />
          {children}
        </Container>
      </Paper>
    );
  };

  //Get Schedules refactor idea: (using an _in where clause I can search for these after drivers are queried usnig driver_ids)
  const { data, error, refetch } = useQuery(GET_SCHEDULES);
  // This was causing bad behavior when adding rows
  // if (loading) {
  //   return (
  //     <DriverScheduleWrapper>
  //       <Loading />
  //     </DriverScheduleWrapper>
  //   );
  // } else 
  
  if (error) {
    return (
      <DriverScheduleWrapper>
        <DefaultErrorFallback message='ERROR GETTING SCHEDULES' />
      </DriverScheduleWrapper>
    );
  } else if (!selectedRegionObj) {
    return (
      <DriverScheduleWrapper>
        <DefaultEmptyFallback message='SELECT A REGION' />
      </DriverScheduleWrapper>
    );
  } else {
    return (
      <DriverScheduleWrapper>
        <DriverScheduleTable selectedRegion={selectedRegionObj} refetch={refetch} schedules={data.driverschedules} />
      </DriverScheduleWrapper>
    );
  }
}

// DriverSchedule.propTypes = {};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

const GET_SCHEDULES = gql`
  query get_schedules {
    driverschedules {
      id
      driver_id
      type
      expiration_date
      details {
        id
        driver_schedule_id
        day
        block_start_time
        block_end_time
      }
    }
  }
`;
