//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useSubscription } from '@apollo/client';
import { useData } from '../../providers/DataProvider';
import { Tooltip, IconButton, Badge, Icon } from '@material-ui/core';
import { getAuthStatus } from '../../utils/authHelper';
import { Link } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { GET_PENDING_CANCELED_MOVES } from './gql';

//////////////////////// COMPONENT ////////////////////////

function PendingCancelIcon(props) {
  const { apolloClient } = useData();
  const { loading, error, data } = useSubscription(GET_PENDING_CANCELED_MOVES);
  const loggedIn = getAuthStatus();

  const BadgeWrapper = props => {
    return (
      <Tooltip title='Pending Cancellations'>
        <Link
          to={{
            pathname: '/moves',
            state: { pendingCancel: true },
          }}
        >
          <IconButton color='inherit'>
            {props.content ? (
              <Badge badgeContent={props.content} color={props.color || 'error'}>
                <Icon>block</Icon>
              </Badge>
            ) : (
              <Icon>block</Icon>
            )}
          </IconButton>
        </Link>
      </Tooltip>
    );
  };

  if (loggedIn && apolloClient) {
    if (loading) return <BadgeWrapper content='...' />;
    if (error) {
      console.error(`Error fetching pending cancellations:`, error);
      Sentry.captureException(error);
      return <BadgeWrapper content='!' />;
    }
    if (!data || !data.moves || !data.moves.length > 0) return <BadgeWrapper />;
    return <BadgeWrapper content={data.moves.length} />;
  } else return <BadgeWrapper />;
}

//////////////////////// EXPORT ////////////////////////

export default PendingCancelIcon;
