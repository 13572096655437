// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip, TextField, IconButton, Icon, InputAdornment, MenuItem } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { usePlansGroup } from './PlansGroupProvider';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupToolbar({ regions, refetch }) {
  const cls = useStyles();

  const {
    selectedPlanDate,
    selectedRegionIds,
    setSelectedPlanDate,
    setSelectedDriverIds,
    setSelectedMoveIds,
    setSelectedRegionIds,
    savePlansGroup,
    closePlansGroup,
  } = usePlansGroup();

  const [loading, setLoading] = React.useState(false);
  const [disablePreviousDate, setDisablePreviousDate] = React.useState(false);

  React.useEffect(() => {
    if (dayjs().add(1, `day`).startOf(`day`).isAfter(dayjs(selectedPlanDate))) setDisablePreviousDate(true);
    else setDisablePreviousDate(false);
  }, [selectedPlanDate]);

  const handleRegionChange = event => {
    const regionId = parseInt(event.target.value);
    if (regionId) setSelectedRegionIds([regionId]);
    else setSelectedRegionIds([]);
  };

  const handleDateChange = value => {
    const newDate = dayjs(value).format(`YYYY-MM-DD`);
    setSelectedPlanDate(newDate);
    setSelectedDriverIds([]);
    setSelectedMoveIds([]);
  };

  const handleGoToDate = name => {
    let newDate = dayjs(selectedPlanDate).format(`YYYY-MM-DD`);

    if (name === `prev`) newDate = dayjs(selectedPlanDate).subtract(1, `day`).format(`YYYY-MM-DD`);
    if (name === `next`) newDate = dayjs(selectedPlanDate).add(1, `day`).format(`YYYY-MM-DD`);

    handleDateChange(newDate);
  };

  const handleSaveAndContinue = async () => {
    setLoading(true);

    await savePlansGroup();
    await refetch();
    setSelectedMoveIds([]);

    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);

    await savePlansGroup();
    closePlansGroup();

    setLoading(false);
  };

  const handleClose = () => {
    closePlansGroup();
  };

  return (
    <div className={cls.toolbar}>
      <Grid container spacing={1} justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
        <Grid item>
          <TextField
            select
            fullWidth
            placeholder='Select region...'
            variant='outlined'
            size='small'
            value={selectedRegionIds?.[0] || 0}
            onChange={handleRegionChange}
            InputProps={{
              className: cls.regionSelect,
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    public
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={0}>All Regions</MenuItem>
            {regions?.map(r => (
              <MenuItem key={`plans-group-region-${r?.id}`} value={r?.id}>
                {r?.name} ({r?.id})
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs />

        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={1} alignItems='center' wrap='nowrap'>
              <Grid item>
                <Tooltip title='Previous Date'>
                  <IconButton
                    className={cls.iconBtn}
                    disabled={disablePreviousDate}
                    onClick={() => handleGoToDate(`prev`)}
                  >
                    <Icon>arrow_back</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>

              <Tooltip title='Change planned date'>
                <Grid item>
                  <Tooltip title='Date Selection'>
                    <DatePicker
                      className={cls.date}
                      inputProps={{
                        className: cls.dateSelect,
                      }}
                      autoOk
                      minDate={dayjs().format()}
                      format='MM/dd/yyyy'
                      orientation='portrait'
                      size='small'
                      variant='inline'
                      inputVariant='outlined'
                      value={dayjs(selectedPlanDate).format()}
                      onChange={handleDateChange}
                    />
                  </Tooltip>
                </Grid>
              </Tooltip>

              <Grid item>
                <Tooltip title='Next Date'>
                  <IconButton className={cls.iconBtn} onClick={() => handleGoToDate(`next`)}>
                    <Icon>arrow_forward</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <Tooltip title='Refetch Data'>
            <IconButton className={cls.iconBtn} onClick={() => (refetch ? refetch() : null)}>
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <Button
            disabled={loading}
            loading={loading}
            color='primary'
            variant='outlined'
            onClick={() => handleSaveAndContinue()}
          >
            Save
          </Button>
        </Grid>

        <Grid item>
          <Button disabled={loading} loading={loading} color='primary' onClick={() => handleSave()}>
            Save & Close
          </Button>
        </Grid>

        <Grid item>
          <Button disabled={loading} onClick={() => handleClose()}>
            Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'relative',
    zIndex: 300,
    width: '100%',
    height: 48,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  regionSelect: {
    minWidth: 240,
    height: 32,
    padding: theme.spacing(1),
  },
  dateSelect: {
    height: `1em`,
    padding: theme.spacing(1, 1),
  },

  date: {
    width: 128,
  },

  iconBtn: {
    padding: theme.spacing(0.5),
  },

  line: {
    width: 1,
    height: 32,
    marginLeft: 8,
    marginRight: 8,
    background: theme.palette.divider,
  },
}));
