import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { makeStyles, Grid, Divider, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

const log = false;

function PayPeriodDetailTableHeader({ hideLogoLine = false, appayments = [], payPeriod = {} }) {
  const cls = useStyles();

  const [summary, setSummary] = useState(null);

  useEffect(() => {
    log && console.log(`Init load to prep summary...`);
    setSummary(prepSummaryValues(appayments));
  }, [appayments]);

  const prepSummaryValues = appayments => {
    // initialize payable line items
    let paySum = 0;
    let accessorials = 0;
    let tollsSum = 0;
    let durationSum = 0;
    let distanceSum = 0;

    // eslint-disable-next-line
    appayments.map(appayment => {
      const { move } = appayment;
      paySum += Number(appayment.amount);
      tollsSum += Number(move.lane.tolls);
      // eslint-disable-next-line
      durationSum += Number(move.lane.duration_sec != 'undefined' ? move.lane.duration_sec : 0);
      // eslint-disable-next-line
      distanceSum += Number(move.lane.distance_miles != 'undefined' ? move.lane.distance_miles : 0);
    });

    if (log) console.log('Total moves: ', appayments.length);
    if (log) console.log('Total pay: ', paySum);
    if (log) console.log('Total duration: ', durationSum);
    if (log) console.log('Total distance: ', distanceSum);
    let payPerMove = appayments.length > 0 ? paySum / appayments.length : 0;
    let payPerHour = durationSum > 0 ? paySum / (durationSum / 60 / 60) : 0;
    let payPerMile = distanceSum > 0 ? paySum / distanceSum : 0;
    if (log) console.log('Total payPerMove: ', payPerMove);
    if (log) console.log('Total payPerHour: ', payPerHour);
    if (log) console.log('Total payPerMile: ', payPerMile);

    return {
      paySum: paySum,
      tollsSum: tollsSum,
      durationSum: durationSum,
      distanceSum: distanceSum,
      payPerMove: payPerMove,
      payPerHour: payPerHour,
      payPerMile: payPerMile,
      accessorials: accessorials,
    };
  };

  if (!(summary && appayments && appayments.length > 0)) return <></>;

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAY PERIOD HEADER' />}>
      <div className={cls.root}>
        <Grid container direction='row' justifyContent='space-between' spacing={4}>
          <Grid item xs={6} className={hideLogoLine ? cls.logoHeaderPrintOnly : null}>
            <img src='https://socialautotransport.com/logo-264px.png' alt='logo' />
          </Grid>
          <Grid item xs={6} className={hideLogoLine ? cls.logoHeaderPrintOnly : null}>
            <Typography className={cls.earningsHeader}>EARNINGS</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={cls.reducedFont} style={{ fontWeight: 'bold' }} variant='h6'>
              FROM
            </Typography>
            <Typography className={cls.reducedFont}>1717 East Cary St, Suite 2120</Typography>
            <Typography className={cls.reducedFont}>Richmond, VA 23223</Typography>
            <Typography className={cls.reducedFont}>1-800-913-7674</Typography>
            <Typography className={cls.reducedFont}>
              <a href='https://www.hopdrive.com'>hopdrive.com</a>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography display='inline' className={cls.detailItem}>
              Total Moves
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {appayments.filter(o => o.type === 'move pay' && o.move.move_type === 'drive').length}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Total Rides
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {appayments.filter(o => o.type === 'move pay' && o.move.move_type === 'ride').length}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Total Adjustments
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {appayments.filter(o => o.type === 'accessorial').length}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Total Miles
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {summary.distanceSum.toFixed(2)}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Total Time
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {moment.utc(summary.durationSum * 1000).format('H:mm:ss')}{' '}
              <em>(about {moment.duration(summary.durationSum, 'seconds').humanize()})</em>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography display='inline' className={cls.detailItem}>
              Driver Name
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {payPeriod.driver_name}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Earnings Summary #
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {payPeriod.driver_id}-{payPeriod.pay_period}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Earnings Date Range
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              {moment(payPeriod.pickup_time_start).format('MM/DD/YYYY')} -{' '}
              {moment(payPeriod.pickup_time_end).format('MM/DD/YYYY')}{' '}
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Average Hourly Rate
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              ${summary.payPerHour.toFixed(2)} per hour
            </Typography>
            <br />
            <Typography display='inline' className={cls.detailItem}>
              Average Per Mile Rate
            </Typography>
            <Typography display='inline' className={cls.detailValue}>
              {' '}
              ${summary.payPerMile.toFixed(2)} per mile
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={cls.horLine} />
            <Typography display='inline' className={cls.earningsTotal}>
              EARNINGS TOTAL
            </Typography>
            <Typography display='inline' className={cls.earningsSum}>
              {' '}
              ${summary.paySum.toFixed(2)}{' '}
            </Typography>
            <Divider className={cls.horLine} />
          </Grid>
        </Grid>
      </div>
    </Sentry.ErrorBoundary>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logoHeaderPrintOnly: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  grid: {
    width: '100%',
    padding: theme.spacing(2),
  },
  earningsHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '180%',
  },
  reducedFont: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
  },
  detailItem: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
    fontWeight: 'bold',
    textAlign: 'left',
  },
  detailValue: {
    fontSize: '100%',
    '@media print': {
      fontSize: '90%',
    },
    textAlign: 'left',
    float: 'right',
  },
  horLine: {
    margin: theme.spacing(0.3),
    backgroundColor: 'black',
  },
  earningsTotal: {
    marginLeft: theme.spacing(1),
    fontSize: '225%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  earningsSum: {
    marginRight: theme.spacing(1),
    fontSize: '225%',
    textAlign: 'left',
    float: 'right',
    fontWeight: 'bold',
  },
}));

export default withRouter(PayPeriodDetailTableHeader);
