export function useProviders() {
  const getSubscriberProviderState = (options = {}) => {
    const {
      subscriptionLoading,
      subscriptionError,
      subscriptionData,
      queryLoading,
      queryError,
      queryData,
      queryRunning,
    } = options;

    let _state;

    if (subscriptionLoading && !subscriptionError && !subscriptionData && !queryRunning) {
      _state = 'subscription.loading';
    } else if (!subscriptionLoading && subscriptionError && !subscriptionData && !queryRunning) {
      _state = 'subscription.error';
    } else if (!subscriptionLoading && !subscriptionError && subscriptionData && !queryRunning) {
      _state = 'subscription.successful';
    } else if (
      queryRunning &&
      queryLoading &&
      !queryError &&
      !queryData
    ) {
      _state = 'query.loading';
    } else if (
      queryRunning &&
      !queryLoading &&
      queryError &&
      !queryData
    ) {
      _state = 'query.error';
    } else if (
      queryRunning &&
      !queryLoading &&
      !queryError &&
      queryData
    ) {
      _state = 'query.successful';
    } else {
      _state = 'unknown';
    }
    return _state;
  };

  return {
    getSubscriberProviderState,
  };
}
