import React from 'react';
import EventLogItemGeneric from './EventLogItemGeneric';
import EventLogItemDriverAppDump from './EventLogItemDriverAppDump';

// interface EventItem {
//   id: string;
//   actiontype: {
//     id: string;
//     name?: string;
//     description?: string;
//   };
//   event_time_utc: string;
//   metadata?: object | undefined;
//   created_at_utc: string;
//   user: string | undefined;
// }

// interface EventLogItemProps {
//   event: EventItem;
// }

const EventLogRenderer = ({ event }) => {
  if (event?.actiontype?.name === 'driver.app.dump') return <EventLogItemDriverAppDump event={event} />;
  return <EventLogItemGeneric event={event} />;
};

export { EventLogRenderer };
