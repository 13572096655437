// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import {
  addParentStepIds,
  buildWorkflowOutput,
  getNextStep,
  getStepByStepId,
  setValueByStepId,
  validateSteps,
} from '@hopdrive/workflows';

// HELPERS -------------------------------------------------- //

const log = true;

const stepTypes = [`group`, `input`, `multibutton`, `select`];
const multiOutcomeStepTypes = [`multibutton`, `select`];

const fallbackScreen = {};
const fallbackStep = {};
const fallbackStepArray = [];
const fallbackStepId = ``;

// CONTEXT -------------------------------------------------- //

const WorkflowContext = React.createContext({});

// PROVIDER -------------------------------------------------- //

/** Some important things to note about the state management:
 ** SCREENS are the ROOT steps of the workflow, meaning that they are not nested and live at the top level.
 ** STEPS are the CHILD steps of the workflow, which are nested inside their parent screens.
 ** The workflowSteps array is directly built from the db workflow.steps array.
 ** The workflowTreeIds array is a series of step ids that gets built by a utility function called buildWorkflowOutput.
 ** The workflowTrackedScreenIds is a running list of the screens that track your current flow.
 */
function WorkflowProvider({ children }) {
  // STATE //

  const [workflowSteps, setWorkflowSteps] = React.useState(fallbackStepArray);
  const [treeIds, setTreeIds] = React.useState(fallbackStepArray);
  const [trackedScreens, setTrackedScreens] = React.useState(fallbackStepArray);

  const [prevScreen, setPrevScreen] = React.useState(fallbackScreen);
  const [currentScreen, setCurrentScreen] = React.useState(fallbackScreen);
  const [nextScreen, setNextScreen] = React.useState(fallbackScreen);

  const [prevStep, setPrevStep] = React.useState(fallbackStep);
  const [currentStep, setCurrentStep] = React.useState(fallbackStep);
  const [nextStep, setNextStep] = React.useState(fallbackStep);

  // USE EFFECTS //

  // Detect workflow steps change
  React.useEffect(() => {
    // log && console.log(`Workflow Steps:`, workflowSteps);
  }, [workflowSteps]);

  // Detect tree ids change
  React.useEffect(() => {
    // log && console.log(`Tree IDs:`, treeIds);
  }, [treeIds]);

  // Detect tracked screens change
  React.useEffect(() => {
    // log && console.log(`Tracked Screens:`, trackedScreens);
  }, [trackedScreens]);

  // FUNCTIONS - HANDLERS //

  /** Handle the initial load of the workflow */
  const handleInitialLoad = workflow => {
    // log && console.log(`handleInitialLoad:`, workflow);

    // Check if the workflow steps exist
    if (workflow?.steps?.length) {
      // Initialize the found workflow steps
      // Workflow is imutable so it needs to be parsed from a string in order to be edited
      // Add any additional data to each step
      let workflowStepsEdit = JSON.parse(JSON.stringify([...workflow.steps]));
      addParentStepIds(workflowStepsEdit);

      // Initialize first screen and first step
      const initialScreen = workflowStepsEdit?.[0];
      const initialStep = initialScreen?.steps?.[0];

      // Set the initial state
      setWorkflowSteps(workflowStepsEdit);
      setTrackedScreens([initialScreen]);
      setCurrentScreen(initialScreen);
      setCurrentStep(initialStep || initialScreen);
    }

    // Otherwise, use fallbacks
    else {
      setWorkflowSteps(fallbackStepArray);
      setCurrentScreen(fallbackStep);
      setCurrentStep(fallbackStep);
    }
  };

  /** Handle a change on a step */
  /**
   * Handle a change on a step
   * @param {string} id - The step id
   * @param {any} value - The step value
   * @example handleStepChange(id, value)
   */
  const handleStepChange = (id, value, nextStepId) => {
    // log && console.log(`handleStepChange:`, { id, value, nextStepId });

    // Set the step value on the workflow state
    let workflowStepsEdit = [...workflowSteps];
    setValueByStepId(workflowStepsEdit, id, value);

    // Set the tree ids
    const workflowOutput = buildWorkflowOutput(workflowStepsEdit);
    const treeIdsEdit = Object.keys(workflowOutput);

    // Set the new step order locally
    const newPrevStep = currentStep;
    const newCurrentStep = getStepByStepId(workflowStepsEdit, id);
    const newNextStep = getNextStep(workflowStepsEdit, newCurrentStep);

    // Build the list of root step ids (screen ids)
    let screenIds = workflowSteps?.map(s => s.id);
    screenIds = [...screenIds, `review`];

    // If the next step is a root step (screen)
    // and if all the current root step's children are valid,
    // set it as the next screen.

    if (screenIds?.includes(newNextStep?.id) && validateSteps(workflowStepsEdit, currentScreen?.id)?.isValid) {
      setNextScreen(newNextStep);
    }

    // Otherwise, set it to the fallback screen.
    else setNextScreen(fallbackScreen);

    // Set workflow variables in state
    setWorkflowSteps(workflowStepsEdit);
    setTreeIds(treeIdsEdit);

    // Set the new step order in state
    setPrevStep(newPrevStep);
    setCurrentStep(newCurrentStep);
    setNextStep(newNextStep);
  };

  /**
   * Handle if the step should be hidden
   * @param {import('@hopdrive/workflows workflowProcessor.mjs').Step} step - The step object
   * @returns {boolean} - Returns true if the step should be hidden
   * @example handleStepHide(step) // true or false
   */
  const handleStepHide = step => {
    // Always show the current screen
    if (step?.id === currentScreen?.id) return false;

    // Check if the step is included on the current screen
    if (step?.parent_step_id === currentScreen?.id) {
      // Always show child steps where the group_type is NOT 'progressive'
      if (currentScreen?.config?.group_type !== `progressive`) return false;

      // Always show child steps in the render tree with a value
      if (treeIds?.includes(step?.id) && step?.value) return false;

      // Always show the first child step
      const firstChildStep = currentScreen?.steps?.[0];
      if (step?.id === firstChildStep?.id) return false;

      // Always show the current step
      if (step?.id === currentStep?.id) return false;

      // Always show the next step
      if (step?.id === nextStep?.id) return false;
    }

    // Default hide the step
    return true;
  };

  // FUNCTIONS - NAVIGATION //

  /** Executes when the previous button is clicked */
  const handlePrevBtn = async callback => {
    // Initialize the tracked screens
    let trackedScreensEdit = [...trackedScreens];

    // Remove the current screen from the tracked screens
    trackedScreensEdit.pop();
    setTrackedScreens(trackedScreensEdit);

    // Get previous, current & next screen based on the tracked screens
    const prevScreenEdit = trackedScreensEdit?.[trackedScreensEdit?.length - 2];

    // Determine current & next screens
    setPrevScreen(prevScreenEdit);
    setCurrentScreen(prevScreen);
    setNextScreen(currentScreen);

    // Steps will all reset when the screen changes
    setPrevStep(fallbackStep);
    setCurrentStep(fallbackStep);
    setNextStep(fallbackStep);

    // Run the callback function
    if (callback) await callback(workflowSteps);
  };

  /** Executes when the next button is clicked */
  const handleNextBtn = async callback => {
    // Initialize the tracked screens
    let trackedScreensEdit = [...trackedScreens];

    // Add the current screen to the tracked screens
    trackedScreensEdit.push(nextScreen);
    setTrackedScreens(trackedScreensEdit);

    // Determine previous, current & next screen
    setPrevScreen(currentScreen);
    setCurrentScreen(nextScreen);
    setNextScreen(fallbackScreen);

    // If the next screen is already filled, we need to revalidate the screen and set the screen after
    const isValid = validateSteps(workflowSteps, nextScreen?.id)?.isValid;
    if (isValid) {
      const finalChildStep = nextScreen?.steps?.[nextScreen?.steps?.length - 1] || nextScreen;
      setNextScreen(getNextStep(workflowSteps, finalChildStep));
    }

    // Steps will all reset when the screen changes
    setPrevStep(fallbackStep);
    setCurrentStep(fallbackStep);
    setNextStep(fallbackStep);

    // Run the callback function
    if (callback) await callback(workflowSteps);
  };

  /** Executes when the review button is clicked */
  const handleReviewBtn = async callback => {
    // Initialize the tracked screens
    let trackedScreensEdit = [...trackedScreens];

    // Add the review screen to the tracked screens
    const reviewScreen = { id: `review`, label: `Review`, description: `Review the outcome.` };
    trackedScreensEdit.push(reviewScreen);
    setTrackedScreens(trackedScreensEdit);

    // Determine previous, current & next screen
    setPrevScreen(currentScreen);
    setCurrentScreen(reviewScreen);
    setNextScreen(fallbackScreen);

    // Steps will all reset when the screen changes
    setPrevStep(fallbackStep);
    setCurrentStep(fallbackStep);
    setNextStep(fallbackStep);

    // Run the callback function
    if (callback) return await callback(workflowSteps);
  };

  /** Executes when the submit button is clicked */
  const handleSubmitBtn = async callback => {
    // Run the callback function
    if (callback) return await callback(workflowSteps);
  };

  /** Executes when the user clicks on a screen module */
  const handleGoToScreen = screenId => {
    // Initialize the tracked screens
    let trackedScreensEdit = [...trackedScreens];

    // Pop screens off the tracked screens until the screen id is reached
    while (trackedScreensEdit?.[trackedScreensEdit?.length - 1]?.id !== screenId) {
      trackedScreensEdit.pop();
    }
    setTrackedScreens(trackedScreensEdit);

    // Get previous, current & next screen based on the tracked screens
    const prevScreenEdit = trackedScreensEdit?.[trackedScreensEdit?.length - 2];
    const currentScreenEdit = trackedScreensEdit?.[trackedScreensEdit?.length - 1];

    // Determine previous, current & next screen
    setPrevScreen(prevScreenEdit);
    setCurrentScreen(currentScreenEdit);
    setNextScreen(fallbackScreen);

    // If the next screen is already filled, we need to revalidate the screen and set the screen after
    const isValid = validateSteps(workflowSteps, currentScreenEdit?.id)?.isValid;
    if (isValid) {
      const finalChildStep = currentScreenEdit?.steps?.[currentScreenEdit?.steps?.length - 1] || currentScreenEdit;
      setNextScreen(getNextStep(workflowSteps, finalChildStep));
    }

    // Steps will all reset when the screen changes
    setPrevStep(fallbackStep);
    setCurrentStep(fallbackStep);
    setNextStep(fallbackStep);
  };

  // FUNCTIONS - NAVIGATION CHECKS //

  /** Check for the end of the workflow */
  const checkForEndOfWorkflow = () => {
    // Check if the current screen is the last screen
    if (currentScreen?.id === workflowSteps?.[workflowSteps?.length - 1]?.id) {
      return true;
    }
    return false;
  };

  /** Determine if the prev button needs to be disabled */
  const checkPrevBtnDisabled = () => {
    return !prevScreen?.id;
  };

  /** Determine if the next button needs to be disabled */
  const checkNextBtnDisabled = () => {
    return !nextScreen?.id;
  };

  /** Determine if the review button needs to be disabled */
  const checkReviewBtnDisabled = () => {};

  /** Determine if the submit button needs to be disabled */
  const checkSubmitBtnDisabled = () => {};

  /** Determine if the next button needs to be hidden */
  const checkNextBtnHide = () => {
    return currentScreen?.id === `review` || nextScreen?.id === `review`;
  };

  /** Determine if the review button needs to be hidden */
  const checkReviewBtnHide = () => {
    return nextScreen?.id !== `review`;
  };

  /** Determine if the submit button needs to be hidden */
  const checkSubmitBtnHide = () => {
    if (currentScreen?.id === `review`) return false;
    return true;
  };

  // CONSTANTS //

  // List of button actions
  const actions = [
    {
      label: `Prev`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Return to the previous section.`,
      handler: async callback => await handlePrevBtn(callback),
      disabled: checkPrevBtnDisabled(),
    },
    {
      label: `Next`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Proceed to the next section.`,
      handler: async callback => await handleNextBtn(callback),
      disabled: checkNextBtnDisabled(),
      hide: checkNextBtnHide(),
    },
    {
      label: `Review`,
      icon: `visibility`,
      variant: `contained`,
      color: `primary`,
      tip: `Review the outcome.`,
      handler: async callback => await handleReviewBtn(callback),
      disabled: checkReviewBtnDisabled(),
      hide: checkReviewBtnHide(),
    },
    {
      label: `Submit`,
      icon: `check_circle`,
      variant: `contained`,
      color: `primary`,
      tip: `Submit the outcome.`,
      handler: async callback => await handleSubmitBtn(callback),
      disabled: checkSubmitBtnDisabled(),
      hide: checkSubmitBtnHide(),
    },
  ];

  // CONTEXT //

  const context = {
    // Constants
    stepTypes,
    multiOutcomeStepTypes,
    fallbackScreen,
    fallbackStep,
    fallbackStepArray,
    fallbackStepId,

    // State
    workflowSteps,
    treeIds,
    trackedScreens,
    prevScreen,
    currentScreen,
    nextScreen,
    prevStep,
    currentStep,
    nextStep,

    // Functions
    handleInitialLoad,
    handleStepChange,
    handleStepHide,
    handlePrevBtn,
    handleNextBtn,
    handleReviewBtn,
    handleSubmitBtn,
    handleGoToScreen,

    // Constants
    actions,
  };

  // RENDER //

  return <WorkflowContext.Provider value={context}>{children}</WorkflowContext.Provider>;
}

// HOOK -------------------------------------------------- //

const useWorkflow = () => React.useContext(WorkflowContext);

// EXPORT -------------------------------------------------- //

export { useWorkflow, WorkflowProvider };
