import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, IconButton, Icon, LinearProgress } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function WorkflowBar({
  groupTitle,
  sectionTitle,
  actions = [],
  isSaving,
  onClose,
  editMode,
  setEditMode,
}) {
  const cls = useStyles();

  return (
    <div className={cls.workflowBar}>
      <div className={cls.workflowBarPadding}>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography className={cls.workflowGroupTxt}>{groupTitle || `Group Title`}&nbsp;&nbsp;/</Typography>
          </Grid>

          <Grid item xs>
            <Typography className={cls.workflowSectionTxt}>{sectionTitle || `Section Title`}</Typography>
          </Grid>

          {actions && actions.length
            ? actions.map((a, i) => (
                <React.Fragment key={`workflow-action-${i}`}>
                  {!a.hide && (
                    <Grid item>
                      <Tooltip title={a.tip || ``}>
                        <div className={cls.hideWhenSmall}>
                          <Spacer column size='xs' />
                          <Button
                            disabled={isSaving}
                            variant={a.variant || `contained`}
                            color={a.color || `default`}
                            onClick={() => (a.handler ? a.handler() : {})}
                          >
                            {a.icon ? <Icon className={cls.btnIcon}>{a.icon}</Icon> : null}
                            {a.label || `Action ${i + 1}`}
                          </Button>
                        </div>
                      </Tooltip>

                      <Tooltip title={a.tip || ``}>
                        <div className={cls.hideWhenLarge}>
                          <IconButton
                            disabled={isSaving}
                            className={cls.iconBtn}
                            color={a.color || `default`}
                            onClick={() => (a.handler ? a.handler() : {})}
                          >
                            <Icon>{a.icon || `help`}</Icon>
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                </React.Fragment>
              ))
            : null}

          <div className={cls.hideWhenSmall}>
            <Spacer column />
          </div>
          <div className={cls.hideWhenLarge}>
            <Spacer column size='xs' />
          </div>

          <Grid item>
            <div className={cls.line} />
          </Grid>

          <Spacer column size='xs' />

          {setEditMode ? (
            <Grid item>
              <Tooltip title='Click to unlock and edit the form. To discard, click the close button.'>
                <IconButton className={cls.iconBtn} onClick={() => setEditMode(!editMode)}>
                  {editMode ? (
                    <Icon className={cls.unlockIcon}>lock_open</Icon>
                  ) : (
                    <Icon className={cls.lockIcon}>lock</Icon>
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}

          <Grid item>
            <Tooltip title='Discard changes & return to the LaunchPad.'>
              <IconButton className={cls.iconBtn} onClick={() => onClose()}>
                <Icon className={cls.closeIcon}>close</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>

      {isSaving ? <LinearProgress className={cls.linearProgress} /> : null}
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowBar: {
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    marginTop: 64,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  workflowBarPadding: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.25),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  workflowGroupTxt: {
    display: 'block',
    marginLeft: 12,
    lineHeight: 1.25,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  workflowSectionTxt: {
    marginLeft: 12,
    lineHeight: 1.25,
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 4,
      fontSize: 16,
    },
  },

  hideWhenSmall: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideWhenLarge: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },

  iconBtn: {
    padding: theme.spacing(1),
  },

  unlockIcon: {
    color: theme.palette.error.main,
  },
  lockIcon: {
    color: theme.palette.text.primary,
  },
  closeIcon: {
    color: theme.palette.text.primary,
  },

  line: {
    width: 1,
    height: 40,
    background: theme.palette.divider,
  },

  linearProgress: {
    zIndex: 201,
  },
}));
