//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import MoveTile from '../../components/MoveTile';

//////////////////////// COMPONENT ////////////////////////

function MoveRelated({ relatedMoves = [], ...props }) {
  const cls = useStyles();

  return (
    <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
      {relatedMoves.map(relatedMove => (
        <Grid key={`move-tile-${relatedMove.id}`} item xs className={cls.tile}>
          <MoveTile move={relatedMove} />
        </Grid>
      ))}
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '420px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '320px',
      maxWidth: '360px',
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveRelated;
