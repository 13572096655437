import React from 'react';
import { gql } from '@apollo/client';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';
import { useData } from '../providers/DataProvider';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

const log = false;

const GET_DRIVERS = gql`
  query get_drivers {
    drivers(order_by: { user: { display_name: asc } }) {
      id
      user {
        id
        display_name
      }
    }
  }
`;

////////// COMPONENT //////////
export default function DriverSelect({ required, label, placeholder, value, onChange, selectAllItem, drivers }) {
  const { apolloClient } = useData();

  const [hopdrive, setHopdrive] = React.useState({ loading: true, drivers: [] });

  React.useEffect(() => {
    const getHopdriveDriverData = async () => {
      try {
        log && console.log(`Getting driver data from the db...`);
        const res = await apolloClient.query({ query: GET_DRIVERS });
        const hopdriveDrivers = getPropValue(res, `data.drivers`) || [];
        log && console.log(`Hopdrive drivers:`, hopdriveDrivers);
        setHopdrive({ loading: false, drivers: hopdriveDrivers });
      } catch (err) {
        console.log(`Failed to retrieve Hopdrive drivers:`, err);
        setHopdrive({ loading: false, drivers: [] });
      }
    };

    if (!drivers?.length && hopdrive?.loading && !hopdrive?.drivers?.length) {
      getHopdriveDriverData();
    }
  }, []);

  return (
    <TextField
      fullWidth
      required={required || false}
      select
      name='driver'
      label={label || `Driver`}
      placeholder={placeholder || `Select a driver...`}
      variant='outlined'
      size='small'
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
            <Icon color='disabled' fontSize='small'>
              person
            </Icon>
          </InputAdornment>
        ),
      }}
    >
      {selectAllItem ? (
        <MenuItem key={'All Drivers'} value={0}>
          All Drivers
        </MenuItem>
      ) : (
        <MenuItem key={'Select a Driver'} value={0}>
          <em>Select a Driver</em>
        </MenuItem>
      )}

      {drivers?.length
        ? drivers?.map((driver, i) => (
            <MenuItem key={`driver-select-${driver?.driver_id || -1}`} value={driver?.driver_id || -1}>
              {driver?.driver_name || 'HopDriver'} ({driver?.driver_id || -1})
            </MenuItem>
          ))
        : null}

      {!drivers?.length && hopdrive?.drivers?.length
        ? hopdrive?.drivers?.map((driver, i) => (
            <MenuItem key={`driver-select-${driver?.id || -1}`} value={driver?.id || -1}>
              {driver?.user?.display_name || 'HopDriver'} ({driver?.id || -1})
            </MenuItem>
          ))
        : null}
    </TextField>
  );
}
