import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Icon,
  InputAdornment,
  TextField,
  CardMedia,
  Chip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';
import { useTools } from '../../hooks/useTools';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import AllowedRegionsTable from './AllowedRegions/AllowedRegionsTable';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';
import AvatarUpload from '../../components/UserManagement/AvatarUpload';
import AdminDriverAddModal from './AdminDriverAddModal';

const titleGridWidth = 5;

export default function UserInfo() {
  const cls = useStyles();
  const history = useHistory();
  const ctx = useUserDetails();
  const { getCleansedPhoneNumber } = useTools();

  const initialState = history?.location?.state;

  const [promptDriver, setPromptDriver] = React.useState(false);

  React.useEffect(() => {
    if (initialState?.promptDriver) {
      setPromptDriver(initialState?.promptDriver);
    }
  }, []);

  React.useEffect(() => {
    if (ctx.allowedRegions && ctx.allowedRegions.length > 0) {
      let preselectedRegions = [];
      ctx.allowedRegions.forEach(region => {
        preselectedRegions.push(region.region_id ? region.region_id : region.id);
      });
      ctx.setAllowedRegionIds(preselectedRegions);
    }
  }, [ctx.allowedRegions, ctx.regions]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };
  const handleSelectedRegions = selections => {
    ctx.setAllowedRegions(selections);
  };

  const handleRemoveRegion = index => {
    ctx?.setAllowedRegions(prevRegions => {
      return prevRegions.filter((_, i) => i !== index);
    });

    ctx?.setAllowedRegionIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  const handleDriverModalClose = () => {
    setPromptDriver(false);
    history.replace(history.location.pathname, {});
  };

  return (
    <Container maxWidth='lg'>
      <AdminDriverAddModal open={promptDriver} close={handleDriverModalClose} />

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                required
                fullWidth
                multiline
                label='Display Name'
                placeholder='Enter display name...'
                variant='outlined'
                size='small'
                value={ctx.displayName}
                onChange={handleInputChange(ctx.setDisplayName)}
                error={ctx?.validation?.displayName === false}
                helperText={ctx?.validation?.displayName === false ? `A display name is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        person
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.displayName}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                required
                fullWidth
                multiline
                label='Email'
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={ctx.email}
                onChange={handleInputChange(ctx.setEmail)}
                error={ctx?.validation?.email === false}
                helperText={ctx?.validation?.email === false ? `A valid email is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        mail
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
                <a href={`mailto:${ctx?.email}`}>{ctx?.email}</a>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Phone (Optional)</Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                fullWidth
                multiline
                label='Phone'
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={ctx.phone}
                onChange={handleInputChange(ctx.setPhone)}
                error={ctx?.validation?.phone === false}
                helperText={ctx?.validation?.phone === false ? `A valid phone number is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
              <a href={`tel:${ctx?.phone}`}>{getCleansedPhoneNumber(ctx?.phone)}</a>
            </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Allowed Regions <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Hopdrive users must be given permissions for at least one market region.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs={5}>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <>
                <ReusableMultiselect
                  dataArray={ctx.regions}
                  selectedArray={ctx.allowedRegionIds}
                  optionName={'name'}
                  label={'Allowed Region'}
                  handleOutput={handleSelectedRegions}
                />

                <Spacer />
                {ctx?.allowedRegions &&
                  ctx.allowedRegions?.map((region, index) => (
                    <Chip
                      key={index}
                      style={{ margin: 5 }}
                      label={region.name || region.region.name}
                      onDelete={() => handleRemoveRegion(index)}
                    />
                  ))}
              </>
            ) : (
              <>
                <AllowedRegionsTable allowedRegions={ctx.allowedRegions} />
              </>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Avatar (Optional)</Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Click or drag and drop a PNG or SVG file containing an imge of the user. Due to server limitations, the
              maximum file size is 0.5MB/500KB.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <AvatarUpload avatar={ctx.avatarUrl} setAvatar={ctx.setAvatarUrl} userId={ctx.userId} />
            ) : (
              <>
                {ctx?.avatarUrl ? (
                  <CardMedia
                    component='img'
                    alt='User profile picture'
                    style={{ width: '25%' }}
                    image={ctx?.avatarUrl}
                    title=''
                  />
                ) : null}
              </>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Driver Record</Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              The driver account associated with the admin user, for access to the driver app.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {!ctx.driverId && ctx.editMode ? (
              <>
                <Button onClick={() => setPromptDriver(true)}>Link Driver</Button>
              </>
            ) : null}
            {!ctx.editMode && ctx.driverId ? (
              <>
                <Typography component={Link} to={`/drivers/${ctx.driverId}`} variant='body1' color='primary'>
                  View Driver Details
                </Typography>
              </>
            ) : null}
            {ctx.editMode && ctx.driverId ? (
              <>
                <Typography>Driver ID: {ctx.driverId}</Typography>
              </>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
