import React from 'react';

export default function useGoogle() {
  const [placesService, setPlacesService] = React.useState(null);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (window?.google?.maps?.places) {
        setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
        clearInterval(intervalId);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  return placesService;
}