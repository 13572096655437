//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Chip, Typography, IconButton, Icon, Tooltip, Menu, MenuItem } from '@material-ui/core';
import { useSettings } from '../providers/SettingsProvider';
import { usePlans } from '../providers/PlansProvider';

//////////////////////// COMPONENT ////////////////////////

export default function TimelineIndexHead(props) {
  const cls = useStyles();

  const { setTimelineScale } = useSettings();
  const [scaleSliderRef, setScaleSliderRef] = React.useState(null);
  const [totalMovesCount, setTotalMovesCount] = React.useState(0);
  const [totalCompletedMovesCount, setTotalCompletedMovesCount] = React.useState(0);

  const { plans } = usePlans();

  React.useEffect(() => {
    let _movesCount = 0;
    let _completedMovesCount = 0;
    plans.forEach(p => {
      _movesCount += p.moves.filter(
        m =>
          m.move_type === `drive` &&
          (!m.cancel_status || m.cancel_status === `started` || m.cancel_status === `delivered`)
      ).length;
      _completedMovesCount += p.moves.filter(
        m =>
          m.move_type === `drive` &&
          (m.status === `delivery successful` || m.cancel_status === `started` || m.cancel_status === `delivered`)
      ).length;
    });
    setTotalMovesCount(_movesCount);
    setTotalCompletedMovesCount(_completedMovesCount);
  }, [plans]);

  const handleSliderRef = event => {
    const value = event.currentTarget;
    if (value) setScaleSliderRef(value);
    else setScaleSliderRef(null);
  };

  const handleAction = action => {
    setScaleSliderRef(null);
    if (action.handler) action.handler();
  };

  const actions = [
    {
      label: `Scale x0.5`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 0.5);
        setTimelineScale(0.5);
      },
    },
    {
      label: `Scale x1.0`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 1);
        setTimelineScale(1);
      },
    },
    {
      label: `Scale x1.5`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 1.5);
        setTimelineScale(1.5);
      },
    },
    {
      label: `Scale x2.0`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 2);
        setTimelineScale(2);
      },
    },
    {
      label: `Scale x2.5`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 2.5);
        setTimelineScale(2.5);
      },
    },
    {
      label: `Scale x3.0`,
      handler: () => {
        localStorage.setItem(`timeline-scale`, 3);
        setTimelineScale(3);
      },
    },
  ];

  return (
    <div className={cls.indexHead}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <Typography className={cls.indexHeadTxt}>Plans</Typography>
        </Grid>
        <Grid item xs>
          <Tooltip title='Total Completed Moves (Chargeable and Non-Chargeable) / Total Moves'>
            <Chip
              data-testid='completed-moves-count'
              classes={{ root: cls.defaultChip, label: cls.chipLabel }}
              label={`${totalCompletedMovesCount} / ${totalMovesCount}`}
              size='small'
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Select Timeline Zoom'>
            <IconButton className={cls.iconBtn} onClick={handleSliderRef}>
              <Icon>zoom_in</Icon>
            </IconButton>
          </Tooltip>

          <Menu
            keepMounted
            id={`timeline-scale-menu`}
            anchorEl={scaleSliderRef}
            open={Boolean(scaleSliderRef)}
            onClose={() => setScaleSliderRef(null)}
          >
            {actions.map((action, i) =>
              !action.hide ? (
                <MenuItem key={`move-action-${i}`} onClick={() => handleAction(action)}>
                  {action.label || `Action ${i + 1}`}
                </MenuItem>
              ) : null
            )}
          </Menu>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  indexHead: {
    zIndex: 112,
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    width: '100%',
    minHeight: 48,
    maxHeight: 48,
    padding: theme.spacing(0, 2),
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  indexHeadTxt: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 600,
  },
  iconBtn: {
    padding: theme.spacing(0.5),
  },
  defaultChip: {
    height: 18,
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
  },
  chipLabel: {
    fontSize: 12,
    fontWeight: 500,
  },
}));
