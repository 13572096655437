//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, TextField, InputAdornment, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  minFuelSpend: `config.fuel.min_fuel_price`,
  maxFuelSpend: `config.fuel.max_fuel_price`,
  fuelLevel: `config.fuel.fuel_level`,
};

// DEFAULTS //
const getDefaultMinFuelSpend = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.minFuelSpend) || getPropValue(inheritedRef, option?.minFuelSpend);
};
const getDefaultMaxFuelSpend = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.maxFuelSpend) || getPropValue(inheritedRef, option?.maxFuelSpend);
};
const getDefaultFuelLevel = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.fuelLevel) || getPropValue(inheritedRef, option?.fuelLevel);
};

//////////////////////// COMPONENT ////////////////////////

export default function Fuel({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [minFuelSpend, setMinFuelSpend] = React.useState(getDefaultMinFuelSpend(overrideRef, inheritedRef));
  const [maxFuelSpend, setMaxFuelSpend] = React.useState(getDefaultMaxFuelSpend(overrideRef, inheritedRef));
  const [fuelLevelDecimal, setFuelLevelDecimal] = React.useState(getDefaultFuelLevel(overrideRef, inheritedRef));
  const [fuelLevelPercent, setFuelLevelPercent] = React.useState(0);

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setMinFuelSpend(getDefaultMinFuelSpend(null, inheritedRef));
      setMaxFuelSpend(getDefaultMaxFuelSpend(null, inheritedRef));
      setFuelLevelDecimal(getDefaultFuelLevel(null, inheritedRef));
    }
  }, [isReset]);

  // Handle min price change
  const handleMinFuelSpendChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { fuel: { min_fuel_price: Number(value) } } };
    setMinFuelSpend(value);
    updateMutableRef(changes);
  };

  // Handle max price change
  const handleMaxFuelSpendPriceChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { fuel: { max_fuel_price: Number(value) } } };
    setMaxFuelSpend(value);
    updateMutableRef(changes);
  };

  // Handle fuel level change
  const handleFuelLevelChange = e => {
    // Get the value
    const value = e?.target?.value;

    const newDecimal = value / 100;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { fuel: { fuel_level: Number(newDecimal) } } };
    setFuelLevelDecimal(newDecimal);
    updateMutableRef(changes);
  };

  React.useEffect(() => {
    setFuelLevelPercent(fuelLevelDecimal * 100);
  }, [fuelLevelDecimal]);

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Fuel`}
        tip={`The fuel module allows us to adjust fuel defaults for a client based on their contract. PLEASE be careful and triple check your inputs before submitting changes to any of these options as they can directly affect client pricing.`}
      />

      <SettingsOption
        contained
        title={`Minimum Fuel Spend`}
        description={`Minimum amount of fuel to purchase (in dollars). Fuel amounts below this value will not be authorized by our system.`}
        value={minFuelSpend}
        checkReset={() => checkMutableRefField(option?.minFuelSpend)}
        onReset={() =>
          deleteMutableRefField(option?.minFuelSpend, () =>
            setMinFuelSpend(getPropValue(inheritedRef, option?.minFuelSpend))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Min Fuel Spend'
          placeholder='Enter a minimum fuel amount...'
          size='small'
          variant='outlined'
          value={minFuelSpend}
          onChange={handleMinFuelSpendChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  attach_money
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '0.1',
            min: '0',
            max: '100',
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Maximum Fuel Spend`}
        description={`The maximum amount amount of fuel to pump when refueling is required (in dollars).`}
        value={maxFuelSpend}
        checkReset={() => checkMutableRefField(option?.maxFuelSpend)}
        onReset={() =>
          deleteMutableRefField(option?.maxFuelSpend, () =>
            setMaxFuelSpend(getPropValue(inheritedRef, option?.maxFuelSpend))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Max Fuel Spend'
          placeholder='Enter a maximum fuel amount...'
          size='small'
          variant='outlined'
          value={maxFuelSpend}
          onChange={handleMaxFuelSpendPriceChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  attach_money
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '0.1',
            min: '0',
            max: '100',
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Fuel Level`}
        description={`The expected fuel level at vehicle delivery (in percent).`}
        value={fuelLevelPercent}
        checkReset={() => checkMutableRefField(option?.fuelLevel)}
        onReset={() =>
          deleteMutableRefField(option?.fuelLevel, () =>
            setFuelLevelDecimal(getPropValue(inheritedRef, option?.fuelLevel))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='number'
          label='Fuel Level'
          placeholder='Enter a fuel level...'
          size='small'
          variant='outlined'
          value={fuelLevelPercent}
          onChange={handleFuelLevelChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  percent
                </Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            step: '1',
            min: '0',
            max: '100',
          }}
        />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
}));
