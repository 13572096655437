import React, { useState, useEffect } from 'react';
import { useData } from '../providers/DataProvider';
import gql from 'graphql-tag';

import { makeStyles, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from './ModalComponents';
import { toast } from 'react-toastify';
import CustomerSelect from './CustomerSelect';

const log = false;

////////// COMPONENT //////////
export default function LocationAddEditModal(props) {
  const ctx = useData();
  const cls = useStyles();

  const { open, onClose, locationInput, showCustomerSelect = false, customerIdDefault = null } = props;

  const [customerId, setCustomerId] = useState(customerIdDefault || ``);
  const [type, setType] = useState(`customer`);
  const [name, setName] = useState(``);
  const [address, setAddress] = useState(``);
  const [nickname, setNickname] = useState(``);
  const [email, setEmail] = useState(``);
  const [phone, setPhone] = useState(``);
  const [timezone, setTimezone] = useState(``);

  useEffect(() => {
    if (locationInput) {
      setCustomerId(locationInput.customer_id || '');
      setType(locationInput.type || `customer`);
      setName(locationInput.name || ``);
      setAddress(locationInput.address || ``);
      setNickname(locationInput.nickname || ``);
      setEmail(locationInput.email || ``);
      setPhone(locationInput.phone || ``);
      setTimezone(locationInput.timezone || ``);
    }
  }, [locationInput]);

  const handleInputChange = setHandler => event => {
    if (setHandler && event) setHandler(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const variables = {
        customerId: customerId,
        regionId: await getRegionId(),
        placeId: locationInput.place_id,
        type: type,
        name: name,
        nickname: nickname,
        address: address,
        latitude: locationInput.latitude,
        longitude: locationInput.longitude,
        email: email,
        phone: phone,
        timezone: timezone,
      };
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_LOCATION,
        variables: variables,
      });
      if (res) {
        const newLoc = res.data.insert_locations.returning[0];
        log && console.log(`>> Successfully created location:`, newLoc);
        toast.success(`Successfully created location.`);

        handleClose(newLoc);
      }
    } catch (err) {
      console.log(`<< Failed to create location:`, err);
      toast.error(`Failed to create location: ${err}`);
    }
  };
  const handleClose = (newLoc = null) => {
    if (onClose) onClose(newLoc);
    setType(`customer`);
    setName(``);
    setAddress(``);
    setNickname(``);
    setEmail(``);
    setPhone(``);
    setTimezone(``);
  };

  const getRegionId = async () => {
    var regionId;
    // Used to get region_id from Latitude & Longitude
    let regionRes = await ctx._sdk.regions.getByCoords([locationInput.longitude, locationInput.latitude]);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      regionId = regionRes.data[0].id;
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      regionId = null;
    }
    return regionId;
  };

  const handleCustomerChange = event => {
    if (event.target.value > 0) {
      setCustomerId(event.target.value);
    } else {
      setCustomerId(null);
    }
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Create & Set Location</ModalHeader>

        <ModalContent
          subtitle={`Fill out the form to create and store this location for future use. You may edit the type & name, but the address is updated by Google. You may also provide an optional nickname, email and phone number associated with it. Verify the address is correct before continuing and refrain from duplicating existing\xa0locations.`}
        >
          <Typography className={cls.title}>Required Fields</Typography>

          {/* 02/04/2020
            The below code allowed the dealer to set the type of location.
            We are taking this out to make the location always default to a standard 'customer' type.
            There is code in the move planner to update a location after move creation if it is associated with a consumer address.  
            
            06/30/2021
            Adding it back in for use in Admin
          */}

          <div className={cls.break} />
          {showCustomerSelect ? <CustomerSelect value={customerId} onChange={handleCustomerChange} /> : null}
          <div className={cls.break} />

          <TextField
            select
            required
            fullWidth
            label='Type'
            placeholder='Specify a location type...'
            value={type}
            onChange={handleInputChange(setType)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label_important
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={'customer'}>Standard</MenuItem>
            <MenuItem value={'consumer business'}>Consumer Business</MenuItem>
            <MenuItem value={'consumer residential'}>Consumer Residential</MenuItem>
          </TextField>

          <div className={cls.break} />

          <TextField
            required
            fullWidth
            label='Name'
            placeholder='Enter a name for the location...'
            value={name}
            onChange={handleInputChange(setName)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    local_offer
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            //Allow users to edit address field
            // disabled
            required
            fullWidth
            multiline
            label='Address'
            placeholder='Enter an address for the location...'
            value={address}
            onChange={handleInputChange(setAddress)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    room
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            required
            fullWidth
            label='Timezone'
            placeholder='Enter a timezone code for the location...'
            value={timezone}
            onChange={handleInputChange(setTimezone)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    access_time_filled
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.bigBreak} />

          <Typography className={cls.title}>Optional Fields</Typography>

          <div className={cls.break} />

          <TextField
            fullWidth
            label='Nickname'
            placeholder='(Optional) Enter a nickname for the location...'
            value={nickname}
            onChange={handleInputChange(setNickname)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    loyalty
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type='email'
            label='Email'
            placeholder='(Optional) Enter a related email...'
            value={email}
            onChange={handleInputChange(setEmail)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type='phone'
            label='Phone'
            placeholder='(Optional) Enter a related phone number...'
            value={phone}
            onChange={handleInputChange(setPhone)}
            margin='dense'
            variant='outlined'
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    phone_iphone
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()} disabled={name.length < 1 || address.length < 1}>
            Submit
          </ModalAction>
          <ModalAction onClick={() => handleClose()} color='secondary'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '21px',
    fontWeight: 500,
  },
  input: {
    margin: 0,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

////////// GRAPHQL //////////
const INSERT_LOCATION = gql`
  mutation insert_location(
    $customerId: bigint!
    $regionId: bigint
    $placeId: String
    $type: String!
    $name: String!
    $nickname: String
    $address: String!
    $latitude: numeric!
    $longitude: numeric!
    $email: String
    $phone: String
    $timezone: String
  ) {
    insert_locations(
      objects: {
        active: 1
        customer_id: $customerId
        region_id: $regionId
        place_id: $placeId
        type: $type
        name: $name
        nickname: $nickname
        address: $address
        latitude: $latitude
        longitude: $longitude
        email: $email
        phone: $phone
        timezone: $timezone
      }
      on_conflict: {
        constraint: locations_customer_id_address_key
        update_columns: [name, nickname, email, phone, timezone, type]
      }
    ) {
      affected_rows
      returning {
        id
        active
        address
        createdat
        customer_id
        customer {
          id
          active
          address
          branded
          config
          email
          id
          name
          phone
          createdat
          updatedat
          billing_frequency
          payment_terms
          auto_pay
          accounting_id
          notify_billing
        }
        email
        favorite
        latitude
        longitude
        name
        nickname
        phone
        notes
        place_id
        region_id
        region {
          id
          team_id
          name
          description
          geofence
          createdat
          updatedat
          last_synced
          region_id
          accounting_class_num
        }
        timezone
        tookan_id
        type
        updatedat
      }
    }
  }
`;
