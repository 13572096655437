import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useTookan } from '../../hooks/useTookan';

//Based this code on: https://blog.andrewmmc.com/fetch-data-with-loading-and-error-state-in-react-hooks-a341706a6ffe
const useTookanDrivers = (team_id = null) => {
  const { getTookanDrivers } = useTookan();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    let ignore = false;
    const fetchProduct = async () => {
      setLoading(true);
      try {
        setError(null);
        const drivers = await getTookanDrivers(null, team_id);
        if (!ignore) setData(drivers);
      } catch (err) {
        toast.error(`Failed to fetch Driver Data from Tookan`);
        console.error(`useTookanDrivers:`, err);
        setError(err);
      }
      setLoading(false);
    };
    fetchProduct();
    return () => {
      ignore = true;
    };
  }, [team_id]);

  return { loading, error, data };
};

export default useTookanDrivers;
