import React from 'react';
import {
  useTheme,
  makeStyles,
  TableContainer,
  Table,
  TablePagination,
  Checkbox,
  Typography,
  Chip,
  Icon,
  Tooltip,
} from '@material-ui/core';

import { useTools } from '../../hooks/useTools';
import { useDriverPay } from './useDriverPay';

import DriverPayTableHead from './DriverPayTableHead';
import DriverPayTableBody from './DriverPayTableBody';

const defaultOrder = `asc`;
const defaultOrderBy = `driver`;

function arrayContainsAll(needle, haystack) {
  for (let i = 0; i < needle.length; i++) {
    if (haystack.indexOf(needle[i]) === -1) {
      return false;
    }
  }
  return true;
}

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayTable({
  regionId,
  taxClass,
  search,
  driverPayArr,
  checkedRows,
  setCheckedRows,
  setScreenTotal,
}) {
  const theme = useTheme();
  const cls = useStyles();

  const { getInitialsFromName, formatUSD } = useTools();
  const { getTotalFromDriverPay } = useDriverPay();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [filteredDriverPay, setFilteredDriverPay] = React.useState([]);

  React.useEffect(() => {
    if (filteredDriverPay && filteredDriverPay.length) {
      const filteredTotal = getTotalFromDriverPay(filteredDriverPay);
      setScreenTotal(filteredTotal);
    } else {
      setScreenTotal(0);
    }
  }, [filteredDriverPay]);

  React.useEffect(() => {
    let newDriverPay = [...driverPayArr];

    if (regionId) newDriverPay = newDriverPay.filter(n => n.region_ids.includes(regionId));

    if (taxClass && taxClass !== `both`) newDriverPay = newDriverPay.filter(n => n.tax_class === taxClass);

    if (search)
      newDriverPay = newDriverPay.filter(
        n =>
          (n.driver_id && `${n.driver_id}`.includes(search)) ||
          (n.driver_name && n.driver_name.toLocaleLowerCase().includes(search))
      );

    setFilteredDriverPay(newDriverPay);
  }, [driverPayArr, regionId, taxClass, search]);

  // See if the row is checked based on ID found in the checkedRows array
  const isItemChecked = id => checkedRows.indexOf(id) !== -1;

  // Handle the row checkbox when clicked
  const handleCheckOne = (event, name) => {
    event.stopPropagation();
    const checkedIndex = checkedRows.indexOf(name);
    let newCheckedArr = [];

    if (checkedIndex === -1) {
      newCheckedArr = newCheckedArr.concat(checkedRows, name);
    } else if (checkedIndex === 0) {
      newCheckedArr = newCheckedArr.concat(checkedRows.slice(1));
    } else if (checkedIndex === checkedRows.length - 1) {
      newCheckedArr = newCheckedArr.concat(checkedRows.slice(0, -1));
    } else if (checkedIndex > 0) {
      newCheckedArr = newCheckedArr.concat(checkedRows.slice(0, checkedIndex), checkedRows.slice(checkedIndex + 1));
    }

    // Remove falsey values
    newCheckedArr = newCheckedArr.filter(nca => nca);

    // Create new set to remove dupes
    setCheckedRows([...new Set(newCheckedArr)]);
  };

  // Handle the header checkbox when clicked
  const handleCheckAll = event => {
    // Only check the filtered driver pay and get the IDs
    let newCheckedArr = filteredDriverPay.map(dp => dp.driver_id);

    // Remove falsey values
    newCheckedArr = newCheckedArr.filter(nca => nca);

    // Check/Uncheck all based on items that are currently checked
    if (checkedRows.length) setCheckedRows([]);
    else setCheckedRows([...new Set(newCheckedArr)]);
  };

  // Header checkbox component that checks/unchecks all unpaid driverPay rows if clicked. If all driverPay rows are paid, it is disabled.
  const HeaderCheckbox = () => {
    const driverPayIds = [...new Set(filteredDriverPay.map(dp => dp.driver_id))];
    return (
      <Checkbox
        className={cls.checkbox}
        color='primary'
        onClick={event => handleCheckAll(event)}
        checked={arrayContainsAll(driverPayIds, checkedRows)}
      />
    );
  };

  // Handle page state
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle pagination rows state
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Table Headers
  const headers = [
    { id: `checkbox`, align: `left`, label: `Checkbox`, component: HeaderCheckbox() },
    { id: `avatar`, align: `left`, label: `Avatar` },
    { id: `driver`, align: `left`, label: `Driver` },
    { id: `direct_deposit`, align: `left`, label: `DD` },
    { id: `tax_class`, align: `left`, label: `Tax\xa0Class` },
    { id: `drive_move_count`, align: `left`, label: `Drive\xa0Count` },
    { id: `ride_move_count`, align: `left`, label: `Ride\xa0Count` },
    { id: `accessorial_count`, align: `left`, label: `Accessorial\xa0Count` },
    { id: `issues`, align: `left`, label: `Issues` },
    { id: `total`, align: `right`, label: `Total` },
  ];

  // Table Rows
  const rows = filteredDriverPay.map((dp, i) => {
    const canceledPayouts = [];
    const failedPayouts = [];
    dp.driverpayouts.forEach(dpo => {
      if (dpo.status && dpo.status === `canceled`) canceledPayouts.push(dpo);
      if (dpo.status && dpo.status === `failed`) failedPayouts.push(dpo);
    });

    return {
      checkbox: (
        <Checkbox
          className={cls.checkbox}
          color='primary'
          onClick={event => handleCheckOne(event, dp.driver_id)}
          checked={isItemChecked(dp.driver_id)}
          disabled={!dp.driver_id || dp.status === `paid`}
          indeterminate={!dp.driver_id || dp.status === `paid`}
        />
      ),
      avatar: dp.driver_avatar_url ? (
        <div className={cls.avatar}>
          <img className={cls.avatarImg} src={dp.driver_avatar_url} alt='avatar' />
        </div>
      ) : dp.driver_name ? (
        <div className={cls.avatar}>
          <div className={cls.avatarImg}>
            <Typography className={cls.initialsTxt}>
              {getInitialsFromName({ driver_name: dp.driver_name || null })}
            </Typography>
          </div>
        </div>
      ) : null,
      driver: `${dp.driver_name} (${dp.driver_id})`,
      direct_deposit: dp.driver_bank_account_token ? (
        <Tooltip title='Direct deposit ready'>
          <Icon style={{ display: `block`, color: theme.palette.success.main }}>check_circle</Icon>
        </Tooltip>
      ) : (
        <Tooltip title='No direct deposit available'>
          <Icon style={{ display: `block`, color: theme.palette.text.disabled }}>remove_circle_outline</Icon>
        </Tooltip>
      ),
      tax_class: (
        <Chip
          label={dp.tax_class}
          color={dp.tax_class === `1099` ? `primary` : `secondary`}
          variant='outlined'
          size='small'
        />
      ),
      drive_move_count: dp.drive_move_count === 1 ? `1 Drive` : `${dp.drive_move_count || 0} Drives`,
      ride_move_count: dp.ride_move_count === 1 ? `1 Ride` : `${dp.ride_move_count || 0} Rides`,
      accessorial_count: dp.accessorial_count === 1 ? `1 Accessorial` : `${dp.accessorial_count || 0} Accessorials`,
      issues: (
        <>
          {canceledPayouts.length
            ? canceledPayouts.map(cpo => (
                <Chip
                  size='small'
                  label={`Contains ${canceledPayouts.length} canceled payout${canceledPayouts.length !== 1 ? `s` : ``}`}
                  classes={{
                    root: cls.chipCanceled,
                  }}
                />
              ))
            : null}
          {failedPayouts.length
            ? failedPayouts.map(fpo => (
                <Chip
                  size='small'
                  label={`Contains ${failedPayouts.length} failed payout${failedPayouts.length !== 1 ? `s` : ``}`}
                  classes={{
                    root: cls.chipFailed,
                  }}
                />
              ))
            : null}
          {!canceledPayouts.length && !failedPayouts.length ? `-` : null}
        </>
      ),
      total: (
        <Chip
          label={formatUSD(dp.total, { removeSign: true })}
          icon={<Icon>attach_money</Icon>}
          classes={{
            label: cls.chipLabel,
          }}
        />
      ),

      // Addtional fields
      driverPay: dp,
      index: i,
      foldId: `${dp.driver_id || 0}-${i}`,
    };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='driver-pay-table'>
          <DriverPayTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <DriverPayTableBody rows={rows} order={order} orderBy={orderBy} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },

  checkbox: {
    padding: theme.spacing(0.25),
  },

  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },

  rowTxt: {
    display: 'inline',
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    fontSize: 21,
    color: '#inherit',
    cursor: 'pointer',
  },

  chipLabel: {
    marginTop: 2,
    marginLeft: -4,
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  chipCanceled: {
    background: theme.palette.default.lighter,
    color: theme.palette.text.contrast,
  },
  chipFailed: {
    background: theme.palette.error.main,
    color: theme.palette.text.contrast,
  },
}));
