//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { getUserToken } from '../../../utils/authHelper';

import {
  useTheme,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  active: `config.branding.active`,
  displayName: `config.branding.name`,
  primaryColor: `config.branding.primary_color`,
  secondaryColor: `config.branding.secondary_color`,
  backdropColor: `config.branding.backdrop_color`,
  logoUrl: `config.branding.logo_url`,
};

// DEFAULTS //
const getDefaultActive = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.active) === false) return false;
  if (getPropValue(overrideRef, option?.active) === true) return true;
  return getPropValue(inheritedRef, option?.active);
};
const getDefaultDisplayName = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.displayName) || getPropValue(inheritedRef, option?.displayName);
};
const getDefaultPrimaryColor = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.primaryColor) || getPropValue(inheritedRef, option?.primaryColor);
};
const getDefaultSecondaryColor = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.secondaryColor) || getPropValue(inheritedRef, option?.secondaryColor);
};
const getDefaultBackdropColor = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.backdropColor) || getPropValue(inheritedRef, option?.backdropColor);
};
const getDefaultLogoUrl = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.logoUrl) || getPropValue(inheritedRef, option?.logoUrl);
};

// HELPERS //
const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function Branding({
  clientType = 'customer',
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const theme = useTheme();
  const cls = useStyles();

  // Manage state of options
  const [active, setActive] = React.useState(getDefaultActive(overrideRef, inheritedRef));
  const [displayName, setDisplayName] = React.useState(getDefaultDisplayName(overrideRef, inheritedRef));
  const [primaryColor, setPrimaryColor] = React.useState(getDefaultPrimaryColor(overrideRef, inheritedRef));
  const [secondaryColor, setSecondaryColor] = React.useState(getDefaultSecondaryColor(overrideRef, inheritedRef));
  const [backdropColor, setBackdropColor] = React.useState(getDefaultBackdropColor(overrideRef, inheritedRef));
  const [logoUrl, setLogoUrl] = React.useState(getDefaultLogoUrl(overrideRef, inheritedRef));

  // Manage state of the file upload box
  const [dropzoneDragover, setDropzoneDragover] = React.useState(false);
  const [dropzoneError, setDropzoneError] = React.useState(false);
  const [dropzoneFile, setDropzoneFile] = React.useState(null);

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setActive(getDefaultActive(null, inheritedRef));
      setDisplayName(getDefaultDisplayName(null, inheritedRef));
      setPrimaryColor(getDefaultPrimaryColor(null, inheritedRef));
      setSecondaryColor(getDefaultSecondaryColor(null, inheritedRef));
      setBackdropColor(getDefaultBackdropColor(null, inheritedRef));
      setLogoUrl(getDefaultLogoUrl(null, inheritedRef));
    }
  }, [isReset]);

  // Handle activation change
  const handleActivationChange = () => {
    // Get the value
    const value = !active;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { active: value } } };
    setActive(value);
    updateMutableRef(changes);
  };

  // Handle display name change
  const handleDisplayNameChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { name: value } } };
    setDisplayName(value);
    updateMutableRef(changes);
  };

  // Handle primary color change
  const handlePrimaryColorChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { primary_color: value } } };
    setPrimaryColor(value);
    updateMutableRef(changes);
  };

  // Handle secondary color change
  const handleSecondaryColorChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { secondary_color: value } } };
    setSecondaryColor(value);
    updateMutableRef(changes);
  };

  // Handle backdrop color change
  const handleBackdropColorChange = e => {
    // Get the value
    const value = e?.target?.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { backdrop_color: value } } };
    setBackdropColor(value);
    updateMutableRef(changes);
  };

  // Handle logo url change
  const handleLogoUrlChange = e => {
    // Get the value
    const value = e;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { branding: { logo_url: value } } };
    setLogoUrl(value);
    updateMutableRef(changes);
  };

  /** Refresh the image from the server (requires a timestamp to be set on the src) */
  const refreshImageFromServer = logoUrl => {
    if (logoUrl) {
      const timestamp = new Date().getTime();
      const queryString = '?t=' + timestamp;
      let element = document.getElementById('branding-logo-output');
      element.src = logoUrl + queryString;
    }
  };

  /** Click upload handler */
  const handleFileClick = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    const file = e?.target?.files[0];
    log && console.log(`Handle click upload:`, file);

    // Upload the file
    await handleLogoUpload(e, file);
  };

  /** Drag & drop upload handler */
  const handleFileDragAndDrop = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    let file = e.dataTransfer.files[0];
    log && console.log(`Handle drag & drop upload:`, file);

    // Upload the file
    await handleLogoUpload(e, file);
  };

  /** Handle dragging over the file upload component */
  const handleFileDragoverEnter = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(true);
    setDropzoneError(false);
  };

  /** Handle dragging off the file upload component */
  const handleFileDragoverExit = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);
  };

  /** Handle uploading the file */
  const handleLogoUpload = async (e, file) => {
    // Check if the file is valid
    if (!file) {
      console.error(`Failed to upload logo: No file provided!`);
      toast.error(`Failed to upload logo: No file provided!`);
      setDropzoneError(true);
      return;
    }
    if (file?.size > 500000) {
      console.error(`Failed to upload logo: File size must be less than 0.5MB/500KB!`);
      toast.error(`Failed to upload logo: File size must be less than 0.5MB/500KB!`);
      setDropzoneError(true);
      return;
    }
    if (file?.type !== 'image/png' && file?.type !== 'image/svg+xml') {
      console.error(`Failed to upload logo: File type not supported!`);
      toast.error(`Failed to upload logo: File type not supported!`);
      setDropzoneError(true);
      return;
    }

    // Set the image element src
    const imgSrc = URL.createObjectURL(file);
    const imgElement = document.getElementById('branding-logo-output');
    imgElement.src = imgSrc;

    // Set the dropzone state
    setDropzoneFile(file);

    // Get the auth token
    const token = await getUserToken();

    // Upload the file
    let reader = new FileReader();
    reader.addEventListener('loadend', async e => {
      try {
        const res = await axios({
          url: '/.netlify/functions/requestUploadUrl',
          method: 'POST',
          data: {
            fileName: `${clientType}/${inheritedRef?.id}/${clientType}-logo-${inheritedRef?.id}`,
            fileType: file?.type,
            bucketName: 'hopdrive-branding-logos',
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const { uploadURL } = res?.data;
        const uploadRes = await axios.put(uploadURL, file, { headers: { 'Content-Type': file?.type || `image/*` } });

        if (uploadRes.status >= 200 && uploadRes.status < 400) {
          const logoUrl = uploadURL.split('?')[0];
          handleLogoUrlChange(logoUrl);
          refreshImageFromServer(logoUrl);
          log && console.log(`Successfully uploaded the logo to S3!`);
        } else {
          console.error(`Failed to upload the logo to S3!`);
          toast.error(`Failed to upload the logo to S3!`);
        }
      } catch (err) {
        console.error(`Failed to upload the logo to S3:`, err);
        toast.error(`Failed to upload the logo to S3!`);
      }
    });
    reader.readAsArrayBuffer(file);
  };

  /** Handle clearing out the logo */
  const handleLogoClear = async () => {
    setDropzoneDragover(false);
    setDropzoneError(false);
    setDropzoneFile(null);
    setLogoUrl(null);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Branding`}
        tip={`The branding module is a way for us to white-label our clients. White-labeling is a way to display our clients in a unique way compared to the HopDrive default branding.`}
      />

      <SettingsOption
        contained
        title={`Activation`}
        description={`Activate/Deactivate the branding feature for this client. Branded clients will be visible to their customers not as HopDrive, but as the client's own brand.`}
        value={active}
        checkReset={() => checkMutableRefField(option?.active)}
        onReset={() =>
          deleteMutableRefField(option?.active, () => setActive(getPropValue(inheritedRef, option?.active)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={active} onChange={() => handleActivationChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Display Name`}
        description={`Name to be displayed to the client's customers.`}
        value={displayName}
        checkReset={() => checkMutableRefField(option?.displayName)}
        onReset={() =>
          deleteMutableRefField(option?.displayName, () =>
            setDisplayName(getPropValue(inheritedRef, option?.displayName))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          label='Display Name'
          placeholder='Enter a display name...'
          size='small'
          variant='outlined'
          value={displayName}
          onChange={handleDisplayNameChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  label
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Primary Color`}
        description={`Primary color is the main color used for branding the client.`}
        value={primaryColor}
        checkReset={() => checkMutableRefField(option?.primaryColor)}
        onReset={() =>
          deleteMutableRefField(option?.primaryColor, () =>
            setPrimaryColor(getPropValue(inheritedRef, option?.primaryColor))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='color'
          label='Primary Color'
          placeholder='Select a primary color...'
          size='small'
          variant='outlined'
          value={primaryColor}
          onChange={handlePrimaryColorChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  color_lens
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Secondary Color`}
        description={`Secondary color is the accent color used for branding the client.`}
        value={secondaryColor}
        checkReset={() => checkMutableRefField(option?.secondaryColor)}
        onReset={() =>
          deleteMutableRefField(option?.secondaryColor, () =>
            setSecondaryColor(getPropValue(inheritedRef, option?.secondaryColor))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='color'
          label='Secondary Color'
          placeholder='Select a secondary color...'
          size='small'
          variant='outlined'
          value={secondaryColor}
          onChange={handleSecondaryColorChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  color_lens
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Backdrop Color`}
        description={`Backdrop color is used as the background behind the logo (usually a muted or neutral color, depending on the color of the logo).`}
        value={backdropColor}
        checkReset={() => checkMutableRefField(option?.backdropColor)}
        onReset={() =>
          deleteMutableRefField(option?.backdropColor, () =>
            setBackdropColor(getPropValue(inheritedRef, option?.backdropColor))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          fullWidth
          type='color'
          label='Backdrop Color'
          placeholder='Select a backdrop color...'
          size='small'
          variant='outlined'
          value={backdropColor}
          onChange={handleBackdropColorChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  color_lens
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Logo`}
        description={`The logo is a link to a PNG or SVG file to give this client some imagery to go along with their name & colors. Due to server limitations, the maximum file size is 0.5MB/500KB. Please also make sure the image dimensions are at least 40px tall as anything lower will be too low of a resolution. NOTE: This option may be expanded upon in the future to allow for different types of logos meant for different mediums.`}
        value={logoUrl}
        checkReset={() => checkMutableRefField(option?.logoUrl)}
        onReset={() =>
          deleteMutableRefField(option?.logoUrl, () => setLogoUrl(getPropValue(inheritedRef, option?.logoUrl)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        {!logoUrl && !dropzoneFile ? (
          <label
            className={clsx(cls.dropzone, {
              [cls.dropzoneDragover]: dropzoneDragover,
              [cls.dropzoneError]: dropzoneError,
            })}
            onDrop={handleFileDragAndDrop}
            onDragOver={handleFileDragoverEnter}
            onDragLeave={handleFileDragoverExit}
          >
            <Typography className={cls.dropzoneTxt}>Click or drag PNG/SVG file here</Typography>
            <Typography className={cls.dropzoneAltLTxt}>Max 0.5MB/500KB</Typography>
            <Typography className={cls.dropzoneAltRTxt}>Min height 40px</Typography>
            <input
              className={cls.hide}
              id='branding-logo-uploader'
              type='file'
              accept='image/png, image/svg+xml'
              onChange={handleFileClick}
            />
          </label>
        ) : null}

        <div className={cls.logo}>
          <div className={clsx(cls.logoBox, { [cls.hide]: !logoUrl && !dropzoneFile })}>
            <img
              className={clsx(cls.logoImg, { [cls.hide]: !logoUrl && !dropzoneFile })}
              id='branding-logo-output'
              alt='branding-logo-output'
              src={logoUrl ? `${logoUrl}?t=${new Date().getTime()}` : null}
            />
          </div>

          <Spacer column size={1} />

          <Tooltip title='Remove logo' placement='top'>
            <IconButton
              className={clsx(cls.logoIconBtn, { [cls.hide]: !logoUrl && !dropzoneFile })}
              onClick={handleLogoClear}
            >
              <Icon>cancel_circle</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </SettingsOption>

      <Spacer />

      <Typography className={cls.previewTitleTxt}>Branding Preview</Typography>

      <Spacer size={1} />

      <div className={cls.preview}>
        <div className={cls.previewBar} style={{ backgroundColor: backdropColor || theme.palette.text.primary }}>
          {logoUrl || dropzoneFile ? (
            <div className={cls.previewImageBox}>
              <img
                className={cls.previewImage}
                id='branding-logo-preview'
                alt='branding-logo-preview'
                src={`${logoUrl}?t=${new Date().getTime()}`}
              />
            </div>
          ) : null}
        </div>

        <div className={cls.previewBox}>
          <div className={cls.previewSection}>
            <div className={cls.previewDefaultBox}>
              <Typography>Default Color</Typography>
            </div>

            <Spacer column />

            <div
              className={cls.previewPrimaryBox}
              style={{ backgroundColor: primaryColor || theme.palette.primary.main }}
            >
              <Typography>Primary Color</Typography>
            </div>

            <Spacer column />

            <div
              className={cls.previewSecondaryBox}
              style={{ backgroundColor: secondaryColor || theme.palette.secondary.main }}
            >
              <Typography>Secondary Color</Typography>
            </div>
          </div>

          <Spacer />

          <div className={cls.previewSection}>
            <Typography className={cls.previewDefaultTxt}>Default Text</Typography>

            <Spacer column />

            <Typography className={cls.previewPrimaryTxt} style={{ color: primaryColor || theme.palette.primary.main }}>
              Primary Text
            </Typography>

            <Spacer column />

            <Typography
              className={cls.previewSecondaryTxt}
              style={{ color: secondaryColor || theme.palette.secondary.main }}
            >
              Secondary Text
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},

  dropzone: {
    position: 'relative',
    display: 'block',
    width: 256,
    height: 128,
    padding: theme.spacing(2),
    border: theme.border[1],
    borderStyle: 'dashed',
    borderRadius: theme.shape.paperRadius,
    cursor: 'pointer',
    transition: `0.15s all ease-in-out`,
  },
  dropzoneDragover: {
    border: theme.border[1],
    borderColor: theme.palette.text.primary,
    '& $dropzoneTxt': {
      color: theme.palette.text.primary,
    },
  },
  dropzoneError: {
    border: theme.border[1],
    borderColor: theme.palette.error.main,
    '& $dropzoneTxt': {
      color: theme.palette.error.main,
    },
  },

  dropzoneTxt: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: `0.15s all ease-in-out`,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dropzoneAltLTxt: {
    position: 'absolute',
    bottom: 4,
    left: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dropzoneAltRTxt: {
    position: 'absolute',
    bottom: 4,
    right: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'right',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },

  logo: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
  },
  logoBox: {
    position: 'relative',
    width: 260,
    padding: theme.spacing(1),
    backgroundColor: '#f4f4f4',
    backgroundImage:
      'linear-gradient(45deg, #d0d0d0 25%, transparent 25%), linear-gradient(-45deg, #d0d0d0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #d0d0d0 75%), linear-gradient(-45deg, transparent 75%, #d0d0d0 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  },
  logoImg: {
    display: 'block',
    maxWidth: 244,
    maxHeight: 144,
    margin: 'auto',
  },
  logoIconBtn: {
    padding: theme.spacing(0.5),
  },

  hide: {
    display: 'none',
  },

  previewTitleTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  preview: {
    position: 'relative',
  },
  previewBar: {
    width: '100%',
    height: 64,
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.text.primary,
  },
  previewImageBox: {
    padding: 12,
    maxHeight: 40,
  },
  previewImage: {
    display: 'block',
    maxHeight: 40,
    margin: 'auto',
  },
  previewBox: {
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderTopWidth: 0,
    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.palette.background.paper,
  },
  previewSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  previewDefaultBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.text.contrast,
  },
  previewPrimaryBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.contrast,
  },
  previewSecondaryBox: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.contrast,
  },
  previewDefaultTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  previewPrimaryTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  previewSecondaryTxt: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
}));
