import { useState, useEffect } from 'react';
import { useData } from '../providers/DataProvider';

let log = false;

export function useRates(lane, rateRules, payRateGroups, estimatedReturnRideCost) {
  const { _sdk } = useData();

  //Rate data from database that applies to chosen lane is stored here
  const [baseRateRule, setBaseRateRule] = useState(null);
  const [strandedRateRule, setStrandedRateRule] = useState(null);
  const [baseRate, setBaseRate] = useState(null);
  const [strandedRate, setStrandedRate] = useState(null);

  //Applicatble pay rates are stored here
  // const [perMinutePayRate, setPerMinutePayRate] = useState(null);
  // const [perMilePayRate, setPerMilePayRate] = useState(null);

  //Note: the param is named moveClass because "class" is a reserved word
  const getBand = (distanceMiles, moveClass, rateRules) => {
    const matchingRule = _sdk.rating.pricing.getMileageBand(distanceMiles, moveClass, rateRules);
    if (matchingRule) return `${matchingRule.distance_start}-\u2060${matchingRule.distance_end}\xa0Miles`;
    else return `Unknown\xa0Band`;
  };
  const getRate = (distanceMiles, moveClass, rateRules) => {
    const matchingRule = rateRules.find(
      rule => rule.class === moveClass && rule.distance_start < distanceMiles && rule.distance_end >= distanceMiles
    );
    if (matchingRule) return matchingRule.rate;
    else return null;
  };
  const getRateRule = (distanceMiles, moveClass, rateRules) => {
    const matchingRule = rateRules.find(
      rule => rule.class === moveClass && rule.distance_start < distanceMiles && rule.distance_end >= distanceMiles
    );
    if (matchingRule) return matchingRule;
    else return null;
  };

  //Set raterule values in state if raterule data
  useEffect(() => {
    if (lane && rateRules) {
      setBaseRateRule(getRateRule(lane.distance_miles, 'base', rateRules));
      setStrandedRateRule(getRateRule(lane.distance_miles, 'stranded', rateRules));
      setBaseRate(getRate(lane.distance_miles, 'base', rateRules));
      setStrandedRate(getRate(lane.distance_miles, 'stranded', rateRules));
    }
  }, [lane, rateRules]);

  // Set Pay raterule values in state if payrategroup data
  // useEffect(() => {
  //   if (lane && payRateGroups) {
  //     let customerId = lane.customer_id || null;
  //     let regionId = lane.pickup ? lane.pickup.region_id : null;
  //     let payRates = getPayRates(customerId, regionId, payRateGroups);
  //     setPerMinutePayRate(payRates[0]);
  //     setPerMilePayRate(payRates[1]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lane, payRateGroups]);

  const getPayRates = (customerId, regionId, payRateGroups) => {
    try {
      var group = null;
      let customerGroup = payRateGroups.find(group => group.customer_id === customerId);
      //Get customer group if exists
      if (customerGroup) {
        log && console.log(`customergroup`);
        group = customerGroup;
      } else {
        var regionGroup = payRateGroups.find(group => group.region_id === regionId);
        //Get region group if exists
        if (regionGroup) {
          log && console.log(`regiongroup`);
          group = regionGroup;
        } else {
          var defaultGroup = payRateGroups.find(group => group.name === `Company Defaults`);
          //Set pay group as default if no more specific pay group exists
          if (defaultGroup) {
            log && console.log(`defaultgroup`);
            group = defaultGroup;
          }
        }
      }
      //Find the specific payraterules that match the lane
      if (payRateGroups && group) {
        log && console.log(`PAYRATE GROUP:`, group);
        const payRates = group.payraterules;
        const matchingPayRates = payRates.find(
          rule => rule.distance_start <= Number(lane.distance_miles) && Number(lane.distance_miles) < rule.distance_end
        );
        if (matchingPayRates) {
          log &&
            console.log(
              `Rate Rules: $${Number(matchingPayRates.per_minute_rate)}`,
              `$${Number(matchingPayRates.per_mile_rate)}`
            );
          return [
            Number(matchingPayRates.per_minute_rate).toFixed(2),
            Number(matchingPayRates.per_mile_rate).toFixed(2),
          ];
        } else {
          console.log('No matching pay rate found');
          return [0, 0];
        }
      } else {
        console.log('No matching pay rate found');
        return [0, 0];
      }
    } catch (err) {
      console.error('Error processing pay rates: ', err);
      return [0, 0];
    }
  };

  //Duration in Minutes
  const driveDurationInMins = () => {
    return Number(lane.duration_sec) / 60 || 0;
  };
  //Drive time plus inspection and dwell times
  const totalDurationInMins = () => {
    /* Note: The function from the rating library was giving amounts shorter than the estimated drive duration
       so instead I just added the inspectino and dwell times to the lane duration
      let averageMilesPerHour = lane.average_drive_speed_min_per_mile * 60
      let totalTime = _sdk.rating.core.getTotalDuration(lane.distance_miles, averageMilesPerHour)
    */
    let core = _sdk.rating.core;
    let totalTime =
      lane.duration_sec +
      core.pickupDwellTime +
      core.pickupInspectionTime +
      core.deliveryDwellTime +
      core.deliveryInspectionTime;
    return Number(totalTime) / 60 || 0;
  };
  //Commission
  const getCommission = () => {
    //Use current raterule if available
    if (baseRateRule && baseRateRule.type && baseRateRule.rate) {
      var currentBasePrice = _sdk.rating.pricing.getCustomerPrice(
        lane.distance_miles,
        baseRateRule.rate,
        baseRateRule.type,
        lane.dealer_base_discount,
        lane.tolls
      );
    }
    return Number(1.55 + 0.024 * Number(currentBasePrice || lane.dealer_base_price)) || 0;
  };

  //--------Not Yet Implemented---------
  //Pay
  // NOTE: sdk rating will need to be refactored before the rating calculations can be used here
  // const getBaseTimePay = perMinuteRate => {
  //   //totalDuration is not the same as the lane's duration_sec value
  //   let totalDuration = _sdk.rating.core.getTotalDuration(lane.distance_miles, lane.average_drive_speed_mph);
  //   return Number(_sdk.rating.driverPay.getDriverBaseTimePay(totalDuration, perMinuteRate));
  // };
  // const getBaseDrivePay = perMileRate => {
  //   //totalDuration is not the same as the lane's duration_sec value
  //   let totalDuration = _sdk.rating.core.getTotalDuration(lane.distance_miles, lane.average_drive_speed_mph);
  //   return Number(_sdk.rating.driverPay.getDriverBaseDrivePay(totalDuration, perMileRate));
  // };

  const getTotalBasePay = () => {
    // if (perMinuteRate && perMileRate) {
    //   let totalBasePay = _sdk.rating.driverPay.getDriverBasePay({ lane: lane }, perMinuteRate, perMileRate);
    //   return totalBasePay;
    // } else {
      return Number(lane.driver_base_pay) - Number(lane.driver_base_pay_discount) || 0;
    // }
  };
  const getTotalStrandedPay = () => {
    return (
      Number(
        Number(lane.driver_base_pay) -
          Number(lane.driver_base_pay_discount) +
          Number(lane.driver_return_pay) -
          Number(lane.driver_return_pay_discount)
      ) || 0
    );
  };

  //Costs
  const getBaseCost = () => {
    return getTotalBasePay() + Number(lane.insurance_cost) + getCommission();
  };
  const getStrandedCost = () => {
    const returnRideEstimate = estimatedReturnRideCost ? Number(estimatedReturnRideCost) : Number(lane.estimated_rideshare_return_cost); 
    return (
      getTotalStrandedPay() +
      Number(lane.insurance_cost) +
      getCommission() +
      returnRideEstimate
    );
  };
  const getTotalBaseCost = () => {
    return Number(getBaseCost() + Number(lane.dealer_base_discount) + Number(lane.tolls));
  };
  const getTotalStrandedCost = () => {
    return Number(getStrandedCost() + Number(lane.dealer_stranded_discount) + Number(lane.tolls));
  };

  ///RATES AND PRICES NOTE: The correct source for any rating value is the raterules table in the database.
  //  The lane values should only be used as a fallback if no rate rule is found

  //Rates
  //If there is a rate from the database, these calculations will use it (from state). Otherwise, the fallback rate assigned to the lane when created will be used
  const getTotalBaseRate = () => {
    return Number(Number(baseRate || lane.dealer_base_rate) - Number(lane.dealer_base_discount)) || 0;
  };
  const getTotalStrandedRate = () => {
    return Number(Number(strandedRate || lane.dealer_stranded_rate) - Number(lane.dealer_stranded_discount)) || 0;
  };

  //Prices
  const getTotalBasePrice = () => {
    //Calculate price based off of current rate rule if available
    if (baseRateRule && baseRateRule.type && baseRateRule.rate) {
      var currentBasePrice = _sdk.rating.pricing.getCustomerPrice(
        lane.distance_miles,
        baseRateRule.rate,
        baseRateRule.type,
        lane.dealer_base_discount,
        lane.tolls
      );
    }
    return (
      Number(currentBasePrice || lane.dealer_base_price) - Number(lane.dealer_base_discount) + Number(lane.tolls) || 0
    );
  };
  const getTotalStrandedPrice = () => {
    //Calculate price based off of current rate rule if available
    if (strandedRateRule && strandedRateRule.rate && strandedRateRule.type) {
      var currentStrandedPrice = _sdk.rating.pricing.getCustomerPrice(
        lane.distance_miles,
        strandedRateRule.rate,
        strandedRateRule.type,
        lane.dealer_stranded_discount,
        lane.tolls
      );
    }
    return (
      Number(currentStrandedPrice || lane.dealer_stranded_price) -
        Number(lane.dealer_stranded_discount) +
        Number(lane.tolls) || 0
    );
  };

  //Profit
  const getTotalBaseProfit = () => {
    //Use current rate rule as type reference if available, else use lane's rate type
    var rateType = baseRateRule ? baseRateRule.type : lane.dealer_base_rate_type;
    if (rateType === `flat`) return getTotalBasePrice() - getTotalBaseCost();
    else if (rateType === `per`)
      return (
        getTotalBaseRate() * Number(lane.distance_miles) -
        getTotalBaseCost() -
        Number(lane.dealer_base_discount) -
        Number(lane.tolls)
      );
    else return 0;
  };
  const getTotalStrandedProfit = () => {
    //Use current rate rule as type reference if available, else use lane's rate type
    var rateType = strandedRateRule ? strandedRateRule.type : lane.dealer_stranded_rate_type;
    if (rateType === `flat`) return getTotalStrandedPrice() - getTotalStrandedCost();
    else if (rateType === `per`)
      return (
        getTotalStrandedRate() * Number(lane.distance_miles) -
        getTotalStrandedCost() -
        Number(lane.dealer_stranded_discount) -
        Number(lane.tolls)
      );
    else return 0;
  };
  // Return hook logic
  return {
    getBand,
    getRate,
    getPayRates,
    driveDurationInMins,
    totalDurationInMins,
    getCommission,
    getTotalBasePay,
    getTotalStrandedPay,
    getBaseCost,
    getStrandedCost,
    getTotalBaseCost,
    getTotalStrandedCost,
    getTotalBaseRate,
    getTotalStrandedRate,
    getTotalBasePrice,
    getTotalStrandedPrice,
    getTotalBaseProfit,
    getTotalStrandedProfit,
  };
}
