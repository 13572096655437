// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Icon, Typography, TextField, InputAdornment } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_CHASE_VEHICLES } from './gql';

import useGroupPlanning from './useGroupPlanning';

import { Modal } from '../../components/ModalComponents';

import AddNewAssignedChaseVehicleModal from '../ChaseVehicles/AddNewAssignedChaseVehicleModal';

/** Search vehicles */
const searchVehicles = (vehicles, searchInput) => {
  if (!searchInput) return vehicles;
  const search = searchInput?.toLowerCase();
  return vehicles?.filter(v => {
    return (
      v?.capacity?.toString()?.includes(search) ||
      v?.make?.toLowerCase()?.includes(search) ||
      v?.model?.toLowerCase()?.includes(search) ||
      v?.plan_date?.toLowerCase()?.includes(search) ||
      v?.vin?.toLowerCase()?.includes(search) ||
      v?.customer?.name?.toLowerCase()?.includes(search) ||
      v?.organization?.name?.toLowerCase()?.includes(search) ||
      v?.region?.name?.toLowerCase()?.includes(search)
    );
  });
};

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormVehicle({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const { getVehicleName, getVehicleVin, getVehicleCapacity } = useGroupPlanning();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [vehicleId, setVehicleId] = React.useState(0);
  const [vehicles, setVehicles] = React.useState([]);
  const [filteredVehicles, setFilteredVehicles] = React.useState([]);
  const [search, setSearch] = React.useState(``);

  const [modalOpen, setModalOpen] = React.useState(false);

  // Lazy fetch
  const [fetchVehicles, { loading, error }] = useLazyQuery(GET_CHASE_VEHICLES);

  /** Handle changing the vehicle ID */
  const handleVehicleIdChange = id => {
    const foundVehicle = vehicles?.find(vehicle => vehicle?.id === id);

    setVehicleId(id);
    setFormData({
      ...formData,
      capacity: foundVehicle?.chasevehicle?.capacity || foundVehicle?.capacity || 1,
      customerId: foundVehicle?.customer?.id || undefined,
      driverIds: [],
      laneIds: [],
      organizationId: foundVehicle?.organization?.id || undefined,
      regionId: foundVehicle?.region?.id || undefined,
      timezone: foundVehicle?.region?.timezone || dayjs.tz.guess(),
      vehicleId: id || 0,
      vehiclePickupLocationId: undefined,
      vehicleDeliveryLocationId: undefined,
    });

    setSearch(``);
  };

  /** Handle fetching vehicles */
  const handleFetchVehicles = async () => {
    if (!formData?.date) return;

    try {
      const res = await fetchVehicles({
        variables: {
          start: dayjs(`${formData?.date}T12:00:00`).subtract(1, `week`).startOf(`day`).format(),
          end: dayjs(`${formData?.date}T12:00:00`).add(1, `week`).endOf(`day`).format(),
        },
      });
      if (res?.data?.chasevehiclesassigned) {
        setVehicles([...res?.data?.chasevehiclesassigned]);
        setFilteredVehicles([...res?.data?.chasevehiclesassigned]);
      }
    } catch (err) {
      console.error(`Failed to fetch vehicles:`, err);
    }
  };

  /** Handle opening the modal */
  const handleModalOpen = () => setModalOpen(true);

  // Clear, then fetch/refetch vehicles on date change
  React.useEffect(() => {
    setVehicleId(0);
    setVehicles([]);
    setFilteredVehicles([]);
    setSearch(``);
    handleFetchVehicles();
  }, [formData?.date]);

  // Set the initial vehicle ID
  React.useEffect(() => {
    if (initialLoad && initialFormData?.vehicleId && vehicles?.length) {
      setVehicleId(initialFormData?.vehicleId);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData, vehicles]);

  // Use search input to filter vehicles
  React.useEffect(() => {
    setFilteredVehicles(() => searchVehicles(vehicles, search));
  }, [search]);

  return (
    <div className={cls.root}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} width='sm'>
        <AddNewAssignedChaseVehicleModal onClose={() => setModalOpen(false)} />
      </Modal>

      <label className={cls.textFieldLabel} htmlFor='vehicleId'>
        Which chase vehicle will be used for this group?<span className={cls.textFieldLabelRequired}> *</span>
      </label>

      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Search for vehicles...'
        value={search}
        onChange={e => setSearch(e?.target?.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon color='disabled' fontSize='small'>
                search
              </Icon>
            </InputAdornment>
          ),
        }}
      />

      <Spacer size='sm' />

      <div className={cls.container}>
        {loading ? (
          <Loading position='absolute' />
        ) : (
          <div className={cls.list}>
            {!filteredVehicles?.length ? (
              <Typography className={cls.emptyTxt}>No vehicles found from search criteria</Typography>
            ) : null}
            {filteredVehicles?.map(vehicle => (
              <div key={vehicle?.id} className={cls.item} onClick={() => handleVehicleIdChange(vehicle?.id)}>
                <div>
                  <Typography className={cls.itemTxt1}>{getVehicleName(vehicle)}</Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.plan_date ? dayjs(vehicle?.plan_date).format(`M/D/YYYY`) : `No Date Specified`}
                  </Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.customer ? vehicle?.customer?.name : `No Customer Specified`}
                  </Typography>
                  <Typography className={cls.itemTxt2}>
                    {vehicle?.region ? vehicle?.region?.name : `No Region Specified`}
                  </Typography>
                </div>

                <div className={cls.badge1}>
                  <Typography className={cls.badgeTxt}>{getVehicleVin(vehicle)}</Typography>
                </div>

                <div className={cls.badge2}>
                  <Typography className={cls.badgeTxt}>Capacity: {getVehicleCapacity(vehicle)}</Typography>
                </div>

                {vehicleId === vehicle?.id ? (
                  <div className={cls.selected}>
                    <Icon className={cls.selectedIcon}>check</Icon>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>

      <Typography className={cls.link} onClick={handleModalOpen}>
        Click here to assign a vehicle from existing chase vehicles
      </Typography>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },
  menuItem: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-start`,
  },

  menuItemTxt1: {
    lineHeight: 1,
    fontSize: 16,
  },
  menuItemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  container: {
    position: `relative`,
    overflowY: `scroll`,
    width: `100%`,
    minHeight: `64px`,
    maxHeight: `384px`,
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
  },

  list: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    flexWrap: `wrap`,
    gap: theme.spacing(1),
    width: `100%`,
    padding: theme.spacing(2),
  },
  item: {
    position: `relative`,
    overflow: `hidden`,
    display: `flex`,
    alignItems: `center`,
    gap: theme.spacing(1),
    flex: `0 1 435px`,
    height: `fit-content`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.dark,
    cursor: `pointer`,
    transition: `all 0.1s`,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  itemTxt1: {
    lineHeight: 1.25,
    fontSize: 14,
    userSelect: `none`,
  },
  itemTxt2: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 12,
    userSelect: `none`,
    color: theme.palette.text.secondary,
  },

  emptyTxt: {
    fontSize: 18,
    color: theme.palette.text.disabled,
  },

  badge1: {
    position: `absolute`,
    top: 6,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.fade[5],
  },
  badge2: {
    position: `absolute`,
    top: 30,
    right: 6,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.fade[3],
  },
  badgeTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.contrastPrimary,
  },

  selected: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `${theme.palette.secondary.main}80`,
  },
  selectedIcon: {
    fontSize: 24,
    color: theme.palette.text.contrast,
    textShadow: '0px 0px 4px #000000',
  },

  link: {
    width: `fit-content`,
    marginTop: 4,
    fontSize: 12,
    color: theme.palette.info.main,
    cursor: `pointer`,
    '&:hover': {
      textDecoration: `underline`,
    },
  },
}));
