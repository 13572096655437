// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Modal } from '../ModalComponents';

import MoveOutcomeReasonModalContent from './MoveOutcomeReasonModalContent';

// COMPONENT ---------------------------------------------------------------- //

export default function MoveOutcomeReasonModal({ open, input, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      {open ? <MoveOutcomeReasonModalContent input={input} onClose={onClose} /> : null}
    </Modal>
  );
}
