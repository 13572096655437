// -------------------------------- IMPORTS -------------------------------- //

import dayjs from 'dayjs';

import { capEach, capFirst, condensedCase } from './GeneralUtils';

// -------------------------------- EXPORTS -------------------------------- //

// NAVIGATION //

/** Open move details in a new tab */
export const openMoveDetailsFromMove = move => {
  if (move?.id) window.open(`/moves/${move?.id}`, `_blank`);
};

/** Open lane details in a new tab */
export const openLaneDetailsFromMove = move => {
  if (move?.lane?.id) window.open(`/lanes/${move?.lane?.id}`, `_blank`);
};

/** Open customer details in a new tab */
export const openCustomerDetailsFromMove = move => {
  if (move?.customer?.id) window.open(`/customers/${move?.customer?.id}`, `_blank`);
};

/** Open organization details in a new tab */
export const openOrganizationDetailsFromMove = move => {
  if (move?.customer?.organization?.id) window.open(`/organizations/${move?.customer?.organization?.id}`, `_blank`);
};

// GETTERS //

/** Get the SLA time difference from projected end time of move */
export const getDeadlineMs = (move, projectedEndTime) => {
  if (move?.deliver_by) {
    const slaTime = dayjs(move?.deliver_by);
    const calcTime = dayjs(projectedEndTime);
    const timeLeft = slaTime.diff(calcTime);
    return timeLeft;
  }
  return null;
};

/** Get the drive type of a move */
export const getDriveTypeFromMove = move => {
  if (move?.consumer_type === `customer`) return `concierge`;
  if (move?.consumer_type === `loaner`) return `loaner`;
  return `operational`;
};

/** Get the drive type icon of a move */
export const getDriveTypeIconFromMove = move => {
  const moveType = getDriveTypeFromMove(move);

  if (moveType === `concierge`) return `account_circle`;
  if (moveType === `loaner`) return `emoji_transportation`;
  if (moveType === `operational`) return `drive_eta`;
  return `help`;
};

/** Get human readable name from rideshare partner */
export const getFormattedRidesharePartner = str => {
  if (str && typeof str === `string`) {
    const newStr = condensedCase(str);
    if (newStr === `auto` || newStr === `a`) return `Auto`;
    if (newStr === `lyft` || newStr === `l`) return `Lyft`;
    if (newStr === `uber` || newStr === `u`) return `Uber`;
    if (newStr === `shuttle` || newStr === `s`) return `HopDrive`;
  }
  return str;
};

/** Get formatted status string from a drive move */
export const getFormattedStatusFromDrive = drive => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Assigned`;
    const status = condensedCase(drive?.status);

    // Check statuses and return a formatted string
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickupstarted`) return `Pickup Started`;
      if (status === `pickuparrived`) return `Pickup Arrived`;
      if (status === `pickupsuccessful`) return `Pickup Successful`;
      if (status === `deliverystarted`) return `Delivery Started`;
      if (status === `deliveryarrived`) return `Delivery Arrived`;
      if (status === `deliverysuccessful`) return `Delivery Successful`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(drive?.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted cancel status string from a drive */
export const getFormattedCancelStatusFromDrive = (drive = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Canceled`;
    const cancelStatus = condensedCase(drive?.cancel_status);

    // Check statuses and return a formatted string
    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      if (cancelStatus === `rescheduled`) return `Rescheduled`;

      return capEach(drive?.cancel_status);
    }
  }
  return fallbackStatus;
};

/** Get formatted combined status string from a drive or ride */
export const getFormattedCombinedStatusFromMove = (move = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for move
  if (move) {
    // Set local variables
    fallbackStatus = move?.move_type === `drive` ? `Not Assigned` : `Not Started`;
    const cancelStatus = condensedCase(move?.cancel_status);
    const status = condensedCase(move?.status);

    // Check statuses and return a formatted string
    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      return capEach(move?.cancel_status);
    }
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickupstarted`) return `Pickup Started`;
      if (status === `pickuparrived`) return `Pickup Arrived`;
      if (status === `pickupsuccessful`) return `Pickup Successful`;
      if (status === `deliverystarted`) return `Delivery Started`;
      if (status === `deliveryarrived`) return `Delivery Arrived`;
      if (status === `deliverysuccessful`) return `Delivery Successful`;
      if (status === `awaitingresponse`) return `Awaiting Response`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup`) return `Picked Up`;
      if (status === `droppedoff`) return `Dropped Off`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(move?.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a ride */
export const getFormattedStatusFromRide = (ride = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for ride
  if (ride) {
    // Set local variables
    fallbackStatus = `Not Called`;
    const status = condensedCase(ride?.status);

    // Check statuses and return a formatted string
    if (status) {
      if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
        return `Awaiting Response`;
      if (status === `pending`) return `Pending`;
      if (status === `processing`) return `Processing`;
      if (status === `estimating`) return `Estimating`;
      if (status === `estimated`) return `Estimated`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
      if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
      if (status === `notchosen` || status === `not chosen` || status === `not_chosen`) return `Not Chosen`;
      if (status === `canceled` || status === `cancelled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(ride?.status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a plan */
export const getFormattedStatusFromPlan = plan => {
  if (!plan?.moves) return null;

  // Find the first move being worked in the plan
  let currentMove = plan?.moves?.find(m => checkMoveInProgress(m));
  if (currentMove) return getFormattedCombinedStatusFromMove(currentMove);
  return null;
};

/** Get the driver ID of a move */
export const getDriverIdFromMove = move => {
  return move?.driver?.id || move?.driver_id || 0;
};

/** Get the driver name of a move */
export const getDriverNameFromMove = move => {
  return move?.driver?.user?.display_name || move?.driver?.name || move?.driver_name || `No Driver`;
};

/** Get the driver name and ID of a move */
export const getDriverNameAndIdFromMove = move => {
  if (
    (move?.driver?.user?.display_name || move?.driver?.name || move?.driver_name) &&
    (move?.driver?.id || move?.driver_id)
  )
    return `${move?.driver?.user?.display_name || move?.driver?.name || move?.driver_name} (${
      move?.driver?.id || move?.driver_id
    })`;
  return `No Driver (-)`;
};

/** Get the customer ID of a move */
export const getCustomerIdFromMove = move => {
  return move?.customer?.id || 0;
};

/** Get the customer name of a move */
export const getCustomerNameFromMove = move => {
  return move?.customer?.name || `No Customer`;
};

/** Get the customer name and ID of a move */
export const getCustomerNameAndIdFromMove = move => {
  if (move?.customer?.name && move?.customer?.id) return `${move?.customer?.name} (${move?.customer?.id})`;
  return `No Customer (-)`;
};

/** Get the organization ID of a move */
export const getOrganizationIdFromMove = move => {
  return move?.customer?.organization?.id || 0;
};

/** Get the organization name of a move */
export const getOrganizationNameFromMove = move => {
  return move?.customer?.organization?.name || `No Organization`;
};

/** Get the organization name and ID of a move */
export const getOrganizationNameAndIdFromMove = move => {
  if (move?.customer?.organization?.name && move?.customer?.organization?.id)
    return `${move?.customer?.organization?.name} (${move?.customer?.organization?.id})`;
  return `No Organization (-)`;
};

/** Get the region ID of a move */
export const getRegionIdFromMove = move => {
  return (
    move?.lane?.pickup?.region?.id ||
    move?.lane?.pickup?.region_id ||
    move?.lane?.delivery?.region?.id ||
    move?.lane?.delivery?.region_id ||
    0
  );
};

/** Get the region name of a move */
export const getRegionNameFromMove = move => {
  return move?.lane?.pickup?.region?.name || move?.lane?.delivery?.region?.name || `No Region`;
};

/** Get the region name and ID of a move */
export const getRegionNameAndIdFromMove = move => {
  if (move?.lane?.pickup?.region?.name && (move?.lane?.pickup?.region?.id || move?.lane?.pickup?.region_id))
    return `${move?.lane?.pickup?.region?.name} (${move?.lane?.pickup?.region?.id || move?.lane?.pickup?.region_id})`;
  if (move?.lane?.delivery?.region?.name && (move?.lane?.delivery?.region?.id || move?.lane?.delivery?.region_id))
    return `${move?.lane?.delivery?.region?.name} (${
      move?.lane?.delivery?.region?.id || move?.lane?.delivery?.region_id
    })`;
  return `No Region (-)`;
};

/** Get the move's lane distance in miles */
export const getLaneDistanceFromMove = move => {
  return move?.lane?.distance_miles || 0;
};

/** Get the move's lane duration in minutes */
export const getLaneDurationFromMove = move => {
  return move?.lane?.duration_sec / 60 || 0;
};

/** Get the move's driving duration in minutes */
export const getDriveDurationFromMove = move => {
  const fullDuration = move?.lane?.duration_sec || 0;
  const pickupInspection = move?.lane?.pickup_inspection_sec || 600;
  const deliveryInspection = move?.lane?.delivery_inspection_sec || 480;

  let driveDuration = fullDuration - pickupInspection - deliveryInspection;
  if (driveDuration < 0) driveDuration = 0;
  driveDuration = driveDuration / 60;

  return driveDuration;
};

/** Get a ride's full duration in minutes */
export const getRideDurationFromMove = move => {
  const fullDuration = move?.lane?.duration_sec || 0;
  const pickupInspection = move?.lane?.pickup_inspection_sec || 600;
  const deliveryInspection = move?.lane?.delivery_inspection_sec || 480;
  const waitTime = move?.lane?.return_ride_wait_sec || 300;

  let rideDuration = fullDuration - pickupInspection - deliveryInspection + waitTime;
  if (rideDuration < 0) rideDuration = 0;
  rideDuration = rideDuration / 60;

  return rideDuration;
};

/** Get the move's formatted lane description */
export const getFormattedLaneFromMove = move => {
  if (move?.lane?.pickup && move?.lane?.delivery) return `${move?.lane?.pickup?.name} to ${move?.lane?.delivery?.name}`;
  if (move?.lane?.description) return move?.lane?.description;
  return `No Lane`;
};

/** Get the move's formatted lane distance in miles */
export const getFormattedLaneDistanceFromMove = move => {
  const distance = getLaneDistanceFromMove(move);
  return `${distance.toFixed(1)} mi`;
};

/** Get the move's formatted lane duration in minutes */
export const getFormattedLaneDurationFromMove = move => {
  const duration = getLaneDurationFromMove(move);
  return `${duration.toFixed(0)} min`;
};

/** Get the move's formatted driving duration in minutes */
export const getFormattedDriveDurationFromMove = move => {
  const duration = getDriveDurationFromMove(move);
  return `${duration.toFixed(0)} min`;
};

/** Get the move's formatted ride duration in minutes */
export const getFormattedRideDurationFromMove = move => {
  const duration = getRideDurationFromMove(move);
  return `${duration.toFixed(0)} min`;
};

/** Get formatted vehicle string from the move object */
export const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
  // Default fallback vehicle string when not enough info is provided
  const fallbackVehicle = `Vehicle`;

  // Check for move
  if (move) {
    // Set local variables
    const year = move?.vehicle_year || move?.driver_vehicle_year || null;
    const make = move?.vehicle_make || move?.driver_vehicle_make || null;
    const model = move?.vehicle_model || move?.driver_vehicle_model || null;
    const color = move?.vehicle_color || move?.driver_vehicle_color || null;

    // Set local vehicle string
    let vehicle = ``;

    // Check for make and model
    if (make && model) vehicle = `${make} ${model}`;
    else if (make) vehicle = make;
    else if (model) vehicle = model;
    else vehicle = fallbackVehicle;

    // Include color and year if specified
    if (includeYear && year) vehicle = `${year} ${vehicle}`;
    if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

    // Return the built vehicle string
    return vehicle;
  }
  return fallbackVehicle;
};

/** Get formatted transmission from a move */
export const getFormattedTransmissionFromMove = move => {
  if (move?.manual_flag === false || move?.manual_flag === true) return move?.manual_flag ? `Manual` : `Automatic`;
  return `Unknown`;
};

/** Get the latest timestamp from a move */
export const getLatestTimestampFromMove = (move = null) => {
  return (
    move?.move_failed ||
    move?.delivery_successful ||
    move?.delivery_arrived ||
    move?.delivery_started ||
    move?.pickup_successful ||
    move?.pickup_arrived ||
    move?.pickup_started ||
    null
  );
};

/** Get the general pickup or delivery time from the move to display to the user */
export const getPickupOrDeliveryTimeFromMove = (type = `pickup`, move = null, format = `MM/DD/YYYY hh:mm A z`) => {
  if (type === `pickup`) {
    if (move?.pickup_arrived) return dayjs(move?.pickup_arrived).format(format);
    if (move?.pickup_time) return dayjs(move?.pickup_time).format(format);
    if (move?.ready_by) return dayjs(move?.ready_by).format(format);
  }
  if (type === `delivery`) {
    if (move?.delivery_successful) return dayjs(move?.delivery_successful).format(format);
    if (move?.delivery_time) return dayjs(move?.delivery_time).format(format);
    if (move?.deliver_by) return dayjs(move?.deliver_by).format(format);
  }
  return null;
};

/** Get move active flag from a move */
export const getFormattedActiveFlagFromMove = move => {
  if (move?.active === 0 || move?.active === 1) return move?.active ? `Yes` : `No`;
  return `-`;
};

/** Get move chargeable flag from a move */
export const getFormattedChargeableFlagFromMove = move => {
  if (move?.chargeable === false || move?.chargeable === true) return move?.chargeable ? `Yes` : `No`;
  return `-`;
};

/** Get move payable flag from a move */
export const getFormattedPayableFlagFromMove = move => {
  if (move?.payable === false || move?.payable === true) return move?.payable ? `Yes` : `No`;
  return `-`;
};

/** Get move settled flag from a move */
export const getFormattedSettledFlagFromMove = move => {
  if (move?.settled === false || move?.settled === true) return move?.settled ? `Yes` : `No`;
  return `-`;
};

// GENERAL CHECKS //

/** Check if the move is in-progress */
export const checkMoveInProgress = (move, includeCompleted = false) => {
  if (move) {
    const moveType = condensedCase(move?.move_type) || ``;
    const status = condensedCase(move?.status) || ``;

    if (moveType === `drive`) {
      if (status !== `deliverysuccessful` && (status?.includes(`pickup`) || status?.includes(`delivery`))) return true;
    }

    if (moveType === `ride`) {
      if (status === `accepted` || status === `arrived` || status === `pickedup`) return true;
    }

    if (includeCompleted) return checkMoveCompleted(move);
  }
  return false;
};

/** Check if the move was completed */
export const checkMoveCompleted = move => {
  if (move) {
    const moveType = condensedCase(move?.move_type) || ``;
    const status = condensedCase(move?.status) || ``;

    if (moveType === `drive` && status === `deliverysuccessful`) return true;
    if (moveType === `ride` && status === `droppedoff`) return true;
  }
  return false;
};

// TAG CHECKS //

/** Check for concierge tag */
export const checkForConciergeTag = move => {
  return move?.consumer_name || move?.consumer_phone ? true : false;
};

/** Check for groupable tag */
export const checkForGroupableTag = (move, isGroupable, customGroupCheck, groupableMoveIds) => {
  // If the move is part of a ridegroup, return true
  if (move?.ridegroup) return true;

  // Check if we passed in a groupable flag
  if (isGroupable) return true;

  // Check if we passed in a custom group checker
  if (customGroupCheck) return customGroupCheck(move);

  // Check if we passed in a list of groupable move ids
  if (groupableMoveIds?.length) return groupableMoveIds?.includes(move?.id);

  // Otherwise, return false
  return false;
};

/** Check for manual tag */
export const checkForManualTag = move => {
  return move?.manual_flag ? true : false;
};

/** Check for notes tag */
export const checkForNotesTag = move => {
  return move?.move_details || move?.dealer_contact ? true : false;
};

/** Check for railyard tag (Hangtag) */
export const checkForRailyardTag = move => {
  return move?.hangtags?.length && move?.hangtags?.[0]?.type === `yard` ? true : false;
};

/** Check for SLA tag */
export const checkForSlaTag = (move, deadlineMs) => {
  if (move?.deliver_by && deadlineMs) {
    return deadlineMs <= 2 * 60 * 60 * 1000 ? true : false; // If the deadline is 2 hours or less in the future, return true
  }
  return false;
};

/** Check for any standard tag */
export const checkForAnyTag = (move, isGroupable, customGroupChecker, groupableMoveIds, projectedEndTime) => {
  const hasConciergeTag = checkForConciergeTag(move);
  const hasGroupableTag = checkForGroupableTag(move, isGroupable, customGroupChecker, groupableMoveIds);
  const hasManualTag = checkForManualTag(move);
  const hasNotesTag = checkForNotesTag(move);
  const hasSlaTag = checkForSlaTag(move, projectedEndTime);

  return hasConciergeTag || hasGroupableTag || hasManualTag || hasNotesTag || hasSlaTag || false;
};
