//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useTools } from '../../hooks/useTools';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import OrganizationsFilter from './OrganizationsFilter';
import OrganizationsTable from './OrganizationsTable';

const getDefaultStatus = () => {
  const localStatus = localStorage.getItem(`organizations-index-status`);
  if (localStatus) return localStatus;
  else return `all`;
};

//////////////////////// COMPONENT ////////////////////////

export default function Organizations(props) {
  const cls = useStyles();

  const { goToRoute } = useTools();

  // Component state
  const [status, setStatus] = React.useState(getDefaultStatus());
  const [search, setSearch] = React.useState(``);

  // Handle add organization
  const handleAddOrganization = () => {
    goToRoute(`/organizations/add`);
  };

  // Control status filter
  const handleStatusChange = (status = null) => {
    if (status) {
      localStorage.setItem(`organizations-index-status`, status);
      setStatus(status);
    }
  };

  // Query
  const { loading, error, data, refetch } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      start: dayjs().startOf(`day`).subtract(30, `day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
    fetchPolicy: `cache-and-network`,
  });

  // Handle clear filters
  const handleClearFilters = () => {
    localStorage.setItem(`organizations-index-status`, `all`);

    setStatus(`all`);
    setSearch(``);
  };

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Organizations'>
          <OrganizationsFilter />
        </Toolbar>

        <DefaultLoadingFallback message='FETCHING ORGANIZATIONS' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching organizations index:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Organizations' onClear={handleClearFilters} refetch={handleRefetch}>
          <OrganizationsFilter
            handleAddOrganization={handleAddOrganization}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING ORGANIZATIONS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data?.organizations?.length) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Organizations' onClear={handleClearFilters} refetch={handleRefetch}>
          <OrganizationsFilter
            handleAddOrganization={handleAddOrganization}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultEmptyFallback message='NO ORGANIZATIONS FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const organizations = data?.organizations;

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Organizations' onClear={handleClearFilters} refetch={handleRefetch}>
        <OrganizationsFilter
          handleAddOrganization={handleAddOrganization}
          status={status}
          onStatusChange={handleStatusChange}
          search={search}
          onSearchChange={setSearch}
        />
      </Toolbar>

      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING ORGANIZATIONS TABLE' />}>
        <OrganizationsTable organizations={organizations} status={status} search={search} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_ORGANIZATIONS = gql`
  query admin_getOrganizations($start: timestamptz!, $end: timestamptz!) {
    organizations(order_by: { name: asc }) {
      id
      active
      category
      config
      created_at
      description
      name
      status
      type
      updated_at
      customers(order_by: { name: asc }) {
        id
        name
        moves_aggregate(
          where: {
            active: { _eq: 1 }
            chargeable: { _eq: true }
            move_type: { _eq: "drive" }
            pickup_time: { _gte: $start, _lte: $end }
            status: { _eq: "delivery successful" }
            _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
