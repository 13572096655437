//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, useTheme, Grid, Typography, Tooltip, Chip, Icon } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import useManageRide from './useManageRide';

//////////////////////// COMPONENT ////////////////////////

export default function AttemptContent({ children, ride = {}, attempt = {}, backdrop = false }) {
  const cls = useStyles();
  const theme = useTheme();

  const { getFormattedStatusFromRide } = useTools();

  const { getRideStatusIcon, getAttemptStatusColor, getAttemptStatusIcon } = useManageRide();

  return (
    <div
      className={cls.details}
      style={{ background: backdrop ? theme.palette.background.dark : theme.palette.background.paper }}
    >
      <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
        <Tooltip placement='top-start' title='Attempt ID'>
          <Grid item xs={4}>
            <Typography className={cls.headTxtL}>{attempt.id ? `Attempt #${attempt.id}` : `No Attempt ID`}</Typography>
          </Grid>
        </Tooltip>

        <Tooltip placement='top' title='Status'>
          <Grid item xs={4}>
            <Typography className={cls.headTxtC}>
              {attempt.status === `pending` ||
              attempt.status === `processing` ||
              attempt.status === `awaitingResponse` ||
              attempt.status === `estimating`
                ? `${getFormattedStatusFromRide(attempt)}...`
                : `${getFormattedStatusFromRide(attempt)}`}
            </Typography>
          </Grid>
        </Tooltip>

        <Grid item xs={4} />

        <Tooltip placement='top-end' title='Status Indicator'>
          <div className={cls.statusIndicator}>
            {ride.status === `pending` ||
            ride.status === `estimating` ||
            ride.status === `estimated` ||
            ride.status === `deciding` ||
            ride.status === `calling` ||
            attempt.status === `pending` ||
            attempt.status === `processing` ||
            attempt.status === `awaitingResponse` ||
            attempt.status === `estimating` ||
            attempt.status === `estimated` ? (
              <Loading icon={getRideStatusIcon(ride.status)} />
            ) : (
              <Chip
                color='primary'
                label={getFormattedStatusFromRide(attempt)}
                icon={<Icon className={cls.statusIcon}>{getAttemptStatusIcon(attempt.status)}</Icon>}
                style={{ background: getAttemptStatusColor(attempt.status) }}
              />
            )}
          </div>
        </Tooltip>
      </Grid>

      <Spacer />

      {children}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  details: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  headTxtL: {
    lineHeight: 1.25,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  headTxtC: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'default',
  },
  statusIndicator: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  statusIcon: {
    fontSize: 20,
  },
}));
