//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useScheduler } from '../providers/SchedulerProvider';

import {
  ScheduleDetailsPanelDrive,
  ScheduleDetailsPanelRide,
  ScheduleDetailsPanelPendingChanges,
  ScheduleDetailsPanelEnrichment,
  UnassignedMovesList,
} from '../index';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleDetailsPanel({ enrichedMove = null }) {
  const cls = useStyles();

  const { circularJSONStringify } = useTools();
  const { enableEnrichmentLogs } = useSettings();
  const { isPlanEditable, isUnassignedListVisible, unplanAllMoves, selectedPlan } = useScheduler();

  // Check if unassigned moves list is visible (render unassigned moves list instead)
  if (isPlanEditable && isUnassignedListVisible) {
    return (
      <>
        <div className={cls.panel}>
          <Typography className={cls.title}>Assign a Move to the Plan</Typography>
          <Button className={cls.unplanBtn} color='error' onClick={() => unplanAllMoves()}>
            Unplan All
          </Button>
        </div>
        <UnassignedMovesList selectedPlan={selectedPlan} />
      </>
    );
  }

  // Check if there is an actual enrichedMove selected (render empty instead)
  if (!enrichedMove) {
    return (
      <div className={cls.panelEmpty}>
        <Typography className={cls.title}>Nothing Selected</Typography>
      </div>
    );
  }

  const enrichedRideBefore = enrichedMove.enrichedRideBefore || null;
  const enrichedRideAfter = enrichedMove.enrichedRideAfter || null;

  return (
    <>
      <ScheduleDetailsPanelDrive
        enrichedDrive={enrichedMove}
        enrichedRideBefore={enrichedRideBefore}
        enrichedRideAfter={enrichedRideAfter}
      />
      <ScheduleDetailsPanelRide enrichedRide={enrichedRideBefore} isAfter={false} />
      <ScheduleDetailsPanelRide enrichedRide={enrichedRideAfter} isAfter={true} />

      <ScheduleDetailsPanelPendingChanges
        title='Pending Drive Changes'
        oldObject={enrichedMove.move}
        newObject={enrichedMove.overrides}
      />

      {enrichedRideBefore ? (
        <ScheduleDetailsPanelPendingChanges
          title='Pending Ride Before Changes'
          oldObject={enrichedRideBefore.move}
          newObject={enrichedRideBefore.overrides}
        />
      ) : null}

      {enrichedRideAfter ? (
        <ScheduleDetailsPanelPendingChanges
          title='Pending Ride After Changes'
          oldObject={enrichedRideAfter.move}
          newObject={enrichedRideAfter.overrides}
        />
      ) : null}

      {enableEnrichmentLogs ? (
        <>
          {enrichedMove ? (
            <ScheduleDetailsPanelEnrichment
              title='Enriched Drive'
              object={circularJSONStringify(enrichedMove, 2, [
                `accessorials`,
                `customer`,
                `lane`,
                `lyftride`,
                `lyft_trigger_move`,
                `movesByLyftTriggerId`,
                `parentMove`,
                `enrichedRideBefore`,
                `enrichedRideAfter`,
                `withOverrides`,
                `prior`,
                `next`,
              ])}
            />
          ) : null}
          {enrichedRideBefore ? (
            <ScheduleDetailsPanelEnrichment
              title='Enriched Ride Before'
              object={circularJSONStringify(enrichedRideBefore, 2, [
                `accessorials`,
                `customer`,
                `lane`,
                `lyftride`,
                `lyft_trigger_move`,
                `movesByLyftTriggerId`,
                `parentMove`,
                `enrichedRideBefore`,
                `enrichedRideAfter`,
                `withOverrides`,
                `prior`,
                `next`,
              ])}
            />
          ) : null}
          {enrichedRideAfter ? (
            <ScheduleDetailsPanelEnrichment
              title='Enriched Ride After'
              object={circularJSONStringify(enrichedRideAfter, 2, [
                `accessorials`,
                `customer`,
                `lane`,
                `lyftride`,
                `lyft_trigger_move`,
                `movesByLyftTriggerId`,
                `parentMove`,
                `enrichedRideBefore`,
                `enrichedRideAfter`,
                `withOverrides`,
                `prior`,
                `next`,
              ])}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  panelEmpty: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  unplanBtn: {
    position: 'absolute',
    top: 8,
    right: 18,
  },
}));
