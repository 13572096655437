import { gql } from '@apollo/client';

const GET_NOTIFICATION_DETAILS = gql`
  subscription get_notification_details($notificationId: bigint!) {
    notifications(where: { id: { _eq: $notificationId }}) {
        id
        type
        title
        body
        assignedto
        assignedat
        assignedby
        resolvedat
        resolvedby
        createdat
        createdby
        notes
        json
        duedate
    }
  }
`;

const UPDATE_NOTIFICATION_DETAILS = gql`
  mutation update_notification_details(
    $id: bigint!
    $type: String
    $title: String
    $body: String
    $assignedto: String
    $assignedby: String
    $assignedat: timestamptz
    $resolvedby: String
    $resolvedat: timestamptz
    $notes: String
    $duedate: timestamptz
  ) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        title: $title
        body: $body
        assignedto: $assignedto
        assignedby: $assignedby
        assignedat: $assignedat
        resolvedby: $resolvedby
        resolvedat: $resolvedat
        notes: $notes
        duedate: $duedate
      }
    ) {
      affected_rows
      returning {
        id
        type
        title
        body
        assignedto
        assignedby
        assignedat
        resolvedby
        resolvedat
        createdby
        createdat
        notes
        duedate
      }
    }
  }
`;


const ADD_NOTIFICATION = gql`
  mutation add_notification( $type: String, $title: String, $body: String, $createdby: String, $createdat: timestamptz, $assignedto: String, $assignedby: String, $assignedat: timestamptz, $resolvedat: timestamptz, $resolvedby: String, $notes: String, $duedate: timestamptz) {
    insert_notifications_one( type: $type, title: $title, body: $body, createdby: $createdby, createdat: $createdat, assignedby: $assignedby, assignedat: $assignedat, assignedto: $assignedto, resolvedby: $resolvedby, resolvedat: $resolvedat, notes: $notes, duedate: $duedate) {
      id,
      title,
      type,
      body,
      createdat,
      createdby,
      assignedto,
      assignedat,
      assignedby,
      resolvedat,
      resolvedby,
      notes
      duedate
    }
  }
`;

const UPDATE_NOTIFICATION_ASSIGNMENT = gql`
  mutation update_notification_assignment(
    $id: bigint
    $assignedto: String
    $assignedby: String
    $assignedat: timestamptz
  ) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: {
        assignedto: $assignedto
        assignedby: $assignedby
        assignedat: $assignedat
      }
    ) {
      affected_rows
      returning {
        id
        type
        title
        body
        assignedto
        assignedby
        assignedat
        resolvedby
        resolvedat
        createdby
        createdat
        notes
      }
    }
  }
`;


const UPDATE_NOTIFICATION_RESOLVED = gql`
  mutation update_notification_resolved(
    $id: bigint
    $resolvedby: String
    $resolvedat: timestamptz
  ) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: {
        resolvedby: $resolvedby
        resolvedat: $resolvedat
      }
    ) {
      affected_rows
      returning {
        id
        type
        title
        body
        assignedto
        assignedby
        assignedat
        resolvedby
        resolvedat
        createdby
        createdat
        notes
      }
    }
  }
`;

export { GET_NOTIFICATION_DETAILS, UPDATE_NOTIFICATION_DETAILS, ADD_NOTIFICATION, UPDATE_NOTIFICATION_ASSIGNMENT, UPDATE_NOTIFICATION_RESOLVED };