import React from 'react';
import { toast } from 'react-toastify';
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  LinearProgress,
  Checkbox,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useDrivers } from './useDrivers';

import RegionSelect from '../../components/RegionSelect';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function AddDriverModalContent({ input, onClose }) {
  const cls = useStyles();

  const { insertDriver, sendOnboardingEmail } = useDrivers();

  const [displayName, setDisplayName] = React.useState(``);
  const [email, setEmail] = React.useState(``);
  const [firstName, setFirstName] = React.useState(``);
  const [id, setId] = React.useState(``);
  const [lastName, setLastName] = React.useState(``);
  const [phone, setPhone] = React.useState(``);
  const [regionId, setRegionId] = React.useState(0);
  const [taxClass, setTaxClass] = React.useState(`1099`);

  const [formStep, setFormStep] = React.useState(1);
  const [isNewDriver, setIsNewDriver] = React.useState(null);
  const [willSendEmail, setWillSendEmail] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [validationArray, setValidationArray] = React.useState([]);

  // Update state if the input changes
  React.useEffect(() => {
    if (input) {
      setIsSaving(false);
      setIsValid(false);
    }
    // eslint-disable-next-line
  }, [input]);

  // Check validity of form
  const isFormValid = () => {
    // Initialize validity
    let validity = true;
    let validityArr = [];

    const isValidEmail = email => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    };

    // Check for required inputs
    if (isNewDriver === null) {
      const step = `isNewDriver`;
      const msg = `New driver check is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (isNewDriver && willSendEmail === null) {
      const step = `willSendEmail`;
      const msg = `Send onboarding email check is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }

    if (!displayName || !displayName.length) {
      const step = `displayName`;
      const msg = `Display Name field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!email || !email.length) {
      const step = `email`;
      const msg = `Email field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!isValidEmail(email)) {
      const step = `email`;
      const msg = `Email must be a valid email address.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!firstName || !firstName.length) {
      const step = `firstName`;
      const msg = `First Name field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!lastName || !lastName.length) {
      const step = `lastName`;
      const msg = `Last Name field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!phone || !phone.length) {
      const step = `phone`;
      const msg = `Phone Number field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!regionId) {
      const step = `regionId`;
      const msg = `Region field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!taxClass || !taxClass.length || taxClass === `none`) {
      const step = `taxClass`;
      const msg = `Tax Class field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }

    // Log out validation array if found
    if (validityArr.length) log && console.log(`Validation Array:`, validityArr);

    // Return validity
    return { validity, validityArr };
  };

  // Check if the form is valid before allowing the submit button
  React.useEffect(() => {
    const validation = isFormValid();
    const validity = validation.validity;
    const validityArr = validation.validityArr;
    setIsValid(validity);
    setValidationArray(validityArr);
    // eslint-disable-next-line
  }, [isNewDriver, willSendEmail, displayName, email, firstName, id, lastName, phone, regionId, taxClass]);

  // Handle setting the display name automatically when the first name and last name are edited
  React.useEffect(() => {
    if (firstName) {
      if (lastName) setDisplayName(`${firstName} ${lastName}`);
      else setDisplayName(firstName);
    }
  }, [firstName, lastName]);

  // Handle clearing to default form state
  const handleFormState = async () => {
    setDisplayName(``);
    setEmail(``);
    setFirstName(``);
    setId(``);
    setLastName(``);
    setPhone(``);
    setTaxClass(`1099`);

    setRegionId(0);
  };

  // Handle submitting the form
  const handleSubmit = async () => {
    // Build user, driver and driverdetail objects
    const user = {
      active: false,
      default_role: `driver`,
      display_name: displayName || null,
      email: email?.toLowerCase() || null,
      phone: phone || null,
    };

    let driver = {
      id: id || null,
      region_id: regionId || null,
      tax_class: taxClass !== `none` ? taxClass : null,
      driver_app_version: `3.0.0`,
      config: {
        attributes: {},
      },
    };

    const driverdetail = {
      first_name: firstName || null,
      last_name: lastName || null,
    };

    // Remove null driver id to avoid errors
    if (!driver.id) delete driver.id;

    // Create an insertable driver
    const insertableDriver = {
      ...driver,
      user: {
        data: user,
        on_conflict: {
          constraint: `users_pkey`,
          update_columns: [`active`, `avatar_url`, `display_name`, `email`, `phone`, `default_role`],
        },
      },
    };
    log && console.log(`Driver Params:`, { ...user, ...driver, ...driverdetail });

    // Do the insert
    const insertedDriverRes = await insertDriver(insertableDriver, driverdetail);

    // Check the result of the insert
    if (insertedDriverRes) {
      // Let the user know the insert was successful
      log && console.log(`Successfully created driver:`, insertedDriverRes);
      toast.success(`Successfully created driver!`);

      // If send onboarding email was checked, send the email
      if (isNewDriver && willSendEmail) {
        const emailRes = await sendOnboardingEmail(
          insertedDriverRes.id,
          insertedDriverRes.user.display_name,
          insertedDriverRes.user.email
        );
        if (emailRes) {
          log && console.log(`Onboarding email sent:`, emailRes);
        } else {
          console.error(`Failed to send onboarding email!`);
          toast.error(`Failed to send onboarding email!`);
        }
      }

      // Reset the form and close the modal
      handleFormState();
      if (onClose) onClose();
    } else {
      console.error(`Failed to create driver!`);
      toast.error(`Failed to create driver!`);
    }

    // Set saving to false
    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Add Driver</ModalHeader>

      {/* NEW DRIVER CHECK */}

      {formStep === 1 ? (
        <ModalContent>
          <Typography className={cls.titleTxt}>New Driver? (Please read carefully!)</Typography>

          <Typography className={cls.subtitleTxt}>
            By adding a new driver, you are confirming that this driver has already passed the interview and is ready
            for onboarding. If you are adding a driver who has not yet been interviewed, please do not create the driver
            until that process has been completed.
          </Typography>

          <Spacer size='xs' />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Are you adding a new driver to the system?&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>

              <Spacer size='xs' />

              <div className={cls.checkDiv}>
                <Checkbox
                  className={cls.checkBox}
                  checked={isNewDriver === true}
                  onChange={() => setIsNewDriver(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  Yes, I am adding a driver to the system that has passed the interview and needs to be onboarded.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </ModalContent>
      ) : null}

      {/* ONBOARDING EMAIL CHECK */}

      {formStep === 2 ? (
        <ModalContent>
          <Typography className={cls.titleTxt}>Send Onboarding Email? (Please read carefully!)</Typography>

          <Typography className={cls.subtitleTxt}>
            If the driver you are adding is able to complete self-service onboarding, you may send them an email with a
            link to start the process. If you wish to enter their information manually for any reason, please answer
            "No" below.
          </Typography>

          <Typography className={cls.subtitleTxt}>
            <b>Note: You may always resend this email from the gear menu on the driver details page.</b>
          </Typography>

          <Spacer size='xs' />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Do you wish to send an email to the driver with their self-service onboarding link?&nbsp;
                </Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>

              <Spacer size='xs' />

              <div className={cls.checkDiv}>
                <Checkbox
                  className={cls.checkBox}
                  checked={willSendEmail === true}
                  onChange={() => setWillSendEmail(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  Yes, send the driver an email so they can complete the onboarding process on their own.
                </Typography>
              </div>

              <div className={cls.checkDiv}>
                <Checkbox
                  className={cls.checkBox}
                  checked={willSendEmail === false}
                  onChange={() => setWillSendEmail(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, do not send an email to the driver so I can complete onboarding for them manually.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </ModalContent>
      ) : null}

      {/* DRIVER INFO FORM */}

      {formStep === 3 ? (
        <ModalContent>
          <Typography className={cls.titleTxt}>Basic Driver Info Form</Typography>

          <Typography className={cls.subtitleTxt}>
            Complete the form below to add your driver to the system. If you are sending an onboarding email, it will be
            delivered to the email address you provide here. Onboarding will need to be completed before the driver can
            be activated and work moves.
          </Typography>

          <Typography className={cls.subtitleTxt}>
            <b>
              Note: You may access the onboarding forms at any point from the header button on the driver details page.
            </b>
          </Typography>

          <Spacer size='xs' />

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label='First Name'
                placeholder='Enter legal first name...'
                variant='outlined'
                size='small'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label='Last Name'
                placeholder='Enter legal last name...'
                variant='outlined'
                size='small'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label='Display Name'
                placeholder='Enter display name (first + last name/goes by)...'
                variant='outlined'
                size='small'
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label_important
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label='Email'
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={email}
                onChange={e => setEmail(e.target.value?.trim())}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        email
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label='Phone Number'
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={phone}
                onChange={e => setPhone(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={8} xs={12}>
              <RegionSelect
                required
                label='Select Region'
                value={regionId || 0}
                onChange={e => setRegionId(e.target.value)}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                required
                select
                fullWidth
                label='Tax Class'
                placeholder='Select tax class...'
                variant='outlined'
                size='small'
                value={taxClass}
                onChange={e => setTaxClass(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        class
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={`none`}>
                  <em className={cls.placeholder}>Select a tax class...</em>
                </MenuItem>
                <MenuItem value={`1099`}>1099</MenuItem>
                <MenuItem value={`W-2`}>W-2</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </ModalContent>
      ) : null}

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <Typography className={cls.footerTxt}>* Starred fields are required</Typography>

        {formStep > 1 ? (
          <ModalAction
            color='secondary'
            onClick={() => {
              if (isNewDriver && formStep === 3) setFormStep(2);
              else setFormStep(1);
            }}
          >
            Back
          </ModalAction>
        ) : null}

        {formStep < 3 ? (
          <ModalAction
            disabled={
              formStep === 1 && isNewDriver === null ? true : formStep === 2 && willSendEmail === null ? true : false
            }
            color='primary'
            onClick={() => {
              if (isNewDriver && formStep === 1) setFormStep(2);
              else setFormStep(3);
            }}
          >
            Next
          </ModalAction>
        ) : null}

        {formStep >= 3 ? (
          <ModalAction
            loading={isSaving}
            disabled={isSaving || !isValid}
            color='primary'
            onClick={() => {
              setIsSaving(true);
              handleSubmit();
            }}
          >
            Submit
          </ModalAction>
        ) : null}

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  subtitleTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.333,
    fontSize: 14,
    fontWeight: 400,
    // color: theme.palette.text.secondary,
  },
  footerTxt: {
    marginRight: 'auto',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },

  background: {
    background: theme.palette.background.dark,
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelRequired: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.error.main,
    [theme.breakpoints.down('xs')]: {
      margin: '2px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },
}));
