// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip } from '@material-ui/core';

import { vanillaFunctions } from '../../hooks/useTools';
const { getFormattedCountdown, getDurationInMinutes } = vanillaFunctions;

// COMPONENT ---------------------------------------------------------------- //

export default function DeadlineTooltip({ children, move, enrichedMove, deadlineMs, calc }) {
  const cls = useStyles();

  return (
    <Tooltip
      title={
        <Grid container spacing={2} wrap='nowrap' className={cls.ttRoot}>
          <Grid item xs className={cls.ttList}>
            {calc === `timeline` || calc === `planning` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Time Left After Completion:</div>
                <div className={cls.ttValBold}>{getFormattedCountdown(deadlineMs, { format: 'dhm' }) || `Unknown`}</div>
              </div>
            ) : null}

            {calc === `unassigned` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Time Left To Plan:</div>
                <div className={cls.ttValBold}>{getFormattedCountdown(deadlineMs, { format: 'dhm' }) || `Unknown`}</div>
              </div>
            ) : null}

            <hr className={cls.ttDivider} />

            {calc === `timeline` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttVal}>Visual Delivery Time</div>
                <div className={cls.ttValBold}>VS </div>
                <div className={cls.ttVal}>Deadline</div>
              </div>
            ) : null}

            {calc === `planning` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttVal}>Planned Delivery Time</div>
                <div className={cls.ttValBold}>VS </div>
                <div className={cls.ttVal}>Deadline</div>
              </div>
            ) : null}

            {calc === `unassigned` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttVal}>Current time + Lane duration + Planning buffer</div>
                <div className={cls.ttValBold}>VS</div>
                <div className={cls.ttVal}>Deadline</div>
              </div>
            ) : null}

            <hr className={cls.ttDivider} />

            {calc === `timeline` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Visual Delivery Time:</div>
                <div className={cls.ttVal}>
                  {enrichedMove?.visualEndTime ? dayjs(enrichedMove?.visualEndTime).format(`M/D/YYYY h:mm A z`) : `-`}
                </div>
              </div>
            ) : null}

            {calc === `planning` ? (
              <div className={cls.ttFlex}>
                <div className={cls.ttKey}>Planned Delivery Time:</div>
                <div className={cls.ttVal}>
                  {move?.delivery_time ? dayjs(move?.delivery_time).format(`M/D/YYYY h:mm A z`) : `-`}
                </div>
              </div>
            ) : null}

            {calc === `unassigned` ? (
              <>
                <div className={cls.ttFlex}>
                  <div className={cls.ttKey}>Current Time:</div>
                  <div className={cls.ttVal}>{dayjs().format(`M/D/YYYY h:mm A z`)}</div>
                </div>

                <div className={cls.ttFlex}>
                  <div className={cls.ttKey}>Lane Duration:</div>
                  <div className={cls.ttVal}>
                    {move?.lane?.duration_sec ? getDurationInMinutes(move?.lane?.duration_sec) : `-`}
                  </div>
                </div>

                <div className={cls.ttFlex}>
                  <div className={cls.ttKey}>Planning Buffer:</div>
                  <div className={cls.ttVal}>15 min</div>
                </div>
              </>
            ) : null}

            <div className={cls.ttFlex}>
              <div className={cls.ttKey}>Deadline:</div>
              <div className={cls.ttVal}>
                {move?.deliver_by ? dayjs(move?.deliver_by).format(`M/D/YYYY h:mm A z`) : `-`}
              </div>
            </div>
          </Grid>
        </Grid>
      }
    >
      {children}
    </Tooltip>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  ttRoot: {},

  ttList: {},

  ttFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 8,
    minWidth: 192,
    maxWidth: 384,
    color: theme.palette.text.contrast,
  },

  ttKey: {
    fontWeight: 300,
  },
  ttKeyFade: {
    fontWeight: 300,
    color: theme.palette.text.contrastFade,
  },

  ttVal: {
    fontWeight: 500,
  },
  ttValFade: {
    fontWeight: 500,
    color: theme.palette.text.contrastFade,
  },
  ttValBold: {
    fontWeight: 900,
  },

  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
