import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

export default function Printable({ children }) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `@page { size: A4 landscape; }`,
  });

  return (
    <>
      <Button size='small' color='secondary' startIcon={<Icon fontSize='small'>print</Icon>} onClick={handlePrint}>
        Print
      </Button>

      <Spacer />

      <div id='print-component' ref={printRef}>
        {children}
      </div>
    </>
  );
}
