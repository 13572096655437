//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, DialogTitle, Typography, IconButton, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function ModalHeader({ className, classes, children, onClose, handleClose, ...rest }) {
  const cls = useStyles();

  return (
    <div id='draggable-modal'>
      <DialogTitle disableTypography className={cls.padding + ' ' + className} classes={classes} {...rest}>
        <Typography className={cls.title}>{children}</Typography>
      </DialogTitle>

      {onClose || handleClose ? (
        <IconButton
          className={cls.close}
          onClick={() => {
            if (onClose) onClose();
            if (handleClose) handleClose();
          }}
          aria-label='close'
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
    cursor: 'move',
  },
  title: {
    fontSize: 21,
    fontWeight: 500,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));
