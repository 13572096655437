//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useSettings } from '../providers/SettingsProvider';
import Spacer from '../../../components/Spacer';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleDetailsPanelEnrichment({ title = `Enrichment`, object = null }) {
  const cls = useStyles();

  const { enableEnrichmentLogs } = useSettings();

  return (
    <>
      {enableEnrichmentLogs ? (
        <div className={cls.panel}>
          <Grid container spacing={1} alignItems='center' wrap='nowrap'>
            <Grid item xs>
              <Typography className={cls.title}>{title}</Typography>
            </Grid>
          </Grid>

          <Spacer />

          <pre>{object}</pre>
        </div>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
    fontSize: 10,
  },
  title: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
}));
