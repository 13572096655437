import React from 'react';
import {
  useTheme,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  Typography,
} from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayModalTable({ driverPay }) {
  const theme = useTheme();
  const cls = useStyles();

  const { formatUSD } = useTools();

  // Table Headers
  const headers = [
    { id: `driver`, align: `left`, label: `Driver` },
    { id: `status`, align: `left`, label: `Status` },
    { id: `driverpayout_id`, align: `left`, label: `Payout\xa0ID` },
    { id: `notes`, align: `left`, label: `Notes` },
    { id: `total`, align: `right`, label: `Total` },
  ];

  // Table Rows
  let rows = driverPay.map((dp, i) => {
    return {
      driver: `${dp.driver_name} (${dp.driver_id})`,
      status: (
        <div className={cls.statusBox}>
          {!dp.status && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.text.disabled }}>
              do_not_disturb_on
            </Icon>
          )}
          {dp.status === `Successful` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.success.main }}>
              check_circle
            </Icon>
          )}
          {dp.status === `Failed` && (
            <Icon className={cls.statusIcon} style={{ color: theme.palette.error.main }}>
              cancel
            </Icon>
          )}
          <Typography className={cls.statusTxt}>{dp.status || `Waiting`}</Typography>
        </div>
      ),
      driverpayout_id: dp.driverpayout_id || null,
      notes: dp.notes || null,
      total: formatUSD(dp.total),
      driverPay: dp,
      index: i,
    };
  });
  rows.sort((a, b) => a.driver_name - b.driver_name);

  return (
    <TableContainer>
      <Table size='small' aria-label='verify-driver-pay-table'>
        <TableHead>
          <TableRow>
            {headers.map(header => {
              return (
                <TableCell
                  key={header.id}
                  align={header.align || `left`}
                  padding={header.padding || `normal`}
                  sortDirection='asc'
                >
                  {header.label || `Label`}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, ri) => {
            const cells = [
              {
                value: row.driver || `-`,
                align: `left`,
              },
              {
                value: row.status || `-`,
                align: `left`,
              },
              {
                value: row.driverpayout_id || `-`,
                align: `left`,
              },
              {
                value: row.notes || `-`,
                align: `left`,
              },
              {
                value: row.total || `-`,
                align: `right`,
              },
            ];
            return (
              <TableRow key={`verify-driver-pay-index-row-${ri}`}>
                {cells.map((cell, ci) => {
                  return (
                    <TableCell
                      key={`verify-driver-pay-index-cell-${ci}`}
                      align={cell.align || `left`}
                      style={{ borderBottom: ri >= rows.length - 1 ? `none` : theme.border[0] }}
                    >
                      {cell.value || null}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  statusBox: {
    position: 'relative',
    display: 'flex',
  },
  statusIcon: {
    fontSize: 20,
    marginRight: 8,
  },
  statusTxt: {
    fontSize: 14,
  },
}));
