//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Prompt } from 'react-router-dom';
import deepmerge from 'deepmerge';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, Grid, Container, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { gql, useMutation } from '@apollo/client';
import { useData } from '../../providers/DataProvider';
import useCustomerDetails from './useCustomerDetails';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import SettingsList from '../../components/Settings/SettingsList';
import SettingsContent from '../../components/Settings/SettingsContent';

import Api from '../../components/Settings/ClientConfig/Api';
import Appraisals from '../../components/Settings/ClientConfig/Appraisals';
import Billing from '../../components/Settings/ClientConfig/Billing';
import Branding from '../../components/Settings/ClientConfig/Branding';
import Contacts from '../../components/Settings/ClientConfig/Contacts';
import CustomerValues from '../../components/Settings/ClientConfig/CustomerValues';
import Fuel from '../../components/Settings/ClientConfig/Fuel';
import MovePlanning from '../../components/Settings/ClientConfig/MovePlanning';
import Notifications from '../../components/Settings/ClientConfig/Notifications';
import Rates from '../../components/Settings/ClientConfig/Rates';
import Rideshare from '../../components/Settings/ClientConfig/Rideshare';
import ThirdPartyPayer from '../../components/Settings/ClientConfig/ThirdPartyPayer';
import Workflows from '../../components/Settings/ClientConfig/Workflows';

import CustomerDetailsSettingsToolbar from './CustomerDetailsSettingsToolbar';
import { toast } from 'react-toastify';

const log = false;

// Configuration for the settings tab
const settings = [
  {
    id: `client-config-api`,
    label: `API`,
    sublabels: [`Activation`],
  },
  {
    id: `client-config-appraisals`,
    label: `Appraisals`,
    sublabels: [`Activation`, `Active Timeframes`, `Allowed Move Types`],
  },
  {
    id: `client-config-billing`,
    label: `Billing`,
    sublabels: [`Frequency`, `Auto Pay`],
  },
  {
    id: `client-config-branding`,
    label: `Branding`,
    sublabels: [`Activation`, `Display Name`, `Primary Color`, `Secondary Color`, `Logo`],
  },
  {
    id: `client-config-customer-values`,
    label: `Custom Settings`,
    sublabels: [`Custom Data Input`],
  },
  {
    id: `client-config-fuel`,
    label: `Fuel`,
    sublabels: [`Minimum Fuel Spend`, `Maximum Fuel Spend`, `Required Fuel Level`],
  },
  {
    id: `client-config-hopdrive-contacts`,
    label: `Hopdrive Contacts`,
    sublabels: [`Account Manager`, `Market Manager`, `Sales Representative`, `Accounts Receivable`],
  },
  {
    id: `client-config-move-planning`,
    label: `Move Planning`,
    sublabels: [
      `Activation`,
      `Bypass Auto-Deactivation`,
      `Allowed Move Types`,
      `Default Move Type`,
      `Activate Distance Estimates`,
      `Activate Price Estimates`,
      `Activate Triangle Moves`,
    ],
  },
  {
    id: `client-config-email-notifications`,
    label: `Notifications`,
    sublabels: [`Move Status Updates`, `Billing Notifications`],
  },
  {
    id: `client-config-rates`,
    label: `Rates`,
    sublabels: [`Cancellation Fee`, `Delay Price Per Minute`, `Fuel Multiplier`],
  },
  {
    id: `client-config-rideshare`,
    label: `Rideshare`,
    sublabels: [`Preferred Ride Partner`, `Surge Charge Accountability`],
  },
  {
    id: `client-config-third-party-payer`,
    label: `Third Party Payer`,
    sublabels: [`Required`, `Default Payer`],
  },
  {
    id: `client-config-workflows`,
    label: `Workflows`,
    sublabels: [`Allowed Workflow Sets`, `Default Workflow Set`],
  },
];

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsSettings({ customer, refetch }) {
  const cls = useStyles();

  const { _sdk } = useData();
  const { getDefaultCustomerRef } = useCustomerDetails();

  // State variables
  const [inheritance, setInheritance] = React.useState(null);
  const [isReset, setIsReset] = React.useState(true);
  const [isSaved, setIsSaved] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  // Mutable ref for editing
  const customerRef = React.useRef(getDefaultCustomerRef(customer));

  // Text written on reset buttons
  const resetText = customer?.organization?.id && `Restore Org Default`;
  const resetTooltip = customer?.organization?.id && `Restore value to the organization default`;

  // Fetch the config inheritance
  React.useEffect(() => {
    const fetchConfigInheritance = async () => {
      try {
        const res = _sdk.configs.enrichOrganizationConfig(customer?.organization?.config);
        if (res) {
          const mutableCustomer = JSON.parse(JSON.stringify(customer || {}));
          setInheritance({ ...mutableCustomer, config: res });
        }
        log && console.log(`Config Inheritance:`, res);
      } catch (err) {
        console.error(`Failed to fetch config inheritance:`, err);
      }
    };
    fetchConfigInheritance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the reset state when the page loads
  React.useEffect(() => {
    handleResetState();
  }, []);

  // Mutation to update the customer record
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  // Handle updating the reset state
  const handleResetState = () => {
    if (Object.keys(customerRef?.current?.config).length === 0) setIsReset(true);
    else setIsReset(false);
  };

  // Handle updating the save state
  const handleSaveState = () => {
    if (isSaved) setIsSaved(false);
  };

  const customMerge = (destination, source) => {
    return deepmerge(destination, source, {
      arrayMerge: (dst, src) => src,
      customMerge: key => {
        if (key === 'customer_values') {
          return (dst, src) => {
            // Replace the current customer_values with the new one
            return src;
          };
        }
        return undefined; // Default merging behavior for other keys
      },
    });
  };

  // Handle updating the mutable ref
  const updateCustomerRef = changes => {
    // Deep merge the changes into the mutable ref
    customerRef.current = customMerge(customerRef?.current, changes);
    log && console.log(`Customer Ref Change:`, customerRef?.current);

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();
  };

  // Handle checking if a field is reset in the mutable ref
  const checkCustomerRefField = field => {
    if (getPropValue(customerRef?.current, field) !== undefined) return false;
    return true;
  };

  // Handle resetting (deleting) a field in the mutable ref
  const deleteCustomerRefField = (field, callback) => {
    try {
      // Split out the keys and reduce to get the final nested key, then delete it
      let keys = field.split(`.`);
      if (keys?.length) {
        let last = keys.pop();
        delete keys.reduce((obj, key) => obj[key], customerRef.current)[last];
        log && console.log(`Customer Ref Delete:`, customerRef?.current);
      }

      // Delete empty config objects if necessary
      Object.keys(customerRef?.current?.config).forEach(key => {
        if (
          typeof customerRef.current.config[key] === `object` &&
          Object.keys(customerRef.current.config[key]).length === 0
        ) {
          delete customerRef.current.config[key];
        }
      });
    } catch (err) {
      console.error(`Failed to delete customer ref field:`, err);
    }

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();

    // Run the callback if it exists
    if (callback) callback();
  };

  // Handle restoring defaults to all settings
  const handleRestoreDefaults = async () => {
    // Reset the mutable ref to defaults
    customerRef.current = getDefaultCustomerRef();
    log && console.log(`Customer Ref Reset:`, customerRef?.current);

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();
  };

  // Handle saving changes
  const handleSaveChanges = async () => {
    // log &&
    console.log(`Customer Ref Save:`, customerRef?.current);
    setIsSaving(true);

    // Update the customer record
    const variables = {
      customerId: customer?.id,
      autoPay: customerRef?.current?.auto_pay,
      billingFrequency: customerRef?.current?.billing_frequency,
      config: customerRef?.current?.config,
      notifyBilling: customerRef?.current?.notify_billing,
    };

    // Update the customer record
    try {
      const res = await updateCustomer({ variables });
      if (res?.data?.update_customers?.affected_rows) {
        log && console.log(`Successfully updated customer record:`, res);
        toast.success(`Successfully updated customer record!`);
        setIsSaved(true);
      }
    } catch (err) {
      console.error(`Failed to update customer record:`, err);
      toast.error(`Failed to update customer record!`);
    }

    await refetch();
    setIsSaving(false);
  };

  // Wait until we have the organization config inheritance before rendering
  if (!inheritance) return <DefaultLoadingFallback message='FETCHING CONFIG INHERITANCE' />;
  return (
    <div className={cls.root}>
      <Prompt when={!isSaved} message='You have unsaved changes, are you sure you want to leave?' />

      <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
        <Grid item>
          <div className={cls.scrollWrapper}>
            <SettingsList settings={settings} />
          </div>
        </Grid>

        <Grid item xs>
          <div className={cls.scrollWrapper}>
            <div id='settings-overflow' className={cls.scrollOverflow}>
              <CustomerDetailsSettingsToolbar
                isReset={isReset}
                isSaved={isSaved}
                isSaving={isSaving}
                onReset={handleRestoreDefaults}
                onSave={handleSaveChanges}
              />

              <SettingsContent>
                <Container maxWidth='md'>
                  <Spacer size={8} />
                  <div className={cls.blurb}>
                    <Icon className={cls.blurbIcon}>info</Icon>
                    <Typography className={cls.blurbTxt}>
                      Applying any changes on this page will ONLY affect the settings for{` `}
                      {customer?.name || `Unknown`}. To set a value for the entire org above this customer, please use
                      that org's settings page instead. Clicking "Restore Org Default" on any value will set it back to
                      the parent org's default value for that setting. When the button is grayed out, the value is
                      already set to the org default.
                    </Typography>
                  </div>
                  <Spacer size={8} />
                  <Api
                    settingId={settings[0]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Appraisals
                    settingId={settings[1]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Billing
                    settingId={settings[2]?.id}
                    overrideRef={customerRef?.current}
                    updateMutableRef={updateCustomerRef}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Branding
                    settingId={settings[3]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                    clientType={`customer`}
                  />
                  <Spacer size={10} />
                  <CustomerValues
                    settingId={settings[4]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    setIsReset={setIsReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Fuel
                    settingId={settings[5]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Contacts
                    settingId={settings[6]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                    clientType={`customer`}
                  />
                  <Spacer size={10} />
                  <MovePlanning
                    settingId={settings[7]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Notifications
                    settingId={settings[8]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Rates
                    settingId={settings[9]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Rideshare
                    settingId={settings[10]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <ThirdPartyPayer
                    settingId={settings[11]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                  />
                  <Spacer size={10} />
                  <Workflows
                    settingId={settings[12]?.id}
                    overrideRef={customerRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateCustomerRef}
                    checkMutableRefField={checkCustomerRefField}
                    deleteMutableRefField={deleteCustomerRefField}
                    isReset={isReset}
                    resetText={resetText}
                    resetTooltip={resetTooltip}
                    customerId={customer?.id}
                    orgId={customer?.organization?.id}
                  />
                  <Spacer size={2} />
                </Container>
              </SettingsContent>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },

  // This is the wrapper that controls the bounding box of the scrollable element
  scrollWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 194,
    backgroundColor: theme.palette.background.paper,
  },
  // This is the element that will scroll (sits inside wrapper)
  scrollOverflow: {
    overflow: 'auto',
  },

  blurb: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.action.hover,
  },
  blurbIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  blurbTxt: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}));

//////////////////////// GQL ////////////////////////

const UPDATE_CUSTOMER = gql`
  mutation admin_updateCustomer(
    $customerId: bigint!
    $autoPay: Boolean!
    $billingFrequency: String!
    $config: jsonb!
    $notifyBilling: Boolean!
  ) {
    update_customers(
      where: { id: { _eq: $customerId } }
      _set: {
        auto_pay: $autoPay
        billing_frequency: $billingFrequency
        config: $config
        notify_billing: $notifyBilling
      }
    ) {
      affected_rows
    }
  }
`;
