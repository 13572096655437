//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function BigButtonGroup({ onChange, preselect, marginBetween = 16, children }) {
  const cls = useStyles();

  const [selected, setSelected] = React.useState(preselect);

  React.useEffect(() => {
    if (preselect) {
      setSelected(preselect);
    }
  }, [preselect]);

  const handleChange = (id, title) => {
    setSelected(id);
    if (onChange) onChange(id, title);
  };

  return (
    <>
      <div className={cls.group}>
        {React.Children.map(children, (child, i) => {
          const count = React.Children.count(children);
          return React.cloneElement(child, {
            key: child.props.key,
            title: child.props.title,
            subtitle: child.props.subtitle,
            selected: selected === child.props.id,
            onChange: handleChange,
            orientation: 'horizontal',
            disabled: child.props.disabled,
            width: Math.floor((0.9 / count) * 100) + '%',
            marginRight: i === count - 1 ? 0 : marginBetween,
          });
        })}
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));
