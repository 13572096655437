//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Typography } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from './ModalComponents';

//////////////////////// COMPONENT ////////////////////////

function ARLogsModal(props) {
  const cls = useStyles();

  const { open, onClose, input } = props;

  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        {input && input.type && input.events && input.events.length > 0 ? (
          <>
            <ModalHeader handleClose={handleClose}>
              Logs for{' '}
              {input.type === `invoice` ? `Invoice #${input.events[0].invoice_id}` : `Move #${input.events[0].move_id}`}
            </ModalHeader>

            <ModalContent className={cls.content}>
              {input.events.map((log, index) => {
                return (
                  <div
                    key={log.id}
                    className={cls.logRow}
                    style={index % 2 ? { background: '#ffffff' } : { background: '#f0f0f0' }}
                  >
                    <Typography className={cls.logTxt} color='textSecondary' display='inline'>
                      {dayjs(log.createdat).format('MM/DD/YY')}&nbsp;@&nbsp;
                      {dayjs(log.createdat).format('hh:mm:ss A z')}&nbsp;-&nbsp;
                    </Typography>
                    <Typography className={cls.logTxt} display='inline'>
                      {log.message}
                    </Typography>
                  </div>
                );
              })}
            </ModalContent>
          </>
        ) : null}
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  logRow: {
    padding: theme.spacing(1, 2),
    wordWrap: 'break-word',
  },
  logTxt: {
    fontSize: 12,
    fontWeight: 400,
  },
  inline: {
    display: 'inline-block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default ARLogsModal;
