import React from 'react';
import { makeStyles, Container, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import useOnboarding from './useOnboarding';

import Toolbar from '../../components/Toolbar';

import LaunchPadActions from './LaunchPadActions';
import LaunchPadGroup from './LaunchPadGroup';
import Workflow from './Workflow';
import RejectionModal from './RejectionModal';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function LaunchPad({ driver, refetch }) {
  const cls = useStyles();

  const { goToDriverDetails } = useTools();
  const { buildLaunchpadGroups } = useOnboarding();

  const [workflow, setWorkflow] = React.useState({ open: false, flowGroup: null, flowSection: null, output: null });
  const handleWorkflowOpen = (flowGroup, flowSection) => {
    setWorkflow({ open: true, flowGroup, flowSection, output: null });
  };
  const handleWorkflowClose = output => {
    setWorkflow({ open: false, flowGroup: null, flowSection: null, output: output });
  };

  const [rejectionModal, setRejectionModal] = React.useState({ open: false, input: null, output: null });
  const handleRejectionModalOpen = (input = null) => {
    setRejectionModal({ open: true, input: input, output: null });
  };
  const handleRejectionModalClose = async (output = null) => {
    setRejectionModal({ open: false, input: null, output: output });
  };

  const rejectedReason =
    driver && driver.verification && driver.verification.rejected_reason ? driver.verification.rejected_reason : null;

  const terminatedReason =
    driver && driver.verification && driver.verification.terminated_reason
      ? driver.verification.terminated_reason
      : null;

  const launchpadGroups = buildLaunchpadGroups(driver, handleWorkflowOpen);

  const launchpadActions = [
    {
      label: `Go to Driver Details`,
      handler: () => goToDriverDetails(driver.id),
    },
    {
      label: `Reject Driver`,
      handler: () => handleRejectionModalOpen({ driver, refetch }),
      disabled:
        driver.status !== `new` &&
        driver.status !== `interviewed` &&
        driver.status !== `onboarding` &&
        driver.status !== `training`,
    },
  ];

  return (
    <>
      <RejectionModal open={rejectionModal.open} input={rejectionModal.input} onClose={handleRejectionModalClose} />

      {!workflow.open ? (
        <Container maxWidth='md'>
          <Spacer />
          <div className={cls.launchPad}>
            <Toolbar
              back
              title={`Onboarding for ${driver.display_name}`}
              refetch={refetch}
              actions={launchpadActions}
            />

            <Typography className={cls.subtitle}>
              Manage and track the potential driver's progress through the onboarding process. Approve each section as
              they complete them. Once they finish all forms and documents, setup a training move for them.
            </Typography>

            {driver.status === `rejected` ? (
              <>
                <Spacer size='md' />

                <div className={cls.alertBox}>
                  <Icon className={cls.alertIcon}>report</Icon>
                  <div>
                    <Typography className={cls.alertTxt}>
                      This driver has been<b> REJECTED</b>. If this was a mistake, please contact an admin. This driver
                      was rejected for the following reason:
                    </Typography>
                    <Spacer size='xs' />
                    <Typography className={cls.alertTxt}>
                      <b>{rejectedReason}</b>
                    </Typography>
                  </div>
                </div>
              </>
            ) : null}

            {driver.status === `terminated` ? (
              <>
                <Spacer size='md' />

                <div className={cls.alertBox}>
                  <Icon className={cls.alertIcon}>report</Icon>
                  <div>
                    <Typography className={cls.alertTxt}>
                      This driver has been<b> TERMINATED</b>. If this was a mistake, please contact an admin. This
                      driver was terminated for the following reason:
                    </Typography>
                    <Spacer size='xs' />
                    <Typography className={cls.alertTxt}>
                      <b>{terminatedReason}</b>
                    </Typography>
                  </div>
                </div>
              </>
            ) : null}

            {driver.status !== `rejected` && driver.status !== `terminated` ? (
              <>
                <Spacer size='lg' />

                <LaunchPadActions driver={driver} launchpadGroups={launchpadGroups} refetch={refetch} />

                <Spacer size='xl' />

                {launchpadGroups && launchpadGroups.length
                  ? launchpadGroups.map((g, i) => (
                      <React.Fragment key={`launchpad-group-${i}`}>
                        {i > 0 ? <Spacer size='xl' /> : null}
                        <LaunchPadGroup index={i} group={g} refetch={refetch} />
                      </React.Fragment>
                    ))
                  : null}
              </>
            ) : null}
          </div>
        </Container>
      ) : (
        <Workflow
          driver={driver}
          flowGroup={workflow.flowGroup}
          flowSection={workflow.flowSection}
          onClose={handleWorkflowClose}
          refetch={refetch}
        />
      )}
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  launchPad: {
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7),
  },

  title: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.333,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      fontSize: 18,
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  alertBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.dark,
  },
  alertIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
  },
  alertTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));
