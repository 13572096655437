import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, TableRow, TableCell, Collapse } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../../styles/react-contextmenu.css';

function AccordionRow(props) {
  const cls = useStyles();
  const { rowId, expandedRowId, setExpandedRowId, columns, actions, onClick, className } = props;

  const collect = props => {
    return props;
  };

  const handleOnClick = column => {
    if (!column.preventClick) {
      if (onClick) onClick();
      if (expandedRowId === rowId) setExpandedRowId(0);
      else setExpandedRowId(rowId);
    }
  };

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${rowId}-cm`}
        source={`atr-row-${rowId}-cm`}
        holdToDisplay={1000}
        collect={collect}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{ className: `MuiTableRow-root ${className}` }}
      >
        {columns.map((column, i) => {
          if (!column.hide)
            return (
              <TableCell
                onClick={() => handleOnClick(column)}
                className={cls.txt}
                align={column.align}
                key={`atr-row-${rowId}-col-${i}`}
              >
                {column.value}
              </TableCell>
            );
          return null;
        })}
      </ContextMenuTrigger>
      <TableRow>
        <TableCell
          style={{ borderBottom: expandedRowId === rowId ? '1px solid #50525824' : 0 }}
          align='left'
          padding='none'
          colSpan={columns.filter(c => !c.hide).length}
        >
          <Collapse timeout='auto' in={expandedRowId === rowId}>
            {rowId && props.children}
          </Collapse>
        </TableCell>
      </TableRow>
      {actions && actions.length > 0 && (
        <TableRow className={cls.hiddenContextMenuRow}>
          <TableCell className={cls.hiddenContextMenuRow}>
            <ContextMenu id={`atr-row-${rowId}-cm`} style={{ boxShadow: '2px 4px 12px #00000050' }}>
              {actions.map(action => {
                if (!action.hide)
                  return (
                    <MenuItem
                      disabled={action.disabled}
                      data={{ action: action, ...action.data }}
                      onClick={action.handler}
                      key={`atr-row-action-${action.label}`}
                    >
                      {action.label}
                    </MenuItem>
                  );
                return null;
              })}
            </ContextMenu>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
export default withRouter(AccordionRow);

const useStyles = makeStyles(theme => ({
  txt: {
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  icon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  noMargin: {
    margin: '0px',
  },
  reviewButton: {
    zIndex: 1001,
    margin: '6px 0',
    backgroundColor: theme.palette.error.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: '#fff',
    },
  },
  hiddenContextMenuRow: {
    padding: 0,
    border:0
  }
}));
