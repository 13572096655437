//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Tooltip, Icon, Chip } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import { Table, Column } from '../../components/TableComponents';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationsTable({ organizations, status, search }) {
  const cls = useStyles();
  const { goToOrganizationDetails, capFirst } = useTools();

  // Set filtered organizations based on search results
  const [filteredOrganizations, setFilteredOrganizations] = React.useState(organizations);
  React.useEffect(() => {
    let newFilteredOrganizations = organizations;

    if (status && status !== `all`) {
      newFilteredOrganizations = newFilteredOrganizations.filter(
        o => o.status && o.status.toLowerCase().includes(status)
      );
    }

    if (search && search.length) {
      const searchInput = search.toLowerCase();
      newFilteredOrganizations = newFilteredOrganizations.filter(o => {
        return (
          `${o.id}`.includes(searchInput) ||
          (o.name && o.name.toLowerCase().includes(searchInput)) ||
          (o.description && o.description.toLowerCase().includes(searchInput)) ||
          (o.status && o.status.toLowerCase().includes(searchInput))
        );
      });
    }

    setFilteredOrganizations(newFilteredOrganizations);
    // eslint-disable-next-line
  }, [organizations, status, search]);

  const getStatusChipClass = status => {
    switch (status) {
      case `live`:
        return cls.statusLive;
      case `onboarding`:
        return cls.statusOnboarding;
      case `deactivated`:
        return cls.statusDeactivated;
      default:
        return cls.statusUnknown;
    }
  };

  const rowActions = [
    {
      label: `Go To Organization Details`,
      handler: organization => goToOrganizationDetails(organization?.id),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={filteredOrganizations}
        onRowClick={organization => goToOrganizationDetails(organization?.id)}
        rowActions={rowActions}
        defaultOrderBy={'moves'}
        defaultOrder={'desc'}
        tableAriaLabel={`organizations`}
        stickyHeader={true}
      >
        <Column
          name={`name`}
          value={organization => organization?.name}
          label={`Name`}
          tooltip={`Name of the organization`}
          style={{ minWidth: 160 }}
          renderer={organization => (
            <Tooltip title={`Org ID #${organization?.id || `-`}`}>
              <Typography className={cls.rowTxt}>{organization?.name || `-`}</Typography>
            </Tooltip>
          )}
        />

        <Column
          name={`description`}
          value={organization => organization?.description || `-`}
          label={`Description`}
          tooltip={`Description of the organization`}
          style={{ minWidth: 160 }}
        />

        <Column
          name={`rooftops`}
          value={organization => organization?.customers?.length}
          label={`Rooftops`}
          tooltip={`Rooftops in the organization`}
          style={{ minWidth: 100 }}
          renderer={organization => (
            <Tooltip
              title={
                <>
                  {organization?.customers.length ? (
                    organization?.customers
                      ?.filter((c, i) => i <= 10)
                      ?.map((c, i) => (
                        <>
                          {i < 10 ? (
                            <div key={`customer-tip-${c.id}`}>
                              {c.name} (#{c.id})
                            </div>
                          ) : (
                            <div key={`customer-tip-${c.id}`}>...{organization?.customers?.length - 10} more</div>
                          )}
                        </>
                      ))
                  ) : (
                    <div>No Rooftops</div>
                  )}
                </>
              }
            >
              <Typography className={cls.rowTxt}>{organization?.customers?.length || `0`}</Typography>
            </Tooltip>
          )}
        />

        <Column
          name={`moves`}
          value={organization => {
            let totalMoves = 0;
            organization?.customers?.forEach(c => {
              totalMoves += c?.moves_aggregate?.aggregate?.count || 0;
            });
            return totalMoves;
          }}
          label={`30-Day Moves`}
          tooltip={`Count of completed moves from the organization in the past 30 days`}
          style={{ minWidth: 150 }}
        />

        <Column
          name={`status`}
          value={organization => organization?.status}
          label={`Status`}
          tooltip={`Status of the organization`}
          style={{ minWidth: 120 }}
          align={`right`}
          renderer={organization => (
            <Chip
              className={getStatusChipClass(organization?.status)}
              label={capFirst(organization?.status || `unknown`)}
              color='primary'
              size='small'
              icon={
                <>
                  {organization?.status === `live` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      check_circle
                    </Icon>
                  )}
                  {organization?.status === `onboarding` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      contrast
                    </Icon>
                  )}
                  {organization?.status === `deactivated` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      block
                    </Icon>
                  )}
                  {!organization?.status && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      help
                    </Icon>
                  )}
                </>
              }
            />
          )}
        />

        <Column
          name={`active`}
          value={organization => organization?.active}
          label={`Active?`}
          tooltip={`Is the organization active or inactive?`}
          style={{ minWidth: 80 }}
          align={`right`}
          renderer={organization => (
            <Chip
              className={organization?.active ? cls.activeTrueChip : cls.activeFalseChip}
              label={organization?.active ? `Active` : `Inactive`}
              color='primary'
              size='small'
            />
          )}
        />
      </Table>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  rowTxt: {
    lineHeight: 1.43,
    fontSize: 14,
    fontWeight: 400,
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  statusLive: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
  },
  statusOnboarding: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
  },
  statusDeactivated: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
  },
  statusUnknown: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },

  statusIcon: {
    marginLeft: 2,
    marginRight: -2,
  },

  activeTrueChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },
  activeFalseChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));
