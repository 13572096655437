// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { GET_PAYCYCLES } from '@hopdrive/sdk/lib/modules/wallet';

import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core';

import Toolbar from '../../components/Toolbar';
import Filter from '../../components/Filter/Filter';
import ActionsMenu from '../../components/ActionsMenu';

import useDriverPay from './useDriverPay';

import DriverPayContent from './DriverPayContent';

const baseFilters = Object.freeze({
  dateRange: Object.freeze({
    range: `past-3-month`,
    start: dayjs().subtract(3, `month`).startOf(`day`).format(),
    end: dayjs().endOf(`day`).format(),
    utc: false,
  }),
});

const getLocalFilters = () => {
  const localFilters = JSON.parse(localStorage.getItem(`DriverPay-Filters`)) || {};
  return localFilters;
};

// COMPONENT ---------------------------------------------------------------- //

export default function DriverPay() {
  const cls = useStyles();

  const { generateCSVFromPaycycles } = useDriverPay();

  const [filters, setFilters] = React.useState(getLocalFilters());
  const [taxClass, setTaxClass] = React.useState(`1099`);

  const handleApplyFilters = output => {
    setFilters(output);
    localStorage.setItem(`DriverPay-Filters`, JSON.stringify(output));
  };
  const handleClearFilters = output => {
    setFilters(output);
    localStorage.removeItem(`DriverPay-Filters`);
  };
  const handleToggleFilters = output => {
    setFilters(output);
  };

  const { loading, error, data } = useQuery(GET_PAYCYCLES, {
    variables: {
      start: filters?.dateRange?.start || baseFilters?.dateRange?.start,
      end: filters?.dateRange?.end || baseFilters?.dateRange?.end,
    },
    fetchPolicy: `network-only`,
  });

  const paycycles = data?.paycycles || [];

  const actions = [
    {
      label: 'Generate CSV',
      handler: () => generateCSVFromPaycycles(paycycles, taxClass),
    },
  ];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Driver Pay'>
        <div className={cls.flex}>
          {/* <Tooltip placement='top' title='Switch between 1099 or W-2 pay cycles'>
            <div>
              <Button
                className={cls.btn1}
                variant={taxClass === `1099` ? `contained` : `outlined`}
                onClick={() => setTaxClass(`1099`)}
              >
                1099
              </Button>

              <Button
                className={cls.btn2}
                variant={taxClass === `W-2` ? `contained` : `outlined`}
                onClick={() => setTaxClass(`W-2`)}
              >
                W-2
              </Button>
            </div>
          </Tooltip> */}

          <div className={cls.flexStretch} />

          <Filter
            tooltip={`Open Driver Pay Filters`}
            baseFilters={baseFilters}
            localFilters={getLocalFilters()}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
            onToggle={handleToggleFilters}
            config={{
              modules: [`dateRange`],
              moduleOptions: {
                dateRange: {
                  ranges: [
                    `custom`,
                    `future-3-month`,
                    `past-7-day`,
                    `past-1-month`,
                    `past-3-month`,
                    `past-6-month`,
                    `past-1-year`,
                  ],
                  minDate: dayjs().subtract(1, `year`).format(),
                  maxDate: dayjs().add(1, `year`).format(),
                  showUtc: false,
                },
              },
            }}
          />

          <ActionsMenu tooltip={`Open Driver Pay Actions`} actions={actions} />
        </div>
      </Toolbar>

      <DriverPayContent loading={loading} error={error} paycycles={paycycles} taxClass={taxClass} />
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexStretch: {
    flex: 1,
  },

  btn1: {
    height: 36.5,
    borderRadius: `4px 0 0 4px`,
  },
  btn2: {
    height: 36.5,
    borderRadius: `0 4px 4px 0`,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
}));
