import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalAction } from '../../../components/ModalComponents';
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Icon,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import sdk from '@hopdrive/sdk';
import { Button } from '@hopdrive/storybook';

import { useEnrichedPlans } from '../hooks/useEnrichedPlans';
import { useScheduler } from '../providers/SchedulerProvider';
import { useRides } from '../hooks/useRides';

import BigButtonGroup from '../../../components/BigButtonGroup';
import BigButton from '../../../components/BigButton';
import Spacer from '../../../components/Spacer';

import { ReactComponent as IconLyft } from '../../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../../static/uber.svg';
import { ReactComponent as IconHD } from '../../../static/bunny.svg';

import { SimpleLogger } from '../../../utils/SimpleLogger';
import LocationSelect from '../../MovePlanner/LocationSelect';
const { log } = new SimpleLogger({ prefix: 'ScheduleRideModal', enabled: false });

export default function ScheduleRideModal({ open, input }) {
  const cls = useStyles();

  const { buildEnrichedRidePlaceholder, enrichRideLaneAsync } = useRides();
  const { closeRideModal, workingPlan, planLocations, runScheduler, deactivateRide, refreshPlanAndSetSuggestionFlag } =
    useScheduler();
  const { applyMoveChanges, findStartOfBlock } = useEnrichedPlans();

  const { enrichedRide, enrichedParentMove, isAfter = true } = input || {};

  const [ridePartner, setRidePartner] = React.useState(`auto`);
  const [laneType, setLaneType] = React.useState(null);
  const [origLocationId, setOrigLocationId] = React.useState(0);
  const [destLocationId, setDestLocationId] = React.useState(0);
  const [pickupLocation, setPickupLocation] = React.useState(null);
  const [deliveryLocation, setDeliveryLocation] = React.useState(null);
  const [accessorialAddable, setAccessorialAddable] = React.useState(false);
  const [accessorialAmount, setAccessorialAmount] = React.useState(0);
  const [accessorialCode, setAccessorialCode] = React.useState(`ride`);
  const [planLocsWithoutPickup, setPlanLocsWithoutPickup] = React.useState(planLocations);
  const [planLocsWithoutDelivery, setPlanLocsWithoutDelivery] = React.useState(planLocations);
  const [parentIsFirst, setParentIsFirst] = React.useState(false);
  const [parentIsLast, setParentIsLast] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [locationOverrideCheck, setLocationOverrideCheck] = React.useState(false);
  const [locationAllSelectCheck, setLocationAllSelectCheck] = React.useState(false);
  const [chargeableCheck, setChargeableCheck] = React.useState(true);

  //Check if move is triangle
  const parentIsTriangle = getPropValue(enrichedParentMove, 'withOverrides.config.triangle_move') === true;

  // Set initial state values on modal open to set preselects
  React.useEffect(() => {
    if (enrichedRide) {
      // Set the initial ride partner
      const rideWithOverrides = enrichedRide.withOverrides || {};
      const initialRidePartner = rideWithOverrides.ride_type;
      setRidePartner(initialRidePartner || `auto`);

      // Set the initial lane type
      const initialLaneType = enrichedRide.rideLaneType;
      setLaneType(initialLaneType);

      // Set the initial pickup and delivery location ids
      const {
        lane: { origin_location_id, destination_location_id },
      } = rideWithOverrides;
      setOrigLocationId(origin_location_id);
      setPickupLocation({ id: origin_location_id });
      setDestLocationId(destination_location_id);
      setDeliveryLocation({ id: destination_location_id });
      setLocationAllSelectCheck(false);
    } else {
      // Fallback to defaults if no enrichedRide is found
      setRidePartner(`auto`);
      setLaneType(null);
      setOrigLocationId(0);
      setDestLocationId(0);
      setAccessorialAddable(false);
      setAccessorialAmount(0);
      setAccessorialCode(`ride`);
      setLocationOverrideCheck(false);
      setLocationAllSelectCheck(false);
      setChargeableCheck(true);
    }
  }, [enrichedRide, isAfter]);

  // useEffect to prevent the user from creating a circular ride that starts and ends at the same location
  // Filters the planLocations without their counterpart's location
  React.useEffect(() => {
    if (planLocations) {
      const newPlanLocsWithoutPickup = planLocations.filter(loc => loc.id !== origLocationId);
      const newPlanLocsWithoutDelivery = planLocations.filter(loc => loc.id !== destLocationId);
      setPlanLocsWithoutPickup(newPlanLocsWithoutPickup);
      setPlanLocsWithoutDelivery(newPlanLocsWithoutDelivery);
    } else {
      setPlanLocsWithoutPickup([]);
      setPlanLocsWithoutDelivery([]);
    }
  }, [planLocations, origLocationId, destLocationId]);

  // See if parent move is first or last drive in array (will disable prev stop and next stop buttons, resp.)
  React.useEffect(() => {
    if (enrichedParentMove) {
      const enrichedMoveArray = workingPlan.enrichedMoves;
      const enrichedDrivesArray = enrichedMoveArray.filter(o => o.move.move_type === 'drive');
      const enrichedParentMoveId = enrichedParentMove && enrichedParentMove.move ? enrichedParentMove.move.id : null;
      const enrichedParentMoveIndex = enrichedDrivesArray.findIndex(
        enrichedMove => getPropValue(enrichedMove, 'move.id') === enrichedParentMoveId
      );

      // Check if parent move index equals the length of the array minus one
      if (isAfter && enrichedParentMoveIndex === enrichedDrivesArray.length - 1) {
        setParentIsLast(true);
      } else if (!isAfter && enrichedParentMoveIndex === 0) {
        setParentIsFirst(true);
      } else {
        setParentIsLast(false);
        setParentIsFirst(false);
      }
    }
  }, [enrichedParentMove, isAfter, workingPlan]);

  // When the parent move changes, the accessorialAmount needs to update
  React.useEffect(() => {
    if (isAccessorialVisible()) {
      // Find the other enrichedRide.withOverrides
      const additionalRide = getPropValue(enrichedParentMove, `enrichedRideBefore.withOverrides`);

      // Get each possible value
      const actualRideCost = getPropValue(additionalRide, `lyftride.activeAttempt.ride_cost`);
      const estimatedRideCost = getPropValue(additionalRide, `lyftride.activeAttempt.estimated_ride_cost`);
      const rideshareRate =
        additionalRide?.lane?.customer?.raterulegroups?.length &&
        additionalRide?.lane?.customer?.raterulegroups[0]?.ridesharerate &&
        Object.keys(additionalRide?.lane?.customer?.raterulegroups[0]?.ridesharerate).length
          ? additionalRide?.lane?.customer?.raterulegroups[0]?.ridesharerate
          : null;
      let laneRideCost = 0;

      if (rideshareRate) {
        laneRideCost = sdk.rating.thirdParty.getEstimatedReturnRideCost(
          additionalRide?.lane?.distance_miles,
          additionalRide?.lane?.average_drive_speed_mph,
          additionalRide?.lane?.tolls,
          rideshareRate
        );
      } else laneRideCost = getPropValue(additionalRide, `lane.estimated_rideshare_return_cost`);

      // Set the cost thru state variable
      setAccessorialAmount(actualRideCost || estimatedRideCost || laneRideCost || 0);
    }
  }, [enrichedParentMove]);

  //////////////////////// HANDLER FUNCTIONS ////////////////////////

  // Change the ride partner of the selected ride
  const handleRidePartnerChange = value => {
    setRidePartner(value);
  };

  // Automatically handle lane/locations for the ride based on laneType
  const handleLaneTypeChange = value => {
    // Initialize enrichedParentMove location ids and overwrite the values if the parent move exists
    let parentOrigLocationId = 0;
    let parentDestLocationId = 0;
    if (enrichedParentMove) {
      const enrichedParentMoveWithOverrides = enrichedParentMove.withOverrides;
      const { lane: parentLane = {} } = enrichedParentMoveWithOverrides;
      parentOrigLocationId = parentLane.origin_location_id || 0;
      parentDestLocationId = parentLane.destination_location_id || 0;
    }

    // Check for the enrichedMovesArray
    if (workingPlan && workingPlan.enrichedMoves && workingPlan.enrichedMoves.length > 0) {
      // Find the parent move's index
      let moveId =
        enrichedParentMove && enrichedParentMove.withOverrides && enrichedParentMove.withOverrides.id
          ? enrichedParentMove.withOverrides.id
          : 0;
      let index = workingPlan.enrichedMoves.findIndex(em => em.move.id === moveId);

      // Check the location type value
      if (value === `next stop`) {
        const newLoc = findNextLoc(index);
        setOrigLocationId(parentDestLocationId);
        if (newLoc) setDestLocationId(newLoc.id);
        else setDestLocationId(0);
      }
      if (value === `prev stop`) {
        const newLoc = findPrevLoc(index);
        if (newLoc) setOrigLocationId(newLoc.id);
        else setOrigLocationId(0);
        setDestLocationId(parentOrigLocationId);
      }
      if (value === 'return ride') {
        const newLoc = findReturnLoc();
        setOrigLocationId(parentDestLocationId);
        if (newLoc) setDestLocationId(newLoc.id);
        else setDestLocationId(0);
      }
      if (value === `parked car`) {
        const newLoc = findParkedLoc();
        setOrigLocationId(parentDestLocationId);
        if (newLoc) setDestLocationId(newLoc.id);
        else setDestLocationId(0);
      }
    }

    // Check for the custom value
    if (value === `custom`) {
      if (isAfter) {
        setOrigLocationId(parentDestLocationId);
        setDestLocationId(0);
      } else {
        setOrigLocationId(0);
        setDestLocationId(parentOrigLocationId);
      }
    }
    //default this box to unselected if switching lane typee
    setLocationAllSelectCheck(false);

    // Set the location type
    setLaneType(value);
  };

  // Change the location in the location select
  const handleLocationChange = str => event => {
    if (str === `pickup`) {
      const locationId = event.target.value || 0;
      setOrigLocationId(locationId);
    } else {
      const locationId = event.target.value || 0;
      setDestLocationId(locationId);
    }
  };

  // Change the location in the location select
  // const handleChangeFullLocation = (event, pickup) => {
  //   console.log('handleChangeFullLocation', event);
  //   const location = event;
  //   const locationId = location && location.id ? location.id : null;
  //   if (pickup && locationId) {
  //     console.log('PICKUP');
  //     setOrigLocationId(locationId);
  //     setPickupLocation(location);
  //   } else if (locationId) {
  //     setDestLocationId(locationId);
  //     setDeliveryLocation(location);
  //   } else {
  //     console.log('No location selected');
  //   }
  // };

  const handlePickupLocationChangeFullSelect = location => {
    if (!location) return;
    setOrigLocationId(location.id);
    setPickupLocation(location);
  };

  const handleDeliveryLocationChangeFullSelect = location => {
    if (!location) return;
    setDestLocationId(location.id);
    setDeliveryLocation(location);
  };

  // Change the accessorial amount
  // const handleAccessorialAmountChange = event => {
  //   const val = Number(event.target.value);
  //   if (val > 0) setAccessorialAmount(val);
  //   else setAccessorialAmount(``);
  // };

  // Change the selected accessorial code
  // const handleAccessorialCodeChange = event => {
  //   setAccessorialCode(event.target.value);
  // };

  // Save changes and close the modal
  const handleSubmit = async () => {
    // Add a new ride if there isnt one
    if (!enrichedRide) {
      log('Scheduling new ride...');
      setIsSaving(true);
      addNewRide();
      // handleClose();
    }
    // Otherwise, edit the existing ride
    else {
      log('Scheduling existing ride with edits...');
      editRide();
      handleClose();
    }
  };

  // Handle removing a ride from the enrichedMovesArray (Queue it up for deletion if it has an id)
  const handleRemove = () => {
    deactivateRide(enrichedRide, enrichedParentMove.id, isAfter, true);
    handleClose();
  };

  // Cancel changes and close the modal
  const handleClose = () => {
    closeRideModal();
    setRidePartner(`auto`);
    setLaneType(null);
    setOrigLocationId(0);
    setDestLocationId(0);
    setAccessorialAddable(false);
    setAccessorialAmount(0);
    setAccessorialCode(`ride`);
    setLocationOverrideCheck(false);
    setChargeableCheck(true);
  };

  //////////////////////// FRONT-END HELPERS ////////////////////////

  // Check if we should show the "add accessorial" section
  // If you are creating a new ride, and there is already a ride, show the section
  const isAccessorialVisible = () => {
    // Check if the after ride is a second ride
    // Skip all this if a move is triangle
    if (
      !parentIsTriangle &&
      isAfter &&
      enrichedParentMove &&
      enrichedParentMove.enrichedRideBefore &&
      !enrichedParentMove.enrichedRideBefore.isPlaceholder
    ) {
      // Check if the ride is new
      // No enrichedRide means brand-new
      // Suggested means its a placeholder - still new)
      if (!enrichedRide || enrichedRide.isPlaceholder) return true;
    }
    return false;
  };

  // Find the pickup location of the next move in sequence
  const findNextLoc = index => {
    // Add 2 to index (+1 for after ride, +1 for next drive move)
    // THIS ONLY WORKS WHEN THERE IS A RIDE OBJECT
    // TODO: Figure out a way to get the next move more accurately
    //   Brand new rides are not able to get the correct move
    const nextMove = workingPlan.enrichedMoves[index + 2];
    if (!nextMove) return null;
    const nextMoveWithOverrides = nextMove.withOverrides;
    const { lane: nextLane = {} } = nextMoveWithOverrides;
    const newLoc = planLocations.find(pl => pl.id === nextLane.origin_location_id);
    return newLoc || null;
  };

  // Find the delivery location of the previous move in sequence
  const findPrevLoc = index => {
    //Look for previous drive move, starting with parent drive
    for (var i = index - 1; i >= 0; i--) {
      if (workingPlan.enrichedMoves[i]) {
        let prevMoveWithOverrides = workingPlan.enrichedMoves[i].withOverrides;
        if (prevMoveWithOverrides.move_type === 'drive') {
          const { lane: prevLane = {} } = prevMoveWithOverrides;
          const newLoc = planLocations.find(pl => pl.id === prevLane.destination_location_id);
          return newLoc;
        }
      }
    }

    return null;
  };

  // Find the pickup location of the first move in the current block
  const findReturnLoc = () => {
    // Return rides circle back to the start of the current move 'block'
    const firstMoveInBlock = findStartOfBlock(enrichedParentMove, workingPlan.enrichedMoves) || {};
    const firstMoveInBlockWithOverrides = firstMoveInBlock.withOverrides || {};
    const { lane: firstLane = {} } = firstMoveInBlockWithOverrides;
    const newLoc = planLocations.find(pl => pl.id === firstLane.origin_location_id);
    return newLoc || null;
  };

  // Check if the return ride location matches the destination of the parent move
  const doesReturnMatch = () => {
    // Get the return location ID
    const returnLoc = findReturnLoc() || {};
    const { id: returnLocId = 0 } = returnLoc;

    // Check for the parent move
    if (enrichedParentMove) {
      // Get the parent move's destination ID
      const enrichedParentMoveWithOverrides = enrichedParentMove.withOverrides;
      const { lane: parentLane = {} } = enrichedParentMoveWithOverrides;
      const parentLocId = parentLane.destination_location_id || 0;

      // Check if the location IDs match
      if (returnLocId === parentLocId) return true;
    }

    // Fallback to false
    return false;
  };

  // Find the full location object of the parked car based on the saved id
  const findParkedLoc = () => {
    const newLoc = planLocations.find(pl => pl.id === workingPlan.plan.parked_location);
    return newLoc || null;
  };

  // Check the laneType and display the proper tooltip explaination
  const getTooltipExplaination = type => {
    // Next Stop
    if (type === `next stop` && isDisabled(`next stop`)) return `There isn't a next stop to go to.`;
    if (type === `next stop`) return `Schedule a ride to the next location in the sequence.`;

    // Previous Stop
    if (type === `prev stop` && isDisabled(`prev stop`)) return `There isn't a previous stop to come from.`;
    if (type === `prev stop`) return `Schedule a ride from the previous location in the sequence.`;

    // Return Ride
    if (type === `return ride` && isDisabled(`return ride`) && isAfter)
      return `Driver is already at the return location.`;
    if (type === `return ride` && isDisabled(`return ride`) && !isAfter)
      return `Cannot return on rides directly before a move.`;
    if (type === `return ride`) return `Return to the location at the beginning of the current block of moves.`;

    // Parked Car
    if (type === `parked car` && isDisabled(`parked car`) && isAfter) return `There is no parked location set.`;
    if (type === `parked car` && isDisabled(`parked car`) && !isAfter)
      return `Cannot return to parked car directly before a move.`;
    if (type === `parked car`) return `Return to the driver to their parked car location.`;

    // Custom
    return `Create a custom route for the ride. Changing the ${
      isAfter ? `pickup` : `delivery`
    } location may be disabled because the ride must be linked to its parent move.`;
  };

  // Check the laneType and display the proper warning explaination
  const getWarningExplaination = type => {
    if (type === `next stop`) return `"Next Stop" is disabled when there isn't a next stop to go to.`;
    if (type === `prev stop`) return `"Previous Stop" is disabled when there isn't a previous stop to come from.`;
    if (type === `return ride`) {
      if (isAfter) return `"Return Ride" is disabled when the driver is already at the return location.`;
      return `"Return Ride" is always disabled on rides directly before a move.`;
    }
    if (type === `parked car`) {
      if (isAfter)
        return `"Parked Car" is disabled when the parked location isn't set, or if the parked location matches the pickup location.`;
      return `"Parked Car" is always disabled on rides directly before a move.`;
    }
    return ``;
  };

  // Get the disabled state of a button based on its laneType
  const isDisabled = type => {
    if (type === `next stop`) return isAfter && parentIsLast;
    if (type === `prev stop`) return !isAfter && parentIsFirst;
    if (type === `return ride`) return !isAfter || doesReturnMatch();
    if (type === `parked car`) {
      const parkedLocation = getPropValue(workingPlan, 'plan.parked_location');
      return !isAfter || !parkedLocation || origLocationId === parkedLocation;
    }
    return false;
  };

  // Get the disabled state of the TextField based on placement and cancel status
  const isSelectDisabled = (str = `pickup`) => {
    // Check for the parent being canceled
    let parentCancelStarted = false;
    if (enrichedParentMove && enrichedParentMove.withOverrides) {
      const enrichedParentMoveWithOverrides = enrichedParentMove.withOverrides;
      if (enrichedParentMoveWithOverrides.cancel_status === `started`) parentCancelStarted = true;
      if (enrichedParentMoveWithOverrides.cancel_status === `rescheduled`) parentCancelStarted = true;
    }

    // Override disabled selection
    if (laneType === `custom` && locationOverrideCheck) return false;

    // Determine if the selection should be disabled or not
    if (str === `pickup`) {
      if (laneType !== `custom` || (laneType === `custom` && isAfter && !parentCancelStarted)) return true;
      return false;
    }
    if (str === `delivery`) {
      if (laneType !== `custom` || (laneType === `custom` && !isAfter && !parentCancelStarted)) return true;
      return false;
    }
  };

  // Get the disabled state of the "Schedule Ride" button
  const isSaveDisabled = () => {
    // Check each state variable needed
    if (!ridePartner || !laneType || !origLocationId || !destLocationId) return true;

    // Check if accessorial state is valid
    if (isAccessorialVisible()) {
      if (accessorialAddable === null) return true;
      if (accessorialAddable === true && !accessorialAmount) return true;
    }

    // Fallback
    return false;
  };

  //////////////////////// ENRICHMENT ////////////////////////

  // Build accessorial object if the accessorial is addable
  const buildAccessorial = enrichedParentMove => {
    if (enrichedParentMove && accessorialAddable) {
      const enrichedParentMoveId = getPropValue(enrichedParentMove, `move.id`);

      // New accessorial to build
      let newAccessorials = [
        {
          code: accessorialCode,
          status: `approved`,
          notes: `Attaching ride-before cost upon adding ride-after`,
          cost: 0,
          ar_amount: accessorialAmount,
          ap_amount: 0,
        },
      ];
      log(`Built new accessorials for #${enrichedParentMoveId}:`, newAccessorials);

      // Apply the changes (return_ride_id gets nulled out automatically in the upsert)
      applyMoveChanges(enrichedParentMove, { accessorials: newAccessorials });
    }
  };

  const addNewRide = async () => {
    const enrichedMoveArray = workingPlan.enrichedMoves;
    const enrichedParentMoveId = getPropValue(enrichedParentMove, `move.id`);
    const enrichedParentMoveIndex = enrichedMoveArray.findIndex(
      enrichedMove => getPropValue(enrichedMove, 'move.id') === enrichedParentMoveId
    );

    // Build accessorial object if the accessorial is addable
    if (enrichedParentMove && accessorialAddable) buildAccessorial(enrichedParentMove);

    if (isAfter) {
      enrichedParentMove.hasRideAfter = true;
    } else {
      enrichedParentMove.hasRideBefore = true;
    }

    // Build a new placeholder ride and then set it to 'not suggested' after lane has been applied
    // const newRide = buildNewRide(
    //   enrichedParentMove,
    //   pickupLocation,
    //   deliveryLocation,
    //   ridePartner,
    //   laneType,
    //   () => {refreshPlanAndSetSuggestionFlag();
    //   //setIsSaving(false)
    // }
    // );

    const newPlaceholderRide = buildEnrichedRidePlaceholder(enrichedParentMove, ridePartner, null, chargeableCheck);

    const pickupName = pickupLocation ? pickupLocation.name || null : null;
    const deliveryName = deliveryLocation ? deliveryLocation.name || null : null;

    //Callbacks refresh scheduler, set isPlaceholder to false, and set local isSaving state to false
    enrichRideLaneAsync(
      newPlaceholderRide,
      origLocationId,
      destLocationId,
      pickupName,
      deliveryName,
      () => {
        refreshPlanAndSetSuggestionFlag(newPlaceholderRide, false);
        setIsSaving(false);
        handleClose();
      },
      () => {
        refreshPlanAndSetSuggestionFlag(newPlaceholderRide, false);
        setIsSaving(false);
        handleClose();
      }
    );
    //Set the type of the lane ('next stop', 'return ride', etc.)
    newPlaceholderRide.rideLaneType = laneType;

    // Insert the new ride into the enrichedMovesArray at the correct spot
    enrichedMoveArray.splice(isAfter ? enrichedParentMoveIndex + 1 : enrichedParentMoveIndex, 0, newPlaceholderRide);

    //If before ride, check for placeholder before it and delete if it is present
    if (!isAfter) {
      //Check if there is a placeholder move before this move and delete it if there is
      if (enrichedParentMoveIndex !== 0 && enrichedMoveArray[enrichedParentMoveIndex - 1].isPlaceholder) {
        enrichedMoveArray.splice(enrichedParentMoveIndex - 1, 1);
      }
    }
  };

  const editRide = () => {
    // Build accessorial object if the accessorial is addable
    if (enrichedParentMove && isAfter && accessorialAddable) buildAccessorial(enrichedParentMove);

    const enrichedRideWithOverrides = enrichedRide.withOverrides;

    //Set ride_type in overrides if it has been changed
    if (ridePartner !== enrichedRideWithOverrides.ride_type) {
      applyMoveChanges(enrichedRide, { ride_type: ridePartner });
    }

    if (enrichedRideWithOverrides.chargeable !== chargeableCheck) {
      applyMoveChanges(enrichedRide, { chargeable: chargeableCheck });
    }

    const rideLocationChanged = hasRideLocationChanged();
    //Fetch or build new lane
    //Then run scheduler and set enriched ride's 'isPlaceholder' flag to false
    if (rideLocationChanged) {
      //Set location type flag
      //Change rideLaneType flag for after moves
      enrichedRide.rideLaneType = laneType;

      //TODO: get the callback to set the lane but not get deleted first - that way the loading spinner will show up
      //  while the lane is building and then transition into a non-placeholder. Once that is working, delete this
      // enrichedRide.isPlaceholder = false;

      const pickupName = pickupLocation ? pickupLocation.name || null : null;
      const deliveryName = deliveryLocation ? deliveryLocation.name || null : null;

      enrichRideLaneAsync(
        enrichedRide,
        origLocationId,
        destLocationId,
        pickupName,
        deliveryName,
        () => refreshPlanAndSetSuggestionFlag(enrichedRide, false),
        () => refreshPlanAndSetSuggestionFlag(enrichedRide, false)
      );
    } else {
      //If location has not been changed, no need to refetch/build lane.
      //Just set isPlaceholder to false and rerun scheduler
      enrichedRide.rideLaneType = laneType;
      enrichedRide.isPlaceholder = false;
      runScheduler('Ride edited');
    }
  };

  //Check if enrichedRide location has changed
  //This could be done by comparing laneType with Ride's laneType flag but this seems safer
  const hasRideLocationChanged = () => {
    const rideWithOverrides = enrichedRide.withOverrides;
    const rideLane = rideWithOverrides.lane;
    if (!rideLane) {
      //If no ride lane exists, we want to fetch or build a new lane, so we say it has changed
      log('   Error checking ride location');
      return true;
    }
    const { origin_location_id, destination_location_id } = rideLane;
    if (isAfter && destination_location_id === destLocationId) {
      return false;
    } else if (!isAfter && origin_location_id === origLocationId) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Modal open={open}>
      <ModalHeader handleClose={handleClose}>
        Schedule Ride {isAfter ? `After` : `Before`} Drive Move
        {enrichedParentMove ? ` #${enrichedParentMove.id}` : ``}
      </ModalHeader>

      <ModalContent>
        {/* RIDE PARTNER SELECTION */}
        <Typography className={cls.titleTxt}>Select a Ride Partner</Typography>

        <Spacer size='xs' />

        <BigButtonGroup onChange={handleRidePartnerChange} preselect={ridePartner}>
          <BigButton
            id='auto'
            title={`AUTO`}
            tip='Automatically determine the best choice for the rideshare partner when called.'
          />
          <BigButton id='lyft' icon={<IconLyft className={cls.iconLyft} />} />
          <BigButton id='uber' icon={<IconUber className={cls.iconUber} />} />
          <BigButton id='shuttle' icon={<IconHD className={cls.iconHD} />} disabled />
        </BigButtonGroup>

        <Spacer />

        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <Typography className={cls.titleTxt}>Select the Ride's Chargeable Status:</Typography>
          </Grid>
          <Grid item>
            <Checkbox
              className={cls.check}
              color='primary'
              checked={chargeableCheck}
              onChange={() => setChargeableCheck(!chargeableCheck)}
            />
          </Grid>
          <Grid item>
            <Typography className={cls.subtitleTxt}>{chargeableCheck ? `Chargeable` : `Non-Chargeable`}</Typography>
          </Grid>
        </Grid>

        <Spacer size='xs' />

        <Grid className={cls.warningBox} container alignItems='center'>
          <Grid item style={{ height: 16 }}>
            <Icon className={cls.warningIcon}>error_outline</Icon>
          </Grid>
          <Grid item>
            <Typography
              className={cls.warningTxt}
            >{`If this is not a return ride for a customer's move (that is, if it's for our own operational purposes and not the customer's), mark this ride as non-chargeable`}</Typography>
          </Grid>
        </Grid>

        <Spacer />

        {/* RIDE LANE SELECTION */}
        <Typography className={cls.titleTxt}>Select the Ride's Lane Type</Typography>

        <Spacer size='xs' />

        {isDisabled(`next stop`) || isDisabled(`prev stop`) || isDisabled(`return ride`) || isDisabled(`parked car`) ? (
          <Grid className={cls.warningBox} container alignItems='center'>
            <Grid item style={{ height: 16 }}>
              <Icon className={cls.warningIcon}>error_outline</Icon>
            </Grid>
            <Grid item>
              {isDisabled(`next stop`) ? (
                <Typography className={cls.warningTxt}>{getWarningExplaination(`next stop`)}</Typography>
              ) : null}
              {isDisabled(`prev stop`) ? (
                <Typography className={cls.warningTxt}>{getWarningExplaination(`prev stop`)}</Typography>
              ) : null}
              {isDisabled(`return ride`) ? (
                <Typography className={cls.warningTxt}>{getWarningExplaination(`return ride`)}</Typography>
              ) : null}
              {isDisabled(`parked car`) ? (
                <Typography className={cls.warningTxt}>{getWarningExplaination(`parked car`)}</Typography>
              ) : null}
            </Grid>
          </Grid>
        ) : null}

        <Spacer size='sm' />

        <Grid container spacing={2}>
          <Grid item xs>
            {isAfter ? (
              <Tooltip placement='top' title={getTooltipExplaination(`next stop`)}>
                <div>
                  <Button
                    fullWidth
                    disabled={isDisabled(`next stop`)}
                    id='next stop'
                    size='large'
                    color='primary'
                    variant={laneType === `next stop` ? `contained` : `outlined`}
                    onClick={() => handleLaneTypeChange(`next stop`)}
                    startIcon={<Icon className={cls.nextIcon}>reply</Icon>}
                  >
                    Next Stop
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement='top' title={getTooltipExplaination(`prev stop`)}>
                <div>
                  <Button
                    fullWidth
                    disabled={isDisabled(`prev stop`)}
                    id='prev stop'
                    size='large'
                    color='primary'
                    variant={laneType === `prev stop` ? `contained` : `outlined`}
                    onClick={() => handleLaneTypeChange(`prev stop`)}
                    startIcon={<Icon className={cls.btnIcon}>reply</Icon>}
                  >
                    Previous Stop
                  </Button>
                </div>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs>
            <Tooltip placement='top' title={getTooltipExplaination(`return ride`)}>
              <div>
                <Button
                  fullWidth
                  disabled={isDisabled(`return ride`)}
                  id='return ride'
                  size='large'
                  color='primary'
                  variant={laneType === `return ride` ? `contained` : `outlined`}
                  onClick={() => handleLaneTypeChange(`return ride`)}
                  startIcon={<Icon className={cls.returnIcon}>low_priority</Icon>}
                >
                  Return Ride
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip placement='top' title={getTooltipExplaination(`parked car`)}>
              <div>
                <Button
                  fullWidth
                  disabled={isDisabled(`parked car`)}
                  id='parked car'
                  size='large'
                  color='primary'
                  variant={laneType === `parked car` ? `contained` : `outlined`}
                  onClick={() => handleLaneTypeChange(`parked car`)}
                  startIcon={<Icon className={cls.btnIcon}>local_parking</Icon>}
                >
                  Parked Car
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip placement='top' title={getTooltipExplaination(`custom`)}>
              <div>
                <Button
                  fullWidth
                  id='custom'
                  size='large'
                  color='primary'
                  variant={laneType === `custom` ? `contained` : `outlined`}
                  onClick={() => handleLaneTypeChange(`custom`)}
                  startIcon={<Icon className={cls.btnIcon}>alt_route</Icon>}
                >
                  Custom
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Spacer />

        {/* LOCATION SELECTION */}
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs>
            <Typography className={cls.titleTxt}>Select Locations</Typography>
          </Grid>
          {laneType === `custom` && isAfter ? (
            <>
              <Grid item>
                <Checkbox
                  className={cls.check}
                  color='primary'
                  checked={locationOverrideCheck}
                  onChange={() => setLocationOverrideCheck(!locationOverrideCheck)}
                />
              </Grid>
              <Grid item>
                <Typography className={cls.subtitleTxt}>
                  Enable {isAfter ? `Pickup` : `Delivery`} Location Override
                </Typography>
              </Grid>
            </>
          ) : null}
          {laneType === `custom` ? (
            <>
              <Grid item>
                <Checkbox
                  className={cls.check}
                  color='primary'
                  checked={locationAllSelectCheck}
                  onChange={() => setLocationAllSelectCheck(!locationAllSelectCheck)}
                />
              </Grid>
              <Grid item>
                <Typography className={cls.subtitleTxt}>
                  Enable All {isAfter ? `Delivery` : `Pickup`} Locations
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Spacer size='xs' />

        <Grid container spacing={2} alignItems='center'>
          {locationAllSelectCheck && !isAfter ? (
            <Grid item xs>
              <LocationSelect
                valid
                modalVersion={false}
                customerId={
                  enrichedParentMove && enrichedParentMove.move && enrichedParentMove.move.customer_id
                    ? enrichedParentMove.move.customer_id
                    : null
                }
                // planLocations
                locationData={pickupLocation}
                onChange={handlePickupLocationChangeFullSelect}
                size='small'
                variant='outlined'
                label='Pickup Location'
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        gps_fixed
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item xs>
              <TextField
                select
                fullWidth
                disabled={isSelectDisabled(`pickup`)}
                size='small'
                variant='outlined'
                label='Pickup Location'
                value={origLocationId}
                onChange={handleLocationChange(`pickup`)}
                InputProps={{
                  style: { padding: '9px' },
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        gps_not_fixed
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem key={`plan-location-0`} value={0}>
                  <em>Select Location...</em>
                </MenuItem>
                {planLocsWithoutDelivery.map((loc, i) => (
                  <MenuItem key={`plan-location-${i}`} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item>
            <Typography className={cls.toTxt}>TO</Typography>
          </Grid>

          {/* Show the dropdown with all the locations if they have it enabled */}
          {locationAllSelectCheck && isAfter ? (
            <Grid item xs>
              <LocationSelect
                valid
                modalVersion={false}
                customerId={
                  enrichedParentMove && enrichedParentMove.move && enrichedParentMove.move.customer_id
                    ? enrichedParentMove.move.customer_id
                    : null
                }
                // planLocations
                locationData={deliveryLocation}
                onChange={handleDeliveryLocationChangeFullSelect}
                size='small'
                variant='outlined'
                label='Delivery Location'
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        gps_fixed
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item xs>
              <TextField
                select
                fullWidth
                disabled={isSelectDisabled(`delivery`)}
                size='small'
                variant='outlined'
                label='Delivery Location'
                value={destLocationId}
                onChange={handleLocationChange(`delivery`)}
                InputProps={{
                  style: { padding: '9px' },
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        gps_fixed
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem key={`plan-location-0`} value={0}>
                  <em>Select Location...</em>
                </MenuItem>
                {planLocsWithoutPickup.map((loc, i) => (
                  <MenuItem key={`plan-location-${i}`} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>

        {/* ADD ACCESSORIAL OF OTHER RIDE - OLD CODE */}
        {/* {isAccessorialVisible() ? (
          <>
            <>
              <Spacer />

              <Typography className={cls.titleTxt}>
                ACTION REQUIRED - Add Accessorial of Ride-Before (${Number(accessorialAmount).toFixed(2)})
              </Typography>

              <Spacer size='xs' />

              <Typography className={cls.subtitleTxt}>
                In some cases, where a move has 2 rides attached (before and after), the ride-before cost must be added
                to the cost of the move. Keep in mind: The Move Outcome system is also able to add this accessorial with
                additional functionality built into it.
              </Typography>

              <Spacer />

              <Grid container spacing={2}>
                <Grid item xs>
                  <Button
                    fullWidth
                    size='large'
                    color='secondary'
                    variant={accessorialAddable === false ? `contained` : `outlined`}
                    onClick={() => setAccessorialAddable(false)}
                    startIcon={<Icon className={cls.returnIcon}>do_not_disturb_on</Icon>}
                  >
                    Don't Add Accessorial
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    fullWidth
                    size='large'
                    color='primary'
                    variant={accessorialAddable === true ? `contained` : `outlined`}
                    onClick={() => setAccessorialAddable(true)}
                    startIcon={<Icon className={cls.btnIcon}>add_circle</Icon>}
                  >
                    Add Accessorial
                  </Button>
                </Grid>
              </Grid>
            </>

            <Spacer />
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  fullWidth
                  disabled={!accessorialAddable}
                  type='number'
                  size='small'
                  variant='outlined'
                  label='Accessorial Amount'
                  value={accessorialAmount}
                  onChange={handleAccessorialAmountChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          attach_money
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  select
                  fullWidth
                  disabled={!accessorialAddable}
                  size='small'
                  variant='outlined'
                  label='Accessorial Code'
                  value={accessorialCode}
                  onChange={handleAccessorialCodeChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          code
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={`delay`}>Delay</MenuItem>
                  <MenuItem value={`fuel`}>Fuel</MenuItem>
                  <MenuItem value={`partial`}>Partial</MenuItem>
                  <MenuItem value={`surge`}>Surge</MenuItem>
                  <MenuItem value={`tow`}>Tow</MenuItem>
                  <MenuItem value={`ride`}>Ride</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </>
        ) : null} */}
      </ModalContent>

      <ModalFooter>
        <ModalAction color='primary' onClick={() => handleSubmit()} disabled={isSaveDisabled()} loading={isSaving}>
          Schedule Ride
        </ModalAction>

        {enrichedRide && !enrichedRide.isPlaceholder ? (
          <ModalAction color='error' onClick={() => handleRemove()}>
            Remove Ride
          </ModalAction>
        ) : null}

        <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = makeStyles(theme => ({
  iconLyft: {
    height: 36,
    marginBottom: -4,
  },
  iconUber: {
    height: 28,
  },
  iconHD: {
    height: 36,
    marginBottom: -4,
  },
  btnIcon: {
    marginTop: -1,
    fontSize: '24px !important',
  },
  nextIcon: {
    marginTop: -1,
    fontSize: '24px !important',
    transform: 'rotateY(180deg)',
  },
  returnIcon: {
    marginTop: -1,
    fontSize: '24px !important',
    transform: 'rotateX(180deg)',
  },
  titleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitleTxt: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  warningBox: {
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.text.disabled,
  },
  warningTxt: {
    lineHeight: 1.333,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  explainationTxt: {
    marginTop: theme.spacing(0.5),
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  check: {
    padding: 0,
  },
  toTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
