//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import {
  makeStyles,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Icon,
  Tooltip,
} from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  active: `config.appraisals.active`,
  activeTimeframes: `config.appraisals.active_timestamps`,
  allowedMoveTypes: `config.appraisals.allowed_move_types`,
};

// DEFAULTS //
const getDefaultActive = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.active) === false) return false;
  if (getPropValue(overrideRef, option?.active) === true) return true;
  return getPropValue(inheritedRef, option?.active);
};
const getDefaultActiveTimeframes = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.activeTimeframes) || getPropValue(inheritedRef, option?.activeTimeframes);
};
const getDefaultAllowedMoveTypes = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.allowedMoveTypes) || getPropValue(inheritedRef, option?.allowedMoveTypes);
};

// HELPERS //
const cleanseDate = date => {
  return dayjs.utc(date).format(`YYYY/MM/DD`);
};

//////////////////////// COMPONENT ////////////////////////

export default function Appraisals({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [active, setActive] = React.useState(getDefaultActive(overrideRef, inheritedRef));
  const [activeTimeframes, setActiveTimeframes] = React.useState(getDefaultActiveTimeframes(overrideRef, inheritedRef));
  const [allowedMoveTypes, setAllowedMoveTypes] = React.useState(getDefaultAllowedMoveTypes(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setActive(getDefaultActive(null, inheritedRef));
      setActiveTimeframes(getDefaultActiveTimeframes(null, inheritedRef));
      setAllowedMoveTypes(getDefaultAllowedMoveTypes(null, inheritedRef));
    }
  }, [isReset]);

  // Handle active change
  const handleActiveChange = () => {
    // Get the value
    const value = !active;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { appraisals: { active: value } } };
    setActive(value);
    updateMutableRef(changes);

    // Do additional logic
    if (value) handleAddTimeframe(null, `now`);
    else handleActiveTimeframesChange(activeTimeframes.length - 1, `to`)(dayjs.utc().endOf(`month`).format());
  };

  // Handle active timeframes change
  const handleActiveTimeframesChange = (index, key) => event => {
    let inputValue = event;
    if (key === `from`) inputValue = dayjs.utc(event).startOf(`day`).format();
    if (key === `to`) inputValue = dayjs.utc(event).endOf(`day`).format();

    if (inputValue !== `Invalid Date`) {
      // Get the value
      const value = [...activeTimeframes];
      value[index][key] = inputValue;

      // Set the state variable, build the changes and update the mutable ref
      const changes = { config: { appraisals: { active_timestamps: value } } };
      setActiveTimeframes(value);
      updateMutableRef(changes);
    }
  };

  // Handle adding a new timeframe
  const handleAddTimeframe = (from, to) => {
    // Get the value
    const value = [...activeTimeframes];

    // First, we need to check if appraisals is currently active
    // If so, insert a timeframe before the current one
    // Otherwise, just push a new timeframe
    if (active) {
      value.splice(value.length - 1, 0, {
        from: from || dayjs.utc().startOf(`month`).format(),
        to: to || dayjs.utc().endOf(`month`).format(),
      });
    } else {
      value.push({
        from: from || dayjs.utc().startOf(`month`).format(),
        to: to || dayjs.utc().endOf(`month`).format(),
      });
    }

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { appraisals: { active_timestamps: value } } };
    setActiveTimeframes(value);
    updateMutableRef(changes);
  };

  // Handle removing a timeframe
  const handleRemoveTimeframe = index => {
    // Get the value
    const value = [...activeTimeframes];
    value.splice(index, 1);

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { appraisals: { active_timestamps: value } } };
    setActiveTimeframes(value);
    updateMutableRef(changes);
  };

  // Handle allowed move types change
  const handleAllowedMoveTypesChange = type => {
    // Get the value
    const value = [...allowedMoveTypes];
    if (value?.includes(type)) value.splice(value.indexOf(type), 1);
    else value.push(type);

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { appraisals: { allowed_move_types: value } } };
    setAllowedMoveTypes(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Appraisals`}
        tip={`The appraisals module is a way for our clients to appraise vehicles right off the lot. This system is powered by the Blackbook API and allows the client to build their own appraisals based on vehicle trim and condition. The data we get from Blackbook costs a premium and therefore requires us to charge the client a subscription fee per month. Be sure to check the client contract before activating this feature.`}
      />

      <SettingsOption
        contained
        title={`Activation`}
        description={`Activate/Deactivate the appraisals feature for this client. NOTE: Flipping this switch ON will create an active timeframe starting at the beginning of the month. Flipping this switch OFF will end the current active timeframe at the end of the month.`}
        value={active}
        checkReset={() => checkMutableRefField(option?.active)}
        onReset={() => deleteMutableRefField(option?.active, () => setActive(getDefaultActive(null, inheritedRef)))}
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={active} onChange={() => handleActiveChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Active Timeframes`}
        description={`Active timeframes are a set of start and end timestamps that determine a window of vehicles for a client to appraise. We do this to restrict clients from being able to access moves they didnt pay the appraisals subscription for. NOTE: The appraisals feature must be activated in order for the client to appraise vehicles (even if they have a time window that includes the current date).`}
        value={activeTimeframes}
        checkReset={() => checkMutableRefField(option?.activeTimeframes)}
        onReset={() =>
          deleteMutableRefField(option?.activeTimeframes, () =>
            setActiveTimeframes(getDefaultActiveTimeframes(null, inheritedRef))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography className={cls.miniTitle}>Timeframe History</Typography>

          {(active && activeTimeframes?.length > 1) || (!active && activeTimeframes?.length > 0) ? <Spacer /> : null}

          {activeTimeframes?.map((timeframe, i) => {
            if (active && i === activeTimeframes.length - 1) return null;
            return (
              <React.Fragment key={`appraisals-active-timeframe-${i}`}>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item xs>
                    <DatePicker
                      className={cls.date}
                      fullWidth
                      autoOk
                      orientation='portrait'
                      format='MM/dd/yyyy'
                      label={`Start Date ${i + 1}`}
                      size='small'
                      variant='inline'
                      inputVariant='outlined'
                      value={cleanseDate(timeframe?.from)}
                      onChange={handleActiveTimeframesChange(i, `from`)}
                    />
                  </Grid>

                  <Grid item>
                    <Typography className={cls.dateTo}>TO</Typography>
                  </Grid>

                  <Grid item xs>
                    <DatePicker
                      className={cls.date}
                      fullWidth
                      autoOk
                      orientation='portrait'
                      format='MM/dd/yyyy'
                      label={`End Date ${i + 1}`}
                      size='small'
                      variant='inline'
                      inputVariant='outlined'
                      value={cleanseDate(timeframe?.to)}
                      onChange={handleActiveTimeframesChange(i, `to`)}
                    />
                  </Grid>

                  <Grid item>
                    <Tooltip title='Click to remove this timeframe' placement='top'>
                      <IconButton className={cls.removeBtn} onClick={() => handleRemoveTimeframe(i)}>
                        <Icon>cancel_circle</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {(active && i < activeTimeframes.length - 2) || (!active && i < activeTimeframes.length - 1) ? (
                  <Spacer />
                ) : null}
              </React.Fragment>
            );
          })}

          <Spacer />

          <Button fullWidth color='primary' variant='outlined' onClick={() => handleAddTimeframe()}>
            Add New Timeframe
          </Button>

          {active ? (
            <>
              <Spacer size='lg' />

              <Typography className={cls.miniTitle}>Current Timeframe</Typography>

              <Spacer />

              <Grid container spacing={1} alignItems='center'>
                <Grid item xs>
                  <DatePicker
                    fullWidth
                    autoOk
                    orientation='portrait'
                    format='MM/dd/yyyy'
                    label={`Current Start Date`}
                    size='small'
                    variant='inline'
                    inputVariant='outlined'
                    value={cleanseDate(activeTimeframes?.[activeTimeframes?.length - 1]?.from)}
                    onChange={handleActiveTimeframesChange(activeTimeframes?.length - 1, `from`)}
                  />
                </Grid>

                <Grid item>
                  <Typography className={cls.dateTo}>TO PRESENT</Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
        </MuiPickersUtilsProvider>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Allowed Move Types`}
        description={`Clients are restricted to specific move types when appraising vehicles. Generally, we should allow all move types unless specified in the client contract.`}
        value={allowedMoveTypes}
        checkReset={() => checkMutableRefField(option?.allowedMoveTypes)}
        onReset={() =>
          deleteMutableRefField(option?.allowedMoveTypes, () =>
            setAllowedMoveTypes(getDefaultAllowedMoveTypes(null, inheritedRef))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <FormControl>
          <FormControlLabel
            label='Concierge'
            value='concierge'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`concierge`)}
                onClick={() => handleAllowedMoveTypesChange(`concierge`)}
              />
            }
          />

          <FormControlLabel
            label='Operations'
            value='operations'
            control={
              <Checkbox
                className={cls.checkbox}
                color='primary'
                checked={allowedMoveTypes?.includes(`operations`)}
                onClick={() => handleAllowedMoveTypesChange(`operations`)}
              />
            }
          />
        </FormControl>
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  miniTitle: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  date: {
    minWidth: 125,
  },
  dateTo: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  removeBtn: {
    padding: theme.spacing(0),
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
