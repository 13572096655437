// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { makeStyles, Icon } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../Fallbacks';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../ModalComponents';

import { useWorkflow } from '../WorkflowComponents/WorkflowProvider';
import { useMoveOutcome } from './MoveOutcomeProvider';
import MoveOutcomeContent from './MoveOutcomeContent';

const log = false;

// COMPONENT ---------------------------------------------------------------- //

export default function MoveOutcomeModal({ handleClose, input }) {
  const cls = useStyles();

  const { actions } = useWorkflow();
  const { fallbackMove, setMove, getActionHandlerCallback, GET_MOVE_OUTCOME_FORM } = useMoveOutcome();

  const [submitting, setSubmitting] = React.useState(false);

  // When a change is detected on the input, rebuild the move
  React.useEffect(() => {
    if (input && input.move) {
      setMove(input.move);
    } else {
      setMove(fallbackMove);
    }
    // eslint-disable-next-line
  }, [input]);

  // Set the title
  const title = `Move Outcome for #${input?.move?.id}`;

  // Handle the action clicked
  const handleAction = async action => {
    if (action?.label === `Submit`) setSubmitting(true);
    if (action?.handler) await action?.handler(getActionHandlerCallback(action));
    if (action?.label === `Submit`) handleClose();
  };

  // QUERY //
  const { loading, error, data } = useQuery(GET_MOVE_OUTCOME_FORM, { variables: { formType: `move-outcome` } });

  // LOADING STATE //
  if (loading) {
    return (
      <>
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>

        <ModalContent>
          <Loading position='fixed' />
        </ModalContent>

        <ModalFooter />
      </>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching move outcome form:`, error);
    Sentry.captureException(error);
    return (
      <>
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>

        <ModalContent>
          <DefaultErrorFallback message='ERROR FETCHING MOVE OUTCOME FORM' />
        </ModalContent>

        <ModalFooter />
      </>
    );
  }

  // EMPTY STATE //
  if (!data || !data.workflows || !data.workflows.length > 0) {
    return (
      <>
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>

        <ModalContent>
          <DefaultEmptyFallback message='NO MOVE OUTCOME FORM FOUND' />
        </ModalContent>

        <ModalFooter />
      </>
    );
  }

  // DATA STATE //
  const workflow = data.workflows[0];
  log && console.log(`Workflow:`, workflow);

  return (
    <>
      <ModalHeader handleClose={handleClose}>{title}</ModalHeader>

      <ModalContent>
        <MoveOutcomeContent workflow={workflow} />
      </ModalContent>

      <ModalFooter>
        {actions && actions?.length
          ? actions?.map((action, i) => {
              if (action?.hide) return null;
              return (
                <ModalAction
                  key={`modal-action-${i}`}
                  className={cls.action}
                  color={action?.color || `default`}
                  variant={action?.variant || `contained`}
                  onClick={action?.handler ? () => handleAction(action) : () => {}}
                  disabled={action?.disabled || submitting}
                  loading={submitting}
                >
                  {action?.icon ? <Icon className={cls.btnIcon}>{action?.icon}</Icon> : null}
                  {action?.label || `Action`}
                </ModalAction>
              );
            })
          : null}
      </ModalFooter>
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
