import React from 'react';
import { DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

let handleAgree;
let handleDisagree;
let ifConfirmed = () => {};

const ConfirmationModal = props => {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [title, setTitle] = React.useState('Are you sure?');
  const [body, setBody] = React.useState('');
  const [agreeButtonLabel, setAgreeButtonLabel] = React.useState('Agree');
  const [disagreeButtonLabel, setDisagreeButtonLabel] = React.useState('Disagree');
  const [labelledBy, setLabelledBy] = React.useState('confirmation-modal');

  const defaults = {
    title: 'Are you sure?',
    body: '',
    agreeButtonLabel: 'Plan Anyway',
    disagreeButtonLabel: 'Cancel',
    onAgree: () => {},
    onDisagree: () => {},
    labelledBy: 'confirmation-modal',
  };

  const ifConfirmedSimple = (onAgree, o = {}) => {
    let options = Object.assign(defaults, o);

    // Set the callback handlers
    if (typeof onAgree !== 'function') options.onAgree = () => {};

    ifConfirmed(options);
  };

  ifConfirmed = (o = {}) => {
    let options = Object.assign(defaults, o);

    // Set the display properties if soemthing was provided
    if (options.title !== '') setTitle(options.title);
    if (options.body !== '') setBody(options.body);
    if (options.agreeButtonLabel !== '') setAgreeButtonLabel(options.agreeButtonLabel);
    if (options.disagreeButtonLabel !== '') setDisagreeButtonLabel(options.disagreeButtonLabel);
    if (options.labelledBy !== '') setLabelledBy(options.labelledBy);

    // Set the callback handlers
    if (typeof options.onAgree !== 'function') options.onAgree = () => {};
    if (typeof options.onDisagree !== 'function') options.onDisagree = () => {};

    // Open the modal
    setConfirmationModalOpen(true);

    // Build the functions to call the callback then close the modal
    handleAgree = () => {
      if (options.onAgree) options.onAgree();
      setConfirmationModalOpen(false);
    };

    handleDisagree = () => {
      if (options.onDisagree) options.onDisagree();
      setConfirmationModalOpen(false);
    };
  };

  return (
    <Dialog open={confirmationModalOpen} aria-labelledby={labelledBy}>
      <DialogTitle id='change-confirmation-modal'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAgree} color='primary'>
          {agreeButtonLabel}
        </Button>
        <Button onClick={handleDisagree}>{disagreeButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationModal, ifConfirmed };
