import React, { useState } from 'react';
import { makeStyles, Grid, Typography, IconButton, Icon, Tooltip, Menu, MenuItem } from '@material-ui/core';

export default function FaqManagerCard(props) {
    const cls = useStyles();
    const { faq, setModalData, setModalRender } = props;
  
    const [options, setOptions] = useState(null);
  
    const handleOptionsOpen = event => {
      setOptions(event.currentTarget);
    };
    const handleOptionsClose = event => {
      setOptions(null);
    };
  
    const handleEditFAQ = () => {
      setModalData({
        id: faq.id,
        active: faq.active,
        user: faq.user,
        question: faq.question,
        answer: faq.answer,
        new_flag: faq.new_flag,
      });
      setModalRender(`edit faq`);
    };
  
    return (
      <>
        <Grid item xs={12} className={cls.box}>
          <div className={cls.type}>
            <Typography className={cls.question}>{faq.question}</Typography>
            <Typography className={cls.answer}>{faq.answer}</Typography>
          </div>
          <div className={cls.btn}>
            <Tooltip title={`FAQ Options`}>
              <IconButton className={cls.holdCog} onClick={handleOptionsOpen}>
                <Icon className={cls.cog}>settings</Icon>
              </IconButton>
            </Tooltip>
            <Menu
              keepMounted
              id='options'
              anchorEl={options}
              open={Boolean(options)}
              onClose={handleOptionsClose}
              onClick={() => handleOptionsClose()}
            >
              <MenuItem onClick={() => handleEditFAQ()}>Edit&nbsp;FAQ</MenuItem>
            </Menu>
          </div>
        </Grid>
      </>
    );
};

// STYLE //////////////////////////////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles(theme => ({
    box: {
      display: 'flex',
      padding: theme.spacing(1),
      borderRadius: '4px',
      background: '#ffffff',
      '&:hover': {
        background: '#f0f0f0',
      },
      transition: '0.1s',
      cursor: 'pointer',
    },
    type: {
      width: '100%',
    },
    btn: {
      width: '32px',
    },
    question: {
      marginBottom: theme.spacing(0.5),
      lineHeight: 1.3,
      fontSize: '14px',
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    answer: {
      lineHeight: 1.3,
      fontSize: '14px',
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    holdCog: {
      width: '32px',
      height: '32px',
      padding: '4px',
    },
    cog: {
      display: 'block',
      verticalAlign: 'top',
    },
  }));