import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  Chip,
  Icon,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
} from '@material-ui/core';
import RateRuleFormModal from './RateRuleFormModal';
import {Divide} from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

const log = false;

export default function RateRulesTable({ rateRules, onChange = rateRules => {} }) {
  const cls = useStyles();

  const [unsavedRateRules, setUnsavedRateRules] = useState(rateRules);
  const [selectedRateRule, setSelectedRateRule] = useState({});
  const [formModalOpen, setFormModalOpen] = useState(false);

  useEffect(() => {
    if (!rateRules) return;
    log && console.log('RateRulesTable loaded with: ', rateRules);
    rateRules.sort((a, b) => a.class - b.class);

    //Catch the scenario where a legacy customer is being modified with a missing rate rules group
    // we need to set all rate rules to hasChanges true so they get written as children to the new
    // rate rule group when it is all saved.
    for (let index = 0; index < rateRules.length; index++) {
      if (!rateRules[index].rate_rule_group_id || rateRules[index].rate_rule_group_id < 1) {
        rateRules[index].hasChanges = true;
      }
    }

    setUnsavedRateRules(rateRules);
  }, [rateRules]);

  useEffect(() => {
    if (!unsavedRateRules) return;
    if (unsavedRateRules.length < 1) return;
    log && console.log('Unsaved Rate Rules changed to: ', unsavedRateRules);
    const hasChanges = unsavedRateRules.some(o => o.hasChanges);
    if (hasChanges) onChange(unsavedRateRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedRateRules]);

  const handleRowClick = rateRule => event => {
    console.log('handleRowClick()');
    console.log('handleRowClick() -- rateRule:', rateRule);
    setSelectedRateRule(rateRule);
    setFormModalOpen(true);
  };

  const handleAddRateRule = defaultClass => () => {
    const newRateRule = {
      id: unsavedRateRules.length * -1, //Need a unique key for the UI. As long as the id is negative, the editor will remove before mutation (see RateRuleGroupEditor.js:handleSaveChanges())
      customer_id: null,
      rate_rule_group_id: null,
      class: defaultClass,
      distance_end: 0,
      distance_start: 0,
      rate: 0,
      class_3_rate: 0,
      class_4_rate: 0,
      class_5_rate: 0,
      class_6_rate: 0,
      type: 'flat',
      hasChanges: true,
    };
    setUnsavedRateRules([...unsavedRateRules, newRateRule]);
    setSelectedRateRule(newRateRule);
    setFormModalOpen(true);
  };

  const handleRateRuleSave = unsavedRateRule => {
    setFormModalOpen(false);

    unsavedRateRule.rate = Number(unsavedRateRule.rate);
    unsavedRateRule.class_3_rate = Number(unsavedRateRule.class_3_rate);
    unsavedRateRule.class_4_rate = Number(unsavedRateRule.class_4_rate);
    unsavedRateRule.class_5_rate = Number(unsavedRateRule.class_5_rate);
    unsavedRateRule.class_6_rate = Number(unsavedRateRule.class_6_rate);
    unsavedRateRule.distance_start = Number(unsavedRateRule.distance_start);
    unsavedRateRule.distance_end = Number(unsavedRateRule.distance_end);

    //Replace this unsaved rate rule in the rate rules state array
    setUnsavedRateRules(
      unsavedRateRules.map(rateRule => {
        if (rateRule.id === unsavedRateRule.id) return { ...unsavedRateRule, hasChanges: true };
        return rateRule;
      })
    );

    setSelectedRateRule({});
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMER RATES' />}>
      {!rateRules && (
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO MILEAGE BANDS FOUND</Typography>
        </div>
      )}
      {rateRules && (
        <>
          <RateRuleFormModal
            open={formModalOpen}
            rateRule={selectedRateRule}
            onSave={handleRateRuleSave}
            onCancel={() => {
              setFormModalOpen(false);
              setSelectedRateRule({});
            }}
          />
          <div className={cls.divide}>
            <Divide>Stranded Pricing</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='rate rules table'>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell align='center'>Type</TableCell>
                <TableCell align='right'>Class 1 & 2</TableCell>
                <TableCell align='right'>Class 3</TableCell>
                <TableCell align='right'>Class 4</TableCell>
                <TableCell align='right'>Class 5</TableCell>
                <TableCell align='right'>Class 6</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unsavedRateRules
                .filter(o => o.class === 'stranded')
                .map(row => (
                  <TableRow
                    hover
                    key={row.id}
                    className={`${selectedRateRule.id === row.id ? cls.rowIsSelected : null} ${
                      row.hasChanges === true ? cls.rowHasChanges : null
                    }`}
                  >
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                      {row.distance_start}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                      {row.distance_end}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='center'>
                      <Chip
                        label={row.type}
                        variant={row.type === 'per' ? 'outlined' : 'default'}
                        size='small'
                        color='secondary'
                      />
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.rate.toFixed(2)}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_3_rate ? row.class_3_rate.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_4_rate ? row.class_4_rate?.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_5_rate ? row.class_5_rate?.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_6_rate ? row.class_6_rate?.toFixed(2) : 0}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow hover key={-1}>
                <TableCell
                  colSpan={8}
                  align='center'
                  onClick={handleAddRateRule('stranded')}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='primary' size='small' startIcon={<Icon>add_box</Icon>}>
                    Add Row
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={cls.divide}>
            <Divide>Base Pricing</Divide>
          </div>
          <Table className={cls.table} style={{ marginTop: '15px' }} size='small' aria-label='rate rules table'>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell align='center'>Type</TableCell>
                <TableCell align='right'>Class 1 & 2</TableCell>
                <TableCell align='right'>Class 3</TableCell>
                <TableCell align='right'>Class 4</TableCell>
                <TableCell align='right'>Class 5</TableCell>
                <TableCell align='right'>Class 6</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unsavedRateRules
                .filter(o => o.class === 'base')
                .map(row => (
                  <TableRow
                    hover
                    key={row.id}
                    className={`${selectedRateRule.id === row.id ? cls.rowIsSelected : null} ${
                      row.hasChanges === true ? cls.rowHasChanges : null
                    }`}
                  >
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                      {row.distance_start}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem}>
                      {row.distance_end}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='center'>
                      <Chip
                        label={row.type}
                        variant={row.type === 'per' ? 'outlined' : 'default'}
                        size='small'
                        color='secondary'
                      />
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.rate.toFixed(2)}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_3_rate ? row.class_3_rate.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_4_rate ? row.class_4_rate?.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_5_rate ? row.class_5_rate?.toFixed(2) : 0}
                    </TableCell>
                    <TableCell onClick={handleRowClick(row)} className={cls.clickableItem} align='right'>
                      ${row.class_6_rate ? row.class_6_rate?.toFixed(2) : 0}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow hover key={-1}>
                <TableCell colSpan={8} align='center' onClick={handleAddRateRule('base')} className={cls.clickableItem}>
                  <Button variant='text' color='primary' size='small' startIcon={<Icon>add_box</Icon>}>
                    Add Row
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
  },
  rowIsSelected: {
    transition: 'all .2s ease-in-out',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c3c3c3',
      color: '#fff',
    },
  },
  rowHasChanges: {
    backgroundColor: '#ffe9ec',
    fontStyle: 'italic',
    color: '#fff',
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divide: {
    marginTop: '25px', 
    marginBottom: '10px',
  }
}));
