import { gql } from '@apollo/client';

const GET_PENDING_CANCELED_MOVES = gql`
  subscription getPendingCanceledMoves {
    moves(where: { _and: { cancel_status: { _like: "pending" }, active: { _eq: "1" } } }) {
      id
      active
      cancel_status
      move_type
    }
  }
`;

const UPDATE_NOTIFICATION_ASSIGNMENT = gql`
  mutation update_notification_assignment(
    $id: bigint
    $assignedto: String
    $assignedby: String
    $assignedat: timestamptz
  ) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: {
        assignedto: $assignedto
        assignedby: $assignedby
        assignedat: $assignedat
      }
    ) {
      affected_rows
      returning {
        id
        type
        title
        body
        assignedto
        assignedby
        assignedat
        resolvedby
        resolvedat
        createdby
        createdat
        notes
      }
    }
  }
`;

const UPDATE_NOTIFICATION_RESOLVED = gql`
  mutation update_notification_assignment(
    $id: bigint
    $resolvedby: String
    $resolvedat: timestamptz
  ) {
    update_notifications(
      where: { id: { _eq: $id } }
      _set: {
        resolvedby: $resolvedby
        resolvedat: $resolvedat
      }
    ) {
      affected_rows
      returning {
        id
        type
        title
        body
        assignedto
        assignedby
        assignedat
        resolvedby
        resolvedat
        createdby
        createdat
        notes
      }
    }
  }
`;

const GET_NOTIFICATIONS = gql`
subscription admin_topNavBar_getNotifications {
  notifications(order_by: {createdat: desc}) {
    type
    title
    resolvedby
    resolvedat
    id
    body
    createdby
    createdat
    assignedto
    assignedby
    assignedat
    duedate
  }
}
`

const GET_DRIVER_EVENT_LOGS = gql`
  subscription admin_driverInfoEventLogs_getEventLogs($driver_id: bigint) {
    eventlogs(where: { driver_id: { _eq: $driver_id } }, order_by: { event_time_utc: desc }, limit: 100) {
      id
      actiontype {
        id
        name
        description
      }
      event_time_utc
      metadata
      created_at_utc
      user
    }
  }
`;

export {
  GET_PENDING_CANCELED_MOVES,
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION_ASSIGNMENT,
  UPDATE_NOTIFICATION_RESOLVED,
  GET_DRIVER_EVENT_LOGS,
};


