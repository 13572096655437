//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import RegionSelect from '../../components/RegionSelect';
import AddDriverModal from './AddDriverModal';

//////////////////////// COMPONENT ////////////////////////

export default function DriversFilter({
  regionId,
  status,
  taxClass,
  search,
  onRegionChange,
  onStatusChange,
  onTaxClassChange,
  onSearchChange,
  drivers,
  refetch,
}) {
  const [driverModal, setDriverModal] = React.useState({ open: false, input: null, output: null });
  const handleDriverModalOpen = (input = null) => {
    setDriverModal({ open: true, input: input, output: null });
  };
  const handleDriverModalClose = async (output = null) => {
    setDriverModal({ open: false, input: null, output: output });
    if (refetch) refetch();
  };

  const handleRegionChange = event => {
    onRegionChange(event.target.value);
  };
  const handleStatusChange = event => {
    onStatusChange(event.target.value);
  };
  const handleTaxClassChange = event => {
    onTaxClassChange(event.target.value);
  };
  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  return (
    <>
      <AddDriverModal open={driverModal.open} input={driverModal.input} onClose={handleDriverModalClose} />

      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={3}>
          <RegionSelect allowAll label='Region Filter' value={regionId || 0} onChange={handleRegionChange} />
        </Grid>

        <Grid item xs>
          <TextField
            select
            fullWidth
            label='Status Filter'
            placeholder='Select a status...'
            variant='outlined'
            size='small'
            value={status}
            onChange={handleStatusChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    token
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`all`}>All Statuses</MenuItem>
            <MenuItem value={`new`}>New</MenuItem>
            <MenuItem value={`interviewed`}>Interviewed</MenuItem>
            <MenuItem value={`onboarding`}>Onboarding</MenuItem>
            <MenuItem value={`training`}>Training</MenuItem>
            <MenuItem value={`offline`}>Offline</MenuItem>
            <MenuItem value={`online`}>Online</MenuItem>
            <MenuItem value={`rejected`}>Rejected</MenuItem>
            <MenuItem value={`terminated`}>Terminated</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <TextField
            select
            fullWidth
            label='Tax Class Filter'
            placeholder='Select a tax class...'
            variant='outlined'
            size='small'
            value={taxClass}
            onChange={handleTaxClassChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    class
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`both`}>Both</MenuItem>
            <MenuItem value={`1099`}>1099</MenuItem>
            <MenuItem value={`W-2`}>W-2</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Button color='primary' size='large' onClick={() => handleDriverModalOpen({ drivers })}>
            Add&nbsp;Driver
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
