import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import CancelPayoutModal from '../../components/CancelPayoutModal';

//////////////////////// COMPONENT ////////////////////////

export default function PayoutToolbar({ payout, refetch }) {
  const cls = useStyles();

  const [cancelModal, setCancelModal] = React.useState({ open: false, input: null, output: null });
  const handleCancelPayoutModalOpen = (input = null) => {
    setCancelModal({ open: true, input: { payout, refetch }, output: null });
  };
  const handleCancelPayoutModalClose = async (output = null) => {
    setCancelModal({ open: false, input: null, output: output });
  };

  return (
    <>
      <CancelPayoutModal open={cancelModal.open} input={cancelModal.input} onClose={handleCancelPayoutModalClose} />

      <Grid container alignItems='center'>
        <Grid item xs />

        <Grid item>
          <Button
            disabled={!payout.transfer_token || (payout.status !== `new` && payout.status !== `processing`)}
            className={cls.cancelBtn}
            variant='outlined'
            color='error'
            onClick={() => handleCancelPayoutModalOpen()}
          >
            Cancel Transfer
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  cancelBtn: {
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
}));
