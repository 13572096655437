import React from 'react';
import { makeStyles, Typography, TextField, MenuItem, InputAdornment, Icon, Grid } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useQuery, gql } from '@apollo/client';

////////// COMPONENT //////////
export default function PayerSelect(props) {
  const cls = useStyles();

  const { payerId, onPayerChange, payersArray } = props;

  const { loading, error, data } = useQuery(GET_PAYER_CUSTOMERS, { variables: { payersArray: payersArray } });

  const handlePayerChange = event => {
    if (onPayerChange) onPayerChange(event.target.value);
  };

  if (error) console.error(`Failed to retrieve customer config:`, error);

  if (data && data.customers && data.customers.length > 0) {
    // Set payer array
    const payers = data.customers && data.customers.length > 0 ? data.customers : [];

    // Set default selection
    let allowedPayers = payers || [];

    return (
      <Grid item xs>
        <Spacer size='xs' />

        <Typography className={cls.templateTxt}>
          If move will be paid by a third party&nbsp;select the payer here:
        </Typography>

        <Spacer />

        <TextField
          fullWidth
          select
          label='Third Party Payers'
          placeholder='None'
          variant='outlined'
          size='small'
          value={payerId}
          onChange={handlePayerChange}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    currency_exchange
                  </Icon>
                </InputAdornment>
              </>
            ),
          }}
        >
          <MenuItem value={0}>
            <em>No Third Party Payer</em>
          </MenuItem>
          {allowedPayers.map((payer, i) => (
            <MenuItem key={`allowed-payer-${i}`} value={payer.id}>
              {payer.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }
  
  return null;
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  templateTxt: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

////////// GQL //////////
const GET_PAYER_CUSTOMERS = gql`
  query get_payer_customers($payersArray: [bigint!]) {
    customers(where: { id: { _in: $payersArray } }) {
      id
      name
    }
  }
`;
