import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useData } from '../../providers/DataProvider';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import gql from 'graphql-tag';

import useValidation from '../../hooks/useValidation';
import useFormat from '../../hooks/useFormat';
import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormPersonalInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const ctx = useData();
  const cls = useStyles();

  // Hook functions
  const { validateDobDay, validateDobMonth, validateDobYear, validateEmail, validatePhone, validateSSN } =
    useValidation();
  const { formatString, formatPhone, formatSSN } = useFormat();
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  // General form state
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [dobDay, setDobDay] = React.useState(``);
  const [dobMonth, setDobMonth] = React.useState(``);
  const [dobYear, setDobYear] = React.useState(``);
  const [ein, setEin] = React.useState(``);
  const [email, setEmail] = React.useState(``);
  const [emergencyNameOne, setEmergencyNameOne] = React.useState(``);
  const [emergencyNameTwo, setEmergencyNameTwo] = React.useState(``);
  const [emergencyPhoneOne, setEmergencyPhoneOne] = React.useState(``);
  const [emergencyPhoneTwo, setEmergencyPhoneTwo] = React.useState(``);
  const [emergencyRelationshipOne, setEmergencyRelationshipOne] = React.useState(``);
  const [emergencyRelationshipTwo, setEmergencyRelationshipTwo] = React.useState(``);
  const [firstName, setFirstName] = React.useState(``);
  const [gender, setGender] = React.useState(`none`);
  const [lastName, setLastName] = React.useState(``);
  const [manual, setManual] = React.useState(null);
  const [middleName, setMiddleName] = React.useState(``);
  const [phone, setPhone] = React.useState(``);
  const [phoneType, setPhoneType] = React.useState(`none`);
  const [primaryPhone, setPrimaryPhone] = React.useState(``);
  const [ssn, setSsn] = React.useState(``);
  const [veteranStatus, setVeteranStatus] = React.useState(null);

  // Additional form State
  const [entity, setEntity] = React.useState(null);
  const [showSsn, setShowSsn] = React.useState(false);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      dobDay: validateDobDay(dobDay),
      dobMonth: validateDobMonth(dobMonth),
      dobYear: validateDobYear(dobYear),
      ein: (entity === `business` && ein) || entity !== `business` ? true : false,
      email: validateEmail(email),
      emergencyNameOne: emergencyNameOne ? true : false,
      emergencyNameTwo: emergencyNameTwo || emergencyNameTwo === `` ? true : false,
      emergencyPhoneOne: validatePhone(emergencyPhoneOne) ? true : false,
      emergencyPhoneTwo: validatePhone(emergencyPhoneTwo) || emergencyPhoneTwo === `` ? true : false,
      emergencyRelationshipOne: emergencyRelationshipOne ? true : false,
      emergencyRelationshipTwo: emergencyRelationshipTwo || emergencyRelationshipTwo === `` ? true : false,
      firstName: firstName ? true : false,
      gender: gender !== `none`,
      lastName: lastName ? true : false,
      manual: manual === true || manual === false,
      middleName: middleName ? true : false,
      phone: validatePhone(phone),
      phoneType: phoneType !== `none`,
      primaryPhone: validatePhone(primaryPhone) || primaryPhone === ``,
      ssn: (entity === `individual` && validateSSN(ssn)) || entity !== `individual` ? true : false,
      veteranStatus: veteranStatus === true || veteranStatus === false,

      entity: entity === `individual` || entity === `business`,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    if (driver) {
      setEmail(driver.email || ``);
      setEmergencyNameOne(driver.emergency_name_one || ``);
      setEmergencyNameTwo(driver.emergency_name_two || ``);
      setEmergencyPhoneOne(driver.emergency_phone_one || ``);
      setEmergencyPhoneTwo(driver.emergency_phone_two || ``);
      setEmergencyRelationshipOne(driver.emergency_relationship_one || ``);
      setEmergencyRelationshipTwo(driver.emergency_relationship_two || ``);
      setFirstName(driver.first_name || ``);
      setGender(driver.gender || `none`);
      setLastName(driver.last_name || ``);
      setMiddleName(driver.middle_name || ``);
      setPhone(driver.phone || ``);
      setPhoneType(driver.phone_type || `none`);
      setPrimaryPhone(driver.phone === driver.primary_phone ? `` : driver.primary_phone ? driver.primary_phone : ``);
      setSsn(driver.social_security || ``);

      // Set date of birth
      const dob = driver.date_of_birth ? driver.date_of_birth.split(`-`) : null;
      if (dob && dob.length) {
        var localDobYear = dob[0];
        var localDobMonth = dob[1];
        var localDobDay = dob[2];
      }
      setDobYear(localDobYear || ``);
      setDobMonth(localDobMonth || ``);
      setDobDay(localDobDay || ``);

      // Set ein and entity conditionally
      if (driver.employer_identifier) {
        setEin(driver.employer_identifier || ``);
        setEntity(`business`);
      } else if (driver.social_security) {
        setEntity(`individual`);
      } else {
        setEntity(null);
      }

      // Set manual trained
      if (driver.config.attributes.manual === true || driver.config.attributes.manual === false) {
        setManual(driver.config.attributes.manual);
      }

      // Set veteran status
      if (driver.veteran.status === true || driver.veteran.status === false) {
        setVeteranStatus(driver.veteran.status);
      }
    }
  }, [driver]);

  // Mutation handler
  const savePersonal = async () => {
    const variables = {
      driverId: driver.id,
      driverInput: {
        config: {
          ...driver.config,
          attributes: {
            ...driver.config.attributes,
            manual: manual,
          },
        },
        phone_type: phoneType !== `none` ? phoneType : null,
      },
      userInput: {
        display_name: `${firstName} ${lastName}` || null,
        email: email || null,
        phone: phone || null,
      },
      driverdetailInput: {
        date_of_birth: dobYear && dobMonth && dobDay ? `${dobYear}-${dobMonth}-${dobDay}` : null,
        emergency_name_one: emergencyNameOne || null,
        emergency_name_two: emergencyNameTwo || null,
        emergency_phone_one: emergencyPhoneOne || null,
        emergency_phone_two: emergencyPhoneTwo || null,
        emergency_relationship_one: emergencyRelationshipOne || null,
        emergency_relationship_two: emergencyRelationshipTwo || null,
        employer_identifier: ein || null,
        first_name: firstName || null,
        gender: gender !== `none` ? gender : null,
        last_name: lastName || null,
        middle_name: middleName || null,
        primary_phone: primaryPhone || phone || null,
        social_security: ssn || null,
        veteran: {
          ...driver.veteran,
          status: veteranStatus,
        },
      },
    };

    log && console.log(`savePersonal - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_PERSONAL,
        variables: variables,
      });

      if (
        getPropValue(res, `data.update_drivers.affected_rows`) &&
        getPropValue(res, `data.update_users.affected_rows`) &&
        getPropValue(res, `data.update_driverdetails.affected_rows`)
      ) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    const res = await savePersonal();
    if (res && res.success === true) {
      setIsSaving(false);
      toast.success(`Information saved!`, { autoClose: 2500 });
      if (proceed) {
        proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
        return;
      }
      if (onClose) {
        onClose();
        return;
      }
    }
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        editMode={editMode}
        setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* IDENTITY INFORMATION */}

          <Typography className={cls.title}>Identity</Typography>
          <Typography className={cls.subtitle}>Top-level info that helps us identify you.</Typography>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>First Name&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter legal first name...'
                variant='outlined'
                size='small'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                onBlur={() => setFirstName(formatString(firstName))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.firstName === false}
                helperText={validation.firstName === false ? `First name is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Middle Name (or Initial)&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter legal middle name...'
                variant='outlined'
                size='small'
                value={middleName}
                onChange={e => setMiddleName(e.target.value)}
                onBlur={() => setMiddleName(formatString(middleName))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.middleName === false}
                helperText={validation.middleName === false ? `Middle name or initial is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Last Name&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter legal last name...'
                variant='outlined'
                size='small'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onBlur={() => setLastName(formatString(lastName))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.lastName === false}
                helperText={validation.lastName === false ? `Last name is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Date of Birth (MM/DD/YYYY)&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='MM'
                    variant='outlined'
                    size='small'
                    value={dobMonth}
                    onChange={e => setDobMonth(e.target.value)}
                    onBlur={() => setDobMonth(formatString(dobMonth, { removeSpaces: true }))}
                    inputProps={{ maxLength: 2 }}
                    error={validation.dobMonth === false}
                    helperText={validation.dobMonth === false ? `Month is invalid` : null}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='DD'
                    variant='outlined'
                    size='small'
                    value={dobDay}
                    onChange={e => setDobDay(e.target.value)}
                    onBlur={() => setDobDay(formatString(dobDay, { removeSpaces: true }))}
                    inputProps={{ maxLength: 2 }}
                    error={validation.dobDay === false}
                    helperText={validation.dobDay === false ? `Day is invalid` : null}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='YYYY'
                    variant='outlined'
                    size='small'
                    value={dobYear}
                    onChange={e => setDobYear(e.target.value)}
                    onBlur={() => setDobYear(formatString(dobYear, { removeSpaces: true }))}
                    inputProps={{ maxLength: 4 }}
                    error={validation.dobYear === false}
                    helperText={validation.dobYear === false ? `Year is invalid` : null}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={8} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Gender&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select gender...'
                variant='outlined'
                size='small'
                value={gender}
                onChange={e => setGender(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        man
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.gender === false}
                helperText={validation.gender === false ? `Select a gender` : null}
              >
                <MenuItem value={`none`}>
                  <div className={cls.placeholder}>Select a gender...</div>
                </MenuItem>
                <MenuItem value={`male`}>Male</MenuItem>
                <MenuItem value={`female`}>Female</MenuItem>
                <MenuItem value={`other`}>Other</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* IDENTIFICATION NUMBER INFORMATION */}

          <Typography className={cls.title}>Identification Number</Typography>
          <Typography className={cls.subtitle}>
            Please specify if you are being paid as an individual or if you are working under a business.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Are you being paid as an individual or a business entity?&nbsp;
                </Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              {validation.entity === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={entity === `individual`}
                  onChange={() => setEntity(`individual`)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>I am being paid as an individual.</Typography>
              </div>
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={entity === `business`}
                  onChange={() => setEntity(`business`)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>I am being paid under a business.</Typography>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Social Security (XXX-XX-XXXX)&nbsp;</Typography>
                {entity === `individual` ? <Typography className={cls.labelRequired}>*</Typography> : null}
                <Tooltip placement='top' title='Please enter your full social security number.'>
                  <Icon className={cls.labelHelp}>help</Icon>
                </Tooltip>
              </div>
              <TextField
                disabled={!editMode || entity !== `individual`}
                required
                fullWidth
                type={showSsn ? `text` : `password`}
                placeholder='Enter social security number...'
                variant='outlined'
                size='small'
                value={ssn}
                onChange={e => setSsn(e.target.value)}
                onFocus={() => setShowSsn(true)}
                onBlur={() => {
                  setSsn(formatSSN(ssn));
                  setShowSsn(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        fingerprint
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.ssn === false}
                helperText={validation.ssn === false ? `Social security is invalid` : null}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Employer Identifier&nbsp;</Typography>
                {entity === `business` ? <Typography className={cls.labelRequired}>*</Typography> : null}
                <Tooltip placement='top' title='Please enter your FEIN.'>
                  <Icon className={cls.labelHelp}>help</Icon>
                </Tooltip>
              </div>
              <TextField
                disabled={!editMode || entity !== `business`}
                required
                fullWidth
                placeholder='Enter your employer identification number...'
                variant='outlined'
                size='small'
                value={ein}
                onChange={e => setEin(e.target.value)}
                onBlur={() => setEin(formatString(ein))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        corporate_fare
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.ein === false}
                helperText={validation.ein === false ? `Employer identifier is invalid` : null}
              />
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* APP INFORMATION */}

          <Typography className={cls.title}>App Credentials</Typography>
          <Typography className={cls.subtitle}>
            The credentials you enter below will allow you to log into our driver app.
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Phone Number&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={phone}
                onChange={e => setPhone(e.target.value)}
                onBlur={() => setPhone(formatPhone(phone))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.phone === false}
                helperText={validation.phone === false ? `Phone number is invalid` : null}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Phone Type&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Enter phone type...'
                variant='outlined'
                size='small'
                value={phoneType}
                onChange={e => setPhoneType(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone_callback
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.phoneType === false}
                helperText={validation.phoneType === false ? `Select a phone type` : null}
              >
                <MenuItem value={`none`}>
                  <div className={cls.placeholder}>Select a phone type...</div>
                </MenuItem>
                <MenuItem value={`android`}>Android</MenuItem>
                <MenuItem value={`ios`}>iPhone</MenuItem>
                <MenuItem value={`other`}>Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Email Address&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={() => setEmail(formatString(email))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        email
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.email === false}
                helperText={validation.email === false ? `Email address is invalid` : null}
              />
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* PRIMARY PHONE INFORMATION */}

          <Typography className={cls.title}>Primary Phone</Typography>
          <Typography className={cls.subtitle}>
            Your primary phone number is the number we will send to the IRS for tax purposes. If you wish to use the
            phone number you already entered above, please leave this field blank.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Primary Phone Number&nbsp;</Typography>
                <Tooltip placement='top' title='You may leave this field blank to use the number you entered above.'>
                  <Icon className={cls.labelHelp}>help</Icon>
                </Tooltip>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter primary phone number...'
                variant='outlined'
                size='small'
                value={primaryPhone}
                onChange={e => setPrimaryPhone(e.target.value)}
                onBlur={() => setPrimaryPhone(formatPhone(primaryPhone))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        contact_phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.primaryPhone === false}
                helperText={validation.primaryPhone === false ? `Primary phone is invalid` : null}
              />
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* EMERGENCY INFORMATION */}

          <Typography className={cls.title}>Emergency Contacts</Typography>
          <Typography className={cls.subtitle}>
            Please provide us with a trusted contact or two in the event of an emergency.
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 1 - Name&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                required
                disabled={!editMode}
                fullWidth
                placeholder='Enter the name of an emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyNameOne}
                onChange={e => setEmergencyNameOne(e.target.value)}
                onBlur={() => setEmergencyNameOne(formatString(emergencyNameOne))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyNameOne === false}
                helperText={validation.emergencyNameOne === false ? `Emergency contact name is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 1 - Relationship&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                required
                disabled={!editMode}
                fullWidth
                placeholder='Enter your relation to the emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyRelationshipOne}
                onChange={e => setEmergencyRelationshipOne(e.target.value)}
                onBlur={() => setEmergencyRelationshipOne(formatString(emergencyRelationshipOne))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyRelationshipOne === false}
                helperText={
                  validation.emergencyRelationshipOne === false ? `Emergency contact relationship is invalid` : null
                }
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 1 - Phone&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter the phone number of the emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyPhoneOne}
                onChange={e => setEmergencyPhoneOne(e.target.value)}
                onBlur={() => setEmergencyPhoneOne(formatPhone(emergencyPhoneOne))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyPhoneOne === false}
                helperText={validation.emergencyPhoneOne === false ? `Emergency contact phone is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 2 - Name&nbsp;</Typography>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter the name of an emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyNameTwo}
                onChange={e => setEmergencyNameTwo(e.target.value)}
                onBlur={() => setEmergencyNameTwo(formatString(emergencyNameTwo))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyNameTwo === false}
                helperText={validation.emergencyNameTwo === false ? `Emergency contact name is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 2 - Relationship&nbsp;</Typography>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter your relation to the emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyRelationshipTwo}
                onChange={e => setEmergencyRelationshipTwo(e.target.value)}
                onBlur={() => setEmergencyRelationshipTwo(formatString(emergencyRelationshipTwo))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyRelationshipTwo === false}
                helperText={
                  validation.emergencyRelationshipTwo === false ? `Emergency contact relationship is invalid` : null
                }
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Emergency Contact 2 - Phone&nbsp;</Typography>
              </div>
              <TextField
                disabled={!editMode}
                fullWidth
                placeholder='Enter the phone number of the emergency contact...'
                variant='outlined'
                size='small'
                value={emergencyPhoneTwo}
                onChange={e => setEmergencyPhoneTwo(e.target.value)}
                onBlur={() => setEmergencyPhoneTwo(formatPhone(emergencyPhoneTwo))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.emergencyPhoneTwo === false}
                helperText={validation.emergencyPhoneTwo === false ? `Emergency contact phone is invalid` : null}
              />
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* MANUAL INFORMATION */}

          <Typography className={cls.title}>Manual Transmission</Typography>
          <Typography className={cls.subtitle}>
            Some vehicles have a manual transmission, so it is helpful for us to know if you can drive stick. If so, you
            can accept drives with vehicles that have a manual transmission.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Can you drive a manual?&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              {validation.manual === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={manual === true}
                  onChange={() => setManual(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  Yes, I am able to drive vehicles with a manual transmission.
                </Typography>
              </div>
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={manual === false}
                  onChange={() => setManual(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, I am unable to drive vehicles with a manual transmission.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Spacer size='xxl' />

          {/* VETERAN INFORMATION */}

          <Typography className={cls.title}>Veteran Status</Typography>
          <Typography className={cls.subtitle}>
            In some cases, veterans may receive additional benefits. Please specify your veteran status below to earn
            these benefits.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>Are you a veteran?&nbsp;</Typography>
                <Typography className={cls.labelRequired}>*</Typography>
              </div>
              {validation.veteranStatus === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={veteranStatus === true}
                  onChange={() => setVeteranStatus(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>Yes, I am a veteran of the United States of America.</Typography>
              </div>
              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={veteranStatus === false}
                  onChange={() => setVeteranStatus(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, I am not a veteran of the United States of America.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelRequired: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.error.main,
    [theme.breakpoints.down('xs')]: {
      margin: '2px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },

  errorMessage: {
    marginLeft: 14,
    lineHeight: 1.25,
    fontSize: 12,
    color: theme.palette.error.main,
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },

  spacer: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_PERSONAL = gql`
  mutation driver_savePersonal(
    $driverId: bigint!
    $driverInput: drivers_set_input!
    $userInput: users_set_input!
    $driverdetailInput: driverdetails_set_input!
  ) {
    update_drivers(where: { id: { _eq: $driverId } }, _set: $driverInput) {
      affected_rows
      returning {
        id
        config
        phone_type
        updated_at
      }
    }
    update_users(where: { driver: { id: { _eq: $driverId } } }, _set: $userInput) {
      affected_rows
      returning {
        id
        display_name
        email
        phone
        updated_at
      }
    }
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailInput) {
      affected_rows
      returning {
        driver_id
        date_of_birth
        emergency_name_one
        emergency_name_two
        emergency_phone_one
        emergency_phone_two
        emergency_relationship_one
        emergency_relationship_two
        employer_identifier
        first_name
        gender
        last_name
        middle_name
        primary_phone
        social_security
        veteran
        updated_at
      }
    }
  }
`;
