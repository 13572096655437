//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import { makeStyles, Container, Grid } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';

import { GET_INVOICE_DETAILS, GET_INVOICE_LOGS } from './gql';

import { useData } from '../../providers/DataProvider';
import { useInvoices } from './useInvoices';
import { useTools } from '../../hooks/useTools';

import Toolbar from '../../components/Toolbar';

import ARReviewModal from '../../components/ARReview/ARReviewModal';
import ARMarkPaidModal from '../../components/ARMarkPaidModal';
import ARLogsModal from '../../components/ARLogsModal';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import { InvoiceInfo, InvoiceProducts, InvoiceTable, InvoicePayments, InvoiceCalculations } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceContent({ invoiceId, setSelectedCustomerIds, invoiceIdInURL }) {
  const cls = useStyles();
  const ctx = useData();
  const { forceInvoiceStatus, reprocessInvoice, generateCSV, generatePDF } = useInvoices();
  const { goToCustomerDetails } = useTools();

  const [checkedArmoves, setCheckedArmoves] = React.useState([]);

  const { loading, error, data, refetch } = useQuery(GET_INVOICE_DETAILS, {
    variables: { invoiceId: invoiceId || 0 },
  });

  React.useEffect(() => {
    if (data && data.arinvoices && Array.isArray(data.arinvoices) && data.arinvoices.length > 0) {
      let invoice = data.arinvoices[0];
      if (invoice && invoice.id) {
        localStorage.setItem('invoice-id', invoice.id);
      }
      if (invoice && invoice.customer && invoice.customer.id && invoiceIdInURL) {
        localStorage.setItem('invoice-customer-id-array', [invoice.customer.id]);
        setSelectedCustomerIds([invoice.customer.id]);
      }
      localStorage.setItem('invoice-fold-id', 0);
    }
  }, [data, setSelectedCustomerIds, invoiceIdInURL]);

  // Review Modal
  const [arReviewModal, setARReviewModal] = React.useState({ open: false });
  const handleARReviewModalOpen = (armove = null) => {
    setARReviewModal({
      ...arReviewModal,
      open: true,
      input: armove,
    });
  };
  const handleARReviewModalClose = (output = null) => {
    if (output) setARReviewModal({ ...arReviewModal, open: false, output: output });
    else setARReviewModal({ ...arReviewModal, open: false });
  };

  // Mark Paid Modal
  const [arMarkPaidModal, setARMarkPaidModal] = React.useState({ open: false });
  const handleARMarkPaidModalOpen = (invoice = {}) => {
    const armoves =
      invoice.armoves && invoice.armoves.length > 0
        ? invoice.armoves.filter(armove => checkedArmoves.includes(armove.id))
        : [];
    setARMarkPaidModal({
      ...arMarkPaidModal,
      open: true,
      input: {
        invoice: invoice,
        armoves: armoves,
      },
    });
  };
  const handleARMarkPaidModalClose = (output = null) => {
    if (output) {
      if (output.success === true) setCheckedArmoves([]);
      setARMarkPaidModal({ ...arMarkPaidModal, open: false, output: output });
    } else setARMarkPaidModal({ ...arMarkPaidModal, open: false });
    handleRefetch();
  };

  // Logs Modal
  const [arLogsModal, setARLogsModal] = React.useState({ open: false });
  const handleARLogsModalOpen = (type = null, events = null) => {
    setARLogsModal({
      ...arLogsModal,
      open: true,
      input: {
        type: type,
        events: events,
      },
    });
  };
  const handleARLogsModalClose = (output = null) => {
    if (output) setARLogsModal({ ...arLogsModal, open: false, output: output });
    else setARLogsModal({ ...arLogsModal, open: false });
  };

  // Handle getting invoice logs
  const handleARLogs = async invoiceId => {
    try {
      const res = await ctx.apolloClient.query({
        query: GET_INVOICE_LOGS,
        variables: { invoiceId: invoiceId },
      });
      if (res && res.data && res.data.arevents && res.data.arevents.length > 0) {
        handleARLogsModalOpen(`invoice`, res.data.arevents);
      }
    } catch (err) {
      toast.error(`Failed to fetch move logs!`);
      console.error(`Failed to fetch move logs:`, err);
    }
  };

  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.content}>
        <Container maxWidth='lg'>
          <Toolbar back title='Invoice Details' />
          <Spacer />
          <Loading absolute />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching invoice details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.content}>
        <Container maxWidth='lg'>
          <Toolbar back title='Invoice Details' />
          <Spacer />
          <DefaultErrorFallback paper message='ERROR FETCHING INVOICE DETAILS' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.arinvoices || !data.arinvoices.length > 0) {
    return (
      <div className={cls.content}>
        <Container maxWidth='lg'>
          <Toolbar back title='Invoice Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback paper message='NO INVOICE SELECTED' />
        </Container>
      </div>
    );
  }

  // DATA STATE //
  const invoice = data.arinvoices[0];

  // Separate ARMoves and Products
  let armoves = invoice.armoves || [];
  let products = invoice.armoves || [];
  if (armoves.length > 0) armoves = armoves.filter(armove => armove.type === `move`);
  if (products.length > 0)
    products = products.filter(
      armove => armove.type === `product` || armove.type === `one-time` || armove.type === `prepaid`
    );

  const invoiceActions = [
    {
      label: `Force Status to 'Acquiring'`,
      handler: () => forceInvoiceStatus(invoice.id, `acquiring`),
      hide: invoice.status === `acquiring`,
    },
    {
      label: `Force Status to 'Processing'`,
      handler: () => forceInvoiceStatus(invoice.id, `processing`),
      hide: invoice.status === `processing`,
    },
    {
      label: `Force Status to 'Closed'`,
      handler: () => forceInvoiceStatus(invoice.id, `closed`),
      hide: invoice.status === `closed`,
    },
    {
      label: `Mark Selected as Paid`,
      handler: () => handleARMarkPaidModalOpen(invoice),
      disabled: !checkedArmoves || checkedArmoves.length < 1,
    },
    {
      label: `Post Invoice to Accounting System`,
      handler: () => reprocessInvoice(invoice, `accountingInvoice`, `Invoice posted!`),
    },
    {
      label: `Resend Email to Customer`,
      handler: () => reprocessInvoice(invoice, `notify`, `Customer emailed!`),
    },
    {
      label: `Rerun Autopay for Invoice`,
      handler: () => reprocessInvoice(invoice, `pay`, `Customer charged for invoice!`),
      disabled: !invoice.accounting_num,
      hide: invoice.customer ? !invoice.customer.auto_pay : true,
    },
    {
      label: `Repost Payments to Accounting System`,
      handler: () => reprocessInvoice(invoice, `accountingPayment`, `Payments posted to accounting system!`),
      hide: invoice.arpayments ? invoice.arpayments.length === 0 : true,
    },
    {
      label: `Repost Processing Fees to Accounting System`,
      handler: () => reprocessInvoice(invoice, `postProcessingFee`, `Posted processing fees to accounting system!`),
      hide: invoice.arpayments ? invoice.arpayments.length === 0 : true,
    },
    {
      label: `Generate CSV`,
      handler: () => generateCSV(invoice),
    },
    {
      label: `Generate PDF`,
      handler: () => generatePDF(invoice),
    },
    {
      label: `View AR Invoice Logs`,
      handler: () => handleARLogs(invoice.id),
    },
    {
      label: `View Customer Details`,
      handler: () => goToCustomerDetails(invoice?.customer?.id),
    }
  ];

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INVOICE CONTENT' />}>
      <ARReviewModal open={arReviewModal.open} onClose={handleARReviewModalClose} input={arReviewModal.input} />
      <ARMarkPaidModal open={arMarkPaidModal.open} onClose={handleARMarkPaidModalClose} input={arMarkPaidModal.input} refetch={handleRefetch} />
      <ARLogsModal open={arLogsModal.open} onClose={handleARLogsModalClose} input={arLogsModal.input} />

      <div className={cls.content}>
        <Container maxWidth='lg'>
          <Toolbar back title='Invoice Details' refetch={handleRefetch} actions={invoiceActions} />

          <Spacer />

          <InvoiceInfo invoice={invoice} armoves={armoves} />

          <Spacer />

          {products && products.length > 0 ? (
            <>
              <InvoiceProducts
                invoice={invoice}
                products={products}
                checkedArmoves={checkedArmoves}
                setCheckedArmoves={setCheckedArmoves}
              />
              <Spacer />
            </>
          ) : null}

          {armoves && armoves.length > 0 ? (
            <>
              <InvoiceTable
                invoice={invoice}
                armoves={armoves}
                checkedArmoves={checkedArmoves}
                setCheckedArmoves={setCheckedArmoves}
                handleARReviewModalOpen={handleARReviewModalOpen}
                handleARLogsModalOpen={handleARLogsModalOpen}
                handleRefetch={handleRefetch}
              />
              <Spacer />
            </>
          ) : null}

          <Grid container spacing={2}>
            <Grid item md={8} sm={6} xs={12}>
              <InvoicePayments invoice={invoice} />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InvoiceCalculations invoice={invoice} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
}));
