import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';
import { GET_CUSTOMER_USERS } from './gql';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';


import { CustomerUsersFilter, CustomerUsersTable } from './index';

//////////////////////// COMPONENT ////////////////////////
function CustomerUsers(props) {
    const cls = useStyles();
    // Call the GET_USERS query (with options passed in) and get back the data + states
    const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_USERS);

    const [search, setSearch] = React.useState(null)

    // Handle refetch callback to pass as props
    const handleRefetch = () => {
        refetch();
    };

      // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Customer Users'>
          <CustomerUsersFilter />
        </Toolbar>
        <Loading fixed />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching users:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Customer Users' refetch={handleRefetch}>
          <CustomerUsersFilter refetch={handleRefetch} />
        </Toolbar>
        <DefaultErrorFallback message='ERROR FETCHING USERS' />
      </div>
    );
  }

    // EMPTY STATE //
    if (!data?.users?.length > 0) {
        return (
            <div className={cls.root}>
              <Toolbar fullscreen title='Customer Users' refetch={handleRefetch}>
                <CustomerUsersFilter refetch={handleRefetch} />
                <CustomerUsersFilter />
              </Toolbar>
              <DefaultEmptyFallback message='NO USERS FOUND' />
            </div>
        );
    }

    // DATA STATE //
    const users = data.users

    return (
        <div className={cls.root}>
          <Toolbar fullscreen title='Customer Users' refetch={handleRefetch}>
            <CustomerUsersFilter search={search} onSearchChange={setSearch} />
          </Toolbar>
        {/* If table body has an error, show error message instead of table */}
          <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING USERS TABLE' />}>
            <CustomerUsersTable users={users} search={search} refetch={handleRefetch}/>
          </Sentry.ErrorBoundary>
        </div>
  );

}

//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(7),
    },
  }));
  //////////////////////// EXPORT ////////////////////////
  export default CustomerUsers;