import React from 'react';

import ManageAccessorialsModal from '../../../components/ManageAccessorialsModal/ManageAccessorialsModal';
import { ManageRideModal } from '../../../components/ManageRide';
import ParkedCarModal from '../components/ParkedCarModal';

const ModalContext = React.createContext({});

function ModalProvider({ children }) {
  // Determine if the modalis visible (to stop the timer)
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  // Manage Accessorials Modal
  const [manageAccessorialsModal, setManageAccessorialsModal] = React.useState({ open: false });
  const handleManageAccessorialsModalOpen = (input = null) => {
    setIsModalVisible(true);
    setManageAccessorialsModal({
      ...manageAccessorialsModal,
      open: true,
      input: input,
    });
  };
  const handleManageAccessorialsModalClose = (output = null) => {
    setIsModalVisible(false);
    if (output) setManageAccessorialsModal({ ...manageAccessorialsModal, open: false, output: output });
    else setManageAccessorialsModal({ ...manageAccessorialsModal, open: false });
  };

  // Manage Ride Modal
  const [manageRideModal, setManageRideModal] = React.useState({ open: false });
  const handleManageRideModalOpen = (input = null) => {
    setIsModalVisible(true);
    setManageRideModal({
      open: true,
      input: input,
    });
  };
  const handleManageRideModalClose = (output = null) => {
    setIsModalVisible(false);
    if (output) setManageRideModal({ open: false, input: null, output: output });
    else setManageRideModal({ open: false, input: null });
  };

  // Parked Car Modal
  const [parkedCarModal, setParkedCarModal] = React.useState({ open: false });
  const handleParkedCarModalOpen = (input = null) => {
    setIsModalVisible(true);
    setParkedCarModal({
      ...parkedCarModal,
      open: true,
      input: input,
    });
  };
  const handleParkedCarModalClose = (output = null) => {
    setIsModalVisible(false);
    if (output) setParkedCarModal({ ...parkedCarModal, open: false, output: output });
    else setParkedCarModal({ ...parkedCarModal, open: false });
  };

  // Context
  const context = {
    isModalVisible,
    setIsModalVisible,
    handleManageAccessorialsModalOpen,
    handleManageRideModalOpen,
    handleParkedCarModalOpen,
  };

  return (
    <ModalContext.Provider value={context}>
      {/* <ManageAccessorialsModal
        open={manageAccessorialsModal.open}
        close={handleManageAccessorialsModalClose}
        moveId={manageAccessorialsModal.input}
      /> */}
      <ManageRideModal open={manageRideModal.open} onClose={handleManageRideModalClose} input={manageRideModal.input} />
      <ParkedCarModal open={parkedCarModal.open} onClose={handleParkedCarModalClose} input={parkedCarModal.input} />
      {children}
    </ModalContext.Provider>
  );
}

const useModal = () => React.useContext(ModalContext);

export { useModal, ModalProvider };
