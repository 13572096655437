// -------------------------------- IMPORTS -------------------------------- //

import { openDriverDetailsFromDriver, openRegionDetailsFromDriver } from '../../utils/DriverUtils';

// -------------------------------- EXPORTS -------------------------------- //

/** Build the default driver actions list */
export const buildDefaultDriverActions = driver => {
  const actions = [
    {
      label: `Open Driver Details`,
      handler: driver => openDriverDetailsFromDriver(driver),
      disabled: !driver?.id,
    },
    {
      label: `Open Region Details`,
      handler: driver => openRegionDetailsFromDriver(driver),
      disabled: !driver?.region_id && !driver?.region?.id,
    },
    // {},
    // {
    //   label: `Manage Plan`,
    //   handler: driver => {},
    // },
    // {
    //   label: `Sync Plan`,
    //   handler: driver => {},
    // },
    // {
    //   label: `Set Parked Location`,
    //   handler: driver => {},
    // },
  ];
  return actions;
};
