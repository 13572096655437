//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container, Grid, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationAddForm({
  formCategory,
  onFormCategoryChange,
  formDescription,
  onFormDescriptionChange,
  formName,
  onFormNameChange,
  isSaving,
  validationErrors,
}) {
  const cls = useStyles();

  const handleInputChange = setHandler => event => {
    const val = event.target.value;
    if (setHandler) setHandler(val);
  };

  return (
    <Container maxWidth='lg'>
      {/* NAME */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Organization Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Name of the organization. The name will be the primary identifier for this organization. If the company
              name changes, this can be updated from the organization screen.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Name'
              placeholder='Enter the organization name...'
              variant='outlined'
              size='small'
              value={formName}
              onChange={handleInputChange(onFormNameChange)}
              error={validationErrors?.formName}
              helperText={validationErrors?.formName || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      label
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* DESCRIPTION */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Organization Description <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Description of the organization. Use this text field to provide additional information about the
              organization. Any notes or details that are not captured in the name itself can be captured here. If the
              name is self-explanitory, copy the name and paste it here.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              required
              fullWidth
              multiline
              label='Description'
              placeholder='Enter the organization description...'
              variant='outlined'
              size='small'
              value={formDescription}
              onChange={handleInputChange(onFormDescriptionChange)}
              error={validationErrors?.formDescription}
              helperText={validationErrors?.formDescription || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      description
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* CATEGORY */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Organization Category <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              Category of the organization. Select a category that best describes this organization. This is simply an
              internal flag to help HopDrive understand what this organization specializes in. This flag has no impact
              on our back-end systems whatsoever.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Operational</b> - This organization specializes in operations-type moves.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Concierge</b> - This organization specializes in concierge-type moves.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>House</b> - This organization is a house account and should not be included in AR/AP processes.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={isSaving}
              required
              select
              fullWidth
              label='Category'
              placeholder='Select the organization category...'
              variant='outlined'
              size='small'
              value={formCategory}
              onChange={handleInputChange(onFormCategoryChange)}
              error={validationErrors?.formCategory}
              helperText={validationErrors?.formCategory || ``}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='operational'>Operational</MenuItem>
              <MenuItem value='concierge'>Concierge</MenuItem>
              <MenuItem value='house'>House</MenuItem>
            </TextField>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
}));
