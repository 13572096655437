//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Grid, Typography, Tooltip, IconButton, Icon, Chip } from '@material-ui/core';
import NatureTwoToneIcon from '@material-ui/icons/NatureTwoTone';
import { Button, Divide } from '@hopdrive/storybook';

import { useTools } from '../../../hooks/useTools';
import { useDrivers } from '../providers/DriversProvider';
import { useScheduler } from '../providers/SchedulerProvider';

import { ScheduleOutlineBundle } from '../index';
import ScheduleRideModal from './ScheduleRideModal';
import Spacer from '../../../components/Spacer';
import IconRefetch from '../../../components/IconRefetch';
import { Droppable } from 'react-beautiful-dnd';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleOutline() {
  const cls = useStyles();

  const { getLocationByIdFromList, getDriverTagsFromAttributes } = useTools();
  const {
    isInitialLoad,
    workingPlan = {},
    savePlanChanges,
    selectEnrichedMove,
    hideScheduler,
    setIsUnassignedListVisible,
    rideModal,
    isBufferVisible,
    isAddMoveButtonVisible,
    isSelected,
    selectedEnrichedMoveId,
    unselectEnrichedMove,
    isPlanSavable,
    isPlanEditable,
    planLocations,
    notSavableMessage,
    selectPlanById,
  } = useScheduler();
  const { getDriverById } = useDrivers();

  const { plan = {}, enrichedMoves = [] } = workingPlan;
  const { driver_id, driver_name, parked_location } = plan;

  const parkedLocation = getLocationByIdFromList(parked_location, planLocations);

  const [isSaving, setIsSaving] = React.useState();

  const handleSave = async () => {
    if (isPlanSavable) {
      setIsSaving(true);
      await savePlanChanges(false);
      setIsSaving(false);
    }
  };

  const handleSaveAndClose = async () => {
    if (isPlanSavable) {
      setIsSaving(true);
      await savePlanChanges(true);
      setIsSaving(false);
    }
  };

  const handleMoveBundleClick = enrichedMove => {
    if (isSelected(enrichedMove)) {
      unselectEnrichedMove();
    } else {
      selectEnrichedMove(enrichedMove);
    }
    setIsUnassignedListVisible(false);
  };

  const convertSecondsIntoHoursAndMinutes = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const hourString = hours ? hours + ' hr ' : '';
    const minuteString = minutes < 60 && minutes > 0 ? minutes + ' min' : '';
    return hourString + minuteString;
  };

  //Get the buffer of the drive- or, if there is an attached before ride, get the buffer of the before ride
  const getBundleBuffer = enrichedMove => {
    let buffer = enrichedMove.enrichedRideBefore ? enrichedMove.enrichedRideBefore.buffer : enrichedMove.buffer;
    return buffer;
  };

  const driver = getDriverById(driver_id);
  const attributesArr = getDriverTagsFromAttributes(
    driver.config && driver.config.attributes ? driver.config.attributes : {}
  );
  const driverAppVersion = driver.driver_app_version;

  // TODO: Disable dropping completely if the plan is in the past by setting
  // the isDropDisabled={false} to some date boolean when we are ready to do
  // that. It will make testing more dificult though during development.

  return (
    <>
      <ScheduleRideModal open={rideModal.open || false} input={rideModal.input} />

      <div
        className={cls.outlineRoot}
        onClick={e => {
          if (selectedEnrichedMoveId) {
            unselectEnrichedMove();
          }
          e.stopPropagation();
        }}
      >
        <div className={cls.outlineToolbar}>
          <Grid container spacing={1} justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item>
              <Tooltip placement={'top'} title='Back to Timeline'>
                <IconButton className={cls.backBtn} onClick={() => hideScheduler()}>
                  <Icon>arrow_back</Icon>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Typography className={cls.headText}>Schedule for {driver_name}</Typography>
              <Tooltip placement='bottom-start' title='Plan ID | Plan Date | Parked Location'>
                <Typography className={cls.parkedText}>
                  #{workingPlan.id} | {dayjs(workingPlan.plan.plan_date).format(`MM/DD/YYYY`)} |{` `}
                  {parkedLocation ? `Parked at ${parkedLocation.name}` : `Parked location unknown`}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs style={{ height: 32 }}>
              {attributesArr.includes(`concierge`) || attributesArr.includes(`concierge trained`) ? (
                <Tooltip title={`Concierge Trained`}>
                  <Icon>assignment_ind</Icon>
                </Tooltip>
              ) : null}

              {attributesArr.includes(`manual`) ? (
                <Tooltip title={`Manual Trained`}>
                  <NatureTwoToneIcon />
                </Tooltip>
              ) : null}

              {attributesArr.includes(`railyard`) ? (
                <Tooltip title={`Railyard Trained`}>
                  <Icon>calendar_view_week</Icon>
                </Tooltip>
              ) : null}
            </Grid>

            <Grid item>
              <Button
                disabled={!isPlanSavable || isSaving}
                loading={isSaving}
                color='primary'
                variant='outlined'
                onClick={e => {
                  handleSave();
                  e.stopPropagation();
                }}
              >
                Save
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={!isPlanSavable || isSaving}
                loading={isSaving}
                color='primary'
                data-testid='save-and-close'
                onClick={e => {
                  handleSaveAndClose();
                  e.stopPropagation();
                }}
              >
                Save & Close
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={e => {
                  hideScheduler();
                  e.stopPropagation();
                }}
              >
                Close
              </Button>
            </Grid>

            <Grid item>
              <IconRefetch
                tooltip={`Refresh Plan`}
                refetch={() => {
                  selectPlanById(workingPlan.id);
                }}
              />
            </Grid>
          </Grid>
        </div>

        <Droppable droppableId='droppable-schedule' direction='vertical' isDropDisabled={false}>
          {(provided, snapshot) => (
            <div
              id='droppable-schedule'
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                height: '100%',
                padding: snapshot.isDraggingOver ? '10px' : '0px',
                backgroundColor: snapshot.isDraggingOver ? 'rgba(160, 160, 160, 0.1)' : 'transparent',
              }}
            >
              <div className={cls.outlineBody}>
                {!isPlanSavable && !isInitialLoad && notSavableMessage ? (
                  <div className={cls.unsavable}>
                    <Typography className={cls.unsavableText}>{notSavableMessage}</Typography>
                  </div>
                ) : null}

                {enrichedMoves
                  .filter(enrichedMove => enrichedMove.move.move_type === 'drive')
                  .map((enrichedMove, index) => {
                    return (
                      <React.Fragment key={`schedule-bundle-${index}`}>
                        {isBufferVisible && getBundleBuffer(enrichedMove) ? (
                          <Divide>{convertSecondsIntoHoursAndMinutes(getBundleBuffer(enrichedMove))}</Divide>
                        ) : null}

                        <ScheduleOutlineBundle
                          enrichedMove={enrichedMove}
                          onClick={e => {
                            handleMoveBundleClick(enrichedMove);
                            e.stopPropagation();
                          }}
                          index={index}
                        />

                        <Spacer size='xs' />
                      </React.Fragment>
                    );
                  })}

                {isPlanEditable && isAddMoveButtonVisible && (
                  <div className={cls.assignBundle}>
                    <Tooltip placement='top' title='Assign New Move'>
                      <div
                        className={cls.assignBox}
                        onClick={e => {
                          setIsUnassignedListVisible(true);
                          e.stopPropagation();
                        }}
                      >
                        <Icon className={cls.assignIcon}>add</Icon>
                      </div>
                    </Tooltip>
                  </div>
                )}

                <Spacer />
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  outlineRoot: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  outlineToolbar: {
    zIndex: 320,
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(1.5, 2),
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
  },
  backBtn: {
    padding: theme.spacing(1),
  },
  headText: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
  },
  parkedText: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  unsavable: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.paperRadius,
    marginBottom: theme.spacing(2),
  },
  unsavableText: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  outlineBody: {
    position: 'relative',
    flex: 1,
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  assignBundle: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.drop,
  },
  assignBox: {
    position: 'relative',
    width: '100%',
    height: 48,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderStyle: 'dashed',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  assignIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.secondary,
  },
}));
