import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

////////// COMPONENT //////////
export default function DatetimeSelect(props) {
  const cls = useStyles();

  const { timeData, onChange, tip, useUtc, disabled } = props;

  const [time, setTime] = useState(timeData ? timeData : null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (timeData) setTime(timeData);
  }, [timeData]);

  const handleTimeChange = event => {
    const newTime = useUtc ? dayjs.utc(dayjs(event)) : dayjs(event);
    // Assure selected time is not in the past
    if (newTime >= dayjs()) {
      // Check if pickup time is within 90 minutes of current time
      if (newTime.diff(dayjs(), `minutes`) < 90) {
        setErrorText(`Pickup time must be at least 90 minutes ahead of the current time!`);
        setError(true);
      } else {
        setErrorText(``);
        setError(false);
      }
    } else {
      setErrorText(`Cannot set pickup time in the past!`);
      setError(true);
    }

    // Always allow the time to be changed
    setTime(newTime.format());
    if (onChange) onChange(newTime);
  };

  return (
    <>
      {tip ? (
        <>
          <Typography className={cls.tip}>{tip}</Typography>
          <div className={cls.break} />
        </>
      ) : null}

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              required
              fullWidth
              autoOk
              disablePast
              disabled={disabled}
              orientation='portrait'
              format='MM/dd/yyyy'
              label='Pickup Date'
              size='small'
              variant='inline'
              inputVariant='outlined'
              value={time}
              onChange={handleTimeChange}
              error={props.validation && props.validation.pdate ? !props.validation.pdate : false}
            />
          </Grid>

          <Grid item xs={6}>
            <KeyboardTimePicker
              required
              fullWidth
              autoOk
              disabled={disabled}
              orientation='portrait'
              minutesStep={5}
              label='Pickup Time'
              size='small'
              variant='inline'
              inputVariant='outlined'
              value={time}
              onChange={handleTimeChange}
              error={error ? true : props.validation && props.validation.ptime ? !props.validation.ptime : false}
              helperText={
                errorText
                  ? errorText
                  : props.validation && !props.validation.ptime
                  ? 'Pickup time cannot be in the past!'
                  : null
              }
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));
