import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, InputAdornment, Icon, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useDriverPay } from './useDriverPay';

import DriverPayModal from './DriverPayModal';
import RegionSelect from '../../components/RegionSelect';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayFilter2({
  regionId,
  taxClass,
  search,
  start,
  end,
  onRegionIdChange,
  onTaxClassChange,
  onSearchChange,
  checkedRows,
  driverPayArr,
  refetch,
  screenTotal,
  balanceAmount,
}) {
  const cls = useStyles();

  const { formatUSD } = useTools();
  const { getTotalFromDriverPay } = useDriverPay();

  const [payModal, setPayModal] = React.useState({ open: false, input: null, output: null });
  const handleDriverPayModalOpen = (input = null) => {
    if (driverPayArr && driverPayArr.length) {
      let modalDriverPay = [];
      checkedRows.forEach((id, i) => {
        const foundDP = driverPayArr.filter(dp => dp.driver_id === id);
        if (foundDP && foundDP.length) {
          const driverPay = foundDP[0];
          modalDriverPay.push(driverPay);
        }
      });
      setPayModal({
        open: true,
        input: {
          driverPay: modalDriverPay,
          start,
          end,
          refetch,
          // handleTopUp: () => handleTopUpModalOpen(),
        },
        output: null,
      });
    }
  };
  const handleDriverPayModalClose = async (output = null) => {
    setPayModal({ open: false, input: null, output: output });
  };

  const handleRegionId = event => {
    if (onRegionIdChange) onRegionIdChange(event.target.value);
  };
  const handleTaxClass = event => {
    if (onTaxClassChange) onTaxClassChange(event.target.value);
  };
  const handleSearch = event => {
    if (onSearchChange) onSearchChange(event.target.value);
  };

  const getPayTotal = () => {
    let selectedDriverPay = [];
    if (checkedRows && checkedRows.length) {
      checkedRows.forEach((id, i) => {
        const foundDP = driverPayArr.filter(dp => dp.driver_id === id);
        if (foundDP && foundDP.length) {
          const driverPay = foundDP[0];
          selectedDriverPay.push(driverPay);
        }
      });
    }
    return getTotalFromDriverPay(selectedDriverPay);
  };

  return (
    <div className={cls.filter}>
      <DriverPayModal
        open={payModal.open}
        input={payModal.input}
        onClose={handleDriverPayModalClose}
        balance={balanceAmount}
      />

      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={4}>
          <RegionSelect allowAll label='Region Filter' value={regionId || 0} onChange={handleRegionId} />
        </Grid>

        <Grid item xs={2}>
          <TextField
            select
            fullWidth
            label='Tax Class Filter'
            placeholder='Filter by tax class...'
            variant='outlined'
            size='small'
            value={taxClass}
            onChange={handleTaxClass}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    class
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value='both'>Both</MenuItem>
            <MenuItem value='1099'>1099</MenuItem>
            <MenuItem value='W-2'>W-2</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <div className={cls.totalBox}>
            <div className={cls.totalKeyBox}>
              <Typography className={cls.totalKeyTxt}>SCREEN TOTAL</Typography>
            </div>
            <Typography className={cls.totalValTxt}>{formatUSD(screenTotal)}</Typography>
          </div>
        </Grid>

        <Grid item>
          <Button
            disabled={!checkedRows || !checkedRows.length || !driverPayArr || !driverPayArr.length}
            color='primary'
            size='large'
            onClick={() => handleDriverPayModalOpen()}
          >
            <Icon className={cls.btnIcon}>check_circle</Icon>
            Pay{getPayTotal() ? ` (${formatUSD(getPayTotal())})` : ``}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
  },

  totalBox: {
    position: 'relative',
    minWidth: 116,
    padding: `11px 10px`,
    border: theme.border[0],
    borderRadius: 4,
  },
  totalKeyBox: {
    position: 'absolute',
    top: -5,
    left: 6,
    padding: `0 4px`,
    background: theme.palette.background.paper,
  },
  totalKeyTxt: {
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  totalValTxt: {
    zIndex: 11,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
  },

  iconBtn: {
    padding: theme.spacing(1),
  },
  btnIconL: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
  btnIconR: {
    marginTop: -2,
    marginLeft: 8,
    marginRight: -4,
    fontSize: 16,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },

  line: {
    width: 1,
    height: 40,
    background: theme.palette.divider,
  },
}));
