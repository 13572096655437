//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { useTheme, makeStyles, Grid, IconButton, Icon, Tooltip, Switch, Typography } from '@material-ui/core';
import { PlansToolbarTabs, PlansToolbarDate, PlansToolbarOptions } from '../index';

import { useRegionsGlobals } from '../../../providers/RegionsProvider';
import { useSettings } from '../providers/SettingsProvider';
import { usePlans } from '../providers/PlansProvider';
import { useRegions } from '../providers/RegionsProvider';

import IconRefetch from '../../../components/IconRefetch';

// import { SimpleLogger } from '../../../utils/SimpleLogger';
// const { log } = new SimpleLogger({ prefix: 'PlansToolbar', enabled: true });

//////////////////////// COMPONENT ////////////////////////

export default function PlansToolbar(props) {
  const theme = useTheme();
  const cls = useStyles();
  const { setRegionModalOpen } = useRegionsGlobals();
  const { timelineDate, setTimelineDate, atRiskView, setAtRiskView } = useSettings();
  const { state: plansProviderState, refetch: refetchPlansFromServer } = usePlans();
  const { regions } = useRegions();

  // Uncomment to see a a toast message for each state change of plans
  // React.useEffect(() => {
  //   toast(`🦄 ${plansProviderState}`, {
  //     position: 'bottom-right',
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: false,
  //     progress: undefined,
  //   });
  // }, [plansProviderState]);

  const handleRegionsModal = () => {
    setRegionModalOpen(true);
  };

  const toggleAtRiskView = () => {
    setAtRiskView(!atRiskView);
  };

  const handleDateChange = newDate => {
    setTimelineDate(dayjs(newDate));
  };

  const handleTodayClick = () => {
    setTimelineDate(dayjs());
  };

  return (
    <div className={cls.toolbar}>
      <Grid container spacing={2} alignItems='center' wrap='nowrap' style={{ minHeight: 64 }}>
        <Grid item>
          <PlansToolbarTabs selectedRegions={regions} />
        </Grid>

        <Grid item>
          <Tooltip title='Add/Remove Regions'>
            <IconButton className={cls.iconBtn} onClick={() => handleRegionsModal()}>
              <Icon>add_circle</Icon>
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs />

        <Grid item>
          <Tooltip title='Focus moves at-risk of missing their SLA deadline (less than 2hrs).'>
            <div className={cls.flex}>
              <Typography
                className={cls.atRiskTxt}
                style={{ color: atRiskView ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                Focus At-Risk
              </Typography>
              <Switch size='small' color='primary' checked={atRiskView} onChange={() => toggleAtRiskView()} />
            </div>
          </Tooltip>
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <PlansToolbarDate date={timelineDate} onDateChange={handleDateChange} />
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <div className={cls.flex}>
            <Tooltip placement={`bottom`} title={`Go to today (T)`}>
              <IconButton className={cls.iconBtn} onClick={handleTodayClick}>
                <Icon>today</Icon>
              </IconButton>
            </Tooltip>

            <IconRefetch
              className={cls.iconBtn}
              refetch={refetchPlansFromServer}
              message='Plans and unassigned moves refetched!'
              tooltipPlacement='bottom'
              toastLevel='off'
            />
          </div>
        </Grid>

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <PlansToolbarOptions />
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
  toolbar: {
    zIndex: 200,
    position: 'relative',
    width: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  line: {
    width: 1,
    height: 32,
    background: theme.palette.divider,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },

  atRiskTxt: {
    marginRight: theme.spacing(0.5),
    fontSize: 12,
  },
}));
