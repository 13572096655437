// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip, Typography, Icon, Divider } from '@material-ui/core';
import ManualIcon from '@material-ui/icons/NatureTwoTone';

import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { condensedCase } from '../../utils/GeneralUtils';
import {
  getDisplayNameFromDriver,
  getRegionNameFromDriver,
  checkForConciergeTag,
  checkForManualTag,
  checkForRailyardTag,
  checkForAnyTag,
} from '../../utils/DriverUtils';

import { buildDefaultDriverActions } from './DriverActions';
import DriverAvatar from './DriverAvatar';
import StaticDriverTooltip from './StaticDriverTooltip';

// -------------------------------- COMPONENT -------------------------------- //

export default function StaticDriverTile({
  driver,
  plan,
  region,
  zIndex = 1,
  width,
  minWidth,
  maxWidth,
  isSelectable,
  isSelected,
  onSelected,
  isPlaceholder,
  driverStatusOverride,
  timezoneOverride = dayjs.tz.guess(),
  index,
  ...props
}) {
  const cls = useStyles();

  // Handle tag checks
  const hasConciergeTag = checkForConciergeTag(driver);
  const hasManualTag = checkForManualTag(driver);
  const hasRailyardTag = checkForRailyardTag(driver);
  const hasAnyTag = checkForAnyTag(driver);

  // Get actions
  const actions = buildDefaultDriverActions(driver);

  /** Get the width styles of the driver */
  const getWidthStyles = () => {
    let styles = {};

    if (width) styles.width = width;
    if (minWidth) styles.minWidth = minWidth;
    if (maxWidth) styles.maxWidth = maxWidth;

    return styles;
  };

  /** Get tile CSS classes */
  const getTileClasses = () => {
    let classes = [cls.tile];

    if (index % 2) classes.push(cls.tileEven);
    else classes.push(cls.tileOdd);

    return clsx(classes);
  };

  /** Get driver CSS classes */
  const getDriverClasses = () => {
    let classes = [cls.driver];

    if (isSelectable) classes.push(cls.driverSelectable);
    if (isSelected) classes.push(cls.driverSelected);

    return clsx(classes);
  };

  /** Handle driver selection */
  const handleSelectDriver = () => {
    if (onSelected && !isPlaceholder) onSelected(driver?.id);
  };

  // Return the placeholder component if there is no driver
  if (isPlaceholder || !driver) {
    return (
      <div className={cls.placeholder} style={{ zIndex: zIndex, ...getWidthStyles() }} {...props}>
        <Grid container spacing={2} alignItems='center' wrap='nowrap'>
          <Grid item>
            <div className={cls.placeholderAvatar} />
          </Grid>

          <Grid item xs>
            <div className={cls.placeholderTopBox} />
            <div className={cls.spacer_xs} />
            <div className={cls.placeholderMiddleBox} />
            <div className={cls.spacer_md} />
            <div className={cls.placeholderBottomBox} />
          </Grid>
        </Grid>
      </div>
    );
  }

  // Return component
  return (
    <div className={cls.shell} onClick={() => handleSelectDriver()} {...props}>
      <ContextMenuTrigger id={`static-driver-${driver?.id}-cm`} source={`static-driver-${driver?.id}-cm`}>
        {/* TILE */}
        <div className={getTileClasses()}>
          {/* DRIVER */}
          <div className={getDriverClasses()} style={getWidthStyles()}>
            <Grid container spacing={2} alignItems='center' wrap='nowrap'>
              <StaticDriverTooltip driver={driver} plan={plan}>
                <Grid item>
                  <DriverAvatar driver={driver} plan={plan} isSelected={isSelected} />
                </Grid>
              </StaticDriverTooltip>

              <Grid item xs>
                <Typography className={cls.nameTxt}>{getDisplayNameFromDriver(driver) || `-`}</Typography>

                <Typography className={cls.regionTxt}>{getRegionNameFromDriver(driver) || `-`}</Typography>

                {/* TAGS */}
                {hasAnyTag ? (
                  <div className={cls.tags}>
                    <Grid container alignItems='center' wrap='nowrap'>
                      {hasConciergeTag && (
                        <Grid item>
                          <Tooltip placement='top' title={<div className={cls.ttVal}>Concierge Trained</div>}>
                            <Icon className={cls.tagIcon}>assignment_ind</Icon>
                          </Tooltip>
                        </Grid>
                      )}

                      {hasManualTag && (
                        <Grid item>
                          <Tooltip placement='top' title={<div className={cls.ttVal}>Manual Trained</div>}>
                            <ManualIcon className={cls.tagIcon} />
                          </Tooltip>
                        </Grid>
                      )}

                      {hasRailyardTag && (
                        <Grid item>
                          <Tooltip placement='top' title={<div className={cls.ttVal}>Railyard Trained</div>}>
                            <Icon className={cls.tagIcon}>calendar_view_week</Icon>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`static-driver-${driver?.id}-cm`}>
        {actions?.map((action, i) => {
          if (!action || action?.hide) return null;
          if (!action?.handler) return <Divider key={`static-driver-action-${i}-cm`} className={cls.cmDivider} />;
          return (
            <ContextMenuItem
              key={`static-driver-action-${condensedCase(action?.label)}-cm`}
              data={{ action: action, ...action?.data }}
              disabled={typeof action?.disabled === 'function' ? action?.disabled(driver) : action?.disabled}
              onClick={() => action?.handler(driver)}
            >
              {action?.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  shell: {
    position: 'relative',
    width: '100%',
  },

  tile: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 80,
  },
  tileOdd: {
    // backgroundColor: theme.palette.background.paper,
  },
  tileEven: {
    // backgroundColor: theme.palette.background.light,
  },

  driver: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 80,
    padding: 16,
    transition: `all 0.15s`,
  },
  driverSelectable: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      transform: `scale(1.025)`,
    },
    '&:active': {
      opacity: 0.667,
    },
  },
  driverSelected: {
    opacity: 0.667,
  },

  nameTxt: {
    marginBottom: 2,
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 600,
  },
  regionTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  tags: {
    width: 'fit-content',
  },
  tagIcon: {
    display: 'block',
    fontSize: 18,
    color: theme.palette.text.primary,
  },

  ttVal: {
    fontWeight: 600,
  },

  cmDivider: {
    marginTop: 4,
    marginBottom: 4,
  },

  spacer_xs: {
    height: 2,
    width: '100%',
  },
  spacer_sm: {
    height: 3,
    width: '100%',
  },
  spacer_md: {
    height: 4,
    width: '100%',
  },
  spacer_lg: {
    height: 6,
    width: '100%',
  },
  spacer_xl: {
    height: 8,
    width: '100%',
  },

  placeholder: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 80,
    padding: 16,
  },
  placeholderAvatar: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    backgroundColor: `${theme.palette.text.primary}40`,
  },
  placeholderTopBox: {
    width: 120,
    height: 12,
    borderRadius: 2,
    backgroundColor: `${theme.palette.text.primary}40`,
  },
  placeholderMiddleBox: {
    width: 100,
    height: 12,
    borderRadius: 2,
    backgroundColor: `${theme.palette.text.primary}30`,
  },
  placeholderBottomBox: {
    width: 32,
    height: 16,
    borderRadius: 2,
    backgroundColor: `${theme.palette.text.primary}20`,
  },
}));
