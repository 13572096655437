import { gql } from '@apollo/client';

const GET_MAKES_AND_MODELS = gql`
  query get_makes_and_models {
    vehiclemakes(order_by: { name: asc }) {
      id
      name
      vehiclemodels(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;

export { GET_MAKES_AND_MODELS };
