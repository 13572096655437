import React from 'react';
import sdk from '@hopdrive/sdk';
import { Modal } from '../ModalComponents';
import WorkflowConfigContent from './WorkflowConfigContent';

//////////////////////// COMPONENT ////////////////////////

// input.customer - Customer object with config and org config inside

export default function WorkflowConfigModal({ open, input, onClose }) {
  const [defaultWorkflowSetId, setDefaultWorkflowSetId] = React.useState(0);
  const [organizationId, setOrganizationId] = React.useState(0);
  const [customerId, setCustomerId] = React.useState(0);
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    if (input) {
      setDefaultWorkflowSetId(input?.customer?.config?.default_workflow_set_id || 0);
      setOrganizationId(input?.customer?.organization_id || 0);
      setCustomerId(input?.customer?.id || 0);
      const customerConfig = sdk.configs.enrichCustomerConfig(
        input?.customer?.config,
        input?.customer?.organization?.config
      );
      setConfig(customerConfig);
    }
  }, [input]);

  return (
    <Modal open={open}>
      {open && input && config ? (
        <WorkflowConfigContent
          defaultWorkflowSetId={defaultWorkflowSetId}
          organizationId={organizationId}
          customerId={customerId}
          config={config}
          onClose={onClose}
        />
      ) : null}
    </Modal>
  );
}
