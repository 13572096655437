import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { useRates } from '../../hooks/useRates'
import sdk from '@hopdrive/sdk';

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function MoveCalculatorOutput(props) {
  const cls = useStyles();

  const { lane, customerId, regionId, ratesData } = props;

  const [estimatedRideshareCost, setEstimatedRideshareCost] = React.useState(lane?.estimated_rideshare_return_cost);

  useEffect(() => {
    const rideshareRate = lane?.customer?.raterulegroups?.length && lane?.customer?.raterulegroups[0].ridesharerate && Object.keys(lane?.customer?.raterulegroups[0].ridesharerate).length ? lane.customer.raterulegroups[0].ridesharerate : null;
      const estRideCost = sdk.rating.thirdParty.getEstimatedReturnRideCost(lane.distance_miles, lane.average_drive_speed_mph, lane.tolls, rideshareRate);
      if (estRideCost) setEstimatedRideshareCost(estRideCost);
  }, [lane]);

  const {
    getBand,
    getPayRates,
    totalDurationInMins,
    driveDurationInMins,
    getCommission,
    getTotalBasePay,
    getTotalStrandedPay,
    getTotalBaseCost,
    getTotalStrandedCost,
    getTotalBaseRate,
    getTotalStrandedRate,
    getTotalBasePrice,
    getTotalStrandedPrice,
    getTotalBaseProfit,
    getTotalStrandedProfit,
  } = useRates(lane, ratesData.raterules, ratesData.payrategroups, estimatedRideshareCost);

  useEffect(() => {
    // Find applicable pay rate group
    if (ratesData && ratesData.payrategroups) {
      let payRates = getPayRates(customerId, regionId, ratesData.payrategroups);
      payRates && payRates.length > 0 ? setPayRates(payRates) : setPayRates([0, 0]);
    }
    //Get applicable MileageBand
    if (ratesData && ratesData.raterules) {
      let baseBand = getBand(lane.distance_miles, 'base', ratesData.raterules);
      setBaseMileageBand(baseBand);
      let strandedBand = getBand(lane.distance_miles, 'stranded', ratesData.raterules);
      setStrandedMileageBand(strandedBand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratesData]);

  //TODO: Separate payrates, move to useRates
  const [payRates, setPayRates] = useState(['0.00', '0.00']);

  const [baseMileageBand, setBaseMileageBand] = useState('');
  const [strandedMileageBand, setStrandedMileageBand] = useState('');

  //Grid Item component
  const GridItem = props => {
    const { label, amount = 0, unit, isMoney } = props;
    if (isMoney) {
      return (
        <Grid item md={6} xs={12}>
          <div className={cls.flex}>
            <Typography className={cls.flexTxtL}>{label}</Typography>
            <div className={cls.flexLine} />
            <Typography className={cls.flexTxtR}>{'$\u00A0' + Number(amount).toFixed(2)}</Typography>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item md={6} xs={12}>
          <div className={cls.flex}>
            <Typography className={cls.flexTxtL}>{label}</Typography>
            <div className={cls.flexLine} />
            <Typography className={cls.flexTxtR}>
              {amount}
              {unit}
            </Typography>
          </div>
        </Grid>
      );
    }
  };

  if (lane) {
    return (
      <div className={cls.container}>
        {/* Route Info Section */}
        <div className={cls.section}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={cls.headerTxtL}>Route Information</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip placement='top' title='Time / Distance'>
                <Typography className={cls.headerTxtR}>{`${driveDurationInMins().toFixed(1)}\xa0min / ${
                  Number(lane.distance_miles).toFixed(1) || 0
                }\xa0mi`}</Typography>
              </Tooltip>
            </Grid>

            <div className={cls.smallBreak} />

            {/* TODO: Add inspection times to "Total Time"*/}
            <GridItem label='Total&nbsp;Time' amount={totalDurationInMins().toFixed(1)} unit='&nbsp;min' />
            <GridItem label='Drive&nbsp;Time' amount={driveDurationInMins().toFixed(1)} unit='&nbsp;min' />
            <GridItem label='Distance' amount={Number(lane.distance_miles).toFixed(1)} unit='&nbsp;mi' />
            <GridItem
              label='Average&nbsp;Speed'
              amount={Number(lane.average_drive_speed_mph).toFixed(1) || 0}
              unit='&nbsp;mph'
            />
          </Grid>
        </div>

        <div className={cls.lineBreak} />

        {/* Driver Pay Section */}
        <div className={cls.section}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={cls.headerTxtL}>Driver Pay</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip placement='top' title='Total Base Pay / Total Stranded Pay'>
                <Typography className={cls.headerTxtR}>
                  {`$${getTotalBasePay().toFixed(2)} / $${getTotalStrandedPay().toFixed(2)}`}
                </Typography>
              </Tooltip>
            </Grid>

            <GridItem label='Drive&nbsp;Discount' amount={lane.driver_base_pay_discount} isMoney={true} />
            <GridItem label='Return&nbsp;Discount' amount={lane.driver_return_pay_discount} isMoney={true} />
            <GridItem label='Time&nbsp;Pay' amount={lane.driver_time_pay} isMoney={true} />
            {/* TODO: store these values separately instead on in an array */}
            <GridItem label='Standard&nbsp;Pay/Min' amount={payRates[0]} isMoney={true} />
            <GridItem label='Standard&nbsp;Pay/Mile' amount={payRates[1]} isMoney={true} />
            <GridItem label='Distance&nbsp;Pay' amount={lane.driver_drive_pay} isMoney={true} />
            <GridItem label='Return&nbsp;Discount' amount={lane.driver_return_pay} isMoney={true} />

            <div className={cls.smallBreak} />

            <Grid item xs={12}>
              <Typography className={cls.subheaderTxt}>Totals</Typography>
            </Grid>
            <GridItem label='Total&nbsp;Base&nbsp;Pay' amount={getTotalBasePay()} isMoney={true} />
            <GridItem label='Total&nbsp;Stranded&nbsp;Pay' amount={getTotalStrandedPay()} isMoney={true} />
          </Grid>
        </div>

        <div className={cls.lineBreak} />

        {/* Customer Pay Section */}
        <div className={cls.section}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={cls.headerTxtL}>Customer Price</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip placement='top' title='Total Base Price / Total Stranded Price'>
                <Typography className={cls.headerTxtR}>{`$${getTotalBasePrice().toFixed(
                  2
                )} / $${getTotalStrandedPrice().toFixed(2)}`}</Typography>
              </Tooltip>
            </Grid>

            <div className={cls.smallBreak} />

            {/* Base Rates */}
            <Grid item xs={12}>
              <Typography className={cls.subheaderTxt}>Base</Typography>
            </Grid>
            <GridItem label='Rate&nbsp;Type' amount={capFirst(lane.dealer_base_rate_type) || 'N/A'} />
            <GridItem label='Mileage&nbsp;Band' amount={baseMileageBand} />
            <GridItem label='Total&nbsp;Base&nbsp;Rate' amount={getTotalBaseRate()} isMoney={true} />
            <GridItem label='Base&nbsp;Discount' amount={lane.dealer_base_discount} isMoney={true} />
            <GridItem label='Total&nbsp;Base&nbsp;Price' amount={getTotalBasePrice()} isMoney={true} />

            <div className={cls.smallBreak} />

            {/* Stranded Rates */}
            <Grid item xs={12}>
              <Typography className={cls.subheaderTxt}>Stranded</Typography>
            </Grid>

            <GridItem label='Rate&nbsp;Type' amount={capFirst(lane.dealer_stranded_rate_type) || 'N/A'} />
            <GridItem label='Mileage&nbsp;Band' amount={strandedMileageBand} />
            <GridItem label='Total&nbsp;Stranded&nbsp;Rate' amount={getTotalStrandedRate()} isMoney={true} />
            <GridItem label='Stranded&nbsp;Discount' amount={lane.dealer_stranded_discount} isMoney={true} />
            <GridItem label='Total&nbsp;Stranded&nbsp;Price' amount={getTotalStrandedPrice()} isMoney={true} />
          </Grid>
        </div>

        <div className={cls.lineBreak} />

        {/* Profit/Cost Section */}
        <div className={cls.section}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className={cls.headerTxtL}>Profit & Cost</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip placement='top' title='Total Base Profit / Total Stranded Profit'>
                <Typography className={cls.headerTxtR}>{`$${getTotalBaseProfit().toFixed(
                  2
                )} / $${getTotalStrandedProfit().toFixed(2)}`}</Typography>
              </Tooltip>
            </Grid>

            <div className={cls.smallBreak} />

            <Grid item xs={12}>
              <Typography className={cls.subheaderTxt}>Factors</Typography>
            </Grid>

            <GridItem label='Insurance&nbsp;Cost/Mile' amount={lane.insurance_cost_per_mile} isMoney={true} />
            <GridItem label='Total&nbsp;Insurance&nbsp;Cost' amount={lane.insurance_cost} isMoney={true} />
            <GridItem label='Commission' amount={getCommission()} isMoney={true} />
            <GridItem
              label='Return&nbsp;Ride&nbsp;Estimate'
              amount={estimatedRideshareCost}
              isMoney={true}
            />
            <GridItem label='Total&nbsp;Base&nbsp;Cost' amount={getTotalBaseCost()} isMoney={true} />
            <GridItem label='Total&nbsp;Stranded&nbsp;Cost' amount={getTotalStrandedCost()} isMoney={true} />

            <div className={cls.smallBreak} />

            <Grid item xs={12}>
              <Typography className={cls.subheaderTxt}>Totals</Typography>
            </Grid>

            <GridItem label='Total&nbsp;Base&nbsp;Profit' amount={getTotalBaseProfit()} isMoney={true} />
            <GridItem label='Total&nbsp;Stranded&nbsp;Profit' amount={getTotalStrandedProfit()} isMoney={true} />
          </Grid>
        </div>
      </div>
    );
  } else {
    return (
      <Grid item md={12} xs={12}>
        <div className={cls.noWrap}>
          <Typography className={cls.noResults}>modify your search</Typography>
        </div>
      </Grid>
    );
  }
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  section: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerTxtL: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '20px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  headerTxtR: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  subheaderTxt: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    verticalAlign: 'top',
    width: '100%',
  },
  flexLine: {
    width: '100%',
    height: '1px',
    marginTop: '5.5px',
    marginBottom: '5.5px',
    background: '#eee',
  },
  flexTxtL: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  flexTxtR: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  loading: {
    marginTop: '20rem',
    // marginBottom: '-20rem'
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    background: '#ddd',
  },
  noWrap: {
    textAlign: 'center',
  },
  noResults: {
    color: theme.palette.text.secondary,
    marginTop: '10px',
  },
}));
