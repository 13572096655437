import { gql } from '@apollo/client';

const GET_SELECTED_CUSTOMERS_INSURANCE_RATES = gql`
  query getSelectedInsuranceRatesForTable($customerIdArray: [bigint!]) {
    insurancerates(
      order_by: { id: desc }
      where: {
        customer_id: { _in: $customerIdArray }
      }
    ) {
        id
        carrier_name
        createdat
        createdby
        description
        effective_date
        expiration_date
        name
        rate
        region_id
        region{
          id
          name
        }
        state
        type
        updatedat
        updatedby
    }
  }
`;
const GET_INSURANCE_RATES = gql`
  query getInsuranceRatesForTable {
    insurancerates(
      order_by: { effective_date: desc }
    ) {
        id
        carrier_name
        createdat
        createdby
        description
        effective_date
        expiration_date
        name
        rate
        region_id
        region{
          id
          name
        }
        state
        type
        updatedat
        updatedby
    }
  }
`;

export { GET_INSURANCE_RATES, GET_SELECTED_CUSTOMERS_INSURANCE_RATES };
