//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import TimelineIndexRow from './TimelineIndexRow';
import { useTools } from '../../../hooks/useTools';
import { useTimeline } from '../providers/TimelineProvider';
import { useDrivers } from '../providers/DriversProvider';

import { SimpleLogger } from '../../../utils/SimpleLogger';
const { log } = new SimpleLogger({ prefix: 'TimelineIndexBody', enabled: false });

//////////////////////// COMPONENT ////////////////////////

export default function TimelineIndexBody(props) {
  const cls = useStyles();

  const { getFormattedStatusFromPlan } = useTools();
  const { enrichedPlans } = useTimeline();
  const { getDriverById, translateDriverStatus } = useDrivers();

  return (
    <div className={cls.indexBody}>
      {enrichedPlans.map((enrichedPlan, i) => {
        const driver = getDriverById(enrichedPlan.plan.driver_id);
        const driverStatus = translateDriverStatus(driver, getFormattedStatusFromPlan(enrichedPlan.plan));
        log(`Rendering enrichedPlan header with driver info for driver: ${driver.display_name}`, enrichedPlan, driver);

        return (
          <div key={`timeline-index-row-${i}`} className={i % 2 ? cls.rowEven : cls.rowOdd}>
            <TimelineIndexRow
              index={i}
              enrichedPlan={enrichedPlan}
              driver={driver}
              driverStatus={driverStatus}
              moveStatus={getFormattedStatusFromPlan(enrichedPlan.plan)}
            />
          </div>
        );
      })}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  indexBody: {
    zIndex: 111,
    position: 'relative',
    flex: 1,
    width: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  rowOdd: {
    position: 'relative',
    width: '100%',
    minHeight: 80,
    maxHeight: 80,
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
    overflow: 'hidden',
  },
  rowEven: {
    position: 'relative',
    width: '100%',
    minHeight: 80,
    maxHeight: 80,
    borderBottom: theme.border[0],
    background: theme.palette.background.light,
    overflow: 'hidden',
  },
}));
