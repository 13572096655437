// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';

// COMPONENT -------------------------------------------------- //

export default function PageContent({ className, children, maxWidth = `lg`, ...rest }) {
  const cls = useStyles();

  const getClassNames = () => {
    let classNames = [cls.root];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <div className={getClassNames()} {...rest}>
      <Container maxWidth={maxWidth}>{children}</Container>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    background: theme.palette.background.default,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
}));
