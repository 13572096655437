import { gql } from '@apollo/client';

const GET_FAQS = gql`
    query get_faqs {
        faqs(where: { active: { _eq: true } }) {
        id
        active
        user
        question
        answer
        new_flag
        }
    }
`;

const UPDATE_MOVE_DISCOUNT = gql`
mutation update_move_discount($id: bigint!, $discountAmount: numeric!, $discountReason: String!) {
  update_moves(
    where: {
      id: {_eq: $id}
    },
    _set: {
      discount_amount: $discountAmount,
      discount_reason: $discountReason,
      updatedat: "now()",
      disputed: false
    }
  ) {
    affected_rows
    returning {
      active
      id
      discount_amount
      discount_reason
      updatedat
    }
  }
}
`;

const INSERT_FAQ = gql`
mutation insert_faq($active: Boolean!, $user: String, $question: String!, $answer: String!, $newFlag: Boolean!) {
  insert_faqs(objects: {active: $active, user: $user, question: $question, answer: $answer, new_flag: $newFlag}) {
    affected_rows
    returning {
      id
      active
      user
      question
      answer
      new_flag
    }
  }
}
`;
const UPDATE_FAQ = gql`
mutation update_faq($id: bigint!, $active: Boolean!, $user: String, $question: String!, $answer: String!, $newFlag: Boolean!) {
  update_faqs(where: {id: {_eq: $id}}, _set: {active: $active, user: $user, question: $question, answer: $answer, new_flag: $newFlag}) {
    affected_rows
    returning {
      id
      active
      user
      question
      answer
      new_flag
    }
  }
}
`;

export { GET_FAQS, UPDATE_MOVE_DISCOUNT, INSERT_FAQ, UPDATE_FAQ };