//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Chip,
  CardMedia,
  Button,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';
import AllowedCustomersTable from './AllowedCustomers/AllowedCustomersTable';
import AvatarUpload from '../../components/UserManagement/AvatarUpload';
import { useTools } from '../../hooks/useTools';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserDetailsInfo({ user, customerSelections, setCustomerSelections }) {
  const cls = useStyles();
  const ctx = useUserDetails();
  const { getCleansedPhoneNumber, goToCustomerDetails } = useTools();

  const [defaultCustomerDisplay, setDefaultCustomerDisplay] = React.useState('');
  const [defaultCustomer, setDefaultCustomer] = React.useState(null);


  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleCustomerIdChange = value => {
    ctx.setCustomerId(value.props.value);
  };

  const handleSelectedCustomers = selections => {
    ctx?.setAllowedCustomers(selections);
  };

  const handleRemoveCustomer = index => {
    ctx?.setAllowedCustomers(prevCustomers => {
      return prevCustomers.filter((_, i) => i !== index);
    });

    ctx?.setAllowedCustomerIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  React.useEffect(() => {
    //Get the customer object for the user's default customer to set display value and give dealer-admins permissions to the default
    if (customerSelections?.length > 0 && ctx.defaultRole && ctx.defaultRole === 'dealer-admin') {
      let customers = [];
      const customer = customerSelections.find(c => c.id === (ctx.customerId || user.customer_id));
      setDefaultCustomer(customer);
      setDefaultCustomerDisplay(`${customer?.name} (#${customer?.id})`);
      if (!ctx?.allowedCustomers?.length > 0) {
        customers = [customer];
      } else if (
        ctx.allowedCustomers.length > 0 &&
        (!ctx.allowedCustomers.find(c => c.id === customer?.id) ||
          !ctx.allowedCustomers.find(c => c.customer_id === customer?.id))
      ) {
        customers = [...ctx.allowedCustomers, customer];
      }
      ctx.setAllowedCustomers(customers);

      //Dealer-super-admins automatically get permissions for all org customers
    } else if (customerSelections?.length > 0 && ctx.defaultRole && ctx.defaultRole === 'dealer-super-admin') {
      ctx?.setAllowedCustomers(customerSelections);
      const customer = customerSelections.find(c => c.id === (ctx.customerId || user.customer_id));
      setDefaultCustomer(customer);
      setDefaultCustomerDisplay(`${customer?.name} (#${customer?.id})`);
    } else if (customerSelections?.length > 0 && ctx.defaultRole && ctx.defaultRole === 'dealer') {
      const customer = customerSelections.find(c => c.id === (ctx.customerId || user.customer_id));
      setDefaultCustomer(customer);
      setDefaultCustomerDisplay(`${customer?.name} (#${customer?.id})`);
      ctx.setAllowedCustomers([]);
    }
  }, [customerSelections, ctx.defaultRole, ctx.customerId]);

  //Keep array of selected customer ids from multiselect in step with allowed customers
  React.useEffect(() => {
    if (ctx.allowedCustomers && ctx.allowedCustomers.length > 0) {
      const customerIds = ctx.allowedCustomers.map(c => c.id || c.customer_id);
      const uniqueCustomerIds = customerIds.filter((num, index, self) => {
        return self.indexOf(num) === index;
      });
      ctx.setAllowedCustomerIds(uniqueCustomerIds);
    }
  }, [ctx.allowedCustomers]);

  return (
    <Container maxWidth='lg'>
      {/* VITALS */}
      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx?.isSaving}
                required
                fullWidth
                multiline
                label='Display Name'
                placeholder='Enter display name...'
                variant='outlined'
                size='small'
                value={ctx?.displayName || ''}
                onChange={handleInputChange(ctx?.setDisplayName)}
                error={!ctx?.displayName}
                helperText={!ctx?.displayName ? `A display name is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        person
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.displayName}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx?.isSaving}
                required
                fullWidth
                multiline
                label='Email'
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={ctx.email || ''}
                onChange={handleInputChange(ctx?.setEmail)}
                error={ctx?.validation?.email === false}
                helperText={ctx?.validation?.email === false ? `A valid email address is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        mail
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
                <a href={`mailto:${ctx?.email}`}>{ctx?.email}</a>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Phone (Optional)</Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx?.isSaving}
                fullWidth
                multiline
                label='Phone'
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={ctx?.phone || ''}
                onChange={handleInputChange(ctx?.setPhone)}
                error={ctx?.validation?.phone === false}
                helperText={ctx?.validation?.phone === false ? `Phone number is invalid!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
                <a href={`tel:${ctx?.phone}`}>{getCleansedPhoneNumber(ctx?.phone)}</a>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Avatar (Optional)</Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <AvatarUpload avatar={ctx.avatarUrl} setAvatar={ctx.setAvatarUrl} userId={ctx.userId} />
            ) : (
              <>
                {ctx?.avatarUrl ? (
                  <CardMedia
                    component='img'
                    alt='User profile picture'
                    style={{ width: '25%' }}
                    image={ctx?.avatarUrl}
                    title=''
                  />
                ) : null}
              </>
            )}
          </div>
        </Grid>
      </Grid>

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Default Customer <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              The main customer/rooftop with which the user is associated. This field is required for all dealer users.
              If you are creating a user with the dealer super admin role, they will receive permissions for all
              rooftops within the organization. If you are creating a dealer admin user, you will be prompted to add
              additional rooftops below.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx?.isSaving}
                select
                fullWidth
                label='Default Customer'
                placeholder='Select the default customer...'
                variant='outlined'
                size='small'
                defaultValue={customerSelections?.find(c => c.id === user.customer_id)?.id}
                value={customerSelections?.find(c => c.id === ctx.customerId)?.id}
                // value={ctx?.customerId || user.customer_id}
                // defaultValue={customerSelections?.find(c => c.id === initialState?.customer?.id)?.id}
                // onChange={handleInputChange(ctx?.setCustomerId)}
                onChange={(event, value) => handleCustomerIdChange(value)}
                error={ctx?.validation?.customerId === false}
                helperText={ctx?.validation?.customerId === false ? `Default customer is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        roofing
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                {customerSelections &&
                  customerSelections?.length &&
                  customerSelections?.map((c, i) => (
                    <MenuItem key={`organization-${c?.id}`} value={c?.id}>
                      {c?.name} (#{c?.id})
                    </MenuItem>
                  ))}
              </TextField>
            ) : (
              <Button
                className={cls.link}
                disableRipple
                onClick={() => goToCustomerDetails(defaultCustomer?.id)}
              >
                <Typography className={cls.link} onClick={() => goToCustomerDetails(defaultCustomer?.id)}>
                  {defaultCustomerDisplay}
                </Typography>
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              User Role <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            {/* {superAdminOverride ? (
            <Typography className={cls.sectionSubtitleTxt}>
            <b>Dealer Super Admin</b> - This user will have full, automatic access to all customers/rooftops within an
            organization. The first user added to a customer will automatically have this role so that they can add additional users for their rooftop.
          </Typography>
            ) : ( */}
            <>
              <Typography className={cls.sectionSubtitleTxt}>
                Select a default role for the user, which will determine their level of access to the customer or
                organization's rooftops.
              </Typography>
              <Spacer />
              {/* <Typography className={cls.sectionSubtitleTxt}>
              <b>Dealer Super Admin</b> - This user will have full, automatic access to all customers/rooftops within an
              organization. They can also add new dealer super admin, dealer admin, and dealer users.
            </Typography> */}
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>Dealer Admin</b> - This user will have access to the subset of customers/rooftops that you will be
                prompted to specify.
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>Dealer</b> - This user will have access to a single customer/rooftop that you will be prompted to
                specify.
              </Typography>
            </>
            {/* )} */}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={
                  ctx?.isSaving
                  //  || superAdminOverride
                }
                required
                select
                fullWidth
                label='Role'
                placeholder='Select a role...'
                variant='outlined'
                size='small'
                value={ctx?.defaultRole || ''}
                onChange={handleInputChange(ctx?.setDefaultRole)}
                error={ctx?.validation?.defaultRole === false}
                helperText={ctx?.validation?.defaultRole === false ? 'Select a default role!' : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        category
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                {/* <MenuItem value='dealer-super-admin'>Dealer Super Admin</MenuItem> */}
                <MenuItem value='dealer-admin'>Dealer Admin</MenuItem>
                <MenuItem value='dealer'>Dealer</MenuItem>
              </TextField>
            ) : (
              <Typography>
                {ctx.defaultRole
                  ?.split('-')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx?.defaultRole === 'dealer-admin' && (
        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>
                Allowed Customers <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                Dealer admin users must be given access to at least one customer/rooftop in addition to their default.
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            <div className={cls.sectionR}>
              {ctx.editMode ? (
                <>
                  <ReusableMultiselect
                    dataArray={customerSelections}
                    selectedArray={ctx?.allowedCustomerIds}
                    optionName={'name'}
                    label={'Allowed Customer'}
                    handleOutput={handleSelectedCustomers}
                  />

                  <Spacer />
                  {ctx?.allowedCustomers?.map((customer, index) => (
                    <Chip
                      key={index}
                      style={{ margin: 5 }}
                      label={customer?.name || customer?.customer?.name}
                      onDelete={() => handleRemoveCustomer(index)}
                    />
                  ))}
                  {ctx?.validation?.allowedCustomers === false && (
                    <Typography variant='body2' color='error'>
                      Dealer admins must have access to at least one customer/rooftop in addition to their default.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <AllowedCustomersTable allowedCustomers={ctx?.allowedCustomers} />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));
