//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Spacer } from '@hopdrive/storybook';
import { gql } from '@apollo/client';

import { useData } from '../providers/DataProvider';
import { getUserToken } from '../utils/authHelper';

const INSERT_MANUAL_PAYMENT_METHOD = gql`
  mutation insert_manual_payment_method($customer_id: bigint!, $name: String!) {
    insert_arpaymentmethods(
      objects: {
        active: true
        account_number: 0
        createdat: "now()"
        customer_id: $customer_id
        gateway_token: "manual"
        name: $name
        primary: false
        source: "admin site"
        type: "manual"
        updatedat: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

//////////////////////// COMPONENT ////////////////////////

function ARMarkPaidModal({ open = false, onClose = null, input = {}, refetch = null }) {
  const ctx = useData();

  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [refNum, setRefNum] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [transactionDate, setTransactionDate] = React.useState(null);
  const [customPaymentMode, setCustomPaymentMode] = React.useState(false);
  const [customPaymentAmount, setCustomPaymentAmount] = React.useState(null);

  const paymentMethodId =
    input.invoice &&
    input.invoice.customer &&
    input.invoice.customer.paymentmethods &&
    input.invoice.customer.paymentmethods.length > 0
      ? input.invoice.customer.paymentmethods[0].id
      : null;

  React.useEffect(() => {
    if (input.armoves && input.armoves.length > 0) {
      const totalPayment = input.armoves
        .map(
          armove => parseFloat(armove.due_amount) - parseFloat(armove.discount_amount) - parseFloat(armove.paid_amount)
        )
        .reduce((total, currentValue) => total + currentValue)
        .toFixed(2);
      setPaymentAmount(totalPayment);
    } else setPaymentAmount(`0.00`);
  }, [input.armoves]);

  const createManualPaymentMethod = async () => {
    try {
      ctx.apolloClient
        .mutate({
          mutation: INSERT_MANUAL_PAYMENT_METHOD,
          variables: {
            customer_id: input.invoice.customer.id,
            name: input.invoice.customer.name + ' Manual Payment Method',
          },
        })
        .then(res => {
          if (
            res.data &&
            res.data.insert_arpaymentmethods &&
            res.data.insert_arpaymentmethods.returning &&
            res.data.insert_arpaymentmethods.returning.length > 0
          ) {
            let newMethodId = Number(res.data.insert_arpaymentmethods.returning[0].id) || null;
            toast.success(`Successfully created manual payment method!`);
            postPayment(newMethodId);
            // handleClose({ success: true });
          } else {
            toast.error(`Failed to create manual payment method!`);
            console.error(`Failed to create manual payment method!`);
          }
          setLoading(false);
        })
        .catch(err => {
          toast.error(`Failed to create manual payment method!`);
          console.error(`Failed to create manual payment method:`, err);
          setLoading(false);
        });
    } catch (err) {
      toast.error(`Failed to create manual payment method!`);
      console.error(`Failed to create manual payment method:`, err);
      setLoading(false);
    }
  };

  const postPayment = async newMethodId => {
    try {
      //Create manual payment method if none exists
      if (!paymentMethodId);
      const customPaymentAmountInt = customPaymentAmount ? parseFloat(customPaymentAmount) : 0;
      const movePaymentAmountInt = parseFloat(paymentAmount);
      //Validation checks for custom payment (if in custom payment mode)
      if (customPaymentMode) {
        if (typeof customPaymentAmountInt !== 'number') {
          toast.warning('Custom payment amount must be a number');
          return;
        }
        if (customPaymentAmountInt === 0) {
          toast.warning('Custom payment amount must be greater than zero');
          return;
        }
        if (customPaymentAmountInt >= movePaymentAmountInt) {
          toast.warning('Custom payment amount must be less than the total move amount');
          return;
        }
      }
      const token = await getUserToken();
      axios({
        method: `POST`,
        url: `/.netlify/functions/postPaymentAR`,
        data: {
          invoiceId: input.invoice.id || null,
          armoves: input.armoves,
          manualPaymentMethodId: newMethodId || paymentMethodId,
          //Use custom payment amount if it is entered
          paymentAmount: customPaymentMode ? customPaymentAmount : paymentAmount,
          refNum: refNum,
          effectiveDate: transactionDate,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res.status === 200) {
            toast.success(`Successfully marked selected moves as paid!`);
            handleClose({ success: true });
            refetch();
          } else {
            toast.error(`Failed to marked selected moves as paid!`);
            console.error(`Failed to marked selected moves as paid!`);
          }
          setLoading(false);
          refetch();
        })
        .catch(err => {
          toast.error(`Failed to marked selected moves as paid!`);
          console.error(`Failed to marked selected moves as paid:`, err);
          setLoading(false);
          refetch();
        });
    } catch (err) {
      toast.error(`Failed to marked selected moves as paid!`);
      console.error(`Failed to marked selected moves as paid:`, err);
      setLoading(false);
      refetch();
    }
  };

  const handleCheckbox = event => {
    if (customPaymentMode) {
      setCustomPaymentAmount(0);
      setCustomPaymentMode(false);
    } else {
      setCustomPaymentMode(true);
    }
  };

  const handleClose = (output = null) => {
    setCustomPaymentMode(false);
    setCustomPaymentAmount(0);
    if (onClose) onClose(output);
  };

  return (
    <Dialog open={open} aria-labelledby='post-payment-modal'>
      <DialogTitle id='post-payment-modal-title'>Post Payment Confirmation</DialogTitle>
      <DialogContent>
        <Grid container justifyContent='space-between'>
          <Grid item xs={12}>
            <Typography style={{ paddingBottom: '1em' }}>
              You are about to post a payment to this invoice manually. This will generate a payment record in this
              system as well as in the accounting system. Please confirm the total below as well as the list of moves
              being paid for in this payment.
            </Typography>
          </Grid>
          <Spacer />
          <Grid item xs={7}>
            <Typography>
              If one is available, please enter the reference number provided by the payment processor.
            </Typography>
            <form>
              <TextField
                id='outlined-basic'
                variant='outlined'
                onChange={event => {
                  setRefNum(event.target.value);
                }}
                fullWidth={true}
              />
            </form>
          </Grid>
          <Spacer />
          <Grid item xs={7}>
            <Typography>Enter the date of the payment transaction</Typography>
            <form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  orientation='portrait'
                  format='MM/dd/yyyy'
                  label='Effective Date'
                  margin='dense'
                  inputVariant='outlined'
                  value={transactionDate}
                  onChange={setTransactionDate}
                  style={{
                    width: '100%',
                    margin: 0,
                  }}
                />
              </MuiPickersUtilsProvider>
            </form>
          </Grid>
          <Spacer />
          <Grid container xs={7}>
            <Checkbox style={{ paddingLeft: '0' }} color='primary' onClick={event => handleCheckbox(event)} />
            <Typography style={{ paddingTop: '12px', paddingBottom: '8px' }}>Enter a custom payment amount</Typography>
          </Grid>
          {customPaymentMode ? (
            <>
              <Grid item xs={7}>
                <form>
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    type='number'
                    value={customPaymentAmount}
                    onChange={event => {
                      setCustomPaymentAmount(event.target.value);
                    }}
                    fullWidth={true}
                    helperText={'Use only if payment amount cannot be set by selecting moves'}
                  />
                </form>
              </Grid>
            </>
          ) : null}
          <Grid item xs={4}>
            <Typography>
              Moves Count: <span style={{ fontWeight: 'bold' }}>{input.armoves ? input.armoves.length : 0}</span>
            </Typography>
            <Typography>
              Total To Pay: <span style={{ fontWeight: 'bold' }}>{' $' + paymentAmount}</span>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading || transactionDate === null}
          loading={loading}
          color='primary'
          onClick={async () => {
            setLoading(true);
            paymentMethodId ? await postPayment() : await createManualPaymentMethod();
          }}
        >
          Mark as Paid
        </Button>
        <Button onClick={() => handleClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ARMarkPaidModal;
