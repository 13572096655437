//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  DialogContentText,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { gql } from 'graphql-tag';
import ManageAccessorialsTable from './ManageAccessorialsModal/ManageAccessorialsTable';
import { useData } from '../providers/DataProvider';
import { toast } from 'react-toastify';
import sdk from '@hopdrive/sdk';
import { Spacer } from '@hopdrive/storybook';

import { REACT_APP_A0_CB_SD } from '../utils/env';

//////////////////////// COMPONENT ////////////////////////

function CancelMoveModal({ open, input, onSave, onClose, move }) {
  const cls = useStyles();
  const ctx = useData();

  const [status, setStatus] = React.useState(null);
  const [reason, setReason] = React.useState(null);
  const [moveStatus, setMoveStatus] = React.useState(null);
  const [index, setIndex] = React.useState(0);
  const [accessorials, setAccessorials] = React.useState(null);

  //In Move Details, it's passed in as "move", but in Scheduler, it's passed in as "input.move",
  // so account for both scenarios when setting moveStatus:
  React.useEffect(() => {
    if (move) {
      if (move.status) setMoveStatus(move.status);
      if (move.cancel_status) setStatus(move.cancel_status);
      if (move.cancel_reason) setReason(move.cancel_reason);
    }
  }, [open, move]);

  React.useEffect(() => {
    if (open && input && input.move && input.move.id) {
      const fetchDetail = async () => {
        await ctx.apolloClient
          .query({
            query: GET_DETAIL,
            variables: { moveId: input.move.id },
            fetchPolicy: `network-only`,
          })
          .then(res => {
            if (res && res.data && res.data.moves && res.data.moves.length > 0) {
              setMoveStatus(res.data.moves[0].status);

              if (res.data.moves[0].accessorials) {
                setAccessorials(res.data.moves[0].accessorials);
              }
            }
          });
      };
      try {
        fetchDetail();
      } catch (err) {
        toast.error(`Unknown error fetching accessorials!`);
        console.error('Unknown error fetching accessorials:', err);
      }
    }
  }, [open, input, ctx.apolloClient]);

  const handleStatusChange = event => {
    const val = event.target.value;
    setStatus(val || null);
    if (!val) setReason(null);
  };

  const handleReasonChange = event => {
    if (status) {
      const val = event.target.value;
      setReason(val || null);
    }
  };

  const handleDialogContent = () => {
    switch (status) {
      case 'started':
        return (
          <>
            <DialogContentText id='alert-dialog-description'>
              The cancellation status of this move will be set to "started". It has been partially worked and may
              require additional charges to the customer or partial driver pay. Please add any additions as accessorials
              below.
            </DialogContentText>
            <DialogContentText id='alert-dialog-description'>
              Reason: <b>{reason}.</b>
            </DialogContentText>
            <DialogContentText id='alert-dialog-description'>Does the customer need to be charged?</DialogContentText>
            <DialogContentText id='alert-dialog-description'>Does the driver need to be paid?</DialogContentText>
            <ManageAccessorialsTable
              type='customer'
              accessorials={Array.from(accessorials.filter(o => o.ap_amount > 0 && o.status === 'approved') || [])}
              moveId={input.move.id}
              move={input.move}
            />
            <br />
          </>
        );
      case 'canceled':
        return (
          <>
            <DialogContentText id='alert-dialog-description'>
              The cancellation status of this move will be set to "canceled". It will be removed from the list unplanned
              moves and will not appear on any driver pay or customer invoices.
            </DialogContentText>
            <DialogContentText id='alert-dialog-description'>
              Reason: <b>{reason}.</b>
            </DialogContentText>
          </>
        );
      case 'delivered':
        return (
          <>
            <DialogContentText id='alert-dialog-description'>
              The cancellation status of this move will be set to "delivered". It is unable to be canceled due to being
              already delivered or being too far along in the move process to stop. Nothing on the move will change and
              driver pay and customer invoices will be unaffected.
            </DialogContentText>
            <DialogContentText id='alert-dialog-description'>
              Reason: <b>{reason}.</b>
            </DialogContentText>
          </>
        );
      default:
        return null;
    }
  };

  const handleButtons = () => {
    if (index === 0) {
      if (['pending', 'seen'].indexOf(status) >= 0) {
        return (
          <>
            <Button onClick={handleClose} color='primary'>
              Close
            </Button>
            <Button onClick={() => handleSave()} color='primary'>
              Save
            </Button>
          </>
        );
      } else
        return (
          <>
            <Button onClick={handleClose} color='primary'>
              Close
            </Button>
            <Button onClick={() => setIndex(1)} color='primary'>
              Next
            </Button>
          </>
        );
    } else
      return (
        <>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
          <Button onClick={() => setIndex(0)} color='primary'>
            Back
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </>
      );
  };

  const createNotification = async input => {
    const variables = {
      type: 'action',
      title: `Move ${input.move.id} Pending Cancel`,
      body: `Move ${input.move.id} is pending cancel. Click the link in the notes below to visit the details page and update the cancel status.`,
      createdat: 'now()',
      createdby: 'move-cancel.admin',
      notes: `[Click to go to Move Details](https://${REACT_APP_A0_CB_SD}.hopdrive.io/moves/${input.move.id})`,
    };
    const res = await sdk.notifications.create(variables);
    console.log(res);
  };

  const handleSave = async () => {
    if (status === 'pending') {
      try {
        await createNotification(input);
      } catch (err) {
        console.log(err);
      }
    }

    onSave({ move: input.move, cancelStatus: status, cancelReason: reason });
    handleClose();
  };

  const handleClose = () => {
    setStatus(null);
    setReason(null);
    setIndex(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose}
      maxWidth='md'
      aria-labelledby='change-cancel-status-modal'
      id='CancelMoveDialog'
    >
      <DialogTitle id='change-cancel-status-modal'>Change Cancel Status</DialogTitle>
      <DialogContent>
        {index === 0 ? (
          <>
            <DialogContentText id='alert-dialog-description'>
              Set the status of a move cancelation request from a customer. Sometimes a move is already in progress and
              cannot be canceled.
              <br />
              <br />
              <span style={{ fontSize: '.8em' }}>
                <strong>Pending</strong> - The cancelation request that has not yet been seen by a dispatcher
                <br />
                <strong>Seen</strong> - Acknowledged by the dispatcher so it can be removed from any dispatcher
                notifications
                <br />
                <strong>Started</strong> - The dispatcher was able to tell the driver to return the vehicle to the
                pickup location
                <br />
                <strong>Canceled</strong> - The dispatcher was able to cancel the move before it was worked
                <br />
                <strong>Delivered</strong> - The dispatcher was NOT able to cancel the move as it was already delivered
              </span>
            </DialogContentText>

            <Spacer />

            <TextField
              select
              className={cls.select}
              label='Cancel Status'
              value={status}
              onChange={handleStatusChange}
              size='small'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      token
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={''}>
                <em>Not Canceled</em>
              </MenuItem>
              <MenuItem value={'pending'}>Pending</MenuItem>
              <MenuItem value={'seen'}>Seen</MenuItem>
              {/* NOTE: Moves in "pickup_started" can be set as canceled or started to give some wiggle room */}
              <MenuItem
                disabled={moveStatus !== null && moveStatus !== 'dispatched' && moveStatus !== 'pickup started'}
                value={'canceled'}
              >
                Canceled
              </MenuItem>
              <MenuItem
                disabled={moveStatus === null || moveStatus === 'dispatched' || moveStatus === 'delivery successful'}
                value={'started'}
              >
                Started
              </MenuItem>
              <MenuItem disabled={moveStatus !== 'delivery successful'} value={'delivered'}>
                Delivered
              </MenuItem>
            </TextField>

            {status ? (
              <>
                <Spacer />

                <TextField
                  select
                  fullWidth
                  label='Cancel Reason'
                  value={reason}
                  onChange={handleReasonChange}
                  size='small'
                  variant='outlined'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          assignment
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value={''}>
                    <em>No reason</em>
                  </MenuItem>
                  <MenuItem value={'Contact not reachable'}>Contact not reachable</MenuItem>
                  <MenuItem value={'Customer canceled previously'}>Customer canceled previously</MenuItem>
                  <MenuItem value={'Customer changed address'}>Customer changed address</MenuItem>
                  <MenuItem value={'Customer needed to reschedule'}>Customer needed to reschedule</MenuItem>
                  <MenuItem value={'Customer not present'}>Customer not present</MenuItem>
                  <MenuItem value={'Duplicate move request'}>Duplicate move request</MenuItem>
                  <MenuItem value={'Incorrect address'}>Incorrect address</MenuItem>
                  <MenuItem value={'Vehicle mechanical failure'}>Vehicle mechanical failure</MenuItem>
                  <MenuItem value={'Vehicle moved previously'}>Vehicle moved previously</MenuItem>
                  <MenuItem value={'Vehicle not ready'}>Vehicle not ready</MenuItem>
                  <MenuItem value={'Vehicle not roadworthy'}>Vehicle not roadworthy</MenuItem>
                </TextField>
              </>
            ) : null}
          </>
        ) : (
          handleDialogContent()
        )}
      </DialogContent>
      <DialogActions>{handleButtons()}</DialogActions>
    </Dialog>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 256,
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_DETAIL = gql`
  query getMoveDetailInScheduler($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId } }) {
      id
      status
      cancel_status
      cancel_reason
      accountsReceivable {
        status
      }
      accessorials {
        id
        move_id
        code
        status
        notes
        cost
        ap_amount
        ar_amount
        move {
          accountsReceivable {
            status
          }
        }
        appayment {
          active
          id
          driver_id
          move_id
          status
          ach_transaction_id
          amount
          createdat
          updatedat
          notes
          author
          type
          accessorial_id
        }
      }
    }
  }
`;

//////////////////////// EXPORT ////////////////////////

export default CancelMoveModal;
