//////////////////////// DEPENDENCIES ////////////////////////

//////////////////////// COMPONENT ////////////////////////

export default function useCustomerDetails() {
  /** Tabs for customer details */
  const tabs = [
    {
      id: 0,
      label: `Overview`,
      route: `overview`,
      icon: `view_quilt`,
    },
    {
      id: 1,
      label: `Users`,
      route: `users`,
      icon: `people_alt`,
    },
    {
      id: 2,
      label: `Pricing`,
      route: `pricing`,
      icon: `price_change`,
    },
    {
      id: 3,
      label: `Settings`,
      route: `settings`,
      icon: `settings_applications`,
    },
  ];

  /** Get the customer object with only the override values */
  const getDefaultCustomerRef = customer => {
    // Build the new customer object with defaults
    let newCustomer = {
      auto_pay: false,
      billing_frequency: `monthly`,
      config: {},
      notify_billing: false,
    };

    // If we pass in a customer object, override some values
    if (customer) {
      // Get the mutable customer copy
      let mutableCustomer = JSON.parse(JSON.stringify(customer || {}));

      // Set values where applicable
      if (mutableCustomer?.auto_pay) newCustomer.auto_pay = mutableCustomer?.auto_pay;
      if (mutableCustomer?.billing_frequency) newCustomer.billing_frequency = mutableCustomer?.billing_frequency;
      if (mutableCustomer?.config) newCustomer.config = mutableCustomer?.config;
      if (mutableCustomer?.notify_billing) newCustomer.notify_billing = mutableCustomer?.notify_billing;
    }

    // Return the new customer object
    return newCustomer;
  };

  // Return additional logic
  return {
    tabs,
    getDefaultCustomerRef,
  };
}
