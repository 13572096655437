//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Prompt } from 'react-router-dom';
import deepmerge from 'deepmerge';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, Grid, Container, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { gql, useMutation } from '@apollo/client';
import { useData } from '../../providers/DataProvider';
import useOrganizationDetails from './useOrganizationDetails';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import SettingsList from '../../components/Settings/SettingsList';
import SettingsContent from '../../components/Settings/SettingsContent';

import Api from '../../components/Settings/ClientConfig/Api';
import Appraisals from '../../components/Settings/ClientConfig/Appraisals';
import Branding from '../../components/Settings/ClientConfig/Branding';
import Contacts from '../../components/Settings/ClientConfig/Contacts';
import CustomerValues from '../../components/Settings/ClientConfig/CustomerValues';
import Fuel from '../../components/Settings/ClientConfig/Fuel';
import MovePlanning from '../../components/Settings/ClientConfig/MovePlanning';
import Notifications from '../../components/Settings/ClientConfig/Notifications';
import Rates from '../../components/Settings/ClientConfig/Rates';
import Rideshare from '../../components/Settings/ClientConfig/Rideshare';
import ThirdPartyPayer from '../../components/Settings/ClientConfig/ThirdPartyPayer';
import Workflows from '../../components/Settings/ClientConfig/Workflows';
// import PrivateOptions from '../../components/Settings/ClientConfig/PrivateOptions';

import OrganizationDetailsSettingsToolbar from './OrganizationDetailsSettingsToolbar';
import { toast } from 'react-toastify';

const log = false;

// Configuration for the settings tab
let settings = [
  {
    id: `client-config-api`,
    label: `API`,
    sublabels: [`Activation`],
  },
  {
    id: `client-config-appraisals`,
    label: `Appraisals`,
    sublabels: [`Activation`, `Active Timeframes`, `Allowed Move Types`],
  },
  {
    id: `client-config-branding`,
    label: `Branding`,
    sublabels: [`Activation`, `Display Name`, `Primary Color`, `Secondary Color`, `Logo`],
  },
  {
    id: `client-config-customer-values`,
    label: `Custom Settings`,
    sublabels: [`Custom Data Input`],
  },
  {
    id: `client-config-fuel`,
    label: `Fuel`,
    sublabels: [`Minimum Fuel Spend`, `Maximum Fuel Spend`, `Required Fuel Level`],
  },
  {
    id: `client-config-hopdrive-contacts`,
    label: `Hopdrive Contacts`,
    sublabels: [`Account Manager`, `Market Manager`, `Sales Representative`, `Accounts Receivable`],
  },
  {
    id: `client-config-move-planning`,
    label: `Move Planning`,
    sublabels: [
      `Activation`,
      `Bypass Auto-Deactivation`,
      `Allowed Move Types`,
      `Default Move Type`,
      `Activate Distance Estimates`,
      `Activate Price Estimates`,
      `Activate Triangle Moves`,
    ],
  },
  {
    id: `client-config-email-notifications`,
    label: `Notifications`,
    sublabels: [`Move Status Updates`, `Billing Notifications`],
  },
  {
    id: `client-config-rates`,
    label: `Rates`,
    sublabels: [`Cancellation Fee`, `Delay Price Per Minute`, `Fuel Multiplier`],
  },
  {
    id: `client-config-rideshare`,
    label: `Rideshare`,
    sublabels: [`Preferred Ride Partner`, `Surge Charge Accountability`],
  },
  {
    id: `client-config-third-party-payer`,
    label: `Third Party Payer`,
    sublabels: [`Required`, `Default Payer`],
  },
  {
    id: `client-config-workflows`,
    label: `Workflows`,
    sublabels: [`Allowed Workflow Sets`, `Default Workflow Set`],
  },
];

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetailsSettings({ organization, refetch }) {
  const cls = useStyles();

  const { _sdk } = useData();
  const { getDefaultOrganizationRef } = useOrganizationDetails();

  // State variables
  const [inheritance, setInheritance] = React.useState(null);
  const [isReset, setIsReset] = React.useState(true);
  const [isSaved, setIsSaved] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  // const [privateOptions, setPrivateOptions] = React.useState(null);

  // Mutable ref for editing
  const organizationRef = React.useRef(getDefaultOrganizationRef(organization));

  // Fetch the config inheritance
  React.useEffect(() => {
    const fetchConfigInheritance = () => {
      try {
        const res = _sdk.configs.getDefaultConfig();
        if (res) {
          const mutableOrganization = JSON.parse(JSON.stringify(organization || {}));
          setInheritance({ ...mutableOrganization, config: res });
        }
        log && console.log(`Config Inheritance:`, res);
      } catch (err) {
        console.error(`Failed to fetch config inheritance:`, err);
      }
    };
    fetchConfigInheritance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the reset state when the page loads
  React.useEffect(() => {
    handleResetState();
  }, []);

  // Check if the organization's workflows have any private steps (e.g. showing fuel receipt photos) that can be configured
  // React.useEffect(() => {
  //   const getPrivateOptions = async () => {
  //     const privateOptions = await _sdk.configs.getPrivateSettings(null, organization.id, "organization");
  //     log && console.log(`Private Options:`, privateOptions);
  //     setPrivateOptions(privateOptions);
  //   };
  //   getPrivateOptions();
  // }, []);

  // React.useEffect(() => {
  //   //If there are private options, add a new settings section for them and merge them into the org config
  //   if (privateOptions) {
  //     organizationRef.current.config['private'] = privateOptions
  //     const sublabels = Object.keys(privateOptions).map(key => {
  //       let words = key.replace(/_/g, ' ').split(' ');
  //       words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  //       return words.join(' ');
  //     });
  //     if (settings.filter(setting => setting.id === `client-config-private`).length === 0) {
  //       settings.push({
  //         id: `client-config-private`,
  //         label: `Private Options`,
  //         sublabels: sublabels,
  //       });
  //     }
  //   }
  //   settings = settings.sort((a, b) => a.label.localeCompare(b.label));
  // }, [privateOptions]);

  // Mutation to update the organization record
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION);

  // Handle updating the reset state
  const handleResetState = () => {
    if (Object.keys(organizationRef?.current?.config).length === 0) setIsReset(true);
    else setIsReset(false);
  };

  // Handle updating the save state
  const handleSaveState = () => {
    if (isSaved) setIsSaved(false);
  };

  const customMerge = (destination, source) => {
    return deepmerge(destination, source, {
      arrayMerge: (dst, src) => src,
      customMerge: key => {
        if (key === 'customer_values') {
          return (dst, src) => {
            // Replace the current customer_values with the new one
            return src;
          };
        }
        return undefined; // Default merging behavior for other keys
      },
    });
  };

  // Handle updating the mutable ref
  const updateOrganizationRef = changes => {
    // Deep merge the changes into the mutable ref
    organizationRef.current = customMerge(organizationRef?.current, changes, { arrayMerge: (dst, src) => src });
    log && console.log(`Organization Ref Change:`, organizationRef?.current);

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();
  };

  // Handle checking if a field is reset in the mutable ref
  const checkOrganizationRefField = field => {
    if (getPropValue(organizationRef?.current, field) !== undefined) return false;
    return true;
  };

  // Handle resetting (deleting) a field in the mutable ref
  const deleteOrganizationRefField = (field, callback) => {
    try {
      // Split out the keys and reduce to get the final nested key, then delete it
      let keys = field.split(`.`);
      if (keys?.length) {
        let last = keys.pop();
        delete keys.reduce((obj, key) => obj[key], organizationRef.current)[last];
        log && console.log(`Organization Ref Delete:`, organizationRef?.current);
      }

      // Delete empty config objects if necessary
      Object.keys(organizationRef?.current?.config).forEach(key => {
        if (
          typeof organizationRef.current.config[key] === `object` &&
          Object.keys(organizationRef.current.config[key]).length === 0
        ) {
          delete organizationRef.current.config[key];
        }
      });
    } catch (err) {
      console.error(`Failed to delete organization ref field:`, err);
    }

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();

    // Run the callback if it exists
    if (callback) callback();
  };

  // Handle restoring defaults to all settings
  const handleRestoreDefaults = async () => {
    // Reset the mutable ref to defaults
    organizationRef.current = getDefaultOrganizationRef();
    log && console.log(`Organization Ref Reset:`, organizationRef?.current);

    // Handle updating the reset & save states
    handleResetState();
    handleSaveState();
  };

  // Handle saving changes
  const handleSaveChanges = async () => {
    log && console.log(`Organization Ref Save:`, organizationRef?.current);
    setIsSaving(true);

    // Update the organization record
    const variables = {
      organizationId: organization?.id,
      config: organizationRef?.current?.config,
    };

    // Update the organization record
    try {
      const res = await updateOrganization({ variables });
      if (res?.data?.update_organizations?.affected_rows) {
        log && console.log(`Successfully updated organization record:`, res);
        toast.success(`Successfully updated organization record!`);
        setIsSaved(true);
      }
    } catch (err) {
      console.error(`Failed to update organization record:`, err);
      toast.error(`Failed to update organization record!`);
    }

    await refetch();
    setIsSaving(false);
  };

  // Wait until we have the organization config inheritance before rendering
  if (!inheritance) return <DefaultLoadingFallback message='FETCHING CONFIG INHERITANCE' />;
  return (
    <div className={cls.root}>
      <Prompt when={!isSaved} message='You have unsaved changes, are you sure you want to leave?' />

      <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
        <Grid item>
          <div className={cls.scrollWrapper}>
            <SettingsList settings={settings} />
          </div>
        </Grid>

        <Grid item xs>
          <div className={cls.scrollWrapper}>
            <div id='settings-overflow' className={cls.scrollOverflow}>
              <OrganizationDetailsSettingsToolbar
                isReset={isReset}
                isSaved={isSaved}
                isSaving={isSaving}
                onReset={handleRestoreDefaults}
                onSave={handleSaveChanges}
              />

              <SettingsContent>
                <Container maxWidth='md'>
                  <Spacer size={8} />
                  <div className={cls.blurb}>
                    <Icon className={cls.blurbIcon}>info</Icon>
                    <Typography className={cls.blurbTxt}>
                      Applying any changes on this page will affect ALL customers under the{' '}
                      {organization?.name || `Unknown`} org. To override a value for a specific customer under this org,
                      please use that customer's settings page instead. Clicking "Restore Default" on any value will set
                      it back to HopDrive's default value for that setting. When the button is grayed out, the value is
                      already set to the HopDrive default.
                    </Typography>
                  </div>
                  <Spacer size={8} />
                  <Api
                    settingId={settings[0]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Appraisals
                    settingId={settings[1]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Branding
                    settingId={settings[2]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                    clientType={`organization`}
                  />
                  <Spacer size={10} />
                  <CustomerValues
                    settingId={settings[3]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Fuel
                    settingId={settings[4]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Contacts
                    settingId={settings[5]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                    clientType={`organization`}
                  />
                  <Spacer size={10} />
                  <MovePlanning
                    settingId={settings[6]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Notifications
                    settingId={settings[7]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  {/* {privateOptions ? (
                    <>
                    <Spacer size={10} />
                    <PrivateOptions
                    settingId={settings[6]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                    />
                    </>
                  ) : null} */}
                  <Spacer size={10} />
                  <Rates
                    settingId={settings[8]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Rideshare
                    settingId={settings[9]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <ThirdPartyPayer
                    settingId={settings[10]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                  />
                  <Spacer size={10} />
                  <Workflows
                    settingId={settings[11]?.id}
                    overrideRef={organizationRef?.current}
                    inheritedRef={inheritance}
                    updateMutableRef={updateOrganizationRef}
                    checkMutableRefField={checkOrganizationRefField}
                    deleteMutableRefField={deleteOrganizationRefField}
                    isReset={isReset}
                    orgId={organization?.id}
                  />
                  <Spacer size={10} />
                </Container>
              </SettingsContent>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },

  // This is the wrapper that controls the bounding box of the scrollable element
  scrollWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 194,
    backgroundColor: theme.palette.background.paper,
  },
  // This is the element that will scroll (sits inside wrapper)
  scrollOverflow: {
    overflow: 'auto',
  },

  blurb: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.action.hover,
  },
  blurbIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  blurbTxt: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}));

//////////////////////// GQL ////////////////////////

const UPDATE_ORGANIZATION = gql`
  mutation admin_updateOrganization($organizationId: bigint!, $config: jsonb!) {
    update_organizations(where: { id: { _eq: $organizationId } }, _set: { config: $config }) {
      affected_rows
    }
  }
`;
