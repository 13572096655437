//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';
import { makeStyles, Button, Icon, Typography, Grid } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { getUserToken, getUserName } from '../../utils/authHelper';
import { UPDATE_LOCATION_DETAILS_NOTES } from './gql';

import MarkdownEditor from '../../components/MarkdownEditor';

const log = true;

//////////////////////// COMPONENT ////////////////////////

function LocationNotes({ location }) {
  const cls = useStyles();

  const { copyToClipboard } = useTools();

  const [updateLocationDetailsNotes] = useMutation(UPDATE_LOCATION_DETAILS_NOTES);

  const [markdown, setMarkdown] = React.useState(``);

  // Manage state of the file upload box
  const [dropzoneDragover, setDropzoneDragover] = React.useState(false);
  const [dropzoneError, setDropzoneError] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);

  React.useEffect(() => {
    if (location?.notes) {
      setMarkdown(location?.notes);
    }
  }, [location]);

  const handleMarkdownChange = value => {
    setMarkdown(value);
  };

  // const replaceProfaneStrings = async notes => {
  //   const profaneWords;

  //   profaneWords.forEach(word => {
  //     const regex = new RegExp(word, 'gi');
  //     notes = notes.replace(regex, `****`);
  //   });

  //   return notes;
  // };

  const handleMarkdownSave = async () => {
    let notes = markdown;

    // if (notes) {
    //   try {
    //     notes = await replaceProfaneStrings(notes);
    //   } catch (err) {
    //     toast.error(`Failed to update location notes!`);
    //     console.error(`Failed to update location notes:`, err);
    //     return;
    //   }
    // }

    try {
      const updatedBy = await getUserName();
      const res = await updateLocationDetailsNotes({
        variables: {
          locationId: location?.id,
          notes: notes || null,
          updatedBy: updatedBy || null,
        },
      });
      if (res?.data?.update_locations?.affected_rows) {
        toast.success(`Location notes saved!`);
      }
    } catch (err) {
      toast.error(`Failed to update location notes!`);
      console.error(`Failed to update location notes:`, err);
    }
  };

  /** Click upload handler */
  const handleFileClick = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    const file = e?.target?.files[0];
    log && console.log(`Handle click upload:`, file);

    // Upload the file
    await handleImageUpload(e, file);
  };

  /** Drag & drop upload handler */
  const handleFileDragAndDrop = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    let file = e.dataTransfer.files[0];
    log && console.log(`Handle drag & drop upload:`, file);

    // Upload the file
    await handleImageUpload(e, file);
  };

  /** Handle dragging over the file upload component */
  const handleFileDragoverEnter = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(true);
    setDropzoneError(false);
  };

  /** Handle dragging off the file upload component */
  const handleFileDragoverExit = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);
  };

  /** Handle uploading the file */
  const handleImageUpload = async (e, file) => {
    // Check if the file is valid
    if (!file) {
      console.error(`Failed to upload image: No file provided!`);
      toast.error(`Failed to upload image: No file provided!`);
      setDropzoneError(true);
      return;
    }
    if (file?.size > 10000000) {
      console.error(`Failed to upload image: File size must be less than 10MB!`);
      toast.error(`Failed to upload image: File size must be less than 10MB!`);
      setDropzoneError(true);
      return;
    }
    if (
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/heif' &&
      file?.type !== 'image/png' &&
      file?.type !== 'image/svg+xml'
    ) {
      console.error(`Failed to upload image: File type not supported!`);
      toast.error(`Failed to upload image: File type not supported!`);
      setDropzoneError(true);
      return;
    }

    // Get the auth token
    const token = await getUserToken();

    // Upload the file
    let reader = new FileReader();
    reader.addEventListener('loadend', async e => {
      try {
        const uniqueId = Math.floor(Math.random() * 1000000);

        const res = await axios({
          url: '/.netlify/functions/requestUploadUrl',
          method: 'POST',
          data: {
            fileName: `${location?.id}/location-image-${location?.id}-${uniqueId}`,
            fileType: file?.type,
            bucketName: 'sat-location-photos',
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const { uploadURL } = res?.data;
        const uploadRes = await axios.put(uploadURL, file, { headers: { 'Content-Type': file?.type || `image/*` } });

        if (uploadRes.status >= 200 && uploadRes.status < 400) {
          const localImageUrl = uploadURL.split('?')[0];
          setImageUrl(localImageUrl);
          copyToClipboard(`![Location](${localImageUrl})`);
          log && console.log(`Successfully uploaded the image to S3!`);
        } else {
          console.error(`Failed to upload the image to S3!`);
          toast.error(`Failed to upload the image to S3!`);
        }
      } catch (err) {
        console.error(`Failed to upload the image to S3:`, err);
        toast.error(`Failed to upload the image to S3!`);
      }
    });
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className={cls.paper}>
      <Typography className={cls.infoTxt}>
        These notes will be visible to any driver working this location. If you are unsure on how to use Markdown, read
        this{' '}
        <a
          className={cls.link}
          href='https://www.markdownguide.org/cheat-sheet/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Markdown&nbsp;Cheat&nbsp;Sheet
        </a>
        , or use the editor toolbar. The left side is the editor and the right side shows a preview of what the final
        product will look like to the driver.
      </Typography>

      <Spacer />

      <Typography className={cls.infoTxt}>
        Example of a clickable CALL link:
        <ul>
          <li>
            <b>[Tap to Call](tel:+18005551234)</b>
          </li>
        </ul>
      </Typography>

      <Spacer />

      <Typography className={cls.infoTxt}>
        Example of a clickable TEXT link (Use this{' '}
        <a className={cls.link} href='https://www.urlencoder.io/' target='_blank' rel='noopener noreferrer'>
          text&nbsp;encoder
        </a>{' '}
        to create a custom message and replace everything after "body=" with the output):
        <ul>
          <li>
            <b>[Tap to Text](sms:+18005551234&body=Hello%20there)</b>
          </li>
        </ul>
      </Typography>

      <Spacer />

      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={12} md={6}>
          <label
            className={clsx(cls.dropzone, {
              [cls.dropzoneDragover]: dropzoneDragover,
              [cls.dropzoneError]: dropzoneError,
            })}
            onDrop={handleFileDragAndDrop}
            onDragOver={handleFileDragoverEnter}
            onDragLeave={handleFileDragoverExit}
          >
            <Typography className={cls.dropzoneTxt}>Click or drag image file here</Typography>
            <input
              className={cls.hide}
              id='location-image-uploader'
              type='file'
              accept='image/jpg, image/jpeg, image/heif, image/png, image/svg+xml'
              onChange={handleFileClick}
            />
          </label>
        </Grid>

        <Grid item xs={12} md={6}>
          {imageUrl ? (
            <div className={cls.copyBox} onClick={() => copyToClipboard(`![Location](${imageUrl})`)}>
              <div className={cls.flex}>
                <Icon className={cls.copyIcon}>content_copy</Icon>

                <div className={cls.copyString}>
                  <Typography className={cls.copyTxt}>Click to copy, then paste your image into the notes:</Typography>
                  <Typography className={cls.copyURLTxt}>![Location]({imageUrl})</Typography>
                </div>
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Spacer />

      <div className={cls.flex}>
        <Button disableElevation color='primary' variant='contained' onClick={() => handleMarkdownSave()}>
          <Icon className={cls.btnIcon}>save</Icon>
          Save
        </Button>

        {location?.notes_updated_at && location?.notes_updated_by ? (
          <Typography className={cls.lastSavedTxt}>
            Last saved {dayjs(location?.notes_updated_at).format(`M/D/YYYY`)} at{' '}
            {dayjs(location?.notes_updated_at).format(`h:mm A`)} by {location?.notes_updated_by || `Unknown`}
          </Typography>
        ) : null}
      </div>

      <Spacer />

      <MarkdownEditor height={720} value={markdown} onChange={handleMarkdownChange} onSave={handleMarkdownSave} />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  infoTxt: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
  lastSavedTxt: {
    marginLeft: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  dropzone: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 64,
    padding: theme.spacing(2),
    border: theme.border[1],
    borderStyle: 'dashed',
    borderRadius: theme.shape.paperRadius,
    cursor: 'pointer',
    transition: `0.15s all ease-in-out`,
  },
  dropzoneDragover: {
    border: theme.border[1],
    borderColor: theme.palette.text.primary,
    '& $dropzoneTxt': {
      color: theme.palette.text.primary,
    },
  },
  dropzoneError: {
    border: theme.border[1],
    borderColor: theme.palette.error.main,
    '& $dropzoneTxt': {
      color: theme.palette.error.main,
    },
  },

  dropzoneTxt: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: `0.15s all ease-in-out`,
    userSelect: 'none',
    pointerEvents: 'none',
  },

  hide: {
    display: 'none',
  },

  copyBox: {
    height: 64,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.background.light,
    cursor: 'pointer',
    transition: `0.15s all ease-in-out`,
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
  copyIcon: {
    marginRight: theme.spacing(1),
  },
  copyString: {
    overflow: 'hidden',
  },
  copyTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  copyURLTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default LocationNotes;
