// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Grid } from '@material-ui/core';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

import { GPSViewerProvider } from './GPSViewerProvider';
import GPSViewerSidebar from './GPSViewerSidebar';
import GPSViewerMap from './GPSViewerMap';

// COMPONENT -------------------------------------------------- //

export default function GPSViewer(props) {
  const cls = useStyles();

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback />}>
      <div className={cls.root}>
        <GPSViewerProvider>
          <Grid container>
            <Grid item>
              <div className={cls.heightUnlock}>
                <GPSViewerSidebar />
              </div>
            </Grid>

            <Grid item xs>
              <div className={cls.heightUnlock}>
                <GPSViewerMap />
              </div>
            </Grid>
          </Grid>
        </GPSViewerProvider>
      </div>
    </Sentry.ErrorBoundary>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  heightUnlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 64,
  },
}));
