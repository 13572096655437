let _siteWideLoggingIsEnabled = true;

function SimpleLogger(options = { prefix: undefined, enabled: true }) {
  const { prefix, enabled } = options;
  this.enabled = enabled;

  return {
    log: (m, ...a) => {
      try {
        if (a) {
          this.enabled && _siteWideLoggingIsEnabled && prefix && console.log(`[${prefix}] ${m}`, ...a);
          this.enabled && _siteWideLoggingIsEnabled && !prefix && console.log(`${m}`, ...a);
        } else {
          this.enabled && _siteWideLoggingIsEnabled && prefix && console.log(`[${prefix}] ${m}`);
          this.enabled && _siteWideLoggingIsEnabled && !prefix && console.log(`${m}`);
        }
      } catch (error) {
        console.error(`[${prefix}] [SimpleLogger] Exception caught in log message: ${error.message}`);
      }
    },
    enableSiteWideLogging: enabled => {
      _siteWideLoggingIsEnabled = enabled;
    },
  };
}

export { SimpleLogger };
