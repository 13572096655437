import React from 'react';
import { makeStyles } from '@material-ui/core';

import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPlate({ driver = {} }) {
  const cls = useStyles();

  const plate = driver.plate || {};

  return <div className={cls.paper}>{!plate.id && <DefaultEmptyFallback message='NO PLATE FOUND' text />}</div>;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
}));
