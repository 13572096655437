// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { buildDriverPayCycleArray } from '@hopdrive/sdk/lib/modules/wallet';
import { GET_PAYCYCLE_BY_ID } from '@hopdrive/sdk/lib/modules/wallet';

import { gql, useQuery, useLazyQuery } from '@apollo/client';

import { Chip, Icon, makeStyles, Typography } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import Toolbar from '../../components/Toolbar';
import Filter from '../../components/Filter/Filter';
import ActionsMenu from '../../components/ActionsMenu';

import { useTools } from '../../hooks/useTools';
import useDriverPay from './useDriverPay';

import PayCycleDetailsContent from './PayCycleDetailsContent';

const baseFilters = Object.freeze({
  select: Object.freeze({
    value: `all`,
  }),
});

const getLocalFilters = () => {
  const localFilters = JSON.parse(localStorage.getItem(`Paycycle-Filters`)) || {};
  return localFilters;
};

// COMPONENT ---------------------------------------------------------------- //

export default function PayCycleDetails(props) {
  const cls = useStyles();

  const paycycleId = props.match.params.id;

  const { capFirst } = useTools();
  const { generateCSVFromDriverPayArray, getPaycycleStatusFromPaycycle } = useDriverPay();

  const [filters, setFilters] = React.useState(getLocalFilters());
  const [filterConfig, setFilterConfig] = React.useState({
    modules: [`select`],
    moduleOptions: {
      select: {
        allowAll: true,
        icon: `public`,
        label: `Region Select`,
        options: [],
      },
    },
  });
  const [isPaying, setIsPaying] = React.useState(false);

  const [getRegions] = useLazyQuery(GET_REGIONS);

  React.useEffect(() => {
    const fetchRegions = async () => {
      try {
        const res = await getRegions();
        const newRegions =
          res?.data?.regions
            ?.map(r => ({ value: r?.id, label: `${r?.name} (${r?.id})` }))
            ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

        setFilterConfig({
          ...filterConfig,
          moduleOptions: {
            ...filterConfig.moduleOptions,
            select: { ...filterConfig.moduleOptions.select, options: newRegions },
          },
        });
      } catch (err) {
        console.error(`Error fetching regions`, err);
      }
    };
    fetchRegions();
  }, []);

  const handleApplyFilters = output => {
    setFilters(output);
    localStorage.setItem(`Paycycle-Filters`, JSON.stringify(output));
  };
  const handleClearFilters = output => {
    setFilters(output);
    localStorage.removeItem(`Paycycle-Filters`);
  };
  const handleToggleFilters = output => {
    setFilters(output);
  };

  const { loading, error, data, refetch } = useQuery(GET_PAYCYCLE_BY_ID, {
    variables: { paycycleId: paycycleId },
    fetchPolicy: `no-cache`,
  });
  const paycycle = data?.paycycles?.[0] || null;
  const defaultDriverPayArray = buildDriverPayCycleArray(paycycle);

  const actions = [
    {
      label: 'Generate CSV',
      handler: () => generateCSVFromDriverPayArray(paycycle, defaultDriverPayArray),
    },
  ];

  const getStatusChipClass = status => {
    switch (status) {
      case `accruing`:
        return cls.statusAccruing;
      case `preparing`:
        return cls.statusPreparing;
      case `prepared`:
        return cls.statusPrepared;
      case `closed`:
        return cls.statusClosed;
      case `done`:
        return cls.statusDone;
      case `failed`:
        return cls.statusFailed;
      default:
        return undefined;
    }
  };

  const getStatusChipVariant = status => {
    switch (status) {
      case `accruing`:
      case `preparing`:
        return `outlined`;
      case `prepared`:
      case `closed`:
      case `done`:
      case `failed`:
      default:
        return `default`;
    }
  };

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow back>
        <div className={cls.flex}>
          <div>
            <div className={cls.flex}>
              <Typography className={cls.title}>Pay Cycle</Typography>
              {paycycle ? (
                <>
                  <Chip size='small' label={paycycle?.tax_class || `N/A`} />
                  <Chip
                    className={getStatusChipClass(getPaycycleStatusFromPaycycle(paycycle))}
                    label={capFirst(paycycle?.status) || `N/A`}
                    icon={
                      getPaycycleStatusFromPaycycle(paycycle) === `done` ? (
                        <Icon className={cls.statusIcon}>check</Icon>
                      ) : undefined
                    }
                    variant={getStatusChipVariant(paycycle?.status)}
                    size='small'
                  />
                </>
              ) : null}
            </div>

            <Typography className={cls.subtitle}>
              {paycycle
                ? `${dayjs.utc(dayjs(paycycle?.start_date)).format(`MMMM Do`)} - ${dayjs
                    .utc(dayjs(paycycle?.end_date))
                    .format(`MMMM Do, YYYY`)}`
                : `Fetching...`}
            </Typography>
          </div>

          <div className={cls.flexItemStretch} />

          {isPaying ? (
            <div className={cls.flex}>
              <Typography className={cls.paying}>Paying Drivers...</Typography>
              <Loading />
            </div>
          ) : null}

          <div className={cls.flexItemStretch} />

          <Filter
            tooltip={`Open Pay Cycle Filters`}
            baseFilters={baseFilters}
            localFilters={getLocalFilters()}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
            onToggle={handleToggleFilters}
            config={filterConfig}
          />

          <ActionsMenu tooltip={`Open Pay Cycle Actions`} actions={actions} />
        </div>
      </Toolbar>

      <PayCycleDetailsContent
        loading={loading}
        error={error}
        refetch={() => refetch()}
        paycycle={paycycle}
        defaultDriverPayArray={defaultDriverPayArray}
        filters={filters}
        isPaying={isPaying}
        setIsPaying={setIsPaying}
      />
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },

  title: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  subtitle: {
    marginTop: 4,
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  paying: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexItemStretch: {
    flex: 1,
  },

  btn1: {
    height: 36.5,
    borderRadius: `4px 0 0 4px`,
  },
  btn2: {
    height: 36.5,
    borderRadius: `0 4px 4px 0`,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },

  statusAccruing: {
    borderColor: theme.palette.default.main,
    color: theme.palette.default.main,
  },
  statusPreparing: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  statusPrepared: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  statusClosed: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusDone: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  statusFailed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },

  statusIcon: {
    fontSize: 16,
    color: theme.palette.success.contrastText,
  },
}));

// GRAPHQL ---------------------------------------------------------------- //

const GET_REGIONS = gql`
  query getRegions {
    regions {
      id
      name
    }
  }
`;
