// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Grid, TextField, InputAdornment, Icon, Typography, Tooltip, Collapse } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Spacer } from '@hopdrive/storybook';

import IconClearFilters from '../../components/IconClearFilters';
import IconUnfold from '../../components/IconUnfold';

import { useGPSViewer } from './GPSViewerProvider';
import DriverSelect from '../../components/DriverSelect';

// COMPONENT -------------------------------------------------- //

export default function GPSViewerFilter(props) {
  const cls = useStyles();

  const {
    // Variables
    driverId,
    moveId,
    start,
    end,
    showRaw,
    showPings,

    // Handlers
    onDriverIdChange,
    onMoveIdChange,
    onDateChange,
    onShowRawChange,
    onShowPingsChange,
    onClearFilters,
  } = useGPSViewer();

  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDriverIdChange = event => {
    onDriverIdChange(event.target.value);
  };
  const handleMoveIdChange = event => {
    onMoveIdChange(event.target.value);
  };
  const handleDate = type => event => {
    onDateChange(event, type);
  };

  return (
    <div className={cls.filter}>
      <div className={cls.filterBar}>
        <Grid container alignItems='center'>
          <Grid item xs>
            <Typography className={cls.filterTitle}>Options</Typography>
          </Grid>

          <Grid item>
            <IconClearFilters onClear={() => onClearFilters()} />
          </Grid>

          <Grid item>
            <IconUnfold unfold={open} onUnfold={() => handleOpen()} />
          </Grid>
        </Grid>
      </div>

      <div className={cls.collapse}>
        <Collapse timeout='auto' in={open}>
          <>
            <Spacer size='lg' />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  <Typography className={cls.labelTxt}>By Driver</Typography>

                  <Tooltip title={`Search for path/points by driver for no longer than the duration of a day.`}>
                    <Icon className={cls.labelIcon}>help</Icon>
                  </Tooltip>

                  <Spacer />

                  <DriverSelect label='Driver' value={driverId || 0} onChange={handleDriverIdChange} />
                </Grid>

                <Grid item xs={6}>
                  <DateTimePicker
                    autoOk
                    // maxDate={end}
                    label='Start Datetime'
                    inputStyle={{ textAlign: 'left' }}
                    format='MM/dd/yyyy hh:mm a'
                    orientation='portrait'
                    size='small'
                    variant='inline'
                    inputVariant='outlined'
                    value={start}
                    onChange={handleDate(`start`)}
                    className={cls.date}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DateTimePicker
                    autoOk
                    // minDate={start}
                    label='End Datetime'
                    format='MM/dd/yyyy hh:mm a'
                    orientation='portrait'
                    size='small'
                    variant='inline'
                    inputVariant='outlined'
                    value={end}
                    onChange={handleDate(`end`)}
                    className={cls.date}
                  />
                </Grid>

                <Grid item xs={12} style={{ position: 'relative' }}>
                  <Typography className={cls.labelTxt}>By Move</Typography>

                  <Tooltip title={`Search for path/points by move (Takes priority over driver).`}>
                    <Icon className={cls.labelIcon}>help</Icon>
                  </Tooltip>

                  <Spacer />

                  <TextField
                    fullWidth
                    label='Move ID'
                    placeholder='Search by move ID...'
                    variant='outlined'
                    size='small'
                    value={moveId}
                    onChange={handleMoveIdChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            directions_car
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={6} style={{ position: 'relative' }}>
                  <Typography className={cls.labelTxt}>Path Type</Typography>

                  <Tooltip
                    title={`Show the raw path from our database or smoothed path from Google's Snap to Roads API.`}
                  >
                    <Icon className={cls.labelIcon}>help</Icon>
                  </Tooltip>

                  <Spacer size='xs' />

                  <div className={cls.btnGroup}>
                    <Button
                      className={cls.btnL}
                      fullWidth
                      variant={showRaw ? `contained` : `outlined`}
                      onClick={() => onShowRawChange(true)}
                    >
                      Raw
                    </Button>

                    <Button
                      className={cls.btnR}
                      fullWidth
                      variant={!showRaw ? `contained` : `outlined`}
                      onClick={() => onShowRawChange(false)}
                    >
                      Smooth
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={6} style={{ position: 'relative' }}>
                  <Typography className={cls.labelTxt}>Show Pings</Typography>

                  <Tooltip title={`Show pings along the path (Does not require redraw).`}>
                    <Icon className={cls.labelIcon}>help</Icon>
                  </Tooltip>

                  <Spacer size='xs' />

                  <div className={cls.btnGroup}>
                    <Button
                      className={cls.btnL}
                      fullWidth
                      variant={showPings ? `contained` : `outlined`}
                      onClick={() => onShowPingsChange(true)}
                    >
                      Yes
                    </Button>

                    <Button
                      className={cls.btnR}
                      fullWidth
                      variant={!showPings ? `contained` : `outlined`}
                      onClick={() => onShowPingsChange(false)}
                    >
                      No
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </>
        </Collapse>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  filterTitle: {
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 600,
  },

  date: {
    width: '100%',
  },

  labelTxt: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  labelIcon: {
    position: 'absolute',
    top: 5,
    right: 6,
    fontSize: 20,
    color: theme.palette.text.secondary,
  },

  btnGroup: {
    position: 'relative',
    display: 'flex',
  },
  btnL: {
    borderRadius: `4px 0 0 4px`,
  },
  btnR: {
    borderRadius: `0 4px 4px 0`,
  },
}));
