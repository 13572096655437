import React, { useEffect, useState } from 'react';
import { makeStyles, TablePagination } from '@material-ui/core';

export default function Pagination(props) {
  const cls = useStyles();

  const { setLimit = () => {}, setOffset = () => {}, totalRecords = 0, page, setPage, rowsPerPage = 50, setRowsPerPage = () => {} } = props;

  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(rowsPerPage);
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    setLimit(rowsPerPage);
    const start = page ? page * rowsPerPage : currentPage * rowsPerPage;
    const end = page ? page * rowsPerPage + rowsPerPage : currentPage * rowsPerPage + rowsPerPage;

    const setPages = () => {
      if (totalRecords === 0) {
        setOffset(0)
        setPageStart(0);
        setPageEnd(0);
      } else if (end < totalRecords && (page !== 0 || currentPage !== 0)) {
        setOffset(start);
        setPageStart(start + 1);
        setPageEnd(end);
      } else if ((page === 0 || currentPage === 0) && end <= totalRecords) {
        setOffset(start);
        setPageStart(1);
        setPageEnd(rowsPerPage);
      } else {
        setOffset(start);
        setPageStart(start + 1 || 1);
        setPageEnd(totalRecords);
      }
    };

    setPages();

  }, [page, rowsPerPage, totalRecords, setOffset, setLimit, currentPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(0)
  };

  return (
    <div>
        <TablePagination
          component='div'
          count={totalRecords}
          page={page || currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={cls.pagination}
          labelDisplayedRows={({ from, to, count }) =>
          props.checkedItemsLength > 0 ? 
            `${from}-${to} of ${count} (${props.checkedItemsLength} selected)`
          :`${from}-${to} of ${count}`}
        />
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      height: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: '15%',
    },
  },
}));