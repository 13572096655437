import React, { useEffect, useState } from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';

export default function DatetimeSelect(props) {
  const { timeData, onChange, type, timezone } = props;

  ////////// VARIABLES //////////

  const [selectedTimezone, setSelectedTimezone] = useState(timezone || 'America/New_York');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [userChange, setUserChange] = useState(false)

  ////////// SET VALUES TO RENDER //////////

  useEffect(() => {
    if (timeData) {
      const convertedTime = convertTime(timeData);
      if (convertedTime && convertedTime.date) setSelectedDate(convertedTime.date);
      if (convertedTime && convertedTime.time) setSelectedTime(convertedTime.time);
    }
  }, [timeData, timezone]);

  //When DatetimeSelect gets the incoming timestamp, separate the date and time to use as individually editable values
  const convertTime = time => {
    // Parse the input time with the given offset
    const parsedTime = moment.tz(time, 'YYYY-MM-DDTHH:mm:ssZ', selectedTimezone);

    // Format the date and time in the desired timezone
    const convertedDate = parsedTime.format('YYYY-MM-DD');
    const convertedTime = parsedTime.format('HH:mm');

    return { date: convertedDate, time: convertedTime };
  };


  //When either the time or the selected timezone change, translate the current date and time to render according to the selected timezone
  useEffect(() => {
    // Combine the date and time
    const combinedDateTime = moment.tz(`${selectedDate} ${selectedTime}`, selectedTimezone);
    // Convert to UTC
    const utcDateTime = combinedDateTime.utc();
    // Get the UTC timestamp in milliseconds
    const utcTimestamp = utcDateTime.valueOf();
    if (utcTimestamp && userChange) {
      onChange(utcTimestamp);
      setUserChange(false)
    }
  }, [selectedDate, selectedTime, userChange]);

  ////////// HANDLE CHANGES //////////

  const handleSelectedTimezone = event => {
    const combinedDateTimeOld = moment.tz(`${selectedDate} ${selectedTime}`, selectedTimezone);
    const oldOffset = Math.abs(combinedDateTimeOld._offset)
    const combinedDateTimeNew = moment.tz(`${selectedDate} ${selectedTime}`, event.target.value);
    const newOffset = Math.abs(combinedDateTimeNew._offset)
    const offsetDiff = oldOffset - newOffset
    const newDateTime = combinedDateTimeNew.add(offsetDiff, 'minutes');
    const convertedDate = newDateTime.format('YYYY-MM-DD');
    const convertedTime = newDateTime.format('HH:mm');
    setSelectedDate(convertedDate);
    setSelectedTime(convertedTime);
    setSelectedTimezone(event.target.value);
  };

  const handleSelectedDate = event => {
    setUserChange(true)
    setSelectedDate(event.target.value);
  };

  const handleSelectedTime = event => {
    setUserChange(true)
    setSelectedTime(event.target.value);
  };

  ////////// RENDER //////////
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            required
            name='date'
            type='date'
            value={selectedDate}
            fullWidth
            variant='outlined'
            size='small'
            onChange={handleSelectedDate}
            label={type === 'begin' ? 'Effective Date' : 'End Date'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            required
            name='time'
            type='time'
            fullWidth
            variant='outlined'
            onChange={handleSelectedTime}
            value={selectedTime}
            size='small'
            label={type === 'begin' ? 'Effective Time' : 'End Time'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label={'Timezone'}
            size='small'
            value={selectedTimezone}
            onChange={handleSelectedTimezone}
            style={{ width: '55%' }}
            variant='outlined'
          >
            <MenuItem value='America/New_York'>America/New York</MenuItem>
            <MenuItem value='America/Chicago'>America/Chicago</MenuItem>
            <MenuItem value='America/Phoenix'>America/Phoenix</MenuItem>
            <MenuItem value='America/Denver'>America/Denver</MenuItem>
            <MenuItem value='America/Los_Angeles'>America/Los Angeles</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </>
  );
}
