import { gql } from 'graphql-tag';
import { useData } from '../../../providers/DataProvider';
import { useTookan } from '../../../hooks/useTookan';
import { useSettings } from '../providers/SettingsProvider';
import { useDrivers } from '../providers/DriversProvider';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { toast } from 'react-toastify';
import { getUserEmail } from '../../../utils/authHelper';
import sdk from '@hopdrive/sdk';
// import { useEnrichedPlans } from './useEnrichedPlans';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////// COMPONENT ////////////////////////

export function useSchedulerPersist() {
  const { apolloClient } = useData();
  const { Tookan } = useTookan();
  const { enablePersistLogs } = useSettings();
  const { getDriverById } = useDrivers();

  const { log } = new SimpleLogger({ prefix: 'useSchedulerPersist', enabled: enablePersistLogs });

  /** Calls the buildUpsertableMovesArray function and upserts
   *
   * @param {Array} enrichedMovesArray Array of enriched moves to build into an upsertable array
   * @param {Array} enrichedMovesToUnplanArray Array of enriched moves to unplan to build into an upsertable array
   * @param {Array} planId ID of the plan to update the updatedat timestamp
   * @returns {Obj} custom object with UpsertedMoves (Array) and UpdatedPlan
   */

  const handleUpsertMoves = async (enrichedMovesArray = [], enrichedMovesToUnplanArray = [], planId) => {
    let upsertedMoves;
    let updatedPlan;
    try {
      // Build the upsertable moves array
      log(`Building upsertable moves...`, enrichedMovesArray);
      const upsertableMovesArray = buildUpsertableMovesArray(enrichedMovesArray);
      log(`Built upsertable moves:`, upsertableMovesArray);

      //Find any rides without parent moves and add them to the moves to unplan array
      let floatingRidesToUnplan = findFloatingRides(enrichedMovesArray);
      enrichedMovesToUnplanArray = enrichedMovesToUnplanArray.concat(floatingRidesToUnplan);

      // Filter out moves without IDs as they aren't persisted anyway
      const enrichedUnplannableMovesArray = enrichedMovesToUnplanArray.filter(
        enrichedMove => enrichedMove && enrichedMove.move && enrichedMove.move.id
      );

      // Build the upsertable moves to unplan array
      log(`Building upsertable moves to unplan...`, enrichedUnplannableMovesArray);
      let upsertableMovesToUnplanArray = buildUpsertableMovesToUnplanArray(enrichedUnplannableMovesArray);
      //check for floating rides and unplan them
      log(`Built upsertable moves to unplan:`, upsertableMovesToUnplanArray);

      // Find all related ridegroups from unplannable moves
      log(`Finding related ridegroups to delete...`, enrichedUnplannableMovesArray);
      const ridegroupIdsToDeleteArray = enrichedUnplannableMovesArray
        ?.map(em => em?.move?.ridegroup?.id)
        ?.filter(rgId => rgId);

      // Concat the two arrays to build an array with all upsertable moves
      let movesToUpsert = upsertableMovesArray.concat(upsertableMovesToUnplanArray);

      //Filter out possible duplicate moves to prevent potential edge case error
      let upsertableMoveIds = [];
      let finalMovesToUpsert = [];

      movesToUpsert.forEach(move => {
        if (move.id && !upsertableMoveIds.includes(move.id)) {
          upsertableMoveIds.push(move.id);
          finalMovesToUpsert.push(move);
        } else if (!move.id) {
          finalMovesToUpsert.push(move);
        }
      });

      // Check the array - Upsert the moves
      if (finalMovesToUpsert && finalMovesToUpsert.length > 0) {
        log(`Upserting moves...`, finalMovesToUpsert);
        const res = await apolloClient.mutate({
          mutation: UPSERT_MOVES,
          variables: { moveObjects: finalMovesToUpsert, planId: planId },
        });
        if (res?.data?.insert_moves?.returning) {
          upsertedMoves = res.data.insert_moves.returning;
          upsertedMoves.map(move => {
            if (move.move_type === 'ride' && move.active === 0) {
              log('unplanned ride move', move);
              buildEventLogCancelRide(move);
            }
          });
          updatedPlan = res?.data?.update_plans?.returning?.[0] || null;

          // Get what we need to sync unplanned moves with Tookan
          let unplannableTookanMoveIds = upsertableMovesToUnplanArray.map(um => um.id);
          let unplannableTookanMoves = enrichedMovesToUnplanArray
            .map(em => {
              return { id: em.id, pickup_stop_id: em.move.pickup_stop_id, delivery_stop_id: em.move.delivery_stop_id };
            })
            .filter(um => unplannableTookanMoveIds.includes(um.id));
          unplannableTookanMoves.forEach(um => {
            Tookan.handleMoveUnplan(apolloClient, um);
          });

          // Delete related ridegroups if any are related
          if (ridegroupIdsToDeleteArray?.length) {
            log(`Deleting related ridegroups...`, ridegroupIdsToDeleteArray);
            const res = await apolloClient.mutate({
              mutation: DELETE_RIDEGROUPS,
              variables: { ridegroupIds: ridegroupIdsToDeleteArray },
            });
            if (res?.data?.delete_ridegroups?.affected_rows) {
              log(`Deleted ${res?.data?.delete_ridegroups?.affected_rows} related ridegroup(s)!`);
            }
          }
        }
      } else {
        log(`No moves to upsert!`);
      }
      return { upsertedMoves: upsertedMoves, updatedPlan: updatedPlan };
    } catch (err) {
      toast.error('Error saving plan!');
      console.error('Error saving plan:', err);
      return null;
    }
  };

  //Finds any rides without parent_move_ids or with parent_move_ids of drive moves that are not in the plan
  const findFloatingRides = enrichedMovesArray => {
    let floatingRidesArray = [];
    let enrichedRidesArray = enrichedMovesArray.filter(em => em.move && em.move.move_type === 'ride');
    let enrichedDriveIdsArray = enrichedMovesArray
      .filter(em => em.move && em.move.move_type === 'drive')
      .map(em => em.id);
    enrichedRidesArray.forEach(enrichedRide => {
      if (!enrichedDriveIdsArray.includes(enrichedRide.parentMoveId)) {
        floatingRidesArray.push(enrichedRide);
      }
    });
    return floatingRidesArray;
  };

  /** Creates an array of move objects to be upserted into the database
   *
   * @param {Array} enrichedMovesArray Array of enriched moves to build into an upsertable array
   */

  const buildUpsertableMovesArray = enrichedMovesArray => {
    // Check for enrichedMovesArray
    if (!enrichedMovesArray || !enrichedMovesArray.length > 0) return [];

    // Initialize upsertable moves
    let upsertableMovesArray = [];

    // Loop over enriched moves and build upsertable moves, then push them to the array
    for (let i = 0; i < enrichedMovesArray.length; i++) {
      // Set the enrichedMove and build the upsertable moves
      let enrichedMove = enrichedMovesArray[i];
      const parentMoveDriverStatus = enrichedMove?.withOverrides?.driver_status || null;

      if (enrichedMove.enrichedRideBefore) {
        let upsertableRideBefore = buildUpsertableRide(enrichedMove.enrichedRideBefore, parentMoveDriverStatus);
        upsertableMovesArray.push(upsertableRideBefore);
      }
      if (enrichedMove.enrichedRideAfter) {
        let upsertableRideAfter = buildUpsertableRide(enrichedMove.enrichedRideAfter, parentMoveDriverStatus);
        upsertableMovesArray.push(upsertableRideAfter);
      }

      if (enrichedMove.move && enrichedMove.move.move_type === 'drive') {
        let upsertableDrive = buildUpsertableDrive(enrichedMove);
        upsertableMovesArray.push(upsertableDrive);
      }
    }

    upsertableMovesArray = upsertableMovesArray.filter(upsertableMove => upsertableMove !== null);
    return upsertableMovesArray;
  };

  /** Creates an array of move objects to be upserted into the database
   *
   * @param {Array} enrichedMovesArray Array of enriched moves to build into an upsertable array
   */

  //Function to build event log when a return ride is canceled
  const buildEventLogCancelRide = async move => {
    try {
      // Insert move update eventlog
      let userEmail = getUserEmail();

      let eventConfig = {
        action: `return.ride.canceled`,
        user: userEmail,
        role: 'admin',
        move_id: move.id,
        customer_id: move.customer_id,
        lane_id: move.lane_id,
        driver_id: move.driver_id,
      };
      await sdk.events.buildAndCreate(eventConfig);
      // log(`Successfully inserted eventlog ${logRes.id}`);
    } catch (err) {
      log(err);
    }
  };

  const buildUpsertableMovesToUnplanArray = enrichedMovesToUnplanArray => {
    // Check for enrichedMovesArray
    if (!enrichedMovesToUnplanArray || !enrichedMovesToUnplanArray.length > 0) return [];

    // Initialize upsertable moves
    let upsertableMovesToUnplanArray = [];

    // Loop over enriched moves and build upsertable moves, then push them to the array
    for (let i = 0; i < enrichedMovesToUnplanArray.length; i++) {
      // Set the enrichedMove and build the upsertable move
      let enrichedMove;
      if (enrichedMovesToUnplanArray[i].id !== null) {
        enrichedMove = enrichedMovesToUnplanArray[i];
      } else {
        enrichedMove = null;
      }
      const moveToUpsert = buildUpsertableMoveToUnplan(enrichedMove);

      // Make sure there is a move to upsert
      if (moveToUpsert) upsertableMovesToUnplanArray.push(moveToUpsert);
      // Otherwise, log an error and do not push anythinng to the array
      else log(`moveToUpsert not found from enrichedMove!`, enrichedMove);
    }

    // Filter out falsey moves and return the array
    upsertableMovesToUnplanArray = upsertableMovesToUnplanArray.filter(upsertableMove => upsertableMove.id !== null);
    return upsertableMovesToUnplanArray;
  };

  /** Creates an upsertable move (drive) object from an enrichedMove
   *
   * @param {Object} enrichedDrive Object from the array of enriched moves that will be upserted
   */

  const buildUpsertableDrive = enrichedDrive => {
    if (!enrichedDrive) return null;
    const driveWithOverrides = enrichedDrive.withOverrides;

    // Check for valid upsertable drive
    if (
      !driveWithOverrides ||
      driveWithOverrides.move_type !== `drive` ||
      !enrichedDrive.hasChanges ||
      !enrichedDrive.isValid
    ) {
      log(`Upsertable drive failed validation!`, enrichedDrive);
      return null;
    }

    // Build the upsertable move - Only gathering the fields we need for the upsert
    let upsertableDrive = {
      id: driveWithOverrides.id,
      active: driveWithOverrides.active,
      cancel_reason: driveWithOverrides.cancel_reason,
      cancel_status: driveWithOverrides.cancel_status,
      chargeable: driveWithOverrides.chargeable,
      class: driveWithOverrides.class,
      config: driveWithOverrides.config,
      customer_id: driveWithOverrides.customer_id,
      delivery_time: driveWithOverrides.delivery_time,
      driver_app_version: driveWithOverrides.driver_app_version,
      driver_id: driveWithOverrides.driver_id,
      driver_name: driveWithOverrides.driver_name,
      driver_status: driveWithOverrides.driver_status,
      lane_id: driveWithOverrides.lane_id,
      lyft_flag: driveWithOverrides.lyft_flag,
      lyft_trigger_id: driveWithOverrides.lyft_trigger_id,
      move_failed: driveWithOverrides.move_failed,
      move_type: 'drive',
      parent_move_id: driveWithOverrides.parent_move_id,
      pickup_time: driveWithOverrides.pickup_time,
      pinnable: driveWithOverrides.pinnable,
      plan_id: driveWithOverrides.plan_id,
      rate_class_override: driveWithOverrides.rate_class_override,
      return_ride_id: driveWithOverrides.return_ride_id,
      ride_type: driveWithOverrides.ride_type,
      sequence: driveWithOverrides.sequence,
      status: driveWithOverrides.status,
      updatedat: 'now()',
    };

    // Check for accessorials in the overrides
    if (enrichedDrive.overrides.accessorials) {
      const accessorials = {
        data: enrichedDrive.overrides.accessorials,
        on_conflict: {
          constraint: 'accessorials_pkey',
          update_columns: [],
        },
      };
      upsertableDrive.accessorials = accessorials;
    }
    log('upsertableDrive', upsertableDrive);
    // Return the upsertable drive
    return upsertableDrive;
  };

  /** Creates an upsertable move (ride) object from an enrichedMove
   *
   * @param {Object} enrichedRide Object from the array of enriched moves that will be upserted
   * @param {String} parentDriveMoveSDriverStatus The driver_status of the parent drive move. Added when the ride is upserted so it will match its parent's driver_status
   * @returns
   */

  const buildUpsertableRide = (enrichedRide, parentDriveMoveDriverStatus) => {
    if (!enrichedRide) return null;
    const rideWithOverrides = enrichedRide.withOverrides;

    // Check for valid upsertable ride
    if (enrichedRide.isPlaceholder) {
      log(`Placeholder ignored when building upsertable ride:`, enrichedRide);
      return null;
    }
    if (
      !rideWithOverrides ||
      rideWithOverrides.move_type !== `ride` ||
      enrichedRide.googleEnrichmentStatus !== `successful` ||
      enrichedRide.apEnrichmentStatus !== `successful` ||
      enrichedRide.arEnrichmentStatus !== `successful` ||
      !enrichedRide.hasChanges ||
      !enrichedRide.isValid
    ) {
      log(`Upsertable ride failed validation:`, enrichedRide);
      return null;
    }

    // Build the upsertable ride - Only gathering the fields we need for the upsert
    let upsertableRide = {
      active: 1,
      cancel_reason: null,
      cancel_status: null,
      chargeable: rideWithOverrides.chargeable,
      class: 'stranded',
      customer_id: rideWithOverrides.customer_id,
      delivery_time: rideWithOverrides.delivery_time,
      driver_app_version: rideWithOverrides.driver_app_version,
      driver_id: rideWithOverrides.driver_id,
      driver_name: rideWithOverrides.driver_name,
      driver_status: parentDriveMoveDriverStatus || rideWithOverrides.driver_status,
      lane_id: rideWithOverrides.lane.id,
      lyft_flag: 0,
      lyft_trigger_id: rideWithOverrides.lyft_trigger_id,
      move_type: 'ride',
      pickup_time: rideWithOverrides.pickup_time,
      plan_id: rideWithOverrides.plan_id,
      parent_move_id: rideWithOverrides.parent_move_id || enrichedRide.parentMoveId,
      ride_type: rideWithOverrides.ride_type,
      rate_class_override: 0, // This was always set to 0 in old timeline
      return_ride_id: null,
      status: null,
      sequence: rideWithOverrides.sequence,
      createdat: 'now()',
      updatedat: 'now()',
    };

    // Add id if the ride has one and overwrite existing fields
    if (rideWithOverrides.id) {
      upsertableRide.id = rideWithOverrides.id;
      upsertableRide.active = rideWithOverrides.active;
      upsertableRide.cancel_reason = rideWithOverrides.cancel_reason;
      upsertableRide.cancel_status = rideWithOverrides.cancel_status;
      upsertableRide.chargable = rideWithOverrides.chargable;
      upsertableRide.lyft_flag = rideWithOverrides.lyft_flag;
      upsertableRide.status = rideWithOverrides.status;
      upsertableRide.createdat = rideWithOverrides.createdat;
    }

    // Persist the lane object if its new
    if (rideWithOverrides.lane && !rideWithOverrides.lane.id) {
      delete rideWithOverrides.lane.pickup;
      delete rideWithOverrides.lane.delivery;
      upsertableRide.lane = {
        data: rideWithOverrides.lane,
        on_conflict: {
          constraint: 'lanes_customer_id_origin_location_id_destination_location_id_ke',
          update_columns: ['active'],
        },
      };

      // Remove the lane id before insert so it gets overriden by the nested lane's id
      // This is just a safety precaution to make sure the lane_id gets set properly
      delete upsertableRide.lane_id;
    }

    // Return the upsertable ride
    return upsertableRide;
  };

  /** Creates an upsertable move from the enrichedMovesToUnplan array
   *
   * @param {Object} enrichedMove Object from the array of enriched moves that will be upserted (unplanned)
   */

  const buildUpsertableMoveToUnplan = enrichedMove => {
    if (!enrichedMove) return null;
    const moveWithOverrides = enrichedMove.withOverrides;

    // Check for valid upsertable move to unplan
    if (!moveWithOverrides) {
      log(`Upsertable move to unplan failed validation!`, enrichedMove);
      return null;
    }

    // Build the upsertable move - Only gathering the fields we need for the upsert
    let upsertableMove = {
      id: moveWithOverrides.id,
      active: moveWithOverrides.move_type === 'ride' ? 0 : moveWithOverrides.active,
      cancel_reason: moveWithOverrides.cancel_reason,
      cancel_status: moveWithOverrides.cancel_status,
      chargeable: moveWithOverrides.chargeable,
      class: moveWithOverrides.class,
      config: moveWithOverrides.config,
      customer_id: moveWithOverrides.customer_id,
      delivery_time: moveWithOverrides.delivery_time,
      driver_app_version: moveWithOverrides.driver_app_version,
      driver_id: moveWithOverrides.driver_id,
      driver_name: moveWithOverrides.driver_name,
      driver_status: moveWithOverrides.driver_status,
      lane_id: moveWithOverrides.lane_id,
      lyft_flag: moveWithOverrides.lyft_flag,
      lyft_trigger_id: moveWithOverrides.lyft_trigger_id,
      move_failed: moveWithOverrides.move_failed,
      move_type: moveWithOverrides.move_type,
      pickup_time: moveWithOverrides.pickup_time,
      pinnable: moveWithOverrides.pinnable,
      plan_id: moveWithOverrides.plan_id,
      rate_class_override: moveWithOverrides.rate_class_override,
      return_ride_id: moveWithOverrides.return_ride_id,
      ride_type: moveWithOverrides.ride_type,
      sequence: moveWithOverrides.sequence,
      status: moveWithOverrides.status,
      updatedat: 'now()',
    };

    // Return the upsertable move
    return upsertableMove;
  };

  /** Nest a ride in its drive to be upserted correctly - Returns null if somethings fails
   *
   * @param {object} upsertableDrive - Upsertable drive
   * @param {object} upsertableRide - Upsertable ride
   */

  const nestRideInDrive = (upsertableDrive, upsertableRide) => {
    // Check for both moves
    if (!upsertableDrive || !upsertableRide) return null;

    // Build the moveByReturnRideId object for upsert
    let moveByReturnRideId = {
      data: upsertableRide,
      on_conflict: {
        constraint: 'moves_pkey',
        update_columns: [
          'active',
          'cancel_reason',
          'cancel_status',
          'chargeable',
          'class',
          'customer_id',
          'delivery_time',
          'driver_app_version',
          'driver_id',
          'driver_name',
          'lane_id',
          'lyft_flag',
          'lyft_trigger_id',
          'move_failed',
          'pickup_time',
          'pinnable',
          'plan_id',
          'rate_class_override',
          'return_ride_id',
          'ride_type',
          'status',
          'sequence',
          'updatedat',
        ],
      },
    };

    // Assign the moveByReturnRideId to the upsertable drive
    const upsertableDriveWithNestedRide = Object.assign(upsertableDrive, { moveByReturnRideId: moveByReturnRideId });

    // Remove the return ride id before insert so it gets overriden by the nested move's id
    delete upsertableDriveWithNestedRide.return_ride_id;

    // Return the upsertable drive with the nested ride
    return upsertableDriveWithNestedRide;
  };

  return { handleUpsertMoves };
}

const UPSERT_MOVES = gql`
  mutation upsert_moves($moveObjects: [moves_insert_input!]!, $planId: bigint!) {
    insert_moves(
      objects: $moveObjects
      on_conflict: {
        constraint: moves_pkey
        update_columns: [
          active
          cancel_reason
          cancel_status
          chargeable
          class
          config
          customer_id
          delivery_time
          driver_app_version
          driver_id
          driver_name
          driver_status
          lane_id
          lyft_flag
          lyft_trigger_id
          move_failed
          move_type
          pickup_time
          pinnable
          plan_id
          rate_class_override
          return_ride_id
          ride_type
          status
          sequence
          updatedat
        ]
      }
    ) {
      affected_rows
      returning {
        id
        active
        actual_delivery_mileage
        actual_pickup_mileage
        auto_assign
        cancel_reason
        cancel_status
        chargeable
        class
        config
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
        createdat
        customer_id
        dealer_contact
        deliver_by
        delivery_arrived
        delivery_started
        delivery_stop_id
        delivery_successful
        delivery_time
        delivery_workflow_id
        driver_app_version
        driver_id
        driver_name
        driver_status
        lane_id
        lyft_flag
        lyft_trigger_id
        manual_flag
        move_details
        move_failed
        move_type
        payable
        pickup_arrived
        pickup_started
        pickup_stop_id
        pickup_successful
        pickup_time
        pickup_workflow_id
        pinnable
        plan_id
        priority
        rate_class_override
        ready_by
        reference_num
        return_ride_id
        ride_type
        sequence
        status
        synced_with_tookan
        tags
        tookan_relationship_id
        tracking_link
        updatedat
        vehicle_color
        vehicle_image
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        accessorials {
          id
          move_id
          code
          status
          notes
          cost
          ap_amount
          ar_amount
        }
        customer {
          id
          name
          config
        }
        lane {
          id
          description
          duration_sec
          distance_miles
          pickup_inspection_sec
          delivery_inspection_sec
          return_ride_wait_sec
          origin_location_id
          destination_location_id
          estimated_rideshare_return_cost
          customer_id
          customer {
            id
            name
          }
          pickup {
            id
            active
            address
            createdat
            customer_id
            email
            favorite
            latitude
            longitude
            name
            nickname
            phone
            notes
            place_id
            region_id
            region {
              id
              name
            }
            timezone
            tookan_id
            type
            updatedat
          }
          delivery {
            id
            active
            address
            createdat
            customer_id
            email
            favorite
            latitude
            longitude
            name
            nickname
            phone
            notes
            place_id
            region_id
            region {
              id
              name
            }
            timezone
            tookan_id
            type
            updatedat
          }
        }
        lyft_trigger_move {
          driver_name
        }
        movesByLyftTriggerId {
          id
          driver_id
          driver_name
        }
        parent_move_id
        parentMove {
          id
          cancel_reason
          cancel_status
          consumer_at_pickup
          consumer_name
          consumer_phone
          consumer_pickup
          consumer_type
          driver_name
          pickup_time
          status
        }
        childMoves {
          id
          move_type
          cancel_reason
          cancel_status
          consumer_at_pickup
          consumer_name
          consumer_phone
          consumer_pickup
          consumer_type
          driver_name
          pickup_time
          status
        }
        parentMove {
          id
          cancel_reason
          cancel_status
          consumer_at_pickup
          consumer_name
          consumer_phone
          consumer_pickup
          consumer_type
          driver_name
          pickup_time
          status
        }
      }
    }

    update_plans(where: { id: { _eq: $planId } }, _set: { updatedat: "now()" }) {
      affected_rows
      returning {
        id
        updatedat
      }
    }
  }
`;

const DELETE_RIDEGROUPS = gql`
  mutation delete_ridegroups($ridegroupIds: [bigint!]!) {
    delete_ridegroups(where: { id: { _in: $ridegroupIds } }) {
      affected_rows
    }
  }
`;
