// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { makeStyles, Checkbox, Collapse, Typography } from '@material-ui/core';

import { useFilters } from './FilterProvider';

import DateRange from './Modules/DateRange';
import GenericRadio from './Modules/GenericRadio';
import RegionSelect from './Modules/RegionSelect';
import Select from './Modules/Select';
import SwitchButton from './Modules/SwitchButton';

const labels = {
  dateRange: `Date Range`,
  regionSelect: `Region Select`,
  select: `Select`,
};

// COMPONENT ---------------------------------------------------------------- //

export default function FilterModule({ module, options, defaults }) {
  const cls = useStyles();

  const { active, checkedFilters, updateModuleChecked } = useFilters();

  return (
    <div className={cls.module}>
      <div
        className={cls.header}
        onClick={() => (active ? updateModuleChecked(module, !checkedFilters?.[module]) : null)}
      >
        <div className={cls.headerFlex}>
          <Checkbox
            className={cls.headerCheckbox}
            disabled={!active}
            color='primary'
            checked={checkedFilters?.[module] === true}
          />

          <Typography className={cls.headerLabel}>{options?.label || labels?.[module] || `Module Label`}</Typography>
        </div>
      </div>

      <Collapse in={checkedFilters?.[module] === true}>
        <div className={active ? cls.body : cls.bodyDisabled}>
          {module === `dateRange` ? <DateRange module={module} options={options} defaults={defaults} /> : null}
          {module === `region` ? <></> : null}
          {module === `genericRadio` ? <GenericRadio module={module} options={options} defaults={defaults} /> : null}
          {module === `switchButton` ? <SwitchButton module={module} options={options} defaults={defaults} /> : null}
          {module === `regionSelect` ? <RegionSelect module={module} options={options} defaults={defaults} /> : null}
          {module === `select` ? <Select module={module} options={options} defaults={defaults} /> : null}
        </div>
      </Collapse>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  module: {
    position: 'relative',
    display: 'block',
    width: '100%',
  },

  header: {
    position: 'relative',
    padding: `8px 12px`,
    borderBottom: theme.border[0],
    cursor: 'pointer',
  },
  headerFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  headerCheckbox: {
    padding: 4,
  },
  headerLabel: {
    fontSize: 16,
  },

  body: {
    position: 'relative',
    padding: 12,
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.dark,
  },
  bodyDisabled: {
    position: 'relative',
    padding: 12,
    borderBottom: theme.border[0],
    backgroundColor: theme.palette.background.dark,
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    userSelect: 'none',
  },
}));
