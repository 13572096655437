import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  IconButton,
  Typography,
  Checkbox,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { useData } from '../../providers/DataProvider';
import { UPDATE_MOVE_DISCOUNT, INSERT_FAQ, UPDATE_FAQ } from './gql';

const log = false;

export default function FaqManagerModal(props) {
  const ctx = useData();
  const cls = useStyles();

  const { open, data, setData, render, setRender } = props;

  log && console.log(`Modal data:`, data);

  const [discount, setDiscount] = useState({ checked: false, amount: 0, reason: `` });
  const [faq, setFaq] = useState({ active: true, user: ``, question: ``, answer: `` });

  useEffect(() => {
    if (data && data !== null) {
      if (data.discount_amount && data.discount_amount > 0) {
        setDiscount({ checked: true, amount: data.discount_amount, reason: `` });
      } else if (data.question) {
        setFaq({ active: data.active, user: data.user ? data.user : ``, question: data.question, answer: data.answer });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  const handleDiscount = name => event => {
    if (name === `checked`) setDiscount({ checked: event.target.checked, amount: data.discount_amount, reason: `` });
    else setDiscount({ ...discount, [name]: event.target.value });
  };
  const handleFaq = name => event => {
    if (name === `active`)
      setFaq({ active: event.target.checked, user: faq.user, question: faq.question, answer: faq.answer });
    else setFaq({ ...faq, [name]: event.target.value });
  };

  const handleClose = () => {
    setDiscount({ checked: false, amount: 0, reason: `` });
    setFaq({ active: false, user: ``, question: ``, answer: `` });
    setData(null);
    setRender(null);
  };

  const updateDiscount = async () => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_MOVE_DISCOUNT,
      variables: { id: data.move_id, discountAmount: discount.amount, discountReason: discount.reason },
    });
  };

  const insertFAQ = async () => {
    return await ctx.apolloClient.mutate({
      mutation: INSERT_FAQ,
      variables: {
        active: true,
        user: faq.user !== `` ? faq.user : null,
        question: faq.question,
        answer: faq.answer,
        newFlag: false,
      },
    });
  };
  const updateFAQ = async () => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_FAQ,
      variables: {
        id: data.id,
        active: faq.active,
        user: faq.user !== `` ? faq.user : null,
        question: faq.question,
        answer: faq.answer,
        newFlag: false,
      },
    });
  };

  const handleUpdateDiscount = async () => {
    try {
      if (discount.amount <= data.due_amount && discount.amount >= 0) {
        const updateRes = await updateDiscount();
        toast.success('Record successfully updated.')
        if (updateRes) handleClose();
      } else {
        console.log('Catch: discount_amount cannot be greater than the due_amount!');
        toast.warning('discount_amount cannot be greater than the due_amount!')
      }
    } catch (err) {
      console.log('Record was not updated:', err);
      toast.error(`Record was not updated: ${err.toString()}`)
    }
  };

  const handleInsertFAQ = async () => {
    try {
      const insertRes = await insertFAQ();
      toast.success('Record successfully inserted.')
      if (insertRes) handleClose();
    } catch (err) {
      console.log('Record was not inserted:', err);
      toast.error(`Record was not inserted: ${err.toString()}`)
    }
  };

  const handleUpdateFAQ = async () => {
    try {
      const updateRes = await updateFAQ();
      toast.success('Record successfully updated.')
      if (updateRes) handleClose();
    } catch (err) {
      console.log('Record was not updated:', err);
      toast.error(`Record was not updated: ${err.toString()}`)
    }
  };

  const DialogHeader = props => {
    const { children, noClose } = props;
    return (
      <DialogTitle disableTypography className={cls.pad}>
        <Typography className={cls.title}>{children}</Typography>
        {!noClose ? (
          <IconButton className={cls.closeBtn} onClick={() => handleClose()} aria-label='close'>
            <Icon>close</Icon>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      {data && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          open={open}
          onClose={handleClose}
          PaperProps={{ className: cls.root }}
        >
          {render === `review` && (
            <>
              <DialogHeader>Review</DialogHeader>
              <DialogContent dividers className={cls.pad}>
                {!data.disputed ? (
                  !discount.checked ? (
                    <Typography className={cls.subtitle}>
                      Since there is no dispute associated with this move, no review is necessary unless you add a
                      discount. To add a discount, click the "Add Discount" checkbox.
                    </Typography>
                  ) : (
                    <Typography className={cls.subtitle}>
                      By adding a discount, the current record of the move will be stored as a revision. The discount
                      will be added to the new version of the move and displayed to the customer.
                    </Typography>
                  )
                ) : !discount.checked ? (
                  <Typography className={cls.subtitle}>
                    By clearing a dispute, the current record of the move will be stored as a revision and the move will
                    be no longer be flagged for review. To add a discount, click the "Add Discount" checkbox.
                  </Typography>
                ) : (
                  <Typography className={cls.subtitle}>
                    By adding a discount and clearing a dispute, the current record of the move will be stored as a
                    revision and the move will be no longer be flagged for review. The discount will be added to the new
                    version of the move and displayed to the customer.
                  </Typography>
                )}
                <div className={cls.break} />
                {data.disputed ? (
                  <>
                    <div className={cls.disputeBox}>
                      <Typography className={cls.disputeHead} color='textSecondary'>
                        Dispute Reason:{' '}
                      </Typography>
                      <Typography className={cls.disputeBody} color='textSecondary'>
                        {data.dispute_reason}
                      </Typography>
                    </div>
                    <div className={cls.break} />
                  </>
                ) : null}
                <Typography className={cls.title + ' ' + cls.inline}>
                  {discount.checked ? (data.discount_amount > 0 ? 'Keep Discount' : 'Add Discount') : 'Add Discount'}
                </Typography>
                <Checkbox
                  className={cls.checkbox + ' ' + cls.inline}
                  checked={discount.checked}
                  value={discount.checked}
                  onChange={handleDiscount('checked')}
                  color='primary'
                  inputProps={{ 'aria-label': 'checkbox-discount' }}
                />
                {discount.checked ? (
                  <>
                    <div className={cls.break} />
                    <TextField
                      required
                      disabled={discount.checked ? false : true}
                      type='number'
                      label='Amount'
                      placeholder='0.00'
                      variant='outlined'
                      margin='none'
                      value={discount.amount}
                      onChange={handleDiscount('amount')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              attach_money
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : null}
                {data.disputed || discount.checked ? (
                  <>
                    <div className={cls.break} />
                    <TextField
                      required
                      fullWidth
                      label='Reason'
                      placeholder={
                        discount.checked
                          ? 'Enter a brief reason for the discount...'
                          : 'Enter a brief reason for clearing the dispute...'
                      }
                      variant='outlined'
                      margin='none'
                      value={discount.reason}
                      onChange={handleDiscount('reason')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              comment
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : null}
              </DialogContent>
              <DialogActions className={cls.padBtn}>
                <Button
                  disabled={!discount.checked && discount.reason.trim().length < 1}
                  color='primary'
                  onClick={() => handleUpdateDiscount()}
                >
                  {discount.checked && data.disputed
                    ? 'Add & Clear'
                    : discount.checked
                    ? data.discount_amount > 0
                      ? 'Keep Discount'
                      : 'Add Discount'
                    : 'Clear Dispute'}
                </Button>
              </DialogActions>
            </>
          )}

          {(render === `invoice logs` || render === `move logs`) && (
            <>
              <DialogHeader>
                Logs for{' '}
                {render === `invoice logs`
                  ? `Invoice #${data[0].invoice_id.toString().padStart(6, `0`)}`
                  : `Move #${data[0].move_id}`}
              </DialogHeader>
              <DialogContent dividers className={cls.noPad}>
                {data.map((log, index) => {
                  return (
                    <div
                      key={log.id}
                      className={cls.logRow}
                      style={index % 2 ? { background: '#ffffff' } : { background: '#f0f0f0' }}
                    >
                      <Typography className={cls.logTxt} color='textSecondary' display='inline'>
                        {dayjs.utc(log.createdat).format('MM/DD/YY')}&nbsp;@&nbsp;{dayjs(log.createdat).format('LTS')}
                        &nbsp;-&nbsp;
                      </Typography>
                      <Typography className={cls.logTxt} display='inline'>
                        {log.message}
                      </Typography>
                    </div>
                  );
                })}
              </DialogContent>
            </>
          )}

          {(render === `create faq` || render === `edit faq`) && (
            <>
              <DialogHeader>{render === `create faq` ? `Create FAQ` : `Edit FAQ`}</DialogHeader>
              <DialogContent dividers className={cls.pad}>
                {render === `edit faq` ? (
                  <>
                    <Typography className={cls.title + ' ' + cls.inline}>Set Active</Typography>
                    <Checkbox
                      className={cls.checkbox + ' ' + cls.inline}
                      checked={faq.active}
                      value={faq.active}
                      onChange={handleFaq('active')}
                      color='primary'
                      inputProps={{ 'aria-label': 'checkbox-faq' }}
                    />
                    <div className={cls.break} />
                  </>
                ) : null}
                <TextField
                  fullWidth
                  select
                  label='User'
                  placeholder='Select a user for the FAQ...'
                  variant='outlined'
                  margin='none'
                  value={faq.user}
                  onChange={handleFaq('user')}
                >
                  <MenuItem key={'All Users'} value={``}>
                    <em>All Users</em>
                  </MenuItem>
                  <MenuItem key={'Dealer'} value={`dealer`}>
                    Dealer
                  </MenuItem>
                  <MenuItem key={'Driver'} value={`driver`}>
                    Driver
                  </MenuItem>
                </TextField>
                <div className={cls.bigBreak} />
                <TextField
                  required
                  fullWidth
                  label='Question'
                  placeholder='Enter a frequently asked question...'
                  variant='outlined'
                  margin='none'
                  value={faq.question}
                  onChange={handleFaq('question')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          help
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className={cls.bigBreak} />
                <TextField
                  required
                  fullWidth
                  multiline
                  label='Answer'
                  placeholder='Enter an answer to the question...'
                  variant='outlined'
                  margin='none'
                  value={faq.answer}
                  onChange={handleFaq('answer')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          question_answer
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </DialogContent>
              <DialogActions className={cls.padBtn}>
                  <Button
                    disabled={faq.question.length <= 0 || faq.answer.length <= 0 ? true : false}
                    color='primary'
                    onClick={() => render === 'create faq' ? handleInsertFAQ() :  handleUpdateFAQ() }
                  >
                    { render === 'create faq' ? 'Create' : 'Apply' }
                  </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}

// STYLES ////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  pad: {
    padding: theme.spacing(2),
    margin: 0,
  },
  padBtn: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    background: '#f0f0f0',
  },
  noPad: {
    padding: 0,
    margin: 0,
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
  },
  disputeBox: {
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: theme.spacing(2),
  },
  disputeHead: {
    fontSize: '14px',
    fontWeight: 500,
  },
  disputeBody: {
    fontSize: '14px',
    fontWeight: 400,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    verticalAlign: '-13.5%',
  },
  logRow: {
    width: '100%',
    padding: theme.spacing(1),
  },
  logTxt: {
    fontSize: '12px',
    fontWeight: 400,
  },
  inline: {
    display: 'inline-block',
  },
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
}));
