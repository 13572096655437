import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useDrivers } from '../Drivers/useDrivers';

//////////////////////// COMPONENT ////////////////////////

export default function DriverActions({ driver = {} }) {
  const cls = useStyles();

  const { sendOnboardingEmail } = useDrivers();

  const showOnboardingActions = driver.active === false;

  return (
    <div className={cls.paper}>
      {showOnboardingActions ? <Button color='primary'>Go To Onboarding</Button> : null}
      {showOnboardingActions ? (
        <Button
          color='primary'
          onClick={() => {
            sendOnboardingEmail(driver.id, driver.display_name, driver.email, driver.verification);
          }}
        >
          Send Onboarding Email
        </Button>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
}));
