// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import uuid from 'uuid';
import axios from 'axios';
import { gql } from 'graphql-tag';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { getUserToken, getUserEmail } from '../../utils/authHelper';

import { buildWorkflowOutput } from '@hopdrive/workflows';

const sdk = require(`@hopdrive/sdk`);

// General Rules when handling accessorials in the move outcome:
// - Existing accessorials need to be part of a gql update mutation
// - Accessorials that are marked for deletion need to be part of a gql delete mutation
// - New/Generated accessorials should be passed into the outcome object before submitting
// - Eventlogs for accessorials should be inserted after the move outcome is submitted

// HELPERS -------------------------------------------------- //

const log = true;

const fallbackMove = {};

// CONTEXT -------------------------------------------------- //

const MoveOutcomeContext = React.createContext({});

// PROVIDER -------------------------------------------------- //

function MoveOutcomeProvider({ children }) {
  // STATE //

  const [move, setMove] = React.useState(fallbackMove);
  const [outcome, setOutcome] = React.useState(null);
  const [accessorials, setAccessorials] = React.useState([]);
  const [selectedAccessorialId, setSelectedAccessorialId] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  // GRAPHQL - MUTATIONS //

  const [upsertAccessorials] = useMutation(UPSERT_ACCESSORIALS);
  const [deleteAccessorials] = useMutation(DELETE_ACCESSORIALS);

  // FUNCTIONS - LOGIC //

  const getActionHandlerCallback = action => {
    if (action?.label === `Review`) return handleGenerateMoveOutcome;
    if (action?.label === `Submit`) return handleSubmitMoveOutcome;
    return null;
  };

  // FUNCTIONS - HANDLERS //

  const handleGenerateMoveOutcome = async workflowSteps => {
    setIsLoading(true);
    const res = await generateMoveOutcome(workflowSteps);
    if (res) {
      setOutcome(res);
      setIsLoading(false);
      return res;
    } else {
      setOutcome(null);
      setIsLoading(false);
      return null;
    }
  };

  const handleSubmitMoveOutcome = async () => {
    if (selectedAccessorialId) {
      if (
        window.confirm(`You are currently editing an accessorial. Would you like to discard your changes and submit?`)
      ) {
        const res = await submitMoveOutcome(outcome);
        return res;
      }
    } else {
      const res = await submitMoveOutcome(outcome);
      return res;
    }
  };

  // FUNCTIONS - GENERATE & SUBMIT //

  const generateMoveOutcome = async workflowSteps => {
    // Build the workflowOutput
    const workflowOutput = buildWorkflowOutput(workflowSteps);
    log && console.log(`Generating move outcome with workflow output:`, workflowOutput);

    // Check for move id
    if (move?.id) {
      try {
        const token = await getUserToken();
        const email = getUserEmail();

        return await axios({
          method: `POST`,
          url: `/.netlify/functions/moveOutcomeBuild`,
          data: {
            moveId: move?.id,
            workflowOutput: workflowOutput,
            email: email,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            if (res.status === 200) {
              log && console.log(`Successfully generated move outcome:`, res.data.data);

              // Create a mutable copy of the outcome data
              let outcomeData = JSON.parse(JSON.stringify(res.data.data));

              // Modify the outcome accessorials to include a unique tempId
              outcomeData.accessorials = outcomeData?.accessorials?.map((accessorial, i) => {
                return {
                  ...accessorial,
                  tempId: uuid(),
                };
              });

              // Return the modified outcome data
              return outcomeData;
            } else {
              toast.error(`Failed to generate move outcome!`);
              console.error(`Failed to generate move outcome!`);
            }
          })
          .catch(err => {
            toast.error(`Failed to generate move outcome!`);
            console.error(`Failed to generate move outcome:`, err);
          });
      } catch (err) {
        toast.error(`Failed to generate move outcome!`);
        console.error(`Failed to generate move outcome:`, err);
      }
    } else {
      toast.warning(`Move ID is missing! Could not generate move outcome.`);
      console.warn(`Move ID is missing! Could not generate move outcome.`);
    }

    // Fallback to null
    return null;
  };

  const submitMoveOutcome = async outcome => {
    log && console.log(`Submitting move outcome...`);

    // Get all data needed for submission
    const email = getUserEmail();
    const currentTime = dayjs().utc().format();
    if (!move?.id) {
      toast.warning(`Move ID is missing! Could not submit move outcome.`);
      console.warn(`Move ID is missing! Could not submit move outcome.`);
      return null;
    }

    // Collect all eventlogs that need to be inserted from state
    const eventlogConfigs = accessorials
      ?.filter(accessorial => accessorial?.eventlogConfig && (accessorial?.isModified || accessorial?.isDeleted))
      ?.map(accessorial => accessorial?.eventlogConfig);

    // Collect all existing accessorials that need to be updated
    const accessorialsToUpdate = accessorials
      ?.filter(accessorial => accessorial?.id && accessorial?.isModified && !accessorial?.isDeleted)
      ?.map(accessorial => {
        return {
          id: accessorial?.id,
          ap_amount: accessorial?.ap_amount,
          ar_amount: accessorial?.ar_amount,
          code: accessorial?.code,
          cost: accessorial?.cost,
          move_id: move?.id,
          notes: accessorial?.notes,
          updatedat: currentTime,
        };
      });

    // Collect all existing accessorials that need to be deleted
    const accessorialIdsToDelete = accessorials
      ?.filter(accessorial => accessorial?.id && accessorial?.isDeleted)
      ?.map(accessorial => accessorial?.id);

    // Collect all new accessorials that need to be inserted (minus any new accessorials that were marked for deletion)
    const accessorialsToInsert = accessorials
      ?.filter(accessorial => !accessorial?.id && accessorial?.code && !accessorial?.isDeleted)
      ?.map(accessorial => {
        return {
          ap_amount: accessorial?.ap_amount,
          ar_amount: accessorial?.ar_amount,
          code: accessorial?.code,
          cost: accessorial?.cost,
          move_id: move?.id,
          notes: accessorial?.notes,
          updatedat: currentTime,
        };
      });

    // Adjust the final outcome accessorials to include any new data to insert
    let finalOutcome = { ...outcome };
    finalOutcome.accessorials = accessorialsToInsert;

    log && console.log(`Accessorials to update:`, accessorialsToUpdate);
    log && console.log(`Accessorial IDs to delete:`, accessorialIdsToDelete);
    log && console.log(`Accessorials to insert:`, accessorialsToInsert);
    log && console.log(`Eventlogs to insert:`, eventlogConfigs);

    // Attempt to update existing accessorials
    try {
      if (accessorialsToUpdate?.length) {
        const res = await upsertAccessorials({
          variables: {
            accessorialsToUpsert: accessorialsToUpdate,
          },
        });
        log && console.log(`Successfully updated existing accessorials:`, res?.data?.insert_accessorials?.returning);
      }
    } catch (err) {
      toast.error(`Failed to update existing accessorials!`);
      console.error(`Failed to update existing accessorials:`, err);
      return false;
    }

    // Attempt to delete existing accessorials that are marked for deletion
    try {
      if (accessorialIdsToDelete?.length) {
        const res = await deleteAccessorials({
          variables: {
            accessorialIdsToDelete: accessorialIdsToDelete,
          },
        });
        log &&
          console.log(
            `Successfully deleted existing accessorials marked for deletion:`,
            res?.data?.delete_accessorials?.returning
          );
      }
    } catch (err) {
      toast.error(`Failed to delete existing accessorials marked for deletion!`);
      console.error(`Failed to delete existing accessorials marked for deletion:`, err);
      return false;
    }

    // Attempt to persist the move outcome
    try {
      const token = await getUserToken();

      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/moveOutcomePersist`,
        data: {
          moveId: move?.id,
          moveOutcome: finalOutcome,
          email: email,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (res?.status === 200) {
        log && console.log(`Successfully submitted move outcome:`, res?.data?.data);
      } else {
        toast.error(`Failed to submit move outcome!`);
        console.error(`Failed to submit move outcome:`, res?.error);
        return false;
      }
    } catch (err) {
      toast.error(`Failed to submit move outcome!`);
      console.error(`Failed to submit move outcome:`, err);
      return false;
    }

    // Attempt to persist the eventlogs
    try {
      eventlogConfigs?.forEach(async eventlogConfig => {
        await sdk.events.buildAndCreate(eventlogConfig);
      });
    } catch (err) {
      toast.error(`Failed to insert eventlogs for accessorials!`);
      console.error(`Failed to insert eventlogs for accessorials:`, err);
    }

    // Return true when successful
    toast.success(`Successfully submitted move outcome!`);
    return true;
  };

  // CONTEXT //

  const context = {
    // Constants
    fallbackMove,

    // State
    move,
    setMove,
    outcome,
    setOutcome,
    accessorials,
    setAccessorials,
    selectedAccessorialId,
    setSelectedAccessorialId,
    isLoading,
    setIsLoading,
    isSaving,
    setIsSaving,

    // Functions
    getActionHandlerCallback,
    handleGenerateMoveOutcome,
    handleSubmitMoveOutcome,

    // Graphql
    GET_MOVE_OUTCOME_FORM,
  };

  // RENDER //

  return <MoveOutcomeContext.Provider value={context}>{children}</MoveOutcomeContext.Provider>;
}

// HOOK -------------------------------------------------- //

const useMoveOutcome = () => React.useContext(MoveOutcomeContext);

// GRAPHQL -------------------------------------------------- //

const GET_MOVE_OUTCOME_FORM = gql`
  query admin_getMoveOutcomeForm($formType: String!) {
    workflows(where: { type: { _eq: $formType } }) {
      id
      description
      name
      steps
      type
      version
    }
  }
`;

const UPSERT_ACCESSORIALS = gql`
  mutation admin_upsertAccessorials($accessorialsToUpsert: [accessorials_insert_input!]!) {
    insert_accessorials(
      objects: $accessorialsToUpsert
      on_conflict: {
        constraint: accessorials_pkey
        update_columns: [ap_amount, ar_amount, code, cost, notes, updatedat]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const DELETE_ACCESSORIALS = gql`
  mutation admin_deleteAccessorials($accessorialIdsToDelete: [bigint!]!) {
    delete_appayments(where: { accessorial_id: { _in: $accessorialIdsToDelete }, status: { _neq: "paid" } }) {
      affected_rows
      returning {
        id
        accessorial_id
      }
    }
    delete_accessorials(where: { id: { _in: $accessorialIdsToDelete } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// EXPORT -------------------------------------------------- //

export { useMoveOutcome, MoveOutcomeProvider };
