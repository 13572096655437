import { gql } from '@apollo/client';

const INSERT_BATCH_JOB = gql`
  mutation insert_driver_mass_payment_batch_job($job: batch_jobs_insert_input!) {
    insert_batch_jobs_one(object: $job) {
      id
      batch_id
    }
  }
`;

const WATCH_BATCH_JOB = gql`
  subscription watch_batch_job($batchId: uuid!) {
    batch_jobs(where: { batch_id: { _eq: $batchId } }) {
      status
      output
    }
  }
`;

export { INSERT_BATCH_JOB, WATCH_BATCH_JOB };
