// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { makeStyles, Icon, InputAdornment, MenuItem, TextField } from '@material-ui/core';

import { useFilters } from '../FilterProvider';

// COMPONENT ---------------------------------------------------------------- //

export default function RegionSelect({ module, options, defaults }) {
  const cls = useStyles();

  const { filters, updateModule } = useFilters();

  const handleRegionChange = value => {
    updateModule(module, `value`, value);
  };

  const regions = options?.regions;

  return (
    <>
      <TextField
        select
        fullWidth
        variant='outlined'
        size='small'
        label='Region'
        value={filters?.[module]?.value || defaults?.value}
        onChange={e => handleRegionChange(e?.target?.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                public
              </Icon>
            </InputAdornment>
          ),
        }}
      >
        {options?.allowAll ? <MenuItem value={`all`}>All Regions</MenuItem> : null}
        {regions?.map(region => (
          <MenuItem key={region?.id} value={region?.id}>
            {region?.name}
          </MenuItem>
        ))}
      </TextField>

      {/* <div className={cls.spacer} />

      <div className={cls.regionChips}>
        {options?.regions?.map(region => (
          <Chip key={region?.id} label={region?.name} />
        ))}
      </div> */}
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  spacer: {
    width: `100%`,
    height: theme.spacing(1),
  },
}));
