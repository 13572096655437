//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Icon } from '@material-ui/core';

import { UnassignedMovesList, DriversList, CanceledMovesList } from '../index';
import { useSettings } from '../providers/SettingsProvider';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Spacer from '../../../components/Spacer';

//////////////////////// COMPONENT ////////////////////////

export default function Drawer(props) {
  const cls = useStyles();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const unassignedMovesSearch = values && values['unassigned-moves-search'] ? values['unassigned-moves-search'] : null;

  const {
    moveDrawerOpen,
    driverDrawerOpen,
    cancelDrawerOpen,
    setMoveDrawerOpen,
    setDriverDrawerOpen,
    setCancelDrawerOpen,
  } = useSettings();

  React.useEffect(() => {
    if (unassignedMovesSearch) {
      setMoveDrawerOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassignedMovesSearch]);

  return (
    <div className={cls.drawer}>
      {moveDrawerOpen ? (
        <div className={cls.moveBox}>
          <div className={cls.titleBox}>
            <Typography className={cls.titleTxt}>Unassigned Moves</Typography>
            <Icon className={cls.titleCloseIcon} onClick={() => setMoveDrawerOpen(false)}>
              cancel
            </Icon>
          </div>

          <div className={cls.contentBox}>
            <UnassignedMovesList search={unassignedMovesSearch} />
          </div>
        </div>
      ) : null}

      {driverDrawerOpen ? (
        <>
          <Spacer column />

          <div className={cls.driverBox}>
            <div className={cls.titleBox}>
              <Typography className={cls.titleTxt}>Drivers</Typography>
              <Icon className={cls.titleCloseIcon} onClick={() => setDriverDrawerOpen(false)}>
                cancel
              </Icon>
            </div>

            <div className={cls.contentBox}>
              <DriversList />
            </div>
          </div>
        </>
      ) : null}

      {cancelDrawerOpen ? (
        <>
          <Spacer column />

          <div className={cls.moveBox}>
            <div className={cls.titleBox}>
              <Typography className={cls.titleTxt}>Selected Date's Canceled Moves</Typography>
              <Icon className={cls.titleCloseIcon} onClick={() => setCancelDrawerOpen(false)}>
                cancel
              </Icon>
            </div>

            <div className={cls.contentBox}>
              <CanceledMovesList />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  drawer: {
    zIndex: 250,
    position: 'absolute',
    display: 'flex',
    top: theme.spacing(0),
    right: theme.spacing(0),
    padding: theme.spacing(2),
    width: 'auto',
    height: '100%',
  },

  moveBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 384,
    maxWidth: 384,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    borderRadius: theme.shape.paperRadius,
    overflow: 'hidden',
  },
  driverBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 288,
    maxWidth: 288,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    borderRadius: theme.shape.paperRadius,
    overflow: 'hidden',
  },

  titleBox: {
    zIndex: 255,
    position: 'relative',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 2,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 500,
  },
  titleCloseIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },

  contentBox: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
}));
