import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';

import { ModalContent, ModalFooter, ModalHeader } from '../ModalComponents';
import DefaultEmptyFallback from '../Fallbacks/DefaultEmptyFallback';

import { gql, useQuery } from '@apollo/client';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

let log = false;

export default function RideshareRateFormModal({
  editMode = false,
  currentRideshareRateId = 0,
  onAddSave,
  selectedRideshareRate = {},
  onCancel = () => {},
}) {

  const [unsavedRideshareRate, setUnsavedRideshareRate] = useState(null);
  const [rideshareRates, setRideshareRates] = useState([]);
  const [selectedRideshareRateId, setSelectedRideshareRateId] = useState(selectedRideshareRate?.id ? selectedRideshareRate.id : currentRideshareRateId);
  const [filteredRideshareRates, setFilteredRideshareRates] = useState([]);

  const { loading, error, data } = useQuery(GET_RIDESHARE_RATES);

  useEffect(() => {
    if (data) {
      log && console.log('Rideshare Rates: ', data, data.ridesharerates);
      setRideshareRates(data.ridesharerates);
      if (data?.ridesharerates?.length) {
        let filteredRates = data.ridesharerates.filter(rate => dayjs(rate?.expiration_date).isAfter(dayjs()));
        setFilteredRideshareRates(filteredRates);
      }
    } else {
      setRideshareRates([]);
      setFilteredRideshareRates([]);
    }
    if (error) {
      console.error('Error loading rideshare rates: ', error);
      // toast.error()
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!unsavedRideshareRate) return;
    log && console.log('Unsaved rideshare rate changed to: ', unsavedRideshareRate);
  }, [unsavedRideshareRate]);

  const handleProductChange = event => {
    if (event.target.value) handleRideshareRateSelect(event.target.value);
    else setSelectedRideshareRateId(0);
  };


//   const unsavedId = unsavedRideshareRate?.id;
//   if (unsavedId && unsavedId !== currentRideshareRateId) {
//     setCurrentRideshareRateId(unsavedId);
//   } else if (rideshareRate && currentRideshareRateId !== rideshareRate.id) {
//     setCurrentRideshareRateId(rideshareRate.id);
//   }

  const handleRideshareRateSelect = rideshareRateId => {

    if (rideshareRateId && selectedRideshareRateId && selectedRideshareRateId !== rideshareRateId) { 
        setSelectedRideshareRateId(rideshareRateId);
        const selectedRideshareRate = rideshareRates.find(rideshareRate => rideshareRate.id === rideshareRateId);
        setUnsavedRideshareRate(selectedRideshareRate);
    } else if (rideshareRateId !== currentRideshareRateId) {
      setSelectedRideshareRateId(rideshareRateId);
      //Set the selected Rideshare Rate
      const selectedRideshareRate = rideshareRates.find(rideshareRate => rideshareRate.id === rideshareRateId);
      log && console.log('selected rideshare rate', selectedRideshareRate);
      setUnsavedRideshareRate(selectedRideshareRate);
    } else {
      setSelectedRideshareRateId(rideshareRateId);
      setUnsavedRideshareRate(null);
    }
  };

  return (
    <>
      <ModalHeader id='rideshare-rate-edit-modal' onClose={onCancel}>
        {editMode ? 'Switch Rideshare Rate' : 'Add Rideshare Rate'}
      </ModalHeader>
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING RIDESHARE RATE FORM' />}>
        <ModalContent dividers>
          {filteredRideshareRates.length ? (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={filteredRideshareRates}
                  getOptionLabel={option => (option.name ? `${option.name}` : '')}
                  value={filteredRideshareRates.find(rate => rate.id === selectedRideshareRateId) || null}
                  onChange={handleProductChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Select Rideshare Rate'
                      placeholder='Select an rideshare rate...'
                      variant='outlined'
                      size='small'
                    />
                  )}
                  renderOption={option => (
                    <MenuItem key={option.id} value={option.id} style={{ width: '100%' }}>
                      {`${option.name}`}
                    </MenuItem>
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <DefaultEmptyFallback message='NO ACTIVE RIDESHARE RATES FOUND' />
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            onClick={() => {
              onCancel();
              setSelectedRideshareRateId(0);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAddSave(unsavedRideshareRate);
            }}
            color='primary'
            //disabled if rideshare rate not selected from dropdown
            disabled={unsavedRideshareRate ? false : true}
          >
            Save
          </Button>
        </ModalFooter>
      </Sentry.ErrorBoundary>
    </>
  );
}

const GET_RIDESHARE_RATES = gql`
  query getRideshareRatesForTable {
    ridesharerates(order_by: { id: desc }) {
      id
      name
      description
      createdat
      createdby
      description
      initial_cost
      service_fee
      per_minute_rate
      per_mile_rate
      effective_date
      expiration_date
      minimum_ride_cost
      maximum_ride_cost
      updatedat
      updatedby
    }
  }
`;