// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Divider, Icon, Menu, MenuItem, Tooltip } from '@material-ui/core';

// COMPONENT ---------------------------------------------------------------- //

export default function ActionsMenu({
  className = null,
  actions = [],
  onOpenCallback = null,
  onCloseCallback = null,
  tooltip = null,
  tooltipPlacement = null,
  ...rest
}) {
  const cls = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionsOpen = e => {
    if (e) e?.stopPropagation();
    setAnchorEl(e?.currentTarget);
    if (onOpenCallback) onOpenCallback();
  };
  const handleActionsClose = e => {
    if (e) e?.stopPropagation();
    setAnchorEl(null);
    if (onCloseCallback) onCloseCallback();
  };

  const handleItemHandler = handler => {
    if (handler) handler();
  };

  return (
    <div className={cls.root}>
      <Tooltip placement={tooltipPlacement || `top`} title={tooltip || `Open Actions`}>
        <button className={clsx(cls.btn, className)} onClick={handleActionsOpen} {...rest}>
          <span className={cls.label}>Actions</span>

          <span>
            <Icon className={cls.icon}>more_vert</Icon>
          </span>
        </button>
      </Tooltip>

      {actions && actions?.length > 0 ? (
        <Menu keepMounted id={`actions-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleActionsClose}>
          {actions?.map((action, i) => {
            if (!action || action?.hide) return null;
            if (!action?.handler) return <Divider key={`action-${i}`} className={cls.divider} />;
            return action?.component ? (
              action?.component(handleActionsClose)
            ) : (
              <MenuItem
                key={`action-${i}`}
                disabled={action?.disabled}
                onClick={e => {
                  handleItemHandler(action?.handler || null);
                  handleActionsClose(e);
                }}
              >
                {action?.label || `Action ${i + 1}`}
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    width: 'fit-content',
  },

  btn: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    border: 0,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    lineHeight: 1.75,
    backgroundColor: theme.palette.action.selected,
    cursor: 'pointer',
    transition: '0.15s',
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  icon: {
    display: 'block',
    marginLeft: 6,
    marginRight: -4,
    fontSize: 20,
    color: theme.palette.text.primary,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
