import axios from 'axios';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useData } from '../../providers/DataProvider';
import sdk from '@hopdrive/sdk';

import { getUserToken, getUserEmail } from '../../utils/authHelper';

const log = false;

//////////////////////// HOOK ////////////////////////

export function useDrivers() {
  const { apolloClient } = useData();

  // Get local storage items
  const getDefaultRegionId = () => {
    const localRegionId = localStorage.getItem(`driver-index-region-id`);
    if (localRegionId) return Number(localRegionId);
    else return 0;
  };
  const getDefaultStatus = () => {
    const localStatus = localStorage.getItem(`driver-index-status`);
    if (localStatus) return localStatus;
    else return `all`;
  };
  const getDefaultTaxClass = () => {
    const localTaxClass = localStorage.getItem(`driver-index-tax-class`);
    if (localTaxClass) return localTaxClass;
    else return `both`;
  };

  /** Generate a CSV list of the current drivers index table data */
  const generateCSV = drivers => {
    if (drivers && drivers.length > 0) {
      try {
        // Create rows and options for CSV
        const createCsvRow = driver => {
          return {
            driver_id: driver.id || null,
            avatar_url: driver.avatar_url || ``,
            display_name: driver.display_name || ``,
            region_id: getPropValue(driver, `region.id`) || null,
            region_name: getPropValue(driver, `region.name`) || ``,
            email: driver.email || ``,
            phone: driver.phone || ``,
            phone_type: driver.phone_type || ``,
            status: driver.status || ``,
            active: driver.active || false,
          };
        };
        const csvRows = drivers.map(driver => createCsvRow(driver));

        // Set CSV options
        const csvOptions = {
          filename: `hopdrivers_${dayjs().format()}`,
          useKeysAsHeaders: true,
        };

        // Create and generate the CSV
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.success(`Generated CSV!`, { autoClose: 2000 });
      } catch (err) {
        toast.error(`Failed to generate CSV!`);
        console.error(`Failed to generate CSV:`, err);
      }
    } else {
      toast.error(`Failed to generate CSV - No drivers found!`);
      console.error(`Failed to generate CSV - No drivers found!`);
    }
  };

  /** Insert a user, driver and driverdetail record together */
  const insertDriver = async (insertableDriver, insertableDriverDetail) => {
    if (insertableDriver) {
      try {
        const res = await apolloClient.mutate({ mutation: CREATE_DRIVER, variables: { insertableDriver } });
        if (getPropValue(res, `data.insert_drivers_one`)) {
          const driver = res.data.insert_drivers_one;
          try {
            insertableDriverDetail.driver_id = driver.id;
            const res = await apolloClient.mutate({
              mutation: CREATE_DRIVER_DETAIL,
              variables: { insertableDriverDetail },
            });
            if (getPropValue(res, `data.insert_driverdetails_one`)) {
              const driverdetail = res.data.insert_driverdetails_one;
              return { ...driver, driverdetail };
            }
          } catch (err) {
            console.error(`Failed to insert records:`, err);
          }
        }
      } catch (err) {
        console.error(`Failed to insert records:`, err);
      }
    }
    return null;
  };

  /** Terminate the driver */
  const terminateDriver = async (driver, reason, callback) => {
    try {
      const newVerification = { terminated_datetime: dayjs().utc().format(), terminated_reason: reason };
      let verification = driver.verification || {};
      verification = { ...verification, ...newVerification };

      const res = await apolloClient.mutate({
        mutation: gql`
          mutation terminateDriver($driverId: bigint!, $verification: jsonb!) {
            update_drivers(
              where: { id: { _eq: $driverId } }
              _set: { status: "terminated", verification: $verification }
            ) {
              affected_rows
              returning {
                id
                status
                verification
              }
            }

            update_users(where: { driver: { id: { _eq: $driverId } } }, _set: { active: false }) {
              affected_rows
              returning {
                id
                active
              }
            }
          }
        `,
        variables: { driverId: driver.id, verification },
      });

      if (
        getPropValue(res, `data.update_drivers.affected_rows`) &&
        getPropValue(res, `data.update_users.affected_rows`)
      ) {
        log && console.log(`Driver #${driver.id} terminated:`, res.data);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to terminate driver!`);
      return false;
    } catch (err) {
      console.error(`Failed to terminate driver:`, err);
      return false;
    }
  };

  const reinstateDriver = async (driver, callback) => {
    let currentVerification = driver.verification || {};

    try {
      const res = await apolloClient.mutate({
        mutation: gql`
          mutation reinstateDriver($driverId: bigint!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { status: "onboarding" }) {
              affected_rows
              returning {
                id
                status
                verification
              }
            }
          }
        `,
        variables: { driverId: driver.id },
      });

      console.log('Driver reinstated:', res.data.update_drivers.returning[0]);
      try {
        const emailRes = await sendOnboardingEmail(driver.id, driver.display_name, driver.email);
        if (emailRes) {
          log && console.log(`Onboarding email sent:`, emailRes);
        } else {
          console.error(`Failed to send onboarding email!`);
        }
      } catch (err) {
        console.error(`Failed to send onboarding email:`, err);
      }

      try {
        const email = await getUserEmail();
        let eventConfig = {
          action: `driver.reinstated`,
          user: email,
          role: 'admin',
          move_id: null,
          customer_id: null,
          lane_id: null,
          driver_id: driver.id,
          metadata: {
            old: currentVerification,
          },
        };
        let logRes = await sdk.events.buildAndCreate(eventConfig);
        log && console.log(`Successfully inserted eventlog ${logRes.id}`);
      } catch (err) {
        console.error(`Failed to insert eventlog:`, err);
      }

      if (callback) callback(res.data.update_drivers.returning[0]);
      return true;
    } catch (err) {
      console.error(`Failed to reinstate driver:`, err);
      return false;
    }
  };

  //You may be wondering what changing the updated_at timestamp on the user record has to do with
  //refreshing Firebase claims. Since we don't yet have the full Firebase library running in admin,
  //we need to trigger Firebase functions from backend db events, and conscripting the updated_at field
  //to do so was the path of least resistance.
  const triggerDriverClaimsRefresh = async userId => {
    const res = await apolloClient.mutate({
      mutation: gql`
        mutation triggerDriverClaimsRefresh($userId: bigint!) {
          update_users(where: { id: { _eq: $userId } }, _set: { updated_at: "now()" }) {
            affected_rows
            returning {
              updated_at
              id
              display_name
            }
          }
        }
      `,
      variables: { userId: userId },
    });

    if (getPropValue(res, `data.update_users.affected_rows`) > 0) {
      log && console.log(`Firebase claims refresh triggered successfully`, res.data);
      return true;
    }
    console.error(`Failed to trigger Firebase claims refresh`);
    return false;
  };

  //You may be wondering what changing the active flag on the user record has to do with
  //inviting a driver to the new driver app. Since we don't yet have the full Firebase
  //library running in admin, we need to trigger Firebase functions from backend db events,
  //and conscripting the active field to do so was the path of least resistance.
  const triggerDriverAuthCreationInFirebase = async userId => {
    const res = await apolloClient.mutate({
      mutation: gql`
        mutation triggerDriverAuthCreationInFirebase($userId: bigint!) {
          update_users(where: { id: { _eq: $userId } }, _set: { active: false }) {
            affected_rows
          }
        }
      `,
      variables: { userId: userId },
    });

    if (getPropValue(res, `data.update_users.affected_rows`) > 0) {
      // 1 second delay to ensure the hasura events fire in order
      await new Promise(resolve => setTimeout(resolve, 1000));

      const res2 = await apolloClient.mutate({
        mutation: gql`
          mutation triggerDriverAuthCreationInFirebase($userId: bigint!) {
            update_users(where: { id: { _eq: $userId } }, _set: { active: true }) {
              affected_rows
            }
          }
        `,
        variables: { userId: userId },
      });
      if (getPropValue(res2, `data.update_users.affected_rows`) > 0) {
        log && console.log(`Firebase auth creation triggered successfully`, res?.data, res2?.data);
        return true;
      }
    }
    console.error(`Failed to trigger auth creation in Firebase`);
    return false;
  };

  /** Send the driver their onboarding email with the magic link */
  const sendOnboardingEmail = async (
    driverId = null,
    driverName = null,
    driverEmail = null,
    driverVerification = null
  ) => {
    if (!driverId || !driverName || !driverEmail) {
      console.error(`No driver id, name or email provided!`);
      return null;
    }

    const variables = {
      driverId,
      driverName,
      driverEmail,
      driverVerification,
    };
    log && console.log(`Resend onboarding email:`, variables);

    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDriverOnboardingEmail`,
        data: variables,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res && res.status === 200) {
            return res.data;
          } else {
            console.error(`Failed to send onboarding email!`);
            return null;
          }
        })
        .catch(err => {
          console.error(`Failed to send onboarding email:`, err);
          return null;
        });
    } catch (err) {
      console.error(`Failed to send onboarding email:`, err);
      return null;
    }
  };

  // Return additional logic
  return {
    getDefaultRegionId,
    getDefaultStatus,
    getDefaultTaxClass,
    generateCSV,
    insertDriver,
    terminateDriver,
    reinstateDriver,
    sendOnboardingEmail,
    triggerDriverClaimsRefresh,
    triggerDriverAuthCreationInFirebase,
  };
}

//////////////////////// GRAPHQL ////////////////////////

const CREATE_DRIVER = gql`
  mutation createDriver($insertableDriver: drivers_insert_input!) {
    insert_drivers_one(
      object: $insertableDriver
      on_conflict: {
        constraint: drivers_pkey
        update_columns: [
          config
          phone_type
          region_id
          status
          tax_class
          user_id
          vehicle_color
          vehicle_license_plate_state
          vehicle_license_plate
          vehicle_make
          vehicle_model
          vehicle_vin
          vehicle_year
        ]
      }
    ) {
      id
      config
      phone_type
      region_id
      status
      tax_class
      user_id
      vehicle_color
      vehicle_license_plate
      vehicle_license_plate_state
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      driverdetail {
        driver_id
        address_city
        address_one
        address_state
        address_two
        address_zip
        date_of_birth
        first_name
        gender
        last_name
        middle_name
        social_security
      }
      user {
        id
        active
        avatar_url
        display_name
        email
        phone
        default_role
      }
    }
  }
`;

const CREATE_DRIVER_DETAIL = gql`
  mutation createDriverDetail($insertableDriverDetail: driverdetails_insert_input!) {
    insert_driverdetails_one(
      object: $insertableDriverDetail
      on_conflict: {
        constraint: driverdetails_pkey
        update_columns: [
          first_name
          middle_name
          last_name
          gender
          date_of_birth
          social_security
          address_one
          address_two
          address_city
          address_state
          address_zip
        ]
      }
    ) {
      driver_id
      first_name
      middle_name
      last_name
      gender
      date_of_birth
      social_security
      address_one
      address_two
      address_city
      address_state
      address_zip
    }
  }
`;
