import React from 'react';
import { Modal } from '../../components/ModalComponents';

import TopUpModalContent from './TopUpModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function TopUpModal({ open, input, onClose, balance }) {
  // Handle closing of the form
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <Modal open={open}>
      {open ? <TopUpModalContent input={input} handleClose={handleClose} balance={balance} /> : null}
    </Modal>
  );
}
