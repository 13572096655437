//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TimelineAreaHead, TimelineAreaBody } from '../index';

//////////////////////// COMPONENT ////////////////////////

export default function TimelineArea(props) {
  const cls = useStyles();

  return (
    <div className={cls.timelineArea}>
      <TimelineAreaHead />
      <TimelineAreaBody />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timelineArea: {
    zIndex: 100,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 100,
    width: 'max-content',
    minHeight: '100%',
    maxHeight: '100%',
  },
}));
