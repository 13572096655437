import 'date-fns';
import React from 'react';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { MissingRegionLocationsTable } from './index';
import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import * as Sentry from '@sentry/react';

function MissingRegionLocations(props) {
    const cls = useStyles();

    const [dateLimit, setDateLimit] = React.useState(dayjs().utc().subtract(6, "month").format().toString())
  
    const { loading, error, data, refetch } = useQuery(GET_MISSING_REGION_LOCATIONS, {
      variables: {dateLimit: dateLimit}
    });

    if (loading) {
      return <Loading />;
    }
    if (error) {
      return <DefaultErrorFallback message={'ERROR FETCHING LOCATIONS'} />;
    }
    if (data && data.locations && data.locations.length < 1) {
      return <DefaultEmptyFallback message={'NO LOCATIONS FOUND'} />;
    }
    if (data && data.locations && data.locations.length > 0) {
      return (
        <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMER PRICING' />}>
        <div className={cls.root}>
          <MissingRegionLocationsTable locations={data.locations} 
          refetch={refetch} 
          />
        </div>
        </Sentry.ErrorBoundary>
      );
    }
  }
  
  
  const GET_MISSING_REGION_LOCATIONS = gql`
  query admin_missingRegions($dateLimit: timestamptz) {
    locations(order_by: {createdat: desc}, where: {_and: {active: {_eq: "1"}, region_id: {_is_null: true}, createdat: {_gt: $dateLimit}}}) {
      id
      active
      latitude
      longitude
      name
      nickname
      address
      region_id
      region {
        id
        name
      }
      updatedat
      createdat
      lanesByOriginLocationId {
        moves_aggregate {
          aggregate {
            count
            max {
              pickup_time
            }
          }
        }
      }
      lanesByDestinationLocationId {
        moves_aggregate {
          aggregate {
            count
            max {
              pickup_time
            }
          }
        }
      }
    }
  }
  `;
  
  const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: theme.spacing(7),
    },
  }));
  
  export default MissingRegionLocations;