// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import { usePlansGroup } from './PlansGroupProvider';
import PlansGroupToolbar from './PlansGroupToolbar';
import PlansGroupContent from './PlansGroupContent';

const log = false;

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupQuery(props) {
  const { selectedPlanDate, getGroupableMoves } = usePlansGroup();

  // FETCH DATA //

  const { loading, error, data, refetch } = useQuery(GET_PLANS_GROUP_DATA, {
    variables: {
      planDate: selectedPlanDate,
      start: dayjs(selectedPlanDate).startOf(`day`).subtract(30, `day`).format(),
      end: dayjs(selectedPlanDate).endOf(`day`).format(),
    },
    fetchPolicy: `network-only`,
  });

  // LOADING STATE //

  if (loading) {
    return (
      <>
        <PlansGroupToolbar />
        <DefaultLoadingFallback message='FETCHING PLANS GROUP DATA' />
      </>
    );
  }

  // ERROR STATE //

  if (error) {
    console.error(`Failed to load plans group data:`, error);
    return (
      <>
        <PlansGroupToolbar />
        <DefaultErrorFallback message='ERROR FETCHING PLANS GROUP DATA' />
      </>
    );
  }

  // EMPTY STATE //

  if (!data) {
    return (
      <>
        <PlansGroupToolbar />
        <DefaultEmptyFallback message='NO PLANS GROUP DATA FOUND' />
      </>
    );
  }

  // DATA STATE //

  let _drivers = data?.drivers;
  let _moves = getGroupableMoves(data?.moves);
  let _plans = data?.plans;
  let _regions = data?.regions;

  const handleRefetch = async () => {
    await refetch();
  };

  log && console.log(`PlansGroup data:`, { drivers: _drivers, moves: _moves, plans: _plans, regions: _regions });

  return (
    <>
      <PlansGroupToolbar regions={_regions} refetch={handleRefetch} />
      <PlansGroupContent drivers={_drivers} moves={_moves} plans={_plans} regions={_regions} refetch={handleRefetch} />
    </>
  );
}

// -------------------------------- GRAPHQL -------------------------------- //

const GET_PLANS_GROUP_DATA = gql`
  query admin_plansGroup_getPlansGroupData($start: timestamptz!, $end: timestamptz!, $planDate: date!) {
    drivers(
      where: { user: { active: { _eq: true } }, status: { _in: ["training", "online", "offline"] } }
      order_by: { region: { name: asc }, user: { display_name: asc } }
    ) {
      id
      config
      driver_app_version
      phone_type
      region_id
      status
      tax_class
      verification
      region {
        id
        name
      }
      user {
        id
        avatar_url
        display_name
        email
        phone
      }
    }

    moves(
      where: {
        active: { _eq: 1 }
        move_type: { _eq: "drive" }
        customer_id: { _is_null: false }
        lane_id: { _is_null: false }
        plan_id: { _is_null: true }
        status: { _is_null: true }
        cancel_status: { _is_null: true }
        ready_by: { _gte: $start, _lte: $end }
      }
      order_by: { ready_by: asc }
    ) {
      id
      active
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_type
      createdat
      createdBy
      dealer_contact
      driver_app_version
      driver_id
      driver_name
      driver_status
      manual_flag
      move_details
      move_type
      parent_move_id
      ready_by
      reference_num
      status
      updatedat
      updatedBy
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_stock
      vehicle_vin
      vehicle_year
      customer {
        id
        name
        organization {
          id
          name
        }
      }
      hangtags {
        id
        hash_id
        rear_code
        status
        type
        workflow_data
        customer {
          id
          name
        }
      }
      lane {
        id
        delivery_inspection_sec
        distance_miles
        duration_sec
        pickup_inspection_sec
        return_ride_wait_sec
        delivery {
          id
          address
          name
          region {
            id
            name
          }
        }
        inverse {
          id
          delivery_inspection_sec
          distance_miles
          duration_sec
          pickup_inspection_sec
          return_ride_wait_sec
          delivery {
            id
            address
            name
            region {
              id
              name
            }
          }
          pickup {
            id
            address
            name
            region {
              id
              name
            }
          }
        }
        pickup {
          id
          address
          name
          region {
            id
            name
          }
        }
      }
    }

    plans(where: { plan_date: { _eq: $planDate } }) {
      id
      driver_id
      plan_date
      moves {
        id
        deliver_by
        delivery_successful
        delivery_time
        move_type
        pickup_time
        ready_by
        status
        lane {
          id
          delivery_inspection_sec
          duration_sec
          pickup_inspection_sec
          return_ride_wait_sec
        }
      }
    }

    regions(order_by: { name: asc }) {
      id
      name
      timezone
    }
  }
`;
