import { gql } from '@apollo/client';

const GET_INVOICES = gql`
  query admin_getInvoices($selectedCustomerIds: [bigint], $start: timestamptz!, $end: timestamptz!) {
    arinvoices(
      where: {
        customer_id: { _in: $selectedCustomerIds }
        _or: [{ start_datetime: { _gte: $start, _lte: $end } }, { end_datetime: { _gte: $start, _lte: $end } }]
      }
      order_by: { start_datetime: desc }
    ) {
      id
      status
      start_datetime
      end_datetime
      customer {
        id
        name
      }
      armoves(where: { active: { _eq: 1 } }, order_by: { id: asc }) {
        id
        active
        due_amount
        paid_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        type
      }
      arpayments {
        id
        status
        amount
      }
    }
  }
`;

const GET_INVOICE_DETAILS = gql`
  query admin_getInvoiceDetails($invoiceId: bigint!) {
    arinvoices(where: { id: { _eq: $invoiceId } }) {
      id
      status
      accounting_num
      start_datetime
      end_datetime
      customer {
        id
        auto_pay
        billing_frequency
        name
        notify_billing
        payment_terms
        paymentmethods(where: { type: { _eq: "manual" } }) {
          id
        }
      }
      armoves(where: { active: { _eq: 1 } }, order_by: { id: asc }) {
        id
        accounting_item_id
        active
        arevent_id
        author
        billable_datetime
        discount_amount
        discount_reason
        dispute_reason
        disputed
        due_amount
        invoice_id
        move_id
        notes
        paid_amount
        status
        type
        move {
          id
          active
          class
          delivery_arrived
          delivery_started
          delivery_successful
          pickup_arrived
          pickup_started
          pickup_successful
          reference_num
          settled
          status
          tags
          vehicle_color
          vehicle_make
          vehicle_model
          vehicle_odometer
          vehicle_stock
          vehicle_vin
          vehicle_year
          customer {
            id
          }
          lane {
            id
            description
            distance_miles
            delivery {
              id
              name
              address
            }
            pickup {
              id
              name
              address
              region {
                id
                accounting_class_num
              }
            }
          }
          raterule {
            id
            rate
            type
          }
        }
        details {
          id
          armove_id
          name
          notes
          amount
          rate_source
          documents {
            id
            mime_type
            name
            url
          }
        }
        revisions(order_by: { revision: desc }) {
          id
          revision
          due_amount
          discount_amount
          discount_reason
          disputed
          dispute_reason
          details {
            id
            name
            notes
            amount
            documents {
              id
              mime_type
              name
              url
            }
          }
        }
      }
      arpayments(order_by: { createdat: asc }) {
        id
        status
        amount
        accounting_id
        gateway_transaction_id
        createdat
      }
    }
  }
`;

const GET_INVOICE_LOGS = gql`
  query get_invoice_logs($invoiceId: bigint!) {
    arevents(where: { invoice_id: { _eq: $invoiceId } }, order_by: { id: desc }) {
      id
      arevent_id
      move_id
      invoice_id
      message
      level
      createdat
    }
  }
`;

const GET_MOVE_LOGS = gql`
  query get_move_logs($moveId: bigint!) {
    arevents(where: { move_id: { _eq: $moveId } }, order_by: { id: desc }) {
      id
      arevent_id
      move_id
      invoice_id
      message
      level
      createdat
    }
  }
`;

const FORCE_INVOICE_STATUS = gql`
  mutation force_invoice_status($invoiceId: bigint!, $status: String!) {
    update_arinvoices(where: { id: { _eq: $invoiceId } }, _set: { status: $status }) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;

export { GET_INVOICES, GET_INVOICE_DETAILS, GET_INVOICE_LOGS, GET_MOVE_LOGS, FORCE_INVOICE_STATUS };
