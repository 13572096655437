// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useTools } from '../../hooks/useTools';
import { lineString, polygon } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import unkinkPolygon from '@turf/unkink-polygon';

// HELPERS -------------------------------------------------- //

const log = false;

// The default center of the map
const defaultCenter = {
  lat: 38.5,
  lng: -96,
};

// Function for getting center lat long coordinates based on region points
let getCenterCoords = points => {
  if (points.length) {
    let latSum = 0;
    let lngSum = 0;

    points.forEach(loc => {
      latSum += loc.lat || 0;
      lngSum += loc.lng || 0;
    });

    const latAvg = latSum / points.length;
    const lngAvg = lngSum / points.length;
    return { lat: latAvg, lng: lngAvg };
  } else {
    return defaultCenter;
  }
};

// CONTEXT -------------------------------------------------- //

const RegionsContext = React.createContext({});

// PROVIDER -------------------------------------------------- //

function RegionsProvider({ regions, refetch, children }) {
  const [forceRenderRegions, setForceRenderRegions] = React.useState(true);

  const [map, setMap] = React.useState(null);
  const [drawingManager, setDrawingManager] = React.useState(null);
  const [drawingPolygon, setDrawingPolygon] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [drawMode, setDrawMode] = React.useState(false);
  const [selectedRegionId, setSelectedRegionId] = React.useState(null);
  const [selectedRegionGeofence, setSelectedRegionGeofence] = React.useState([]);

  const { convertRegionGeofenceToDB } = useTools();

  // Setup the save region mutation
  const [upsertRegion] = useMutation(SAVE_REGION);

  // Setup the delete region mutation
  const [deleteRegion] = useMutation(DELETE_REGION);

  /** Handle selecting a region */
  const selectRegion = region => {
    if (region && selectedRegionId !== region.id) {
      // Set state variables
      setEditMode(true);
      setDrawMode(false);
      setSelectedRegionId(region.id);
      setSelectedRegionGeofence(region.points);

      // Remove the current polygon being drawn (if there is one)
      if (drawingPolygon) drawingPolygon.setMap(null);

      // Find the center coords for future use
      const center = getCenterCoords(region.points);

      // Go to the region
      map.panTo(center);
      map.setZoom(7);
    }
  };

  /** Handle unselecting a region */
  const unselectRegion = () => {
    // Force the rerendering of regions if editing a polygon
    // This is needed to undo path changes to an edited polygon
    if (selectedRegionId) {
      setForceRenderRegions(false);
      setTimeout(() => {
        setForceRenderRegions(true);
      }, 1);
    }

    // Set state variables
    setEditMode(false);
    setDrawMode(false);
    setSelectedRegionId(null);
    setSelectedRegionGeofence([]);

    // Remove the current polygon being drawn (if there is one)
    if (drawingPolygon) drawingPolygon.setMap(null);

    // Go to the default center
    map.panTo(defaultCenter);
    map.setZoom(5);
  };

  /** Handle adding a new region */
  const addNewRegion = () => {
    // Set state variables
    setEditMode(true);
    setDrawMode(true);
    setSelectedRegionId(null);
    setSelectedRegionGeofence([]);

    // Remove the current polygon being drawn (if there is one)
    if (drawingPolygon) drawingPolygon.setMap(null);

    // Go to the default center
    map.panTo(defaultCenter);
    map.setZoom(5);
  };

  /** Handle completing a polygon */
  const completeGeofence = geofence => {
    // Set state variables
    setDrawMode(false);
    setSelectedRegionGeofence(geofence);
  };

  /** Get geofences from all regions (minus the one selected) */
  const getAllRegionGeofences = () => {
    // Get array of geofences
    let geofenceArray = regions.map(r => (r.id !== selectedRegionId ? r.points : null));
    geofenceArray = geofenceArray.filter(Boolean);

    // Return the geofence array
    return geofenceArray;
  };

  /** Check to see if the geofence intersects with other geofences */
  const isGeofenceIntersecting = (geofence, geofenceArray) => {
    // Set an initial validation bool
    let isInvalid = false;

    // Convert the main geofence so turf can read it
    const gfMain = geofence.map(g => {
      return [g.lat, g.lng];
    });
    const lineMain = lineString(gfMain);

    // Loop over every geofence in the geofence array and check if there are any intersects
    geofenceArray.forEach((gf, i) => {
      // Convert the comparable geofence so turf can read it
      const gfCompare = gf.map(g => {
        return [g.lat, g.lng];
      });
      const lineCompare = lineString(gfCompare);

      // Detect intersects by comparing the two geofences
      const intersects = lineIntersect(lineMain, lineCompare);

      // If an intersect is detected, make it known
      if (intersects && intersects.features && intersects.features.length) {
        console.error(`Intersection detected with geofence ${i}:`, geofence, gf, intersects);
        isInvalid = true;
      }
    });

    // Return if the geofence is invalid or not
    return isInvalid;
  };

  /** Check to see if the geofence contains kinks or twists (self-intersection) */
  const isGeofenceSelfIntersecting = geofence => {
    // Set an initial validation bool
    let isInvalid = false;

    // Convert the geofence so turf can read it
    let turfGeofence = [...geofence];
    if (JSON.stringify(turfGeofence[0]) !== JSON.stringify(turfGeofence[turfGeofence.length - 1]))
      turfGeofence.push(turfGeofence[0]);
    turfGeofence = turfGeofence.map(g => {
      return [g.lat, g.lng];
    });

    // Initialize the turf polygon and detect kinks
    const turfPolygon = polygon([turfGeofence]);
    const turfKinks = unkinkPolygon(turfPolygon);

    // If an intersect is detected, make it known
    // This is detected by checking if turf generates more than one polygon from the unkink function
    if (turfKinks.features.length > 1) {
      console.error(`Self-intersection(s) detected:`, turfKinks.features.length - 1);
      isInvalid = true;
    }

    // Return if the geofence is invalid or not
    return isInvalid;
  };

  /** Check if the geofence is valid */
  const isGeofenceValid = (geofence, toastMsg = false) => {
    // Make sure the geofence exists
    if (!geofence) {
      const msg = `Geofence is invalid because no geofence was provided!`;
      console.error(msg);
      toastMsg && toast.error(msg);
      return false;
    }

    // Make sure the geofence has enough points
    if (geofence.length < 3) {
      const msg = `Geofence is invalid because it needs at least 3 points!`;
      console.error(msg);
      toastMsg && toast.error(msg);
      return false;
    }

    // Make sure the geofence isnt overlapping
    const geofenceArray = getAllRegionGeofences();
    if (isGeofenceIntersecting(geofence, geofenceArray)) {
      const msg = `Geofence is invalid because it cannot intersect with other geofences!`;
      console.error(msg);
      toastMsg && toast.error(msg);
      return false;
    }

    // Make sure the geofence isnt self-intersecting
    if (isGeofenceSelfIntersecting(geofence)) {
      const msg = `Geofence is invalid because it cannot intersect with itself!`;
      console.error(msg);
      toastMsg && toast.error(msg);
      return false;
    }

    // Otherwise the geofence is valid
    log && console.log(`Geofence is valid.`);
    return true;
  };

  /** Handle saving a region */
  const saveRegionAndGeofence = async region => {
    try {
      log && console.log(`Attempting to save region and geofence:`, region);

      // Check if the geofence is valid
      if (isGeofenceValid(region.points, true)) {
        // Build database geofence
        const dbGeofence = convertRegionGeofenceToDB(region);

        // Set variables object
        const variables = {
          upsertableRegion: {
            accounting_class_num: region.accounting_class_num || null,
            description: region.description || null,
            email: region.email || null,
            geofence: dbGeofence,
            name: region.name || null,
            team_id: region.team_id || null,
            timezone: region.timezone || null,
            updatedat: 'now()',
            status: region.status || 'upcoming',
          },
        };
        if (region.id) variables.upsertableRegion.id = region.id;
        log && console.log(`Upserting region with variables:`, variables);

        // Upsert the region
        const res = await upsertRegion({
          variables: variables,
        });
        log && console.log(`Upsert region response:`, res);

        // Check to see if the upsert was successful
        if (getPropValue(res, `data.insert_regions_one`)) {
          const returnedRegion = res.data.insert_regions_one;
          unselectRegion();
          return { success: true, region: returnedRegion };
        }
      }

      // Otherwise return failure
      return { success: false };
    } catch (err) {
      console.error(`Failed to save region & geofence:`, err);
      return { success: false, error: `Failed to save region & geofence!` };
    }
  };

  /** Handle removing a region */
  const removeRegion = async region => {
    try {
      log && console.log(`Attempting to remove region and geofence:`, region);

      // Set variables object
      const variables = {
        regionId: region.id,
      };
      log && console.log(`Deleting region with variables:`, variables);

      // Upsert the region
      const res = await deleteRegion({
        variables: variables,
      });
      log && console.log(`Delete region response:`, res);

      // Check to see if the upsert was successful
      if (getPropValue(res, `data.delete_regions.affected_rows`)) {
        const returnedRegion = res.data.delete_regions.returning[0];
        unselectRegion();
        return { success: true, region: returnedRegion };
      }

      // Otherwise return failure
      return { success: false };
    } catch (err) {
      console.error(`Failed to remove region & geofence:`, err);
      return { success: false, error: `Failed to remove region & geofence!` };
    }
  };

  const context = {
    defaultCenter,
    getCenterCoords,
    forceRenderRegions,
    setForceRenderRegions,
    map,
    setMap,
    drawingManager,
    setDrawingManager,
    drawingPolygon,
    setDrawingPolygon,
    editMode,
    setEditMode,
    drawMode,
    setDrawMode,
    selectedRegionId,
    setSelectedRegionId,
    selectedRegionGeofence,
    setSelectedRegionGeofence,
    selectRegion,
    unselectRegion,
    addNewRegion,
    isGeofenceValid,
    completeGeofence,
    saveRegionAndGeofence,
    removeRegion,
  };

  return <RegionsContext.Provider value={context}>{children}</RegionsContext.Provider>;
}

// HOOK -------------------------------------------------- //

const useRegions = () => React.useContext(RegionsContext);

// GRAPHQL -------------------------------------------------- //

const SAVE_REGION = gql`
  mutation admin_regions_saveRegion($upsertableRegion: regions_insert_input!) {
    insert_regions_one(
      object: $upsertableRegion
      on_conflict: {
        constraint: regions_pkey
        update_columns: [accounting_class_num, description, email, geofence, name, timezone, updatedat, status]
      }
    ) {
      id
      accounting_class_num
      description
      email
      geofence
      name
      timezone
      createdat
      updatedat
      status
    }
  }
`;

const DELETE_REGION = gql`
  mutation admin_regions_deleteRegion($regionId: bigint!) {
    delete_regions(where: { id: { _eq: $regionId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// EXPORT -------------------------------------------------- //

export { useRegions, RegionsProvider };
