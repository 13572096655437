import React from 'react';
import { makeStyles, Grid, Link, Icon } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from '../../components/ModalComponents';
import { useTools } from '../../hooks/useTools';

export default function TrackingLinkModal(props) {
  const { open, setTrackingLinkModal, conciergeUrl } = props;
  const cls = useStyles();


  const { copyToClipboard } = useTools();

  const handleClose = () => {
    setTrackingLinkModal(false);
  };

  return (
    <Modal open={open}>
      <>
        <ModalHeader handleClose={handleClose}>Concierge Tracking Link</ModalHeader>
        <ModalContent>

            <div className={cls.root}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={1} style={{ textAlign: 'left' }} textAlign='left'>
                  <Icon style={{ marginLeft: '50px', color: '#00000040' }} onClick={() => copyToClipboard(conciergeUrl)}>
                    content_copy_icon
                  </Icon>
                </Grid>
                <Grid item xs={11}>
                  <Link style={{ fontSize: '12pt', padding: '1px' }} target='_blank' href={conciergeUrl}>
                    {conciergeUrl}
                  </Link>
                </Grid>
              </Grid>
            </div>
        </ModalContent>
      </>
    </Modal>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(3),
  },

  headers: {
    marginTop: theme.spacing(4),
  },


  url: {
    backgroundColor: '#f0f0f0',
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
    width: '100%',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
}));
