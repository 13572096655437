import React from 'react';
import { makeStyles, Grid, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import theme from '../../utils/theme';

////////// COMPONENT //////////
export default function MapLocation(props) {
  const cls = useStyles();
  const { copyToClipboard, capFirst } = useTools();

  const { location, handleLocationAddEditModalOpen, handleLocationRemoveModalOpen, origin, destination, setOrigin, setDestination, setSidebarState } =
    props;

  const handleSetLocation = (location, type) => {
    //Set location in map state
    if (type === 'origin') {
      setOrigin(location);
    } else if (type === 'destination') {
      setDestination(location);
    }
    //If both locations are set, enter lane mode
    if (origin && destination) {
      setSidebarState('lane');
    }
  };

  return (
    <>
      {location ? (
        <div className={cls.location}>
          <div className={cls.description}>
            <Typography className={cls.descriptionNameTxt}>{location.nickname || location.name}</Typography>
            <Typography className={cls.descriptionAddressTxt}>{location.address || `No address provided`}</Typography>
          </div>

          <div className={cls.actions}>
            <Grid container spacing={0} wrap='nowrap'>
              <Grid item xs>
                <div className={cls.action}>
                  <IconButton onClick={() => handleSetLocation(location, 'origin')} className={cls.actionBtn}>
                    <Icon>gps_not_fixed</Icon>
                  </IconButton>
                </div>
                <Typography className={cls.actionTxtPrimary}>{`Select as`}</Typography>
                <Typography className={cls.actionTxtPrimary}>{`Origin`}</Typography>
              </Grid>
              <Grid item xs>
                <div className={cls.action}>
                  <IconButton onClick={() => handleSetLocation(location, 'destination')} className={cls.actionBtn}>
                    <Icon>gps_fixed</Icon>
                  </IconButton>
                </div>
                <Typography className={cls.actionTxtPrimary}>{`Select as`}</Typography>
                <Typography className={cls.actionTxtPrimary}>{`Destination`}</Typography>
              </Grid>
              <Grid item xs>
                <div className={cls.action}>
                  <IconButton onClick={() => handleLocationAddEditModalOpen(location)} className={cls.editButton}>
                    <Icon>edit</Icon>
                  </IconButton>
                </div>
                <Typography className={cls.actionTxtSecondary}>{`Edit`}</Typography>
                <Typography className={cls.actionTxtSecondary}>{`Location`}</Typography>
              </Grid>
              <Grid item xs>
                <div className={cls.action}>
                  <IconButton onClick={() => handleLocationRemoveModalOpen(location)} className={cls.removeButton}>
                    <Icon>close</Icon>
                  </IconButton>
                </div>
                <Typography className={cls.actionTxtBlack}>{`Remove`}</Typography>
                <Typography className={cls.actionTxtBlack}>{`Location`}</Typography>
              </Grid>
            </Grid>
          </div>

          <div className={cls.mainInfo}>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Name`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => copyToClipboard(location.name)}
                >
                  local_offer
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.name ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.name || `No name provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Address`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => copyToClipboard(location.address)}
                >
                  room
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.address ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.address || `No address provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Nickname`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => copyToClipboard(location.nickname)}
                >
                  loyalty
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.nickname ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.nickname || `No nickname provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Email Address`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => copyToClipboard(location.email)}
                >
                  mail
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.email ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.email || `No email address provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Phone Number`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => copyToClipboard(location.phone)}
                >
                  phone_iphone
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.phone ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.phone || `No phone number provided`}
              </Typography>
            </div>
          </div>

          <div className={cls.auxInfo}>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Type`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => copyToClipboard(location.type)}
                >
                  label_important
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.type ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.type ? capFirst(location.type) : `No type provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Customer Name`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => copyToClipboard(location.customer ? location.customer.name : null)}
                >
                  group
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{
                  color:
                    location.customer && location.customer.name
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                }}
              >
                {location.customer && location.customer.name ? location.customer.name : `No customer provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Region Name`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => copyToClipboard(location.region ? location.region.name : null)}
                >
                  public
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{
                  color:
                    location.region && location.region.name ? theme.palette.text.primary : theme.palette.text.secondary,
                }}
              >
                {location.region && location.region.name ? location.region.name : `No region provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Latitude`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => copyToClipboard(location.latitude)}
                >
                  adjust
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.latitude ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.latitude || `No latitude provided`}
              </Typography>
            </div>
            <div className={cls.infoBox}>
              <Tooltip placement='top' title={`Copy Longitude`}>
                <Icon
                  className={cls.infoBoxIcon}
                  style={{ color: theme.palette.secondary.main }}
                  onClick={() => copyToClipboard(location.longitude)}
                >
                  adjust
                </Icon>
              </Tooltip>
              <Typography
                className={cls.infoBoxTxt}
                style={{ color: location.longitude ? theme.palette.text.primary : theme.palette.text.secondary }}
              >
                {location.longitude || `No longitude provided`}
              </Typography>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  location: {
    position: 'relative',
  },
  description: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  descriptionNameTxt: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1.2,
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  descriptionAddressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.2,
    fontSize: 16,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  actions: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottom: theme.border[0],
  },
  action: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  actionBtn: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: `${theme.palette.primary.main} !important`,
    color: theme.palette.text.contrast,
  },
  editButton: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: `${theme.palette.secondary.main} !important`,
    color: theme.palette.text.contrast,
  },
  removeButton: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: `${theme.palette.text.primary} !important`,
    color: theme.palette.text.contrast,
  },
  actionTxtPrimary: {
    color: theme.palette.primary.main,
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: 1.2,
    fontSize: '12px',
    fontWeight: 400,
  },
  actionTxtSecondary: {
    color: theme.palette.secondary.main,
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: 1.2,
    fontSize: '12px',
    fontWeight: 400,
  },
  actionTxtBlack: {
    color: theme.palette.text.primary,
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: 1.2,
    fontSize: '12px',
    fontWeight: 400,
  },
  mainInfo: {
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  auxInfo: {
    padding: theme.spacing(2),
  },
  infoBox: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  infoBoxIcon: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: theme.spacing(2),
    '&:hover': {
      filter: 'brightness(0.9)',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  infoBoxTxt: {
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'top',
    paddingTop: '4px',
    paddingBottom: '4px',
    lineHeight: 1.2,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));
