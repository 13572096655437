//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ScheduleOutline, ScheduleDetailsPanel } from '../index';
import { useScheduler } from '../providers/SchedulerProvider';

//////////////////////// COMPONENT ////////////////////////

export default function Schedule() {
  const cls = useStyles();
  const { selectedPlan, workingPlan, isSchedulerVisible = false, selectedEnrichedMoveId } = useScheduler();

  // Check to mkae sure there is a selected plan
  if (!isSchedulerVisible || !selectedPlan) return null;

  // Find the enrichedMove to pass into the schedule details panel (get the latest verion instead of an old clone)
  let selectedEnrichedMove = null;
  if (selectedEnrichedMoveId && workingPlan && workingPlan.enrichedMoves && workingPlan.enrichedMoves.length > 0) {
    selectedEnrichedMove = workingPlan.enrichedMoves.find(em => em.id === selectedEnrichedMoveId) || null;
  }

  return (
    <div className={cls.scheduleRoot}>
      <div className={cls.navPad} />
      <div className={cls.schedule}>
        <div className={cls.scheduleOutline}>
          <ScheduleOutline />
        </div>
        <div className={cls.scheduleDetailsPanel}>
          <ScheduleDetailsPanel enrichedMove={selectedEnrichedMove} />
        </div>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  scheduleRoot: {
    zIndex: 300,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  navPad: {
    position: 'relative',
    width: '100%',
    minHeight: 64,
    maxHeight: 64,
  },
  schedule: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
  },
  scheduleOutline: {
    position: 'relative',
    flex: 1,
    flexGrow: 8,
    width: '100%',
    background: theme.palette.background.paper,
  },
  scheduleDetailsPanel: {
    position: 'relative',
    flex: 1,
    flexGrow: 4,
    width: '100%',
    borderLeft: theme.border[0],
    background: theme.palette.background.main,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
}));
