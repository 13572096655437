import React, { createContext, useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useData } from '../../../providers/DataProvider';
import { useTools } from '../../../hooks/useTools';
import { useSettings } from './SettingsProvider';
import { useRegions } from '../providers/RegionsProvider';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

const DriversContext = createContext({});

function DriversProvider({ refreshSeconds = 500, children }) {
  const { apolloClient } = useData();
  const { spreadDriverObj, condensedCase } = useTools();
  const { regions } = useRegions();
  const { enableDriverLogs } = useSettings();

  const { log } = new SimpleLogger({ prefix: 'DriversProvider', enabled: enableDriverLogs });

  const [drivers, setDrivers] = useState();

  React.useEffect(() => {
    refreshDrivers();
  }, [regions]);

  const refreshDrivers = async () => {
    try {
      let _drivers = [];
      let res = await apolloClient.query({
        query: gql`
          query admin_getDrivers {
            drivers(where: { user: { active: { _eq: true } }, status: { _in: ["training", "online", "offline"] } }) {
              id
              config
              device_token
              driver_app_version
              region_id
              status
              verification
              region {
                id
                name
              }
              user {
                id
                avatar_url
                display_name
                email
                phone
              }
            }
          }
        `,
      });
      if (getPropValue(res, `data.drivers`) && res.data.drivers.length) {
        _drivers = res.data.drivers.map(d => spreadDriverObj(d));

        _drivers = _drivers.sort((a, b) => {
          if (a.display_name > b.display_name) return 1;
          if (a.display_name < b.display_name) return -1;
          return 0;
        });

        log(`Drivers:`, _drivers);
        setDrivers(_drivers);
      } else throw new Error(`No drivers found!`);
    } catch (err) {
      console.error(`Failed to fetch drivers:`, err);
      setDrivers([]);
    }
  };

  const getDriverById = id => {
    if (!drivers || drivers.length < 1) return {};
    const driver = drivers.find(d => d.id === id) || {};
    return driver;
  };

  const translateDriverStatus = (driver, latestMoveStatus) => {
    // Format driverStatus and moveStatus
    const driverStatus = driver && driver.status ? condensedCase(driver.status) : null;
    const moveStatus = condensedCase(latestMoveStatus) || null;

    // Set the simple driver status
    if (
      (driverStatus === `online` || driverStatus === `training`) &&
      (moveStatus === `accepted` ||
        moveStatus === `arrived` ||
        moveStatus === `pickedup` ||
        moveStatus === `droppedoff` ||
        moveStatus === `pickupstarted` ||
        moveStatus === `pickuparrived` ||
        moveStatus === `pickupsuccessful` ||
        moveStatus === `deliverystarted` ||
        moveStatus === `deliveryarrived`)
    ) {
      return `busy`;
    }
    if (driverStatus === `online` || driverStatus === `training`) return `free`;
    return `inactive`;
  };

  let _timerID = null;

  useEffect(() => {
    async function doAsync() {
      await refreshDrivers();

      _timerID = setInterval(() => {
        refreshDrivers();
      }, refreshSeconds * 1000);
    }
    doAsync();

    return function cleanup() {
      log(`Cleaning up driver auto refresh`);
      clearInterval(_timerID);
    };
  }, []);

  const context = {
    drivers,
    getDriverById,
    refreshDrivers,
    translateDriverStatus,
  };

  return <DriversContext.Provider value={context}>{children}</DriversContext.Provider>;
}

const useDrivers = () => useContext(DriversContext);

export { useDrivers, DriversProvider };
