// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, LinearProgress, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Button } from '@hopdrive/storybook';

import { useLazyQuery } from '@apollo/client';
import { GET_DATA_FOR_GROUP_PLANNING } from './gql';

import { useTools } from '../../hooks/useTools';
import useGroupPlanning from './useGroupPlanning';

import Timeline from '../../components/TimelineComponents/Timeline';
import TimelineToolbarBasic from '../../components/TimelineComponents/TimelineToolbarBasic';
import Toolbar from '../../components/Toolbar';


import {
  buildDraftOrder,
  buildChasePlan,
  convertPlanToComponentFormat,
  createBatchJob,
} from '../../utils/autoplanner/utils';

import { getUserEmail } from '../../utils/authHelper';
// COMPONENT ---------------------------------------------------------------- //

// Must take in formData props from location state (from /group-planning/form)

// Example move config
// const moveConfig = {
//   chaseVehicleCapacity: 10,
// };

// Example chase vehicle config
// const chaseVehicleConfig = {
//   loadId: "JH49D2",
// };

export default function GPTimeline({ location }) {
  const cls = useStyles();

  const { goToGroupPlanningForm, goToPlans } = useTools();

  const initialTimelineData = location?.state?.initialTimelineData || undefined;
  console.log(`INITIAL TIMELINE DATA:`, initialTimelineData);

  const timezoneOffset = dayjs()
    .tz(initialTimelineData?.timezone || dayjs.tz.guess())
    .format(`Z`);

  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [timelineZoom, setTimelineZoom] = React.useState(1);
  const [plans, setPlans] = React.useState([]);
  const [draftPlan, setDraftPlan] = React.useState([]);

  const markers = [
    {
      id: `start`,
      label: `Start Time`,
      time: dayjs(`${initialTimelineData?.date}T${initialTimelineData?.startTime}:00${timezoneOffset}`).format(),
    },
    {
      id: `end`,
      label: `End Time`,
      time:
        initialTimelineData?.endTime > initialTimelineData?.startTime
          ? dayjs(`${initialTimelineData?.date}T${initialTimelineData?.endTime}:00${timezoneOffset}`).format()
          : dayjs(`${initialTimelineData?.date}T${initialTimelineData?.endTime}:00${timezoneOffset}`)
              .add(1, `day`)
              .format(),
    },
  ];

  const [getDataForGroupPlanning] = useLazyQuery(GET_DATA_FOR_GROUP_PLANNING, {
    variables: {
      driverIds: [initialTimelineData?.vehicleDriverId, ...initialTimelineData?.driverIds],
      laneIds: initialTimelineData?.laneIds,
      vehicleId: initialTimelineData?.vehicleId,
      start: dayjs().subtract(1, `week`).startOf(`day`).format(),
      end: dayjs().add(1, `week`).endOf(`day`).format(),
    },
  });

  const { waitForBatchJobCompletion } = useGroupPlanning();

  const fetchPlans = async () => {
    try {
      const res = await getDataForGroupPlanning();
      if (res) {
        const vehicleDriver =
          res?.data?.drivers?.find(driver => driver?.id === initialTimelineData?.vehicleDriverId) || {};
        const drivers = res?.data?.drivers?.filter(driver => driver?.id !== initialTimelineData?.vehicleDriverId) || [];
        const moves = res?.data?.moves || [];
        const chaseVehicle = res?.data?.chaseVehicle[0] || {};

        const start = dayjs(
          `${initialTimelineData?.date}T${initialTimelineData?.startTime}:00${timezoneOffset}`
        ).format();
        const end =
          initialTimelineData?.endTime > initialTimelineData?.startTime
            ? dayjs(`${initialTimelineData?.date}T${initialTimelineData?.endTime}:00${timezoneOffset}`).format()
            : dayjs(`${initialTimelineData?.date}T${initialTimelineData?.endTime}:00${timezoneOffset}`)
                .add(1, `day`)
                .format();

        const draftOrder = buildDraftOrder(moves);
        const builtPlans = buildChasePlan({
          chaseDriver: vehicleDriver,
          drivers,
          draftMoves: draftOrder,
          chaseVehicle,
          start,
          end,
        });
        const convertedPlans = convertPlanToComponentFormat(builtPlans);

        setPlans(convertedPlans);
        setDraftPlan(builtPlans);
        setLoading(false);
      }
    } catch (err) {
      console.log(`Failed to fetch drivers by driver ids:`, err);
    }
  };

  const handleSubmitPlan = async () => {
    setSaving(true);
    try {
      const email = getUserEmail();
      const batchJob = await createBatchJob(draftPlan, email);
      console.log(`batchJob: `, batchJob);
      const batchJobResult = await waitForBatchJobCompletion(batchJob?.batch_id);
      console.log(`batchJobResult: `, batchJobResult);
      goToPlans(dayjs(initialTimelineData?.date).format(`YYYY-MM-DD`));
    } catch (err) {
      console.log(`Failed to save plan:`, err);
    }
    setSaving(false);

  };

  React.useEffect(() => {
    if (initialTimelineData) fetchPlans();
  }, [initialTimelineData]);

  return (
    <div className={cls.root}>
      <div className={cls.toolbarWrapper}>
        <Toolbar fullscreen shadow back={() => goToGroupPlanningForm(initialTimelineData)} title={`Group Planning`}>
          <Grid container>
            <Grid item xs />
            <Grid item>
              {!loading ? (
                <Button
                  loading={saving}
                  disabled={saving}
                  style={{ marginTop: -5, marginBottom: -5 }}
                  color='primary'
                  onClick={handleSubmitPlan}
                >
                  Submit Plan
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </div>

      <div className={cls.toolbarPad} />

      {loading ? (
        <LinearProgress />
      ) : (
        <Timeline
          data={plans}
          dataType='static-plan'
          date={initialTimelineData?.date}
          earliestTime={markers[0]?.time}
          header={
            <TimelineToolbarBasic
              title={dayjs(initialTimelineData?.date).format(`M/D/YYYY`)}
              zoom={timelineZoom}
              setZoom={setTimelineZoom}
            />
          }
          latestTime={markers[1]?.time}
          markers={markers}
          timezoneOverride={initialTimelineData?.timezone}
          zoom={timelineZoom}
        />
      )}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  toolbarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 300,
  },
  toolbarPad: {
    width: '100%',
    height: 56,
  },
}));
