//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import { useInvoices } from '../Invoices/useInvoices';

import { ZeroDollarTableRow } from './index';

//////////////////////// COMPONENT ////////////////////////

function ZeroDollarTableBody(props) {
  const { rows, order, orderBy, page, rowsPerPage, handleARReviewModalOpen, handleARLogsModalOpen } = props;
  const { getDefaultFoldId } = useInvoices();

  const [foldId, setFoldId] = React.useState(getDefaultFoldId());

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`zero-dollar-row-${i}`}>
              <ZeroDollarTableRow
                row={row}
                foldId={foldId}
                setFoldId={setFoldId}
                handleARReviewModalOpen={handleARReviewModalOpen}
                handleARLogsModalOpen={handleARLogsModalOpen}
              />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ZeroDollarTableBody;