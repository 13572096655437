import React from 'react';
import { makeStyles, Grid, Link, Icon, Typography } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from '../../components/ModalComponents';
import { useTools } from '../../hooks/useTools';

export default function VinOverrideModal(props) {
  const { open, setVinOverrideModal, vinOverrideHash } = props;
  const cls = useStyles();

  const { copyToClipboard } = useTools();

  const handleClose = () => {
    setVinOverrideModal(false);
  };

  return (
    <Modal open={open}>
      <>
        <ModalHeader handleClose={handleClose}>VIN Override Code</ModalHeader>
        <ModalContent  subtitle={`The following code can be provided to the driver in the case that the vehicle VIN is invalid and there is no way to update it to the correct VIN on the move. The VIN captured by the driver will be assumed correct and added to Pickup Workflow Data.`}>
            <div className={cls.root}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={1} style={{ textAlign: 'left' }}>
                  <Icon style={{ fontSize: '30pt', marginLeft: '50px', color: '#00000040' }} onClick={() => copyToClipboard(vinOverrideHash)}>
                    content_copy_icon
                  </Icon>
                </Grid>
                <Grid item xs={11}>
                    <Typography style={{ fontSize: '40pt', paddingLeft: '20px' }} >
                        {vinOverrideHash}
                    </Typography>
                </Grid>
              </Grid>
            </div>
        </ModalContent>
      </>
    </Modal>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(3),
  },

  headers: {
    marginTop: theme.spacing(4),
  },


  url: {
    backgroundColor: '#f0f0f0',
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
    width: '100%',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
}));