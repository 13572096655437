//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  allowEmailNotifications: `config.allow_email_notifications`,
  notifyBilling: `notify_billing`,
};

// DEFAULTS //
const getDefaultEmailPreference = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.allowEmailNotifications) === false) return false;
  if (getPropValue(overrideRef, option?.allowEmailNotifications) === true) return true;
  return getPropValue(inheritedRef, option?.allowEmailNotifications);
};

const getDefaultBillingNotificationPreference = overrideRef => {
  return getPropValue(overrideRef, option?.notifyBilling);
};

//////////////////////// COMPONENT ////////////////////////

export default function Notifications({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [emailPreference, setEmailPreference] = React.useState(getDefaultEmailPreference(overrideRef, inheritedRef));

  const [billingNotificationPreference, setBillingNotificationPreference] = React.useState(
    getDefaultBillingNotificationPreference(overrideRef, inheritedRef)
  );

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setEmailPreference(getDefaultEmailPreference(null, inheritedRef));
    }
  }, [isReset]);

  const handleEmailPreferenceChange = () => {
    // Get the value
    const value = !emailPreference;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { allow_email_notifications: value } };
    setEmailPreference(value);
    updateMutableRef(changes);
  };

  // Handle notifications change
  const handleBillingNotificationPreferenceChange = () => {
    // Get the value
    const value = !billingNotificationPreference;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { notify_billing: value };
    setBillingNotificationPreference(value);
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Notifications`}
        tip={`The module records the communication from Hopdrive which the client has opted into.`}
      />

      <SettingsOption
        contained
        title={`Move Status Updates`}
        description={`Select whether this client prefers its users to receive email notifications when a move has been picked up or delivered successfully. If selected, the user who created the move, as well as an optional list of other users specified during move planning, will receive a notification via email.`}
        value={emailPreference}
        checkReset={() => checkMutableRefField(option?.allowEmailNotifications)}
        onReset={() =>
          deleteMutableRefField(option?.allowEmailNotifications, () =>
            setEmailPreference(getPropValue(inheritedRef, option?.allowEmailNotifications))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={emailPreference} onChange={e => handleEmailPreferenceChange(e)} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Billing Notifications`}
        description={`Activate/Deactivate billing notifications for this client. Billing notifications determine whether or not we send updates to the client for AR related reasons such as an invoice closing.`}
      >
        <Switch
          color='primary'
          checked={billingNotificationPreference}
          onChange={() => handleBillingNotificationPreferenceChange()}
        />
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  radio: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));
