//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Chip, Icon, Typography } from '@material-ui/core';
import { Table, Column } from '../../../components/TableComponents';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '../../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../../components/Fallbacks/DefaultEmptyFallback';
import DefaultLoadingFallback from '../../../components/Fallbacks/DefaultLoadingFallback';
import Toolbar from '../../../components/Toolbar';
import CustomerUsersFilter from './CustomerUsersFilter';

import { useTools } from '../../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerUsersTable({ customer }) {
  const cls = useStyles();
  const { goToDealerUserDetails, getInitialsFromName, goToDealerUserAdd } = useTools();

  // Component state
  const [status, setStatus] = React.useState(`all`);
  const [search, setSearch] = React.useState(``);
  const [users, setUsers] = React.useState([]);

  const { loading, error, data, refetch } = useQuery(GET_USERS_BY_CUSTOMER, {
    variables: {
      customerId: customer.id,
    },
  });

  React.useEffect(() => {
    let usersArray = [];
    if (data && data.users) {
      usersArray = data.users;
    }

    //Filter out possible duplicates from allowed customers and by customer id queries
    let uniqueArray;
    if (usersArray.length > 0) {
      uniqueArray = usersArray.filter((obj, index, array) => {
        return array.findIndex(item => item.id === obj.id) === index;
      });
      setUsers(uniqueArray);
    }
  }, [data]);

  // Handle add user
  const handleAddCustomerUser = () => {
    goToDealerUserAdd({ customer: customer });
  };

  // Handle clear filters
  const handleClearFilters = () => {
    setStatus(`all`);
    setSearch(``);
  };

  // Set filtered users based on search results
  const [filteredUsers, setFilteredUsers] = React.useState(users);
  React.useEffect(() => {
    let newFilteredUsers = users;

    if (search && search.length > 0) {
      const searchInput = search.toLowerCase();
      newFilteredUsers = newFilteredUsers.filter(u => {
        return (
          `${u?.id}`.includes(searchInput) ||
          u?.display_name?.toLocaleLowerCase().includes(searchInput) ||
          u?.email?.toLocaleLowerCase().includes(searchInput) ||
          u?.default_role?.toLocaleLowerCase().includes(searchInput)
        );
      });
    }

    setFilteredUsers(newFilteredUsers);
    // eslint-disable-next-line
  }, [users, search]);

  const rowActions = [
    {
      label: `Go To User Details`,
      handler: user => goToDealerUserDetails(user.id ? user.id : null),
      disabled: false,
      hide: false,
    },
  ];

  const getFormattedDate = date => {
    let formattedDate = dayjs(date).format('M/DD/YYYY');
    let formattedTime = dayjs(date).format('h:mm a');
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Users' onClear={handleClearFilters} refetch={refetch}>
        <CustomerUsersFilter handleAddUser={handleAddCustomerUser} search={search} onSearchChange={setSearch} />
      </Toolbar>

      {error ? (
        <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMERS TABLE' />}></Sentry.ErrorBoundary>
      ) : loading ? (
        <DefaultLoadingFallback />
      ) : filteredUsers.length ? (
        <Table
        data={filteredUsers}
        onRowClick={user => goToDealerUserDetails(user.id ? user.id : null)}
        rowActions={rowActions}
        defaultOrderBy={'name'}
        defaultOrder={'asc'}
        tableAriaLabel={`users`}
        stickyHeader={true}
      >
        <Column name={`id`} value={user => user.id} label={`ID`} tooltip={`ID of the user`} style={{ minWidth: 20 }} />
        <Column
          name={`avatar_img`}
          value={user => user.display_name || null}
          renderer={user =>
            user.avatar_url ? (
              <div className={cls.avatar}>
                <img className={cls.avatarImg} src={user?.user?.avatar_url || user.avatar_url} alt='avatar' />
              </div>
            ) : (
              <div className={cls.avatar}>
                <div className={cls.avatarImg}>
                  <Typography className={cls.initialsTxt}>
                    {getInitialsFromName({ user_name: user?.user?.display_name || user.display_name || null })}
                  </Typography>
                </div>
              </div>
            )
          }
          label={`Avatar`}
          tooltip={`Image of the user`}
        />
        <Column
          name={`name`}
          value={user => user.display_name}
          label={`Name`}
          tooltip={`Name of the user`}
          style={{ minWidth: 40 }}
        />
        <Column
          name={`email`}
          value={user => user.email}
          label={`Email`}
          tooltip={`Email of the user`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`role`}
          value={user => user.default_role}
          label={`Role`}
          tooltip={`Default role of the user`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`createdat`}
          value={user => getFormattedDate(user.created_at)}
          label={`Created`}
          tooltip={`When the user was created`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`status`}
          value={user => user.active}
          renderer={user => (
            <Chip
              variant={user.active ? 'default' : 'outlined'}
              icon={user.active ? null : <Icon fontSize='small'>close</Icon>}
              size='small'
              color={user.active ? 'primary' : 'default'}
              label={user.active ? 'Active' : 'Inactive'}
            />
          )}
          label={`Status`}
          align={`right`}
          tooltip={`Status of the user`}
          style={{ minWidth: 30 }}
        />
      </Table>
      ) : <DefaultEmptyFallback message='NO USERS FOUND' />}
    </div>
  );
}

//////////////////////// GQL ////////////////////////

const GET_USERS_BY_CUSTOMER = gql`
  query get_users_by_customer($customerId: bigint!) {
    users(
      where: {
        _or: [{ customer_id: { _eq: $customerId } }, { users_usertocustomers: { customer_id: { _eq: $customerId } } }]
      }
    ) {
      active
      avatar_url
      created_at
      customer_id
      default_role
      display_name
      email
      id
      phone
      updated_at
    }
  }
`;

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
}));
