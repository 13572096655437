import { gql } from 'graphql-tag';

const GET_ACCESSORIALS = gql`
  query getAccessorials($moveId: bigint!) {
    accessorials(where: { move_id: { _eq: $moveId } }) {
      id
      move_id
      code
      status
      notes
      cost
      ap_amount
      ar_amount
      move {
        accountsReceivable {
          status
        }
      }
      appayment {
        active
        id
        driver_id
        move_id
        status
        ach_transaction_id
        amount
        createdat
        updatedat
        notes
        author
        type
        accessorial_id
      }
    }
  }
`;

const GET_LANE_INFO = gql`
  query GetLaneInfo($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId } }) {
      driver_id
      lane {
        distance_miles
        driver_pay_per_minute
        pickup {
          region_id
        }
        delivery {
          region_id
        }
      }
      customer_id
    }
  }
`;
const GET_PER_MIN_RATES = gql`
  query getPayRateRules($date: timestamptz!, $region: bigint!, $customer: bigint!, $distance: numeric!) {
    byCustomerAndRegion: payraterules(
      where: {
        _and: [
          { payrategroup: { active: { _eq: true } } }
          { payrategroup: { customer_id: { _eq: $customer } } }
          { payrategroup: { begin_date: { _lte: $date } } }
          { payrategroup: { end_date: { _gt: $date } } }
          { payrategroup: { region_id: { _eq: $region } } }
          { distance_start: { _lte: $distance } }
          { distance_end: { _gt: $distance } }
        ]
      }
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byCustomer: payraterules(
      where: {
        _and: [
          { payrategroup: { active: { _eq: true } } }
          { payrategroup: { customer_id: { _eq: $customer } } }
          { payrategroup: { begin_date: { _lte: $date } } }
          { payrategroup: { end_date: { _gt: $date } } }
          { payrategroup: { region_id: { _is_null: true } } }
          { distance_start: { _lte: $distance } }
          { distance_end: { _gt: $distance } }
        ]
      }
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byRegion: payraterules(
      where: {
        _and: [
          { payrategroup: { active: { _eq: true } } }
          { payrategroup: { customer_id: { _is_null: true } } }
          { payrategroup: { begin_date: { _lte: $date } } }
          { payrategroup: { end_date: { _gt: $date } } }
          { payrategroup: { region_id: { _eq: $region } } }
          { distance_start: { _lte: $distance } }
          { distance_end: { _gt: $distance } }
        ]
      }
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
    byMileage: payraterules(
      where: {
        _and: [
          { payrategroup: { active: { _eq: true } } }
          { payrategroup: { customer_id: { _is_null: true } } }
          { payrategroup: { begin_date: { _lte: $date } } }
          { payrategroup: { end_date: { _gt: $date } } }
          { payrategroup: { region_id: { _is_null: true } } }
          { distance_start: { _lte: $distance } }
          { distance_end: { _gt: $distance } }
        ]
      }
    ) {
      per_minute_rate
      payrategroup {
        name
        description
      }
    }
  }
`;

export { GET_ACCESSORIALS, GET_LANE_INFO, GET_PER_MIN_RATES };
