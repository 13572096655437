// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Typography } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineAreaMarkerHover({ showLabel }) {
  const cls = useStyles();

  const { timezoneOverride, markerHoverTime, markerHoverPosition } = useTimeline();

  return (
    <div className={cls.timelineAreaMarker} style={{ left: markerHoverPosition }}>
      <div className={cls.line} />

      {showLabel ? (
        <div className={cls.label}>
          <Typography className={cls.labelTxt}>
            {dayjs(markerHoverTime).tz(timezoneOverride).format(`h:mm A z`)}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineAreaMarker: {
    zIndex: 300,
    position: 'absolute',
    display: 'flex',
    top: 0,
    height: '100%',
    userSelect: 'none',
    pointerEvents: 'none',
  },

  line: {
    borderLeft: `1px solid ${theme.palette.default.lighter}`,
    height: '100%',
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    height: 'fit-content',
    padding: 3,
    borderRadius: '0 0 4px 0',
    background: theme.palette.default.lighter,
  },
  labelTxt: {
    whiteSpace: 'nowrap',
    lineHeight: 1,
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.contrastPrimary,
  },
}));
