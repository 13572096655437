import React from 'react';
import { Button, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '../../components/Toolbar';
import ChaseVehicleContent from './ChaseVehicleContent';
import AddChaseVehicleContent from './AddChaseVehicleModal';
import { Modal } from '../../components/ModalComponents';

export default function ChaseVehicles() {
  const theme = useTheme();
  const cls = useStyles();

  const [addOpen, setAddOpen] = React.useState(false);

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Chase Vehicles'>
        <div className={cls.flex}>
          <div className={cls.flexStretch} />
          <Button variant='contained' color='primary' size='medium' onClick={() => setAddOpen(true)}>
            Add Chase Vehicle
          </Button>
        </div>
      </Toolbar>
      <ChaseVehicleContent />
      <Modal open={addOpen} onClose={() => setAddOpen(false)} width='sm'>
        <AddChaseVehicleContent onClose={() => setAddOpen(false)} />
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexStretch: {
    flex: 1,
  },
}));
