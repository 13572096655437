import React from 'react';
import { useData } from '../../providers/DataProvider';

import { REMOVE_LOCATION } from './gql';
import { toast } from 'react-toastify';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

const log = false;

////////// COMPONENT //////////
export default function LocationRemoveModal(props) {
  const ctx = useData();

  const { open, onClose, locationInput } = props;

  const handleSubmit = async () => {
    try {
      let res = await ctx.apolloClient.mutate({
        mutation: REMOVE_LOCATION,
        variables: { id: locationInput.id },
      });
      if (res) {
        const resLoc = res.data.update_locations.returning[0];
        log && console.log(`>> DELETED Location:`, resLoc);
        toast.success('Deleted location');
        handleClose();
      }
    } catch (err) {
      console.error(`Error removing location:`, err);
      toast.error('Error deleting location');
    }
  };
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Remove Location</ModalHeader>

        <ModalContent
          subtitle={`Are you sure you want to remove the location: '${
            locationInput && locationInput.name
          }'? Note this will set the location to inactive, but will not remove it from the database.`}
        />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}
