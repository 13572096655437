// -------------------------------- IMPORTS -------------------------------- //

import axios from 'axios';
import dayjs from 'dayjs';
import { getUserToken } from './authHelper';

// -------------------------------- EXPORTS -------------------------------- //

/** Breakdown a string of text to its simplest form (remove all formatting and spacing) */
export const condensedCase = (str = null) => {
  if (str && typeof str === `string`) {
    const newStr = str
      .replace(/[,.?!:;`'"/{}()<>\s_+-]/g, ``)
      .toLowerCase()
      .trim();
    return newStr;
  }
  return str;
};

/** Capitalize the first letter of the first word */
export const capFirst = (str = null) => {
  if (str && typeof str === `string`) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/** Capitalize the first letter of each word */
export const capEach = (str = null) => {
  if (str && typeof str === `string`) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
  return str;
};

/** Capitalize every letter of every word */
export const capAll = (str = null) => {
  if (str && typeof str === `string`) {
    return str.toUpperCase();
  }
  return str;
};

/** Round a number with a precision */
export const round = (num = null, precision = 0) => {
  if (num) {
    const multiplier = Math.pow(10, precision);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  }
  return 0;
};

/** Detect if a number is negative */
export const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

/** Get formatted status string from a hangtag */
export const getFormattedStatusFromHangtag = (hangtag = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for hangtag
  if (hangtag) {
    // Set local variables
    fallbackStatus = `Unassigned`;
    const status = hangtag.status ? hangtag.status.toLowerCase() : null;
    const type = hangtag.type ? hangtag.type.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status && type === `concierge`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `assigned`) return `Assigned`;
      if (status === `scanned`) return `Scanned`;
      if (status === `completed`) return `Completed`;
      return capEach(status);
    }
    if (status && type === `yard`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `scanned`) return `Started`;
      if (status === `assigned`) return `Assigned`;
      if (status === `completed`) return `Staged`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

/** Get the address components from a address */
export const getAddressComponents = async address => {
  // Check for address
  if (address && typeof address === `string`) {
    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/getAddressComponents`,
        data: {
          address: address,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res?.status === 200) {
            return res?.data;
          } else return null;
        })
        .catch(err => {
          console.error(`Failed to get address components from address:`, err);
        });
    } catch (err) {
      console.error(`Failed to get address components from address:`, err);
    }
  }

  // Fallback to returning null
  return null;
};

/** Get the timezone string from a location's latitude and longitude */
export const getLatLngTimezone = async (lat, lng) => {
  // Check for latitude and longitude
  if (lat && lng && Number(lat) && Number(lng)) {
    try {
      const token = await getUserToken();
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/getTimezone`,
        data: {
          latitude: lat,
          longitude: lng,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res?.status === 200) {
            return res?.data?.timeZoneId;
          } else return null;
        })
        .catch(err => {
          console.error(`Failed to get timezone from latitude and longitude:`, err);
        });
    } catch (err) {
      console.error(`Failed to get timezone from latitude and longitude:`, err);
    }
  }

  // Fallback to returning null
  return null;
};

/** Get user name from a move/driver/user object */
export const getUserName = (obj = null) => {
  return (
    obj?.name ||
    obj?.driver_name ||
    obj?.display_name ||
    obj?.driver?.name ||
    obj?.driver?.driver_name ||
    obj?.driver?.display_name ||
    obj?.user?.name ||
    obj?.user_name ||
    obj?.user?.driver_name ||
    obj?.user?.display_name ||
    obj?.driver?.user?.name ||
    obj?.driver?.user?.driver_name ||
    obj?.driver?.user?.display_name ||
    null
  );
};

/** Get the users's first name from the display_name */
export const getFirstNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length) {
      const firstName = splitArr[0];
      return firstName;
    }
  }
  return null;
};

/** Get the users's middle name from the display_name */
export const getMiddleNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 2) {
      const middleName = splitArr[1];
      return middleName;
    }
  }
  return null;
};

/** Get the users's last name from the display_name */
export const getLastNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 1) {
      const lastName = splitArr[splitArr.length - 1];
      return lastName;
    }
  }
  return null;
};

/** Get user initials string from an object */
export const getInitialsFromName = (obj = null) => {
  // Default fallback initials string when not enough info is provided
  const fallbackInitials = `N/A`;

  // Check for obj
  if (obj) {
    // Set local variables
    const fullName = getUserName(obj) || fallbackInitials;

    // Get/Set initials
    if (fullName !== fallbackInitials) {
      // Split the full name into first, middle and last
      const firstName = getFirstNameFromDisplayName(fullName);
      const middleName = getMiddleNameFromDisplayName(fullName);
      const lastName = getLastNameFromDisplayName(fullName);

      // Assign each initial
      const firstI = firstName ? condensedCase(firstName[0]) : ``;
      const middleI = middleName ? condensedCase(middleName[0]) : ``;
      const lastI = lastName ? condensedCase(lastName[0]) : ``;

      // Return built initials
      const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
      if (initials && initials !== ``) return initials;
    }
  }
  return fallbackInitials;
};

/** Get formatted activity string from driverlocation object */
export const getActivityFromPoint = (point = null) => {
  // Default fallback activity string when not enough info is provided
  const fallbackActivity = `Unknown`;

  // Check for point
  if (point) {
    // Set local activity string
    let activity = fallbackActivity;

    // Check activity string from driverlocation
    if (point.activity_type === `still`) activity = `Still`;
    if (point.activity_type === `on_foot` || point.activity_type === `walking`) activity = `Walking`;
    if (point.activity_type === `in_vehicle` || point.activity_type === `driving`) activity = `Driving`;

    // Return the built activity string
    return activity;
  }
  return fallbackActivity;
};

/** Get formatted activity icon string from driverlocation object */
export const getActivityIconFromPoint = (point = null) => {
  // Default fallback activity icon string when not enough info is provided
  const fallbackActivityIcon = `question_mark`;

  // Check for point
  if (point) {
    // Set local activity icon string
    let activityIcon = fallbackActivityIcon;

    // Check activity icon string from driverlocation
    if (point.activity_type === `still`) activityIcon = `pause`;
    if (point.activity_type === `on_foot` || point.activity_type === `walking`) activityIcon = `directions_walk`;
    if (point.activity_type === `in_vehicle` || point.activity_type === `driving`) activityIcon = `drive_eta`;

    // Return the built activity icon string
    return activityIcon;
  }
  return fallbackActivityIcon;
};

/** Get formatted type string from the location object */
export const getFormattedTypeFromLocation = (location = null) => {
  // Default fallback status string when not enough info is provided
  const fallbackStatus = `-`;

  // Check for location
  if (location) {
    // Set local variables
    const type = condensedCase(location.type) || ``;

    // Check type and return a formatted string
    if (type && type !== ``) {
      if (type === `customer`) return `Standard`;
      else if (type === `consumerbusiness`) return `Consumer Business`;
      else if (type === `consumerresidential`) return `Consumer Residential`;
      else return capEach(location.type);
    }
  }
  return fallbackStatus;
};

/** Get a location from a list by passing in the id and list to look in */
export const getLocationByIdFromList = (locationId, locations) => {
  if (locationId) {
    const foundLocation = locations.find(pl => pl.id === locationId);
    if (foundLocation) return foundLocation;
  }
  return null;
};

/** Get the accessorial in pending status by passing in the accessorials array */
export const getPendingAccessorial = accs => {
  return accs.find(acc => acc.status === 'pending' && acc.authorization);
};

/** Get preferred name of location */
export const getNameFromLocation = (location = null) => {
  const fallbackName = `Unknown Location`;

  if (location) {
    let locationName = fallbackName;

    if (location.name) locationName = location.name;
    // if (location.nickname) locationName = location.nickname;

    return locationName;
  }

  return fallbackName;
};

/** Find the duration between two times (in seconds) */
export const durationBetween = (timeOne = null, timeTwo = null) => {
  if (timeOne && timeTwo) {
    const formattedTimeOne = dayjs(timeOne).format();
    const formattedTimeTwo = dayjs(timeTwo);
    const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
    return durationSec;
  }
  return 0;
};

/** Get duration (in minutes) from specified lane */
export const getDurationInMinutes = (seconds = 0, precision = 0) => {
  const fallbackDuration = `0 min`;

  if (seconds) {
    return `${Number(seconds / 60).toFixed(precision)} min`;
  }

  return fallbackDuration;
};

/** Get the number of minutes between 2 timestamps */
export const getMinutesBetween = (startTimestamp = null, endTimestamp = null) => {
  if (startTimestamp && endTimestamp) {
    let start = dayjs(startTimestamp);
    let end = dayjs(endTimestamp);
    if (start && end) {
      let dur = end.diff(start);
      let mins = Math.round(Math.abs(dayjs.duration(dur).asMinutes()));
      return mins;
    }
  }
  return 0;
};

/** Get the last N digits of a string (N defautls to 8) */
export const getLastNDigits = (str = null, n = 8) => {
  if (str && typeof str === `string` && str.length > n) return str.slice(-n);
  return str;
};

/** Get a cleansed text string (remove underscores and cap all) */
export const getReadableText = (str = null, nbsp = false) => {
  if (str) {
    let newStr = str.split(/[.\s_-]/g);
    newStr = newStr.map(val => capFirst(val).trim());
    return newStr.join(nbsp ? `\xa0` : ` `);
  }
  return str;
};

/** Get snake case from a string (underscore separators + lowercase) */
export const getSnakeCase = (str = null, upperCase = false) => {
  if (str) {
    let newStr = str.split(/[,.\s-]/g);
    newStr = newStr.map(val => val.trim());
    newStr = newStr.join(`_`);
    return upperCase ? newStr.toUpperCase() : newStr.toLowerCase();
  }
  return str;
};

/** Get a cleansed monetary value from a number or string number */
export const formatUSD = (value = null, options = {}) => {
  const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options;

  // Check for value and return null
  if (!value && returnNull) return null;

  // Remove spaces and commas so the value can be converted to a number
  // Build the cleansed monetary string from the numeric value
  const replacedValue = `${value}`.replace(/[,]/g, ``);
  const numValue = Number(replacedValue) || 0;
  let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `$1,`);

  // Additional options
  if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, ``);
  if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, ``);
  if (removeCents) cleansedValue = cleansedValue.split(`.`)[0];

  // Return cleansed monetary value
  return cleansedValue;
};

/** Get a cleansed phone number from a string */
export const getCleansedPhoneNumber = (str = null, removeCountry = true) => {
  if (str) {
    let cleaned = (`` + str).replace(/\D/g, ``);
    let match = cleaned.match(/^([0-9]+|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? `+${match[1]} ` : `+1 `;
      return [removeCountry ? `` : intlCode, `(`, match[2], `)\xa0`, match[3], `-\u2060`, match[4]].join(``);
    }
  }
  return str;
};

/** Get a cleansed phone number flat from a string */
export const getCleansedPhoneNumberFlat = (str = null, removeCountry = true) => {
  if (str) {
    let cleaned = (`` + str).replace(/\D/g, ``);
    let match = cleaned.match(/^([0-9]+|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? `+${match[1]}` : `+1`;
      return [removeCountry ? `` : intlCode, match[2], match[3], match[4]].join(``);
    }
  }
  return str;
};

/** Get a cleansed phone type from a string */
export const getCleansedPhoneType = (str = null) => {
  if (str) {
    if (str === `android` || str === `droid`) return `Android`;
    if (str === `ios` || str === `iphone` || str === `apple`) return `iOS`;
    if (str === `other`) return `Other`;
  }
  return str;
};

/** Replace spaces with non-breaking spaces */
export const getNonBreakingWord = (str = null) => {
  if (str) {
    let newStr = str.replace(/\s/g, '\xa0');
    return newStr;
  }
  return str;
};

/** Parse move workflow data */
export const getWorkflowData = (type, workflow_data, format = `csv`, keyValue = false) => {
  const typeStr = type ? `${type}_` : `extra_`;

  if (workflow_data && typeof workflow_data === `object`) {
    const workflowDataKeys = workflow_data ? Object.keys(workflow_data) : [];
    const workflowDataVals = workflowDataKeys.map(key => workflow_data[key]) || [];

    if (keyValue) {
      const workflowData = workflowDataKeys.map((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        return { index: i, key: formattedKey, val: workflowDataVals[i] };
      });

      return workflowData || [];
    } else {
      let workflowData = {};
      workflowDataKeys.forEach((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        workflowData[formattedKey] = workflowDataVals[i];
      });

      return workflowData || {};
    }
  }
  return null;
};

/** Get the cancel reason from the move */
export const getCancelReasonFromMove = (move = null) => {
  const cancelStatus = move && move.cancel_status ? move.cancel_status : null;
  const cancelReason = move && move.cancel_reason ? move.cancel_reason : null;

  if (cancelReason) return `${cancelReason}.`;
  if (cancelStatus === `pending` || cancelStatus === `seen`) return `Canceled by user.`;
  if (cancelStatus === `delivered`) return `Canceled after vehicle was delivered.`;
  return `Cancel reason not provided.`;
};

/** Check a number to see if its negative, if so, clamp it to 0 */
export const clampNegNum = (num = null) => {
  if (num && !isNaN(parseFloat(num)) && num > 0) return num;
  return 0;
};

/** Copy a string to the clipboard */
export const copyToClipboard = (str = null) => {
  try {
    if (str) navigator.clipboard.writeText(str);
  } catch (err) {
    console.error(`Failed to copy text:`, err);
  }
};

/** Search a location on google maps */
export const searchLocationOnGoogleMaps = (str = null) => {
  try {
    if (str) {
      const searchStr = `https://www.google.com/maps/search/?api=1&query=${str}`;
      window.open(searchStr, `_blank`);
    }
  } catch (err) {
    console.error(`Failed to search location:`, err);
  }
};

/** Search a lane on google maps */
export const searchLaneOnGoogleMaps = (pickupStr = null, deliveryStr = null) => {
  try {
    if (pickupStr && deliveryStr) {
      const searchStr = `https://www.google.com/maps/dir/?api=1&origin=${pickupStr}&destination=${deliveryStr}`;
      window.open(searchStr, `_blank`);
    }
  } catch (err) {
    console.error(`Failed to search lane:`, err);
  }
};

/** Prevent circular reference error on object */
export const circularJSONStringify = (json, spaces = 2, hiddenProperties = []) => {
  let cache = [];
  let formattedJson = '';
  try {
    formattedJson = JSON.stringify(
      json,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          // Duplicate reference found, discard key
          if (cache.includes(value)) return;

          // Store value in our collection
          cache.push(value);
        }
        return hiddenProperties.includes(key) ? 'hidden by circularStringify()' : value;
      },
      spaces
    );
  } catch (error) {
    console.error(`Failed doing JSON.stringify() on circular object: ${error.message}`, json, error);
  } finally {
    cache = null;
    return formattedJson;
  }
};

/** Generate a string array of years */
export const genListOfYears = () => {
  const year = dayjs().add(1, 'y').format('YYYY');
  const till = dayjs().subtract(40, 'y').format('YYYY');
  let yearList = [];
  for (var y = year; y >= till; y--) {
    yearList.push(`${y}`);
  }
  return yearList;
};

/** Generate a string array of colors */
export const genListOfColors = () => {
  const listOfColors = [
    `Black`,
    `Blue`,
    `Brown`,
    `Burgundy`,
    `Gold`,
    `Gray`,
    `Green`,
    `Orange`,
    `Pink`,
    `Purple`,
    `Red`,
    `Silver`,
    `Tan`,
    `White`,
    `Yellow`,
  ];
  return listOfColors;
};

/** Generate a string array of states and territories */
export const genListOfStates = (includeDC = false, includeTerritories = false) => {
  let listOfStates = [
    { initials: `AL`, full: `Alabama` },
    { initials: `AK`, full: `Alaska` },
    { initials: `AZ`, full: `Arizona` },
    { initials: `AR`, full: `Arkansas` },
    { initials: `CA`, full: `California` },
    { initials: `CO`, full: `Colorado` },
    { initials: `CT`, full: `Connecticut` },
    { initials: `DE`, full: `Delaware` },
    { initials: `FL`, full: `Florida` },
    { initials: `GA`, full: `Georgia` },
    { initials: `HI`, full: `Hawaii` },
    { initials: `ID`, full: `Idaho` },
    { initials: `IL`, full: `Illinois` },
    { initials: `IN`, full: `Indiana` },
    { initials: `IA`, full: `Iowa` },
    { initials: `KS`, full: `Kansas` },
    { initials: `KY`, full: `Kentucky` },
    { initials: `LA`, full: `Louisiana` },
    { initials: `ME`, full: `Maine` },
    { initials: `MD`, full: `Maryland` },
    { initials: `MA`, full: `Massachusetts` },
    { initials: `MI`, full: `Michigan` },
    { initials: `MN`, full: `Minnesota` },
    { initials: `MS`, full: `Mississippi` },
    { initials: `MO`, full: `Missouri` },
    { initials: `MT`, full: `Montana` },
    { initials: `NE`, full: `Nebraska` },
    { initials: `NV`, full: `Nevada` },
    { initials: `NH`, full: `New Hampshire` },
    { initials: `NJ`, full: `New Jersey` },
    { initials: `NM`, full: `New Mexico` },
    { initials: `NY`, full: `New York` },
    { initials: `NC`, full: `North Carolina` },
    { initials: `ND`, full: `North Dakota` },
    { initials: `OH`, full: `Ohio` },
    { initials: `OK`, full: `Oklahoma` },
    { initials: `OR`, full: `Oregon` },
    { initials: `PA`, full: `Pennsylvania` },
    { initials: `RI`, full: `Rhode Island` },
    { initials: `SC`, full: `South Carolina` },
    { initials: `SD`, full: `South Dakota` },
    { initials: `TN`, full: `Tennessee` },
    { initials: `TX`, full: `Texas` },
    { initials: `UT`, full: `Utah` },
    { initials: `VT`, full: `Vermont` },
    { initials: `VA`, full: `Virginia` },
    { initials: `WA`, full: `Washington` },
    { initials: `WV`, full: `West Virginia` },
    { initials: `WI`, full: `Wisconsin` },
    { initials: `WY`, full: `Wyoming` },
  ];

  if (includeDC) {
    listOfStates.push({ initials: `DC`, full: `District of Columbia` });
  }

  if (includeTerritories) {
    listOfStates = [
      ...listOfStates,
      { initials: `AS`, full: `American Samoa` },
      { initials: `GU`, full: `Guam` },
      { initials: `MP`, full: `Northern Mariana Islands` },
      { initials: `PR`, full: `Puerto Rico` },
      { initials: `VI`, full: `U.S. Virgin Islands` },
      { initials: `UM`, full: `U.S. Minor Outlying Islands` },
    ];
  }

  return listOfStates;
};

// Format the pickup_time of a move
export const formatTime = time => {
  if (time && typeof time === `string`) {
    const newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
    return newTime;
  }
  return null;
};

// Get the name of a function
export const functionName = f => {
  var ret = f.toString();
  ret = ret.substr('function '.length);
  ret = ret.substr(0, ret.indexOf('('));
  return ret;
};

/** Remove the last point of a geofence if the first and last points are identical
 * This is used for consistency's sake
 */
export const conformRegionGeofenceForMap = geofence => {
  if (geofence && geofence.length) {
    if (JSON.stringify(geofence[0]) === JSON.stringify(geofence[geofence.length - 1])) geofence.pop();
    return geofence;
  }
  return [];
};

/** Convert the strange way we store a geofence in the database to something more readable */
export const convertRegionGeofenceToReadable = region => {
  if (
    region &&
    region.geofence &&
    region.geofence.coordinates &&
    region.geofence.coordinates[0] &&
    region.geofence.coordinates[0].length
  ) {
    // Set the new geofence to something readable by the map
    let newGeofence = region.geofence.coordinates[0].map(coords => ({ lat: coords[1], lng: coords[0] }));

    // If the first and last points are identical, remove the last point
    newGeofence = conformRegionGeofenceForMap(newGeofence);

    // Return the new geofence
    return newGeofence;
  }
  return [];
};

/** Convert the readable geofence to the way we store it in the database */
export const convertRegionGeofenceToDB = region => {
  if (region?.points?.length) {
    // Set the new geofence to the region points
    let newGeofence = region.points;

    // If the first and last points are NOT identical, duplicate the first point
    // This is required for saving the geofence in our DB for some reason
    if (JSON.stringify(newGeofence[0]) !== JSON.stringify(newGeofence[newGeofence.length - 1]))
      newGeofence.push(newGeofence[0]);

    // Build the database geofence object
    newGeofence = {
      type: 'Polygon',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326',
        },
      },
      coordinates: [newGeofence.map(coords => [coords.lng, coords.lat])],
    };

    // Return the new geofence
    return newGeofence;
  }
  return {};
};
