//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationsFilter({ handleAddOrganization, status, onStatusChange, search, onSearchChange }) {
  const cls = useStyles();

  const handleStatusChange = event => {
    onStatusChange(event.target.value);
  };
  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  return (
    <Grid container spacing={2} wrap='nowrap'>
      <Grid item xs>
        <TextField
          select
          fullWidth
          label='Status Filter'
          placeholder='Select a status...'
          variant='outlined'
          size='small'
          value={status || `all`}
          onChange={handleStatusChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  token
                </Icon>
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value={`all`}>All Statuses</MenuItem>
          <MenuItem value={`live`}>Live</MenuItem>
          <MenuItem value={`onboarding`}>Onboarding</MenuItem>
          <MenuItem value={`deactivated`}>Deactivated</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs>
        <TextField
          fullWidth
          label='Search'
          placeholder='Search table...'
          variant='outlined'
          size='small'
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item>
        <Button color='primary' size='large' onClick={() => handleAddOrganization()}>
          <Icon className={cls.addIcon}>add_circle</Icon>
          Add Organization
        </Button>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  addIcon: {
    marginTop: -2,
    marginBottom: -2,
    marginRight: 10,
    fontSize: 20,
  },
}));
