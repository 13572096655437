//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles, Dialog, Paper } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function Modal({ className, classes, children, open, width, onClose, handleClose, ...rest }) {
  const cls = useStyles();

  const getClassName = () => {
    let classNames = [cls.root];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  const handleDragStop = event => {
    // console.log(event);
  };

  function PaperComponent(props) {
    return (
      <Draggable handle='#draggable-modal' bounds='parent' onStop={handleDragStop}>
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Dialog
      aria-labelledby='draggable-modal'
      open={open}
      onClose={(event, reason) => {
        if (onClose) onClose();
        if (handleClose) handleClose();
      }}
      maxWidth={width || `md`}
      PaperComponent={PaperComponent}
      PaperProps={{ className: getClassName() }}
      classes={classes}
      {...rest}
    >
      {children}
    </Dialog>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
}));
