//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function SettingsContent({ children }) {
  const cls = useStyles();

  return <div id='settings-content' className={cls.content}>{children}</div>;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
}));
