//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';

import { vanillaFunctions as tools } from '../../hooks/useTools';

//////////////////////// CALCULATIONS ////////////////////////

// 30 Day Moves
const get30DayMoves = (customer, value = `current30Moves`) => {
  const totalMoves = customer?.[value]?.length || 0;
  return totalMoves || 0;
};
const get30DayMovesCompare = customer => {
  const current = get30DayMoves(customer, `current30Moves`);
  const previous = get30DayMoves(customer, `previous30Moves`);
  const diff = current - previous;
  const percent = (diff / previous) * 100;
  return tools.round(percent, 0) || 0;
};

// Moves Per Day
const getMovesPerDay = (customer, value = `current30Moves`) => {
  const totalMoves = get30DayMoves(customer, value);
  const avgMoves = totalMoves / 30;
  return avgMoves || 0;
};
const getMovesPerDayCompare = customer => {
  const current = getMovesPerDay(customer, `current30Moves`);
  const previous = getMovesPerDay(customer, `previous30Moves`);
  const diff = current - previous;
  const percent = (diff / previous) * 100;
  return tools.round(percent, 0) || 0;
};

// 30 Day Revenue
const get30DayRevenue = (customer, value = `current30Moves`) => {
  let totalRevenue = 0;
  customer?.[value]?.forEach(m => {
    totalRevenue += m?.accountsReceivable?.due_amount || 0;
  });
  return totalRevenue || 0;
};
const get30DayRevenueCompare = customer => {
  const current = get30DayRevenue(customer, `current30Moves`);
  const previous = get30DayRevenue(customer, `previous30Moves`);
  const diff = current - previous;
  const percent = (diff / previous) * 100;
  return tools.round(percent, 0) || 0;
};

// Revenue Per Move
const getRevenuePerMove = (customer, value = `current30Moves`) => {
  const totalRevenue = get30DayRevenue(customer, value);
  const totalMoves = get30DayMoves(customer, value);
  const avgRevenue = totalRevenue / totalMoves;
  return avgRevenue || 0;
};
const getRevenuePerMoveCompare = customer => {
  const current = getRevenuePerMove(customer, `current30Moves`);
  const previous = getRevenuePerMove(customer, `previous30Moves`);
  const diff = current - previous;
  const percent = (diff / previous) * 100;
  return tools.round(percent, 0) || 0;
};

/** Return a positive, negative or neutral string */
const getOperatorString = value => {
  if (value < 0) return ``;
  return `+`;
};

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsOverviewMetrics({ customer }) {
  const cls = useStyles();

  const runningMoves = get30DayMoves(customer);
  const runningMovesCompare = get30DayMovesCompare(customer);

  const movesPerDay = getMovesPerDay(customer);
  const movesPerDayCompare = getMovesPerDayCompare(customer);

  const runningRevenue = get30DayRevenue(customer);
  const runningRevenueCompare = get30DayRevenueCompare(customer);

  const revenuePerMove = getRevenuePerMove(customer);
  const revenuePerMoveCompare = getRevenuePerMoveCompare(customer);

  const getOperatorStyle = value => {
    if (value > 0) return cls.compareTxtPositive;
    if (value < 0) return cls.compareTxtNegative;
    return cls.compareTxtNeutral;
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <div className={cls.paper}>
          <Typography className={cls.keyTxt}>30-Day Moves</Typography>

          <div className={cls.values}>
            <Typography className={cls.valTxt}>{runningMoves}</Typography>
            <Typography className={clsx(cls.compareTxt, getOperatorStyle(runningMovesCompare))}>
              {getOperatorString(runningMovesCompare)}
              {runningMovesCompare}%
            </Typography>
          </div>

          <div className={cls.help}>
            <Tooltip title='Count of completed moves in the past 30 days.'>
              <Icon className={cls.helpIcon}>info_outline</Icon>
            </Tooltip>
          </div>
        </div>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <div className={cls.paper}>
          <Typography className={cls.keyTxt}>Moves Per Day</Typography>

          <div className={cls.values}>
            <Typography className={cls.valTxt}>{tools.round(movesPerDay, 1)}</Typography>
            <Typography className={clsx(cls.compareTxt, getOperatorStyle(movesPerDayCompare))}>
              {getOperatorString(movesPerDayCompare)}
              {movesPerDayCompare}%
            </Typography>
          </div>

          <div className={cls.help}>
            <Tooltip title='Average completed moves per day in the past 30 days.'>
              <Icon className={cls.helpIcon}>info_outline</Icon>
            </Tooltip>
          </div>
        </div>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <div className={cls.paper}>
          <Typography className={cls.keyTxt}>30-Day Revenue</Typography>

          <div className={cls.values}>
            <Typography className={cls.valTxt}>{tools.formatUSD(runningRevenue)}</Typography>
            <Typography className={clsx(cls.compareTxt, getOperatorStyle(runningRevenueCompare))}>
              {getOperatorString(runningRevenueCompare)}
              {runningRevenueCompare}%
            </Typography>
          </div>

          <div className={cls.help}>
            <Tooltip title='Revenue from completed moves in the past 30 days.'>
              <Icon className={cls.helpIcon}>info_outline</Icon>
            </Tooltip>
          </div>
        </div>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <div className={cls.paper}>
          <Typography className={cls.keyTxt}>Revenue Per Move</Typography>

          <div className={cls.values}>
            <Typography className={cls.valTxt}>{tools.formatUSD(revenuePerMove)}</Typography>
            <Typography className={clsx(cls.compareTxt, getOperatorStyle(revenuePerMoveCompare))}>
              {getOperatorString(revenuePerMoveCompare)}
              {revenuePerMoveCompare}%
            </Typography>
          </div>

          <div className={cls.help}>
            <Tooltip title='Average revenue per completed move in the past 30 days.'>
              <Icon className={cls.helpIcon}>info_outline</Icon>
            </Tooltip>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },

  values: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  keyTxt: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  valTxt: {
    fontSize: 24,
    fontWeight: 600,
  },

  compareTxt: {
    marginBottom: 8,
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 400,
  },
  compareTxtPositive: {
    color: theme.palette.success.main,
  },
  compareTxtNegative: {
    color: theme.palette.error.main,
  },
  compareTxtNeutral: {
    color: theme.palette.text.secondary,
  },

  help: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  helpIcon: {
    fontSize: 20,
    color: theme.palette.text.disabled,
    cursor: 'pointer',
    transition: '0.15s',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}));
