import React, { useState } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import RateRuleGroupSelector from './RateRuleGroupSelector';
import RateRuleGroupEditor from './RateRuleGroupEditor';
import dayjs from 'dayjs';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

const log = false;

export default function CustomerPricing({ customer, slas, refetch }) {
  const cls = useStyles();

  // If we don't have an array of rate rule groups then artificially create one
  const defaultRateRuleGroup = {
    id: null,
    customer_id: customer?.id,
    name: 'Original Pricing',
    description: 'The first version of pricing and product contract',
    begin_date: dayjs().startOf('month').format('YYYY/MM/DD'),
    end_date: dayjs().endOf('month').add(5, 'year').format('YYYY/MM/DD'),
    createdat: null,
    updatedat: null,
    raterules: customer?.raterules,
  };
  if (!customer?.raterulegroups?.length) customer.raterulegroups = [defaultRateRuleGroup];

  const [selectedRateRuleGroup, setSelectedRateRuleGroup] = useState(null);
  const [editorHasChanges, setEditorHasChanges] = useState(false);

  const handleRateRuleGroupChange = rateRuleGroup => {
    log && console.log('Rate rule group selected:', rateRuleGroup);
    setEditorHasChanges(false);
    setSelectedRateRuleGroup(rateRuleGroup);
  };

  const handleEditorHasChanges = () => {
    log && console.log('Editor just informed that there are now changes!');
    setEditorHasChanges(true);
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMER PRICING' />}>
      <Grid container>
        <Grid item md={12}>
          <div className={cls.paper}>
            <Grid container>
              <Grid item md={3}>
                <Box
                  borderRight={0}
                  borderColor='LightGrey'
                  display='flex'
                  flexWrap='wrap'
                  alignContent='flex-start'
                  height='100%'
                >
                  <RateRuleGroupSelector
                    allowChanges={!editorHasChanges}
                    rateRuleGroups={customer?.raterulegroups}
                    rateRules={customer?.raterules}
                    onChange={handleRateRuleGroupChange}
                  />
                </Box>
              </Grid>
              <Grid item md={9}>
                <Box m={2}>
                  <RateRuleGroupEditor
                    customer={customer}
                    slas={slas}
                    rateRuleGroup={selectedRateRuleGroup}
                    onHasChanges={handleEditorHasChanges}
                    refetch={refetch}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
