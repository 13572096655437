import { gql } from '@apollo/client';

const GET_INSURANCE_RATE_DETAILS = gql`
  query getInsuranceRateDetails ($id: bigint) {
    insurancerates(
      where: { id: {_eq: $id} }
    ) {
        id
        carrier_name
        createdat
        createdby
        description
        effective_date
        expiration_date
        name
        rate
        class_3_rate
        class_4_rate
        class_5_rate
        class_6_rate
        region_id
        region{
          id
          name
        }
        raterulegroups(order_by: {begin_date: desc}) {
          id
          begin_date
          customer {
            active
            id
            name
          }
          createdat
          customer_id
          description
          end_date
          insurance_rate_id
          name
        }
        state
        type
        updatedat
        updatedby
    }
  }
`;

export { GET_INSURANCE_RATE_DETAILS };
