// Taken from an excellent discussion on a Gist:
// https://gist.github.com/mudge/eb9178a4b6d595ffde8f9cb31744afcf

import { useState, useEffect, useRef } from 'react';

export function useDebounce(callback, delay) {
  const latestCallback = useRef();
  const [lastCalledAt, setLastCalledAt] = useState(null);

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (lastCalledAt) {
      const fire = () => {
        setLastCalledAt(null);
        latestCallback.current();
      };
      const fireAt = lastCalledAt + delay;
      const fireIn = Math.max(fireAt - Date.now(), 0);
      const id = setTimeout(fire, fireIn);

      return () => clearTimeout(id);
    }
  }, [lastCalledAt, delay]);

  return () => setLastCalledAt(Date.now());
}
