//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, useTheme, Typography, Tooltip, Grid, Chip, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';

import { useTools } from '../../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function DriverAvatar({ driver = null, driverStatus = null, clickable, disableTooltip, ...props }) {
  const cls = useStyles();
  const theme = useTheme();
  const { goToDriverDetails, capFirst, condensedCase, getInitialsFromName, getDriverTagsFromAttributes } = useTools();

  let attributes = getDriverTagsFromAttributes(driver?.config?.attributes);

  const getStatusColor = () => {
    if (condensedCase(driverStatus) === `free`) return theme.palette.success.main;
    if (condensedCase(driverStatus) === `busy`) return theme.palette.warning.main;
    return theme.palette.default.lighter;
  };

  const driverActions = [
    {
      label: `Go to Driver Details`,
      handler: () => goToDriverDetails(driver?.id),
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`driver-context-menu-${driver?.id}`}
        holdToDisplay={1000}
        collect={p => p}
        disableIfShiftIsPressed={true}
      >
        <Tooltip
          disableHoverListener={disableTooltip}
          title={
            <div className={cls.ttBox}>
              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Internal ID:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.id || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Name:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.display_name || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Phone:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.phone || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Email:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.email || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Region:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.region_name || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Status:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {capFirst(driverStatus) || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  App Version:
                </Grid>
                <Grid item className={cls.ttVal}>
                  {driver?.driver_app_version || `-`}
                </Grid>
              </Grid>

              <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid item className={cls.ttKey}>
                  Attributes:
                </Grid>
                {attributes.length > 0 ? (
                  <Grid item xs={12}>
                    {attributes.map((attribute, i) => (
                      <span key={`driver-attribute-${i}`} className={cls.toolChipDiv}>
                        <Chip
                          classes={{ root: cls.toolChip, label: cls.toolChipLabel }}
                          label={attribute}
                          size='small'
                          variant='outlined'
                        />
                        {i < attributes.length - 1 ? <Spacer size='xxxs' /> : null}
                      </span>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
            </div>
          }
          placement='bottom'
        >
          <div className={cls.avatar} style={{ cursor: clickable ? `pointer` : `default` }} {...props}>
            {driver?.avatar_url ? (
              <img className={cls.image} src={driver?.avatar_url} alt={driver?.display_name || `HopDriver`} />
            ) : (
              <div className={cls.image}>
                <Typography className={cls.initialsTxt}>
                  {getInitialsFromName({ driver_name: driver?.display_name })}
                </Typography>
              </div>
            )}
            <div className={cls.badge} style={{ background: getStatusColor() }} />
          </div>
        </Tooltip>
      </ContextMenuTrigger>

      <ContextMenu id={`driver-context-menu-${driver?.id}`}>
        {driverActions.map((action, i) => (
          <MenuItem key={`driver-actions-${i}`} disabled={action.disabled} onClick={() => action.handler()}>
            {action.label}
          </MenuItem>
        ))}
      </ContextMenu>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  avatar: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  image: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  badge: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 15,
    height: 15,
    border: '3px solid white',
    borderRadius: '50%',
    background: theme.palette.success.main,
  },

  toolChipDiv: {
    width: 'fit-content',
  },
  toolChip: {
    height: 16,
    borderColor: theme.palette.text.contrast,
    color: theme.palette.text.contrast,
  },
  toolChipLabel: {
    textTransform: 'capitalize',
    fontSize: 10,
    fontWeight: 500,
  },

  ttBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 192,
  },
  ttKey: {
    color: theme.palette.text.contrastFade,
  },
  ttVal: {
    color: theme.palette.text.contrast,
  },
  ttDivider: {
    width: '100%',
    opacity: 0.5,
  },
}));
