import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import { makeStyles, Table, TableBody, TableCell, TableHead, 
TableRow, TableSortLabel, TablePagination } from '@material-ui/core';
import dayjs from 'dayjs';

import Loading from '../../components/Loading';
import { GET_PROMOS } from './gql';
import { DefaultErrorFallback } from '../../components/Fallbacks';

import PromosRow from './PromosRow';

export default function PromosTable(props) {
    const cls = useStyles();

    const { size, headers } = props;

    // STATES ////////////////////////////////////////////////////////////////////////
    const [ page, setPage ] = useState(0)
    const [ rowsPerPage, setRowsPerPage ] = useState(5)

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');

    // LOGIC ////////////////////////////////////////////////////////////////////////
    const handleSortByProperty = property => event => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(0)
    };
    
    // HOOK ////////////////////////////////////////////////////////////////////////
    const { loading, error, data } = useQuery(GET_PROMOS);

    // RENDER //////////////////////////////////////////////////////////////////////
    if (loading) return <Loading />;
    if (error) {
        console.log(error)
        Sentry.captureException(error)
        return <DefaultErrorFallback message={'ERROR FETCHING PROMOS'} />;
    }
    if (data) {
        return ( <>
            <div className={cls.tableWrapper}>
            <Table className={cls.table} size={size ? size : `medium`}>
                <TableHead>
                    <TableRow>
                    { headers.map(
                        header =>
                            !header.hide && (
                            <TableCell
                                key={`${header.id}-call`}
                                align={header.alignLeft ? 'left' : 'right'}
                                padding='normal'
                                sortDirection={orderBy === header.id ? order : false}
                            >
                                {header.value || (
                                <TableSortLabel
                                    className={cls.headTxt}
                                    active={orderBy === header.id}
                                    direction={order}
                                    onClick={handleSortByProperty(header.id)}
                                >
                                    {header.label}
                                    {orderBy === header.id ? (
                                    <span className={cls.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                    ) : null}
                                </TableSortLabel>
                                )}
                            </TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
            <TableBody>
                { stableSort(data.promos, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => {
                    return (
                        <PromosRow
                            key={`region-${row.id}-row`}
                            rowId={row.id}
                            columns={[
                                { align: 'left', value: row.customer ? row.customer.name : 'All Customers' },
                                { align: 'left', value: row.region ? row.region.name : 'All Regions' },
                                { align: 'left', value: row.rule.name },
                                { align: 'left', value: row.rule.description },
                                { align: 'left', value: row.expiration_date ? dayjs(row.expiration_date).format("MM/DD/YYYY") : 'No Expiration Date'},
                            ]}
                            className={cls.row}
                        />
                    );
                    })}
                </TableBody>
            </Table>
            </div>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={ data.promos.length }
            rowsPerPage={ rowsPerPage }
            page={ page }
            backIconButtonProps={{ 'aria-label': 'Previous Page'}}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>);
    }

};

////////// STYLE ///////////
const useStyles = makeStyles(theme => ({
    tableWrapper: {
      overflowX: 'auto',
    },
    table: {
      minWidth: '400px',
    },
    headTxt: {
      fontSize: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    row: {
      height: 45,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: '#ffffff',
      color: theme.palette.text.primary,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      transition: '0.1s',
      cursor: 'pointer',
    },
  }));
  