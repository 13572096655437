// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import { makeStyles, Grid, Tooltip, TextField, IconButton, Icon, InputAdornment, Typography } from '@material-ui/core';

import { condensedCase } from '../../utils/GeneralUtils';

import { usePlansGroup } from './PlansGroupProvider';

import PlansGroupDriverItem from './PlansGroupDriverItem';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupDriverList({ drivers = [], moves = [], plans = [], regions = [] }) {
  const cls = useStyles();

  const { selectedDriverIds, selectedMoveIds, selectedRegionIds, handleClearDriverIds, getSelectableDrivers } =
    usePlansGroup();

  const [filteredDrivers, setFilteredDrivers] = React.useState([...drivers]);
  const [search, setSearch] = React.useState(``);

  React.useEffect(() => {
    let selectedArray = [...drivers?.filter(d => selectedDriverIds?.includes(d?.id))];
    let placeholderArray = [];
    let filteredArray = getSelectableDrivers(drivers, moves, plans);

    // Set the placeholder drivers array
    if (selectedMoveIds?.length - selectedDriverIds?.length > 0) {
      for (let i = 0; i < selectedMoveIds?.length - selectedDriverIds?.length; i++) {
        placeholderArray.push({ id: `placeholder-${i}` });
      }
    }

    // Filter the drivers array
    if (filteredArray?.length) {
      // Region filter
      if (selectedRegionIds?.length) {
        filteredArray = filteredArray?.filter(d => selectedRegionIds?.includes(d?.region?.id || d?.region_id));
      }

      // Search filter
      if (search?.length) {
        const searchEdit = condensedCase(search);
        filteredArray = filteredArray?.filter(
          d =>
            condensedCase(d?.id)?.toString()?.includes(searchEdit) ||
            condensedCase(d?.display_name || d?.user?.display_name)
              ?.toString()
              ?.includes(searchEdit) ||
            condensedCase(d?.region_name || d?.region?.name)
              ?.toString()
              ?.includes(searchEdit)
        );
      }
    }

    // Set the filtered drivers array
    setFilteredDrivers([...selectedArray, ...placeholderArray, ...filteredArray]);
    // eslint-disable-next-line
  }, [selectedDriverIds, selectedMoveIds, selectedRegionIds, search]);

  return (
    <div className={cls.driverList}>
      <div className={cls.driverListHeader}>
        <div className={cls.driverListHeaderContent}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs>
              <TextField
                fullWidth
                name='search'
                placeholder='Search for a driver...'
                size='small'
                variant='outlined'
                value={search}
                onChange={e => setSearch(e?.target?.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon color='disabled' fontSize='small'>
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='clear input'
                        style={{ display: search?.length ? `block` : `none`, padding: 4, marginRight: -8 }}
                        onClick={() => {
                          setSearch(``);
                        }}
                      >
                        <Icon fontSize='small'>clear</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {selectedDriverIds?.length ? (
              <Grid item>
                <Tooltip title='Clear Selected Drivers'>
                  <IconButton className={cls.clear} onClick={() => handleClearDriverIds()}>
                    <Icon>layers_clear</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        </div>

        <div className={cls.driverListHeaderFade} />
      </div>

      <div className={cls.driverListBody}>
        {filteredDrivers?.length ? (
          filteredDrivers?.map((driver, i) => (
            <React.Fragment key={`plans-group-driver-item-${driver?.id}`}>
              <PlansGroupDriverItem
                driver={driver}
                plan={plans?.find(p => p?.driver_id === driver?.id)}
                region={regions?.find(r => r?.id === driver?.region_id)}
                index={i}
              />

              <div className={cls.driverListBodyDivider} />
            </React.Fragment>
          ))
        ) : (
          <Typography className={cls.notFoundTxt}>No Drivers Found</Typography>
        )}
      </div>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  driverList: {
    zIndex: 100,
    position: 'relative',
    width: 256,
    minHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  driverListHeader: {
    zIndex: 101,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  driverListHeaderContent: {
    maxHeight: 48,
    padding: 8,
    paddingBottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  driverListHeaderFade: {
    width: '100%',
    height: 8,
    background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
  },

  driverListBody: {
    position: 'relative',
  },
  driverListBodyDivider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.dividerStrong,
  },

  clear: {
    padding: 4,
  },

  notFoundTxt: {
    paddingTop: 32,
    paddingBottom: 32,
    lineHeight: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
