//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import Toolbar from '../../components/Toolbar';

import OrganizationAddToolbar from './OrganizationAddToolbar';
import OrganizationAddForm from './OrganizationAddForm';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationAdd(props) {
  const cls = useStyles();

  const { goToPreviousPage, goToOrganizationDetails, goToCustomerAdd } = useTools();

  const initialState = props?.location?.state;

  const [formCategory, setFormCategory] = React.useState(initialState?.category || ``);
  const [formDescription, setFormDescription] = React.useState(initialState?.description || ``);
  const [formName, setFormName] = React.useState(initialState?.name || ``);

  const [isSaving, setIsSaving] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState({});

  const [insertOrganization] = useMutation(INSERT_ORGANIZATION);

  const checkIsFormValid = () => {
    let isValid = true;
    let newValidationErrors = {};

    if (!formCategory) {
      isValid = false;
      newValidationErrors.formCategory = `Category must be selected!`;
    }
    if (!formDescription) {
      isValid = false;
      newValidationErrors.formDescription = `Description is required!`;
    }
    if (!formName || formName?.length < 3) {
      isValid = false;
      newValidationErrors.formName = `Name is required and must be at least 3 characters long!`;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const createOrganization = async () => {
    try {
      const variables = {
        category: formCategory || null,
        config: {},
        description: formDescription || null,
        name: formName || null,
      };

      const res = await insertOrganization({ variables: variables });
      if (res?.data?.insert_organizations?.returning?.[0]) {
        const newOrganization = res?.data?.insert_organizations?.returning?.[0];
        return newOrganization;
      }
    } catch (err) {
      console.error(`Error creating organization:`, err);
      return null;
    }
  };

  const handleCancel = () => {
    goToPreviousPage();
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    const isFormValid = checkIsFormValid();
    if (isFormValid) {
      const org = await createOrganization();
      if (org?.id) goToOrganizationDetails(org?.id);
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  const handleSubmitAndAddACustomer = async () => {
    setIsSaving(true);

    const isFormValid = checkIsFormValid();
    if (isFormValid) {
      const org = await createOrganization();
      if (org?.id) goToCustomerAdd(org?.id);
    } else {
      toast.warning(`Please make sure the form is valid, then resubmit!`);
    }

    setIsSaving(false);
  };

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title={`Add Organization`} back>
        <OrganizationAddToolbar
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onSubmitAndAddACustomer={handleSubmitAndAddACustomer}
          isSaving={isSaving}
        />
      </Toolbar>

      <Spacer size='xl' />

      <OrganizationAddForm
        formCategory={formCategory}
        onFormCategoryChange={setFormCategory}
        formDescription={formDescription}
        onFormDescriptionChange={setFormDescription}
        formName={formName}
        onFormNameChange={setFormName}
        isSaving={isSaving}
        validationErrors={validationErrors}
      />

      <Spacer size='xl' />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: theme.palette.background.paper,
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const INSERT_ORGANIZATION = gql`
  mutation admin_insertOrganization($category: String!, $config: jsonb!, $description: String!, $name: String!) {
    insert_organizations(objects: { category: $category, config: $config, description: $description, name: $name }) {
      affected_rows
      returning {
        id
        category
        config
        description
        name
      }
    }
  }
`;
