import React from 'react';
import { LinearProgress } from '@material-ui/core';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import BatchJobTable from './BatchJobTable';

export default function BatchJobTableWrapper({ loading, error, batchJobs }) {
  const [filteredBatchJobs, setFilteredBatchJobs] = React.useState(batchJobs || []);

  React.useEffect(() => {
    setFilteredBatchJobs(batchJobs);
  }, [batchJobs]);

  if (error) console.error(`Failed to fetch batch jobs:`, error);
  return (
    <>
      {loading ? <LinearProgress /> : null}
      {error ? <DefaultErrorFallback message={`Failed to fetch batch jobs`} /> : null}
      {!loading && !error && !filteredBatchJobs?.length ? (
        <DefaultEmptyFallback message={`No Batch Jobs Found with Selected Filters`} />
      ) : !loading && !error && filteredBatchJobs?.length ? (
        <BatchJobTable batchJobs={filteredBatchJobs} />
      ) : null}
    </>
  );
}
