import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, Icon, Typography } from '@material-ui/core';

import GoogleMap from 'google-map-react';
import Geocode from 'react-geocode';
import { toast } from 'react-toastify';
import theme from '../../utils/theme';
import { styles } from '../../utils/googleMapOptions';

const log = false;

////////// COMPONENT //////////
export default function MapBounds(props) {
  const cls = useStyles();

  // var googleMaps
  var directionsService;
  var directionsRenderer;

  const [maps, setMaps] = useState(null);
  const [map, setMap] = useState(null);
  const [service, setService] = useState(null);
  const [renderer, setRenderer] = useState(null);

  //default center is Richmond's coordinates
  const {
    center = { lat: 37.5, lng: -77.4 },
    locations,
    selectedLocation,
    setSelectedLocation,
    setSidebarState,
    createMode,
    setCreateMode,
    handleLocationAddEditModalOpen,
    setBounds,
    // selectedLane,
    origin,
    destination,
    laneMode,
  } = props;

  // render route when lane selected
  useEffect(() => {
    log &&
      console.log(
        'route effect',
        maps ? 'maps ready' : 'maps is null',
        map ? 'map ready' : 'map is null',
        service ? 'service ready' : 'service is null',
        renderer ? 'renderer ready' : 'renderer is null',
        origin ? 'origin ready' : 'origin is null',
        destination ? 'destination ready' : 'destination is null'
      );
    if (map && service && renderer && origin && destination) {
      log && console.log('Render Map with Lane');
      calcRoute();
    } else if (maps && (origin === null || destination === null)) {
      log && console.log('Render Map with no Lane');
      renderer && renderer.setMap(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps, map, service, renderer, origin, destination]);

  //Clicking Map will open create location modal if in createMode has been turned on
  const handleMapClick = async info => {
    log && console.log(`Map Clicked:`, info);
    if (createMode) {
      setCreateMode(false);
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.fromLatLng(info.lat, info.lng)
        .then(res => {
          const address = res.results[0].formatted_address;
          const placeId = res.results[0].place_id;
          log && console.log(`Found address and place_id from lat/lng:`, { address, placeId });
          handleLocationAddEditModalOpen({
            type: `customer`,
            address: address,
            latitude: info.lat,
            longitude: info.lng,
            place_id: placeId,
          });
        })
        .catch(err => {
          console.log(`Error creating new location from lat/lng:`, err);
          //TOAST
        });
    }
  };

  //Clicking markers will select and deselect them
  const handleMarkerClick = location => {
    if (selectedLocation && selectedLocation.id === location.id) {
      if (!laneMode) {
        setSidebarState(`index`);
        setSelectedLocation(null);
      } else {
        setSidebarState(`lane`);
        setSelectedLocation(null);
      }
    } else {
      setSelectedLocation(location);
      setSidebarState(`location`);
    }
  };

  const LocationMarker = ({ location, icon, classType }) => {
    return (
      <>
        <div
          onClick={() => handleMarkerClick(location)}
          className={clsx(cls.marker, {
            [cls.markerHover]: classType === 'hover',
            [cls.activeMarker]: classType === 'selected',
            [cls.laneLocMarker]: classType === 'laneLoc',
          })}
        >
          <Icon className={cls.icon}>{icon}</Icon>
          <div className={cls.bubbleBox}>
            <Typography className={cls.bubbleNameTxt}>{location.name || `No name provided`}</Typography>
            <Typography className={cls.bubbleAddressTxt}>{location.address || `No address provided`}</Typography>
          </div>
        </div>
      </>
    );
  };

  const handleMarkerIcon = location => {
    let icon = 'place';
    if (origin && origin.id === location.id) {
      icon = 'gps_not_fixed';
    } else if (destination && destination.id === location.id) {
      icon = 'gps_fixed';
    } else if (location.type === 'customer') {
      icon = 'place';
    } else if (location.type === 'consumer business') {
      icon = 'store';
    } else if (location.type === 'consumer residential') {
      icon = 'home';
    }
    return icon;
  };
  //Note: the order here is important- selected laneLoc will be class 'selected' not 'laneLoc'
  const handleMarkerClass = location => {
    let style = undefined;
    if (location.active === 0) {
      style = 'removedLoc';
    } else if (origin && origin.id === location.id) {
      style = 'laneLoc';
    } else if (destination && destination.id === location.id) {
      style = 'laneLoc';
    } else if (selectedLocation && selectedLocation.id === location.id) {
      style = 'selected';
    } else {
      style = 'hover';
    }
    return style;
  };

  const handleApiLoaded = (map, maps) => {
    //Set Map object
    setMaps(maps);
    setMap(map);
    //Set objects for rendering routes
    directionsService = new maps.DirectionsService();
    directionsRenderer = new maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: theme.palette.info.main,
      },
      suppressMarkers: true,
      provideRouteAlternatives: true,
    });
    directionsRenderer.setMap(map);
    setService(directionsService);
    setRenderer(directionsRenderer);
    //Listener for map bounds
    maps.event.addListener(map, 'bounds_changed', () => {
      var bounds = map.getBounds();
      var latLngBounds = bounds.toJSON();
      setBounds(latLngBounds);
    });
  };

  const calcRoute = () => {
    if (maps && map) {
      renderer.setMap(map);
      console.log('calcRoute');
      var request = {
        origin: origin.address,
        destination: destination.address,
        travelMode: 'DRIVING',
      };
      service.route(request, function (response, status) {
        if (status === 'OK') {
          console.log('setting directions...');
          renderer.setDirections(response);
        }
      });
    } else {
      console.error('Missing the origin or destination', origin, destination);
      toast.error('Cannot Calculate Lane: MIssing Pickup or Delivery Location');
    }
  };

  return (
    //Important! Will only render if container height is set inline
    <div className={cls.map} style={{ height: '100vh', width: '100%' }}>
      {createMode ? <div className={cls.createModeBanner}>Click Anywhere on the Map to Create a Location</div> : null}
      <GoogleMap
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        //TODO: change these to props, and find a way to set these from our DB
        onClick={info => {
          handleMapClick(info);
        }}
        center={center}
        // defaultCenter={{ lat: 37.5407, lng: 77.4360 }}
        defaultZoom={9}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={{
          styles: styles,
        }}
        setOptions={{ clickableIcons: false }}
      >
        {locations.map(location => (
          <LocationMarker
            key={`location-marker-${location.id}`}
            lat={location.latitude}
            lng={location.longitude}
            location={location}
            classType={handleMarkerClass(location)}
            icon={handleMarkerIcon(location)}
          />
        ))}
      </GoogleMap>
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  map: {
    zIndex: 1,
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.background.dark,
    cursor: true ? 'pointer' : 'help',
  },
  createModeBanner: {
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: '20px',
    color: theme.palette.text.contrast,
    height: '24px',
  },
  //Basic Marker styling used for all markers
  marker: {
    zIndex: 2,
    position: 'absolute',
    transformOrigin: '50% 85%',
    transform: 'translate(-50%, -85%)',
    color: theme.palette.primary.main,
    transition: '0.2s',
    cursor: 'pointer',
    '& $icon': {
      fontSize: 24,
    },
    '& $bubbleBox': {
      display: 'none',
    },
  },
  //Adds hover css (currently used on markers that are not selected/origin/destinatoin/etc)
  markerHover: {
    '&:hover': {
      zIndex: 4,
      color: theme.palette.primary.light,
      transform: 'translate(-50%, -85%)',
      '& $icon': {
        fontSize: 48,
      },
      '& $bubbleBox': {
        display: 'block',
      },
    },
  },
  //for selected location
  activeMarker: {
    zIndex: 3,
    color: theme.palette.info.main,
    '& $icon': {
      fontSize: 48,
    },
    '& $bubbleBox': {
      display: 'block',
    },
  },
  //for selected location
  removedMarker: {
    display: 'none',
  },
  //for origin and destination of lane
  laneLocMarker: {
    zIndex: 6,
    color: theme.palette.info.light,
  },
  icon: {
    filter: 'drop-shadow(1px 1px 1px #00000064)',
    transition: '0.2s',
  },
  bubbleBox: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: theme.spacing(7),
    minWidth: '180px',
    maxWidth: '180px',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 1px 2px #00000064',
    backgroundColor: '#fff',
    transition: '0.2s',
  },
  bubbleNameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  bubbleAddressTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));
