import { gql } from '@apollo/client';

const GET_RULES = gql`
    query get_rules {
        businessrules(where: {active: {_eq: true}, ruletype: {name: {_eq: "modifier"}}}) {
        id
        name
        ruletype {
            name
        }
        description
        }
    }
`;

export { GET_RULES };