//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, Typography, Chip, Button } from '@material-ui/core';
import { ModalContent, ModalHeader, ModalFooter, ModalAction } from '../../components/ModalComponents';
import Spacer from '../../components/Spacer';
import sdk from '@hopdrive/sdk'

//////////////////////// COMPONENT ////////////////////////

function MoveVehicleWeightModalContent(props) {
  const cls = useStyles();

  const { handleClose, input } = props;

  const [weightInput, setWeightInput] = React.useState(null);
  const [cleansedWeight, setCleansedWeight] = React.useState(null);
  const [alertText, setAlertText] = React.useState('Weight entered must be between 400 and 33,000 lbs.');
  const [disableSave, setDisableSave] = React.useState(false);
  const [weightClass, setWeightClass] = React.useState(null);
  const [dispatcherProvidedWeightClass, setDispatcherProvidedWeightClass] = React.useState(null);
  const [customerProvidedWeightClass, setCustomerProvidedWeightClass] = React.useState(null);
  const [driverProvidedWeightClass, setDriverProvidedWeightClass] = React.useState(null);
  const [dispatcherProvidedWeight, setDispatcherProvidedWeight] = React.useState(null);
  const [customerProvidedWeight, setCustomerProvidedWeight] = React.useState(null);
  const [driverProvidedWeight, setDriverProvidedWeight] = React.useState(null);

  const getWeightClass = weight => {
    if (weight) {
      const moveWeightClassInt = sdk.utilities.getVehicleClassNumberFromWeight(weight);
      return moveWeightClassInt
    } else return null
  };

  React.useEffect(() => {
    if (input && input.config && input.config.dispatcherProvidedWeight) {
      let weightClass = getWeightClass(input.config.dispatcherProvidedWeight);
      setDispatcherProvidedWeightClass(weightClass);
      setDispatcherProvidedWeight(input.config.dispatcherProvidedWeight);
    }

    if (input && input.weight) {
      let weightClass = getWeightClass(input.weight);
      setCustomerProvidedWeight(input.weight)
      setCustomerProvidedWeightClass(weightClass);
    }

    let driverWeight;
    let driverWeightClass;
    if (input && input.pickupWorkflow && input.pickupWorkflow['vehicle-weight']) {
      driverWeight = input.pickupWorkflow['vehicle-weight'];
    } else if (input && input.deliveryWorkflow && input.deliveryWorkflow['vehicle-weight']) {
      driverWeight = input.deliveryWorkflow['vehicle-weight'];
    }
    setDriverProvidedWeight(driverWeight);
    driverWeightClass = getWeightClass(driverWeight);
    setDriverProvidedWeightClass(driverWeightClass);
  }, [input]);

  React.useEffect(() => {
    let newWeightClass
    if (cleansedWeight) {
      if (cleansedWeight.length >= 3) {
        newWeightClass = getWeightClass(cleansedWeight);
        setWeightClass(newWeightClass);
      }

      if (!newWeightClass || cleansedWeight < 400 || cleansedWeight > 33000) {
        setAlertText('Weight entered must be between 400 and 33,000 lbs.');
        setDisableSave(true);
      } else if (newWeightClass > 5 && cleansedWeight > 19999) { // (8/8/24) Class 5 ends at 19500 lbs, but insurance covers us through 19999 lbs. This check will need to be updated as weight class coverage changes
        setAlertText(`Note: We are not insured to move cars above 19,999 lbs.`);
        setDisableSave(false);
      } else {
        setDisableSave(false);
        setAlertText(null);
      }
    } else {
      setWeightClass(null);
      setDisableSave(true);
    }
  }, [cleansedWeight]);

  React.useEffect(() => {
    if (weightInput && /[^0-9]/.test(weightInput)) {
      const formattedWeight = weightInput.replace(/[^0-9]/g, '');
      setCleansedWeight(formattedWeight);
    } else if (weightInput && !/[^0-9]/.test(weightInput)) {
      setCleansedWeight(weightInput);
    } else setCleansedWeight(null);
  }, [weightInput]);

  const handleSubmit = async () => {
    let updatedConfig = input.config;
    let eventlogMetadata
    let oldWeight

    if (dispatcherProvidedWeight) {
      oldWeight = dispatcherProvidedWeight
    } else if (driverProvidedWeight) {
      oldWeight = driverProvidedWeight
    } else if (customerProvidedWeight) {
      oldWeight = customerProvidedWeight
    } else {
      oldWeight = null
    }

    eventlogMetadata = {old: oldWeight, new: cleansedWeight}

    if (!input.config) {
      updatedConfig = { dispatcherProvidedWeight: cleansedWeight };
    } else updatedConfig['dispatcherProvidedWeight'] = cleansedWeight;

    handleClose({
      weight: cleansedWeight,
      config: updatedConfig,
      eventlogMetadata: eventlogMetadata
    });
  };

  return (
    <>
      <ModalHeader handleClose={handleClose}>Edit Vehicle Weight</ModalHeader>
      <ModalContent
        subtitle={`Edit the weight of the current move's vehicle. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        disableDividers={true}
      >
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <Typography className={cls.displayTxt}>
              New Weight:&nbsp;&nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              placeholder='Enter weight in lbs...'
              variant='outlined'
              size='small'
              value={weightInput}
              onChange={e => setWeightInput(e.target.value)}
            />
          </Grid>
          {weightClass ? (
            <Grid item xs={4}>
              <Chip
                label={`Class ${weightClass}`}
                variant='outlined'
                size='small'
                color='primary'
                style={{ minWidth: 'auto', maxHeight: '20px', fontSize: '11px' }}
              />
            </Grid>
          ) : (<Grid item xs={4}></Grid>)}
        </Grid>
        <Spacer />
        <Spacer />
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ alignContent: 'center' }}>
            <Typography className={cls.keyTxt}>Dispatcher&nbsp;Provided&nbsp;Weight:&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.valTxt}>
              {dispatcherProvidedWeight ? dispatcherProvidedWeight : `-`}&nbsp;&nbsp;
              {dispatcherProvidedWeight ? (
                <Chip
                  label={`Class ${dispatcherProvidedWeightClass}`}
                  variant='outlined'
                  size='small'
                  color='primary'
                  style={{ minWidth: 'auto', maxHeight: '20px', fontSize: '11px' }}
                />
              ) : null}
              {dispatcherProvidedWeight &&
              (!weightInput ||
                (weightInput && dispatcherProvidedWeight && weightInput !== dispatcherProvidedWeight)) ? (
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  onClick={() => setWeightInput(dispatcherProvidedWeight)}
                  style={{ minWidth: 'auto', padding: '4px 8px', fontSize: '10px', marginLeft: '8px' }}
                >
                  Restore
                </Button>
              ) : null}
            </Typography>
          </Grid>
          <div className={cls.lineBreak} />
          <Grid item xs={6} alignItems='center' style={{ textAlign: 'center' }}>
            <Typography className={cls.keyTxt}>Driver&nbsp;Provided&nbsp;Weight:&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.valTxt}>
              {driverProvidedWeight ? driverProvidedWeight : `-`}&nbsp;&nbsp;
              {driverProvidedWeight ? (
                <Chip
                  label={`Class ${driverProvidedWeightClass}`}
                  variant='outlined'
                  size='small'
                  color='primary'
                  style={{ minWidth: 'auto', maxHeight: '20px', fontSize: '11px' }}
                />
              ) : null}
              {driverProvidedWeight && (!weightInput || (weightInput && weightInput !== driverProvidedWeight)) ? (
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  onClick={() => setWeightInput(driverProvidedWeight)}
                  style={{ minWidth: 'auto', padding: '4px 8px', fontSize: '10px', marginLeft: '8px' }}
                >
                  Restore
                </Button>
              ) : null}
            </Typography>
          </Grid>
          <div className={cls.lineBreak} />
          <Grid item xs={6}>
            <Typography className={cls.keyTxt}>Customer&nbsp;Provided&nbsp;Weight:&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.valTxt}>
              {input.weight ? input.weight : `-`}&nbsp;&nbsp;
              {input.weight ? (
                <Chip
                  label={`Class ${customerProvidedWeightClass}`}
                  variant='outlined'
                  size='small'
                  color='primary'
                  style={{ minWidth: 'auto', maxHeight: '20px', fontSize: '11px' }}
                />
              ) : null}
              {input.weight && (!weightInput || (input.weight && weightInput !== input.weight)) ? (
                <Button
                  size='small'
                  color='primary'
                  variant='contained'
                  onClick={() => setWeightInput(input.weight)}
                  style={{ minWidth: 'auto', padding: '4px 8px', fontSize: '10px', marginLeft: '8px' }}
                >
                  Restore
                </Button>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
      </ModalContent>

      <ModalFooter>
        {alertText ? (
          <Typography className={cls.displayTxt} style={{ color: '#f44232' }}>
            {alertText}
          </Typography>
        ) : null}
        <ModalAction disabled={disableSave} onClick={() => handleSubmit()} color='primary'>
          Submit
        </ModalAction>
        <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  displayTxt: {
    fontSize: 15,
    fontWeight: 600,
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    background: theme.palette.divider,
    marginTop: '2px',
    marginBottom: '4px',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveVehicleWeightModalContent;
