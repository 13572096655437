import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Container, Typography, Grid, TextField, MenuItem, Tooltip, Button, Icon } from '@material-ui/core';
import RegionSelect from '../../components/RegionSelect';
import PayableDrivers from './PayableDrivers';
import PayPeriods from './PayPeriods';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

const getPayPeriodId = date => {
  // Rob 20201231 - The weekyear produced here by moment does not always
  //  match the weekyear produced by the Postgres database
  //
  //  In our driver pay sql view we use the following SQL to determine the
  //  weekyear then aggregate driver pay around that weekyear id.
  //
  //    to_char(testDate + INTERVAL '1 day', 'IYYY-IW')
  //
  //  For Sunday 2020/12/27, the above SQL returns 2020-53 while if left
  //  to it's default settings compare that to what moment() calcs from
  //  the following Javascript using moment's week() and weekYear().
  //
  //    `${moment().weekYear()}-${String(moment().week()).padStart(2, '0')}`
  //
  //  For the same Sunday 2020/12/27, the above moment() returns 2021-01.
  //  The reason for this is because the day weeks start with and the day
  //  years start with in the default moment local configuration.
  //
  // From the postgres docs:
  //   The number of the ISO 8601 week-numbering week of the year. By
  //   definition, ISO weeks start on Mondays and the first week of a year
  //   contains January 4 of that year. In other words, the first Thursday of
  //   a year is in week 1 of that year.
  //
  // From the moment docs:
  //   For example, in the United States, Sunday is the first day of the week.
  //   The week with January 1st in it is the first week of the year. In France,
  //   Monday is the first day of the week, and the week with January 4th is
  //   the first week of the year.
  //
  // The solution ended up being that the locale needs to be set globally for moment
  //  so we move the moment.locale call out to the global scope of the module and
  //  then everything works. What it's doing in this call is to set the starting
  //  day of the week to Sunday and the starting day of the year to Jan 4th to align
  //  with how Postgres views the date world.
  //
  // Here are some resources that helped me uncover the solution:
  //  https://stackoverflow.com/questions/18875649/starting-the-week-on-monday-with-isoweekday
  //  https://momentjs.com/docs/#/i18n/instance-locale/
  //  https://momentjs.com/docs/#/customization/dow-doy/
  return `${date.weekYear()}-${String(date.week()).padStart(2, '0')}`;
};

moment.locale('en', { week: { dow: 0, doy: 4 } });

export default function DriverPay(props) {
  const cls = useStyles();

  const [sunday, setSunday] = useState(moment().startOf('week').subtract(7, 'days'));
  const [payPeriod, setPayPeriod] = useState(getPayPeriodId(moment()));

  const [status, setStatus] = useState(null);
  const localDriverId = parseInt(localStorage.getItem(`driverId`));
  const [driverId, setDriverId] = useState(localDriverId || null);
  const localRegionId = parseInt(localStorage.getItem(`regionId`));
  const [regionId, setRegionId] = useState(localRegionId || null);

  useEffect(() => {
    localStorage.setItem(`driverId`, driverId);
  }, [driverId]);

  useEffect(() => {
    localStorage.setItem(`regionId`, regionId);
  }, [regionId]);

  useEffect(() => {
    setPayPeriod(getPayPeriodId(sunday.clone()));
  }, [sunday]);

  const handleStatusChange = event => {
    if (event.target.value !== `any`) setStatus(event.target.value);
    else setStatus(null);
  };

  const handleRegionChange = event => {
    if (event.target.value > 0) setRegionId(event.target.value);
    else setRegionId(null);
  };

  const handleDriverChange = event => {
    if (event.target.value > 0) setDriverId(event.target.value);
    else setDriverId(null);
  };

  const handlePayPeriodChange = event => {
    if (event.target.value === 'this-week') {
      setSunday(moment().startOf('week'));
    }
    if (event.target.value === 'last-week') {
      setSunday(moment().startOf('week').subtract(1, 'week'));
    }
    if (event.target.value === 'next-week') {
      setSunday(moment().startOf('week').add(1, 'week'));
    }
    if (event.target.value === 'two-weeks-ago') {
      setSunday(moment().startOf('week').subtract(2, 'weeks'));
    }
    if (event.target.value === 'this-week-last-year') {
      setSunday(moment().startOf('week').subtract(52, 'weeks'));
    }
    if (event.target.value === 'two-weeks-out') {
      setSunday(moment().startOf('week').add(2, 'weeks'));
    }
  };

  const getPayPeriodLabel = date => {
    if (date.format() === moment().startOf('week').format()) {
      return 'this-week';
    }
    if (date.format() === moment().startOf('week').subtract(1, 'week').format()) {
      return 'last-week';
    }
    if (date.format() === moment().startOf('week').add(1, 'week').format()) {
      return 'next-week';
    }
    if (date.format() === moment().startOf('week').subtract(2, 'weeks').format()) {
      return 'two-weeks-ago';
    }
    if (date.format() === moment().startOf('week').subtract(52, 'weeks').format()) {
      return 'this-week-last-year';
    }
    if (date.format() === moment().startOf('week').add(2, 'weeks').format()) {
      return 'two-weeks-out';
    }
    return null;
  };

  const getDateRangeString = (start, end = null) => {
    if (end) {
      return (
        <Typography fontWeight={800} variant='body1'>
          {start.format('dddd')} {start.format('MMM D')} through {end.format('dddd')} {end.format('MMM D')}{' '}
          <em>({getPayPeriodId(start)})</em>{' '}
        </Typography>
      );
    } else {
      return <em>&nbsp;{`(starting on ${start.format('dddd MMM D')})`}</em>;
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING DRIVER PAY' />}>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          {/* Driver Pay Toolbar */}
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={6}>
              <Typography className={cls.headTxt}>Driver Pay</Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography variant='h6' style={{ textAlign: 'right' }} gutterBottom>
                {getDateRangeString(sunday.clone(), sunday.clone().endOf('week'))}
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Tooltip
                    title={`${sunday.clone().subtract(1, 'week').format('MMM D')} - ${sunday
                      .clone()
                      .subtract(1, 'week')
                      .endOf('week')
                      .format('MMM D')}`}
                  >
                    <Button
                      fullWidth
                      style={{ height: '100%' }}
                      variant='outlined'
                      color='secondary'
                      startIcon={<Icon>keyboard_arrow_left</Icon>}
                      onClick={() => {
                        setSunday(sunday.clone().subtract(1, 'week'));
                      }}
                    >
                      Prev
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={8}>
                  <Tooltip title={`Select a predefined pay period`}>
                    <TextField
                      select
                      fullWidth
                      color='primary'
                      label='Pay Period'
                      placeholder='Select a pay period...'
                      variant='outlined'
                      margin='dense'
                      value={getPayPeriodLabel(sunday.clone()) || `custom`}
                      onChange={handlePayPeriodChange}
                      className={cls.input}
                    >
                      <MenuItem value={`this-week-last-year`}>
                        This Week Last Year {getDateRangeString(moment().startOf('week').subtract(52, 'weeks'))}
                      </MenuItem>
                      <MenuItem value={`two-weeks-ago`}>
                        Two Weeks Ago {getDateRangeString(moment().startOf('week').subtract(2, 'weeks'))}
                      </MenuItem>
                      <MenuItem value={`last-week`}>
                        Last Week {getDateRangeString(moment().startOf('week').subtract(1, 'week'))}
                      </MenuItem>
                      <MenuItem value={`this-week`}>
                        This Week {getDateRangeString(moment().startOf('week').subtract(0, 'weeks'))}
                      </MenuItem>
                      <MenuItem value={`next-week`}>
                        Next Week {getDateRangeString(moment().startOf('week').add(1, 'weeks'))}
                      </MenuItem>
                      <MenuItem value={`two-weeks-out`}>
                        Two Weeks Out {getDateRangeString(moment().startOf('week').add(2, 'weeks'))}
                      </MenuItem>
                      <MenuItem value={`custom`} style={{'display': 'none'}}>Custom</MenuItem>
                    </TextField>
                  </Tooltip>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip
                    title={`${sunday.clone().add(1, 'week').format('MMM D')} - ${sunday
                      .clone()
                      .add(1, 'week')
                      .endOf('week')
                      .format('MMM D')}`}
                  >
                    <Button
                      fullWidth
                      style={{ height: '100%' }}
                      variant='outlined'
                      color='primary'
                      endIcon={<Icon>keyboard_arrow_right</Icon>}
                      onClick={() => {
                        setSunday(sunday.clone().add(1, 'week'));
                      }}
                    >
                      Next
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <RegionSelect allowAll label='Region Filter' value={regionId || 0} onChange={handleRegionChange} />
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Status Filter'
                placeholder='Select a status filter...'
                variant='outlined'
                margin='dense'
                value={status || `any`}
                className={cls.input}
                onChange={handleStatusChange}
              >
                <MenuItem value={`any`}>Any Status</MenuItem>
                <MenuItem value={`paid`}>Paid</MenuItem>
                <MenuItem value={`unpaid`}>Unpaid</MenuItem>
                <MenuItem value={`partial`}>Partial</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <PayableDrivers
                regionId={regionId}
                payPeriod={payPeriod}
                status={status}
                driverId={driverId}
                handleDriverChange={handleDriverChange}
                sunday={sunday}
                cls={cls}
              />
            </Grid>
          </Grid>

          <div className={cls.break} />
          {/* Driver Pay Table */}
          <PayPeriods
            regionId={regionId}
            payPeriod={payPeriod}
            status={status}
            driverId={driverId}
            sunday={sunday}
            cls={cls}
          />
        </Container>
      </div>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
