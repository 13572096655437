// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';

import { usePlansGroup } from './PlansGroupProvider';

import StaticDriverTile from '../../components/DriverComponents/StaticDriverTile';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupDriverItem({ driver, plan, region, index }) {
  const { selectedDriverIds, handleSelectDriverId } = usePlansGroup();

  return (
    <StaticDriverTile
      driver={driver}
      plan={plan}
      region={region}
      isSelectable
      isSelected={selectedDriverIds?.includes(driver?.id)}
      onSelected={handleSelectDriverId}
      isPlaceholder={typeof driver?.id === 'string'}
      index={index}
    />
  );
}
