import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { makeStyles, Container, Button, FormControl, InputLabel, Select, TextField, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useLazyQuery } from '@apollo/client';
import { useData } from '../../providers/DataProvider';
import { toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

import { GET_PROMO, UPDATE_PROMO, ADD_PROMO, GET_PROMO_TYPES } from './gql';

import Loading from '../../components/Loading';
import CustomerSelect from '../../components/CustomerSelect';
import RegionSelect from '../../components/RegionSelect';
import PromoSelect from '../../components/PromoSelect';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';

const log = false;

export default function PromosDetails(props) {
    const ctx = useData();
    const cls = useStyles()

    const type = props.match.params.id;

    // HOOKS ////////////////////////////////////////////////////////////////////////////////
    const [ getPromo, { loading, error, data: promoData } ] = useLazyQuery(GET_PROMO, {
        variables: { id: type }
    });

    const [ getPromoTypes, { data: typeData } ] = useLazyQuery(GET_PROMO_TYPES)

    // STATES ////////////////////////////////////////////////////////////////////////////////
    const [ promoObj, setPromoObj ] = useState({
        id: type !== 'add' ? type : 0,
        customer: 0,
        region: 0,
        activationDate: dayjs().format('YYYY-MM-DD'),
        expirationDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
        config: []
    });

    const [ ruleObj, setRuleObj ] = useState({
        id: 0,
        name: 0,
        rules: [],
        description: 0,
        schema: [],
    });

    // LOGIC ////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (type !== 'add') getPromo()
        else getPromoTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ type ]);

    useEffect(() => {
        if (promoData && promoData.promos) { 
            let promo = promoData.promos[0];
            let rule = promoData.promos[0].rule;  

            log && console.log("Setting Promo Object", promo)
            setPromoObj({
                id: promo.id,
                customer: promo.customer_id,
                region: promo.region_id,
                activationDate: dayjs(promo.activation_date).add(1, 'day').format('YYYY-MM-DD'),
                expirationDate: dayjs(promo.expiration_date).add(1, 'day').format('YYYY-MM-DD'),
                config: promo.config,
            });  

            log && console.log("Setting Rule Object", rule)
            setRuleObj({
                id: rule.id,
                name: rule.name,
                description: rule.description,
                schema: rule.jsonb,
            })
        }

        if (typeData && typeData.businessrules) setRuleObj({ ...ruleObj, rules: typeData.businessrules })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ promoData, typeData ]);

    useEffect(() => {
        if (ruleObj.schema) createConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ruleObj ])

    const formatLabel = (string) => {
        let formattedString = string.replace(/_/g, " ");
        formattedString.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        return formattedString
    };

    const handlePromoTypeSelect = event => {
        log && console.log('handlePromoTypeSelect', event.target.value)
        const rules = ruleObj.rules ?? '';

        rules.forEach(rule => {
            if (event.target.value === rule.id) {
                log && console.log(`Setting rule as:`, rule)
                setRuleObj({ 
                    ...ruleObj, 
                    id: event.target.value,
                    description: rule.description,
                    schema: rule.jsonb ?? []
                });
            }
        })
    };

    const handleDateChange = (name, event) => {
        log && console.log("Setting data....", name, event);

        if (name === 'activation') { 
            setPromoObj({ ...promoObj, activationDate: dayjs(event).format('YYYY-MM-DD')}) 
        }
        else if (name === 'expiration') {
            setPromoObj({ ...promoObj, expirationDate: dayjs(event).format('YYYY-MM-DD')}) 
        }
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setPromoObj({ ...promoObj, [name.toLowerCase()]: value });
    };

    const createConfig = () => {
        let newConfig = {};
        ruleObj.schema.forEach(item => {
            newConfig[item.name] = item.default
        });
        log && console.log('newConfig', newConfig)

    }
    const handleConfigChange = async (name, event) => {
        let configClone = Object.assign({}, promoObj.config)
        let value = event.target.value ?? '';
        log && console.log('configClone', configClone)

        configClone[name] = Number(value);
        setPromoObj({ ...promoObj, config: configClone})
    }

    const handleConfigBoolChange = (name, event) => {
        let configClone = Object.assign({}, promoObj.config)
        log && console.log('configClone', configClone)
        let value = event.target.value === "null" ? null : Boolean(event.target.value)

        configClone[name] = value
        setPromoObj({ ...promoObj, config: configClone})
    }

    const updatePromo = async () => {
        ctx.apolloClient.mutate({
            mutation: UPDATE_PROMO,
            variables: {
                id: promoObj.id,
                activation_date: promoObj.activationDate || null,
                config: promoObj.config,
                customer_id: promoObj.customer || null,
                expiration_date: promoObj.expirationDate || null,
                region_id: promoObj.region || null,
                rule_id: ruleObj.id
            }
        }).then(res => {
            log && console.log("PROMO UPDATED ---", res.data)
            toast.success(`Promo updated`);
        }
        ).catch(err => {
            console.log('Error updating promo');
            toast.error(`Failed to update Promo data into Hasura: ${err.toString()}`);
        })
    };

    const addPromo = async () => {
        ctx.apolloClient.mutate({
            mutation: ADD_PROMO,
                variables: {
                    activation_date: promoObj.activationDate || null,
                config: promoObj.config,
                customer_id: promoObj.customer || null,
                expiration_date: promoObj.expirationDate || null,
                region_id: promoObj.region || null,
                rule_id: ruleObj.id
            }
        }).then(res => {
            log && console.log("PROMO ADDED----", res.data);
            toast.success("Promo Added");
            props.history.push({ pathname: '/promos/'});

        })
    }

    // RENDER /////////////////////////////////////////////////////////////////////////////
    if (loading) return <Loading fixed />
    else if (error) {
        console.log("Error Fetching Promo:", error);
        Sentry.captureException(error);
        return <DefaultErrorFallback message="ERROR FETCHING PROMO"/>
    } else if ((promoData) || type ==='add') {
        log && type !== 'add' && console.log("Promo Fetched:", promoData)
        const promo = promoData && promoData.promos ? promoData.promos[0] : {};
        
        return(<div className={ cls.root }>
            <Container maxWidth="lg">
                <Grid container direction="column">
                <Typography style={{ marginLeft: "50px" }} variant={'h6'} >
                    { type === 'add' ? 'Add' : 'Edit' } Promo
                </Typography>
                { type === 'add' ?
                    (<Grid container direction="row">
                        <Grid item xs={6}>
                            <FormControl variant="outlined" className={cls.selectInput}>
                                <PromoSelect 
                                    onChange={handlePromoTypeSelect}
                                    value={ ruleObj.id }
                                /> 
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={cls.selectInput}>
                                <InputLabel>{ruleObj.description ? "Promo Description" : "Select a Promo"}</InputLabel>
                                <div>{ ruleObj.description || "" }</div>
                            </div>
                        </Grid>
                    </Grid>)
                    :
                    (<Grid container direction="row">
                        <Grid item xs={6}>
                            <div className={cls.selectInput}>
                                <InputLabel>Promo Name</InputLabel>
                                <div>{ ruleObj.name }</div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={cls.selectInput}>
                                <InputLabel>Promo Description</InputLabel>
                                <div>{ ruleObj.description }</div>
                            </div>
                        </Grid>
                    </Grid>)
                }
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={cls.selectInput}>
                            <CustomerSelect item xs={6} md={5}
                                value={ promoObj.customer }
                                onChange={ handleInputChange }
                                selectAllItem={true}
                            />
                        </FormControl>
                    </Grid>

                    {/* add name and description fields */}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" className={cls.selectInput}>
                            <RegionSelect 
                                allowAll
                                value={ promoObj.region }
                                onChange={ handleInputChange } 
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item container direction="row" justifyContent="space-evenly">
                        <Grid item md={4} sm={12}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                className={cls.date}
                                style={{ marginRight: '6px' }}
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="dense"
                                id="activationDate"
                                label="Start Date"
                                value={ dayjs(promoObj.activationDate).format() }
                                onChange={event => handleDateChange('activation', event)}
                                InputProps={{
                                    classes: {
                                        input: cls.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change start date',
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={12}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                className={cls.date}
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="dense"
                                id="expirationDate"
                                label="End Date"
                                value={ dayjs(promoObj.expirationDate).format() }
                                onChange={event => handleDateChange('expiration', event)}
                                InputProps={{
                                    classes: {
                                        input: cls.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change end date',
                                }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
                <br />
                <br />
                <Grid item container direction="row" justifyContent="flex-start">
                    { promo && promo.config && promo.config.length > 0 ? 
                        Object.keys(promo.config).map(function(key, index) {
                            const desc = ruleObj.schema.find(item => item.name === key);
                            return(
                                promo ?
                                    <Grid item xs={6} key={`${key}-grid`}>
                                        <FormControl className={cls.inputField} key={`${key}-control`}>
                                            <TextField
                                                key={key}
                                                type='number'
                                                label={formatLabel(key)}
                                                defaultValue={promo.config[key]}
                                                onChange={(event) => handleConfigChange(key, event)}
                                            />
                                        </FormControl>
                                        <div key={`${key}-description`} className={cls.inputDescription}>{desc ? desc.description : ''}</div>
                                    </Grid>
                                    :
                                    <Grid item xs={6}  key={`${key}-grid`}>
                                        <FormControl className={cls.booleanSelect}  key={`${key}-control`}>
                                            <InputLabel htmlFor={'boolean-select-' + key}  key={`${key}-label`}> 
                                                {formatLabel(key)}
                                            </InputLabel>
                                            <Select
                                                key={key}
                                                label={formatLabel(key)}
                                                defaultValue={promo.config[key]}
                                                onChange={(event) => handleConfigBoolChange(key, event)}
                                                inputProps={{
                                                    name: key + 'bool',
                                                    id: 'boolean-select-' + key
                                                }}
                                            >
                                                <option value={"null"}>Null</option>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </Select>
                                        </FormControl>
                                        <div  key={`${key}-description`} className={cls.inputDescription}>{desc ? desc.description : ''}</div>                                  
                                    </Grid>
                            )
                        })
                        : ruleObj.schema.map(item =>
                            item.type !== 'boolean' ?
                                <Grid item xs={6}  key={`${item.name}-grid`}>
                                    <FormControl className={cls.inputField} key={`${item.name}-control`}>
                                        <TextField
                                            key={item.name}
                                            type='number'
                                            label={formatLabel(item.name)}
                                            defaultValue={item.default}
                                            onChange={(event) => handleConfigChange(item.name, event)}
                                        />
                                    </FormControl>
                                    <div key={`${item.name}-description`} className={cls.inputDescription} >{item.description}</div>
                                </Grid>
                                :
                                <Grid item xs={6} key={`${item.name}-grid`}>
                                    <FormControl className={cls.booleanSelect} key={`${item.name}-control`}>
                                        <InputLabel htmlFor={'boolean-select-' + item.name}>
                                            {formatLabel(item.name)}
                                        </InputLabel>
                                        <Select
                                            key={item.name}
                                            label={formatLabel(item.name)}
                                            defaultValue={item.default}
                                            onChange={(event) => handleConfigBoolChange(item.name, event)}
                                            inputProps={{
                                                name: item.name + 'bool',
                                                id: 'boolean-select-' + item.name
                                            }}
                                        >
                                            <option value={"null"}>Null</option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                        </Select>
                                    </FormControl>
                                    <div key={`${item.name}-description`} className={cls.inputDescription} >{item.description}</div>
                                </Grid>
                            )
                
                
                    }
                    </Grid>
                    <br />
                    <br />
                    <Grid>
                        {type === 'add'  ?
                            <Button
                                className={cls.button}
                                id='saveRuleButton'
                                disabled={ !(ruleObj.id) }
                                onClick={() => { addPromo() }}
                                color='primary'
                                size='medium'
                                variant='outlined'
                            > Save Rule
                            </Button>
                            :
                            <Button
                                className={cls.button}
                                id='updateRuleButton'
                                disabled={!(promoObj.id && ruleObj.id)}
                                onClick={() => { updatePromo() }}
                                color='primary'
                                size='medium'
                                variant='outlined'
                            > Update Rule
                        </Button>}
                    </Grid>
                </Grid>
            </Container>
        </div>)
    } else return <DefaultEmptyFallback message="NO PROMO FOUND"/>
};

// STYLING /////////////////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
    selectInput: {
        marginLeft: "45px",
        width: "500px",
        padding: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    inputDescription: {
        color: theme.palette.text.secondary,
        marginLeft: "45px",
        marginBottom: "10px"
    },
    inputField: {
        marginLeft: "45px",
    },
    booleanSelect: {
        marginLeft: "45px",
        width: "150px",
    },
    date: {
        marginLeft: "45px",
        // paddingLeft: "25%",
        // display: "flex",
        // justifyContent: "center",
    },
    button: {
        marginLeft: "45px",
    }
}));