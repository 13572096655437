//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { DataProvider } from './providers/DataProvider';
import { RegionsProvider } from './providers/RegionsProvider';
import { NotificationsProvider } from './providers/NotificationsProvider';
import { UserDetailsProvider } from './providers/UserDetailsProvider';

import theme from './utils/theme';

import * as Sentry from '@sentry/react';
import TopLevelFallback from './components/Fallbacks/TopLevelFallback';
import Layout from './components/_app/Layout';
import Routes from './Routes';

//////////////////////// COMPONENT ////////////////////////

function App(props) {
  return (
    <Sentry.ErrorBoundary fallback={<TopLevelFallback />}>
      <DataProvider>
        <UserDetailsProvider>
          <RegionsProvider>
            <NotificationsProvider>
              <MuiThemeProvider theme={theme}>
                <Layout>
                  <Routes />
                </Layout>
              </MuiThemeProvider>
            </NotificationsProvider>
          </RegionsProvider>
        </UserDetailsProvider>
      </DataProvider>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// EXPORT ////////////////////////

export default App;
