//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Chip, TableContainer, Table, TablePagination } from '@material-ui/core';

import { RateRuleGroupTableHead, RateRuleGroupTableBody } from './index';

const defaultOrder = `desc`;
const defaultOrderBy = `end_date`;

//////////////////////// COMPONENT ////////////////////////

function RateRuleGroupTable(props) {
  const { rateRuleGroups = [] } = props;
  console.log("raterulegroup", rateRuleGroups[0].customer.name)

  const cls = useStyles();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getContractStatus = (beginDate, endDate) => {
    const isExpired = dayjs().isAfter(endDate);
    const isPending = dayjs().isBefore(beginDate);  
    if(isExpired){
      return (<Chip label='Expired' variant='default' size='small' color='secondary' />)
    }else if(isPending){
      return (<Chip label='Pending' variant='default' size='small' color='tertiary' />)
    }else{
      return (<Chip label='Active' variant='default' size='small' color='primary' />)
    }
  }

  const headers = [
    { id: 'NAME', align: 'left', label: 'Name' },
    { id: 'CUSTOMER_NAME', align: 'left', label: 'Customer' },
    { id: 'STATUS', align: 'left', label: 'Contract\xa0Status' },
    { id: 'BEGIN_DATE', align: 'left', label: 'Start\xa0Date' },
    { id: 'END_DATE', align: 'right', label: 'End\xa0Date' },
  ];

  const rows = rateRuleGroups.map((rateRuleGroup) => {
    return {
      NAME: rateRuleGroup.name ?? '-',
      CUSTOMER_NAME: rateRuleGroup.customer && rateRuleGroup.customer.name ? rateRuleGroup.customer.name : '-',
      STATUS: getContractStatus(rateRuleGroup.begin_date, rateRuleGroup.end_date),
      BEGIN_DATE: rateRuleGroup.begin_date,
      END_DATE: rateRuleGroup.end_date,
      CUSTOMER_ID: rateRuleGroup.customer && rateRuleGroup.customer.id ? rateRuleGroup.customer.id : '-'
    };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='rate-rule-group-index-table'>
          <RateRuleGroupTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <RateRuleGroupTableBody rows={rows} order={order} orderBy={orderBy} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
}));

//////////////////////// EXPORT ////////////////////////

export default RateRuleGroupTable;