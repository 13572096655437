import React from 'react';
import { makeStyles, Container, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTools } from '../../hooks/useTools';

export default function RegionsBanner() {
    const cls = useStyles();
    const { goToRoute } = useTools();

    return (<>
        <div className={cls.headers}>
            <Container className={cls.leftHeaders}>
                <Typography className={cls.regionsHeader}>Promos</Typography>
            </Container>
            <Container maxWidth='lg'>
                <div className={cls.rightHeaders}>
                <Button className={cls.btnEl} onClick={() => { goToRoute(`/promos/add`) }} variant='contained' color='primary'> 
                    Create Promo
                </Button>
                </div>
            </Container>
        </div>
    </>)
};

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
    headers: {
      marginTop: theme.spacing(4),
    },
    leftHeaders: {
      width: '100%',
      marginBottom: '-65px',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1),
      },
    },
    regionsHeader: {
      fontWeight: '700',
      fontSize: '22px',
      marginLeft: theme.spacing(1),
    },
    rightHeaders: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
    },
    btnEl: {
      boxShadow: `none`,
      '&:hover, &:active': {
        boxShadow: `none`,
      },
    },
  }));
  