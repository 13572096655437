//////////////////////// DEPENDENCIES ////////////////////////
import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, Container } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';


//////////////////////// COMPONENT ////////////////////////

function MoveLane(props) {
  const cls = useStyles();
  const { capFirst } = useTools();

  const { move } = props;
  const { lane } = move;

  let ln = {};

  ln.totalTime = Number(lane.duration_sec)
  ln.totalHours = Math.floor(lane.duration_sec / 3600)
  ln.totalMinutes = Math.floor(lane.duration_sec % 3600 / 60)

  ln.distance = Number(lane.distance_miles);
  ln.averageSpeed = Number(lane.average_drive_speed_mph);

  ln.baseRateType = capFirst(lane.dealer_base_rate_type);
  ln.basePay = Number(lane.driver_base_pay);
  ln.basePrice = Number(lane.dealer_base_price);

  ln.strandedRateType = capFirst(lane.dealer_stranded_rate_type);
  ln.strandedPay = Number(lane.driver_base_pay) + Number(lane.driver_return_pay);
  ln.strandedPrice = Number(lane.dealer_stranded_price);

  let regionName

  if (lane) {
    if (lane.pickup && lane.pickup.region && lane.pickup.region.name !== null) {
      regionName = lane.pickup.region.name
    } else if (!lane.pickup.region && (lane.delivery && lane.delivery.region && lane.delivery.region.name !== null)){
      regionName = lane.delivery.region.name
    } else regionName = null
  }




  try {
    return (
      <>
        <div className={cls.paper}>
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              <Tooltip placement='top-start' title='Lane ID'>
                <Typography className={cls.headTxtL}>Lane&nbsp;#{lane.id}</Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip placement='top-end' title='Pickup to Delivery'>
                {regionName !== null ? (
                  <Typography style={{paddingTop: '10px'}} className={cls.headTxtR}>{lane.description}&nbsp;&nbsp;&#40;Region: {regionName}&#41;&nbsp;&nbsp;</Typography>
                ) : (<Typography style={{paddingTop: '10px', color: 'red'}} className={cls.headTxtR}>{lane.description}&nbsp;&nbsp;&#40;No Region&#41;&nbsp;&nbsp;</Typography>)}
                
              </Tooltip>
            </Grid>
          </Grid>
          <div className={cls.break} />
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Typography className={cls.subheadTxt}>Route Info</Typography>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Total&nbsp;Time:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{ln.totalHours > 0 ? ln.totalHours + ' hr' : null}&nbsp;{ln.totalMinutes + ' min'}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Distance:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{ln.distance.toFixed(1)}&nbsp;mi</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Average&nbsp;Speed:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{ln.averageSpeed.toFixed(1)}&nbsp;mph</Typography>
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={4} xs={12}>
              <Typography className={cls.subheadTxt}>Base Standards</Typography>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Base&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{ln.baseRateType}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Base&nbsp;Price:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${ln.basePrice.toFixed(2)}</Typography>
              </div>
            </Grid>

            <div className={cls.hiddenBreak} />

            <Grid item sm={4} xs={12}>
              <Typography className={cls.subheadTxt}>Stranded Standards</Typography>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stranded&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{ln.strandedRateType}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stranded&nbsp;Price:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>${ln.strandedPrice.toFixed(2)}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={cls.break} />
      </>
    );
  } catch (error) {
    console.error(`Error with lane:`, error);
    return (
      <div className={cls.rootTable}>
        <Container maxWidth='sm'>
          <div className={cls.notFound}>
            <Typography className={cls.notFoundTxt}>ERROR WITH LANE, PLEASE CHECK RELATED LOCATIONS</Typography>
          </div>
        </Container>
      </div>
    );
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  btn: {
    verticalAlign: 'top',
    minHeight: '40px',
    maxHeight: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: theme.palette.text.disabled,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveLane;
