import { gql } from '@apollo/client';

const GET_APPAYMENTS = (status, driverId) =>  gql`
    query get_armoves($status: String, $driverId: bigint, $start: timestamptz!, $end: timestamptz!) {
      appayments(
        where: {
          move: { active: { _eq: 1 },
          pickup_time: { _gte: $start, _lte: $end } }
          ${status ? 'status: { _eq: $status }' : ''}
          ${driverId ? 'driver_id: { _eq: $driverId }' : ''}
        }
        order_by: { id: desc }
      ) {
        id
        driver_id
        move_id
        status
        amount
        createdat
        type
        accessorial {
          id
          code
        }
        move {
          id
          pickup_time
          active
          move_type
          driver_name
        }
      }
    }
  `;

export { GET_APPAYMENTS };
