import React from 'react';
import MDEditor from '@uiw/react-markdown-editor';
import { makeStyles, Icon } from '@material-ui/core';

export default function MarkdownEditor({ height, value, onChange, onSave }) {
  const cls = useStyles();

  // Custom toolbar buttons (on the left)
  const commandsLeft = [
    `undo`,
    `redo`,
    `header`,
    `bold`,
    `italic`,
    // `underline`,
    `strike`,
    `link`,
    `image`,
    `olist`,
    `ulist`,
    // `todo`,
    `quote`,
    `code`,
    `codeBlock`,
  ];

  // Custom toolbar buttons (on the right)
  const commandsRight = [
    `preview`,
    {
      name: 'save',
      keyCommand: 'save',
      button: { 'aria-label': 'Save' },
      icon: <Icon className={cls.icon}>save</Icon>,
      execute: ({ state, view }) => {
        if (onSave) onSave(state, view);
      },
    },
  ];

  return (
    <MDEditor
      visible
      height={height}
      value={value}
      onChange={(value, viewUpdate) => onChange(value)}
      toolbars={commandsLeft}
      toolbarsMode={commandsRight}
    />
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 16,
  },
}));
