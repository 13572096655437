// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Grid, Typography, IconButton, Icon } from '@material-ui/core';

// COMPONENT -------------------------------------------------- //

export default function PageHeader({ className, children, titles = [], onClose, handleClose, ...rest }) {
  const cls = useStyles();

  const getClassNames = () => {
    let classNames = [cls.root];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <div className={getClassNames()} {...rest}>
      <Grid container alignItems='center' wrap='nowrap'>
        <Grid item xs>
          {titles && titles.length ? (
            titles.map((title, i) => (
              <Typography key={`page-title-${i}`} className={i === titles.length - 1 ? cls.titleTxt : cls.titleTxtGray}>
                {title}
                {i === titles.length - 1 ? `` : ` /\xa0`}
              </Typography>
            ))
          ) : (
            <Typography className={cls.titleTxt}>Page Title</Typography>
          )}
        </Grid>

        {children}

        <Grid item>
          <div className={cls.line} />
        </Grid>

        <Grid item>
          <IconButton className={cls.iconBtn} onClick={() => handleClose()}>
            <Icon className={cls.icon}>close</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1301,
    position: 'relative',
    width: '100%',
    height: 64,
    padding: theme.spacing(0, 1.5, 0, 2.5),
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    display: 'inline',
    fontSize: 21,
    fontWeight: 600,
  },
  titleTxtGray: {
    display: 'inline',
    fontSize: 21,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  line: {
    width: 1,
    height: 40,
    margin: theme.spacing(1.5),
    background: theme.palette.divider,
  },
  iconBtn: {
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));
