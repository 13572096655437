//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Modal } from '../ModalComponents';
import RideshareRateFormModal from './RideshareRateFormModal';

//////////////////////// COMPONENT ////////////////////////

// The Modal form inputs do not work correctly without this wrapper component

export default function RideshareRateFormModalWrapper({  
    open = false,
    editMode = false,
    currentRideshareRateId,
    onAddSave,
    selectedRideshareRate = {},
    onCancel = () => {},}) {
  return (
    <Modal open={open}>
        {open ? (<RideshareRateFormModal
            editMode = {editMode}
            currentRideshareRateId = {currentRideshareRateId}
            onAddSave = {onAddSave}
            onCancel = {onCancel}
            selectedRideshareRate = {selectedRideshareRate}
        />):null}
    </Modal>
  );
}