//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useRegions } from '../providers/RegionsProvider';
import RegionSelect from '../../../components/RegionSelect';

//////////////////////// COMPONENT ////////////////////////

export default function PlansToolbarTabs() {
  const cls = useStyles();

  const { selectedRegionId, setSelectedRegionId } = useRegions();

  const handleRegionChange = event => {
    const val = Number(event.target.value);
    if (val && val > 0) {
      localStorage.setItem(`selected-region-id`, val);
      setSelectedRegionId(val);
    } else {
      localStorage.setItem(`selected-region-id`, 0);
      setSelectedRegionId(0);
    }
  };

  return (
    <RegionSelect
      allowAll={false}
      allowSelected={true}
      value={selectedRegionId}
      onChange={handleRegionChange}
      InputProps={{ className: cls.input }}
    />
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  input: {
    height: 32,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));
