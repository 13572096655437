//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, TextField, MenuItem, Switch, Typography, Tooltip } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useQuery, gql } from '@apollo/client';

// import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  publicWorkflowSets: `config.public_workflowsets`,
  defaultWorkflowSetId: `config.default_workflow_set_id`,
  defaultPickupWorkflowId: `config.default_pickup_workflow_id`,
  defaultDeliveryWorkflowId: `config.default_delivery_workflow_id`,
};

// DEFAULTS //
const getDefaultPublicWorkflowSets = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.publicWorkflowSets) === false) return false;
  if (getPropValue(overrideRef, option?.publicWorkflowSets) === true) return true;
  return getPropValue(inheritedRef, option?.publicWorkflowSets);
};
const getDefaultWorkflowSetId = (overrideRef, inheritedRef) => {
  return (
    getPropValue(overrideRef, option?.defaultWorkflowSetId) || getPropValue(inheritedRef, option?.defaultWorkflowSetId)
  );
};
const getDefaultPickupWorkflowId = (overrideRef, inheritedRef) => {
  return (
    getPropValue(overrideRef, option?.defaultPickupWorkflowId) ||
    getPropValue(inheritedRef, option?.defaultPickupWorkflowId)
  );
};
const getDefaultDeliveryWorkflowId = (overrideRef, inheritedRef) => {
  return (
    getPropValue(overrideRef, option?.defaultDeliveryWorkflowId) ||
    getPropValue(inheritedRef, option?.defaultDeliveryWorkflowId)
  );
};

//////////////////////// COMPONENT ////////////////////////

export default function Workflows({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
  orgId,
  customerId,
}) {
  const cls = useStyles();

  // Manage state of options
  const [defaultWorkflowSetId, setDefaultWorkflowSetId] = React.useState(
    getDefaultWorkflowSetId(overrideRef, inheritedRef)
  );
  const [defaultPickupWorkflowId, setDefaultPickupWorkflowId] = React.useState(
    getDefaultPickupWorkflowId(overrideRef, inheritedRef)
  );
  const [defaultDeliveryWorkflowId, setDefaultDeliveryWorkflowId] = React.useState(
    getDefaultDeliveryWorkflowId(overrideRef, inheritedRef)
  );

  const [publicWorkflowSets, setPublicWorkflowSets] = React.useState(
    getDefaultPublicWorkflowSets(overrideRef, inheritedRef)
  );

  const [workflowsets, setWorkflowsets] = React.useState([]);
  const [disablePublicSwitch, setDisablePublicSwitch] = React.useState(false);

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setDefaultWorkflowSetId(getDefaultWorkflowSetId(null, inheritedRef));
      setDefaultPickupWorkflowId(getDefaultPickupWorkflowId(null, inheritedRef));
      setDefaultDeliveryWorkflowId(getDefaultDeliveryWorkflowId(null, inheritedRef));
      setPublicWorkflowSets(getDefaultPublicWorkflowSets(null, inheritedRef));
    }
  }, [isReset]);

  // Handle default workflowset change from dropdown
  const handleManualDefaultWorkflowSetChange = workflowsets => e => {
    // Get the value
    const value = e?.target?.value || 1;

    // Get the workflow set and set the default workflow ids
    const workflowset = workflowsets.find(workflowset => workflowset.id === value);
    const pickupWorkflowId = workflowset?.pickup_workflow_id || 1;
    const deliveryWorkflowId = workflowset?.delivery_workflow_id || 2;

    // Set the state variable, build the changes and update the mutable ref
    const changes = {
      config: {
        default_workflow_set_id: value,
        default_pickup_workflow_id: pickupWorkflowId,
        default_delivery_workflow_id: deliveryWorkflowId,
      },
    };
    setDefaultWorkflowSetId(value);
    setDefaultPickupWorkflowId(pickupWorkflowId);
    setDefaultDeliveryWorkflowId(deliveryWorkflowId);
    updateMutableRef(changes);
  };

  // Automatically handle default workflowset change based on public workflow value
  const handleAutoDefaultWorkflowSetChange = workflowsets => {
    if (workflowsets && workflowsets.length) {
      // Get the first workflow set and set the default workflow ids
      const workflowset = workflowsets[0];
      const pickupWorkflowId = workflowset?.pickup_workflow_id;
      const deliveryWorkflowId = workflowset?.delivery_workflow_id;

      // Set the state variable, build the changes and update the mutable ref
      const changes = {
        config: {
          default_workflow_set_id: workflowset.id,
          default_pickup_workflow_id: pickupWorkflowId,
          default_delivery_workflow_id: deliveryWorkflowId,
        },
      };
      setDefaultWorkflowSetId(workflowset.id);
      setDefaultPickupWorkflowId(pickupWorkflowId);
      setDefaultDeliveryWorkflowId(deliveryWorkflowId);
      updateMutableRef(changes);
    }
  };

  // Handle Public Workflow change
  const handlePublicWorkflowSetsChange = () => {
    // Get the value
    const value = !publicWorkflowSets;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { public_workflowsets: value } };
    setPublicWorkflowSets(value);
    updateMutableRef(changes);
  };

  //////////////////////// QUERY ////////////////////////

  const { error, data } = useQuery(GET_WORKFLOWS, {
    variables: {
      organizationId: orgId || 0,
      customerId: customerId || 0,
    },
  });

  React.useEffect(() => {
    let workflowsetOptions = [];
    if (data?.workflowsets?.length > 0) {
      workflowsetOptions = JSON.parse(JSON.stringify(data?.workflowsets || []));
      setWorkflowsets(workflowsetOptions);
    }
    if (workflowsetOptions?.length > 0 && !publicWorkflowSets) {
      workflowsetOptions = workflowsetOptions.filter(workflowset => workflowset.public === false);
      handleAutoDefaultWorkflowSetChange(workflowsetOptions);
    }
    setWorkflowsets(workflowsetOptions);
    let privateWF = workflowsetOptions.filter(wfso => wfso.public === false);
    if (privateWF.length === 0) {
      setDisablePublicSwitch(true);
    } else {
      setDisablePublicSwitch(false);
    }
  }, [data, publicWorkflowSets]);

  // ERROR STATE //
  if (error) {
    console.error(`Failed to fetch workflow sets:`, error);
  }

  // DATA STATE //

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Workflows`}
        tip={`The workflows module allows us to set the default workflow set and designate which workflow sets should be viewable to the customer.`}
      />

      <SettingsOption
        contained
        title={`Public Workflow Sets`}
        description={`If an organization/rooftop requires that a custom workflow set be used, this switch allows you to hide the generic workflow sets typically available to all rooftops.
        You can only switch off public workflow sets if there are custom workflow sets available for the organization/rooftop.
        Note: if you switch off the public workflow sets, the default workflow set will be automatically set to the first custom workflow set available.`}
        value={publicWorkflowSets}
        checkReset={() => checkMutableRefField(option?.publicWorkflowSets)}
        onReset={() =>
          deleteMutableRefField(option?.publicWorkflowSets, () =>
            setPublicWorkflowSets(getPropValue(inheritedRef, option?.publicWorkflowSets))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Tooltip
          title={publicWorkflowSets ? `Hide public workflow sets` : `Show public workflow sets`}
          placement='top'
          arrow
        >
          <Switch
            disabled={disablePublicSwitch}
            color='primary'
            checked={publicWorkflowSets}
            onChange={() => handlePublicWorkflowSetsChange()}
          />
        </Tooltip>
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Default Workflow Set`}
        description={`Select the default workflow set for this client. The default workflow set is the preferred set of pickup and delivery workflows used for this client. The workflows and workflow sets are currently only managed from the back-end so users will need to contact a tech person for support on creating these.`}
        value={defaultWorkflowSetId}
        checkReset={() => checkMutableRefField(option?.defaultWorkflowSetId)}
        onReset={() =>
          deleteMutableRefField(option?.defaultWorkflowSetId, () => {
            setDefaultWorkflowSetId(getPropValue(inheritedRef, option?.defaultWorkflowSetId));
            setDefaultPickupWorkflowId(getPropValue(inheritedRef, option?.defaultPickupWorkflowId));
            setDefaultDeliveryWorkflowId(getPropValue(inheritedRef, option?.defaultDeliveryWorkflowId));
          })
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        {workflowsets?.length ? (
          <TextField
            fullWidth
            select
            label='Default Workflow Set'
            placeholder='Select a workflow set...'
            size='small'
            variant='outlined'
            value={defaultWorkflowSetId}
            onChange={handleManualDefaultWorkflowSetChange(workflowsets)}
          >
            {workflowsets?.map(workflowset => (
              <MenuItem key={`workflow-set-${workflowset.id}`} className={cls.workflowset} value={workflowset.id}>
                <Typography className={cls.workflowsetNameTxt}>
                  {workflowset.id} - {workflowset.name}
                </Typography>
                <Typography className={cls.workflowsetDescTxt}>{workflowset.description}</Typography>
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </SettingsOption>

      <Spacer />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  workflowset: {
    display: 'block',
  },
  workflowsetNameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  workflowsetDescTxt: {
    whiteSpace: 'wrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

//////////////////////// GQL ////////////////////////

const GET_WORKFLOWS = gql`
  query admin_getWorkflows($organizationId: bigint, $customerId: bigint) {
    workflowsets(
      where: {
        _or: [
          { public: { _eq: true } }
          { organization_id: { _eq: $organizationId } }
          { customer_id: { _eq: $customerId } }
        ]
      }
      order_by: { id: asc }
    ) {
      id
      customer_id
      delivery_workflow_id
      description
      name
      organization_id
      pickup_workflow_id
      public
      pickupWorkflow {
        id
        description
        name
        type
        version
      }
      deliveryWorkflow {
        id
        description
        name
        type
        version
      }
    }
  }
`;
