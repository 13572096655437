//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, DialogActions } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function ModalFooter({ children, ...rest }) {
  const cls = useStyles();
  return (
    <DialogActions className={cls.padding} {...rest}>
      {children}
    </DialogActions>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  padding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    background: theme.palette.background.dark,
  },
}));
