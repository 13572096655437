//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserAddToolbar() {
  const cls = useStyles();
  const ctx = useUserDetails()

  const { goToPreviousPage } = useTools();

  const handleCancel = () => {
    goToPreviousPage();
  };

  const handleSubmit = async () => {
      const res = await ctx?.handleCreateUser('dealer');
      if (res?.success === true) {
        goToPreviousPage();
      } else ctx.setIsSaving(false)
  };

  const handleSubmitAndAddAnother = async () => {
      const res = await ctx?.handleCreateUser('dealer');
      if (res?.success === true) {
        window.location.reload();
      }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs />
      <Grid item>
        <Button onClick={() => handleCancel()}>
          <Icon className={cls.btnIcon}>highlight_off</Icon>
          Cancel
        </Button>
      </Grid>

      <Grid item>
        <Button loading={ctx.isSaving} disabled={ctx.isSaving} color='primary' onClick={() => handleSubmit()}>
          <Icon className={cls.btnIcon}>task_alt</Icon>
          Submit
        </Button>
      </Grid>

      <Grid item>
        <Button
          loading={ctx.isSaving}
          disabled={ctx.isSaving}
          variant='outlined'
          color='primary'
          onClick={() => handleSubmitAndAddAnother()}
        >
          <Icon className={cls.btnIcon}>add_task</Icon>
          Submit & Add Another
        </Button>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));