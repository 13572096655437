import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const headers = [
  { id: 'id', align: 'left', label: 'ID' },
  { id: 'name', align: 'left', label: 'Name' },
  { id: 'address', align: 'left', label: 'Address' },
  { id: 'created at', align: 'left', label: 'Created On'},
  { id: 'affected moves', align: 'right', label: 'Affected Move Count'},
  { id: 'placeholder', align: 'right', label: ''}
];

function MissingRegionLocationsHeaders(props) {
  const cls = useStyles();
  const { onRequestSort, order, orderBy, rowCount } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow key={'location-header'}>
        {headers.map(header => (
          <TableCell
            className={cls.headTxt}
            key={'location-header-' + header.id}
            align={header.align || 'left'}
            padding='normal'
            sortDirection={orderBy === header.id ? order : false}
          >
            <TableSortLabel
              key={'location-header-label-' + header.id}
              active={orderBy === header.id}
              direction={order}
              onClick={header.id ? createSortHandler(header.id) : null}
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    
  );
}

MissingRegionLocationsHeaders.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  headTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

export default MissingRegionLocationsHeaders;