//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import { gql } from '@apollo/client';

import { ProductsFilter, ProductsTable } from './index';

//////////////////////// COMPONENT ////////////////////////

function Products(props) {
  const cls = useStyles();

  // Call the GET_PRODUCTS query
  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS);

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Products'>
          <ProductsFilter />
        </Toolbar>
        <Loading fixed />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching products:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Products' refetch={handleRefetch}>
          <ProductsFilter />
        </Toolbar>
        <DefaultErrorFallback message='ERROR FETCHING PRODUCTS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.products || !data.products.length > 0) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Products' refetch={handleRefetch}>
          <ProductsFilter />
        </Toolbar>
        <DefaultEmptyFallback message='NO PRODUCTS FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const products = data.products;

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Products' refetch={handleRefetch}>
        <ProductsFilter />
      </Toolbar>
      {/* If table body has an error, show error message instead of table */}
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PRODUCTS TABLE' />}>
        <ProductsTable products={products} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
}));

//////////////////////// GQL ////////////////////////

const GET_PRODUCTS = gql`
  query admin_getProducts {
    products(where: {salable: {_eq: true}, active: {_eq: true}}) {
      id
      active
      cost
      description
      name
      term
      createdBy
      created_at
      prepaid
      salable
    }
  }
`;

//////////////////////// EXPORT ////////////////////////

export default Products;
