import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useTools } from '../../hooks/useTools';
import { useData } from '../../providers/DataProvider';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

export default function InvoicePaymentRow({ payment, index, paymentCount }) {
  const cls = useStyles();
  const { capFirst, formatUSD } = useTools();
  const ctx = useData();

  const updatePaymentStatus = paymentId => {
    ctx.apolloClient
      .mutate({
        mutation: UPDATE_PAYMENT_STATUS,
        variables: { id: paymentId },
      })
      .then(res => {
        console.log(res);
        if (res.data && res.data.update_arpayments && res.data.update_arpayments.affected_rows === 1) {
          //success toast
          toast.success("Set payment status as 'paid'");
        } else {
          //fail toast
          toast.error("Failed to set payment status to 'paid'");
        }
      })
      .catch(err => {
        console.error(err);
        toast.error("Failed to set payment status to 'paid'");
      });
  };

  // Set a list of actions after right-clicking on a row
  const rowActions = [
    {
      label: `Mark as Paid`,
      handler: () => updatePaymentStatus(payment.id),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <React.Fragment key={'paymentRow' + payment.id}>
      <ContextMenuTrigger
        id={`payment-options-cm`}
        source={`payment-options-cm`}
        holdToDisplay={1000}
        // collect={() => props}
        disableIfShiftIsPressed={true}
      >
        <Grid container spacing={1} wrap='nowrap'>
          <Grid item xs={1}>
            <Typography align='left' className={cls.payVal}>
              {payment.id}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align='left' className={cls.payVal}>
              {dayjs(payment.createdat).format(`MM/DD/YYYY`)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align='left' className={cls.payVal}>
              {payment.accounting_id || `-`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align='left' className={cls.payVal}>
              {payment.gateway_transaction_id || `-`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align='left' className={cls.payVal}>
              {capFirst(payment.status)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align='right' className={cls.payVal}>
              {formatUSD(payment.amount)}
            </Typography>
          </Grid>
        </Grid>
        {index < paymentCount - 1 ? <div className={cls.lineBreak} /> : null}
      </ContextMenuTrigger>
      {rowActions && rowActions.length > 0 && (
        <ContextMenu id={`payment-options-cm`} style={{ boxShadow: '2px 4px 12px #00000050' }}>
          {rowActions.map(
            action =>
              !action.hide && (
                <MenuItem
                  data={{ action: action, ...action.data }}
                  onClick={action.handler}
                  disabled={action.disabled}
                  key={`atr-table-option-${action.name}`}
                >
                  {action.label}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}
    </React.Fragment>
  );
}

//////////////////////// GQL ////////////////////////
const UPDATE_PAYMENT_STATUS = gql`
  mutation UPDATE_PAYMENT_TO_PAID($id: bigint!) {
    update_arpayments(where: { id: { _eq: $id } }, _set: { status: "paid" }) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  totalKey: {
    verticalAlign: 'top',
    marginTop: '1px',
    marginRight: theme.spacing(2),
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
    },
  },
  totalVal: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  payKey: {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  payVal: {
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.dividerStrong,
  },
}));
