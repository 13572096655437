import React from 'react';
import { makeStyles, Grid, TextField, InputAdornment, Icon } from '@material-ui/core';

function LocationsToolbar({ children, search, onSearchChange }) {
  const cls = useStyles();

  return (
    <Grid container spacing={2} wrap='nowrap'>
      <Grid item xs />
      <Grid item>
        <TextField
          className={cls.searchbar}
          label='Search Filter'
          placeholder='Search for a location...'
          value={search}
          onChange={onSearchChange}
          size='small'
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  search
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  searchbar: {
    width: '100%',
  },
}));

export default LocationsToolbar;
