// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import clsx from 'clsx';

import { makeStyles, Popover, Typography } from '@material-ui/core';

import { useFilters } from './FilterProvider';
import FilterModule from './FilterModule';

// COMPONENT ---------------------------------------------------------------- //

export default function FilterPopover() {
  const cls = useStyles();

  const { baseFilters, config, anchorEl, setAnchorEl, open, popoverId, handleApplyFilters, handleClearFilters } =
    useFilters();

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={cls.main}>
        <div className={cls.header}>
          <button className={clsx(cls.headerBtn, cls.headerClearBtn)} onClick={() => handleClearFilters()}>
            <span className={cls.headerBtnLabel}>Clear</span>
          </button>

          <Typography className={cls.headerTxt}>Filters</Typography>

          <button className={clsx(cls.headerBtn, cls.headerApplyBtn)} onClick={() => handleApplyFilters()}>
            <span className={cls.headerBtnLabel}>Apply</span>
          </button>
        </div>

        <div className={cls.line} />

        <div className={cls.body}>
          {config?.modules?.length
            ? config?.modules?.map(module => {
                return (
                  <React.Fragment key={`filter-${module}`}>
                    <FilterModule
                      module={module}
                      options={config?.moduleOptions?.[module]}
                      defaults={baseFilters?.[module]}
                    />
                  </React.Fragment>
                );
              })
            : null}
        </div>
      </div>
    </Popover>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  main: {
    position: 'relative',
    minWidth: 240,
    maxWidth: 480,
  },

  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
    padding: 12,
  },
  headerTxt: {
    fontSize: 18,
    fontWeight: 500,
  },
  headerBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px',
    border: 0,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    lineHeight: 1,
    cursor: 'pointer',
    transition: '0.15s',
  },
  headerClearBtn: {
    backgroundColor: theme.palette.utility.main,
    '&:hover': {
      backgroundColor: theme.palette.utility.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.utility.light,
    },
  },
  headerApplyBtn: {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.info.light,
    },
  },
  headerBtnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.white,
  },

  body: {
    position: 'relative',
  },

  line: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));
