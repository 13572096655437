import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles, Tooltip, Icon, Chip } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import { useEngine } from '../../hooks/useEngine';
import { Table, Column } from '../../components/TableComponents';

const statusSequence = [
  null,
  'dispatched',
  'pickup started',
  'pickup arrived',
  'pickup successful',
  'delivery started',
  'delivery arrived',
  'delivery successful',
];

export default function MovesTable({
  moves,
  checkedMoves,
  setCheckedMoves,
  moveCount,
  paginationData,
  setPaginationData,
  incomingData,
}) {
  const cls = useStyles();
  const {
    goToMoveDetails,
    goToLaneDetails,
    goToMapLane,
    getFormattedCombinedStatusFromMove,
    getFormattedVehicleFromMove,
    formatTime,
  } = useTools();
  const { rerunAR } = useEngine();

  const [totalRecordLimit, setTotalRecordLimit] = React.useState(null);

  const rowActions = [
    {
      label: `Go To Move Details`,
      handler: move => goToMoveDetails(move.id ? move.id : null),
      disabled: false,
      hide: false,
    },
    {
      label: `Go To Lane Details`,
      handler: move => goToLaneDetails(move.lane && move.lane.id ? move.lane.id : null),
      disabled: false,
      hide: false,
    },
    {
      label: `Go To Lane On Map`,
      handler: move => goToMapLane(move.lane && move.lane.id ? move.lane.id : null),
      disabled: false,
      hide: false,
    },
    {
      label: `Rerun Accounts Receivable`,
      handler: move => rerunAR(move.id ? move.id : null),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={totalRecordLimit ? moves : incomingData}
        onRowClick={move => goToMoveDetails(move.id)}
        rowActions={rowActions}
        defaultOrderBy={'id'}
        defaultOrder={'desc'}
        tableAriaLabel={`moves`}
        stickyHeader={true}
        checkedItems={checkedMoves}
        setCheckedItems={setCheckedMoves}
        totalRecordsCount={moveCount}
        totalRecords={moves}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        usePagination={true}
        totalRecordLimit={totalRecordLimit}
        setTotalRecordLimit={setTotalRecordLimit}
      >
        <Column
          name={`checkbox`}
          value={move => move.id}
          label={`Checkbox`}
          tooltip={`Select a move`}
          checkbox={true}
        />
        <Column
          name={`id`}
          value={move => move.id}
          label={`Move ID`}
          tooltip={`ID of a move`}
          style={{ minWidth: 120 }}
        />
        <Column
          name={`reference_num`}
          value={move => move.reference_num}
          label={`Ref #`}
          tooltip={`Reference number of a move`}
          style={{ minWidth: 100 }}
        />
        <Column
          name={`customer.name`}
          value={move => getPropValue(move, `customer.name`)}
          disableSort={true}
          renderer={move =>
            move.customer && move.customer.name ? (
              move.payer ? (
                <>
                  {move.customer.name}&nbsp;&nbsp;
                  <Tooltip title='Third Party Payer'>
                    <Icon fontSize='small'>currency_exchange</Icon>
                  </Tooltip>
                </>
              ) : (
                move.customer.name
              )
            ) : (
              `-`
            )
          }
          label={`Customer`}
          tooltip={`Name of the customer`}
        />
        <Column
          name={`pickup_time`}
          value={move => move.pickup_time}
          renderer={move => formatTime(move.pickup_time)}
          label={`Pickup`}
          tooltip={`Pickup time of the move`}
          style={{ minWidth: 220 }}
        />
        <Column name={`vehicle_vin`} value={move => move.vehicle_vin} label={`VIN`} tooltip={`VIN of the vehicle`} />
        <Column
          name={`vehicle`}
          disableSort={true}
          value={move => getFormattedVehicleFromMove(move)}
          label={`Vehicle`}
          tooltip={`Year, make, model and color of the vehicle`}
        />
        <Column
          name={`lane.description`}
          disableSort={true}
          value={move => getPropValue(move, 'lane.description')}
          label={`Lane`}
          tooltip={`Pickup and delivery points`}
        />
        <Column
          name={`status`}
          value={move => statusSequence.indexOf(move.status)}
          renderer={move => <Chip label={getFormattedCombinedStatusFromMove(move)} size='small' variant='outlined' />}
          align={`right`}
          label={`Status`}
          tooltip={`Status of the move`}
        />
      </Table>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
}));
