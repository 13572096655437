//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import axios from 'axios';
import sdk from '@hopdrive/sdk';
import gql from 'graphql-tag';

import {
  makeStyles,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Container,
  Icon,
  InputAdornment,
  InputLabel,
  IconButton,
  useTheme
} from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import MDEditor from '@uiw/react-md-editor';
import Spacer from '../../components/Spacer';
import { toast } from 'react-toastify';
import { Divide } from '@hopdrive/storybook';
import Select from 'react-select';
import { useNotificationsGlobals } from '../../providers/NotificationsProvider';
import { getUserEmail } from '../../utils/authHelper'
import { useMutation, useQuery } from '@apollo/client';
import { ADD_NOTIFICATION } from './gql';
import { useTools } from '../../hooks/useTools';
import Loading from '../../components/Loading';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

//////////////////////// COMPONENT ////////////////////////

function NotificationAdd(props) {
  const cls = useStyles();
  const theme = useTheme();
  const { goToPreviousPage } = useTools();

  const [type, setType] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [body, setBody] = React.useState(null);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [assignedAt, setAssignedAt] = React.useState(null);
  const [assignedBy, setAssignedBy] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [dueDate, setDueDate] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState(null)

  const { assignmentBell, setAssignmentBell } = useNotificationsGlobals();

  const GET_ADMIN_USERS = gql`
    query getAdminUsers {
      users(where: {default_role: {_eq: "admin"}}) {
        email
        firebase_id
        default_role
      }
    }
  `

  const { loading, error, data } = useQuery(GET_ADMIN_USERS);

  React.useEffect(() => {
    if (data && data.users && data.users.length > 0) {
      setUsers(data.users)
    }
  }, [data])

  React.useEffect(() => {
    let email = getUserEmail()
    setUserEmail(email)
  }, []);

  const handleSendEmail = async () => {
    try {
      await axios({
        method: 'POST',
        url: `/.netlify/functions/handleNotificationEmail`,
        data: {
          email: assignedTo,
          name: assignedTo,
          title: title,
          body: body,
          // id: notification.id,
          dueDate: dueDate || null
        },
      })
        .then(res => {
          if (res && res.status === 200) {
            console.log('email sent')
          } else {
            console.error('error');
          }
        })
        .catch(err => {
          console.error('Failed to send email:', err);
          toast.error(`Failed to send email alert`);
        });
    } catch (err) {
      console.error('Unexpected error while sending email alert:', err);
      toast.error(`Failed to send email alert`);
    }
  }

  const assignIcon = (
    <div>
      <Typography style={{ alignItems: 'center' }}>
        <Icon color='disabled' style={{ marginTop: '5px' }}>
          assignment_ind
        </Icon>
        <span style={{ marginBottom: '5px' }}>Type or use dropdown to assign this notification</span>
      </Typography>
    </div>
  );

  const dueIcon = (

        <Icon color='disabled' style={{ marginTop: '5px' }}>
          av_timer
        </Icon>

  );

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  const handleAssignedChange = selectedOption => {
    setAssignedTo(selectedOption.value);
    setAssignedBy(userEmail);
  };

  const handleDueDateChange = time => {
    setDueDate(dayjs(time).format());
  };

  const handleAddNotification = async () => {
    if (assignedTo && assignedTo === userEmail) {
      setAssignmentBell(true)
    } else if (assignedTo && assignedTo !== userEmail) {
      setAssignmentBell(false)
    }


    try {
      const variables = {
        type: type,
        title: title,
        body: body,
        assignedto: assignedTo || null,
        assignedby: assignedBy || null,
        assignedat: assignedTo !== null ? 'now()' : null,
        createdby: userEmail || null,
        createdat: 'now()',
        notes: notes || null,
        duedate: dueDate || null
      };
      const res = await sdk.notifications.create(variables);
      console.log('notification res', res);
      if (res && res.success === true) {
        toast.success(`Successfully added notification!`);
        goToPreviousPage();
        handleSendEmail();
      } else toast.error(`Failed to add notification!`);
    } catch (err) {
      toast.error(`Failed to add notification!`);
      console.error(`Failed to add notification:`, err);
    }
  };

  if (loading) return <Loading fixed />;
  if (error) {
    console.log(error);
    return (
      <div className={cls.root}>
        <div className={cls.notifyBox}>
          <Typography className={cls.notifyText} style={{ color: theme.palette.error.main }}>
            AN ERROR OCCURRED
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <IconButton className={cls.iconBtn} onClick={() => goToPreviousPage()}>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography className={cls.headTxt}>Add Notification</Typography>
          </Grid>
          <Grid item>
            <Button color='primary' size='large' onClick={() => handleAddNotification()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>

      <div className={cls.main}>
        <Container maxWidth='lg'>
          <Grid container spacing={2} style={{ justifyContent: 'center', margin: '20px' }}>
            <Grid item xs={5} className={cls.paper} style={{ padding: '40px', margin: '10px' }}>
              <Divide tip='Enter basic information about the notification'>Information (Required)</Divide>

              <TextField
                required
                select
                fullWidth
                label='Select Type'
                placeholder='Select a notification type'
                variant='outlined'
                value={type}
                onChange={handleInputChange(setType)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        info
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={0}>
                  <em>Select Type</em>
                </MenuItem>
                <MenuItem value={'inform'}>Informational Notification</MenuItem>
                <MenuItem value={'action'}>Action Item</MenuItem>
              </TextField>

              <Spacer />

              <TextField
                fullWidth
                required
                label='Title'
                placeholder='Enter a title for the notification...'
                variant='outlined'
                size='small'
                value={title}
                onChange={handleInputChange(setTitle)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        description
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />

              <Spacer />

              <TextField
                fullWidth
                required
                label='Body'
                placeholder='Enter a description for the notification...'
                variant='outlined'
                size='small'
                value={body}
                onChange={handleInputChange(setBody)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        menu_book
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />

              {type === 'action' ? (
                <div>
                  <Divide
                    color='primary'
                    spacer
                    tip='Use this field if you would like to assign this notification to a user now'
                  >
                    Assign (optional)
                  </Divide>




                                  <div className={cls.listItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      fullWidth
                      label={'Due Date'}
                      inputProps={{ className: cls.nakedValInput,}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                              <Icon color='disabled' fontSize='small'>
                                timer
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      inputStyle={{textAlign: 'left'}}
                      placeholder={`Enter a due date if applicable `}
                      orientation='portrait'
                      inputVariant='outlined'
                      value={dueDate ? dueDate : null}
                      onChange={handleDueDateChange}
                    />
                  </MuiPickersUtilsProvider>

                  <Spacer />
                  <Select
                    inputLabel='Assigned To'
                    options={users.map(option => ({ label: option.email, value: option.email }))}
                    onChange={handleAssignedChange}
                    placeholder={assignIcon}
                  />
                </div>
                </div>
              ) : null}
            </Grid>

            <Grid item xs={5} className={cls.paper} style={{ padding: '40px', margin: '10px' }}>
              <Divide tip='Use this editor to add rich-text notes about the notification'>Add Notes (Optional)</Divide>

              <MDEditor color='disabled' label='Notes' value={notes} onChange={setNotes} fullWidth />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  detailBox: {
    position: 'relative',
    padding: theme.spacing(1),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.main,
    textAlign: 'center',
  },
  detailKeyTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  detailValTxt: {
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
  nakedValInput: {
    width: '100%',
    minHeight: '35px',
    maxHeight: '35px',
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'left',
    // fontSize: '.85rem',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NotificationAdd;
