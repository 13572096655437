//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';

import { makeStyles, Grid, TextField, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';
import Spacer from '../../components/Spacer';
import { getUserEmail } from '../../utils/authHelper';
import { Button } from '@hopdrive/storybook';

const sdk = require('@hopdrive/sdk');

const driveStatuses = [
  {
    name: `Dispatched`,
    desc: `Move was added to a driver's plan.`,
    value: `dispatched`,
  },
  {
    name: `Pickup Started`,
    desc: `Driver is heading to the pickup location.`,
    value: `pickup started`,
    timestampNames: [`pickupStarted`],
  },
  {
    name: `Pickup Arrived`,
    desc: `Driver has arrived at the pickup location.`,
    value: `pickup arrived`,
    timestampNames: [`pickupArrived`],
  },
  {
    name: `Pickup Successful`,
    desc: `Driver successfully obtained the vehicle.`,
    value: `pickup successful`,
    timestampNames: [`pickupSuccessful`],
  },
  {
    name: `Delivery Started`,
    desc: `Driver is heading to the delivery location.`,
    value: `delivery started`,
    timestampNames: [`deliveryStarted`],
  },
  {
    name: `Delivery Arrived`,
    desc: `Driver has arrived at the delivery location.`,
    value: `delivery arrived`,
    timestampNames: [`deliveryArrived`],
  },
  {
    name: `Delivery Successful`,
    desc: `Driver successfully delivered the vehicle.`,
    value: `delivery successful`,
    timestampNames: [`deliverySuccessful`],
  },
];

const rideStatuses = [
  {
    name: `Accepted`,
    desc: `The ride is on it's way to the driver.`,
    value: `accepted`,
    timestampNames: [`pickupStarted`],
  },
  {
    name: `Arrived`,
    desc: `The ride has arrived at the driver's origin.`,
    value: `arrived`,
    timestampNames: [`pickupArrived`],
  },
  {
    name: `Picked Up`,
    desc: `Driver was picked up by the ride.`,
    value: `pickedUp`,
    timestampNames: [`pickupSuccessful`, `deliveryStarted`],
  },
  {
    name: `Dropped Off`,
    desc: `Driver was successfully taken to their destination.`,
    value: `droppedOff`,
    timestampNames: [`deliveryArrived`, `deliverySuccessful`],
  },
  {
    name: `Failed`,
    desc: `The ride never showed up, was canceled, or crashed.`,
    value: `failed`,
  },
];

const log = false;

//////////////////////// COMPONENT ////////////////////////

function MoveStatusModal(props) {
  const cls = useStyles();

  const { open, onClose, input } = props;

  const [status, setStatus] = React.useState(``);
  const [statusObj, setStatusObj] = React.useState(null);
  const [pickupStarted, setPickupStartedTime] = React.useState(null);
  const [pickupArrived, setPickupArrivedTime] = React.useState(null);
  const [pickupSuccessful, setPickupSuccessfulTime] = React.useState(null);
  const [deliveryStarted, setDeliveryStartedTime] = React.useState(null);
  const [deliveryArrived, setDeliveryArrivedTime] = React.useState(null);
  const [deliverySuccessful, setDeliverySuccessfulTime] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [laneDuration, setLaneDuration] = React.useState(null);
  const [pickupInspectionDuration, setPickupInspectionDuration] = React.useState(600);
  const [deliveryInspectionDuration, setDeliveryInspectionDuration] = React.useState(480);
  const [autoEstimate, setAutoEstimate] = React.useState({ newStatus: null, oldStatus: null });
  const [isManualEdit, setIsManualEdit] = React.useState(false);

  React.useEffect(() => {
    if (input) {
      if (input?.move?.lane?.duration_sec) setLaneDuration(input.move.lane.duration_sec);
      setPickupInspectionDuration(input?.move?.lane?.pickup_inspection_sec || 600);
      setDeliveryInspectionDuration(input?.move?.lane?.delivery_inspection_sec || 480);
      const {
        move: {
          pickup_started,
          pickup_arrived,
          pickup_successful,
          delivery_started,
          delivery_arrived,
          delivery_successful,
        } = {},
      } = input || {};
      log &&
        console.log('Setting status and times: ', input.status, {
          pickup_started,
          pickup_arrived,
          pickup_successful,
          delivery_started,
          delivery_arrived,
          delivery_successful,
        });
      setStatus(input.status || ``);
      setPickupStartedTime(pickup_started ? dayjs(pickup_started).utc().format() : null);
      setPickupArrivedTime(pickup_arrived ? dayjs(pickup_arrived).utc().format() : null);
      setPickupSuccessfulTime(pickup_successful ? dayjs(pickup_successful).utc().format() : null);
      setDeliveryStartedTime(delivery_started ? dayjs(delivery_started).utc().format() : null);
      setDeliveryArrivedTime(delivery_arrived ? dayjs(delivery_arrived).utc().format() : null);
      setDeliverySuccessfulTime(delivery_successful ? dayjs(delivery_successful).utc().format() : null);
    }
  }, [input]);

  React.useEffect(() => {
    setIsDisabled(checkOutput());
    setShowError(checkOutput());
  }, [
    status,
    pickupStarted,
    pickupArrived,
    pickupSuccessful,
    deliveryStarted,
    deliveryArrived,
    deliverySuccessful,
  ]);

  const handleShowEstimate = () => {
    //Functions to build timestamps based on lane duration and inspection time estimates according to each move status
    const addLaneDuration = time => dayjs(time)?.add(laneDuration, 'second').format();
    const addPickupInspectionDuration = time => dayjs(time)?.add(pickupInspectionDuration, 'second').format();
    const addDeliveryInspectionDuration = time => dayjs(time)?.add(deliveryInspectionDuration, 'second').format();
    const addLaneDurationAndPickupInspection = time =>
      dayjs(time)
        ?.add(laneDuration + pickupInspectionDuration, 'second')
        .format();
    const addLaneDurationAndDeliveryInspection = time =>
      dayjs(time)
        ?.add(laneDuration + deliveryInspectionDuration, 'second')
        .format();
    const addLaneDurationAndBothInspections = time =>
      dayjs(time)
        ?.add(laneDuration + pickupInspectionDuration + deliveryInspectionDuration, 'second')
        .format();
    //We don't know how far they're traveling to get to the pickup location, so we'll add 10 minutes to the pickup_started time, and the dispatcher can edit as needed
    const addPickupBuffer = time => dayjs(time)?.add(10, 'minute').format();

    //If there are no existing status timestamps to build estimates from, we'll use the current time as a starting point for building default times
    const defaultPickupStart = dayjs().format();
    const defaultPickupArrive = addPickupBuffer(defaultPickupStart);
    const defaultPickupSuccess = addPickupInspectionDuration(defaultPickupArrive);
    const defaultDeliveryStart = defaultPickupSuccess;
    const defaultDeliveryArrive = addLaneDuration(defaultDeliveryStart);
    const defaultDeliverySuccess = addDeliveryInspectionDuration(defaultDeliveryArrive);

    switch (isManualEdit ? autoEstimate?.oldStatus : autoEstimate?.newStatus) {
      case 'pickup started':
        setPickupStartedTime(defaultPickupStart);
        break;

      case 'pickup arrived':
        if (!pickupStarted) {
          setPickupStartedTime(defaultPickupStart);
          setPickupArrivedTime(defaultPickupArrive);
        } else {
          const arriveTime = addPickupBuffer(pickupStarted);
          setPickupArrivedTime(arriveTime);
        }
        break;

      case 'pickup successful':
        if (!pickupStarted) {
          setPickupStartedTime(defaultPickupStart);
          setPickupArrivedTime(defaultPickupArrive);
          setPickupSuccessfulTime(defaultPickupSuccess);
        } else if (pickupStarted && !pickupArrived) {
          const arriveTime = addPickupBuffer(pickupStarted);
          setPickupArrivedTime(arriveTime);
          setPickupSuccessfulTime(addPickupInspectionDuration(arriveTime));
        } else if (pickupArrived && !pickupSuccessful) {
          setPickupSuccessfulTime(addPickupInspectionDuration(pickupArrived));
        }
        break;

      case 'delivery started':
        if (!pickupStarted) {
          setPickupStartedTime(defaultPickupStart);
          setPickupArrivedTime(defaultPickupArrive);
          setPickupSuccessfulTime(defaultPickupSuccess);
          setDeliveryStartedTime(defaultDeliveryStart);
        } else if (pickupStarted && !pickupArrived) {
          const arriveTime = addPickupBuffer(pickupStarted);
          setPickupArrivedTime(arriveTime);
          setPickupSuccessfulTime(addPickupInspectionDuration(arriveTime));
          setDeliveryStartedTime(addPickupInspectionDuration(arriveTime));
        } else if (pickupArrived && !pickupSuccessful) {
          setPickupSuccessfulTime(addPickupInspectionDuration(pickupArrived));
          setDeliveryStartedTime(addPickupInspectionDuration(pickupArrived));
        } else if (pickupSuccessful && !deliveryStarted) {
          setDeliveryStartedTime(dayjs(pickupSuccessful).format());
        }
        break;

      case 'delivery arrived':
        if (!pickupStarted) {
          setPickupStartedTime(defaultPickupStart);
          setPickupArrivedTime(defaultPickupArrive);
          setPickupSuccessfulTime(defaultPickupSuccess);
          setDeliveryStartedTime(defaultDeliveryStart);
          setDeliveryArrivedTime(defaultDeliveryArrive);
        } else if (pickupStarted && !pickupArrived) {
          const arriveTime = addPickupBuffer(pickupStarted);
          setPickupArrivedTime(arriveTime);
          setPickupSuccessfulTime(addPickupInspectionDuration(arriveTime));
          setDeliveryStartedTime(addPickupInspectionDuration(arriveTime));
          setDeliveryArrivedTime(addLaneDurationAndPickupInspection(arriveTime));
        } else if (pickupArrived && !pickupSuccessful) {
          setPickupSuccessfulTime(addPickupInspectionDuration(pickupArrived));
          setDeliveryStartedTime(addPickupInspectionDuration(pickupArrived));
          setDeliveryArrivedTime(addLaneDurationAndPickupInspection(pickupArrived));
        } else if (pickupSuccessful && !deliveryStarted) {
          setDeliveryStartedTime(dayjs(pickupSuccessful).format());
          setDeliveryArrivedTime(addLaneDuration(pickupSuccessful));
        } else if (deliveryStarted && !deliveryArrived) {
          setDeliveryArrivedTime(addLaneDuration(deliveryStarted));
        }
        break;

      case 'delivery successful':
        if (!pickupStarted) {
          setPickupStartedTime(defaultPickupStart);
          setPickupArrivedTime(defaultPickupArrive);
          setPickupSuccessfulTime(defaultPickupSuccess);
          setDeliveryStartedTime(defaultDeliveryStart);
          setDeliveryArrivedTime(defaultDeliveryArrive);
          setDeliverySuccessfulTime(defaultDeliverySuccess);
        } else if (pickupStarted && !pickupArrived) {
          const arriveTime = addPickupBuffer(pickupStarted);
          setPickupArrivedTime(arriveTime);
          setPickupSuccessfulTime(addPickupInspectionDuration(arriveTime));
          setDeliveryStartedTime(addPickupInspectionDuration(arriveTime));
          setDeliveryArrivedTime(addLaneDurationAndPickupInspection(arriveTime));
          setDeliverySuccessfulTime(addLaneDurationAndBothInspections(arriveTime));
        } else if (pickupArrived && !pickupSuccessful) {
          setPickupSuccessfulTime(addPickupInspectionDuration(pickupArrived));
          setDeliveryStartedTime(addPickupInspectionDuration(pickupArrived));
          setDeliveryArrivedTime(addLaneDurationAndPickupInspection(pickupArrived));
          setDeliverySuccessfulTime(addLaneDurationAndBothInspections(pickupArrived));
        } else if (pickupSuccessful && !deliveryStarted) {
          setDeliveryStartedTime(dayjs(pickupSuccessful).format());
          setDeliveryArrivedTime(addLaneDuration(pickupSuccessful));
          setDeliverySuccessfulTime(addLaneDurationAndDeliveryInspection(pickupSuccessful));
        } else if (deliveryStarted && !deliveryArrived) {
          setDeliveryArrivedTime(addLaneDuration(deliveryStarted));
          setDeliverySuccessfulTime(addLaneDurationAndDeliveryInspection(deliveryStarted));
        } else if (deliveryArrived && !deliverySuccessful) {
          setDeliverySuccessfulTime(addDeliveryInspectionDuration(deliveryArrived));
        }
        break;

      default:
        break;
    }
    setIsManualEdit(false);
    setAutoEstimate(null);
  };

  const handleTimestampEntryGuide = () => {
    const timestampAssessment = {
      pickupStarted: {
        disabled: status === 'dispatched',
        highlight:
          (status === 'pickup started' && !pickupStarted) ||
          (status === 'pickup arrived' && !pickupStarted) ||
          (status === 'pickup successful' && !pickupStarted) ||
          (status === 'delivery started' && !pickupStarted) ||
          (status === 'delivery arrived' && !pickupStarted) ||
          (status === 'delivery successful' && !pickupStarted),
      },
      pickupArrived: {
        disabled: !pickupStarted || compareStatus(status, 'pickup arrived') === 'earlier',
        highlight:
          (status === 'pickup arrived' && pickupStarted && !pickupArrived) ||
            (status === 'pickup successful' && pickupStarted && !pickupArrived) ||
            (status === 'delivery started' && pickupStarted && !pickupArrived) ||
            (status === 'delivery arrived' && pickupStarted && !pickupArrived) ||
            (status === 'delivery successful' && pickupStarted && !pickupArrived),
      },
      pickupSuccessful: {
        disabled: !pickupArrived || compareStatus(status, 'pickup successful') === 'earlier',
        highlight:
          (status === 'pickup successful' && pickupArrived && !pickupSuccessful) ||
            (status === 'delivery started' && pickupArrived && !pickupSuccessful) ||
            (status === 'delivery arrived' && pickupArrived && !pickupSuccessful) ||
            (status === 'delivery successful' && pickupArrived && !pickupSuccessful),
      },
      deliveryStarted: {
        disabled: !pickupSuccessful || compareStatus(status, 'delivery started') === 'earlier',
        highlight:
          (status === 'delivery started' && pickupSuccessful && !deliveryStarted) ||
            (status === 'delivery arrived' && pickupSuccessful && !deliveryStarted) ||
            (status === 'delivery successful' && pickupSuccessful && !deliveryStarted),
      },
      deliveryArrived: {
        disabled: !deliveryStarted || compareStatus(status, 'delivery arrived') === 'earlier',
        highlight:
          (status === 'delivery arrived' && deliveryStarted && !deliveryArrived) ||
            (status === 'delivery successful' && deliveryStarted && !deliveryArrived),
      },
      deliverySuccessful: {
        disabled: !deliveryArrived || compareStatus(status, 'delivery successful') === 'earlier',
        highlight: status === 'delivery successful' && deliveryArrived && !deliverySuccessful,
      },
    };
    return timestampAssessment;
  };

  const compareStatus = (newStatus, oldStatus) => {
    // Find the index of the original and new statuses
    const originalIndex = driveStatuses.findIndex(status => status.value === oldStatus);
    const newIndex = driveStatuses.findIndex(status => status.value === newStatus);

    // Compare indices to determine whether the move's status was set forward or back
    if (newIndex > originalIndex) {
      return 'later';
    } else if (newIndex < originalIndex) {
      return 'earlier';
    } else {
      return 'same';
    }
  };

  const clearSubsequentTimestamps = currentStatus => {
    const currentStatusIndex = driveStatuses.findIndex(status => status.value === currentStatus);

    if (currentStatusIndex === -1) return; // If status not found, exit

    // Array of functions to clear the state
    const clearFunctions = [
      setPickupStartedTime,
      setPickupArrivedTime,
      setPickupSuccessfulTime,
      setDeliveryStartedTime,
      setDeliveryArrivedTime,
      setDeliverySuccessfulTime,
    ];

    // Iterate over subsequent statuses and clear their corresponding state
    for (let i = currentStatusIndex; i < clearFunctions.length; i++) {
      clearFunctions[i](null);
    }
  };

  const handleStatusChange = event => {
    const localStatus = event.target.value;

    if (input.moveType === `drive`) {
      const newStatusObj = driveStatuses.find(ds => ds.value === localStatus);
      setStatusObj(newStatusObj);
    }
    if (input.moveType === `ride`) {
      const newStatusObj = rideStatuses.find(ds => ds.value === localStatus);
      setStatusObj(newStatusObj);
    }

    const orderComparison = compareStatus(localStatus, status);

    const prevStatus = status;
    setStatus(localStatus);

    log && console.log('Status updated to', localStatus);

    //It's not enough to compare the the order of the old and new statuses. Since the status and timestamps can be changed forward and back multiple times before the dispatcher clicks "save"
    //we need to also check the status against the existing timestamps and, if needed, compare to the original status from the move. 
    if (orderComparison === 'earlier') {
      clearSubsequentTimestamps(localStatus);
      if (localStatus === 'pickup started' && pickupStarted) {
        setAutoEstimate(null)
      } else if (localStatus === 'pickup arrived' && pickupArrived) {
        setAutoEstimate(null)
      } else if (localStatus === 'pickup successful' && pickupSuccessful) {
        setAutoEstimate(null)
      } else if (localStatus === 'delivery started' && deliveryStarted) {
        setAutoEstimate(null)
      } else if (localStatus === 'delivery arrived' && deliveryArrived) {
        setAutoEstimate(null)
      } else if (localStatus === 'delivery successful' && deliverySuccessful) {
        setAutoEstimate(null)
      } else {
        const compareOriginal = compareStatus(localStatus, input?.move?.status);
        if (compareOriginal === 'later') {
          setAutoEstimate({ newStatus: localStatus, oldStatus: input?.move?.status });
        } else setAutoEstimate(null);
      }
    } else if (orderComparison === 'later') {
      setAutoEstimate({ newStatus: localStatus, oldStatus: prevStatus });
    } else setAutoEstimate(null);
  };

  const handlePickupStartedTimeChange = time => {
    setIsManualEdit(true);
    setPickupStartedTime(dayjs(time).format());
    clearSubsequentTimestamps('pickup started');
    const orderComparison = compareStatus('pickup started', status);
    if (orderComparison === 'earlier') {
      setAutoEstimate({ newStatus: 'pickup started', oldStatus: status });
    }
  };

  const handlePickupArrivedTimeChange = time => {
    setIsManualEdit(true);
    setPickupArrivedTime(dayjs(time).format());
    clearSubsequentTimestamps('pickup arrived');
    const orderComparison = compareStatus('pickup arrived', status);
    if (orderComparison === 'earlier') {
      setAutoEstimate({ newStatus: 'pickup arrived', oldStatus: status });
    }
  };

  const handlePickupSuccessfulTimeChange = time => {
    setIsManualEdit(true);
    setPickupSuccessfulTime(dayjs(time).format());
    clearSubsequentTimestamps('pickup successful');
    const orderComparison = compareStatus('pickup successful', status);
    if (orderComparison === 'earlier') {
      setAutoEstimate({ newStatus: 'pickup successful', oldStatus: status });
    }
  };

  const handleDeliveryStartedTimeChange = time => {
    setIsManualEdit(true);
    setDeliveryStartedTime(dayjs(time).format());
    clearSubsequentTimestamps('delivery started');
    const orderComparison = compareStatus('delivery started', status);
    if (orderComparison === 'earlier') {
      setAutoEstimate({ newStatus: 'delivery started', oldStatus: status });
    }
  };

  const handleDeliveryArrivedTimeChange = time => {
    setIsManualEdit(true);
    setDeliveryArrivedTime(dayjs(time).format());
    clearSubsequentTimestamps('delivery arrived');
    const orderComparison = compareStatus('delivery arrived', status);
    if (orderComparison === 'earlier') {
      setAutoEstimate({ newStatus: 'delivery arrived', oldStatus: status });
    }
  };

  const handleDeliverySuccessfulTimeChange = time => {
    setIsManualEdit(true);
    setDeliverySuccessfulTime(dayjs(time).format());
  };

  const checkOutput = () => {
    if (status === 'dispatched') return false;
    else if (status === 'pickup started' && pickupStarted === null) return true;
    else if (status === 'pickup arrived' && (pickupStarted === null || pickupArrived === null)) return true;
    else if (
      status === 'pickup successful' &&
      (pickupArrived === null || pickupStarted === null || pickupSuccessful === null)
    )
      return true;
    else if (
      status === 'delivery started' &&
      (pickupArrived === null || pickupStarted === null || pickupSuccessful === null || deliveryStarted === null)
    )
      return true;
    else if (
      status === 'delivery arrived' &&
      (pickupArrived === null ||
        pickupStarted === null ||
        pickupSuccessful === null ||
        deliveryStarted === null ||
        deliveryArrived === null)
    )
      return true;
    else if (
      status === 'delivery successful' &&
      (pickupArrived === null ||
        pickupStarted === null ||
        pickupSuccessful === null ||
        deliveryStarted === null ||
        deliveryArrived === null ||
        deliverySuccessful === null)
    )
      return true;
    else return false;
  };

  const handleSubmit = async () => {
    // Init output object with statuses
    let output = {
      move: input.move,
      status,
      pickupStarted,
      pickupArrived,
      pickupSuccessful,
      deliveryStarted,
      deliveryArrived,
      deliverySuccessful,
    };

    log && console.log('The output is: ', output);

    if (input && input.move && input.move.status && input.move.status !== status) {
      let moveId;
      let customerId;
      let laneId;
      let driverId;

      if (input.move.customer && input.move.customer.id) {
        customerId = input.move.customer.id;
      } else customerId = null;
      if (input.move.id) {
        moveId = input.move.id;
      } else moveId = null;
      if (input.move.lane && input.move.lane.id) {
        laneId = input.move.lane.id;
      } else laneId = null;
      if (input.move.driver_id) {
        driverId = input.move.driver_id;
      } else driverId = null;

      try {
        const userEmail = getUserEmail();
        log && console.log('--EVENT LOG--');
        // Insert move update eventlog
        let eventConfig = {
          action: `move.status.overridden`,
          user: userEmail,
          role: 'admin',
          move_id: moveId,
          customer_id: customerId,
          lane_id: laneId,
          driver_id: driverId,
          metadata: { oldStatus: `${input.move.status}`, newStatus: `${status}` } || null,
        };
        let logRes = await sdk.events.buildAndCreate(eventConfig);
        log && console.log(`Successfully inserted eventlog ${logRes.id}`);
      } catch (err) {
        //Can't return bc other functions need to run
        console.error('Failed to insert eventlog', err);
      }
    }

    handleClose(output);
  };

  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        {input ? (
          <>
            <ModalHeader handleClose={handleClose}>Edit Status</ModalHeader>

            <ModalContent
              subtitle={`Edit the current move's statuses stored in our database. Your changes will be made upon clicking 'Save Status.' NOTE: Reverting to an earlier stage in the move will eliminate existing timestamps from later stages in the process. To edit times manually, see below.`}
            >
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs>
                  <TextField
                    select
                    fullWidth
                    label='Status'
                    placeholder='Select a status...'
                    variant='outlined'
                    size='small'
                    value={status}
                    onChange={handleStatusChange}
                    InputLabelProps={{ shrink: true }}
                  >
                    {input.moveType === `drive`
                      ? driveStatuses.map((s, i) => (
                          <MenuItem key={`modal-drive-status-${i}`} className={cls.menuItem} value={s.value}>
                            <Typography className={cls.nameTxt}>{s.name}</Typography>
                            <Typography className={cls.descTxt}>{s.desc}</Typography>
                          </MenuItem>
                        ))
                      : null}

                    {input.moveType === `ride`
                      ? rideStatuses.map((s, i) => (
                          <MenuItem key={`modal-ride-status-${i}`} className={cls.menuItem} value={s.value}>
                            <Typography className={cls.nameTxt}>{s.name}</Typography>
                            <Typography className={cls.descTxt}>{s.desc}</Typography>
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
              </Grid>

              <Spacer />

              <ModalHeader className={cls.subHeader} handleClose={handleClose}>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography style={{ fontWeight: 500, fontSize: '21px', marginRight: '10px' }}>
                      Manually Update Status Times
                    </Typography>
                  </Grid>
                  {autoEstimate?.oldStatus && autoEstimate.newStatus ? (
                    <Tooltip title='Automatically estimate timestamps based on lane data and the most recent status update available'>
                      <Grid>
                        <Button onClick={handleShowEstimate} color='primary'>
                          Auto-Estimate Times
                        </Button>
                      </Grid>
                    </Tooltip>
                  ) : null}
                </Grid>
              </ModalHeader>

              <Spacer />

              <Grid container spacing={2} wrap='nowrap'>
                <Grid container item xs={6} direction='column'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.pickupStarted?.highlight
                          ? 'rgba(244, 66,63, 0.1)'
                          : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Pickup Started Time'
                        inputVariant='outlined'
                        value={pickupStarted}
                        onChange={handlePickupStartedTimeChange}
                        disabled={status === 'dispatched'}
                      />
                    </div>
                  </MuiPickersUtilsProvider>

                  <Spacer />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.pickupArrived?.highlight
                          ? 'rgba(244, 66,63, 0.1)'
                          : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Pickup Arrived Time'
                        inputVariant='outlined'
                        value={pickupArrived}
                        onChange={handlePickupArrivedTimeChange}
                        disabled={handleTimestampEntryGuide()?.pickupArrived?.disabled}
                      />
                    </div>
                  </MuiPickersUtilsProvider>

                  <Spacer />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.pickupSuccessful?.highlight ? 'rgba(244, 66,63, 0.1)' : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Pickup Successful Time'
                        inputVariant='outlined'
                        value={pickupSuccessful}
                        onChange={handlePickupSuccessfulTimeChange}
                        disabled={handleTimestampEntryGuide()?.pickupSuccessful?.disabled}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid container item xs={6} direction='column'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.deliveryStarted?.highlight ? 'rgba(244, 66,63, 0.1)' : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Delivery Started Time'
                        inputVariant='outlined'
                        value={deliveryStarted}
                        onChange={handleDeliveryStartedTimeChange}
                        disabled={handleTimestampEntryGuide()?.deliveryStarted?.disabled}
                      />
                    </div>
                  </MuiPickersUtilsProvider>

                  <Spacer />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.deliveryArrived?.highlight ? 'rgba(244, 66,63, 0.1)' : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Delivery Arrived Time'
                        inputVariant='outlined'
                        value={deliveryArrived}
                        onChange={handleDeliveryArrivedTimeChange}
                        disabled={handleTimestampEntryGuide()?.deliveryArrived?.disabled}
                      />
                    </div>
                  </MuiPickersUtilsProvider>

                  <Spacer />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      style={{
                        backgroundColor: handleTimestampEntryGuide()?.deliverySuccessful?.highlight ? 'rgba(244, 66,63, 0.1)' : '',
                      }}
                    >
                      <DateTimePicker
                        fullWidth
                        orientation='portrait'
                        label='Delivery Successful Time'
                        inputVariant='outlined'
                        value={deliverySuccessful}
                        onChange={handleDeliverySuccessfulTimeChange}
                        disabled={handleTimestampEntryGuide()?.deliverySuccessful?.disabled}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </ModalContent>

            <ModalFooter>
              {isDisabled ? (
                <Typography className={cls.errorTxt}>
                  Please add timestamps for all statuses up to and including the current status
                </Typography>
              ) : null}
              <ModalAction disabled={isDisabled} onClick={() => handleSubmit()} color='primary'>
                Save Status
              </ModalAction>
              <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'block',
  },
  nameTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  descTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  errorTxt: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    color: 'red',
  },
  subHeader: {
    fontSize: 10,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveStatusModal;
