//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, DialogContent, Typography } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function ModalContent(props) {
  const cls = useStyles();
  const { className, subtitleClassName, subtitle, disableDividers, disablePadding } = props;
  return (
    <DialogContent
      dividers={!disableDividers}
      className={(disablePadding ? cls.noPadding : cls.padding) + ' ' + className}
    >
      {subtitle && (
        <>
          <Typography className={cls.subtitle + ' ' + subtitleClassName}>{subtitle}</Typography>
          <div className={cls.break} />
        </>
      )}
      {props.children}
    </DialogContent>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  noPadding: {
    padding: 0,
    margin: 0,
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
