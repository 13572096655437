//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useQuery, gql } from '@apollo/client';

import Switch from '../../Switch';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  required: `config.require_third_party_payer`,
  defaultPayerId: `config.default_payer`,
};

// DEFAULTS //
const getDefaultRequired = (overrideRef, inheritedRef) => {
  if (getPropValue(overrideRef, option?.required) === false) return false;
  if (getPropValue(overrideRef, option?.required) === true) return true;
  return getPropValue(inheritedRef, option?.required);
};
const getDefaultPayerId = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.defaultPayerId) || getPropValue(inheritedRef, option?.defaultPayerId);
};

//////////////////////// COMPONENT ////////////////////////

export default function ThirdPartyPayer({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [required, setRequired] = React.useState(getDefaultRequired(overrideRef, inheritedRef));
  const [defaultPayerId, setDefaultPayerId] = React.useState(getDefaultPayerId(overrideRef, inheritedRef));

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setRequired(getDefaultRequired(null, inheritedRef));
      setDefaultPayerId(getDefaultPayerId(null, inheritedRef));
    }
  }, [isReset]);

  // Handle required change
  const handleRequiredChange = () => {
    // Get the value
    const value = !required;

    // Set the state variable, build the changes and update the mutable ref
    let changes = {};
    if (value === true) {
      changes = { config: { require_third_party_payer: value } };
    } else {
      changes = { config: { require_third_party_payer: value, default_payer: null } };
      setDefaultPayerId(null);
    }
    setRequired(value);
    updateMutableRef(changes);
  };

  // Handle default payer change
  const handleDefaultPayerChange = e => {
    // Get the value
    const value = e.target.value;

    // Set the state variable, build the changes and update the mutable ref
    const changes = { config: { default_payer: value || null } };
    setDefaultPayerId(value);
    updateMutableRef(changes);
  };

  //////////////////////// QUERY ////////////////////////

  const { error, data } = useQuery(GET_PAYERS);

  // ERROR STATE //
  if (error) {
    console.error(`Failed to fetch payers:`, error);
  }

  // DATA STATE //
  let payers = JSON.parse(JSON.stringify(data?.payers || []));
  payers = payers?.map(payer => payer?.payer);
  payers = payers?.sort((a, b) => a?.name?.localeCompare(b?.name));

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Third Party Payer`}
        tip={`The third party payer module contains options for clients with third party payers.`}
      />

      <SettingsOption
        contained
        title={`Required`}
        description={`Require the client to input a third party payer when creating moves through the portal or API.`}
        value={required}
        checkReset={() => checkMutableRefField(option?.required)}
        onReset={() =>
          deleteMutableRefField(option?.required, () => setRequired(getPropValue(inheritedRef, option?.required)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        <Switch color='primary' checked={required} onChange={() => handleRequiredChange()} />
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Default Payer`}
        description={`The default payer for moves created through the portal or API. For now, we have to select this from the full list of customers. Please be sure you have selected the correct customer before you save. To remove the default payer, select 'None'.`}
        value={defaultPayerId}
        checkReset={() => checkMutableRefField(option?.defaultPayerId)}
        onReset={() =>
          deleteMutableRefField(option?.defaultPayerId, () =>
            setDefaultPayerId(getPropValue(inheritedRef, option?.defaultPayerId))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          select
          fullWidth
          label='Default Payer'
          placeholder='Select a payer...'
          size='small'
          variant='outlined'
          value={defaultPayerId || 0}
          onChange={handleDefaultPayerChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  currency_exchange
                </Icon>
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>

          {payers?.map(customer => (
            <MenuItem key={`payers-${customer?.id}`} value={customer?.id}>
              {customer?.name} (#{customer?.id})
            </MenuItem>
          ))}
        </TextField>
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
}));

//////////////////////// GQL ////////////////////////

const GET_PAYERS = gql`
  query admin_getPayers {
    payers: payertocustomer(distinct_on: [payer_id]) {
      id
      payer_id
      payer {
        id
        name
      }
    }
  }
`;
