// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { makeStyles, TextField, InputAdornment, Icon } from '@material-ui/core';

// COMPONENT ---------------------------------------------------------------- //

export default function GPFormTime({ initialFormData, formData, setFormData }) {
  const cls = useStyles();

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [startTime, setStartTime] = React.useState(dayjs().add(15, `minute`).ceil(15, `minute`).format(`HH:mm`));
  const [endTime, setEndTime] = React.useState(
    dayjs().add(15, `minute`).add(8, `hour`).ceil(15, `minute`).format(`HH:mm`)
  );

  /** Handle the start time change */
  const handleStartTimeChange = e => {
    const value = e?.target?.value;

    setStartTime(value);
    setFormData({ ...formData, startTime: value });
  };

  /** Handle the end time change */
  const handleEndTimeChange = e => {
    const value = e?.target?.value;

    setEndTime(value);
    setFormData({ ...formData, endTime: value });
  };

  // Handle both times when either is blurred
  const handleBlur = () => {
    const minimumEndTime = dayjs(`${formData?.date}T${startTime}`).add(2, `hour`).format(`HH:mm`);
    const currentMinimumEndTime = dayjs(`${formData?.date}T${endTime}`).subtract(2, `hour`).format(`HH:mm`);

    if (endTime < minimumEndTime) {
      toast.warning(`End time was adjusted to be 2 hours after the start time.`, {
        toastId: `gp-form-time`,
      });
      setEndTime(minimumEndTime);
      setFormData({ ...formData, endTime: minimumEndTime });
    } else if (startTime > currentMinimumEndTime) {
      toast.warning(`End time was adjusted to be 2 hours after the start time.`, {
        toastId: `gp-form-time`,
      });
      setEndTime(minimumEndTime);
      setFormData({ ...formData, endTime: currentMinimumEndTime });
    }
  };

  // Set the initial start and end times
  React.useEffect(() => {
    if (initialLoad) {
      if (initialFormData?.startTime) setStartTime(initialFormData?.startTime);
      if (initialFormData?.endTime) setEndTime(initialFormData?.endTime);
      setInitialLoad(false);
    }
  }, [initialLoad, initialFormData]);

  return (
    <div className={cls.root}>
      <div className={cls.flex}>
        <div className={cls.flexItemStretch}>
          <label className={cls.textFieldLabel} htmlFor='startTime'>
            What is the planned start time for this group?<span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            required
            fullWidth
            type='time'
            name='startTime'
            variant='outlined'
            size='small'
            helperText={dayjs().tz(formData?.timezone).format(`zzz (z)`)}
            value={startTime}
            onChange={handleStartTimeChange}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    play_arrow
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={cls.flexItemStretch}>
          <label className={cls.textFieldLabel} htmlFor='endTime'>
            What is the cutoff time for planning this group?<span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            required
            fullWidth
            type='time'
            name='endTime'
            variant='outlined'
            size='small'
            helperText={dayjs().tz(formData?.timezone).format(`zzz (z)`)}
            value={endTime}
            onChange={handleEndTimeChange}
            onBlur={handleBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon color='disabled' fontSize='small'>
                    stop
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    paddingBottom: theme.spacing(4),
  },

  textFieldLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  textFieldLabelRequired: {
    color: theme.palette.error.main,
  },

  flex: {
    display: `flex`,
    gap: theme.spacing(2),
  },
  flexItemStretch: {
    flex: 1,
  },
}));
