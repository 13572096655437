//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useData } from '../../providers/DataProvider';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import gql from 'graphql-tag';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';
import AvatarUpload from '../../components/UserManagement/AvatarUpload';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserAddForm({
  initialState,
  superAdminOverride,
}) {
  const cls = useStyles();
  const ctx = useUserDetails();
  const { apolloClient } = useData();

  const [customers, setCustomers] = React.useState([])

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSelectedCustomers = selections => {
    ctx?.setAllowedCustomers(selections);
  };

  const handleRemoveCustomer = index => {
    ctx?.setAllowedCustomers(prevCustomers => {
      return prevCustomers.filter((_, i) => i !== index);
    });

    ctx?.setAllowedCustomerIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  const getAllCustomers = async () => {
    const res = await apolloClient.query({ query: GET_CUSTOMERS });
    const allCustomers = res?.data?.customers;
    setCustomers(allCustomers);
  };

  React.useEffect(() => {
      if (initialState?.orgCustomers?.length) {
        setCustomers(initialState.orgCustomers);
      } else if (initialState?.customer) {
        getOrgCustomers(initialState?.customer.id)
      } 
      else {
        getAllCustomers();
      }
  }, []);

  const getOrgCustomers = async (customerId) => {
    try {
      const res = await apolloClient.query({
        query: GET_CUSTOMERS_BY_ORG,
        variables: {customerId}
      })
      if (res?.data?.customers[0]?.organization?.customers) {
        setCustomers(res.data.customers[0].organization.customers)
        if (ctx?.defaultRole && ctx?.defaultRole === 'dealer-super-admin') {
          ctx?.setAllowedCustomers(res.data.customers[0].organization.customers)
        }

      }
    } catch (err) {
      console.log('error getting org customers', err)
    }
  }

  React.useEffect(() => {
    let selectedCustomers = [];

    if (ctx) {
      if (customers?.length > 0 && ctx?.defaultRole && ctx?.defaultRole === 'dealer-super-admin') ctx?.setAllowedCustomers(customers) 
      if (initialState && initialState?.customer) {
        if (ctx?.defaultRole && ctx?.defaultRole === 'dealer-admin') {
          ctx?.setAllowedCustomers([initialState?.customer])
        }
        if (!ctx?.customerId) {
          selectedCustomers.push(initialState?.customer?.id)
          ctx?.setCustomerId(initialState?.customer?.id)
        }
      }
  
      if (ctx?.customerId && (ctx?.defaultRole === 'dealer-super-admin' || ctx?.defaultRole === 'dealer-admin')) {
        if (ctx?.allowedCustomerIds?.length > 0 && !ctx?.allowedCustomerIds.includes(ctx?.customerId)) {
          selectedCustomers = [...ctx?.allowedCustomerIds, ctx?.customerId];
        } else {
          selectedCustomers.push(ctx?.customerId);
        }
      } 
      ctx?.setAllowedCustomerIds(selectedCustomers);
    }

  }, [ctx?.defaultRole, customers, ctx?.customerId])

  return (
    <Container maxWidth='lg'>

     {/* VITALS */}
     <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx?.isSaving}
              required
              fullWidth
              multiline
              label='Display Name'
              placeholder='Enter display name...'
              variant='outlined'
              size='small'
              value={ctx?.displayName || ''}
              onChange={handleInputChange(ctx?.setDisplayName)}
              error={ctx?.validation?.displayName === false}
              helperText={ctx?.validation?.displayName === false ? `A display name is required!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      person
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

     <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx?.isSaving}
              required
              fullWidth
              multiline
              label='Email'
              placeholder='Enter email address...'
              variant='outlined'
              size='small'
              value={ctx?.email || ''}
              onChange={handleInputChange(ctx?.setEmail)}
              error={ctx?.validation?.email === false}
              helperText={ctx?.validation?.email === false ? `A valid email address is required!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      mail
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Phone (Optional)
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx?.isSaving}
              fullWidth
              multiline
              label='Phone'
              placeholder='Enter phone number...'
              variant='outlined'
              size='small'
              value={ctx?.phone || ''}
              onChange={handleInputChange(ctx?.setPhone)}
              error={ctx?.validation?.phone === false}
              helperText={ctx?.validation?.phone === false ? `Phone number is invalid!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      phone
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Avatar (Optional)
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
          <AvatarUpload avatar={ctx.avatarUrl} setAvatar={ctx.setAvatarUrl} userId={ctx.userId} />
          </div>
        </Grid>
      </Grid>

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Default Customer <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              The main customer/rooftop with which the user is associated. This field is required for all dealer users.
              If you are creating a user with the dealer super admin role, they will receive permissions for all
              rooftops within the organization. If you are creating a dealer admin user, you will be prompted to add
              additional rooftops below.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx?.isSaving || initialState?.customer}
              select
              fullWidth
              label='Default Customer'
              placeholder='Select the default customer...'
              variant='outlined'
              size='small'
              value={customers?.find(c => c.id === ctx?.customerId)?.id || ''}
              // defaultValue={customers?.find(c => c.id === initialState?.customer?.id)?.id}
              onChange={handleInputChange(ctx?.setCustomerId)}
              error={ctx?.validation?.customerId === false}
              helperText={ctx?.validation?.customerId === false ? `Default customer is required!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      roofing
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              {customers && Array.isArray(customers) && customers?.length &&
                customers?.map((c, i) => (
                  <MenuItem key={`organization-${c?.id}`} value={c?.id}>
                    {c?.name} (#{c?.id})
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              User Role <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            {superAdminOverride ? (
            <Typography className={cls.sectionSubtitleTxt}>
            <b>Dealer Super Admin</b> - This user will have full, automatic access to all customers/rooftops within an
            organization. The first user added to a customer will automatically have this role so that they can add additional users for their rooftop.
          </Typography>
            ) : (
              <>
              <Typography className={cls.sectionSubtitleTxt}>
              Select a default role for the user, which will determine their level of access to the customer or
              organization's rooftops.
            </Typography>
            <Spacer />
            {/* <Typography className={cls.sectionSubtitleTxt}>
              <b>Dealer Super Admin</b> - This user will have full, automatic access to all customers/rooftops within an
              organization. They can also add new dealer super admin, dealer admin, and dealer users.
            </Typography> */}
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Dealer Admin</b> - This user will have access to the subset of customers/rooftops that you will be
              prompted to specify.
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              <b>Dealer</b> - This user will have access to a single customer/rooftop that you will be prompted to
              specify.
            </Typography>
            </>
            )}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx?.isSaving
                //  || superAdminOverride
                }
              required
              select
              fullWidth
              label='Role'
              placeholder='Select a role...'
              variant='outlined'
              size='small'
              value={ctx?.defaultRole || ''}
              onChange={handleInputChange(ctx?.setDefaultRole)}
              error={ctx?.validation?.defaultRole === false}
              helperText={ctx?.validation?.defaultRole === false ? 'Select a default role!' : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              {/* <MenuItem value='dealer-super-admin'>Dealer Super Admin</MenuItem> */}
              <MenuItem value='dealer-admin'>Dealer Admin</MenuItem>
              <MenuItem value='dealer'>Dealer</MenuItem>
            </TextField>
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx?.defaultRole && ctx?.defaultRole === 'dealer-admin' && (
        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>
                Allowed Customers <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                Dealer admin users must be given access to at least one customer/rooftop in addition to their default.
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            <div className={cls.sectionR}>
              <ReusableMultiselect
                dataArray={customers}
                selectedArray={ctx?.allowedCustomerIds}
                optionName={'name'}
                label={'Allowed Customer'}
                handleOutput={handleSelectedCustomers}
              />

              <Spacer />
              {ctx?.allowedCustomers?.map((customer, index) => (
                <Chip
                  key={index}
                  style={{margin: 5}}
                  label={customer.name}
                  onDelete={() => handleRemoveCustomer(index)}
                />
              ))}
                      {ctx?.validation?.allowedCustomers === false && (
          <Typography variant="body2" color="error">
            Dealer admins must have access to at least one customer/rooftop in addition to their default.
          </Typography>
        )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const GET_CUSTOMERS = gql`
  query get_customers {
    customers(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_CUSTOMERS_BY_ORG = gql`
query get_org_customers($customerId: bigint!) {
  customers(where: {id: {_eq: $customerId}}) {
    organization {
      customers {
        id
        name
      }
    }
  }
}
`

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
}));
