import React from 'react';
import { useTheme, makeStyles, Grid, IconButton, Icon, Tooltip } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useUserDetails } from '../../providers/UserDetailsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserDetailsToolbar() {
  const theme = useTheme();
  const cls = useStyles();

  const { editMode, setEditMode, isSaving, handleSave } = useUserDetails();

  return (
    <Grid container alignItems='center'>
      <Grid item xs />

      {editMode ? (
        <Grid item>
          <Tooltip placement='top' title={`Click to lock & save your changes`}>
            <Button
              loading={isSaving}
              disabled={isSaving}
              className={cls.bigBtn}
              color='primary'
              onClick={() => handleSave('dealer')}
            >
              Save Changes
            </Button>
          </Tooltip>
        </Grid>
      ) : null}

        <Grid item>
          <Tooltip
            placement='top'
            title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the driver`}
          >
            <IconButton
              style={{
                color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              className={cls.iconBtn}
              onClick={() => setEditMode(!editMode)}
            >
              <Icon>{editMode ? `lock_open` : `lock`}</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  bigBtn: {
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
}));