import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DriverScheduleRow from './DriverScheduleRow';

let log = false;

export default function DriverScheduleBody(props) {
  const cls = useStyles();

  const { drivers, schedules } = props;
  const [driversWithSchedules, setDriversWithSchedules] = useState([]);

  useEffect(() => {
    if (drivers && schedules) {
      log && console.log('drivers', drivers);
      setDriverSchedules(drivers, schedules);
    }
  }, [drivers, schedules]);

  let setDriverSchedules = (drivers, schedules) => {
    log && console.log('schedules', schedules);
    drivers.forEach(driver => {
      let driverSchedules = schedules.filter(schedule => schedule.driver_id === driver.fleet_id);
      let scheduleDetailArr = driverSchedules.map(sched => {
        return { id: sched.id, type: sched.type, details: sched.details };
      });
      driver.schedule = scheduleDetailArr;
      if (driver.schedule.length > 0) log && console.log('driver with schedule', driver);
    });
    setDriversWithSchedules(drivers);
  };
  return (
    <div className={cls.container}>
      {driversWithSchedules.map(driver => {
        return (
          <DriverScheduleRow
            className={cls.row}
            key={'Driver' + driver.fleet_id}
            driver={driver}
            // refetch={props.refetch}
          />
        );
      })}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.primary,
    /* grid container settings */
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    overflow: 'hidden',
    gridTemplateAreas: 'header main footer',
  },
  row: {
    height: '60px',
    display: 'inline-grid',
    gridTemplateColumns: '16% repeat(14, 6%)',
  },
}));
