//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, TableContainer, Table, TablePagination } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { getUserRole } from '../../utils/authHelper';

import { DisputedTableHead, DisputedTableBody } from './index';

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

//////////////////////// COMPONENT ////////////////////////

function InvoiceTable(props) {
  const cls = useStyles();
  const { capFirst, clampNegNum } = useTools();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [userRole, setUserRole] = React.useState();

  React.useEffect(() => {
    const runAsync = async () => {
      let userRole = await getUserRole();
      setUserRole(userRole);
    };

    runAsync();
  }, []);

  const { disputedMoves, handleARReviewModalOpen, handleARLogsModalOpen } = props;

  // Handle page state
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle pagination rows state
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Set headers array used in the table
  const headers = [
    { id: `MOVE_ID`, align: `left`, label: `Move\xa0ID` },
    { id: `REF_NUM`, align: `left`, label: `Ref\xa0#` },
    { id: `CUSTOMER`, align: `left`, label: `Customer` },
    { id: `MOVE_DATE`, align: `left`, label: `Move\xa0Date` },
    { id: `DISPUTE`, align: `left`, label: `Dispute\xa0Reason` },
    { id: `DISCOUNT`, align: `right`, label: `Discount` },
    { id: `TOTAL`, align: `right`, label: `Total` },
    { id: `BUTTON`, align: `right`, label: `` },
  ];

  // A cleaner way to build the row object for the table (stronger logic when it comes to error handling)
  const buildRowObject = (armove = null) => {
    // Initialize the row object
    let row = {};

    // Check for armove and set each row field
    if (armove) {
      row.armove = armove;

      if (armove.move) {
        row.MOVE_ID = armove.move.id || null;
        row.REF_NUM = armove.move.reference_num || null;

        if (armove.move.customer) {
          row.CUSTOMER = armove.move.customer.name || null;
        }
      }

      row.MOVE_DATE = armove.billable_datetime ? dayjs(armove.billable_datetime).format(`MM/DD/YYYY`) : null;
      row.DISPUTE = capFirst(armove.dispute_reason) || null;
      row.DISCOUNT =
        armove.discount_amount > 0
          ? `$${clampNegNum(armove.discount_amount).toFixed(2)}`
          : armove.discount_amount === 0
          ? `$0.00`
          : `-`;
      row.TOTAL =
        armove.due_amount > 0
          ? `$${clampNegNum(armove.due_amount - armove.discount_amount).toFixed(2)}`
          : armove.due_amount === 0
          ? `$0.00`
          : `-`;
      row.BUTTON = (
        <Button
          color='error'
          size='small'
          onClick={() => handleARReviewModalOpen(armove)}
          disabled={armove?.move?.settled && userRole !== 'admin'}
        >
          Review
        </Button>
      );
    }
    return row;
  };

  // Set rows array with the armove object attached to each row
  const rows = disputedMoves.map((armove, i) => {
    return buildRowObject(armove);
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='disputed-table'>
          <DisputedTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <DisputedTableBody
            rows={rows}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleARReviewModalOpen={handleARReviewModalOpen}
            handleARLogsModalOpen={handleARLogsModalOpen}
          />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InvoiceTable;
