// -------------------------------- IMPORTS -------------------------------- //

import { searchLocationOnGoogleMaps, searchLaneOnGoogleMaps } from '../../utils/GeneralUtils';
import {
  openMoveDetailsFromMove,
  openLaneDetailsFromMove,
  openOrganizationDetailsFromMove,
  openCustomerDetailsFromMove,
} from '../../utils/MoveUtils';

// -------------------------------- EXPORTS -------------------------------- //

/** Build the default drive actions list */
export const buildDefaultDriveActions = move => {
  const actions = [
    {
      label: `Open Move Details`,
      handler: move => openMoveDetailsFromMove(move),
      disabled: !move?.id,
    },
    {
      label: `Open Lane Details`,
      handler: move => openLaneDetailsFromMove(move),
      disabled: !move?.lane?.id,
    },
    {
      label: `Open Customer Details`,
      handler: move => openCustomerDetailsFromMove(move),
      disabled: !move?.customer?.id,
    },
    {
      label: `Open Organization Details`,
      handler: move => openOrganizationDetailsFromMove(move),
      disabled: !move?.customer?.organization?.id,
    },
    {},
    {
      label: `Search Pickup Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
      disabled: !move?.lane?.pickup?.address,
    },
    {
      label: `Search Delivery Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
      disabled: !move?.lane?.delivery?.address,
    },
    {
      label: `Search Directions`,
      handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
      disabled: !move?.lane?.pickup?.address || !move?.lane?.delivery?.address,
    },
  ];
  return actions;
};

/** Build the default ride actions list */
export const buildDefaultRideActions = move => {
  const actions = [
    {
      label: `Open Move Details`,
      handler: move => openMoveDetailsFromMove(move),
      disabled: !move?.id,
    },
    {
      label: `Open Lane Details`,
      handler: move => openLaneDetailsFromMove(move),
      disabled: !move?.lane?.id,
    },
    {
      label: `Open Customer Details`,
      handler: move => openCustomerDetailsFromMove(move),
      disabled: !move?.customer?.id,
    },
    {
      label: `Open Organization Details`,
      handler: move => openOrganizationDetailsFromMove(move),
      disabled: !move?.customer?.organization?.id,
    },
    {},
    {
      label: `Search Pickup Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
      disabled: !move?.lane?.pickup?.address,
    },
    {
      label: `Search Delivery Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
      disabled: !move?.lane?.delivery?.address,
    },
    {
      label: `Search Directions`,
      handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
      disabled: !move?.lane?.pickup?.address || !move?.lane?.delivery?.address,
    },
  ];
  return actions;
};
