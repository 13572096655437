//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Container } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import CustomerPricing from '../../components/CustomerPricing/CustomerPricing';
import CustomerDetailsPricingPaymentMethods from './CustomerDetailsPricingPaymentMethods';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsPricing({ customerId }) {
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_PRICING, {
    variables: {
      customerId,
    },
  });

  if (loading) return <DefaultLoadingFallback message={`Fetching pricing details`} />;

  if (error) {
    Sentry.captureException(error);
    console.error(error);
    return <DefaultErrorFallback message={`Error fetching pricing details`} />;
  }

  if (!data?.customers?.length) return <DefaultEmptyFallback message={`No pricing details found`} />;

  const customer = JSON.parse(JSON.stringify(data?.customers?.[0]));
  const slas = JSON.parse(JSON.stringify(data?.slas));

  return (
    <Container maxWidth='lg'>
      <Divide spacer tip={`View and manage the customer's established mileage bands, rates, and purchased products.`}>
        Pricing & Rates
      </Divide>

      <CustomerPricing customer={customer} slas={slas} refetch={() => refetch()} />

      <Divide
        spacer
        tip={`View the customer's payment methods. To manage payment methods, log into dealer portal, emulate this customer and navigate to the billing page.`}
      >
        Payment Methods
      </Divide>

      <CustomerDetailsPricingPaymentMethods paymentmethods={customer?.paymentmethods} />
    </Container>
  );
}

//////////////////////// GQL ////////////////////////

const GET_CUSTOMER_PRICING = gql`
  query admin_getCustomerPricing($customerId: bigint!) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      accounting_id
      active
      address
      auto_pay
      billing_frequency
      branded
      category
      config
      createdat
      email
      name
      notify_billing
      organization_id
      payment_terms
      phone
      updatedat
      location {
        timezone
      }
      organization {
        id
        name
      }
      paymentmethods(
        where: { active: { _eq: true }, type: { _nilike: "manual" } }
        order_by: { primary: desc_nulls_last }
      ) {
        id
        account_number
        active
        card_type
        expiration
        name
        primary
        routing_number
        type
      }
      raterulegroups(order_by: { end_date: desc }) {
        id
        begin_date
        createdat
        customer_id
        description
        end_date
        name
        updatedat
        insurancerate {
          id
          class_3_rate
          class_4_rate
          class_5_rate
          class_6_rate
          description
          effective_date
          expiration_date
          name
          rate
          state
          region {
            id
            name
          }
        }
        ridesharerate {
          id
          name
          description
          initial_cost
          service_fee
          per_minute_rate
          per_mile_rate
          minimum_ride_cost
          maximum_ride_cost
          effective_date
          expiration_date
        }
        productfees(where: { active: { _eq: true } }) {
          id
          active
          billable_date
          charge
          cost
          createdBy
          notes
          product_id
          updatedBy
          product {
            id
            active
            cost
            description
            name
            prepaid
            salable
            term
          }
        }
        raterules(where: { active: { _eq: "1" } }, order_by: { distance_start: asc }) {
          id
          class
          class_3_rate
          class_4_rate
          class_5_rate
          class_6_rate
          customer_id
          distance_end
          distance_start
          rate
          rate_rule_group_id
          type
        }
        slas_to_raterulegroup {
          id
          sla {
            id
            name
            label
            description
            duration_hrs
            public
          }
        }
      }
      raterules(where: { active: { _eq: "1" } }, order_by: { distance_start: asc }) {
        id
        class
        customer_id
        distance_end
        distance_start
        rate
        rate_rule_group_id
        type
      }
    }
    slas(where: { public: { _eq: false } }) {
      id
      name
      label
      description
      duration_hrs
      public
    }
  }
`;
