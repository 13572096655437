import React, { Fragment } from 'react';
import { Typography, makeStyles, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import dayjs from 'dayjs';

const EventLogItemDriverAppDump = ({ event }) => {
  const cls = useStyles();

  const url = event?.metadata?.archiveUrl;

  return (
    <Fragment key={'test'}>
      <div className={cls.lineBreak} />
      <div className={cls.listItem}>
        <Typography className={cls.keyTxt}>
          <b>Driver App Dump</b>
          <br />
          {dayjs(event?.event_time_utc).format('MM/DD/YYYY hh:mm A')}
        </Typography>
        <Typography className={cls.valTxt}>
          <Button
            onClick={async () => {
              window.open(url, '_blank');
            }}
            className={cls.button}
          >
            Download App Dump
            <Icon className={cls.linkIcon}>launch</Icon>
          </Button>
        </Typography>
      </div>
    </Fragment>
  );
};

export default EventLogItemDriverAppDump;

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    height: 40,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#999 !important',
    },
  },
  linkIcon: {
    marginTop: -4,
    marginBottom: -2,
    marginLeft: 10,
    fontSize: 20,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    minWidth: '50%',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));