//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, TextField, IconButton, Icon, Grid } from '@material-ui/core';

import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';

// OPTION PATHS //
const option = {
  customerValues: `config.customer_values`,
};

// DEFAULTS //
const getDefaultCustomerValues = (overrideRef, inheritedRef) => {
  return {
    config: {
      customer_values:
        getPropValue(overrideRef, option?.customerValues) || getPropValue(inheritedRef, option?.customerValues),
    },
  };
};

const getDefaultLocalCustomerValues = (overrideRef, inheritedRef) => {
  return getDefaultCustomerValues(overrideRef, inheritedRef)?.config?.customer_values;
};

//////////////////////// COMPONENT ////////////////////////

export default function CustomerValues({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();

  // Manage state of options
  const [localCustomerValues, setLocalCustomerValues] = React.useState(
    getDefaultLocalCustomerValues(overrideRef, inheritedRef)
  );
  const [customerValues, setCustomerValues] = React.useState(getDefaultCustomerValues(overrideRef, inheritedRef));
  const [newKey, setNewKey] = React.useState('');
  const [newValue, setNewValue] = React.useState('');
  const [addingNewPair, setAddingNewPair] = React.useState(true);

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setCustomerValues(getDefaultCustomerValues(null, inheritedRef));
    }
  }, [isReset]);

  React.useEffect(() => {
    setLocalCustomerValues(customerValues?.config?.customer_values);
  }, [customerValues]);

  React.useEffect(() => {
    if (customerValues?.config?.customer_values !== localCustomerValues) {
      setCustomerValues({ config: { customer_values: localCustomerValues } });
    }
  }, [localCustomerValues]);

  const handleAddKeyValue = () => {
    const currentConfig = { ...customerValues };
    currentConfig.config = currentConfig.config || {};
    currentConfig.config.customer_values = currentConfig.config.customer_values || {};

    const formattedKey = newKey.trim().toLowerCase().replace(/\s+/g, '_');

    currentConfig.config.customer_values[formattedKey] = newValue;

    setCustomerValues(currentConfig);
    setLocalCustomerValues(currentConfig.config.customer_values);
    setNewKey('');
    setNewValue('');
    updateMutableRef(currentConfig);
  };

  const handleEditValue = (keyToEdit, newValue) => {
    const updatedValues = { ...localCustomerValues };

    updatedValues[keyToEdit] = newValue;

    setLocalCustomerValues(updatedValues);
    updateMutableRef({ config: { customer_values: updatedValues } });
  };

  const handleRemoveKeyValue = keyToRemove => {
    const currentConfig = { ...customerValues };
    if (currentConfig.config && currentConfig.config.customer_values) {
      delete currentConfig.config.customer_values[keyToRemove];

      setCustomerValues(currentConfig);
      setLocalCustomerValues(currentConfig.config.customer_values);
      updateMutableRef({ config: { customer_values: currentConfig.config.customer_values } });
    }
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Custom Settings`}
        tip={`This module allows you to store custom data pertaining to the customer.`}
      />

      <SettingsOption
        contained
        title={`Custom Data Input`}
        description={`Some organizations and customers have specific data requirements (e.g., info required for reporting). You can use this module to specify those fields and their values.`}
        value={localCustomerValues}
        checkReset={() => checkMutableRefField(option?.customerValues)}
        onReset={() =>
          deleteMutableRefField(option?.customerValues, () =>
            setLocalCustomerValues(getPropValue(inheritedRef, option?.customerValues))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
      >
        {localCustomerValues &&
          Object.entries(localCustomerValues).map(([key, value]) => (
            <Grid container spacing={1} key={key}>
              <Grid item xs={5}>
                <TextField fullWidth value={key} InputProps={{ readOnly: true }} className={cls.readOnly} />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label='Value'
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={value}
                  onChange={e => handleEditValue(key, e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveKeyValue(key)}>
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        {addingNewPair && (
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <TextField
                size='small'
                variant='outlined'
                fullWidth
                label='New Key'
                value={newKey}
                onChange={e => setNewKey(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size='small'
                variant='outlined'
                fullWidth
                label='New Value'
                value={newValue}
                onChange={e => setNewValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleAddKeyValue} disabled={!newKey}>
                <Icon>add</Icon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {!addingNewPair && (
          <IconButton onClick={() => setAddingNewPair(true)} disabled={!newKey}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  readOnly: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove underline
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none', // Remove underline on hover
    },
    '& .MuiInput-underline:hover:after': {
      borderBottom: 'none', // Remove underline on hover
    },
  },
}));
