// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

const log = false;

// CONTEXT ---------------------------------------------------------------- //

const FilterContext = React.createContext({});

// PROVIDER ---------------------------------------------------------------- //

function FilterProvider({ children, baseFilters, localFilters, onApply, onClear, onToggle, config }) {
  // STATE //

  // Anchor state for the popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'filters-popover' : undefined;

  // Filter state management
  const [active, setActive] = React.useState(true);
  const [filters, setFilters] = React.useState({ ...localFilters });
  const [checkedFilters, setCheckedFilters] = React.useState({});
  const [count, setCount] = React.useState(0);

  // Set checked filters on config change
  React.useEffect(() => {
    updateCheckedFilters(filters);
  }, []);

  // Set count on checked filters change
  React.useEffect(() => {
    const newCount = Object?.keys(checkedFilters)?.filter(filter => checkedFilters[filter] === true)?.length || 0;
    setCount(newCount);
  }, [checkedFilters]);

  // HANDLERS //

  /** Handle applying active filters
   ** Output needs to be spread in order for state to recognize new object instance
   */
  const handleApplyFilters = () => {
    setAnchorEl(null);

    const checkedKeys = Object?.keys(checkedFilters)?.filter(filter => checkedFilters[filter] === true);
    const output = {};
    checkedKeys?.forEach(key => (output[key] = filters?.[key]));
    updateFilters(output);
    updateCheckedFilters(output);
    updateCount(output);

    if (onApply) onApply(output);
  };

  /** Handle clearing all filters
   ** Output needs to be spread in order for state to recognize new object instance
   */
  const handleClearFilters = () => {
    setAnchorEl(null);

    const output = {};
    updateFilters(output);
    updateCheckedFilters(output);
    updateCount(output);

    if (onClear) onClear(output);
  };

  /** Handle toggling active filters on or off */
  const handleToggleFilters = active => {
    setActive(active);
    if (active) {
      const output = { ...filters };
      if (onToggle) onToggle(output);
    } else {
      const output = {};
      if (onToggle) onToggle(output);
    }
  };

  // METHODS //

  /** Handle updating filters */
  const updateFilters = input => {
    setFilters(input || {});
  };

  /** Handle updating checked filters */
  const updateCheckedFilters = input => {
    const defaultFilterKeys = Object?.keys(input || {}) || [];
    const newCheckedFilters = {};
    defaultFilterKeys?.forEach(module => (newCheckedFilters[module] = true));
    setCheckedFilters(newCheckedFilters);
  };

  /** Handle updating count */
  const updateCount = input => {
    const newCount = Object?.keys(input || {})?.filter(module => input[module] === true)?.length || 0;
    setCount(newCount);
  };

  /** Handle updating a single module */
  const updateModule = (module, key, value) => {
    setFilters(prev => ({ ...prev, [module]: { ...prev[module], [key]: value } }));
  };

  /** Handle updating a module's active status */
  const updateModuleChecked = (module, active) => {
    if (active === true) setCheckedFilters({ ...checkedFilters, [module]: true });
    else setCheckedFilters({ ...checkedFilters, [module]: false });
  };

  // CONTEXT //

  /** Context for the provider and hook */
  const context = {
    baseFilters,
    localFilters,
    config,

    anchorEl,
    setAnchorEl,
    open,
    popoverId,

    active,
    setActive,
    filters,
    setFilters,
    checkedFilters,
    setCheckedFilters,
    count,
    setCount,

    handleApplyFilters,
    handleClearFilters,
    handleToggleFilters,

    updateModule,
    updateModuleChecked,
  };

  return <FilterContext.Provider value={context}>{children}</FilterContext.Provider>;
}

// HOOK ---------------------------------------------------------------- //

const useFilters = () => React.useContext(FilterContext);

// EXPORT ---------------------------------------------------------------- //

export { useFilters, FilterProvider };
