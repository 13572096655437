import React from 'react';
import gql from 'graphql-tag';
import { LinearProgress } from '@material-ui/core';
import { useSubscription, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';

import Toolbar from '../../components/Toolbar';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import BatchJobDetailContent from './BatchJobDetailContent';
import BatchJobDetailToolbar from './BatchJobDetailToolbar';

export default function BatchJobDetail(props) {
  const cls = useStyles();

  const id = props.match.params.batchJobId;

  const { data, loading, error } = useSubscription(SUBSCRIBE_TO_BATCH_JOB, {
    variables: { id },
  });

  const [resetBatchJob, { loading: resetting }] = useMutation(RESET_BATCH_JOB, {
    onError: error => {
      console.error('Reset error:', error);
      toast.error(error.message || 'Failed to reset batch job');
    },
  });

  const handleReset = async () => {
    try {
      await resetBatchJob({
        variables: { id: batchJob.id },
      });
    } catch (err) {
      console.error('Reset execution error:', err);
    }
  };

  const isResetDisabled = () => {
    const status = batchJob?.status?.toLowerCase();
    //return resetting || ['processing', 'pending', 'delaying'].includes(status);
    return false;
  };

  const batchJob = data?.batch_jobs[0] || null;
  if (error) console.error(`Failed to subscribe to batch job:`, error);

  return (
    <div className={cls.root}>
      <Toolbar zIndex={11} fullscreen title={[`Batch Jobs`, `${id}`]} back>
        <BatchJobDetailToolbar batchJob={batchJob} onReset={handleReset} resetDisabled={isResetDisabled()} />
      </Toolbar>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <DefaultErrorFallback message={`Failed to fetch batch jobs`} />
      ) : batchJob ? (
        <BatchJobDetailContent batchJob={batchJob} />
      ) : (
        <DefaultEmptyFallback message={`No Batch Job Found`} />
      )}
    </div>
  );
}

const SUBSCRIBE_TO_BATCH_JOB = gql`
  subscription subscribeToBatchJob($id: bigint!) {
    batch_jobs(where: { id: { _eq: $id } }) {
      id
      batch_id
      input
      output
      trigger_type
      status
      sequence
      delay_key
      delay_ms
      createdat
      createdby
      updatedat
      updatedby
    }
  }
`;

const RESET_BATCH_JOB = gql`
  mutation resetBatchJob($id: bigint!) {
    update_batch_jobs_by_pk(pk_columns: { id: $id }, _set: { status: "pending", output: null }) {
      id
      status
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    //overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },
}));
