import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Container } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';
import { sendPasswordResetEmail } from '../../utils/authHelper';

import * as Sentry from '@sentry/react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_USER_DETAILS, REFRESH_CLAIMS, GET_CUSTOMERS } from '../../utils/gqlUserManagement';

import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';
import Toolbar from '../../components/Toolbar';

import { UserDetailsProvider, useUserDetails } from '../../providers/UserDetailsProvider'
import DealerUserDetailsInfo from './DealerUserDetailsInfo';
import DealerUserDetailsToolbar from './DealerUserDetailsToolbar';
import TerminationModal from '../../components/UserManagement/TerminationModal';
import ReinstateModal from '../../components/UserManagement/ReinstateModal';

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserDetails(props) {
  const cls = useStyles();

  const userId = props.match.params.id;
  const [user, setUser] = React.useState({})

  const [terminationModal, setTerminationModal] = React.useState({ open: false, input: null, output: null });
  const handleTerminationModalOpen = (input = null) => {
    setTerminationModal({ open: true, input: input, output: null });
  };
  const handleTerminationModalClose = async (output = null) => {
    setTerminationModal({ open: false, input: null, output: output });
  };

  const [reinstateModal, setReinstateModal] = React.useState({ open: false, input: null, output: null });
  const handleReinstateModalOpen = (input = null) => {
    setReinstateModal({ open: true, input: input, output: null });
  };
  const handleReinstateModalClose = async (output = null) => {
    setReinstateModal({ open: false, input: null, output: output });
  };

  const { loading, error, data, refetch } = useQuery(GET_USER_DETAILS, {
    variables: { userId: userId },
  });
  const [refreshUserClaims] = useMutation(REFRESH_CLAIMS, {
    variables: { userId: userId },
  });

  React.useEffect(() => {
    if (data && data.users && data.users.length > 0) {
      setUser(data.users[0])
    }
  }, [data])

  const [customerSelections, setCustomerSelections] = React.useState([])
  const [getCustomers] = useLazyQuery(GET_CUSTOMERS, {
    variables: { customerId: user.customer_id}
  });
  React.useEffect(() => {
    const fetchCustomers = async () => {
        try {
            const res = await getCustomers();
            if (res && res.data && res.data.customers && res.data.customers.length > 0) {
              if (res.data.customers[0].organization?.customers?.length > 0) setCustomerSelections(res.data.customers[0].organization.customers)
            }
        } catch (err) {
            console.log('Error fetching customers:', err);
            toast.error('Error fetching customers');
        }
    }
    if (user && user.customer_id) {
      fetchCustomers(user.customer_id);
    }
  
  }, [user])

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  const resetPassword = async email => {
    try {
      const res = await sendPasswordResetEmail('reset password', email, null);
      console.log('res', res)
      if (res) {
        toast.success('Password reset email sent successfully');
      } else {
        console.log('no res', res)
        toast.error('Failed to send password reset email');
      }
    } catch (err) {
      console.log('Error sending reset password email:', err);
      toast.error('Error sending password reset email');
    }
  };

  const refreshClaims = async () => {
    try {
      const refreshedClaimsRes = await refreshUserClaims()
      if (refreshedClaimsRes && refreshedClaimsRes.data && refreshedClaimsRes.data.update_users && refreshedClaimsRes.data.update_users.affected_rows > 0){
        toast.success('Successfully refreshed claims');
      } else toast.error('Error refreshing claims')
    } catch (err) {
      console.log('Error refreshing claims:', err);
      toast.error('Error refreshing claims');
    }
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' />
          <Loading position='fixed' />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting user details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultErrorFallback message='ERROR GETTING USER DETAILS' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.users || !data.users.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback message='NO USER FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //

  const userActions = [
    {
      label: `Reset Password`,
      handler: async () => {
        await resetPassword(user.email);
      },
    },
    {
      label: `Refresh Auth Claims`,
      handler: async () => {
        await refreshClaims();
      },
    },
    {
      label: `Deactivate User`,
      handler: () => handleTerminationModalOpen({ user, refetch: handleRefetch }),
      disabled: !user.active,
    },
    {
      label: `Reinstate User`,
      handler: () => handleReinstateModalOpen({ user, refetch: handleRefetch }),
      disabled: user.active,
    },
  ];

  return (
    <UserDetailsProvider user={user} handleRefetch={handleRefetch}>
      <TerminationModal
        open={terminationModal.open}
        input={terminationModal.input}
        onClose={handleTerminationModalClose}
      />

      <ReinstateModal
        open={reinstateModal.open}
        input={reinstateModal.input}
        onClose={handleReinstateModalClose}
      />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Dealer User Details' refetch={handleRefetch} actions={userActions}>
            <DealerUserDetailsToolbar user={user}/>
          </Toolbar>

          <Spacer />

          <DealerUserDetailsInfo user={user} customerSelections={customerSelections} setCustomerSelections={setCustomerSelections}/>

          <Spacer />
        </Container>
      </div>
    </UserDetailsProvider>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));