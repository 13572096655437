// TODO: Implement a queue for the autoplanner

class Queue {
    constructor(initialItems = []) {
        this.items = [...initialItems];
        this.head = 0;
        this.tail = initialItems.length;
    }

    /**
     * Adds an item to the end of the queue
     * @param {*} item - The item to add to the queue
     */
    enqueue(item) {
        this.items[this.tail] = item;
        this.tail++;
    }

    /**
     * Removes and returns the first item in the queue
     * @returns {*} The first item in the queue, or undefined if queue is empty
     */
    dequeue() {
        if (this.isEmpty()) {
            return undefined;
        }
        const item = this.items[this.head];
        this.head++;
        
        // Reset head and tail and clean up array when queue is empty
        if (this.head === this.tail) {
            this.head = 0;
            this.tail = 0;
            this.items = [];
        }
        
        return item;
    }

    /**
     * Returns the first item in the queue without removing it
     * @returns {*} The first item in the queue, or undefined if queue is empty
     */
    peek() {
        return this.isEmpty() ? undefined : this.items[this.head];
    }

    /**
     * Checks if the queue is empty
     * @returns {boolean} True if the queue is empty, false otherwise
     */
    isEmpty() {
        return this.head === this.tail;
    }

    /**
     * Returns the number of items in the queue
     * @returns {number} The number of items in the queue
     */
    size() {
        return this.tail - this.head;
    }
}

export default Queue;
