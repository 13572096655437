import { gql } from 'graphql-tag';

const GET_USERS = gql`
query get_users {
    users(where: {default_role: {_eq: "admin"}}) {
      active
      avatar_url
      created_at
      default_role
      display_name
      email
      id
      updated_at
    }
  }
`;

export { GET_USERS };