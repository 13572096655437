import React from 'react';
import gql from 'graphql-tag';
import { LinearProgress } from '@material-ui/core';

import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core';

import Toolbar from '../../components/Toolbar';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import ActiveTimeAuditContent from './ActiveTimeAuditContent';

export default function ActiveTimeAudit(props) {
  const cls = useStyles();

  const moveId = props.match.params.moveId;

  const { data, loading, error, refetch } = useQuery(GET_ACTIVE_TIME_BY_ID, {
    variables: { moveId },
    fetchPolicy: `network-only`,
  });
  const activeTime = data?.activetimes[0] || null;
  if (error) console.error(`Failed to fetch active times:`, error);

  console.log(`Active time:`, activeTime);
  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Active Time Audit' back />
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <DefaultErrorFallback message={`Failed to fetch active times`} />
      ) : activeTime ? (
        <ActiveTimeAuditContent activeTime={activeTime} refetch={refetch} />
      ) : (
        <DefaultEmptyFallback message={`No Active Time Found`} />
      )}
    </div>
  );
}

const GET_ACTIVE_TIME_BY_ID = gql`
  query GetActiveTimeByMoveId($moveId: bigint!) {
    activetimes(where: { move_id: { _eq: $moveId } }) {
      id
      move_id
      duration_minutes
      factors
      override_minutes
      notes
      status
      move {
        id
        move_type
        status
        cancel_status
        cancel_requested_at
        canceled_at
        pickup_time
        pickup_started
        pickup_arrived
        pickup_successful
        delivery_time
        delivery_started
        delivery_arrived
        delivery_successful
        plan {
          plan_date
        }
        childMoves(where: { active: { _eq: 1 }, move_type: { _eq: "ride" } }) {
          id
          move_type
          status
          cancel_status
          pickup_time
          pickup_started
          pickup_arrived
          pickup_successful
          delivery_time
          delivery_started
          delivery_arrived
          delivery_successful
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },
}));
