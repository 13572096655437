// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Grid, Typography, Icon, Tooltip, Chip } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useRegions } from './RegionsProvider';

// COMPONENT -------------------------------------------------- //

export default function RegionsTable({ regions, refetch }) {
  const cls = useStyles();

  const { selectRegion } = useRegions();

  // Check for regions
  if (!regions || !regions.length) {
    return (
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>No regions found.</Typography>
      </div>
    );
  }

  // Render items
  return (
    <div className={cls.table}>
      {regions.map((region, i) => {
        return (
          <div key={`region-item-${region.id}`} className={i % 2 ? cls.regionEven : cls.regionOdd}>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='space-between' wrap='nowrap'>
              <Grid item>
                <Tooltip title={`Region Name (Region ID)`} placement='top-start'>
                  <Typography className={cls.regionNameTxt}>
                    {region.name || <em>No name</em>} ({region.id})
                  </Typography>
                </Tooltip>

                <Tooltip title={`Region Description`} placement='top-start'>
                  <Typography className={cls.regionDescriptionTxt}>
                    {region.description || <em>No description</em>}
                  </Typography>
                </Tooltip>
              </Grid>

              <Grid item xs />

              <Grid item>
                <Tooltip title={`View and edit this region`}>
                  <Chip
                    classes={{
                      root: cls.chip,
                    }}
                    size='small'
                    label={`Select Region`}
                    icon={<Icon className={cls.chipIcon}>gps_fixed</Icon>}
                    onClick={() => selectRegion(region)}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Spacer size='xs' />

            <Grid container spacing={1} alignItems='center' wrap='nowrap'>
              <Grid item xs={5}>
                <Tooltip title={`Timezone`} placement='bottom-start'>
                  <div>
                    <Icon className={cls.infoIcon}>access_time_filled</Icon>
                    <Typography className={cls.infoTxt}>{region.timezone || <em>No timezone</em>}</Typography>
                  </div>
                </Tooltip>
              </Grid>

              <Grid item xs={7}>
                {region.email ? (
                  <Tooltip title={`Market Manager Email`} placement='bottom-start'>
                    <div>
                      <Icon className={cls.infoIcon}>email</Icon>
                      <Typography className={cls.infoTxt}>{region.email || <em>No email</em>}</Typography>
                    </div>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  regionOdd: {
    position: 'relative',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
  },
  regionEven: {
    position: 'relative',
    padding: theme.spacing(2),
    background: theme.palette.background.dark,
  },

  regionNameTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  regionDescriptionTxt: {
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  chip: {
    background: theme.palette.default.main,
    color: theme.palette.text.contrast,
    '&:hover': {
      background: theme.palette.default.light,
    },
  },
  chipIcon: {
    display: 'block',
    fontSize: 16,
    color: theme.palette.text.contrast,
  },

  infoIcon: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    fontSize: 20,
    color: theme.palette.text.secondary,
  },
  infoTxt: {
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  notFound: {
    padding: theme.spacing(2),
  },
  notFoundTxt: {
    lineHeight: 1,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
}));
