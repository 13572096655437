import { gql } from '@apollo/client';

////////// FRAGMENTS //////////

const LOCATION_FRAGMENT = gql`
  fragment LocationWithCustomerAndRegion on locations {
    id
    active
    address
    createdat
    customer_id
    customer {
      id
      name
    }
    email
    favorite
    latitude
    longitude
    name
    nickname
    phone
    notes
    place_id
    region_id
    region {
      id
      name
      geofence
    }
    timezone
    tookan_id
    type
    updatedat
  }
`;

const LANE_FRAGMENT = gql`
  fragment LaneWithLocations on lanes {
    id
    active
    description
    createdat
    customer_id
    customer {
      id
      name
    }
    pickup {
      ...LocationWithCustomerAndRegion
    }
    delivery {
      ...LocationWithCustomerAndRegion
    }
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    dealer_base_discount
    dealer_base_price
    dealer_base_rate
    dealer_base_rate_type
    dealer_stranded_discount
    dealer_stranded_price
    dealer_stranded_rate
    dealer_stranded_rate_type
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay
    driver_base_pay_discount
    driver_drive_pay
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    estimated_rideshare_return_cost
    insurance_cost
    insurance_cost_per_mile
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
    updatedat
  }
  ${LOCATION_FRAGMENT}
`;

////////// QUERIES //////////

const GET_LOCATIONS = customerId => gql`
  query get_locations($customerId: bigint) {
    locations(where: { 
      active: { _eq: 1 }, 
      ${customerId ? `customer_id: { _eq: $customerId }` : ''} 
    }, order_by: { name: asc }) {
      ...LocationWithCustomerAndRegion
    }
  }
  ${LOCATION_FRAGMENT}
`;
const GET_LOCATION = gql`
  query get_location($id: bigint!) {
    locations(where: { id: { _eq: $id } }) {
      ...LocationWithCustomerAndRegion
    }
  }
  ${LOCATION_FRAGMENT}
`;

const GET_LANE = gql`
  query get_lane($id: bigint!) {
    lanes(where: { id: { _eq: $id } }) {
      ...LaneWithLocations
    }
  }
  ${LANE_FRAGMENT}
`;

const UPSERT_LANE = gql`
  mutation upsert_lanes($laneObjects: [lanes_insert_input!]!) {
    insert_lanes(
      objects: $laneObjects
      on_conflict: {
        constraint: lanes_pkey
        update_columns: [
          average_drive_speed_min_per_mile
          average_drive_speed_mph
          createdat
          dealer_base_discount
          dealer_base_price
          dealer_base_rate
          dealer_base_rate_type
          dealer_stranded_discount
          dealer_stranded_price
          dealer_stranded_rate
          dealer_stranded_rate_type
          delivery_inspection_sec
          description
          destination_location_id
          distance_miles
          driver_base_pay
          driver_base_pay_discount
          driver_drive_pay
          driver_pay_per_kilometer
          driver_pay_per_mile
          driver_pay_per_minute
          driver_rake
          driver_return_pay
          driver_return_pay_discount
          driver_time_pay
          duration_sec
          estimated_rideshare_return_cost
          insurance_cost
          insurance_cost_per_mile
          tolls
          updatedat
        ]
      }
    ) {
      returning {
        id
        description
      }
    }
  }
`;

const REMOVE_LOCATION = gql`
  mutation remove_location($id: bigint!) {
    update_locations(where: { id: { _eq: $id } }, _set: { active: 0 }) {
      affected_rows
      returning {
        id
        active
        name
      }
    }
  }
`;

export { GET_LANE, GET_LOCATIONS, GET_LOCATION, UPSERT_LANE, REMOVE_LOCATION };
