import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

// const log = false;

export default function ScheduleTableHeader(props) {
  const cls = useStyles();

  const [payPeriodDates, setPayPeriodDates] = useState([]);

  useEffect(() => {
    let dateArr = createDates();
    setPayPeriodDates(dateArr);
  }, []);

  let createDates = () => {
    //The start of week can be changed for testing purposes
    const startOfWeek = dayjs().weekday(0);
    let dateArr = [];
    for (var i = 0; i < 14; i++) {
      dateArr.push(startOfWeek.add(i, 'day').format('ddd MM/DD'));
    }
    return dateArr;
  };
  return (
    <div className={cls.headerRow} id='scheduleHeader'>
      {/* Refetch wasn't working and looked bad */}
      {/* <Tooltip placement='top' title='Manually refresh'>
        <IconButton
          onClick={() => {
            props.refetch();
          }}
        >
          <Icon>cached</Icon>
        </IconButton>
      </Tooltip> */}
      <Typography key={'header-corner'} className={cls.date}></Typography>
      {payPeriodDates.map(date => {
        return (
          <Typography key={'day' + date} className={cls.date}>
            {date}
          </Typography>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  headerRow: {
    position: 'sticky',
    top: 0,
    gridArea: 'header',
    height: '100%',
    display: 'inline-grid',
    gridTemplateColumns: '16% repeat(14, 6%)',
    gridRowStart: 1,
    gridRowEnd: 'span 1',
    backgroundColor: theme.palette.background.main,
    gridColumnStart: 1,
    gridColumnEnd: 'span 1',
  },
  date: {
    wordBreak: 'normal',
    padding: '10px',
    gridColumnEnd: 'span 1',
    placeSelf: 'center',
    margin: '10px',
  },
}));
