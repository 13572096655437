//////////////////////// DEPENDENCIES ////////////////////////

import dayjs from 'dayjs';
import { ExportToCsv } from 'export-to-csv';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function useDriverPayouts() {
  const { formatUSD } = useTools();

  // Get local storage items
  const getDefaultRange = () => {
    const localRange = localStorage.getItem(`driver-payouts-range`);
    if (localRange) return localRange;
    else return `week`;
  };
  const getDefaultStart = () => {
    const localRange = localStorage.getItem(`driver-payouts-range`);
    const localStart = localStorage.getItem(`driver-payouts-start`);

    if (!localRange) return dayjs().startOf(`week`).format();
    else if (localRange !== `custom`) return dayjs().startOf(localRange).format();
    else return dayjs(localStart).format();
  };
  const getDefaultEnd = () => {
    const localRange = localStorage.getItem(`driver-payouts-range`);
    const localEnd = localStorage.getItem(`driver-payouts-end`);

    if (!localRange) return dayjs().endOf(`week`).format();
    else if (localRange !== `custom`) return dayjs().endOf(localRange).format();
    else return dayjs(localEnd).format();
  };
  const getDefaultRegionId = () => {
    const localRegionId = localStorage.getItem(`driver-payouts-region-id`);
    if (localRegionId) return Number(localRegionId);
    else return 0;
  };
  const getDefaultDriverId = () => {
    const localDriverId = localStorage.getItem(`driver-payouts-driver-id`);
    if (localDriverId) return Number(localDriverId);
    else return 0;
  };
  const getDefaultStatus = () => {
    const localStatus = localStorage.getItem(`driver-payouts-status`);
    if (localStatus) return localStatus;
    else return `all`;
  };

  const formatTime = time => {
    if (time && typeof time === `string`) {
      const newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A`);
      return newTime;
    }
    return null;
  };

  const getDateString = (range, activeRange, start, end) => {
    let str = null;

    if (range === `week`) {
      if (range === activeRange) str = `${dayjs(start).format(`MM/DD/YYYY`)} - ${dayjs(end).format(`MM/DD/YYYY`)}`;
      else str = `${dayjs().startOf(`week`).format(`MM/DD/YYYY`)} - ${dayjs().endOf(`week`).format(`MM/DD/YYYY`)}`;
    }

    if (range === `month`) {
      if (range === activeRange) str = dayjs(start).format(`MMMM YYYY`);
      else str = dayjs().format(`MMMM YYYY`);
    }

    if (range === `year`) {
      if (range === activeRange) str = dayjs(start).format(`YYYY`);
      else str = dayjs().format(`YYYY`);
    }

    if (range === `custom`) str = `Use Pickers`;

    if (str) return `(${str})`;
    return ``;
  };

  /** Generate and download a CSV from driverpayouts */
  const generateCSV = (driverpayouts, start, end) => {
    const createCsvRow = dp => {
      return {
        driverpayout_id: dp.id || ``,
        type: dp.type || ``,
        driver_id: dp.driver_id || ``,
        driver_name: dp.driver.user.display_name || ``,
        date_paid: dp.created_at || ``,
        transfer_token: dp.transfer_token || ``,
        status: dp.status || ``,
        amount: formatUSD(dp.amount) || `$0.00`,
      };
    };
    const csvRows = driverpayouts.map(dp => createCsvRow(dp));
    const csvOptions = {
      filename: `driver_payouts_${start}_to_${end}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  // Return additional logic
  return {
    getDefaultRange,
    getDefaultStart,
    getDefaultEnd,
    getDefaultRegionId,
    getDefaultDriverId,
    getDefaultStatus,
    formatTime,
    getDateString,
    generateCSV,
  };
}
