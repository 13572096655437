// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { Button, Icon, makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import MoveOutcomeReviewAccessorialRow from './MoveOutcomeReviewAccessorialRow';

// COMPONENT -------------------------------------------------- //

export default function MoveOutcomeReviewAccessorials({
  accessorials,
  selectedAccessorialId,
  onApplyEdits,
  onDiscardEdits,
  onEditAccessorial,
  onDeleteAccessorial,
  onRestoreAccessorial,
  onAddAccessorial,
}) {
  const cls = useStyles();

  return (
    <>
      <Typography className={cls.outcomeTitleTxt}>Accessorials</Typography>
      <Typography className={cls.outcomeSubtitleTxt}>
        These are all of the current accessorials on the move. Types of adjustments to these accessorials are
        represented by different colors.
      </Typography>

      <div className={cls.outcomeLegend}>
        <div className={cls.outcomeLegendFlex}>
          <div className={cls.outcomeLegendColorGrey} />
          <Typography className={cls.outcomeLegendTxt}> = Existing Accessorial</Typography>
        </div>

        <div className={cls.outcomeLegendFlex}>
          <div className={cls.outcomeLegendColorBlue} />
          <Typography className={cls.outcomeLegendTxt}> = Modified Accessorial</Typography>
        </div>

        <div className={cls.outcomeLegendFlex}>
          <div className={cls.outcomeLegendColorGreen} />
          <Typography className={cls.outcomeLegendTxt}> = New/Generated Accessorial</Typography>
        </div>

        <div className={cls.outcomeLegendFlex}>
          <div className={cls.outcomeLegendColorRed} />
          <Typography className={cls.outcomeLegendTxt}> = Deleted Accessorial</Typography>
        </div>
      </div>

      {accessorials?.length ? (
        <>
          <Spacer size='sm' />

          <div className={cls.outcomeTable}>
            <>
              <Spacer size='sm' />

              <div className={cls.outcomeTable}>
                {accessorials?.map((accessorial, i) => (
                  <React.Fragment key={`review-accessorial-${i}`}>
                    <MoveOutcomeReviewAccessorialRow
                      accessorial={accessorial}
                      index={i}
                      showControls
                      isEditing={
                        selectedAccessorialId === accessorial?.id || selectedAccessorialId === accessorial?.tempId
                      }
                      isModified={accessorial?.isModified}
                      isNew={accessorial?.tempId ? true : false}
                      isDeleted={accessorial?.isDeleted}
                      onApplyEdits={onApplyEdits}
                      onDiscardEdits={onDiscardEdits}
                      onEditAccessorial={onEditAccessorial}
                      onDeleteAccessorial={onDeleteAccessorial}
                      onRestoreAccessorial={onRestoreAccessorial}
                    />
                    {i < accessorials?.length - 1 ? <Spacer size='xs' /> : null}
                  </React.Fragment>
                ))}
              </div>
            </>
          </div>
        </>
      ) : null}

      <Spacer size='sm' />

      <Button
        disabled={selectedAccessorialId}
        fullWidth
        variant='outlined'
        color='primary'
        onClick={() => onAddAccessorial()}
      >
        <Icon className={cls.buttonIcon}>add</Icon>
        Add Accessorial
      </Button>
    </>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  outcomeTitleTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  },
  outcomeSubtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  outcomeLegend: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 16,
    marginTop: 12,
  },
  outcomeLegendFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  outcomeLegendTxt: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  outcomeLegendColorGrey: {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
  },
  outcomeLegendColorBlue: {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.info.main}20`,
  },
  outcomeLegendColorGreen: {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.success.main}20`,
  },
  outcomeLegendColorRed: {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.error.main}20`,
  },

  outcomeRow: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 4,
    padding: 4,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  outcomeCell: {
    position: 'relative',
    padding: 4,
  },
  outcomeCellFlex: {
    position: 'relative',
    flex: 1,
    padding: 4,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.main,
  },
  outcomeKeyTxt: {
    marginBottom: 4,
    lineHeight: 1,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  outcomeValTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

  buttonIcon: {
    marginRight: 8,
    fontSize: 18,
  },
}));
