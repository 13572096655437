//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Icon, TableCell, Tooltip, Typography, useTheme } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useData } from '../../providers/DataProvider';
import { useTools } from '../../hooks/useTools';
import { useEngine } from '../../hooks/useEngine';

import { GET_MOVE_LOGS } from './gql';

import ArmoveFold from '../../components/Folds/ArmoveFold';

import { getUserRole } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceTableRow(props) {
  const theme = useTheme();
  const { capFirst, clampNegNum, formatUSD } = useTools();
  const { row, foldId, setFoldId, handleARReviewModalOpen, handleARLogsModalOpen, handleRefetch } = props;
  const armove = row.armove || {};
  const move = armove.move || {};

  const cls = useStyles();
  const ctx = useData();
  const { goToMoveDetails, copyToClipboard } = useTools();
  const { rerunAR, tagDeliveryReported } = useEngine();
  const [userRole, setUserRole] = React.useState();

  React.useEffect(() => {
    const runAsync = async () => {
      let userRole = await getUserRole();
      setUserRole(userRole);
    };

    runAsync();
  }, []);

  // Handle fold row state
  const handleFoldRow = (id = 0) => {
    let newFoldId = id === foldId ? 0 : id;
    localStorage.setItem(`invoice-fold-id`, newFoldId);
    setFoldId(newFoldId);
  };

  // Handle getting ar move logs
  const handleARLogs = async moveId => {
    try {
      const res = await ctx.apolloClient.query({
        query: GET_MOVE_LOGS,
        variables: { moveId: moveId },
      });
      if (res && res.data && res.data.arevents && res.data.arevents.length > 0) {
        handleARLogsModalOpen(`move`, res.data.arevents);
      }
    } catch (err) {
      toast.error(`Failed to fetch move logs!`);
      console.error(`Failed to fetch move logs:`, err);
    }
  };

  const CostCell = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'right',
          justifyContent: 'flex-end',
          flexWrap: 'nowrap',
        }}
      >
        {armove.disputed || armove.discount_amount > 0 ? (
          <Tooltip
            placement='top'
            title={
              armove.disputed
                ? `Record is disputed - ${capFirst(armove.dispute_reason)}`
                : `Record is discounted for $${armove.discount_amount.toFixed(2)} - ${capFirst(armove.discount_reason)}`
            }
          >
            <Icon className={cls.rowIcon} fontSize='small'>
              {armove.disputed ? `announcement` : `local_offer`}
            </Icon>
          </Tooltip>
        ) : null}
        {armove.due_amount - armove.discount_amount <= armove.paid_amount ? (
          <Tooltip placement='top' title={`Paid - ${formatUSD(armove.paid_amount)}`}>
            <Icon className={cls.rowIcon} style={{ color: theme.palette.success.main }} fontSize='small'>
              check_circle
            </Icon>
          </Tooltip>
        ) : armove.paid_amount > 0 ? (
          <Tooltip placement='top' title={`Partially Paid - ${formatUSD(armove.paid_amount)}`}>
            <Icon className={cls.rowIcon} style={{ color: theme.palette.warning.main }} fontSize='small'>
              contrast
            </Icon>
          </Tooltip>
        ) : null}
        <Typography className={cls.rowTxt}>
          {armove.due_amount > 0
            ? `${formatUSD(clampNegNum(armove.due_amount - armove.discount_amount))}`
            : armove.due_amount === 0
            ? formatUSD(0)
            : `-`}
        </Typography>
      </div>
    );
  };

  const formatWeightClass = inputString => {
    if (!inputString) return '-';
    let formattedString = inputString.replace(/-/g, ' ');
    formattedString = formattedString.replace(/\s+\S+$/, '');
    formattedString = formattedString.replace(/^\w/, firstWord => firstWord.toUpperCase());
    return formattedString;
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.CHECKBOX || `-`,
      align: `left`,
    },
    {
      value: row.MOVE_ID || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.REF_NUM || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.MOVE_DATE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.LANE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.DLV_REP || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
      hide: armove.move && armove.move.customer && armove.move.customer.id !== 2,
    },
    {
      value: row.DISTANCE || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: formatWeightClass(row.WEIGHT_CLASS) || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: CostCell() || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
  ];

  // Set a list of actions after right-clicking on a row
  const rowActions = [
    {
      label: `Go To Move Details`,
      handler: () => goToMoveDetails(move.id || null),
    },
    {
      label: `Recalculate Customer Price`,
      handler: async () => {
        rerunAR(move.id || null, handleRefetch);
      },
      disabled: move.settled,
    },
    {
      label: `Review/Discount`,
      handler: () => handleARReviewModalOpen(armove || null),
      disabled: move.settled && userRole !== 'admin',
    },
    {
      label: `View AR Move Logs`,
      handler: () => handleARLogs(move.id || null),
    },
    {
      label: `Tag Delivery Reported`,
      handler: () => tagDeliveryReported(move || null),
      hide: (move.tags && move.tags.includes(`delivery reported`)) || (move.customer && move.customer.id !== 2),
    },
    {
      label: `Untag Delivery Reported`,
      handler: () => tagDeliveryReported(move || null),
      hide: !move.tags || !move.tags.includes(`delivery reported`),
    },
    {
      label: `Copy Reference #`,
      handler: () => copyToClipboard(move.reference_num),
      hide: !move.reference_num || (move.customer && move.customer.id !== 2),
    },
    {
      label: `Copy Delivery Timestamp`,
      handler: () => copyToClipboard(move.delivery_successful),
      hide: !move.delivery_successful,
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${armove.id}-cm`}
        source={`atr-row-${armove.id}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{
          className: `MuiTableRow-root ${foldId === armove.id ? cls.rowActive : cls.row}`,
        }}
      >
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${armove.id}-col-${i}`}
                    className={foldId === armove.id ? cls.cellActive : armove.disputed ? cls.cellDisputed : cls.cell}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </ContextMenuTrigger>

      {rowActions && rowActions.length > 0 && (
        <ContextMenu id={`atr-row-${armove.id}-cm`}>
          {rowActions.map(
            (action, i) =>
              !action.hide && (
                <MenuItem
                  key={`atr-row-action-${i}`}
                  disabled={action.disabled || false}
                  onClick={action.handler || null}
                >
                  {action.label || `Action ${i + 1}`}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}

      <ArmoveFold armove={row.armove} actions={rowActions} rowCellCount={rowCells.length} foldId={foldId} />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  row: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  rowActive: {
    background: theme.palette.primary.main,
    '&:hover, &:active': {
      background: `${theme.palette.primary.main} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  cell: {
    color: theme.palette.text.primary,
    transition: '0.15s',
  },
  cellDisputed: {
    color: theme.palette.text.secondary,
    transition: '0.15s',
  },
  cellActive: {
    color: theme.palette.text.contrast,
    transition: '0.15s',
  },
}));
