// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';

import { useWorkflow } from './WorkflowProvider';
import StepLabel from './StepLabel';

// COMPONENT -------------------------------------------------- //

export default function StepSelect({ step = {}, overrideValue }) {
  const { handleStepChange } = useWorkflow();

  const { id, required, default: defaultValue, config = {} } = step;
  const { options, validation_pattern, validation_message, start_icon, end_text, ...inputProps } = config;

  const [value, setValue] = React.useState(overrideValue || defaultValue || ``);

  // Handle the change of the input (and validation)
  const handleValueChange = (event = null) => {
    const newVal = event?.target?.value;
    setValue(newVal);

    // Change the state
    if (handleStepChange) handleStepChange(id, newVal);
  };

  return (
    <>
      <StepLabel step={step} />

      <TextField
        fullWidth
        select
        required={required}
        variant='outlined'
        size='small'
        value={value}
        onChange={handleValueChange}
        InputProps={{
          startAdornment: start_icon ? (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                {start_icon}
              </Icon>
            </InputAdornment>
          ) : null,
          endAdornment: end_text ? (
            <InputAdornment style={{ verticalAlign: 'top' }} position='end'>
              {end_text}
            </InputAdornment>
          ) : null,
        }}
        inputProps={{
          ...inputProps,
        }}
      >
        {options?.map(option => (
          <MenuItem key={`select-${id}-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
