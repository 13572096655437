import React from 'react';
import { toast } from 'react-toastify';
import { LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from './ModalComponents';

import { useEngine } from '../hooks/useEngine';

const log = false;

//////////////////////// COMPONENT ////////////////////////

// input.payout - Driverpayout object with id and transfer_token
// input.refetch - Refetch the data after confirmation

export default function DriverPayModal({ open, input, onClose }) {
  const { cancelDriverpayout } = useEngine();

  const [payout, setPayout] = React.useState([]);
  const [isCanceling, setIsCanceling] = React.useState(false);
  const [wasInitiated, setWasInitiated] = React.useState(false);

  // Update driverPay array if the input changes
  React.useEffect(() => {
    if (input) {
      const localPayout = input.payout || {};
      log && console.log(`Payout Input:`, localPayout);

      if (localPayout) setPayout(localPayout);
      setIsCanceling(false);
      setWasInitiated(false);
    }
  }, [input]);

  // Handle the transfer cancelation
  const handleCancelTransfer = async () => {
    const res = await cancelDriverpayout(payout, input.refetch);
    if (res && res.success === true) {
      toast.success(`Successfully canceled driverpayout!`);
      setWasInitiated(true);
      handleClose();
    } else if (res && res.error) toast.error(`Failed to cancel driverpayout! ${res.error}`);
    else toast.error(`Failed to cancel driverpayout!`);

    setIsCanceling(false);
  };

  // Handle closing of the form
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <Modal open={open}>
      {open && payout ? (
        <>
          <ModalHeader onClose={onClose}>Cancel Driver Payout #{payout.id}</ModalHeader>

          <ModalContent subtitle='Are you sure you want to cancel this driver payout? The driver will not be paid and the related payment details will show back up on the Driver Pay screen.'>
            {isCanceling ? <LinearProgress /> : <Spacer size='xxs' />}
          </ModalContent>

          <ModalFooter>
            <ModalAction
              loading={isCanceling}
              disabled={isCanceling || wasInitiated}
              color='primary'
              onClick={() => {
                setIsCanceling(true);
                handleCancelTransfer();
              }}
            >
              Confirm
            </ModalAction>

            <ModalAction onClick={() => handleClose()}>Close</ModalAction>
          </ModalFooter>
        </>
      ) : null}
    </Modal>
  );
}
