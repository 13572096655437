//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import { getUserToken } from '../../utils/authHelper';

import { makeStyles, IconButton, Icon, Typography, Tooltip } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

const log = true;

//////////////////////// COMPONENT ////////////////////////

export default function DriverImageDropzone({ driver, defaultImageUrl, onImageUrlChange }) {
  const cls = useStyles();

  const { condensedCase } = useTools();

  // Manage main image URL state
  const [imageUrl, setImageUrl] = React.useState(defaultImageUrl);

  // Manage state of the file upload box
  const [dropzoneDragover, setDropzoneDragover] = React.useState(false);
  const [dropzoneError, setDropzoneError] = React.useState(false);
  const [dropzoneFile, setDropzoneFile] = React.useState(null);

  /** Refresh the image from the server (requires a timestamp to be set on the src) */
  const refreshImageFromServer = url => {
    if (url) {
      const timestamp = new Date().getTime();
      const queryString = '?t=' + timestamp;
      let element = document.getElementById('driver-file-output');
      element.src = url + queryString;
    }
  };

  /** Click upload handler */
  const handleFileClick = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    const file = e?.target?.files[0];
    log && console.log(`Handle click upload:`, file);

    // Upload the file
    await handleImageUpload(e, file);
  };

  /** Drag & drop upload handler */
  const handleFileDragAndDrop = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);

    // Capture the file
    let file = e.dataTransfer.files[0];
    log && console.log(`Handle drag & drop upload:`, file);

    // Upload the file
    await handleImageUpload(e, file);
  };

  /** Handle dragging over the file upload component */
  const handleFileDragoverEnter = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(true);
    setDropzoneError(false);
  };

  /** Handle dragging off the file upload component */
  const handleFileDragoverExit = async e => {
    // Prevent default behavior and set the dropzone state
    e.preventDefault();
    setDropzoneDragover(false);
    setDropzoneError(false);
  };

  /** Handle uploading the file */
  const handleImageUpload = async (e, file) => {
    // Check if the file is valid
    if (!file) {
      console.error(`Failed to upload image: No file provided!`);
      toast.error(`Failed to upload image: No file provided!`);
      setDropzoneError(true);
      return;
    }
    if (file?.size > 5000000) {
      console.error(`Failed to upload image: File size must be less than 5MB/5000KB!`);
      toast.error(`Failed to upload image: File size must be less than 5MB/5000KB!`);
      setDropzoneError(true);
      return;
    }
    if (
      file?.type !== 'image/jpg' &&
      file?.type !== 'image/jpeg' &&
      file?.type !== 'image/png' &&
      file?.type !== 'image/heic'
    ) {
      console.error(`Failed to upload image: File type not supported!`);
      toast.error(`Failed to upload image: File type not supported!`);
      setDropzoneError(true);
      return;
    }

    // Set the image element src
    const imgSrc = URL.createObjectURL(file);
    const imgElement = document.getElementById('driver-file-output');
    imgElement.src = imgSrc;

    // Set the dropzone state
    setDropzoneFile(file);

    // Get the auth token
    const token = await getUserToken();

    // Upload the file
    let reader = new FileReader();
    reader.addEventListener('loadend', async e => {
      try {
        const res = await axios({
          url: '/.netlify/functions/requestUploadUrl',
          method: 'POST',
          data: {
            fileName: `${driver?.id}-${condensedCase(driver?.display_name || driver?.user?.display_name)}`,
            fileType: file?.type,
            bucketName: `driver-photos.hopdrive.com`,
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        });

        const { uploadURL } = res?.data;
        const uploadRes = await axios.put(uploadURL, file, { headers: { 'Content-Type': file?.type || `image/*` } });

        if (uploadRes.status >= 200 && uploadRes.status < 400) {
          const newUrl = uploadURL.split('?')[0];
          setImageUrl(newUrl);
          refreshImageFromServer(newUrl);
          if (onImageUrlChange) onImageUrlChange(newUrl);
          log && console.log(`Successfully uploaded the image to S3!`);
        } else {
          console.error(`Failed to upload the image to S3!`);
          toast.error(`Failed to upload the image to S3!`);
        }
      } catch (err) {
        console.error(`Failed to upload the image to S3:`, err);
        toast.error(`Failed to upload the image to S3!`);
      }
    });
    reader.readAsArrayBuffer(file);
  };

  /** Handle clearing out the image */
  const handleLogoClear = async () => {
    setDropzoneDragover(false);
    setDropzoneError(false);
    setDropzoneFile(null);
    setImageUrl(null);
    if (onImageUrlChange) onImageUrlChange(null);
  };

  // Return component
  return (
    <div className={cls.root}>
      {!imageUrl && !dropzoneFile ? (
        <label
          className={clsx(cls.dropzone, {
            [cls.dropzoneDragover]: dropzoneDragover,
            [cls.dropzoneError]: dropzoneError,
          })}
          onDrop={handleFileDragAndDrop}
          onDragOver={handleFileDragoverEnter}
          onDragLeave={handleFileDragoverExit}
        >
          <Typography className={cls.dropzoneTxt}>Click or drag image here</Typography>
          <Typography className={cls.dropzoneAltLTxt}>Max 5MB/5000KB</Typography>
          <Typography className={cls.dropzoneAltRTxt}>Min size 256px</Typography>
          <input
            className={cls.hide}
            id='driver-file-uploader'
            type='file'
            accept='image/jpg, image/jpeg, image/png, image/heic'
            onChange={handleFileClick}
          />
        </label>
      ) : null}

      <div className={cls.image}>
        <div className={clsx(cls.imageBox, { [cls.hide]: !imageUrl && !dropzoneFile })}>
          <img
            className={clsx(cls.imageImg, { [cls.hide]: !imageUrl && !dropzoneFile })}
            id='driver-file-output'
            alt='driver-file-output'
            src={imageUrl ? `${imageUrl}?t=${new Date().getTime()}` : null}
          />
        </div>

        <Tooltip title='Remove image' placement='top'>
          <IconButton
            className={clsx(cls.imageIconBtn, { [cls.hide]: !imageUrl && !dropzoneFile })}
            onClick={handleLogoClear}
          >
            <Icon>cancel_circle</Icon>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},

  dropzone: {
    position: 'relative',
    display: 'block',
    width: 256,
    height: 192,
    padding: theme.spacing(2),
    border: theme.border[1],
    borderStyle: 'dashed',
    borderRadius: theme.shape.paperRadius,
    cursor: 'pointer',
    transition: `0.15s all ease-in-out`,
  },
  dropzoneDragover: {
    border: theme.border[1],
    borderColor: theme.palette.text.primary,
    '& $dropzoneTxt': {
      color: theme.palette.text.primary,
    },
  },
  dropzoneError: {
    border: theme.border[1],
    borderColor: theme.palette.error.main,
    '& $dropzoneTxt': {
      color: theme.palette.error.main,
    },
  },

  dropzoneTxt: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: `0.15s all ease-in-out`,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  // dropzoneAltTxt: {
  //   position: 'absolute',
  //   bottom: 4,
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   fontSize: 12,
  //   fontWeight: 400,
  //   textAlign: 'left',
  //   color: theme.palette.text.disabled,
  //   userSelect: 'none',
  //   pointerEvents: 'none',
  // },
  dropzoneAltLTxt: {
    position: 'absolute',
    bottom: 4,
    left: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  dropzoneAltRTxt: {
    position: 'absolute',
    bottom: 4,
    right: 8,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'right',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },

  image: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
  },
  imageBox: {
    position: 'relative',
    width: 160,
  },
  imageImg: {
    display: 'block',
    objectFit: 'cover',
    width: 160,
    height: 160,
    borderRadius: '50%',
    margin: 'auto',
  },
  imageIconBtn: {
    padding: theme.spacing(0.5),
  },

  hide: {
    display: 'none',
  },
}));
