// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import StaticDriveTileAlt from '../../../MoveComponents/StaticDriveTileAlt';
import StaticRideTileAlt from '../../../MoveComponents/StaticRideTileAlt';

// COMPONENT ---------------------------------------------------------------- //

export default function StaticPlanAreaRow({
  dataItem,
  index,
  startGap,
  itemOffset,
  multiplier,
  timezoneOverride,
  zoom,
}) {
  const cls = useStyles();

  return dataItem?.moves?.map((move, i) => (
    <React.Fragment key={`timeline-area-row-move-${move?.id || i}`}>
      {move?.move_type === 'drive' ? (
        <StaticDriveTileAlt
          showMoveId
          move={move}
          index={i}
          gap={i === 0 ? startGap : 0}
          offset={itemOffset}
          width={(move?.lane?.duration_sec / 60 || 30) * multiplier * zoom - itemOffset}
          timezoneOverride={timezoneOverride}
        />
      ) : null}

      {move?.move_type === 'ride' ? (
        <StaticRideTileAlt
          showMoveId
          move={move}
          index={i}
          gap={i === 0 ? startGap : 0}
          offset={itemOffset}
          width={(move?.lane?.duration_sec / 60 || 30) * multiplier * zoom - itemOffset}
          timezoneOverride={timezoneOverride}
        />
      ) : null}
    </React.Fragment>
  ));
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
