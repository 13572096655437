//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationUsersFilter({
  handleAddUser,
  customers,
  customerIdArray,
  setCustomerIdArray,
  status,
  onStatusChange,
  search,
  onSearchChange,
}) {
  const cls = useStyles();

  const handleStatusChange = event => {
    onStatusChange(event.target.value);
  };
  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  const handleSelectedCustomers = selections => {
    let selectedIds = selections.map(s => s.id);
    setCustomerIdArray(selectedIds);
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      {setCustomerIdArray ? (
        <Grid item xs>
          <ReusableMultiselect
            label='Customer Filter'
            dataArray={customers || []}
            selectedArray={customerIdArray || []}
            optionName={'name'}
            handleOutput={handleSelectedCustomers}
          />
        </Grid>
      ) : null}

      {onStatusChange ? (
        <Grid item xs>
          <TextField
            select
            fullWidth
            label='Status Filter'
            placeholder='Select a status...'
            variant='outlined'
            size='small'
            value={status || `all`}
            onChange={handleStatusChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    token
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`all`}>All Statuses</MenuItem>
            <MenuItem value={`live`}>Live</MenuItem>
            <MenuItem value={`onboarding`}>Onboarding</MenuItem>
            <MenuItem value={`deactivated`}>Deactivated</MenuItem>
          </TextField>
        </Grid>
      ) : null}

      {onSearchChange ? (
        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}

      {handleAddUser ? (
        <Grid item>
          <Button color='primary' size='large' onClick={() => handleAddUser()}>
            <Icon className={cls.addIcon}>add_circle</Icon>
            Add User
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  addIcon: {
    marginTop: -2,
    marginBottom: -2,
    marginRight: 10,
    fontSize: 20,
  },
}));
