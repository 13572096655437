//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Tooltip, Container } from '@material-ui/core';
import { useTools } from '../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function MoveLocation(props) {
  const cls = useStyles();
  const { goToLocationDetails, capFirst, getPickupOrDeliveryTimeFromMove, getMinutesBetween, checkMoveCompleted } =
    useTools();

  const { type, move, printMode, editMode, selected, setSelected } = props;

  const isCompleted = checkMoveCompleted(move);

  const formatPickupStarted = dayjs(move.pickup_started).format(`h:mm A`);
  const formatPickupArrived = dayjs(move.pickup_arrived).format(`h:mm A`);
  const formatPickupSuccessful = dayjs(move.pickup_successful).format(`h:mm A`);
  const formatDeliveryStarted = dayjs(move.delivery_started).format(`h:mm A`);
  const formatDeliveryArrived = dayjs(move.delivery_arrived).format(`h:mm A`);
  const formatDeliverySuccessful = dayjs(move.delivery_successful).format(`h:mm A`);

  const pickupDrive = getMinutesBetween(move.pickup_started, move.pickup_arrived);
  const pickupInspect = getMinutesBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getMinutesBetween(move.pickup_successful, move.delivery_started);
  const deliveryDrive = getMinutesBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getMinutesBetween(move.delivery_arrived, move.delivery_successful);

  const pickupTotal = pickupDrive + pickupInspect + pickupDwell;
  const deliveryTotal = deliveryDrive + deliveryInspect;

  const totalTime = pickupDrive + pickupInspect + pickupDwell + deliveryDrive + deliveryInspect;

  const handleSetSelected = () => {
    if (setSelected) setSelected(type);
  };

  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupDrive / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupInspect / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${pickupTotal && !isNaN(pickupTotal) ? (pickupDwell / pickupTotal) * 100 : 33.333}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${deliveryTotal && !isNaN(deliveryTotal) ? (deliveryDrive / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${deliveryTotal && !isNaN(deliveryTotal) ? (deliveryInspect / deliveryTotal) * 100 : 50}%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
  }));
  const barCls = useBarStyles();

  try {
    return (
      <>
        <div
          className={printMode ? cls.paperPrint : editMode ? cls.editSelect : cls.paper}
          onClick={() => handleSetSelected()}
          style={{ border: selected === type ? `2px solid #486496` : `1px solid transparent` }}
        >
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              <Tooltip placement='top-start' title={`${capFirst(type)} Location ID`}>
                <Typography className={cls.headTxtL}>
                  {capFirst(type)}&nbsp;#
                  {type === `pickup` ? move.lane.pickup.id : move.lane.delivery.id}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                placement='top-end'
                title={`${isCompleted ? `Actual` : `Planned`} ${type === `pickup` ? `Start` : `End`} Time`}
              >
                <Typography className={cls.headTxtR}>{getPickupOrDeliveryTimeFromMove(type, move) || `N/A`}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <div className={cls.smallBreak} />

          {!editMode ? (
            <div
              className={cls.location}
              onClick={() => {
                goToLocationDetails(type === `pickup` ? move.lane.pickup.id : move.lane.delivery.id);
              }}
            >
              <Tooltip placement='top' title={`Click to see details for this location`}>
                <Grid container>
                <Grid item lg={9} maxWidth={100}>
                  <Typography className={cls.nameTxt}>{move.lane[type].name}</Typography>
                  <Typography className={cls.addressTxt}>{move.lane[type].address}</Typography>
                </Grid>
                </Grid>
              </Tooltip>
            </div>
          ) : (
            <div className={cls.locationNoHover}>
              <Typography className={cls.nameTxt}>{move.lane[type].name}</Typography>
              <Typography className={cls.addressTxt}>{move.lane[type].address}</Typography>
            </div>
          )}

          <div className={cls.midBreak} />

          <div className={cls.block}>
            {type === `pickup` ? (
              <>
                {pickupDrive || pickupTotal === 0 ? (
                  <Tooltip
                    title={`Pickup Drive - ${
                      pickupDrive ? `${pickupDrive} minutes (${formatPickupStarted} to ${formatPickupArrived})` : `0m`
                    }`}
                  >
                    <div className={barCls.p1} />
                  </Tooltip>
                ) : null}

                {pickupInspect || pickupTotal === 0 ? (
                  <Tooltip
                    title={`Pickup Inspection - ${
                      pickupInspect
                        ? `${pickupInspect} minutes (${formatPickupArrived} to ${formatPickupSuccessful})`
                        : `0m`
                    }`}
                  >
                    <div className={barCls.p2} />
                  </Tooltip>
                ) : null}

                {pickupDwell || pickupTotal === 0 ? (
                  <Tooltip
                    title={`Pickup Dwelling - ${
                      pickupDwell
                        ? `${pickupDwell} minutes (${formatPickupSuccessful} to ${formatDeliveryStarted})`
                        : `0m`
                    }`}
                  >
                    <div className={barCls.p3} />
                  </Tooltip>
                ) : null}
              </>
            ) : (
              <>
                {deliveryDrive || deliveryTotal === 0 ? (
                  <Tooltip
                    title={`Delivery Drive - ${
                      deliveryDrive
                        ? `${deliveryDrive} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})`
                        : `0m`
                    }`}
                  >
                    <div className={barCls.d1} />
                  </Tooltip>
                ) : null}

                {deliveryInspect || deliveryTotal === 0 ? (
                  <Tooltip
                    title={`Delivery Inspection - ${
                      deliveryInspect
                        ? `${deliveryInspect} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})`
                        : `0m`
                    }`}
                  >
                    <div className={barCls.d2} />
                  </Tooltip>
                ) : null}
              </>
            )}
          </div>

          <Grid container spacing={0} justifyContent='space-between' wrap='nowrap'>
            {type === `pickup` ? (
              <>
                <Grid item xs={4} className={cls.leftTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Drive:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupDrive ? `${pickupDrive}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.midTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupInspect ? `${pickupInspect}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.rightTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Dwell:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {pickupDwell ? `${pickupDwell}m` : `0m`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4} className={cls.leftTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Drive:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {deliveryDrive ? `${deliveryDrive}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.midTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Inspect:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {deliveryInspect ? `${deliveryInspect}m` : `0m`}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.rightTxt + ' ' + cls.ellipse}>
                  <Typography className={cls.timeTxtKey + ' ' + cls.inline}>Total:&nbsp;</Typography>
                  <Typography className={cls.timeTxtVal + ' ' + cls.inline}>
                    {totalTime ? `${totalTime}m` : `0m`}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </>
    );
  } catch (error) {
    console.error(error);
    return (
      <div className={cls.rootTable}>
        <Container maxWidth='sm'>
          <div className={cls.notFound}>
            <Typography className={cls.notFoundTxt}>ERROR WITH LOCATION</Typography>
          </div>
        </Container>
      </div>
    );
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  editSelect: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    backgroundColor: '#00000000',
    cursor: 'pointer',
    boxShadow: theme.shadow.medium,
    border: `1px solid transparent`, // Initial border with transparent color
    '&:hover': {
      backgroundColor: '#00000012',
      border: `1px solid ${theme.palette.secondary.main}`, // Border color on hover
    },
  },
  paperPrint: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    border: theme.border[1],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#00000000',
    '&:hover': {
      backgroundColor: '#00000012',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  locationNoHover: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#00000000',
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  addressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  timeTxtKey: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  timeTxtVal: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  sig: {
    display: 'block',
    position: 'relative',
    height: 'fit-content',
  },
  sigImage: {
    display: 'block',
    position: 'relative',
    maxWidth: 200,
    maxHeight: 200,
  },
  sigTxt: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    color: '#fff',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  block: {
    display: 'block',
    height: theme.spacing(1.5),
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  leftTxt: {
    textAlign: 'left',
  },
  midTxt: {
    textAlign: 'center',
  },
  rightTxt: {
    textAlign: 'right',
  },
  ellipse: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
