//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid } from '@material-ui/core';
import MethodCard from '../../components/MethodCard';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsPricingPaymentMethods(props) {
  const { paymentmethods } = props;

  return (
    <Grid container spacing={2}>
      {paymentmethods && paymentmethods.length > 0 ? (
        paymentmethods.map(m => (
          <React.Fragment key={m.id}>
            <Grid item md={6} xs={12}>
              <MethodCard method={m} />
            </Grid>
          </React.Fragment>
        ))
      ) : (
        <Grid item md={6} xs={12}>
          Customer Has No Payment Methods
        </Grid>
      )}
    </Grid>
  );
}
