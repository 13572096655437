import React from 'react';
import { withStyles, Switch as MUISwitch } from '@material-ui/core';

const AntSwitch = withStyles(theme => ({
  root: {
    display: 'flex',
    width: 48,
    height: 24,
    padding: 0,
    transition: '0.2s all ease-in-out',
  },
  switchBase: {
    padding: 4,
    color: theme.palette.text.disabled,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.primary.contrastText,
    },
    '&$checked + $track': {
      outline: 'none',
      outlineOffset: 0,
      opacity: 1,
    },
    '&$disabled + $track': {
      backgroundColor: theme.palette.text.disabled,
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    padding: 0,
    border: 'none',
    borderRadius: 24,
    outline: theme.border[0],
    outlineOffset: -1,
    backgroundColor: 'transparent',
    opacity: 1,
  },
  checked: {},
}))(MUISwitch);

export default function Switch({ children, ...rest }) {
  return <AntSwitch {...rest} />;
}
