// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Icon } from '@material-ui/core';

import BigButtonGroup from '../BigButtonGroup';
import BigButton from '../BigButton';

import { useWorkflow } from './WorkflowProvider';
import StepLabel from './StepLabel';

// COMPONENT -------------------------------------------------- //

export default function StepMultibutton({ step = {}, overrideValue }) {
  const cls = useStyles();

  const { handleStepChange } = useWorkflow();

  const { id, default: defaultValue, config = {} } = step;

  // Handle the selection of an option (and validation)
  const handleValueChange = (selection = null) => {
    // Validation
    const option = config?.options?.find(option => option?.value === selection);
    if (option) {
      // Change
      if (handleStepChange) handleStepChange(id, option?.value, option?.next_step_id);
    }
  };

  if (config?.options_type === `big`) {
    return (
      <>
        <StepLabel step={step} />

        <BigButtonGroup onChange={handleValueChange} preselect={overrideValue || defaultValue || ``}>
          {config?.options
            ? config?.options?.map(option => {
                const optionConfig = option?.config || {};

                return (
                  <BigButton
                    key={`big-button-${option?.value}`}
                    id={option?.value}
                    title={option?.label}
                    subtitle={option?.description}
                    icon={optionConfig?.icon ? <Icon className={cls.icon}>{optionConfig.icon}</Icon> : null}
                    tip={optionConfig?.tooltip}
                  />
                );
              })
            : null}
        </BigButtonGroup>
      </>
    );
  }
}

// COMPONENT -------------------------------------------------- //
const useStyles = makeStyles(theme => ({
  icon: {
    height: 36,
  },
}));
