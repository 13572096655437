import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Chip, Icon, TableRow, TableCell, TableHead, TableBody, Table, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

import RideshareRateFormModalWrapper from './RideshareRateFormModalWrapper';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';
// import { useTools } from '../../hooks/useTools';
import { Divide } from '@hopdrive/storybook';

const log = false;

export default function CustomerPricingRideshareRates({
  rideshareRate,
  onChange = rideshareRate => {},
  setHasChanged,
}) {
  const cls = useStyles();

  const [currentRideshareRateId, setCurrentRideshareRateId] = useState(0);
  const [unsavedRideshareRate, setUnsavedRideshareRate] = useState(null);
  const [formModalOpen, setFormModalOpen] = useState(false);

  useEffect(() => {
    log && console.log('rideshareRate', rideshareRate);
    setUnsavedRideshareRate(null);
    if (rideshareRate && currentRideshareRateId !== rideshareRate.id) {
      setCurrentRideshareRateId(rideshareRate.id);
    }
  }, [rideshareRate, currentRideshareRateId]);

  const handleRowClick = rideshareRate => event => {
    log && console.log('handleRowClick()');
    log && console.log('handleRowClick() -- rideshare rate:', rideshareRate);
    setFormModalOpen(true);
  };

  const handleAddRideshareRate = newRideshareRate => {
    log && console.log('setting new rideshare rate', newRideshareRate);
    setUnsavedRideshareRate(newRideshareRate);
    onChange(newRideshareRate);
    setHasChanged(true);
    setFormModalOpen(false);
  };

  const getRideshareRateStatus = (beginDate, endDate) => {
    const isExpired = dayjs(endDate).isBefore(dayjs());
    const isPending = dayjs(beginDate).isAfter(dayjs());
    if (isExpired) {
      return <Chip label='Rideshare Rate Status: Expired' variant='default' size='small' color='secondary' />;
    } else if (isPending) {
      return <Chip label='Rideshare Rate Status: Pending' variant='default' size='small' color='tertiary' />;
    } else {
      return <Chip label='Rideshare Rate Status: Active' variant='default' size='small' color='primary' />;
    }
  };

  const handleReplaceRideshare = savedRideshareRate => {};

  const RideshareRateTable = () => {
    log && console.log(rideshareRate, unsavedRideshareRate);
    //There will only ever be one row in this table. The options are:
    // 1) There is an rideshare rate saved to the contract and the user has not switched it for a new one
    // 2) The user has selected a new rideshare rate for the contract to replace the old one
    // 3) No rideshare rate has been added
    if (rideshareRate && !unsavedRideshareRate) {
      return (
        <>
          <div className={cls.divide}>
            <Divide>Rideshare Rates</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='rideshare rate table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>Base</TableCell>
                <TableCell align='right'>Service Fee</TableCell>
                <TableCell align='right'>Per&nbsp;Minute</TableCell>
                <TableCell align='right'>Per&nbsp;Mile</TableCell>
                <TableCell align='right'>Minimum</TableCell>
                <TableCell align='right'>Maximum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={rideshareRate?.id || 'rideshareRateRow'}>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem}>
                  {rideshareRate?.name}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem}  align='right'>
                  {rideshareRate?.initial_cost ?? '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem}  align='right'>
                  {rideshareRate?.service_fee ?? '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='center'>
                  {rideshareRate?.per_minute_rate ? `$${rideshareRate?.per_minute_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='right'>
                  {rideshareRate?.per_mile_rate ? `$${rideshareRate?.per_mile_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='center'>
                  {rideshareRate?.minimum_ride_cost  ? `$${rideshareRate?.minimum_ride_cost ?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='right'>
                  {rideshareRate?.maximum_ride_cost  ? `$${rideshareRate?.maximum_ride_cost ?.toFixed(2)}` : '-'}
                </TableCell>
              </TableRow>
              <TableRow hover key={-1}>
                <TableCell
                  colSpan={8}
                  align='center'
                  onClick={handleRowClick(rideshareRate)}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='disabled' size='small'>
                    {getRideshareRateStatus(rideshareRate.effective_date, rideshareRate.expiration_date)}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      );
    } else if (unsavedRideshareRate) {
      return (
        <>
          <div className={cls.divide}>
            <Divide>Rideshare Rate</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='rideshare rate table'>
            <TableHead>
              <TableRow>
              <TableCell>Name</TableCell>
                <TableCell>Base</TableCell>
                <TableCell>Service Fee</TableCell>
                <TableCell align='right'>Per Minute</TableCell>
                <TableCell align='right'>Per Mile</TableCell>
                <TableCell align='right'>Minimum</TableCell>
                <TableCell align='right'>Maximum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={unsavedRideshareRate.id || 'unsavedRideshareRateRow'} className={cls.rowHasChanges}>
              <TableCell onClick={handleRowClick(unsavedRideshareRate)} className={cls.clickableItem}>
                  {unsavedRideshareRate?.name}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedRideshareRate)} className={cls.clickableItem}>
                  {unsavedRideshareRate?.initial_cost ?? '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedRideshareRate)} className={cls.clickableItem}>
                  {unsavedRideshareRate?.service_fee ?? '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedRideshareRate)} className={cls.clickableItem} align='center'>
                  {unsavedRideshareRate?.per_minute_rate ? `$${unsavedRideshareRate?.per_minute_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedRideshareRate)} className={cls.clickableItem} align='right'>
                  {unsavedRideshareRate?.per_mile_rate ? `$${unsavedRideshareRate?.per_mile_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='right'>
                  {unsavedRideshareRate?.minimum_ride_cost ? `$${unsavedRideshareRate?.minimum_ride_cost?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(rideshareRate)} className={cls.clickableItem} align='right'>
                  {unsavedRideshareRate?.maximum_ride_cost ? `$${unsavedRideshareRate?.maximum_ride_cost?.toFixed(2)}` : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell
                  colSpan={8}
                  align='center'
                  onClick={handleRowClick(unsavedRideshareRate)}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='disabled' size='small'>
                    {getRideshareRateStatus(unsavedRideshareRate?.effective_date, unsavedRideshareRate?.expiration_date)}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      );
    } else {
      return (
        <Table className={cls.table} aria-label='rideshare rate table'>
          <div className={cls.divide}>
            <Divide>Rideshare Rate</Divide>
          </div>
          <TableBody>
            <TableRow
              hover
              className={cls.addRideshareButton}
              key={-1}
              onClick={() => {
                setFormModalOpen(true);
              }}
            >
              <TableCell colSpan={5} align='center' style={{ borderWidth: 0, padding: 1 }}>
                <Button
                  fullWidth
                  style={{ height: 73.34 }}
                  variant='outlined'
                  color='primary'
                  startIcon={<Icon>add_box</Icon>}
                >
                  Add Rideshare Rate
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING RIDESHARE RATE' />}>
      <>
        {/* Modal containing a dropdown of rideshare rates for changing the contract's rideshare rate or adding an rideshare rate to the contract */}
        <RideshareRateFormModalWrapper
          open={formModalOpen}
          currentRideshareRateId={rideshareRate ? rideshareRate.id : null}
          selectedRideshareRate={unsavedRideshareRate}
          onEditSave={handleReplaceRideshare}
          onAddSave={handleAddRideshareRate}
          onCancel={() => {
            setFormModalOpen(false);
            setUnsavedRideshareRate(null);
          }}
        />

        <RideshareRateTable />
      </>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
  },
  rowIsSelected: {
    transition: 'all .2s ease-in-out',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c3c3c3',
      color: '#fff',
    },
  },
  rowHasChanges: {
    backgroundColor: '#ffe9ec',
    fontStyle: 'italic',
    color: '#fff',
  },
  addRideshareButton: {
    borderWidth: '1px',
    borderColor: 'primary',
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divide: {
    marginTop: '25px',
    marginBottom: '10px',
  },
}));