import 'date-fns';
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { LocationsTable } from './index';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import { LocationsFilter } from './index';
import * as Sentry from '@sentry/react';

export default function Locations(props) {
  const cls = useStyles();

  const [search, setSearch] = useState('');

  const { loading, error, data, refetch } = useQuery(GET_LOCATIONS);

  const handleSearchChange = event => setSearch(event.target.value);

  const handleFilteredLocations = (locations, search) => {
    if (!search || search.length < 1) return locations;
    else
      return locations.filter(
        o =>
          (o.name && o.name.toLocaleLowerCase().includes(search)) ||
          (o.nickname && o.nickname.toLocaleLowerCase().includes(search))
      );
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DefaultErrorFallback message={'ERROR FETCHING LOCATIONS'} />;
  }

  if (data && data.locations && data.locations.length < 1) {
    return <DefaultEmptyFallback message={'NO LOCATIONS FOUND'} />;
  }

  const locations = data.locations;
  let filteredLocations = handleFilteredLocations(locations, search);

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Locations' refetch={() => refetch()} actions={null}>
        <LocationsFilter search={search} onSearchChange={handleSearchChange} />
      </Toolbar>
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING TABLE' />}>
        <LocationsTable locations={filteredLocations} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));

const GET_LOCATIONS = gql`
  {
    locations(order_by: { updatedat: desc }, where: { active: { _eq: 1 } }) {
      id
      active
      address
      createdat
      customer_id
      customer {
        id
        name
      }
      email
      favorite
      latitude
      longitude
      name
      nickname
      phone
      notes
      place_id
      region_id
      region {
        id
        name
      }
      tookan_id
      type
      updatedat
    }
  }
`;
