//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import { useTimeline } from '../providers/TimelineProvider';
import { useEnrichedPlans } from '../hooks/useEnrichedPlans';
import { useSettings } from '../providers/SettingsProvider';
import { useKeyPress } from '../../../hooks/useKeyPress';
import { TimelineIndex, TimelineArea } from '../index';
import Loading from '../../../components/Loading';

//UNUSED
// import { SimpleLogger } from '../../../utils/SimpleLogger';
// const { log } = new SimpleLogger({ prefix: 'Timeline', enabled: true });

//////////////////////// COMPONENT ////////////////////////

export default function Timeline(props) {
  const cls = useStyles();

  const { state: timelineState, getLastScrollPosition, updateLastScrollSeconds } = useTimeline();
  const { timelineDate, setTimelineDate, isTimelineDateToday } = useSettings();
  const { getCurrentPosition } = useEnrichedPlans();
  const goToNowKeypress = useKeyPress('n');
  const tKeypress = useKeyPress('t');
  const altKeypress = useKeyPress('Alt');
  const timelineElement = React.useRef(null);

  React.useEffect(() => {
    scrollToPosition();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    scrollToPosition();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToNowKeypress, timelineDate, timelineState]);

  React.useEffect(() => {
    const itsNotToday = dayjs(timelineDate).day() !== dayjs().day();
    if (tKeypress && altKeypress && itsNotToday){
      setTimelineDate(dayjs());
      scrollToPosition();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tKeypress, altKeypress]);

  const scrollToPosition = () => {
    if (timelineElement && timelineElement.current) {
      if (isTimelineDateToday) {
        timelineElement.current.scrollLeft = getCurrentPosition() - 250;
      } else {
        const { pixels } = getLastScrollPosition();
        timelineElement.current.scrollLeft = pixels;
      }
    }
  };

  const handleScroll = () => {
    if (timelineElement && timelineElement.current) {
      updateLastScrollSeconds(timelineElement.current.scrollLeft);
    }
  };

  return (
    <div className={cls.timelineRoot}>
      <div className={cls.toolPad} />
      {timelineState === 'timeline.ready' && (
        <div ref={timelineElement} id='timeline' className={cls.timeline} onScroll={handleScroll}>
          <TimelineIndex />
          <TimelineArea />
        </div>
      )}
      {timelineState !== 'timeline.ready' && <Loading fixed />}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timelineRoot: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  toolPad: {
    position: 'relative',
    height: 112,
  },
  timeline: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
    // overflow: 'auto',
  },
}));

// The TimelineIndex sticks to the left while TimelineIndexHead sticks to the top (as well as TimelineAreaHead).
// This allows for the parent div (cls.timeline) to control all the scrolling happening within
