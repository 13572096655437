import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useData } from '../../providers/DataProvider';

import { makeStyles, IconButton, Icon, Tooltip } from '@material-ui/core';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading';
import LocationSearch from './LocationSearch';

import LaneCalculatorOutput from './MapLaneCalculator';

let log = true;

////////// COMPONENT //////////
export default function MapLane(props) {
  const cls = useStyles();
  const ctx = useData();

  const { allLocations, lane, origin, destination, setLane, setOrigin, setDestination } = props;

  const [rateRules, setRateRules] = useState([]);
  const [payRateGroups, setPayRateGroups] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleSwap = (o, d) => {
    setOrigin(d);
    setDestination(o);
  };

  useEffect(() => {
    if (origin && destination) {
      //get or create lane, get rateruls and pay rate rules
      log && console.log('GETLANE');
      getLaneByLocationsPlusRateRules(ctx.apolloClient, origin, destination, origin.customer_id, origin.region_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination]);

  const getLaneByLocationsPlusRateRules = async (client, pickup, delivery, customerId, regionId) => {
    log && console.log('GETLANE');
    setLoading(true);
    const res = await client.query({
      query: GET_LANES_AND_RATES,
      variables: { pickup_id: pickup.id, delivery_id: delivery.id, customer_id: customerId, region_id: regionId },
      fetchPolicy: 'network-only', //bypasses cache and checks db directly
    });
    if (res.data.raterules) {
      log && console.log('RATE_RULES', res.data.raterules);
      setRateRules(res.data.raterules);
    } else {
      //handle error
      toast.error('Could Not Get Rate Rules')
    }
    if (res.data.payrategroups) {
      log && console.log('PAY_RATE_RULES', res.data.payrategroups);
      setPayRateGroups(res.data.payrategroups);
    } else {
      toast.error('Could Not Get Pay Rate Rules')
    }
    if (res.data.lanes[0]) {
      //LANE EXISTS
      log && console.log('getLanebyLocation', res.data.lanes[0].id);
      setLane(res.data.lanes[0]);
    } else {
      //BUILD LANE BY LOCATION
      //TODO: ADD VALIDATION OF LANE OBJECT TO PREVENT ERRORS
      console.log("BUILDING LANE.....")
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/buildLanes',
        data: {
          type: 'fromLocations',
          customer_id: customerId,
          pickup: { name: pickup.name, address: pickup.address, id: pickup.id },
          delivery: { name: delivery.name, address: delivery.address, id: delivery.id },
        },
      });
      let newLanes = response.data;
      log && console.log('Newly built lanes: ', newLanes);
      setLane(newLanes[0]);
    }
    setLoading(false);
  };

  return (
    <>
      <div className={cls.lane}>
        {/* Lane Selection Section */}
        <div className={cls.sectionOne}>
          <div className={cls.flex}>
            <LocationSearch
              allLocations={allLocations}
              loading={loading}
              location={origin}
              onChange={setOrigin}
              placeholder='Select origin...'
              icon='gps_not_fixed'
              iconTip='Origin Select'
            />
            <Tooltip placement='top' title='Swap Origin & Destination'>
              <IconButton onClick={() => handleSwap(origin, destination)} className={cls.laneBtn}>
                <Icon style={{ color: '#fff' }}>swap_vert</Icon>
              </IconButton>
            </Tooltip>
          </div>

          <div className={cls.break} />

          <div className={cls.flex}>
            <LocationSearch
              allLocations={allLocations}
              loading={loading}
              location={destination}
              onChange={setDestination}
              placeholder='Select destination...'
              icon='gps_fixed'
              iconTip='Destination Select'
            />
            <Tooltip placement='top' title='Clear Lane'>
              <IconButton className={cls.laneBtn}>
                <Icon style={{ color: '#fff' }}>replay</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {loading ? (
          <>
            {/* Loading Lane Section */}
            <div className={cls.section}>
              <div className={cls.bigBreak} />
              <div className={cls.bigBreak} />
              <Loading />
              <div className={cls.bigBreak} />
              <div className={cls.bigBreak} />
            </div>
          </>
        ) : (
          <LaneCalculatorOutput
            lane={lane}
            setLane={setLane}
            rateRules={rateRules}
            payRateGroups={payRateGroups}
            regionId={origin ? origin.region_id : 0 || 0}
          />
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  lane: {
    width: '100%',
  },
  sectionOne: {
    background: theme.palette.text.primary,
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  section: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  descriptionTxt1: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  descriptionTxt2: {
    lineHeight: 1.2,
    fontSize: '18px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  historyBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  headerTxtL: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  headerTxtR: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  subheaderTxt: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  input: {
    verticalAlign: 'top',
    margin: 0,
  },
  laneBtn: {
    verticalAlign: 'top',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    verticalAlign: 'top',
    width: '100%',
  },
  flexLine: {
    width: '100%',
    height: '1px',
    marginTop: '5.5px',
    marginBottom: '5.5px',
    background: '#eee',
  },
  flexTxtL: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  flexTxtR: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    background: '#ddd',
  },
}));

////////// GRAPHQL //////////
const GET_LANES_AND_RATES = gql`
  query get_lanes_and_rates($delivery_id: bigint, $pickup_id: bigint, $customer_id: bigint, $region_id: bigint) {
    lanes(where: { destination_location_id: { _eq: $delivery_id }, origin_location_id: { _eq: $pickup_id } }) {
      id
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      customer_id
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      pickup {
        id
        region_id
      }
    }
    raterules(where: { customer_id: { _eq: $customer_id } }, order_by: { distance_end: asc }) {
      active
      class
      createdat
      customer_id
      distance_end
      distance_start
      id
      pay_rate_group_id
      rate
      rate_rule_group_id
      type
      updatedat
      raterulegroup {
        id
        ridesharerate {
          average_mph
          description
          effective_date
          expiration_date
          id
          initial_cost
          maximum_ride_cost
          minimum_ride_cost
          name
          per_mile_rate
          per_minute_rate
        }
      }
    }
    payrategroups(
      where: {
        end_date: { _gte: "now()" }
        active: { _eq: true }
        _or: [
          { customer_id: { _eq: $customer_id } }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _eq: $region_id } }] }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _is_null: true } }] }
        ]
      }
    ) {
      name
      description
      begin_date
      end_date
      payraterules {
        id
        distance_start
        distance_end
        per_mile_rate
        per_minute_rate
      }
      customer_id
      region_id
    }
  }
`;
