//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import SettingsResetToDefault from './SettingsResetToDefault';

//////////////////////// COMPONENT ////////////////////////

export default function SettingsOption({
  children,
  contained = false,
  disabled = false,
  title,
  description,
  gif,
  value,
  checkReset,
  onReset,
  resetText,
  resetTooltip,
  minWidth,
  maxWidth,
}) {
  const cls = useStyles();

  const clsxOption = clsx(cls.option, {
    [cls.optionContained]: contained,
    [cls.optionDisabled]: disabled,
  });

  return (
    <div className={clsxOption}>
      {title && description ? (
        <Grid container spacing={4} alignItems='flex-start'>
          <>
            <Grid item xs>
              <Typography className={cls.title}>{title}</Typography>
              <Spacer size={1} />
              <Typography className={cls.description}>{description}</Typography>
              <div className={cls.gif}>{gif}</div>
            </Grid>
            <Grid item style={{ minWidth: minWidth || `0%`, maxWidth: maxWidth || `100%` }}>
              {onReset ? (
                <SettingsResetToDefault
                  value={value}
                  checkReset={checkReset}
                  onReset={onReset}
                  resetText={resetText}
                  resetTooltip={resetTooltip}
                />
              ) : null}
              {onReset ? <Spacer size={2} /> : null}
              {children}
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid container spacing={4} alignItems='flex-start'>
            <Grid item xs>
              {children}
            </Grid>
          </Grid>
      )}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  option: {
    position: 'relative',
  },
  optionContained: {
    position: 'relative',
    padding: theme.spacing(2),
    outline: theme.border[0],
    outlineOffset: -1,
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
  },
  optionDisabled: {
    position: 'relative',
    padding: theme.spacing(2),
    outline: 'none',
    outlineOffset: 0,
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.dark,
  },

  title: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  description: {
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  gif: {},
}));
