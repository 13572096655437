//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, useTheme, Grid, Typography, Icon, Tooltip, Divider } from '@material-ui/core';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useTools } from '../../../hooks/useTools';
import { useScheduler } from '../providers/SchedulerProvider';
import useMoveActions from '../hooks/useMoveActions';

import { ReactComponent as IconLyft } from '../../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../../static/uber.svg';
import { ReactComponent as IconHD } from '../../../static/bunny.svg';

//////////////////////// COMPONENT ////////////////////////

export default function ScheduleOutlineRide({ enrichedDrive, enrichedRide, isAfter = false, onClick, index }) {
  const theme = useTheme();
  const cls = useStyles();
  const { getDurationInMinutes } = useTools();
  const { isPlanEditable } = useScheduler();
  const { actions } = useMoveActions(enrichedRide, isAfter);

  const { hasLinkBefore, hasLinkAfter, hasRideGapAfter } = enrichedDrive || {};

  const hasLink = isAfter ? hasLinkAfter : hasLinkBefore;

  let { withOverrides: rideWithOverrides = {} } = enrichedRide || {};

  //////////////////////// RENDER LOGIC ////////////////////////

  // Check if ride button should be disabled
  const isDisabled = () => {
    //If ride is complete or in progress, it will be disabled
    //For before rides, if drive is inProgress or complete, button will disable
    if (enrichedRide && (enrichedRide.inProgress || enrichedRide.isComplete)) {
      return true;
    } else if (!isAfter && enrichedDrive && (enrichedDrive.inProgress || enrichedDrive.isComplete)) {
      return true;
    } else {
      return false;
    }
  };

  // Create style for ride button in specific cases
  const createStyle = type => {
    let style = {};

    if (isDisabled()) style.pointerEvents = `none`;

    if (type === `full` || type === `floating`) {
      if (rideWithOverrides.ride_type === `lyft`) style.background = theme.palette.lyft.main;
      else if (rideWithOverrides.ride_type === `uber`) style.background = theme.palette.uber.main;
      else if (rideWithOverrides.ride_type === `shuttle`) style.background = theme.palette.primary.main;
      else style.background = theme.palette.auto.main;
    }

    return style;
  };

  //////////////////////// BABY COMPONENTS ////////////////////////

  // Get the rideshare partner's icon
  const RidePartnerIcon = () => {
    if (rideWithOverrides.ride_type === `lyft`) return <IconLyft className={cls.iconLyft} />;
    if (rideWithOverrides.ride_type === `uber`) return <IconUber className={cls.iconUber} />;
    if (rideWithOverrides.ride_type === `shuttle`) return <IconHD className={cls.iconHD} />;
    return <Icon className={cls.iconAuto}>hdr_auto</Icon>;
  };

  // Get the lane type for the ride
  const RideLaneIcon = () => {
    if (enrichedRide.rideLaneType === `next stop`) return <Icon className={cls.nextIcon}>reply</Icon>;
    if (enrichedRide.rideLaneType === `prev stop`) return <Icon className={cls.rideIcon}>reply</Icon>;
    if (enrichedRide.rideLaneType === `return ride`) return <Icon className={cls.returnIcon}>low_priority</Icon>;
    if (enrichedRide.rideLaneType === `parked car`) return <Icon className={cls.rideIcon}>local_parking</Icon>;
    return <Icon className={cls.rideIcon}>alt_route</Icon>;
  };

  // Get the correct tooltip to show
  const AttachmentTooltip = type => {
    let actionTxt = `Click to Add Ride`;
    if (type === `full`) actionTxt = `Click to Edit Ride`;
    if (type === `floating`) actionTxt = `Click to Edit Ride (Has Mismatched Locations)`;
    if (type === `half`) actionTxt = `Click to Add Ride (Linked to Previous Move)`;
    if (type === `loading`) actionTxt = `Loading Ride Duration...`;
    if (type === `link`) actionTxt = isAfter ? `Linked to Next Move` : `Linked to Previous Move`;
    if (type === `error`) actionTxt = `Ride Cannot Be Saved - Error with Lane`;
    if (type === `blank`) actionTxt = `Cannot Add Ride`;

    const lane = rideWithOverrides.lane || {};
    let pickupName = null;
    let deliveryName = null;
    let description = lane.description;
    if (lane && lane.pickup && lane.delivery) {
      pickupName = lane.pickup.name;
      deliveryName = lane.delivery.name;
    }

    return (
      <>
        <div>{actionTxt}</div>
        {lane ? (
          <>
            <hr style={{ opacity: 0.5 }} />
            {(pickupName && deliveryName) || !description ? (
              <>
                <div>{pickupName}</div>
                <div>to {deliveryName}</div>
              </>
            ) : (
              <div>{description}</div>
            )}
          </>
        ) : null}
      </>
    );
  };

  //////////////////////// ATTACHMENTS ////////////////////////

  // Existing Ride or ride created through modal
  const FullAttachment = props => {
    return (
      <>
        <ContextMenuTrigger
          id={`ride-attachment-${index}-${isAfter}-cm`}
          source={`ride-attachment-${index}-${isAfter}-cm`}
        >
          <Tooltip placement='top' title={AttachmentTooltip(`full`)}>
            <div
              className={cls.fullAttachment}
              onClick={e => (onClick ? onClick(e) : null)}
              style={createStyle(`full`)}
            >
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs>
                  <div className={cls.rideHold}>{RidePartnerIcon()}</div>
                </Grid>
                <Grid item>
                  <div className={cls.iconHold}>{RideLaneIcon()}</div>
                  <Typography className={cls.rideText}>{getDurationInMinutes(enrichedRide.duration)}</Typography>
                </Grid>
              </Grid>
            </div>
          </Tooltip>
        </ContextMenuTrigger>

        <ContextMenu id={`ride-attachment-${index}-${isAfter}-cm`}>
          {actions.map((action, i) => {
            if (!action || action.hide) return null;
            if (!action.handler) return <Divider key={`ride-attachment-action-${i}`} className={cls.divider} />;
            return (
              <ContextMenuItem
                key={`ride-attachment-action-${i}`}
                disabled={action.disabled}
                onClick={() => action.handler(rideWithOverrides)}
              >
                {action.label}
              </ContextMenuItem>
            );
          })}
        </ContextMenu>
      </>
    );
  };

  // Existing Ride or ride created through modal (floating because locations dont match)
  const FloatingAttachment = props => {
    return (
      <>
        <ContextMenuTrigger
          id={`ride-attachment-${index}-${isAfter}-cm`}
          source={`ride-attachment-${index}-${isAfter}-cm`}
        >
          <Tooltip placement='top' title={AttachmentTooltip(`floating`)}>
            <div
              className={cls.floatingAttachment}
              onClick={e => (onClick ? onClick(e) : null)}
              style={createStyle(`floating`)}
            >
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs>
                  <div className={cls.rideHold}>{RidePartnerIcon()}</div>
                </Grid>
                <Grid item>
                  <div className={cls.iconHold}>{RideLaneIcon()}</div>
                  <Typography className={cls.rideText}>{getDurationInMinutes(enrichedRide.duration)}</Typography>
                </Grid>
              </Grid>
            </div>
          </Tooltip>
        </ContextMenuTrigger>

        <ContextMenu id={`ride-attachment-${index}-${isAfter}-cm`}>
          {actions.map((action, i) => {
            if (!action || action.hide) return null;
            if (!action.handler) return <Divider key={`ride-attachment-action-${i}`} className={cls.divider} />;
            return (
              <ContextMenuItem
                key={`ride-attachment-action-${i}`}
                disabled={action.disabled}
                onClick={() => action.handler(rideWithOverrides)}
              >
                {action.label}
              </ContextMenuItem>
            );
          })}
        </ContextMenu>
      </>
    );
  };

  // Placeholder/New ride (with or without duration)
  const EmptyAttachment = ({ hasDuration = false }) => {
    return (
      <Tooltip placement='top' title={AttachmentTooltip(`empty`)}>
        <div className={cls.emptyAttachment} onClick={e => (onClick ? onClick(e) : null)} style={createStyle(`empty`)}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs>
              <div className={cls.rideHold}>
                <Icon className={hasDuration ? cls.iconPlus : cls.emptyAttachmentIcon}>add</Icon>
              </div>
            </Grid>
            {hasDuration ? (
              <Grid item>
                <div className={cls.iconHold}>{RideLaneIcon()}</div>
                <Typography className={cls.rideText}>{getDurationInMinutes(enrichedRide.duration)}</Typography>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Tooltip>
    );
  };

  // Ride is not editable
  const BlankAttachment = props => {
    return (
      <Tooltip placement='top' title={AttachmentTooltip(`blank`)}>
        <div className={cls.emptyAttachment}>
          <Icon className={cls.emptyAttachmentIcon}>remove</Icon>
        </div>
      </Tooltip>
    );
  };

  // Loading ride (hourglass spinner)
  const LoadingAttachment = props => {
    return (
      <Tooltip placement='top' title={AttachmentTooltip(`loading`)}>
        <div className={cls.emptyAttachment}>
          <Icon className={cls.emptyAttachmentIconLoading}>hourglass_empty</Icon>
        </div>
      </Tooltip>
    );
  };

  // Loading ride (hourglass spinner)
  const LinkAttachment = props => {
    return (
      <Tooltip placement='top' title={AttachmentTooltip(`link`)}>
        <div className={cls.fullAttachment}>
          <Icon className={cls.fullAttachmentIcon}>link</Icon>
        </div>
      </Tooltip>
    );
  };

  // Loading ride (hourglass spinner)
  const ErrorAttachment = props => {
    return (
      <Tooltip placement='top' title={AttachmentTooltip(`error`)}>
        <div className={cls.errorAttachment}>
          <Icon className={cls.errorAttachmentIcon}>cancel_presentation</Icon>
        </div>
      </Tooltip>
    );
  };

  //////////////////////// RENDER ////////////////////////

  // Show link if the drive matches locations of the adjacent drive
  if (hasLink) {
    return <LinkAttachment />;
  }
  // Show loading if the drive has a ride gap or there is no lane data on the ride (provided by google)
  //Loading states are: 1) there is a 'ride gap' (location mismatch) between two unstarted drives- this means a placeholder ride will be created
  // 2) a ride's lane is being built
  if (
    (hasRideGapAfter && !enrichedDrive.inProgress && !enrichedDrive.isComplete) ||
    (enrichedRide &&
      (enrichedRide.googleEnrichmentStatus === 'running' || enrichedRide.googleEnrichmentStatus === 'new'))
  ) {
    return <LoadingAttachment />;
  }

  if (enrichedRide && enrichedRide.googleEnrichmentStatus === 'failure') {
    return <ErrorAttachment />;
  }
  if (enrichedRide && enrichedRide.isPlaceholder === false) {
    return <FloatingAttachment />;
  }
  if (enrichedRide && enrichedRide.isPlaceholder === false) {
    return <FullAttachment />;
  }

  // Show the cannot add ride button
  if (!isPlanEditable) {
    return <BlankAttachment />;
  }

  // Show the placeholder attachment with the duration (provided by google)
  if (enrichedRide && enrichedRide.googleEnrichmentStatus === 'successful') {
    return <EmptyAttachment hasDuration />;
  }

  // Fallback to showing the default empty add attachment
  return <EmptyAttachment />;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  fullAttachment: {
    position: 'relative',
    width: 112,
    height: 48,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.default.main,
    color: theme.palette.text.contrast,
    cursor: 'pointer',
  },
  fullAttachmentIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.contrast,
  },
  floatingAttachment: {
    position: 'relative',
    width: 112,
    height: 48,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.default.light,
    color: theme.palette.text.contrast,
    cursor: 'pointer',
  },
  errorAttachment: {
    position: 'relative',
    width: 112,
    height: 48,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.error.main,
    color: theme.palette.text.contrast,
    cursor: 'pointer',
  },
  emptyAttachment: {
    position: 'relative',
    width: 112,
    height: 48,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.text.secondary}`,
    borderStyle: 'dashed',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& svg': {
      '& path': {
        fill: theme.palette.text.secondary,
      },
      '& rect': {
        fill: theme.palette.text.secondary,
      },
      '& polygon': {
        fill: theme.palette.text.secondary,
      },
    },
  },
  emptyAttachmentIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.secondary,
  },
  emptyAttachmentIconLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.secondary,
    animation: 'hourglass2 1.667s infinite',
    animationTimingFunction: 'ease-in-out',
  },
  errorAttachmentIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.contrast,
  },
  rideText: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
  },
  iconHold: {
    width: 18,
    height: 18,
    marginBottom: theme.spacing(0.25),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rideIcon: {
    fontSize: 18,
  },
  nextIcon: {
    transform: 'rotateY(180deg)',
    fontSize: 18,
  },
  returnIcon: {
    transform: 'rotateX(180deg)',
    fontSize: 18,
  },
  rideHold: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconPlus: {
    height: 18,
  },
  iconAuto: {
    fontSize: 24,
    marginTop: 4,
    marginBottom: -2,
  },
  iconLyft: {
    height: 18,
    marginTop: 8,
  },
  iconUber: {
    height: 14,
    marginTop: 5,
  },
  iconShuttle: {
    height: 24,
    marginTop: 4,
  },
  iconHD: {
    height: 18,
    marginTop: 6,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
}));
