// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useTools } from '../../hooks/useTools';

import * as Sentry from '@sentry/react';

import { Loading } from '@hopdrive/storybook';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';

import { RegionsProvider } from './RegionsProvider';
import RegionsSidebar from './RegionsSidebar';
import RegionsMap from './RegionsMap';

const log = false;

// COMPONENT -------------------------------------------------- //

export default function Regions(props) {
  const cls = useStyles();

  const { convertRegionGeofenceToReadable } = useTools();

  const { loading, error, data, refetch } = useQuery(GET_REGIONS, { fetchPolicy: `network-only` });

  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return <Loading position='fixed' />;
  }

  // ERROR STATE //
  if (error) {
    console.error(`Failed to find regions:`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FINDING REGIONS' />;
  }

  // EMPTY STATE //
  if (!data || !data.regions || !data.regions.length > 0) {
    log && console.log(`No regions were found!`);
    return <DefaultEmptyFallback message='NO REGIONS FOUND' />;
  }

  // DATA STATE //
  let regions = data.regions;
  regions = regions.map(region => ({ ...region, points: convertRegionGeofenceToReadable(region) }));
  log && console.log(`Regions:`, regions);

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback />}>
      <div className={cls.root}>
        <RegionsProvider regions={regions} refetch={handleRefetch}>
          <Grid container>
            <Grid item>
              <div className={cls.heightUnlock}>
                <RegionsSidebar regions={regions} refetch={handleRefetch} />
              </div>
            </Grid>

            <Grid item xs>
              <div className={cls.heightUnlock}>
                <RegionsMap regions={regions} refetch={handleRefetch} />
              </div>
            </Grid>
          </Grid>
        </RegionsProvider>
      </div>
    </Sentry.ErrorBoundary>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  heightUnlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 64,
  },
}));

// GRAPHQL -------------------------------------------------- //

const GET_REGIONS = gql`
  query admin_getRegions {
    regions(order_by: { name: asc }) {
      id
      accounting_class_num
      description
      email
      geofence
      name
      timezone
      createdat
      updatedat
      status
    }
  }
`;
