import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Chip, Icon, TableRow, TableCell, TableHead, TableBody, Table, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

import InsuranceRateFormModalWrapper from './InsuranceRateFormModalWrapper';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';
// import { useTools } from '../../hooks/useTools';
import { Divide } from '@hopdrive/storybook';

const log = false;

export default function CustomerPricingInsurance({
  insuranceRate,
  rateRuleGroupId,
  onChange = insuranceRate => {},
  setHasChanged,
}) {
  const cls = useStyles();

  // const { goToInsuranceDetails } = useTools();

  const [currentInsuranceRateId, setCurrentInsuranceRateId] = useState(0);
  const [unsavedInsuranceRate, setUnsavedInsuranceRate] = useState(null);
  const [formModalOpen, setFormModalOpen] = useState(false);

  useEffect(() => {
    log && console.log('insuranceRate', insuranceRate);
    setUnsavedInsuranceRate(null);
    if (insuranceRate && currentInsuranceRateId !== insuranceRate.id) {
      setCurrentInsuranceRateId(insuranceRate.id);
    }
  }, [insuranceRate, currentInsuranceRateId]);

  const handleRowClick = insuranceRate => event => {
    log && console.log('handleRowClick()');
    log && console.log('handleRowClick() -- insurance rate:', insuranceRate);
    setFormModalOpen(true);
  };

  const handleAddInsuranceRate = newInsuranceRate => {
    log && console.log('setting new insurance rate', newInsuranceRate);
    setUnsavedInsuranceRate(newInsuranceRate);
    onChange(newInsuranceRate);
    setHasChanged(true);
    setFormModalOpen(false);
  };

  const getInsuranceRateStatus = (beginDate, endDate) => {
    const isExpired = dayjs(endDate).isBefore(dayjs());
    const isPending = dayjs(beginDate).isAfter(dayjs());
    if (isExpired) {
      return <Chip label='Insurance Status: Expired' variant='default' size='small' color='secondary' />;
    } else if (isPending) {
      return <Chip label='Insurance Status: Pending' variant='default' size='small' color='tertiary' />;
    } else {
      return <Chip label='Insurance Status: Active' variant='default' size='small' color='primary' />;
    }
  };

  const handleReplaceInsurance = savedInsuranceRate => {};

  const InsuranceRateTable = () => {
    log && console.log(insuranceRate, unsavedInsuranceRate);
    //There will only ever be one row in this table. The options are:
    // 1) There is an insurance rate saved to the contract and the user has not switched it for a new one
    // 2) The user has selected a new insurance rate for the contract to replace the old one
    // 3) No insurance rate has been added
    if (insuranceRate && !unsavedInsuranceRate) {
      return (
        <>
          <div className={cls.divide}>
            <Divide>Insurance Rates (Per Mile)</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='insurance rate table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Region</TableCell>
                <TableCell align='right'>Class 1 & 2</TableCell>
                <TableCell align='right'>Class 3</TableCell>
                <TableCell align='right'>Class 4</TableCell>
                <TableCell align='right'>Class 5</TableCell>
                <TableCell align='right'>Class 6</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={insuranceRate.id || 'insuranceRateRow'}>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem}>
                  {insuranceRate.name}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem}>
                  {insuranceRate.state ?? '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem}>
                  {insuranceRate.region && insuranceRate.region.name ? insuranceRate.region.name : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem} align='center'>
                  {insuranceRate?.rate ? `$${insuranceRate?.rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem} align='right'>
                  {insuranceRate?.class_3_rate ? `$${insuranceRate?.class_3_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem} align='right'>
                  {insuranceRate?.class_4_rate ? `$${insuranceRate?.class_4_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem} align='right'>
                  {insuranceRate?.class_5_rate ? `$${insuranceRate?.class_5_rate?.toFixed(2)}` : '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem} align='right'>
                  {insuranceRate?.class_6_rate ? `$${insuranceRate?.class_6_rate?.toFixed(2)}` : '-'}
                </TableCell>
              </TableRow>
              <TableRow hover key={-1}>
                <TableCell
                  colSpan={8}
                  align='center'
                  onClick={handleRowClick(insuranceRate)}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='disabled' size='small'>
                    {getInsuranceRateStatus(insuranceRate.effective_date, insuranceRate.expiration_date)}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      );
    } else if (unsavedInsuranceRate) {
      return (
        <>
          <div className={cls.divide}>
            <Divide>Insurance Rate</Divide>
          </div>
          <Table className={cls.table} size='small' aria-label='insurance rate table'>
            <TableHead>
              <TableRow>
              <TableCell>Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Region</TableCell>
                <TableCell align='right'>Class 1 & 2</TableCell>
                <TableCell align='right'>Class 3</TableCell>
                <TableCell align='right'>Class 4</TableCell>
                <TableCell align='right'>Class 5</TableCell>
                <TableCell align='right'>Class 6</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={unsavedInsuranceRate.id || 'unsavedInsuranceRateRow'} className={cls.rowHasChanges}>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem}>
                  {unsavedInsuranceRate.name}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem}>
                  {unsavedInsuranceRate.state}
                </TableCell>
                <TableCell onClick={handleRowClick(insuranceRate)} className={cls.clickableItem}>
                  {unsavedInsuranceRate?.region?.name || '-'}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem} align='right'>
                  ${unsavedInsuranceRate?.rate?.toFixed(2)}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem} align='right'>
                  ${unsavedInsuranceRate?.class_3_rate?.toFixed(2)}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem} align='right'>
                  ${unsavedInsuranceRate?.class_4_rate?.toFixed(2)}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem} align='right'>
                  ${unsavedInsuranceRate?.class_5_rate?.toFixed(2)}
                </TableCell>
                <TableCell onClick={handleRowClick(unsavedInsuranceRate)} className={cls.clickableItem} align='right'>
                  ${unsavedInsuranceRate?.class_6_rate?.toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell
                  colSpan={8}
                  align='center'
                  onClick={handleRowClick(unsavedInsuranceRate)}
                  className={cls.clickableItem}
                >
                  <Button variant='text' color='disabled' size='small'>
                    {getInsuranceRateStatus(unsavedInsuranceRate?.effective_date, unsavedInsuranceRate?.expiration_date)}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      );
    } else {
      return (
        <Table className={cls.table} aria-label='insurance rate table'>
          <div className={cls.divide}>
            <Divide>Insurance Rate</Divide>
          </div>
          <TableBody>
            <TableRow
              hover
              className={cls.addInsuranceButton}
              key={-1}
              onClick={() => {
                setFormModalOpen(true);
              }}
            >
              <TableCell colSpan={5} align='center' style={{ borderWidth: 0, padding: 1 }}>
                <Button
                  fullWidth
                  style={{ height: 73.34 }}
                  variant='outlined'
                  color='primary'
                  startIcon={<Icon>add_box</Icon>}
                >
                  Add Insurance
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INSURANCE RATE' />}>
      <>
        {/* Modal containing a dropdown of insurance rates for changing the contract's insurance rate or adding an insurance rate to the contract */}
        <InsuranceRateFormModalWrapper
          open={formModalOpen}
          currentInsuranceRateId={insuranceRate ? insuranceRate.id : null}
          selectedInsuranceRate={unsavedInsuranceRate}
          onEditSave={handleReplaceInsurance}
          onAddSave={handleAddInsuranceRate}
          onCancel={() => {
            setFormModalOpen(false);
            setUnsavedInsuranceRate(null);
          }}
        />

        <InsuranceRateTable />
      </>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
  },
  rowIsSelected: {
    transition: 'all .2s ease-in-out',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c3c3c3',
      color: '#fff',
    },
  },
  rowHasChanges: {
    backgroundColor: '#ffe9ec',
    fontStyle: 'italic',
    color: '#fff',
  },
  addInsuranceButton: {
    borderWidth: '1px',
    borderColor: 'primary',
  },
  clickableItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  divide: {
    marginTop: '25px',
    marginBottom: '10px',
  },
}));
