//////////////////////// DEPENDENCIES ////////////////////////
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../../components/TableComponents';
import dayjs from 'dayjs';
import { useTools } from '../../hooks/useTools';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

export default function InsuranceRatesTable(props) {
  const cls = useStyles();

  const { insuranceRates } = props;

  const { goToInsuranceDetails } = useTools();

  const columnConfig = [
    {
      name: `id`,
      value: insurancerate => insurancerate.id, // insurancerate.id is the value for this column
      align: `left`,
      label: `ID`,
    },
    {
      name: `name`,
      value: insurancerate => insurancerate.name,
      align: `left`,
      label: `Name`,
      sortable: true,
    },
    {
      name: `description`,
      value: insurancerate => insurancerate.description,
      align: `left`,
      label: `Description`,
      tooltip: `Description of the Insurance Rate`,
      sortable: true,
    },
    {
      name: `state`,
      value: product => product.state,
      align: `left`,
      label: `State`,
      tooltip: `The US State in which the rate applies`,
      sortable: true,
    },
    {
      name: `region.name`,
      value: product => getPropValue(product, 'region.name'),
      align: `left`,
      label: `Region Name`,
      tooltip: `The HopDrive Region associated with the insurance rate`,
      sortable: true,
      style: {minWidth: 160}
    },
    {
      name: `effective_date`,
      value: product => product.effective_date,
      renderer: product => dayjs(product.effective_date).format("MM/DD/YYYY"),
      align: `left`,
      label: `Start Date`,
      sortable: true,
      style: {minWidth: 140}
    },
    {
      name: `expiration_date`,
      value: product => product.expiration_date,
      renderer: product => dayjs(product.expiration_date).format("MM/DD/YYYY"),
      align: `left`,
      label: `End Date`,
      sortable: true,
      style: {minWidth: 140}
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={insuranceRates}
        columnConfig={columnConfig}
        onRowClick={insurancerate => goToInsuranceDetails(insurancerate.id)}
        defaultOrderBy={'id'}
        defaultOrder={'asc'}
        tableAriaLabel={`insurancerates`}
        stickyHeader={true}
        // classes={ }  // Override or extend the styles applied to the component. See CSS API below for more details.
      />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
}));