//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useTools } from '../../hooks/useTools';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import Toolbar from '../../components/Toolbar';

import CustomersFilter from './CustomersFilter';
import CustomersTable from './CustomersTable';

const getDefaultCustomerIdArray = () => {
  const localCustomerIdArrayString = localStorage.getItem(`customers-index-customer-ids`);
  const localCustomerIdArray = localCustomerIdArrayString ? localCustomerIdArrayString.split(',') : null;
  const intParsedLocalCustomerIdArray =
    localCustomerIdArray && Array.isArray(localCustomerIdArray)
      ? localCustomerIdArray.map(customerId => parseInt(customerId))
      : [];
  if (intParsedLocalCustomerIdArray && intParsedLocalCustomerIdArray.length > 0) return intParsedLocalCustomerIdArray;
  return [];
};

const getDefaultStatus = () => {
  const localStatus = localStorage.getItem(`customers-index-status`);
  if (localStatus) return localStatus;
  else return `all`;
};

//////////////////////// COMPONENT ////////////////////////

export default function Customers(props) {
  const cls = useStyles();

  const { goToRoute } = useTools();

  // Component state
  const [customerIdArray, setCustomerIdArray] = React.useState(getDefaultCustomerIdArray());
  const [status, setStatus] = React.useState(getDefaultStatus());
  const [search, setSearch] = React.useState(``);

  // Handle add customer
  const handleAddCustomer = () => {
    goToRoute(`/customers/add`);
  };

  // Control customer filter
  const handleCustomersChange = (customerIds = []) => {
    if (customerIds) {
      localStorage.setItem(`customers-index-customer-ids`, customerIds);
      setCustomerIdArray(customerIds);
    }
  };

  // Control status filter
  const handleStatusChange = (status = null) => {
    if (status) {
      localStorage.setItem(`customers-index-status`, status);
      setStatus(status);
    }
  };

  // Query
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMERS, {
    variables: {
      start: dayjs().startOf(`day`).subtract(30, `day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
    fetchPolicy: `cache-and-network`,
  });

  // Handle clear filters
  const handleClearFilters = () => {
    localStorage.setItem(`customers-index-customer-ids`, ``);
    localStorage.setItem(`customers-index-status`, `all`);

    setCustomerIdArray([]);
    setStatus(`all`);
    setSearch(``);
  };

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Customers'>
          <CustomersFilter />
        </Toolbar>

        <DefaultLoadingFallback message='LOADING CUSTOMERS' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching customers index:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
          <CustomersFilter
            handleAddCustomer={handleAddCustomer}
            customerIdArray={customerIdArray}
            onCustomersChange={handleCustomersChange}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING CUSTOMERS' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data?.customers?.length) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
          <CustomersFilter
            handleAddCustomer={handleAddCustomer}
            customerIdArray={customerIdArray}
            onCustomersChange={handleCustomersChange}
            status={status}
            onStatusChange={handleStatusChange}
            search={search}
            onSearchChange={setSearch}
          />
        </Toolbar>

        <DefaultEmptyFallback message='NO CUSTOMERS FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const customers = data?.customers;

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Customers' onClear={handleClearFilters} refetch={handleRefetch}>
        <CustomersFilter
          handleAddCustomer={handleAddCustomer}
          customerIdArray={customerIdArray}
          onCustomersChange={handleCustomersChange}
          status={status}
          onStatusChange={handleStatusChange}
          search={search}
          onSearchChange={setSearch}
        />
      </Toolbar>

      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING CUSTOMERS TABLE' />}>
        <CustomersTable customers={customers} customerIdArray={customerIdArray} status={status} search={search} />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_CUSTOMERS = gql`
  query admin_getCustomers($start: timestamptz!, $end: timestamptz!) {
    customers(order_by: { name: asc }) {
      id
      active
      address
      email
      name
      phone
      status
      moves_aggregate(
        where: {
          active: { _eq: 1 }
          chargeable: { _eq: true }
          move_type: { _eq: "drive" }
          pickup_time: { _gte: $start, _lte: $end }
          status: { _eq: "delivery successful" }
          _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
        }
      ) {
        aggregate {
          count
        }
      }
      organization {
        id
        name
      }
    }
  }
`;
