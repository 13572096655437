// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import StaticDriverTile from '../../../DriverComponents/StaticDriverTile';

// COMPONENT ---------------------------------------------------------------- //

export default function StaticPlanIndexRow({ dataItem, index }) {
  const cls = useStyles();

  return <StaticDriverTile driver={dataItem?.driver} index={index} />;
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
