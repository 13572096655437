import React from 'react';
import AssignedChaseVehiclesContent from './AssignedChaseVehiclesContent';
import { Button, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '../../components/Toolbar';
import AddAssignedChaseVehicleContent from './AddAssignedChaseVehicleModal';
import { Modal } from '../../components/ModalComponents';

export default function AssignedChaseVehicles(props) {
  const theme = useTheme();
  const cls = useStyles();
  const chaseId = props.match.params.id;
  const chaseIdNumber = Number(chaseId);
  console.log(`Chase ID:`, typeof chaseIdNumber);

  const [addOpen, setAddOpen] = React.useState(false);

  return (
    <div className={cls.root}>
      <Toolbar fullscreen shadow position='relative' title='Assigned Chase Vehicles' back>
        <div className={cls.flex}>
          <div className={cls.flexStretch} />
          <Button variant='contained' color='primary' onClick={() => setAddOpen(true)} size='medium'>
            Add Vehicle
          </Button>
        </div>
      </Toolbar>
      <AssignedChaseVehiclesContent chaseId={chaseIdNumber} />
      <Modal open={addOpen} onClose={() => setAddOpen(false)} width='sm'>
        <AddAssignedChaseVehicleContent chaseId={chaseIdNumber} onClose={() => setAddOpen(false)} />
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: 64,
    marginTop: -64,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  flexStretch: {
    flex: 1,
  },
}));
