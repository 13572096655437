import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Typography, TextField, InputAdornment, Icon, LinearProgress } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useDrivers } from '../Drivers/useDrivers';

//////////////////////// COMPONENT ////////////////////////

export default function TerminationModalContent({ input, onClose }) {
  const cls = useStyles();
  const { terminateDriver } = useDrivers();

  const [reason, setReason] = React.useState(``);

  const [isSaving, setIsSaving] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  // Check if the form is valid before allowing the submit button
  React.useEffect(() => {
    if (reason.length > 2) setIsValid(true);
    else setIsValid(false);
  }, [reason]);

  // Handle submitting the form
  const handleSubmit = async () => {
    const res = await terminateDriver(input.driver, reason);
    if (res) {
      toast.success(`Successfully terminated driver!`, { autoClose: 2500 });
      if (onClose) onClose();
    } else {
      toast.error(`Failed to terminate driver, please contact an admin!`);
    }

    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Terminate Driver</ModalHeader>

      <ModalContent>
        <Typography className={cls.subtitle}>
          Please enter a valid reason of termination below. Please follow up with anyone on a need-to-know basis,
          especially the driver.
        </Typography>

        <Spacer />

        <TextField
          required
          fullWidth
          multiline
          label='Termination Reason'
          placeholder='Enter termination reason...'
          variant='outlined'
          value={reason}
          onChange={e => setReason(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  comment
                </Icon>
              </InputAdornment>
            ),
          }}
        />
      </ModalContent>

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <ModalAction
          loading={isSaving}
          disabled={isSaving || !isValid}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));
