import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';

import RegionSelect from '../../components/RegionSelect';
import DriverSelect from '../../components/DriverSelect';

//////////////////////// COMPONENT ////////////////////////

export default function DriverPayoutsFilter2({
  regionId,
  driverId,
  status,
  search,
  onRegionIdChange,
  onDriverIdChange,
  onStatusChange,
  onSearchChange,
}) {
  const cls = useStyles();

  const handleRegionId = event => {
    if (onRegionIdChange) onRegionIdChange(event.target.value);
  };
  const handleDriverId = event => {
    if (onDriverIdChange) onDriverIdChange(event.target.value);
  };
  const handleStatus = event => {
    if (onStatusChange) onStatusChange(event.target.value);
  };
  const handleSearch = event => {
    if (onSearchChange) onSearchChange(event.target.value);
  };

  return (
    <>
      <div className={cls.filter}>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item xs={3}>
            <RegionSelect allowAll label='Region Filter' value={regionId || 0} onChange={handleRegionId} />
          </Grid>

          <Grid item xs={3}>
            <DriverSelect selectAllItem label='Driver Filter' value={driverId || 0} onChange={handleDriverId} />
          </Grid>

          <Grid item xs={2}>
            <TextField
              select
              fullWidth
              label='Status Filter'
              placeholder='Select a status filter...'
              variant='outlined'
              size='small'
              value={status}
              onChange={handleStatus}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      token
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={`all`}>All Statuses</MenuItem>
              <MenuItem value={`new`}>New</MenuItem>
              <MenuItem value={`processing`}>Processing</MenuItem>
              <MenuItem value={`successful`}>Successful</MenuItem>
              <MenuItem value={`failed`}>Failed</MenuItem>
              <MenuItem value={`canceled`}>Canceled</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label='Search'
              placeholder='Search table...'
              variant='outlined'
              size='small'
              value={search}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      search
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(2),
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
  },
}));
