// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineIndexHeader() {
  const cls = useStyles();

  const { header, headerSize } = useTimeline();

  if (!headerSize) return null;
  return (
    <div className={cls.timelineIndexHeader} style={{ flex: `0 0 ${headerSize}px` }}>
      {header}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineIndexHeader: {
    zIndex: 112,
    position: 'sticky',
    overflow: 'hidden',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },

  titleTxt: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
  spacer: {
    flex: 1,
  },
}));
