//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import CustomerMultiselect from '../../components/CustomerMultiselect/CustomerMultiselect';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';

const statuses = [
  { id: 1, label: 'Not Assigned', value: null },
  { id: 2, label: 'Dispatched', value: 'dispatched' },
  { id: 3, label: 'Pickup Started', value: 'pickup started' },
  { id: 4, label: 'Pickup Arrived', value: 'pickup arrived' },
  { id: 5, label: 'Pickup Successful', value: 'pickup successful' },
  { id: 6, label: 'Delivery Started', value: 'delivery started' },
  { id: 7, label: 'Delivery Arrived', value: 'delivery arrived' },
  { id: 8, label: 'Delivery Successful', value: 'delivery successful' },
  { id: 9, label: 'Failed', value: 'failed' },
];

const cancelStatuses = [
  { id: 1, label: 'Pending Cancel', value: 'pending' },
  { id: 2, label: 'Canceled After Started', value: 'started' },
  { id: 3, label: 'Canceled Before Started', value: 'canceled' },
]

//////////////////////// COMPONENT ////////////////////////

function MovesFilter({
  range,
  start,
  end,
  search,
  customerIdsArray,
  moveStatusArray,
  cancelStatusArray,
  disablePickers,
  onRangeChange,
  onDateChange,
  onSearchChange,
  onCustomerIdsChange,
  onStatusesChange,
  onCancelStatusesChange,
  handleSearchSubmit,
  checkedMoves,
  moves,
}) {
  const cls = useStyles();

  const [provisionalSearch, setProvisionalSearch] = React.useState();

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = name => event => {
    onDateChange(event, name);
  };

  const handleSearchChange = event => {
    event.preventDefault();
    if (provisionalSearch) {
      onSearchChange(`%${provisionalSearch}%`);
    } else onSearchChange('');
  };

  const handleSearchInputChange = event => {
    setProvisionalSearch(event.target.value);
  };

  return (
    <>
      <div className={cls.filter}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label='Date Range'
                placeholder='Select a date range...'
                variant='outlined'
                size='small'
                value={range}
                onChange={handleRangeChange}
              >
                <MenuItem value={`day`}>Today</MenuItem>
                <MenuItem value={`week`}>Past Week</MenuItem>
                <MenuItem value={`month`}>Past Month</MenuItem>
                <MenuItem value={`year`}>Past Year</MenuItem>
                <MenuItem value={`custom`}>Custom Range</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                maxDate={end}
                label='Start Date'
                format='MM/dd/yyyy'
                orientation='portrait'
                size='small'
                variant='inline'
                inputVariant='outlined'
                value={start}
                onChange={handleDateChange(`start`)}
                className={cls.date}
              />
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                minDate={start}
                label='End Date'
                format='MM/dd/yyyy'
                orientation='portrait'
                size='small'
                variant='inline'
                inputVariant='outlined'
                value={end}
                onChange={handleDateChange(`end`)}
                className={cls.date}
              />
            </Grid>

            <Grid item xs={3}>
              <CustomerMultiselect
                label='Customer Filter'
                selectedCustomerIdArray={customerIdsArray || []}
                handleChangeCustomers={onCustomerIdsChange}
              />
            </Grid>

            <Grid item xs={3}>
              <ReusableMultiselect
                label='Move Statuse'
                selectedArray={moveStatusArray?.map(status => status.id)}
                dataArray={statuses}
                optionName='label'
                handleOutput={onStatusesChange}
                noIdInLabel={true}
              />
            </Grid>

            <Grid item xs={3}>
              <ReusableMultiselect
                label='Cancel Statuse'
                selectedArray={cancelStatusArray?.map(status => status.id)}
                dataArray={cancelStatuses}
                optionName='label'
                handleOutput={onCancelStatusesChange}
                noIdInLabel={true}
              />
            </Grid>

            <Grid item xs={3}>
              <form onSubmit={handleSearchChange}>
                <TextField
                  fullWidth
                  labe6l='Search'
                  placeholder='Search table...'
                  variant='outlined'
                  size='small'
                  value={provisionalSearch}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon color='disabled' fontSize='small'>
                          search
                        </Icon>
                      </InputAdornment>
                    ),
                    endAdornment:
                      provisionalSearch || search ? ( // Render the "Enter" icon if there is input
                        <InputAdornment position='end'>
                          <Icon color='disabled' fontSize='small' onClick={handleSearchSubmit}>
                            keyboard_return
                          </Icon>
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  date: {
    width: '100%',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MovesFilter;
