//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container } from '@material-ui/core';
import dayjs from 'dayjs';

import * as Sentry from '@sentry/react';

import { GET_ZERO_DOLLAR_MOVES } from './gql';

import Loading from '../../components/Loading';
import Spacer from '../../components/Spacer';
import Toolbar from '../../components/Toolbar';

import ARReviewModal from '../../components/ARReview/ARReviewModal';
import ARLogsModal from '../../components/ARLogsModal';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import { ZeroDollarTable } from './index';

//////////////////////// COMPONENT ////////////////////////

function ZeroDollarMoves(props) {
  const cls = useStyles();

  const today = dayjs().startOf('day');
  const sixMonthsAgo = dayjs(today).subtract(6, 'month').format();

  const { loading, error, data, refetch } = useQuery(GET_ZERO_DOLLAR_MOVES, {
    variables: {
      dateLimit: sixMonthsAgo,
    },
  });

  // Review Modal
  const [arReviewModal, setARReviewModal] = React.useState({ open: false });
  const handleARReviewModalOpen = (armove = null) => {
    setARReviewModal({
      ...arReviewModal,
      open: true,
      input: armove,
    });
  };
  const handleARReviewModalClose = (output = null) => {
    if (output) setARReviewModal({ ...arReviewModal, open: false, output: output });
    else setARReviewModal({ ...arReviewModal, open: false });
  };

  // Logs Modal
  const [arLogsModal, setARLogsModal] = React.useState({ open: false });
  const handleARLogsModalOpen = (type = null, events = null) => {
    setARLogsModal({
      ...arLogsModal,
      open: true,
      input: {
        type: type,
        events: events,
      },
    });
  };
  const handleARLogsModalClose = (output = null) => {
    if (output) setARLogsModal({ ...arLogsModal, open: false, output: output });
    else setARLogsModal({ ...arLogsModal, open: false });
  };

  const handleRefetch = () => refetch();

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Zero Dollar Moves' />
          <Spacer />
          <Loading fixed />
        </Container>
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching zeroDollar moves:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Zero Dollar Moves' />
          <Spacer />
          <DefaultErrorFallback paper message='ERROR FETHING ZERO DOLLAR MOVES' />
        </Container>
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.moves || !data.moves.length > 0) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='Zero Dollar Moves' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback paper message='NO ZERO DOLLAR MOVES FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //

  // Moved cancel filtering to query

  const checkHasWaived = m => {
    if (
      m.accountsReceivable &&
      Array.isArray(m.accountsReceivable.details) &&
      m.accountsReceivable.details.length > 0
    ) {
      const details = m.accountsReceivable.details;
      const hasWaived = details.find(detail => detail.name === 'waived');
      if (hasWaived) {
        return true;
      } else {
        return false;
      }
    }
  };

  const zeroDollarMoves = data.moves;
  //Filters out 0 dollar moves with a "waived" accessorial

  const filteredZeroDollarMoves = zeroDollarMoves.filter(move => !checkHasWaived(move));

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING ZERO DOLLAR MOVES' />}>
      <ARReviewModal open={arReviewModal.open} onClose={handleARReviewModalClose} input={arReviewModal.input} />
      <ARLogsModal open={arLogsModal.open} onClose={handleARLogsModalClose} input={arLogsModal.input} />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title={`${filteredZeroDollarMoves.length} Zero Dollar Moves`} refetch={handleRefetch} />
          <Spacer />
          <ZeroDollarTable
            filteredZeroDollarMoves={filteredZeroDollarMoves}
            handleARReviewModalOpen={handleARReviewModalOpen}
            handleARLogsModalOpen={handleARLogsModalOpen}
          />
        </Container>
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default ZeroDollarMoves;
