// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';

import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import DriverPayHeader from './DriverPayHeader';
import DriverPayTable from './DriverPayTable';

import useDriverPay from './useDriverPay';

// COMPONENT ---------------------------------------------------------------- //

export default function DriverPayContent({ loading, error, paycycles, taxClass }) {
  const cls = useStyles();

  const { findFeaturedPaycycle } = useDriverPay();

  const [filteredPayCycles, setFilteredPayCycles] = React.useState(paycycles || []);
  const [featuredPaycycle, setFeaturedPaycycle] = React.useState(null);

  React.useEffect(() => {
    // Initialize filtered pay cycles
    let editedPaycycles = [...paycycles];

    // Filter pay cycles based on tax class
    editedPaycycles = editedPaycycles.filter(paycycle => paycycle.tax_class === taxClass);

    // Set the filtered pay cycles
    setFilteredPayCycles(editedPaycycles);

    // Set the featured pay cycle
    setFeaturedPaycycle(findFeaturedPaycycle(paycycles, taxClass));
  }, [paycycles, taxClass]);

  if (error) console.error(`Failed to fetch pay cycles:`, error);
  return (
    <>
      <DriverPayHeader featuredPaycycle={featuredPaycycle} />
      {loading ? <LinearProgress /> : null}
      {error ? <DefaultErrorFallback message={`Failed to fetch pay cycles`} /> : null}
      {!loading && !error && !filteredPayCycles?.length ? (
        <DefaultEmptyFallback message={`No Pay Cycles Found`} />
      ) : !loading && !error && filteredPayCycles?.length ? (
        <DriverPayTable paycycles={filteredPayCycles} />
      ) : null}
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({}));
