// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

import StaticPlanIndexRow from './DataTypes/StaticPlan/StaticPlanIndexRow';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineIndexRow({ dataItem, index }) {
  const cls = useStyles();

  const { dataType, rowSize } = useTimeline();

  return (
    <div
      className={clsx(
        cls.timelineIndexRow,
        cls[index % 2 ? 'even' : 'odd'],
        dataItem?.isSpecial ? cls[index % 2 ? 'specialEven' : 'specialOdd'] : null
      )}
      style={{ height: `${rowSize}px` }}
    >
      {dataType === `static-plan` ? <StaticPlanIndexRow dataItem={dataItem} index={index} /> : null}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineIndexRow: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  odd: {
    backgroundColor: theme.palette.background.paper,
  },
  even: {
    backgroundColor: theme.palette.background.light,
  },
  specialOdd: {
    backgroundColor: `${theme.palette.info.main}30`,
  },
  specialEven: {
    backgroundColor: `${theme.palette.info.main}40`,
  },
}));
