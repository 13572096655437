import React from 'react';
import { Grid, Typography, TextField, FormHelperText } from '@material-ui/core';

import LocationSelect from '../../components/LocationSelect';

export default function LanesEntryRow(props) {

    const { pickup, customer, lane, handleSetLocation, validateAddress, handleInputChange } = props;

    const Location = pickup ? lane.pickupName : lane.deliveryName;
    console.log("Location", Location)

    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6' component='h6'>
            {props.pickup ? 'Pickup Location' : 'Delivery Location'}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Typography variant='body1' component='p'>
                Location Lookup:
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <LocationSelect
                customerId={customer}
                onChange={event => handleSetLocation(event, pickup)}
                label={props.pickup ? 'pickupLocation' : 'deliveryLocation'}
                // value={props.pickup ? lane.pickupLocation : lane.deliveryLocation}
              />
            </Grid>
          </Grid>
        </Grid>
        {Location ? (
          <br />
        ) : (
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <Typography variant='body1' component='p'>
                  New Location:
                </Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems='center' justifyContent='flex-start' xs={12} spacing={1}>
              <Grid container item alignItems='center' justifyContent='space-between' xs={9} spacing={1}>
                <Grid item md={3} xs={4}>
                  <TextField
                    name={props.pickup ? 'pickupName' : 'deliveryName'}
                    label='Name'
                    value={props.pickup ? lane.pickupName : lane.deliveryName}
                    onChange={handleInputChange}
                    margin='normal'
                    variant='outlined'
                  />
                </Grid>
                <Grid item md={9} xs={8}>
                  <TextField
                    style={{ minWidth: '25rem' }}
                    name={props.pickup ? 'pickupStreet' : 'deliveryStreet'}
                    label='Address'
                    value={props.pickup ? lane.pickupStreet : lane.deliveryStreet}
                    onBlur={validateAddress}
                    onChange={handleInputChange}
                    margin='normal'
                    variant='outlined'
                    error={lane.addressError}
                  />
                  <FormHelperText
                    id='address-input-error-text'
                    style={{ display: lane.addressError ? 'block' : 'none' }}
                  >
                    Address must contain commas between address, city, and state
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };