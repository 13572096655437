// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import uuid from 'uuid';

import { makeStyles, Typography, Icon, Divider } from '@material-ui/core';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { condensedCase } from '../../utils/GeneralUtils';

import { buildDefaultRideActions } from './MoveActions';
import RideTooltip from './RideTooltip';

import { ReactComponent as IconLyft } from '../../static/lyft.svg';
import { ReactComponent as IconUber } from '../../static/uber.svg';
import { ReactComponent as IconHD } from '../../static/bunny.svg';

// -------------------------------- COMPONENT -------------------------------- //

export default function StaticRideTileAlt({
  move,
  index,
  showMoveId,
  zIndex = 1,
  gap = 0,
  offset = 0,
  width,
  minWidth,
  maxWidth,
  isSelectable,
  isSelected,
  onSelected,
  isPlaceholder,
  timezoneOverride = dayjs.tz.guess(),
  ...props
}) {
  const cls = useStyles();

  const randomId = !move?.id && !move?.temp_id ? uuid() : undefined;

  // Get actions
  const actions = buildDefaultRideActions(move);

  /** Get the width styles of the move */
  const getMoveStyles = () => {
    let styles = {};

    if (width) styles.width = width;
    if (minWidth) styles.minWidth = minWidth;
    if (maxWidth) styles.maxWidth = maxWidth;

    return styles;
  };

  /** Get tile CSS classes */
  const getTileClasses = () => {
    let classes = [cls.tile];

    if (isSelectable) classes.push(cls.tileSelectable);
    if (isSelected) classes.push(cls.tileSelected);

    return clsx(classes);
  };

  /** Get ride CSS classes */
  const getRideClasses = () => {
    let classes = [cls.ride];

    if (move?.ride_type === `lyft`) classes.push(cls.rideLyft);
    else if (move?.ride_type === `uber`) classes.push(cls.rideUber);
    else if (move?.ride_type === `shuttle`) classes.push(cls.rideHD);
    else classes.push(cls.rideAuto);

    return clsx(classes);
  };

  /** Get the rideshare partner's icon */
  const RidePartnerIcon = () => {
    if (move.ride_type === `lyft`) return <IconLyft className={cls.rideIconLyft} />;
    if (move.ride_type === `uber`) return <IconUber className={cls.rideIconUber} />;
    if (move.ride_type === `shuttle`) return <IconHD className={cls.rideIconHD} />;
    return <Icon className={cls.rideIconAuto}>hdr_auto</Icon>;
  };

  /** Handle move selection */
  const handleSelectMove = () => {
    if (onSelected) onSelected(move?.id || move?.temp_id || randomId);
  };

  // Return the placeholder component if there is no move
  if (isPlaceholder || !move) {
    return (
      <div className={cls.placeholder} style={{ zIndex: zIndex, ...getMoveStyles() }} {...props}>
        <div className={cls.placeholderTop}>
          <div className={cls.placeholderTopBox} />
          <div className={cls.spacer_xs} />
          <div className={cls.placeholderTopBox} />
        </div>

        <div className={cls.placeholderBottom}>
          <div className={cls.placeholderBottomBox} />
        </div>

        <div className={cls.placeholderFade} />
      </div>
    );
  }

  // Return component
  return (
    <>
      {/* SPACER BEFORE */}
      {gap ? <div style={{ width: `${gap}px` }} /> : null}

      {/* CONTEXT MENU */}
      <ContextMenuTrigger
        id={`static-ride-alt-${move?.id || move?.temp_id || randomId}-cm`}
        source={`static-ride-alt-${move?.id || move?.temp_id || randomId}-cm`}
      >
        {/* SHELL */}
        <div className={cls.shell} style={{ zIndex: zIndex }} onClick={() => handleSelectMove()} {...props}>
          {/* OVERLAYS */}

          {/* TILE */}
          <div className={getTileClasses()}>
            {/* RIDE */}
            <div className={getRideClasses()} style={getMoveStyles()}>
              <div className={cls.lane}>
                <Typography className={cls.laneTxt}>
                  {showMoveId && move?.id ? <strong>#{move.id} - </strong> : null}
                  {move?.lane?.pickup?.name || `Unknown Pickup`}
                </Typography>
                <div className={cls.spacer_xs} />
                <Typography className={cls.laneTxt}>to {move?.lane?.delivery?.name || `Unknown Delivery`}</Typography>
              </div>

              {/* RIDE ICON */}
              <RideTooltip move={move}>
                <div className={cls.rideIcon}>{RidePartnerIcon()}</div>
              </RideTooltip>

              {/* TILE FADE */}
              <div className={cls.fadeTile} style={{ zIndex: zIndex + 1 }} />
            </div>
          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`static-ride-alt-${move?.id || move?.temp_id || randomId}-cm`}>
        {actions?.map((action, i) => {
          if (!action || action?.hide) return null;
          if (!action?.handler) return <Divider key={`static-ride-action-alt-${i}-cm`} className={cls.cmDivider} />;
          return (
            <ContextMenuItem
              key={`static-ride-action-alt-${condensedCase(action?.label)}-cm`}
              data={{ action: action, ...action?.data }}
              disabled={typeof action?.disabled === 'function' ? action?.disabled(move) : action?.disabled}
              onClick={() => action?.handler(move)}
            >
              {action?.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>

      {/* SPACER AFTER */}
      {offset ? <div style={{ width: `${offset}px` }} /> : null}
    </>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  shell: {
    position: 'relative',
    width: '100%',
  },

  tile: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex', // Flex for extentions on the left and right of the tile
    width: '100%',
    height: 64,
    borderRadius: 8,
    cursor: 'pointer',
    transition: `all 0.15s`,
  },
  tileSelectable: {
    '&:hover, &:focus': {
      // transform: `scale(1.025)`,
    },
    '&:active': {
      opacity: 0.667,
    },
  },
  tileSelected: {
    boxShadow: 'inset 0 0 9px 3px #ffffff40',
    opacity: 0.667,
  },

  ride: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 64,
    padding: 6,
    borderColor: theme.palette.text.disabled,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 2,
    backgroundColor: '#ffffff',
    color: theme.palette.text.disabled,
  },

  rideAuto: {
    borderColor: theme.palette.auto.main,
    color: theme.palette.auto.main,
  },
  rideLyft: {
    borderColor: theme.palette.lyft.main,
    color: theme.palette.lyft.main,
  },
  rideUber: {
    borderColor: theme.palette.uber.main,
    color: theme.palette.uber.main,
  },
  rideHD: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },

  rideIcon: {
    position: 'absolute',
    bottom: 4,
    left: 6,
  },
  rideIconAuto: {
    display: 'block',
    fontSize: 21,
    marginBottom: -1,
    marginLeft: -2,
  },
  rideIconLyft: {
    display: 'block',
    height: 18,
    marginBottom: -1,
  },
  rideIconUber: {
    display: 'block',
    height: 14,
    marginBottom: 2,
  },
  rideIconHD: {
    display: 'block',
    height: 18,
  },

  lane: {
    position: 'absolute',
    top: 6,
    left: 6,
  },
  laneTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 400,
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },

  fadeTile: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 16,
    height: 64,
    background: `linear-gradient(90deg, #ffffff00, #ffffff)`,
    pointerEvents: 'none',
  },

  cmDivider: {
    marginTop: 4,
    marginBottom: 4,
  },

  spacer_xs: {
    height: 2,
    width: '100%',
  },
  spacer_sm: {
    height: 3,
    width: '100%',
  },
  spacer_md: {
    height: 4,
    width: '100%',
  },
  spacer_lg: {
    height: 6,
    width: '100%',
  },
  spacer_xl: {
    height: 8,
    width: '100%',
  },

  placeholder: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 64,
    padding: 8,
    borderColor: `${theme.palette.text.primary}40`,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 2,
    backgroundColor: theme.palette.background.main,
    color: `${theme.palette.text.primary}40`,
  },
  placeholderTop: {
    position: 'absolute',
    top: 6,
    left: 6,
  },
  placeholderTopBox: {
    width: 160,
    height: 12,
    borderRadius: 2,
    backgroundColor: `${theme.palette.text.primary}40`,
  },
  placeholderBottom: {
    position: 'absolute',
    bottom: 6,
    left: 6,
  },
  placeholderBottomBox: {
    width: 32,
    height: 16,
    borderRadius: 2,
    backgroundColor: `${theme.palette.text.primary}20`,
  },
  placeholderFade: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 16,
    height: 64,
    background: `linear-gradient(90deg, ${theme.palette.background.main}00, ${theme.palette.background.main})`,
    pointerEvents: 'none',
  },
}));
