import React, { useState } from 'react';
import { Typography, Chip, CircularProgress, Icon, Box } from '@material-ui/core';
import gql from 'graphql-tag';
import Loading from '../../components/Loading';
import { AccordionTable, AccordionRow, TableSort } from '../../components/AccordionTable';
import { ExportToCsv } from 'export-to-csv';
import PayPeriodDetailTable from './PayPeriodDetailTable';
import SendEmailButton from './SendEmailButton';
import MarkAsPaidButton from './MarkAsPaidButton';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

function PayPeriods(props) {
  const { regionId, payPeriod, status, driverId, sunday, cls } = props;

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const checkNeg = num => {
    if (num > 0) return num;
    else return 0;
  };

  const cap = str => {
    if (str) {
      if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
      else {
        let arr = str.split(` `);
        arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
        return arr.join(` `);
      }
    }
  };

  const round = (num, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  };

  const applyFilters = data => {
    //Always filter out pay periods with no driver
    if (!search || search.length < 1) return data.filter(o => o.driver_id);
    else {
      return data.filter(o => {
        if (
          (o.driver_name && o.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.driver_id && (o.driver_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.total_pay_amount && (o.total_pay_amount + ``).toLocaleLowerCase().includes(search)) ||
          (o.status && (o.status + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const getTableActions = payPeriods => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(payPeriods) }];
  };
  const getRowActions = payPeriod => {
    return [
      //{ name: `send-email`, label: `Send\xa0Email`, handler: () => { } },
      //{ name: `mark-as-paid`, label: `Mark\xa0as\xa0Paid`, handler: () => { } },
    ];
  };

  const generateCSV = pay_periods_by_driver => {
    console.log({pay_periods_by_driver})
    const createCsvRow = pay_period => {
      return {
        DRIVER_ID: pay_period.driver_id,
        DRIVER_NAME: pay_period.driver_name || `HopDriver`,
        REGION: getPropValue(pay_period, `region.name`) || ``,
        MOVES: pay_period.move_count,
        STATUS: pay_period.status ? cap(pay_period.status) : `-`,
        TOTAL: pay_period.total_pay_amount
          ? `$${pay_period.total_pay_amount.toFixed(2)}`
          : pay_period.total_pay_amount === 0
          ? `$0.00`
          : `-`,
      };
    };
    const csvRows = pay_periods_by_driver.map(pay_period => createCsvRow(pay_period));
    const csvOptions = {
      filename: `${
        driverId ? pay_periods_by_driver[0].driver_name.replace(/ /g, '_') : `All`
      }_Driver_Pay_from_${sunday.format()}_to_${sunday.clone().endOf('week').format()}`,
      showTitle: true,
      title: `${driverId ? pay_periods_by_driver[0].driver_name : `All`} Driver Pay from ${sunday.format()} to ${sunday
        .clone()
        .endOf('week')
        .format()}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const { loading, error, data, refetch } = useQuery(GET_PAY_PERIODS(driverId, payPeriod, status, regionId), {
    variables: { regionId: regionId, driverId: driverId, payPeriod: payPeriod, status: status },
  });

  if (loading) return <Loading fixed />;
  if (error) {
    toast.error('Failed to retrieve pay_periods_by_driver');
    console.error('Failed to retrieve pay_periods_by_driver:', error);
    return (
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
      </div>
    );
  }
  if (data && data.pay_periods_by_driver && data.pay_periods_by_driver.length > 0) {
    const filteredPayPeriods = applyFilters(data.pay_periods_by_driver);

    // Set a consistent amount object that holds the totals
    var amount = {};

    // Valid moves to calculate base totals
    const subtotalPayments = filteredPayPeriods.filter(item => item.total_pay_amount > 0);
    const paidPayments = subtotalPayments.filter(item => item.status === `paid`);

    // Base totals from valid moves
    amount.subtotal = round(
      subtotalPayments.length > 0
        ? subtotalPayments.map(item => item.total_pay_amount).reduce((total, current) => total + current)
        : 0,
      2
    );
    amount.paid = round(
      paidPayments.length > 0
        ? paidPayments.map(item => item.total_pay_amount).reduce((total, current) => total + current)
        : 0,
      2
    );

    // Set calculations from base totals
    amount.total = checkNeg(amount.subtotal);
    amount.unpaid = checkNeg(amount.total - amount.paid);

    // log && console.log(`AP Amount:`, amount);

    const headers = [
      { id: `EARNINGS_NUM`, alignLeft: true, numeric: true, label: `Earnings\xa0Summary` },
      { id: `DRIVER_NAME`, alignLeft: true, numeric: false, label: `Driver` },
      { id: `REGION`, alignLeft: true, numeric: true, label: `Region` },
      { id: `MOVES`, alignLeft: true, numeric: true, label: `Moves` },
      { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
      { id: `TOTAL`, alignLeft: false, numeric: true, label: `Total` },
      { id: `BUTTONS`, alignLeft: false, numeric: false, label: `Actions` },
    ];
    const rows = filteredPayPeriods.map(payPeriod => {
      return {
        EARNINGS_NUM: `${payPeriod.driver_id}-${payPeriod.pay_period}`,
        DRIVER_NAME: payPeriod.driver_name,
        REGION: payPeriod.region && (payPeriod.region.name || ''),
        MOVES: payPeriod.move_count,
        STATUS: (
          <Chip
            variant={payPeriod.status === 'paid' ? 'default' : 'outlined'}
            icon={<Icon fontSize='small'>{payPeriod.status === 'paid' ? 'done' : 'monetization_on'}</Icon>}
            size='small'
            color={payPeriod.status === 'unpaid' ? 'primary' : 'secondary'}
            label={payPeriod.status}
          />
        ),
        TOTAL: payPeriod.total_pay_amount
          ? `$${payPeriod.total_pay_amount.toFixed(2)}`
          : payPeriod.total_pay_amount === 0
          ? `$0.00`
          : `-`,
        BUTTONS: (
          <>
            <SendEmailButton payPeriod={payPeriod} />
            &nbsp;&nbsp;
            <MarkAsPaidButton payPeriod={payPeriod} refetch={refetch} />
          </>
        ),
        payPeriod: payPeriod,
      };
    });

    return (
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAY PERIODS' />}>
        <AccordionTable
          title={
            <>
              <Typography>Found {data.pay_periods_by_driver.length} matching pay periods</Typography>
              <Typography variant='subtitle1' gutterBottom>
                <Box rendertag='div' position='relative' display='inline-flex' marginRight={2}>
                  <CircularProgress
                    variant='determinate'
                    value={Math.round(
                      (data.pay_periods_by_driver.filter(o => o.status === 'paid').length /
                        data.pay_periods_by_driver.length) *
                        100
                    )}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position='absolute'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
                      (data.pay_periods_by_driver.filter(o => o.status === 'paid').length /
                        data.pay_periods_by_driver.length) *
                        100
                    )}%`}</Typography>
                  </Box>
                </Box>
                <strong>Paid:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'paid').length}
                &nbsp;|&nbsp;&nbsp;
                <strong>Unpaid:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'unpaid').length}
                &nbsp;|&nbsp;&nbsp;
                <strong>Partial:</strong> {data.pay_periods_by_driver.filter(o => o.status === 'partial').length}
              </Typography>
            </>
          }
          headers={headers}
          rows={rows}
          actions={getTableActions(data.pay_periods_by_driver)}
          search={search}
          defaultOrder={defaultOrder}
          defaultOrderBy={defaultOrderBy}
          order={order}
          orderBy={orderBy}
          tablePage={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          setSearch={setSearch}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setTablePage={setTablePage}
          setRowsPerPage={setRowsPerPage}
          setExpandedRowId={setExpandedRowId}
          className={cls.table}
          refetch={refetch}
          refreshPersistAs='ap_driver_pay'
        >
          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
            .map(row => (
              <AccordionRow
                key={`ap-pay-period-${row.EARNINGS_NUM}`}
                rowId={row.EARNINGS_NUM}
                expandedRowId={expandedRowId}
                setExpandedRowId={setExpandedRowId}
                columns={[
                  { align: 'left', value: row.EARNINGS_NUM },
                  { align: 'left', value: row.DRIVER_NAME },
                  { align: 'left', value: row.REGION },
                  { align: 'left', value: row.MOVES },
                  { align: 'left', value: row.STATUS },
                  { align: 'right', value: row.TOTAL },
                  { align: 'right', value: row.BUTTONS, preventClick: true },
                ]}
                actions={getRowActions(row.payPeriod)}
                className={cls.row}
              >
                <PayPeriodDetailTable payPeriod={row.payPeriod} />
              </AccordionRow>
            ))}
        </AccordionTable>
      </Sentry.ErrorBoundary>
    );
  } else
    return (
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>NO DRIVER PAY FOUND</Typography>
      </div>
    );
}

const GET_PAY_PERIODS = (driverId, payPeriod, status, regionId) => gql`
  query pay_periods_by_driver($driverId: bigint, $regionId: bigint, $payPeriod: String, $status: String) {
    pay_periods_by_driver(where: {
      ${driverId ? 'driver_id: {_eq: $driverId},' : ''}
      ${payPeriod ? 'pay_period: {_eq: $payPeriod},' : ''}
      ${status ? 'status: {_eq: $status},' : ''}
      ${regionId ? 'region_id: {_eq: $regionId},' : ''}
    }, order_by: {driver_name: asc, pay_period: asc}) {
      driver_id
      driver_name
      region {
        id
        name
      }
      pay_period
      move_count
      total_pay_amount
      status
      pickup_time_start
      pickup_time_end
    }
  }
`;

export default PayPeriods;
