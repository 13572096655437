//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Tooltip, Icon } from '@material-ui/core';
import Spacer from './Spacer';

//////////////////////// COMPONENT ////////////////////////

export default function BigButton({
  key,
  id,
  icon,
  title,
  subtitle,
  tip,
  selected = false,
  disabled = false,
  orientation = 'horizontal',
  width = '',
  marginRight = 0,
  onChange,
}) {
  const cls = useStyles();

  const handleClick = () => {
    if (onChange) onChange(id, title);
  };

  return (
    <div
      key={key}
      className={selected ? cls.boxActive : disabled ? cls.boxDisabled : cls.box}
      style={{ width: width, marginRight: marginRight }}
      onClick={!disabled ? handleClick : () => {}}
    >
      {icon ? icon : null}
      {icon && title ? <Spacer size='xs' /> : null}
      {title && subtitle ? <Typography className={cls.key}>{title}</Typography> : null}
      {title && !subtitle ? <Typography className={cls.text}>{title}</Typography> : null}
      {(icon && subtitle) || (title && subtitle) ? <Spacer size='xs' /> : null}
      {subtitle ? <Typography className={cls.val}>{subtitle}</Typography> : null}
      {tip ? (
        <Tooltip title={tip} placement='top'>
          <div className={cls.tip}>
            <Icon className={cls.tipIcon}>help</Icon>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: `8px`,
    background: '#f0f0f0',
    '& svg': {
      '& path': {
        fill: theme.palette.text.primary,
      },
      '& rect': {
        fill: theme.palette.text.primary,
      },
      '& polygon': {
        fill: theme.palette.text.primary,
      },
    },
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    transition: '0.15s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  boxDisabled: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: theme.border[0],
    borderRadius: `8px`,
    background: '#f4f4f4',
    color: theme.palette.text.disabled,
    transition: '0.15s',
    cursor: 'not-allowed',
    '& svg': {
      '& path': {
        fill: theme.palette.text.disabled,
      },
      '& rect': {
        fill: theme.palette.text.disabled,
      },
      '& polygon': {
        fill: theme.palette.text.disabled,
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  boxActive: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: `8px`,
    background: theme.palette.primary.main,
    color: '#fff',
    transition: '0.15s',
    cursor: 'pointer',
    '& svg': {
      '& path': {
        fill: theme.palette.text.contrast,
      },
      '& rect': {
        fill: theme.palette.text.contrast,
      },
      '& polygon': {
        fill: theme.palette.text.contrast,
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  key: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  val: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  text: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  tip: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  tipIcon: {
    fontSize: 20,
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
}));
