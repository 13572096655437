import { gql } from 'graphql-tag';

const GET_CUSTOMER_USERS = gql`
query get_customer_users {
    users(where: {default_role: {_ilike: "%dealer%"}}) {
      active
      avatar_url
      created_at
      default_role
      display_name
      email
      id
      updated_at
      customer {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export { GET_CUSTOMER_USERS };