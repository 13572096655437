import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { makeStyles, Grid, Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';

import sdk from '@hopdrive/sdk';
import { GET_LANE_BY_LOCATION_IDS } from './gql';
import Loading from '../../components/Loading';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';
import LanesEntryRow from './LanesEntryRow';
import LanesEntryDetails from './LanesEntryDetails';

const log = true;

export default function LanesEntryOutput(props) {
  const { customer } = props;

  // STATE //////////////////////////////////////////////////////////////////
  const [ pickup, setPickup ] = useState();
  const [ delivery, setDelivery ] = useState()
  const [ lane, setLane ] = useState({
    pickupName: '',
    pickupAddress: '',
    deliveryAddress: '',
    primaryLane: null,
    inverseLane: null,
    submittedPrimaryLane: false,
    submittedInverseLane: false,
    addressError: false,
  });


  useEffect(() => {
    console.log("LANE", lane)
    // if (lane.pickupLocation && lane.deliveryLocation) 
  }, [ lane ])

  // LOGIC //////////////////////////////////////////////////////////////////
  const handleInputChange = event => {
    const { name, value } = event.target;
    setLane({ ...lane, [name]: value });
  };

  const handleSetSubmit = name => {
    setLane({ ...lane, [name]: true });
  };

  const validateAddress = event => {
    const { value } = event.target;
    if (sdk.utilities.validateAddress(value) === false) {
      console.log('invalid address');
      setLane({ ...lane, addressError: true });
    } else {
      setLane({ ...lane, addressError: false });
    }
  };

  const handleSetLocation = (event, pickup) => {
    const type = pickup ? 'pickup' : 'delivery';
    log && console.log(`Setting ${type} location...`, event)

    if (type === 'pickup') {
        if (event && event.name) {
          setPickup(event)
          setLane({ 
          ...lane, 
          pickupAddress: event.address,
          pickupName: event.name,
        })
      } else {
        setPickup(null)
        setLane({
          ...lane,
          pickupName: '',
          pickupAddress: '',
        });
      }
    } else if (type === 'delivery') {
      if (event && event.name) {
        setDelivery(event);
        setLane({
          ...lane,
          deliveryAddress: event.address,
          deliveryName: event.name
        })
      } else {
        setDelivery(null);
        setLane({
          ...lane,
          deliveryName: '',
          deliveryAddress: '',
        });
      }
    }
  };

  const createNewLanes = async () => {
    toast.info('Working on Lane...');

    // if location(s) selected from dropdowns
    if (pickup && delivery) {
      // check if either lane or inverse already exists
      let lanesExist = await Promise.all([
        sdk.lanes.get({
          customer_id: 1,
          origin_location_id: 1,
          destination_location_id: 2,
        }),
        sdk.lanes.get({
          customer_id: 1,
          origin_location_id: 2,
          destination_location_id: 1,
        }),
      ]);
      log && console.log("LANESEXIST", lanesExist);
      let primaryExists = lanesExist[0].success;
      let inverseExists = lanesExist[1].success;
      if (primaryExists && inverseExists) {
        toast.error("Lanes already exist!");
        console.log('these two lanes already exist!');
        setPickup(null) 
        setDelivery(null)
        return;
      } else if (primaryExists) {
        // get primary lane
        sdk.gql
          .query(GET_LANE_BY_LOCATION_IDS, {
            origin_location_id: parseInt(pickup.id),
            destination_location_id: parseInt(delivery.id),
          })
          .then(res => {
            const laneOne = res.data.lanes[0];
            if (log) console.log('GET_LANE_BY_LOCATION_IDS response (res.data):', res.data);
            setLane({ ...lane, primaryLane: laneOne });
          })
          .catch(function (err) {
            console.error(err);
            toast.error('Error on Lane GET');
            throw err;
          });
      } else if (inverseExists) {
        // get inverse lane
        sdk.gql
          .query(GET_LANE_BY_LOCATION_IDS, {
            origin_location_id: parseInt(delivery.id),
            destination_location_id: parseInt(pickup.id),
          })
          .then(res => {
            const laneTwo = res.data.lanes[0];
            log && console.log('GET_LANE_BY_LOCATION_IDS response (res.data):', res.data);
            setLane({ ...lane, inverseLane: laneTwo });
          })
          .catch(function (err) {
            console.error(err);
            toast.error('Error on Lane GET');
            throw err;
          });
      } 
    }

    const pickupName = lane.pickupName;
    const pickupAddress = lane.pickupAddress;
    const deliveryName = lane.deliveryName;
    const deliveryAddress = lane.deliveryAddress;

    log && console.log(`Building lane for ${pickupName} at ${pickupAddress} to ${deliveryName} at ${deliveryAddress}`);

    axios({
      method: 'POST',
      url: '/.netlify/functions/buildLanes',
      data: {
        type: 'fromAddresses',
        customer_id: customer,
        pickup: { name: pickupName, address:  pickupAddress },
        delivery: { name: deliveryName, address: deliveryAddress },
      },
    })
      .then(res => {
        console.log("AXIOS RESPONSE", res)
        const newLanes = res.data;
        log && console.log('createNewLane - created Lane object:', newLanes);
        if (newLanes.length === 1 && lane.primaryLane !== null) {
          setLane({ ...lane, inverseLane: newLanes[0] });
          toast.success('Selected Lane Built!');
        } else if (newLanes.length === 1 && lane.inverseLane !== null) {
          setLane({ ...lane, primaryLane: newLanes[0] });
          toast.success('Inverse of Selected Lane Built');
        }
        // two new lanes
        else if (newLanes[0] !== null && newLanes[1] !== null) {
          setLane({ ...lane, primaryLane: newLanes[0], inverseLane: newLanes[1] });
          toast.success('Two New Lanes Built!');
        }
      })
      .catch(function (err) {
        console.error(err);
        toast.error('Lane failed to build: ' + err.toString());
        throw err;
      });
  };

  const clearLocations = () => {
    setPickup(null)
    setDelivery(null)
    setLane({
      pickupName: '',
      pickupAddress: '',
      deliveryAddress: '',
      primaryLane: null,
      inverseLane: null,
      submitedPrimaryLane: false,
      submitedInverseLane: false,
      addressError: false,
    });
  };

  return (
    <>
      <LanesEntryRow
        lane={lane}
        customer={customer}
        pickup={true}
        handleSetLocation={handleSetLocation}
        handleInputChange={handleInputChange}
        validateAddress={validateAddress}
        style={{ marginTop: '20px' }}
        // subscribeToLocations={subscribeToLocations}
      />
      <LanesEntryRow
        lane={lane}
        customer={customer}
        pickup={false}
        handleSetLocation={handleSetLocation}
        handleInputChange={handleInputChange}
        validateAddress={validateAddress}
        style={{ marginTop: '20px' }}
        // subscribeToLocations={subscribeToLocations}
      />
      <Grid container justifyContent='flex-start' style={{ marginTop: '20px' }}>
        <Grid item xs={2}>
          <Button
            disabled={ !lane.pickupName || !lane.deliveryName || lane.addressError }
            size='medium'
            color='primary'
            variant='outlined'
            onClick={createNewLanes}
          >
            Set Locations
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button size='medium' color='secondary' variant='outlined' onClick={clearLocations}>
            Clear Locations
          </Button>
        </Grid>
      </Grid>
      { lane.primaryLane && lane.inverseLane ? ( <>
        <LanesEntryDetails lane={lane.primaryLane} handleSetSubmit={handleSetSubmit}/> 
        <LanesEntryDetails lane={lane.inverseLane} handleSetSubmit={handleSetSubmit}/> 
      </>) : null }
    </>
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dlPairs: {
    margin: '1em 0',
    borderBottom: '1px solid rgb(231, 231, 231)',
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
