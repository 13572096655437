import React, { Fragment } from 'react';
import { makeStyles, Grid, Typography, Tooltip, Icon, InputBase } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useSubscription } from '@apollo/client';
import { GET_DRIVER_EVENT_LOGS } from '../../components/_app/gql';
import { useDriverDetails } from './DriverDetailsProvider';
import Loading from '../../components/Loading';
import { EventLogRenderer } from '../../components/EventLogs';

//////////////////////// COMPONENT ////////////////////////

export default function DriverInfoEventLogs({ driver = {} }) {
  const cls = useStyles();
  const ctx = useDriverDetails();

  const { loading, error, data } = useSubscription(GET_DRIVER_EVENT_LOGS, {
    variables: { driver_id: driver?.id },
  });

  if (loading || !data) return <Loading />;

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  return (
    <div className={cls.paper}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <Typography className={cls.headTxt}>Events</Typography>
        </Grid>
        <Grid item>
          <Tooltip placement='top' title='Events the driver has performed.'>
            <Icon className={cls.headIcon}>help</Icon>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {loading ? (
            <Loading />
          ) : (
            data?.eventlogs?.map((event, index) => <EventLogRenderer key={event?.id} event={event} />)
          )}
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headIcon: {
    fontSize: 21,
    color: theme.palette.text.secondary,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: 17,
    maxHeight: 17,
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
