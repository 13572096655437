// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useTheme, makeStyles, Icon, Tooltip } from '@material-ui/core';

import { useFilters } from './FilterProvider';

// COMPONENT ---------------------------------------------------------------- //

export default function FilterButton({ tooltip = null, tooltipPlacement = null, ...rest }) {
  const theme = useTheme();
  const cls = useStyles();

  const { setAnchorEl, active, count, handleToggleFilters } = useFilters();

  return (
    <div className={cls.filter}>
      <Tooltip placement={tooltipPlacement || `top`} title={tooltip || `Open Filters`}>
        <button className={cls.btn} onClick={e => setAnchorEl(e?.currentTarget)} {...rest}>
          <span>
            <Icon className={cls.icon}>{active ? `filter_alt` : `filter_alt_off`}</Icon>
          </span>

          <span className={cls.label}>Filters</span>
        </button>
      </Tooltip>

      <div className={cls.btnLine} />

      <Tooltip
        placement={tooltipPlacement || `top`}
        title={`${
          active
            ? `You have ${count} filter${count === 1 ? `` : `s`} active (Click to use default filters)`
            : `You are using default filters (Click to use custom filters)`
        }`}
      >
        <button className={cls.countBtn} onClick={() => handleToggleFilters(!active)}>
          <span
            className={cls.countLabel}
            style={{ color: active ? theme.palette.text.contrastPrimary : theme.palette.text.contrastDisabled }}
          >
            {active ? count : 0}
          </span>
        </button>
      </Tooltip>
    </div>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btn: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    border: 0,
    borderRadius: '4px 0 0 4px',
    margin: 0,
    lineHeight: 1.75,
    backgroundColor: theme.palette.info.main,
    cursor: 'pointer',
    transition: '0.15s',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
  },
  icon: {
    display: 'block',
    marginLeft: -4,
    marginRight: 6,
    fontSize: 20,
    color: theme.palette.info.contrastText,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.info.contrastText,
  },

  countBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px',
    border: 0,
    borderRadius: '0 4px 4px 0',
    margin: 0,
    lineHeight: 1.75,
    backgroundColor: theme.palette.info.main,
    cursor: 'pointer',
    transition: '0.15s',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
  },
  countLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.info.contrastText,
  },

  btnLine: {
    width: 1,
    height: 36.5,
  },
}));
