//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';

const log = false;

//////////////////////// COMPONENT ////////////////////////

function ScrollToTop(props) {
  // Element to detect sroll on
  const { element } = props;

  // Set if the button is visible or not
  const [visible, setVisible] = React.useState(false);

  // Set the listener and listener function
  React.useEffect(() => {
    const handleVisible = () => {
      if (element && element.scrollTop > 32) setVisible(true);
      else setVisible(false);
    };

    log && console.log(`Element:`, element);
    if (element) {
      element.addEventListener(`scroll`, handleVisible);
      return () => element.removeEventListener(`scroll`, handleVisible);
    }
  }, [element]);

  // OnClick that makes the element scroll to the top
  const handleScrollToTop = () => {
    log && console.log(`Scroll To Top!`);
    if (element) element.scrollTop = 0;
  };

  return (
    <div className={`scroll-to-top ${visible ? `show` : `hide`}`} onClick={handleScrollToTop}>
      <AiOutlineArrowUp />
    </div>
  );
}

//////////////////////// EXPORT ////////////////////////

export default ScrollToTop;
