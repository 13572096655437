import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Script from 'react-load-script';
import { useData } from '../../providers/DataProvider';
import sdk from '@hopdrive/sdk';

import { makeStyles, Grid, Typography, TextField, InputAdornment, Icon, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';

import { getUserToken } from '../../utils/authHelper'

import { useSubscription, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import fragments from '../../utils/fragments';

import Loading from '../../components/Loading';

// import LocationAddEditModal from './LocationAddEditModal';
// import AddressBuilderModal from './AddressBuilderModal';

import hdLogo from '../../static/location-icon.png';
import { REACT_APP_A0_CB_SD } from '../../utils/env';
const log = false;

////////// COMPONENT //////////
export default function LocationSelect(props) {
  const ctx = useData();
  const cls = useStyles();

  const { locationData, onChange, label, customerId, modalVersion = true } = props;

  const [location, setLocation] = useState(locationData ? locationData : null);
  const [input, setInput] = useState(``);
  const [aptSuite, setAptSuite] = useState(``);
  const [google, setGoogle] = useState(``);
  const [suggestions, setSuggestions] = useState([]);

  const [locLoading, setLocLoading] = useState(false);

  const [generalError, setGeneralError] = useState(false);
  const [regionError, setRegionError] = useState(false);
  const [errorText, setErrorText] = useState(``);

  // const [addressModal, setAddressModal] = useState({ open: false });
  // const [locationModal, setLocationModal] = useState({ open: false });

  useEffect(() => {
    if (onChange && location) {
      onChange(location);
    }
  }, [location]);

  useEffect(() => {
    setLocation(locationData);
  }, [locationData]);

  const { loading, error, data, refetch } = useQuery(GET_LOCATIONS, { variables: { customerId: customerId } });


  const handleLocationChange = async newLoc => {
    log && console.log(`Selected Location:`, newLoc);
    if (newLoc && typeof newLoc === `object`) {
      if (newLoc.id) {
        setLocation(newLoc);
      } else {
        log && console.log('New location');
        setLocLoading(true);
        await handleUpsertLocation(newLoc);
        refetch()
        setLocLoading(false);
      }
    } else setLocation(null);
  };

  const handleInputChange = dbLocs => (event, value, reason) => {
    setGeneralError(false);
    setRegionError(false);
    setErrorText(``);
    if (reason === `input` && value && value !== ``) {
      log && console.log(`Input:`, value);
      handleLocationChange(null);
      handleGoogleSearch(value, dbLocs);
      setInput(value);
    } else {
      setSuggestions([]);
      setInput(``);
    }
  };

  const handleAptSuiteChange = event => {
    const val = event.target.value;
    setAptSuite(val);
  };

  const handleFocusEnter = () => {
    setGeneralError(false);
    setErrorText(``);
  };

  const handleFocusExit = async () => {
    if (input || (location && aptSuite)) {
      try {
        let output = input;

        if (aptSuite) {
          let choppedInput;
          if (input) choppedInput = input.split(`,`);
          if (location) choppedInput = location.address.split(`,`);
          choppedInput[0] += ` ${aptSuite}`;
          output = choppedInput.join(`,`);
          setAptSuite(``);
        }

        let getOrBuildRes = await sdk.locations.getOrBuild(
          customerId,
          sdk.locations.defaultLocationName(output),
          output,
          null,
          null,
          null,
          `customer`,
          { noTimezoneCall: true }
        );
        log && console.log(`ON BLUR:`, getOrBuildRes);

        if (getOrBuildRes) {
          await handleLocationChange(getOrBuildRes);
        }
      } catch (err) {
        console.error(`Failed to find location on Google:`, err);
        setGeneralError(true);
        setErrorText(
          `Failed to find location on Google! Please provide a more fleshed out address or select from the suggestions in the list. If this problem persists, please contact us.`
        );
      }
    }
  };

  const handleGoogleScript = () => {
    setGoogle(new window.google.maps.places.PlacesService(document.createElement('div')));
  };
  const handleGoogleSearch = (input, dbLocs) => {
    google.textSearch({ query: input }, res => {
      if (res) {
        log && console.log(`Response from Google:`, res);

        // Set array of all existing address
        let existingAddresses = dbLocs.length > 0 ? dbLocs.map(dbLoc => dbLoc.address) : [];

        // Set newSuggestions array and filter out existing locations
        // This will help prevent dupes
        let newSuggestions = res.map(s => ({
          id: null,
          customer_id: null,
          region_id: null,
          place_id: s.place_id,
          type: `customer`,
          name: s.name,
          address: s.formatted_address,
          input_query: input, // retain the address that the user input
          latitude: s.geometry.location.lat(),
          longitude: s.geometry.location.lng(),
          nickname: null,
          email: null,
          phone: null,
          notes: null,
          favorite: false,
        }));
        newSuggestions = newSuggestions.filter(suggestion => !existingAddresses.includes(suggestion.address));

        setSuggestions(newSuggestions);
      }
    });
  };

  const handleLocationFavorite = async () => {
    if (location) {
      try {
        const res = await setFavorite();
        if (res) {
          setLocation({ ...location, favorite: !location.favorite });
          log && console.log(`>> UPDATED Favorite Location:`, res.data.update_locations);
        }
      } catch (err) {
        log && console.log(`Error updating location:`, err);
        toast.error(`Failed to update location: ${err.toString()}`);
      }
    } else log && console.log(`Error: Cannot set favorite of an empty location!`);
  };

  const setFavorite = async () => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_LOCATION_FAVORITE,
      variables: { id: location.id, fav: !location.favorite },
    });
  };

  // Build and upsert the location on focus loss
  const handleUpsertLocation = async newLoc => {
    try {
      let region_id;
      if (newLoc) {
        // Set region ID if it doesnt have one
        log && console.log(newLoc);

        // try {
        if (!newLoc.region_id) {
          let regionRes = await sdk.regions.getByCoords([newLoc.longitude, newLoc.latitude]);
          //if the location does not exist in any current region, we still create it
          region_id = regionRes.data && regionRes.data.length > 0 ? regionRes.data[0].id : null;
          log && console.log('Found region_id:', region_id);
          newLoc.region_id = region_id;
        }

        const token = await getUserToken()

        // Get timezone
        if (newLoc.latitude && newLoc.longitude) {
          await axios({
            method: `POST`,
            url: `/.netlify/functions/getTimezone`,
            data: {
              latitude: newLoc.latitude,
              longitude: newLoc.longitude,
            },
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
            .then(res => {
              if (res.status === 200) {
                log && console.log(`Timezone data:`, res.data);
                newLoc.timezone = res.data.timeZoneId;
              } else newLoc.timezone = null;
            })
            .catch(err => {
              console.error(`Failed to get timezone from location:`, err);
              newLoc.timezone = null;
            });
        }

        // Check for place_id which is used for location building
        if (newLoc.place_id) {
          // Set variables to upsert
          const variables = {
            address: newLoc.address,
            customerId: customerId,
            email: newLoc.email,
            latitude: newLoc.latitude,
            longitude: newLoc.longitude,
            name: newLoc.name,
            nickname: newLoc.nickname,
            phone: newLoc.phone,
            placeId: newLoc.place_id,
            regionId: newLoc.region_id,
            timezone: newLoc.timezone,
            type: newLoc.type,
          };

          // Do the upsert
          log && console.log(`Upserting location with variables:`, variables);
          const res = await ctx.apolloClient.mutate({
            mutation: UPSERT_LOCATION,
            variables: variables,
          });

          // Check the data returned
          if (res && res.data && res.data.insert_locations && res.data.insert_locations.returning.length > 0) {
            log && console.log('locationUpsertRes', res);
            const resLoc = res.data.insert_locations.returning[0];
            log && console.log('newLocation', resLoc);
            log && console.log(`Successfully created location:`, resLoc);
            setLocation(resLoc || null);

            if (resLoc.region_id === null) {
              try {
                createNotification(resLoc);
              } catch (err) {
                console.log(err, 'Error generating null region notification');
              }
            }
          }
        } else {
          console.error(`Failed to create location, no google place_id was found!`);
          setGeneralError(true);
          setErrorText(
            `Failed to create location! No Google Place ID was found. We need this to properly build the location. If this problem persists, please contact us.`
          );
          return;
        }
      }
      return;
    } catch (err) {
      console.error(`Failed to create location:`, err);
      setGeneralError(true);
      setErrorText(
        `Failed to create location! Please try again or try a different address. If this problem persists, please contact us.`
      );
      return;
    }
  };

  const createNotification = async newLoc => {
    const variables = {
      type: 'action',
      title: `Region null for ${newLoc.name} (ID: ${newLoc.id})`,
      body: `New location ${newLoc.name} (ID: ${newLoc.id}) was created with a null region ID. Try refetching the region from the location's details page, which you can find by clicking the link in the notes below.`,
      notes: `[Click to go to Location Details](https://${REACT_APP_A0_CB_SD}.hopdrive.io/locations/${newLoc.id})`,
      createdat: 'now()',
      createdby: 'location-build.admin',
    };
    const res = await sdk.notifications.create(variables);
    console.log(res);
  };

  // May eventually use this to parse out the address components
  // const buildLocationFromGoogleGeocode = googleGeocodedAddress => {
  //   let location = {
  //     name: '',
  //     address_one: '',
  //     address_two: '',
  //     city: '',
  //     state: '',
  //     zip: '',
  //   };

  //   const { results } = googleGeocodedAddress;

  //   if (!(Array.isArray(results) && results.length > 0)) {
  //     throw new Error(`Geocode result is not an array`);
  //   }

  //   const firstMatchedAddress = results[0];

  //   const { address_components } = firstMatchedAddress;

  //   if (!(Array.isArray(address_components) && address_components.length > 0)) {
  //     throw new Error(`Address components is not an array`);
  //   }

  //   address_components.forEach(addressPart => {
  //     const { types = [], short_name = '', long_name = '' } = addressPart;
  //     if (Array.isArray(types)) {
  //       if (types.includes('street_number')) {
  //         location.address_one = long_name;
  //       }
  //       if (types.includes('route')) {
  //         location.address_one += ' ' + long_name;
  //         location.name = location.address_one;
  //       }
  //       //Check for address line 2
  //       if (types.includes('locality')) {
  //         location.city = long_name;
  //       }
  //       if (types.includes('administrative_area_level_1')) {
  //         location.state = short_name;
  //       }
  //       if (types.includes('postal_code')) {
  //         location.zip = long_name;
  //       }
  //       if (types.includes('postal_code_suffix')) {
  //         location.zip += '-' + long_name;
  //       }
  //     }
  //   });

  //   return location;
  // };

  // const handleAddressBuilderModalOpen = (addressInput = null) => {
  //   setAddressModal({ ...addressModal, open: true, input: addressInput });
  // };
  // const handleAddressBuilderModalClose = (addressOutput = null) => {
  //   if (addressOutput) {
  //     setAddressModal({ ...addressModal, open: false });
  //     handleLocationAddEditModalOpen(addressOutput);
  //   } else setAddressModal({ ...addressModal, open: false });
  // };

  // const handleLocationAddEditModalOpen = (locationInput = null) => {
  //   setLocationModal({
  //     ...locationModal,
  //     open: true,
  //     input: { ...locationInput, buildAddress: handleAddressBuilderModalOpen },
  //   });
  // };
  // const handleLocationAddEditModalClose = (locationOutput = null) => {
  //   if (locationOutput) {
  //     setLocationModal({ ...locationModal, open: false });
  //     setLocation(locationOutput);
  //   } else setLocationModal({ ...locationModal, open: false });
  // };

  // LOADING STATE //
  if (loading) {
    // Return a fake TextField to hide the loading
    return (
      <TextField
        error={props.valid || regionError ? !props.valid.toString() : 'false'}
        fullWidth
        label={label ? label : `Location`}
        placeholder='Loading'
        variant='outlined'
        margin='none'
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  // ERROR STATE //
  if (error) {
    log && console.log(`Failed to retrieve locations:`, error);
    toast.error(`Subscription failed to retrieve locations: ${error.toString()}`);
  }

  // EMPTY STATE //
  if (!data || !data.locations) return <div>Error Finding Locations</div>;

  // DATA STATE //
  let locs = data.locations.length > 0 ? data.locations : [];

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GPLCS}&libraries=places`}
        onLoad={handleGoogleScript}
      />

      <Grid container spacing={1}>
        <Grid item xs={modalVersion ? 9 : 12} className={cls.autocomplete}>
          <Autocomplete
            freeSolo
            includeInputInList
            options={locs.concat(suggestions)}
            filterOptions={createFilterOptions({
              stringify: option => `${option.name} ${option.nickname} ${option.address} ${option.input_query}`,
            })}
            getOptionLabel={option => option.name}
            noOptionsText='Press enter to open the location finder'
            value={location}
            onChange={(event, value) => handleLocationChange(value)}
            onInputChange={handleInputChange(locs)}
            onFocus={handleFocusEnter}
            onBlur={handleFocusExit}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={generalError || regionError}
                fullWidth
                label={label ? label : `Location`}
                placeholder='Search for a location...'
                helperText={
                  modalVersion ? (errorText ? errorText : location && location.address ? location.address : null) : null
                }
                variant='outlined'
                margin='none'
                InputProps={{
                  ...params.InputProps,
                  onKeyDown: e => {
                    if (e.key === `Enter`) {
                      e.stopPropagation();
                      handleFocusExit();
                    }
                  },
                  startAdornment: (
                    <>
                      {location ? (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Tooltip
                              placement='top'
                              title={location.favorite ? `Unfavorite Location` : `Favorite Location`}
                            >
                              <Icon
                                className={location.favorite ? cls.heartActive : cls.heart}
                                onClick={() => handleLocationFavorite()}
                              >
                                {location.favorite ? `favorite` : `favorite_border`}
                              </Icon>
                            </Tooltip>
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ) : null}
                    </>
                  ),
                }}
              />
            )}
            renderOption={option => (
              <>
                {option.id ? (
                  option.favorite ? (
                    <Tooltip placement='top' title='Favored Location'>
                      <div className={cls.optionIcon}>
                        <Icon className={cls.favoriteIcon}>favorite</Icon>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip placement='top' title='Stored Location'>
                      <div className={cls.optionIcon}>
                        <img className={cls.hdIcon} src={hdLogo} alt='location pin' />
                      </div>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip placement='top' title='Google-Suggested Location'>
                    <div className={cls.optionIcon}>
                      <FontAwesomeIcon className={cls.googleIcon} icon={faGoogle} title='Google-Suggested Location' />
                    </div>
                  </Tooltip>
                )}
                <div className={cls.option}>
                  <Typography className={cls.optionName}>{option.name}</Typography>
                  <Typography className={cls.optionAddress}>{option.address}</Typography>
                </div>
              </>
            )}
          />
          {locLoading ? <Loading right={8} /> : null}
        </Grid>

        {modalVersion ? (
          <Grid item xs={3}>
            <TextField
              fullWidth
              label='Apt/Suite #'
              placeholder='Optional'
              variant='outlined'
              value={aptSuite}
              onChange={handleAptSuiteChange}
              onBlur={handleFocusExit}
              inputProps={{ type: 'search' }}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  autocomplete: {
    position: 'relative',
  },
  heart: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favoriteIcon: {
    color: theme.palette.error.main,
  },
  hdIcon: {
    maxWidth: 24,
    maxHeight: 24,
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.6),
  },
  googleIcon: {
    color: theme.palette.text.secondary,
  },
  option: {
    display: 'block',
  },
  optionName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  optionAddress: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
}));

////////// GRAPHQL //////////
const GET_LOCATIONS = gql`
  query get_locations($customerId: bigint!) {
    locations(
      where: { customer_id: { _eq: $customerId }, active: { _eq: 1 } }
      order_by: [{ favorite: desc }, { name: asc }]
    ) {
      id
      address
      customer_id
      email
      favorite
      latitude
      longitude
      name
      nickname
      notes
      phone
      place_id
      region_id
      timezone
      type
    }
  }
`;

const UPSERT_LOCATION = gql`
  mutation upsert_location(
    $address: String!
    $customerId: bigint!
    $email: String
    $latitude: numeric!
    $longitude: numeric!
    $name: String!
    $nickname: String
    $phone: String
    $placeId: String
    $regionId: bigint
    $timezone: String
    $type: String
  ) {
    insert_locations(
      objects: {
        active: 1
        address: $address
        customer_id: $customerId
        email: $email
        latitude: $latitude
        longitude: $longitude
        name: $name
        nickname: $nickname
        phone: $phone
        place_id: $placeId
        region_id: $regionId
        timezone: $timezone
        type: $type
        createdat: "now()"
        updatedat: "now()"
      }
      on_conflict: {
        constraint: locations_customer_id_address_key
        update_columns: [
          active
          address
          customer_id
          email
          latitude
          longitude
          name
          nickname
          phone
          place_id
          region_id
          timezone
          type
          updatedat
        ]
      }
    ) {
      affected_rows
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;
const UPDATE_LOCATION_FAVORITE = gql`
  mutation update_location_favorite($id: bigint!, $fav: Boolean!) {
    update_locations(where: { id: { _eq: $id } }, _set: { favorite: $fav }) {
      affected_rows
      returning {
        id
        name
        nickname
        address
        favorite
      }
    }
  }
`;
