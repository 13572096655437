//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import axios from 'axios';
import { makeStyles, Grid, Typography, TextField, MenuItem, Chip, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import MDEditor from '@uiw/react-md-editor';
import ReactMarkdown from 'react-markdown';
import Select from 'react-select';
import { getUserEmail } from '../../utils/authHelper'
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Divide } from '@hopdrive/storybook';
import { useNotificationsGlobals } from '../../providers/NotificationsProvider';
import Loading from '../../components/Loading'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

//////////////////////// COMPONENT ////////////////////////

function NotificationInfo(props) {
  const cls = useStyles();
  const theme = useTheme()
  const { notification, editMode, editVals, editSetVals } = props;
  const { assignmentBell, setAssignmentBell } = useNotificationsGlobals();

  const [users, setUsers] = React.useState(null);
  const [isDueDatePast, setIsDueDatePast] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState(null)
  const assignedDate = moment(notification.assignedat).format('MM/DD/YYYY');
  const assignedTime = moment(notification.assignedat).format('LT');
  const createdDate = moment(notification.createdat).format('MM/DD/YYYY');
  const createdTime = moment(notification.createdat).format('LT');
  const resolvedDate = moment(notification.resolvedat).format('MM/DD/YYYY');
  const resolvedTime = moment(notification.resolvedat).format('LT');
  const dueDateDate = moment(notification.duedate).format('MM/DD/YYYY');
  const dueDateTime = moment(notification.duedate).format('LT');

  const checkDueDate = () => {
    if (notification.duedate && !dayjs().isBefore(notification.duedate)) {
      setIsDueDatePast(true);
    } else {
      setIsDueDatePast(false);
    }
  };

  React.useEffect(() => {
      checkDueDate();

      let email = getUserEmail()
      setUserEmail(email)
  }, []);

  const reactSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#00000008', minHeight: '25px', maxHeight: '25px' }),
    placeholder: styles => ({ ...styles, right: '0%', marginTop: '-2px' }),
    indicator: styles => ({ ...styles, marginTop: '-12px', maxHeight: '25px' }),
    indicatorsContainer: styles => ({ ...styles, marginTop: '-8px' }),
    indicatorSeparator: styles => ({ ...styles, height: '0px' }),
    input: styles => ({ ...styles, marginTop: '-5px', color: 'black', right: '0%' }),
    container: styles => ({ ...styles, border: '0px' }),
    option: styles => {
      return {
        ...styles,
        backgroundColor: '#00000008',
        color: 'black',
      };
    },
  };

  const { type, title, body, assignedTo, assignedBy, assignedAt, resolvedBy, resolvedAt, notes, dueDate } = editVals;
  const {
    setType,
    setTitle,
    setBody,
    setAssignedTo,
    setAssignedBy,
    setAssignedAt,
    setResolvedBy,
    setResolvedAt,
    setNotes,
    setDueDate,
  } = editSetVals;

  const GET_ADMIN_USERS = gql`
  query getAdminUsers {
    users(where: {default_role: {_eq: "admin"}}) {
      email
      firebase_id
      default_role
    }
  }
`

const { loading, error, data } = useQuery(GET_ADMIN_USERS);

React.useEffect(() => {
  if (data && data.users && data.users.length > 0) {
    setUsers(data.users)
  }
}, [data])

  React.useEffect(() => {
    setType(notification.type || ``);
    setTitle(notification.title || ``);
    setBody(notification.body || ``);
    setAssignedTo(notification.assignedto || ``);
    setAssignedBy(notification.assignedby || ``);
    setAssignedAt(notification.assignedat || ``);
    setResolvedBy(notification.resolvedby || ``);
    setResolvedAt(notification.resolvedat || ``);
    setNotes(notification.notes || ``);
    setDueDate(notification.duedate || ``);
  }, [editMode, notification]);


  const handleAssignedToChange = async selectedOption => {
    setAssignedTo(selectedOption.value === 'Clear Value' ? null : selectedOption.value);
    setAssignmentBell(false);
    if (selectedOption.value !== 'Clear Value') {
      setAssignedAt(dayjs().format());
      setAssignedBy(userEmail);
      setResolvedBy(null);
      setResolvedAt(null);
    } else {
      setAssignedAt(null);
      setAssignedBy(null);
      setResolvedBy(null);
      setResolvedAt(null);
    }
  };

  const handleResolvedByChange = selectedOption => {
    setResolvedBy(selectedOption.value === 'Clear Value' ? null : selectedOption.value);
    if (selectedOption.value === 'Clear Value') {
      setResolvedAt(null);
    } else setResolvedAt(dayjs().format());
    console.log('resolved input changed');
  };

  const handleAssignedByChange = selectedOption => {
    setAssignedBy(selectedOption.value === 'Clear Value' ? null : selectedOption.value);
    if (selectedOption.value === 'Clear Value') {
      setAssignedBy(null);
      setAssignedAt(null);
    } else setAssignedAt(dayjs().format());
  };

  const handleInputChange = setHandler => event => {
    setHandler(event.target.value);
  };

  const handleTypeChange = event => {
    if (event.target.value === 'inform') {
      setType(event.target.value);
      setResolvedBy(null);
      setResolvedAt(null);
      setAssignedTo(null);
      setAssignedBy(null);
      setAssignedAt(null);
      setDueDate(null);
    } else setType(event.target.value);
  };

  const handleAssignedAtTimeChange = time => {
    setAssignedAt(dayjs(time).format());
  };

  const handleResolvedAtTimeChange = time => {
    setResolvedAt(dayjs(time).format());
  };

  const handleDueDateChange = time => {
    setDueDate(dayjs(time).format());
  };

  if (loading || !notification) {
    return <Loading fixed />;
  }

  if (error) {
    console.log(error);
    return (
      <div className={cls.root}>
        <div className={cls.notifyBox}>
          <Typography className={cls.notifyText} style={{ color: theme.palette.error.main }}>
            AN ERROR OCCURRED
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={cls.main}>
      <Grid container style={{ justifyContent: 'space-evenly' }}>
        {editMode ? (
          <Grid item xs={5}>
            <Divide spacer>At a glance</Divide>
            <div className={cls.paper} style={{ display: 'grid', minHeight: '282px', alignContent: 'start' }}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
                <TextField
                  inputProps={{ className: cls.nakedValInput }}
                  select
                  required
                  fullWidth
                  placeholder={notification.type[0].toUpperCase() + notification.type.substring(1) || `-`}
                  value={type}
                  onChange={handleTypeChange}
                  side='small'
                  variant='outlined'
                >
                  <MenuItem value={`inform`}>Inform</MenuItem>
                  <MenuItem value={`action`}>Action</MenuItem>
                </TextField>
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Title:&nbsp;&nbsp;&nbsp;</Typography>
                <TextField
                  inputProps={{ className: cls.nakedValInput }}
                  required
                  fullWidth
                  value={title}
                  placeholder={notification.title[0].toUpperCase() + notification.title.substring(1) || `-`}
                  onChange={handleInputChange(setTitle)}
                  size='medium'
                  variant='outlined'
                />
              </div>

              <div className={cls.lineBreak} />
              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Due:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Due:&nbsp;&nbsp;&nbsp;</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      fullWidth
                      inputProps={{ className: cls.nakedValInput }}
                      orientation='portrait'
                      inputVariant='outlined'
                      value={dueDate ? dueDate : null}
                      onChange={handleDueDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}
            

            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Body:&nbsp;&nbsp;&nbsp;</Typography>
              <TextField
                inputProps={{
                  style: { textAlign: 'right', fontSize: '.85rem' },
                }}
                className={cls.nakedBodyInput}
                required
                multiline
                fullWidth
                value={body}
                placeholder={notification.body || `-`}
                onChange={handleInputChange(setBody)}
                size='small'
                variant='outlined'
              />
            </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={5}>
            <Divide spacer>At a glance</Divide>
            <div className={cls.paper} style={{ display: 'grid', minHeight: '244px', alignContent: 'start' }}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Type:&nbsp;&nbsp;&nbsp;</Typography>
                {notification.type === 'inform' ? (
                  <Typography className={cls.valTxt} style={{ textAlign: 'right' }}>
                    Information
                  </Typography>
                ) : (
                  <Typography className={cls.valTxt} style={{ textAlign: 'right' }}>
                    Action Item
                  </Typography>
                )}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Title:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.title[0].toUpperCase() + notification.title.substring(1) || `-`}
                </Typography>
              </div>

              <div className={cls.lineBreak} />

              {isDueDatePast && notification.resolvedby === null ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Due:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    <Chip variant={'outlined'} size='small' color={'primary'} label={'Overdue'} />
                    &nbsp;&nbsp;{notification.duedate ? dueDateDate + ', ' + dueDateTime : `-`}
                  </Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Due:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>
                    {notification.duedate ? dueDateDate + ', ' + dueDateTime : `-`}
                  </Typography>
                </div>
              )}

              <div className={cls.lineBreak} />

              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Body:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{notification.body || `-`}</Typography>
              </div>
            </div>
          </Grid>
        )}

        {editMode ? (
          <Grid item xs={5}>
            <Divide spacer>Workflow</Divide>
            <div className={cls.paper}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Created&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{notification.createdby || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Created&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{createdDate + ', ' + createdTime || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;To:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;To:&nbsp;&nbsp;&nbsp;</Typography>
                  <div style={{ width: '500px' }}>
                    <Select
                      styles={reactSelectStyles}
                      inputLabel='Assigned To'
                      options={users.map(option => ({ label: option.name, value: option.name }))}
                      onChange={handleAssignedToChange}
                      variant='outlined'
                      value={assignedTo}
                      placeholder={assignedTo}
                    />
                  </div>
                </div>
              )}
              <div className={cls.lineBreak} />

              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      fullWidth
                      inputProps={{ className: cls.nakedValInput }}
                      orientation='portrait'
                      inputVariant='outlined'
                      value={assignedAt ? assignedAt : null}
                      onChange={handleAssignedAtTimeChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}

              <div className={cls.lineBreak} />

              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Assigned&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                  <div style={{ width: '500px' }}>
                    <Select
                      styles={reactSelectStyles}
                      inputLabel='Assigned By'
                      options={users.map(option => ({ label: option.name, value: option.name }))}
                      onChange={handleAssignedByChange}
                      variant='outlined'
                      value={assignedBy}
                      placeholder={assignedBy}
                    />
                  </div>
                </div>
              )}

              <div className={cls.lineBreak} />

              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Resolved&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Resolved&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                  <div style={{ width: '500px' }}>
                    <Select
                      styles={reactSelectStyles}
                      inputLabel='ResolvedBy'
                      options={users.map(option => ({ label: option.name, value: option.name }))}
                      onChange={handleResolvedByChange}
                      variant='outlined'
                      value={resolvedBy}
                      placeholder={resolvedBy}
                    />
                  </div>
                </div>
              )}

              <div className={cls.lineBreak} />

              {type === 'inform' ? (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Resolved&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography className={cls.valTxt}>-</Typography>
                </div>
              ) : (
                <div className={cls.listItem}>
                  <Typography className={cls.keyTxt}>Resolved&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      fullWidth
                      inputProps={{ className: cls.nakedValInput }}
                      orientation='portrait'
                      inputVariant='outlined'
                      value={resolvedAt ? resolvedAt : null}
                      onChange={handleResolvedAtTimeChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}
            </div>
          </Grid>
        ) : (
          <Grid item xs={5}>
            <Divide spacer>Workflow</Divide>
            <div className={cls.paper}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Created&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{notification.createdby || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Created&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{createdDate + ', ' + createdTime || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Assigned&nbsp;To:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.assignedto ? notification.assignedto : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Assigned&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.assignedat ? assignedDate + ', ' + assignedTime : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Assigned&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.assignedby ? notification.assignedby : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Resolved&nbsp;By:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.resolvedby ? notification.resolvedby : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Resolved&nbsp;On:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {notification.resolvedat ? resolvedDate + ', ' + resolvedTime : `-`}
                </Typography>
              </div>
            </div>
          </Grid>
        )}

        {editMode ? (
          <Grid item xs={11}>
            <Divide spacer>Notes</Divide>
            <div className={cls.paper}>
              <div className={cls.listItem}>
                <MDEditor style={{ width: '100%' }} value={notes} onChange={setNotes} fullWidth />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={11}>
            <Divide spacer>Notes</Divide>
            <div className={cls.paper}>
              <div className={cls.listItem}>
                <Typography className={cls.valTxt}>
                  {notification.notes ? (
                    <Typography className={cls.valTxt} style={{ textAlign: 'left' }}>
                      <ReactMarkdown>{notification.notes}</ReactMarkdown>
                    </Typography>
                  ) : (
                    <Typography className={cls.notFoundTxt}>NO NOTES YET</Typography>
                  )}
                </Typography>
              </div>
            </div>
          </Grid>
        )}
        {notification.json ? (
          <Grid item xs={11}>
            <Divide spacer>Metadata</Divide>
            <div className={cls.paper}>
              <div className={cls.listItem}>
                <JSONInput
                  key={notification.id + 'JSONInput'}
                  id={notification.id}
                  placeholder={notification.json || null}
                  viewOnly={true}
                  locale={locale}
                  height='200px'
                  width='100%'
                />
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#e0e0e0',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  // nakedValInput: {
  //   width: '100%',
  //   padding: '3px',
  //   borderRadius: '5px',
  //   minHeight: '17px',
  //   maxHeight: '17px',
  //   textAlign: 'right',
  // },
  nakedValInput: {
    width: '100%',
    minHeight: '25px',
    maxHeight: '25px',
    paddingRight: 12,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    background: '#00000008',
    textAlign: 'right',
    fontSize: '.85rem',
  },
  nakedBodyInput: {
    width: '100%',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
    fontSize: '.85rem',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default NotificationInfo;
