const { REACT_APP_ENV } = process.env;

const REACT_APP_A0_CB_SD = REACT_APP_ENV === 'production' ? 'admin' : 'admin-test';
const REACT_APP_A0_SD = REACT_APP_ENV === 'production' ? 'sat-auth' : 'sat-auth-test';
const REACT_APP_ACC_CODES = 'delay,fuel,partial,waived,surge,tow,toll,ride,insurance,reimbursement';
const REACT_APP_GQL_SD = REACT_APP_ENV === 'production' ? 'gql-alb' : 'gql-test';
const REACT_APP_TKN_SD = REACT_APP_ENV === 'production' ? 'dispatch-api-prod' : 'dispatch-api-test';
const RET_RIDE_SD = REACT_APP_ENV === 'production' ? 'returns' : 'returns-test';
const CON_SD = REACT_APP_ENV === 'production' ? 'concierge' : 'concierge-test';
const EVENTS_SD = REACT_APP_ENV === 'production' ? 'events' : 'events-test';

export { REACT_APP_A0_CB_SD, REACT_APP_A0_SD, REACT_APP_ACC_CODES, REACT_APP_GQL_SD, REACT_APP_TKN_SD, RET_RIDE_SD, CON_SD, EVENTS_SD };
