//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Tooltip, Icon, Chip } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

import { Table, Column } from '../../components/TableComponents';

//////////////////////// COMPONENT ////////////////////////

export default function CustomersTable({ customers, customerIdArray, status, search }) {
  const cls = useStyles();
  const { capFirst, goToCustomerDetails, getCleansedPhoneNumber } = useTools();

  // Set filtered customers based on search results
  const [filteredCustomers, setFilteredCustomers] = React.useState(customers);
  React.useEffect(() => {
    let newFilteredCustomers = customers;

    if (customerIdArray?.length) {
      newFilteredCustomers = newFilteredCustomers.filter(c => {
        return customerIdArray?.length > 0 && customerIdArray?.includes(c?.id);
      });
    }

    if (status && status !== `all`) {
      newFilteredCustomers = newFilteredCustomers.filter(c => c.status && c.status.toLowerCase().includes(status));
    }

    if (search && search.length) {
      const searchInput = search.toLowerCase();
      newFilteredCustomers = newFilteredCustomers.filter(c => {
        return (
          `${c?.id}`.includes(searchInput) ||
          c?.name?.toLocaleLowerCase().includes(searchInput) ||
          c?.phone?.toLocaleLowerCase().includes(searchInput) ||
          c?.email?.toLocaleLowerCase().includes(searchInput) ||
          c?.organization?.name?.toLocaleLowerCase().includes(searchInput)
        );
      });
    }

    setFilteredCustomers(newFilteredCustomers);
    // eslint-disable-next-line
  }, [customers, customerIdArray, status, search]);

  const getStatusChipClass = status => {
    switch (status) {
      case `live`:
        return cls.statusLive;
      case `onboarding`:
        return cls.statusOnboarding;
      case `deactivated`:
        return cls.statusDeactivated;
      default:
        return cls.statusUnknown;
    }
  };

  const rowActions = [
    {
      label: `Go To Customer Details`,
      handler: customer => goToCustomerDetails(customer?.id),
      disabled: false,
      hide: false,
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={filteredCustomers}
        onRowClick={customer => goToCustomerDetails(customer?.id)}
        rowActions={rowActions}
        defaultOrderBy={'moves'}
        defaultOrder={'desc'}
        tableAriaLabel={`customers`}
        stickyHeader={true}
      >
        <Column
          name={`name`}
          value={customer => customer?.name || `-`}
          label={`Name`}
          tooltip={`Name of the customer`}
          style={{ minWidth: 160 }}
          renderer={customer => (
            <Tooltip title={`Customer ID #${customer?.id || `-`}`}>
              <Typography className={cls.rowTxt}>{customer?.name || `-`}</Typography>
            </Tooltip>
          )}
        />

        <Column
          name={`organization`}
          value={customer => customer?.organization?.name || `-`}
          label={`Organization`}
          tooltip={`Organization the customer belongs to`}
          style={{ minWidth: 160 }}
          renderer={customer => (
            <Tooltip title={customer?.organization ? `Org ID #${customer?.organization?.id}` : ``}>
              <Typography className={cls.rowTxt}>{customer?.organization?.name || `-`}</Typography>
            </Tooltip>
          )}
        />

        <Column
          name={`moves`}
          value={customer => customer?.moves_aggregate?.aggregate?.count || `0`}
          label={`30-Day Moves`}
          tooltip={`Count of completed moves from the customer in the past 30 days`}
          style={{ minWidth: 150 }}
        />

        <Column
          name={`address`}
          value={customer => customer?.address || `-`}
          label={`Address`}
          tooltip={`Address of the customer`}
        />

        <Column
          name={`email`}
          value={customer => customer?.email?.split(`,`)?.[0] || `-`}
          label={`Email`}
          tooltip={`Email of the customer`}
          style={{ minWidth: 120 }}
        />

        <Column
          name={`phone`}
          value={customer => getCleansedPhoneNumber(customer?.phone) || `-`}
          label={`Phone`}
          tooltip={`Phone number of the customer`}
          style={{ minWidth: 140 }}
        />

        <Column
          name={`status`}
          value={customer => customer?.status}
          label={`Status`}
          tooltip={`Status of the customer`}
          style={{ minWidth: 120 }}
          align={`right`}
          renderer={customer => (
            <Chip
              className={getStatusChipClass(customer?.status)}
              label={capFirst(customer?.status || `unknown`)}
              color='primary'
              size='small'
              icon={
                <>
                  {customer?.status === `live` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      check_circle
                    </Icon>
                  )}
                  {customer?.status === `onboarding` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      contrast
                    </Icon>
                  )}
                  {customer?.status === `deactivated` && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      block
                    </Icon>
                  )}
                  {!customer?.status && (
                    <Icon className={cls.statusIcon} fontSize='small'>
                      help
                    </Icon>
                  )}
                </>
              }
            />
          )}
        />

        <Column
          name={`active`}
          value={customer => customer?.active}
          label={`Active?`}
          tooltip={`Is the customer active or inactive?`}
          style={{ minWidth: 80 }}
          align={`right`}
          renderer={customer => (
            <Chip
              className={customer?.active ? cls.activeTrueChip : cls.activeFalseChip}
              label={customer?.active ? `Active` : `Inactive`}
              color='primary'
              size='small'
            />
          )}
        />
      </Table>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  rowTxt: {
    lineHeight: 1.43,
    fontSize: 14,
    fontWeight: 400,
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  statusLive: {
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
  },
  statusOnboarding: {
    fontWeight: 600,
    backgroundColor: theme.palette.warning.main,
  },
  statusDeactivated: {
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
  },
  statusUnknown: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },

  statusIcon: {
    marginLeft: 2,
    marginRight: -2,
  },

  activeTrueChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.default.light,
  },
  activeFalseChip: {
    fontWeight: 600,
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));
