import React, { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import DefaultErrorFallback from '../../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../../components/Fallbacks/DefaultEmptyFallback';
import { useLazyQuery, gql } from '@apollo/client';
import Loading from '../../../components/Loading';
import { SimpleLogger } from '../../../utils/SimpleLogger';
import { useSettings } from './SettingsProvider';
import { useRegionsGlobals } from '../../../providers/RegionsProvider';
import { getAllowedRegions } from '../../../utils/authHelper';

const RegionsContext = createContext({});

function RegionsProvider({ children }) {
  const { enableRegionLogs } = useSettings();
  const { selectedRegionIds, setSelectedRegionIds} = useRegionsGlobals()

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedRegionId, setSelectedRegionId] = useState(Number(localStorage.getItem(`selected-region-id`)) || 0);
  const [timezoneOverride, setTimezoneOverride] = useState(dayjs.tz.guess());
  const [preselectedRegions, setPreselectedRegions] = useState(null)

  const { log } = new SimpleLogger({ prefix: 'RegionsProvider', enabled: enableRegionLogs });

  //Grab keys from region preferences object to make array of only selected regions' ids
  const getSelectedRegions = () => {
    let result
    let regionPreferencesObject = JSON.parse(localStorage.getItem('selected-regions'))
    if (regionPreferencesObject) {
      result = Object.keys(regionPreferencesObject).map(function (key) {
        return [Number(key), regionPreferencesObject[key]]
      })
    }

    let preferredRegionIds = []
    if (result) {
      result.map(r => {
        if (r[1] === true ){
          preferredRegionIds = preferredRegionIds.concat(r[0])
        }
        return preferredRegionIds
      })
    }
    return preferredRegionIds
  }

  const _selectedRegionIds = getSelectedRegions()

  let _regions = [];

  // Go refresh the regions array when the selected Ids change
  useEffect(() => {
    if (!selectedRegionId) {
      setSelectedRegion(null);
      setTimezoneOverride(dayjs.tz.guess());
      log(`All Regions selected - Guessing timezone: "${dayjs.tz.guess()}"`);
      return;
    }

    const _region = _regions.find(region => region.id === selectedRegionId);
    if (_region) {
      setSelectedRegion(_region);
      setTimezoneOverride(_region.timezone);
      log(`"${_region.name}" selected - Timezone: "${_region.timezone}"`);
    }
  }, [selectedRegionId]);

  // Go refresh the regions array when the selected Ids change
  useEffect(() => {
    if (!selectedRegionIds) return;
    log('Refetching the regions because selected region ids changed...');
    runRegionsQuery();
  }, [selectedRegionIds]);

  //If there are selected regions already saved in local storage, we'll use those.
  //If there are none, we'll preselect all of the user's allowed regions.
  //(This will prevent confusion, because plans remains empty until you've selected some regions.)
  useEffect(() => {
    const getInitialLocalStorageValues = (ar) => {
      let initialLocalStorageObj = {}
      ar.forEach(element => {
        initialLocalStorageObj[element] = true
      })
      localStorage.setItem('selected-regions', JSON.stringify(initialLocalStorageObj))
    }

    const retrieveAllowedRegions = async () => {
      const allowedRegions = await getAllowedRegions()
      if (allowedRegions) {
        const parsedAllowedRegions = JSON.parse(allowedRegions.replace('{', '[').replace('}', ']'))
        setPreselectedRegions(parsedAllowedRegions)
        getInitialLocalStorageValues(parsedAllowedRegions)
      }
    }
    log('Initializing through useEffect()...');
    if (_selectedRegionIds && _selectedRegionIds.length && _selectedRegionIds.length > 0) {
      setSelectedRegionIds(_selectedRegionIds)
    } else {
      retrieveAllowedRegions()
    }
  }, []);

  useEffect(() => {
    if (preselectedRegions && preselectedRegions.length && preselectedRegions.length > 0) {
      setSelectedRegionIds(preselectedRegions)
    }
  }, [preselectedRegions])

  const [runRegionsQuery, { called, loading, error, data, refetch }] = useLazyQuery(
    gql`
      query admin_plans_get_selected_regions($selectedRegionIds: [bigint!]) {
        regions(where: { id: { _in: $selectedRegionIds } }, order_by: { id: asc }) {
          id
          accounting_class_num
          description
          geofence
          last_synced
          name
          region_id
          team_id
          timezone
          createdat
          updatedat
        }
      }
    `,
    {
      variables: {
        selectedRegionIds: selectedRegionIds,
      },
    }
  );

  // NOT CALLED //
  if (!called) {
    log('Query not called yet to get the regions from the server...');
    return <Loading fixed />;
  }

  // LOADING STATE //
  if (loading) {
    log('Still loading the regions from the server...');
    return <Loading fixed />;
  }

  // ERROR STATE //
  if (error) {
    log('Error fetching regions:', error);
    toast.error(`Failed to retrieve region data`);
    //Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR GETTING REGIONS' />;
  }

  // EMPTY STATE //
  if ((!data || !data.regions || data.regions.length <= 0) && !loading && !called) {
    log(`No regions found using ${JSON.stringify(selectedRegionIds)}. Here's the data returned:`, data);
    return <DefaultEmptyFallback message={<span>No regions found</span>} />;
  } else {
    log('Found regions: ', data.regions);
  }

  // DATA STATE //
  _regions = data.regions;

  // Export state variables by adding them to context
  const context = {
    regions: _regions,
    selectedRegion,
    setSelectedRegion,
    selectedRegionId,
    setSelectedRegionId,    
    selectedRegionIds: selectedRegionIds,
    setSelectedRegionIds,
    timezoneOverride,
    setTimezoneOverride,
  };

  return <RegionsContext.Provider value={context}>{children}</RegionsContext.Provider>;
}

const useRegions = () => useContext(RegionsContext);

export { useRegions, RegionsProvider };
