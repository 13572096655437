//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles, Container, Typography, Grid } from '@material-ui/core';
import { Timeline } from '@material-ui/lab/';
import { gql } from 'graphql-tag';
import MoveTile from '../../components/MoveTile';

//////////////////////// COMPONENT ////////////////////////

function LocationMovesList({ location, ...props }) {
  const cls = useStyles();

  const { loading, error, data } = useQuery(GET_LOCATION_MOVES, { variables: { locationId: location.id } });

  // LOADING STATE //
  if (loading) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>LOADING ASSOCIATED MOVES...</Typography>
        </div>
      </Container>
    );
  }

  // ERROR STATE //
  if (error) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>ERROR FETCHING ASSOCIATED MOVES</Typography>
        </div>
      </Container>
    );
  }

  // EMPTY STATE //
  if (!data || !data.moves || !data.moves.length > 0) {
    return (
      <Container maxWidth='md'>
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO ASSOCIATED MOVES AVAILABLE</Typography>
        </div>
      </Container>
    );
  }

  // DATA STATE //

  if (data || data.moves || data.moves.length > 0) {
    const movesArray = Object.keys(data.moves).map(key => [data.moves[key]]);
    return (
      <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
        {movesArray.map((move, i) => (
          <Grid key={`move-tile-${i}`} item xs className={cls.tile}>
            <MoveTile move={move[0]} />
          </Grid>
        ))}
      </Grid>
    );
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  timeline: {
    position: 'relative',
    padding: 0,
    margin: 0,
  },
  load: {
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  tile: {
    position: 'relative',
    minWidth: '420px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '320px',
      maxWidth: '360px',
    },
  },
}));

const GET_LOCATION_MOVES = gql`
query get_location_moves($locationId: bigint!) {
  moves(limit: 10, order_by: {createdat: desc}, where: {_or: [{lane: {origin_location_id: {_eq: $locationId}}}, {lane: {destination_location_id: {_eq: $locationId}}}]})
  {
    id
    active
    status
    cancel_status
    move_type
    ride_type
    lane {
      id
      description
      delivery {
        id
        name
        address
        latitude
        longitude
        region {
          name
          id
        }
      }
    }
    reference_num
    vehicle_stock
    vehicle_vin
    vehicle_make
    vehicle_model
    vehicle_year
    vehicle_color
    vehicle_image
    vehicle_odometer
  }
}
`;

//////////////////////// EXPORT ////////////////////////

export default LocationMovesList;
