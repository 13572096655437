// DEPENDENCIES -------------------------------------------------- //

import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import IconAction from '../../components/IconAction';

import { useGPSViewer } from './GPSViewerProvider';
import GPSViewerFilter from './GPSViewerFilter';
import GPSViewerTable from './GPSViewerTable';

const log = true;

// COMPONENT -------------------------------------------------- //

export default function GPSViewerSidebar(props) {
  const cls = useStyles();

  const { rawPoints, driverId, moveId, convertToMapPoints, fetchGPSPingsByDriver, fetchGPSPingsByMove, fetchMove } =
    useGPSViewer();

  const [refetching, setRefetching] = React.useState(false);

  const handleRefetch = async () => {
    log && console.log(`REFETCHING`);

    // If a move ID exists, query that first
    if (moveId) {
      const gpsRes = await fetchGPSPingsByMove();
      const moveRes = await fetchMove();
      const newMapPoints = convertToMapPoints(gpsRes);
    } else if (driverId) {
      const gpsRes = await fetchGPSPingsByDriver();
      const newMapPoints = convertToMapPoints(gpsRes);
    } else {
    }

    // Reset refetch bool
    setRefetching(false);
  };

  return (
    <div className={cls.sidebar}>
      <div className={cls.sidebarTop}>
        <div className={cls.sectionPad}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs>
              <Typography className={cls.sidebarTopTitle}>GPS Viewer</Typography>
            </Grid>

            <Grid item>
              <Button
                disabled={refetching || (!driverId && !moveId)}
                loading={refetching}
                color='primary'
                onClick={() => {
                  setRefetching(true);
                  handleRefetch();
                }}
              >
                Draw On Map
              </Button>
            </Grid>

            <Grid item>
              <IconAction />
            </Grid>
          </Grid>
        </div>

        <div className={cls.line} />

        <div className={cls.sectionPad}>
          <GPSViewerFilter />
        </div>

        <div className={cls.section}>
          <div className={cls.pointAmount}>
            <Typography className={cls.pointAmountTxt}>{rawPoints.length} Raw Points</Typography>
          </div>
        </div>
      </div>

      <div className={cls.sidebarBottom}>
        <GPSViewerTable />
      </div>
    </div>
  );
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  sidebar: {
    zIndex: 10,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    height: '100%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },

  sidebarTop: {
    zIndex: 11,
    boxShadow: theme.shadow.medium,
  },
  sidebarTopTitle: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  sidebarBottom: {
    height: '100%',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },

  sectionPad: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },

  pointAmount: {
    padding: theme.spacing(1, 2, 1, 2),
    background: theme.palette.default.main,
  },
  pointAmountTxt: {
    lineHeight: 1.25,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.contrast,
  },

  line: {
    width: '100%',
    height: 1,
    background: theme.palette.dividerStrong,
  },
}));
