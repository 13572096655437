// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

// COMPONENT -------------------------------------------------- //

export default function StepLabel({ step = {} }) {
  const cls = useStyles();

  const { id, label, description, required } = step;

  return (
    <div className={cls.label}>
      <div className={cls.title}>
        {label ? (
          <>
            <Typography className={cls.titleTxt}>{label}</Typography>
            {required ? <Typography className={cls.requiredTxt}> *</Typography> : null}
          </>
        ) : null}
      </div>

      <div className={cls.subtitle}>
        {description ? <Typography className={cls.subtitleTxt}>{description}</Typography> : null}
      </div>

      {label || description ? <Spacer /> : null}
    </div>
  );
}

// COMPONENT -------------------------------------------------- //
const useStyles = makeStyles(theme => ({
  title: {
    display: 'block',
  },
  titleTxt: {
    display: 'inline',
    fontSize: 18,
    fontWeight: 500,
  },
  requiredTxt: {
    display: 'inline',
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.error.main,
  },

  subtitle: {
    display: 'block',
  },
  subtitleTxt: {
    display: 'inline',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));
