// -------------------------------- IMPORTS -------------------------------- //

import dayjs from 'dayjs';

// -------------------------------- EXPORTS -------------------------------- //

// GETTERS //

/** Get the current position on the timeline */
export const getCurrentPosition = (date, zoom, multiplier, timezoneOverride) => {
  const zoomMultiplier = (zoom * multiplier) / 60;
  const startOfDay = dayjs(date).tz(timezoneOverride).add(12, `hours`).startOf(`day`).format();
  const currentTime = dayjs().tz(timezoneOverride);
  // const currentTime = dayjs().tz(timezoneOverride).add(30, `minute`); // TEST for when you want to change the current position manually

  return currentTime.diff(startOfDay, `seconds`) * zoomMultiplier;
};

/** Get the current time */
export const getCurrentTime = timezoneOverride => {
  return dayjs().tz(timezoneOverride).format();
};

/** Get the position from the last saved scoll position (convert seconds to pixels) */
export const getLastScrollPosition = (key, zoom, multiplier) => {
  const zoomMultiplier = (zoom * multiplier) / 60;
  const scrollSeconds = localStorage.getItem(key) || 25200; // Defaults to 7am in seconds
  return scrollSeconds * zoomMultiplier;
};

/** Save the last scroll position in local storage (convert pixels to seconds) */
export const setLastScrollPosition = (key, value, zoom, multiplier) => {
  const zoomMultiplier = (zoom * multiplier) / 60;
  const scrollSeconds = value / zoomMultiplier;
  localStorage.setItem(key, scrollSeconds);
};

/** Get the gap in pixels between two times */
export const getGapBetweenTimes = (startDate, endDate, zoom, multiplier) => {
  return dayjs(endDate).diff(startDate, `minutes`) * multiplier * zoom;
};

/** Get the time from a position */
export const getTimeFromPosition = (position, zoom, multiplier, timezoneOverride) => {
  return dayjs()
    .tz(timezoneOverride)
    .startOf(`day`)
    .add(position / (multiplier * zoom), `minutes`)
    .format();
};
