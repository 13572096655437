import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, TableCell, TablePagination, Typography } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { MissingRegionLocationsHeaders} from './index';
import { useTools } from '../../hooks/useTools';
import Loading from '../../components/Loading';
import dayjs from 'dayjs'


function MissingRegionLocationsTable(props) {
  const cls = useStyles();

  const { locations } = props;

  const { goToRoute } = useTools();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  if (!locations || locations.length < 0){
    return  <Loading />;
  }


  function descendingComparator(a, b, orderBy) {
    //put in this fix to allow sorting by customer name
    if (orderBy === 'customer.name') {
      if (b.customer.name < a.customer.name) {
        return -1;
      }
      if (b.customer.name > a.customer.name) {
        return 1;
      }
      return 0;
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const newOrderBy = property;
    let newOrder = 'desc';

    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc';
    }
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const openDetails = id => {
    let path = `/locations/${id}`;
    goToRoute(path);
  };

  const getMoveCount = (row) => {
    let moveCount = 0

    row.lanesByOriginLocationId.map(move => {
      moveCount = moveCount + move.moves_aggregate.aggregate.count
      return moveCount
    })

    row.lanesByDestinationLocationId.map(move => {
      moveCount = moveCount + move.moves_aggregate.aggregate.count
    })

    if (moveCount === undefined) {
      return 0
    } else return moveCount
  }



  return (
    <>
    <Typography className={cls.head}>Missing Region Locations</Typography>
        <Typography className={cls.sub}>
        The locations below are missing regions. Click on the row to go to the Location Details page to refetch the region and see what recent moves may have been affected.
        </Typography>



      <div className={cls.paper}>
        <TableContainer>
          <Table size='small' aria-labelledby='tableTitle'>
            <MissingRegionLocationsHeaders
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={locations.length}
            />
            <TableBody>
              {stableSort(locations, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const LocationActions = [
                    {
                      label: `Go to location details`,
                      handler: () => goToRoute(`/locations/${row.id}`),
                    },
                  ];
                  return (
                    <React.Fragment key={`location-manager-row-wrapper-${row.id}`}>
                      <ContextMenuTrigger
                        id={`atr-row-${row.id}-cm`}
                        key={`location-row-${row.id}`}
                        className={cls.tableRow}
                        source={`atr-row-${row.id}-cm`}
                        holdToDisplay={1000}
                        collect={() => props}
                        disableIfShiftIsPressed={true}
                        renderTag='tr'
                        attributes={{ className: `MuiTableRow-root ${index % 2 ? cls.rowEven : cls.rowOdd}` }}
                      >
                        <TableCell align='left' onClick={() => openDetails(row.id)} key={`location-manager-id-${row.id}`}>
                          {row.id || '-'}
                        </TableCell>
                        <TableCell align='left' onClick={() => openDetails(row.id)} key={`location-manager-name-${row.id}`}>
                          {row.name || '-'}
                        </TableCell>
                        <TableCell align='left' onClick={() => openDetails(row.id)} key={`location-manager-address-${row.id}`}>
                          {row.address || '-'}
                        </TableCell>
                        <TableCell align='left' onClick={() => openDetails(row.id)} key={`location-manager-created-at-${row.id}`}>
                          {dayjs(row.createdat).format('MM/DD/YYYY') || '-'}
                        </TableCell>
                        <TableCell align='right' onClick={() => openDetails(row.id)} key={`location-manager-move-count-${row.id}`}>
                          {getMoveCount(row)}
                        </TableCell>
                        <TableCell align='right' onClick={() => openDetails(row.id)} key={`location-manager-placeholder-${row.id}`}>
                          {null}
                        </TableCell>
                      </ContextMenuTrigger>

                      {LocationActions.map((action, index) => (
                        <ContextMenu id={`atr-row-${row.id}-cm`} key={`location-manager-action-menu-${row.id}`}>
                          <MenuItem
                            key={`atr-row-action-${row.id}`}
                            disabled={action.disabled || false}
                            onClick={action.handler || null}
                          >
                            {action.label || `Action ${index + 1}`}
                          </MenuItem>
                        </ContextMenu>
                      ))}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={locations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  image: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  tableRow: {
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.005)',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  head: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
}));

export default MissingRegionLocationsTable;