//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import { useTools } from '../../hooks/useTools';
import useOrganizationDetails from './useOrganizationDetails';

import DefaultLoadingFallback from '../../components/Fallbacks/DefaultLoadingFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';

import Toolbar from '../../components/Toolbar';

import OrganizationDetailsToolbar from './OrganizationDetailsToolbar';
import OrganizationDetailsOverview from './OrganizationDetailsOverview';
import OrganizationDetailsUsers from './OrganizationDetailsUsers';
import OrganizationDetailsSettings from './OrganizationDetailsSettings';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function OrganizationDetails(props) {
  const cls = useStyles();

  const { replacePath } = useTools();
  const { tabs } = useOrganizationDetails();

  const organizationId = props?.match?.params?.id;
  const pathExtension = props?.location?.pathname?.split(`/`)?.[3];
  const initialTab = tabs.find(tab => tab?.route === pathExtension);

  const [activeTab, setActiveTab] = React.useState(initialTab?.id || 0);

  // Handle tab routing
  const handleRouteToTab = id => {
    const foundTab = tabs?.find(tab => tab?.id === id);
    setActiveTab(foundTab?.id);
    replacePath(`/organizations/${organizationId}/${foundTab?.route}`);
  };

  // Query for fetching organization details
  const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: {
      organizationId,
      start60: dayjs().subtract(60, `day`).startOf(`day`).format(),
      start30: dayjs().subtract(30, `day`).startOf(`day`).format(),
      end: dayjs().endOf(`day`).format(),
    },
  });

  // Handle refetching data
  const handleRefetch = () => {
    refetch();
  };

  // Mutation for activating/deactivating organization
  const [activateDeactivateOrganization] = useMutation(ACTIVATE_DEACTIVATE_ORGANIZATION);
  const handleOrganizationActivation = async (active, status) => {
    try {
      const res = await activateDeactivateOrganization({ variables: { organizationId, active, status } });
      if (res?.data?.update_organizations?.affected_rows) {
        toast.success(`Successfully ${active ? `activated` : `deactivated`} organization!`);
        handleRefetch();
      }
    } catch (err) {
      console.error(`Failed to ${active ? `activate` : `deactivate`} organization:`, err);
      toast.error(`Failed to ${active ? `activate` : `deactivate`} organization!`);
    }
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Organizations`, `#${organizationId}`]}
          back
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <OrganizationDetailsToolbar />
        </Toolbar>

        <DefaultLoadingFallback message='FETCHING ORGANIZATION' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching organization:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Organizations`, `#${organizationId}`]}
          back
          refetch={handleRefetch}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <OrganizationDetailsToolbar />
        </Toolbar>

        <DefaultErrorFallback message='ERROR FETCHING ORGANIZATION' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.organizations || !data.organizations.length) {
    return (
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Organizations`, `#${organizationId}`]}
          back
          refetch={handleRefetch}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <OrganizationDetailsToolbar />
        </Toolbar>

        <DefaultEmptyFallback message='NO ORGANIZATION FOUND' />
      </div>
    );
  }

  // DATA STATE //
  const organization = data.organizations[0];
  log && console.log(`Organization:`, organization);

  // Organization actions
  const actions = [
    {
      label: `Activate Organization`,
      handler: async () => await handleOrganizationActivation(true, `live`),
      hide: organization?.active,
    },
    {
      label: `Deactivate Organization`,
      handler: async () => await handleOrganizationActivation(false, `deactivated`),
      hide: !organization?.active,
    },
  ];

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING ORGANIZATION DETAILS' />}>
      <div className={cls.root}>
        <Toolbar
          zIndex={11}
          fullscreen
          title={[`Organizations`, organization?.name]}
          back
          refetch={handleRefetch}
          actions={actions}
          tabs={tabs}
          activeTab={activeTab}
          onActiveTabChange={e => handleRouteToTab(e)}
        >
          <OrganizationDetailsToolbar organization={organization} />
        </Toolbar>

        {/* OVERVIEW */}
        {activeTab === 0 && <OrganizationDetailsOverview organization={organization} refetch={handleRefetch} />}

        {/* USERS */}
        {activeTab === 1 && <OrganizationDetailsUsers organization={organization} />}

        {/* SETTINGS */}
        {activeTab === 2 && <OrganizationDetailsSettings organization={organization} refetch={handleRefetch} />}
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_ORGANIZATION_DETAILS = gql`
  query admin_getOrganizationDetails(
    $organizationId: bigint!
    $start60: timestamptz!
    $start30: timestamptz!
    $end: timestamptz!
  ) {
    organizations(where: { id: { _eq: $organizationId } }) {
      id
      active
      category
      config
      created_at
      description
      name
      status
      type
      updated_at
      customers(order_by: { name: asc }) {
        id
        name
        previous30Moves: moves(
          where: {
            active: { _eq: 1 }
            chargeable: { _eq: true }
            move_type: { _eq: "drive" }
            pickup_time: { _gte: $start60, _lte: $start30 }
            status: { _eq: "delivery successful" }
            _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
          }
        ) {
          id
          accountsReceivable {
            id
            due_amount
          }
        }
        current30Moves: moves(
          where: {
            active: { _eq: 1 }
            chargeable: { _eq: true }
            move_type: { _eq: "drive" }
            pickup_time: { _gte: $start30, _lte: $end }
            status: { _eq: "delivery successful" }
            _or: [{ cancel_status: { _is_null: true } }, { cancel_status: { _eq: "started" } }]
          }
        ) {
          id
          accountsReceivable {
            id
            due_amount
          }
        }
      }
    }
  }
`;

const ACTIVATE_DEACTIVATE_ORGANIZATION = gql`
  mutation admin_activateDeactivateOrganization($organizationId: bigint!, $active: Boolean!, $status: String!) {
    update_organizations(
      where: { id: { _eq: $organizationId } }
      _set: { active: $active, status: $status, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`;
