import React, { useState, useEffect } from 'react';
import LanesTable from './LanesTable';
import Pagination from '../../components/Pagination';
import Loading from '../../components/Loading';
import { useQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { DefaultEmptyFallback, DefaultErrorFallback } from '../../components/Fallbacks';

////////// COMPONENT ///////////
export default function TableWrapper({ customerId }) {
  const cls = useStyles();

  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  //Get the count of lanes to set number of records/pages in pagination
  const { loading, error, data } = useQuery(GET_CUSTOMER_LANE_COUNT, {
    variables: {
      customerId: customerId,
    },
  });
  useEffect(() => {
    if (data && data.lanes_aggregate) {
      setTotalRecords(data.lanes_aggregate.aggregate.count);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.error(`Error getting lane records:`, error);
    return <DefaultErrorFallback message={'ERROR FETCHING LANES'} />;
  }
  if (data && data.lanes_aggregate) {
    /////////COMPONENT////////
    return (
      <>
        {/* Table */}
        <div className={cls.paper}>
          <div className={cls.rootTable}>
            {totalRecords && totalRecords > 0 ? (
              <>
                  <LanesTable
                    customerId={customerId}
                    offset={offset}
                    limit={limit}
                  />
              </>
            ) : (
              <DefaultEmptyFallback message='CUSTOMER HAS NO LANES' />
            )}
            {/* Pagination */}
            <Pagination
              setLimit={setLimit}
              setOffset={setOffset}
              customerId={customerId}
              totalRecords={totalRecords}
              setPage={setPage}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <DefaultEmptyFallback message='NO LANES FOUND' />;
  }
}

////////// GQL ///////////
const GET_CUSTOMER_LANE_COUNT = gql`
  query get_customer_lane_count($customerId: bigint) {
    lanes_aggregate(where: { customer_id: { _eq: $customerId } }) {
      aggregate {
        count
      }
    }
  }
`;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
}));
