//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import sdk from '@hopdrive/sdk';
import { makeStyles, TextField, MenuItem, Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import SettingsTitle from '../SettingsTitle';
import SettingsOption from '../SettingsOption';
import { useTools } from '../../../hooks/useTools';

// OPTION PATHS //
const option = {
  marketManagerId: `config.hopdrive_contacts.market_manager`,
  accountManagerId: `config.hopdrive_contacts.account_manager`,
  salesRepId: `config.hopdrive_contacts.sales_rep`,
  arRepId: `config.hopdrive_contacts.ar_rep`,
};

// DEFAULTS //
const getDefaultMarketManagerId = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.marketManagerId) || getPropValue(inheritedRef, option?.marketManagerId);
};
const getDefaultAccountManagerId = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.accountManagerId) || getPropValue(inheritedRef, option?.accountManagerId);
};
const getDefaultSalesRepId = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.salesRepId) || getPropValue(inheritedRef, option?.salesRepId);
};
const getDefaultArRepId = (overrideRef, inheritedRef) => {
  return getPropValue(overrideRef, option?.arRepId) || getPropValue(inheritedRef, option?.arRepId);
};

//////////////////////// COMPONENT ////////////////////////

export default function Contacts({
  settingId,
  overrideRef,
  inheritedRef,
  updateMutableRef = () => {},
  checkMutableRefField = () => {},
  deleteMutableRefField = () => {},
  isReset,
  resetText,
  resetTooltip,
}) {
  const cls = useStyles();
  const { getCleansedPhoneNumber, goToUserDetails } = useTools();

  // Manage state of options
  const [marketManagerId, setMarketManagerId] = React.useState(getDefaultMarketManagerId(overrideRef, inheritedRef));
  const [marketManager, setMarketManager] = React.useState(null);
  const [accountManagerId, setAccountManagerId] = React.useState(getDefaultAccountManagerId(overrideRef, inheritedRef));
  const [accountManager, setAccountManager] = React.useState(null);
  const [salesRepId, setSalesRepId] = React.useState(getDefaultSalesRepId(overrideRef, inheritedRef));
  const [salesRep, setSalesRep] = React.useState(null);
  const [arRepId, setArRepId] = React.useState(getDefaultArRepId(overrideRef, inheritedRef));
  const [arRep, setArRep] = React.useState(null);
  const [admins, setAdmins] = React.useState([]);

  // Fetch active admins to populate dropdown options
  React.useEffect(() => {
    const fetchActiveAdmins = async () => {
      try {
        const response = await sdk.gql.query(GET_ACTIVE_ADMINS);
        const activeAdmins = response?.data || [];
        try {
          const mutableAdmins = [...activeAdmins]; // Create a mutable copy of activeAdmins
          mutableAdmins.sort((a, b) => {
            const emailA = a.email?.toLowerCase();
            const emailB = b.email?.toLowerCase();
            if (emailA < emailB) return -1;
            if (emailA > emailB) return 1;
            return 0;
          });
          setAdmins(mutableAdmins); // Update state with the sorted mutable copy
        } catch (error) {
          console.error('Error sorting activeAdmins:', error);
        }
      } catch (err) {
        console.error(`Failed to fetch active admins:`, err);
      }
    };
    fetchActiveAdmins();
  }, []);

  //Once we have a list of admins, set initial contacts if they exist
  //Also on reset when contact ids default
  React.useEffect(() => {
    if (admins && admins.length > 0) {
      if (marketManagerId) {
        const marketManager = admins.find(admin => admin.id === marketManagerId) || null;
        setMarketManager(marketManager);
      } else setMarketManager(null);
      if (accountManagerId) {
        const accountManager = admins.find(admin => admin.id === accountManagerId) || null;
        setAccountManager(accountManager);
      } else setAccountManager(null);
      if (salesRepId) {
        const salesRep = admins.find(admin => admin.id === salesRepId) || null;
        setSalesRep(salesRep);
      } else setSalesRep(null);
      if (arRepId) {
        const arRep = admins.find(admin => admin.id === arRepId) || null;
        setArRep(arRep);
      } else setArRep(null);
    }
  }, [admins, marketManagerId, accountManagerId, salesRepId, arRepId]);

  // Manage updates to the mutable ref when state is changed
  React.useEffect(() => {
    if (isReset) {
      setMarketManagerId(getDefaultMarketManagerId(null, inheritedRef));
      setAccountManagerId(getDefaultAccountManagerId(null, inheritedRef));
      setSalesRepId(getDefaultSalesRepId(null, inheritedRef));
      setArRepId(getDefaultArRepId(null, inheritedRef));
    }
  }, [isReset]);

  const handleMarketManagerChange = e => {
    const value = e?.target?.value;
    setMarketManager(value);
    setMarketManagerId(value?.id || 0);

    const changes = { config: { hopdrive_contacts: { market_manager: value?.id || 0 } } };
    updateMutableRef(changes);
  };

  const handleAccountManagerChange = e => {
    const value = e?.target?.value;
    setAccountManager(value);
    setAccountManagerId(value?.id || 0);

    const changes = { config: { hopdrive_contacts: { account_manager: value?.id || 0 } } };
    updateMutableRef(changes);
  };

  const handleSalesRepChange = e => {
    const value = e?.target?.value;
    setSalesRep(value);
    setSalesRepId(value?.id || 0);

    const changes = { config: { hopdrive_contacts: { sales_rep: value?.id || 0 } } };
    updateMutableRef(changes);
  };

  const handleArChange = e => {
    const value = e?.target?.value;
    setArRep(value);
    setArRepId(value?.id || 0);

    const changes = { config: { hopdrive_contacts: { ar_rep: value?.id || 0 } } };
    updateMutableRef(changes);
  };

  // Return component
  return (
    <div id={settingId} className={cls.root}>
      <SettingsTitle
        settingId={settingId}
        title={`Hopdrive Contacts`}
        tip={`This module allows us to set the contact information for the account manager, market manager, sales representative, and accounts receivable representative assigned to the customer's account.`}
      />

      <SettingsOption
        contained
        title={`Account Manager`}
        description={`The account manager assigned to this customer.`}
        value={accountManager}
        checkReset={() => checkMutableRefField(option?.accountManagerId)}
        onReset={() =>
          deleteMutableRefField(option?.accountManagerId, () =>
            setAccountManagerId(getPropValue(inheritedRef, option?.accountManagerId))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          select
          label='Select Account Manager'
          value={accountManager || ''}
          onChange={handleAccountManagerChange}
          variant='outlined'
          size='small'
          fullWidth
        >
          <MenuItem value={null}>None</MenuItem>
          {admins &&
            admins.map(item => (
              <MenuItem key={item.id} value={item}>
                {item.email}
              </MenuItem>
            ))}
        </TextField>

        {accountManager && (
          <>
            <Spacer />
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ mb: 1.5 }} gutterBottom className={cls.description}>
                  Contact info as it will appear to customers:
                </Typography>
                <Spacer />
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>NAME:</span> {accountManager.display_name || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>EMAIL:</span> {accountManager.email || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>PHONE:</span> {getCleansedPhoneNumber(accountManager.phone) || '–'}{' '}
                  <span className={cls.description}> (optional) </span>
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={cls.button} size='small' onClick={() => goToUserDetails(accountManager.id)}>
                  Update User Info
                </Button>
              </CardActions>
            </Card>
          </>
        )}
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Market Manager`}
        description={`The market manager assigned to the customer's region.`}
        value={marketManager}
        checkReset={() => checkMutableRefField(option?.marketManagerId)}
        onReset={() =>
          deleteMutableRefField(option?.marketManagerId, () =>
            setMarketManagerId(getPropValue(inheritedRef, option?.marketManagerId))
          )
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          select
          label='Select Market Manager'
          value={marketManager || ''}
          onChange={handleMarketManagerChange}
          variant='outlined'
          size='small'
          fullWidth
        >
          <MenuItem value={null}>None</MenuItem>
          {admins &&
            admins.map(item => (
              <MenuItem key={item.id} value={item}>
                {item.email}
              </MenuItem>
            ))}
        </TextField>

        {marketManager && (
          <>
            <Spacer />
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ mb: 1.5 }} gutterBottom className={cls.description}>
                  Contact info as it will appear to customers:
                </Typography>
                <Spacer />
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>NAME:</span> {marketManager.display_name || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>EMAIL:</span> {marketManager.email || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>PHONE:</span> {getCleansedPhoneNumber(marketManager.phone) || '–'}{' '}
                  <span className={cls.description}> (optional) </span>
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={cls.button} size='small' onClick={() => goToUserDetails(marketManager.id)}>
                  Update User Info
                </Button>
              </CardActions>
            </Card>
          </>
        )}
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Sales Representative`}
        description={`The customer's sales representative.`}
        value={salesRep}
        checkReset={() => checkMutableRefField(option?.salesRepId)}
        onReset={() =>
          deleteMutableRefField(option?.salesRepId, () => setSalesRepId(getPropValue(inheritedRef, option?.salesRepId)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          select
          label='Select Sales Representative'
          value={salesRep || ''}
          onChange={handleSalesRepChange}
          variant='outlined'
          size='small'
          fullWidth
        >
          <MenuItem value={null}>None</MenuItem>
          {admins &&
            admins.map(item => (
              <MenuItem key={item.id} value={item}>
                {item.email}
              </MenuItem>
            ))}
        </TextField>

        {salesRep && (
          <>
            <Spacer />
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ mb: 1.5 }} gutterBottom className={cls.description}>
                  Contact info as it will appear to customers:
                </Typography>
                <Spacer />
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>NAME:</span> {salesRep.display_name || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>EMAIL:</span> {salesRep.email || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>PHONE:</span> {getCleansedPhoneNumber(salesRep.phone) || '–'}{' '}
                  <span className={cls.description}> (optional) </span>
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={cls.button} size='small' onClick={() => goToUserDetails(salesRep.id)}>
                  Update User Info
                </Button>
              </CardActions>
            </Card>
          </>
        )}
      </SettingsOption>

      <Spacer />

      <SettingsOption
        contained
        title={`Accounts Receivable Representative`}
        description={`The customer's accounts receivable contact.`}
        value={arRep}
        checkReset={() => checkMutableRefField(option?.arRepId)}
        onReset={() =>
          deleteMutableRefField(option?.arRepId, () => setArRepId(getPropValue(inheritedRef, option?.arRepId)))
        }
        resetText={resetText}
        resetTooltip={resetTooltip}
        minWidth={360}
        maxWidth={360}
      >
        <TextField
          select
          label='Select AR Representative'
          value={arRep || ''}
          onChange={handleArChange}
          variant='outlined'
          size='small'
          fullWidth
        >
          <MenuItem value={null}>None</MenuItem>
          {admins &&
            admins.map(item => (
              <MenuItem key={item.id} value={item}>
                {item.email}
              </MenuItem>
            ))}
        </TextField>

        {arRep && (
          <>
            <Spacer />
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ mb: 1.5 }} gutterBottom className={cls.description}>
                  Contact info as it will appear to customers:
                </Typography>
                <Spacer />
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>NAME:</span> {arRep.display_name || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>EMAIL:</span> {arRep.email || '–'}
                </Typography>
                <Typography variant='body2'>
                  <span style={{ fontWeight: 500 }}>PHONE:</span> {getCleansedPhoneNumber(arRep.phone) || '–'}{' '}
                  <span className={cls.description}> (optional) </span>
                </Typography>
              </CardContent>
              <CardActions>
                <Button className={cls.button} size='small' onClick={() => goToUserDetails(arRep.id)}>
                  Update User Info
                </Button>
              </CardActions>
            </Card>
          </>
        )}
      </SettingsOption>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {},
  description: {
    lineHeight: 1.5,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  button: {
    color: '#f44232', // Red color
    '&:hover': {
      backgroundColor: 'rgba(244, 66, 50, 0.1)', // Opaque red color on hover
    },
  },
}));

//////////////////////// GQL ////////////////////////

const GET_ACTIVE_ADMINS = `
query get_active_admins {
    users(where: {_and: {active: {_eq: true}, default_role: {_eq: "admin"}, email: {_is_null: false}}}) {
      active
      default_role
      display_name
      email
      phone
      id
    }
  }
`;
