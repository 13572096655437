import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  InputAdornment,
  Grid,
  DialogContentText,
  Button,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
// import { DatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

import { ModalContent, ModalFooter, ModalHeader } from '../ModalComponents';

import { gql, useQuery } from '@apollo/client';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

let log = true;

export default function ProductFeeFormModal({
  editMode = false,
  rateRuleGroupId,
  productFee = null,
  onEditSave = unsavedProductFee => {},
  onAddSave,
  onCancel = () => {},
  handleRemoveProduct = unsavedProductFee => {},
}) {
  const cls = useStyles();
  const currentDay = dayjs().format();

  const [unsavedProductFee, setUnsavedProductFee] = useState(productFee);
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);
  // const [billableDate, setBillableDate] = useState(
  //   productFee && productFee.billable_date ? productFee.billable_date : currentDay
  // );

  const { loading, error, data } = useQuery(GET_PRODUCTS);

  useEffect(() => {
    if (data) {
      console.log('Products: ', data, data.products);
      setProducts(data.products);
    } else {
      setProducts([]);
    }
    if (error) {
      console.error('Error loading products: ', error);
      // toast.error()
    }
  }, [data, loading, error]);

  useEffect(() => {
    log && console.log('Inbound product fee prop set to: ', productFee);
    if (!productFee) return;
    setUnsavedProductFee(productFee);
  }, [productFee]);

  useEffect(() => {
    if (!unsavedProductFee) return;
    log && console.log('Unsaved product fee changed to: ', unsavedProductFee);
  }, [unsavedProductFee]);

  const handleProductChange = event => {
    if (event.target.value) handleProductSelect(event.target.value);
    else setSelectedProductId({});
  };

  const handleProductSelect = productId => {
    setSelectedProductId(productId);
    //Create selected productfee
    const selectedProduct = products.find(product => product.id === productId);
    if (selectedProduct) {
      const newProductFee = {
        hasChanges: true,
        id: null,
        active: true,
        product_id: productId,
        rate_rule_group_id: rateRuleGroupId,
        cost: selectedProduct.cost,
        charge: selectedProduct.cost,
        notes: null,
        //Products charged only once initially have a billable_date set to null.
        //Once paid the billable_date is set to the date of payment and the productfee is considered "expired"
        billable_date: selectedProduct.term === 'once' ? null : currentDay,
        product: selectedProduct,
      };
      console.log('creating new product fee', newProductFee);
      setUnsavedProductFee(newProductFee);
    } else {
      console.error('Error selecting product');
    }
  };

  const handleInputChange = name => event => {
    console.log('handleInputChange', unsavedProductFee, name, event.target.value);
    setUnsavedProductFee({ ...unsavedProductFee, [name]: event.target.value });
  };

  const formatProductName = string => {
    if (string) {
      if (!string.includes(`_`)) return string.charAt(0).toUpperCase() + string.slice(1);
      else {
        let arr = string.split(`_`);
        arr = arr.map(substring => substring.charAt(0).toUpperCase() + substring.slice(1));
        return arr.join(` `);
      }
    }
  };

  return (
    <>
      <ModalHeader id='product-fee-edit-modal' onClose={onCancel}>
        {editMode ? 'Customer Product Editor' : 'Add Product'}
      </ModalHeader>
      {/* <DialogContentText>Edit the Product for this Customer</DialogContentText> */}
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PRODUCT FEE FORM' />}>
        <ModalContent dividers>
          {!editMode && products ? (
            <Grid container spacing={4}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  select
                  className={cls.productSelector}
                  name='product'
                  margin='normal'
                  label={'Select Product'}
                  placeholder='Select a product...'
                  size='small'
                  variant='outlined'
                  value={selectedProductId}
                  onChange={handleProductChange}
                >
                  <MenuItem key={'product-0'} value={0}>
                    Select a product to add...
                  </MenuItem>
                  {products.map(product => (
                    <MenuItem key={product.name} value={product.id}>
                      {formatProductName(product.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {unsavedProductFee && unsavedProductFee.product && (
            <>
              {editMode ? (
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <Typography>
                      {unsavedProductFee.product
                        ? formatProductName(unsavedProductFee.product.name)
                        : 'missing product name'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid container>
                <Grid item md={12}>
                  <DialogContentText>
                    {unsavedProductFee.product ? unsavedProductFee.product.description : 'missing product description'}
                  </DialogContentText>
                </Grid>
                <div className={cls.lineBreak} />
                <Grid item md={4}>
                  <TextField
                    id='charge'
                    margin='normal'
                    value={unsavedProductFee.charge || 0}
                    onChange={handleInputChange('charge')}
                    variant='outlined'
                    className={cls.inputField}
                    style={{ width: 200 }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    label={'Price'}
                    size='small'
                  ></TextField>
                </Grid>
                <Grid item md={8}>
                  <Typography className={cls.inputInfo}>
                    The amount the customer will be charged for the product. It can be adjusted higher or lower if
                    needed.
                  </Typography>
                </Grid>
                {/* We changed how billable date is implemented - it will no longer be set by the user */}
                {/* {unsavedProductFee.product && unsavedProductFee.product.term === 'once' ? (
                  <>
                    <Grid item md={4}>
                      <DatePicker
                        autoOk
                        orientation='portrait'
                        format='MM/dd/yyyy'
                        label='Charge Date'
                        margin='dense'
                        inputVariant='outlined'
                        value={unsavedProductFee.billable_date || currentDay}
                        onChange={handleInputChange}
                        className={cls.date}
                        style={{ width: 200 }}
                        // minDate={currentDay}
                        // style={{backgroundColor: '#ffe9ec' }}
                      />
                    </Grid>
                    <Grid item md={8} className={cls.inputInfo}>
                      <Typography>Select the date the customer will be charged for this one-time fee.</Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )} */}
                <Grid item md={4}>
                  <TextField
                    label='Notes'
                    margin='normal'
                    variant='outlined'
                    minRows={2}
                    placeholder='Add Notes (optional)'
                    value={unsavedProductFee.notes || undefined}
                    onChange={handleInputChange('notes')}
                    className={cls.inputField}
                    multiline
                    // helperText='For Internal Use Only'
                  ></TextField>
                </Grid>
                <Grid container item md={8} className={cls.inputInfo}>
                  <Typography>
                    Notes can be added to the product, if needed. For example, if the price was lowered or raised- that
                    adjustment can be explained here.
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography style={{ textAlign: 'center' }}>
                    This product will be charged to the customer:
                    {unsavedProductFee.product ? ' ' + unsavedProductFee.product.term : ' missing product term'}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {!unsavedProductFee && (
            <>
              <DialogContentText>No product provided to edit</DialogContentText>
            </>
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            onClick={() => {
              handleRemoveProduct(unsavedProductFee);
              setSelectedProductId(0);
            }}
            color='primary'
          >
            Remove Product
          </Button>
          <Button
            onClick={() => {
              onCancel();
              setSelectedProductId(0);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              editMode ? onEditSave(unsavedProductFee) : onAddSave(unsavedProductFee);
            }}
            color='primary'
            //disabled if product fee not selected from dropdown
            disabled={selectedProductId?false:true}
          >
            Save
          </Button>
        </ModalFooter>
      </Sentry.ErrorBoundary>
    </>
  );
}

const GET_PRODUCTS = gql`
  query get_products {
    products(where: { active: { _eq: true }, salable: { _eq: true } }) {
      id
      name
      description
      cost
      prepaid
      salable
      term
      createdBy
      created_at
      updated_at
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // padding: theme.spacing(2),
    width: 800,
    height: 'fitContent',
  },
  productSelector: {
    minWidth: 400,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 600,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '4px 0',
    background: theme.palette.divider,
  },
  inputInfo: {
    padding: 20,
  },
  inputField: {
    width: 200,
    margin: 20,
  },
  date: {
    width: 200,
    margin: 20,
  },
}));
