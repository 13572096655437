//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';

import { makeStyles, Container, Grid } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import { GET_INSURANCE_RATE_DETAILS } from './gql';

import * as Sentry from '@sentry/react';

import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';

import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { InsuranceRateBanner, InsuranceRateInfoPanel, InsuranceRateRuleGroupList } from './index';

//////////////////////// COMPONENT ////////////////////////

function InsuranceRateDetails(props) {
  const cls = useStyles();

  const insurancerateId = getPropValue(props, 'match.params.id');

  const { loading, error, data } = useQuery(GET_INSURANCE_RATE_DETAILS, { variables: { id: insurancerateId } });

  // LOADING STATE //
  if (loading) {
    return <Loading fixed />;
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting InsuranceRate details:`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR GETTING INSURANCE RATE DETAILS' />;
  }

  // EMPTY STATE //
  if (!data || !data.insurancerates || !data.insurancerates.length > 0) {
    return <DefaultEmptyFallback message='NO INSURANCE RATE FOUND' />;
  }

  // DATA STATE //
  const insuranceRate = data.insurancerates[0];
  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <InsuranceRateBanner/>

        <div className={cls.break_md} />

        <InsuranceRateInfoPanel insuranceRate={insuranceRate} />

        <Grid item xs={12}>
            <Divide spacer tip='The Rate Rule Groups (Customer Contracts) Associated with this Insurance Rate.'>
              Associated Customer Contracts
            </Divide>
            <div className={cls.break_md} />
            <InsuranceRateRuleGroupList insuranceRate={insuranceRate} />
        </Grid>
      </Container>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  break_md: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default InsuranceRateDetails;