//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography, Icon, Tooltip } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerDetailsSettingsToolbar({ isReset, isSaved, isSaving, onReset, onSave }) {
  const cls = useStyles();

  return (
    <div className={cls.toolbar}>
      <div className={cls.header}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography className={cls.headerTxt}>Customer Settings</Typography>
          </Grid>

          <Grid item xs />

          <Grid item>
            <Tooltip title='Warning: This will restore all settings to organization defaults!'>
              <Button
                disabled={isReset || isSaving}
                loading={isSaving}
                color='default'
                colorTint='light'
                size='large'
                onClick={onReset}
              >
                <Icon className={cls.btnIcon}>replay</Icon>
                Restore All Defaults
              </Button>
            </Tooltip>
          </Grid>

          <Grid item>
            <Spacer column size={1} />
          </Grid>

          <Grid item>
            <Button disabled={isSaved || isSaving} loading={isSaving} color='primary' size='large' onClick={onSave}>
              <Icon className={cls.btnIcon}>save</Icon>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className={cls.fade} />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  toolbar: {
    zIndex: 10,
    position: 'sticky',
    top: 0,
    left: 0,
  },
  header: {
    padding: theme.spacing(2, 2, 0, 2),
    backgroundColor: theme.palette.background.paper,
  },
  headerTxt: {
    fontSize: 21,
    fontWeight: 600,
  },
  fade: {
    width: '100%',
    height: theme.spacing(2),
    background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
  },
  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 18,
  },
}));
