//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { useTools } from '../../../hooks/useTools'

//////////////////////// COMPONENT ////////////////////////

function AllowedCustomersTableRow(props) {
  const { row, index } = props;
  const { goToCustomerDetails } = useTools();

  const cls = useStyles();
  

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.ID || `-`,
      align: `left`,
    },
    {
      value: row.CUSTOMER || `-`,
      align: `left`,
    },
  ];

  return (
    <div className={`MuiTableRow-root  ${index % 2 ? cls.rowEven : cls.rowOdd}`} onClick={() => {goToCustomerDetails(row.ID)}}>
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${row.ID}-col-${i}`}
                    align={cell.align || `left`}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));

//////////////////////// EXPORT ////////////////////////

export default AllowedCustomersTableRow;