//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

function MoveAP(props) {
  const cls = useStyles();
  const { capFirst, clampNegNum } = useTools();

  const { move } = props;

  // Rob 2022-07-22 - Expecting the passed in move object to contain the following properties
  /*
      driverPayRate {
        distance_miles
        final_source
        final_driver_base_pay
        final_driver_return_pay
        region_source
        appliedRegion {
          name
        }
        pay_rate_group_type
        appliedPayRateGroup {
          name
          description
        }
        appliedPayRateRule {
          id
          distance_start
          distance_end
          per_mile_rate
          per_minute_rate
        }
      }
  */

  const { driverPayRate = {} } = move;

  const { region_source, appliedRegion, appliedPayRateGroup, appliedPayRateRule } = driverPayRate;

  const appayments = move.appayments || [];
  const paidPayments = appayments && appayments.length > 0 ? appayments.filter(ap => ap.status === `paid`) : [];

  let thisMoveLineItems =
    appayments && appayments.length > 0
      ? appayments.map(ap => {
          let name = `Unknown Key`;
          let notes = `N/A`;
          let amount = ap.amount;

          if (ap.type === `move pay`) {
            if (ap.move.move_type === `drive`) {
              name = ` Move Pay - Drive`;
              notes = `Standard pay rate for transporting the vehicle`;
            } else {
              name = ` Move Pay - Ride`;
              notes = `Standard pay rate for the ride back`;
            }
          } else if (ap.type === `accessorial`) {
            name = `Accessorial - ${capFirst(ap.accessorial.code)}`;
            notes = capFirst(ap.accessorial.notes);
          }

          if (ap.amount < 0) amount = `($${Math.abs(amount).toFixed(2)})`;
          else amount = `$${amount.toFixed(2)}`;

          return { name: name, notes: notes, amount: amount };
        })
      : [];

  thisMoveLineItems = thisMoveLineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const currentMoveTotal =
    appayments && appayments.length > 0
      ? appayments.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;

  const currentMovePaid =
    paidPayments && paidPayments.length > 0
      ? paidPayments.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;

  let childMovePay = [];
  let relatedMoveTotal;
  let relatedMovePaid;
  let childPaidPayments;

  if (move.childMoves && move.childMoves.length > 0) {
    move.childMoves.forEach(cm => {
      if (cm.move_type === 'ride' && cm.active && cm.appayments && cm.appayments.length > 0) {
        childMovePay.push(...cm.appayments);
      }
    });
  }

  let relatedMoveLineItems =
    childMovePay && childMovePay.length > 0
      ? childMovePay.map(ap => {
          let name = `Unknown Key`;
          let notes = `N/A`;
          let amount = ap.amount;

          if (ap.type === `move pay`) {
            name = ` Related Move Pay - Ride`;
            notes = `Standard pay rate for the ride back`;
          } else if (ap.type === `accessorial`) {
            name = `Related Move Accessorial - ${capFirst(ap.accessorial.code)}`;
            notes = capFirst(ap.accessorial.notes);
          }

          if (ap.amount < 0) amount = `($${Math.abs(amount).toFixed(2)})`;
          else amount = `$${amount.toFixed(2)}`;

          return { name: name, notes: notes, amount: amount };
        })
      : [];

  relatedMoveLineItems = relatedMoveLineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  childPaidPayments = childMovePay && childMovePay.length > 0 ? childMovePay.filter(ap => ap.status === `paid`) : [];

  relatedMovePaid =
    childPaidPayments && childPaidPayments.length > 0
      ? childPaidPayments.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;

  relatedMoveTotal =
    childMovePay && childMovePay.length > 0
      ? childMovePay.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;

  let lineItems = thisMoveLineItems.concat(relatedMoveLineItems);

  let total = relatedMoveTotal + currentMoveTotal;
  let paid = relatedMovePaid + currentMovePaid;
  let due = total - paid;

  return (
    <>
      {appayments && appayments.length > 0 ? (
        <div className={cls.paper}>
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
            <Grid item xs>
              <Typography className={cls.headTxtL}>Driver Pay</Typography>
            </Grid>
            <Grid item>
              <Tooltip placement='top-end' title='Driver'>
                <Typography className={cls.headTxtR}>
                  {move?.driver_name || `HopDriver`}
                  {move?.driver_id ? ` #${move?.driver_id}` : ``}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <div className={cls.break} />
          <div className={cls.block}>
            <Typography className={cls.detailNameTxt}>
              <strong></strong> {appliedPayRateGroup?.name}
            </Typography>
            <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs>
                <Typography className={cls.detailNotesTxt}>{appliedPayRateGroup?.description}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs>
                <Typography className={cls.detailNotesTxt}>
                  {appliedRegion?.id} - <strong>{appliedRegion?.name}</strong>{' '}
                  <em>(region sourced from {region_source})</em>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs>
                <Typography className={cls.detailNotesTxt}>
                  <Chip
                    style={{}}
                    variant={'outlined'}
                    size='small'
                    color={'secondary'}
                    label={driverPayRate?.final_source}
                  />
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography className={cls.detailNotesTxt}>
                  <Chip
                    style={{}}
                    size='small'
                    color={'secondary'}
                    label={`Mileage band ${appliedPayRateRule?.distance_start}-${appliedPayRateRule?.distance_end} mi`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={cls.detailAmountTxt}>
                  <Chip
                    style={{}}
                    size='small'
                    color={'primary'}
                    label={`$${appliedPayRateRule?.per_minute_rate || '0.00'} min | $${
                      appliedPayRateRule?.per_mile_rate || '0.00'
                    } mi`}
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={cls.lineBreak} />

          {lineItems.length > 0 &&
            lineItems.map((item, i) => (
              <React.Fragment key={`payment-detail-${i}`}>
                <div className={cls.block}>
                  <Typography className={cls.detailNameTxt}>{item.name}</Typography>
                  <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Typography className={cls.detailNotesTxt}>{item.notes}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={cls.detailAmountTxt}>{item.amount}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={cls.lineBreak} />
              </React.Fragment>
            ))}

          {lineItems.length > 0 ? <div className={cls.break} /> : null}
          <Grid container spacing={0} alignItems='flex-start' wrap='nowrap' style={{ justifyContent: 'right' }}>
            <Grid item>
              <Typography className={cls.key}>Total:&nbsp;&nbsp;&nbsp;</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.key}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.key}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
            </Grid>
            <Grid item>
              <Typography className={cls.val}>${clampNegNum(total).toFixed(2)}</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.val}>${clampNegNum(paid).toFixed(2)}</Typography>
              <div className={cls.smallBreak} />
              <Typography className={cls.val}>${clampNegNum(due).toFixed(2)}</Typography>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO AP RECORD FOUND</Typography>
        </div>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  key: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  val: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  block: {
    display: 'block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: '#e0e0e0',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveAP;
