import React from 'react';
import { Chip } from '@material-ui/core';

export default function DriverAppVersionChip({ version }) {
  return (
    <Chip
      label={`${
        version && version.startsWith('1')
          ? 'Tookan'
          : version && !version.startsWith('1')
          ? `v${version}`
          : `-`
      }`}
      size={'small'}
      color={version && version.startsWith('1') ? 'secondary' : `primary`}
    />
  );
}
