import React from 'react';
import { getUserToken } from '../../utils/authHelper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
const { EVENTS_SD } = require('../../utils/env');

const AppDumpDownload = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const bucketname = searchParams.get('bucketname');
  const filepath = searchParams.get('filepath');
  const filename = searchParams.get('filename');
  React.useEffect(() => {
    const eventsURL = `https://${EVENTS_SD}.hopdrive.io/.netlify/functions/get-s3-signed-redirect/?bucketname=${bucketname}&filepath=${filepath}&filename=${filename}`;
    const callRedirectWithAuthHeader = async () => {
      const token = await getUserToken();
      try {
        const response = await axios({
          method: 'get',
          url: eventsURL,
          headers: {
            authorization: 'Bearer ' + token,
          },
        });
        if (response?.data?.url) {
          window.open(response?.data?.url);
        } else {
          console.log('No presigned URL returned from function', response);
          toast.error('No App Dump URL returned');
        }
      } catch (e) {
        console.error('Error getting URL to app dump: ', e);
        toast.error('Error Downloading App Dump');
      }
    };
    callRedirectWithAuthHeader();
  }, [filepath, filename, bucketname]);
};

export default AppDumpDownload;
