//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles, Grid, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { useTools } from '../hooks/useTools';

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

//////////////////////// COMPONENT ////////////////////////

export default function ImageCarousel({ size = `sm`, images }) {
  const cls = useStyles();
  const { condensedCase } = useTools();

  const [filteredImages, setFilteredImages] = React.useState([]);
  const [picIndex, setPicIndex] = React.useState(0);
  const [imageFilter, setImageFilter] = React.useState(null);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  const galleryRef = React.useRef(null);

  React.useEffect(() => {
    setFilteredImages(images || []);
    setPicIndex(0);
    setImageFilter(null);
  }, [images]);

  const getImageTitle = (index = 0) => {
    const imageTitle = getPropValue(images[index], `label`) || null;
    return imageTitle;
  };

  const getMainSrc = (index = 0) => {
    const mainSrc = getPropValue(images[index], `original`) || null;
    return mainSrc;
  };

  const getPrevSrc = (index = 0) => {
    const prevSrc = getPropValue(images[(index + images.length - 1) % images.length], `original`) || null;
    return prevSrc;
  };

  const getNextSrc = (index = 0) => {
    const nextSrc = getPropValue(images[(index + 1) % images.length], `original`) || null;
    return nextSrc;
  };

  const handleFilterChange = (str = null) => {
    let newImages = [...images];

    if (newImages && newImages.length > 0) {
      if (str === `exterior`)
        newImages = newImages.filter(image => {
          if (
            condensedCase(image.label).includes(`exterior`) ||
            condensedCase(image.label).includes(`driver`) ||
            condensedCase(image.label).includes(`passenger`) ||
            condensedCase(image.label).includes(`roof`)
          )
            return true;
          return false;
        });
      if (str === `interior`)
        newImages = newImages.filter(image => {
          if (
            condensedCase(image.label).includes(`interior`) ||
            condensedCase(image.label).includes(`instrument`) ||
            condensedCase(image.label).includes(`trunk`) ||
            condensedCase(image.label).includes(`weight`) ||
            condensedCase(image.label).includes(`windshield`) ||
            condensedCase(image.label).includes(`vin`)
          )
            return true;
          return false;
        });
      if (str === `wheel`) newImages = newImages.filter(image => condensedCase(image.label).includes(`wheel`));
      if (str === `damage`) newImages = newImages.filter(image => condensedCase(image.label).includes(`damage`));

      setFilteredImages(newImages);
      setPicIndex(0);
      setImageFilter(str);
    }
    return newImages;
  };

  const handleFullscreen = event => {
    let currentIndex = 0;
    if (galleryRef) currentIndex = galleryRef.current.state.currentIndex;
    if (filteredImages && filteredImages.length > 0) {
      setPicIndex(currentIndex);
      setLightboxOpen(!lightboxOpen);
    }
  };

  return (
    <>
      <div className={cls.gallery}>
        <Grid container spacing={1} justifyContent='space-between'>
          <Grid item xs>
            <Button
              fullWidth
              color='secondary'
              variant={!imageFilter ? `contained` : `outlined`}
              onClick={() => handleFilterChange()}
            >
              {size === `sm` ? `All` : `Virtual Walkaround`}
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `exterior` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`exterior`)}
            >
              Exterior
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `interior` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`interior`)}
            >
              Interior
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `wheel` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`wheel`)}
            >
              Wheel
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `damage` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`damage`)}
            >
              Damage
            </Button>
          </Grid>
        </Grid>

        <Spacer />

        {filteredImages.length > 0 ? (
          <div className={cls.carousel}>
            <Gallery
              ref={galleryRef}
              items={filteredImages}
              thumbnailPosition='left'
              lazyLoad
              showIndex
              disableKeyDown
              startIndex={picIndex}
              slideDuration={250}
              showFullscreenButton={false}
              onClick={handleFullscreen}
            />
            <Icon className={cls.fullscreenIcon} onClick={handleFullscreen}>
              fullscreen
            </Icon>
          </div>
        ) : (
          <>
            <Typography className={cls.notFoundTxt}>NO IMAGES FOUND</Typography>
          </>
        )}
      </div>

      {lightboxOpen && filteredImages.length > 0 ? (
        <Lightbox
          imageTitle={getImageTitle(picIndex)}
          imageCaption={
            filteredImages[picIndex].label !== filteredImages[picIndex].description
              ? filteredImages[picIndex].description
              : null
          }
          mainSrc={getMainSrc(picIndex)}
          prevSrc={getPrevSrc(picIndex)}
          nextSrc={getNextSrc(picIndex)}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setPicIndex((picIndex + filteredImages.length - 1) % filteredImages.length)}
          onMoveNextRequest={() => setPicIndex((picIndex + 1) % filteredImages.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 1000000,
            },
            content: {
              zIndex: 1000000,
            },
          }}
        />
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  gallery: {
    position: 'relative',
  },
  carousel: {
    position: 'relative',
  },
  fullscreenIcon: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: theme.palette.text.contrast,
    filter: 'drop-shadow(2px 2px 2px #00000080)',
    fontSize: 48,
    '&:hover, &:active': {
      color: theme.palette.info.main,
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
