import { React, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import MoveCalculatorOutput from './MoveCalculatorOutput';
import { useData } from '../../providers/DataProvider';
import { useEngine } from '../../hooks/useEngine';
import { DefaultErrorFallback } from '../../components/Fallbacks';
import Loading from '../../components/Loading';

var log = false;

function OutputWrapper(props) {
  const { customerId, pickup, delivery, regionId } = props;

  const { apolloClient } = useData();
  const { buildBackgroundLanesFromAddresses, buildBackgroundLanesFromLocations } = useEngine();

  const [lane, setLane] = useState(null);
  const [loadingLane, setLoadingLane] = useState(true);

  const { loading, error, data } = useQuery(GET_RATES, {
    variables: {
      customer_id: customerId,
      region_id: regionId || 0,
    },
  });

  //UseEffect to get lane data from database or build the lane if it does not exist
  useEffect(() => {
    //Query Lane from Locations
    const fetchLaneFromLocations = async (pickupId, deliveryId) => {
      let lanesRes = await apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: {
          pickupId: pickupId,
          deliveryId: deliveryId,
        },
        fetchPolicy: `network-only`,
      });
      if (lanesRes.data && lanesRes.data.lanes && lanesRes.data.lanes.length > 0) {
        //If query returns a lane, return it
        return lanesRes.data.lanes[0];
      } else {
        return null;
      }
    };
    //Query Lane from Addresses
    const fetchLaneFromAddresses = async (pickupAddress, deliveryAddress) => {
      let lanesRes = await apolloClient.query({
        query: GET_LANES_BY_ADDRESSES,
        variables: {
          pickupAddress: pickupAddress,
          deliveryAddress: deliveryAddress,
        },
        fetchPolicy: `network-only`,
      });
      if (lanesRes.data && lanesRes.data.lanes && lanesRes.data.lanes.length > 0) {
        //If query returns a lane, return it
        return lanesRes.data.lanes[0];
      } else {
        return null;
      }
    };

    //Query existing lane or build lane from existing locations
    const fetchOrBuildLane = async (customerId, pickup, delivery) => {
      if (pickup.id && delivery.id) {
        let fetchedLane = await fetchLaneFromLocations(pickup.id, delivery.id);
        log && console.log("Lane Fetch Attempt:", fetchedLane);
        if (fetchedLane) {
          //If query returns a lane, set it in state
          setLane(fetchedLane);
          setLoadingLane(false);
        } else {
          log && console.log("Building Lane from Locations");
          await buildBackgroundLanesFromLocations(
            customerId,
            pickup.id,
            pickup.address,
            pickup.name,
            delivery.id,
            delivery.address,
            delivery.name
          );

          // Background function no longer returns built lane. Instead of using return value, wait for back end to build and try to fetch again
          let newFetchedLane;
          for (let i = 0; i < 5; i++) {
            await new Promise(resolve => {
              log && console.log('Waiting for background function to complete...');
              setTimeout(resolve, 2000);
            });

            newFetchedLane = await fetchLaneFromLocations(pickup.id, delivery.id);
            log && console.log(`Lane Fetch Retry ${i + 1}:`, newFetchedLane);

            if (newFetchedLane) {
              //If query returns a lane, set it in state and break loop. If not, retry up to 5 times
              setLane(newFetchedLane);
              setLoadingLane(false);
              break;
            }
          }
          if (!newFetchedLane) {
            //If no lane is fetched after 5 loops (10 seconds), set lane to null and loading to false
            setLane(null);
            setLoadingLane(false);
          }
        }
      } else if (pickup.address && delivery.address) {
        log && console.log("Building Lane from Addresses");
        await buildBackgroundLanesFromAddresses(
          customerId,
          pickup.address,
          pickup.name,
          delivery.address,
          delivery.name
        );

        // Background function no longer returns built lane. Instead of using return value, wait for back end to build and try to fetch again
        let newFetchedLane;
        for (let i = 0; i < 5; i++) {
          await new Promise(resolve => {
            log && console.log('Waiting for background function to complete...');
            setTimeout(resolve, 2000);
          });

          newFetchedLane = await fetchLaneFromAddresses(pickup.address, delivery.address);
          log && console.log(`Lane Fetch Retry ${i + 1}:`, newFetchedLane);

          if (newFetchedLane) {
            //If query returns a lane, set it in state and break loop. If not, retry up to 5 times
            setLane(newFetchedLane);
            setLoadingLane(false);
            break;
          }
        }
        if (!newFetchedLane) {
          //If no lane is fetched after 5 loops (10 seconds), set lane to null and loading to false
          setLane(null);
          setLoadingLane(false);
        }
      }
    };
    //Call function
    setLoadingLane(true);
    fetchOrBuildLane(customerId, pickup, delivery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup, delivery]);

  if (loadingLane || loading) {
    return <Loading />;
  } else if (lane === null) {
    return <DefaultErrorFallback message='Error: No Lane' />;
  } else if (lane && (data || error)) {
    return <MoveCalculatorOutput lane={lane} customerId={customerId} regionId={regionId} ratesData={data} />;
  } else {
    return <DefaultErrorFallback message='Error: No Rate Data Found' />;
  }
}
const GET_LANES_BY_LOCATIONS = gql`
  query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
    lanes(
      where: { origin_location_id: { _eq: $pickupId }, destination_location_id: { _eq: $deliveryId } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      id
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      customer {
        raterulegroups(where: { _and: { begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } } }) {
          ridesharerate {
            description
            effective_date
            expiration_date
            id
            initial_cost
            maximum_ride_cost
            minimum_ride_cost
            name
            per_mile_rate
            per_minute_rate
          }
        }
      }
    }
  }
`;

const GET_LANES_BY_ADDRESSES = gql`
  query get_lanes_by_addresses($pickupAddress: String!, $deliveryAddress: String!) {
    lanes(
      where: { pickup: { address: { _eq: $pickupAddress } }, delivery: { address: { _eq: $deliveryAddress } } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      id
      average_drive_speed_min_per_mile
      average_drive_speed_mph
      dealer_base_discount
      dealer_base_price
      dealer_base_rate
      dealer_base_rate_type
      dealer_stranded_discount
      dealer_stranded_price
      dealer_stranded_rate
      dealer_stranded_rate_type
      delivery_inspection_sec
      destination_location_id
      distance_miles
      driver_base_pay
      driver_base_pay_discount
      driver_drive_pay
      driver_pay_per_kilometer
      driver_pay_per_mile
      driver_pay_per_minute
      driver_rake
      driver_return_pay
      driver_return_pay_discount
      driver_time_pay
      duration_sec
      estimated_rideshare_return_cost
      insurance_cost
      insurance_cost_per_mile
      origin_location_id
      pickup_inspection_sec
      return_ride_wait_sec
      tolls
      customer {
        raterulegroups(where: { _and: { begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } } }) {
          ridesharerate {
            description
            effective_date
            expiration_date
            id
            initial_cost
            maximum_ride_cost
            minimum_ride_cost
            name
            per_mile_rate
            per_minute_rate
          }
        }
      }
    }
  }
`;

const GET_RATES = gql`
  query get_rates($customer_id: bigint!, $region_id: bigint) {
    raterules(where: { customer_id: { _eq: $customer_id } }, order_by: { distance_end: asc }) {
      active
      class
      createdat
      customer_id
      distance_end
      distance_start
      id
      pay_rate_group_id
      rate
      rate_rule_group_id
      type
      updatedat
    }
    payrategroups(
      where: {
        end_date: { _gte: "now()" }
        active: { _eq: true }
        _or: [
          { customer_id: { _eq: $customer_id } }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _eq: $region_id } }] }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _is_null: true } }] }
        ]
      }
    ) {
      name
      description
      begin_date
      end_date
      payraterules {
        id
        distance_end
        distance_start
        per_mile_rate
        per_minute_rate
      }
      customer_id
      region_id
    }
  }
`;

export default OutputWrapper;
