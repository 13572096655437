//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, TextField, InputAdornment, Icon } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function CustomerUsersFilter({ handleAddUser, search, onSearchChange }) {
  const cls = useStyles();

  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      {onSearchChange ? (
        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}

      {handleAddUser ? (
        <Grid item>
          <Button color='primary' size='large' onClick={() => handleAddUser()}>
            <Icon className={cls.addIcon}>add_circle</Icon>
            Add User
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  addIcon: {
    marginTop: -2,
    marginBottom: -2,
    marginRight: 10,
    fontSize: 20,
  },
}));
