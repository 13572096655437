//////////////////////// DEPENDENCIES ////////////////////////
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../../components/TableComponents';
import dayjs from 'dayjs';

export default function ProductsTable(props) {
  const cls = useStyles();

  const { products } = props;

  const columnConfig = [
    {
      name: `id`,
      value: product => product.id, // product.id is the value for this column
      align: `left`,
      label: `Id`,
    },
    {
      name: `name`,
      value: product => product.name,
      align: `left`,
      label: `Name`,
      tooltip: `Name of the Product`,
      sortable: true,
    },
    {
      name: `cost`,
      value: product => product.cost,
      align: `left`,
      label: `Cost`,
      tooltip: `Cost of Product`,
      sortable: true,
    },
    {
      name: `description`,
      value: product => product.description,
      align: `left`,
      label: `Description`,
      tooltip: `Description of the Product`,
      sortable: true,
    },
    {
      name: `term`,
      value: product => product.term,
      align: `left`,
      label: `Term`,
      tooltip: `How often product is charged to customer`,
      sortable: true,
    },
    {
      name: `createdby`,
      value: product => product.createdBy,
      align: `right`,
      label: `Created By`,
      sortable: true,
      style: {minWidth: 140}
    },
    {
      name: `createdat`,
      value: product => product.created_at,
      renderer: product => dayjs(product.created_at).format("MM/DD/YYYY"),
      align: `right`,
      label: `Date Created`,
      sortable: true,
      style: {minWidth: 140}
    },
  ];

  return (
    <div className={cls.paper}>
      <Table
        data={products}
        columnConfig={columnConfig}
        // rowActions={rowActions}
        defaultOrderBy={'id'}
        defaultOrder={'asc'}
        tableAriaLabel={`products`}
        stickyHeader={true}
        // classes={ }  // Override or extend the styles applied to the component. See CSS API below for more details.
      />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
}));