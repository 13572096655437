//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useSettings } from '../providers/SettingsProvider';
import { TimelineAreaMarker } from '../index';

const hourTimes = [
  `12\xa0AM`,
  `1\xa0AM`,
  `2\xa0AM`,
  `3\xa0AM`,
  `4\xa0AM`,
  `5\xa0AM`,
  `6\xa0AM`,
  `7\xa0AM`,
  `8\xa0AM`,
  `9\xa0AM`,
  `10\xa0AM`,
  `11\xa0AM`,
  `12\xa0PM`,
  `1\xa0PM`,
  `2\xa0PM`,
  `3\xa0PM`,
  `4\xa0PM`,
  `5\xa0PM`,
  `6\xa0PM`,
  `7\xa0PM`,
  `8\xa0PM`,
  `9\xa0PM`,
  `10\xa0PM`,
  `11\xa0PM`,
  `12\xa0AM`,
];
const quarterAfterTimes = [
  `12:15\xa0AM`,
  `1:15\xa0AM`,
  `2:15\xa0AM`,
  `3:15\xa0AM`,
  `4:15\xa0AM`,
  `5:15\xa0AM`,
  `6:15\xa0AM`,
  `7:15\xa0AM`,
  `8:15\xa0AM`,
  `9:15\xa0AM`,
  `10:15\xa0AM`,
  `11:15\xa0AM`,
  `12:15\xa0PM`,
  `1:15\xa0PM`,
  `2:15\xa0PM`,
  `3:15\xa0PM`,
  `4:15\xa0PM`,
  `5:15\xa0PM`,
  `6:15\xa0PM`,
  `7:15\xa0PM`,
  `8:15\xa0PM`,
  `9:15\xa0PM`,
  `10:15\xa0PM`,
  `11:15\xa0PM`,
];
const halfHourTimes = [
  `12:30\xa0AM`,
  `1:30\xa0AM`,
  `2:30\xa0AM`,
  `3:30\xa0AM`,
  `4:30\xa0AM`,
  `5:30\xa0AM`,
  `6:30\xa0AM`,
  `7:30\xa0AM`,
  `8:30\xa0AM`,
  `9:30\xa0AM`,
  `10:30\xa0AM`,
  `11:30\xa0AM`,
  `12:30\xa0PM`,
  `1:30\xa0PM`,
  `2:30\xa0PM`,
  `3:30\xa0PM`,
  `4:30\xa0PM`,
  `5:30\xa0PM`,
  `6:30\xa0PM`,
  `7:30\xa0PM`,
  `8:30\xa0PM`,
  `9:30\xa0PM`,
  `10:30\xa0PM`,
  `11:30\xa0PM`,
];
const quarterBeforeTimes = [
  `12:45\xa0AM`,
  `1:45\xa0AM`,
  `2:45\xa0AM`,
  `3:45\xa0AM`,
  `4:45\xa0AM`,
  `5:45\xa0AM`,
  `6:45\xa0AM`,
  `7:45\xa0AM`,
  `8:45\xa0AM`,
  `9:45\xa0AM`,
  `10:45\xa0AM`,
  `11:45\xa0AM`,
  `12:45\xa0PM`,
  `1:45\xa0PM`,
  `2:45\xa0PM`,
  `3:45\xa0PM`,
  `4:45\xa0PM`,
  `5:45\xa0PM`,
  `6:45\xa0PM`,
  `7:45\xa0PM`,
  `8:45\xa0PM`,
  `9:45\xa0PM`,
  `10:45\xa0PM`,
  `11:45\xa0PM`,
];

//////////////////////// COMPONENT ////////////////////////

export default function TimelineAreaHead(props) {
  const { timelineScale, timelineMult } = useSettings();

  const useStyles = makeStyles(theme => ({
    areaHead: {
      zIndex: 102,
      position: 'sticky',
      top: 0,
      width: 'max-content',
      minHeight: 48,
      maxHeight: 48,
      background: theme.palette.background.paper,
      boxShadow: theme.shadow.medium,
    },
    hourBlock: {
      position: 'relative',
    },
    hourTickFull: {
      verticalAlign: 'top',
      width: 0,
      height: 24,
      borderLeft: theme.border[0],
      marginRight: 5 * timelineMult * timelineScale - 1, // minutes * constant mult * scale - 1px for line width
    },
    hourTickHalf: {
      verticalAlign: 'top',
      width: 0,
      height: 18,
      borderLeft: theme.border[0],
      marginRight: 5 * timelineMult * timelineScale - 1, // minutes * constant mult * scale - 1px for line width
    },
    hourTickQuarter: {
      verticalAlign: 'top',
      width: 0,
      height: 12,
      borderLeft: theme.border[0],
      marginRight: 5 * timelineMult * timelineScale - 1, // minutes * constant mult * scale - 1px for line width
    },
    hourTickTwelth: {
      verticalAlign: 'top',
      width: 0,
      height: 6,
      borderLeft: theme.border[0],
      marginRight: 5 * timelineMult * timelineScale - 1, // minutes * constant mult * scale - 1px for line width
    },
    hourTickFinal: {
      verticalAlign: 'top',
      width: 0,
      height: 24,
    },
    hourLabelStart: {
      position: 'absolute',
      left: 0,
    },
    hourLabel: {
      position: 'absolute',
      left: 0,
      transform: 'translateX(-50%)',
    },
    hourLabelEnd: {
      position: 'absolute',
      left: 0,
      transform: 'translateX(-100%)',
    },
    hourLabelTxt: {
      marginTop: theme.spacing(0.75),
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.text.disabled,
    },
    halfLabelTxt: {
      marginTop: theme.spacing(1.5),
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 500,
      color: `${theme.palette.text.primary}48`,
    },
    quarterLabelTxt: {
      marginTop: theme.spacing(2.25),
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 500,
      color: `${theme.palette.text.primary}36`,
    },
  }));
  const cls = useStyles();

  const HourFull = ({ index, hourTime }) => {
    if (hourTime)
      return (
        <Grid item className={cls.hourBlock}>
          <Grid container alignItems='flex-start' wrap='nowrap'>
            {index !== hourTimes.length - 1 ? (
              <>
                <Grid item>
                  <div className={cls.hourTickFull} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickQuarter} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickHalf} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickQuarter} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
                <Grid item>
                  <div className={cls.hourTickTwelth} />
                </Grid>
              </>
            ) : (
              <Grid item>
                <div className={cls.hourTickFinal} />
              </Grid>
            )}
          </Grid>

          <div
            className={
              index === 0 ? cls.hourLabelStart : index === hourTimes.length - 1 ? cls.hourLabelEnd : cls.hourLabel
            }
          >
            <Typography className={cls.hourLabelTxt}>{hourTime}</Typography>
          </div>
        </Grid>
      );
    return null;
  };

  const HourHalf = ({ index, hourTime, halfHourTime }) => {
    return (
      <>
        {hourTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              {index !== hourTimes.length - 1 ? (
                <>
                  <Grid item>
                    <div className={cls.hourTickFull} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickQuarter} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <div className={cls.hourTickFinal} />
                </Grid>
              )}
            </Grid>

            <div
              className={
                index === 0 ? cls.hourLabelStart : index === hourTimes.length - 1 ? cls.hourLabelEnd : cls.hourLabel
              }
            >
              <Typography className={cls.hourLabelTxt}>{hourTime}</Typography>
            </div>
          </Grid>
        ) : null}

        {halfHourTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              <Grid item>
                <div className={cls.hourTickHalf} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickQuarter} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
            </Grid>

            <div className={cls.hourLabel}>
              <Typography className={cls.halfLabelTxt}>{halfHourTime}</Typography>
            </div>
          </Grid>
        ) : null}
      </>
    );
  };

  const HourQuarter = ({ index, hourTime, quarterAfterTime, halfHourTime, quarterBeforeTime }) => {
    return (
      <>
        {hourTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              {index !== hourTimes.length - 1 ? (
                <>
                  <Grid item>
                    <div className={cls.hourTickFull} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                  <Grid item>
                    <div className={cls.hourTickTwelth} />
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <div className={cls.hourTickFinal} />
                </Grid>
              )}
            </Grid>

            <div
              className={
                index === 0 ? cls.hourLabelStart : index === hourTimes.length - 1 ? cls.hourLabelEnd : cls.hourLabel
              }
            >
              <Typography className={cls.hourLabelTxt}>{hourTime}</Typography>
            </div>
          </Grid>
        ) : null}

        {quarterAfterTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              <Grid item>
                <div className={cls.hourTickQuarter} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
            </Grid>

            <div className={cls.hourLabel}>
              <Typography className={cls.quarterLabelTxt}>{quarterAfterTime}</Typography>
            </div>
          </Grid>
        ) : null}

        {halfHourTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              <Grid item>
                <div className={cls.hourTickHalf} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
            </Grid>

            <div className={cls.hourLabel}>
              <Typography className={cls.halfLabelTxt}>{halfHourTime}</Typography>
            </div>
          </Grid>
        ) : null}

        {quarterBeforeTime ? (
          <Grid item className={cls.hourBlock}>
            <Grid container alignItems='flex-start' wrap='nowrap'>
              <Grid item>
                <div className={cls.hourTickQuarter} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
              <Grid item>
                <div className={cls.hourTickTwelth} />
              </Grid>
            </Grid>

            <div className={cls.hourLabel}>
              <Typography className={cls.quarterLabelTxt}>{quarterBeforeTime}</Typography>
            </div>
          </Grid>
        ) : null}
      </>
    );
  };

  const LabelLoop = props => {
    let labels = [];
    for (let i = 0; i < hourTimes.length; i++) {
      const hourTime = hourTimes[i];
      const quarterAfterTime = quarterAfterTimes[i] || null;
      const halfHourTime = halfHourTimes[i] || null;
      const quarterBeforeTime = quarterBeforeTimes[i] || null;

      if (timelineScale >= 2) {
        labels.push(
          <HourQuarter
            key={`timeline-head-label-${i}`}
            index={i}
            hourTime={hourTime}
            quarterAfterTime={quarterAfterTime}
            halfHourTime={halfHourTime}
            quarterBeforeTime={quarterBeforeTime}
          />
        );
      } else if (timelineScale >= 1) {
        labels.push(
          <HourHalf key={`timeline-head-label-${i}`} index={i} hourTime={hourTime} halfHourTime={halfHourTime} />
        );
      } else {
        labels.push(<HourFull key={`timeline-head-label-${i}`} index={i} hourTime={hourTime} />);
      }
    }

    return <>{labels.map(label => label)}</>;
  };

  return (
    <div className={cls.areaHead}>
      <Grid container alignItems='flex-start' wrap='nowrap'>
        <LabelLoop />
      </Grid>
      <TimelineAreaMarker />
    </div>
  );
}
