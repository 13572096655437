import React from 'react';
import { Popover, IconButton, Paper, Button, Icon } from '@material-ui/core';

// Function takes in the prop 'actions' which is an array of objects structured as below:
// [ {label: "button text here", function: () => action; }, {...}]
// Each object will be rendered as a separate button

export default function DotMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : null;

  return (
    <div>
      <IconButton aria-describedby={id} variant='contained' onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{ textAlign: 'center' }} onClick={handleClose}>
          {props.actions.map((action, index) => (
            <div key={'button-wrapper-' + index}>
              <Button key={'action-' + index} style={{ width: '100%' }} onClick={action.function}>
                {action.label}
              </Button>{' '}
              <br />{' '}
            </div>
          ))}
        </Paper>
      </Popover>
    </div>
  );
}
