//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useSubscription } from '@apollo/client';

import { makeStyles, Container, Grid } from '@material-ui/core';
import { Divide } from '@hopdrive/storybook';

import { GET_LOCATION_DETAILS } from './gql';

import * as Sentry from '@sentry/react';

import Loading from '../../components/Loading';
import { DefaultErrorFallback, DefaultEmptyFallback } from '../../components/Fallbacks';

import { LocationBanner, LocationInfo, LocationEdit, LocationMap, LocationNotes, LocationMovesList } from './index';

//////////////////////// COMPONENT ////////////////////////

function LocationDetails(props) {
  const cls = useStyles();

  const locationId = props.match.params.id;

  const { loading, error, data } = useSubscription(GET_LOCATION_DETAILS, { variables: { locationId: locationId } });

  // LOADING STATE //
  if (loading) {
    return <Loading fixed />;
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error getting location details:`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR GETTING LOCATION DETAILS' />;
  }

  // EMPTY STATE //
  if (!data || !data.locations || !data.locations.length > 0) {
    return <DefaultEmptyFallback message='NO LOCATION FOUND' />;
  }

  // DATA STATE //
  const location = data.locations[0];
  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <LocationBanner locationId={locationId} />

        <div className={cls.break_md} />

        <LocationInfo location={location} />

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Divide spacer tip='Edit the details of this location.'>
              Edit Location
            </Divide>
            <LocationEdit location={location} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Divide spacer tip='View this location on Google maps.'>
              View Location
            </Divide>
            <LocationMap location={location} />
          </Grid>
          <Grid item xs={12}>
            <Divide spacer tip='Use the markdown editor to create additional notes/instructions for this location.'>
              Location Notes & Instructions
            </Divide>
            <LocationNotes location={location} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divide spacer tip='View recent moves associated with this location.'>
            Associated Moves
          </Divide>
          <LocationMovesList location={location} />
        </Grid>
      </Container>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  break_md: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default LocationDetails;
