// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

// COMPONENT -------------------------------------------------- //

export default function Page({ className, children, open, onClose, handleClose, ...rest }) {
  const cls = useStyles();

  const getClassNames = () => {
    let classNames = [cls.root];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  if (open)
    return (
      <div className={getClassNames()} {...rest}>
        {children}
      </div>
    );

  return null;
}

// STYLES -------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1300,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    paddingTop: 64,
    background: '#00000080',
  },
}));
