//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { makeStyles, TextField, MenuItem, InputAdornment, Icon } from '@material-ui/core/';
import { useRegionsGlobals } from '../providers/RegionsProvider';

//////////////////////// COMPONENT ////////////////////////
export default function RegionSelect(props) {
  const cls = useStyles();

  let {
    value,
    name = 'Region',
    onChange = () => {},
    placeholder = 'Select a region...',
    label,
    required = false,
    allowAll = false,
    allowSelected = false,
    fullWidth = true,
    variant = 'outlined',
    size = 'small',
    style,
    InputProps,
  } = props;

  const { selectedRegionIds, setSelectedRegionIds } = useRegionsGlobals();

  const { loading, error, data } = useQuery(GET_REGIONS);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [regionSelectionList, setRegionSelectionList] = React.useState(
    localStorage.getItem('selected-regions') ? Object.values(JSON.parse(localStorage.getItem('selected-regions'))) : []
  );
  const [preferredRegionIds, setPreferredRegionIds] = React.useState([]);

  React.useEffect(() => {
    if (localStorage.getItem('selected-regions')) {
      setRegionSelectionList(Object.values(JSON.parse(localStorage.getItem('selected-regions'))));
    }
  }, [selectedRegionIds]);

  //Filter out plans not selected in the user's region preferences
  React.useEffect(() => {
    if (data && selectedRegionIds && selectedRegionIds.length > 0) {
      let filteredForSelected = data.regions.filter(region => selectedRegionIds.includes(region.id));
      setSelectedRegions(filteredForSelected);
    }
  }, [data, regionSelectionList]);

  return (
    <TextField
      select
      defaultValue=''
      fullWidth={fullWidth}
      required={required}
      name={name}
      label={label}
      placeholder={loading ? 'Loading...' : placeholder}
      variant={variant}
      size={size}
      value={data && data.regions && value ? value : 0}
      onChange={onChange}
      style={style}
      InputProps={{
        startAdornment: (
          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
            <Icon color='disabled' fontSize='small'>
              public
            </Icon>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    >
      {loading && (
        <MenuItem key={'Loading...'} value={0}>
          Loading...
        </MenuItem>
      )}
      {error && (
        <MenuItem key={'Error fetching regions'} value={0}>
          Error fetching regions
        </MenuItem>
      )}
      {allowAll && !allowSelected && (
        <MenuItem key={'All Regions'} value={0}>
          All Regions
        </MenuItem>
      )}
      {!allowAll && allowSelected && (
        <MenuItem key={'All Selected Regions'} value={0}>
          All Selected Regions
        </MenuItem>
      )}
      {!allowAll && !allowSelected && (
        <MenuItem key={placeholder} value={0}>
          <em className={cls.placeholder}>{placeholder}</em>
        </MenuItem>
      )}
      {!allowSelected &&
        data &&
        data.regions &&
        data.regions.map(region => (
          <MenuItem key={region.name} value={region.id}>
            {region.name} ({region.id})
          </MenuItem>
        ))}
      {allowSelected &&
        data &&
        data.regions &&
        selectedRegions.map(region => (
          <MenuItem key={region.name} value={region.id}>
            {region.name} ({region.id})
          </MenuItem>
        ))}
    </TextField>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  placeholder: {
    color: theme.palette.text.disabled,
  },
}));

// GQL ////////////////////////////////////////////////////////////
const GET_REGIONS = gql`
  query get_regions {
    regions(order_by: { name: asc }) {
      id
      name
    }
  }
`;
