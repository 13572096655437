import { gql } from '@apollo/client';

const GET_RULE_DETAILS = gql`
    query get_rule_details($id: bigint!) {
        businessrules(where: { id: { _eq: $id } }, order_by: { id: asc }) {
            id
            type
            name
            description
            jsonb
        }
    }
`;

const UPDATE_RULE = gql`
mutation update_rule($id: bigint!, $name: String!, $description: String! $jsonb: jsonb!, $type: Int!) {
    __typename
    update_businessrules(where: {id: {_eq: $id}}, _set: {jsonb: $jsonb, name: $name, description: $description, type: $type}) {
      affected_rows
    }
  }
`;

const SAVE_RULE = gql`
mutation save_rule($name: String!, $description: String! $jsonb: jsonb!, $type: Int!) {
    __typename
    insert_businessrules(objects: {active: true, name: $name, description: $description, jsonb: $jsonb, type: $type}) {
      affected_rows
    }
  }
`;

const GET_RULETYPES = gql`
query get_ruletypes {
    businessruletypes(where:{active:{_eq:true}, id:{_neq:3}}) {
        id
        name
      }
    }
`;
const GET_TEMPLATES = gql`
query get_templates {
    businessrules(where: { active: {_eq: true}, type: {_eq: 3} }) {
        id
        type
        description
        name
        jsonb
        ruletype {
            id
            name
        }
    }
  }
`;

export { 
    GET_RULE_DETAILS, 
    UPDATE_RULE, 
    SAVE_RULE, 
    GET_RULETYPES, 
    GET_TEMPLATES 
};