// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

import TimelineIndexHeader from './TimelineIndexHeader';
import TimelineIndexBody from './TimelineIndexBody';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineIndex() {
  const cls = useStyles();

  const { sidebarSize } = useTimeline();

  if (!sidebarSize) return null;
  return (
    <div className={cls.timelineIndex} style={{ flex: `0 0 ${sidebarSize}px` }}>
      <TimelineIndexHeader />
      <TimelineIndexBody />
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineIndex: {
    zIndex: 110,
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    minHeight: '100%',
  },
}));
