import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function TopLevelFallback(props) {
  const cls = useStyles();
  return (
    <div className={cls.notifyBox}>
      <Typography className={cls.notifyText}>
        The App has failed due to an internal error - please contact an admin
      </Typography>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  notifyBox: {
    position: 'relative',
    width: '100%',
    padding: '2rem',
    borderBottom: `1px solid #00000020`,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: `#ffffff`,
  },
  notifyText: {
    color: `#32323296`,
    lineHeight: 1.25,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
