import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { gql } from '@apollo/client';

import { useData } from '../../providers/DataProvider';

const log = false;

//////////////////////////////////////// HOOK ////////////////////////////////////////

export default function useOnboarding() {
  const { apolloClient } = useData();

  /** Proceed to the prev workflow based on current workflow */
  const proceedToPrevWorkflow = async (group, sectionId, close) => {
    if (group && group.sections && group.sections.length) {
      // Find the prev section in the group by looking at the next section
      const prevSection = group.sections.find((g, i) => {
        const nextSection = group.sections[i + 1];
        if (nextSection && nextSection.id === sectionId) return true;
        return false;
      });

      // If no prev section is found, just return to the LaunchPad
      if (!prevSection) {
        log && console.log(`No prev section found, returning to LaunchPad.`);
        if (close) close();
        return;
      }

      // Proceed to prev section otherwise
      log && console.log(`Proceeding to '${prevSection.id}'...`);
      prevSection.handler(group, prevSection);
    } else {
      console.error(`Cannot proceed to prev section! No group found.`);
    }
  };

  /** Proceed to the next workflow based on current workflow */
  const proceedToNextWorkflow = async (group, sectionId, close) => {
    if (group && group.sections && group.sections.length) {
      // Find the next section in the group by looking at the prev section
      const nextSection = group.sections.find((g, i) => {
        const prevSection = group.sections[i - 1];
        if (prevSection && prevSection.id === sectionId) return true;
        return false;
      });

      // If no next section is found, just return to the LaunchPad
      if (!nextSection) {
        log && console.log(`No next section found, returning to LaunchPad.`);
        if (close) close();
        return;
      }

      // Proceed to next section otherwise
      log && console.log(`Proceeding to '${nextSection.id}'...`);
      nextSection.handler(group, nextSection);
    } else {
      console.error(`Cannot proceed to next section! No group found.`);
    }
  };

  /** Get status of personal information */
  const getPersonalInfoStatus = driver => {
    if (
      driver.date_of_birth &&
      driver.display_name &&
      driver.email &&
      driver.first_name &&
      driver.gender &&
      driver.last_name &&
      driver.middle_name &&
      driver.phone &&
      driver.phone_type &&
      driver.primary_phone &&
      (driver.social_security || driver.employer_identifier) &&
      (driver.config.attributes.manual === true || driver.config.attributes.manual === false) &&
      (driver.veteran.status === true || driver.veteran.status === false)
    ) {
      return `done`;
    }

    if (
      driver.date_of_birth ||
      driver.display_name ||
      driver.email ||
      driver.first_name ||
      driver.gender ||
      driver.last_name ||
      driver.middle_name ||
      driver.phone ||
      driver.phone_type ||
      driver.primary_phone ||
      driver.social_security ||
      driver.employer_identifier ||
      driver.config.attributes.manual === true ||
      driver.config.attributes.manual === false ||
      driver.veteran.status === true ||
      driver.veteran.status === false
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of address information */
  const getAddressInfoStatus = driver => {
    if (
      (driver.address_one &&
        driver.address_city &&
        driver.address_state &&
        driver.address_zip &&
        !driver.mail_address_one &&
        !driver.mail_address_city &&
        !driver.mail_address_state &&
        !driver.mail_address_zip) ||
      (driver.address_one &&
        driver.address_city &&
        driver.address_state &&
        driver.address_zip &&
        driver.mail_address_one &&
        driver.mail_address_city &&
        driver.mail_address_state &&
        driver.mail_address_zip)
    ) {
      return `done`;
    }

    if (
      driver.address_one ||
      driver.address_two ||
      driver.address_city ||
      driver.address_state ||
      driver.address_zip ||
      driver.mail_address_one ||
      driver.mail_address_two ||
      driver.mail_address_city ||
      driver.mail_address_state ||
      driver.mail_address_zip
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of vehicle information */
  const getVehicleInfoStatus = driver => {
    if (
      driver.vehicle_year &&
      driver.vehicle_make &&
      driver.vehicle_model &&
      driver.vehicle_color &&
      driver.vehicle_vin &&
      driver.vehicle_license_plate &&
      driver.vehicle_license_plate_state
    ) {
      return `done`;
    }

    if (
      driver.vehicle_year ||
      driver.vehicle_make ||
      driver.vehicle_model ||
      driver.vehicle_color ||
      driver.vehicle_vin ||
      driver.vehicle_license_plate ||
      driver.vehicle_license_plate_state
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of drivers license information */
  const getDriversLicenseInfoStatus = driver => {
    if (driver.license_number && driver.license_state) {
      return `done`;
    }

    if (driver.license_number || driver.license_state || driver.license_photo_url) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Get status of insurance information */
  const getInsuranceInfoStatus = driver => {
    if (
      driver.insurance_provider &&
      driver.insurance_policy_id &&
      driver.insurance_coverage_amount &&
      (driver.insurance_rideshare_rider === true || driver.insurance_rideshare_rider === false)
    ) {
      return `done`;
    }

    if (
      driver.insurance_provider ||
      driver.insurance_policy_id ||
      driver.insurance_coverage_amount ||
      driver.insurance_rideshare_rider === true ||
      driver.insurance_rideshare_rider === false
    ) {
      return `in progress`;
    }

    return `not started`;
  };

  /** Check if information-forms group has been completed */
  const checkInformationalFormsGroupComplete = driver => {
    if (driver && driver.verification && driver.verification.info_completed_datetime) return true;
    return false;
  };

  /** Check if information-forms group has been approved */
  const checkInformationalFormsGroupApproved = driver => {
    if (driver && driver.verification && driver.verification.info_approval_datetime) return true;
    return false;
  };

  /** Get status of the background check */
  const getBackgroundCheckStatus = driver => {
    // REQUIRED FIELDS IN REQUEST
    // first_name
    // middle_name
    // no_middle_name
    // last_name
    // email
    // phone
    // zipcode
    // dob
    // ssn
    // driver_license_number
    // driver_license_state

    if (
      driver.address_zip &&
      driver.date_of_birth &&
      driver.email &&
      driver.first_name &&
      driver.last_name &&
      driver.license_number &&
      driver.license_state &&
      driver.middle_name &&
      driver.phone &&
      driver.social_security
    ) {
      return `ready`;
    }

    return `not ready`;
  };

  /** Check if information-forms group has been approved */
  const getTrainingStatus = driver => {
    if (driver.status !== `training` && getPropValue(driver, `verification.training_completed_datetime`)) return `done`;
    if (driver.status === `training` && getPropValue(driver, `verification.training_initiated_datetime`))
      return `in progress`;
    if (checkInformationalFormsGroupApproved(driver)) return `ready`;
    return `not ready`;
  };

  /** Build a list of launchpad groups and sections */
  const buildLaunchpadGroups = (driver, handleWorkflowOpen) => {
    const groups = [
      // INFORMATIONAL FORMS
      {
        id: `informational-forms`,
        label: `Informational Forms`,
        sections: [
          {
            id: `personal-information`,
            label: `Personal Information`,
            status: getPersonalInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
          },
          {
            id: `address-information`,
            label: `Address Information`,
            status: getAddressInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
          },
          {
            id: `vehicle-information`,
            label: `Vehicle Information`,
            status: getVehicleInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
          },
          {
            id: `drivers-license-information`,
            label: `Drivers License Information`,
            status: getDriversLicenseInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
          },
          {
            id: `insurance-information`,
            label: `Insurance Information`,
            status: getInsuranceInfoStatus(driver),
            handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
          },
        ],
        canApprove: () => {
          if (
            getPersonalInfoStatus(driver) === `done` &&
            getAddressInfoStatus(driver) === `done` &&
            getVehicleInfoStatus(driver) === `done` &&
            getDriversLicenseInfoStatus(driver) === `done` &&
            getInsuranceInfoStatus(driver) === `done` &&
            checkInformationalFormsGroupComplete(driver) &&
            !checkInformationalFormsGroupApproved(driver)
          ) {
            return true;
          }
          return false;
        },
        isApproved: () => checkInformationalFormsGroupApproved(driver),
        approve: async callback => {
          const success = await approveInformationalFormsGroup(driver, callback);
          if (success === true) toast.success(`Informational forms were approved!`);
          else toast.error(`An error occured while approving, please contact an admin!`);
        },
      },

      // LEGAL DOCUMENTATION
      // {
      //   id: `legal-documentation`,
      //   label: `Legal Documentation`,
      //   sections: [
      //     {
      //       id: `employee-verification`,
      //       label: `Employee Verification`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `driver-agreement`,
      //       label: `Driver Agreement`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `code-of-conduct`,
      //       label: `Code of Conduct`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `drug-and-alcohol-policy`,
      //       label: `Drug & Alcohol Policy`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `employee-w-4`,
      //       label: `Employee W-4`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //     {
      //       id: `part-time-employment-agreement`,
      //       label: `Part-Time Employment Agreement`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //     {
      //       id: `offer-letter`,
      //       label: `Offer Letter`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       hide: driver.tax_class !== `W-2`,
      //     },
      //   ],
      //   canApprove: () => false,
      //   approve: () => {
      //     console.log(`APPROVE LEGAL DOCUMENTATION`);
      //   },
      // },

      // ONBOARDING FEES
      // {
      //   id: `onboarding-fees`,
      //   label: `Onboarding Fees`,
      //   sections: [
      //     {
      //       id: `payment-method`,
      //       label: `Payment Method`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //     },
      //     {
      //       id: `process-payment`,
      //       label: `Process Payment`,
      //       status: `not started`,
      //       handler: (flowGroup, flowSection) => handleWorkflowOpen(flowGroup, flowSection),
      //       disable: !driver.payment_method,
      //     },
      //   ],
      //   canApprove: () => false,
      //   approve: () => {
      //     console.log(`APPROVE ONBOARDING FEES`);
      //   },
      // },
    ];
    return groups;
  };

  /** Approve informational-forms group */
  const approveInformationalFormsGroup = async (driver, callback) => {
    const verification = { info_approval_datetime: dayjs().utc().format() };
    return await updateDriverVerification(driver, verification, callback);
  };

  /** Start the background check with the information provided */
  const runBackgroundCheck = (driver, callback) => {};

  /** Start training phase and update driver status and verification */
  const initiateTraining = async (driver, callback) => {
    const verification = { training_initiated_datetime: dayjs().utc().format() };
    const driverRes = await updateDriverStatusAndVerification(driver, `training`, verification, callback);
    if (driverRes) {
      try {
        const res = await apolloClient.mutate({
          mutation: gql`
            mutation initiateTraining($driverId: bigint!) {
              update_users(where: { driver: { id: { _eq: $driverId } } }, _set: { active: true }) {
                affected_rows
                returning {
                  id
                  active
                }
              }
            }
          `,
          variables: { driverId: driver.id },
        });

        if (getPropValue(res, `data.update_users.affected_rows`)) {
          log && console.log(`User updated:`, res.data);
          if (callback) callback();
          return true;
        }
        console.error(`Failed to update user record!`);
        return false;
      } catch (err) {
        console.error(`Failed to update user record:`, err);
        return false;
      }
    }
  };

  /** Complete training phase and update driver status and verification */
  const completeTraining = async (driver, callback) => {
    const verification = { training_completed_datetime: dayjs().utc().format() };
    return await updateDriverStatusAndVerification(driver, `online`, verification, callback);
  };

  /** Reject the driver */
  const rejectDriver = async (driver, reason, callback) => {
    const verification = { rejected_datetime: dayjs().utc().format(), rejected_reason: reason };
    const driverRes = await updateDriverStatusAndVerification(driver, `rejected`, verification, callback);
    if (driverRes) {
      try {
        const res = await apolloClient.mutate({
          mutation: gql`
            mutation rejectDriver($driverId: bigint!) {
              update_users(where: { driver: { id: { _eq: $driverId } } }, _set: { active: false }) {
                affected_rows
                returning {
                  id
                  active
                }
              }
            }
          `,
          variables: { driverId: driver.id },
        });

        if (getPropValue(res, `data.update_users.affected_rows`)) {
          log && console.log(`User updated:`, res.data);
          if (callback) callback();
          return true;
        }
        console.error(`Failed to update user!`);
        return false;
      } catch (err) {
        console.error(`Failed to update user:`, err);
        return false;
      }
    }
  };

  /** Update the driver's avatar URL with the one provided */
  const updateDriverAvatarUrl = async (driver, avatarUrl, callback) => {
    try {
      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverAvatarUrl($driverId: bigint!, $avatarUrl: String) {
            update_users(where: { driver: { id: { _eq: $driverId } } }, _set: { avatar_url: $avatarUrl }) {
              affected_rows
              returning {
                id
                avatar_url
              }
            }
          }
        `,
        variables: { driverId: driver?.id, avatarUrl },
      });

      if (res?.data?.update_users?.affected_rows) {
        log && console.log(`Avatar URL updated on driver #${driver?.id}:`, res.data);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update user avatar!`);
      return false;
    } catch (err) {
      console.error(`Failed to update user avatar:`, err);
      return false;
    }
  };

  /** Update driver status to string passed in */
  const updateDriverStatus = async (driver, status, callback) => {
    try {
      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverStatus($driverId: bigint!, $status: String!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { status: $status }) {
              affected_rows
              returning {
                id
                status
              }
            }
          }
        `,
        variables: { driverId: driver.id, status },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`)) {
        log && console.log(`Driver #${driver.id} status set to "${status}":`, res.data);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver status!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver status:`, err);
      return false;
    }
  };

  /** Update driver verification to object passed in */
  const updateDriverVerification = async (driver, verificationAddition, callback) => {
    try {
      let verification = driver.verification || {};
      verification = { ...verification, ...verificationAddition };

      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverVerification($driverId: bigint!, $verification: jsonb!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { verification: $verification }) {
              affected_rows
              returning {
                id
                verification
              }
            }
          }
        `,
        variables: { driverId: driver.id, verification },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`)) {
        log && console.log(`Driver #${driver.id} verification updated:`, res.data);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver verification!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver verification:`, err);
      return false;
    }
  };

  /** Update driver status string and verification object */
  const updateDriverStatusAndVerification = async (driver, status, verificationAddition, callback) => {
    try {
      let verification = driver.verification || {};
      verification = { ...verification, ...verificationAddition };

      const res = await apolloClient.mutate({
        mutation: gql`
          mutation updateDriverStatusAndVerification($driverId: bigint!, $status: String!, $verification: jsonb!) {
            update_drivers(where: { id: { _eq: $driverId } }, _set: { status: $status, verification: $verification }) {
              affected_rows
              returning {
                id
                status
                verification
              }
            }
          }
        `,
        variables: { driverId: driver.id, status, verification },
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`)) {
        log && console.log(`Driver #${driver.id} status set to "${status}" and verification updated:`, res.data);
        if (callback) callback();
        return true;
      }
      console.error(`Failed to update driver status and verification!`);
      return false;
    } catch (err) {
      console.error(`Failed to update driver status and verification:`, err);
      return false;
    }
  };

  // Return hook logic
  return {
    proceedToPrevWorkflow,
    proceedToNextWorkflow,
    getBackgroundCheckStatus,
    getTrainingStatus,
    buildLaunchpadGroups,
    runBackgroundCheck,
    initiateTraining,
    completeTraining,
    rejectDriver,
    updateDriverAvatarUrl,
    updateDriverStatus,
    updateDriverVerification,
    updateDriverStatusAndVerification,
  };
}
