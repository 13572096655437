import { gql } from 'graphql-tag';

const GET_RIDE = gql`
  subscription get_ride($rideMoveId: bigint!) {
    lyftrides(where: { ride_move_id: { _eq: $rideMoveId } }) {
      id
      createdat
      last_attempt
      latitude
      longitude
      passenger_phone
      pickup_location_type
      ride_move_id
      status
      active_estimate_attempts
      decision_reason
      updatedat
      activeAttempt {
        id
        active
        createdat
        destination_location_id
        driver_first_name
        driver_id
        driver_phone
        driver_rating
        driver_vehicle_color
        driver_vehicle_license_plate
        driver_vehicle_license_plate_state
        driver_vehicle_make
        driver_vehicle_model
        driver_vehicle_year
        estimate_id
        estimated_arrival_time
        estimated_cost_max
        estimated_cost_min
        estimated_ride_cost
        origin_location_id
        passenger_phone
        requested_at
        requester
        ride_cost
        ride_distance
        ride_duration
        ride_id
        ride_type
        rideshare_delivery_address
        rideshare_delivery_latitude
        rideshare_delivery_longitude
        rideshare_id
        rideshare_pickup_address
        rideshare_pickup_latitude
        rideshare_pickup_longitude
        status
        surge_cost_percentage
        updatedat
      }
      attempts: attempts(order_by: { createdat: desc_nulls_last }) {
        id
        active
        createdat
        destination_location_id
        driver_first_name
        driver_id
        driver_phone
        driver_rating
        driver_vehicle_color
        driver_vehicle_license_plate
        driver_vehicle_license_plate_state
        driver_vehicle_make
        driver_vehicle_model
        driver_vehicle_year
        estimate_id
        estimated_arrival_time
        estimated_cost_max
        estimated_cost_min
        estimated_ride_cost
        origin_location_id
        passenger_phone
        requested_at
        requester
        ride_cost
        ride_distance
        ride_duration
        ride_id
        ride_type
        rideshare_delivery_address
        rideshare_delivery_latitude
        rideshare_delivery_longitude
        rideshare_id
        rideshare_pickup_address
        rideshare_pickup_latitude
        rideshare_pickup_longitude
        status
        surge_cost_percentage
        updatedat
      }
    }
  }
`;

const UPDATE_MOVE_RIDE_TYPE = gql`
  mutation update_move_ride_type($rideMoveId: bigint!, $rideType: String!) {
    update_moves(where: { id: { _eq: $rideMoveId } }, _set: { ride_type: $rideType, updatedat: "now()" }) {
      affected_rows
      returning {
        id
        ride_type
      }
    }
  }
`;

const UPSERT_RIDE = gql`
  mutation upsert_ride($rideMoveId: bigint!) {
    insert_lyftrides(
      objects: { ride_move_id: $rideMoveId, status: "pending", last_attempt: "now()" }
      on_conflict: { constraint: lyftrides_ride_move_id_key, update_columns: [status, last_attempt] }
    ) {
      affected_rows
      returning {
        id
        ride_move_id
        status
        last_attempt
      }
    }
  }
`;

const UPDATE_ATTEMPT_AND_MOVE_STATUS = gql`
  mutation update_attempt_and_move_status($rideMoveId: bigint!, $attemptId: bigint!, $status: String) {
    update_lyftrideattempts(where: { id: { _eq: $attemptId } }, _set: { status: $status, updatedat: "now()" }) {
      affected_rows
      returning {
        id
        status
      }
    }
    update_moves(where: { id: { _eq: $rideMoveId } }, _set: { status: $status, updatedat: "now()" }) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;

const UPDATE_ACTIVE_ATTEMPT_FAILED = gql`
  mutation update_active_attempt_failed($rideMoveId: bigint!, $attemptId: bigint!) {
    update_lyftrideattempts(where: { id: { _eq: $attemptId } }, _set: { status: "failed", updatedat: "now()" }) {
      affected_rows
      returning {
        id
        ride_id
        status
        updatedat
      }
    }
    update_lyftrides(
      where: { ride_move_id: { _eq: $rideMoveId } }
      _set: { active_attempt: null, active_estimate_attempts: null, status: "failed", updatedat: "now()" }
    ) {
      affected_rows
      returning {
        id
        ride_move_id
        active_attempt
        status
        updatedat
      }
    }
    update_moves(where: { id: { _eq: $rideMoveId } }, _set: { status: "failed", updatedat: "now()" }) {
      affected_rows
      returning {
        id
        status
        updatedat
      }
    }
  }
`;

export { GET_RIDE, UPDATE_MOVE_RIDE_TYPE, UPSERT_RIDE, UPDATE_ATTEMPT_AND_MOVE_STATUS, UPDATE_ACTIVE_ATTEMPT_FAILED };
