import React from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_CHASE_VEHICLE, GET_CHASE_VEHICLES } from './gql';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';
import { Typography } from '@material-ui/core';

export default function DeleteChaseVehicleContent({ input, onClose }) {
  const [deleteChaseVehicle] = useMutation(DELETE_CHASE_VEHICLE, {
    refetchQueries: [{ query: GET_CHASE_VEHICLES }],
  });

  console.log('input', input);

  const handleDelete = () => {
    deleteChaseVehicle({ variables: { id: input.id } });
    onClose();
  };

  return (
    <>
      <ModalHeader handleClose={onClose}>Delete Chase Vehicle</ModalHeader>
      <ModalContent>
        <Typography>Are you sure you want to delete this chase vehicle?</Typography>
        <ul>
          <li>
            <Typography>
              VIN: {input.vin}
            </Typography>
          </li>
          <li>
            <Typography>Make: {input.make}</Typography>
          </li>
          <li>
            <Typography>Model: {input.model}</Typography>
          </li>
        </ul>
      </ModalContent>
      <ModalFooter>
        <ModalAction color='error' onClick={handleDelete}>
          Delete
        </ModalAction>
        <ModalAction onClick={onClose}>Cancel</ModalAction>
      </ModalFooter>
    </>
  );
}
