import React from 'react';
import { makeStyles, Container } from '@material-ui/core';

import PromosBanner from './PromosBanner'
import PromosTable from './PromosTable';

export default function Promos(props) {
    const cls = useStyles();

    const headers = [
        { id: `customer`, alignLeft: true, numeric: false, label: `Customer` },
        { id: `region`, alignLeft: true, numeric: true, label: `Region` },
        { id: `type`, alignLeft: true, numeric: false, label: `Type` },
        { id: `description`, alignLeft: true, numeric: false, label: `Description` },
        { id: `expiration_date`, alignLeft: true, numeric: true, label: `Expiration Date` },
    ]

    return(<>
        <PromosBanner />
        <div className={ cls.rootTable }>
            <Container maxWidth='lg'>
                <PromosTable size={`small`} headers={headers} />
            </Container> 
        </div>
    </>)
};

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
    rootTable: {
      verticalAlign: 'top',
      position: 'relative',
      width: '100%',
    },
  }));