import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';

import { GET_APPAYMENTS_BY_RANGE, buildDriverPayArray } from '@hopdrive/sdk/lib/modules/wallet';
import { useEngine } from '../../hooks/useEngine';
import { useDriverPay } from './useDriverPay';

import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import Toolbar from '../../components/Toolbar';

import DriverPayFilter1 from './DriverPayFilter1';
import DriverPayFilter2 from './DriverPayFilter2';
import DriverPayTable from './DriverPayTable';

const log = true;

//////////////////////// COMPONENT ////////////////////////

export default function DriverPay(props) {
  const cls = useStyles();

  // Engine Hook
  const { fetchBalanceAmount } = useEngine();

  // Driver Pay Hook
  const {
    defaultType,
    defaultRegionId,
    defaultTaxClass,
    getDefaultRegionId,
    getDefaultTaxClass,
    getStart,
    getEnd,
    generateDriverPayCSV,
  } = useDriverPay();

  // Handle loading the balance
  const handleBalance = async () => {
    const newBalance = await fetchBalanceAmount();
    setBalanceAmount(newBalance);
    setLoadingBalance(false);
  };

  // Filter State
  const [type, setType] = React.useState(defaultType);
  const [regionId, setRegionId] = React.useState(getDefaultRegionId());
  const [taxClass, setTaxClass] = React.useState(getDefaultTaxClass());
  const [search, setSearch] = React.useState(``);
  const [start, setStart] = React.useState(getStart(defaultType));
  const [end, setEnd] = React.useState(getEnd(defaultType));

  // Table State
  const [checkedRows, setCheckedRows] = React.useState([]);

  // Amount State
  const [screenTotal, setScreenTotal] = React.useState(0);
  const [balanceAmount, setBalanceAmount] = React.useState(0);
  const [loadingBalance, setLoadingBalance] = React.useState(true);

  // Whenever type change, set start and end
  React.useEffect(() => {
    handleBalance();
    // eslint-disable-next-line
  }, []);

  // Whenever type change, set start and end
  React.useEffect(() => {
    setStart(getStart(type));
    setEnd(getEnd(type));
    // eslint-disable-next-line
  }, [type]);

  // Whenever filters change, reset checkedRows
  React.useEffect(() => {
    setCheckedRows([]);
  }, [start, end]);

  const handleType = value => {
    setType(value);
  };
  const handleRegionId = value => {
    localStorage.setItem(`driver-pay-region-id`, value);
    setRegionId(value);
  };
  const handleTaxClass = value => {
    localStorage.setItem(`driver-pay-tax-class`, value);
    setTaxClass(value);
  };
  const handleSearch = value => {
    setSearch(value);
  };

  // Handle clearing the filters stored in state
  const handleClearFilters = () => {
    localStorage.removeItem(`driver-pay-region-id`);
    localStorage.removeItem(`driver-pay-tax-class`);

    setType(defaultType);
    setRegionId(defaultRegionId);
    setTaxClass(defaultTaxClass);
    setSearch(``);
  };

  // Call the query (with options passed in) and get back the data + states
  const { loading, error, data, refetch } = useQuery(GET_APPAYMENTS_BY_RANGE, {
    variables: {
      start: start,
      end: end,
    },
    fetchPolicy: `network-only`,
  });

  // Handle refetch callback to pass as props
  const handleRefetch = async () => {
    await handleBalance();
    refetch();
  };

  // LOADING STATE //
  if (loading) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title={`Driver Pay`} onClear={handleClearFilters}>
          <DriverPayFilter1
            type={type}
            regionId={regionId}
            taxClass={taxClass}
            search={search}
            start={start}
            end={end}
            onTypeChange={handleType}
            onRegionIdChange={handleRegionId}
            onTaxClassChange={handleTaxClass}
            onSearchChange={handleSearch}
          />
        </Toolbar>
        <DriverPayFilter2
          type={type}
          regionId={regionId}
          taxClass={taxClass}
          search={search}
          start={start}
          end={end}
          onTypeChange={handleType}
          onRegionIdChange={handleRegionId}
          onTaxClassChange={handleTaxClass}
          onSearchChange={handleSearch}
        />
        <Loading position='fixed' />
      </div>
    );
  }

  // ERROR STATE //
  if (error) {
    console.error(`Error fetching driver pay index:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title={`Driver Pay`} onClear={handleClearFilters} refetch={handleRefetch}>
          <DriverPayFilter1
            type={type}
            regionId={regionId}
            taxClass={taxClass}
            search={search}
            start={start}
            end={end}
            onTypeChange={handleType}
            onRegionIdChange={handleRegionId}
            onTaxClassChange={handleTaxClass}
            onSearchChange={handleSearch}
          />
        </Toolbar>
        <DriverPayFilter2
          type={type}
          regionId={regionId}
          taxClass={taxClass}
          search={search}
          start={start}
          end={end}
          onTypeChange={handleType}
          onRegionIdChange={handleRegionId}
          onTaxClassChange={handleTaxClass}
          onSearchChange={handleSearch}
        />
        <DefaultErrorFallback message='ERROR FETCHING DRIVER PAY' />
      </div>
    );
  }

  // EMPTY STATE //
  if (!data || !data.appayments || !data.appayments.length) {
    return (
      <div className={cls.root}>
        <Toolbar fullscreen title={`Driver Pay`} onClear={handleClearFilters} refetch={handleRefetch}>
          <DriverPayFilter1
            type={type}
            regionId={regionId}
            taxClass={taxClass}
            search={search}
            start={start}
            end={end}
            onTypeChange={handleType}
            onRegionIdChange={handleRegionId}
            onTaxClassChange={handleTaxClass}
            onSearchChange={handleSearch}
          />
        </Toolbar>
        <DriverPayFilter2
          type={type}
          regionId={regionId}
          taxClass={taxClass}
          search={search}
          start={start}
          end={end}
          onTypeChange={handleType}
          onRegionIdChange={handleRegionId}
          onTaxClassChange={handleTaxClass}
          onSearchChange={handleSearch}
        />
        <DefaultEmptyFallback message='ALL DRIVERS PAID WITHIN DATE RANGE' />
      </div>
    );
  }

  // DATA STATE //

  // Capture appayments
  const appayments = data.appayments;
  log && console.log(`AP Payments:`, appayments);

  // Build driver pay array based on appayments
  const driverPayArr = buildDriverPayArray(appayments);
  log && console.log(`Driver Pay:`, driverPayArr);

  // List of table-level actions
  const actions = [
    {
      label: `Generate CSV`,
      handler: () => generateDriverPayCSV(driverPayArr, start, end),
    },
  ];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title={`Driver Pay`} onClear={handleClearFilters} refetch={handleRefetch} actions={actions}>
        <DriverPayFilter1
          type={type}
          regionId={regionId}
          taxClass={taxClass}
          search={search}
          start={start}
          end={end}
          onTypeChange={handleType}
          onRegionIdChange={handleRegionId}
          onTaxClassChange={handleTaxClass}
          onSearchChange={handleSearch}
          checkedRows={checkedRows}
          driverPayArr={driverPayArr}
          refetch={handleRefetch}
          screenTotal={screenTotal}
          balanceAmount={balanceAmount}
        />
      </Toolbar>
      <DriverPayFilter2
        type={type}
        regionId={regionId}
        taxClass={taxClass}
        search={search}
        start={start}
        end={end}
        onTypeChange={handleType}
        onRegionIdChange={handleRegionId}
        onTaxClassChange={handleTaxClass}
        onSearchChange={handleSearch}
        checkedRows={checkedRows}
        driverPayArr={driverPayArr}
        refetch={handleRefetch}
        screenTotal={screenTotal}
        balanceAmount={balanceAmount}
      />
      <DriverPayTable
        regionId={regionId}
        taxClass={taxClass}
        search={search}
        driverPayArr={driverPayArr}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
        setScreenTotal={setScreenTotal}
      />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
  btnIconL: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },
}));
