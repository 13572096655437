import React, { useState } from 'react';
import { Icon, Button } from '@material-ui/core';
import moment from 'moment';
import fragments from '../../utils/fragments';
import gql from 'graphql-tag';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';

import { getUserToken } from '../../utils/authHelper'

const log = false;

export default function SendEmailButton({ payPeriod, refetch }) {

  const [sent, setSent] = useState(false);

  const sendEmail = async appayments => {
    if (!appayments) {
      console.error('There are no appayments to send');
      return;
    }
    try {
      const token = await getUserToken()
      await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDriverPayEmail`,
        data: {
          driverId: payPeriod.driver_id,
          moveCount: appayments.length,
          paidAmount: Number(appayments.reduce((a, b) => a + (b['amount'] || 0), 0)).toFixed(2),
          startDate: moment(payPeriod.pickup_time_start).format('MM/DD/YYYY'),
          endDate: moment(payPeriod.pickup_time_end).format('MM/DD/YYYY'),
          payments: formatPaymentData(appayments),
        },
        headers: {
          authorization: `Bearer ${token.__raw}`,
        },
      })
        .then(res => {
          log && console.log(`Successfully sent email`, res);
          if (refetch) refetch();
          setSent(true);
          toast.success('Successfully sent email');
          return true;
        })
        .catch(err => {
          console.error(`Error occured when attempting to send email:`, err);
          toast.error('Failed to send email: ' + err.toString());
          return false;
        });
    } catch (err) {
      console.error(`Error occured when attempting to send email:`, err);
      toast.error('Error occured when attempting to send email: ' + err.toString());
      return false;
    }
  };

  //formats the selected appayments for the table in the driver email
  const formatPaymentData = paymentsArr => {
    return paymentsArr.map(o => {
      if (o.type === 'move pay') {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format('MM/DD/YYYY'),
          pickupTime: moment(o.move.pickup_time).format('LT'),
          type: `${o.type} (${o.move.move_type})`,
          description: o.move.lane.description,
          distance: `${o.move.lane.distance_miles} mi`,
          totalPay: o.amount.toFixed(2),
        };
      } else if (o.type === 'accessorial') {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format('MM/DD/YYYY'),
          pickupTime: moment(o.move.pickup_time).format('LT'),
          type: `${o.type} (${o.accessorial.code})`,
          description: o.accessorial.notes,
          distance: '-',
          totalPay: o.amount.toFixed(2),
        };
      } else {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format('MM/DD/YYYY'),
          pickupTime: moment(o.move.pickup_time).format('LT'),
          type: `${o.notes}`,
          description: o.notes,
          distance: '-',
          totalPay: o.amount.toFixed(2),
        };
      }
    });
  };

  const { loading, error, data } = useQuery(GET_APPAYMENTS, {
    variables: { driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start, end: payPeriod.pickup_time_end },
  });

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING EMAIL BUTTON' />}>
      <Button
        disabled={loading || error || sent || (data && data.appayments && data.appayments.length === 0)}
        size='small'
        variant='outlined'
        endIcon={<Icon fontSize='small'>send</Icon>}
        color='secondary'
        onClick={() => sendEmail(data.appayments)}
      >
        Send Email
      </Button>
    </Sentry.ErrorBoundary>
  );
}

const GET_APPAYMENTS = gql`
  query get_appayments_for_sending_driver_email($driverId: bigint, $start: timestamptz, $end: timestamptz) {
    appayments(
      where: {
        move: {
          active: { _eq: 1 }
          pickup_time: { _gte: $start, _lte: $end }
          _or: [{ cancel_status: { _neq: "canceled" } }, { cancel_status: { _is_null: true } }]
        }
        active: { _eq: 1 }
        driver_id: { _eq: $driverId }
      }
      order_by: { move: { pickup_time: desc } }
    ) {
      ...APPayment
      move {
        ...Move
      }
      accessorial {
        ...Accessorials
      }
    }
  }
  ${fragments.move}
  ${fragments.appayment}
  ${fragments.accessorials}
`;
