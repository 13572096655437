import React from 'react';
import { toast } from 'react-toastify';
import sdk from '@hopdrive/sdk';
import {
  makeStyles,
  Grid,
  Typography,
  Icon,
  LinearProgress,
  Checkbox,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../components/ModalComponents';

import { useTools } from '../../hooks/useTools';
import { useDrivers } from '../../pages/Drivers/useDrivers';
import { useUserDetails } from '../../providers/UserDetailsProvider';

import RegionSelect from '../../components/RegionSelect';
import { REFRESH_CLAIMS } from '../../utils/gqlUserManagement';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function AdminDriverAddModalContent({ onClose, refetch }) {
  const cls = useStyles();
  const ctx = useUserDetails();

  const {
    getFirstNameFromDisplayName,
    getMiddleNameFromDisplayName,
    getLastNameFromDisplayName,
    goToAdminUserDetails,
  } = useTools();
  const { insertDriver, sendOnboardingEmail } = useDrivers();
  const [id, setId] = React.useState(``);
  const [regionId, setRegionId] = React.useState(0);

  const [willSendEmail, setWillSendEmail] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  // Update state if the input changes
  React.useEffect(() => {
      setIsSaving(false);
      setIsValid(false);
  }, []);

  // Check validity of form
  const isFormValid = () => {
    // Initialize validity
    let validity = true;
    let validityArr = [];

    // Check for required inputs
    if (willSendEmail === null) {
      const step = `willSendEmail`;
      const msg = `Send onboarding email check is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }
    if (!regionId) {
      const step = `regionId`;
      const msg = `Region field is required.`;
      validityArr.push({ step, msg });
      validity = false;
    }

    // Log out validation array if found
    if (validityArr.length) log && console.log(`Validation Array:`, validityArr);

    // Return validity
    return { validity, validityArr };
  };

  // Check if the form is valid before allowing the submit button
  React.useEffect(() => {
    const validation = isFormValid();
    const validity = validation.validity;
    setIsValid(validity);
    // eslint-disable-next-line
  }, [willSendEmail, id, regionId,]);

  // Handle setting form or clearing it
  const handleFormState = async (td = null) => {
    setId(``);
    setRegionId(0);
  };

  // Handle submitting the form
  const handleSubmit = async () => {
    // Build driver and driverdetail objects
    let driver = {
      id: id || null,
      region_id: regionId || null,
      status: `new`,
      tax_class: `W-2`,
      user_id: ctx.userId,
    };

    const driverdetail = {
      first_name: getFirstNameFromDisplayName(ctx.displayName) || null,
      middle_name: getMiddleNameFromDisplayName(ctx.displayName) || null,
      last_name: getLastNameFromDisplayName(ctx.displayName) || null,
    };

    // Remove null driver id to avoid errors
    if (!driver.id) delete driver.id;

    // Create an insertable driver
    const insertableDriver = {
      ...driver,
    };
    log &&
      console.log(`Driver Params:`, {
        ...driver,
        ...driverdetail,
      });

    // Do the insert
    const insertedDriverRes = await insertDriver(insertableDriver, driverdetail);
    const updateUserRes = await sdk.gql.mutation(REFRESH_CLAIMS, {userId: ctx.userId})

    // Check the result of the insert
    if (insertedDriverRes && updateUserRes.success) {
      // Let the user know the insert was successful
      log && console.log(`Successfully created driver:`, insertedDriverRes);
      toast.success(`Successfully created driver!`);
      // goToAdminUserDetails(ctx.userId, false)
      ctx.handleRefetch()

      // If send onboarding email was checked, send the email
      if (willSendEmail) {
        const emailRes = await sendOnboardingEmail(
          insertedDriverRes.id,
          insertedDriverRes.user.display_name,
          insertedDriverRes.user.email
        );
        if (emailRes) {
          log && console.log(`Onboarding email sent:`, emailRes);
        } else {
          console.error(`Failed to send onboarding email!`);
          toast.error(`Failed to send onboarding email!`);
        }
      }

      // Reset the form and close the modal
      handleFormState();
      if (onClose) onClose();
    } else {
      console.error(`Failed to create driver!`);
      toast.error(`Failed to create driver!`);
    }

    // Set saving to false
    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Add Driver</ModalHeader>
      <ModalContent>
        <>
          <Typography className={cls.titleTxt}>Send Onboarding Email?</Typography>

          <Grid item xs={12}>
            <div className={cls.label}>
              <Typography className={cls.labelTxt}>
                Do you wish to send an email to the user with their self-service driver onboarding link?&nbsp;
              </Typography>
              <Typography className={cls.labelRequired}>*</Typography>
            </div>
            <div className={cls.checkDiv}>
              <Checkbox
                className={cls.checkBox}
                checked={willSendEmail === true}
                onChange={() => setWillSendEmail(true)}
                icon={<Icon>radio_button_unchecked</Icon>}
                checkedIcon={<Icon>radio_button_checked</Icon>}
              />
              <Typography className={cls.checkTxt}>Yes, send the driver an email.</Typography>
            </div>
            <div className={cls.checkDiv}>
              <Checkbox
                className={cls.checkBox}
                checked={willSendEmail === false}
                onChange={() => setWillSendEmail(false)}
                icon={<Icon>radio_button_unchecked</Icon>}
                checkedIcon={<Icon>radio_button_checked</Icon>}
              />
              <Typography className={cls.checkTxt}>
                No, I or the user will enter this information manually from Driver Details.
              </Typography>
            </div>
          </Grid>

          <Spacer xl />

          <Typography className={cls.titleTxt}>Select Region</Typography>

          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <RegionSelect
                required
                label='Select Region'
                value={regionId || 0}
                onChange={e => setRegionId(e.target.value)}
              />
            </Grid>
          </Grid>
        </>
      </ModalContent>

      <div className={cls.background}>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <Typography className={cls.footerTxt}>* Starred fields are required</Typography>

        <ModalAction
          loading={isSaving}
          disabled={isSaving || !isValid}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  subtitleTxt: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    // color: theme.palette.text.secondary,
  },
  footerTxt: {
    marginRight: 'auto',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  placeholder: {
    color: theme.palette.text.disabled,
  },

  background: {
    background: theme.palette.background.dark,
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelRequired: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.error.main,
    [theme.breakpoints.down('xs')]: {
      margin: '2px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },
}));
