//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';

import { useTools } from '../../hooks/useTools';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function InvoicesListItem({ invoice, selectedInvoiceId, setSelectedInvoiceId }) {
  const cls = useStyles();
  const { formatUSD } = useTools();
  const { buildAmountObject } = useInvoices();

  const amount = buildAmountObject(invoice);

  const handleSelectInvoice = () => {
    setSelectedInvoiceId(invoice.id);
  };

  const getRowClassNames = () => {
    let classNames = [cls.row];
    if (invoice.status === `acquiring`) classNames.push(cls.rowAcquiring);
    if (invoice.status === `processing`) classNames.push(cls.rowProcessing);
    if (invoice.id === selectedInvoiceId && invoice.status === `closed`) classNames.push(cls.rowActive);
    if (invoice.id === selectedInvoiceId && invoice.status === `acquiring`) classNames.push(cls.rowAcquiringActive);
    if (invoice.id === selectedInvoiceId && invoice.status === `processing`) classNames.push(cls.rowProcessingActive);
    return classNames.join(` `);
  };

  return (
    <div className={getRowClassNames()} onClick={() => handleSelectInvoice()}>
      <Grid container>
        <Grid item xs>
          <Typography className={cls.idTxt}>#{invoice.id}</Typography>
        </Grid>
        <Grid item>
          <Typography className={cls.amountTxt}>{formatUSD(amount.due)}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography className={cls.customerTxt}>{invoice.customer.name}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography className={cls.dateTxt}>
            {dayjs.utc(dayjs(invoice.start_datetime)).format('MM/DD/YYYY')} -{' '}
            {dayjs.utc(dayjs(invoice.end_datetime)).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      </Grid>
      <div className={cls.iconArea}>
        {invoice.armoves.length > 0 && amount.discounted > 0 ? (
          <Tooltip placement='top' title={`Has Discounts`}>
            <Icon className={cls.icon}>local_offer</Icon>
          </Tooltip>
        ) : null}
        {invoice.armoves.length > 0 && amount.disputed > 0 ? (
          <Tooltip placement='top' title={`Has Disputes`}>
            <Icon className={cls.icon}>announcement</Icon>
          </Tooltip>
        ) : null}
        {invoice.armoves.length > 0 && (amount.disputed <= 0 && amount.paid > 0 && amount.paid <= amount.total) && amount.due > 0 ? (
          <Tooltip placement='top' title={`Partially Paid`}>
            <Icon className={cls.icon}>contrast</Icon>
          </Tooltip>
        ) : null}
        {invoice.armoves.length > 0 && ((amount.disputed <= 0 && amount.paid >= 0 && amount.paid >= amount.total) || amount.due <= 0) ? (
          <Tooltip placement='top' title={`Fully Paid`}>
            <Icon className={cls.icon}>check_circle</Icon>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  row: {
    position: 'relative',
    padding: theme.spacing(1),
    borderBottom: theme.border[0],
    borderRight: theme.border[0],
    background: theme.palette.background.paper,
    '&:hover, &:focus': {
      background: theme.palette.action.hover,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  rowActive: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  rowAcquiring: {
    color: theme.palette.text.secondary,
    '&:hover, &:focus': {
      color: theme.palette.text.secondary,
    },
  },
  rowAcquiringActive: {
    background: theme.palette.action.selected,
    color: theme.palette.text.secondary,
    '&:hover, &:focus': {
      background: theme.palette.action.selected,
      color: theme.palette.text.secondary,
    },
  },
  rowProcessing: {
    color: theme.palette.error.main,
    '&:hover, &:focus': {
      color: theme.palette.error.main,
    },
  },
  rowProcessingActive: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover, &:focus': {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  idTxt: {
    marginBottom: theme.spacing(0.75),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
  },
  amountTxt: {
    marginBottom: theme.spacing(0.75),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 500,
  },
  customerTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 14,
  },
  dateTxt: {
    lineHeight: 1,
    fontSize: 12,
  },
  iconArea: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: theme.spacing(1),
    lineHeight: 0,
  },
  icon: {
    width: 16,
    height: 16,
    fontSize: 16,
  },
}));
