import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PlansToolbar, Schedule } from '../index';
import { useDragAndDrop } from '../hooks/useDragAndDrop';
import { DragDropContext } from 'react-beautiful-dnd';

//////////////////////// COMPONENT ////////////////////////

export default function Layout({ children = <></> }) {
  const cls = useStyles();
  const { onBeforeDragStart, onDragStart, onDragUpdate, onDragEnd } = useDragAndDrop();

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
    >
        <div className={cls.root}>
          <div className={cls.body}>
            <div className={cls.navPad} />
            <PlansToolbar />
            <div className={cls.funArea}>{children}</div>
          </div>
        </div>

        <Schedule />
    </DragDropContext>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  body: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  navPad: {
    position: 'relative',
    width: '100%',
    height: 64,
  },
  funArea: {
    zIndex: 100,
    position: 'relative',
    flex: 1,
    width: '100%',
    height: '100%',
  },
}));
