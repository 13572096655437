import React, { useEffect, useState } from 'react';
import { makeStyles, Chip, Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import ChangeConfirmationModal from './ChangeConfirmationModal';
import dayjs from 'dayjs';

import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../Fallbacks/DefaultErrorFallback';

const log = false;

export default function RateRuleGroupSelector({ rateRuleGroups, rateRules, onChange = () => {}, allowChanges = true }) {
  const cls = useStyles();

  const [selectedRateRuleGroup, setSelectedRateRuleGroup] = useState(null);
  const [pendingRateRuleGroup, setPendingRateRuleGroup] = useState(null);
  const [changeConfirmationModalOpen, setChangeConfirmationModalOpen] = useState(false);

  useEffect(() => {
    log && console.log('Inbound rate rule groups prop set to: ', rateRuleGroups);
    if (!rateRuleGroups) return;
    setSelectedRateRuleGroup(rateRuleGroups.length > 0 ? rateRuleGroups[0] : null);
  }, [rateRuleGroups]);

  useEffect(() => {
    if (!selectedRateRuleGroup) return;
    log && console.log('Selected rate rule group changed to: ', selectedRateRuleGroup.name);
    onChange(selectedRateRuleGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRateRuleGroup]);

  const attemptSelectionChange = rateRuleGroup => {
    if (allowChanges) {
      setSelectedRateRuleGroup(rateRuleGroup);
    } else {
      setChangeConfirmationModalOpen(true);
      setPendingRateRuleGroup(rateRuleGroup);
    }
  };

  const handleChangeConfirmation = () => {
    setChangeConfirmationModalOpen(false);
    setSelectedRateRuleGroup(pendingRateRuleGroup);
    setPendingRateRuleGroup(null);
  };

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING RATE GROUPS' />}>
      <div className={cls.root}>
        <ChangeConfirmationModal
          open={changeConfirmationModalOpen}
          onAgree={handleChangeConfirmation}
          onDisagree={() => {
            setChangeConfirmationModalOpen(false);
            setPendingRateRuleGroup(null);
          }}
        />
        <List component='div' aria-label='rate rule group selector' style={{ width: '100%', padding: '0px' }}>
          {!rateRuleGroups && (
            <ListItem>
              <ListItemText disabled secondary={'Loading...'} />
            </ListItem>
          )}
          {rateRuleGroups &&
            rateRuleGroups.map((rateRuleGroup, index) => (
              <ListItem
                button
                classes={{ root: cls.selectList, selected: cls.selected }}
                key={`rate-rule-group-${rateRuleGroup.id}`}
                onClick={() => {
                  attemptSelectionChange(rateRuleGroup);
                }}
                selected={selectedRateRuleGroup && rateRuleGroup.id === selectedRateRuleGroup.id}
              >
                <ListItemText
                  primary={
                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                      <Grid item md={8}>
                        <Typography style={{ textAlign: 'left' }}>{rateRuleGroup.name}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography style={{ textAlign: 'right' }} component='div'>
                          {((dayjs(rateRuleGroup.begin_date) <= dayjs() && dayjs(rateRuleGroup.end_date) > dayjs()) ||
                            rateRuleGroup.end_date === null) && (
                            <Chip label='Active' variant='default' size='small' color='primary' />
                          )}
                          {dayjs(rateRuleGroup.end_date) <= dayjs() && (
                            <Chip label='Expired' variant='default' size='small' color='secondary' />
                          )}
                          {dayjs(rateRuleGroup.begin_date) >= dayjs() && (
                            <Chip label='Pending' variant='default' size='small' color='secondary' />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  secondary={
                    rateRuleGroup.begin_date !== null
                      ? `${dayjs(rateRuleGroup.begin_date).format(`MMM DD, YYYY`)} - ${dayjs(
                          rateRuleGroup.end_date
                        ).format(`MMM DD, YYYY`)}`
                      : `Unlimited timeframe`
                  }
                />
              </ListItem>
            ))}
          {/*}
        <ListItem button>
          <ListItemIcon style={{minWidth: '35px'}}><Icon>add_box</Icon></ListItemIcon>
          <ListItemText primary={'Add New'} secondary={'Add a new revision'} />
        </ListItem>
        <ListItem style={{display:'flex', justifyContent:'center'}}>
          <Tooltip placement="top" title={`Click to add a new version of this pricing`}>
            <Button className={cls.newVersionBtn} onClick={null}>New Version</Button>
          </Tooltip>
        </ListItem>
        */}
          {/* <ListItem style={{display:'flex', justifyContent:'center', marginTop: '20px', marginBottom: '20px'}}>
          <Tooltip placement="top" title={`Click to add a new version of the active pricing`}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<Icon>add_box</Icon>}
            >
              New Pricing
            </Button>
          </Tooltip>
        </ListItem> */}
        </List>
      </div>
    </Sentry.ErrorBoundary>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: '#f4f4f4',
    //paddingLeft: '15px'
  },
  selectList: {
    '&$selected': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#c3c3c3',
        color: '#fff',
      },
    },
  },
  selected: {},
  newVersionBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
