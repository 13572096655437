// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useTimeline } from './TimelineProvider';

import TimelineIndexRow from './TimelineIndexRow';

// COMPONENT ---------------------------------------------------------------- //

export default function TimelineIndexBody() {
  const cls = useStyles();

  const { data } = useTimeline();

  if (!data?.length) return null;
  return (
    <div className={cls.timelineIndexBody}>
      {data?.map((dataItem, i) => (
        <React.Fragment key={`timeline-index-row-${i}`}>
          <TimelineIndexRow dataItem={dataItem} index={i} />
        </React.Fragment>
      ))}
    </div>
  );
}

// STYLES --------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  timelineIndexBody: {
    zIndex: 111,
    position: 'relative',
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));
