// DEPENDENCIES -------------------------------------------------- //

import React from 'react';
import { TextField, InputAdornment, Icon } from '@material-ui/core';

import { useWorkflow } from './WorkflowProvider';
import StepLabel from './StepLabel';

// COMPONENT -------------------------------------------------- //

export default function StepInput({ step = {}, overrideValue }) {
  const { handleStepChange } = useWorkflow();

  const { id, required, default: defaultValue, config = {}, next_step_id } = step;
  const { validation_pattern, validation_message, start_icon, end_text, ...inputProps } = config;

  const [value, setValue] = React.useState(overrideValue || defaultValue || ``);
  const [valid, setValid] = React.useState(true);

  // Handle the change of the input (and validation)
  const handleValueChange = (event = null) => {
    const newVal = event?.target?.value;
    setValue(newVal);

    // Validate the input
    if (newVal && (!validation_pattern || (validation_pattern && newVal?.match(validation_pattern)))) {
      setValid(true);
    } else {
      setValid(false);
    }

    // Change the state
    if (handleStepChange) handleStepChange(id, newVal, next_step_id);
  };

  return (
    <>
      <StepLabel step={step} />

      <TextField
        fullWidth
        required={required}
        variant='outlined'
        size='small'
        value={value}
        onChange={handleValueChange}
        InputProps={{
          startAdornment: start_icon ? (
            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
              <Icon color='disabled' fontSize='small'>
                {start_icon}
              </Icon>
            </InputAdornment>
          ) : null,
          endAdornment: end_text ? (
            <InputAdornment style={{ verticalAlign: 'top' }} position='end'>
              {end_text}
            </InputAdornment>
          ) : null,
        }}
        inputProps={{
          ...inputProps,
        }}
        helperText={valid ? null : validation_message ? validation_message : `Input is invalid!`}
        error={valid ? false : true}
      />
    </>
  );
}
