import sdk from '@hopdrive/sdk';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { useTools } from '../../../hooks/useTools';
import { useScheduler } from '../providers/SchedulerProvider';

const useMoveActions = (enrichedMove, isAfter = false) => {
  const { searchLocationOnGoogleMaps, searchLaneOnGoogleMaps, goToMoveDetails, goToLaneDetails } = useTools();
  const {
    unplanScheduledDrive,
    deactivateRide,
    setSelectedEnrichedMoveId,
    isPlanEditable,
    handleCancelModalOpen,
    applyMoveChangesThenRunScheduler,
  } = useScheduler();

  // Make sure the enrichedMove exists
  if (!enrichedMove) return [];

  // Extract the move, moveWithOverrides and lane from the enrichedMove
  const move = enrichedMove.move || null;
  const moveWithOverrides = enrichedMove.withOverrides || {};
  const lane = moveWithOverrides.lane || {};

  // Extract enrichedRides
  const enrichedRideBefore = enrichedMove.enrichedRideBefore || null;
  const enrichedRideAfter = enrichedMove.enrichedRideAfter || null;
  //Condition required to allw adding triangle moves
  const customerConfig = sdk.configs.enrichCustomerConfig(
    enrichedMove?.withOverrides?.customer?.config,
    enrichedMove?.withOverrides?.customer?.organization?.config
  );
  const customerAllowsTriangles = customerConfig?.allow_triangle_moves === true;

  // Initialize actions
  let actions = [];

  //////////////////////// LOGIC FUNCTIONS ////////////////////////

  // Unassign the selected drive
  const handleUnplanDrive = () => {
    unplanScheduledDrive(enrichedMove);
    setSelectedEnrichedMoveId(null);
  };

  // Unassign the selected ride
  const handleUnplanRide = () => {
    deactivateRide(enrichedMove, getPropValue(enrichedMove, `withOverrides.lyft_trigger_id`), isAfter, true);
  };

  // Unassign a ride attached to a drive
  const handleUnplanSpecificRide = str => {
    if (str === `before`) {
      deactivateRide(
        enrichedRideBefore,
        getPropValue(enrichedRideBefore, `withOverrides.lyft_trigger_id`),
        false,
        true
      );
    }
    if (str === `after`) {
      deactivateRide(enrichedRideAfter, getPropValue(enrichedRideAfter, `withOverrides.lyft_trigger_id`), true, true);
    }
  };

  //Set a move as triangle (ride before and after, both to be charged in full to customer)
  const handleSetAsTriangle = () => {
    const moveConfig = moveWithOverrides.config;
    //NOTE: empty obj move config was causing issues so I added this check here
    if (Object.keys(moveConfig === null || moveConfig).length === 0) {
      applyMoveChangesThenRunScheduler(enrichedMove, {
        config: { triangle_move: true },
        class: 'base',
        rate_class_override: 1,
      });
    } else {
      const isTriangleMove = moveConfig.triangle_move === true;
      const newMoveConfig = { ...moveConfig, triangle_move: !isTriangleMove };
      //If we are setting this as a triangle move, we also need to set the move to base and set rate class override
      if (!isTriangleMove === true) {
        applyMoveChangesThenRunScheduler(enrichedMove, {
          config: newMoveConfig,
          class: 'base',
          rate_class_override: 1,
        });
      } else {
        applyMoveChangesThenRunScheduler(enrichedMove, { config: newMoveConfig, rate_class_override: 0 });
      }
    }
  };

  //////////////////////// SET ACTIONS ////////////////////////

  // Create a list of actions for the user to take on a drive
  if (moveWithOverrides.move_type === `drive`) {
    actions = [
      {
        label: `Go To Move Details`,
        handler: () => goToMoveDetails(enrichedMove.id),
      },
      {
        label: `Go To Lane Details`,
        handler: () => goToLaneDetails(lane.id),
        disabled: !lane || !lane.id,
      },
      {},
      {
        label: `Unplan Move`,
        handler: () => handleUnplanDrive(),
        disabled:
          !isPlanEditable &&
          moveWithOverrides.status !== `dispatched` &&
          moveWithOverrides.status !== null &&
          moveWithOverrides.status !== 'pickup started',
      },
      {
        label: `Unplan Ride Before`,
        handler: () => handleUnplanSpecificRide(`before`),
        hide: !enrichedRideBefore || enrichedRideBefore.isPlaceholder,
        disabled: !isPlanEditable || !enrichedRideBefore || enrichedRideBefore.inProgress,
      },
      {
        label: `Unplan Ride After`,
        handler: () => handleUnplanSpecificRide(`after`),
        hide: !enrichedRideAfter || enrichedRideAfter.isPlaceholder,
        disabled: !isPlanEditable || !enrichedRideAfter || enrichedRideAfter.inProgress,
      },
      // {
      //   label: `Cancel Move`,
      //   handler: () => handleCancelModalOpen({ move }),
      //   disabled: !isPlanEditable || moveWithOverrides.status === `delivery successful`,
      // },
      {},
      {
        label: `Toggle Triangle Move`,
        handler: () => handleSetAsTriangle(),
        hide: !customerAllowsTriangles,
        disabled: !isPlanEditable,
      },
      {},
      {
        label: `Search Pickup Address`,
        handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
      },
      {
        label: `Search Delivery Address`,
        handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
      },
      {
        label: `Search Directions`,
        handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
      },
    ];
  }

  // Create a list of actions for the user to take on the ride (before or after)
  if (moveWithOverrides.move_type === `ride`) {
    actions = [
      {
        label: `Go To Move Details`,
        handler: () => goToMoveDetails(enrichedMove.id),
      },
      {
        label: `Go To Lane Details`,
        handler: () => goToLaneDetails(lane.id),
        disabled: !lane || !lane.id,
      },
      {},
      {
        label: `Unplan Ride ${isAfter ? `After` : `Before`}`,
        handler: () => handleUnplanRide(),
        disabled: !isPlanEditable || !enrichedMove || enrichedMove.inProgress,
      },
      {},
      {
        label: `Search Pickup Address`,
        handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
      },
      {
        label: `Search Delivery Address`,
        handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
      },
      {
        label: `Search Directions`,
        handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
      },
    ];
  }

  return { handleUnplanDrive, handleUnplanRide, actions };
};

export default useMoveActions;
