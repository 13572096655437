import React from 'react';
import { useTheme, makeStyles, Grid, IconButton, Icon, Tooltip } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useDriverDetails } from './DriverDetailsProvider';

//////////////////////// COMPONENT ////////////////////////

export default function DriverToolbar({ driver }) {
  const theme = useTheme();
  const cls = useStyles();

  const { goToOnboarding } = useTools();
  const { editMode, setEditMode, isSaving, handleSaveChanges } = useDriverDetails();

  return (
    <Grid container alignItems='center'>
      <Grid item xs />

      {driver &&
      (driver.status === `new` ||
        driver.status === `interviewed` ||
        driver.status === `onboarding` ||
        driver.status === `training`) ? (
        <Grid item>
          <Button className={cls.bigBtn} color='secondary' onClick={() => goToOnboarding(driver.id)}>
            Go To Onboarding
          </Button>
        </Grid>
      ) : null}

      {editMode ? (
        <Grid item>
          <Tooltip placement='top' title={`Click to lock & save your changes`}>
            <Button
              loading={isSaving}
              disabled={isSaving}
              className={cls.bigBtn}
              color='primary'
              onClick={() => handleSaveChanges()}
            >
              Save Changes
            </Button>
          </Tooltip>
        </Grid>
      ) : null}

      <Grid item>
        <Tooltip
          placement='top'
          title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the driver`}
        >
          <IconButton
            style={{
              color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
            }}
            className={cls.iconBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <Icon>{editMode ? `lock_open` : `lock`}</Icon>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  bigBtn: {
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
}));
