// -------------------------------- DEPENDENCIES -------------------------------- //

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Grid } from '@material-ui/core';

import { usePlansGroup } from './PlansGroupProvider';
import StaticDriveTileAlt from '../../components/MoveComponents/StaticDriveTileAlt';
import StaticRideTileAlt from '../../components/MoveComponents/StaticRideTileAlt';

// -------------------------------- COMPONENT -------------------------------- //

export default function PlansGroupBundleItem({ driver, move, plan, index }) {
  const cls = useStyles();

  const { rideActive, rideFirst, rideType } = usePlansGroup();

  /** Get the row classNames */
  const getRowClassName = () => {
    let classes = [cls.bundleListRow];

    if (index % 2) classes.push(cls.bundleListRowEven);
    else classes.push(cls.bundleListRowOdd);

    return classes;
  };

  /** Build the ride move */
  const ride = move
    ? {
        ...move,
        ride_type: rideType,
        move_type: `ride`,
        lane: move?.lane?.inverse,
      }
    : undefined;

  return (
    <div className={clsx(getRowClassName())}>
      <Grid container spacing={1}>
        {rideActive && rideFirst ? (
          <Grid item xs>
            <StaticRideTileAlt move={ride} />
          </Grid>
        ) : null}

        <Grid item xs>
          <StaticDriveTileAlt move={move} showMoveId />
        </Grid>

        {rideActive && !rideFirst ? (
          <Grid item xs>
            <StaticRideTileAlt move={ride} />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

// -------------------------------- STYLES -------------------------------- //

const useStyles = makeStyles(theme => ({
  bundleListRow: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
    padding: 8,
  },
  bundleListRowOdd: {
    backgroundColor: theme.palette.background.paper,
  },
  bundleListRowEven: {
    backgroundColor: theme.palette.background.light,
  },
}));
