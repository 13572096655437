//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, useTheme, Grid, Icon, Tooltip, Typography } from '@material-ui/core';

import { useTools } from '../../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileStatusBar({ enrichedMove = {} }) {
  const theme = useTheme();
  const { getFormattedStatusFromDrive, getFormattedStatusFromRide } = useTools();

  const { move = {} } = enrichedMove;

  // Get icon string for StatusBar
  const getStatusBarIcon = () => {
    if (move.status === `failed`) return `close`;

    if (move.status && move.move_type === `drive`) {
      if (move.status === `delivery successful`) return null;
      if (move.status.includes(`pickup`) || move.status.includes(`delivery`)) return `play_arrow`;
    }

    if (move.status && move.move_type === `ride`) {
      if (move.status === `droppedOff`) return null;
      if (move.status === `accepted` || move.status === `arrived` || move.status === `pickedUp`) return `play_arrow`;
    }

    return null;
  };

  // Get text string for StatusBar
  const getStatusBarText = () => {
    if (move.status === `failed`) return `Failed`;

    if (move.status && move.move_type === `drive`) {
      if (move.status === `delivery successful`) return null;
      if (move.status.includes(`delivery`)) return `Delivery`;
      if (move.status.includes(`pickup`)) return `Pickup`;
    }

    if (move.status && move.move_type === `ride`) {
      if (move.status === `droppedOff`) return null;
      if (move.status === `pickedUp`) return `Riding`;
      if (move.status === `arrived`) return `Arrived`;
      if (move.status === `accepted`) return `Waiting`;
    }

    return null;
  };

  // Get color for StatusBar
  const getStatusBarColor = () => {
    if (move.status === `failed`) return theme.palette.error.main;

    if (move.status && move.move_type === `drive`) {
      if (move.status === `delivery successful`) return null;
      if (move.status.includes(`pickup`) || move.status.includes(`delivery`)) return theme.palette.warning.main;
    }

    if (move.status && move.move_type === `ride`) {
      if (move.status === `droppedOff`) return null;
      if (move.status === `accepted` || move.status === `arrived` || move.status === `pickedUp`)
        return theme.palette.warning.main;
    }

    return null;
  };

  // Get status bar animation
  const getStatusBarAnimation = () => {
    if (move.status && move.move_type === `drive`) {
      if (move.status === `delivery successful`) return `none`;
      if (move.status.includes(`pickup`) || move.status.includes(`delivery`)) return `$pulse 1s infinite`;
    }

    if (move.status && move.move_type === `ride`) {
      if (move.status === `droppedOff`) return `none`;
      if (move.status === `accepted` || move.status === `arrived` || move.status === `pickedUp`)
        return `$pulse 1s infinite`;
    }

    return `none`;
  };

  // Get tooltip for StatusBar
  const getStatusBarTooltip = () => {
    if (move.move_type === `drive`) return getFormattedStatusFromDrive(move);
    if (move.move_type === `ride`) return getFormattedStatusFromRide(move);
  };

  const statusBarIcon = getStatusBarIcon();
  const statusBarText = getStatusBarText();
  const statusBarColor = getStatusBarColor();
  const statusBarAnimation = getStatusBarAnimation();
  const statusBarTooltip = getStatusBarTooltip();

  const useStyles = makeStyles(theme => ({
    statusBox: {
      zIndex: 275,
      position: 'absolute',
      display: statusBarColor ? `block` : `none`,
      top: -6,
      left: 6,
      height: 12,
      paddingLeft: 2,
      paddingRight: 6,
      borderRadius: 8,
      background: statusBarColor || `none`,
      color: theme.palette.text.contrast,
      boxShadow: '1px 2px 2px #00000032',
      cursor: 'pointer',
      overflow: 'hidden',
      animation: statusBarAnimation,
    },
    statusIcon: {
      display: 'block',
      fontSize: 12,
      marginRight: 2,
    },
    statusText: {
      verticalAlign: 'top',
      lineHeight: 1,
      fontSize: 10,
      fontWeight: 600,
    },
    '@keyframes pulse': {
      '0%': {
        filter: 'brightness(85%)',
      },
      '50%': {
        filter: 'brightness(115%)',
      },
      '100%': {
        filter: 'brightness(85%)',
      },
    },
  }));
  const cls = useStyles();

  return (
    <Tooltip placement='top' title={statusBarTooltip}>
      <div className={cls.statusBox}>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Icon className={cls.statusIcon}>{statusBarIcon}</Icon>
          </Grid>
          <Grid item xs>
            <Typography className={cls.statusText}>{statusBarText}</Typography>
          </Grid>
        </Grid>
      </div>
    </Tooltip>
  );
}
