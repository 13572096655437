//////////////////////// DEPENDENCIES ////////////////////////
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useData } from '../../providers/DataProvider';
import * as Sentry from '@sentry/react';
import Loading from '../../components/Loading';
import { ErrorBoundary } from '@sentry/react';

class BuggyCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { counter: 0 };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(({ counter }) => ({
      counter: counter + 1,
    }));
  }

  render() {
    if (this.state.counter === 5) {
      // Simulate a JS error
      throw new Error('I crashed!');
    }
    return (
      <h1 style={{ marginLeft: '10px' }} onClick={this.handleClick}>
        {this.state.counter}
      </h1>
    );
  }
}

//////////////////////// COMPONENT ////////////////////////
function Testing(props) {
  const cls = useStyles();
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_CUSTOMERS);
  // const [doLazyQuery] = useLazyQuery(GET_CUSTOMERS);
  // const [doMutation] = useMutation(UPDATE_CUSTOMER);
  const { _sdk } = useData();
  useEffect(() => {
    //SDK use example (Testing purposes only)
    const execute = async () => {
      const customer = await _sdk.gql.query(GET_CUSTOMERS);
      console.log('customer', customer);
    };
    execute();
  }, [_sdk]);
  if (queryLoading) return <Loading fixed />;
  if (queryError) {
    console.error(queryError);
    Sentry.captureException(queryError);
    return (
      <div className={cls.root}>
        <pre style={{ margin: 0 }}>{JSON.stringify(queryError, null, 2)}</pre>
      </div>
    );
  }
  if (queryData)
    return (
      <>
        <ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <React.Fragment>
              <div>You have encountered an error</div>
              <div>{error.toString()}</div>
              <div>{componentStack}</div>
              <button
                onClick={() => {
                  this.setState({ message: 'This is my app' });
                  resetError();
                }}
              >
                Click here to reset!
              </button>
            </React.Fragment>
          )}
        >
          <div>Click to 5 to trigger a component-level error boundary</div>
          <BuggyCounter />
        </ErrorBoundary>
        <br />
        <div>Click to 5 to trigger a page-level error boundary</div>
        <BuggyCounter />
        <br />
        <div className={cls.root}>
          <pre style={{ margin: 0 }}>{JSON.stringify(queryData, null, 2)}</pre>
        </div>
      </>
    );
}
//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));
//////////////////////// GRAPHQL ////////////////////////
const GET_CUSTOMERS = gql`
  query get_customers {
    customers {
      id
      name
    }
  }
`;

// const UPDATE_CUSTOMER = gql`
//   mutation updateCustomer($active: smallint!) {
//     update_customers(where: { id: { _eq: 2 } }, _set: { active: $active }) {
//       affected_rows
//       returning {
//         id
//         active
//       }
//     }
//   }
// `;
//////////////////////// EXPORT ////////////////////////
export default Testing;
